// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("./crdb.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Row2$BsConsole = require("./components/Row2.js");
var Shape$BsConsole = require("./bs-crdb-response/src/Shape.js");
var Format$BsConsole = require("./bs-crdb-response/src/Format.js");
var TriageGroup$BsConsole = require("./TriageGroup.js");
var Tooltip = require("@material-ui/core/Tooltip");
var WindowVariables$BsConsole = require("./WindowVariables.js");
var DrilldownContext$BsConsole = require("./DrilldownContext.js");
var MoreVert = require("@material-ui/icons/MoreVert");
var IconButton = require("@material-ui/core/IconButton");
var BugReport = require("@material-ui/icons/BugReport");

function createStringFilterOptions(fingerprint) {
  return [
          /* tuple */[
            "fingerprint",
            /* Equal */Block.__(0, [/* `String */[
                  -976970511,
                  fingerprint
                ]])
          ],
          /* tuple */[
            "fingerprint",
            /* NotEqual */Block.__(1, [/* `String */[
                  -976970511,
                  fingerprint
                ]])
          ]
        ];
}

var fingerprint = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.unsafe("color", "inherit"),
            /* :: */[
              Css.fontFamily("Inconsolata"),
              /* :: */[
                Css.padding(Css.px(4)),
                /* :: */[
                  Css.selector(":visited", /* :: */[
                        Css.unsafe("color", "inherit"),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]),
      /* :: */[
        "triage-fingerprint-link",
        /* [] */0
      ]
    ]);

var iconButton = {
  root: Css.style(/* :: */[
        Css.fontSize(Css.rem(1)),
        /* :: */[
          Css.important(Css.padding(Css.px(4))),
          /* [] */0
        ]
      ]),
  label: Css.merge(/* :: */[
        Css.style(/* :: */[
              Css.unsafe("fontSize", "inherit"),
              /* [] */0
            ]),
        /* :: */[
          "triage-action",
          /* [] */0
        ]
      ])
};

var row = Css.style(/* :: */[
      Css.selector("> *:not(:first-child)", /* :: */[
            Css.marginLeft(Css.px(4)),
            /* :: */[
              Css.justifySelf(/* flexEnd */924268066),
              /* [] */0
            ]
          ]),
      /* [] */0
    ]);

var fingerprintContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var friendlyNameContainer = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.7)),
      /* [] */0
    ]);

var Styles = {
  fingerprint: fingerprint,
  iconButton: iconButton,
  row: row,
  fingerprintContainer: fingerprintContainer,
  friendlyNameContainer: friendlyNameContainer
};

function addFilter(aperture, filters) {
  var filterMap = Belt_List.toArray(filters).reduce((function (filterMap, param) {
          return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                          param[0],
                          param[1]
                        ]), filterMap);
        }), Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap, aperture);
}

function getDebuggerRoute(projectName, fingerprint, aperture) {
  var filtersToAdd = WindowVariables$BsConsole.maybeAutoAddDeletedEqualZero ? /* :: */[
      /* tuple */[
        "fingerprint",
        /* Equal */Block.__(0, [/* `String */[
              -976970511,
              fingerprint
            ]])
      ],
      /* :: */[
        /* tuple */[
          "_deleted",
          /* Equal */Block.__(0, [/* `Int */[
                3654863,
                Int64.zero
              ]])
        ],
        /* [] */0
      ]
    ] : /* :: */[
      /* tuple */[
        "fingerprint",
        /* Equal */Block.__(0, [/* `String */[
              -976970511,
              fingerprint
            ]])
      ],
      /* [] */0
    ];
  return /* ProjectDebugger2 */Block.__(19, [
            projectName,
            {
              aperture: Crdb$BsConsole.addFilter(aperture, filtersToAdd),
              state: undefined
            }
          ]);
}

function TriageFingerprint(Props) {
  var fingerprint$1 = Props.fingerprint;
  var handleChangeUrl = Props.handleChangeUrl;
  var getDetailsRoute = Props.getDetailsRoute;
  var aperture = Props.aperture;
  var projectName = Props.projectName;
  var row$1 = Props.row;
  var match = Belt_Option.getWithDefault(Belt_Array.getBy(row$1.aggregations, (function (shape) {
              if (Shape$BsConsole.getName(shape) === "error.message") {
                return Shape$BsConsole.getAggString(shape) === "head";
              } else {
                return false;
              }
            })), /* Head */Block.__(3, [
          "Default",
          undefined
        ]));
  var maybeErrorMessage;
  if (match.tag === /* Head */3 && match[0] === "error.message") {
    var errorMessage = match[1];
    maybeErrorMessage = errorMessage !== undefined ? Format$BsConsole.toString(errorMessage) : undefined;
  } else {
    maybeErrorMessage = undefined;
  }
  var drilldownDispatch = DrilldownContext$BsConsole.useDrilldownDispatch(undefined);
  var detailsRoute = Curry._1(getDetailsRoute, fingerprint$1);
  var debuggerRoute = getDebuggerRoute(projectName, fingerprint$1, aperture);
  var match$1 = Gate$BsConsole.isBetaFn(undefined);
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: row,
              children: React.createElement("div", {
                    className: Belt_Option.isSome(maybeErrorMessage) && Gate$BsConsole.isBetaFn(undefined) ? fingerprintContainer : ""
                  }, React.createElement("div", undefined, React.createElement(Link$BsConsole.Jsx3.make, {
                            route: detailsRoute,
                            handleChangeUrl: handleChangeUrl,
                            className: fingerprint,
                            style: { },
                            children: React.createElement(Tooltip.default, {
                                  placement: "top",
                                  children: React.createElement("span", undefined, I18N$BsConsole.showSkip(TriageGroup$BsConsole.formatFingerprint(fingerprint$1).slice(0, 7))),
                                  title: I18N$BsConsole.get(undefined, "View fingerprint group details")
                                })
                          }), React.createElement(Link$BsConsole.Jsx3.make, {
                            route: debuggerRoute,
                            handleChangeUrl: handleChangeUrl,
                            children: React.createElement(Tooltip.default, {
                                  placement: "top",
                                  children: React.createElement(IconButton.default, {
                                        classes: iconButton,
                                        children: React.createElement(BugReport.default, {
                                              color: "inherit",
                                              fontSize: "inherit"
                                            })
                                      }),
                                  title: I18N$BsConsole.get(undefined, "View in debugger")
                                })
                          }), React.createElement(Tooltip.default, {
                            placement: "top",
                            children: React.createElement(IconButton.default, {
                                  classes: iconButton,
                                  onClick: (function ($$event) {
                                      var el = $$event.currentTarget;
                                      if (!(el == null)) {
                                        if (drilldownDispatch !== undefined) {
                                          return Curry._1(drilldownDispatch, /* SetDrilldown */[/* tuple */[
                                                        Caml_option.some(el),
                                                        fingerprint$1,
                                                        createStringFilterOptions(fingerprint$1),
                                                        [],
                                                        (function (param) {
                                                            
                                                          })
                                                      ]]);
                                        } else {
                                          console.log("DrilldownContext's provider is needed as a parent for this component to function properly.");
                                          return ;
                                        }
                                      }
                                      
                                    }),
                                  children: React.createElement(MoreVert.default, {
                                        color: "inherit",
                                        fontSize: "inherit"
                                      })
                                }),
                            title: I18N$BsConsole.get(undefined, "More group options")
                          })), maybeErrorMessage !== undefined && match$1 ? React.createElement("div", {
                          className: friendlyNameContainer
                        }, I18N$BsConsole.show(undefined, maybeErrorMessage)) : null)
            });
}

var make = TriageFingerprint;

exports.createStringFilterOptions = createStringFilterOptions;
exports.Styles = Styles;
exports.addFilter = addFilter;
exports.getDebuggerRoute = getDebuggerRoute;
exports.make = make;
/* fingerprint Not a pure module */
