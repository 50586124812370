// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var NewStyles$BsConsole = require("./NewStyles.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var BpgController$BsConsole = require("../BpgController.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var SavedQueriesModel$BsConsole = require("./SavedQueriesModel.js");

function SavedQueriesDelete(Props) {
  var savedView = Props.savedView;
  var query = Props.query;
  var refetchQueries = Props.refetchQueries;
  var handleTask = Props.handleTask;
  var token = Props.token;
  var navToLoad = Props.navToLoad;
  var navToDetails = Props.navToDetails;
  var config = Props.config;
  var pid = Props.pid;
  var projectDefault = Props.projectDefault;
  var refetchProjectDefault = Props.refetchProjectDefault;
  var userDefault = Props.userDefault;
  var refetchUserDefault = Props.refetchUserDefault;
  var projectName = Props.projectName;
  var savedViewName = SavedQueriesModel$BsConsole.getName(savedView);
  var userDefaultName = Belt_Option.map(userDefault, SavedQueriesModel$BsConsole.getName);
  var projectDefaultName = Belt_Option.map(projectDefault, SavedQueriesModel$BsConsole.getName);
  var isProjectDefault = projectDefaultName !== undefined ? projectDefaultName === savedViewName : false;
  var isUserDefault = userDefaultName !== undefined ? userDefaultName === savedViewName : false;
  return ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Query.Delete.make, (function (param, param$1) {
                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully deleted view "));
                    if (isProjectDefault) {
                      SavedQueriesModel$BsConsole.clearProjectDefault(token, pid, projectName, refetchProjectDefault);
                    }
                    if (isUserDefault) {
                      SavedQueriesModel$BsConsole.clearUserDefault(token, config, config.user.uid, projectName, refetchUserDefault);
                    }
                    Curry._1(refetchQueries, undefined);
                    return Curry._1(navToLoad, undefined);
                  }), handleTask, token, (function (state, handleSubmit, _toKey) {
                    var msg = state.remote;
                    var tmp;
                    tmp = typeof msg === "number" || msg.tag !== /* Failure */1 ? null : ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading5 */4, undefined, Css.style(/* :: */[
                                    Css.margin(/* zero */-789508312),
                                    /* [] */0
                                  ]), undefined, undefined, undefined, [Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                          /* String_literal */Block.__(11, [
                                              "Error: ",
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* End_of_format */0
                                                ])
                                            ]),
                                          "Error: %s"
                                        ]), msg[0])]));
                    return React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.height(/* `vh */[
                                            26418,
                                            100
                                          ]),
                                      /* :: */[
                                        Css.padding(/* `rem */[
                                              5691738,
                                              3
                                            ]),
                                        /* [] */0
                                      ]
                                    ])
                              }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                        Css.style(/* :: */[
                                              Css.height(Css.px(48)),
                                              /* :: */[
                                                Css.position(/* absolute */-1013592457),
                                                /* :: */[
                                                  Css.top(/* `rem */[
                                                        5691738,
                                                        1
                                                      ]),
                                                  /* :: */[
                                                    Css.right(/* `rem */[
                                                          5691738,
                                                          1
                                                        ]),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]),
                                        undefined,
                                        (function (param) {
                                            return Curry._1(navToLoad, undefined);
                                          }),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Clear.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                      ])), tmp, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading5 */4, undefined, Css.style(/* :: */[
                                                Css.margin(/* zero */-789508312),
                                                /* [] */0
                                              ]), undefined, undefined, undefined, [
                                            I18N$BsConsole.show(undefined, "Are you sure you want to delete the "),
                                            React.createElement("span", {
                                                  className: Css.style(/* :: */[
                                                        Css.color(Css.hex(Colors$BsConsole.accent)),
                                                        /* [] */0
                                                      ])
                                                }, I18N$BsConsole.showSkip(SavedQueriesModel$BsConsole.getName(savedView))),
                                            I18N$BsConsole.show(undefined, " view?")
                                          ])), isUserDefault ? ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading5 */4, undefined, Css.style(/* :: */[
                                                  Css.marginBottom(/* zero */-789508312),
                                                  /* :: */[
                                                    Css.marginTop(/* `rem */[
                                                          5691738,
                                                          2
                                                        ]),
                                                    /* [] */0
                                                  ]
                                                ]), undefined, undefined, undefined, [
                                              I18N$BsConsole.show(undefined, "This view is your "),
                                              React.createElement("span", {
                                                    className: Css.style(/* :: */[
                                                          Css.color(Css.hex(Colors$BsConsole.accent)),
                                                          /* [] */0
                                                        ])
                                                  }, I18N$BsConsole.show(undefined, "User default")),
                                              I18N$BsConsole.showSkip(".")
                                            ])) : null, isProjectDefault ? ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading5 */4, undefined, Css.style(/* :: */[
                                                  Css.marginBottom(/* zero */-789508312),
                                                  /* :: */[
                                                    Css.marginTop(/* `rem */[
                                                          5691738,
                                                          2
                                                        ]),
                                                    /* [] */0
                                                  ]
                                                ]), undefined, undefined, undefined, [
                                              I18N$BsConsole.show(undefined, "This view is your "),
                                              React.createElement("span", {
                                                    className: Css.style(/* :: */[
                                                          Css.color(Css.hex(Colors$BsConsole.accent)),
                                                          /* [] */0
                                                        ])
                                                  }, I18N$BsConsole.show(undefined, "Project default")),
                                              I18N$BsConsole.show(undefined, ".")
                                            ])) : null, React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.marginTop(/* `rem */[
                                                    5691738,
                                                    8
                                                  ]),
                                              /* [] */0
                                            ])
                                      }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                undefined,
                                                Css.merge(/* :: */[
                                                      NewStyles$BsConsole.containedButton,
                                                      /* [] */0
                                                    ]),
                                                (function (param) {
                                                    return Curry._1(handleSubmit, /* tuple */[
                                                                query,
                                                                Util$BsConsole.identity
                                                              ]);
                                                  }),
                                                undefined,
                                                undefined,
                                                /* Raised */-387916264,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [I18N$BsConsole.show(undefined, "Yes, delete it")]
                                              ])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                undefined,
                                                Css.merge(/* :: */[
                                                      NewStyles$BsConsole.textButton,
                                                      /* :: */[
                                                        Css.style(/* :: */[
                                                              Css.marginLeft(/* `rem */[
                                                                    5691738,
                                                                    1
                                                                  ]),
                                                              /* [] */0
                                                            ]),
                                                        /* [] */0
                                                      ]
                                                    ]),
                                                (function (_e) {
                                                    return Curry._1(navToDetails, undefined);
                                                  }),
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [I18N$BsConsole.show(undefined, "Nevermind")]
                                              ])))));
                  })));
}

function make(savedView, query, refetchQueries, handleTask, token, navToLoad, navToDetails, config, pid, projectDefault, refetchProjectDefault, userDefault, refetchUserDefault, projectName, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(SavedQueriesDelete, {
              savedView: savedView,
              query: query,
              refetchQueries: refetchQueries,
              handleTask: handleTask,
              token: token,
              navToLoad: navToLoad,
              navToDetails: navToDetails,
              config: config,
              pid: pid,
              projectDefault: projectDefault,
              refetchProjectDefault: refetchProjectDefault,
              userDefault: userDefault,
              refetchUserDefault: refetchUserDefault,
              projectName: projectName
            }, _children);
}

var Re = {
  make: make
};

var make$1 = SavedQueriesDelete;

exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
