// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var Services$BsConsole = require("../Services.js");
var Chat = require("@material-ui/icons/Chat");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var GetApp = require("@material-ui/icons/GetApp");
var BugDownloadError$BsConsole = require("./BugDownloadError.js");
var BugMultiLevelMenu$BsConsole = require("./BugMultiLevelMenu.js");
var BugCommentsPopover$BsConsole = require("./BugCommentsPopover.js");
var BugViewAttachments$BsConsole = require("./BugViewAttachments.js");
var Reprocessing_Tasks$BsConsole = require("../reprocessing/Reprocessing_Tasks.js");
var ErrorOutline = require("@material-ui/icons/ErrorOutline");
var BugAnnotationsPopover$BsConsole = require("./BugAnnotationsPopover.js");
var CenterFocusStrong = require("@material-ui/icons/CenterFocusStrong");

function BugHeaderActionButtons(Props) {
  var variableAnnotations = Props.variableAnnotations;
  var onSelectVariableId = Props.onSelectVariableId;
  var downloadMissingSymsBtn = Props.downloadMissingSymsBtn;
  var faulting = Props.faulting;
  var onJumpToFaultingFrame = Props.onJumpToFaultingFrame;
  var config = Props.config;
  var fingerprint = Props.fingerprint;
  var token = Props.token;
  var project = Props.project;
  var errorId = Props.errorId;
  var attachments = Props.attachments;
  var onAttachmentUploaded = Props.onAttachmentUploaded;
  var missingSymbols = Props.missingSymbols;
  var compressed = Props.compressed;
  var universeName = Props.universeName;
  var match = React.useState((function () {
          
        }));
  var setAnnotationsPopoverAnchor = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setCommentsPopoverAnchor = match$1[1];
  var activeUser = config.user;
  var onReprocessObject = function (param) {
    var arg = Reprocessing_Tasks$BsConsole.reloadObjects(token, project.name, universeName, [errorId]);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, 4000, undefined, I18N$BsConsole.show(undefined, "Reprocessing scheduled for error instance. Refresh the page to reload the error."));
                  console.log(resp);
                  
                }));
  };
  var serviceUrl = Services$BsConsole.getEndpoint(undefined)(config.services, "comments");
  var tmp = {
    config: config,
    activeUser: activeUser,
    errorId: errorId,
    onReprocessObject: onReprocessObject,
    projectName: project.name,
    isCompressed: Belt_Option.flatMap(compressed, (function (v) {
            return Json_decode.optional(Json_decode.$$int, v);
          }))
  };
  if (missingSymbols !== undefined) {
    tmp.missingSymbols = Caml_option.valFromOption(missingSymbols);
  }
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              children: null
            }, variableAnnotations !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                        ariaLabel: variableAnnotations.length !== 0 ? I18N$BsConsole.get(undefined, "Annotations") : I18N$BsConsole.get(undefined, "No annotations"),
                        classes: {
                          root: Css.style(/* :: */[
                                Css.padding2(Css.px(2), Css.px(3)),
                                /* :: */[
                                  Css.paddingRight(Css.px(5)),
                                  /* :: */[
                                    Css.borderRadius(Css.px(2)),
                                    /* :: */[
                                      Css.color(variableAnnotations.length !== 0 ? Css.hex(Colors$BsConsole.orangeDark) : Css.hex(Colors$BsConsole.grey5)),
                                      /* :: */[
                                        Css.background(Css.hex(variableAnnotations.length !== 0 ? Colors$BsConsole.orangeExtraLight : Colors$BsConsole.grey8)),
                                        /* :: */[
                                          Css.hover(/* :: */[
                                                Css.background(Css.hex(variableAnnotations.length !== 0 ? Colors$BsConsole.orangeExtraLight : Colors$BsConsole.grey8)),
                                                /* [] */0
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]),
                          label: Css.style(/* :: */[
                                Css.height(Css.px(16)),
                                /* [] */0
                              ])
                        },
                        onClick: (function (e) {
                            if (variableAnnotations.length === 0) {
                              return ;
                            }
                            var currTarget = e.currentTarget;
                            return Curry._1(setAnnotationsPopoverAnchor, (function (param) {
                                          return Caml_option.some(currTarget);
                                        }));
                          }),
                        enterDelay_tooltip: 250,
                        title_tooltip: variableAnnotations.length !== 0 ? I18N$BsConsole.get(undefined, "Annotations") : I18N$BsConsole.get(undefined, "No annotations"),
                        children: null
                      }, React.createElement(ErrorOutline.default, {
                            fontSize: "small",
                            viewBox: "-4 -4 31 31"
                          }), React.createElement("span", {
                            className: Css.style(/* :: */[
                                  Css.fontSize(Css.px(15)),
                                  /* :: */[
                                    Css.lineHeight(Css.px(16)),
                                    /* :: */[
                                      Css.paddingLeft(Css.px(2)),
                                      /* :: */[
                                        Css.fontWeight(Css.normal),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ])
                          }, I18N$BsConsole.showSkip(String(variableAnnotations.length)))), React.createElement(BugAnnotationsPopover$BsConsole.make, {
                        annotations: variableAnnotations,
                        anchorEl: match[0],
                        onClose: (function (param) {
                            return Curry._1(setAnnotationsPopoverAnchor, (function (param) {
                                          
                                        }));
                          }),
                        onSelectVariableId: onSelectVariableId
                      })) : null, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  className: Css.style(/* :: */[
                        Css.padding2(Css.zero, Css.px(8)),
                        /* [] */0
                      ]),
                  children: null
                }, downloadMissingSymsBtn, React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                      ariaLabel: faulting !== undefined ? (
                          faulting ? I18N$BsConsole.get(undefined, "Jump to faulting frame") : I18N$BsConsole.get(undefined, "Jump to faulting thread")
                        ) : I18N$BsConsole.get(undefined, "No faulting thread"),
                      disabled: Belt_Option.isNone(faulting),
                      onClick: (function (param) {
                          return Curry._1(onJumpToFaultingFrame, undefined);
                        }),
                      enterDelay_tooltip: 250,
                      title_tooltip: faulting !== undefined ? (
                          faulting ? I18N$BsConsole.get(undefined, "Jump to faulting frame") : I18N$BsConsole.get(undefined, "Jump to faulting thread")
                        ) : I18N$BsConsole.get(undefined, "No faulting thread"),
                      children: React.createElement("span", {
                            className: Css.style(/* :: */[
                                  Css.width(Css.px(20)),
                                  /* :: */[
                                    Css.height(Css.px(20)),
                                    /* :: */[
                                      Css.fontSize(Css.px(20)),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          }, React.createElement(CenterFocusStrong.default, {
                                fontSize: "inherit",
                                viewBox: "1 1 23 23"
                              }))
                    }), React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                      ariaLabel: I18N$BsConsole.get(undefined, "Comments"),
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginBottom(Css.px(1)),
                              /* [] */0
                            ])
                      },
                      onClick: (function (e) {
                          var currTarget = e.currentTarget;
                          return Curry._1(setCommentsPopoverAnchor, (function (param) {
                                        return Caml_option.some(currTarget);
                                      }));
                        }),
                      enterDelay_tooltip: 250,
                      title_tooltip: I18N$BsConsole.get(undefined, "Comments"),
                      children: React.createElement("span", {
                            className: Css.style(/* :: */[
                                  Css.width(Css.px(20)),
                                  /* :: */[
                                    Css.height(Css.px(20)),
                                    /* :: */[
                                      Css.fontSize(Css.px(20)),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          }, React.createElement(Chat.default, {
                                fontSize: "inherit",
                                viewBox: "-1 -1 26 26"
                              }))
                    }), serviceUrl !== undefined ? React.createElement(BugCommentsPopover$BsConsole.make, {
                        fingerprint: fingerprint,
                        activeUser: activeUser,
                        projectId: project.pid,
                        anchorEl: match$1[0],
                        onClose: (function (param) {
                            return Curry._1(setCommentsPopoverAnchor, (function (param) {
                                          
                                        }));
                          }),
                        serviceUrl: serviceUrl,
                        token: token
                      }) : null, React.createElement(BugViewAttachments$BsConsole.make, {
                      token: token,
                      projectName: project.name,
                      errorId: errorId,
                      attachments: attachments,
                      config: config,
                      onAttachmentUploaded: onAttachmentUploaded
                    }), React.createElement(BugDownloadError$BsConsole.make, {
                      token: token,
                      projectName: project.name,
                      errorId: errorId,
                      children: (function (onClick) {
                          return React.createElement(Mui$BsConsole.IconButton.make, {
                                      ariaLabel: "download error",
                                      onClick: onClick,
                                      children: React.createElement("span", {
                                            className: Css.style(/* :: */[
                                                  Css.width(Css.px(20)),
                                                  /* :: */[
                                                    Css.height(Css.px(20)),
                                                    /* :: */[
                                                      Css.fontSize(Css.px(20)),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ])
                                          }, React.createElement(GetApp.default, {
                                                fontSize: "inherit",
                                                viewBox: "1 1 23 23"
                                              }))
                                    });
                        })
                    }), React.createElement(BugMultiLevelMenu$BsConsole.make, tmp)));
}

var make = BugHeaderActionButtons;

exports.make = make;
/* Css Not a pure module */
