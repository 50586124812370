// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var I18N$BsConsole = require("./I18N.js");
var Util$BsConsole = require("./util.js");
var ApiWf$BsConsole = require("./workflow/workflows-api/ApiWf.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var WfTasks$BsConsole = require("./workflow/WfTasks.js");
var WfRouter$BsConsole = require("./workflow/WfRouter.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var Menu = require("@material-ui/core/Menu");
var BtTypography$BsConsole = require("./BtTypography.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var Table = require("@material-ui/core/Table");
var Edit = require("@material-ui/icons/Edit");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Select = require("@material-ui/core/Select");
var WarningMessage$BsConsole = require("./WarningMessage.js");
var WatcherNewTask$BsConsole = require("./WatcherNewTask.js");
var Tooltip = require("@material-ui/core/Tooltip");
var Cancel = require("@material-ui/icons/Cancel");
var Delete = require("@material-ui/icons/Delete");
var Repeat = require("@material-ui/icons/Repeat");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");
var MenuItem = require("@material-ui/core/MenuItem");
var TableRow = require("@material-ui/core/TableRow");
var ApiWfConnections$BsConsole = require("./workflow/workflows-api/ApiWfConnections.js");
var TableBody = require("@material-ui/core/TableBody");
var TableCell = require("@material-ui/core/TableCell");
var TableHead = require("@material-ui/core/TableHead");
var TextField = require("@material-ui/core/TextField");
var MoreVert = require("@material-ui/icons/MoreVert");
var ApiWfIntegrations$BsConsole = require("./workflow/workflows-api/ApiWfIntegrations.js");
var IconButton = require("@material-ui/core/IconButton");
var DialogTitle = require("@material-ui/core/DialogTitle");
var CheckCircle = require("@material-ui/icons/CheckCircle");
var DialogActions = require("@material-ui/core/DialogActions");
var DialogContent = require("@material-ui/core/DialogContent");
var OutlinedInput = require("@material-ui/core/OutlinedInput");
var L10N_date_time_calendar$BsConsole = require("./L10N_date_time_calendar.js");
var CircularProgress = require("@material-ui/core/CircularProgress");
var DialogContentText = require("@material-ui/core/DialogContentText");

function createErrorElement(index, failedIntegration, integrations) {
  var getIntegrationName = function (id) {
    var matchedIntegration = Belt_Array.getBy(integrations, (function (integration) {
            return integration.id === id;
          }));
    if (matchedIntegration !== undefined) {
      return matchedIntegration.watcherName;
    } else {
      return "N/A";
    }
  };
  return React.createElement("ul", {
              key: "failed-error-" + String(index)
            }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                      /* String_literal */Block.__(11, [
                          "Integration: ",
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* End_of_format */0
                            ])
                        ]),
                      "Integration: %s"
                    ]), getIntegrationName(failedIntegration.id)), React.createElement("li", undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                          /* String_literal */Block.__(11, [
                              "error: ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ]),
                          "error: %s"
                        ]), failedIntegration.result.error.error)), React.createElement("li", undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                          /* String_literal */Block.__(11, [
                              "description: ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ]),
                          "description: %s"
                        ]), failedIntegration.result.error.error_description)));
}

var inputItem = Css.style(/* :: */[
      Css.padding(Css.px(10)),
      /* :: */[
        Css.minWidth(Css.px(250)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* :: */[
            Css.boxSizing(/* borderBox */9307263),
            /* [] */0
          ]
        ]
      ]
    ]);

var loadingContainerLarge = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.bottom(/* zero */-789508312),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* :: */[
              Css.right(/* zero */-789508312),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.justifyContent(/* center */98248149),
                  /* :: */[
                    Css.alignItems(/* center */98248149),
                    /* :: */[
                      Css.backgroundColor(Css.hex(Colors$BsConsole.blackA15)),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.white)),
                        /* :: */[
                          Css.zIndex(5),
                          /* :: */[
                            Css.height(Css.vh(75)),
                            /* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var loadingContainerSmall = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.bottom(/* zero */-789508312),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* :: */[
              Css.right(/* zero */-789508312),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.justifyContent(/* center */98248149),
                  /* :: */[
                    Css.alignItems(/* center */98248149),
                    /* :: */[
                      Css.backgroundColor(Css.hex(Colors$BsConsole.blackA15)),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.white)),
                        /* :: */[
                          Css.zIndex(5),
                          /* :: */[
                            Css.height(Css.pct(100)),
                            /* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var container = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.padding(Css.px(24)),
        /* [] */0
      ]
    ]);

var container$1 = Css.style(/* :: */[
      Css.marginBottom(Css.px(41)),
      /* [] */0
    ]);

var title = Css.style(/* :: */[
      Css.marginTop(Css.px(10)),
      /* :: */[
        Css.marginBottom(Css.px(10)),
        /* :: */[
          Css.fontSize(Css.px(24)),
          /* :: */[
            Css.lineHeight(Css.px(24)),
            /* :: */[
              Css.fontWeight(/* `num */[
                    5496390,
                    500
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var description = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.lineHeight(Css.px(24)),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                400
              ]),
          /* [] */0
        ]
      ]
    ]);

var Header = {
  container: container$1,
  title: title,
  description: description
};

var container$2 = Css.style(/* :: */[
      Css.unsafe("width", "calc(100% - 28px -28px)"),
      /* :: */[
        Css.marginRight(Css.px(28)),
        /* :: */[
          Css.marginLeft(Css.px(28)),
          /* [] */0
        ]
      ]
    ]);

var cell = {
  root: Css.style(/* :: */[
        Css.important(Css.padding(Css.px(0))),
        /* [] */0
      ])
};

var cellHeader = {
  root: Css.style(/* :: */[
        Css.important(Css.padding(Css.px(0))),
        /* :: */[
          Css.fontSize(Css.px(11)),
          /* :: */[
            Css.lineHeight(Css.px(16)),
            /* :: */[
              Css.fontWeight(/* `num */[
                    5496390,
                    600
                  ]),
              /* [] */0
            ]
          ]
        ]
      ])
};

var largeCell = Css.style(/* :: */[
      Css.width(Css.pct(25)),
      /* [] */0
    ]);

var smallCell = Css.style(/* :: */[
      Css.width(Css.pct(15)),
      /* [] */0
    ]);

var menuCell = Css.style(/* :: */[
      Css.width(Css.pct(5)),
      /* [] */0
    ]);

var empty = Css.merge(/* :: */[
      description,
      /* :: */[
        Css.style(/* :: */[
              Css.marginTop(Css.px(15)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var Table$1 = {
  container: container$2,
  cell: cell,
  cellHeader: cellHeader,
  largeCell: largeCell,
  smallCell: smallCell,
  menuCell: menuCell,
  empty: empty
};

var container$3 = Css.style(/* :: */[
      Css.marginBottom(Css.px(41)),
      /* [] */0
    ]);

var ConnectionRow = {
  container: container$3
};

var container$4 = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* [] */0
      ]
    ]);

var icon = Css.style(/* :: */[
      Css.width(Css.px(20)),
      /* :: */[
        Css.height(Css.px(20)),
        /* :: */[
          Css.marginRight(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var iconRed = Css.style(/* :: */[
      Css.width(Css.px(20)),
      /* :: */[
        Css.height(Css.px(20)),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.redDark)),
          /* [] */0
        ]
      ]
    ]);

var title$1 = Css.style(/* :: */[
      Css.fontSize(Css.px(20)),
      /* :: */[
        Css.lineHeight(Css.px(24)),
        /* [] */0
      ]
    ]);

var buttonsContainer = Css.style(/* :: */[
      Css.flexGrow(1),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.justifyContent(/* flexEnd */924268066),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* [] */0
          ]
        ]
      ]
    ]);

var button = Css.style(/* :: */[
      Css.padding(Css.px(4)),
      /* :: */[
        Css.paddingRight(Css.px(16)),
        /* :: */[
          Css.paddingLeft(Css.px(16)),
          /* :: */[
            Css.cursor(/* pointer */-786317123),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.blue)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var buttonRed = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.redDark)),
      /* [] */0
    ]);

var buttonIcon = Css.style(/* :: */[
      Css.width(Css.px(15)),
      /* :: */[
        Css.height(Css.px(15)),
        /* :: */[
          Css.marginRight(Css.px(6)),
          /* [] */0
        ]
      ]
    ]);

var buttonLabel = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.lineHeight(Css.px(24)),
        /* :: */[
          Css.cursor(/* pointer */-786317123),
          /* [] */0
        ]
      ]
    ]);

var ConnectionHeader = {
  container: container$4,
  icon: icon,
  iconRed: iconRed,
  title: title$1,
  buttonsContainer: buttonsContainer,
  button: button,
  buttonRed: buttonRed,
  buttonIcon: buttonIcon,
  buttonLabel: buttonLabel
};

var statusIcon = Css.style(/* :: */[
      Css.fontSize(Css.rem(1.2)),
      /* :: */[
        Css.marginRight(Css.px(5)),
        /* [] */0
      ]
    ]);

var statusIconContainer = Css.style(/* :: */[
      Css.display(/* inlineFlex */53323314),
      /* :: */[
        Css.paddingRight(Css.px(5)),
        /* :: */[
          Css.paddingLeft(Css.px(2)),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.borderRadius(Css.rem(0.5)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var green = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.npGreen)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.greenExtraLight)),
        /* [] */0
      ]
    ]);

var red = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.redDark)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.redBackground)),
        /* [] */0
      ]
    ]);

var IntegrationRow = {
  statusIcon: statusIcon,
  statusIconContainer: statusIconContainer,
  green: green,
  red: red
};

var container$5 = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.margin(Css.px(0)),
        /* [] */0
      ]
    ]);

var description$1 = Css.style(/* :: */[
      Css.marginBottom(Css.px(16)),
      /* [] */0
    ]);

var Dialog$1 = {
  container: container$5,
  description: description$1
};

var errorDataDiv = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexStart */662439529),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.position(/* relative */903134412),
            /* :: */[
              Css.marginTop(Css.px(10)),
              /* :: */[
                Css.selector("ul", /* :: */[
                      Css.marginLeft(Css.px(0)),
                      /* :: */[
                        Css.marginTop(Css.px(10)),
                        /* :: */[
                          Css.padding(Css.px(0)),
                          /* [] */0
                        ]
                      ]
                    ]),
                /* :: */[
                  Css.selector("li", /* :: */[
                        Css.marginLeft(Css.px(20)),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  inputItem: inputItem,
  loadingContainerLarge: loadingContainerLarge,
  loadingContainerSmall: loadingContainerSmall,
  container: container,
  Header: Header,
  Table: Table$1,
  ConnectionRow: ConnectionRow,
  ConnectionHeader: ConnectionHeader,
  IntegrationRow: IntegrationRow,
  Dialog: Dialog$1,
  errorDataDiv: errorDataDiv
};

function SettingsWfConnectionManagement$Loading(Props) {
  var size = Props.size;
  var className = Props.className;
  return React.createElement("div", {
              className: className
            }, React.createElement(CircularProgress.default, {
                  size: size,
                  color: "inherit"
                }));
}

var Loading = {
  make: SettingsWfConnectionManagement$Loading
};

function SettingsWfConnectionManagement$SelectConnectionToTransferIntegration(Props) {
  var integration = Props.integration;
  var config = Props.config;
  var token = Props.token;
  var connections = Props.connections;
  var availableIntegrations = Props.availableIntegrations;
  var handleSetError = Props.handleSetError;
  var closeDialog = Props.closeDialog;
  var closeDialogAndRefetch = Props.closeDialogAndRefetch;
  var match = React.useState((function () {
          
        }));
  var setNewConnection = match[1];
  var newConnection = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setLoading = match$1[1];
  var matchedIntegration = Belt_Array.keep(availableIntegrations, (function (availableIntegration) {
          return availableIntegration.description.type_ === integration.pluginId;
        }));
  var icon$1 = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(matchedIntegration, 0), (function (match_) {
                            return match_.description.icon;
                          })), "");
        }), [matchedIntegration]);
  return React.createElement(Dialog.default, {
              open: true,
              onClose: (function (param) {
                  return Curry._1(closeDialog, undefined);
                }),
              children: React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.position(/* relative */903134412),
                          /* [] */0
                        ])
                  }, match$1[0] ? React.createElement(SettingsWfConnectionManagement$Loading, {
                          size: 50,
                          className: loadingContainerSmall
                        }) : null, React.createElement(DialogTitle.default, {
                        children: I18N$BsConsole.show(undefined, "Transfer integration to another connection")
                      }), React.createElement(DialogContent.default, {
                        children: null
                      }, React.createElement(DialogContentText.default, {
                            className: description$1,
                            children: Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                                      /* String_literal */Block.__(11, [
                                          "Integration ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* String_literal */Block.__(11, [
                                                  " will be transferred to newly selected connection.",
                                                  /* End_of_format */0
                                                ])
                                            ])
                                        ]),
                                      "Integration %s will be transferred to newly selected connection."
                                    ]), integration.watcherName)
                          }), React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Body1 */8,
                            children: I18N$BsConsole.show(undefined, "Transfer integration to")
                          }), React.createElement(Select.default, {
                            value: newConnection,
                            disabled: false,
                            onChange: (function ($$event, param) {
                                var value = $$event.target.value;
                                return Curry._1(setNewConnection, (function (param) {
                                              return value;
                                            }));
                              }),
                            input: React.createElement(OutlinedInput.default, {
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.width(Css.pct(100)),
                                          /* [] */0
                                        ])
                                  },
                                  labelWidth: 0
                                }),
                            classes: {
                              select: inputItem
                            },
                            autoWidth: true,
                            children: Belt_Array.map(Belt_Array.keep(connections, (function (connection) {
                                        var match = integration.connection;
                                        if (match !== undefined && connection.id !== match.connectionId) {
                                          return connection.pluginId === integration.pluginId;
                                        } else {
                                          return false;
                                        }
                                      })), (function (connection) {
                                    return React.createElement(MenuItem.default, {
                                                value: connection.id,
                                                children: null,
                                                key: String(connection.id)
                                              }, React.createElement("img", {
                                                    className: icon,
                                                    src: icon$1
                                                  }), I18N$BsConsole.showSkip(connection.name));
                                  }))
                          })), React.createElement(DialogActions.default, {
                        className: container$5,
                        children: null
                      }, React.createElement(Button.default, {
                            color: "primary",
                            onClick: (function (param) {
                                return Curry._1(closeDialog, undefined);
                              }),
                            children: I18N$BsConsole.show(undefined, "Cancel")
                          }), React.createElement(Button.default, {
                            variant: "contained",
                            color: "primary",
                            disabled: Belt_Option.isNone(newConnection),
                            onClick: (function (param) {
                                Curry._1(setLoading, (function (param) {
                                        return true;
                                      }));
                                if (newConnection === undefined) {
                                  return ;
                                }
                                var maybeTask = ApiWfIntegrations$BsConsole.TransferIntegration.transferIntegrationTask(config, integration.id, newConnection);
                                if (maybeTask !== undefined) {
                                  return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                                return ApiWf$BsConsole.responseCb((function (param) {
                                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Transfered */Block.__(15, [integration.pluginId])]));
                                                              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Integration was transfered successfully."));
                                                              return Curry._1(closeDialogAndRefetch, undefined);
                                                            }), (function (err) {
                                                              Curry._1(closeDialog, undefined);
                                                              return Curry._1(handleSetError, {
                                                                          errorMessage: "Could not transfer integration: " + err,
                                                                          errorElement: undefined
                                                                        });
                                                            }), param);
                                              }));
                                } else {
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                }
                              }),
                            children: I18N$BsConsole.show(undefined, "Transfer")
                          })))
            });
}

var SelectConnectionToTransferIntegration = {
  make: SettingsWfConnectionManagement$SelectConnectionToTransferIntegration
};

function SettingsWfConnectionManagement$SelectConnectionToTransferIntegrations(Props) {
  var connection = Props.connection;
  var config = Props.config;
  var token = Props.token;
  var integrations = Props.integrations;
  var connections = Props.connections;
  var availableIntegrations = Props.availableIntegrations;
  var handleSetError = Props.handleSetError;
  var closeDialog = Props.closeDialog;
  var closeDialogAndRefetch = Props.closeDialogAndRefetch;
  var match = React.useState((function () {
          
        }));
  var setNewConnection = match[1];
  var newConnection = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setLoading = match$1[1];
  var matchedConnection = Belt_Array.keep(availableIntegrations, (function (availableIntegration) {
          return availableIntegration.description.type_ === connection.pluginId;
        }));
  var icon$1 = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(matchedConnection, 0), (function (match_) {
                            return match_.description.icon;
                          })), "");
        }), [matchedConnection]);
  return React.createElement(Dialog.default, {
              open: true,
              onClose: (function (param) {
                  return Curry._1(closeDialog, undefined);
                }),
              children: React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.position(/* relative */903134412),
                          /* [] */0
                        ])
                  }, match$1[0] ? React.createElement(SettingsWfConnectionManagement$Loading, {
                          size: 50,
                          className: loadingContainerSmall
                        }) : null, React.createElement(DialogTitle.default, {
                        children: I18N$BsConsole.show(undefined, "Transfer integrations ownership to another connection")
                      }), React.createElement(DialogContent.default, {
                        children: null
                      }, React.createElement(DialogContentText.default, {
                            className: description$1,
                            children: Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                                      /* String_literal */Block.__(11, [
                                          "All integrations from connection \"",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* String_literal */Block.__(11, [
                                                  "\" and their ownership will be transferred to newly selected connection.",
                                                  /* End_of_format */0
                                                ])
                                            ])
                                        ]),
                                      "All integrations from connection \"%s\" and their ownership will be transferred to newly selected connection."
                                    ]), connection.name)
                          }), React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Body1 */8,
                            children: I18N$BsConsole.show(undefined, "Transfer integrations to")
                          }), React.createElement(Select.default, {
                            value: newConnection,
                            disabled: false,
                            onChange: (function ($$event, param) {
                                var value = $$event.target.value;
                                return Curry._1(setNewConnection, (function (param) {
                                              return value;
                                            }));
                              }),
                            input: React.createElement(OutlinedInput.default, {
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.width(Css.pct(100)),
                                          /* [] */0
                                        ])
                                  },
                                  labelWidth: 0
                                }),
                            classes: {
                              select: inputItem
                            },
                            autoWidth: true,
                            children: Belt_Array.map(Belt_Array.keep(connections, (function (conn) {
                                        if (conn.id !== connection.id) {
                                          return conn.pluginId === connection.pluginId;
                                        } else {
                                          return false;
                                        }
                                      })), (function (connection) {
                                    return React.createElement(MenuItem.default, {
                                                value: connection.id,
                                                children: null,
                                                key: String(connection.id)
                                              }, React.createElement("img", {
                                                    className: icon,
                                                    src: icon$1
                                                  }), I18N$BsConsole.showSkip(connection.name));
                                  }))
                          })), React.createElement(DialogActions.default, {
                        className: container$5,
                        children: null
                      }, React.createElement(Button.default, {
                            color: "primary",
                            onClick: (function (param) {
                                return Curry._1(closeDialog, undefined);
                              }),
                            children: I18N$BsConsole.show(undefined, "Cancel")
                          }), React.createElement(Button.default, {
                            variant: "contained",
                            color: "primary",
                            disabled: Belt_Option.isNone(newConnection),
                            onClick: (function (param) {
                                Curry._1(setLoading, (function (param) {
                                        return true;
                                      }));
                                if (newConnection === undefined) {
                                  return ;
                                }
                                var maybeTask = ApiWfIntegrations$BsConsole.BulkTransferIntegrations.bulkTransferIntegrationsTask(config, Belt_Array.map(Belt_Array.keep(integrations, (function (integration) {
                                                var match = integration.connection;
                                                if (match !== undefined) {
                                                  return match.connectionId === connection.id;
                                                } else {
                                                  return false;
                                                }
                                              })), (function (integration) {
                                            return integration.id;
                                          })), newConnection);
                                if (maybeTask !== undefined) {
                                  return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                                return ApiWf$BsConsole.responseCb((function (response) {
                                                              var failedArray = response.body.failed;
                                                              if (failedArray.length !== 0) {
                                                                var el = React.createElement("div", {
                                                                      className: errorDataDiv
                                                                    }, Belt_Array.mapWithIndex(failedArray, (function (index, failed) {
                                                                            return createErrorElement(index, failed, integrations);
                                                                          })));
                                                                Curry._1(closeDialog, undefined);
                                                                return Curry._1(handleSetError, {
                                                                            errorMessage: "The following integrations failed to transfer: ",
                                                                            errorElement: Caml_option.some(el)
                                                                          });
                                                              }
                                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Connection_Transfered */Block.__(9, [connection.pluginId])]));
                                                              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Connection integrations were transfered successfully."));
                                                              return Curry._1(closeDialogAndRefetch, undefined);
                                                            }), (function (err) {
                                                              Curry._1(closeDialog, undefined);
                                                              return Curry._1(handleSetError, {
                                                                          errorMessage: "Could not transfer integrations: " + err,
                                                                          errorElement: undefined
                                                                        });
                                                            }), param);
                                              }));
                                } else {
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                }
                              }),
                            children: I18N$BsConsole.show(undefined, "Transfer")
                          })))
            });
}

var SelectConnectionToTransferIntegrations = {
  make: SettingsWfConnectionManagement$SelectConnectionToTransferIntegrations
};

function SettingsWfConnectionManagement$DeleteConnection(Props) {
  var connection = Props.connection;
  var integrations = Props.integrations;
  var linkedIntegrations = Props.linkedIntegrations;
  var config = Props.config;
  var token = Props.token;
  var closeDialogAndRefetch = Props.closeDialogAndRefetch;
  var closeDialog = Props.closeDialog;
  var handleSetError = Props.handleSetError;
  var match = React.useState((function () {
          return false;
        }));
  var setLoading = match[1];
  var loading = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setShowAssociatedIntegrationsWarning = match$1[1];
  React.useEffect((function () {
          if (linkedIntegrations.length !== 0) {
            Curry._1(setShowAssociatedIntegrationsWarning, (function (param) {
                    return true;
                  }));
          }
          
        }), ([]));
  if (match$1[0]) {
    return React.createElement(Dialog.default, {
                open: true,
                onClose: (function (param) {
                    return Curry._1(closeDialog, undefined);
                  }),
                children: React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.position(/* relative */903134412),
                            /* [] */0
                          ])
                    }, loading ? React.createElement(SettingsWfConnectionManagement$Loading, {
                            size: 50,
                            className: loadingContainerSmall
                          }) : null, React.createElement(DialogTitle.default, {
                          children: I18N$BsConsole.show(undefined, "Delete connection")
                        }), React.createElement(DialogContent.default, {
                          children: null
                        }, React.createElement(DialogContentText.default, {
                              className: description$1,
                              children: I18N$BsConsole.show(undefined, "The following integrations are associated with the connection and will also be deleted. This action is not recoverable. Cancel and use the transfer option if you want these integrations to be maintained.")
                            }), React.createElement(DialogContentText.default, {
                              className: Css.style(/* :: */[
                                    Css.margin(Css.px(20)),
                                    /* [] */0
                                  ]),
                              children: null
                            }, React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Body1 */8,
                                  children: I18N$BsConsole.show(undefined, "Associated integrations:")
                                }), linkedIntegrations.length !== 0 ? Belt_Array.map(linkedIntegrations, (function (integration) {
                                      return React.createElement("li", undefined, I18N$BsConsole.showSkip(integration.watcherName));
                                    })) : React.createElement("li", undefined, I18N$BsConsole.show(undefined, "No integrations are associated with this connection")))), React.createElement(DialogActions.default, {
                          className: container$5,
                          children: null
                        }, React.createElement(Button.default, {
                              color: "primary",
                              onClick: (function (param) {
                                  return Curry._1(closeDialog, undefined);
                                }),
                              children: I18N$BsConsole.show(undefined, "Cancel")
                            }), React.createElement(Button.default, {
                              variant: "contained",
                              className: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.white)),
                                    /* :: */[
                                      Css.backgroundColor(Css.hex(Colors$BsConsole.redDark)),
                                      /* :: */[
                                        Css.hover(/* :: */[
                                              Css.color(Css.hex(Colors$BsConsole.redDark)),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ]),
                              disabled: false,
                              onClick: (function (param) {
                                  return Curry._1(setShowAssociatedIntegrationsWarning, (function (param) {
                                                return false;
                                              }));
                                }),
                              children: I18N$BsConsole.show(undefined, "Proceed")
                            })))
              });
  } else {
    return React.createElement(Dialog.default, {
                open: true,
                onClose: (function (param) {
                    return Curry._1(closeDialog, undefined);
                  }),
                children: React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.position(/* relative */903134412),
                            /* [] */0
                          ])
                    }, loading ? React.createElement(SettingsWfConnectionManagement$Loading, {
                            size: 50,
                            className: loadingContainerSmall
                          }) : null, React.createElement(DialogTitle.default, {
                          children: I18N$BsConsole.show(undefined, "Delete connection")
                        }), React.createElement(DialogContent.default, {
                          children: null
                        }, React.createElement(DialogContentText.default, {
                              className: description$1,
                              children: Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                                        /* String_literal */Block.__(11, [
                                            "This action will delete \"",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* String_literal */Block.__(11, [
                                                    "\" connection. All associated integrations will be lost.",
                                                    /* End_of_format */0
                                                  ])
                                              ])
                                          ]),
                                        "This action will delete \"%s\" connection. All associated integrations will be lost."
                                      ]), connection.name)
                            }), React.createElement(DialogContentText.default, {
                              className: Css.style(/* :: */[
                                    Css.margin(Css.px(20)),
                                    /* [] */0
                                  ]),
                              children: null
                            }, React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Body1 */8,
                                  children: I18N$BsConsole.show(undefined, "Associated integrations:")
                                }), linkedIntegrations.length !== 0 ? Belt_Array.map(linkedIntegrations, (function (integration) {
                                      return React.createElement("li", undefined, I18N$BsConsole.showSkip(integration.watcherName));
                                    })) : React.createElement("li", undefined, I18N$BsConsole.show(undefined, "No integrations are associated with this connection")))), React.createElement(DialogActions.default, {
                          className: container$5,
                          children: null
                        }, React.createElement(Button.default, {
                              color: "primary",
                              onClick: (function (param) {
                                  return Curry._1(closeDialog, undefined);
                                }),
                              children: I18N$BsConsole.show(undefined, "Cancel")
                            }), React.createElement(Button.default, {
                              variant: "contained",
                              className: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.white)),
                                    /* :: */[
                                      Css.backgroundColor(Css.hex(Colors$BsConsole.redDark)),
                                      /* :: */[
                                        Css.hover(/* :: */[
                                              Css.color(Css.hex(Colors$BsConsole.redDark)),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ]),
                              disabled: false,
                              onClick: (function (param) {
                                  Curry._1(setLoading, (function (param) {
                                          return true;
                                        }));
                                  var deleteConnection = function (param) {
                                    var maybeTask = ApiWfConnections$BsConsole.DeleteConnection.deleteConnectionTask(config, connection.id);
                                    if (maybeTask !== undefined) {
                                      return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                                    return ApiWf$BsConsole.responseCb((function (param) {
                                                                  MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Connection_Deleted */Block.__(10, [connection.pluginId])]));
                                                                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Connection was deleted successfully."));
                                                                  return Curry._1(closeDialogAndRefetch, undefined);
                                                                }), (function (err) {
                                                                  Curry._1(closeDialog, undefined);
                                                                  return Curry._1(handleSetError, {
                                                                              errorMessage: "Could not delete connection: " + err,
                                                                              errorElement: undefined
                                                                            });
                                                                }), param);
                                                  }));
                                    } else {
                                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                    }
                                  };
                                  if (linkedIntegrations.length !== 0) {
                                    var maybeTask = ApiWfIntegrations$BsConsole.BulkDeleteIntegrations.bulkDeleteIntegrationsTask(config, Belt_Array.map(linkedIntegrations, (function (integration) {
                                                return integration.id;
                                              })));
                                    if (maybeTask !== undefined) {
                                      return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                                    return ApiWf$BsConsole.responseCb((function (response) {
                                                                  var failedArray = response.body.failed;
                                                                  if (failedArray.length !== 0) {
                                                                    var el = React.createElement("div", {
                                                                          className: errorDataDiv
                                                                        }, Belt_Array.mapWithIndex(failedArray, (function (index, failed) {
                                                                                return createErrorElement(index, failed, integrations);
                                                                              })));
                                                                    Curry._1(closeDialog, undefined);
                                                                    return Curry._1(handleSetError, {
                                                                                errorMessage: "Could not delete connection. The following associated integrations failed to delete:",
                                                                                errorElement: Caml_option.some(el)
                                                                              });
                                                                  }
                                                                  MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Deleted */Block.__(14, [connection.pluginId])]));
                                                                  return deleteConnection(undefined);
                                                                }), (function (err) {
                                                                  Curry._1(closeDialog, undefined);
                                                                  return Curry._1(handleSetError, {
                                                                              errorMessage: "Could not delete associated integrations: " + err,
                                                                              errorElement: undefined
                                                                            });
                                                                }), param);
                                                  }));
                                    } else {
                                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                    }
                                  } else {
                                    return deleteConnection(undefined);
                                  }
                                }),
                              children: I18N$BsConsole.show(undefined, "Delete")
                            })))
              });
  }
}

var DeleteConnection = {
  make: SettingsWfConnectionManagement$DeleteConnection
};

function SettingsWfConnectionManagement$DeleteConfirmation(Props) {
  var integration = Props.integration;
  var token = Props.token;
  var handleSetError = Props.handleSetError;
  var closeDialog = Props.closeDialog;
  var closeDialogAndRefetch = Props.closeDialogAndRefetch;
  var config = Props.config;
  return React.createElement(Dialog.default, {
              open: true,
              onClose: (function (param) {
                  return Curry._1(closeDialog, undefined);
                }),
              children: null
            }, React.createElement(DialogTitle.default, {
                  children: I18N$BsConsole.show(undefined, "Are you sure?")
                }), React.createElement(DialogContent.default, {
                  children: React.createElement(DialogContentText.default, {
                        children: Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                                  /* String_literal */Block.__(11, [
                                      "Are you sure you want to delete the integration ",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* Char_literal */Block.__(12, [
                                              /* "?" */63,
                                              /* End_of_format */0
                                            ])
                                        ])
                                    ]),
                                  "Are you sure you want to delete the integration %s?"
                                ]), integration.watcherName)
                      })
                }), React.createElement(DialogActions.default, {
                  children: null
                }, React.createElement(Button.default, {
                      color: "primary",
                      onClick: (function (param) {
                          return Curry._1(closeDialog, undefined);
                        }),
                      children: I18N$BsConsole.show(undefined, "No")
                    }), React.createElement(Button.default, {
                      color: "primary",
                      onClick: (function (param) {
                          var maybeTask = WatcherNewTask$BsConsole.$$delete(integration.project, integration.pluginId, integration.id, config);
                          if (maybeTask !== undefined) {
                            return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                          return ApiWf$BsConsole.responseCb((function (param) {
                                                        var match = integration.pluginId;
                                                        switch (match) {
                                                          case "jira" :
                                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Deleted */Block.__(14, ["jiraCloud"])]));
                                                              break;
                                                          case "jira-server" :
                                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Deleted */Block.__(14, ["jiraServer"])]));
                                                              break;
                                                          default:
                                                            
                                                        }
                                                        SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Integration was deleted successfully."));
                                                        return Curry._1(closeDialogAndRefetch, undefined);
                                                      }), (function (err) {
                                                        Curry._1(closeDialog, undefined);
                                                        return Curry._1(handleSetError, {
                                                                    errorMessage: "Could not delete integration: " + err,
                                                                    errorElement: undefined
                                                                  });
                                                      }), param);
                                        }));
                          } else {
                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                          }
                        }),
                      children: I18N$BsConsole.show(undefined, "Yes")
                    })));
}

var DeleteConfirmation = {
  make: SettingsWfConnectionManagement$DeleteConfirmation
};

function SettingsWfConnectionManagement$Header(Props) {
  return React.createElement("div", {
              className: container$1
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading3 */2,
                  className: title,
                  color: Colors$BsConsole.grey1,
                  children: I18N$BsConsole.show(undefined, "Workflow connections")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: description,
                  color: Colors$BsConsole.grey4,
                  children: I18N$BsConsole.show(undefined, "Manage third-party workflow integrations configured across different projects within your organization.")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: description,
                  color: Colors$BsConsole.grey4,
                  children: I18N$BsConsole.show(undefined, "Workflow connections are first created within Project settings. Navigate to Project settings > Integrations to create a new connection.")
                }));
}

var Header$1 = {
  make: SettingsWfConnectionManagement$Header
};

function SettingsWfConnectionManagement$ConnectionHeaderIcons(Props) {
  var type_ = Props.type_;
  var connection = Props.connection;
  var linkedConnections = Props.linkedConnections;
  var linkedIntegrations = Props.linkedIntegrations;
  var config = Props.config;
  var handleDeleteConnection = Props.handleDeleteConnection;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleTransferIntegrations = Props.handleTransferIntegrations;
  var hanldeShouldUpdateName = Props.hanldeShouldUpdateName;
  var match = linkedIntegrations.length === 0;
  var match$1 = linkedConnections.length === 0;
  var match$2 = connection.pluginId;
  var tmp;
  switch (match$2) {
    case "jira-server" :
    case "slack" :
        tmp = false;
        break;
    default:
      tmp = true;
  }
  var match$3 = connection.pluginId;
  var tmp$1;
  switch (match$3) {
    case "jira-server" :
    case "slack" :
        tmp$1 = I18N$BsConsole.get(undefined, "Edit connection");
        break;
    default:
      tmp$1 = I18N$BsConsole.get(undefined, "Edit is currently unavailable");
  }
  return React.createElement("div", {
              className: buttonsContainer
            }, React.createElement(Tooltip.default, {
                  placement: "bottom",
                  children: React.createElement("span", undefined, React.createElement(Button.default, {
                            className: button,
                            disabled: linkedIntegrations.length === 0 || linkedConnections.length === 0,
                            onClick: (function (param) {
                                return Curry._1(handleTransferIntegrations, connection);
                              }),
                            children: null
                          }, React.createElement(Repeat.default, {
                                className: buttonIcon,
                                color: "inherit"
                              }), React.createElement("label", {
                                className: buttonLabel
                              }, I18N$BsConsole.show(undefined, "Transfer")))),
                  title: match ? I18N$BsConsole.get(undefined, "There are no associated integrations to transfer") : (
                      match$1 ? I18N$BsConsole.get(undefined, "There are no connections of the same type to tranfer to") : I18N$BsConsole.get(undefined, "Transfer all associated integrations")
                    )
                }), React.createElement(Tooltip.default, {
                  placement: "bottom",
                  children: React.createElement("span", undefined, React.createElement(Button.default, {
                            className: button,
                            disabled: tmp,
                            onClick: (function (param) {
                                switch (type_) {
                                  case "jira-server" :
                                      var projectFromSessionStorage = window.sessionStorage.getItem("_Backtrace_last_project");
                                      var lastProjectEphemeral = Belt_Option.flatMap(config.lastProjectPid, (function (projectPid) {
                                              return Belt_List.getBy(config.projects, (function (project) {
                                                            return project.pid === projectPid;
                                                          }));
                                            }));
                                      var firstProjectInProjectsList = Belt_List.get(config.projects, 0);
                                      var projectName;
                                      if (projectFromSessionStorage == null) {
                                        if (lastProjectEphemeral !== undefined) {
                                          projectName = lastProjectEphemeral.name;
                                        } else if (firstProjectInProjectsList !== undefined) {
                                          projectName = firstProjectInProjectsList.name;
                                        } else {
                                          throw [
                                                Caml_builtin_exceptions.assert_failure,
                                                /* tuple */[
                                                  "SettingsWfConnectionManagement.re",
                                                  797,
                                                  17
                                                ]
                                              ];
                                        }
                                      } else {
                                        projectName = projectFromSessionStorage;
                                      }
                                      return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                                    projectName,
                                                    /* JiraServer */Block.__(0, [/* Edit */Block.__(0, [
                                                            connection.id,
                                                            undefined
                                                          ])]),
                                                    undefined
                                                  ]));
                                  case "slack" :
                                      return Curry._1(hanldeShouldUpdateName, undefined);
                                  default:
                                    return ;
                                }
                              }),
                            children: null
                          }, React.createElement(Edit.default, {
                                className: buttonIcon,
                                color: "inherit"
                              }), React.createElement("label", {
                                className: buttonLabel
                              }, I18N$BsConsole.show(undefined, "Edit")))),
                  title: tmp$1
                }), React.createElement(Tooltip.default, {
                  placement: "bottom",
                  children: React.createElement(Button.default, {
                        className: Css.merge(/* :: */[
                              button,
                              /* :: */[
                                buttonRed,
                                /* [] */0
                              ]
                            ]),
                        onClick: (function (param) {
                            return Curry._1(handleDeleteConnection, connection);
                          }),
                        children: null
                      }, React.createElement(Delete.default, {
                            className: buttonIcon,
                            color: "inherit"
                          }), React.createElement("label", {
                            className: buttonLabel
                          }, I18N$BsConsole.show(undefined, "Delete"))),
                  title: I18N$BsConsole.get(undefined, "Delete connection")
                }));
}

var ConnectionHeaderIcons = {
  make: SettingsWfConnectionManagement$ConnectionHeaderIcons
};

function SettingsWfConnectionManagement$ConnectionHeader(Props) {
  var config = Props.config;
  var connection = Props.connection;
  var linkedConnections = Props.linkedConnections;
  var linkedIntegrations = Props.linkedIntegrations;
  var availableIntegrations = Props.availableIntegrations;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleDeleteConnection = Props.handleDeleteConnection;
  var handleTransferIntegrations = Props.handleTransferIntegrations;
  var handleConnectionNameChange = Props.handleConnectionNameChange;
  var match = React.useState((function () {
          return false;
        }));
  var setShouldUpdateName = match[1];
  var match$1 = React.useState((function () {
          return connection.name;
        }));
  var setEditedName = match$1[1];
  var editedName = match$1[0];
  var matchedIntegration = Belt_Array.keep(availableIntegrations, (function (availableIntegration) {
          return availableIntegration.description.type_ === connection.pluginId;
        }));
  var icon$1 = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.get(matchedIntegration, 0), (function (match_) {
                            return match_.description.icon;
                          })), "");
        }), [matchedIntegration]);
  return React.createElement("div", {
              className: container$4
            }, React.createElement("img", {
                  className: icon,
                  src: icon$1
                }), match[0] ? React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.flexGrow(2),
                            /* [] */0
                          ]
                        ])
                  }, React.createElement(TextField.default, {
                        autoFocus: true,
                        placeholder: "Connection name",
                        value: editedName,
                        color: "primary",
                        variant: "outlined",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.selector("input", /* :: */[
                                      Css.important(Css.padding(Css.px(10))),
                                      /* [] */0
                                    ]),
                                /* :: */[
                                  Css.width(Css.pct(100)),
                                  /* [] */0
                                ]
                              ])
                        },
                        onChange: (function ($$event) {
                            var newConnectionName = Belt_Option.getWithDefault($$event.target.value, "");
                            return Curry._1(setEditedName, (function (param) {
                                          return newConnectionName;
                                        }));
                          })
                      }), React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        className: Css.style(/* :: */[
                              Css.marginLeft(Css.px(10)),
                              /* [] */0
                            ]),
                        disabled: false,
                        onClick: (function (param) {
                            var newConnectionName$prime = editedName.trim();
                            if (newConnectionName$prime !== connection.name) {
                              return Curry._3(handleConnectionNameChange, newConnectionName$prime, connection.pluginId, connection.id);
                            } else {
                              return Curry._1(setShouldUpdateName, (function (param) {
                                            return false;
                                          }));
                            }
                          }),
                        children: I18N$BsConsole.show(undefined, "Save")
                      })) : React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading4 */3,
                    className: title$1,
                    color: Colors$BsConsole.grey1,
                    children: I18N$BsConsole.show(undefined, connection.name)
                  }), React.createElement(SettingsWfConnectionManagement$ConnectionHeaderIcons, {
                  type_: connection.pluginId,
                  connection: connection,
                  linkedConnections: linkedConnections,
                  linkedIntegrations: linkedIntegrations,
                  config: config,
                  handleDeleteConnection: handleDeleteConnection,
                  handleChangeUrl: handleChangeUrl,
                  handleTransferIntegrations: handleTransferIntegrations,
                  hanldeShouldUpdateName: (function (param) {
                      return Curry._1(setShouldUpdateName, (function (param) {
                                    return true;
                                  }));
                    })
                }));
}

var ConnectionHeader$1 = {
  make: SettingsWfConnectionManagement$ConnectionHeader
};

function SettingsWfConnectionManagement$IntegrationTableRow(Props) {
  var users = Props.users;
  var integration = Props.integration;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleDeleteConfirmation = Props.handleDeleteConfirmation;
  var handleTransferIntegration = Props.handleTransferIntegration;
  var linkedConnections = Props.linkedConnections;
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var match$1 = integration.metadata;
  var creationDate = match$1 !== undefined ? Caml_option.some(new Date(match$1.creationDate)) : undefined;
  var user$prime = Belt_Array.get(Belt_Array.keep(users, (function (user) {
              return user.uid === integration.creatorUserId;
            })), 0);
  var user = user$prime !== undefined ? user$prime.username : "N/A";
  return React.createElement(React.Fragment, undefined, React.createElement(TableRow.default, {
                  children: null
                }, React.createElement(TableCell.default, {
                      classes: cell,
                      children: I18N$BsConsole.show(undefined, integration.project)
                    }), React.createElement(TableCell.default, {
                      classes: cell,
                      children: I18N$BsConsole.show(undefined, integration.watcherName)
                    }), React.createElement(TableCell.default, {
                      classes: cell,
                      children: creationDate !== undefined ? Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* End_of_format */0
                                    ]),
                                  "%s"
                                ]), L10N_date_time_calendar$BsConsole.Format.textAbbreviatedDay(undefined, Caml_option.valFromOption(creationDate))) : I18N$BsConsole.show(undefined, "N/A")
                    }), React.createElement(TableCell.default, {
                      classes: cell,
                      children: I18N$BsConsole.show(undefined, user)
                    }), React.createElement(TableCell.default, {
                      classes: cell,
                      children: integration.state === "enabled" ? React.createElement("div", {
                              className: Css.merge(/* :: */[
                                    statusIconContainer,
                                    /* :: */[
                                      green,
                                      /* [] */0
                                    ]
                                  ])
                            }, React.createElement(CheckCircle.default, {
                                  className: statusIcon
                                }), I18N$BsConsole.show(undefined, "Active")) : React.createElement("div", {
                              className: Css.merge(/* :: */[
                                    statusIconContainer,
                                    /* :: */[
                                      red,
                                      /* [] */0
                                    ]
                                  ])
                            }, React.createElement(Cancel.default, {
                                  className: statusIcon
                                }), I18N$BsConsole.show(undefined, "Disabled"))
                    }), React.createElement(TableCell.default, {
                      className: menuCell,
                      classes: cell,
                      children: null
                    }, React.createElement(IconButton.default, {
                          onClick: (function ($$event) {
                              var el = $$event.currentTarget;
                              if (!(el == null)) {
                                return Curry._1(setAnchorEl, (function (param) {
                                              return Caml_option.some(el);
                                            }));
                              }
                              
                            }),
                          children: React.createElement(MoreVert.default, { })
                        }), anchorEl !== undefined ? React.createElement(Menu.default, {
                            anchorEl: Caml_option.valFromOption(anchorEl),
                            open: true,
                            onClose: (function (param) {
                                return Curry._1(setAnchorEl, (function (param) {
                                              
                                            }));
                              }),
                            children: null
                          }, React.createElement(Tooltip.default, {
                                placement: "bottom",
                                children: React.createElement("span", undefined, React.createElement(MenuItem.default, {
                                          disabled: linkedConnections.length === 0,
                                          onClick: (function (param) {
                                              Curry._1(setAnchorEl, (function (param) {
                                                      
                                                    }));
                                              return Curry._1(handleTransferIntegration, integration);
                                            }),
                                          children: I18N$BsConsole.show(undefined, "Transfer integration to another connection")
                                        })),
                                title: linkedConnections.length === 0 ? "There are no connections of the same type to tranfer to" : ""
                              }), React.createElement(MenuItem.default, {
                                onClick: (function (param) {
                                    var match = integration.pluginId;
                                    switch (match) {
                                      case "jira" :
                                      case "jira-server" :
                                      case "slack" :
                                          break;
                                      default:
                                        return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                                      integration.project,
                                                      /* WatcherEdit */Block.__(2, [
                                                          integration.watcherName,
                                                          /* ConfigureConnection */1
                                                        ]),
                                                      undefined
                                                    ]));
                                    }
                                    var param_pluginId = integration.pluginId;
                                    var param_integrationId = integration.id;
                                    var param_referrer = /* ConnectionManager */0;
                                    var param$1 = {
                                      pluginId: param_pluginId,
                                      connectionId: undefined,
                                      connectionCreated: undefined,
                                      integrationId: param_integrationId,
                                      referrer: param_referrer,
                                      error: undefined
                                    };
                                    return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                                  integration.project,
                                                  WfRouter$BsConsole.root,
                                                  param$1
                                                ]));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Integration settings")
                              }), React.createElement(MenuItem.default, {
                                onClick: (function (param) {
                                    Curry._1(setAnchorEl, (function (param) {
                                            
                                          }));
                                    return Curry._1(handleDeleteConfirmation, integration);
                                  }),
                                children: I18N$BsConsole.show(undefined, "Delete integration")
                              })) : null)));
}

var IntegrationTableRow = {
  make: SettingsWfConnectionManagement$IntegrationTableRow
};

function SettingsWfConnectionManagement$ConnectionTable(Props) {
  var users = Props.users;
  var integrations = Props.integrations;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleDeleteConfirmation = Props.handleDeleteConfirmation;
  var handleTransferIntegration = Props.handleTransferIntegration;
  var linkedConnections = Props.linkedConnections;
  return React.createElement("div", {
              className: container$2
            }, integrations.length !== 0 ? React.createElement(Table.default, {
                    children: null
                  }, React.createElement(TableHead.default, {
                        children: React.createElement(TableRow.default, {
                              children: null
                            }, React.createElement(TableCell.default, {
                                  className: largeCell,
                                  classes: cellHeader,
                                  children: I18N$BsConsole.show(undefined, "PROJECT")
                                }), React.createElement(TableCell.default, {
                                  className: largeCell,
                                  classes: cellHeader,
                                  children: I18N$BsConsole.show(undefined, "INTEGRATION NAME")
                                }), React.createElement(TableCell.default, {
                                  className: smallCell,
                                  classes: cellHeader,
                                  children: I18N$BsConsole.show(undefined, "CREATED")
                                }), React.createElement(TableCell.default, {
                                  className: smallCell,
                                  classes: cellHeader,
                                  children: I18N$BsConsole.show(undefined, "OWNER")
                                }), React.createElement(TableCell.default, {
                                  className: smallCell,
                                  classes: cellHeader,
                                  children: I18N$BsConsole.show(undefined, "STATUS")
                                }), React.createElement(TableCell.default, {
                                  className: Css.style(/* :: */[
                                        Css.width(Css.px(50)),
                                        /* [] */0
                                      ]),
                                  classes: cellHeader
                                }))
                      }), React.createElement(TableBody.default, {
                        children: Belt_Array.mapWithIndex(integrations, (function (index, integration) {
                                return React.createElement(SettingsWfConnectionManagement$IntegrationTableRow, {
                                            users: users,
                                            integration: integration,
                                            handleChangeUrl: handleChangeUrl,
                                            handleDeleteConfirmation: handleDeleteConfirmation,
                                            handleTransferIntegration: handleTransferIntegration,
                                            linkedConnections: linkedConnections,
                                            key: "integrationRow-" + String(index)
                                          });
                              }))
                      })) : React.createElement("div", {
                    className: empty
                  }, I18N$BsConsole.show(undefined, "No integrations have been configured")));
}

var ConnectionTable = {
  make: SettingsWfConnectionManagement$ConnectionTable
};

function SettingsWfConnectionManagement$ConnectionRows(Props) {
  var connections = Props.connections;
  var integrations = Props.integrations;
  var availableIntegrations = Props.availableIntegrations;
  var users = Props.users;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleDeleteConfirmation = Props.handleDeleteConfirmation;
  var handleTransferIntegration = Props.handleTransferIntegration;
  var handleDeleteConnection = Props.handleDeleteConnection;
  var handleTransferIntegrations = Props.handleTransferIntegrations;
  var handleConnectionNameChange = Props.handleConnectionNameChange;
  var config = Props.config;
  return React.createElement("div", undefined, Belt_Array.mapWithIndex(connections, (function (index, connection) {
                    return React.createElement("div", {
                                key: "connection-row" + String(index),
                                className: container$3
                              }, React.createElement(SettingsWfConnectionManagement$ConnectionHeader, {
                                    config: config,
                                    connection: connection,
                                    linkedConnections: Belt_Array.keep(connections, (function (conn) {
                                            if (conn.id !== connection.id) {
                                              return conn.pluginId === connection.pluginId;
                                            } else {
                                              return false;
                                            }
                                          })),
                                    linkedIntegrations: Belt_Array.keep(integrations, (function (integration) {
                                            var match = integration.connection;
                                            if (match !== undefined) {
                                              return match.connectionId === connection.id;
                                            } else {
                                              return false;
                                            }
                                          })),
                                    availableIntegrations: availableIntegrations,
                                    handleChangeUrl: handleChangeUrl,
                                    handleDeleteConnection: handleDeleteConnection,
                                    handleTransferIntegrations: handleTransferIntegrations,
                                    handleConnectionNameChange: handleConnectionNameChange
                                  }), React.createElement(SettingsWfConnectionManagement$ConnectionTable, {
                                    users: users,
                                    integrations: Belt_Array.keep(integrations, (function (integration) {
                                            var match = integration.connection;
                                            if (match !== undefined) {
                                              return match.connectionId === connection.id;
                                            } else {
                                              return false;
                                            }
                                          })),
                                    handleChangeUrl: handleChangeUrl,
                                    handleDeleteConfirmation: handleDeleteConfirmation,
                                    handleTransferIntegration: handleTransferIntegration,
                                    linkedConnections: Belt_Array.keep(connections, (function (conn) {
                                            if (conn.id !== connection.id) {
                                              return conn.pluginId === connection.pluginId;
                                            } else {
                                              return false;
                                            }
                                          }))
                                  }));
                  })));
}

var ConnectionRows = {
  make: SettingsWfConnectionManagement$ConnectionRows
};

var initialState = {
  connectionsRemote: /* NotAsked */0,
  integrationsRemote: /* NotAsked */0,
  availableIntegrationsRemote: /* NotAsked */0,
  dialog: /* ClosedDialog */0,
  errorData: {
    errorMessage: undefined,
    errorElement: undefined
  }
};

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === /* DialogResetAndRefetchIntegrations */0) {
      return {
              connectionsRemote: state.connectionsRemote,
              integrationsRemote: /* Loading */1,
              availableIntegrationsRemote: state.availableIntegrationsRemote,
              dialog: /* ClosedDialog */0,
              errorData: state.errorData
            };
    } else {
      return {
              connectionsRemote: /* Loading */1,
              integrationsRemote: /* Loading */1,
              availableIntegrationsRemote: state.availableIntegrationsRemote,
              dialog: /* ClosedDialog */0,
              errorData: state.errorData
            };
    }
  }
  switch (action.tag | 0) {
    case /* SetConnectionRemote */0 :
        return {
                connectionsRemote: action[0],
                integrationsRemote: state.integrationsRemote,
                availableIntegrationsRemote: state.availableIntegrationsRemote,
                dialog: state.dialog,
                errorData: state.errorData
              };
    case /* SetIntegrationsRemote */1 :
        return {
                connectionsRemote: state.connectionsRemote,
                integrationsRemote: action[0],
                availableIntegrationsRemote: state.availableIntegrationsRemote,
                dialog: state.dialog,
                errorData: state.errorData
              };
    case /* SetAvailableIntegrations */2 :
        return {
                connectionsRemote: state.connectionsRemote,
                integrationsRemote: state.integrationsRemote,
                availableIntegrationsRemote: action[0],
                dialog: state.dialog,
                errorData: state.errorData
              };
    case /* SetDialog */3 :
        return {
                connectionsRemote: state.connectionsRemote,
                integrationsRemote: state.integrationsRemote,
                availableIntegrationsRemote: state.availableIntegrationsRemote,
                dialog: action[0],
                errorData: state.errorData
              };
    case /* SetErrorMessage */4 :
        return {
                connectionsRemote: state.connectionsRemote,
                integrationsRemote: state.integrationsRemote,
                availableIntegrationsRemote: state.availableIntegrationsRemote,
                dialog: state.dialog,
                errorData: action[0]
              };
    
  }
}

function useConnectionData(token, config) {
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var integrationsRemote = match$1.integrationsRemote;
  var connectionsRemote = match$1.connectionsRemote;
  React.useEffect((function () {
          if (typeof connectionsRemote === "number") {
            var onSuccess = function (param) {
              return Curry._1(dispatch, /* SetConnectionRemote */Block.__(0, [/* Success */Block.__(0, [Belt_SortArray.stableSortBy(param.body, (function (c1, c2) {
                                        return $$String.compare(c1.pluginId.toLowerCase(), c2.pluginId.toLowerCase());
                                      }))])]));
            };
            var onFailure = function (err) {
              return Curry._1(dispatch, /* SetConnectionRemote */Block.__(0, [/* Failure */Block.__(1, ["Error fetching connections: " + err])]));
            };
            var maybeTask = ApiWfConnections$BsConsole.QueryConnections.queryConnectionsByUniverseTask(config);
            if (maybeTask !== undefined) {
              Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
            }
          }
          
        }), [connectionsRemote]);
  React.useEffect((function () {
          var onSuccess = function (availableIntegrations) {
            return Curry._1(dispatch, /* SetAvailableIntegrations */Block.__(2, [/* Success */Block.__(0, [availableIntegrations])]));
          };
          var onFailure = function (err) {
            return Curry._1(dispatch, /* SetAvailableIntegrations */Block.__(2, [/* Failure */Block.__(1, ["Error fetching available integrations: " + err])]));
          };
          var maybeTask = WfTasks$BsConsole.QueryWFIntegrations.queryWFIntegrationsTask(config);
          if (maybeTask !== undefined) {
            Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                    return WfTasks$BsConsole.QueryWFIntegrations.responseCb(onSuccess, onFailure, param);
                  }));
          } else {
            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
          }
          
        }), ([]));
  React.useEffect((function () {
          if (typeof integrationsRemote === "number") {
            var onSuccess = function (param) {
              return Curry._1(dispatch, /* SetIntegrationsRemote */Block.__(1, [/* Success */Block.__(0, [param.body])]));
            };
            var onFailure = function (err) {
              return Curry._1(dispatch, /* SetIntegrationsRemote */Block.__(1, [/* Failure */Block.__(1, ["Error fetching integrations: " + err])]));
            };
            var maybeTask = ApiWfIntegrations$BsConsole.QueryIntegrations.queryIntegrationsByUniverseTask(config);
            if (maybeTask !== undefined) {
              Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
            }
          }
          
        }), [integrationsRemote]);
  return /* tuple */[
          connectionsRemote,
          integrationsRemote,
          match$1.availableIntegrationsRemote,
          match$1.dialog,
          match$1.errorData,
          dispatch
        ];
}

function SettingsWfConnectionManagement(Props) {
  var token = Props.token;
  var config = Props.config;
  var handleChangeUrl = Props.handleChangeUrl;
  var errorOpt = Props.error;
  var error = errorOpt !== undefined ? Caml_option.valFromOption(errorOpt) : undefined;
  var match = useConnectionData(token, config);
  var dispatch = match[5];
  var errorData = match[4];
  var dialog = match[3];
  var availableIntegrationsRemote = match[2];
  var integrationsRemote = match[1];
  var connectionsRemote = match[0];
  React.useEffect((function () {
          window.scrollTo(0, 0);
          
        }), [errorData]);
  var allUsersRemote = Task2$BsConsole.use(undefined, BpgTask$BsConsole.Users.fetchAll, undefined);
  var users;
  if (typeof allUsersRemote === "number") {
    users = [];
  } else if (allUsersRemote.tag) {
    users = [];
  } else {
    var __x = Belt_List.fromArray(Util$BsConsole.filterBacktraceInternalUsers(allUsersRemote[0][0], config.universe.name, IdentityVariant$BsConsole.current === /* Unity */2));
    users = Belt_List.toArray(Belt_List.sort(__x, (function (u1, u2) {
                return $$String.compare(u1.username.toLowerCase(), u2.username.toLowerCase());
              })));
  }
  var handleSetError = function (errorData) {
    return Curry._1(dispatch, /* SetErrorMessage */Block.__(4, [errorData]));
  };
  var tmp;
  if (typeof dialog === "number") {
    tmp = null;
  } else {
    switch (dialog.tag | 0) {
      case /* DeleteConfirmation */0 :
          tmp = React.createElement(SettingsWfConnectionManagement$DeleteConfirmation, {
                integration: dialog[0],
                token: token,
                handleSetError: handleSetError,
                closeDialog: (function (param) {
                    return Curry._1(dispatch, /* SetDialog */Block.__(3, [/* ClosedDialog */0]));
                  }),
                closeDialogAndRefetch: (function (param) {
                    return Curry._1(dispatch, /* DialogResetAndRefetchIntegrations */0);
                  }),
                config: config
              });
          break;
      case /* TransferIntegration */1 :
          tmp = typeof connectionsRemote === "number" || connectionsRemote.tag || typeof availableIntegrationsRemote === "number" || availableIntegrationsRemote.tag ? null : React.createElement(SettingsWfConnectionManagement$SelectConnectionToTransferIntegration, {
                  integration: dialog[0],
                  config: config,
                  token: token,
                  connections: connectionsRemote[0],
                  availableIntegrations: availableIntegrationsRemote[0],
                  handleSetError: handleSetError,
                  closeDialog: (function (param) {
                      return Curry._1(dispatch, /* SetDialog */Block.__(3, [/* ClosedDialog */0]));
                    }),
                  closeDialogAndRefetch: (function (param) {
                      return Curry._1(dispatch, /* DialogResetAndRefetchIntegrations */0);
                    })
                });
          break;
      case /* TransferIntegrations */2 :
          tmp = typeof connectionsRemote === "number" || connectionsRemote.tag || typeof integrationsRemote === "number" || integrationsRemote.tag || typeof availableIntegrationsRemote === "number" || availableIntegrationsRemote.tag ? null : React.createElement(SettingsWfConnectionManagement$SelectConnectionToTransferIntegrations, {
                  connection: dialog[0],
                  config: config,
                  token: token,
                  integrations: integrationsRemote[0],
                  connections: connectionsRemote[0],
                  availableIntegrations: availableIntegrationsRemote[0],
                  handleSetError: handleSetError,
                  closeDialog: (function (param) {
                      return Curry._1(dispatch, /* SetDialog */Block.__(3, [/* ClosedDialog */0]));
                    }),
                  closeDialogAndRefetch: (function (param) {
                      return Curry._1(dispatch, /* DialogResetAndRefetchIntegrations */0);
                    })
                });
          break;
      case /* DeleteConnection */3 :
          tmp = typeof integrationsRemote === "number" || integrationsRemote.tag ? null : React.createElement(SettingsWfConnectionManagement$DeleteConnection, {
                  connection: dialog[0],
                  integrations: integrationsRemote[0],
                  linkedIntegrations: dialog[1],
                  config: config,
                  token: token,
                  closeDialogAndRefetch: (function (param) {
                      return Curry._1(dispatch, /* DialogResetAndRefetchConnections */1);
                    }),
                  closeDialog: (function (param) {
                      return Curry._1(dispatch, /* SetDialog */Block.__(3, [/* ClosedDialog */0]));
                    }),
                  handleSetError: handleSetError
                });
          break;
      
    }
  }
  var match$1 = errorData.errorMessage;
  var match$2 = errorData.errorElement;
  var tmp$1;
  var exit = 0;
  var err;
  var exit$1 = 0;
  var exit$2 = 0;
  if (typeof connectionsRemote === "number") {
    exit = 1;
  } else if (connectionsRemote.tag || typeof integrationsRemote === "number") {
    exit$2 = 4;
  } else if (integrationsRemote.tag) {
    exit$1 = 3;
  } else {
    var integrations = integrationsRemote[0];
    if (typeof availableIntegrationsRemote === "number") {
      exit$1 = 3;
    } else if (availableIntegrationsRemote.tag) {
      err = availableIntegrationsRemote[0];
      exit = 2;
    } else {
      tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(SettingsWfConnectionManagement$Header, { }), React.createElement(SettingsWfConnectionManagement$ConnectionRows, {
                connections: connectionsRemote[0],
                integrations: integrations,
                availableIntegrations: availableIntegrationsRemote[0],
                users: users,
                handleChangeUrl: handleChangeUrl,
                handleDeleteConfirmation: (function (integration) {
                    return Curry._1(dispatch, /* SetDialog */Block.__(3, [/* DeleteConfirmation */Block.__(0, [integration])]));
                  }),
                handleTransferIntegration: (function (integration) {
                    return Curry._1(dispatch, /* SetDialog */Block.__(3, [/* TransferIntegration */Block.__(1, [integration])]));
                  }),
                handleDeleteConnection: (function (connection) {
                    var linkedIntegrations = Belt_Array.keep(integrations, (function (integration) {
                            var match = integration.connection;
                            if (match !== undefined) {
                              return match.connectionId === connection.id;
                            } else {
                              return false;
                            }
                          }));
                    return Curry._1(dispatch, /* SetDialog */Block.__(3, [/* DeleteConnection */Block.__(3, [
                                      connection,
                                      linkedIntegrations
                                    ])]));
                  }),
                handleTransferIntegrations: (function (connection) {
                    return Curry._1(dispatch, /* SetDialog */Block.__(3, [/* TransferIntegrations */Block.__(2, [connection])]));
                  }),
                handleConnectionNameChange: (function (newConnectionName, pluginId, connectionId) {
                    var onSuccess = function (param) {
                      Curry._1(dispatch, /* SetConnectionRemote */Block.__(0, [/* Loading */1]));
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Connection name was updated successfully."));
                    };
                    var onFailure = function (err) {
                      return Curry._1(dispatch, /* SetConnectionRemote */Block.__(0, [/* Failure */Block.__(1, ["Error fetching connections: " + err])]));
                    };
                    var maybeTask = ApiWfConnections$BsConsole.EditConnection.editConnectionTask(config, pluginId, connectionId, newConnectionName);
                    if (maybeTask !== undefined) {
                      return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                    return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                                  }));
                    } else {
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                    }
                  }),
                config: config
              }));
    }
  }
  if (exit$2 === 4) {
    if (typeof integrationsRemote === "number") {
      exit = 1;
    } else {
      exit$1 = 3;
    }
  }
  if (exit$1 === 3) {
    if (typeof availableIntegrationsRemote === "number") {
      exit = 1;
    } else if (typeof connectionsRemote !== "number") {
      if (connectionsRemote.tag) {
        err = connectionsRemote[0];
        exit = 2;
      } else {
        err = integrationsRemote[0];
        exit = 2;
      }
    }
    
  }
  switch (exit) {
    case 1 :
        tmp$1 = React.createElement(SettingsWfConnectionManagement$Loading, {
              size: 100,
              className: loadingContainerLarge
            });
        break;
    case 2 :
        tmp$1 = React.createElement(WarningMessage$BsConsole.make, {
              errorMessage: I18N$BsConsole.show(undefined, err)
            });
        break;
    
  }
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: null
            }, Belt_Option.isSome(error) ? React.createElement(WarningMessage$BsConsole.make, {
                    errorMessage: I18N$BsConsole.show(undefined, Belt_Option.getWithDefault(error, "Unknown error"))
                  }) : null, tmp, match$1 !== undefined ? (
                match$2 !== undefined ? React.createElement(WarningMessage$BsConsole.make, {
                        errorMessage: I18N$BsConsole.show(undefined, match$1),
                        errorElement: Caml_option.valFromOption(match$2)
                      }) : React.createElement(WarningMessage$BsConsole.make, {
                        errorMessage: I18N$BsConsole.show(undefined, match$1)
                      })
              ) : null, React.createElement(Paper.default, {
                  classes: {
                    root: container
                  },
                  children: tmp$1
                }));
}

var component = ReasonReact.statelessComponent("SlackConnection");

function make(token, config, handleChangeUrl, errorOpt, children) {
  var error = errorOpt !== undefined ? Caml_option.valFromOption(errorOpt) : undefined;
  return ReasonReactCompat.wrapReactForReasonReact(SettingsWfConnectionManagement, {
              token: token,
              config: config,
              handleChangeUrl: handleChangeUrl,
              error: error
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = SettingsWfConnectionManagement;

exports.createErrorElement = createErrorElement;
exports.Styles = Styles;
exports.Loading = Loading;
exports.SelectConnectionToTransferIntegration = SelectConnectionToTransferIntegration;
exports.SelectConnectionToTransferIntegrations = SelectConnectionToTransferIntegrations;
exports.DeleteConnection = DeleteConnection;
exports.DeleteConfirmation = DeleteConfirmation;
exports.Header = Header$1;
exports.ConnectionHeaderIcons = ConnectionHeaderIcons;
exports.ConnectionHeader = ConnectionHeader$1;
exports.IntegrationTableRow = IntegrationTableRow;
exports.ConnectionTable = ConnectionTable;
exports.ConnectionRows = ConnectionRows;
exports.initialState = initialState;
exports.reducer = reducer;
exports.useConnectionData = useConnectionData;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* inputItem Not a pure module */
