// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Crdb$BsConsole = require("./crdb.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Error$BsConsole = require("./error.js");
var Parse$BsConsole = require("./bs-crdb-response/src/Parse.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var TriageGroup$BsConsole = require("./TriageGroup.js");
var FetchProjectUsers$BsConsole = require("./FetchProjectUsers.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");

var ParseError = Caml_exceptions.create("FetchIssuesData-BsConsole.ParseError");

var CoronerdError = Caml_exceptions.create("FetchIssuesData-BsConsole.CoronerdError");

function createGetIssuesQuery(aperture, fingerprints) {
  var fingerprintRegExString = "(" + (fingerprints.join("|") + ")");
  var aperture$prime = Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                "fingerprint",
                Crdb$BsConsole.FilterOperation.fromTuple(/* tuple */[
                      "regular-expression",
                      fingerprintRegExString
                    ])
              ]), Crdb$BsConsole.Filters.empty), aperture);
  var fold = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
            "fingerprint;issues;invariants",
            /* Head */1
          ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                "fingerprint;issues;tags",
                /* Head */1
              ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                    "fingerprint;issues;state",
                    /* Head */1
                  ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        "fingerprint;issues;ticket",
                        /* Head */1
                      ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                            "fingerprint;issues;assignee",
                            /* Head */1
                          ]), Crdb$BsConsole.Fold.empty)))));
  return /* Aggregate */Block.__(0, [
            aperture$prime,
            /* Identity */0,
            fold,
            undefined,
            undefined,
            undefined
          ]);
}

function getIssuesTask(aperture, fingerprints, projectName) {
  var query = createGetIssuesQuery(aperture, fingerprints);
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/query?project=" + (String(projectName) + "")]), undefined, /* Post */Block.__(0, [Crdb$BsConsole.QueryManager.toCrdb(query)]), undefined, (function (param) {
                var json = param.json;
                try {
                  var columns = Parse$BsConsole.parseColumns(json);
                  var rows = Parse$BsConsole.decodeExn(/* tuple */[
                        Curry._1(Crdb$BsConsole.Factor.toCrdbString, /* Identity */0),
                        undefined
                      ], columns, json);
                  var metadata = Parse$BsConsole.Aggregate.metadata(json);
                  return /* Ok */Block.__(0, [/* tuple */[
                              columns,
                              rows,
                              metadata
                            ]]);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  var error = Error$BsConsole.HTTPD.decode(json);
                  if (error !== undefined) {
                    return /* Error */Block.__(1, [[
                                CoronerdError,
                                error
                              ]]);
                  }
                  var error$1 = Uncaught$BsConsole.castToJsExn(err);
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "FetchIssuesData/getIssuesTask", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `exn */[
                        5049499,
                        error$1
                      ]);
                  return /* Error */Block.__(1, [[
                              ParseError,
                              error$1
                            ]]);
                }
              }), undefined, undefined);
}

function use(projectName, token, query) {
  var match = React.useState((function () {
          return [];
        }));
  var setFingerprints = match[1];
  var fingerprints = match[0];
  var match$1 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setIssueRemote = match$1[1];
  var issueRemote = match$1[0];
  var match$2 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setTriageGroupsRemote = match$2[1];
  var triageGroupsRemote = match$2[0];
  var match$3 = QueryPaginationTask$BsConsole.CrdbAggregate.use(token, projectName, query, "triage_table");
  var setPage = match$3[2];
  var page = match$3[1];
  var paginationRemote = match$3[0];
  var match$4 = FetchProjectUsers$BsConsole.use(token, projectName);
  var projectUsersRemote = match$4[0];
  React.useEffect((function () {
          if (typeof paginationRemote === "number") {
            if (paginationRemote !== /* NotAsked */0) {
              Curry._1(setTriageGroupsRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
            
          } else if (paginationRemote.tag) {
            var msg = paginationRemote[0];
            Curry._1(setTriageGroupsRemote, (function (param) {
                    return /* Failure */Block.__(1, [msg]);
                  }));
          } else if (typeof projectUsersRemote !== "number" && !projectUsersRemote.tag) {
            var success = paginationRemote[0];
            var next = success[1];
            var match = success[0];
            var requestQuery = match[3];
            var meta = match[2];
            var rows = match[1];
            var columns = match[0];
            var projectUsers = projectUsersRemote[0];
            var match$1 = TriageGroup$BsConsole.fromCrdbResponseGroup(projectUsers, columns, meta.seq, rows);
            var aggColumns = match$1[1];
            var triageGroups = match$1[0];
            Curry._1(setTriageGroupsRemote, (function (param) {
                    return /* Success */Block.__(0, [{
                                triageGroups: triageGroups,
                                aggColumns: aggColumns,
                                loading: typeof next === "number" ? next !== 0 : false,
                                rows: rows,
                                columns: columns,
                                meta: meta,
                                projectUsers: projectUsers,
                                page: page,
                                setPage: (function (page) {
                                    return Curry._1(setPage, (function (param) {
                                                  return page;
                                                }));
                                  }),
                                requestQuery: requestQuery
                              }]);
                  }));
          }
          
        }), /* tuple */[
        paginationRemote,
        projectUsersRemote
      ]);
  React.useEffect((function () {
          if (fingerprints.length !== 0) {
            Curry._1(setIssueRemote, (function (param) {
                    return /* Loading */1;
                  }));
          }
          
        }), [fingerprints]);
  React.useEffect((function () {
          if (typeof issueRemote === "number") {
            if (issueRemote !== /* NotAsked */0 && typeof triageGroupsRemote !== "number" && !triageGroupsRemote.tag) {
              var arg = getIssuesTask(Curry._1(Crdb$BsConsole.Query.getAperture, query), fingerprints, projectName);
              Curry._2((function (param) {
                        return (function (param$1, param$2) {
                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                          });
                      })(token), undefined, (function (resp) {
                      if (resp.tag) {
                        var error = resp[0];
                        var errorMessage;
                        if (error[0] === CoronerdError) {
                          errorMessage = error[1].message;
                        } else if (error[0] === ParseError) {
                          var message = error[1].message;
                          errorMessage = message !== undefined ? message : "Something went wrong. This error has been reported to Backtrace.";
                        } else {
                          errorMessage = "Something went wrong. This error has been reported to Backtrace.";
                        }
                        return Curry._1(setIssueRemote, (function (param) {
                                      return /* Failure */Block.__(1, [errorMessage]);
                                    }));
                      }
                      var resp$1 = resp[0];
                      return Curry._1(setIssueRemote, (function (param) {
                                    return /* Success */Block.__(0, [resp$1]);
                                  }));
                    }));
            }
            
          } else if (!issueRemote.tag && typeof triageGroupsRemote !== "number" && !triageGroupsRemote.tag) {
            var results = triageGroupsRemote[0];
            var projectUsers = results.projectUsers;
            var match = issueRemote[0];
            var meta = match[2];
            var columns = match[0];
            var groups = Belt_List.map(match[1], (function (row) {
                    return /* tuple */[
                            row.factor.value,
                            TriageGroup$BsConsole.parseIssueColumns(projectUsers, columns, meta.seq, row)
                          ];
                  }));
            var newTriageGroups = Belt_Array.map(results.triageGroups, (function (t) {
                    var match = Belt_List.getBy(groups, (function (param) {
                            return param[0] === t.fingerprint;
                          }));
                    if (match !== undefined) {
                      return {
                              count: t.count,
                              fingerprint: t.fingerprint,
                              callstack: t.callstack,
                              timestampRange: t.timestampRange,
                              timestampBin: t.timestampBin,
                              firstSeen: t.firstSeen,
                              aggregations: t.aggregations,
                              issues: match[1]
                            };
                    } else {
                      return t;
                    }
                  }));
            Curry._1(setTriageGroupsRemote, (function (param) {
                    return /* Success */Block.__(0, [{
                                triageGroups: newTriageGroups,
                                aggColumns: results.aggColumns,
                                loading: results.loading,
                                rows: results.rows,
                                columns: results.columns,
                                meta: results.meta,
                                projectUsers: results.projectUsers,
                                page: results.page,
                                setPage: results.setPage,
                                requestQuery: results.requestQuery
                              }]);
                  }));
          }
          
        }), [issueRemote]);
  var fetchIssues = function (fingerprints) {
    return Curry._1(setFingerprints, (function (param) {
                  return fingerprints;
                }));
  };
  return /* tuple */[
          triageGroupsRemote,
          fetchIssues
        ];
}

exports.ParseError = ParseError;
exports.CoronerdError = CoronerdError;
exports.createGetIssuesQuery = createGetIssuesQuery;
exports.getIssuesTask = getIssuesTask;
exports.use = use;
/* react Not a pure module */
