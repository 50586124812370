// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var TeamsUtils$BsConsole = require("./TeamsUtils.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var MenuItem = require("@material-ui/core/MenuItem");
var InputLabel = require("@material-ui/core/InputLabel");

function TeamsAddProjectAccessToTeam(Props) {
  var token = Props.token;
  var openDialog = Props.openDialog;
  var closeDialogFn = Props.closeDialogFn;
  var team = Props.team;
  var refetchProjectMemberTeams = Props.refetchProjectMemberTeams;
  var allProjects = Props.allProjects;
  var match = React.useState((function () {
          
        }));
  var setSelectedProject = match[1];
  var selectedProject = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setSelectedRole = match$1[1];
  var selectedRole = match$1[0];
  var allRoles = [
    "guest",
    "member",
    "admin"
  ];
  return React.createElement(BtModal$BsConsole.Default.make, {
              open: openDialog,
              onClose: (function (param) {
                  Curry._1(setSelectedProject, (function (param) {
                          
                        }));
                  Curry._1(setSelectedRole, (function (param) {
                          
                        }));
                  return Curry._1(closeDialogFn, undefined);
                }),
              title: "Add project access",
              subtitle: "",
              divider: true,
              children: React.createElement(Col2$BsConsole.make, {
                    justifyContent: /* flexEnd */924268066,
                    children: null
                  }, React.createElement(InputLabel.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginTop(Css.px(8)),
                                /* :: */[
                                  Css.marginBottom(Css.px(3)),
                                  /* [] */0
                                ]
                              ])
                        },
                        htmlFor: "select-project",
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Caption */11,
                              children: I18N$BsConsole.show(undefined, "Project")
                            })
                      }), React.createElement(Select.default, {
                        id: "select-project",
                        value: selectedProject !== undefined ? selectedProject.name : "",
                        onChange: (function ($$event, param) {
                            var value = $$event.target.value;
                            return Curry._1(setSelectedProject, (function (param) {
                                          return value;
                                        }));
                          }),
                        renderValue: (function (v) {
                            return React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Caption */11,
                                        children: I18N$BsConsole.showSkip(v)
                                      });
                          }),
                        disableUnderline: true,
                        classes: {
                          root: TeamsUtils$BsConsole.selectStyle
                        },
                        children: Belt_Array.map(allProjects, (function (v) {
                                return React.createElement(MenuItem.default, {
                                            value: v,
                                            children: React.createElement(BtTypography$BsConsole.make, {
                                                  variant: /* Caption */11,
                                                  children: I18N$BsConsole.showSkip(v.name)
                                                }),
                                            key: v.name
                                          });
                              }))
                      }), React.createElement(InputLabel.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginTop(Css.px(8)),
                                /* :: */[
                                  Css.marginBottom(Css.px(3)),
                                  /* [] */0
                                ]
                              ])
                        },
                        htmlFor: "select-role",
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Caption */11,
                              children: I18N$BsConsole.show(undefined, "Role")
                            })
                      }), React.createElement(Select.default, {
                        id: "select-role",
                        value: selectedRole !== undefined ? selectedRole : "",
                        onChange: (function ($$event, param) {
                            var value = $$event.target.value;
                            return Curry._1(setSelectedRole, (function (param) {
                                          return value;
                                        }));
                          }),
                        renderValue: (function (v) {
                            return React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Caption */11,
                                        children: I18N$BsConsole.showSkip(v)
                                      });
                          }),
                        disableUnderline: true,
                        classes: {
                          root: TeamsUtils$BsConsole.selectStyle
                        },
                        children: $$Array.map((function (v) {
                                return React.createElement(MenuItem.default, {
                                            value: v,
                                            children: React.createElement(BtTypography$BsConsole.make, {
                                                  variant: /* Caption */11,
                                                  children: I18N$BsConsole.showSkip(v)
                                                })
                                          });
                              }), allRoles)
                      }), React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* flexEnd */924268066,
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]),
                        children: React.createElement(Button.default, {
                              variant: "contained",
                              color: "primary",
                              disabled: selectedProject === undefined || selectedRole === undefined,
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.marginTop(Css.px(10)),
                                      /* :: */[
                                        Css.marginBottom(Css.px(10)),
                                        /* [] */0
                                      ]
                                    ])
                              },
                              onClick: (function (param) {
                                  if (selectedProject !== undefined && selectedRole !== undefined) {
                                    var arg = BpgTask$BsConsole.ProjectMemberTeam.create({
                                          project: selectedProject.pid,
                                          team: team.id,
                                          role: selectedRole,
                                          __state: undefined,
                                          __create: undefined,
                                          __modify: undefined
                                        });
                                    Curry._2((function (param) {
                                              return (function (param$1, param$2) {
                                                  return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                });
                                            })(token), true, (function (results) {
                                            return TeamsUtils$BsConsole.handleResults(results, "create team-project mapping", refetchProjectMemberTeams);
                                          }));
                                  }
                                  Curry._1(setSelectedProject, (function (param) {
                                          
                                        }));
                                  Curry._1(setSelectedRole, (function (param) {
                                          
                                        }));
                                  return Curry._1(closeDialogFn, undefined);
                                }),
                              children: I18N$BsConsole.show(undefined, "Add")
                            })
                      }))
            });
}

var make = TeamsAddProjectAccessToTeam;

exports.make = make;
/* Css Not a pure module */
