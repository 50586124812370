// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Route$BsConsole = require("../route.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Services$BsConsole = require("../Services.js");
var ChartsBin$BsConsole = require("../charts/ChartsBin.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var BtTableColumn$BsConsole = require("../BtTableColumn.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var FeatureService$BsConsole = require("../FeatureService.js");
var BtEnhancedTable$BsConsole = require("../BtEnhancedTable.js");
var SimilarityUtils$BsConsole = require("./SimilarityUtils.js");
var SimilaritySummary$BsConsole = require("./SimilaritySummary.js");
var SimilarityCandidate$BsConsole = require("./SimilarityCandidate.js");
var CircularProgress = require("@material-ui/core/CircularProgress");
var SimilarityDistanceParameter$BsConsole = require("./SimilarityDistanceParameter.js");

function SimilarityProject$Loading(Props) {
  return React.createElement(Col2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.paddingTop(Css.px(12)),
                    /* :: */[
                      Css.paddingBottom(Css.px(12)),
                      /* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.height(Css.pct(100)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]),
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.paddingTop(Css.px(12)),
                        /* :: */[
                          Css.paddingBottom(Css.px(12)),
                          /* [] */0
                        ]
                      ])
                }, I18N$BsConsole.show(undefined, "Computing similarity results for project using the current filter. This can take a while.")), React.createElement(CircularProgress.default, { }));
}

var Loading = {
  make: SimilarityProject$Loading
};

function SimilarityProject$NoSummaryResults(Props) {
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              justifyContent: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.height(Css.px(60)),
                    /* [] */0
                  ]),
              children: React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body2 */9,
                    color: Colors$BsConsole.grey4,
                    children: I18N$BsConsole.show(undefined, "No summary results found for this project.")
                  })
            });
}

var NoSummaryResults = {
  make: SimilarityProject$NoSummaryResults
};

function distanceGroupsToBin(counts) {
  return Belt_List.mapWithIndex(counts, (function (index, count) {
                return /* tuple */[
                        index,
                        index + 0.99,
                        count
                      ];
              }));
}

function similarityUrl(fingerprint, projectName) {
  var params = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var aperture = params.aperture;
  var timeAperture = Crdb$BsConsole.granularityStringToTimeAperture("all");
  if (fingerprint !== undefined) {
    return Route$BsConsole.pathForRoute(/* ProjectTriage */Block.__(11, [
                  projectName,
                  {
                    aperture: Crdb$BsConsole.addFilter(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, aperture), /* :: */[
                          /* tuple */[
                            "fingerprint",
                            /* Equal */Block.__(0, [/* `String */[
                                  -976970511,
                                  fingerprint
                                ]])
                          ],
                          /* [] */0
                        ]),
                    sort: params.sort,
                    stats: params.stats,
                    havings: params.havings,
                    fold: params.fold,
                    normBy: params.normBy,
                    similarity: true
                  }
                ]));
  } else {
    return Route$BsConsole.pathForRoute(/* ProjectTriage */Block.__(11, [
                  projectName,
                  {
                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, aperture),
                    sort: params.sort,
                    stats: params.stats,
                    havings: params.havings,
                    fold: params.fold,
                    normBy: params.normBy,
                    similarity: true
                  }
                ]));
  }
}

function getDetailsRoute(fingerprint, projectName, aperture) {
  var init = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  return /* ProjectTriage */Block.__(11, [
            projectName,
            {
              aperture: Crdb$BsConsole.addFilter(aperture, /* :: */[
                    /* tuple */[
                      "fingerprint",
                      /* Equal */Block.__(0, [/* `String */[
                            -976970511,
                            fingerprint
                          ]])
                    ],
                    /* [] */0
                  ]),
              sort: init.sort,
              stats: /* [] */0,
              havings: init.havings,
              fold: Crdb$BsConsole.Fold.empty,
              normBy: init.normBy,
              similarity: init.similarity
            }
          ]);
}

var columns = [
  BtTableColumn$BsConsole.make("Rank", "rank", (function (row) {
          return /* `Int */[
                  3654863,
                  row.rank
                ];
        }), undefined, undefined, undefined, undefined, undefined, 166, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make("Status", "status", (function (row) {
          var state = row.state;
          if (state !== undefined) {
            return /* `String */[
                    -976970511,
                    state
                  ];
          } else {
            return /* `String */[
                    -976970511,
                    "-"
                  ];
          }
        }), undefined, undefined, undefined, undefined, undefined, 166, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make("Fingerprint", "fingerprint", (function (row) {
          return /* `String */[
                  -976970511,
                  row.fingerprint
                ];
        }), undefined, undefined, undefined, undefined, undefined, 166, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make("Date range", "dates", (function (row) {
          return /* `String */[
                  -976970511,
                  SimilarityCandidate$BsConsole.datesToDateString(row.dates)
                ];
        }), undefined, undefined, undefined, undefined, undefined, 200, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make("Candidates", "candidates", (function (row) {
          return /* `Int */[
                  3654863,
                  row.candidates
                ];
        }), undefined, undefined, undefined, undefined, undefined, 166, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make("Instances", "candidateInstances", (function (row) {
          return /* `Int */[
                  3654863,
                  row.candidateInstances
                ];
        }), undefined, undefined, undefined, undefined, undefined, 166, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make("Distances", "groupedByDistance", (function (row) {
          var data = distanceGroupsToBin(row.groupedByDistance);
          return /* `Element */[
                  -744106340,
                  React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.marginTop(Css.px(5)),
                              /* [] */0
                            ])
                      }, React.createElement(ChartsBin$BsConsole.SimilarityDistanceChart.make, {
                            numYAxisTicks: 3,
                            hideXAxis: false,
                            hideYAxis: true,
                            hideGridColumns: true,
                            hideGridRows: true,
                            data: Belt_List.toArray(data),
                            marginTop: 5,
                            marginLeft: 5,
                            marginRight: 5,
                            marginBottom: 10,
                            height: 50,
                            width: 125
                          }))
                ];
        }), undefined, undefined, undefined, undefined, undefined, 250, undefined, undefined, undefined, undefined)
];

function createPageGroups(arr, rowsPerPage) {
  var arrLength = arr.length;
  var numPages = Caml_int32.div(arrLength, rowsPerPage) + (
    Caml_int32.mod_(arrLength, rowsPerPage) === 0 ? 0 : 1
  ) | 0;
  try {
    return Belt_Array.makeBy(numPages, (function (i) {
                  var offset = Caml_int32.imul(i + 1 | 0, rowsPerPage) - rowsPerPage | 0;
                  return Belt_Array.slice(arr, offset, rowsPerPage);
                }));
  }
  catch (exn){
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error processing similarity summary."));
    return [];
  }
}

function SimilarityProject$SummaryView(Props) {
  var endpoint = Props.endpoint;
  Props.config;
  var token = Props.token;
  var projectName = Props.projectName;
  Props.handleChangeUrl;
  var aperture = Props.aperture;
  var match = React.useState((function () {
          
        }));
  var setValidServiceVersion = match[1];
  var validServiceVersion = match[0];
  var match$1 = React.useState((function () {
          return true;
        }));
  var setLoading = match$1[1];
  var match$2 = React.useState((function () {
          
        }));
  var setHoveredFp = match$2[1];
  var hoveredFp = match$2[0];
  var match$3 = React.useState((function () {
          return [];
        }));
  var setPageGroups = match$3[1];
  var match$4 = React.useState((function () {
          return 0;
        }));
  var setCurrentPage = match$4[1];
  var currentPage = match$4[0];
  var match$5 = React.useState((function () {
          return [];
        }));
  var setTotalRows = match$5[1];
  var totalRows = match$5[0];
  var match$6 = React.useState((function () {
          
        }));
  var setSortingState = match$6[1];
  var sortingState = match$6[0];
  React.useEffect((function () {
          var arg = SimilarityUtils$BsConsole.fetchServiceVersion(endpoint);
          Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (version) {
                  if (version.tag) {
                    Curry._1(setValidServiceVersion, (function (param) {
                            return false;
                          }));
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                        /* String_literal */Block.__(11, [
                                            "Error retrieving similarity version: ",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* End_of_format */0
                                              ])
                                          ]),
                                        "Error retrieving similarity version: %s"
                                      ]), Task2$BsConsole.renderError(undefined, version[0])));
                  }
                  var validVersion = Semver.valid(version[0]);
                  var isVersionValid = validVersion !== undefined && Semver.gte(validVersion, SimilarityUtils$BsConsole.minimumRequiredVersion) ? true : false;
                  return Curry._1(setValidServiceVersion, (function (param) {
                                return isVersionValid;
                              }));
                }));
          
        }), [endpoint]);
  React.useEffect((function () {
          if (validServiceVersion !== undefined && validServiceVersion) {
            var arg = SimilaritySummary$BsConsole.fetchSummary(endpoint, projectName, aperture, SimilarityDistanceParameter$BsConsole.Hardcoded.toDefaultValue(/* Limit */2), SimilarityDistanceParameter$BsConsole.Hardcoded.toDefaultValue(/* Threshold */0), SimilarityDistanceParameter$BsConsole.Hardcoded.toDefaultValue(/* Truncate */1), SimilarityDistanceParameter$BsConsole.Dynamic.toDynamic(/* Intersection */1), SimilarityDistanceParameter$BsConsole.Dynamic.toDynamic(/* Distance */0));
            Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (results) {
                    if (results.tag) {
                      SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                    /* String_literal */Block.__(11, [
                                        "Error retrieving similarity summary: ",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* End_of_format */0
                                          ])
                                      ]),
                                    "Error retrieving similarity summary: %s"
                                  ]), Task2$BsConsole.renderError(undefined, results[0])));
                      return Curry._1(setLoading, (function (param) {
                                    return false;
                                  }));
                    }
                    var __x = results[0].results;
                    var arr = Belt_List.toArray(Belt_List.mapWithIndex(__x, (function (index, summary) {
                                return {
                                        fingerprint: summary.fingerprint,
                                        state: summary.state,
                                        candidateInstances: summary.candidateInstances,
                                        candidates: summary.candidates,
                                        dates: summary.dates,
                                        groupedByDistance: summary.groupedByDistance,
                                        rank: index + 1 | 0
                                      };
                              })));
                    var sortedPageGroups = createPageGroups(arr, 10);
                    Curry._1(setPageGroups, (function (param) {
                            return sortedPageGroups;
                          }));
                    Curry._1(setTotalRows, (function (param) {
                            return arr;
                          }));
                    return Curry._1(setLoading, (function (param) {
                                  return false;
                                }));
                  }));
          }
          
        }), /* tuple */[
        validServiceVersion,
        projectName,
        token,
        aperture
      ]);
  React.useEffect((function () {
          if (sortingState !== undefined) {
            var arr = BtEnhancedTable$BsConsole.defaultRowSort(totalRows, columns, sortingState);
            var sortedPageGroups = createPageGroups(arr, 10);
            Curry._1(setPageGroups, (function (param) {
                    return sortedPageGroups;
                  }));
          } else {
            var sortedPageGroups$1 = createPageGroups(totalRows, 10);
            Curry._1(setPageGroups, (function (param) {
                    return sortedPageGroups$1;
                  }));
          }
          
        }), [sortingState]);
  var rows = Belt_Array.get(match$3[0], currentPage);
  var currentPageRows = rows !== undefined ? rows : [];
  if (validServiceVersion === undefined) {
    return null;
  }
  if (!validServiceVersion) {
    return React.createElement(FeatureService$BsConsole.UnavailableVersion.make, {
                serviceName: "similarity",
                minimumRequiredVersion: SimilarityUtils$BsConsole.minimumRequiredVersion
              });
  }
  if (match$1[0]) {
    return React.createElement(SimilarityProject$Loading, { });
  }
  var tmp;
  if (totalRows.length !== 0) {
    var tmp$1 = {
      columns: columns,
      rows: currentPageRows,
      getRowId: (function (row) {
          return row.fingerprint;
        }),
      onSortChange: (function (sortingState) {
          return Curry._1(setSortingState, (function (param) {
                        return sortingState;
                      }));
        }),
      onRowEnter: (function (_event, row) {
          return Curry._1(setHoveredFp, (function (param) {
                        return row.fingerprint;
                      }));
        }),
      onRowLeave: (function (param, _r) {
          return Curry._1(setHoveredFp, (function (param) {
                        
                      }));
        }),
      onRowClick: (function (param, _r) {
          return Util$BsConsole.openUrl(similarityUrl(hoveredFp, projectName));
        }),
      resizeableColumns: true,
      page: currentPage,
      onChangePage: (function (page) {
          return Curry._1(setCurrentPage, (function (param) {
                        return page;
                      }));
        }),
      totalRows: totalRows.length,
      rowsPerPage: 10
    };
    if (sortingState !== undefined) {
      tmp$1.sortingState = Caml_option.valFromOption(sortingState);
    }
    tmp = React.createElement(BtEnhancedTable$BsConsole.make, tmp$1);
  } else {
    tmp = React.createElement(SimilarityProject$NoSummaryResults, { });
  }
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.selector(".BtTableBody > .BtTableRow:hover", /* :: */[
                          Css.backgroundColor(Css.hex(Colors$BsConsole.npLightYellow2)),
                          /* :: */[
                            Css.cursor(/* pointer */-786317123),
                            /* [] */0
                          ]
                        ]),
                    /* [] */0
                  ])
            }, tmp);
}

var SummaryView = {
  make: SimilarityProject$SummaryView
};

function SimilarityProject(Props) {
  var config = Props.config;
  var token = Props.token;
  var projectName = Props.projectName;
  var handleChangeUrl = Props.handleChangeUrl;
  var aperture = Props.aperture;
  var similarityEndpointMaybe = Services$BsConsole.getEndpoint(undefined)(config.services, "similarity");
  if (similarityEndpointMaybe !== undefined) {
    return React.createElement(SimilarityProject$SummaryView, {
                endpoint: similarityEndpointMaybe,
                config: config,
                token: token,
                projectName: projectName,
                handleChangeUrl: handleChangeUrl,
                aperture: aperture
              });
  } else {
    return React.createElement(FeatureService$BsConsole.Unavailable.make, {
                serviceName: "similarity"
              });
  }
}

function make(config, token, projectName, handleChangeUrl, aperture, children) {
  return ReasonReactCompat.wrapReactForReasonReact(SimilarityProject, {
              config: config,
              token: token,
              projectName: projectName,
              handleChangeUrl: handleChangeUrl,
              aperture: aperture
            }, children);
}

var Re = {
  make: make
};

var make$1 = SimilarityProject;

exports.Loading = Loading;
exports.NoSummaryResults = NoSummaryResults;
exports.distanceGroupsToBin = distanceGroupsToBin;
exports.similarityUrl = similarityUrl;
exports.getDetailsRoute = getDetailsRoute;
exports.columns = columns;
exports.createPageGroups = createPageGroups;
exports.SummaryView = SummaryView;
exports.make = make$1;
exports.Re = Re;
/* columns Not a pure module */
