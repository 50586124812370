// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Col2$BsConsole = require("../../components/Col2.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var FuzzaldrinPlus = require("fuzzaldrin-plus");
var Colors$BsConsole = require("../../Colors.js");
var Numeral$BsConsole = require("../../Numeral.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtSelectJsx3$BsConsole = require("../../BtSelectJsx3.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var FetchSymbols$BsConsole = require("../../FetchSymbols.js");
var BtTableColumn$BsConsole = require("../../BtTableColumn.js");
var Close = require("@material-ui/icons/Close");
var L10N_date_time$BsConsole = require("../../L10N_date_time.js");
var Toolbar = require("@material-ui/core/Toolbar");
var BtEnhancedTable$BsConsole = require("../../BtEnhancedTable.js");
var Collapse = require("@material-ui/core/Collapse");
var MenuItem = require("@material-ui/core/MenuItem");
var TextField = require("@material-ui/core/TextField");
var IconButton = require("@material-ui/core/IconButton");
var CheckCircle = require("@material-ui/icons/CheckCircle");
var KeyboardArrowUp = require("@material-ui/icons/KeyboardArrowUp");
var KeyboardArrowDown = require("@material-ui/icons/KeyboardArrowDown");

function fuzz(options, searchValue, getName) {
  if (searchValue.trim() !== "") {
    return Belt_Array.keepMap(Belt_Array.map(FuzzaldrinPlus.filter(Belt_Array.mapWithIndex(options, (function (i, e) {
                              return {
                                      q: Curry._1(getName, e),
                                      index: i
                                    };
                            })), searchValue, {
                        key: "q"
                      }), (function (r) {
                      return r.index;
                    })), (function (index) {
                  return Belt_Array.get(options, index);
                }));
  } else {
    return options;
  }
}

function msTimestampToString($$float) {
  return L10N_date_time$BsConsole.Format.numericDayTwentyFour(undefined, new Date($$float * 1000));
}

var missingSymbols = [
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "First seen"), "timestamp", (function (row) {
          return /* `String */[
                  -976970511,
                  msTimestampToString(row.timestamp)
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Filename"), "debugFile", (function (row) {
          return /* `String */[
                  -976970511,
                  row.debugFile
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Debug identifier"), "debugId", (function (row) {
          return /* `String */[
                  -976970511,
                  row.debugId
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
];

var uploadedSymbols = [
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Uploaded"), "uploadTime", (function (row) {
          return /* `String */[
                  -976970511,
                  msTimestampToString(Caml_format.caml_float_of_string(row.uploadTime))
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Filename"), "debugFile", (function (row) {
          return /* `String */[
                  -976970511,
                  row.debugFile
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Debug identifier"), "debugId", (function (row) {
          return /* `String */[
                  -976970511,
                  row.debugId
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
];

var uploadedArchives = [
  BtTableColumn$BsConsole.make("Status", "status", (function (row) {
          var match = row.status;
          switch (match) {
            case "failed" :
                return /* `Element */[
                        -744106340,
                        React.createElement("span", {
                              className: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.redDark)),
                                    /* [] */0
                                  ])
                            }, React.createElement(Close.default, {
                                  color: "inherit"
                                }))
                      ];
            case "succeeded" :
                return /* `Element */[
                        -744106340,
                        React.createElement("span", {
                              className: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.greenLight)),
                                    /* [] */0
                                  ])
                            }, React.createElement(CheckCircle.default, {
                                  color: "inherit"
                                }))
                      ];
            default:
              return /* None */870530776;
          }
        }), undefined, undefined, undefined, undefined, undefined, undefined, Css.style(/* :: */[
            Css.maxWidth(Css.px(150)),
            /* [] */0
          ]), undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Uploaded"), "uploadTime", (function (row) {
          return /* `String */[
                  -976970511,
                  msTimestampToString(row.uploadTime)
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "ID"), "archiveId", (function (row) {
          return /* `String */[
                  -976970511,
                  row.archiveId
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Size"), "fileSize", (function (row) {
          var value = Numeral$BsConsole.format("0,0.0b", String(row.fileSize));
          return /* `String */[
                  -976970511,
                  value
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Right */-57574468, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "New"), "newSymbols", (function (row) {
          return /* `Int */[
                  3654863,
                  row.newSymbols
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Duplicate"), "duplicateSymbols", (function (row) {
          return /* `Int */[
                  3654863,
                  row.duplicateSymbols
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Invalid"), "invalidSymbols", (function (row) {
          return /* `Int */[
                  3654863,
                  row.invalidSymbols
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
];

var simpleSymbols = [
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Filename"), "debugFile", (function (row) {
          return /* `String */[
                  -976970511,
                  row.debugFile
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
  BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Debug identifier"), "debugId", (function (row) {
          return /* `String */[
                  -976970511,
                  row.debugId
                ];
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
];

var Columns = {
  missingSymbols: missingSymbols,
  uploadedSymbols: uploadedSymbols,
  uploadedArchives: uploadedArchives,
  simpleSymbols: simpleSymbols
};

var initialSortingState = {
  columnName: "timestamp",
  direction: /* Desc */759138929
};

function simpleSort(rows, sortingState) {
  return Belt_List.toArray(Belt_List.sort(rows, (function (a, b) {
                    var match = sortingState.columnName;
                    var match$1 = sortingState.direction;
                    switch (match) {
                      case "debugFile" :
                          if (match$1 >= 759138929) {
                            if (a.debugFile > b.debugFile) {
                              return 1;
                            } else {
                              return -1;
                            }
                          } else if (a.debugFile < b.debugFile) {
                            return 1;
                          } else {
                            return -1;
                          }
                      case "debugId" :
                          if (match$1 >= 759138929) {
                            if (a.debugId > b.debugId) {
                              return 1;
                            } else {
                              return -1;
                            }
                          } else if (a.debugId < b.debugId) {
                            return 1;
                          } else {
                            return -1;
                          }
                      case "timestamp" :
                          if (match$1 >= 759138929) {
                            return b.timestamp - a.timestamp | 0;
                          } else {
                            return a.timestamp - b.timestamp | 0;
                          }
                      default:
                        return 1;
                    }
                  })));
}

var MissingSymbols = {
  initialSortingState: initialSortingState,
  simpleSort: simpleSort
};

var initialSortingState$1 = {
  columnName: "uploadTime",
  direction: /* Desc */759138929
};

function simpleSort$1(rows, sortingState) {
  return Belt_List.toArray(Belt_List.sort(rows, (function (a, b) {
                    var match = sortingState.columnName;
                    var match$1 = sortingState.direction;
                    switch (match) {
                      case "debugFile" :
                          if (match$1 >= 759138929) {
                            if (a.debugFile > b.debugFile) {
                              return 1;
                            } else {
                              return -1;
                            }
                          } else if (a.debugFile < b.debugFile) {
                            return 1;
                          } else {
                            return -1;
                          }
                      case "debugId" :
                          if (match$1 >= 759138929) {
                            if (a.debugId > b.debugId) {
                              return 1;
                            } else {
                              return -1;
                            }
                          } else if (a.debugId < b.debugId) {
                            return 1;
                          } else {
                            return -1;
                          }
                      case "uploadTime" :
                          if (match$1 >= 759138929) {
                            return Caml_format.caml_int_of_string(b.uploadTime) - Caml_format.caml_int_of_string(a.uploadTime) | 0;
                          } else {
                            return Caml_format.caml_int_of_string(a.uploadTime) - Caml_format.caml_int_of_string(b.uploadTime) | 0;
                          }
                      default:
                        return 1;
                    }
                  })));
}

var Uploaded = {
  initialSortingState: initialSortingState$1,
  simpleSort: simpleSort$1
};

function simpleSort$2(rows, sortingState) {
  return Belt_List.toArray(Belt_List.sort(rows, (function (a, b) {
                    var match = sortingState.columnName;
                    var match$1 = sortingState.direction;
                    switch (match) {
                      case "archiveId" :
                          if (match$1 >= 759138929) {
                            if (a.archiveId > b.archiveId) {
                              return 1;
                            } else {
                              return -1;
                            }
                          } else if (a.archiveId < b.archiveId) {
                            return 1;
                          } else {
                            return -1;
                          }
                      case "duplicateSymbols" :
                          if (match$1 >= 759138929) {
                            return b.duplicateSymbols - a.duplicateSymbols | 0;
                          } else {
                            return a.duplicateSymbols - b.duplicateSymbols | 0;
                          }
                      case "fileSize" :
                          if (match$1 >= 759138929) {
                            return b.fileSize - a.fileSize | 0;
                          } else {
                            return a.fileSize - b.fileSize | 0;
                          }
                      case "invalidSymbols" :
                          if (match$1 >= 759138929) {
                            return b.invalidSymbols - a.invalidSymbols | 0;
                          } else {
                            return a.invalidSymbols - b.invalidSymbols | 0;
                          }
                      case "newSymbols" :
                          if (match$1 >= 759138929) {
                            return b.newSymbols - a.newSymbols | 0;
                          } else {
                            return a.newSymbols - b.newSymbols | 0;
                          }
                      case "status" :
                          if (match$1 >= 759138929) {
                            if (a.status > b.status) {
                              return 1;
                            } else {
                              return -1;
                            }
                          } else if (a.status < b.status) {
                            return 1;
                          } else {
                            return -1;
                          }
                      case "uploadTime" :
                          if (match$1 >= 759138929) {
                            return b.uploadTime - a.uploadTime | 0;
                          } else {
                            return a.uploadTime - b.uploadTime | 0;
                          }
                      default:
                        return 1;
                    }
                  })));
}

var UploadedArchives_initialSortingState = {
  columnName: "uploadTime",
  direction: /* Desc */759138929
};

var UploadedArchives = {
  initialSortingState: UploadedArchives_initialSortingState,
  simpleSort: simpleSort$2
};

function simpleSort$3(tags) {
  return Belt_List.sort(tags, (function (a, b) {
                return b.lastUpdateTime - a.lastUpdateTime | 0;
              }));
}

var Tags = {
  simpleSort: simpleSort$3
};

var initialSortingState$2 = {
  columnName: "debugFile",
  direction: /* Desc */759138929
};

function simpleSort$4(rows, sortingState) {
  return Belt_List.toArray(Belt_List.sort(rows, (function (a, b) {
                    var match = sortingState.columnName;
                    var match$1 = sortingState.direction;
                    switch (match) {
                      case "debugFile" :
                          if (match$1 >= 759138929) {
                            if (a.debugFile > b.debugFile) {
                              return 1;
                            } else {
                              return -1;
                            }
                          } else if (a.debugFile < b.debugFile) {
                            return 1;
                          } else {
                            return -1;
                          }
                      case "debugId" :
                          if (match$1 >= 759138929) {
                            if (a.debugId > b.debugId) {
                              return 1;
                            } else {
                              return -1;
                            }
                          } else if (a.debugId < b.debugId) {
                            return 1;
                          } else {
                            return -1;
                          }
                      default:
                        return 1;
                    }
                  })));
}

var SimpleSymbols = {
  initialSortingState: initialSortingState$2,
  simpleSort: simpleSort$4
};

var Sorting = {
  MissingSymbols: MissingSymbols,
  Uploaded: Uploaded,
  UploadedArchives: UploadedArchives,
  Tags: Tags,
  SimpleSymbols: SimpleSymbols
};

function arg(row) {
  return row.debugFile + row.debugId;
}

function missingSymbolSearch(param) {
  return (function (param$1) {
      return fuzz(param, param$1, arg);
    });
}

function arg$1(row) {
  return row.debugFile + row.debugId;
}

function uploadedSymbolSearch(param) {
  return (function (param$1) {
      return fuzz(param, param$1, arg$1);
    });
}

function arg$2(row) {
  return row.archiveId;
}

function uploadedArchiveSearch(param) {
  return (function (param$1) {
      return fuzz(param, param$1, arg$2);
    });
}

var Search = {
  missingSymbolSearch: missingSymbolSearch,
  uploadedSymbolSearch: uploadedSymbolSearch,
  uploadedArchiveSearch: uploadedArchiveSearch
};

function SymbolTable$Missing(Props) {
  var data = Props.data;
  var match = React.useState((function () {
          return 0;
        }));
  var setPage = match[1];
  var page = match[0];
  var match$1 = React.useState((function () {
          return initialSortingState;
        }));
  var setSortingState = match$1[1];
  var sortingState = match$1[0];
  var match$2 = React.useState((function () {
          return "";
        }));
  var setSearchValue = match$2[1];
  var searchValue = match$2[0];
  var sortedRows = simpleSort(data, sortingState);
  var sortedAndSearchedRows = missingSymbolSearch(sortedRows)(searchValue);
  var start = Caml_int32.imul(page, 100);
  var end_ = (start + 100 | 0) > sortedAndSearchedRows.length ? sortedAndSearchedRows.length : start + 100 | 0;
  var rows = sortedAndSearchedRows.slice(start, end_);
  var search = searchValue.trim();
  var emptyTableText = search !== "" ? I18N$BsConsole.get(undefined, "No missing symbols for current search parameter") : I18N$BsConsole.get(undefined, "No missing symbols");
  return React.createElement(React.Fragment, undefined, React.createElement(Toolbar.default, {
                  className: Css.style(/* :: */[
                        Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                        /* [] */0
                      ]),
                  children: React.createElement(TextField.default, {
                        placeholder: I18N$BsConsole.get(undefined, "Search missing symbols..."),
                        value: searchValue,
                        classes: {
                          root: Css.style(/* :: */[
                                Css.minWidth(Css.pct(50)),
                                /* [] */0
                              ])
                        },
                        onChange: (function (e) {
                            var value = e.target.value;
                            return Curry._1(setSearchValue, (function (param) {
                                          return value;
                                        }));
                          })
                      })
                }), React.createElement(BtEnhancedTable$BsConsole.make, {
                  columns: missingSymbols,
                  rows: rows,
                  getRowId: (function (row) {
                      return row.debugId;
                    }),
                  showGrid: false,
                  sortingState: sortingState,
                  onSortChange: (function (sortingState) {
                      return Curry._1(setSortingState, (function (param) {
                                    return sortingState;
                                  }));
                    }),
                  page: page,
                  onChangePage: (function (page) {
                      return Curry._1(setPage, (function (param) {
                                    return page;
                                  }));
                    }),
                  totalRows: sortedAndSearchedRows.length,
                  rowsPerPage: 100,
                  emptyTableText: emptyTableText
                }));
}

function make(data, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(SymbolTable$Missing, {
              data: data
            }, _children);
}

var Re = {
  make: make
};

var Missing = {
  make: SymbolTable$Missing,
  Re: Re
};

function SymbolTable$TagSelect(Props) {
  var tags = Props.tags;
  var tag = Props.tag;
  var onChange = Props.onChange;
  if (tag !== undefined) {
    return React.createElement(BtSelectJsx3$BsConsole.make, {
                onChange: onChange,
                value: tag.tag,
                label: I18N$BsConsole.get(undefined, "Archive Tag"),
                renderValue: I18N$BsConsole.showSkip,
                children: Belt_Array.mapWithIndex(Belt_List.toArray(simpleSort$3(tags)), (function (idx, tag) {
                        return React.createElement(MenuItem.default, {
                                    value: /* `String */[
                                      -976970511,
                                      tag.tag
                                    ],
                                    children: I18N$BsConsole.showSkip(tag.tag),
                                    key: tag.tag + ("__" + String(idx))
                                  });
                      }))
              });
  } else {
    return null;
  }
}

var TagSelect = {
  make: SymbolTable$TagSelect
};

function SymbolTable$Uploaded(Props) {
  var data = Props.data;
  var tags = Props.tags;
  var match = React.useState((function () {
          return 0;
        }));
  var setPage = match[1];
  var page = match[0];
  var match$1 = React.useState((function () {
          return initialSortingState$1;
        }));
  var setSortingState = match$1[1];
  var sortingState = match$1[0];
  var match$2 = React.useState((function () {
          return "";
        }));
  var setSearchValue = match$2[1];
  var searchValue = match$2[0];
  var match$3 = React.useState((function () {
          return Belt_List.head(simpleSort$3(tags));
        }));
  var setTag = match$3[1];
  var tag = match$3[0];
  var symbolFilesForSelectedTag;
  if (tag !== undefined) {
    var symbols = Belt_List.getBy(data, (function (symbols) {
            return symbols.tag === tag.tag;
          }));
    symbolFilesForSelectedTag = symbols !== undefined ? symbols.files : /* [] */0;
  } else {
    symbolFilesForSelectedTag = /* [] */0;
  }
  var sortedRows = simpleSort$1(symbolFilesForSelectedTag, sortingState);
  var sortedAndSearchedRows = uploadedSymbolSearch(sortedRows)(searchValue);
  var start = Caml_int32.imul(page, 100);
  var end_ = (start + 100 | 0) > sortedAndSearchedRows.length ? sortedAndSearchedRows.length : start + 100 | 0;
  var rows = sortedAndSearchedRows.slice(start, end_);
  searchValue.trim();
  var emptyTableText = tag !== undefined ? I18N$BsConsole.get(undefined, "No symbols found in current tag") : I18N$BsConsole.get(undefined, "No symbols found");
  return React.createElement(React.Fragment, undefined, React.createElement(Toolbar.default, {
                  className: Css.style(/* :: */[
                        Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                        /* :: */[
                          Css.justifyContent(/* spaceBetween */516682146),
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, React.createElement(TextField.default, {
                      placeholder: I18N$BsConsole.get(undefined, "Search uploaded symbols..."),
                      value: searchValue,
                      classes: {
                        root: Css.style(/* :: */[
                              Css.minWidth(Css.pct(50)),
                              /* [] */0
                            ])
                      },
                      onChange: (function (e) {
                          var value = e.target.value;
                          return Curry._1(setSearchValue, (function (param) {
                                        return value;
                                      }));
                        })
                    }), React.createElement(SymbolTable$TagSelect, {
                      tags: tags,
                      tag: tag,
                      onChange: (function ($$event, param) {
                          var match = $$event.target.value;
                          if (typeof match === "number") {
                            return ;
                          }
                          if (match[0] !== -976970511) {
                            return ;
                          }
                          var value = match[1];
                          var nextTag = Belt_List.getBy(tags, (function (tag) {
                                  return tag.tag === value;
                                }));
                          return Curry._1(setTag, (function (param) {
                                        return nextTag;
                                      }));
                        })
                    })), React.createElement(BtEnhancedTable$BsConsole.make, {
                  columns: uploadedSymbols,
                  rows: rows,
                  getRowId: (function (row) {
                      return row.debugId;
                    }),
                  showGrid: false,
                  sortingState: sortingState,
                  onSortChange: (function (sortingState) {
                      return Curry._1(setSortingState, (function (param) {
                                    return sortingState;
                                  }));
                    }),
                  page: page,
                  onChangePage: (function (page) {
                      return Curry._1(setPage, (function (param) {
                                    return page;
                                  }));
                    }),
                  totalRows: sortedAndSearchedRows.length,
                  rowsPerPage: 100,
                  emptyTableText: emptyTableText
                }));
}

function make$1(data, tags, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(SymbolTable$Uploaded, {
              data: data,
              tags: tags
            }, _children);
}

var Re$1 = {
  make: make$1
};

var Uploaded$1 = {
  make: SymbolTable$Uploaded,
  Re: Re$1
};

function SymbolTable$TableOutlineContainer(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    Css.style(/* :: */[
                          Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                          /* :: */[
                            Css.borderRadius(Css.px(4)),
                            /* [] */0
                          ]
                        ]),
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var TableOutlineContainer = {
  make: SymbolTable$TableOutlineContainer
};

function SymbolTable$SimpleSymbols(Props) {
  var data = Props.data;
  var match = React.useState((function () {
          return 0;
        }));
  var setPage = match[1];
  var page = match[0];
  var match$1 = React.useState((function () {
          return initialSortingState$2;
        }));
  var setSortingState = match$1[1];
  var sortingState = match$1[0];
  var sortedRows = simpleSort$1(data, sortingState);
  var start = Caml_int32.imul(page, 100);
  var end_ = (start + 100 | 0) > sortedRows.length ? sortedRows.length : start + 100 | 0;
  var rows = sortedRows.slice(start, end_);
  var emptyTableText = I18N$BsConsole.get(undefined, "No symbols found");
  return React.createElement(SymbolTable$TableOutlineContainer, {
              className: Css.style(/* :: */[
                    Css.marginLeft(Css.px(32)),
                    /* :: */[
                      Css.selector(".BtTableContainer", /* :: */[
                            Css.maxHeight(Css.px(250)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]),
              children: React.createElement(BtEnhancedTable$BsConsole.make, {
                    columns: simpleSymbols,
                    rows: rows,
                    getRowId: (function (row) {
                        return row.debugId;
                      }),
                    showGrid: false,
                    sortingState: sortingState,
                    onSortChange: (function (sortingState) {
                        return Curry._1(setSortingState, (function (param) {
                                      return sortingState;
                                    }));
                      }),
                    page: page,
                    onChangePage: (function (page) {
                        return Curry._1(setPage, (function (param) {
                                      return page;
                                    }));
                      }),
                    totalRows: sortedRows.length,
                    rowsPerPage: 100,
                    emptyTableText: emptyTableText
                  })
            });
}

var SimpleSymbols$1 = {
  make: SymbolTable$SimpleSymbols
};

function SymbolTable$ErrorsList(Props) {
  var errors = Props.errors;
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.marginLeft(Css.px(32)),
                    /* [] */0
                  ]),
              children: Belt_Array.map(errors, (function (error) {
                      return React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Body2 */9,
                                  className: Css.style(/* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.redDark)),
                                        /* [] */0
                                      ]),
                                  children: I18N$BsConsole.dynamic(error)
                                });
                    }))
            });
}

var ErrorsList = {
  make: SymbolTable$ErrorsList
};

function SymbolTable$ExpandSection(Props) {
  var label = Props.label;
  var expandDisabled = Props.expandDisabled;
  var expandedContent = Props.expandedContent;
  var match = React.useState((function () {
          return false;
        }));
  var setExpanded = match[1];
  var expanded = match[0];
  return React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  children: null
                }, React.createElement(IconButton.default, {
                      disabled: expandDisabled,
                      classes: {
                        root: Css.style(/* :: */[
                              Css.fontSize(Css.rem(1)),
                              /* :: */[
                                Css.important(Css.padding(Css.px(4))),
                                /* [] */0
                              ]
                            ]),
                        label: Css.style(/* :: */[
                              Css.unsafe("fontSize", "inherit"),
                              /* [] */0
                            ])
                      },
                      size: "small",
                      onClick: (function (param) {
                          return Curry._1(setExpanded, (function (expandedState) {
                                        return !expandedState;
                                      }));
                        }),
                      children: expanded ? React.createElement(KeyboardArrowUp.default, { }) : React.createElement(KeyboardArrowDown.default, { })
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body2 */9,
                      children: I18N$BsConsole.showSkip(label)
                    })), React.createElement(Collapse.default, {
                  in: expanded,
                  timeout: 100,
                  children: expandedContent
                }));
}

var ExpandSection = {
  make: SymbolTable$ExpandSection
};

function SymbolTable$UploadedArchivesRowDetails(Props) {
  var token = Props.token;
  var projectName = Props.projectName;
  var errors = Props.errors;
  var archiveId = Props.archiveId;
  var tag = Props.tag;
  var symbolsRemote = FetchSymbols$BsConsole.useTask(token, projectName, archiveId, tag);
  var symbolFiles;
  symbolFiles = typeof symbolsRemote === "number" || symbolsRemote.tag ? /* [] */0 : Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_List.head(symbolsRemote[0]), (function (archive) {
                return archive.files;
              })), /* [] */0);
  return React.createElement(React.Fragment, undefined, React.createElement(SymbolTable$ExpandSection, {
                  label: I18N$BsConsole.get(undefined, "Symbols") + (" (" + (String(Belt_List.length(symbolFiles)) + ")")),
                  expandDisabled: Belt_List.length(symbolFiles) === 0,
                  expandedContent: React.createElement(SymbolTable$SimpleSymbols, {
                        data: symbolFiles
                      })
                }), React.createElement(SymbolTable$ExpandSection, {
                  label: I18N$BsConsole.get(undefined, "Errors") + (" (" + (String(Belt_List.length(errors)) + ")")),
                  expandDisabled: Belt_List.length(errors) === 0,
                  expandedContent: React.createElement(SymbolTable$ErrorsList, {
                        errors: Belt_List.toArray(errors)
                      })
                }));
}

var UploadedArchivesRowDetails = {
  make: SymbolTable$UploadedArchivesRowDetails
};

function SymbolTable$UploadedArchives(Props) {
  var token = Props.token;
  var projectName = Props.projectName;
  var data = Props.data;
  var tags = Props.tags;
  var match = React.useState((function () {
          return 0;
        }));
  var setPage = match[1];
  var page = match[0];
  var match$1 = React.useState((function () {
          return initialSortingState$1;
        }));
  var setSortingState = match$1[1];
  var sortingState = match$1[0];
  var match$2 = React.useState((function () {
          return "";
        }));
  var setSearchValue = match$2[1];
  var searchValue = match$2[0];
  var match$3 = React.useState((function () {
          return Belt_List.head(simpleSort$3(tags));
        }));
  var setTag = match$3[1];
  var tag = match$3[0];
  var symbolFilesForSelectedTag;
  if (tag !== undefined) {
    var symbols = Belt_List.getBy(data, (function (symbolArchive) {
            return symbolArchive.tag === tag.tag;
          }));
    symbolFilesForSelectedTag = symbols !== undefined ? symbols.files : /* [] */0;
  } else {
    symbolFilesForSelectedTag = /* [] */0;
  }
  var sortedRows = simpleSort$2(symbolFilesForSelectedTag, sortingState);
  var sortedAndSearchedRows = uploadedArchiveSearch(sortedRows)(searchValue);
  var start = Caml_int32.imul(page, 100);
  var end_ = (start + 100 | 0) > sortedAndSearchedRows.length ? sortedAndSearchedRows.length : start + 100 | 0;
  var rows = sortedAndSearchedRows.slice(start, end_);
  searchValue.trim();
  var emptyTableText = tag !== undefined ? I18N$BsConsole.get(undefined, "No archives found in current tag") : "No archives found";
  return React.createElement(React.Fragment, undefined, React.createElement(Toolbar.default, {
                  className: Css.style(/* :: */[
                        Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                        /* :: */[
                          Css.justifyContent(/* spaceBetween */516682146),
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, React.createElement(TextField.default, {
                      placeholder: I18N$BsConsole.get(undefined, "Search uploaded symbols..."),
                      value: searchValue,
                      classes: {
                        root: Css.style(/* :: */[
                              Css.minWidth(Css.pct(50)),
                              /* [] */0
                            ])
                      },
                      onChange: (function (e) {
                          var value = e.target.value;
                          return Curry._1(setSearchValue, (function (param) {
                                        return value;
                                      }));
                        })
                    }), React.createElement(SymbolTable$TagSelect, {
                      tags: tags,
                      tag: tag,
                      onChange: (function ($$event, param) {
                          var match = $$event.target.value;
                          if (typeof match === "number") {
                            return ;
                          }
                          if (match[0] !== -976970511) {
                            return ;
                          }
                          var value = match[1];
                          var nextTag = Belt_List.getBy(tags, (function (tag) {
                                  return tag.tag === value;
                                }));
                          return Curry._1(setTag, (function (param) {
                                        return nextTag;
                                      }));
                        })
                    })), React.createElement(BtEnhancedTable$BsConsole.make, {
                  columns: uploadedArchives,
                  rows: rows,
                  getRowId: (function (row) {
                      return row.archiveId;
                    }),
                  showGrid: false,
                  renderExpandedRowContent: (function (row) {
                      return React.createElement(SymbolTable$UploadedArchivesRowDetails, {
                                  token: token,
                                  projectName: projectName,
                                  errors: row.errors,
                                  archiveId: row.archiveId,
                                  tag: Belt_Option.flatMap(tag, (function (t) {
                                          return t.tag;
                                        }))
                                });
                    }),
                  sortingState: sortingState,
                  onSortChange: (function (sortingState) {
                      return Curry._1(setSortingState, (function (param) {
                                    return sortingState;
                                  }));
                    }),
                  page: page,
                  onChangePage: (function (page) {
                      return Curry._1(setPage, (function (param) {
                                    return page;
                                  }));
                    }),
                  totalRows: sortedAndSearchedRows.length,
                  rowsPerPage: 100,
                  emptyTableText: emptyTableText
                }));
}

function make$2(token, projectName, data, tags, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(SymbolTable$UploadedArchives, {
              token: token,
              projectName: projectName,
              data: data,
              tags: tags
            }, _children);
}

var Re$2 = {
  make: make$2
};

var UploadedArchives$1 = {
  make: SymbolTable$UploadedArchives,
  Re: Re$2
};

function SymbolTable$UploadSummary(Props) {
  var token = Props.token;
  var projectName = Props.projectName;
  var data = Props.data;
  var tag = Props.tag;
  var archiveIds = Props.archiveIds;
  var match = React.useState((function () {
          return 0;
        }));
  var setPage = match[1];
  var page = match[0];
  var match$1 = React.useState((function () {
          return initialSortingState$1;
        }));
  var setSortingState = match$1[1];
  var sortingState = match$1[0];
  var symbols = Belt_List.getBy(data, (function (symbolArchive) {
          return symbolArchive.tag === tag;
        }));
  var symbolFilesForSelectedTag = symbols !== undefined ? symbols.files : /* [] */0;
  var sortedRows = Belt_Array.keep(simpleSort$2(symbolFilesForSelectedTag, sortingState), (function (archive) {
          return archiveIds.includes(archive.archiveId);
        }));
  var start = Caml_int32.imul(page, 100);
  var end_ = (start + 100 | 0) > sortedRows.length ? sortedRows.length : start + 100 | 0;
  var rows = sortedRows.slice(start, end_);
  var emptyTableText = I18N$BsConsole.get(undefined, "No archives uploaded");
  return React.createElement(SymbolTable$TableOutlineContainer, {
              children: React.createElement(BtEnhancedTable$BsConsole.make, {
                    columns: uploadedArchives,
                    rows: rows,
                    getRowId: (function (row) {
                        return row.archiveId;
                      }),
                    showGrid: false,
                    renderExpandedRowContent: (function (row) {
                        return React.createElement(SymbolTable$UploadedArchivesRowDetails, {
                                    token: token,
                                    projectName: projectName,
                                    errors: row.errors,
                                    archiveId: row.archiveId,
                                    tag: tag
                                  });
                      }),
                    sortingState: sortingState,
                    onSortChange: (function (sortingState) {
                        return Curry._1(setSortingState, (function (param) {
                                      return sortingState;
                                    }));
                      }),
                    page: page,
                    onChangePage: (function (page) {
                        return Curry._1(setPage, (function (param) {
                                      return page;
                                    }));
                      }),
                    totalRows: sortedRows.length,
                    rowsPerPage: 100,
                    emptyTableText: emptyTableText
                  })
            });
}

function make$3(token, projectName, data, tag, archiveIds, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(SymbolTable$UploadSummary, {
              token: token,
              projectName: projectName,
              data: data,
              tag: tag,
              archiveIds: archiveIds
            }, _children);
}

var Re$3 = {
  make: make$3
};

var UploadSummary = {
  make: SymbolTable$UploadSummary,
  Re: Re$3
};

var rowsPerPage = 100;

exports.rowsPerPage = rowsPerPage;
exports.fuzz = fuzz;
exports.msTimestampToString = msTimestampToString;
exports.Columns = Columns;
exports.Sorting = Sorting;
exports.Search = Search;
exports.Missing = Missing;
exports.TagSelect = TagSelect;
exports.Uploaded = Uploaded$1;
exports.TableOutlineContainer = TableOutlineContainer;
exports.SimpleSymbols = SimpleSymbols$1;
exports.ErrorsList = ErrorsList;
exports.ExpandSection = ExpandSection;
exports.UploadedArchivesRowDetails = UploadedArchivesRowDetails;
exports.UploadedArchives = UploadedArchives$1;
exports.UploadSummary = UploadSummary;
/* missingSymbols Not a pure module */
