// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var I18N$BsConsole = require("../../I18N.js");
var ApiWf$BsConsole = require("../workflows-api/ApiWf.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var WfRouter$BsConsole = require("../WfRouter.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var DescribeTask$BsConsole = require("../../DescribeTask.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Close = require("@material-ui/icons/Close");
var BtThemeContext$BsConsole = require("../../BtThemeContext.js");
var SuccessMessage$BsConsole = require("../../SuccessMessage.js");
var WarningMessage$BsConsole = require("../../WarningMessage.js");
var TextField = require("@material-ui/core/TextField");
var ApiWfIntegrations$BsConsole = require("../workflows-api/ApiWfIntegrations.js");
var Wf2MainBodyConent$BsConsole = require("../Wf2MainBodyConent.js");
var Wf2AdvancedOptions$BsConsole = require("../Wf2AdvancedOptions.js");
var FormHelperText = require("@material-ui/core/FormHelperText");
var AddCircleOutline = require("@material-ui/icons/AddCircleOutline");

var parentCreate = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* [] */0
    ]);

var title = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* [] */0
        ]
      ]
    ]);

var titleText = Css.style(/* :: */[
      Css.fontSize(Css.px(24)),
      /* :: */[
        Css.margin(Css.px(0)),
        /* [] */0
      ]
    ]);

var titleLogo = Css.style(/* :: */[
      Css.width(Css.px(24)),
      /* :: */[
        Css.margin4(Css.px(0), Css.px(16), Css.px(0), Css.px(0)),
        /* [] */0
      ]
    ]);

var bottomMargin = Css.style(/* :: */[
      Css.marginBottom(Css.px(40)),
      /* [] */0
    ]);

var smBottomMargin = Css.style(/* :: */[
      Css.marginBottom(Css.px(10)),
      /* [] */0
    ]);

var buttonContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexEnd */924268066),
        /* :: */[
          Css.marginTop(Css.px(50)),
          /* [] */0
        ]
      ]
    ]);

var saveButton = Css.style(/* :: */[
      Css.marginLeft(Css.px(10)),
      /* [] */0
    ]);

var flexRow = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.marginBottom(Css.px(10)),
          /* [] */0
        ]
      ]
    ]);

var flexColumn = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* [] */0
      ]
    ]);

var section = Css.merge(/* :: */[
      flexRow,
      /* :: */[
        bottomMargin,
        /* :: */[
          Css.style(/* :: */[
                Css.flexDirection(/* row */5693978),
                /* :: */[
                  Css.width(Css.pct(100)),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var sectionRow = Css.merge(/* :: */[
      flexRow,
      /* :: */[
        Css.style(/* :: */[
              Css.flexDirection(/* row */5693978),
              /* :: */[
                Css.width(Css.pct(100)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var sectionColumn = Css.merge(/* :: */[
      flexColumn,
      /* :: */[
        Css.style(/* :: */[
              Css.flex(/* `num */[
                    5496390,
                    1
                  ]),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var integrationNameLabel = Css.style(/* :: */[
      Css.fontSize(Css.px(16)),
      /* :: */[
        Css.marginBottom(Css.px(5)),
        /* [] */0
      ]
    ]);

var inputItem = Css.style(/* :: */[
      Css.padding(Css.px(10)),
      /* :: */[
        Css.minWidth(Css.px(250)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var halfWIdth = Css.style(/* :: */[
      Css.selector("input", /* :: */[
            Css.important(Css.padding(Css.px(10))),
            /* [] */0
          ]),
      /* :: */[
        Css.unsafe("width", "calc(50% - 20px - 29px)"),
        /* [] */0
      ]
    ]);

var fullWIdth = Css.style(/* :: */[
      Css.selector("input", /* :: */[
            Css.important(Css.padding(Css.px(10))),
            /* [] */0
          ]),
      /* :: */[
        Css.unsafe("width", "calc(100% - 20px - 29px)"),
        /* [] */0
      ]
    ]);

var channels = Css.style(/* :: */[
      Css.selector("input", /* :: */[
            Css.important(Css.padding(Css.px(10))),
            /* [] */0
          ]),
      /* :: */[
        Css.width(Css.pct(100)),
        /* [] */0
      ]
    ]);

var Input = {
  halfWIdth: halfWIdth,
  fullWIdth: fullWIdth,
  channels: channels
};

var formHelperText = Css.style(/* :: */[
      Css.margin4(Css.px(8), Css.px(0), Css.px(0), Css.px(0)),
      /* [] */0
    ]);

var Styles = {
  parentCreate: parentCreate,
  title: title,
  titleText: titleText,
  titleLogo: titleLogo,
  bottomMargin: bottomMargin,
  smBottomMargin: smBottomMargin,
  buttonContainer: buttonContainer,
  saveButton: saveButton,
  flexRow: flexRow,
  flexColumn: flexColumn,
  section: section,
  sectionRow: sectionRow,
  sectionColumn: sectionColumn,
  integrationNameLabel: integrationNameLabel,
  inputItem: inputItem,
  Input: Input,
  formHelperText: formHelperText
};

function SlackIntegration$Required(Props) {
  var classNameOpt = Props.className;
  var variant = Props.variant;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(BtTypography$BsConsole.make, {
              variant: variant,
              className: className,
              children: null
            }, children, React.createElement("span", {
                  style: {
                    color: Colors$BsConsole.octothorpe(Colors$BsConsole.npDarkRed)
                  }
                }, I18N$BsConsole.showSkip(" *")));
}

var Required = {
  make: SlackIntegration$Required
};

function SlackIntegration$Section(Props) {
  var children = Props.children;
  var classNamesOpt = Props.classNames;
  var classNames = classNamesOpt !== undefined ? classNamesOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    section,
                    /* :: */[
                      classNames,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var Section = {
  make: SlackIntegration$Section
};

function SlackIntegration$SectionRow(Props) {
  var children = Props.children;
  var classNamesOpt = Props.classNames;
  var classNames = classNamesOpt !== undefined ? classNamesOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    sectionRow,
                    /* :: */[
                      classNames,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var SectionRow = {
  make: SlackIntegration$SectionRow
};

function SlackIntegration$SectionColumn(Props) {
  var children = Props.children;
  var classNamesOpt = Props.classNames;
  var classNames = classNamesOpt !== undefined ? classNamesOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    sectionColumn,
                    /* :: */[
                      classNames,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var SectionColumn = {
  make: SlackIntegration$SectionColumn
};

function SlackIntegration$NewChannelRowButton(Props) {
  var handleClick = Props.handleClick;
  var palette = BtThemeContext$BsConsole.useThemePalette(undefined);
  var primaryMain = Colors$BsConsole.deoctothorpe(palette.primary.main);
  return React.createElement(React.Fragment, undefined, React.createElement(Button.default, {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(primaryMain)),
                        /* [] */0
                      ]),
                  onClick: (function (_e) {
                      return Curry._1(handleClick, undefined);
                    }),
                  children: null
                }, React.createElement(AddCircleOutline.default, {
                      fontSize: "inherit"
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body2 */9,
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(primaryMain)),
                            /* :: */[
                              Css.fontWeight(/* `num */[
                                    5496390,
                                    500
                                  ]),
                              /* :: */[
                                Css.fontSize(Css.rem(0.875)),
                                /* :: */[
                                  Css.paddingLeft(Css.px(3)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, "Add new channel")
                    })));
}

var NewChannelRowButton = {
  make: SlackIntegration$NewChannelRowButton
};

function SlackIntegration$CloseButton(Props) {
  var handleClick = Props.handleClick;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Mui$BsConsole.IconButton.make, {
              ariaLabel: "Close",
              size: "small",
              className: Css.merge(/* :: */[
                    className,
                    /* :: */[
                      Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.red)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]),
              onClick: (function (_e) {
                  return Curry._1(handleClick, undefined);
                }),
              children: React.createElement(Close.default, {
                    fontSize: "small"
                  })
            });
}

var CloseButton = {
  make: SlackIntegration$CloseButton
};

function SlackIntegration$ChannelRow(Props) {
  var isInvalid = Props.isInvalid;
  var receiver = Props.receiver;
  var index = Props.index;
  var handleAddReceiver = Props.handleAddReceiver;
  var handleDeleteReceiver = Props.handleDeleteReceiver;
  var match = React.useState((function () {
          return true;
        }));
  var setisPrefixValid = match[1];
  var isPrefixValid = match[0];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.alignItems(/* center */98248149),
                          /* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.marginBottom(Css.px(10)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.flex(/* `num */[
                                  5496390,
                                  1
                                ]),
                            /* [] */0
                          ])
                    }, React.createElement(TextField.default, {
                          placeholder: "",
                          value: receiver,
                          variant: "outlined",
                          classes: {
                            root: channels
                          },
                          onChange: (function ($$event) {
                              var newReceiver = Belt_Option.getWithDefault($$event.target.value, "").trim();
                              if (newReceiver.startsWith("@") || newReceiver.startsWith("#")) {
                                Curry._1(setisPrefixValid, (function (param) {
                                        return true;
                                      }));
                                return Curry._2(handleAddReceiver, index, newReceiver);
                              } else {
                                Curry._1(setisPrefixValid, (function (param) {
                                        return false;
                                      }));
                                return Curry._2(handleAddReceiver, index, "");
                              }
                            })
                        }), isInvalid ? React.createElement(FormHelperText.default, {
                            error: true,
                            children: I18N$BsConsole.show(undefined, "Channel is required & must start with @ or #")
                          }) : (
                        isPrefixValid ? null : React.createElement(FormHelperText.default, {
                                error: true,
                                children: I18N$BsConsole.show(undefined, "Channel must start with @ or #")
                              })
                      )), React.createElement(SlackIntegration$CloseButton, {
                      handleClick: (function (param) {
                          return Curry._1(handleDeleteReceiver, index);
                        }),
                      className: Css.style(/* :: */[
                            Css.flex3(0, 1, /* zero */-789508312),
                            /* :: */[
                              Css.fontSize(Css.rem(1.5)),
                              /* :: */[
                                Css.marginLeft(Css.px(5)),
                                /* :: */[
                                  isInvalid || !isPrefixValid ? Css.marginTop(Css.px(-20)) : Css.marginTop(/* auto */-1065951377),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ])
                    })));
}

var ChannelRow = {
  make: SlackIntegration$ChannelRow
};

function SlackIntegration$Channels(Props) {
  var isInvalid = Props.isInvalid;
  var handleAddReceiver = Props.handleAddReceiver;
  var handleDeleteReceiver = Props.handleDeleteReceiver;
  var handleAddNewChannelBar = Props.handleAddNewChannelBar;
  var receivers = Props.receivers;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ])
            }, React.createElement(SlackIntegration$Required, {
                  variant: /* Body1 */8,
                  children: I18N$BsConsole.show(undefined, "Channel Name")
                }), React.createElement(FormHelperText.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(10)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Where Backtrace will post messages, you can provide #channel, @group, or @user\n")
                }), Belt_Array.mapWithIndex(receivers, (function (index, receiver) {
                    return React.createElement(SlackIntegration$ChannelRow, {
                                isInvalid: index === 0 ? isInvalid : false,
                                receiver: receiver,
                                index: index,
                                handleAddReceiver: handleAddReceiver,
                                handleDeleteReceiver: handleDeleteReceiver,
                                key: String(index)
                              });
                  })), React.createElement(SlackIntegration$NewChannelRowButton, {
                  handleClick: (function (param) {
                      return Curry._1(handleAddNewChannelBar, undefined);
                    })
                }));
}

var Channels = {
  make: SlackIntegration$Channels
};

function SlackIntegration$IntegrationNameInput(Props) {
  var formState = Props.formState;
  var integrationName = Props.integrationName;
  var handleChange = Props.handleChange;
  var isInvalid = Props.isInvalid;
  var tmp;
  tmp = formState.tag ? true : false;
  return React.createElement(SlackIntegration$SectionColumn, {
              children: null
            }, React.createElement(SlackIntegration$Required, {
                  className: integrationNameLabel,
                  variant: /* Body1 */8,
                  children: I18N$BsConsole.show(undefined, "Integration Name")
                }), React.createElement(TextField.default, {
                  autoFocus: false,
                  disabled: tmp,
                  placeholder: "",
                  value: integrationName,
                  color: "primary",
                  variant: "outlined",
                  classes: {
                    root: halfWIdth
                  },
                  onChange: (function ($$event) {
                      return Curry._1(handleChange, Belt_Option.getWithDefault($$event.target.value, ""));
                    })
                }), isInvalid ? React.createElement(FormHelperText.default, {
                    error: true,
                    children: I18N$BsConsole.show(undefined, "Integration name is required")
                  }) : React.createElement(FormHelperText.default, {
                    children: I18N$BsConsole.show(undefined, "To identify the integration throughout the app")
                  }));
}

var IntegrationNameInput = {
  make: SlackIntegration$IntegrationNameInput
};

var initialState_receivers = [""];

var initialState_selectedAttributes = [];

var initialState = {
  connectionId: undefined,
  connectionCreated: false,
  integrationName: "",
  receivers: initialState_receivers,
  validationErrors: "",
  apiErrors: "",
  advancedOptionsOpen: false,
  selectedAttributes: initialState_selectedAttributes
};

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            connectionId: state.connectionId,
            connectionCreated: state.connectionCreated,
            integrationName: state.integrationName,
            receivers: state.receivers,
            validationErrors: state.validationErrors,
            apiErrors: state.apiErrors,
            advancedOptionsOpen: !state.advancedOptionsOpen,
            selectedAttributes: state.selectedAttributes
          };
  }
  switch (action.tag | 0) {
    case /* SetConnectionId */0 :
        return {
                connectionId: action[0],
                connectionCreated: action[1],
                integrationName: state.integrationName,
                receivers: state.receivers,
                validationErrors: state.validationErrors,
                apiErrors: state.apiErrors,
                advancedOptionsOpen: state.advancedOptionsOpen,
                selectedAttributes: state.selectedAttributes
              };
    case /* SetIntegrationName */1 :
        return {
                connectionId: state.connectionId,
                connectionCreated: state.connectionCreated,
                integrationName: action[0],
                receivers: state.receivers,
                validationErrors: state.validationErrors,
                apiErrors: state.apiErrors,
                advancedOptionsOpen: state.advancedOptionsOpen,
                selectedAttributes: state.selectedAttributes
              };
    case /* SetReceivers */2 :
        return {
                connectionId: state.connectionId,
                connectionCreated: state.connectionCreated,
                integrationName: state.integrationName,
                receivers: action[0],
                validationErrors: state.validationErrors,
                apiErrors: state.apiErrors,
                advancedOptionsOpen: state.advancedOptionsOpen,
                selectedAttributes: state.selectedAttributes
              };
    case /* SetValidationError */3 :
        return {
                connectionId: state.connectionId,
                connectionCreated: state.connectionCreated,
                integrationName: state.integrationName,
                receivers: state.receivers,
                validationErrors: action[0],
                apiErrors: state.apiErrors,
                advancedOptionsOpen: state.advancedOptionsOpen,
                selectedAttributes: state.selectedAttributes
              };
    case /* SetApiError */4 :
        return {
                connectionId: state.connectionId,
                connectionCreated: state.connectionCreated,
                integrationName: state.integrationName,
                receivers: state.receivers,
                validationErrors: state.validationErrors,
                apiErrors: action[0],
                advancedOptionsOpen: state.advancedOptionsOpen,
                selectedAttributes: state.selectedAttributes
              };
    case /* SetSelectedAttributes */5 :
        return {
                connectionId: state.connectionId,
                connectionCreated: state.connectionCreated,
                integrationName: state.integrationName,
                receivers: state.receivers,
                validationErrors: state.validationErrors,
                apiErrors: state.apiErrors,
                advancedOptionsOpen: state.advancedOptionsOpen,
                selectedAttributes: action[0]
              };
    case /* SetAutoPopulateState */6 :
        var autoPopulateState = action[0];
        return {
                connectionId: autoPopulateState.connectionId,
                connectionCreated: state.connectionCreated,
                integrationName: autoPopulateState.integrationName,
                receivers: autoPopulateState.receivers,
                validationErrors: state.validationErrors,
                apiErrors: state.apiErrors,
                advancedOptionsOpen: autoPopulateState.advancedOptionsOpen,
                selectedAttributes: autoPopulateState.selectedAttributes
              };
    
  }
}

function SlackIntegration(Props) {
  var formState = Props.formState;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var config = Props.config;
  var match = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var describeRemote = match[0];
  var attributes;
  attributes = typeof describeRemote === "number" || describeRemote.tag ? /* [] */0 : Belt_List.sort(describeRemote[0], (function (attr1, attr2) {
            return $$String.compare(attr1.name.toLowerCase(), attr2.name.toLowerCase());
          }));
  var handleCancel = function (param) {
    if (formState.tag && !formState[1]) {
      return Curry._1(handleChangeUrl, /* SettingsWfConnectionManagement */Block.__(6, [{
                      error: undefined
                    }]));
    } else {
      return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                    projectName,
                    WfRouter$BsConsole.root,
                    undefined
                  ]));
    }
  };
  var match$1 = React.useReducer(reducer, initialState);
  var dispatch = match$1[1];
  var match$2 = match$1[0];
  var selectedAttributes = match$2.selectedAttributes;
  var apiErrors = match$2.apiErrors;
  var validationErrors = match$2.validationErrors;
  var receivers = match$2.receivers;
  var integrationName = match$2.integrationName;
  var connectionId = match$2.connectionId;
  var autoPopulateForm = function (integrationId) {
    var onFailure = function (err) {
      return Curry._1(dispatch, /* SetApiError */Block.__(4, [err]));
    };
    var onSuccess = function (param) {
      var body = param.body;
      var match = body.connection;
      if (match === undefined) {
        return Curry._1(dispatch, /* SetApiError */Block.__(4, ["No connectionId found."]));
      }
      var match$1 = body.options;
      if (match$1 === undefined) {
        return Curry._1(dispatch, /* SetApiError */Block.__(4, ["Response body options are missing values."]));
      }
      var autoPopulateState_connectionId = match.connectionId;
      var autoPopulateState_integrationName = body.watcherName;
      var autoPopulateState_selectedAttributes = Belt_Option.getWithDefault(Belt_Option.map(match$1.displaySettings, (function (settings) {
                  return settings.attributeList;
                })), []);
      var autoPopulateState_receivers = Belt_Option.getWithDefault(match$1.receivers, []);
      var autoPopulateState = {
        connectionId: autoPopulateState_connectionId,
        integrationName: autoPopulateState_integrationName,
        advancedOptionsOpen: true,
        selectedAttributes: autoPopulateState_selectedAttributes,
        receivers: autoPopulateState_receivers
      };
      return Curry._1(dispatch, /* SetAutoPopulateState */Block.__(6, [autoPopulateState]));
    };
    var maybeTask = ApiWfIntegrations$BsConsole.QueryIntegration.queryIntegrationTask(projectName, "slack", integrationId, config);
    if (maybeTask !== undefined) {
      return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                    return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                  }));
    } else {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
    }
  };
  React.useEffect((function () {
          if (formState.tag) {
            autoPopulateForm(formState[0]);
          } else {
            Curry._1(dispatch, /* SetConnectionId */Block.__(0, [
                    formState[0],
                    formState[1]
                  ]));
          }
          
        }), ([]));
  var removeEmptyRows = function (arr) {
    return Belt_Array.keep(arr, (function (row) {
                  return row.trim() !== "";
                }));
  };
  var handleSave = function (param) {
    var onSuccess = function (param) {
      MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Created */Block.__(12, ["slack"])]));
      if (formState.tag && !formState[1]) {
        return Curry._1(handleChangeUrl, /* SettingsWfConnectionManagement */Block.__(6, [{
                        error: undefined
                      }]));
      } else {
        return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                      projectName,
                      WfRouter$BsConsole.root,
                      undefined
                    ]));
      }
    };
    var onFailure = function (err) {
      return Curry._1(dispatch, /* SetApiError */Block.__(4, [err]));
    };
    var match = integrationName.trim() !== "";
    var match$1 = removeEmptyRows(receivers).length !== 0;
    var isValid = match && match$1 ? /* IsValid */[
        true,
        ""
      ] : /* IsValid */[
        false,
        "Some form fields are invalid."
      ];
    if (!isValid[0]) {
      return Curry._1(dispatch, /* SetValidationError */Block.__(3, [isValid[1]]));
    }
    if (connectionId === undefined) {
      return Curry._1(dispatch, /* SetApiError */Block.__(4, ["Connection Id is missing."]));
    }
    if (formState.tag) {
      var maybeTask = ApiWfIntegrations$BsConsole.EditIntegration.editSlackIntegrationTask("slack", projectName, config, formState[0], integrationName, connectionId, removeEmptyRows(receivers), true, removeEmptyRows(selectedAttributes));
      if (maybeTask !== undefined) {
        return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
      } else {
        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
      }
    }
    var maybeTask$1 = ApiWfIntegrations$BsConsole.CreateIntegration.createSlackIntegrationTask("slack", projectName, config, integrationName, connectionId, removeEmptyRows(receivers), true, removeEmptyRows(selectedAttributes));
    if (maybeTask$1 !== undefined) {
      return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask$1), undefined, (function (param) {
                    return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                  }));
    } else {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
    }
  };
  var match$3 = apiErrors !== "";
  var match$4 = validationErrors !== "";
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: null
            }, match$3 ? React.createElement(WarningMessage$BsConsole.make, {
                    errorMessage: I18N$BsConsole.show(undefined, apiErrors)
                  }) : (
                match$4 ? React.createElement(WarningMessage$BsConsole.make, {
                        errorMessage: I18N$BsConsole.show(undefined, validationErrors)
                      }) : null
              ), match$2.connectionCreated ? React.createElement(SuccessMessage$BsConsole.make, {
                    successMessage: I18N$BsConsole.show(undefined, "Your connection was created successfully.")
                  }) : null, React.createElement(Paper.default, {
                  className: parentCreate,
                  children: null
                }, React.createElement("div", {
                      className: Css.merge(/* :: */[
                            title,
                            /* :: */[
                              bottomMargin,
                              /* [] */0
                            ]
                          ])
                    }, React.createElement("img", {
                          className: titleLogo,
                          src: "https://res.cloudinary.com/backtrace/image/upload/v165544361956/workflows_slack.svg"
                        }), React.createElement("p", {
                          className: titleText
                        }, I18N$BsConsole.show(undefined, "Integration for Slack"))), React.createElement(SlackIntegration$Section, {
                      children: React.createElement(SlackIntegration$IntegrationNameInput, {
                            formState: formState,
                            integrationName: integrationName,
                            handleChange: (function (name) {
                                return Curry._1(dispatch, /* SetIntegrationName */Block.__(1, [name]));
                              }),
                            isInvalid: validationErrors !== "" && integrationName === ""
                          })
                    }), React.createElement(SlackIntegration$Section, {
                      children: React.createElement(SlackIntegration$Channels, {
                            isInvalid: validationErrors !== "" && removeEmptyRows(receivers).length === 0,
                            handleAddReceiver: (function (index, newReceiver) {
                                var updatedRecievers = Belt_Array.mapWithIndex(receivers, (function (idx, reciever) {
                                        if (index === idx) {
                                          return newReceiver;
                                        } else {
                                          return reciever;
                                        }
                                      }));
                                return Curry._1(dispatch, /* SetReceivers */Block.__(2, [updatedRecievers]));
                              }),
                            handleDeleteReceiver: (function (index) {
                                var updatedRecievers = Belt_Array.keepWithIndex(receivers, (function (param, idx) {
                                        return index !== idx;
                                      }));
                                return Curry._1(dispatch, /* SetReceivers */Block.__(2, [updatedRecievers]));
                              }),
                            handleAddNewChannelBar: (function (param) {
                                var updatedRecievers = Belt_Array.concat(receivers, [""]);
                                return Curry._1(dispatch, /* SetReceivers */Block.__(2, [updatedRecievers]));
                              }),
                            receivers: receivers
                          })
                    }), React.createElement(Wf2AdvancedOptions$BsConsole.make, {
                      isOpen: match$2.advancedOptionsOpen,
                      handleToggle: (function (param) {
                          return Curry._1(dispatch, /* ToggleAdvancedOptionsOpen */0);
                        }),
                      children: null
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(25)),
                                /* [] */0
                              ]),
                          children: I18N$BsConsole.show(undefined, "Slack template")
                        }), React.createElement(Wf2MainBodyConent$BsConsole.make, {
                          attributes: attributes,
                          handleAddSelectedAttribute: (function (attribute) {
                              if (attribute !== undefined) {
                                return Curry._1(dispatch, /* SetSelectedAttributes */Block.__(5, [Belt_Array.concat(selectedAttributes, [attribute.name])]));
                              }
                              
                            }),
                          handleDeleteSelectedAttribute: (function (index) {
                              var length = selectedAttributes.length;
                              if (length <= 1) {
                                return Curry._1(dispatch, /* SetSelectedAttributes */Block.__(5, [[]]));
                              }
                              var firstHalf = Belt_Array.slice(selectedAttributes, 0, index);
                              var secondHalf = Belt_Array.sliceToEnd(selectedAttributes, index + 1 | 0);
                              var removedAttributeArray = Belt_Array.concat(firstHalf, secondHalf);
                              return Curry._1(dispatch, /* SetSelectedAttributes */Block.__(5, [removedAttributeArray]));
                            }),
                          selectedAttributes: selectedAttributes,
                          handleUpdateAttributeOrder: (function (updatedSelectionOrder) {
                              return Curry._1(dispatch, /* SetSelectedAttributes */Block.__(5, [updatedSelectionOrder]));
                            })
                        })), React.createElement("div", {
                      className: buttonContainer
                    }, React.createElement(Button.default, {
                          color: "primary",
                          onClick: handleCancel,
                          children: I18N$BsConsole.show(undefined, "Cancel")
                        }), React.createElement(Button.default, {
                          variant: "contained",
                          color: "primary",
                          className: saveButton,
                          disabled: false,
                          onClick: handleSave,
                          children: I18N$BsConsole.show(undefined, "Save")
                        }))));
}

var component = ReasonReact.statelessComponent("SlackIntegration");

function make(formState, token, handleChangeUrl, projectName, config, children) {
  return ReasonReactCompat.wrapReactForReasonReact(SlackIntegration, {
              formState: formState,
              token: token,
              handleChangeUrl: handleChangeUrl,
              projectName: projectName,
              config: config
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = SlackIntegration;

exports.Styles = Styles;
exports.Required = Required;
exports.Section = Section;
exports.SectionRow = SectionRow;
exports.SectionColumn = SectionColumn;
exports.NewChannelRowButton = NewChannelRowButton;
exports.CloseButton = CloseButton;
exports.ChannelRow = ChannelRow;
exports.Channels = Channels;
exports.IntegrationNameInput = IntegrationNameInput;
exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* parentCreate Not a pure module */
