// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Link$BsConsole = require("../Link.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var WfTasks$BsConsole = require("./WfTasks.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var WfMetrics$BsConsole = require("./WfMetrics.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var L10N_date_time$BsConsole = require("../L10N_date_time.js");
var HideFeatureGate$BsConsole = require("../HideFeatureGate.js");
var WorkflowVersion$BsConsole = require("./WorkflowVersion.js");
var WfIntegrationIcon$BsConsole = require("./WfIntegrationIcon.js");
var WfIntegrationLogs$BsConsole = require("./WfIntegrationLogs.js");

var Integration = { };

var td = Css.style(/* :: */[
      Css.paddingTop(Css.px(8)),
      /* [] */0
    ]);

var col1 = Css.style(/* :: */[
      Css.width(Css.px(60)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.alignItems(Css.center),
          /* :: */[
            Css.justifyContent(Css.center),
            /* [] */0
          ]
        ]
      ]
    ]);

var col2 = Css.style(/* :: */[
      Css.width(/* `calc */[
            -1044768619,
            /* tuple */[
              Css.sub,
              Css.pct(50),
              Css.px(145)
            ]
          ]),
      /* [] */0
    ]);

var col3 = Css.style(/* :: */[
      Css.width(/* `calc */[
            -1044768619,
            /* tuple */[
              Css.sub,
              Css.pct(50),
              Css.px(145)
            ]
          ]),
      /* [] */0
    ]);

var col4 = Css.style(/* :: */[
      Css.width(Css.px(60)),
      /* :: */[
        Css.textAlign(Css.center),
        /* [] */0
      ]
    ]);

var col5 = Css.style(/* :: */[
      Css.width(Css.px(170)),
      /* :: */[
        Css.textAlign(/* right */-379319332),
        /* [] */0
      ]
    ]);

var Styles = {
  td: td,
  col1: col1,
  col2: col2,
  col3: col3,
  col4: col4,
  col5: col5
};

var component = RR$BsConsole.reducerComponent("WfWatchersListWatcher-BsConsole");

function make(twoWaySyncStatus, token, onEditIntegration, onDeleteIntegration, expanded, onExpand, onCollapse, noBorder, integration, watcher, project, config, alerts, handleChangeUrl, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var state = param.state;
              var testResponse = state.testResponse;
              var rowHovered = state.rowHovered;
              var send = param.send;
              var match = WorkflowVersion$BsConsole.current(config);
              var tmp;
              var exit = 0;
              if (typeof match === "number" && match !== 0) {
                var match$1 = watcher.behavior;
                tmp = React.createElement("div", {
                      className: [
                          td,
                          col3
                        ].join(" ")
                    }, match$1 !== undefined ? (
                        match$1.eventType ? I18N$BsConsole.show(undefined, "Every first occurrence") : I18N$BsConsole.show(undefined, "Every occurrence")
                      ) : I18N$BsConsole.show(undefined, "Automatic notifications disabled"));
              } else {
                exit = 1;
              }
              if (exit === 1) {
                var alertsConfigured = Belt_Array.keep(alerts, (function (alert) {
                        return Belt_Array.some(alert.integrations, (function (integration) {
                                      return integration.id === Belt_Option.getWithDefault(watcher.integrationId, -1);
                                    }));
                      }));
                tmp = React.createElement("div", {
                      className: [
                          td,
                          col3
                        ].join(" ")
                    }, ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectSettingsAlerts */Block.__(27, [
                                project.name,
                                {
                                  tab: undefined,
                                  id: undefined
                                }
                              ]), undefined, handleChangeUrl, undefined, undefined, Css.style(/* :: */[
                                  Css.padding2(Css.px(4), /* zero */-789508312),
                                  /* :: */[
                                    Css.hover(/* :: */[
                                          Css.color(Css.hex(Colors$BsConsole.accent)),
                                          /* :: */[
                                            Css.selector(".link-item", /* :: */[
                                                  Css.color(Css.hex(Colors$BsConsole.blue)),
                                                  /* :: */[
                                                    Css.textDecoration(/* underline */131142924),
                                                    /* [] */0
                                                  ]
                                                ]),
                                            /* [] */0
                                          ]
                                        ]),
                                    /* [] */0
                                  ]
                                ]), [React.createElement("span", {
                                    className: "link-item"
                                  }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                            /* String_literal */Block.__(11, [
                                                "Alerts configured: ",
                                                /* String */Block.__(2, [
                                                    /* No_padding */0,
                                                    /* End_of_format */0
                                                  ])
                                              ]),
                                            "Alerts configured: %s"
                                          ]), String(alertsConfigured.length)))])));
              }
              var match$2 = WorkflowVersion$BsConsole.current(config);
              var failing;
              var exit$1 = 0;
              if (typeof match$2 === "number" && match$2 !== 0) {
                failing = Belt_Option.mapWithDefault(watcher.state__, false, (function (state) {
                        return state.failing;
                      }));
              } else {
                exit$1 = 1;
              }
              if (exit$1 === 1) {
                var status = watcher.state;
                failing = status !== undefined ? status !== "enabled" : true;
              }
              var arg = L10N_date_time$BsConsole.Format.textDay;
              var match$3 = WorkflowVersion$BsConsole.current(config);
              var tmp$1;
              if (typeof match$3 === "number") {
                if (match$3 !== 0) {
                  var match$4 = Json_decode.optional((function (param) {
                          return Json_decode.field("two_way_sync", Json_decode.bool, param);
                        }), watcher.settings);
                  if (twoWaySyncStatus !== undefined && match$4 !== undefined && match$4) {
                    var webhookStatus = twoWaySyncStatus.webhookStatus;
                    var arg$1 = L10N_date_time$BsConsole.Format.textDay;
                    tmp$1 = React.createElement(React.Fragment, undefined, webhookStatus !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Two way synchronization")]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [typeof webhookStatus === "number" ? (
                                                webhookStatus !== 0 ? I18N$BsConsole.show(undefined, "Disabled") : I18N$BsConsole.show(undefined, "Enabled")
                                              ) : Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                        /* String_literal */Block.__(11, [
                                                            "Error: ",
                                                            /* String */Block.__(2, [
                                                                /* No_padding */0,
                                                                /* End_of_format */0
                                                              ])
                                                          ]),
                                                        "Error: %s"
                                                      ]), webhookStatus[0])])))) : null, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Last webhook called at")]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(twoWaySyncStatus.lastWebhookReceived, "None", (function (eta) {
                                                  return Curry._2(arg$1, undefined, eta);
                                                })))]))));
                  } else {
                    tmp$1 = null;
                  }
                } else {
                  tmp$1 = null;
                }
              } else {
                var match$5 = watcher.state;
                var match$6 = Json_decode.optional((function (param) {
                        return Json_decode.field("two_way_sync", Json_decode.bool, param);
                      }), watcher.settings);
                if (match$5 !== undefined && match$6 !== undefined && match$6) {
                  var tmp$2;
                  switch (match$5) {
                    case "disabled" :
                        tmp$2 = I18N$BsConsole.show(undefined, "Disabled");
                        break;
                    case "enabled" :
                        tmp$2 = I18N$BsConsole.show(undefined, "Enabled");
                        break;
                    case "stopped" :
                        tmp$2 = I18N$BsConsole.show(undefined, "Stopped");
                        break;
                    default:
                      tmp$2 = I18N$BsConsole.showSkip("Error");
                  }
                  tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Two way synchronization")]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [tmp$2]))));
                } else {
                  tmp$1 = null;
                }
              }
              var tmp$3;
              if (testResponse !== undefined) {
                var tmp$4;
                tmp$4 = testResponse.tag ? React.createElement("span", {
                        style: {
                          color: Colors$BsConsole.octothorpe(Colors$BsConsole.red),
                          paddingLeft: "4px"
                        }
                      }, I18N$BsConsole.show(undefined, "Errors occured")) : React.createElement("span", {
                        style: {
                          color: Colors$BsConsole.octothorpe(Colors$BsConsole.green),
                          paddingLeft: "4px"
                        }
                      }, I18N$BsConsole.show(undefined, "No errors"));
                var tmp$5;
                tmp$5 = testResponse.tag ? React.createElement("div", {
                        style: {
                          paddingTop: "12px"
                        }
                      }, I18N$BsConsole.showSkip(testResponse[0])) : null;
                tmp$3 = React.createElement("div", {
                      style: {
                        padding: "0px 66px 16px",
                        width: "100%"
                      }
                    }, React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* :: */[
                                  Css.padding(Css.px(16)),
                                  /* :: */[
                                    Css.maxHeight(Css.px(200)),
                                    /* :: */[
                                      Css.overflowY(/* auto */-1065951377),
                                      /* :: */[
                                        Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.blue)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ])
                        }, React.createElement("div", undefined, React.createElement("span", {
                                  style: {
                                    fontWeight: "500"
                                  }
                                }, I18N$BsConsole.show(undefined, "Test results:")), tmp$4), tmp$5));
              } else {
                tmp$3 = null;
              }
              var match$7 = WorkflowVersion$BsConsole.current(config);
              return React.createElement("div", {
                          style: {
                            borderTop: noBorder ? "none" : "2px solid #dcdcdc"
                          },
                          onMouseEnter: (function (param) {
                              return Curry._1(send, /* RowHovered */0);
                            }),
                          onMouseLeave: (function (param) {
                              return Curry._1(send, /* RowUnhovered */1);
                            })
                        }, React.createElement("div", {
                              style: {
                                display: "flex",
                                alignItems: "center"
                              }
                            }, React.createElement("div", {
                                  className: [
                                      td,
                                      col1
                                    ].join(" ")
                                }, ReasonReact.element(undefined, undefined, WfIntegrationIcon$BsConsole.make(Belt_Option.flatMap(integration, (function (integration) {
                                                return integration.description.icon;
                                              })), 28, []))), React.createElement("div", {
                                  className: [
                                      td,
                                      col2
                                    ].join(" ")
                                }, I18N$BsConsole.showSkip(watcher.userFriendlyName)), tmp, React.createElement("div", {
                                  "aria-label": watcher.userFriendlyName + (" integration is " + (
                                      failing ? "disabled" : "enabled"
                                    )),
                                  className: [
                                      td,
                                      col4
                                    ].join(" "),
                                  style: {
                                    color: failing ? Colors$BsConsole.octothorpe(Colors$BsConsole.orange) : Colors$BsConsole.octothorpe(Colors$BsConsole.blue)
                                  }
                                }, failing ? ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.$$Error.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])) : ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.CheckCircle.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), React.createElement("div", {
                                  className: [
                                      td,
                                      col5
                                    ].join(" ")
                                }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                          undefined,
                                          {
                                            visibility: Belt_Option.isSome(integration) && (rowHovered || expanded) ? "visible" : "hidden"
                                          },
                                          (function (param) {
                                              var match = watcher.type_;
                                              if (match !== undefined) {
                                                switch (match) {
                                                  case "jira" :
                                                      MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Edited */Block.__(13, ["jiraCloud"])]));
                                                      break;
                                                  case "jira-server" :
                                                      MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Edited */Block.__(13, ["jiraServer"])]));
                                                      break;
                                                  case "slack" :
                                                      MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Integration_Edited */Block.__(13, ["slack"])]));
                                                      break;
                                                  default:
                                                    
                                                }
                                              }
                                              return Curry._1(onEditIntegration, watcher);
                                            }),
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          "Edit " + (Belt_Option.getWithDefault(watcher.type_, "") + (" integration named " + watcher.userFriendlyName)),
                                          [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Edit.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                        ])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                          undefined,
                                          {
                                            visibility: Belt_Option.isSome(integration) && (rowHovered || expanded) ? "visible" : "hidden"
                                          },
                                          (function (param) {
                                              return Curry._1(onDeleteIntegration, watcher);
                                            }),
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          "Delete " + (Belt_Option.getWithDefault(watcher.type_, "") + (" integration named " + watcher.userFriendlyName)),
                                          [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Delete.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                        ])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                          undefined,
                                          undefined,
                                          (function (param) {
                                              if (expanded) {
                                                return Curry._1(onCollapse, undefined);
                                              } else {
                                                return Curry._1(onExpand, undefined);
                                              }
                                            }),
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          expanded ? "Collapse " + (watcher.userFriendlyName + "'s dropdown") : "Expand " + (watcher.userFriendlyName + "'s dropdown"),
                                          [React.createElement("div", {
                                                  style: {
                                                    display: "flex",
                                                    transition: "transform 0.25s ease-in-out",
                                                    transform: "rotate(" + ((
                                                        expanded ? "180deg" : " 0deg"
                                                      ) + ")")
                                                  }
                                                }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.KeyboardArrowDown.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))]
                                        ])))), ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Collapse.Jsx2.make, undefined, undefined, expanded, /* `Int */[
                                  3654863,
                                  250
                                ], [
                                  React.createElement("div", {
                                        style: {
                                          backgroundColor: Colors$BsConsole.octothorpe(Colors$BsConsole.grey740)
                                        }
                                      }, React.createElement("div", {
                                            style: {
                                              display: "flex",
                                              padding: "16px",
                                              justifyContent: "space-between"
                                            }
                                          }, React.createElement("div", {
                                                style: {
                                                  display: "flex"
                                                }
                                              }, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Details")]))), React.createElement("div", {
                                                    className: Css.style(/* :: */[
                                                          Css.display(/* grid */-999565626),
                                                          /* :: */[
                                                            Css.gridTemplateColumns(/* :: */[
                                                                  Css.auto,
                                                                  /* :: */[
                                                                    Css.auto,
                                                                    /* [] */0
                                                                  ]
                                                                ]),
                                                            /* :: */[
                                                              Css.paddingLeft(Css.px(24)),
                                                              /* :: */[
                                                                Css.gridRowGap(Css.px(8)),
                                                                /* :: */[
                                                                  Css.gridColumnGap(Css.px(16)),
                                                                  /* :: */[
                                                                    Css.paddingTop(Css.px(2)),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ])
                                                  }, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Last activity")]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(Belt_Option.flatMap(watcher.state__, (function (state) {
                                                                                return state.lastActivity;
                                                                              })), "None", (function (eta) {
                                                                            return Curry._2(arg, undefined, eta);
                                                                          })))]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Successes")]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(String(Belt_Option.getWithDefault(Belt_Option.flatMap(watcher.state__, (function (state) {
                                                                                    return state.successes;
                                                                                  })), 0)))]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Failures")]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(watcher.state__, "0", (function (state) {
                                                                            return String(state.failures);
                                                                          })))]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Pending actions")]))), React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(watcher.state__, "0", (function (state) {
                                                                            return String(state.pending);
                                                                          })))]))), tmp$1)), React.createElement("div", undefined, Belt_Option.mapWithDefault(integration, false, (function (integration) {
                                                      return Belt_Option.isSome(integration.supportedActions);
                                                    })) ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                          undefined,
                                                          undefined,
                                                          (function (param) {
                                                              return Curry._1(send, /* TestIntegration */2);
                                                            }),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          /* Secondary */67972948,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [I18N$BsConsole.show(undefined, "Test integration")]
                                                        ])) : null)), tmp$3),
                                  ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(typeof match$7 === "number" ? true : false, [ReasonReact.element(undefined, undefined, WfIntegrationLogs$BsConsole.Jsx2.make(expanded, token, config, watcher.integrationId, project.name)([]))]))
                                ])));
            }),
          initialState: (function (param) {
              return {
                      rowHovered: false,
                      testResponse: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action !== "number") {
                return /* Update */Block.__(0, [{
                            rowHovered: state.rowHovered,
                            testResponse: action[0]
                          }]);
              }
              switch (action) {
                case /* RowHovered */0 :
                    return /* Update */Block.__(0, [{
                                rowHovered: true,
                                testResponse: state.testResponse
                              }]);
                case /* RowUnhovered */1 :
                    return /* Update */Block.__(0, [{
                                rowHovered: false,
                                testResponse: state.testResponse
                              }]);
                case /* TestIntegration */2 :
                    return /* SideEffects */Block.__(1, [(function (param) {
                                  var send = param.send;
                                  if (integration !== undefined) {
                                    WfMetrics$BsConsole.send(/* IntegrationTested */Block.__(0, [
                                            integration,
                                            watcher
                                          ]));
                                  }
                                  var maybeTask = WfTasks$BsConsole.testWorkflow(project.name, watcher.watcherName, config, undefined);
                                  if (maybeTask !== undefined) {
                                    return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (resp) {
                                                  if (!resp.tag) {
                                                    return Curry._1(send, /* SetTestResponse */[/* Ok */Block.__(0, [undefined])]);
                                                  }
                                                  var message = resp[0];
                                                  if (message[0] === Task2$BsConsole.CoronerError) {
                                                    return Curry._1(send, /* SetTestResponse */[/* Error */Block.__(1, [message[1].message])]);
                                                  } else if (message[0] === WfTasks$BsConsole.ServiceError) {
                                                    return Curry._1(send, /* SetTestResponse */[/* Error */Block.__(1, [message[1]])]);
                                                  } else {
                                                    return Curry._1(send, /* SetTestResponse */[/* Error */Block.__(1, [I18N$BsConsole.get(undefined, "Unknown error has occured")])]);
                                                  }
                                                }));
                                  } else {
                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                  }
                                })]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Integration = Integration;
exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* td Not a pure module */
