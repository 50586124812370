// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Form$BsConsole = require("./Form.js");
var Task$BsConsole = require("./task.js");
var Bpg_response$BsConsole = require("./bpg_response.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var BacktraceParserGenerator$BsConsole = require("./BacktraceParserGenerator.js");

function MakeCreate(F) {
  var R = Bpg_response$BsConsole.MakeCreate(F);
  var decodeBPG = function (json) {
    return Belt_List.head(Json_decode.field("results", (function (param) {
                      return Json_decode.list((function (param) {
                                    return Json_decode.tuple2((function (param) {
                                                  return Json_decode.field("code", Json_decode.$$int, param);
                                                }), (function (param) {
                                                  return Json_decode.field("text", Json_decode.string, param);
                                                }), param);
                                  }), param);
                    }), json));
  };
  var component = ReasonReact.reducerComponent("BpgController-BsConsole");
  var make = function (onSuccess, handleTask, token, children) {
    return {
            debugName: component.debugName,
            reactClassInternal: component.reactClassInternal,
            handedOffState: component.handedOffState,
            willReceiveProps: component.willReceiveProps,
            didMount: component.didMount,
            didUpdate: component.didUpdate,
            willUnmount: component.willUnmount,
            willUpdate: component.willUpdate,
            shouldUpdate: component.shouldUpdate,
            render: (function (self) {
                var handleSubmit = function (param) {
                  return Curry._1(self.send, /* Request */Block.__(0, [
                                param[0],
                                param[1],
                                param[2],
                                param[3],
                                param[4]
                              ]));
                };
                return Curry._3(children, self.state, handleSubmit, F.to_key);
              }),
            initialState: (function (param) {
                return {
                        remote: /* NotAsked */0
                      };
              }),
            retainedProps: component.retainedProps,
            reducer: (function (action, _state) {
                switch (action.tag | 0) {
                  case /* Request */0 :
                      var updater = action[1];
                      var entity = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  remote: /* Loading */1
                                },
                                (function (self) {
                                    var json = Json_encode.object_(/* :: */[
                                          /* tuple */[
                                            "type",
                                            "configuration/" + F.table
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "action",
                                              "create"
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "object",
                                                Curry._1(F.to_json, entity)
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]);
                                    return Curry._2(handleTask, (function (json) {
                                                  var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                  var match = Belt_Option.mapWithDefault(__x, R.defaultError, R.decode);
                                                  if (match.tag) {
                                                    return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                  match[0],
                                                                  match[1]
                                                                ]));
                                                  }
                                                  var result = match[1];
                                                  if (result !== undefined) {
                                                    return Curry._1(self.send, /* Success */Block.__(1, [
                                                                  match[0],
                                                                  Caml_option.valFromOption(result),
                                                                  Curry._1(updater, entity)
                                                                ]));
                                                  } else {
                                                    console.log("INVARIANT");
                                                    return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                  "Something went wrong",
                                                                  undefined
                                                                ]));
                                                  }
                                                }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                      token,
                                                      /* :: */[
                                                        json,
                                                        /* [] */0
                                                      ]
                                                    ])));
                                  })
                              ]);
                  case /* Success */1 :
                      var updated = action[2];
                      var result = action[1];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  remote: /* Success */Block.__(0, [result])
                                },
                                (function (_self) {
                                    return Curry._2(onSuccess, result, updated);
                                  })
                              ]);
                  case /* Failure */2 :
                      return /* Update */Block.__(0, [{
                                  remote: /* Failure */Block.__(1, [action[0]])
                                }]);
                  
                }
              }),
            jsElementWrapped: component.jsElementWrapped
          };
  };
  return {
          R: R,
          decodeBPG: decodeBPG,
          component: component,
          make: make
        };
}

function MakeGet(F) {
  var R = Bpg_response$BsConsole.Make(F);
  var decodeBPG = function (json) {
    return Belt_List.head(Json_decode.field("results", (function (param) {
                      return Json_decode.list((function (param) {
                                    return Json_decode.tuple2((function (param) {
                                                  return Json_decode.field("code", Json_decode.$$int, param);
                                                }), (function (param) {
                                                  return Json_decode.field("text", Json_decode.string, param);
                                                }), param);
                                  }), param);
                    }), json));
  };
  var component = ReasonReact.reducerComponent("BpgController-BsConsole" + (F.table + "get"));
  var make = function (handleTask, token, filter, children) {
    return {
            debugName: component.debugName,
            reactClassInternal: component.reactClassInternal,
            handedOffState: component.handedOffState,
            willReceiveProps: component.willReceiveProps,
            didMount: (function (self) {
                return Curry._1(self.send, /* Request */0);
              }),
            didUpdate: component.didUpdate,
            willUnmount: component.willUnmount,
            willUpdate: component.willUpdate,
            shouldUpdate: component.shouldUpdate,
            render: (function (self) {
                var update = function (param) {
                  return Curry._1(self.send, /* Update */1);
                };
                return Curry._2(children, self.state, update);
              }),
            initialState: (function (param) {
                return {
                        remote: /* NotAsked */0
                      };
              }),
            retainedProps: component.retainedProps,
            reducer: (function (action, _state) {
                if (typeof action === "number") {
                  if (action === /* Request */0) {
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + F.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "get"
                                          ],
                                          /* [] */0
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R.defaultError, R.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(1, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(0, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                  } else {
                    return /* SideEffects */Block.__(1, [(function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + F.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "get"
                                          ],
                                          /* [] */0
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R.defaultError, R.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })]);
                  }
                }
                switch (action.tag | 0) {
                  case /* Success */0 :
                      var result = action[1];
                      return /* Update */Block.__(0, [{
                                  remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                              return Curry._1(filter, result);
                                            }))])
                                }]);
                  case /* UpdateSuccess */2 :
                      var result$1 = action[1];
                      return /* Update */Block.__(0, [{
                                  remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                              return Curry._1(filter, result$1);
                                            }))])
                                }]);
                  case /* Failure */1 :
                  case /* UpdateFailure */3 :
                      break;
                  
                }
                return /* Update */Block.__(0, [{
                            remote: /* Failure */Block.__(1, [action[0]])
                          }]);
              }),
            jsElementWrapped: component.jsElementWrapped
          };
  };
  return {
          R: R,
          decodeBPG: decodeBPG,
          component: component,
          make: make
        };
}

function MakeModify(F) {
  var R = Bpg_response$BsConsole.Make(F);
  var decodeBPG = function (json) {
    return Belt_List.head(Json_decode.field("results", (function (param) {
                      return Json_decode.list((function (param) {
                                    return Json_decode.tuple2((function (param) {
                                                  return Json_decode.field("code", Json_decode.$$int, param);
                                                }), (function (param) {
                                                  return Json_decode.field("text", Json_decode.string, param);
                                                }), param);
                                  }), param);
                    }), json));
  };
  var component = ReasonReact.reducerComponent("BpgController-BsConsole");
  var make = function (onSuccess, handleTask, token, children) {
    return {
            debugName: component.debugName,
            reactClassInternal: component.reactClassInternal,
            handedOffState: component.handedOffState,
            willReceiveProps: component.willReceiveProps,
            didMount: component.didMount,
            didUpdate: component.didUpdate,
            willUnmount: component.willUnmount,
            willUpdate: component.willUpdate,
            shouldUpdate: component.shouldUpdate,
            render: (function (self) {
                var handleSubmit = function (param) {
                  return Curry._1(self.send, /* Request */Block.__(0, [
                                param[0],
                                param[1],
                                param[2],
                                param[3],
                                param[4],
                                param[5]
                              ]));
                };
                return Curry._3(children, self.state, handleSubmit, F.to_key);
              }),
            initialState: (function (param) {
                return {
                        remote: /* NotAsked */0
                      };
              }),
            retainedProps: component.retainedProps,
            reducer: (function (action, _state) {
                switch (action.tag | 0) {
                  case /* Request */0 :
                      var fields = action[2];
                      var updater = action[1];
                      var entity = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  remote: /* Loading */1
                                },
                                (function (self) {
                                    var json = Json_encode.object_(/* :: */[
                                          /* tuple */[
                                            "type",
                                            "configuration/" + F.table
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "action",
                                              "modify"
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "key",
                                                Curry._1(F.Key.to_json, Curry._1(F.to_key, entity))
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  "fields",
                                                  Json_encode.object_(List.map((function (param) {
                                                              var v = param[1];
                                                              var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                              return /* tuple */[
                                                                      param[0],
                                                                      tmp
                                                                    ];
                                                            }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                                ],
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]);
                                    return Curry._2(handleTask, (function (json) {
                                                  var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                  var match = Belt_Option.mapWithDefault(__x, R.defaultError, R.decode);
                                                  if (match.tag) {
                                                    return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                  match[0],
                                                                  match[1]
                                                                ]));
                                                  } else {
                                                    return Curry._1(self.send, /* Success */Block.__(1, [
                                                                  match[0],
                                                                  match[1],
                                                                  Curry._1(updater, entity)
                                                                ]));
                                                  }
                                                }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                      token,
                                                      /* :: */[
                                                        json,
                                                        /* [] */0
                                                      ]
                                                    ])));
                                  })
                              ]);
                  case /* Success */1 :
                      var updated = action[2];
                      var result = action[1];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  remote: /* NotAsked */0
                                },
                                (function (_self) {
                                    return Curry._2(onSuccess, result, updated);
                                  })
                              ]);
                  case /* Failure */2 :
                      return /* Update */Block.__(0, [{
                                  remote: /* Failure */Block.__(1, [action[0]])
                                }]);
                  
                }
              }),
            jsElementWrapped: component.jsElementWrapped
          };
  };
  return {
          R: R,
          decodeBPG: decodeBPG,
          component: component,
          make: make
        };
}

function MakeDelete(F) {
  var R = Bpg_response$BsConsole.Make(F);
  var decodeBPG = function (json) {
    return Belt_List.head(Json_decode.field("results", (function (param) {
                      return Json_decode.list((function (param) {
                                    return Json_decode.tuple2((function (param) {
                                                  return Json_decode.field("code", Json_decode.$$int, param);
                                                }), (function (param) {
                                                  return Json_decode.field("text", Json_decode.string, param);
                                                }), param);
                                  }), param);
                    }), json));
  };
  var component = ReasonReact.reducerComponent("BpgController-BsConsole");
  var make = function (onSuccess, handleTask, token, children) {
    return {
            debugName: component.debugName,
            reactClassInternal: component.reactClassInternal,
            handedOffState: component.handedOffState,
            willReceiveProps: component.willReceiveProps,
            didMount: component.didMount,
            didUpdate: component.didUpdate,
            willUnmount: component.willUnmount,
            willUpdate: component.willUpdate,
            shouldUpdate: component.shouldUpdate,
            render: (function (self) {
                var handleSubmit = function (param) {
                  return Curry._1(self.send, /* Request */Block.__(0, [
                                param[0],
                                param[1]
                              ]));
                };
                return Curry._3(children, self.state, handleSubmit, F.to_key);
              }),
            initialState: (function (param) {
                return {
                        remote: /* NotAsked */0
                      };
              }),
            retainedProps: component.retainedProps,
            reducer: (function (action, _state) {
                switch (action.tag | 0) {
                  case /* Request */0 :
                      var updater = action[1];
                      var entity = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  remote: /* Loading */1
                                },
                                (function (self) {
                                    var json = Json_encode.object_(/* :: */[
                                          /* tuple */[
                                            "type",
                                            "configuration/" + F.table
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "action",
                                              "delete"
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "key",
                                                Curry._1(F.Key.to_json, Curry._1(F.to_key, entity))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]);
                                    return Curry._2(handleTask, (function (json) {
                                                  var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                  var match = Belt_Option.mapWithDefault(__x, R.defaultError, R.decode);
                                                  if (match.tag) {
                                                    return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                  match[0],
                                                                  match[1]
                                                                ]));
                                                  } else {
                                                    return Curry._1(self.send, /* Success */Block.__(1, [
                                                                  match[0],
                                                                  match[1],
                                                                  Curry._1(updater, entity)
                                                                ]));
                                                  }
                                                }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                      token,
                                                      /* :: */[
                                                        json,
                                                        /* [] */0
                                                      ]
                                                    ])));
                                  })
                              ]);
                  case /* Success */1 :
                      var updated = action[2];
                      var result = action[1];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  remote: /* NotAsked */0
                                },
                                (function (_self) {
                                    return Curry._2(onSuccess, result, updated);
                                  })
                              ]);
                  case /* Failure */2 :
                      return /* Update */Block.__(0, [{
                                  remote: /* Failure */Block.__(1, [action[0]])
                                }]);
                  
                }
              }),
            jsElementWrapped: component.jsElementWrapped
          };
  };
  return {
          R: R,
          decodeBPG: decodeBPG,
          component: component,
          make: make
        };
}

function Make(F) {
  var R = Bpg_response$BsConsole.Make(F);
  var decodeBPG = function (json) {
    return Belt_List.head(Json_decode.field("results", (function (param) {
                      return Json_decode.list((function (param) {
                                    return Json_decode.tuple2((function (param) {
                                                  return Json_decode.field("code", Json_decode.$$int, param);
                                                }), (function (param) {
                                                  return Json_decode.field("text", Json_decode.string, param);
                                                }), param);
                                  }), param);
                    }), json));
  };
  var component = ReasonReact.reducerComponent("BpgController-BsConsole" + (F.table + "get"));
  var make = function (handleTask, token, filter, children) {
    return {
            debugName: component.debugName,
            reactClassInternal: component.reactClassInternal,
            handedOffState: component.handedOffState,
            willReceiveProps: component.willReceiveProps,
            didMount: (function (self) {
                return Curry._1(self.send, /* Request */0);
              }),
            didUpdate: component.didUpdate,
            willUnmount: component.willUnmount,
            willUpdate: component.willUpdate,
            shouldUpdate: component.shouldUpdate,
            render: (function (self) {
                var update = function (param) {
                  return Curry._1(self.send, /* Update */1);
                };
                return Curry._2(children, self.state, update);
              }),
            initialState: (function (param) {
                return {
                        remote: /* NotAsked */0
                      };
              }),
            retainedProps: component.retainedProps,
            reducer: (function (action, _state) {
                if (typeof action === "number") {
                  if (action === /* Request */0) {
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + F.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "get"
                                          ],
                                          /* [] */0
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R.defaultError, R.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(1, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(0, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                  } else {
                    return /* SideEffects */Block.__(1, [(function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + F.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "get"
                                          ],
                                          /* [] */0
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R.defaultError, R.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })]);
                  }
                }
                switch (action.tag | 0) {
                  case /* Success */0 :
                      var result = action[1];
                      return /* Update */Block.__(0, [{
                                  remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                              return Curry._1(filter, result);
                                            }))])
                                }]);
                  case /* UpdateSuccess */2 :
                      var result$1 = action[1];
                      return /* Update */Block.__(0, [{
                                  remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                              return Curry._1(filter, result$1);
                                            }))])
                                }]);
                  case /* Failure */1 :
                  case /* UpdateFailure */3 :
                      break;
                  
                }
                return /* Update */Block.__(0, [{
                            remote: /* Failure */Block.__(1, [action[0]])
                          }]);
              }),
            jsElementWrapped: component.jsElementWrapped
          };
  };
  var Get = {
    R: R,
    decodeBPG: decodeBPG,
    component: component,
    make: make
  };
  var R$1 = Bpg_response$BsConsole.Make(F);
  var decodeBPG$1 = function (json) {
    return Belt_List.head(Json_decode.field("results", (function (param) {
                      return Json_decode.list((function (param) {
                                    return Json_decode.tuple2((function (param) {
                                                  return Json_decode.field("code", Json_decode.$$int, param);
                                                }), (function (param) {
                                                  return Json_decode.field("text", Json_decode.string, param);
                                                }), param);
                                  }), param);
                    }), json));
  };
  var component$1 = ReasonReact.reducerComponent("BpgController-BsConsole");
  var make$1 = function (onSuccess, handleTask, token, children) {
    return {
            debugName: component$1.debugName,
            reactClassInternal: component$1.reactClassInternal,
            handedOffState: component$1.handedOffState,
            willReceiveProps: component$1.willReceiveProps,
            didMount: component$1.didMount,
            didUpdate: component$1.didUpdate,
            willUnmount: component$1.willUnmount,
            willUpdate: component$1.willUpdate,
            shouldUpdate: component$1.shouldUpdate,
            render: (function (self) {
                var handleSubmit = function (param) {
                  return Curry._1(self.send, /* Request */Block.__(0, [
                                param[0],
                                param[1],
                                param[2],
                                param[3],
                                param[4],
                                param[5]
                              ]));
                };
                return Curry._3(children, self.state, handleSubmit, F.to_key);
              }),
            initialState: (function (param) {
                return {
                        remote: /* NotAsked */0
                      };
              }),
            retainedProps: component$1.retainedProps,
            reducer: (function (action, _state) {
                switch (action.tag | 0) {
                  case /* Request */0 :
                      var fields = action[2];
                      var updater = action[1];
                      var entity = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  remote: /* Loading */1
                                },
                                (function (self) {
                                    var json = Json_encode.object_(/* :: */[
                                          /* tuple */[
                                            "type",
                                            "configuration/" + F.table
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "action",
                                              "modify"
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "key",
                                                Curry._1(F.Key.to_json, Curry._1(F.to_key, entity))
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  "fields",
                                                  Json_encode.object_(List.map((function (param) {
                                                              var v = param[1];
                                                              var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                              return /* tuple */[
                                                                      param[0],
                                                                      tmp
                                                                    ];
                                                            }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                                ],
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]);
                                    return Curry._2(handleTask, (function (json) {
                                                  var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                  var match = Belt_Option.mapWithDefault(__x, R$1.defaultError, R$1.decode);
                                                  if (match.tag) {
                                                    return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                  match[0],
                                                                  match[1]
                                                                ]));
                                                  } else {
                                                    return Curry._1(self.send, /* Success */Block.__(1, [
                                                                  match[0],
                                                                  match[1],
                                                                  Curry._1(updater, entity)
                                                                ]));
                                                  }
                                                }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                      token,
                                                      /* :: */[
                                                        json,
                                                        /* [] */0
                                                      ]
                                                    ])));
                                  })
                              ]);
                  case /* Success */1 :
                      var updated = action[2];
                      var result = action[1];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  remote: /* NotAsked */0
                                },
                                (function (_self) {
                                    return Curry._2(onSuccess, result, updated);
                                  })
                              ]);
                  case /* Failure */2 :
                      return /* Update */Block.__(0, [{
                                  remote: /* Failure */Block.__(1, [action[0]])
                                }]);
                  
                }
              }),
            jsElementWrapped: component$1.jsElementWrapped
          };
  };
  var Modify = {
    R: R$1,
    decodeBPG: decodeBPG$1,
    component: component$1,
    make: make$1
  };
  var R$2 = Bpg_response$BsConsole.MakeCreate(F);
  var decodeBPG$2 = function (json) {
    return Belt_List.head(Json_decode.field("results", (function (param) {
                      return Json_decode.list((function (param) {
                                    return Json_decode.tuple2((function (param) {
                                                  return Json_decode.field("code", Json_decode.$$int, param);
                                                }), (function (param) {
                                                  return Json_decode.field("text", Json_decode.string, param);
                                                }), param);
                                  }), param);
                    }), json));
  };
  var component$2 = ReasonReact.reducerComponent("BpgController-BsConsole");
  var make$2 = function (onSuccess, handleTask, token, children) {
    return {
            debugName: component$2.debugName,
            reactClassInternal: component$2.reactClassInternal,
            handedOffState: component$2.handedOffState,
            willReceiveProps: component$2.willReceiveProps,
            didMount: component$2.didMount,
            didUpdate: component$2.didUpdate,
            willUnmount: component$2.willUnmount,
            willUpdate: component$2.willUpdate,
            shouldUpdate: component$2.shouldUpdate,
            render: (function (self) {
                var handleSubmit = function (param) {
                  return Curry._1(self.send, /* Request */Block.__(0, [
                                param[0],
                                param[1],
                                param[2],
                                param[3],
                                param[4]
                              ]));
                };
                return Curry._3(children, self.state, handleSubmit, F.to_key);
              }),
            initialState: (function (param) {
                return {
                        remote: /* NotAsked */0
                      };
              }),
            retainedProps: component$2.retainedProps,
            reducer: (function (action, _state) {
                switch (action.tag | 0) {
                  case /* Request */0 :
                      var updater = action[1];
                      var entity = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  remote: /* Loading */1
                                },
                                (function (self) {
                                    var json = Json_encode.object_(/* :: */[
                                          /* tuple */[
                                            "type",
                                            "configuration/" + F.table
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "action",
                                              "create"
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "object",
                                                Curry._1(F.to_json, entity)
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]);
                                    return Curry._2(handleTask, (function (json) {
                                                  var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                  var match = Belt_Option.mapWithDefault(__x, R$2.defaultError, R$2.decode);
                                                  if (match.tag) {
                                                    return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                  match[0],
                                                                  match[1]
                                                                ]));
                                                  }
                                                  var result = match[1];
                                                  if (result !== undefined) {
                                                    return Curry._1(self.send, /* Success */Block.__(1, [
                                                                  match[0],
                                                                  Caml_option.valFromOption(result),
                                                                  Curry._1(updater, entity)
                                                                ]));
                                                  } else {
                                                    console.log("INVARIANT");
                                                    return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                  "Something went wrong",
                                                                  undefined
                                                                ]));
                                                  }
                                                }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                      token,
                                                      /* :: */[
                                                        json,
                                                        /* [] */0
                                                      ]
                                                    ])));
                                  })
                              ]);
                  case /* Success */1 :
                      var updated = action[2];
                      var result = action[1];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  remote: /* Success */Block.__(0, [result])
                                },
                                (function (_self) {
                                    return Curry._2(onSuccess, result, updated);
                                  })
                              ]);
                  case /* Failure */2 :
                      return /* Update */Block.__(0, [{
                                  remote: /* Failure */Block.__(1, [action[0]])
                                }]);
                  
                }
              }),
            jsElementWrapped: component$2.jsElementWrapped
          };
  };
  var Create = {
    R: R$2,
    decodeBPG: decodeBPG$2,
    component: component$2,
    make: make$2
  };
  var R$3 = Bpg_response$BsConsole.Make(F);
  var decodeBPG$3 = function (json) {
    return Belt_List.head(Json_decode.field("results", (function (param) {
                      return Json_decode.list((function (param) {
                                    return Json_decode.tuple2((function (param) {
                                                  return Json_decode.field("code", Json_decode.$$int, param);
                                                }), (function (param) {
                                                  return Json_decode.field("text", Json_decode.string, param);
                                                }), param);
                                  }), param);
                    }), json));
  };
  var component$3 = ReasonReact.reducerComponent("BpgController-BsConsole");
  var make$3 = function (onSuccess, handleTask, token, children) {
    return {
            debugName: component$3.debugName,
            reactClassInternal: component$3.reactClassInternal,
            handedOffState: component$3.handedOffState,
            willReceiveProps: component$3.willReceiveProps,
            didMount: component$3.didMount,
            didUpdate: component$3.didUpdate,
            willUnmount: component$3.willUnmount,
            willUpdate: component$3.willUpdate,
            shouldUpdate: component$3.shouldUpdate,
            render: (function (self) {
                var handleSubmit = function (param) {
                  return Curry._1(self.send, /* Request */Block.__(0, [
                                param[0],
                                param[1]
                              ]));
                };
                return Curry._3(children, self.state, handleSubmit, F.to_key);
              }),
            initialState: (function (param) {
                return {
                        remote: /* NotAsked */0
                      };
              }),
            retainedProps: component$3.retainedProps,
            reducer: (function (action, _state) {
                switch (action.tag | 0) {
                  case /* Request */0 :
                      var updater = action[1];
                      var entity = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  remote: /* Loading */1
                                },
                                (function (self) {
                                    var json = Json_encode.object_(/* :: */[
                                          /* tuple */[
                                            "type",
                                            "configuration/" + F.table
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "action",
                                              "delete"
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "key",
                                                Curry._1(F.Key.to_json, Curry._1(F.to_key, entity))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]);
                                    return Curry._2(handleTask, (function (json) {
                                                  var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                  var match = Belt_Option.mapWithDefault(__x, R$3.defaultError, R$3.decode);
                                                  if (match.tag) {
                                                    return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                  match[0],
                                                                  match[1]
                                                                ]));
                                                  } else {
                                                    return Curry._1(self.send, /* Success */Block.__(1, [
                                                                  match[0],
                                                                  match[1],
                                                                  Curry._1(updater, entity)
                                                                ]));
                                                  }
                                                }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                      token,
                                                      /* :: */[
                                                        json,
                                                        /* [] */0
                                                      ]
                                                    ])));
                                  })
                              ]);
                  case /* Success */1 :
                      var updated = action[2];
                      var result = action[1];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  remote: /* NotAsked */0
                                },
                                (function (_self) {
                                    return Curry._2(onSuccess, result, updated);
                                  })
                              ]);
                  case /* Failure */2 :
                      return /* Update */Block.__(0, [{
                                  remote: /* Failure */Block.__(1, [action[0]])
                                }]);
                  
                }
              }),
            jsElementWrapped: component$3.jsElementWrapped
          };
  };
  var Delete = {
    R: R$3,
    decodeBPG: decodeBPG$3,
    component: component$3,
    make: make$3
  };
  return {
          Get: Get,
          Modify: Modify,
          Create: Create,
          Delete: Delete
        };
}

var F_table = BacktraceParserGenerator$BsConsole.Users.table;

var F_to_json = BacktraceParserGenerator$BsConsole.Users.to_json;

var F_of_json = BacktraceParserGenerator$BsConsole.Users.of_json;

var F_Key = BacktraceParserGenerator$BsConsole.Users.Key;

var F_to_key = BacktraceParserGenerator$BsConsole.Users.to_key;

var F = {
  table: F_table,
  to_json: F_to_json,
  of_json: F_of_json,
  Key: F_Key,
  to_key: F_to_key
};

var R = Bpg_response$BsConsole.Make(F);

function decodeBPG(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.Users.table + "get"));

function make(handleTask, token, filter, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Users.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R.defaultError, R.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Users.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R.defaultError, R.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var Get = {
  R: R,
  decodeBPG: decodeBPG,
  component: component,
  make: make
};

var R$1 = Bpg_response$BsConsole.Make(F);

function decodeBPG$1(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$1 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$1(onSuccess, handleTask, token, children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Users.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Users.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Users.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Users.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$1.defaultError, R$1.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var Modify = {
  R: R$1,
  decodeBPG: decodeBPG$1,
  component: component$1,
  make: make$1
};

var R$2 = Bpg_response$BsConsole.MakeCreate(F);

function decodeBPG$2(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$2 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$2(onSuccess, handleTask, token, children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Users.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$2.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Users.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Users.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$2.defaultError, R$2.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var Create = {
  R: R$2,
  decodeBPG: decodeBPG$2,
  component: component$2,
  make: make$2
};

var R$3 = Bpg_response$BsConsole.Make(F);

function decodeBPG$3(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$3 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$3(onSuccess, handleTask, token, children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: component$3.didMount,
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Users.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$3.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Users.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Users.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Users.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$3.defaultError, R$3.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$3.jsElementWrapped
        };
}

var Delete = {
  R: R$3,
  decodeBPG: decodeBPG$3,
  component: component$3,
  make: make$3
};

var User = {
  Get: Get,
  Modify: Modify,
  Create: Create,
  Delete: Delete
};

var F_table$1 = BacktraceParserGenerator$BsConsole.SignupWhitelist.table;

var F_to_json$1 = BacktraceParserGenerator$BsConsole.SignupWhitelist.to_json;

var F_of_json$1 = BacktraceParserGenerator$BsConsole.SignupWhitelist.of_json;

var F_Key$1 = BacktraceParserGenerator$BsConsole.SignupWhitelist.Key;

var F_to_key$1 = BacktraceParserGenerator$BsConsole.SignupWhitelist.to_key;

var F$1 = {
  table: F_table$1,
  to_json: F_to_json$1,
  of_json: F_of_json$1,
  Key: F_Key$1,
  to_key: F_to_key$1
};

var R$4 = Bpg_response$BsConsole.Make(F$1);

function decodeBPG$4(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$4 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.SignupWhitelist.table + "get"));

function make$4(handleTask, token, filter, children) {
  return {
          debugName: component$4.debugName,
          reactClassInternal: component$4.reactClassInternal,
          handedOffState: component$4.handedOffState,
          willReceiveProps: component$4.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$4.didUpdate,
          willUnmount: component$4.willUnmount,
          willUpdate: component$4.willUpdate,
          shouldUpdate: component$4.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$4.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.SignupWhitelist.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$4.defaultError, R$4.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.SignupWhitelist.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$4.defaultError, R$4.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$4.jsElementWrapped
        };
}

var Get$1 = {
  R: R$4,
  decodeBPG: decodeBPG$4,
  component: component$4,
  make: make$4
};

var R$5 = Bpg_response$BsConsole.Make(F$1);

function decodeBPG$5(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$5 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$5(onSuccess, handleTask, token, children) {
  return {
          debugName: component$5.debugName,
          reactClassInternal: component$5.reactClassInternal,
          handedOffState: component$5.handedOffState,
          willReceiveProps: component$5.willReceiveProps,
          didMount: component$5.didMount,
          didUpdate: component$5.didUpdate,
          willUnmount: component$5.willUnmount,
          willUpdate: component$5.willUpdate,
          shouldUpdate: component$5.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.SignupWhitelist.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$5.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.SignupWhitelist.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.SignupWhitelist.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.SignupWhitelist.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$5.defaultError, R$5.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$5.jsElementWrapped
        };
}

var Modify$1 = {
  R: R$5,
  decodeBPG: decodeBPG$5,
  component: component$5,
  make: make$5
};

var R$6 = Bpg_response$BsConsole.MakeCreate(F$1);

function decodeBPG$6(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$6 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$6(onSuccess, handleTask, token, children) {
  return {
          debugName: component$6.debugName,
          reactClassInternal: component$6.reactClassInternal,
          handedOffState: component$6.handedOffState,
          willReceiveProps: component$6.willReceiveProps,
          didMount: component$6.didMount,
          didUpdate: component$6.didUpdate,
          willUnmount: component$6.willUnmount,
          willUpdate: component$6.willUpdate,
          shouldUpdate: component$6.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.SignupWhitelist.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$6.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.SignupWhitelist.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.SignupWhitelist.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$6.defaultError, R$6.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$6.jsElementWrapped
        };
}

var Create$1 = {
  R: R$6,
  decodeBPG: decodeBPG$6,
  component: component$6,
  make: make$6
};

var R$7 = Bpg_response$BsConsole.Make(F$1);

function decodeBPG$7(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$7 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$7(onSuccess, handleTask, token, children) {
  return {
          debugName: component$7.debugName,
          reactClassInternal: component$7.reactClassInternal,
          handedOffState: component$7.handedOffState,
          willReceiveProps: component$7.willReceiveProps,
          didMount: component$7.didMount,
          didUpdate: component$7.didUpdate,
          willUnmount: component$7.willUnmount,
          willUpdate: component$7.willUpdate,
          shouldUpdate: component$7.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.SignupWhitelist.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$7.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.SignupWhitelist.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.SignupWhitelist.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.SignupWhitelist.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$7.defaultError, R$7.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$7.jsElementWrapped
        };
}

var Delete$1 = {
  R: R$7,
  decodeBPG: decodeBPG$7,
  component: component$7,
  make: make$7
};

var SignupWhitelist = {
  Get: Get$1,
  Modify: Modify$1,
  Create: Create$1,
  Delete: Delete$1
};

var F_table$2 = BacktraceParserGenerator$BsConsole.SignupPending.table;

var F_to_json$2 = BacktraceParserGenerator$BsConsole.SignupPending.to_json;

var F_of_json$2 = BacktraceParserGenerator$BsConsole.SignupPending.of_json;

var F_Key$2 = BacktraceParserGenerator$BsConsole.SignupPending.Key;

var F_to_key$2 = BacktraceParserGenerator$BsConsole.SignupPending.to_key;

var F$2 = {
  table: F_table$2,
  to_json: F_to_json$2,
  of_json: F_of_json$2,
  Key: F_Key$2,
  to_key: F_to_key$2
};

var R$8 = Bpg_response$BsConsole.Make(F$2);

function decodeBPG$8(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$8 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.SignupPending.table + "get"));

function make$8(handleTask, token, filter, children) {
  return {
          debugName: component$8.debugName,
          reactClassInternal: component$8.reactClassInternal,
          handedOffState: component$8.handedOffState,
          willReceiveProps: component$8.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$8.didUpdate,
          willUnmount: component$8.willUnmount,
          willUpdate: component$8.willUpdate,
          shouldUpdate: component$8.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$8.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.SignupPending.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$8.defaultError, R$8.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.SignupPending.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$8.defaultError, R$8.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$8.jsElementWrapped
        };
}

var Get$2 = {
  R: R$8,
  decodeBPG: decodeBPG$8,
  component: component$8,
  make: make$8
};

var R$9 = Bpg_response$BsConsole.Make(F$2);

function decodeBPG$9(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$9 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$9(onSuccess, handleTask, token, children) {
  return {
          debugName: component$9.debugName,
          reactClassInternal: component$9.reactClassInternal,
          handedOffState: component$9.handedOffState,
          willReceiveProps: component$9.willReceiveProps,
          didMount: component$9.didMount,
          didUpdate: component$9.didUpdate,
          willUnmount: component$9.willUnmount,
          willUpdate: component$9.willUpdate,
          shouldUpdate: component$9.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.SignupPending.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$9.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.SignupPending.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$9.defaultError, R$9.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$9.jsElementWrapped
        };
}

var Modify$2 = {
  R: R$9,
  decodeBPG: decodeBPG$9,
  component: component$9,
  make: make$9
};

var R$10 = Bpg_response$BsConsole.MakeCreate(F$2);

function decodeBPG$10(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$10 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$10(onSuccess, handleTask, token, children) {
  return {
          debugName: component$10.debugName,
          reactClassInternal: component$10.reactClassInternal,
          handedOffState: component$10.handedOffState,
          willReceiveProps: component$10.willReceiveProps,
          didMount: component$10.didMount,
          didUpdate: component$10.didUpdate,
          willUnmount: component$10.willUnmount,
          willUpdate: component$10.willUpdate,
          shouldUpdate: component$10.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.SignupPending.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$10.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.SignupPending.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$10.defaultError, R$10.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$10.jsElementWrapped
        };
}

var Create$2 = {
  R: R$10,
  decodeBPG: decodeBPG$10,
  component: component$10,
  make: make$10
};

var R$11 = Bpg_response$BsConsole.Make(F$2);

function decodeBPG$11(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$11 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$11(onSuccess, handleTask, token, children) {
  return {
          debugName: component$11.debugName,
          reactClassInternal: component$11.reactClassInternal,
          handedOffState: component$11.handedOffState,
          willReceiveProps: component$11.willReceiveProps,
          didMount: component$11.didMount,
          didUpdate: component$11.didUpdate,
          willUnmount: component$11.willUnmount,
          willUpdate: component$11.willUpdate,
          shouldUpdate: component$11.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.SignupPending.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$11.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.SignupPending.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.SignupPending.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$11.defaultError, R$11.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$11.jsElementWrapped
        };
}

var Delete$2 = {
  R: R$11,
  decodeBPG: decodeBPG$11,
  component: component$11,
  make: make$11
};

var SignupPending = {
  Get: Get$2,
  Modify: Modify$2,
  Create: Create$2,
  Delete: Delete$2
};

var F_table$3 = BacktraceParserGenerator$BsConsole.Universe.table;

var F_to_json$3 = BacktraceParserGenerator$BsConsole.Universe.to_json;

var F_of_json$3 = BacktraceParserGenerator$BsConsole.Universe.of_json;

var F_Key$3 = BacktraceParserGenerator$BsConsole.Universe.Key;

var F_to_key$3 = BacktraceParserGenerator$BsConsole.Universe.to_key;

var F$3 = {
  table: F_table$3,
  to_json: F_to_json$3,
  of_json: F_of_json$3,
  Key: F_Key$3,
  to_key: F_to_key$3
};

var R$12 = Bpg_response$BsConsole.Make(F$3);

function decodeBPG$12(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$12 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.Universe.table + "get"));

function make$12(handleTask, token, filter, children) {
  return {
          debugName: component$12.debugName,
          reactClassInternal: component$12.reactClassInternal,
          handedOffState: component$12.handedOffState,
          willReceiveProps: component$12.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$12.didUpdate,
          willUnmount: component$12.willUnmount,
          willUpdate: component$12.willUpdate,
          shouldUpdate: component$12.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$12.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Universe.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$12.defaultError, R$12.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Universe.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$12.defaultError, R$12.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$12.jsElementWrapped
        };
}

var Get$3 = {
  R: R$12,
  decodeBPG: decodeBPG$12,
  component: component$12,
  make: make$12
};

var R$13 = Bpg_response$BsConsole.Make(F$3);

function decodeBPG$13(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$13 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$13(onSuccess, handleTask, token, children) {
  return {
          debugName: component$13.debugName,
          reactClassInternal: component$13.reactClassInternal,
          handedOffState: component$13.handedOffState,
          willReceiveProps: component$13.willReceiveProps,
          didMount: component$13.didMount,
          didUpdate: component$13.didUpdate,
          willUnmount: component$13.willUnmount,
          willUpdate: component$13.willUpdate,
          shouldUpdate: component$13.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Universe.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$13.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Universe.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Universe.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Universe.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$13.defaultError, R$13.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$13.jsElementWrapped
        };
}

var Modify$3 = {
  R: R$13,
  decodeBPG: decodeBPG$13,
  component: component$13,
  make: make$13
};

var R$14 = Bpg_response$BsConsole.MakeCreate(F$3);

function decodeBPG$14(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$14 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$14(onSuccess, handleTask, token, children) {
  return {
          debugName: component$14.debugName,
          reactClassInternal: component$14.reactClassInternal,
          handedOffState: component$14.handedOffState,
          willReceiveProps: component$14.willReceiveProps,
          didMount: component$14.didMount,
          didUpdate: component$14.didUpdate,
          willUnmount: component$14.willUnmount,
          willUpdate: component$14.willUpdate,
          shouldUpdate: component$14.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Universe.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$14.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Universe.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Universe.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$14.defaultError, R$14.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$14.jsElementWrapped
        };
}

var Create$3 = {
  R: R$14,
  decodeBPG: decodeBPG$14,
  component: component$14,
  make: make$14
};

var R$15 = Bpg_response$BsConsole.Make(F$3);

function decodeBPG$15(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$15 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$15(onSuccess, handleTask, token, children) {
  return {
          debugName: component$15.debugName,
          reactClassInternal: component$15.reactClassInternal,
          handedOffState: component$15.handedOffState,
          willReceiveProps: component$15.willReceiveProps,
          didMount: component$15.didMount,
          didUpdate: component$15.didUpdate,
          willUnmount: component$15.willUnmount,
          willUpdate: component$15.willUpdate,
          shouldUpdate: component$15.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Universe.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$15.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Universe.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Universe.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Universe.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$15.defaultError, R$15.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$15.jsElementWrapped
        };
}

var Delete$3 = {
  R: R$15,
  decodeBPG: decodeBPG$15,
  component: component$15,
  make: make$15
};

var Universe = {
  Get: Get$3,
  Modify: Modify$3,
  Create: Create$3,
  Delete: Delete$3
};

var F_table$4 = BacktraceParserGenerator$BsConsole.Project.table;

var F_to_json$4 = BacktraceParserGenerator$BsConsole.Project.to_json;

var F_of_json$4 = BacktraceParserGenerator$BsConsole.Project.of_json;

var F_Key$4 = BacktraceParserGenerator$BsConsole.Project.Key;

var F_to_key$4 = BacktraceParserGenerator$BsConsole.Project.to_key;

var F$4 = {
  table: F_table$4,
  to_json: F_to_json$4,
  of_json: F_of_json$4,
  Key: F_Key$4,
  to_key: F_to_key$4
};

var R$16 = Bpg_response$BsConsole.Make(F$4);

function decodeBPG$16(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$16 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.Project.table + "get"));

function make$16(handleTask, token, filter, children) {
  return {
          debugName: component$16.debugName,
          reactClassInternal: component$16.reactClassInternal,
          handedOffState: component$16.handedOffState,
          willReceiveProps: component$16.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$16.didUpdate,
          willUnmount: component$16.willUnmount,
          willUpdate: component$16.willUpdate,
          shouldUpdate: component$16.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$16.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Project.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$16.defaultError, R$16.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Project.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$16.defaultError, R$16.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$16.jsElementWrapped
        };
}

var Get$4 = {
  R: R$16,
  decodeBPG: decodeBPG$16,
  component: component$16,
  make: make$16
};

var R$17 = Bpg_response$BsConsole.Make(F$4);

function decodeBPG$17(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$17 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$17(onSuccess, handleTask, token, children) {
  return {
          debugName: component$17.debugName,
          reactClassInternal: component$17.reactClassInternal,
          handedOffState: component$17.handedOffState,
          willReceiveProps: component$17.willReceiveProps,
          didMount: component$17.didMount,
          didUpdate: component$17.didUpdate,
          willUnmount: component$17.willUnmount,
          willUpdate: component$17.willUpdate,
          shouldUpdate: component$17.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Project.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$17.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Project.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Project.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Project.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$17.defaultError, R$17.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$17.jsElementWrapped
        };
}

var Modify$4 = {
  R: R$17,
  decodeBPG: decodeBPG$17,
  component: component$17,
  make: make$17
};

var R$18 = Bpg_response$BsConsole.MakeCreate(F$4);

function decodeBPG$18(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$18 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$18(onSuccess, handleTask, token, children) {
  return {
          debugName: component$18.debugName,
          reactClassInternal: component$18.reactClassInternal,
          handedOffState: component$18.handedOffState,
          willReceiveProps: component$18.willReceiveProps,
          didMount: component$18.didMount,
          didUpdate: component$18.didUpdate,
          willUnmount: component$18.willUnmount,
          willUpdate: component$18.willUpdate,
          shouldUpdate: component$18.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Project.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$18.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Project.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Project.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$18.defaultError, R$18.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$18.jsElementWrapped
        };
}

var Create$4 = {
  R: R$18,
  decodeBPG: decodeBPG$18,
  component: component$18,
  make: make$18
};

var R$19 = Bpg_response$BsConsole.Make(F$4);

function decodeBPG$19(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$19 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$19(onSuccess, handleTask, token, children) {
  return {
          debugName: component$19.debugName,
          reactClassInternal: component$19.reactClassInternal,
          handedOffState: component$19.handedOffState,
          willReceiveProps: component$19.willReceiveProps,
          didMount: component$19.didMount,
          didUpdate: component$19.didUpdate,
          willUnmount: component$19.willUnmount,
          willUpdate: component$19.willUpdate,
          shouldUpdate: component$19.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Project.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$19.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Project.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Project.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Project.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$19.defaultError, R$19.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$19.jsElementWrapped
        };
}

var Delete$4 = {
  R: R$19,
  decodeBPG: decodeBPG$19,
  component: component$19,
  make: make$19
};

var Project = {
  Get: Get$4,
  Modify: Modify$4,
  Create: Create$4,
  Delete: Delete$4
};

var F_table$5 = BacktraceParserGenerator$BsConsole.WatcherNew.table;

var F_to_json$5 = BacktraceParserGenerator$BsConsole.WatcherNew.to_json;

var F_of_json$5 = BacktraceParserGenerator$BsConsole.WatcherNew.of_json;

var F_Key$5 = BacktraceParserGenerator$BsConsole.WatcherNew.Key;

var F_to_key$5 = BacktraceParserGenerator$BsConsole.WatcherNew.to_key;

var F$5 = {
  table: F_table$5,
  to_json: F_to_json$5,
  of_json: F_of_json$5,
  Key: F_Key$5,
  to_key: F_to_key$5
};

var R$20 = Bpg_response$BsConsole.Make(F$5);

function decodeBPG$20(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$20 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.WatcherNew.table + "get"));

function make$20(handleTask, token, filter, children) {
  return {
          debugName: component$20.debugName,
          reactClassInternal: component$20.reactClassInternal,
          handedOffState: component$20.handedOffState,
          willReceiveProps: component$20.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$20.didUpdate,
          willUnmount: component$20.willUnmount,
          willUpdate: component$20.willUpdate,
          shouldUpdate: component$20.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$20.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.WatcherNew.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$20.defaultError, R$20.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.WatcherNew.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$20.defaultError, R$20.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$20.jsElementWrapped
        };
}

var Get$5 = {
  R: R$20,
  decodeBPG: decodeBPG$20,
  component: component$20,
  make: make$20
};

var R$21 = Bpg_response$BsConsole.Make(F$5);

function decodeBPG$21(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$21 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$21(onSuccess, handleTask, token, children) {
  return {
          debugName: component$21.debugName,
          reactClassInternal: component$21.reactClassInternal,
          handedOffState: component$21.handedOffState,
          willReceiveProps: component$21.willReceiveProps,
          didMount: component$21.didMount,
          didUpdate: component$21.didUpdate,
          willUnmount: component$21.willUnmount,
          willUpdate: component$21.willUpdate,
          shouldUpdate: component$21.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.WatcherNew.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$21.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.WatcherNew.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$21.defaultError, R$21.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$21.jsElementWrapped
        };
}

var Modify$5 = {
  R: R$21,
  decodeBPG: decodeBPG$21,
  component: component$21,
  make: make$21
};

var R$22 = Bpg_response$BsConsole.MakeCreate(F$5);

function decodeBPG$22(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$22 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$22(onSuccess, handleTask, token, children) {
  return {
          debugName: component$22.debugName,
          reactClassInternal: component$22.reactClassInternal,
          handedOffState: component$22.handedOffState,
          willReceiveProps: component$22.willReceiveProps,
          didMount: component$22.didMount,
          didUpdate: component$22.didUpdate,
          willUnmount: component$22.willUnmount,
          willUpdate: component$22.willUpdate,
          shouldUpdate: component$22.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.WatcherNew.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$22.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.WatcherNew.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$22.defaultError, R$22.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$22.jsElementWrapped
        };
}

var Create$5 = {
  R: R$22,
  decodeBPG: decodeBPG$22,
  component: component$22,
  make: make$22
};

var R$23 = Bpg_response$BsConsole.Make(F$5);

function decodeBPG$23(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$23 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$23(onSuccess, handleTask, token, children) {
  return {
          debugName: component$23.debugName,
          reactClassInternal: component$23.reactClassInternal,
          handedOffState: component$23.handedOffState,
          willReceiveProps: component$23.willReceiveProps,
          didMount: component$23.didMount,
          didUpdate: component$23.didUpdate,
          willUnmount: component$23.willUnmount,
          willUpdate: component$23.willUpdate,
          shouldUpdate: component$23.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.WatcherNew.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$23.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.WatcherNew.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.WatcherNew.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$23.defaultError, R$23.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$23.jsElementWrapped
        };
}

var Delete$5 = {
  R: R$23,
  decodeBPG: decodeBPG$23,
  component: component$23,
  make: make$23
};

var WatcherNew = {
  Get: Get$5,
  Modify: Modify$5,
  Create: Create$5,
  Delete: Delete$5
};

var F_table$6 = BacktraceParserGenerator$BsConsole.Smtp.table;

var F_to_json$6 = BacktraceParserGenerator$BsConsole.Smtp.to_json;

var F_of_json$6 = BacktraceParserGenerator$BsConsole.Smtp.of_json;

var F_Key$6 = BacktraceParserGenerator$BsConsole.Smtp.Key;

var F_to_key$6 = BacktraceParserGenerator$BsConsole.Smtp.to_key;

var F$6 = {
  table: F_table$6,
  to_json: F_to_json$6,
  of_json: F_of_json$6,
  Key: F_Key$6,
  to_key: F_to_key$6
};

var R$24 = Bpg_response$BsConsole.Make(F$6);

function decodeBPG$24(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$24 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.Smtp.table + "get"));

function make$24(handleTask, token, filter, children) {
  return {
          debugName: component$24.debugName,
          reactClassInternal: component$24.reactClassInternal,
          handedOffState: component$24.handedOffState,
          willReceiveProps: component$24.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$24.didUpdate,
          willUnmount: component$24.willUnmount,
          willUpdate: component$24.willUpdate,
          shouldUpdate: component$24.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$24.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Smtp.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$24.defaultError, R$24.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Smtp.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$24.defaultError, R$24.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$24.jsElementWrapped
        };
}

var Get$6 = {
  R: R$24,
  decodeBPG: decodeBPG$24,
  component: component$24,
  make: make$24
};

var R$25 = Bpg_response$BsConsole.Make(F$6);

function decodeBPG$25(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$25 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$25(onSuccess, handleTask, token, children) {
  return {
          debugName: component$25.debugName,
          reactClassInternal: component$25.reactClassInternal,
          handedOffState: component$25.handedOffState,
          willReceiveProps: component$25.willReceiveProps,
          didMount: component$25.didMount,
          didUpdate: component$25.didUpdate,
          willUnmount: component$25.willUnmount,
          willUpdate: component$25.willUpdate,
          shouldUpdate: component$25.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Smtp.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$25.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Smtp.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Smtp.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Smtp.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$25.defaultError, R$25.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$25.jsElementWrapped
        };
}

var Modify$6 = {
  R: R$25,
  decodeBPG: decodeBPG$25,
  component: component$25,
  make: make$25
};

var R$26 = Bpg_response$BsConsole.MakeCreate(F$6);

function decodeBPG$26(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$26 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$26(onSuccess, handleTask, token, children) {
  return {
          debugName: component$26.debugName,
          reactClassInternal: component$26.reactClassInternal,
          handedOffState: component$26.handedOffState,
          willReceiveProps: component$26.willReceiveProps,
          didMount: component$26.didMount,
          didUpdate: component$26.didUpdate,
          willUnmount: component$26.willUnmount,
          willUpdate: component$26.willUpdate,
          shouldUpdate: component$26.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Smtp.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$26.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Smtp.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Smtp.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$26.defaultError, R$26.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$26.jsElementWrapped
        };
}

var Create$6 = {
  R: R$26,
  decodeBPG: decodeBPG$26,
  component: component$26,
  make: make$26
};

var R$27 = Bpg_response$BsConsole.Make(F$6);

function decodeBPG$27(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$27 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$27(onSuccess, handleTask, token, children) {
  return {
          debugName: component$27.debugName,
          reactClassInternal: component$27.reactClassInternal,
          handedOffState: component$27.handedOffState,
          willReceiveProps: component$27.willReceiveProps,
          didMount: component$27.didMount,
          didUpdate: component$27.didUpdate,
          willUnmount: component$27.willUnmount,
          willUpdate: component$27.willUpdate,
          shouldUpdate: component$27.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Smtp.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$27.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Smtp.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Smtp.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Smtp.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$27.defaultError, R$27.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$27.jsElementWrapped
        };
}

var Delete$6 = {
  R: R$27,
  decodeBPG: decodeBPG$27,
  component: component$27,
  make: make$27
};

var Smtp = {
  Get: Get$6,
  Modify: Modify$6,
  Create: Create$6,
  Delete: Delete$6
};

var F_table$7 = BacktraceParserGenerator$BsConsole.Ssl.table;

var F_to_json$7 = BacktraceParserGenerator$BsConsole.Ssl.to_json;

var F_of_json$7 = BacktraceParserGenerator$BsConsole.Ssl.of_json;

var F_Key$7 = BacktraceParserGenerator$BsConsole.Ssl.Key;

var F_to_key$7 = BacktraceParserGenerator$BsConsole.Ssl.to_key;

var F$7 = {
  table: F_table$7,
  to_json: F_to_json$7,
  of_json: F_of_json$7,
  Key: F_Key$7,
  to_key: F_to_key$7
};

var R$28 = Bpg_response$BsConsole.Make(F$7);

function decodeBPG$28(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$28 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.Ssl.table + "get"));

function make$28(handleTask, token, filter, children) {
  return {
          debugName: component$28.debugName,
          reactClassInternal: component$28.reactClassInternal,
          handedOffState: component$28.handedOffState,
          willReceiveProps: component$28.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$28.didUpdate,
          willUnmount: component$28.willUnmount,
          willUpdate: component$28.willUpdate,
          shouldUpdate: component$28.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$28.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Ssl.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$28.defaultError, R$28.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Ssl.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$28.defaultError, R$28.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$28.jsElementWrapped
        };
}

var Get$7 = {
  R: R$28,
  decodeBPG: decodeBPG$28,
  component: component$28,
  make: make$28
};

var R$29 = Bpg_response$BsConsole.Make(F$7);

function decodeBPG$29(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$29 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$29(onSuccess, handleTask, token, children) {
  return {
          debugName: component$29.debugName,
          reactClassInternal: component$29.reactClassInternal,
          handedOffState: component$29.handedOffState,
          willReceiveProps: component$29.willReceiveProps,
          didMount: component$29.didMount,
          didUpdate: component$29.didUpdate,
          willUnmount: component$29.willUnmount,
          willUpdate: component$29.willUpdate,
          shouldUpdate: component$29.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Ssl.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$29.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Ssl.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Ssl.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Ssl.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$29.defaultError, R$29.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$29.jsElementWrapped
        };
}

var Modify$7 = {
  R: R$29,
  decodeBPG: decodeBPG$29,
  component: component$29,
  make: make$29
};

var R$30 = Bpg_response$BsConsole.MakeCreate(F$7);

function decodeBPG$30(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$30 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$30(onSuccess, handleTask, token, children) {
  return {
          debugName: component$30.debugName,
          reactClassInternal: component$30.reactClassInternal,
          handedOffState: component$30.handedOffState,
          willReceiveProps: component$30.willReceiveProps,
          didMount: component$30.didMount,
          didUpdate: component$30.didUpdate,
          willUnmount: component$30.willUnmount,
          willUpdate: component$30.willUpdate,
          shouldUpdate: component$30.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Ssl.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$30.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Ssl.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Ssl.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$30.defaultError, R$30.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$30.jsElementWrapped
        };
}

var Create$7 = {
  R: R$30,
  decodeBPG: decodeBPG$30,
  component: component$30,
  make: make$30
};

var R$31 = Bpg_response$BsConsole.Make(F$7);

function decodeBPG$31(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$31 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$31(onSuccess, handleTask, token, children) {
  return {
          debugName: component$31.debugName,
          reactClassInternal: component$31.reactClassInternal,
          handedOffState: component$31.handedOffState,
          willReceiveProps: component$31.willReceiveProps,
          didMount: component$31.didMount,
          didUpdate: component$31.didUpdate,
          willUnmount: component$31.willUnmount,
          willUpdate: component$31.willUpdate,
          shouldUpdate: component$31.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Ssl.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$31.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Ssl.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Ssl.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Ssl.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$31.defaultError, R$31.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$31.jsElementWrapped
        };
}

var Delete$7 = {
  R: R$31,
  decodeBPG: decodeBPG$31,
  component: component$31,
  make: make$31
};

var Ssl = {
  Get: Get$7,
  Modify: Modify$7,
  Create: Create$7,
  Delete: Delete$7
};

var F_table$8 = BacktraceParserGenerator$BsConsole.Listener.table;

var F_to_json$8 = BacktraceParserGenerator$BsConsole.Listener.to_json;

var F_of_json$8 = BacktraceParserGenerator$BsConsole.Listener.of_json;

var F_Key$8 = BacktraceParserGenerator$BsConsole.Listener.Key;

var F_to_key$8 = BacktraceParserGenerator$BsConsole.Listener.to_key;

var F$8 = {
  table: F_table$8,
  to_json: F_to_json$8,
  of_json: F_of_json$8,
  Key: F_Key$8,
  to_key: F_to_key$8
};

var R$32 = Bpg_response$BsConsole.Make(F$8);

function decodeBPG$32(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$32 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.Listener.table + "get"));

function make$32(handleTask, token, filter, children) {
  return {
          debugName: component$32.debugName,
          reactClassInternal: component$32.reactClassInternal,
          handedOffState: component$32.handedOffState,
          willReceiveProps: component$32.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$32.didUpdate,
          willUnmount: component$32.willUnmount,
          willUpdate: component$32.willUpdate,
          shouldUpdate: component$32.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$32.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Listener.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$32.defaultError, R$32.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Listener.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$32.defaultError, R$32.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$32.jsElementWrapped
        };
}

var Get$8 = {
  R: R$32,
  decodeBPG: decodeBPG$32,
  component: component$32,
  make: make$32
};

var R$33 = Bpg_response$BsConsole.Make(F$8);

function decodeBPG$33(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$33 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$33(onSuccess, handleTask, token, children) {
  return {
          debugName: component$33.debugName,
          reactClassInternal: component$33.reactClassInternal,
          handedOffState: component$33.handedOffState,
          willReceiveProps: component$33.willReceiveProps,
          didMount: component$33.didMount,
          didUpdate: component$33.didUpdate,
          willUnmount: component$33.willUnmount,
          willUpdate: component$33.willUpdate,
          shouldUpdate: component$33.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Listener.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$33.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Listener.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Listener.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Listener.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$33.defaultError, R$33.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$33.jsElementWrapped
        };
}

var Modify$8 = {
  R: R$33,
  decodeBPG: decodeBPG$33,
  component: component$33,
  make: make$33
};

var R$34 = Bpg_response$BsConsole.MakeCreate(F$8);

function decodeBPG$34(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$34 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$34(onSuccess, handleTask, token, children) {
  return {
          debugName: component$34.debugName,
          reactClassInternal: component$34.reactClassInternal,
          handedOffState: component$34.handedOffState,
          willReceiveProps: component$34.willReceiveProps,
          didMount: component$34.didMount,
          didUpdate: component$34.didUpdate,
          willUnmount: component$34.willUnmount,
          willUpdate: component$34.willUpdate,
          shouldUpdate: component$34.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Listener.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$34.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Listener.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Listener.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$34.defaultError, R$34.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$34.jsElementWrapped
        };
}

var Create$8 = {
  R: R$34,
  decodeBPG: decodeBPG$34,
  component: component$34,
  make: make$34
};

var R$35 = Bpg_response$BsConsole.Make(F$8);

function decodeBPG$35(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$35 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$35(onSuccess, handleTask, token, children) {
  return {
          debugName: component$35.debugName,
          reactClassInternal: component$35.reactClassInternal,
          handedOffState: component$35.handedOffState,
          willReceiveProps: component$35.willReceiveProps,
          didMount: component$35.didMount,
          didUpdate: component$35.didUpdate,
          willUnmount: component$35.willUnmount,
          willUpdate: component$35.willUpdate,
          shouldUpdate: component$35.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Listener.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$35.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Listener.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Listener.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Listener.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$35.defaultError, R$35.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$35.jsElementWrapped
        };
}

var Delete$8 = {
  R: R$35,
  decodeBPG: decodeBPG$35,
  component: component$35,
  make: make$35
};

var Listener = {
  Get: Get$8,
  Modify: Modify$8,
  Create: Create$8,
  Delete: Delete$8
};

var F_table$9 = BacktraceParserGenerator$BsConsole.Attribute.table;

var F_to_json$9 = BacktraceParserGenerator$BsConsole.Attribute.to_json;

var F_of_json$9 = BacktraceParserGenerator$BsConsole.Attribute.of_json;

var F_Key$9 = BacktraceParserGenerator$BsConsole.Attribute.Key;

var F_to_key$9 = BacktraceParserGenerator$BsConsole.Attribute.to_key;

var F$9 = {
  table: F_table$9,
  to_json: F_to_json$9,
  of_json: F_of_json$9,
  Key: F_Key$9,
  to_key: F_to_key$9
};

var R$36 = Bpg_response$BsConsole.Make(F$9);

function decodeBPG$36(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$36 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.Attribute.table + "get"));

function make$36(handleTask, token, filter, children) {
  return {
          debugName: component$36.debugName,
          reactClassInternal: component$36.reactClassInternal,
          handedOffState: component$36.handedOffState,
          willReceiveProps: component$36.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$36.didUpdate,
          willUnmount: component$36.willUnmount,
          willUpdate: component$36.willUpdate,
          shouldUpdate: component$36.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$36.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Attribute.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$36.defaultError, R$36.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Attribute.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$36.defaultError, R$36.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$36.jsElementWrapped
        };
}

var Get$9 = {
  R: R$36,
  decodeBPG: decodeBPG$36,
  component: component$36,
  make: make$36
};

var R$37 = Bpg_response$BsConsole.Make(F$9);

function decodeBPG$37(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$37 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$37(onSuccess, handleTask, token, children) {
  return {
          debugName: component$37.debugName,
          reactClassInternal: component$37.reactClassInternal,
          handedOffState: component$37.handedOffState,
          willReceiveProps: component$37.willReceiveProps,
          didMount: component$37.didMount,
          didUpdate: component$37.didUpdate,
          willUnmount: component$37.willUnmount,
          willUpdate: component$37.willUpdate,
          shouldUpdate: component$37.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Attribute.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$37.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Attribute.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Attribute.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Attribute.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$37.defaultError, R$37.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$37.jsElementWrapped
        };
}

var Modify$9 = {
  R: R$37,
  decodeBPG: decodeBPG$37,
  component: component$37,
  make: make$37
};

var R$38 = Bpg_response$BsConsole.MakeCreate(F$9);

function decodeBPG$38(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$38 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$38(onSuccess, handleTask, token, children) {
  return {
          debugName: component$38.debugName,
          reactClassInternal: component$38.reactClassInternal,
          handedOffState: component$38.handedOffState,
          willReceiveProps: component$38.willReceiveProps,
          didMount: component$38.didMount,
          didUpdate: component$38.didUpdate,
          willUnmount: component$38.willUnmount,
          willUpdate: component$38.willUpdate,
          shouldUpdate: component$38.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Attribute.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$38.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Attribute.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Attribute.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$38.defaultError, R$38.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$38.jsElementWrapped
        };
}

var Create$9 = {
  R: R$38,
  decodeBPG: decodeBPG$38,
  component: component$38,
  make: make$38
};

var R$39 = Bpg_response$BsConsole.Make(F$9);

function decodeBPG$39(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$39 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$39(onSuccess, handleTask, token, children) {
  return {
          debugName: component$39.debugName,
          reactClassInternal: component$39.reactClassInternal,
          handedOffState: component$39.handedOffState,
          willReceiveProps: component$39.willReceiveProps,
          didMount: component$39.didMount,
          didUpdate: component$39.didUpdate,
          willUnmount: component$39.willUnmount,
          willUpdate: component$39.willUpdate,
          shouldUpdate: component$39.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Attribute.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$39.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Attribute.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Attribute.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Attribute.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$39.defaultError, R$39.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$39.jsElementWrapped
        };
}

var Delete$9 = {
  R: R$39,
  decodeBPG: decodeBPG$39,
  component: component$39,
  make: make$39
};

var Attribute = {
  Get: Get$9,
  Modify: Modify$9,
  Create: Create$9,
  Delete: Delete$9
};

var F_table$10 = BacktraceParserGenerator$BsConsole.Token.table;

var F_to_json$10 = BacktraceParserGenerator$BsConsole.Token.to_json;

var F_of_json$10 = BacktraceParserGenerator$BsConsole.Token.of_json;

var F_Key$10 = BacktraceParserGenerator$BsConsole.Token.Key;

var F_to_key$10 = BacktraceParserGenerator$BsConsole.Token.to_key;

var F$10 = {
  table: F_table$10,
  to_json: F_to_json$10,
  of_json: F_of_json$10,
  Key: F_Key$10,
  to_key: F_to_key$10
};

var R$40 = Bpg_response$BsConsole.Make(F$10);

function decodeBPG$40(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$40 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.Token.table + "get"));

function make$40(handleTask, token, filter, children) {
  return {
          debugName: component$40.debugName,
          reactClassInternal: component$40.reactClassInternal,
          handedOffState: component$40.handedOffState,
          willReceiveProps: component$40.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$40.didUpdate,
          willUnmount: component$40.willUnmount,
          willUpdate: component$40.willUpdate,
          shouldUpdate: component$40.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$40.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Token.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$40.defaultError, R$40.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Token.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$40.defaultError, R$40.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$40.jsElementWrapped
        };
}

var Get$10 = {
  R: R$40,
  decodeBPG: decodeBPG$40,
  component: component$40,
  make: make$40
};

var R$41 = Bpg_response$BsConsole.Make(F$10);

function decodeBPG$41(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$41 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$41(onSuccess, handleTask, token, children) {
  return {
          debugName: component$41.debugName,
          reactClassInternal: component$41.reactClassInternal,
          handedOffState: component$41.handedOffState,
          willReceiveProps: component$41.willReceiveProps,
          didMount: component$41.didMount,
          didUpdate: component$41.didUpdate,
          willUnmount: component$41.willUnmount,
          willUpdate: component$41.willUpdate,
          shouldUpdate: component$41.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Token.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$41.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Token.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Token.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Token.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$41.defaultError, R$41.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$41.jsElementWrapped
        };
}

var Modify$10 = {
  R: R$41,
  decodeBPG: decodeBPG$41,
  component: component$41,
  make: make$41
};

var R$42 = Bpg_response$BsConsole.MakeCreate(F$10);

function decodeBPG$42(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$42 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$42(onSuccess, handleTask, token, children) {
  return {
          debugName: component$42.debugName,
          reactClassInternal: component$42.reactClassInternal,
          handedOffState: component$42.handedOffState,
          willReceiveProps: component$42.willReceiveProps,
          didMount: component$42.didMount,
          didUpdate: component$42.didUpdate,
          willUnmount: component$42.willUnmount,
          willUpdate: component$42.willUpdate,
          shouldUpdate: component$42.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Token.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$42.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Token.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Token.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$42.defaultError, R$42.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$42.jsElementWrapped
        };
}

var Create$10 = {
  R: R$42,
  decodeBPG: decodeBPG$42,
  component: component$42,
  make: make$42
};

var R$43 = Bpg_response$BsConsole.Make(F$10);

function decodeBPG$43(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$43 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$43(onSuccess, handleTask, token, children) {
  return {
          debugName: component$43.debugName,
          reactClassInternal: component$43.reactClassInternal,
          handedOffState: component$43.handedOffState,
          willReceiveProps: component$43.willReceiveProps,
          didMount: component$43.didMount,
          didUpdate: component$43.didUpdate,
          willUnmount: component$43.willUnmount,
          willUpdate: component$43.willUpdate,
          shouldUpdate: component$43.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Token.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$43.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Token.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Token.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Token.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$43.defaultError, R$43.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$43.jsElementWrapped
        };
}

var Delete$10 = {
  R: R$43,
  decodeBPG: decodeBPG$43,
  component: component$43,
  make: make$43
};

var Token = {
  Get: Get$10,
  Modify: Modify$10,
  Create: Create$10,
  Delete: Delete$10
};

var F_table$11 = BacktraceParserGenerator$BsConsole.ApiToken.table;

var F_to_json$11 = BacktraceParserGenerator$BsConsole.ApiToken.to_json;

var F_of_json$11 = BacktraceParserGenerator$BsConsole.ApiToken.of_json;

var F_Key$11 = BacktraceParserGenerator$BsConsole.ApiToken.Key;

var F_to_key$11 = BacktraceParserGenerator$BsConsole.ApiToken.to_key;

var F$11 = {
  table: F_table$11,
  to_json: F_to_json$11,
  of_json: F_of_json$11,
  Key: F_Key$11,
  to_key: F_to_key$11
};

var R$44 = Bpg_response$BsConsole.Make(F$11);

function decodeBPG$44(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$44 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.ApiToken.table + "get"));

function make$44(handleTask, token, filter, children) {
  return {
          debugName: component$44.debugName,
          reactClassInternal: component$44.reactClassInternal,
          handedOffState: component$44.handedOffState,
          willReceiveProps: component$44.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$44.didUpdate,
          willUnmount: component$44.willUnmount,
          willUpdate: component$44.willUpdate,
          shouldUpdate: component$44.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$44.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.ApiToken.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$44.defaultError, R$44.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.ApiToken.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$44.defaultError, R$44.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$44.jsElementWrapped
        };
}

var Get$11 = {
  R: R$44,
  decodeBPG: decodeBPG$44,
  component: component$44,
  make: make$44
};

var R$45 = Bpg_response$BsConsole.Make(F$11);

function decodeBPG$45(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$45 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$45(onSuccess, handleTask, token, children) {
  return {
          debugName: component$45.debugName,
          reactClassInternal: component$45.reactClassInternal,
          handedOffState: component$45.handedOffState,
          willReceiveProps: component$45.willReceiveProps,
          didMount: component$45.didMount,
          didUpdate: component$45.didUpdate,
          willUnmount: component$45.willUnmount,
          willUpdate: component$45.willUpdate,
          shouldUpdate: component$45.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.ApiToken.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$45.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.ApiToken.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$45.defaultError, R$45.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$45.jsElementWrapped
        };
}

var Modify$11 = {
  R: R$45,
  decodeBPG: decodeBPG$45,
  component: component$45,
  make: make$45
};

var R$46 = Bpg_response$BsConsole.MakeCreate(F$11);

function decodeBPG$46(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$46 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$46(onSuccess, handleTask, token, children) {
  return {
          debugName: component$46.debugName,
          reactClassInternal: component$46.reactClassInternal,
          handedOffState: component$46.handedOffState,
          willReceiveProps: component$46.willReceiveProps,
          didMount: component$46.didMount,
          didUpdate: component$46.didUpdate,
          willUnmount: component$46.willUnmount,
          willUpdate: component$46.willUpdate,
          shouldUpdate: component$46.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.ApiToken.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$46.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.ApiToken.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$46.defaultError, R$46.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$46.jsElementWrapped
        };
}

var Create$11 = {
  R: R$46,
  decodeBPG: decodeBPG$46,
  component: component$46,
  make: make$46
};

var R$47 = Bpg_response$BsConsole.Make(F$11);

function decodeBPG$47(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$47 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$47(onSuccess, handleTask, token, children) {
  return {
          debugName: component$47.debugName,
          reactClassInternal: component$47.reactClassInternal,
          handedOffState: component$47.handedOffState,
          willReceiveProps: component$47.willReceiveProps,
          didMount: component$47.didMount,
          didUpdate: component$47.didUpdate,
          willUnmount: component$47.willUnmount,
          willUpdate: component$47.willUpdate,
          shouldUpdate: component$47.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.ApiToken.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$47.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.ApiToken.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ApiToken.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$47.defaultError, R$47.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$47.jsElementWrapped
        };
}

var Delete$11 = {
  R: R$47,
  decodeBPG: decodeBPG$47,
  component: component$47,
  make: make$47
};

var ApiToken = {
  Get: Get$11,
  Modify: Modify$11,
  Create: Create$11,
  Delete: Delete$11
};

var F_table$12 = BacktraceParserGenerator$BsConsole.Query.table;

var F_to_json$12 = BacktraceParserGenerator$BsConsole.Query.to_json;

var F_of_json$12 = BacktraceParserGenerator$BsConsole.Query.of_json;

var F_Key$12 = BacktraceParserGenerator$BsConsole.Query.Key;

var F_to_key$12 = BacktraceParserGenerator$BsConsole.Query.to_key;

var F$12 = {
  table: F_table$12,
  to_json: F_to_json$12,
  of_json: F_of_json$12,
  Key: F_Key$12,
  to_key: F_to_key$12
};

var R$48 = Bpg_response$BsConsole.Make(F$12);

function decodeBPG$48(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$48 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.Query.table + "get"));

function make$48(handleTask, token, filter, children) {
  return {
          debugName: component$48.debugName,
          reactClassInternal: component$48.reactClassInternal,
          handedOffState: component$48.handedOffState,
          willReceiveProps: component$48.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$48.didUpdate,
          willUnmount: component$48.willUnmount,
          willUpdate: component$48.willUpdate,
          shouldUpdate: component$48.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$48.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Query.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$48.defaultError, R$48.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Query.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$48.defaultError, R$48.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$48.jsElementWrapped
        };
}

var Get$12 = {
  R: R$48,
  decodeBPG: decodeBPG$48,
  component: component$48,
  make: make$48
};

var R$49 = Bpg_response$BsConsole.Make(F$12);

function decodeBPG$49(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$49 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$49(onSuccess, handleTask, token, children) {
  return {
          debugName: component$49.debugName,
          reactClassInternal: component$49.reactClassInternal,
          handedOffState: component$49.handedOffState,
          willReceiveProps: component$49.willReceiveProps,
          didMount: component$49.didMount,
          didUpdate: component$49.didUpdate,
          willUnmount: component$49.willUnmount,
          willUpdate: component$49.willUpdate,
          shouldUpdate: component$49.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Query.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$49.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Query.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Query.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Query.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$49.defaultError, R$49.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$49.jsElementWrapped
        };
}

var Modify$12 = {
  R: R$49,
  decodeBPG: decodeBPG$49,
  component: component$49,
  make: make$49
};

var R$50 = Bpg_response$BsConsole.MakeCreate(F$12);

function decodeBPG$50(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$50 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$50(onSuccess, handleTask, token, children) {
  return {
          debugName: component$50.debugName,
          reactClassInternal: component$50.reactClassInternal,
          handedOffState: component$50.handedOffState,
          willReceiveProps: component$50.willReceiveProps,
          didMount: component$50.didMount,
          didUpdate: component$50.didUpdate,
          willUnmount: component$50.willUnmount,
          willUpdate: component$50.willUpdate,
          shouldUpdate: component$50.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Query.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$50.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Query.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Query.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$50.defaultError, R$50.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$50.jsElementWrapped
        };
}

var Create$12 = {
  R: R$50,
  decodeBPG: decodeBPG$50,
  component: component$50,
  make: make$50
};

var R$51 = Bpg_response$BsConsole.Make(F$12);

function decodeBPG$51(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$51 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$51(onSuccess, handleTask, token, children) {
  return {
          debugName: component$51.debugName,
          reactClassInternal: component$51.reactClassInternal,
          handedOffState: component$51.handedOffState,
          willReceiveProps: component$51.willReceiveProps,
          didMount: component$51.didMount,
          didUpdate: component$51.didUpdate,
          willUnmount: component$51.willUnmount,
          willUpdate: component$51.willUpdate,
          shouldUpdate: component$51.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Query.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$51.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Query.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Query.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Query.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$51.defaultError, R$51.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$51.jsElementWrapped
        };
}

var Delete$12 = {
  R: R$51,
  decodeBPG: decodeBPG$51,
  component: component$51,
  make: make$51
};

var Query = {
  Get: Get$12,
  Modify: Modify$12,
  Create: Create$12,
  Delete: Delete$12
};

var F_table$13 = BacktraceParserGenerator$BsConsole.Report.table;

var F_to_json$13 = BacktraceParserGenerator$BsConsole.Report.to_json;

var F_of_json$13 = BacktraceParserGenerator$BsConsole.Report.of_json;

var F_Key$13 = BacktraceParserGenerator$BsConsole.Report.Key;

var F_to_key$13 = BacktraceParserGenerator$BsConsole.Report.to_key;

var F$13 = {
  table: F_table$13,
  to_json: F_to_json$13,
  of_json: F_of_json$13,
  Key: F_Key$13,
  to_key: F_to_key$13
};

var R$52 = Bpg_response$BsConsole.Make(F$13);

function decodeBPG$52(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$52 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.Report.table + "get"));

function make$52(handleTask, token, filter, children) {
  return {
          debugName: component$52.debugName,
          reactClassInternal: component$52.reactClassInternal,
          handedOffState: component$52.handedOffState,
          willReceiveProps: component$52.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$52.didUpdate,
          willUnmount: component$52.willUnmount,
          willUpdate: component$52.willUpdate,
          shouldUpdate: component$52.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$52.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Report.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$52.defaultError, R$52.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.Report.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$52.defaultError, R$52.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$52.jsElementWrapped
        };
}

var Get$13 = {
  R: R$52,
  decodeBPG: decodeBPG$52,
  component: component$52,
  make: make$52
};

var R$53 = Bpg_response$BsConsole.Make(F$13);

function decodeBPG$53(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$53 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$53(onSuccess, handleTask, token, children) {
  return {
          debugName: component$53.debugName,
          reactClassInternal: component$53.reactClassInternal,
          handedOffState: component$53.handedOffState,
          willReceiveProps: component$53.willReceiveProps,
          didMount: component$53.didMount,
          didUpdate: component$53.didUpdate,
          willUnmount: component$53.willUnmount,
          willUpdate: component$53.willUpdate,
          shouldUpdate: component$53.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Report.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$53.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Report.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Report.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Report.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$53.defaultError, R$53.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$53.jsElementWrapped
        };
}

var Modify$13 = {
  R: R$53,
  decodeBPG: decodeBPG$53,
  component: component$53,
  make: make$53
};

var R$54 = Bpg_response$BsConsole.MakeCreate(F$13);

function decodeBPG$54(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$54 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$54(onSuccess, handleTask, token, children) {
  return {
          debugName: component$54.debugName,
          reactClassInternal: component$54.reactClassInternal,
          handedOffState: component$54.handedOffState,
          willReceiveProps: component$54.willReceiveProps,
          didMount: component$54.didMount,
          didUpdate: component$54.didUpdate,
          willUnmount: component$54.willUnmount,
          willUpdate: component$54.willUpdate,
          shouldUpdate: component$54.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Report.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$54.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Report.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Report.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$54.defaultError, R$54.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$54.jsElementWrapped
        };
}

var Create$13 = {
  R: R$54,
  decodeBPG: decodeBPG$54,
  component: component$54,
  make: make$54
};

var R$55 = Bpg_response$BsConsole.Make(F$13);

function decodeBPG$55(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$55 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$55(onSuccess, handleTask, token, children) {
  return {
          debugName: component$55.debugName,
          reactClassInternal: component$55.reactClassInternal,
          handedOffState: component$55.handedOffState,
          willReceiveProps: component$55.willReceiveProps,
          didMount: component$55.didMount,
          didUpdate: component$55.didUpdate,
          willUnmount: component$55.willUnmount,
          willUpdate: component$55.willUpdate,
          shouldUpdate: component$55.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.Report.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$55.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.Report.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.Report.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.Report.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$55.defaultError, R$55.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$55.jsElementWrapped
        };
}

var Delete$13 = {
  R: R$55,
  decodeBPG: decodeBPG$55,
  component: component$55,
  make: make$55
};

var Report = {
  Get: Get$13,
  Modify: Modify$13,
  Create: Create$13,
  Delete: Delete$13
};

var F_table$14 = BacktraceParserGenerator$BsConsole.InstanceRetention.table;

var F_to_json$14 = BacktraceParserGenerator$BsConsole.InstanceRetention.to_json;

var F_of_json$14 = BacktraceParserGenerator$BsConsole.InstanceRetention.of_json;

var F_Key$14 = BacktraceParserGenerator$BsConsole.InstanceRetention.Key;

var F_to_key$14 = BacktraceParserGenerator$BsConsole.InstanceRetention.to_key;

var F$14 = {
  table: F_table$14,
  to_json: F_to_json$14,
  of_json: F_of_json$14,
  Key: F_Key$14,
  to_key: F_to_key$14
};

var R$56 = Bpg_response$BsConsole.Make(F$14);

function decodeBPG$56(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$56 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.InstanceRetention.table + "get"));

function make$56(handleTask, token, filter, children) {
  return {
          debugName: component$56.debugName,
          reactClassInternal: component$56.reactClassInternal,
          handedOffState: component$56.handedOffState,
          willReceiveProps: component$56.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$56.didUpdate,
          willUnmount: component$56.willUnmount,
          willUpdate: component$56.willUpdate,
          shouldUpdate: component$56.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$56.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.InstanceRetention.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$56.defaultError, R$56.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.InstanceRetention.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$56.defaultError, R$56.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$56.jsElementWrapped
        };
}

var Get$14 = {
  R: R$56,
  decodeBPG: decodeBPG$56,
  component: component$56,
  make: make$56
};

var R$57 = Bpg_response$BsConsole.Make(F$14);

function decodeBPG$57(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$57 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$57(onSuccess, handleTask, token, children) {
  return {
          debugName: component$57.debugName,
          reactClassInternal: component$57.reactClassInternal,
          handedOffState: component$57.handedOffState,
          willReceiveProps: component$57.willReceiveProps,
          didMount: component$57.didMount,
          didUpdate: component$57.didUpdate,
          willUnmount: component$57.willUnmount,
          willUpdate: component$57.willUpdate,
          shouldUpdate: component$57.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.InstanceRetention.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$57.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.InstanceRetention.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$57.defaultError, R$57.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$57.jsElementWrapped
        };
}

var Modify$14 = {
  R: R$57,
  decodeBPG: decodeBPG$57,
  component: component$57,
  make: make$57
};

var R$58 = Bpg_response$BsConsole.MakeCreate(F$14);

function decodeBPG$58(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$58 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$58(onSuccess, handleTask, token, children) {
  return {
          debugName: component$58.debugName,
          reactClassInternal: component$58.reactClassInternal,
          handedOffState: component$58.handedOffState,
          willReceiveProps: component$58.willReceiveProps,
          didMount: component$58.didMount,
          didUpdate: component$58.didUpdate,
          willUnmount: component$58.willUnmount,
          willUpdate: component$58.willUpdate,
          shouldUpdate: component$58.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.InstanceRetention.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$58.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.InstanceRetention.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$58.defaultError, R$58.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$58.jsElementWrapped
        };
}

var Create$14 = {
  R: R$58,
  decodeBPG: decodeBPG$58,
  component: component$58,
  make: make$58
};

var R$59 = Bpg_response$BsConsole.Make(F$14);

function decodeBPG$59(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$59 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$59(onSuccess, handleTask, token, children) {
  return {
          debugName: component$59.debugName,
          reactClassInternal: component$59.reactClassInternal,
          handedOffState: component$59.handedOffState,
          willReceiveProps: component$59.willReceiveProps,
          didMount: component$59.didMount,
          didUpdate: component$59.didUpdate,
          willUnmount: component$59.willUnmount,
          willUpdate: component$59.willUpdate,
          shouldUpdate: component$59.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.InstanceRetention.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$59.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.InstanceRetention.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.InstanceRetention.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$59.defaultError, R$59.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$59.jsElementWrapped
        };
}

var Delete$14 = {
  R: R$59,
  decodeBPG: decodeBPG$59,
  component: component$59,
  make: make$59
};

var InstanceRetention = {
  Get: Get$14,
  Modify: Modify$14,
  Create: Create$14,
  Delete: Delete$14
};

var F_table$15 = BacktraceParserGenerator$BsConsole.UniverseRetention.table;

var F_to_json$15 = BacktraceParserGenerator$BsConsole.UniverseRetention.to_json;

var F_of_json$15 = BacktraceParserGenerator$BsConsole.UniverseRetention.of_json;

var F_Key$15 = BacktraceParserGenerator$BsConsole.UniverseRetention.Key;

var F_to_key$15 = BacktraceParserGenerator$BsConsole.UniverseRetention.to_key;

var F$15 = {
  table: F_table$15,
  to_json: F_to_json$15,
  of_json: F_of_json$15,
  Key: F_Key$15,
  to_key: F_to_key$15
};

var R$60 = Bpg_response$BsConsole.Make(F$15);

function decodeBPG$60(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$60 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.UniverseRetention.table + "get"));

function make$60(handleTask, token, filter, children) {
  return {
          debugName: component$60.debugName,
          reactClassInternal: component$60.reactClassInternal,
          handedOffState: component$60.handedOffState,
          willReceiveProps: component$60.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$60.didUpdate,
          willUnmount: component$60.willUnmount,
          willUpdate: component$60.willUpdate,
          shouldUpdate: component$60.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$60.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.UniverseRetention.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$60.defaultError, R$60.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.UniverseRetention.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$60.defaultError, R$60.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$60.jsElementWrapped
        };
}

var Get$15 = {
  R: R$60,
  decodeBPG: decodeBPG$60,
  component: component$60,
  make: make$60
};

var R$61 = Bpg_response$BsConsole.Make(F$15);

function decodeBPG$61(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$61 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$61(onSuccess, handleTask, token, children) {
  return {
          debugName: component$61.debugName,
          reactClassInternal: component$61.reactClassInternal,
          handedOffState: component$61.handedOffState,
          willReceiveProps: component$61.willReceiveProps,
          didMount: component$61.didMount,
          didUpdate: component$61.didUpdate,
          willUnmount: component$61.willUnmount,
          willUpdate: component$61.willUpdate,
          shouldUpdate: component$61.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.UniverseRetention.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$61.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.UniverseRetention.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$61.defaultError, R$61.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$61.jsElementWrapped
        };
}

var Modify$15 = {
  R: R$61,
  decodeBPG: decodeBPG$61,
  component: component$61,
  make: make$61
};

var R$62 = Bpg_response$BsConsole.MakeCreate(F$15);

function decodeBPG$62(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$62 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$62(onSuccess, handleTask, token, children) {
  return {
          debugName: component$62.debugName,
          reactClassInternal: component$62.reactClassInternal,
          handedOffState: component$62.handedOffState,
          willReceiveProps: component$62.willReceiveProps,
          didMount: component$62.didMount,
          didUpdate: component$62.didUpdate,
          willUnmount: component$62.willUnmount,
          willUpdate: component$62.willUpdate,
          shouldUpdate: component$62.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.UniverseRetention.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$62.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.UniverseRetention.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$62.defaultError, R$62.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$62.jsElementWrapped
        };
}

var Create$15 = {
  R: R$62,
  decodeBPG: decodeBPG$62,
  component: component$62,
  make: make$62
};

var R$63 = Bpg_response$BsConsole.Make(F$15);

function decodeBPG$63(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$63 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$63(onSuccess, handleTask, token, children) {
  return {
          debugName: component$63.debugName,
          reactClassInternal: component$63.reactClassInternal,
          handedOffState: component$63.handedOffState,
          willReceiveProps: component$63.willReceiveProps,
          didMount: component$63.didMount,
          didUpdate: component$63.didUpdate,
          willUnmount: component$63.willUnmount,
          willUpdate: component$63.willUpdate,
          shouldUpdate: component$63.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.UniverseRetention.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$63.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.UniverseRetention.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.UniverseRetention.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$63.defaultError, R$63.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$63.jsElementWrapped
        };
}

var Delete$15 = {
  R: R$63,
  decodeBPG: decodeBPG$63,
  component: component$63,
  make: make$63
};

var UniverseRetention = {
  Get: Get$15,
  Modify: Modify$15,
  Create: Create$15,
  Delete: Delete$15
};

var F_table$16 = BacktraceParserGenerator$BsConsole.ProjectRetention.table;

var F_to_json$16 = BacktraceParserGenerator$BsConsole.ProjectRetention.to_json;

var F_of_json$16 = BacktraceParserGenerator$BsConsole.ProjectRetention.of_json;

var F_Key$16 = BacktraceParserGenerator$BsConsole.ProjectRetention.Key;

var F_to_key$16 = BacktraceParserGenerator$BsConsole.ProjectRetention.to_key;

var F$16 = {
  table: F_table$16,
  to_json: F_to_json$16,
  of_json: F_of_json$16,
  Key: F_Key$16,
  to_key: F_to_key$16
};

var R$64 = Bpg_response$BsConsole.Make(F$16);

function decodeBPG$64(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$64 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.ProjectRetention.table + "get"));

function make$64(handleTask, token, filter, children) {
  return {
          debugName: component$64.debugName,
          reactClassInternal: component$64.reactClassInternal,
          handedOffState: component$64.handedOffState,
          willReceiveProps: component$64.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$64.didUpdate,
          willUnmount: component$64.willUnmount,
          willUpdate: component$64.willUpdate,
          shouldUpdate: component$64.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$64.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.ProjectRetention.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$64.defaultError, R$64.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.ProjectRetention.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$64.defaultError, R$64.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$64.jsElementWrapped
        };
}

var Get$16 = {
  R: R$64,
  decodeBPG: decodeBPG$64,
  component: component$64,
  make: make$64
};

var R$65 = Bpg_response$BsConsole.Make(F$16);

function decodeBPG$65(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$65 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$65(onSuccess, handleTask, token, children) {
  return {
          debugName: component$65.debugName,
          reactClassInternal: component$65.reactClassInternal,
          handedOffState: component$65.handedOffState,
          willReceiveProps: component$65.willReceiveProps,
          didMount: component$65.didMount,
          didUpdate: component$65.didUpdate,
          willUnmount: component$65.willUnmount,
          willUpdate: component$65.willUpdate,
          shouldUpdate: component$65.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.ProjectRetention.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$65.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.ProjectRetention.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$65.defaultError, R$65.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$65.jsElementWrapped
        };
}

var Modify$16 = {
  R: R$65,
  decodeBPG: decodeBPG$65,
  component: component$65,
  make: make$65
};

var R$66 = Bpg_response$BsConsole.MakeCreate(F$16);

function decodeBPG$66(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$66 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$66(onSuccess, handleTask, token, children) {
  return {
          debugName: component$66.debugName,
          reactClassInternal: component$66.reactClassInternal,
          handedOffState: component$66.handedOffState,
          willReceiveProps: component$66.willReceiveProps,
          didMount: component$66.didMount,
          didUpdate: component$66.didUpdate,
          willUnmount: component$66.willUnmount,
          willUpdate: component$66.willUpdate,
          shouldUpdate: component$66.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.ProjectRetention.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$66.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.ProjectRetention.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$66.defaultError, R$66.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$66.jsElementWrapped
        };
}

var Create$16 = {
  R: R$66,
  decodeBPG: decodeBPG$66,
  component: component$66,
  make: make$66
};

var R$67 = Bpg_response$BsConsole.Make(F$16);

function decodeBPG$67(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$67 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$67(onSuccess, handleTask, token, children) {
  return {
          debugName: component$67.debugName,
          reactClassInternal: component$67.reactClassInternal,
          handedOffState: component$67.handedOffState,
          willReceiveProps: component$67.willReceiveProps,
          didMount: component$67.didMount,
          didUpdate: component$67.didUpdate,
          willUnmount: component$67.willUnmount,
          willUpdate: component$67.willUpdate,
          shouldUpdate: component$67.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.ProjectRetention.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$67.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.ProjectRetention.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ProjectRetention.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$67.defaultError, R$67.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$67.jsElementWrapped
        };
}

var Delete$16 = {
  R: R$67,
  decodeBPG: decodeBPG$67,
  component: component$67,
  make: make$67
};

var ProjectRetention = {
  Get: Get$16,
  Modify: Modify$16,
  Create: Create$16,
  Delete: Delete$16
};

var F_table$17 = BacktraceParserGenerator$BsConsole.ResourceOverride.table;

var F_to_json$17 = BacktraceParserGenerator$BsConsole.ResourceOverride.to_json;

var F_of_json$17 = BacktraceParserGenerator$BsConsole.ResourceOverride.of_json;

var F_Key$17 = BacktraceParserGenerator$BsConsole.ResourceOverride.Key;

var F_to_key$17 = BacktraceParserGenerator$BsConsole.ResourceOverride.to_key;

var F$17 = {
  table: F_table$17,
  to_json: F_to_json$17,
  of_json: F_of_json$17,
  Key: F_Key$17,
  to_key: F_to_key$17
};

var R$68 = Bpg_response$BsConsole.Make(F$17);

function decodeBPG$68(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$68 = ReasonReact.reducerComponent("BpgController-BsConsole" + (BacktraceParserGenerator$BsConsole.ResourceOverride.table + "get"));

function make$68(handleTask, token, filter, children) {
  return {
          debugName: component$68.debugName,
          reactClassInternal: component$68.reactClassInternal,
          handedOffState: component$68.handedOffState,
          willReceiveProps: component$68.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$68.didUpdate,
          willUnmount: component$68.willUnmount,
          willUpdate: component$68.willUpdate,
          shouldUpdate: component$68.shouldUpdate,
          render: (function (self) {
              var update = function (param) {
                return Curry._1(self.send, /* Update */1);
              };
              return Curry._2(children, self.state, update);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$68.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action === /* Request */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.ResourceOverride.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$68.defaultError, R$68.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* Failure */Block.__(1, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* Success */Block.__(0, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })
                          ]);
                } else {
                  return /* SideEffects */Block.__(1, [(function (self) {
                                var json = Json_encode.object_(/* :: */[
                                      /* tuple */[
                                        "type",
                                        "configuration/" + BacktraceParserGenerator$BsConsole.ResourceOverride.table
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "action",
                                          "get"
                                        ],
                                        /* [] */0
                                      ]
                                    ]);
                                return Curry._2(handleTask, (function (json) {
                                              var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                              var match = Belt_Option.mapWithDefault(__x, R$68.defaultError, R$68.decode);
                                              if (match.tag) {
                                                return Curry._1(self.send, /* UpdateFailure */Block.__(3, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              } else {
                                                return Curry._1(self.send, /* UpdateSuccess */Block.__(2, [
                                                              match[0],
                                                              match[1]
                                                            ]));
                                              }
                                            }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                  token,
                                                  /* :: */[
                                                    json,
                                                    /* [] */0
                                                  ]
                                                ])));
                              })]);
                }
              }
              switch (action.tag | 0) {
                case /* Success */0 :
                    var result = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result, (function (filter) {
                                            return Curry._1(filter, result);
                                          }))])
                              }]);
                case /* UpdateSuccess */2 :
                    var result$1 = action[1];
                    return /* Update */Block.__(0, [{
                                remote: /* Success */Block.__(0, [Belt_Option.mapWithDefault(filter, result$1, (function (filter) {
                                            return Curry._1(filter, result$1);
                                          }))])
                              }]);
                case /* Failure */1 :
                case /* UpdateFailure */3 :
                    break;
                
              }
              return /* Update */Block.__(0, [{
                          remote: /* Failure */Block.__(1, [action[0]])
                        }]);
            }),
          jsElementWrapped: component$68.jsElementWrapped
        };
}

var Get$17 = {
  R: R$68,
  decodeBPG: decodeBPG$68,
  component: component$68,
  make: make$68
};

var R$69 = Bpg_response$BsConsole.Make(F$17);

function decodeBPG$69(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$69 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$69(onSuccess, handleTask, token, children) {
  return {
          debugName: component$69.debugName,
          reactClassInternal: component$69.reactClassInternal,
          handedOffState: component$69.handedOffState,
          willReceiveProps: component$69.willReceiveProps,
          didMount: component$69.didMount,
          didUpdate: component$69.didUpdate,
          willUnmount: component$69.willUnmount,
          willUpdate: component$69.willUpdate,
          shouldUpdate: component$69.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4],
                              param[5]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.ResourceOverride.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$69.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var fields = action[2];
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.ResourceOverride.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "modify"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.to_key, entity))
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "fields",
                                                Json_encode.object_(List.map((function (param) {
                                                            var v = param[1];
                                                            var tmp = v === "__BACKTRACE_BPG__NULL__VALUE__" ? null : v;
                                                            return /* tuple */[
                                                                    param[0],
                                                                    tmp
                                                                  ];
                                                          }), Curry._1(Form$BsConsole.Fields.toList, fields)))
                                              ],
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$69.defaultError, R$69.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$69.jsElementWrapped
        };
}

var Modify$17 = {
  R: R$69,
  decodeBPG: decodeBPG$69,
  component: component$69,
  make: make$69
};

var R$70 = Bpg_response$BsConsole.MakeCreate(F$17);

function decodeBPG$70(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$70 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$70(onSuccess, handleTask, token, children) {
  return {
          debugName: component$70.debugName,
          reactClassInternal: component$70.reactClassInternal,
          handedOffState: component$70.handedOffState,
          willReceiveProps: component$70.willReceiveProps,
          didMount: component$70.didMount,
          didUpdate: component$70.didUpdate,
          willUnmount: component$70.willUnmount,
          willUpdate: component$70.willUpdate,
          shouldUpdate: component$70.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1],
                              param[2],
                              param[3],
                              param[4]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.ResourceOverride.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$70.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.ResourceOverride.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "create"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "object",
                                              Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.to_json, entity)
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$70.defaultError, R$70.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                }
                                                var result = match[1];
                                                if (result !== undefined) {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                Caml_option.valFromOption(result),
                                                                Curry._1(updater, entity)
                                                              ]));
                                                } else {
                                                  console.log("INVARIANT");
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                "Something went wrong",
                                                                undefined
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Success */Block.__(0, [result])
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$70.jsElementWrapped
        };
}

var Create$17 = {
  R: R$70,
  decodeBPG: decodeBPG$70,
  component: component$70,
  make: make$70
};

var R$71 = Bpg_response$BsConsole.Make(F$17);

function decodeBPG$71(json) {
  return Belt_List.head(Json_decode.field("results", (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.tuple2((function (param) {
                                                return Json_decode.field("code", Json_decode.$$int, param);
                                              }), (function (param) {
                                                return Json_decode.field("text", Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), json));
}

var component$71 = ReasonReact.reducerComponent("BpgController-BsConsole");

function make$71(onSuccess, handleTask, token, children) {
  return {
          debugName: component$71.debugName,
          reactClassInternal: component$71.reactClassInternal,
          handedOffState: component$71.handedOffState,
          willReceiveProps: component$71.willReceiveProps,
          didMount: component$71.didMount,
          didUpdate: component$71.didUpdate,
          willUnmount: component$71.willUnmount,
          willUpdate: component$71.willUpdate,
          shouldUpdate: component$71.shouldUpdate,
          render: (function (self) {
              var handleSubmit = function (param) {
                return Curry._1(self.send, /* Request */Block.__(0, [
                              param[0],
                              param[1]
                            ]));
              };
              return Curry._3(children, self.state, handleSubmit, BacktraceParserGenerator$BsConsole.ResourceOverride.to_key);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$71.retainedProps,
          reducer: (function (action, _state) {
              switch (action.tag | 0) {
                case /* Request */0 :
                    var updater = action[1];
                    var entity = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* Loading */1
                              },
                              (function (self) {
                                  var json = Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "type",
                                          "configuration/" + BacktraceParserGenerator$BsConsole.ResourceOverride.table
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "action",
                                            "delete"
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "key",
                                              Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.Key.to_json, Curry._1(BacktraceParserGenerator$BsConsole.ResourceOverride.to_key, entity))
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ]);
                                  return Curry._2(handleTask, (function (json) {
                                                var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                                                var match = Belt_Option.mapWithDefault(__x, R$71.defaultError, R$71.decode);
                                                if (match.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [
                                                                match[0],
                                                                match[1]
                                                              ]));
                                                } else {
                                                  return Curry._1(self.send, /* Success */Block.__(1, [
                                                                match[0],
                                                                match[1],
                                                                Curry._1(updater, entity)
                                                              ]));
                                                }
                                              }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                                                    token,
                                                    /* :: */[
                                                      json,
                                                      /* [] */0
                                                    ]
                                                  ])));
                                })
                            ]);
                case /* Success */1 :
                    var updated = action[2];
                    var result = action[1];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                remote: /* NotAsked */0
                              },
                              (function (_self) {
                                  return Curry._2(onSuccess, result, updated);
                                })
                            ]);
                case /* Failure */2 :
                    return /* Update */Block.__(0, [{
                                remote: /* Failure */Block.__(1, [action[0]])
                              }]);
                
              }
            }),
          jsElementWrapped: component$71.jsElementWrapped
        };
}

var Delete$17 = {
  R: R$71,
  decodeBPG: decodeBPG$71,
  component: component$71,
  make: make$71
};

var ResourceOverride = {
  Get: Get$17,
  Modify: Modify$17,
  Create: Create$17,
  Delete: Delete$17
};

exports.MakeCreate = MakeCreate;
exports.MakeGet = MakeGet;
exports.MakeModify = MakeModify;
exports.MakeDelete = MakeDelete;
exports.Make = Make;
exports.User = User;
exports.SignupWhitelist = SignupWhitelist;
exports.SignupPending = SignupPending;
exports.Universe = Universe;
exports.Project = Project;
exports.WatcherNew = WatcherNew;
exports.Smtp = Smtp;
exports.Ssl = Ssl;
exports.Listener = Listener;
exports.Attribute = Attribute;
exports.Token = Token;
exports.ApiToken = ApiToken;
exports.Query = Query;
exports.Report = Report;
exports.InstanceRetention = InstanceRetention;
exports.UniverseRetention = UniverseRetention;
exports.ProjectRetention = ProjectRetention;
exports.ResourceOverride = ResourceOverride;
/* R Not a pure module */
