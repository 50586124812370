// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");

function initial(param) {
  return {
          hover: false
        };
}

function hover(t) {
  return t.hover;
}

var State = {
  initial: initial,
  hover: hover
};

function reducer(action, state) {
  return /* Update */Block.__(0, [{
              hover: action[0]
            }]);
}

var Action = {
  reducer: reducer
};

var hoverCard = Css.style(/* :: */[
      Css.marginRight(Css.em(1.0)),
      /* :: */[
        Css.marginBottom(Css.em(1.0)),
        /* :: */[
          Css.height(Css.px(80)),
          /* :: */[
            Css.width(Css.px(130)),
            /* :: */[
              Css.cursor(/* pointer */-786317123),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var hoverCardContent = Css.style(/* :: */[
      Css.height(Css.pct(100.0)),
      /* :: */[
        Css.padding2(Css.px(16), /* zero */-789508312),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.flexDirection(/* column */-963948842),
            /* :: */[
              Css.justifyContent(/* spaceBetween */516682146),
              /* :: */[
                Css.alignItems(/* center */98248149),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  hoverCard: hoverCard,
  hoverCardContent: hoverCardContent
};

function render(iconUrl, raised, title) {
  var tmp = iconUrl === "" ? null : React.createElement("img", {
          alt: title,
          src: iconUrl
        });
  return ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, hoverCard, undefined, raised, undefined, [React.createElement("div", {
                        className: hoverCardContent
                      }, tmp, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(title)])))]));
}

function make(title, iconUrl, onClick, _children) {
  var init = ReasonReact.reducerComponent("PS_Documentation_DocCard-BsConsole");
  return {
          debugName: init.debugName,
          reactClassInternal: init.reactClassInternal,
          handedOffState: init.handedOffState,
          willReceiveProps: init.willReceiveProps,
          didMount: init.didMount,
          didUpdate: init.didUpdate,
          willUnmount: init.willUnmount,
          willUpdate: init.willUpdate,
          shouldUpdate: init.shouldUpdate,
          render: (function (self) {
              return React.createElement("div", {
                          onClick: onClick,
                          onMouseEnter: (function (_e) {
                              return Curry._1(self.send, /* SetHover */[true]);
                            }),
                          onMouseLeave: (function (_e) {
                              return Curry._1(self.send, /* SetHover */[false]);
                            })
                        }, render(iconUrl, self.state.hover, title));
            }),
          initialState: initial,
          retainedProps: init.retainedProps,
          reducer: reducer,
          jsElementWrapped: init.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.State = State;
exports.Action = Action;
exports.Style = Style;
exports.render = render;
exports.make = make;
/* hoverCard Not a pure module */
