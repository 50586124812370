// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Col2$BsConsole = require("./components/Col2.js");
var Crdb$BsConsole = require("./crdb.js");
var Fuzz$BsConsole = require("./bindings/Fuzz.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Util$BsConsole = require("./util.js");
var Route$BsConsole = require("./route.js");
var Colors$BsConsole = require("./Colors.js");
var ListUtils$BsConsole = require("./ListUtils.js");
var Tab = require("@material-ui/core/Tab");
var FilterInput$BsConsole = require("./FilterInput.js");
var FilterPills$BsConsole = require("./FilterPills.js");
var List = require("@material-ui/core/List");
var Tabs = require("@material-ui/core/Tabs");
var Add = require("@material-ui/icons/Add");
var BtTypography$BsConsole = require("./BtTypography.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var Input = require("@material-ui/core/Input");
var Edit = require("@material-ui/icons/Edit");
var Info = require("@material-ui/icons/Info");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var Check = require("@material-ui/icons/Check");
var Tooltip = require("@material-ui/core/Tooltip");
var Search = require("@material-ui/icons/Search");
var ListItem = require("@material-ui/core/ListItem");
var MenuItem = require("@material-ui/core/MenuItem");
var InputLabel = require("@material-ui/core/InputLabel");
var Typography = require("@material-ui/core/Typography");
var ListItemIcon = require("@material-ui/core/ListItemIcon");
var ListItemText = require("@material-ui/core/ListItemText");

function groupToStr(param) {
  switch (param) {
    case /* Suggested */0 :
        return "suggested";
    case /* ErrorDetails */1 :
        return "error_details";
    case /* DevicesAndPlatforms */2 :
        return "devices_and_platforms";
    case /* MemoryAndProcess */3 :
        return "memory_and_process";
    case /* Misc */4 :
        return "misc";
    case /* Custom */5 :
        return "custom";
    
  }
}

function groupToFriendlyStr(param) {
  switch (param) {
    case /* Suggested */0 :
        return "Suggested";
    case /* ErrorDetails */1 :
        return "Error Details";
    case /* DevicesAndPlatforms */2 :
        return "Devices And Platforms";
    case /* MemoryAndProcess */3 :
        return "Memory And Process";
    case /* Misc */4 :
        return "Misc";
    case /* Custom */5 :
        return "Custom";
    
  }
}

function operatorToString(f) {
  switch (f) {
    case /* Equal */0 :
        return "equal";
    case /* NotEqual */1 :
        return "not-equal";
    case /* Contains */2 :
        return "contains";
    case /* NotContains */3 :
        return "not-contains";
    case /* RegularExpression */4 :
        return "regular-expression";
    case /* InverseRegularExpression */5 :
        return "inverse-regular-expression";
    case /* GreaterThan */6 :
        return "greater-than";
    case /* LessThan */7 :
        return "less-than";
    case /* AtLeast */8 :
        return "at-least";
    case /* AtMost */9 :
        return "at-most";
    case /* AssignedTo */10 :
        return "assigned-to";
    case /* NotAssignedTo */11 :
        return "not-assigned-to";
    case /* LinkedTo */12 :
        return "linked-to";
    case /* NotLinkedTo */13 :
        return "not-linked-to";
    
  }
}

var suggested = /* :: */[
  {
    group: /* Suggested */0,
    attributeName: "error.type",
    friendlyName: "Error Type",
    defaultOperator: /* Equal */0
  },
  /* :: */[
    {
      group: /* Suggested */0,
      attributeName: "device.model",
      friendlyName: "Device Model",
      defaultOperator: /* Equal */0
    },
    /* :: */[
      {
        group: /* Suggested */0,
        attributeName: "uname.sysname",
        friendlyName: "Platform",
        defaultOperator: /* Equal */0
      },
      /* [] */0
    ]
  ]
];

var errorDetails = /* :: */[
  {
    group: /* ErrorDetails */1,
    attributeName: "error.type",
    friendlyName: "Error Type",
    defaultOperator: /* Equal */0
  },
  /* :: */[
    {
      group: /* ErrorDetails */1,
      attributeName: "classifier",
      friendlyName: "Error Classifier",
      defaultOperator: /* Equal */0
    },
    /* :: */[
      {
        group: /* ErrorDetails */1,
        attributeName: "error.message",
        friendlyName: "Error Message",
        defaultOperator: /* Equal */0
      },
      /* :: */[
        {
          group: /* ErrorDetails */1,
          attributeName: "callstack",
          friendlyName: "Callstack",
          defaultOperator: /* Contains */2
        },
        /* :: */[
          {
            group: /* ErrorDetails */1,
            attributeName: "callstack.files",
            friendlyName: "File",
            defaultOperator: /* Contains */2
          },
          /* :: */[
            {
              group: /* ErrorDetails */1,
              attributeName: "callstack.functions",
              friendlyName: "Function",
              defaultOperator: /* Contains */2
            },
            /* :: */[
              {
                group: /* ErrorDetails */1,
                attributeName: "callstack.modules",
                friendlyName: "Modules",
                defaultOperator: /* Contains */2
              },
              /* [] */0
            ]
          ]
        ]
      ]
    ]
  ]
];

var devicesAndPlatforms = /* :: */[
  {
    group: /* DevicesAndPlatforms */2,
    attributeName: "device.model",
    friendlyName: "Device Model",
    defaultOperator: /* Equal */0
  },
  /* :: */[
    {
      group: /* DevicesAndPlatforms */2,
      attributeName: "uname.sysname",
      friendlyName: "Platform",
      defaultOperator: /* Equal */0
    },
    /* :: */[
      {
        group: /* DevicesAndPlatforms */2,
        attributeName: "device.type",
        friendlyName: "Device Type",
        defaultOperator: /* Equal */0
      },
      /* :: */[
        {
          group: /* DevicesAndPlatforms */2,
          attributeName: "device.name",
          friendlyName: "Device Name",
          defaultOperator: /* Equal */0
        },
        /* :: */[
          {
            group: /* DevicesAndPlatforms */2,
            attributeName: "uname.version",
            friendlyName: "Platform Version",
            defaultOperator: /* Equal */0
          },
          /* :: */[
            {
              group: /* DevicesAndPlatforms */2,
              attributeName: "cpu.brand",
              friendlyName: "CPU Brand",
              defaultOperator: /* Equal */0
            },
            /* [] */0
          ]
        ]
      ]
    ]
  ]
];

var memoryAndProcess = /* :: */[
  {
    group: /* MemoryAndProcess */3,
    attributeName: "process.age",
    friendlyName: "Length of Process",
    defaultOperator: /* LessThan */7
  },
  /* :: */[
    {
      group: /* MemoryAndProcess */3,
      attributeName: "object.size",
      friendlyName: "Object Size",
      defaultOperator: /* Equal */0
    },
    /* :: */[
      {
        group: /* MemoryAndProcess */3,
        attributeName: "process.thread.count",
        friendlyName: "Thread Count",
        defaultOperator: /* Equal */0
      },
      /* :: */[
        {
          group: /* MemoryAndProcess */3,
          attributeName: "system.memory.free",
          friendlyName: "Free Memory",
          defaultOperator: /* LessThan */7
        },
        /* :: */[
          {
            group: /* MemoryAndProcess */3,
            attributeName: "system.memory.inactive",
            friendlyName: "Inactive Memory",
            defaultOperator: /* LessThan */7
          },
          /* :: */[
            {
              group: /* MemoryAndProcess */3,
              attributeName: "system.memory.total",
              friendlyName: "Total Memory",
              defaultOperator: /* LessThan */7
            },
            /* :: */[
              {
                group: /* MemoryAndProcess */3,
                attributeName: "fault.address",
                friendlyName: "Fault Address",
                defaultOperator: /* Equal */0
              },
              /* [] */0
            ]
          ]
        ]
      ]
    ]
  ]
];

var misc = /* :: */[
  {
    group: /* Misc */4,
    attributeName: "application",
    friendlyName: "Application",
    defaultOperator: /* Equal */0
  },
  /* :: */[
    {
      group: /* Misc */4,
      attributeName: "_tx",
      friendlyName: "Error Object ID",
      defaultOperator: /* Equal */0
    },
    /* :: */[
      {
        group: /* Misc */4,
        attributeName: "guid",
        friendlyName: "User ID",
        defaultOperator: /* Equal */0
      },
      /* :: */[
        {
          group: /* Misc */4,
          attributeName: "_rxid",
          friendlyName: "Transaction ID",
          defaultOperator: /* Equal */0
        },
        /* :: */[
          {
            group: /* Misc */4,
            attributeName: "lang.name",
            friendlyName: "Language",
            defaultOperator: /* Equal */0
          },
          /* :: */[
            {
              group: /* Misc */4,
              attributeName: "client.ipv4",
              friendlyName: "IP Address",
              defaultOperator: /* Equal */0
            },
            /* [] */0
          ]
        ]
      ]
    ]
  ]
];

var all = Belt_List.concatMany([
      suggested,
      errorDetails,
      devicesAndPlatforms,
      memoryAndProcess,
      misc
    ]);

function getIndexKey(attributeName, group) {
  return attributeName + ("_" + groupToStr(group));
}

var indexedByAttributeNameAndGroup = Js_dict.fromList(Belt_List.map(all, (function (d) {
            return /* tuple */[
                    getIndexKey(d.attributeName, d.group),
                    d
                  ];
          })));

var indexedByAttributeName = Js_dict.fromList(Belt_List.map(all, (function (d) {
            return /* tuple */[
                    d.attributeName,
                    d
                  ];
          })));

function getForGroup(attrName, group) {
  return Js_dict.get(indexedByAttributeNameAndGroup, getIndexKey(attrName, group));
}

function getGroup(group) {
  return Belt_List.keep(all, (function (d) {
                return d.group === group;
              }));
}

function getForAnyGroup(attrName) {
  return Js_dict.get(indexedByAttributeName, attrName);
}

function filterToGroup(attributes, group) {
  if (group >= 5) {
    return Belt_List.filter(attributes, (function (a) {
                  return a.custom === true;
                }));
  } else {
    return Belt_List.keepMap(attributes, (function (attr) {
                  return Belt_Option.flatMap(getForGroup(attr.name, group), (function (attrDefaultData) {
                                if (attrDefaultData.group === group) {
                                  return attr;
                                }
                                
                              }));
                }));
  }
}

var Data = {
  operatorToString: operatorToString,
  suggested: suggested,
  errorDetails: errorDetails,
  devicesAndPlatforms: devicesAndPlatforms,
  memoryAndProcess: memoryAndProcess,
  misc: misc,
  all: all,
  getIndexKey: getIndexKey,
  indexedByAttributeNameAndGroup: indexedByAttributeNameAndGroup,
  indexedByAttributeName: indexedByAttributeName,
  getForGroup: getForGroup,
  getGroup: getGroup,
  getForAnyGroup: getForAnyGroup,
  filterToGroup: filterToGroup
};

var Groups = {
  groupToStr: groupToStr,
  groupToFriendlyStr: groupToFriendlyStr,
  Data: Data
};

var sortedOps = /* :: */[
  "equal",
  /* :: */[
    "contains",
    /* :: */[
      "regular-expression",
      /* :: */[
        "inverse-regular-expression",
        /* :: */[
          "greater-than",
          /* :: */[
            "less-than",
            /* :: */[
              "at-least",
              /* :: */[
                "at-most",
                /* :: */[
                  "not-equal",
                  /* :: */[
                    "not-contains",
                    /* :: */[
                      "not-assigned-to",
                      /* :: */[
                        "not-linked-to",
                        /* :: */[
                          "assigned-to",
                          /* :: */[
                            "linked-to",
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var sortPriority = Js_dict.fromList(Belt_List.mapWithIndex(sortedOps, (function (index, v) {
            return /* tuple */[
                    v,
                    index
                  ];
          })));

function sort(ops) {
  return Belt_List.sort(ops, (function (a, b) {
                var prioA = Js_dict.get(sortPriority, a);
                var prioB = Js_dict.get(sortPriority, b);
                if (prioA !== undefined && prioB !== undefined) {
                  return prioA - prioB | 0;
                } else {
                  return -1;
                }
              }));
}

var OperatorFriendlySort = {
  sortedOps: sortedOps,
  sortPriority: sortPriority,
  sort: sort
};

function ofJson(json) {
  return {
          project: Json_decode.field("project", Json_decode.string, json),
          attributeName: Json_decode.field("attributeName", Json_decode.string, json),
          operator: Json_decode.field("operator", Json_decode.string, json)
        };
}

function toJson(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "attributeName",
                t.attributeName
              ],
              /* :: */[
                /* tuple */[
                  "operator",
                  t.operator
                ],
                /* :: */[
                  /* tuple */[
                    "project",
                    t.project
                  ],
                  /* [] */0
                ]
              ]
            ]);
}

var RecentAttribute = {
  ofJson: ofJson,
  toJson: toJson
};

function toJson$1(t) {
  return Json_encode.object_(Belt_List.map(Belt_List.fromArray(Js_dict.entries(t)), (function (param) {
                    var valueJson = Json_encode.array(toJson, param[1]);
                    return /* tuple */[
                            param[0],
                            valueJson
                          ];
                  })));
}

function ofJson$1(json) {
  return Json_decode.dict((function (param) {
                return Json_decode.array(ofJson, param);
              }), json);
}

function _getAll(param) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Caml_option.null_to_opt(localStorage.getItem("Backtrace-Recent-Attributes")), Json.parse), (function (j) {
                    return Json_decode.dict((function (param) {
                                  return Json_decode.array(ofJson, param);
                                }), j);
                  })), { });
}

function _get(projectName) {
  return Belt_Option.getWithDefault(Js_dict.get(_getAll(undefined), projectName), []);
}

function persist(ts) {
  localStorage.setItem("Backtrace-Recent-Attributes", Json.stringify(toJson$1(ts)));
  
}

function save(attr) {
  var ts = _getAll(undefined);
  var attrs = Belt_Array.keep(Belt_Option.getWithDefault(Js_dict.get(ts, attr.project), []), (function (param) {
          return param.attributeName !== attr.attributeName;
        }));
  var attrs$1 = attrs.length < 5 ? attrs : Belt_Array.slice(attrs, 0, 5);
  var attrs$2 = Belt_Array.concat([attr], attrs$1);
  ts[attr.project] = attrs$2;
  return persist(ts);
}

function getAttrs(project, activeAttrs) {
  var recents = _get(project);
  var activesDict = { };
  Belt_List.forEach(activeAttrs, (function (a) {
          activesDict[a.name] = a;
          
        }));
  return Belt_Array.keepMap(recents, (function (recentAttr) {
                var active = Js_dict.get(activesDict, recentAttr.attributeName);
                if (active !== undefined) {
                  return /* tuple */[
                          active,
                          recentAttr.operator
                        ];
                }
                
              }));
}

function getOps(project) {
  var recents = _get(project);
  var recentsDict = { };
  Belt_Array.forEach(recents, (function (r) {
          recentsDict[r.attributeName] = r.operator;
          
        }));
  return recentsDict;
}

var RecentAttributes = {
  maxRecentAttrs: 5,
  toJson: toJson$1,
  ofJson: ofJson$1,
  _getAll: _getAll,
  _get: _get,
  persist: persist,
  save: save,
  getAttrs: getAttrs,
  getOps: getOps
};

var tab = Css.style(/* :: */[
      Css.unsafe("min-width", "unset"),
      /* :: */[
        Css.width(Css.pct(16.66)),
        /* :: */[
          Css.fontSize(Css.rem(1)),
          /* :: */[
            Css.hover(/* :: */[
                  Css.opacity(0.95),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var indicator = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
      /* [] */0
    ]);

var labelContainer = Css.style(/* :: */[
      Css.padding(Css.rem(0.2)),
      /* [] */0
    ]);

var Style = {
  tab: tab,
  indicator: indicator,
  labelContainer: labelContainer
};

function FilterQueryBuilder$Tabs(Props) {
  var tabData = Props.tabData;
  var initialValue = Props.initialValue;
  var callback = Props.callback;
  var match = React.useState((function () {
          return initialValue;
        }));
  var setValue = match[1];
  var value = match[0];
  React.useEffect((function () {
          Curry._1(callback, value);
          
        }), [value]);
  return React.createElement(Tabs.default, {
              value: value,
              classes: {
                root: Css.style(/* :: */[
                      Css.width(Css.pct(100)),
                      /* [] */0
                    ]),
                indicator: indicator
              },
              onChange: (function (_evt, value) {
                  return Curry._1(setValue, (function (param) {
                                return value;
                              }));
                }),
              children: Belt_List.toArray(Belt_List.map(tabData, (function (param) {
                          return React.createElement(Tab.default, {
                                      classes: {
                                        root: tab,
                                        labelContainer: labelContainer
                                      },
                                      value: param[0],
                                      label: I18N$BsConsole.show(undefined, param[1])
                                    });
                        })))
            });
}

var Tabs$1 = {
  Style: Style,
  make: FilterQueryBuilder$Tabs
};

var root = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.padding(/* zero */-789508312),
        /* [] */0
      ]
    ]);

var List$1 = {
  root: root
};

var listItem = Css.style(/* :: */[
      Css.borderBottom(Css.px(1), Css.solid, Css.hex("37474F")),
      /* :: */[
        Css.paddingTop(/* zero */-789508312),
        /* :: */[
          Css.paddingBottom(/* zero */-789508312),
          /* :: */[
            Css.minHeight(Css.rem(2.75)),
            /* :: */[
              Css.hover(/* :: */[
                    Css.selector(".add-icon", /* :: */[
                          Css.color(Css.hex("FFFFFF")),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var selectedBg = Css.style(/* :: */[
      Css.important(Css.backgroundColor(Css.hex("547581"))),
      /* [] */0
    ]);

var listItemSelected = Css.merge(/* :: */[
      listItem,
      /* :: */[
        selectedBg,
        /* [] */0
      ]
    ]);

var textPrimary = Css.style(/* :: */[
      Css.color(Css.hex("FFFFFF")),
      /* :: */[
        Css.lineHeight(Css.rem(1.5)),
        /* :: */[
          Css.fontSize(Css.rem(0.875)),
          /* [] */0
        ]
      ]
    ]);

var spanPrimary = Css.style(/* :: */[
      Css.paddingRight(Css.rem(0.55)),
      /* :: */[
        Css.unsafe("overflow-wrap", "break-word"),
        /* [] */0
      ]
    ]);

var textSecondary = Css.style(/* :: */[
      Css.lineHeight(Css.rem(1.25)),
      /* :: */[
        Css.fontSize(Css.rem(0.8125)),
        /* :: */[
          Css.color(Css.hex("CDCDCD")),
          /* [] */0
        ]
      ]
    ]);

var textTertiary = Css.style(/* :: */[
      Css.display(/* inlineBlock */-147785676),
      /* :: */[
        Css.fontFamily("Roboto Mono"),
        /* :: */[
          Css.fontSize(Css.rem(0.625)),
          /* [] */0
        ]
      ]
    ]);

var textRoot = Css.style(/* :: */[
      Css.padding(/* zero */-789508312),
      /* [] */0
    ]);

var iconRoot = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.white)),
      /* [] */0
    ]);

var ListItem$1 = {
  listItem: listItem,
  selectedBg: selectedBg,
  listItemSelected: listItemSelected,
  textPrimary: textPrimary,
  spanPrimary: spanPrimary,
  textSecondary: textSecondary,
  textTertiary: textTertiary,
  textRoot: textRoot,
  iconRoot: iconRoot
};

var Style$1 = {
  List: List$1,
  ListItem: ListItem$1
};

function makeItem(item, primaryTxt, secondaryTxt, tertiaryTxt, chip, param) {
  return {
          item: item,
          primaryTxt: primaryTxt,
          secondaryTxt: secondaryTxt,
          tertiaryTxt: tertiaryTxt,
          chip: chip
        };
}

function FilterQueryBuilder$SelectableList(Props) {
  var items = Props.items;
  var selectedItem = Props.selectedItem;
  var eq = Props.eq;
  var onChange = Props.onChange;
  return React.createElement(List.default, {
              classes: {
                root: root
              },
              children: Belt_List.toArray(Belt_List.map(items, (function (item$prime) {
                          var chip = item$prime.chip;
                          var tertiaryTxt = item$prime.tertiaryTxt;
                          var item = item$prime.item;
                          var secondaryTxt = Belt_Option.map(item$prime.secondaryTxt, I18N$BsConsole.showSkip);
                          var isSelected = selectedItem !== undefined ? Curry._2(eq, Caml_option.valFromOption(selectedItem), item) : false;
                          var icon = isSelected ? React.createElement(Check.default, { }) : React.createElement(Add.default, {
                                  className: Css.merge(/* :: */[
                                        "add-icon",
                                        /* :: */[
                                          Css.style(/* :: */[
                                                Css.color(Css.hex("547580")),
                                                /* [] */0
                                              ]),
                                          /* [] */0
                                        ]
                                      ])
                                });
                          var itemStyle = isSelected ? listItemSelected : listItem;
                          var tmp = {
                            classes: {
                              primary: textPrimary,
                              secondary: textSecondary,
                              root: textRoot
                            },
                            children: null
                          };
                          if (secondaryTxt !== undefined) {
                            tmp.secondary = Caml_option.valFromOption(secondaryTxt);
                          }
                          return React.createElement(ListItem.default, {
                                      classes: {
                                        root: itemStyle
                                      },
                                      button: true,
                                      onClick: (function (_evt) {
                                          return Curry._1(onChange, item);
                                        }),
                                      children: null
                                    }, React.createElement(ListItemIcon.default, {
                                          classes: {
                                            root: iconRoot
                                          },
                                          children: icon
                                        }), React.createElement(ListItemText.default, tmp, React.createElement("span", {
                                              className: spanPrimary
                                            }, I18N$BsConsole.showSkip(item$prime.primaryTxt)), tertiaryTxt !== undefined ? React.createElement("div", {
                                                className: textTertiary
                                              }, I18N$BsConsole.showSkip(tertiaryTxt)) : null), chip !== undefined ? Caml_option.valFromOption(chip) : null);
                        })))
            });
}

var SelectableList = {
  Style: Style$1,
  makeItem: makeItem,
  make: FilterQueryBuilder$SelectableList
};

function FilterQueryBuilder$AttributeList(Props) {
  var attributes = Props.attributes;
  var allAttributes = Props.allAttributes;
  var group = Props.group;
  var selected = Props.selected;
  var projectName = Props.projectName;
  var onChange = Props.onChange;
  var attrsDict = Js_dict.fromList(Belt_List.map(attributes, (function (attr) {
              return /* tuple */[
                      attr.name,
                      true
                    ];
            })));
  var recents = Belt_List.fromArray(Belt_Array.keep(Belt_Array.map(getAttrs(projectName, allAttributes), (function (param) {
                  return param[0];
                })), (function (r) {
              return !Belt_Option.getWithDefault(Js_dict.get(attrsDict, r.name), false);
            })));
  var recentsDict = Js_dict.fromList(Belt_List.map(recents, (function (recentAttr) {
              return /* tuple */[
                      recentAttr.name,
                      true
                    ];
            })));
  var items$prime = group === /* Suggested */0 ? Belt_List.concat(attributes, recents) : attributes;
  var items = Belt_List.map(items$prime, (function (i) {
          var isRecent = group === /* Suggested */0 && Belt_Option.getWithDefault(Js_dict.get(recentsDict, i.name), false);
          var groupData = isRecent ? Js_dict.get(indexedByAttributeName, i.name) : getForGroup(i.name, group);
          var primaryTxt = Belt_Option.mapWithDefault(groupData, i.name, (function (d) {
                  return d.friendlyName;
                }));
          var secondaryTxt = i.description;
          var tertiaryTxt = i.name;
          var chip = group >= 5 ? React.createElement(FilterPills$BsConsole.Custom.make, { }) : (
              isRecent ? React.createElement(FilterPills$BsConsole.Recent.make, { }) : React.createElement(FilterPills$BsConsole.Default.make, { })
            );
          return makeItem(i, primaryTxt, secondaryTxt, tertiaryTxt, Caml_option.some(chip), undefined);
        }));
  var eq = function (a, b) {
    return a.name === b.name;
  };
  return React.createElement(FilterQueryBuilder$SelectableList, {
              items: items,
              selectedItem: selected,
              eq: eq,
              onChange: onChange
            });
}

var AttributeList = {
  make: FilterQueryBuilder$AttributeList
};

function FilterQueryBuilder$UnindexedAttributeList(Props) {
  var attributes = Props.attributes;
  var allAttributes = Props.allAttributes;
  var selected = Props.selected;
  var group = Props.group;
  var onChange = Props.onChange;
  var unindexedAttributes = React.useMemo((function () {
          return Belt_List.keep(getGroup(group), (function (suggAttr) {
                        return !Belt_List.has(allAttributes, suggAttr, (function (a, b) {
                                      return a.name === b.attributeName;
                                    }));
                      }));
        }), [attributes]);
  var items = Belt_List.map(unindexedAttributes, (function (i) {
          var primaryTxt = i.friendlyName;
          var secondaryTxt = i.attributeName;
          var chip = React.createElement(FilterPills$BsConsole.Custom.make, { });
          return makeItem(i, primaryTxt, secondaryTxt, undefined, Caml_option.some(chip), undefined);
        }));
  var eq = function (a, b) {
    return a.attributeName === b.attributeName;
  };
  return React.createElement(FilterQueryBuilder$SelectableList, {
              items: items,
              selectedItem: selected,
              eq: eq,
              onChange: onChange
            });
}

var UnindexedAttributeList = {
  make: FilterQueryBuilder$UnindexedAttributeList
};

function FilterQueryBuilder$OperatorSelect(Props) {
  var attribute = Props.attribute;
  var value = Props.value;
  var onChange = Props.onChange;
  return React.createElement(Col2$BsConsole.make, {
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  children: React.createElement(InputLabel.default, {
                        className: Css.style(/* :: */[
                              Css.color(Css.hex("FFFFFF")),
                              /* :: */[
                                Css.marginBottom(Css.rem(0.5)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.show(undefined, "Operator")
                      })
                }), React.createElement(Row2$BsConsole.make, {
                  children: React.createElement(Select.default, {
                        value: value,
                        onChange: (function ($$event, param) {
                            return Curry._1(onChange, Belt_Option.getWithDefault($$event.target.value, ""));
                          }),
                        input: React.createElement(Input.default, {
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.borderRadius(Css.px(4)),
                                      /* [] */0
                                    ])
                              }
                            }),
                        renderValue: (function (value$prime) {
                            return React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body1 */8,
                                        className: Css.style(/* :: */[
                                              Css.fontWeight(/* `num */[
                                                    5496390,
                                                    500
                                                  ]),
                                              /* :: */[
                                                Css.width(Css.ch(12)),
                                                /* :: */[
                                                  Css.whiteSpace(/* nowrap */867913355),
                                                  /* :: */[
                                                    Css.overflow(/* hidden */-862584982),
                                                    /* :: */[
                                                      Css.textOverflow(/* ellipsis */166888785),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]),
                                        children: I18N$BsConsole.show(undefined, value$prime)
                                      });
                          }),
                        disableUnderline: true,
                        classes: {
                          icon: Css.style(/* :: */[
                                Css.color(Css.hex("FFFFFF")),
                                /* [] */0
                              ]),
                          select: Css.style(/* :: */[
                                Css.paddingLeft(Css.rem(0.75)),
                                /* [] */0
                              ])
                        },
                        className: Css.style(/* :: */[
                              Css.color(Css.hex("FFFFFF")),
                              /* :: */[
                                Css.backgroundColor(Css.hex("547580")),
                                /* :: */[
                                  Css.height(Css.rem(2.5)),
                                  /* :: */[
                                    Css.width(Css.ch(16)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]),
                        variant: "menu",
                        MenuProps: {
                          classes: {
                            paper: Css.style(/* :: */[
                                  Css.backgroundColor(Css.hex("547580")),
                                  /* [] */0
                                ])
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          transitionDuration: 0
                        },
                        children: Belt_List.toArray(Belt_List.map(attribute.filter, (function (f) {
                                    return React.createElement(MenuItem.default, {
                                                value: f,
                                                children: React.createElement(Typography.default, {
                                                      variant: "subtitle2",
                                                      classes: {
                                                        root: Css.style(/* :: */[
                                                              Css.color(Css.hex("FFFFFF")),
                                                              /* [] */0
                                                            ])
                                                      },
                                                      children: I18N$BsConsole.show(undefined, f)
                                                    })
                                              });
                                  })))
                      })
                }));
}

var OperatorSelect = {
  make: FilterQueryBuilder$OperatorSelect
};

function FilterQueryBuilder$ValueInput(Props) {
  var value = Props.value;
  var setValue = Props.setValue;
  var placeholder = Props.placeholder;
  var value$1 = Belt_Option.getWithDefault(value, "");
  return React.createElement(Col2$BsConsole.make, {
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  children: React.createElement(InputLabel.default, {
                        className: Css.style(/* :: */[
                              Css.color(Css.hex("FFFFFF")),
                              /* :: */[
                                Css.marginBottom(Css.rem(0.5)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.show(undefined, "Value")
                      })
                }), React.createElement(Row2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: React.createElement(Input.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.borderRadius(Css.px(4)),
                                /* [] */0
                              ])
                        },
                        className: Css.style(/* :: */[
                              Css.backgroundColor(Css.hex("547580")),
                              /* :: */[
                                Css.height(Css.rem(2.5)),
                                /* :: */[
                                  Css.width(Css.pct(95)),
                                  /* :: */[
                                    Css.selector("input", /* :: */[
                                          Css.color(Css.hex(Colors$BsConsole.white)),
                                          /* [] */0
                                        ]),
                                    /* :: */[
                                      Css.paddingLeft(Css.rem(0.75)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]),
                        value: value$1,
                        placeholder: placeholder,
                        onChange: (function ($$event) {
                            var value = $$event.target.value;
                            return Curry._1(setValue, Belt_Option.getWithDefault((value == null) ? undefined : Caml_option.some(value), ""));
                          }),
                        disableUnderline: true
                      })
                }));
}

var ValueInput = {
  make: FilterQueryBuilder$ValueInput
};

var radio = Css.style(/* :: */[
      Css.color(Css.hex("FFFFFF")),
      /* [] */0
    ]);

var leftPad = Css.rem(1.125);

var Style$2 = {
  radio: radio,
  leftPad: leftPad
};

function FilterQueryBuilder$ValuesAutocomplete(Props) {
  var attribute = Props.attribute;
  var aperture = Props.aperture;
  var havings = Props.havings;
  var token = Props.token;
  var projectName = Props.projectName;
  var filter = Props.filter;
  var onChange = Props.onChange;
  var match = FilterInput$BsConsole.ValueInputAutocomplete.useAttributeValueSuggestions(attribute, aperture, havings, token, projectName);
  var loading = match[1];
  var values = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setValue = match$1[1];
  var value = match$1[0];
  React.useEffect((function () {
          Curry._1(onChange, value);
          
        }), [value]);
  var match$2 = React.useState((function () {
          return false;
        }));
  var setZeroResults = match$2[1];
  React.useEffect((function () {
          if (loading === false && values.length === 0) {
            Curry._1(setZeroResults, (function (param) {
                    return true;
                  }));
          } else {
            Curry._1(setZeroResults, (function (param) {
                    return false;
                  }));
          }
          
        }), /* tuple */[
        values,
        loading
      ]);
  var match$3 = React.useState((function () {
          return [];
        }));
  var setFilteredValues = match$3[1];
  React.useEffect((function () {
          var results;
          var exit = 0;
          if (filter !== undefined && filter.trim() !== "") {
            results = Fuzz$BsConsole.fuzz(filter, Belt_Array.map(values, (function (prim) {
                        return prim[0];
                      })), Util$BsConsole.identity);
          } else {
            exit = 1;
          }
          if (exit === 1) {
            results = Belt_Array.map(values, (function (prim) {
                    return prim[0];
                  }));
          }
          Curry._1(setFilteredValues, (function (param) {
                  return results;
                }));
          var exit$1 = 0;
          if (filter !== undefined && filter.trim() !== "") {
            Curry._1(setValue, (function (param) {
                    return filter;
                  }));
          } else {
            exit$1 = 1;
          }
          if (exit$1 === 1) {
            Curry._1(setValue, (function (param) {
                    
                  }));
          }
          
        }), /* tuple */[
        values,
        filter
      ]);
  var items = Belt_List.map(Belt_List.fromArray(match$3[0]), (function (v) {
          return /* tuple */[
                  v,
                  v
                ];
        }));
  var items$prime;
  if (filter !== undefined && filter.trim() !== "") {
    var rawItem_000 = I18N$BsConsole.get(undefined, "raw input: ") + filter;
    var rawItem = /* tuple */[
      rawItem_000,
      filter
    ];
    items$prime = /* :: */[
      rawItem,
      items
    ];
  } else {
    items$prime = items;
  }
  var items$prime$prime = Belt_List.map(items$prime, (function (i) {
          var nbsp = "\u00A0";
          var primaryTxt = i[1] === "*" ? "*" + (nbsp + (nbsp + "(No value)")) : i[0];
          return makeItem(i, primaryTxt, undefined, undefined, undefined, undefined);
        }));
  var eq = function (param, param$1) {
    return Caml_obj.caml_equal(param[1], param$1[1]);
  };
  var selectedItem = Belt_Option.map(value, (function (v) {
          return /* tuple */[
                  v,
                  v
                ];
        }));
  var menu = React.createElement(FilterQueryBuilder$SelectableList, {
        items: items$prime$prime,
        selectedItem: selectedItem,
        eq: eq,
        onChange: (function (param) {
            var v = param[1];
            return Curry._1(setValue, (function (param) {
                          return v;
                        }));
          })
      });
  if (match$2[0] && Belt_Option.isNone(value)) {
    return React.createElement(Row2$BsConsole.make, {
                className: Css.style(/* :: */[
                      Css.lineHeight(Css.rem(1.5)),
                      /* [] */0
                    ]),
                children: null
              }, React.createElement("span", {
                    className: Css.style(/* :: */[
                          Css.paddingLeft(leftPad),
                          /* :: */[
                            Css.marginRight(Css.rem(0.25)),
                            /* [] */0
                          ]
                        ])
                  }, I18N$BsConsole.show(undefined, "Zero results.")), React.createElement(Tooltip.default, {
                    interactive: true,
                    placement: "top",
                    children: React.createElement(Info.default, {
                          className: Css.style(/* :: */[
                                Css.width(Css.rem(0.75)),
                                /* [] */0
                              ])
                        }),
                    title: React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body1 */8,
                          className: Css.style(/* :: */[
                                Css.lineHeight(Css.em(1.375)),
                                /* [] */0
                              ]),
                          children: I18N$BsConsole.show(undefined, "Please try widening your time frame or removing filters.")
                        })
                  }));
  } else if (loading) {
    return React.createElement("span", {
                className: Css.style(/* :: */[
                      Css.paddingLeft(leftPad),
                      /* [] */0
                    ])
              }, I18N$BsConsole.show(undefined, "Loading..."));
  } else {
    return menu;
  }
}

var ValuesAutocomplete = {
  Style: Style$2,
  make: FilterQueryBuilder$ValuesAutocomplete
};

var radio$1 = Css.style(/* :: */[
      Css.color(Css.hex("FFFFFF")),
      /* [] */0
    ]);

var Style$3 = {
  radio: radio$1
};

function FilterQueryBuilder$ValueDisplay(Props) {
  var value = Props.value;
  if (value === undefined) {
    return null;
  }
  var items$prime = /* :: */[
    value,
    /* [] */0
  ];
  var items$prime$prime = Belt_List.map(items$prime, (function (i) {
          return makeItem(i, i, undefined, undefined, undefined, undefined);
        }));
  var eq = function (param, param$1) {
    return true;
  };
  return React.createElement(FilterQueryBuilder$SelectableList, {
              items: items$prime$prime,
              selectedItem: value,
              eq: eq,
              onChange: (function (param) {
                  
                })
            });
}

var ValueDisplay = {
  Style: Style$3,
  make: FilterQueryBuilder$ValueDisplay
};

function FilterQueryBuilder$ValuePane(Props) {
  var operator = Props.operator;
  var attribute = Props.attribute;
  var aperture = Props.aperture;
  var havings = Props.havings;
  var token = Props.token;
  var projectName = Props.projectName;
  var value = Props.value;
  var setValue = Props.setValue;
  var attrValueInput = Props.attrValueInput;
  var setAttrValueInput = Props.setAttrValueInput;
  var onClose = Props.onClose;
  var formValid = Props.formValid;
  var onSubmit = Props.onSubmit;
  var shouldDisplayAutocomplete = Caml_obj.caml_equal(operator, "equal") && !ListUtils$BsConsole.contains(attribute.aggregate, "bin");
  var valueInputOnChange = shouldDisplayAutocomplete ? (function (v) {
        return Curry._1(setAttrValueInput, (function (param) {
                      if (v.trim() === "") {
                        return ;
                      } else {
                        return v;
                      }
                    }));
      }) : (function (v) {
        return Curry._1(setValue, (function (param) {
                      if (v.trim() === "") {
                        return ;
                      } else {
                        return v;
                      }
                    }));
      });
  var showValueComponent = shouldDisplayAutocomplete ? React.createElement(FilterQueryBuilder$ValuesAutocomplete, {
          attribute: attribute,
          aperture: aperture,
          havings: havings,
          token: token,
          projectName: projectName,
          filter: attrValueInput,
          onChange: (function (v) {
              return Curry._1(setValue, (function (param) {
                            return v;
                          }));
            })
        }) : React.createElement(FilterQueryBuilder$ValueDisplay, {
          value: value
        });
  var inputPlaceholder = shouldDisplayAutocomplete ? I18N$BsConsole.get(undefined, "Find value") : I18N$BsConsole.get(undefined, "Enter value");
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.paddingLeft(Css.rem(1.125)),
                        /* :: */[
                          Css.paddingTop(Css.rem(1)),
                          /* :: */[
                            Css.width(Css.pct(100)),
                            /* [] */0
                          ]
                        ]
                      ])
                }, React.createElement(FilterQueryBuilder$ValueInput, {
                      value: value,
                      setValue: valueInputOnChange,
                      placeholder: inputPlaceholder
                    })), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.marginTop(Css.rem(1)),
                        /* :: */[
                          Css.overflow(/* auto */-1065951377),
                          /* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.height(Css.pct(100)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, showValueComponent), React.createElement(Row2$BsConsole.make, {
                  justifyContent: /* flexEnd */924268066,
                  className: Css.style(/* :: */[
                        Css.paddingTop(Css.rem(1.5)),
                        /* :: */[
                          Css.unsafe("padding-right", "calc(0.05*(100% - 1.125rem))"),
                          /* :: */[
                            Css.paddingBottom(Css.rem(1)),
                            /* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement(Button.default, {
                      className: Css.style(/* :: */[
                            Css.backgroundColor(Css.hex("547580")),
                            /* :: */[
                              Css.color(Css.hex("FFFFFF")),
                              /* :: */[
                                Css.marginRight(Css.rem(1)),
                                /* :: */[
                                  Css.unsafe("transition", "unset"),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      onClick: (function (param) {
                          MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                  /* FilterQueryBuilderCancel */128,
                                  Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "attribute",
                                          attribute.name
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "operator",
                                            Belt_Option.getWithDefault(operator, "")
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "value",
                                              Belt_Option.getWithDefault(value, "")
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ])
                                ]));
                          return Curry._1(onClose, undefined);
                        }),
                      children: I18N$BsConsole.show(undefined, "Close")
                    }), React.createElement(Button.default, {
                      className: Css.style(/* :: */[
                            Css.hover(/* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.white)),
                                  /* [] */0
                                ]),
                            /* :: */[
                              Css.backgroundColor(Css.hex("FFFFFF")),
                              /* :: */[
                                Css.color(Css.hex("405B65")),
                                /* :: */[
                                  Css.unsafe("transition", "unset"),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      disabled: !Curry._1(formValid, undefined),
                      classes: {
                        disabled: Css.style(/* :: */[
                              Css.important(Css.color(Css.rgba(0, 0, 0, 0.45))),
                              /* [] */0
                            ])
                      },
                      onClick: (function (param) {
                          MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                  /* FilterQueryBuilderApply */127,
                                  Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "attribute",
                                          attribute.name
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "operator",
                                            Belt_Option.getWithDefault(operator, "")
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "value",
                                              Belt_Option.getWithDefault(value, "")
                                            ],
                                            /* [] */0
                                          ]
                                        ]
                                      ])
                                ]));
                          return Curry._1(onSubmit, undefined);
                        }),
                      children: I18N$BsConsole.show(undefined, "Add")
                    })));
}

var ValuePane = {
  make: FilterQueryBuilder$ValuePane
};

var main = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.minWidth(Css.px(850)),
        /* :: */[
          Css.height(Css.px(501)),
          /* :: */[
            Css.backgroundColor(Css.hex("405B65")),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.white)),
              /* :: */[
                Css.position(/* absolute */-1013592457),
                /* :: */[
                  Css.top(Css.rem(3.25)),
                  /* :: */[
                    Css.zIndex(13),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Style$4 = {
  main: main
};

function FilterQueryBuilder(Props) {
  var aperture = Props.aperture;
  var havings = Props.havings;
  var token = Props.token;
  var projectName = Props.projectName;
  var describeAttributes = Props.describeAttributes;
  var onChange = Props.onChange;
  var onClose = Props.onClose;
  var setQueryBuilderState = Props.setQueryBuilderState;
  var saveRecentAttribute = Props.saveRecentAttribute;
  var initialAttributes = filterToGroup(describeAttributes, /* Suggested */0);
  var initialAttribute = Belt_List.get(initialAttributes, 0);
  var match = React.useState((function () {
          return /* Suggested */0;
        }));
  var setGroup = match[1];
  var group = match[0];
  var match$1 = React.useState((function () {
          return initialAttributes;
        }));
  var setAttributes = match$1[1];
  var attributes = match$1[0];
  var match$2 = React.useState((function () {
          return initialAttribute;
        }));
  var setAttribute = match$2[1];
  var attribute = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setOperator = match$3[1];
  var operator = match$3[0];
  React.useEffect((function () {
          if (attribute !== undefined) {
            var attrDefaults = getForGroup(attribute.name, group);
            var fromDefaults = Belt_Option.map(attrDefaults, (function (d) {
                    return operatorToString(d.defaultOperator);
                  }));
            var first = Belt_List.head(attribute.filter);
            var fromRecents = Js_dict.get(getOps(projectName), attribute.name);
            var defaultOp = fromRecents !== undefined ? fromRecents : (
                fromDefaults !== undefined ? fromDefaults : (
                    first !== undefined ? first : undefined
                  )
              );
            Curry._1(setOperator, (function (param) {
                    return defaultOp;
                  }));
          }
          
        }), [attribute]);
  var match$4 = React.useState((function () {
          
        }));
  var setAttrValueInput = match$4[1];
  var match$5 = React.useState((function () {
          
        }));
  var setValue = match$5[1];
  var value = match$5[0];
  React.useEffect((function () {
          Curry._1(setAttrValueInput, (function (param) {
                  
                }));
          Curry._1(setValue, (function (param) {
                  
                }));
          
        }), /* tuple */[
        attribute,
        group,
        operator
      ]);
  var match$6 = React.useState((function () {
          
        }));
  var setUnindexedAttr = match$6[1];
  var match$7 = React.useState((function () {
          
        }));
  var setSelection = match$7[1];
  var selection = match$7[0];
  React.useEffect((function () {
          if (selection !== undefined) {
            if (selection.tag) {
              var attr = selection[0];
              Curry._1(setUnindexedAttr, (function (param) {
                      return attr;
                    }));
              Curry._1(setAttribute, (function (param) {
                      
                    }));
            } else {
              var attr$1 = selection[0];
              Curry._1(setAttribute, (function (param) {
                      return attr$1;
                    }));
              Curry._1(setUnindexedAttr, (function (param) {
                      
                    }));
            }
          } else {
            Curry._1(setAttribute, (function (param) {
                    
                  }));
            Curry._1(setUnindexedAttr, (function (param) {
                    
                  }));
          }
          
        }), [selection]);
  React.useEffect((function () {
          var attributes = filterToGroup(describeAttributes, group);
          var attr = Belt_List.get(attributes, 0);
          Curry._1(setAttribute, (function (param) {
                  return attr;
                }));
          Curry._1(setAttributes, (function (param) {
                  return attributes;
                }));
          Curry._1(setSelection, (function (param) {
                  return Belt_Option.map(attr, (function (a) {
                                return /* Indexed */Block.__(0, [a]);
                              }));
                }));
          
        }), [group]);
  var formValid = function (param) {
    if (attribute !== undefined && operator !== undefined) {
      return value !== undefined;
    } else {
      return false;
    }
  };
  var onSubmit = function (param) {
    if (attribute === undefined) {
      return ;
    }
    if (operator === undefined) {
      return ;
    }
    if (value === undefined) {
      return ;
    }
    var newFilter = Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
          attribute.name,
          Crdb$BsConsole.FilterOperation.fromTuple(/* tuple */[
                operator,
                value
              ])
        ]);
    Curry._1(saveRecentAttribute, newFilter);
    var __x = Curry._2(Crdb$BsConsole.Filters.add, newFilter, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
    return Curry._1(onChange, Curry._2(Crdb$BsConsole.Aperture.setFilters, __x, aperture));
  };
  React.useEffect((function () {
          Curry._1(setQueryBuilderState, (function (param) {
                  return /* tuple */[
                          attribute,
                          operator,
                          value
                        ];
                }));
          
        }), /* tuple */[
        attribute,
        operator,
        value
      ]);
  var tmp;
  var exit = 0;
  if (selection !== undefined && selection.tag) {
    var attr = selection[0];
    tmp = React.createElement(Col2$BsConsole.make, {
          className: Css.style(/* :: */[
                Css.height(Css.pct(100)),
                /* :: */[
                  Css.paddingLeft(Css.rem(1.125)),
                  /* :: */[
                    Css.width(Css.pct(65)),
                    /* :: */[
                      Css.borderRight(Css.px(1), /* solid */12956715, Css.hex("394C54")),
                      /* :: */[
                        Css.justifyContent(/* center */98248149),
                        /* :: */[
                          Css.alignItems(/* center */98248149),
                          /* :: */[
                            Css.marginTop(Css.rem(-3)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]),
          children: null
        }, React.createElement(Search.default, {
              className: Css.style(/* :: */[
                    Css.fontSize(Css.rem(2.3)),
                    /* :: */[
                      Css.color(Css.hex("CDCDCD")),
                      /* [] */0
                    ]
                  ])
            }), React.createElement(BtTypography$BsConsole.make, {
              variant: /* Heading6 */5,
              className: Css.style(/* :: */[
                    Css.lineHeight(Css.em(1.375)),
                    /* :: */[
                      Css.fontWeight(/* `num */[
                            5496390,
                            500
                          ]),
                      /* :: */[
                        Css.marginTop(Css.rem(0.5)),
                        /* [] */0
                      ]
                    ]
                  ]),
              children: I18N$BsConsole.show(undefined, "Attribute not available")
            }), React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              className: Css.style(/* :: */[
                    Css.lineHeight(Css.em(1.375)),
                    /* :: */[
                      Css.padding4(Css.rem(0.5), Css.rem(1), Css.rem(1), Css.rem(1)),
                      /* :: */[
                        Css.textAlign(/* center */98248149),
                        /* [] */0
                      ]
                    ]
                  ]),
              children: null
            }, I18N$BsConsole.show(undefined, "The suggested attribute "), React.createElement("span", {
                  className: Css.style(/* :: */[
                        Css.fontWeight(/* `num */[
                              5496390,
                              500
                            ]),
                        /* [] */0
                      ])
                }, I18N$BsConsole.show(undefined, attr.attributeName)), I18N$BsConsole.show(undefined, " is not available in the project")), React.createElement(Button.default, {
              variant: "outlined",
              color: "common",
              className: Css.style(/* :: */[
                    Css.color(Css.hex(Colors$BsConsole.white)),
                    /* :: */[
                      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.white)),
                      /* [] */0
                    ]
                  ]),
              onClick: (function (param) {
                  
                }),
              children: React.createElement("a", {
                    className: Css.style(/* :: */[
                          Css.important(Css.color(Css.hex(Colors$BsConsole.white))),
                          /* [] */0
                        ]),
                    href: Route$BsConsole.pathForRoute(/* ProjectSettingsAttributes */Block.__(34, [
                            projectName,
                            {
                              attribute: attr.attributeName
                            }
                          ])),
                    target: "_blank"
                  }, I18N$BsConsole.show(undefined, "Add this attribute"))
            }));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.height(Css.pct(100)),
                    /* :: */[
                      Css.paddingLeft(Css.rem(1.125)),
                      /* :: */[
                        Css.width(Css.pct(15)),
                        /* :: */[
                          Css.minWidth(Css.rem(11)),
                          /* :: */[
                            Css.borderRight(Css.px(1), /* solid */12956715, Css.hex("394C54")),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]),
              children: attribute !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.paddingTop(Css.rem(1)),
                                /* [] */0
                              ])
                        }, operator !== undefined ? React.createElement(FilterQueryBuilder$OperatorSelect, {
                                attribute: attribute,
                                value: operator,
                                onChange: (function (value) {
                                    return Curry._1(setOperator, (function (param) {
                                                  return value;
                                                }));
                                  })
                              }) : null)) : null
            }), React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.height(Css.pct(100)),
                    /* :: */[
                      Css.width(Css.pct(50)),
                      /* :: */[
                        Css.overflow(/* auto */-1065951377),
                        /* [] */0
                      ]
                    ]
                  ]),
              children: attribute !== undefined ? React.createElement(FilterQueryBuilder$ValuePane, {
                      operator: operator,
                      attribute: attribute,
                      aperture: aperture,
                      havings: havings,
                      token: token,
                      projectName: projectName,
                      value: value,
                      setValue: setValue,
                      attrValueInput: match$4[0],
                      setAttrValueInput: setAttrValueInput,
                      onClose: onClose,
                      formValid: formValid,
                      onSubmit: onSubmit
                    }) : null
            }));
  }
  return React.createElement("div", {
              className: main
            }, React.createElement(Row2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.height(Css.rem(1.714)),
                        /* :: */[
                          Css.backgroundColor(Css.hex("344B53")),
                          /* :: */[
                            Css.alignItems(/* center */98248149),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement(Edit.default, {
                      className: Css.style(/* :: */[
                            Css.height(Css.rem(0.96)),
                            /* :: */[
                              Css.width(Css.rem(0.96)),
                              /* :: */[
                                Css.marginLeft(Css.rem(0.875)),
                                /* :: */[
                                  Css.marginRight(Css.rem(0.854)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ])
                    }), React.createElement("span", {
                      className: Css.style(/* :: */[
                            Css.fontSize(Css.rem(0.75)),
                            /* :: */[
                              Css.fontWeight(/* bold */-1055161979),
                              /* :: */[
                                Css.userSelect(/* none */-922086728),
                                /* [] */0
                              ]
                            ]
                          ])
                    }, I18N$BsConsole.show(undefined, "Query builder"))), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* spaceBetween */516682146,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.boxShadow(undefined, Css.px(-2), undefined, undefined, true, Css.hex("547580")),
                          /* :: */[
                            Css.height(Css.pct(10)),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: React.createElement(FilterQueryBuilder$Tabs, {
                        tabData: /* :: */[
                          /* tuple */[
                            /* Suggested */0,
                            "Suggested"
                          ],
                          /* :: */[
                            /* tuple */[
                              /* ErrorDetails */1,
                              "Error details"
                            ],
                            /* :: */[
                              /* tuple */[
                                /* DevicesAndPlatforms */2,
                                "Devices & Platforms"
                              ],
                              /* :: */[
                                /* tuple */[
                                  /* MemoryAndProcess */3,
                                  "Memory & Process"
                                ],
                                /* :: */[
                                  /* tuple */[
                                    /* Misc */4,
                                    "Misc."
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      /* Custom */5,
                                      "Custom"
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ],
                        initialValue: /* Suggested */0,
                        callback: (function (g) {
                            Curry._1(setGroup, (function (param) {
                                    return g;
                                  }));
                            return MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                          /* FilterQueryBuilderTabSelect */129,
                                          Json_encode.object_(/* :: */[
                                                /* tuple */[
                                                  "tab",
                                                  groupToStr(g)
                                                ],
                                                /* [] */0
                                              ])
                                        ]));
                          })
                      })
                }), React.createElement(Row2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.unsafe("height", "calc(90% - 1.714rem)"),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(Col2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.height(Css.pct(100)),
                            /* :: */[
                              Css.borderRight(Css.px(1), Css.solid, Css.hex("394C54")),
                              /* :: */[
                                Css.width(Css.pct(35)),
                                /* :: */[
                                  Css.minWidth(Css.pct(35)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      children: null
                    }, React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.paddingLeft(Css.rem(1.125)),
                                /* :: */[
                                  Css.paddingTop(Css.rem(1)),
                                  /* :: */[
                                    Css.marginBottom(Css.rem(0.3)),
                                    /* [] */0
                                  ]
                                ]
                              ])
                        }, React.createElement(InputLabel.default, {
                              className: Css.style(/* :: */[
                                    Css.paddingTop(Css.rem(1)),
                                    /* :: */[
                                      Css.color(Css.hex("FFFFFF")),
                                      /* [] */0
                                    ]
                                  ]),
                              children: I18N$BsConsole.show(undefined, "Attribute")
                            })), React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.overflow(/* auto */-1065951377),
                                /* :: */[
                                  Css.width(Css.pct(100)),
                                  /* [] */0
                                ]
                              ])
                        }, React.createElement(FilterQueryBuilder$AttributeList, {
                              attributes: attributes,
                              allAttributes: describeAttributes,
                              group: group,
                              selected: attribute,
                              projectName: projectName,
                              onChange: (function (v) {
                                  return Curry._1(setSelection, (function (param) {
                                                return /* Indexed */Block.__(0, [v]);
                                              }));
                                })
                            }), group !== /* Custom */5 ? React.createElement(FilterQueryBuilder$UnindexedAttributeList, {
                                attributes: attributes,
                                allAttributes: describeAttributes,
                                selected: match$6[0],
                                group: group,
                                onChange: (function (v) {
                                    return Curry._1(setSelection, (function (param) {
                                                  return /* Unindexed */Block.__(1, [v]);
                                                }));
                                  })
                              }) : null)), tmp));
}

var make = FilterQueryBuilder;

exports.Groups = Groups;
exports.OperatorFriendlySort = OperatorFriendlySort;
exports.RecentAttribute = RecentAttribute;
exports.RecentAttributes = RecentAttributes;
exports.Tabs = Tabs$1;
exports.SelectableList = SelectableList;
exports.AttributeList = AttributeList;
exports.UnindexedAttributeList = UnindexedAttributeList;
exports.OperatorSelect = OperatorSelect;
exports.ValueInput = ValueInput;
exports.ValuesAutocomplete = ValuesAutocomplete;
exports.ValueDisplay = ValueDisplay;
exports.ValuePane = ValuePane;
exports.Style = Style$4;
exports.make = make;
/* all Not a pure module */
