// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_string = require("bs-platform/lib/js/caml_string.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");

var white = "FAFAFA";

var black = "000000";

var grey2 = "37474F";

var grey6 = "CFD8DC";

var grey95 = "1E2127";

var tags = [
  "F4FF81",
  "CCFF90",
  "93FFD9",
  "6BFCFC",
  "80D8FF",
  "82B1FF",
  "B388FF",
  "EA80FC",
  "FF90BD",
  "FF71A1"
];

var greenExtraDark = "064D50";

var greenDark = "006064";

var green = "007D61";

var greenLight = "4D9645";

var greenExtraLight = "E7F4E6";

var tealExtraDark = "1F6A80";

var tealDark = "0090A2";

var teal = "10CFE7";

var tealLight = "CEF0F4";

var tealExtraLight = "E6F9FC";

var orangeExtraDark = "A25300";

var orangeDark = "D36D02";

var orange = "FFA600";

var orangeLight = "FFCD00";

var orangeExtraLight = "FFF5CB";

var blueDark = "004D9C";

var blue = "007BF9";

var blueLight = "ADD6FF";

var blueBackground = "EDF6FF";

var redDark = "B73C3C";

var red = "FE5656";

var redLight = "FFA8A8";

var redBackground = "FFECEC";

var qualitativeViz2 = "00776C";

var qualitativeViz3 = "679F3B";

var qualitativeVizPalette = [
  grey2,
  qualitativeViz2,
  qualitativeViz3,
  orange
];

function octothorpe(color) {
  return "#" + color;
}

function deoctothorpe(color) {
  if (color.indexOf("#") === 0) {
    return color.substr(1);
  } else {
    return color;
  }
}

function getTagBackgroundColor(str) {
  var stringValue = Belt_Array.reduce(Belt_Array.mapWithIndex(str.split(""), (function (idx, $$char) {
              return Caml_int32.imul(Caml_string.get($$char, 0), idx);
            })), 0, (function (prim, prim$1) {
          return prim + prim$1 | 0;
        }));
  var tagsLength = tags.length;
  var index = Caml_int32.mod_(stringValue, tagsLength);
  return Belt_Option.getWithDefault(Belt_Array.get(tags, index), "F4FF81");
}

function addAlpha(colorHex, alpha) {
  var alpha$prime = Caml_primitive.caml_float_max(0, 1 < alpha ? 1 : alpha);
  var intValue = Math.round(alpha$prime * 255) | 0;
  var hexValue = intValue.toString(16);
  var hexValueString = hexValue.length === 1 ? "0" + hexValue : hexValue;
  return colorHex + hexValueString;
}

var blackA00 = addAlpha(black, 0.0);

var blackA04 = addAlpha(black, 0.04);

var blackA08 = addAlpha(black, 0.08);

var blackA09 = addAlpha(black, 0.09);

var blackA10 = addAlpha(black, 0.10);

var blackA12 = addAlpha(black, 0.12);

var blackA125 = addAlpha(black, 0.125);

var blackA14 = addAlpha(black, 0.14);

var blackA15 = addAlpha(black, 0.15);

var blackA18 = addAlpha(black, 0.18);

var blackA20 = addAlpha(black, 0.20);

var blackA23 = addAlpha(black, 0.23);

var blackA26 = addAlpha(black, 0.26);

var blackA40 = addAlpha(black, 0.40);

var blackA45 = addAlpha(black, 0.45);

var blackA54 = addAlpha(black, 0.54);

var blackA60 = addAlpha(black, 0.60);

var blackA87 = addAlpha(black, 0.87);

var grey95A45 = addAlpha(grey95, 0.45);

var redA25 = addAlpha("BF360C", 0.25);

var whiteA30 = addAlpha(white, 0.30);

var whiteA70 = addAlpha(white, 0.70);

var greyA60 = addAlpha(grey6, 0.60);

var yellowGreenA80 = addAlpha("CBFF8F", 0.80);

var yellowA50 = addAlpha("f4ff80", 0.50);

var tealA30 = addAlpha(teal, 0.30);

var Debugger = {
  grey1: "6D7584",
  grey2: "7B7C7C",
  grey3: "D1D1D1",
  grey4: "E1DCE5",
  white1: "FFFFFF",
  white2: "FAF9FB",
  green1: "327B62"
};

var disabled = "CCCCCC";

var grey0 = "212121";

var grey1 = "2A363B";

var grey3 = "455A64";

var grey4 = "647881";

var grey5 = "B3C3CA";

var grey65 = "E1E6E9";

var grey7 = "ECEFF1";

var grey8 = "F6F7F8";

var grey9 = "FDFDFD";

var greenOlive = "9FA612";

var appBadgeBlue = "E6F1FD";

var orangeBackground = "FFFCEE";

var blueLighter = "D8EBFF";

var deleteRed = "AE0F0F";

var deleteRedDark = "790A0A";

var statusOpen = "FF7278";

var statusInProgress = "FED500";

var statusMuted = "A9A7AB";

var statusResolved = "2BCDA3";

var qualitativeViz1 = grey2;

var qualitativeViz4 = orange;

var qualitativeVizRest = grey6;

var mutedChartViz = "B3A9BC";

var primaryExtraDark = greenExtraDark;

var primaryDark = greenDark;

var primary = green;

var primaryLight = greenLight;

var primaryExtraLight = greenExtraLight;

var secondaryExtraDark = tealExtraDark;

var secondaryDark = tealDark;

var secondary = teal;

var secondaryLight = tealLight;

var secondaryExtraLight = tealExtraLight;

var tertiaryExtradark = orangeExtraDark;

var tertiaryDark = orangeDark;

var tertiary = orange;

var tertiaryLight = orangeLight;

var tertiaryExtraLight = orangeExtraLight;

var accentDark = blueDark;

var accent = blue;

var accentLight = blueLight;

var accentBackground = blueBackground;

var errorDark = redDark;

var error = red;

var errorLight = redLight;

var errorBackground = redBackground;

var tagPink = "FF71A1";

var tagDarkPink = "EA80FC";

var tagLightBlue = "82B1FF";

var tagLightPurple = "B388FF";

var npDarkGrey = "1B2B34";

var grey15 = "282828";

var grey35 = "616161";

var grey45 = "939393";

var grey605 = "D3D3D3";

var grey610 = "D8DEE9";

var grey615 = "d9d9d9";

var grey625 = "e0e0e0";

var grey675 = "e3e2e3";

var grey685 = "e8e8e8";

var grey725 = "f3f3f3";

var grey740 = "f8f8f8";

var grey75 = "eeeeee";

var grey10 = "313131";

var greyPink = "57484c";

var searchIcon = "979eab";

var npDarkBlue = "273868";

var npDarkBlue2 = "0088B8";

var npDarkBlue3 = "3f51b5";

var npLightBlue = "54B8EA";

var npGreen = "6fa74f";

var npLightYellow = "ffffe0";

var npLightYellow2 = "fff8c4";

var npLightRed = "F65C51";

var npDarkRed = "db4437";

var npLightPink = "ffebee";

var npOrangeLightBackground = "FFF2E2";

var npOrangeBorderColor = "FF8514";

var blackBackground = "282828";

exports.white = white;
exports.black = black;
exports.disabled = disabled;
exports.grey0 = grey0;
exports.grey1 = grey1;
exports.grey2 = grey2;
exports.grey3 = grey3;
exports.grey4 = grey4;
exports.grey5 = grey5;
exports.grey6 = grey6;
exports.grey65 = grey65;
exports.grey7 = grey7;
exports.grey8 = grey8;
exports.grey9 = grey9;
exports.grey95 = grey95;
exports.tags = tags;
exports.greenExtraDark = greenExtraDark;
exports.greenDark = greenDark;
exports.green = green;
exports.greenLight = greenLight;
exports.greenExtraLight = greenExtraLight;
exports.greenOlive = greenOlive;
exports.tealExtraDark = tealExtraDark;
exports.tealDark = tealDark;
exports.teal = teal;
exports.tealLight = tealLight;
exports.tealExtraLight = tealExtraLight;
exports.appBadgeBlue = appBadgeBlue;
exports.orangeExtraDark = orangeExtraDark;
exports.orangeDark = orangeDark;
exports.orange = orange;
exports.orangeLight = orangeLight;
exports.orangeExtraLight = orangeExtraLight;
exports.orangeBackground = orangeBackground;
exports.blueDark = blueDark;
exports.blue = blue;
exports.blueLighter = blueLighter;
exports.blueLight = blueLight;
exports.blueBackground = blueBackground;
exports.redDark = redDark;
exports.red = red;
exports.redLight = redLight;
exports.redBackground = redBackground;
exports.deleteRed = deleteRed;
exports.deleteRedDark = deleteRedDark;
exports.statusOpen = statusOpen;
exports.statusInProgress = statusInProgress;
exports.statusMuted = statusMuted;
exports.statusResolved = statusResolved;
exports.qualitativeViz1 = qualitativeViz1;
exports.qualitativeViz2 = qualitativeViz2;
exports.qualitativeViz3 = qualitativeViz3;
exports.qualitativeViz4 = qualitativeViz4;
exports.qualitativeVizPalette = qualitativeVizPalette;
exports.qualitativeVizRest = qualitativeVizRest;
exports.mutedChartViz = mutedChartViz;
exports.primaryExtraDark = primaryExtraDark;
exports.primaryDark = primaryDark;
exports.primary = primary;
exports.primaryLight = primaryLight;
exports.primaryExtraLight = primaryExtraLight;
exports.secondaryExtraDark = secondaryExtraDark;
exports.secondaryDark = secondaryDark;
exports.secondary = secondary;
exports.secondaryLight = secondaryLight;
exports.secondaryExtraLight = secondaryExtraLight;
exports.tertiaryExtradark = tertiaryExtradark;
exports.tertiaryDark = tertiaryDark;
exports.tertiary = tertiary;
exports.tertiaryLight = tertiaryLight;
exports.tertiaryExtraLight = tertiaryExtraLight;
exports.accentDark = accentDark;
exports.accent = accent;
exports.accentLight = accentLight;
exports.accentBackground = accentBackground;
exports.errorDark = errorDark;
exports.error = error;
exports.errorLight = errorLight;
exports.errorBackground = errorBackground;
exports.octothorpe = octothorpe;
exports.deoctothorpe = deoctothorpe;
exports.getTagBackgroundColor = getTagBackgroundColor;
exports.addAlpha = addAlpha;
exports.tagPink = tagPink;
exports.tagDarkPink = tagDarkPink;
exports.tagLightBlue = tagLightBlue;
exports.tagLightPurple = tagLightPurple;
exports.npDarkGrey = npDarkGrey;
exports.grey15 = grey15;
exports.grey35 = grey35;
exports.grey45 = grey45;
exports.grey605 = grey605;
exports.grey610 = grey610;
exports.grey615 = grey615;
exports.grey625 = grey625;
exports.grey675 = grey675;
exports.grey685 = grey685;
exports.grey725 = grey725;
exports.grey740 = grey740;
exports.grey75 = grey75;
exports.grey10 = grey10;
exports.greyPink = greyPink;
exports.searchIcon = searchIcon;
exports.npDarkBlue = npDarkBlue;
exports.npDarkBlue2 = npDarkBlue2;
exports.npDarkBlue3 = npDarkBlue3;
exports.npLightBlue = npLightBlue;
exports.npGreen = npGreen;
exports.npLightYellow = npLightYellow;
exports.npLightYellow2 = npLightYellow2;
exports.npLightRed = npLightRed;
exports.npDarkRed = npDarkRed;
exports.npLightPink = npLightPink;
exports.npOrangeLightBackground = npOrangeLightBackground;
exports.npOrangeBorderColor = npOrangeBorderColor;
exports.blackBackground = blackBackground;
exports.blackA00 = blackA00;
exports.blackA04 = blackA04;
exports.blackA08 = blackA08;
exports.blackA09 = blackA09;
exports.blackA10 = blackA10;
exports.blackA12 = blackA12;
exports.blackA125 = blackA125;
exports.blackA14 = blackA14;
exports.blackA15 = blackA15;
exports.blackA18 = blackA18;
exports.blackA20 = blackA20;
exports.blackA23 = blackA23;
exports.blackA26 = blackA26;
exports.blackA40 = blackA40;
exports.blackA45 = blackA45;
exports.blackA54 = blackA54;
exports.blackA60 = blackA60;
exports.blackA87 = blackA87;
exports.grey95A45 = grey95A45;
exports.redA25 = redA25;
exports.whiteA30 = whiteA30;
exports.whiteA70 = whiteA70;
exports.greyA60 = greyA60;
exports.yellowGreenA80 = yellowGreenA80;
exports.yellowA50 = yellowA50;
exports.tealA30 = tealA30;
exports.Debugger = Debugger;
/* blackA00 Not a pure module */
