// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ElementRe = require("bs-webapi/lib/js/src/dom/nodes/ElementRe.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("./components/Col2.js");
var Fuzz$BsConsole = require("./bindings/Fuzz.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var Popper = require("@material-ui/core/Popper");

var paper = Css.style(/* :: */[
      Css.fontSize(Css.px(13)),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.margin2(Css.px(2), Css.zero),
          /* [] */0
        ]
      ]
    ]);

var listBox = Css.style(/* :: */[
      Css.unsafe("listStyle", "none"),
      /* :: */[
        Css.margin(/* zero */-789508312),
        /* :: */[
          Css.padding(/* zero */-789508312),
          /* :: */[
            Css.maxHeight(Css.vh(40)),
            /* :: */[
              Css.overflow(/* auto */-1065951377),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var loading = Css.style(/* :: */[
      Css.padding2(Css.px(14), Css.px(16)),
      /* [] */0
    ]);

var noOptions = Css.style(/* :: */[
      Css.padding2(Css.px(14), Css.px(16)),
      /* [] */0
    ]);

var option = Css.merge(/* :: */[
      "BtAutocomplete-option",
      /* :: */[
        Css.style(/* :: */[
              Css.maxWidth(Css.px(350)),
              /* :: */[
                Css.minHeight(Css.px(48)),
                /* :: */[
                  Css.display(/* flex */-1010954439),
                  /* :: */[
                    Css.justifyContent(/* flexStart */662439529),
                    /* :: */[
                      Css.alignItems(/* center */98248149),
                      /* :: */[
                        Css.cursor(/* pointer */-786317123),
                        /* :: */[
                          Css.paddingTop(Css.zero),
                          /* :: */[
                            Css.boxSizing(/* borderBox */9307263),
                            /* :: */[
                              Css.unsafe("outline", "none"),
                              /* :: */[
                                Css.paddingLeft(Css.px(16)),
                                /* :: */[
                                  Css.paddingRight(Css.px(16)),
                                  /* :: */[
                                    Css.selector("&[aria-selected='true']", /* :: */[
                                          Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground)),
                                          /* [] */0
                                        ]),
                                    /* :: */[
                                      Css.selector("&[data-focus='true']", /* :: */[
                                            Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                                            /* [] */0
                                          ]),
                                      /* :: */[
                                        Css.selector(":active", /* :: */[
                                              Css.backgroundColor(Css.hex(Colors$BsConsole.accentLight)),
                                              /* [] */0
                                            ]),
                                        /* :: */[
                                          Css.selector(":hover", /* :: */[
                                                Css.backgroundColor(Css.hex(Colors$BsConsole.grey6)),
                                                /* [] */0
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var Styles = {
  paper: paper,
  listBox: listBox,
  loading: loading,
  noOptions: noOptions,
  option: option
};

function BtAutocomplete$ListItem(Props) {
  var index = Props.index;
  var className = Props.className;
  var onClick = Props.onClick;
  var children = Props.children;
  var tmp = {
    tabIndex: 0
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.cloneElement(React.createElement("li", tmp), {
              "data-option-index": index
            }, children);
}

var ListItem = {
  make: BtAutocomplete$ListItem
};

function BtAutocomplete$RawListItem(Props) {
  var value = Props.value;
  var label = Props.label;
  var onClick = Props.onClick;
  var renderRawListOption = Props.renderRawListOption;
  var body = renderRawListOption !== undefined ? Curry._2(renderRawListOption, label, value) : React.createElement(Col2$BsConsole.make, {
          justifyContent: /* spaceAround */-485895757,
          children: null
        }, React.createElement(BtTypography$BsConsole.make, {
              variant: /* Caption */11,
              className: Css.style(/* :: */[
                    Css.color(Css.hex(Colors$BsConsole.grey4)),
                    /* :: */[
                      Css.marginBottom(Css.zero),
                      /* [] */0
                    ]
                  ]),
              children: I18N$BsConsole.showSkip(label)
            }), React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              className: Css.style(/* :: */[
                    Css.margin(Css.zero),
                    /* [] */0
                  ]),
              children: I18N$BsConsole.showSkip(value)
            }));
  return React.createElement(BtAutocomplete$ListItem, {
              index: -1,
              className: option,
              onClick: onClick,
              children: body
            });
}

var RawListItem = {
  make: BtAutocomplete$RawListItem
};

function BtAutocomplete$Default(Props) {
  var toString = Props.toString;
  var freeSoloOpt = Props.freeSolo;
  var freeSoloLabelOpt = Props.freeSoloLabel;
  var considerations = Props.considerations;
  var onDeleteOpt = Props.onDelete;
  var onChange = Props.onChange;
  var keyboardHandlersOpt = Props.keyboardHandlers;
  var defaultValueOpt = Props.defaultValue;
  var loadingOpt = Props.loading;
  var placeholderOpt = Props.placeholder;
  var autoFocusOpt = Props.autoFocus;
  var inputAriaLabelOpt = Props.inputAriaLabel;
  var filterOptions = Props.filterOptions;
  var renderListOption = Props.renderListOption;
  var renderRawListOption = Props.renderRawListOption;
  var inputRef = Props.inputRef;
  var renderInput = Props.renderInput;
  var inputClassNameOpt = Props.inputClassName;
  var listClassNameOpt = Props.listClassName;
  var rootClassNameOpt = Props.rootClassName;
  var onBlur = Props.onBlur;
  var debugOpt = Props.debug;
  var clearOnChangeOpt = Props.clearOnChange;
  var staticItem = Props.staticItem;
  var suppressPopperOpt = Props.suppressPopper;
  var containerFocused = Props.containerFocused;
  var freeSolo = freeSoloOpt !== undefined ? freeSoloOpt : false;
  var freeSoloLabel = freeSoloLabelOpt !== undefined ? freeSoloLabelOpt : "raw input";
  var onDelete = onDeleteOpt !== undefined ? onDeleteOpt : (function (param) {
        
      });
  var keyboardHandlers = keyboardHandlersOpt !== undefined ? keyboardHandlersOpt : /* [] */0;
  var defaultValue = defaultValueOpt !== undefined ? defaultValueOpt : "";
  var loading$1 = loadingOpt !== undefined ? loadingOpt : false;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var inputAriaLabel = inputAriaLabelOpt !== undefined ? inputAriaLabelOpt : "autocompete-input";
  var inputClassName = inputClassNameOpt !== undefined ? inputClassNameOpt : "";
  var listClassName = listClassNameOpt !== undefined ? listClassNameOpt : "";
  var rootClassName = rootClassNameOpt !== undefined ? rootClassNameOpt : "";
  var debug = debugOpt !== undefined ? debugOpt : false;
  var clearOnChange = clearOnChangeOpt !== undefined ? clearOnChangeOpt : false;
  var suppressPopper = suppressPopperOpt !== undefined ? suppressPopperOpt : false;
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setListboxRef = match$1[1];
  var listboxRef = match$1[0];
  var match$2 = React.useState((function () {
          return defaultValue;
        }));
  var setValue = match$2[1];
  var value = match$2[0];
  var match$3 = React.useState((function () {
          return considerations;
        }));
  var setOptions = match$3[1];
  var options = match$3[0];
  var match$4 = React.useState((function () {
          return false;
        }));
  var setOpen_ = match$4[1];
  var open_ = match$4[0];
  var match$5 = React.useState((function () {
          
        }));
  var setBlurCb = match$5[1];
  var blurCb = match$5[0];
  var match$6 = React.useState((function () {
          return 0;
        }));
  var setPopperKey = match$6[1];
  var popperKey = match$6[0];
  var highlightedIndexRef = React.useRef(-1);
  var handleOpen = function (_event) {
    if (open_) {
      return ;
    } else {
      return Curry._1(setOpen_, (function (param) {
                    return true;
                  }));
    }
  };
  var handleClose = function (_event) {
    if (open_) {
      return Curry._1(setOpen_, (function (param) {
                    return false;
                  }));
    }
    
  };
  var handleFocus = handleOpen;
  React.useEffect((function () {
          if (Caml_obj.caml_equal(containerFocused, false)) {
            handleClose(undefined);
            Curry._1(setValue, (function (param) {
                    return defaultValue;
                  }));
            if (onBlur !== undefined) {
              Curry._1(onBlur, undefined);
            }
            
          }
          
        }), [containerFocused]);
  var handleInputChange = function ($$event) {
    var newValue = $$event.target.value;
    if (newValue !== value) {
      Curry._1(setValue, (function (param) {
              return newValue;
            }));
    }
    if (newValue !== "") {
      return handleOpen($$event);
    }
    
  };
  var changeHighlightedIndex = function (setIndex) {
    if (!open_) {
      return ;
    }
    var tmp;
    if (typeof setIndex === "number") {
      tmp = setIndex !== 0 ? -1 : 0;
    } else {
      var newIndex = highlightedIndexRef.current + setIndex[0] | 0;
      tmp = newIndex >= options.length ? options.length - 1 | 0 : (
          newIndex <= -1 ? -1 : newIndex
        );
    }
    highlightedIndexRef.current = tmp;
    var listboxNode;
    if (listboxRef !== undefined) {
      var p = Caml_option.valFromOption(listboxRef).parentElement;
      listboxNode = (p == null) ? undefined : Caml_option.nullable_to_opt(p.querySelector("[role='listbox']"));
    } else {
      listboxNode = undefined;
    }
    if (listboxNode === undefined) {
      return ;
    }
    var node = Caml_option.valFromOption(listboxNode);
    var prev = node.querySelector("[data-focus='true']");
    if (!(prev == null)) {
      prev.removeAttribute("data-focus");
    }
    var option = node.querySelector("[data-option-index=\"" + (String(tmp) + "\"]"));
    if (option == null) {
      return ;
    }
    option.setAttribute("data-focus", "true");
    if (node.scrollHeight <= node.clientHeight) {
      return ;
    }
    var scrollBottom = (node.scrollTop | 0) + node.clientHeight | 0;
    var o$prime = ElementRe.asHtmlElement(option);
    var match;
    if (o$prime !== undefined) {
      var o$prime$1 = Caml_option.valFromOption(o$prime);
      match = /* tuple */[
        o$prime$1.offsetTop,
        o$prime$1.offsetHeight
      ];
    } else {
      match = /* tuple */[
        0,
        0
      ];
    }
    var optionOffsetTop = match[0];
    var optionBottom = optionOffsetTop + match[1] | 0;
    if (optionBottom <= scrollBottom) {
      if (optionOffsetTop < (node.scrollTop | 0)) {
        node.scrollTop = optionOffsetTop;
        return ;
      } else {
        return ;
      }
    }
    var newScrollTop = optionBottom - node.clientHeight | 0;
    node.scrollTop = newScrollTop;
    
  };
  var handleKeyDown = function ($$event) {
    var keyName = $$event.key;
    var selected = highlightedIndexRef.current;
    Belt_List.forEach(keyboardHandlers, (function (param) {
            if (keyName !== param[0]) {
              return ;
            }
            var handler = param[1];
            $$event.preventDefault();
            if (selected === -1 && freeSolo) {
              return Curry._2(handler, value, undefined);
            }
            if (!(selected >= 0 && selected < options.length)) {
              return ;
            }
            var option = Belt_Array.get(options, selected);
            if (option === undefined) {
              return ;
            }
            var option$1 = Caml_option.valFromOption(option);
            return Curry._2(handler, Curry._1(toString, option$1), Caml_option.some(option$1));
          }));
    switch (keyName) {
      case "ArrowDown" :
          $$event.preventDefault();
          if (!open_) {
            handleOpen($$event);
          }
          return changeHighlightedIndex(/* Diff */[1]);
      case "ArrowUp" :
          $$event.preventDefault();
          return changeHighlightedIndex(/* Diff */[-1]);
      case "Backspace" :
          if (value === "") {
            Curry._1(onDelete, undefined);
            return handleClose($$event);
          } else {
            return ;
          }
      case "Enter" :
          if (selected === -1 && !freeSolo && Belt_Option.isSome(staticItem)) {
            var $$document$1 = document.documentElement;
            var el = $$document$1.querySelector("[data-focus='true']");
            if (!(el == null)) {
              Belt_Option.map(ElementRe.asHtmlElement(el), (function (el) {
                      el.click();
                      
                    }));
            }
            
          }
          if (selected === -1 && freeSolo) {
            Curry._2(onChange, value, undefined);
            if (clearOnChange) {
              Curry._1(setValue, (function (param) {
                      return "";
                    }));
            }
            return handleClose($$event);
          }
          if (!(selected >= 0 && selected < options.length)) {
            return ;
          }
          var option = Belt_Array.get(options, selected);
          if (option === undefined) {
            return ;
          }
          var option$1 = Caml_option.valFromOption(option);
          Curry._2(onChange, Curry._1(toString, option$1), Caml_option.some(option$1));
          if (clearOnChange) {
            Curry._1(setValue, (function (param) {
                    return "";
                  }));
          } else {
            Curry._1(setValue, (function (param) {
                    return Curry._1(toString, option$1);
                  }));
          }
          return handleClose($$event);
      case "Escape" :
      case "Tab" :
          break;
      default:
        return ;
    }
    handleClose($$event);
    if (onBlur !== undefined) {
      return Curry._1(onBlur, undefined);
    }
    
  };
  React.useEffect((function () {
          var nextCb;
          if (anchorEl !== undefined && listboxRef !== undefined) {
            var list = Caml_option.valFromOption(listboxRef);
            var el = Caml_option.valFromOption(anchorEl);
            nextCb = (function ($$event) {
                var eventEl = $$event.target;
                var debugMode = debug && value !== "";
                try {
                  if (el.contains(eventEl) || list.contains(eventEl) || debugMode) {
                    return ;
                  } else {
                    handleClose($$event);
                    if (onBlur !== undefined) {
                      return Curry._1(onBlur, undefined);
                    } else {
                      return ;
                    }
                  }
                }
                catch (exn){
                  return ;
                }
              });
          } else {
            nextCb = undefined;
          }
          Curry._1(setBlurCb, (function (oldCb) {
                  if (oldCb !== undefined) {
                    window.removeEventListener("mousedown", oldCb);
                  }
                  return nextCb;
                }));
          
        }), /* tuple */[
        listboxRef,
        anchorEl
      ]);
  React.useEffect((function () {
          if (blurCb !== undefined) {
            window.addEventListener("mousedown", blurCb);
          }
          return (function (param) {
                    if (blurCb !== undefined) {
                      window.removeEventListener("mousedown", blurCb);
                      return ;
                    }
                    
                  });
        }), [blurCb]);
  React.useEffect((function () {
          var nextConsiderations = value.trim() !== "" ? (
              filterOptions !== undefined ? Curry._2(filterOptions, considerations, value) : Fuzz$BsConsole.fuzz(value, considerations, toString)
            ) : considerations;
          Curry._1(setOptions, (function (param) {
                  return nextConsiderations;
                }));
          
        }), /* tuple */[
        value,
        considerations
      ]);
  var option$1 = Belt_Array.get(options, 0);
  var shouldShowRaw = option$1 !== undefined ? freeSolo && value.trim() !== "" && value.trim() !== Curry._1(toString, Caml_option.valFromOption(option$1)) : freeSolo && value.trim() !== "";
  var shouldShowStatic = Belt_Option.isSome(staticItem) && value.trim() === "";
  React.useLayoutEffect((function () {
          if (listboxRef !== undefined) {
            var node = Caml_option.valFromOption(listboxRef);
            var el = node.querySelector("[data-focus='true']");
            if (!(el == null)) {
              el.removeAttribute("data-focus");
            }
            node.scrollTop = 0.0;
          }
          var _option = Belt_Array.get(options, 0);
          if (_option !== undefined && !(shouldShowRaw || shouldShowStatic)) {
            changeHighlightedIndex(/* Start */0);
          } else {
            changeHighlightedIndex(/* Reset */1);
          }
          
        }), /* tuple */[
        popperKey,
        listboxRef
      ]);
  React.useLayoutEffect((function () {
          Curry._1(setPopperKey, (function (k) {
                  return k + 1 | 0;
                }));
          
        }), [options]);
  var tmp;
  if (options.length !== 0 || freeSolo) {
    var optionsList = Belt_Array.mapWithIndex(options, (function (idx, option$2) {
            return React.createElement(BtAutocomplete$ListItem, {
                        index: idx,
                        className: option,
                        onClick: (function ($$event) {
                            Curry._2(onChange, Curry._1(toString, option$2), Caml_option.some(option$2));
                            if (clearOnChange) {
                              Curry._1(setValue, (function (param) {
                                      return "";
                                    }));
                            } else {
                              Curry._1(setValue, (function (param) {
                                      return Curry._1(toString, option$2);
                                    }));
                            }
                            return handleClose($$event);
                          }),
                        children: renderListOption !== undefined ? Curry._2(renderListOption, option$2, idx) : React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Body1 */8,
                                className: Css.style(/* :: */[
                                      Css.margin(Css.zero),
                                      /* [] */0
                                    ]),
                                children: I18N$BsConsole.showSkip(Curry._1(toString, option$2))
                              }),
                        key: Curry._1(toString, option$2) + ("__" + String(idx))
                      });
          }));
    var raw;
    if (shouldShowRaw) {
      var tmp$1 = {
        value: value,
        label: freeSoloLabel,
        onClick: (function ($$event) {
            Curry._2(onChange, value, undefined);
            if (clearOnChange) {
              Curry._1(setValue, (function (param) {
                      return "";
                    }));
            }
            return handleClose($$event);
          }),
        key: value + "__raw"
      };
      if (renderRawListOption !== undefined) {
        tmp$1.renderRawListOption = Caml_option.valFromOption(renderRawListOption);
      }
      raw = [React.createElement(BtAutocomplete$RawListItem, tmp$1)];
    } else {
      raw = [];
    }
    var $$static;
    if (shouldShowStatic) {
      if (staticItem !== undefined) {
        var handler = staticItem[1];
        $$static = [React.createElement(BtAutocomplete$ListItem, {
                index: -1,
                className: option,
                onClick: (function (_event) {
                    Curry._1(handler, undefined);
                    return Curry._1(setOpen_, (function (param) {
                                  return false;
                                }));
                  }),
                children: staticItem[0],
                key: "__static"
              })];
      } else {
        $$static = [];
      }
    } else {
      $$static = [];
    }
    tmp = React.createElement("ul", {
          ref: (function (el) {
              return Curry._1(setListboxRef, (function (param) {
                            if (el == null) {
                              return ;
                            } else {
                              return Caml_option.some(el);
                            }
                          }));
            }),
          className: Css.merge(/* :: */[
                listBox,
                /* :: */[
                  listClassName,
                  /* [] */0
                ]
              ]),
          role: "listbox"
        }, Belt_Array.concatMany([
              $$static,
              raw,
              optionsList
            ]));
  } else {
    tmp = null;
  }
  var body = React.createElement(Paper.default, {
        classes: {
          root: Css.merge(/* :: */[
                paper,
                /* :: */[
                  rootClassName,
                  /* [] */0
                ]
              ])
        },
        children: null
      }, loading$1 && considerations.length === 0 ? React.createElement("div", {
              className: loading
            }, I18N$BsConsole.show(undefined, "Loading...")) : null, options.length === 0 && !freeSolo && !loading$1 ? React.createElement("div", {
              className: loading
            }, I18N$BsConsole.show(undefined, "No options")) : null, tmp);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: (function (el) {
                      return Curry._1(setAnchorEl, (function (param) {
                                    if (el == null) {
                                      return ;
                                    } else {
                                      return Caml_option.some(el);
                                    }
                                  }));
                    }),
                  onKeyDown: handleKeyDown
                }, renderInput !== undefined ? Curry._1(renderInput, {
                        value: value,
                        autoFocus: autoFocus,
                        placeholder: placeholder,
                        ariaLabel: inputAriaLabel,
                        onChange: handleInputChange,
                        onFocus: handleFocus
                      }) : React.createElement("input", {
                        ref: (function (el) {
                            if (inputRef !== undefined) {
                              return Curry._1(inputRef, el);
                            }
                            
                          }),
                        "aria-label": inputAriaLabel,
                        className: inputClassName,
                        autoFocus: autoFocus,
                        placeholder: placeholder,
                        value: value,
                        onFocus: handleFocus,
                        onChange: handleInputChange
                      })), suppressPopper ? (
                open_ ? body : null
              ) : React.createElement(Popper.default, {
                    anchorEl: anchorEl,
                    open: open_,
                    placement: "bottom-start",
                    children: body,
                    key: String(popperKey)
                  }));
}

function make(toString, freeSolo, considerations, onDelete, onChange, defaultValue, loading, placeholder, filterOptions, renderListOption, renderRawListOption, inputRef, debug, renderInput, inputClassName, autoFocus, onBlur, inputAriaLabel, staticItem, suppressPopperOpt, children) {
  var suppressPopper = suppressPopperOpt !== undefined ? suppressPopperOpt : false;
  var tmp = {
    toString: toString,
    considerations: considerations,
    onChange: onChange,
    defaultValue: defaultValue,
    loading: loading,
    placeholder: placeholder,
    suppressPopper: suppressPopper
  };
  if (freeSolo !== undefined) {
    tmp.freeSolo = Caml_option.valFromOption(freeSolo);
  }
  if (onDelete !== undefined) {
    tmp.onDelete = Caml_option.valFromOption(onDelete);
  }
  if (autoFocus !== undefined) {
    tmp.autoFocus = Caml_option.valFromOption(autoFocus);
  }
  if (inputAriaLabel !== undefined) {
    tmp.inputAriaLabel = Caml_option.valFromOption(inputAriaLabel);
  }
  if (filterOptions !== undefined) {
    tmp.filterOptions = Caml_option.valFromOption(filterOptions);
  }
  if (renderListOption !== undefined) {
    tmp.renderListOption = Caml_option.valFromOption(renderListOption);
  }
  if (renderRawListOption !== undefined) {
    tmp.renderRawListOption = Caml_option.valFromOption(renderRawListOption);
  }
  if (inputRef !== undefined) {
    tmp.inputRef = Caml_option.valFromOption(inputRef);
  }
  if (renderInput !== undefined) {
    tmp.renderInput = Caml_option.valFromOption(renderInput);
  }
  if (inputClassName !== undefined) {
    tmp.inputClassName = Caml_option.valFromOption(inputClassName);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (debug !== undefined) {
    tmp.debug = Caml_option.valFromOption(debug);
  }
  if (staticItem !== undefined) {
    tmp.staticItem = Caml_option.valFromOption(staticItem);
  }
  return ReasonReactCompat.wrapReactForReasonReact(BtAutocomplete$Default, tmp, children);
}

var Re = {
  make: make
};

var Default = {
  make: BtAutocomplete$Default,
  Re: Re
};

var make$1 = BtAutocomplete$Default;

exports.Styles = Styles;
exports.ListItem = ListItem;
exports.RawListItem = RawListItem;
exports.Default = Default;
exports.make = make$1;
exports.Re = Re;
/* paper Not a pure module */
