// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var TeamsModel$BsConsole = require("./TeamsModel.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Table = require("@material-ui/core/Table");
var Button = require("@material-ui/core/Button");
var Tooltip = require("@material-ui/core/Tooltip");
var Delete = require("@material-ui/icons/Delete");
var Collapse = require("@material-ui/core/Collapse");
var TableRow = require("@material-ui/core/TableRow");
var TableBody = require("@material-ui/core/TableBody");
var TableCell = require("@material-ui/core/TableCell");
var TableHead = require("@material-ui/core/TableHead");
var IconButton = require("@material-ui/core/IconButton");
var KeyboardArrowDown = require("@material-ui/icons/KeyboardArrowDown");
var KeyboardArrowRight = require("@material-ui/icons/KeyboardArrowRight");

function TeamsProjectMemberTeamRow$UserHeader(Props) {
  return React.createElement(TableHead.default, {
              children: React.createElement(TableRow.default, {
                    children: null
                  }, React.createElement(TableCell.default, {
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* TableTitle */13,
                              color: Colors$BsConsole.grey0,
                              children: I18N$BsConsole.show(undefined, "Email")
                            })
                      }), React.createElement(TableCell.default, {
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* TableTitle */13,
                              color: Colors$BsConsole.grey0,
                              children: I18N$BsConsole.show(undefined, "Username")
                            })
                      }))
            });
}

var UserHeader = {
  make: TeamsProjectMemberTeamRow$UserHeader
};

function TeamsProjectMemberTeamRow$MissingTeamData(Props) {
  var projectMember = Props.projectMember;
  return React.createElement(TableRow.default, {
              children: React.createElement(TableCell.default, {
                    colSpan: 4,
                    children: React.createElement(BtTypography$BsConsole.make, {
                          variant: /* TableBody */14,
                          color: Colors$BsConsole.grey0,
                          children: I18N$BsConsole.show(undefined, "Missing team data.")
                        })
                  }),
              key: String(projectMember.project) + String(projectMember.team)
            });
}

var MissingTeamData = {
  make: TeamsProjectMemberTeamRow$MissingTeamData
};

function TeamsProjectMemberTeamRow$NoMembers(Props) {
  return React.createElement(Table.default, {
              children: null
            }, React.createElement(TeamsProjectMemberTeamRow$UserHeader, { }), React.createElement(TableBody.default, {
                  children: React.createElement(TableRow.default, {
                        children: React.createElement(TableCell.default, {
                              colSpan: 2,
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* TableBody */14,
                                    color: Colors$BsConsole.grey0,
                                    children: I18N$BsConsole.show(undefined, "This team does not have any members yet.")
                                  })
                            })
                      })
                }));
}

var NoMembers = {
  make: TeamsProjectMemberTeamRow$NoMembers
};

function TeamsProjectMemberTeamRow(Props) {
  var token = Props.token;
  var team = Props.team;
  var projectMember = Props.projectMember;
  var project = Props.project;
  var allUsers = Props.allUsers;
  var setDeleteDialog = Props.setDeleteDialog;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = React.useState((function () {
          return false;
        }));
  var setExpandUsers = match[1];
  var expandUsers = match[0];
  var match$1 = TeamsModel$BsConsole.useRefetchableTeamMembers(token, team);
  var members = match$1[0];
  var arrayOfUsers = members !== undefined ? Belt_Array.map(members, (function (teamMember) {
            var user = Belt_Array.getBy(allUsers, (function (u) {
                    return u.uid === teamMember.user;
                  }));
            return /* tuple */[
                    user,
                    teamMember
                  ];
          })) : [];
  var memberCount = arrayOfUsers.length;
  return React.createElement(React.Fragment, {
              children: null,
              key: String(team.id)
            }, React.createElement(TableRow.default, {
                  children: null,
                  key: String(projectMember.project) + String(projectMember.team)
                }, React.createElement(TableCell.default, {
                      children: React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.display(/* flex */-1010954439),
                                  /* :: */[
                                    Css.alignItems(/* center */98248149),
                                    /* [] */0
                                  ]
                                ])
                          }, React.createElement(IconButton.default, {
                                ariaLabel: I18N$BsConsole.get(undefined, "View team members"),
                                onClick: (function (_e) {
                                    return Curry._1(setExpandUsers, (function (prev) {
                                                  return !prev;
                                                }));
                                  }),
                                children: expandUsers ? React.createElement(KeyboardArrowDown.default, { }) : React.createElement(KeyboardArrowRight.default, { })
                              }), React.createElement(BtTypography$BsConsole.make, {
                                variant: /* TableBody */14,
                                color: Colors$BsConsole.grey0,
                                children: I18N$BsConsole.showSkip(team.name)
                              }))
                    }), React.createElement(TableCell.default, {
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* TableBody */14,
                            color: Colors$BsConsole.grey0,
                            children: I18N$BsConsole.show(undefined, "Team")
                          })
                    }), React.createElement(TableCell.default, {
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* TableBody */14,
                            color: Colors$BsConsole.grey0,
                            children: I18N$BsConsole.dynamic(String(memberCount))
                          })
                    }), React.createElement(TableCell.default, {
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* TableBody */14,
                            color: Colors$BsConsole.grey0,
                            children: I18N$BsConsole.dynamic(projectMember.role)
                          })
                    }), React.createElement(TableCell.default, {
                      className: Css.style(/* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.justifyContent(/* flexEnd */924268066),
                              /* :: */[
                                Css.important(Css.marginBottom(Css.px(-1))),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: React.createElement(Tooltip.default, {
                            children: React.createElement(IconButton.default, {
                                  ariaLabel: I18N$BsConsole.get(undefined, "Remove team access to project"),
                                  onClick: (function (_e) {
                                      return Curry._1(setDeleteDialog, (function (param) {
                                                    return /* ProjectMemberTeam */Block.__(2, [
                                                              projectMember.project,
                                                              projectMember.team,
                                                              team,
                                                              project
                                                            ]);
                                                  }));
                                    }),
                                  children: React.createElement(Delete.default, { })
                                }),
                            title: I18N$BsConsole.get(undefined, "Remove team access to project")
                          })
                    })), React.createElement(TableRow.default, {
                  className: Css.style(/* :: */[
                        Css.height(Css.px(0)),
                        /* [] */0
                      ]),
                  children: React.createElement(TableCell.default, {
                        className: Css.style(/* :: */[
                              Css.padding(Css.px(0)),
                              /* :: */[
                                Css.border(Css.px(0), Css.solid, Css.transparent),
                                /* [] */0
                              ]
                            ]),
                        colSpan: 2,
                        children: React.createElement(Collapse.default, {
                              in: expandUsers,
                              children: React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.marginLeft(Css.px(50)),
                                          /* [] */0
                                        ])
                                  }, React.createElement(Row2$BsConsole.make, {
                                        alignItems: /* center */98248149,
                                        justifyContent: /* spaceBetween */516682146,
                                        className: Css.style(/* :: */[
                                              Css.width(Css.pct(100)),
                                              /* [] */0
                                            ]),
                                        children: null
                                      }, React.createElement("div", {
                                            className: Css.style(/* :: */[
                                                  Css.marginLeft(Css.px(20)),
                                                  /* [] */0
                                                ])
                                          }, React.createElement(BtTypography$BsConsole.make, {
                                                variant: /* TableTitle */13,
                                                children: I18N$BsConsole.show(undefined, "Members")
                                              })), React.createElement(Button.default, {
                                            variant: "outlined",
                                            color: "primary",
                                            classes: {
                                              root: Css.style(/* :: */[
                                                    Css.marginTop(Css.px(10)),
                                                    /* :: */[
                                                      Css.marginBottom(Css.px(10)),
                                                      /* :: */[
                                                        Css.marginRight(Css.px(10)),
                                                        /* [] */0
                                                      ]
                                                    ]
                                                  ])
                                            },
                                            onClick: (function (param) {
                                                return Curry._1(handleChangeUrl, /* SettingsTeams */7);
                                              }),
                                            children: I18N$BsConsole.show(undefined, "Team settings")
                                          })), memberCount !== 0 ? React.createElement(Table.default, {
                                          children: null
                                        }, React.createElement(TeamsProjectMemberTeamRow$UserHeader, { }), React.createElement(TableBody.default, {
                                              children: $$Array.map((function (param) {
                                                      var user = param[0];
                                                      if (user !== undefined) {
                                                        return React.createElement(TableRow.default, {
                                                                    children: null,
                                                                    key: user.email + ("-" + user.username)
                                                                  }, React.createElement(TableCell.default, {
                                                                        className: Css.style(/* :: */[
                                                                              Css.important(Css.borderBottom(Css.px(0), Css.solid, /* transparent */582626130)),
                                                                              /* [] */0
                                                                            ]),
                                                                        children: React.createElement(BtTypography$BsConsole.make, {
                                                                              variant: /* TableBody */14,
                                                                              color: Colors$BsConsole.grey0,
                                                                              children: I18N$BsConsole.showSkip(user.email)
                                                                            })
                                                                      }), React.createElement(TableCell.default, {
                                                                        className: Css.style(/* :: */[
                                                                              Css.important(Css.borderBottom(Css.px(0), Css.solid, /* transparent */582626130)),
                                                                              /* [] */0
                                                                            ]),
                                                                        children: React.createElement(BtTypography$BsConsole.make, {
                                                                              variant: /* TableBody */14,
                                                                              color: Colors$BsConsole.grey0,
                                                                              children: I18N$BsConsole.showSkip(user.username)
                                                                            })
                                                                      }));
                                                      } else {
                                                        return null;
                                                      }
                                                    }), arrayOfUsers)
                                            })) : React.createElement(TeamsProjectMemberTeamRow$NoMembers, { }))
                            })
                      })
                }));
}

var make = TeamsProjectMemberTeamRow;

exports.UserHeader = UserHeader;
exports.MissingTeamData = MissingTeamData;
exports.NoMembers = NoMembers;
exports.make = make;
/* Css Not a pure module */
