// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Route$BsConsole = require("./route.js");
var Upload$BsConsole = require("./Upload.js");
var Request$BsConsole = require("./Request.js");
var DropZone$BsConsole = require("./DropZone.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var Configuration$BsConsole = require("./configuration.js");

var component = ReasonReact.reducerComponent("ProjectUpload-BsConsole_File_Upload");

function make(token, port, name, universe, file, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              Curry._1(self.send, /* SetFileId */Block.__(1, [file.name]));
              var onProgress = function (param) {
                return Curry._1(self.send, /* SetProgress */Block.__(0, [param[0] / param[1] * 100]));
              };
              Curry._1(self.send, /* SetStatus */Block.__(2, [/* Loading */2]));
              var match = Upload$BsConsole.makeRequest(/* TraceFile */Block.__(2, [
                      token,
                      port,
                      name,
                      universe.name,
                      file
                    ]), (function (response) {
                      var match = response.status;
                      if (match) {
                        return Curry._1(self.send, /* SetStatus */Block.__(2, [/* Success */0]));
                      } else {
                        return Curry._1(self.send, /* SetStatus */Block.__(2, [/* Failure */[response.message]]));
                      }
                    }));
              var request = match[0];
              Request$BsConsole.XML_HttpRequest.addOnProgressListener(onProgress, request);
              return Request$BsConsole.XML_HttpRequest.sendForm(match[1], request);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var match = self.state.fileId;
              var match$1 = self.state.status;
              if (match === undefined) {
                return null;
              }
              if (typeof match$1 !== "number") {
                return ReasonReact.element(match + "_uploader", undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Column */-81804554, undefined, undefined, [
                                ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, undefined, [
                                                    ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_label", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body2 */-904051920, undefined, /* Secondary */67972948, undefined, undefined, [I18N$BsConsole.showSkip(match)]))])),
                                                    ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_status", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, /* Secondary */67972948, undefined, undefined, [I18N$BsConsole.show(undefined, "Error")]))]))
                                                  ]))])),
                                ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_error_message", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(match$1[0])]))]))
                              ]));
              }
              switch (match$1) {
                case /* Success */0 :
                    return ReasonReact.element(match + "_uploader", undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Column */-81804554, undefined, undefined, [
                                    ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, undefined, [
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_label", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body2 */-904051920, undefined, /* Primary */-791844958, undefined, undefined, [I18N$BsConsole.showSkip(match)]))])),
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_status", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, /* Primary */-791844958, undefined, undefined, [I18N$BsConsole.show(undefined, "Success")]))]))
                                                      ]))])),
                                    ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.LinearProgress.Jsx2.make, undefined, undefined, undefined, /* `Float */[
                                                        365180284,
                                                        self.state.progress
                                                      ], /* Secondary */67972948, /* Determinate */373815968, []))]))
                                  ]));
                case /* NotAsked */1 :
                    return null;
                case /* Loading */2 :
                    return ReasonReact.element(match + "_uploader", undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Column */-81804554, undefined, undefined, [
                                    ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, undefined, [
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_label", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(match)]))])),
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_status", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "...uploading")]))]))
                                                      ]))])),
                                    ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.LinearProgress.Jsx2.make, undefined, undefined, undefined, /* `Float */[
                                                        365180284,
                                                        self.state.progress
                                                      ], /* Primary */-791844958, /* Determinate */373815968, []))]))
                                  ]));
                
              }
            }),
          initialState: (function (param) {
              return {
                      progress: 0,
                      fileId: undefined,
                      status: /* NotAsked */1
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* SetProgress */0 :
                    return /* Update */Block.__(0, [{
                                progress: action[0],
                                fileId: state.fileId,
                                status: state.status
                              }]);
                case /* SetFileId */1 :
                    return /* Update */Block.__(0, [{
                                progress: state.progress,
                                fileId: action[0],
                                status: state.status
                              }]);
                case /* SetStatus */2 :
                    return /* Update */Block.__(0, [{
                                progress: state.progress,
                                fileId: state.fileId,
                                status: action[0]
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var FileUploader = {
  component: component,
  make: make
};

var component$1 = ReasonReact.reducerComponent("ProjectUpload-BsConsole");

function getMaybePort(shell, endpointName) {
  if (typeof shell === "number" || shell.tag) {
    return ;
  } else {
    return Configuration$BsConsole.getPort(endpointName, shell[0]);
  }
}

function getMaybeUniverse(shell) {
  if (typeof shell === "number" || shell.tag) {
    return ;
  } else {
    return shell[0].universe;
  }
}

function make$1(handleChangeUrl, token, name, shell, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var match = getMaybePort(shell, "api/post");
              var match$1 = getMaybeUniverse(shell);
              return React.createElement("div", {
                          key: name
                        }, ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectTriage */Block.__(11, [
                                    name,
                                    Route$BsConsole.getInboxParams(name, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
                                  ]), undefined, handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, Css.style(/* :: */[
                                                Css.width(Css.px(160)),
                                                /* [] */0
                                              ]), true, undefined, undefined, [
                                            ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.ListItemIcon.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronLeft.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))])),
                                            ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Back")]))
                                          ]))])), ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Around */199819814, undefined, undefined, /* Center */980392437, [match !== undefined && match$1 !== undefined ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Column */-81804554, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, undefined, undefined, undefined, undefined, [
                                                                            ReasonReact.element(undefined, undefined, DropZone$BsConsole.make((function (files) {
                                                                                        return Curry._1(self.send, /* SetFiles */[files]);
                                                                                      }), ".dmp, .btt, .mdmp", {
                                                                                      minHeight: "150px",
                                                                                      minWidth: "350px",
                                                                                      padding: "3em"
                                                                                    }, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Title */594052472, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Drag & drop files here OR click to select files")]))])),
                                                                            ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])),
                                                                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [$$Array.map((function (file) {
                                                                                              return ReasonReact.element(file.name + ("_" + Pervasives.string_of_float(file.lastModified)), undefined, make(token, match, name, match$1, file, []));
                                                                                            }), self.state.files)]))
                                                                          ]))]))]))])) : null])));
            }),
          initialState: (function (param) {
              return {
                      files: [],
                      maybeForm: undefined
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          files: action[0],
                          maybeForm: state.maybeForm
                        }]);
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.FileUploader = FileUploader;
exports.component = component$1;
exports.getMaybePort = getMaybePort;
exports.getMaybeUniverse = getMaybeUniverse;
exports.make = make$1;
/* component Not a pure module */
