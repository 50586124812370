// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var Column$BsConsole = require("./utilities/Column.js");
var Effect$BsConsole = require("./effect.js");

var component = RR$BsConsole.statelessComponent("SamlErrorPage-BsConsole");

var logoContainer = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.px(16)),
      /* :: */[
        Css.marginBottom(Css.px(32)),
        /* [] */0
      ]
    ]);

var logo = Css.style(/* :: */[
      Css.height(Css.px(32)),
      /* [] */0
    ]);

var Styles = {
  logoContainer: logoContainer,
  logo: logo
};

function make(context, message, redirect, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Column$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                  Css.backgroundColor(Css.hex(Colors$BsConsole.grey75)),
                                  /* :: */[
                                    Css.width(Css.vw(100)),
                                    /* :: */[
                                      Css.height(Css.vh(100)),
                                      /* [] */0
                                    ]
                                  ]
                                ]), [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Column$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                                      Css.width(Css.px(360)),
                                                      /* :: */[
                                                        Css.padding(Css.px(24)),
                                                        /* [] */0
                                                      ]
                                                    ]), [
                                                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, logoContainer, [React.createElement("img", {
                                                                  className: logo,
                                                                  src: "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg"
                                                                })])),
                                                  ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                Css.paddingTop(Css.px(24)),
                                                                /* [] */0
                                                              ]), [
                                                            React.createElement("div", {
                                                                  className: Css.style(/* :: */[
                                                                        Css.fontSize(Css.em(1.5)),
                                                                        /* :: */[
                                                                          Css.fontWeight(/* `num */[
                                                                                5496390,
                                                                                500
                                                                              ]),
                                                                          /* [] */0
                                                                        ]
                                                                      ])
                                                                }, I18N$BsConsole.showSkip(context)),
                                                            React.createElement("div", {
                                                                  className: Css.style(/* :: */[
                                                                        Css.paddingTop(Css.px(8)),
                                                                        /* [] */0
                                                                      ])
                                                                }, I18N$BsConsole.showSkip(message))
                                                          ])),
                                                  React.createElement("div", {
                                                        className: Css.style(/* :: */[
                                                              Css.paddingTop(Css.px(24)),
                                                              /* [] */0
                                                            ])
                                                      }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                undefined,
                                                                undefined,
                                                                (function (param) {
                                                                    var r = Effect$BsConsole.getReferrerFromLocalStorage(undefined);
                                                                    var referrer;
                                                                    if (r !== undefined) {
                                                                      try {
                                                                        referrer = atob(r);
                                                                      }
                                                                      catch (exn){
                                                                        referrer = undefined;
                                                                      }
                                                                    } else {
                                                                      referrer = undefined;
                                                                    }
                                                                    Effect$BsConsole.removeReferrerFromLocalStorage(undefined);
                                                                    return Curry._1(redirect, /* Login */Block.__(3, [{
                                                                                    referrer: referrer,
                                                                                    token: undefined,
                                                                                    redirect: undefined,
                                                                                    method: undefined
                                                                                  }]));
                                                                  }),
                                                                undefined,
                                                                undefined,
                                                                /* Raised */-387916264,
                                                                /* Primary */-791844958,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                true,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                [I18N$BsConsole.show(undefined, "Back to login")]
                                                              ])))
                                                ]))]))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.Styles = Styles;
exports.make = make;
/* component Not a pure module */
