// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../../../RR.js");
var Mui$BsConsole = require("../../../bindings/Mui.js");
var Row$BsConsole = require("../../../utilities/Row.js");
var I18N$BsConsole = require("../../../I18N.js");
var Task2$BsConsole = require("../../../Task2.js");
var Colors$BsConsole = require("../../../Colors.js");
var Column$BsConsole = require("../../../utilities/Column.js");
var MuiIcons$BsConsole = require("../../../MuiIcons.js");
var AccentCard$BsConsole = require("../../../utilities/AccentCard.js");
var BtSettings$BsConsole = require("../../../BtSettings.js");
var SnackPurveyor$BsConsole = require("../../../SnackPurveyor.js");
var OrderedCardList$BsConsole = require("../../../utilities/OrderedCardList.js");
var SymbolServer_API$BsConsole = require("./SymbolServer_API.js");
var SymbolServers_Tabs$BsConsole = require("./SymbolServers_Tabs.js");
var CancelContinueDialog$BsConsole = require("../../../utilities/CancelContinueDialog.js");
var SymbolServers_Metrics$BsConsole = require("./SymbolServers_Metrics.js");
var SymbolServers_EditView$BsConsole = require("./SymbolServers_EditView.js");
var L10N_date_time_calendar$BsConsole = require("../../../L10N_date_time_calendar.js");

var backtracePrefix = "Backtrace server - ";

var noServersView = React.createElement("div", {
      className: Css.style(/* :: */[
            Css.padding(Css.px(40)),
            /* :: */[
              Css.fontSize(Css.px(14)),
              /* :: */[
                Css.fontWeight(/* medium */-20425611),
                /* :: */[
                  Css.color(Css.hex(Colors$BsConsole.grey2)),
                  /* [] */0
                ]
              ]
            ]
          ])
    }, I18N$BsConsole.show(undefined, "No servers found."));

var serverPlaceholder = {
  id: 0,
  name: "",
  url: "",
  projectId: undefined,
  universeId: undefined,
  priority: 0,
  numberOfConcurrentDownload: 0,
  retryLimit: 0,
  retryTimeout: 0,
  timeout: 0,
  creationDate: "",
  lastUpdateDate: "",
  whiteList: false,
  disable: false,
  statistics: {
    numberOfBytesDownloaded: 0,
    numberOfFailuresAtDownloadStage: 0,
    numberOfFailuresAtSymbolConversionStage: 0,
    numberOfSuccessAtDownloadStage: 0,
    numberOfSuccessAtSymbolConversionStage: 0,
    symbolServerId: 0,
    lastUpdateDate: ""
  }
};

var component = RR$BsConsole.reducerComponentWithRetainedProps("SymbolServers_InfoView-BsConsole");

function make(symboldVersion, serverList, isSuperUser, showAddView, hideAddView, showEditView, hideEditView, addView, editView, universe, project, token, endpoint, refetch, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: (function (self) {
              var listsEqual = Belt_List.eq(self.retainedProps, serverList, Caml_obj.caml_equal);
              if (listsEqual) {
                return self.state;
              }
              var init = self.state;
              var serversInOrder = Belt_List.sort(serverList, (function (a, b) {
                      return a.priority - b.priority | 0;
                    }));
              return {
                      dialog: init.dialog,
                      originalList: serverList,
                      currentList: serverList,
                      selectedServer: serversInOrder ? serversInOrder[0] : serverPlaceholder
                    };
            }),
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var selectedServer = match.selectedServer;
              var dialog = match.dialog;
              var __x = Belt_List.sort(match.currentList, (function (a, b) {
                      return a.priority - b.priority | 0;
                    }));
              var serverData = Belt_List.map(__x, (function (server) {
                      var date = L10N_date_time_calendar$BsConsole.Format.textAbbreviatedDay(undefined, DateFns.parseString(undefined, server.lastUpdateDate));
                      var match = server.projectId;
                      var match$1 = server.universeId;
                      var match$2 = server.projectId;
                      var match$3 = server.universeId;
                      var tmp;
                      var exit = 0;
                      if (match$2 !== undefined || match$3 !== undefined) {
                        exit = 1;
                      } else {
                        tmp = isSuperUser ? /* :: */[
                            {
                              executable: (function (param) {
                                  if (addView || editView !== undefined) {
                                    return Curry._1(send, /* Dialog */Block.__(0, [/* WarnAboutEdit */0]));
                                  } else {
                                    Curry._1(send, /* SelectServer */Block.__(4, [server.id]));
                                    return Curry._1(showEditView, server.id);
                                  }
                                }),
                              label: I18N$BsConsole.get(undefined, "Edit")
                            },
                            /* :: */[
                              {
                                executable: (function (param) {
                                    if (addView || editView !== undefined) {
                                      return Curry._1(send, /* Dialog */Block.__(0, [/* WarnAboutEdit */0]));
                                    } else {
                                      return Curry._1(send, /* Dialog */Block.__(0, [/* Delete */[server.id]]));
                                    }
                                  }),
                                label: I18N$BsConsole.get(undefined, "Delete")
                              },
                              /* [] */0
                            ]
                          ] : /* [] */0;
                      }
                      if (exit === 1) {
                        tmp = /* :: */[
                          {
                            executable: (function (param) {
                                if (addView || editView !== undefined) {
                                  return Curry._1(send, /* Dialog */Block.__(0, [/* WarnAboutEdit */0]));
                                } else {
                                  Curry._1(send, /* SelectServer */Block.__(4, [server.id]));
                                  return Curry._1(showEditView, server.id);
                                }
                              }),
                            label: I18N$BsConsole.get(undefined, "Edit")
                          },
                          /* :: */[
                            {
                              executable: (function (param) {
                                  if (addView || editView !== undefined) {
                                    return Curry._1(send, /* Dialog */Block.__(0, [/* WarnAboutEdit */0]));
                                  } else {
                                    return Curry._1(send, /* Dialog */Block.__(0, [/* Delete */[server.id]]));
                                  }
                                }),
                              label: I18N$BsConsole.get(undefined, "Delete")
                            },
                            /* [] */0
                          ]
                        ];
                      }
                      return {
                              dataKey: "server_" + String(server.id),
                              title: match !== undefined || match$1 !== undefined ? server.name : backtracePrefix + server.name,
                              subtitle: I18N$BsConsole.get(undefined, "Last updated") + (" - " + date),
                              infoLeft: /* String */Block.__(0, [I18N$BsConsole.get(undefined, "Successful downloads") + (" - " + Pervasives.string_of_float(server.statistics.numberOfSuccessAtDownloadStage))]),
                              infoRight: /* String */Block.__(0, [I18N$BsConsole.get(undefined, "Failed downloads") + (" - " + Pervasives.string_of_float(server.statistics.numberOfFailuresAtDownloadStage))]),
                              handleTitleUpdate: undefined,
                              onEditEnabled: undefined,
                              onEditDisabled: (function (param) {
                                  return Curry._1(hideEditView, undefined);
                                }),
                              editTitleText: undefined,
                              saveTitleText: I18N$BsConsole.get(undefined, "Save changes"),
                              cancelEditText: I18N$BsConsole.get(undefined, "Discard changes"),
                              deleteCardText: undefined,
                              deleteAction: undefined,
                              onCardClick: (function (param) {
                                  if (addView || editView !== undefined) {
                                    Curry._1(send, /* Dialog */Block.__(0, [/* WarnAboutEdit */0]));
                                  } else {
                                    Curry._1(send, /* SelectServer */Block.__(4, [server.id]));
                                  }
                                  
                                }),
                              moreActions: tmp,
                              view: server.id === selectedServer.id ? /* Selected */0 : /* Standard */1,
                              cardDisabled: false
                            };
                    }));
              var hasServers = Belt_List.length(serverData) > 0;
              var tmp;
              var exit = 0;
              if (addView) {
                if (editView !== undefined) {
                  exit = 1;
                } else {
                  tmp = ReasonReact.element(undefined, undefined, SymbolServers_EditView$BsConsole.make(symboldVersion, universe, project, endpoint, token, refetch, hideAddView, hideEditView, undefined, []));
                }
              } else if (editView !== undefined) {
                exit = 1;
              } else {
                tmp = hasServers ? ReasonReact.element(undefined, undefined, SymbolServers_Tabs$BsConsole.make(undefined, selectedServer, token, endpoint, universe, project, [])) : noServersView;
              }
              if (exit === 1) {
                tmp = React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, SymbolServers_EditView$BsConsole.make(symboldVersion, universe, project, endpoint, token, refetch, hideAddView, hideEditView, editView, [])), ReasonReact.element(undefined, undefined, SymbolServers_Tabs$BsConsole.make(undefined, selectedServer, token, endpoint, universe, project, [])));
              }
              var tmp$1;
              if (dialog !== undefined) {
                if (dialog) {
                  var id = dialog[0];
                  tmp$1 = ReasonReact.element(undefined, undefined, CancelContinueDialog$BsConsole.make((function (_event) {
                              return Curry._1(send, /* Dialog */Block.__(0, [undefined]));
                            }), (function (_event) {
                              new Promise((function (resolve, param) {
                                      var arg = SymbolServer_API$BsConsole.deleteSymbolServer(endpoint, id, undefined);
                                      return Curry._2((function (param) {
                                                      return (function (param$1, param$2) {
                                                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                        });
                                                    })(token), undefined, (function (resp) {
                                                    if (resp.tag) {
                                                      return resolve(Caml_option.some(resp[0]));
                                                    }
                                                    var res = resp[0];
                                                    var _error = res.error;
                                                    if (_error !== undefined) {
                                                      SymbolServers_Metrics$BsConsole.send(/* ServerDeleteFailed */5);
                                                      var m = res.message;
                                                      if (m !== undefined) {
                                                        SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Failed to delete server. ") + m));
                                                      } else {
                                                        SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to delete server."));
                                                      }
                                                      Curry._1(send, /* Dialog */Block.__(0, [undefined]));
                                                    } else {
                                                      SymbolServers_Metrics$BsConsole.send(/* ServerDeleted */4);
                                                      Curry._1(refetch, undefined);
                                                      Curry._1(send, /* Dialog */Block.__(0, [undefined]));
                                                    }
                                                    return resolve(undefined);
                                                  }));
                                    }));
                              
                            }), I18N$BsConsole.show(undefined, "Are you sure you want to delete this server?"), I18N$BsConsole.show(undefined, "Delete server"), []));
                } else {
                  tmp$1 = ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                            undefined,
                            true,
                            (function (_event) {
                                return Curry._1(send, /* Dialog */Block.__(0, [undefined]));
                              }),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                        ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, /* flexEnd */924268066, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                            undefined,
                                                            undefined,
                                                            (function (_event) {
                                                                return Curry._1(send, /* Dialog */Block.__(0, [undefined]));
                                                              }),
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                          ]))])),
                                        ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [React.createElement("div", {
                                                        className: Css.style(/* :: */[
                                                              Css.marginBottom(Css.px(10)),
                                                              /* [] */0
                                                            ])
                                                      }, I18N$BsConsole.show(undefined, "Please save or cancel the existing server before continuing."))]))
                                      ]))]
                          ]));
                }
              } else {
                tmp$1 = null;
              }
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, Css.style(/* :: */[
                                            Css.height(/* `calc */[
                                                  -1044768619,
                                                  /* tuple */[
                                                    Css.sub,
                                                    Css.vh(100),
                                                    Css.px(64)
                                                  ]
                                                ]),
                                            /* :: */[
                                              Css.width(Css.pct(100)),
                                              /* :: */[
                                                Css.overflow(/* hidden */-862584982),
                                                /* [] */0
                                              ]
                                            ]
                                          ]), [ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                      Css.height(Css.pct(100)),
                                                      /* [] */0
                                                    ]), [ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                Css.overflow(/* hidden */-862584982),
                                                                /* :: */[
                                                                  Css.height(Css.pct(100)),
                                                                  /* [] */0
                                                                ]
                                                              ]), [
                                                            ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                          Css.paddingLeft(Css.px(20)),
                                                                          /* :: */[
                                                                            Css.paddingRight(Css.px(20)),
                                                                            /* :: */[
                                                                              Css.paddingTop(Css.px(20)),
                                                                              /* :: */[
                                                                                Css.paddingBottom(Css.px(20)),
                                                                                /* :: */[
                                                                                  Css.overflowY(Css.auto),
                                                                                  /* :: */[
                                                                                    Css.height(Css.pct(100)),
                                                                                    /* [] */0
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ]
                                                                        ]), [
                                                                      ReasonReact.element(undefined, undefined, AccentCard$BsConsole.make(I18N$BsConsole.get(undefined, "Add new symbol server"), Caml_option.some(ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.AddCircle.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), showAddView, addView, undefined, [])),
                                                                      ReasonReact.element(undefined, undefined, OrderedCardList$BsConsole.make(serverData, (function (newList) {
                                                                                  var newListObjects = Belt_List.map(newList, (function (item) {
                                                                                          var match_ = Belt_List.getBy(serverList, (function (obj) {
                                                                                                  return "server_" + String(obj.id) === item.dataKey;
                                                                                                }));
                                                                                          if (match_ !== undefined) {
                                                                                            return match_;
                                                                                          } else {
                                                                                            return serverPlaceholder;
                                                                                          }
                                                                                        }));
                                                                                  var newListObjectsWithPriorities = Belt_List.mapWithIndex(newListObjects, (function (index, item) {
                                                                                          return {
                                                                                                  id: item.id,
                                                                                                  name: item.name,
                                                                                                  url: item.url,
                                                                                                  projectId: item.projectId,
                                                                                                  universeId: item.universeId,
                                                                                                  priority: index,
                                                                                                  numberOfConcurrentDownload: item.numberOfConcurrentDownload,
                                                                                                  retryLimit: item.retryLimit,
                                                                                                  retryTimeout: item.retryTimeout,
                                                                                                  timeout: item.timeout,
                                                                                                  creationDate: item.creationDate,
                                                                                                  lastUpdateDate: item.lastUpdateDate,
                                                                                                  whiteList: item.whiteList,
                                                                                                  disable: item.disable,
                                                                                                  statistics: item.statistics
                                                                                                };
                                                                                        }));
                                                                                  Curry._1(send, /* UpdateCurrentList */Block.__(3, [newListObjectsWithPriorities]));
                                                                                  var newListWithNewPrioritiesToPost = Belt_List.mapWithIndex(newListObjects, (function (index, item) {
                                                                                          return {
                                                                                                  symbolServerId: item.id,
                                                                                                  priority: index
                                                                                                };
                                                                                        }));
                                                                                  new Promise((function (resolve, param) {
                                                                                          var arg = SymbolServer_API$BsConsole.postPriority(endpoint, universe, project, newListWithNewPrioritiesToPost, undefined);
                                                                                          return Curry._2((function (param) {
                                                                                                          return (function (param$1, param$2) {
                                                                                                              return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                                                            });
                                                                                                        })(token), undefined, (function (resp) {
                                                                                                        if (resp.tag) {
                                                                                                          SymbolServers_Metrics$BsConsole.send(/* ServerPriorityUpdateFailed */7);
                                                                                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to update servers. Order has been reset."));
                                                                                                          return resolve(Caml_option.some(resp[0]));
                                                                                                        }
                                                                                                        Curry._1(send, /* UpdateOriginalList */Block.__(2, [newListObjects]));
                                                                                                        SymbolServers_Metrics$BsConsole.send(/* ServerPriorityUpdate */6);
                                                                                                        SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully updated server order."));
                                                                                                        return resolve(undefined);
                                                                                                      }));
                                                                                        }));
                                                                                  
                                                                                }), undefined, []))
                                                                    ])),
                                                            React.createElement("div", {
                                                                  className: Css.style(/* :: */[
                                                                        Css.borderRight(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey5)),
                                                                        /* :: */[
                                                                          Css.paddingLeft(Css.px(1)),
                                                                          /* [] */0
                                                                        ]
                                                                      ])
                                                                }),
                                                            ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                                          Css.flex(/* `num */[
                                                                                5496390,
                                                                                1
                                                                              ]),
                                                                          /* :: */[
                                                                            Css.minWidth(Css.zero),
                                                                            /* :: */[
                                                                              Css.height(Css.pct(100)),
                                                                              /* [] */0
                                                                            ]
                                                                          ]
                                                                        ]), [tmp]))
                                                          ]))]))])),
                              tmp$1
                            ]));
            }),
          initialState: (function (param) {
              var serversInOrder = Belt_List.sort(serverList, (function (a, b) {
                      return a.priority - b.priority | 0;
                    }));
              return {
                      dialog: undefined,
                      originalList: serverList,
                      currentList: serverList,
                      selectedServer: serversInOrder ? serversInOrder[0] : serverPlaceholder
                    };
            }),
          retainedProps: serverList,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* Update */Block.__(0, [{
                            dialog: state.dialog,
                            originalList: state.originalList,
                            currentList: state.originalList,
                            selectedServer: state.selectedServer
                          }]);
              }
              switch (action.tag | 0) {
                case /* Dialog */0 :
                    return /* Update */Block.__(0, [{
                                dialog: action[0],
                                originalList: state.originalList,
                                currentList: state.currentList,
                                selectedServer: state.selectedServer
                              }]);
                case /* DeleteServer */1 :
                    return /* NoUpdate */0;
                case /* UpdateOriginalList */2 :
                    return /* Update */Block.__(0, [{
                                dialog: state.dialog,
                                originalList: action[0],
                                currentList: state.currentList,
                                selectedServer: state.selectedServer
                              }]);
                case /* UpdateCurrentList */3 :
                    return /* Update */Block.__(0, [{
                                dialog: state.dialog,
                                originalList: state.originalList,
                                currentList: action[0],
                                selectedServer: state.selectedServer
                              }]);
                case /* SelectServer */4 :
                    var id = action[0];
                    var __x = state.currentList;
                    var r = Belt_List.filter(__x, (function (o) {
                            return o.id === id;
                          }));
                    return /* Update */Block.__(0, [{
                                dialog: state.dialog,
                                originalList: state.originalList,
                                currentList: state.currentList,
                                selectedServer: r ? r[0] : serverPlaceholder
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.backtracePrefix = backtracePrefix;
exports.noServersView = noServersView;
exports.serverPlaceholder = serverPlaceholder;
exports.component = component;
exports.make = make;
/* noServersView Not a pure module */
