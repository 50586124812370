// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var Rawable$BsConsole = require("../Rawable.js");
var BugStyles$BsConsole = require("./BugStyles.js");
var BugSystem$BsConsole = require("./BugSystem.js");
var BugMetrics$BsConsole = require("./BugMetrics.js");
var Tab = require("@material-ui/core/Tab");
var Icon = require("@material-ui/core/Icon");
var Tabs = require("@material-ui/core/Tabs");
var BugMemoryMap$BsConsole = require("./BugMemoryMap.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var BugAttributes$BsConsole = require("./BugAttributes.js");
var Switch = require("@material-ui/core/Switch");
var BugAttachments$BsConsole = require("./BugAttachments.js");
var BugBreadcrumbs$BsConsole = require("./breadcrumbs/BugBreadcrumbs.js");
var Tooltip = require("@material-ui/core/Tooltip");
var Attribute_Helpers$BsConsole = require("../Attribute_Helpers.js");
var BugGlobalVariables$BsConsole = require("./BugGlobalVariables.js");
var BugBreadcrumbsUtils$BsConsole = require("./breadcrumbs/BugBreadcrumbsUtils.js");
var BugGlobalAnnotations$BsConsole = require("./BugGlobalAnnotations.js");

function toString(param) {
  switch (param) {
    case /* Attributes */0 :
        return "Attributes";
    case /* System */1 :
        return "System";
    case /* MemoryMap */2 :
        return "MemoryMap";
    case /* GlobalAnnotations */3 :
        return "GlobalAnnotations";
    case /* GlobalVariables */4 :
        return "GlobalVariables";
    case /* Source */5 :
        return "Source";
    case /* Breadcrumbs */6 :
        return "Breadcrumbs";
    case /* Attachments */7 :
        return "Attachments";
    
  }
}

function BugTabs$Tabs(Props) {
  var activeTab = Props.activeTab;
  var setActiveTab = Props.setActiveTab;
  var scrollable = Props.scrollable;
  var attributes = Props.attributes;
  var system = Props.system;
  var memoryMap = Props.memoryMap;
  var globalAnnotations = Props.globalAnnotations;
  var globalVariables = Props.globalVariables;
  Props.projectName;
  var enableSourceTabOpt = Props.enableSourceTab;
  var shouldShowBreadcrumbs = Props.shouldShowBreadcrumbs;
  var enableSourceTab = enableSourceTabOpt !== undefined ? enableSourceTabOpt : false;
  var tmp = {
    value: activeTab,
    classes: {
      root: Css.style(/* :: */[
            Css.width(Css.pct(100)),
            /* [] */0
          ])
    },
    indicatorColor: "primary",
    textColor: "primary",
    onChange: (function (param, newTab) {
        BugMetrics$BsConsole.send(/* ChangeTab */[toString(newTab)]);
        return Curry._1(setActiveTab, (function (param) {
                      return newTab;
                    }));
      }),
    children: null
  };
  var tmp$1 = scrollable ? "scrollable" : undefined;
  if (tmp$1 !== undefined) {
    tmp.variant = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Tabs.default, tmp, enableSourceTab ? React.createElement(Tab.default, {
                    classes: {
                      root: "walkthrough--debugger--source_code"
                    },
                    value: /* Source */5,
                    label: I18N$BsConsole.show(undefined, "Source"),
                    disabled: false
                  }) : null, React.createElement(Tab.default, {
                  classes: {
                    root: "walkthrough--debugger--attributes"
                  },
                  value: /* Attributes */0,
                  label: I18N$BsConsole.show(undefined, "Attributes"),
                  disabled: Object.keys(attributes).length === 0
                }), system !== undefined ? React.createElement(Tab.default, {
                    classes: {
                      root: "walkthrough--debugger--system"
                    },
                    value: /* System */1,
                    label: I18N$BsConsole.show(undefined, "System")
                  }) : null, memoryMap !== undefined && memoryMap.length !== 0 ? React.createElement(Tab.default, {
                    classes: {
                      root: "walkthrough--debugger--memory_map"
                    },
                    value: /* MemoryMap */2,
                    label: I18N$BsConsole.show(undefined, "Memory map"),
                    disabled: memoryMap.length === 0
                  }) : null, React.createElement(Tab.default, {
                  classes: {
                    root: "walkthrough--debugger--global_annotations"
                  },
                  value: /* GlobalAnnotations */3,
                  label: I18N$BsConsole.show(undefined, "Global annotations"),
                  disabled: globalAnnotations.length === 0
                }), globalVariables !== undefined && globalVariables.length !== 0 ? React.createElement(Tab.default, {
                    classes: {
                      root: "walkthrough--debugger--global_variables"
                    },
                    value: /* GlobalVariables */4,
                    label: I18N$BsConsole.show(undefined, "Global variables")
                  }) : null, Curry._1(shouldShowBreadcrumbs, undefined) ? React.createElement(Tab.default, {
                    classes: {
                      root: "walkthrough--debugger--breadcrumbs"
                    },
                    value: /* Breadcrumbs */6,
                    label: I18N$BsConsole.show(undefined, "Breadcrumbs")
                  }) : null, React.createElement(Tab.default, {
                  classes: {
                    root: "walkthrough--debugger--attachments"
                  },
                  value: /* Attachments */7,
                  label: I18N$BsConsole.show(undefined, "Attachments")
                }));
}

var Tabs$1 = {
  make: BugTabs$Tabs
};

function BugTabs$TabContent(Props) {
  var token = Props.token;
  var isRaw = Props.isRaw;
  var onAddFilter = Props.onAddFilter;
  var activeTab = Props.activeTab;
  var attributes = Props.attributes;
  var system = Props.system;
  var memoryMap = Props.memoryMap;
  var globalAnnotations = Props.globalAnnotations;
  var globalVariables = Props.globalVariables;
  var width = Props.width;
  var height = Props.height;
  var selectedMemoryAddress = Props.selectedMemoryAddress;
  var bugSourceEl = Props.bugSourceEl;
  var attachments = Props.attachments;
  var projectName = Props.projectName;
  var errorId = Props.errorId;
  var showBacktraceUnitySDKCTA = Props.showBacktraceUnitySDKCTA;
  var match = React.useState((function () {
          
        }));
  var tmp;
  switch (activeTab) {
    case /* Attributes */0 :
        tmp = React.createElement(Rawable$BsConsole.make, {
              raw: isRaw,
              data: attributes,
              children: React.createElement(BugAttributes$BsConsole.make, {
                    onAddFilter: onAddFilter,
                    width: width,
                    height: height,
                    attributes: attributes
                  })
            });
        break;
    case /* System */1 :
        if (system !== undefined) {
          var system$1 = Caml_option.valFromOption(system);
          tmp = React.createElement(Rawable$BsConsole.make, {
                raw: isRaw,
                data: system$1,
                children: React.createElement(BugSystem$BsConsole.make, {
                      system: system$1
                    })
              });
        } else {
          tmp = null;
        }
        break;
    case /* MemoryMap */2 :
        tmp = memoryMap !== undefined ? React.createElement(Rawable$BsConsole.make, {
                raw: isRaw,
                data: memoryMap,
                children: React.createElement(BugMemoryMap$BsConsole.make, {
                      selectedMemoryAddress: selectedMemoryAddress,
                      memoryMap: memoryMap,
                      height: height
                    })
              }) : null;
        break;
    case /* GlobalAnnotations */3 :
        tmp = React.createElement(Rawable$BsConsole.make, {
              raw: isRaw,
              data: globalAnnotations,
              children: React.createElement(BugGlobalAnnotations$BsConsole.make, {
                    globalAnnotations: globalAnnotations
                  })
            });
        break;
    case /* GlobalVariables */4 :
        tmp = globalVariables !== undefined ? React.createElement(Rawable$BsConsole.make, {
                raw: isRaw,
                data: globalVariables,
                children: React.createElement(BugGlobalVariables$BsConsole.make, {
                      globalVariables: globalVariables
                    })
              }) : null;
        break;
    case /* Source */5 :
        tmp = Belt_Option.mapWithDefault(bugSourceEl, null, Util$BsConsole.identity);
        break;
    case /* Breadcrumbs */6 :
        tmp = React.createElement(BugBreadcrumbs$BsConsole.make, {
              token: token,
              showHeader: false,
              attachments: attachments,
              attributes: attributes,
              projectName: projectName,
              errorId: errorId,
              breadbox: match[0],
              setBreadbox: match[1],
              height: height,
              width: width,
              showBacktraceUnitySDKCTA: showBacktraceUnitySDKCTA
            });
        break;
    case /* Attachments */7 :
        tmp = React.createElement(BugAttachments$BsConsole.make, {
              token: token,
              attachments: attachments,
              projectName: projectName,
              errorId: errorId
            });
        break;
    
  }
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.height(Css.pct(100)),
                    /* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.marginTop(Css.px(1)),
                        /* :: */[
                          Css.overflow(Css.auto),
                          /* [] */0
                        ]
                      ]
                    ]
                  ])
            }, tmp);
}

var TabContent = {
  make: BugTabs$TabContent
};

function shouldShowBacktraceUnitySDKCallToAction(attributes) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Js_dict.get(attributes, "backtrace.datasource"), (function (jsonValue) {
                        return Caml_option.null_to_opt(Json_decode.nullable(Json_decode.string, jsonValue));
                      })), (function (dataSource) {
                    return dataSource === "connector.ucd";
                  })), false);
}

function BugTabs(Props) {
  var token = Props.token;
  var onAddFilter = Props.onAddFilter;
  var attributes = Props.attributes;
  var globalAnnotations = Props.globalAnnotations;
  var memoryMap = Props.memoryMap;
  var system = Props.system;
  var globalVariables = Props.globalVariables;
  var width = Props.width;
  var height = Props.height;
  var selectedMemoryAddress = Props.selectedMemoryAddress;
  var bugSourceEl = Props.bugSourceEl;
  var projectName = Props.projectName;
  var attachments = Props.attachments;
  var errorId = Props.errorId;
  var isRaw = Props.isRaw;
  var toggleIsRaw = Props.toggleIsRaw;
  var match = React.useState((function () {
          return false;
        }));
  var setSplit = match[1];
  var split = match[0];
  var match$1 = React.useState((function () {
          return /* Attributes */0;
        }));
  var setActiveTab = match$1[1];
  var activeTab = match$1[0];
  var match$2 = React.useState((function () {
          return /* Attributes */0;
        }));
  var activeTab2 = match$2[0];
  var tabContentHeight = height - 33 | 0;
  var splitButton = React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
        ariaLabel: split ? "Switch to single view" : "Switch to split view",
        className: Css.style(/* :: */[
              Css.marginRight(Css.px(16)),
              /* [] */0
            ]),
        onClick: (function (param) {
            MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_SplitViewClick */110]));
            return Curry._1(setSplit, (function (prim) {
                          return !prim;
                        }));
          }),
        title_tooltip: split ? "Unsplit view" : "Split view",
        children: React.createElement(Icon.default, {
              fontSize: "small",
              children: I18N$BsConsole.showSkip("vertical_split")
            })
      });
  var showBacktraceUnitySDKCTA = shouldShowBacktraceUnitySDKCallToAction(attributes);
  var shouldShowBreadcrumbs = function (param) {
    var match = Attribute_Helpers$BsConsole.SDKVariant.getSDKVariant(attributes);
    if (match) {
      if (BugBreadcrumbsUtils$BsConsole.hasBreadcrumbFiles(attachments)) {
        return true;
      } else {
        return showBacktraceUnitySDKCTA;
      }
    } else {
      return false;
    }
  };
  var tmp = {
    token: token,
    isRaw: isRaw,
    onAddFilter: onAddFilter,
    activeTab: activeTab,
    attributes: attributes,
    system: system,
    memoryMap: memoryMap,
    globalAnnotations: globalAnnotations,
    globalVariables: globalVariables,
    width: split ? width / 2 | 0 : width,
    height: tabContentHeight,
    attachments: attachments,
    projectName: projectName,
    errorId: errorId,
    showBacktraceUnitySDKCTA: showBacktraceUnitySDKCTA
  };
  if (selectedMemoryAddress !== undefined) {
    tmp.selectedMemoryAddress = Caml_option.valFromOption(selectedMemoryAddress);
  }
  if (bugSourceEl !== undefined) {
    tmp.bugSourceEl = Caml_option.valFromOption(bugSourceEl);
  }
  var tmp$1;
  if (split) {
    var tmp$2 = {
      token: token,
      isRaw: isRaw,
      onAddFilter: onAddFilter,
      activeTab: activeTab2,
      attributes: attributes,
      system: system,
      memoryMap: memoryMap,
      globalAnnotations: globalAnnotations,
      globalVariables: globalVariables,
      width: split ? width / 2 | 0 : width,
      height: tabContentHeight,
      attachments: attachments,
      projectName: projectName,
      errorId: errorId,
      showBacktraceUnitySDKCTA: showBacktraceUnitySDKCTA
    };
    if (selectedMemoryAddress !== undefined) {
      tmp$2.selectedMemoryAddress = Caml_option.valFromOption(selectedMemoryAddress);
    }
    if (bugSourceEl !== undefined) {
      tmp$2.bugSourceEl = Caml_option.valFromOption(bugSourceEl);
    }
    tmp$1 = React.createElement("div", {
          className: Css.style(/* :: */[
                Css.width(Css.pct(50)),
                /* :: */[
                  Css.height(Css.pct(100)),
                  /* [] */0
                ]
              ])
        }, React.createElement(BugTabs$TabContent, tmp$2));
  } else {
    tmp$1 = null;
  }
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.height(Css.pct(100)),
                      /* :: */[
                        Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
                        /* :: */[
                          Css.position(Css.relative),
                          /* :: */[
                            Css.zIndex(5),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]),
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  justifyContent: /* spaceBetween */516682146,
                  className: Css.style(/* :: */[
                        Css.borderBottom(Css.px(1), Css.solid, Css.hex(BugStyles$BsConsole.Color.dividerTertiary)),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]
                      ]),
                  children: split ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.width(Css.pct(50)),
                                    /* :: */[
                                      Css.borderRight(Css.px(1), Css.solid, Css.hex(BugStyles$BsConsole.Color.dividerPrimary)),
                                      /* [] */0
                                    ]
                                  ])
                            }, React.createElement(BugTabs$Tabs, {
                                  activeTab: activeTab,
                                  setActiveTab: setActiveTab,
                                  scrollable: true,
                                  attributes: attributes,
                                  system: system,
                                  memoryMap: memoryMap,
                                  globalAnnotations: globalAnnotations,
                                  globalVariables: globalVariables,
                                  projectName: projectName,
                                  enableSourceTab: Belt_Option.isSome(bugSourceEl),
                                  shouldShowBreadcrumbs: shouldShowBreadcrumbs
                                })), React.createElement(Row2$BsConsole.make, {
                              className: Css.style(/* :: */[
                                    Css.width(Css.pct(50)),
                                    /* :: */[
                                      Css.alignItems(/* center */98248149),
                                      /* [] */0
                                    ]
                                  ]),
                              children: null
                            }, React.createElement(BugTabs$Tabs, {
                                  activeTab: activeTab2,
                                  setActiveTab: match$2[1],
                                  scrollable: true,
                                  attributes: attributes,
                                  system: system,
                                  memoryMap: memoryMap,
                                  globalAnnotations: globalAnnotations,
                                  globalVariables: globalVariables,
                                  projectName: projectName,
                                  enableSourceTab: Belt_Option.isSome(bugSourceEl),
                                  shouldShowBreadcrumbs: shouldShowBreadcrumbs
                                }), React.createElement(Tooltip.default, {
                                  children: React.createElement("div", undefined, React.createElement(Switch.default, {
                                            classes: {
                                              switchBase: Css.style(/* :: */[
                                                    Css.height(Css.px(32)),
                                                    /* [] */0
                                                  ])
                                            },
                                            onChange: (function (param, param$1) {
                                                MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_ShowFormattedDataClick */107]));
                                                return Curry._1(toggleIsRaw, undefined);
                                              }),
                                            disableRipple: true,
                                            checked: !isRaw
                                          })),
                                  title: "Show formatted data"
                                }), splitButton)) : React.createElement(React.Fragment, undefined, React.createElement(BugTabs$Tabs, {
                              activeTab: activeTab,
                              setActiveTab: setActiveTab,
                              scrollable: split,
                              attributes: attributes,
                              system: system,
                              memoryMap: memoryMap,
                              globalAnnotations: globalAnnotations,
                              globalVariables: globalVariables,
                              projectName: projectName,
                              enableSourceTab: Belt_Option.isSome(bugSourceEl),
                              shouldShowBreadcrumbs: shouldShowBreadcrumbs
                            }), React.createElement(Tooltip.default, {
                              children: React.createElement("div", undefined, React.createElement(Switch.default, {
                                        classes: {
                                          switchBase: Css.style(/* :: */[
                                                Css.height(Css.px(32)),
                                                /* [] */0
                                              ])
                                        },
                                        onChange: (function (param, param$1) {
                                            MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_ShowFormattedDataClick */107]));
                                            return Curry._1(toggleIsRaw, undefined);
                                          }),
                                        disableRipple: true,
                                        checked: !isRaw
                                      })),
                              title: "Show formatted data"
                            }), splitButton)
                }), React.createElement(Row2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.height(Css.pct(100)),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.overflow(/* hidden */-862584982),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement("div", {
                      className: split ? Css.style(/* :: */[
                              Css.width(Css.pct(50)),
                              /* :: */[
                                Css.height(Css.pct(100)),
                                /* :: */[
                                  Css.borderRight(Css.px(1), Css.solid, Css.hex(BugStyles$BsConsole.Color.dividerPrimary)),
                                  /* [] */0
                                ]
                              ]
                            ]) : Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.height(Css.pct(100)),
                                /* [] */0
                              ]
                            ])
                    }, React.createElement(BugTabs$TabContent, tmp)), tmp$1));
}

var make = React.memo(BugTabs);

exports.toString = toString;
exports.Tabs = Tabs$1;
exports.TabContent = TabContent;
exports.shouldShowBacktraceUnitySDKCallToAction = shouldShowBacktraceUnitySDKCallToAction;
exports.make = make;
/* make Not a pure module */
