// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("./I18N.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var StorageFetch$BsConsole = require("./StorageFetch.js");
var SystemUsageDistribution$BsConsole = require("./charts/SystemUsageDistribution.js");

function SystemUsage(Props) {
  var config = Props.config;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = StorageFetch$BsConsole.use(token, config);
  var storageRemote = match[0];
  if (typeof storageRemote === "number") {
    return null;
  }
  if (storageRemote.tag) {
    return null;
  }
  var uni = storageRemote[0];
  var data = Belt_Array.map(uni.projects, (function (project) {
          return /* tuple */[
                  project.name,
                  project.total
                ];
        }));
  return ReasonReact.element(undefined, undefined, SystemUsageDistribution$BsConsole.Re.make(data, undefined, (function (param) {
                    return Curry._1(handleChangeUrl, /* ProjectSettings */Block.__(22, [param[0]]));
                  }), uni.timestamp, I18N$BsConsole.get(undefined, "Projects"), []));
}

function make(config, token, handleChangeUrl, children) {
  return ReasonReactCompat.wrapReactForReasonReact(SystemUsage, {
              config: config,
              token: token,
              handleChangeUrl: handleChangeUrl
            }, children);
}

var Re = {
  make: make
};

var make$1 = SystemUsage;

exports.make = make$1;
exports.Re = Re;
/* ReasonReact Not a pure module */
