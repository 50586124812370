// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var SubUtil$BsConsole = require("./SubUtil.js");
var Card = require("@material-ui/core/Card");
var Button = require("@material-ui/core/Button");
var NemesisMetrics$BsConsole = require("./NemesisMetrics.js");
var CardContent = require("@material-ui/core/CardContent");

var logoContainer = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.px(16)),
      /* :: */[
        Css.marginBottom(Css.px(32)),
        /* [] */0
      ]
    ]);

var logo = Css.style(/* :: */[
      Css.height(Css.px(32)),
      /* [] */0
    ]);

var Styles = {
  logoContainer: logoContainer,
  logo: logo
};

function FrozenPage(Props) {
  var handleLogout = Props.handleLogout;
  React.useEffect((function () {
          NemesisMetrics$BsConsole.send(/* FrozenPageImpression */7);
          
        }), ([]));
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.position(/* absolute */-1013592457),
                    /* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.height(Css.pct(100)),
                        /* :: */[
                          Css.backgroundColor(Css.hex(Colors$BsConsole.blueBackground)),
                          /* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.justifyContent(/* center */98248149),
                              /* :: */[
                                Css.alignItems(/* center */98248149),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement(Card.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.width(Css.px(400)),
                          /* [] */0
                        ])
                  },
                  children: React.createElement(CardContent.default, {
                        children: null
                      }, React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            justifyContent: /* center */98248149,
                            className: logoContainer,
                            children: React.createElement("img", {
                                  className: logo,
                                  src: "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg"
                                })
                          }), React.createElement("div", {
                            className: Css.merge(/* :: */[
                                  SubUtil$BsConsole.Style.titleText,
                                  /* :: */[
                                    Css.style(/* :: */[
                                          Css.marginTop(Css.px(24)),
                                          /* :: */[
                                            Css.marginBottom(Css.px(12)),
                                            /* [] */0
                                          ]
                                        ]),
                                    /* [] */0
                                  ]
                                ])
                          }, I18N$BsConsole.show(undefined, "This account has been frozen.")), React.createElement("div", {
                            className: SubUtil$BsConsole.Style.bodyText
                          }, I18N$BsConsole.show(undefined, "Your free trial has expired. Your account can be restored through a paid subscription. Please reach out to the owner of your Backtrace instance or our support team for help.")), React.createElement(Row2$BsConsole.make, {
                            justifyContent: /* flexEnd */924268066,
                            children: React.createElement(Button.default, {
                                  color: "primary",
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.marginTop(Css.px(12)),
                                          /* [] */0
                                        ])
                                  },
                                  onClick: (function (param) {
                                      NemesisMetrics$BsConsole.send(/* FrozenPageLogoutClick */8);
                                      return Curry._1(handleLogout, undefined);
                                    }),
                                  children: I18N$BsConsole.show(undefined, "Logout")
                                })
                          }))
                }));
}

function make(handleLogout, children) {
  return ReasonReactCompat.wrapReactForReasonReact(FrozenPage, {
              handleLogout: handleLogout
            }, children);
}

var Jsx2 = {
  make: make
};

var make$1 = FrozenPage;

exports.Styles = Styles;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* logoContainer Not a pure module */
