// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Route$BsConsole = require("../route.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Classifiers$BsConsole = require("../format-renderers/Classifiers.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var BtTableColumn$BsConsole = require("../BtTableColumn.js");
var FormatPopover$BsConsole = require("../format-renderers/FormatPopover.js");
var TriageTickets$BsConsole = require("../TriageTickets.js");
var Button = require("@material-ui/core/Button");
var FeatureService$BsConsole = require("../FeatureService.js");
var FormatRenderer$BsConsole = require("../format-renderers/FormatRenderer.js");
var Divider = require("@material-ui/core/Divider");
var Tooltip = require("@material-ui/core/Tooltip");
var BtEnhancedTable$BsConsole = require("../BtEnhancedTable.js");
var InboxMergeButton$BsConsole = require("../inbox/InboxMergeButton.js");
var IconButton = require("@material-ui/core/IconButton");
var SimilarityCandidate$BsConsole = require("./SimilarityCandidate.js");
var KeyboardArrowRight = require("@material-ui/icons/KeyboardArrowRight");
var SimilarityCallstackComparison$BsConsole = require("./SimilarityCallstackComparison.js");

var encodedPipe = "%7C";

function exploreUrl(fps, projectName) {
  var fpString = Belt_Array.reduce(fps, "", (function (a, b) {
          if (a === "") {
            return b;
          } else {
            return a + (encodedPipe + b);
          }
        }));
  return "/p/" + (projectName + ("/explore?time=all&filters=((fingerprint%2Cregex%2C%22 " + (fpString + "%22))&aggregations=((callstack%2Chead))")));
}

function SimilarityDetail(Props) {
  var candidateDataMaybe = Props.candidateDataMaybe;
  var similarityEndpointMaybe = Props.similarityEndpointMaybe;
  var supportsTickets = Props.supportsTickets;
  var setSelectedGroups = Props.setSelectedGroups;
  var selectedGroups = Props.selectedGroups;
  var triageGroup = Props.triageGroup;
  var token = Props.token;
  var projectName = Props.projectName;
  var handleChangeUrl = Props.handleChangeUrl;
  var aperture = Props.aperture;
  var config = Props.config;
  var match = React.useState((function () {
          
        }));
  var setSortingState = match[1];
  var sortingState = match[0];
  React.useState((function () {
          return false;
        }));
  React.useEffect((function () {
          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Similarity_DetailTabClick */90]));
          
        }), ([]));
  var baseClassifiersMaybe = Belt_Array.keepMap(triageGroup.aggregations, (function (shape) {
          if (shape.tag === /* Head */3 && shape[0] === "classifiers") {
            return shape[1];
          }
          
        }));
  var baseClassifiers;
  if (baseClassifiersMaybe.length !== 1) {
    baseClassifiers = /* [] */0;
  } else {
    var c = baseClassifiersMaybe[0];
    baseClassifiers = c.tag === /* Classifiers */4 ? c[0] : /* [] */0;
  }
  var match$1 = triageGroup.timestampRange;
  var baseTimeRange = match$1 !== undefined ? /* :: */[
      match$1[0].getTime() / 1000 | 0,
      /* :: */[
        match$1[1].getTime() / 1000 | 0,
        /* [] */0
      ]
    ] : /* [] */0;
  if (similarityEndpointMaybe === undefined) {
    return React.createElement(FeatureService$BsConsole.Unavailable.make, {
                serviceName: "similarity"
              });
  }
  if (candidateDataMaybe === undefined) {
    return React.createElement(BtTypography$BsConsole.make, {
                variant: /* Body1 */8,
                className: Css.style(/* :: */[
                      Css.padding(Css.px(25)),
                      /* [] */0
                    ]),
                color: Colors$BsConsole.grey0,
                children: I18N$BsConsole.show(undefined, "Whoops, something went wrong fetching data from the similarity service.")
              });
  }
  var __x = candidateDataMaybe.candidates;
  var candidatesWithRank = Belt_List.mapWithIndex(__x, (function (index, candidate) {
          var c = Belt_List.head(candidate.classifiers);
          return {
                  fingerprint: candidate.fingerprint,
                  first_seen: candidate.first_seen,
                  callstack: candidate.callstack,
                  classifiers: c !== undefined ? Belt_List.fromArray(c.split(" ")) : /* [] */0,
                  dates: candidate.dates,
                  count: candidate.count,
                  mainCallstackDiff: candidate.mainCallstackDiff,
                  candidateCallstackDiff: candidate.candidateCallstackDiff,
                  distance: candidate.distance,
                  state: candidate.state,
                  tickets: candidate.tickets,
                  rank: index + 1 | 0
                };
        }));
  var columns = [
    BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Rank"), "rank", (function (row) {
            return /* `Int */[
                    3654863,
                    row.rank
                  ];
          }), undefined, undefined, undefined, undefined, undefined, 166, undefined, undefined, undefined, undefined),
    BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Distance"), "distance", (function (row) {
            return /* `Element */[
                    -744106340,
                    React.createElement(Row2$BsConsole.make, {
                          justifyContent: /* flexEnd */924268066,
                          children: React.createElement(Tooltip.default, {
                                classes: {
                                  popper: Css.style(/* :: */[
                                        Css.opacity(1),
                                        /* [] */0
                                      ]),
                                  tooltip: Css.style(/* :: */[
                                        Css.width(Css.pct(100)),
                                        /* :: */[
                                          Css.padding(Css.zero),
                                          /* :: */[
                                            Css.background(Css.none),
                                            /* :: */[
                                              Css.maxWidth(Css.vw(40)),
                                              /* :: */[
                                                Css.color(Css.hex(Colors$BsConsole.grey0)),
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]
                                      ])
                                },
                                interactive: true,
                                placement: "right-start",
                                enterDelay: 300,
                                children: React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.cursor(/* pointer */-786317123),
                                            /* :: */[
                                              Css.display(/* flex */-1010954439),
                                              /* :: */[
                                                Css.alignItems(/* center */98248149),
                                                /* [] */0
                                              ]
                                            ]
                                          ])
                                    }, React.createElement("div", undefined, I18N$BsConsole.showSkip(String(row.distance))), React.createElement(IconButton.default, {
                                          classes: {
                                            root: Css.style(/* :: */[
                                                  Css.padding(Css.px(5)),
                                                  /* :: */[
                                                    Css.marginLeft(Css.px(3)),
                                                    /* [] */0
                                                  ]
                                                ])
                                          },
                                          onClick: (function (param) {
                                              
                                            }),
                                          children: React.createElement(KeyboardArrowRight.default, { })
                                        })),
                                leaveDelay: 300,
                                title: React.createElement(SimilarityCallstackComparison$BsConsole.make, {
                                      base: {
                                        fingerprint: triageGroup.fingerprint,
                                        dates: baseTimeRange,
                                        count: triageGroup.count,
                                        classifiers: baseClassifiers
                                      },
                                      candidate: row,
                                      projectName: projectName
                                    })
                              })
                        })
                  ];
          }), undefined, undefined, undefined, undefined, undefined, 166, undefined, undefined, undefined, undefined),
    BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Status"), "status", (function (row) {
            return /* `String */[
                    -976970511,
                    row.state
                  ];
          }), undefined, undefined, undefined, undefined, undefined, 166, undefined, undefined, undefined, undefined),
    BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Fingerprint"), "fingerprint", (function (row) {
            var maybeFrom = Belt_List.get(row.dates, 0);
            var maybeTo = Belt_List.get(row.dates, 1);
            if (maybeFrom === undefined) {
              return /* `String */[
                      -976970511,
                      row.fingerprint
                    ];
            }
            if (maybeTo === undefined) {
              return /* `String */[
                      -976970511,
                      row.fingerprint
                    ];
            }
            var el = React.createElement(Tooltip.default, {
                  placement: "bottom",
                  children: React.createElement(Button.default, {
                        variant: "text",
                        className: Css.style(/* :: */[
                              Css.maxWidth(Css.pct(100)),
                              /* :: */[
                                Css.overflow(/* hidden */-862584982),
                                /* :: */[
                                  Css.fontSize(/* inherit_ */-601204732),
                                  /* :: */[
                                    Css.fontWeight(/* inherit_ */-601204732),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]),
                        classes: {
                          label: Css.style(/* :: */[
                                Css.justifyContent(/* flexStart */662439529),
                                /* :: */[
                                  Css.paddingRight(Css.px(6)),
                                  /* [] */0
                                ]
                              ])
                        },
                        onClick: (function (param) {
                            return Curry._1(handleChangeUrl, Route$BsConsole.ProjectQueryBuilder.withDefaults(projectName, Caml_option.some(Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                            "fingerprint",
                                                            /* Equal */Block.__(0, [/* `String */[
                                                                  -976970511,
                                                                  row.fingerprint
                                                                ]])
                                                          ]), Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Custom */Block.__(1, [/* tuple */[
                                                            new Date(maybeFrom * 1000),
                                                            new Date(maybeTo * 1000)
                                                          ]]), Crdb$BsConsole.Aperture.$$default))), undefined, undefined, undefined, undefined, undefined, undefined, undefined));
                          }),
                        children: I18N$BsConsole.show(undefined, row.fingerprint)
                      }),
                  title: I18N$BsConsole.get(undefined, "Filter to fingerprint and time frame")
                });
            return /* `Element */[
                    -744106340,
                    el
                  ];
          }), undefined, undefined, undefined, undefined, undefined, 166, undefined, undefined, undefined, undefined),
    BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Date range"), "dates", (function (row) {
            return /* `String */[
                    -976970511,
                    SimilarityCandidate$BsConsole.datesToDateString(row.dates)
                  ];
          }), undefined, undefined, undefined, undefined, undefined, 166, undefined, undefined, undefined, undefined),
    BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Errors"), "errors", (function (row) {
            return /* `Int */[
                    3654863,
                    row.count
                  ];
          }), undefined, undefined, undefined, undefined, undefined, 166, undefined, undefined, undefined, undefined),
    BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Classifiers"), "classifiers", (function (row) {
            return /* `CrdbSelect */[
                    615627497,
                    /* tuple */[
                      "classifiers",
                      /* Classifiers */Block.__(4, [row.classifiers])
                    ]
                  ];
          }), undefined, undefined, undefined, undefined, undefined, 166, undefined, undefined, undefined, undefined)
  ];
  var columns$1 = supportsTickets ? $$Array.concat(/* :: */[
          columns,
          /* :: */[
            [BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Tickets"), "tickets", (function (row) {
                      var tickets = row.tickets;
                      var el = tickets !== undefined ? React.createElement(Row2$BsConsole.make, {
                              alignItems: /* center */98248149,
                              justifyContent: /* spaceBetween */516682146,
                              className: TriageTickets$BsConsole.Styles.ticketsRow,
                              children: React.createElement(FormatPopover$BsConsole.make, {
                                    content: React.createElement(FormatRenderer$BsConsole.make, {
                                          value: /* IssueTickets */Block.__(13, [tickets]),
                                          attribute: "fingerprint;issues;tickets"
                                        }),
                                    className: TriageTickets$BsConsole.Styles.ticketsContainer,
                                    children: React.createElement(FormatRenderer$BsConsole.make, {
                                          value: /* IssueTickets */Block.__(13, [tickets]),
                                          attribute: "fingerprint;issues;tickets"
                                        })
                                  })
                            }) : null;
                      return /* `Element */[
                              -744106340,
                              el
                            ];
                    }), true, undefined, undefined, undefined, undefined, 166, undefined, undefined, undefined, undefined)],
            /* [] */0
          ]
        ]) : columns;
  var rows = Belt_List.toArray(candidatesWithRank);
  var sortedRows = sortingState !== undefined ? BtEnhancedTable$BsConsole.defaultRowSort(rows, columns$1, sortingState) : rows;
  var onMerge = function (group) {
    Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
            projectName,
            Route$BsConsole.getInboxParams(projectName, Caml_option.some(Crdb$BsConsole.addFilter(aperture, /* :: */[
                          /* tuple */[
                            "fingerprint",
                            Crdb$BsConsole.FilterOperation.fromTuple(/* tuple */[
                                  "equal",
                                  group
                                ])
                          ],
                          /* [] */0
                        ])), undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ]));
    return Curry._1(setSelectedGroups, []);
  };
  var tmp = {
    columns: columns$1,
    rows: sortedRows,
    getRowId: (function (row) {
        return row.fingerprint;
      }),
    onSortChange: (function (sortingState) {
        return Curry._1(setSortingState, (function (param) {
                      return sortingState;
                    }));
      }),
    onSelectedChange: Curry.__1(setSelectedGroups),
    selected: selectedGroups,
    resizeableColumns: true,
    zebraStripe: false,
    emptyTableText: I18N$BsConsole.get(undefined, "No similar candidates found for this fingerprint.")
  };
  if (sortingState !== undefined) {
    tmp.sortingState = Caml_option.valFromOption(sortingState);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Subtitle2 */7,
                        className: Css.style(/* :: */[
                              Css.marginBottom(Css.px(8)),
                              /* [] */0
                            ]),
                        children: I18N$BsConsole.show(undefined, "Base fingerprint")
                      })
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* baseline */287825029,
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body2 */9,
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(8)),
                            /* [] */0
                          ]),
                      color: Colors$BsConsole.grey4,
                      children: I18N$BsConsole.show(undefined, "Errors: ")
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body2 */9,
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(25)),
                            /* [] */0
                          ]),
                      color: Colors$BsConsole.grey0,
                      children: I18N$BsConsole.showSkip(String(triageGroup.count))
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body2 */9,
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(8)),
                            /* [] */0
                          ]),
                      color: Colors$BsConsole.grey4,
                      children: I18N$BsConsole.show(undefined, "Classifiers: ")
                    }), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(25)),
                            /* [] */0
                          ])
                    }, React.createElement(Classifiers$BsConsole.make, {
                          classifiers: Belt_List.toArray(Belt_List.sort(baseClassifiers, (function (a, b) {
                                      if (b.length > a.length) {
                                        return 1;
                                      } else {
                                        return -1;
                                      }
                                    })))
                        })), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body2 */9,
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(8)),
                            /* [] */0
                          ]),
                      color: Colors$BsConsole.grey4,
                      children: I18N$BsConsole.show(undefined, "Date range: ")
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body2 */9,
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(25)),
                            /* [] */0
                          ]),
                      color: Colors$BsConsole.grey0,
                      children: I18N$BsConsole.showSkip(SimilarityCandidate$BsConsole.datesToDateString(baseTimeRange))
                    })), React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.marginTop(Css.px(10)),
                        /* :: */[
                          Css.marginBottom(Css.px(10)),
                          /* [] */0
                        ]
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Subtitle2 */7,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(8)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Candidate fingerprints")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(8)),
                        /* [] */0
                      ]),
                  color: Colors$BsConsole.grey4,
                  children: I18N$BsConsole.show(undefined, "Hover over the distance value in a row to view fingerprint details. Use the checkboxes to select all candidates you would like to merge or open in Explore mode.")
                }), React.createElement("div", undefined, React.createElement(Row2$BsConsole.make, {
                      justifyContent: /* flexEnd */924268066,
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.px(15)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(InboxMergeButton$BsConsole.make, {
                          fingerprints: Belt_Array.concat(selectedGroups, [candidateDataMaybe.fingerprint]),
                          onMerge: onMerge,
                          config: config,
                          projectName: projectName,
                          token: token,
                          defaultSourceFingerprint: candidateDataMaybe.fingerprint
                        }), React.createElement(Button.default, {
                          variant: "outline",
                          color: "primary",
                          disabled: selectedGroups.length === 0,
                          onClick: (function (param) {
                              return Util$BsConsole.openUrl(exploreUrl(Belt_Array.concat(selectedGroups, [triageGroup.fingerprint]), projectName));
                            }),
                          children: I18N$BsConsole.show(undefined, "View in explore")
                        }))), React.createElement(Paper.default, {
                  square: true,
                  children: React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.unsafe("backgroundColor", "inherit"),
                              /* :: */[
                                Css.selector(".BtTableBody > .BtTableRow:hover", /* :: */[
                                      Css.backgroundColor(Css.hex(Colors$BsConsole.npLightYellow2)),
                                      /* [] */0
                                    ]),
                                /* [] */0
                              ]
                            ])
                      }, React.createElement(BtEnhancedTable$BsConsole.make, tmp))
                }));
}

function make(candidateDataMaybe, similarityEndpointMaybe, setSelectedGroups, selectedGroups, triageGroup, token, projectName, handleChangeUrl, aperture, supportsTickets, config, children) {
  return ReasonReactCompat.wrapReactForReasonReact(SimilarityDetail, {
              candidateDataMaybe: candidateDataMaybe,
              similarityEndpointMaybe: similarityEndpointMaybe,
              supportsTickets: supportsTickets,
              setSelectedGroups: setSelectedGroups,
              selectedGroups: selectedGroups,
              triageGroup: triageGroup,
              token: token,
              projectName: projectName,
              handleChangeUrl: handleChangeUrl,
              aperture: aperture,
              config: config
            }, children);
}

var Re = {
  make: make
};

var make$1 = SimilarityDetail;

exports.encodedPipe = encodedPipe;
exports.exploreUrl = exploreUrl;
exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
