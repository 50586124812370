// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var ExporterSetApi$BsConsole = require("../metrics-exporter/api/ExporterSetApi.js");
var Popover = require("@material-ui/core/Popover");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var Publish = require("@material-ui/icons/Publish");
var ExporterStoreApi$BsConsole = require("../metrics-exporter/api/ExporterStoreApi.js");
var ExporterExportApi$BsConsole = require("../metrics-exporter/api/ExporterExportApi.js");
var ExporterMetricApi$BsConsole = require("../metrics-exporter/api/ExporterMetricApi.js");
var IconButton = require("@material-ui/core/IconButton");
var AlertsAlertConfigApi$BsConsole = require("../alerts/api/AlertsAlertConfigApi.js");
var AlertsCreateAlertModal$BsConsole = require("../alerts/AlertsCreateAlertModal.js");
var ExporterCreateMetricsModal$BsConsole = require("../metrics-exporter/ExporterCreateMetricsModal.js");

function ExploreExportAs(Props) {
  var config = Props.config;
  var token = Props.token;
  var exporterEndpoint = Props.exporterEndpoint;
  var alertsEndpoint = Props.alertsEndpoint;
  var universeName = Props.universeName;
  var projectName = Props.projectName;
  var factor = Props.factor;
  var fold = Props.fold;
  var aperture = Props.aperture;
  var sort = Props.sort;
  var havings = Props.havings;
  var highlightExporter = Props.highlightExporter;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = React.useState((function () {
          return false;
        }));
  var setMenu = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setModalType = match$1[1];
  var modalType = match$1[0];
  var match$2 = React.useState((function () {
          return /* [] */0;
        }));
  var setSets = match$2[1];
  var match$3 = React.useState((function () {
          return /* [] */0;
        }));
  var setStores = match$3[1];
  var match$4 = React.useState((function () {
          return /* [] */0;
        }));
  var setAlertConfigs = match$4[1];
  var match$5 = React.useState((function () {
          
        }));
  var setAnchor = match$5[1];
  var anchor = match$5[0];
  var fetchSets = function (param) {
    if (exporterEndpoint === undefined) {
      return ;
    }
    var arg = ExporterSetApi$BsConsole.get(Caml_option.valFromOption(exporterEndpoint), universeName, projectName, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var result = resp[0];
                  return Curry._1(setSets, (function (param) {
                                return result;
                              }));
                }));
  };
  var fetchStores = function (param) {
    if (exporterEndpoint === undefined) {
      return ;
    }
    var arg = ExporterStoreApi$BsConsole.get(Caml_option.valFromOption(exporterEndpoint), universeName, projectName, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var result = resp[0];
                  return Curry._1(setStores, (function (param) {
                                return result;
                              }));
                }));
  };
  var fetchAlertConfigs = function (param) {
    if (alertsEndpoint === undefined) {
      return ;
    }
    var endpoint = Caml_option.valFromOption(alertsEndpoint);
    var arg = AlertsAlertConfigApi$BsConsole.get(endpoint, universeName, projectName, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var existingAlertPromises = Belt_Array.map(Belt_List.toArray(resp[0].values), (function (t) {
                          return new Promise((function (resolve, param) {
                                        var arg = AlertsAlertConfigApi$BsConsole.getById(endpoint, universeName, projectName, t.id, undefined);
                                        return Curry._2((function (param) {
                                                        return (function (param$1, param$2) {
                                                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                          });
                                                      })(token), undefined, (function (resp) {
                                                      if (resp.tag) {
                                                        return resolve(undefined);
                                                      } else {
                                                        return resolve(resp[0]);
                                                      }
                                                    }));
                                      }));
                        }));
                  var __x = Promise.all(existingAlertPromises);
                  var __x$1 = __x.then((function (results) {
                          var res = Belt_List.keepMap(Belt_List.fromArray(results), (function (r) {
                                  return r;
                                }));
                          Curry._1(setAlertConfigs, (function (param) {
                                  return res;
                                }));
                          return Promise.resolve(undefined);
                        }));
                  __x$1.catch((function (_exn) {
                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error fetching alerts."));
                          return Promise.resolve(undefined);
                        }));
                  
                }));
  };
  React.useEffect((function () {
          if (highlightExporter !== undefined && highlightExporter && anchor !== undefined) {
            Caml_option.valFromOption(anchor).querySelector("button").focus();
          }
          
        }), /* tuple */[
        highlightExporter,
        anchor
      ]);
  React.useEffect((function () {
          fetchSets(undefined);
          fetchStores(undefined);
          
        }), /* tuple */[
        token,
        exporterEndpoint,
        universeName,
        projectName
      ]);
  React.useEffect((function () {
          fetchAlertConfigs(undefined);
          
        }), /* tuple */[
        token,
        alertsEndpoint,
        universeName,
        projectName
      ]);
  var handleSaveMetric = function (name, setId, selectedStores) {
    if (exporterEndpoint === undefined) {
      return ;
    }
    var endpoint = Caml_option.valFromOption(exporterEndpoint);
    var arg = ExporterMetricApi$BsConsole.create(endpoint, universeName, projectName, name, fold, factor, aperture, setId, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var promises = Belt_Array.map(selectedStores, (function (storeToAdd) {
                          return new Promise((function (resolve, param) {
                                        var arg = ExporterExportApi$BsConsole.create(endpoint, universeName, projectName, storeToAdd.id, setId, undefined);
                                        return Curry._2((function (param) {
                                                        return (function (param$1, param$2) {
                                                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                          });
                                                      })(token), undefined, (function (resp) {
                                                      if (resp.tag) {
                                                        SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                                                      }
                                                      return resolve(resp);
                                                    }));
                                      }));
                        }));
                  var __x = Promise.all(promises);
                  var __x$1 = __x.then((function (_result) {
                          return Promise.resolve(undefined);
                        }));
                  __x$1.catch((function (_exn) {
                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Error adding exports."));
                          return Promise.resolve(undefined);
                        }));
                  return SnackPurveyor$BsConsole.enqueue({
                              label: I18N$BsConsole.get(undefined, "View in project settings"),
                              onClick: (function (param) {
                                  SnackPurveyor$BsConsole.close(undefined);
                                  return Curry._1(handleChangeUrl, /* ProjectSettingsMetricsExporter */Block.__(24, [projectName]));
                                })
                            }, undefined, -1, undefined, I18N$BsConsole.show(undefined, "Success creating metric! "));
                }));
  };
  return React.createElement(React.Fragment, undefined, exporterEndpoint !== undefined ? React.createElement(ExporterCreateMetricsModal$BsConsole.make, {
                    open: modalType !== undefined ? modalType === 1039596624 : false,
                    onClose: (function (param) {
                        return Curry._1(setModalType, (function (param) {
                                      
                                    }));
                      }),
                    handleSave: handleSaveMetric,
                    sets: Belt_List.toArray(match$2[0]),
                    endpoint: Caml_option.valFromOption(exporterEndpoint),
                    universeName: universeName,
                    projectName: projectName,
                    token: token,
                    stores: match$3[0]
                  }) : null, alertsEndpoint !== undefined ? React.createElement(AlertsCreateAlertModal$BsConsole.make, {
                    config: config,
                    open: modalType !== undefined ? modalType === 884721468 : false,
                    onClose: (function (param) {
                        return Curry._1(setModalType, (function (param) {
                                      
                                    }));
                      }),
                    init: /* Query */Block.__(1, [
                        factor,
                        fold,
                        aperture,
                        havings,
                        sort
                      ]),
                    handleSave: (function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
                        return AlertsAlertConfigApi$BsConsole.saveAlert(/* ExploreView */0, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7);
                      }),
                    endpoint: Caml_option.valFromOption(alertsEndpoint),
                    universeName: universeName,
                    projectName: projectName,
                    token: token,
                    handleChangeUrl: handleChangeUrl,
                    existingAlerts: match$4[0],
                    showPreviewInExplore: false
                  }) : null, React.createElement("div", {
                  ref: (function (el) {
                      return Curry._1(setAnchor, (function (param) {
                                    if (el == null) {
                                      return ;
                                    } else {
                                      return Caml_option.some(el);
                                    }
                                  }));
                    })
                }, React.createElement(Tooltip.default, {
                      children: React.createElement(IconButton.default, {
                            color: "primary",
                            onClick: (function (param) {
                                return Curry._1(setMenu, (function (param) {
                                              return true;
                                            }));
                              }),
                            children: React.createElement(Publish.default, {
                                  color: "inherit",
                                  fontSize: "inherit"
                                })
                          }),
                      title: I18N$BsConsole.get(undefined, "Export as...")
                    })), React.createElement(Popover.default, {
                  anchorEl: anchor,
                  open: match[0],
                  onClose: (function (param) {
                      return Curry._1(setMenu, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: React.createElement(MenuList.default, {
                        children: null
                      }, exporterEndpoint !== undefined ? React.createElement(MenuItem.default, {
                              onClick: (function (param) {
                                  Curry._1(setMenu, (function (param) {
                                          return false;
                                        }));
                                  return Curry._1(setModalType, (function (param) {
                                                return /* Metric */1039596624;
                                              }));
                                }),
                              children: I18N$BsConsole.show(undefined, "Metric")
                            }) : null, alertsEndpoint !== undefined ? React.createElement(MenuItem.default, {
                              onClick: (function (param) {
                                  Curry._1(setMenu, (function (param) {
                                          return false;
                                        }));
                                  return Curry._1(setModalType, (function (param) {
                                                return /* Alert */884721468;
                                              }));
                                }),
                              children: React.createElement("span", {
                                    className: Css.style(/* :: */[
                                          Css.marginRight(Css.px(5)),
                                          /* [] */0
                                        ])
                                  }, I18N$BsConsole.show(undefined, "Alert"))
                            }) : null)
                }));
}

function make(config, token, exporterEndpoint, alertsEndpoint, universeName, projectName, factor, fold, aperture, sort, havings, highlightExporter, handleChangeUrl, children) {
  return ReasonReactCompat.wrapReactForReasonReact(ExploreExportAs, {
              config: config,
              token: token,
              exporterEndpoint: exporterEndpoint,
              alertsEndpoint: alertsEndpoint,
              universeName: universeName,
              projectName: projectName,
              factor: factor,
              fold: fold,
              aperture: aperture,
              sort: sort,
              havings: havings,
              highlightExporter: highlightExporter,
              handleChangeUrl: handleChangeUrl
            }, children);
}

var Re = {
  make: make
};

var make$1 = ExploreExportAs;

exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
