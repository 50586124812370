// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Colors$BsConsole = require("../Colors.js");

var monospaceFont = Css.fontFamily("Inconsolata");

var Color = {
  textTitle: Colors$BsConsole.grey0,
  textMain: Colors$BsConsole.grey2,
  textGrey: Colors$BsConsole.grey4,
  textLight: Colors$BsConsole.grey5,
  dividerPrimary: Colors$BsConsole.grey5,
  dividerSecondary: Colors$BsConsole.grey6,
  dividerTertiary: Colors$BsConsole.grey65,
  bgHeader: Colors$BsConsole.grey2,
  bgFilter: Colors$BsConsole.grey3,
  bgApplication: Colors$BsConsole.grey7,
  bgDropdown: Colors$BsConsole.grey8,
  bgClassifiers: Colors$BsConsole.greenExtraLight,
  bgAnnotations: Colors$BsConsole.orangeExtraLight,
  bgCommentMentions: Colors$BsConsole.tealExtraLight,
  primary: Colors$BsConsole.green,
  primaryDark: Colors$BsConsole.greenDark,
  primaryVeryDark: Colors$BsConsole.greenExtraDark,
  primaryLight: Colors$BsConsole.greenLight,
  primaryVeryLight: Colors$BsConsole.greenExtraLight,
  secondary1: Colors$BsConsole.teal,
  secondary1Dark: Colors$BsConsole.tealDark,
  secondary1VeryDark: Colors$BsConsole.tealExtraDark,
  secondary1Light: Colors$BsConsole.tealLight,
  secondary1VeryLight: Colors$BsConsole.tealExtraLight,
  secondary2: Colors$BsConsole.orange,
  secondary2Dark: Colors$BsConsole.orangeDark,
  secondary2VeryDark: Colors$BsConsole.orangeExtraDark,
  secondary2Light: Colors$BsConsole.orangeLight,
  secondary2VeryLight: Colors$BsConsole.orangeExtraLight,
  accent1: Colors$BsConsole.blue,
  accent1Dark: Colors$BsConsole.blueDark,
  accent1Light: Colors$BsConsole.blueLight,
  accent1VeryLight: Colors$BsConsole.blueBackground,
  accent2: Colors$BsConsole.red,
  accent2Dark: Colors$BsConsole.redDark,
  accent2Light: Colors$BsConsole.redLight,
  accent2VeryLight: Colors$BsConsole.redBackground,
  tag: Colors$BsConsole.tags
};

exports.monospaceFont = monospaceFont;
exports.Color = Color;
/* monospaceFont Not a pure module */
