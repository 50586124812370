// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Util$BsConsole = require("../util.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("../Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../Uncaught.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var WorkflowUtil$BsConsole = require("../WorkflowUtil.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var WfIntegration$BsConsole = require("./WfIntegration.js");
var WfIntegrationFull$BsConsole = require("./WfIntegrationFull.js");

var ParseError = Caml_exceptions.create("WfTasks-BsConsole.ParseError");

var ServiceError = Caml_exceptions.create("WfTasks-BsConsole.ServiceError");

var DecodeError = Caml_exceptions.create("WfTasks-BsConsole.DecodeError");

function fromJson(json) {
  return {
          error: Json_decode.field("error", Json_decode.string, json),
          success: Json_decode.field("success", Json_decode.bool, json)
        };
}

var $$Error = {
  fromJson: fromJson
};

function describeWorkflow(config) {
  var maybePath = WorkflowUtil$BsConsole.getUrl(/* Describe */1, config);
  if (maybePath === undefined) {
    return ;
  }
  var cacheKey = Task2$BsConsole.Path.toString(maybePath);
  return Caml_option.some(Task2$BsConsole.make(maybePath, undefined, /* Get */0, undefined, (function (param) {
                    try {
                      var integrations = Json_decode.field("integrations", (function (param) {
                              return Json_decode.dict(WfIntegration$BsConsole.fromJson, param);
                            }), param.json);
                      var integrations$1 = Belt_Array.map(Js_dict.entries(integrations), (function (param) {
                              return param[1];
                            }));
                      return /* Ok */Block.__(0, [integrations$1]);
                    }
                    catch (raw_e){
                      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                      return /* Error */Block.__(1, [[
                                  DecodeError,
                                  e
                                ]]);
                    }
                  }), cacheKey, undefined));
}

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = error[0] === ServiceError ? error[1] : "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

function fromJson$1(json) {
  var integrations = Json_decode.field("integrations", (function (param) {
          return Json_decode.dict(WfIntegration$BsConsole.fromJson, param);
        }), json);
  return Belt_Array.map(Js_dict.entries(integrations), (function (param) {
                return param[1];
              }));
}

function queryWFIntegrationsTask(config) {
  var maybePath = WorkflowUtil$BsConsole.getUrl(/* Describe */1, config);
  if (maybePath === undefined) {
    return ;
  }
  var cacheKey = Task2$BsConsole.Path.toString(maybePath);
  return Caml_option.some(Task2$BsConsole.make(maybePath, undefined, /* Get */0, undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status >= 400) {
                        var response = fromJson(json);
                        return /* Error */Block.__(1, [[
                                    ServiceError,
                                    response.error
                                  ]]);
                      }
                      var response$1 = fromJson$1(json);
                      return /* Ok */Block.__(0, [response$1]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "WfTasks/QueryIntegration.queryIntegrationTask", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ParseError,
                                  error
                                ]]);
                    }
                  }), cacheKey, undefined));
}

var QueryWFIntegrations = {
  responseCb: responseCb,
  fromJson: fromJson$1,
  queryWFIntegrationsTask: queryWFIntegrationsTask
};

function describeIntegration(integrationName, config) {
  var maybePath = WorkflowUtil$BsConsole.getUrl(/* DescribeIntegration */Block.__(1, [integrationName]), config);
  if (maybePath === undefined) {
    return ;
  }
  var cacheKey = Task2$BsConsole.Path.toString(maybePath);
  return Caml_option.some(Task2$BsConsole.make(maybePath, undefined, /* Get */0, undefined, (function (param) {
                    var json = param.json;
                    try {
                      if (param.status < 400) {
                        return /* Ok */Block.__(0, [WfIntegrationFull$BsConsole.fromJson(json)]);
                      }
                      var response = fromJson(json);
                      return /* Error */Block.__(1, [[
                                  ServiceError,
                                  response.error
                                ]]);
                    }
                    catch (raw_err){
                      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                      var error = Uncaught$BsConsole.castToJsExn(err);
                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "WfTasks/describeIntegration", Backtrace$BsConsole.Attributes.make(undefined));
                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                            5049499,
                            error
                          ]);
                      return /* Error */Block.__(1, [[
                                  ParseError,
                                  error
                                ]]);
                    }
                  }), cacheKey, undefined));
}

function fetchWatchersFullIntegration(watcher, config) {
  var maybePath = WorkflowUtil$BsConsole.getUrl(/* DescribeWatcher */Block.__(0, [watcher.watcherName]), config);
  if (maybePath !== undefined) {
    return Caml_option.some(Task2$BsConsole.make(maybePath, undefined, /* Get */0, undefined, (function (param) {
                      return /* Ok */Block.__(0, [WfIntegrationFull$BsConsole.fromJson(param.json)]);
                    }), undefined, undefined));
  }
  
}

function fromJson$2(obj) {
  var state = Json_decode.field("state", Json_decode.string, obj);
  switch (state) {
    case "disabled" :
        return /* Disabled */1;
    case "enabled" :
        return /* Enabled */0;
    case "error" :
        return /* Error */[Json_decode.field("userFriendlyMessage", Json_decode.string, obj)];
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "WfTasks.re",
              165,
              13
            ]
          ];
  }
}

var Status = {
  fromJson: fromJson$2
};

function fromJson$3(json) {
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("lastWebhookReceived", Json_decode.string, param);
        }), json);
  return {
          webhookStatus: Json_decode.optional((function (param) {
                  return Json_decode.field("webhookStatus", fromJson$2, param);
                }), json),
          lastWebhookReceived: Belt_Option.map(__x, (function (prim) {
                  return new Date(prim);
                }))
        };
}

var WebhookStatus = {
  Status: Status,
  fromJson: fromJson$3
};

function getWatcherStatuses(hooksUrl, param) {
  var cacheKey = hooksUrl + "/getWatcherStatuses";
  return Task2$BsConsole.make(/* Custom */Block.__(1, [hooksUrl + "/getWatcherStatuses"]), /* CORS */3, /* Post */Block.__(0, [Json_encode.object_(/* [] */0)]), undefined, (function (param) {
                var statuses = Json_decode.dict(fromJson$3, Json_decode.field("statuses", Util$BsConsole.identity, param.json));
                return /* Ok */Block.__(0, [statuses]);
              }), cacheKey, undefined);
}

function workflowAction(decodeResp, config, params) {
  var maybePath = WorkflowUtil$BsConsole.getUrl(/* Workflow */Block.__(2, [
          params.projectName,
          params.watcherName
        ]), config);
  if (maybePath !== undefined) {
    return Caml_option.some(Task2$BsConsole.make(maybePath, undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                            /* tuple */[
                              "type",
                              params.action
                            ],
                            /* :: */[
                              /* tuple */[
                                "action",
                                params.payload
                              ],
                              /* [] */0
                            ]
                          ])]), undefined, decodeResp, undefined, undefined));
  }
  
}

var TestError = Caml_exceptions.create("WfTasks-BsConsole.TestError");

function testWorkflow(projectName, watcherName, config, param) {
  return workflowAction((function (resp) {
                var error = {
                  message: Json_decode.optional((function (param) {
                          return Json_decode.field("error", Json_decode.string, param);
                        }), resp.json)
                };
                var match = resp.status >= 400;
                var match$1 = error.message;
                if (match) {
                  if (match$1 !== undefined) {
                    return /* Error */Block.__(1, [[
                                ServiceError,
                                match$1
                              ]]);
                  } else {
                    return /* Error */Block.__(1, [[
                                ServiceError,
                                "Unknown Error"
                              ]]);
                  }
                } else {
                  return /* Ok */Block.__(0, [undefined]);
                }
              }), config, {
              action: "test",
              projectName: projectName,
              watcherName: watcherName,
              payload: null
            });
}

function assignTicket(config, linkTicketParams) {
  return workflowAction((function (param) {
                return /* Ok */Block.__(0, [param.json]);
              }), config, {
              action: "ticket:assign",
              projectName: linkTicketParams.projectName,
              watcherName: linkTicketParams.watcherName,
              payload: linkTicketParams.payload
            });
}

function createTicket(config, createTicketParams) {
  var fingerprint = createTicketParams.fingerprint;
  var payload = fingerprint !== undefined ? Object.assign(createTicketParams.payload, {
          fingerprint: fingerprint
        }) : createTicketParams.payload;
  var reporting_user = createTicketParams.reporting_user;
  var payload$1 = reporting_user !== undefined ? Object.assign(payload, {
          reporting_user: reporting_user
        }) : payload;
  return workflowAction((function (param) {
                var payload = Json_decode.field("payload", Util$BsConsole.identity, param.json);
                var ticketInfo_short = Json_decode.field("short", Json_decode.string, payload);
                var ticketInfo_url = Json_decode.field("url", Json_decode.string, payload);
                var ticketInfo = {
                  short: ticketInfo_short,
                  url: ticketInfo_url
                };
                return /* Ok */Block.__(0, [ticketInfo]);
              }), config, {
              action: "ticket:create",
              projectName: createTicketParams.projectName,
              watcherName: createTicketParams.watcherName,
              payload: payload$1
            });
}

function put(endpoint, universe, crashProject, crashProjectToken, defectProject, defectProjectToken, queryUrl, ignoreFiles, ignoreFunctions, limitFiles, limitFunctions, classifierMatch, matchColumn) {
  var url = endpoint + ("/backfill-correlation/" + (universe + "/"));
  var form = Json_encode.object_(/* :: */[
        /* tuple */[
          "crashProjectToken",
          crashProjectToken
        ],
        /* :: */[
          /* tuple */[
            "crashProject",
            crashProject
          ],
          /* :: */[
            /* tuple */[
              "defectProject",
              defectProject
            ],
            /* :: */[
              /* tuple */[
                "defectProjectToken",
                defectProjectToken
              ],
              /* :: */[
                /* tuple */[
                  "queryUrl",
                  queryUrl
                ],
                /* :: */[
                  /* tuple */[
                    "ignoreFiles",
                    ignoreFiles
                  ],
                  /* :: */[
                    /* tuple */[
                      "ignoreFunctions",
                      ignoreFunctions
                    ],
                    /* :: */[
                      /* tuple */[
                        "limitFiles",
                        limitFiles
                      ],
                      /* :: */[
                        /* tuple */[
                          "limitFunctions",
                          limitFunctions
                        ],
                        /* :: */[
                          /* tuple */[
                            "classifierMatch",
                            classifierMatch
                          ],
                          /* :: */[
                            /* tuple */[
                              "matchColumn",
                              matchColumn
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, [url]), undefined, /* Put */Block.__(1, [form]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var resp = {
                  message: Json_decode.field("message", Json_decode.string, param.json)
                };
                return /* Ok */Block.__(0, [resp]);
              }), undefined, undefined);
}

var ScaBackfill = {
  put: put
};

exports.ParseError = ParseError;
exports.ServiceError = ServiceError;
exports.DecodeError = DecodeError;
exports.$$Error = $$Error;
exports.describeWorkflow = describeWorkflow;
exports.QueryWFIntegrations = QueryWFIntegrations;
exports.describeIntegration = describeIntegration;
exports.fetchWatchersFullIntegration = fetchWatchersFullIntegration;
exports.WebhookStatus = WebhookStatus;
exports.getWatcherStatuses = getWatcherStatuses;
exports.workflowAction = workflowAction;
exports.TestError = TestError;
exports.testWorkflow = testWorkflow;
exports.assignTicket = assignTicket;
exports.createTicket = createTicket;
exports.ScaBackfill = ScaBackfill;
/* Util-BsConsole Not a pure module */
