// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_Id = require("bs-platform/lib/js/belt_Id.js");
var Belt_Set = require("bs-platform/lib/js/belt_Set.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Col2$BsConsole = require("../components/Col2.js");
var Crdb$BsConsole = require("../crdb.js");
var Gate$BsConsole = require("../Gate.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Route$BsConsole = require("../route.js");
var Colors$BsConsole = require("../Colors.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");
var Remote$BsConsole = require("../remote.js");
var BugChip$BsConsole = require("./BugChip.js");
var BugLink$BsConsole = require("./BugLink.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BugTasks$BsConsole = require("./data/BugTasks.js");
var BugUtils$BsConsole = require("./data/BugUtils.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var Clipboard$BsConsole = require("../bindings/Clipboard.js");
var WfActions$BsConsole = require("../workflow/manual-actions/WfActions.js");
var ReopenGate$BsConsole = require("../reopen/ReopenGate.js");
var BugIssueTags$BsConsole = require("./bindings/BugIssueTags.js");
var TokenContext$BsConsole = require("../context/TokenContext.js");
var Paper = require("@material-ui/core/Paper");
var BugPagination$BsConsole = require("./BugPagination.js");
var RouterContext$BsConsole = require("../context/RouterContext.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var BugIssueTicket$BsConsole = require("./bindings/BugIssueTicket.js");
var Divider = require("@material-ui/core/Divider");
var Popover = require("@material-ui/core/Popover");
var Tooltip = require("@material-ui/core/Tooltip");
var Issues_assignee$BsConsole = require("../bs-crdb-response/src/Issues_assignee.js");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var BugIssueAssignee$BsConsole = require("./bindings/BugIssueAssignee.js");
var FetchProjectUsers$BsConsole = require("../FetchProjectUsers.js");
var TriageIssueRemote$BsConsole = require("../TriageIssueRemote.js");
var TriageTopBarMultiState$BsConsole = require("../inbox/TriageTopBarMultiState.js");
var FetchAvailableWorkflows$BsConsole = require("../FetchAvailableWorkflows.js");
var CircularProgress = require("@material-ui/core/CircularProgress");

var moduleContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.width(/* auto */-1065951377),
          /* :: */[
            Css.maxWidth(Css.px(180)),
            /* [] */0
          ]
        ]
      ]
    ]);

var divider = Css.style(/* :: */[
      Css.height(Css.px(50)),
      /* :: */[
        Css.width(Css.px(1)),
        /* [] */0
      ]
    ]);

var Style = {
  moduleContainer: moduleContainer,
  divider: divider
};

function TriageTopBar$EmptyState(Props) {
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                      /* :: */[
                        Css.height(Css.px(80)),
                        /* :: */[
                          Css.padding2(Css.px(16), Css.px(40)),
                          /* :: */[
                            Css.justifyContent(/* spaceBetween */516682146),
                            /* :: */[
                              Css.alignItems(/* center */98248149),
                              /* :: */[
                                Css.width(Css.pct(100)),
                                /* :: */[
                                  Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                                  /* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement(CircularProgress.default, {
                  size: 14,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.green)),
                        /* [] */0
                      ])
                }));
}

var EmptyState = {
  make: TriageTopBar$EmptyState
};

function TriageTopBar$InboxContent(Props) {
  var loading = Props.loading;
  var children = Props.children;
  return React.createElement(Row2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(Css.rem(1.25)),
                        /* :: */[
                          Css.flexShrink(0),
                          /* [] */0
                        ]
                      ])
                }, loading ? React.createElement(CircularProgress.default, {
                        size: 14
                      }) : null), React.createElement("div", {
                  className: Css.style(/* :: */[
                        loading ? Css.opacity(0.5) : Css.marginBottom(Css.zero),
                        /* :: */[
                          Css.width(/* `calc */[
                                -1044768619,
                                /* tuple */[
                                  /* sub */5745024,
                                  Css.pct(100),
                                  Css.px(14)
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, children));
}

var InboxContent = {
  make: TriageTopBar$InboxContent
};

function TriageTopBar$MultiState(Props) {
  var config = Props.config;
  var fetchIssues = Props.fetchIssues;
  var token = Props.token;
  var project = Props.project;
  var triageGroup = Props.triageGroup;
  var aperture = Props.aperture;
  var handleSetState = function (s, i) {
    return TriageIssueRemote$BsConsole.setIssue((function (param) {
                  return true;
                }), (function (fingerprint, result) {
                  Curry._1(fetchIssues, [fingerprint]);
                  TriageIssueRemote$BsConsole.$$Response.toast(fingerprint, Caml_option.some(undefined), result);
                  
                }), token, project.name, triageGroup, ReopenGate$BsConsole.allow ? /* :: */[
                  /* SetState */Block.__(1, [s]),
                  /* :: */[
                    /* SetInvariant */Block.__(2, [i]),
                    /* [] */0
                  ]
                ] : /* :: */[
                  /* SetState */Block.__(1, [s]),
                  /* [] */0
                ], undefined);
  };
  var tmp = {
    config: config,
    handleSetState: handleSetState,
    aperture: aperture,
    invariant: triageGroup.issues.invariants,
    projectName: project.name,
    labelStyle: Css.style(/* :: */[
          Css.paddingLeft(/* zero */-789508312),
          /* :: */[
            Css.minHeight(/* zero */-789508312),
            /* [] */0
          ]
        ])
  };
  if (triageGroup.issues.state !== undefined) {
    tmp.state = Caml_option.valFromOption(triageGroup.issues.state);
  }
  return React.createElement("div", {
              className: moduleContainer
            }, React.createElement(TriageTopBarMultiState$BsConsole.make, tmp));
}

var MultiState = {
  make: TriageTopBar$MultiState
};

function keepUniques(arr) {
  var cmp = Caml_obj.caml_compare;
  var Comparator = Belt_Id.MakeComparable({
        cmp: cmp
      });
  return Belt_Set.toArray(Belt_Set.fromArray(arr, Comparator));
}

function TriageTopBar$IssueAssignee(Props) {
  var fetchIssues = Props.fetchIssues;
  var token = Props.token;
  var project = Props.project;
  var projectUsers = Props.projectUsers;
  var triageGroup = Props.triageGroup;
  var anchorOrigin = Props.anchorOrigin;
  var config = Props.config;
  var match = React.useState((function () {
          return false;
        }));
  var setAssigneesLoading = match[1];
  var handleSetAssignee = React.useCallback((function (triageGroup) {
          return (function (projectUser, tickets) {
              return TriageIssueRemote$BsConsole.setIssue((function (param) {
                            return true;
                          }), (function (param, param$1) {
                            
                          }), token, project.name, triageGroup, /* :: */[
                          /* SetTicketsAssignment */Block.__(0, [
                              true,
                              Belt_List.keepMap(tickets, (function (ticket) {
                                      switch (ticket.tag | 0) {
                                        case /* Linked */1 :
                                            var match = ticket[0];
                                            return /* tuple */[
                                                    match.short,
                                                    match.watcher
                                                  ];
                                        case /* Unlinked */0 :
                                        case /* InteralAssigneeLink */2 :
                                            return ;
                                        
                                      }
                                    })),
                              projectUser
                            ]),
                          /* [] */0
                        ], undefined);
            });
        }), ([]));
  var tmp = {
    handleSetAssignee: (function (projectUser, tickets, param) {
        Curry._1(setAssigneesLoading, (function (param) {
                return true;
              }));
        var __x = Curry._3(handleSetAssignee, triageGroup, projectUser, tickets);
        __x.then((function (param) {
                Curry._1(fetchIssues, [triageGroup.fingerprint]);
                Curry._1(setAssigneesLoading, (function (param) {
                        return false;
                      }));
                return Promise.resolve(undefined);
              }));
        
      }),
    target: (function (refHandler, onFocus, onClick) {
        return React.createElement("div", {
                    ref: refHandler,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]),
                    onFocus: onFocus
                  }, React.createElement(TriageTopBarMultiState$BsConsole.Header.make, {
                        title: I18N$BsConsole.get(undefined, "Assignees"),
                        icon: React.createElement(MuiIcons$BsConsole.User.make, {
                              color: "inherit",
                              fontSize: "inherit"
                            }),
                        onClick: onClick,
                        ariaLabel: Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                  /* String_literal */Block.__(11, [
                                      "Set assignees. Current assignee: ",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* End_of_format */0
                                        ])
                                    ]),
                                  "Set assignees. Current assignee: %s"
                                ]), Belt_Array.reduce(keepUniques(Belt_List.toArray(Belt_List.map(Belt_Option.getWithDefault(triageGroup.issues.assignees, /* [] */0), Issues_assignee$BsConsole.getDisplayName))), "", (function (acc, assignee) {
                                    return acc + (assignee + " ");
                                  })))
                      }));
      }),
    projectUsers: projectUsers,
    tickets: Belt_Option.getWithDefault(triageGroup.issues.tickets, /* [] */0),
    assignees: Belt_Option.getWithDefault(triageGroup.issues.assignees, /* [] */0),
    config: config
  };
  if (anchorOrigin !== undefined) {
    tmp.anchorOrigin = Caml_option.valFromOption(anchorOrigin);
  }
  var assignees = Belt_SortArray.stableSortBy(Belt_Option.mapWithDefault(triageGroup.issues.assignees, [], Belt_List.toArray), (function (a1, a2) {
          return $$String.compare(Issues_assignee$BsConsole.getDisplayName(a1).toLowerCase(), Issues_assignee$BsConsole.getDisplayName(a2).toLowerCase());
        }));
  return React.createElement("div", {
              className: moduleContainer
            }, React.createElement(BugIssueAssignee$BsConsole.make, tmp), React.createElement(TriageTopBar$InboxContent, {
                  loading: match[0],
                  children: React.createElement(Col2$BsConsole.make, {
                        className: Css.style(/* :: */[
                              Css.fontSize(Css.rem(1)),
                              /* :: */[
                                Css.lineHeight(Css.rem(1.25)),
                                /* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.grey95)),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        children: assignees.length !== 0 ? Belt_Array.map(keepUniques(Belt_Array.map(assignees, Issues_assignee$BsConsole.getDisplayName)), (function (assignee) {
                                  return React.createElement(Tooltip.default, {
                                              children: React.createElement("span", {
                                                    key: assignee,
                                                    className: Css.style(/* :: */[
                                                          Css.textOverflow(/* ellipsis */166888785),
                                                          /* :: */[
                                                            Css.overflow(/* hidden */-862584982),
                                                            /* :: */[
                                                              Css.whiteSpace(/* nowrap */867913355),
                                                              /* :: */[
                                                                Css.maxWidth(Css.px(104)),
                                                                /* [] */0
                                                              ]
                                                            ]
                                                          ]
                                                        ])
                                                  }, I18N$BsConsole.showSkip(assignee)),
                                              title: assignee
                                            });
                                })) : I18N$BsConsole.showSkip("-")
                      })
                }));
}

var IssueAssignee = {
  keepUniques: keepUniques,
  make: TriageTopBar$IssueAssignee
};

function TriageTopBar$Tooltip(Props) {
  var body = Props.body;
  var children = Props.children;
  return React.createElement(Tooltip.default, {
              classes: {
                popper: Css.style(/* :: */[
                      Css.opacity(1),
                      /* [] */0
                    ]),
                tooltip: Css.style(/* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.padding(Css.zero),
                        /* :: */[
                          Css.background(Css.none),
                          /* :: */[
                            Css.maxWidth(Css.vw(40)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ])
              },
              interactive: true,
              placement: "bottom",
              enterDelay: 100,
              children: React.createElement("div", undefined, children),
              leaveDelay: 100,
              title: React.createElement(Paper.default, {
                    classes: {
                      root: Css.style(/* :: */[
                            Css.fontSize(Css.rem(1)),
                            /* :: */[
                              Css.display(/* flex */-1010954439),
                              /* :: */[
                                Css.flexDirection(/* column */-963948842),
                                /* :: */[
                                  Css.alignItems(/* flexStart */662439529),
                                  /* :: */[
                                    Css.padding2(Css.rem(1), Css.rem(0.875)),
                                    /* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.grey0)),
                                      /* :: */[
                                        Css.gridGap(Css.rem(0.5)),
                                        /* :: */[
                                          Css.maxHeight(Css.vh(85)),
                                          /* :: */[
                                            Css.overflowY(/* auto */-1065951377),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ])
                    },
                    children: body
                  })
            });
}

var Tooltip$1 = {
  make: TriageTopBar$Tooltip
};

function TriageTopBar$ViewMoreTooltip(Props) {
  var body = Props.body;
  var label = Props.label;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "visuallyhidden"
                }, body), React.createElement(TriageTopBar$Tooltip, {
                  body: body,
                  children: React.createElement("span", {
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.Debugger.grey2)),
                              /* :: */[
                                Css.cursor(/* pointer */-786317123),
                                /* :: */[
                                  Css.visited(/* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.grey95)),
                                        /* [] */0
                                      ]),
                                  /* :: */[
                                    Css.fontWeight(/* medium */-20425611),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      }, I18N$BsConsole.showSkip(" +" + label))
                }));
}

var ViewMoreTooltip = {
  make: TriageTopBar$ViewMoreTooltip
};

function TriageTopBar$IssueTickets(Props) {
  var config = Props.config;
  var anchorOrigin = Props.anchorOrigin;
  var activeUser = Props.activeUser;
  var fetchIssues = Props.fetchIssues;
  var handleChangeUrl = Props.handleChangeUrl;
  var project = Props.project;
  var token = Props.token;
  var triageGroup = Props.triageGroup;
  var fingerprint = Props.fingerprint;
  var workflows = Props.workflows;
  var match = React.useState((function () {
          return false;
        }));
  var setTicketsLoading = match[1];
  var linkedTickets = Issues$BsConsole.Tickets.getLinked(triageGroup.issues.tickets);
  var unlinkedTickets = Issues$BsConsole.Tickets.getUnlinked(triageGroup.issues.tickets);
  var __x = Belt_Option.getWithDefault(linkedTickets, /* [] */0);
  var linkedAndUnlinkedTickets = Belt_List.concat(__x, Belt_Option.getWithDefault(unlinkedTickets, /* [] */0));
  var handleAddTicket = React.useCallback((function (triageGroup) {
          return (function (ticket) {
              return TriageIssueRemote$BsConsole.setIssue((function (param) {
                            return true;
                          }), (function (param, param$1) {
                            
                          }), token, project.name, triageGroup, /* :: */[
                          /* AddUnlinkedTicket */Block.__(5, [ticket]),
                          /* [] */0
                        ], undefined);
            });
        }), ([]));
  var handleRemoveTicket = React.useCallback((function (triageGroup) {
          return (function (ticket) {
              return TriageIssueRemote$BsConsole.setIssue((function (param) {
                            return true;
                          }), (function (param, param$1) {
                            
                          }), token, project.name, triageGroup, /* :: */[
                          /* RemoveTickets */Block.__(6, [/* :: */[
                                ticket,
                                /* [] */0
                              ]]),
                          /* [] */0
                        ], undefined);
            });
        }), ([]));
  var allTickets = Belt_List.sort(Belt_List.keepMap(Belt_List.map(linkedAndUnlinkedTickets, (function (ticket) {
                  switch (ticket.tag | 0) {
                    case /* Unlinked */0 :
                    case /* Linked */1 :
                        break;
                    case /* InteralAssigneeLink */2 :
                        return ;
                    
                  }
                  var match = ticket[0];
                  return /* tuple */[
                          match.short,
                          match.url
                        ];
                })), Util$BsConsole.identity), (function (t1, t2) {
          return $$String.compare(t1[0].toLowerCase(), t2[0].toLowerCase());
        }));
  var tmp = {
    handleWfAssociateTicket: (function (workflow, _cb) {
        return WfActions$BsConsole.createTicketWithTask(activeUser.uid, triageGroup.fingerprint, token, workflow, project.name, (function (ticketParams) {
                      if (ticketParams === undefined) {
                        return ;
                      }
                      var url = ticketParams.url;
                      var $$short = ticketParams.short;
                      Curry._1(fetchIssues, [triageGroup.fingerprint]);
                      SnackPurveyor$BsConsole.enqueue({
                            label: $$short,
                            onClick: (function (param) {
                                return Util$BsConsole.openUrl(url);
                              })
                          }, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Success creating external ticket."));
                      
                    }), config, undefined);
      }),
    workflows: workflows,
    projectName: project.name,
    target: (function (refHandler, onFocus, onClick) {
        return React.createElement("div", {
                    ref: refHandler,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]),
                    onFocus: onFocus,
                    onClick: onClick
                  }, React.createElement(TriageTopBarMultiState$BsConsole.Header.make, {
                        title: I18N$BsConsole.get(undefined, "Tickets"),
                        icon: React.createElement(MuiIcons$BsConsole.JiraLogo.make, {
                              color: "inherit",
                              fontSize: "inherit"
                            }),
                        onClick: onClick,
                        ariaLabel: Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                  /* String_literal */Block.__(11, [
                                      "Set tickets. Current tickets: ",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* End_of_format */0
                                        ])
                                    ]),
                                  "Set tickets. Current tickets: %s"
                                ]), Belt_List.reduce(allTickets, "", (function (acc, ticket) {
                                    return acc + (ticket[0] + " ");
                                  })))
                      }));
      }),
    handleAddTicket: (function (ticket, cb) {
        Curry._1(cb, undefined);
        Curry._1(setTicketsLoading, (function (param) {
                return true;
              }));
        var __x = Curry._2(handleAddTicket, triageGroup, ticket);
        __x.then((function (param) {
                Curry._1(fetchIssues, [triageGroup.fingerprint]);
                Curry._1(setTicketsLoading, (function (param) {
                        return false;
                      }));
                return Promise.resolve(undefined);
              }));
        
      }),
    tickets: linkedAndUnlinkedTickets,
    handleRemoveTicket: (function (ticket, cb) {
        Curry._1(cb, undefined);
        Curry._1(setTicketsLoading, (function (param) {
                return true;
              }));
        var __x = Curry._2(handleRemoveTicket, triageGroup, ticket);
        __x.then((function (param) {
                Curry._1(fetchIssues, [triageGroup.fingerprint]);
                Curry._1(setTicketsLoading, (function (param) {
                        return false;
                      }));
                return Promise.resolve(undefined);
              }));
        
      }),
    handleChangeUrl: handleChangeUrl,
    config: config,
    token: token,
    fetchIssues: fetchIssues,
    selectedFingerprints: [fingerprint]
  };
  if (anchorOrigin !== undefined) {
    tmp.anchorOrigin = Caml_option.valFromOption(anchorOrigin);
  }
  var tmp$1;
  if (allTickets) {
    var remaining = allTickets[1];
    var first = allTickets[0];
    if (remaining) {
      var remainingEls = Belt_Array.map(Belt_List.toArray(remaining), (function (param) {
              return React.createElement("a", {
                          className: Css.style(/* :: */[
                                Css.fontSize(Css.rem(0.875)),
                                /* [] */0
                              ]),
                          href: param[1],
                          rel: "noopener noreferrer",
                          target: "_blank",
                          onClick: (function (e) {
                              e.stopPropagation();
                              
                            })
                        }, I18N$BsConsole.showSkip(param[0]));
            }));
      tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.whiteSpace(/* nowrap */867913355),
                      /* :: */[
                        Css.overflow(/* hidden */-862584982),
                        /* :: */[
                          Css.textOverflow(/* ellipsis */166888785),
                          /* [] */0
                        ]
                      ]
                    ])
              }, React.createElement("a", {
                    className: Css.style(/* :: */[
                          Css.color(Css.hex(Colors$BsConsole.grey95)),
                          /* :: */[
                            Css.visited(/* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.grey95)),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]),
                    href: first[1],
                    rel: "noopener noreferrer",
                    target: "_blank",
                    onClick: (function (e) {
                        e.stopPropagation();
                        
                      })
                  }, I18N$BsConsole.showSkip(first[0]))), React.createElement(TriageTopBar$ViewMoreTooltip, {
                body: remainingEls,
                label: String(Belt_List.length(remaining))
              }));
    } else {
      tmp$1 = React.createElement("div", {
            className: Css.style(/* :: */[
                  Css.whiteSpace(/* nowrap */867913355),
                  /* :: */[
                    Css.overflow(/* hidden */-862584982),
                    /* :: */[
                      Css.textOverflow(/* ellipsis */166888785),
                      /* [] */0
                    ]
                  ]
                ])
          }, React.createElement("a", {
                className: Css.style(/* :: */[
                      Css.color(Css.hex(Colors$BsConsole.grey95)),
                      /* :: */[
                        Css.visited(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey95)),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]),
                href: first[1],
                rel: "noopener noreferrer",
                target: "_blank",
                onClick: (function (e) {
                    e.stopPropagation();
                    
                  })
              }, I18N$BsConsole.showSkip(first[0])));
    }
  } else {
    tmp$1 = I18N$BsConsole.showSkip("-");
  }
  return React.createElement("div", {
              className: moduleContainer
            }, React.createElement(BugIssueTicket$BsConsole.make, tmp), React.createElement(TriageTopBar$InboxContent, {
                  loading: match[0],
                  children: React.createElement(Col2$BsConsole.make, {
                        className: Css.style(/* :: */[
                              Css.lineHeight(Css.rem(1.25)),
                              /* :: */[
                                Css.flexDirection(/* row */5693978),
                                /* :: */[
                                  Css.overflow(/* hidden */-862584982),
                                  /* :: */[
                                    Css.gridGap(Css.rem(0.25)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]),
                        children: tmp$1
                      })
                }));
}

var IssueTickets = {
  make: TriageTopBar$IssueTickets
};

function TriageTopBar$IssueTags(Props) {
  var anchorOrigin = Props.anchorOrigin;
  var fetchIssues = Props.fetchIssues;
  var token = Props.token;
  var triageGroup = Props.triageGroup;
  var project = Props.project;
  var match = React.useState((function () {
          return false;
        }));
  var setTagsLoading = match[1];
  var handleAddRemoveTags = React.useCallback((function (triageGroup) {
          return (function (addedTags, removedTags) {
              var action;
              var exit = 0;
              if (addedTags) {
                if (removedTags) {
                  exit = 1;
                } else {
                  action = /* AddTags */Block.__(8, [addedTags]);
                }
              } else if (removedTags) {
                exit = 1;
              } else {
                action = undefined;
              }
              if (exit === 1) {
                action = addedTags ? /* AddRemoveTags */Block.__(9, [
                      addedTags,
                      removedTags
                    ]) : /* RemoveTags */Block.__(7, [removedTags]);
              }
              if (action !== undefined) {
                return TriageIssueRemote$BsConsole.setIssue((function (param) {
                                return true;
                              }), (function (param, param$1) {
                                
                              }), token, project.name, triageGroup, /* :: */[
                              action,
                              /* [] */0
                            ], undefined).then((function (param) {
                              return Promise.resolve(undefined);
                            }));
              } else {
                return Promise.resolve(undefined);
              }
            });
        }), ([]));
  var allTags = Belt_SortArray.stableSortBy(Belt_Option.mapWithDefault(triageGroup.issues.tags, [], Belt_List.toArray), (function (t1, t2) {
          return Caml_primitive.caml_int_compare(t1.length, t2.length);
        }));
  var tagsJsx = allTags.length !== 0 ? Belt_Array.map(allTags, (function (tag) {
            var labelInt = Belt_Array.reduce(Belt_Array.map(Belt_Array.map(tag.split(""), (function (__x) {
                            return __x.charCodeAt(0);
                          })), (function (prim) {
                        return prim | 0;
                      })), 0, (function (prim, prim$1) {
                    return prim + prim$1 | 0;
                  }));
            var bgColor = Belt_Array.getExn(Colors$BsConsole.tags, Caml_int32.mod_(labelInt, Colors$BsConsole.tags.length));
            return React.createElement(BugChip$BsConsole.make, {
                        backgroundColor: bgColor,
                        label: tag,
                        className: Css.style(/* :: */[
                              Css.maxWidth(Css.pct(100)),
                              /* :: */[
                                Css.display(/* inlineBlock */-147785676),
                                /* :: */[
                                  Css.marginRight(Css.px(3)),
                                  /* :: */[
                                    Css.minHeight(Css.rem(1.125)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      });
          })) : I18N$BsConsole.showSkip("-");
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    moduleContainer,
                    /* :: */[
                      Css.style(/* :: */[
                            Css.maxWidth(Css.vw(20)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(BugIssueTags$BsConsole.make, {
                  anchorOrigin: anchorOrigin,
                  allTags: allTags,
                  target: (function (refHandler, onFocus, onClick) {
                      return React.createElement("div", {
                                  ref: refHandler,
                                  className: Css.style(/* :: */[
                                        Css.width(Css.pct(100)),
                                        /* [] */0
                                      ]),
                                  onFocus: onFocus,
                                  onClick: onClick
                                }, React.createElement(TriageTopBarMultiState$BsConsole.Header.make, {
                                      title: I18N$BsConsole.get(undefined, "Tags"),
                                      icon: React.createElement(MuiIcons$BsConsole.Tags.make, {
                                            color: "inherit",
                                            fontSize: "inherit"
                                          }),
                                      onClick: onClick,
                                      ariaLabel: Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                                /* String_literal */Block.__(11, [
                                                    "Set tags. Current tags: ",
                                                    /* String */Block.__(2, [
                                                        /* No_padding */0,
                                                        /* End_of_format */0
                                                      ])
                                                  ]),
                                                "Set tags. Current tags: %s"
                                              ]), Belt_Array.reduce(allTags, "", (function (acc, tag) {
                                                  return acc + (tag + " ");
                                                })))
                                    }));
                    }),
                  tagGroups: /* :: */[
                    Belt_Option.getWithDefault(triageGroup.issues.tags, /* [] */0),
                    /* [] */0
                  ],
                  handleAddRemoveTags: (function (addedTags, removedTags) {
                      Curry._1(setTagsLoading, (function (param) {
                              return true;
                            }));
                      var __x = Curry._3(handleAddRemoveTags, triageGroup, addedTags, removedTags);
                      __x.then((function (param) {
                              Curry._1(fetchIssues, [triageGroup.fingerprint]);
                              Curry._1(setTagsLoading, (function (param) {
                                      return false;
                                    }));
                              return Promise.resolve(undefined);
                            }));
                      
                    })
                }), React.createElement(TriageTopBar$InboxContent, {
                  loading: match[0],
                  children: React.createElement(TriageTopBar$Tooltip, {
                        body: tagsJsx,
                        children: React.createElement(Col2$BsConsole.make, {
                              className: Css.style(/* :: */[
                                    Css.display(/* block */888960333),
                                    /* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.black)),
                                      /* :: */[
                                        Css.overflowX(/* hidden */-862584982),
                                        /* :: */[
                                          Css.textOverflow(/* ellipsis */166888785),
                                          /* :: */[
                                            Css.whiteSpace(/* nowrap */867913355),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]),
                              children: tagsJsx
                            })
                      })
                }));
}

var IssueTags = {
  make: TriageTopBar$IssueTags
};

function TriageTopBar$FingerprintRow(Props) {
  var onAddFilter = Props.onAddFilter;
  var handleChangeUrl = Props.handleChangeUrl;
  var project = Props.project;
  var aperture = Props.aperture;
  var fingerprint = Props.fingerprint;
  var errorId = Props.errorId;
  var outerStyleOpt = Props.outerStyle;
  var labelStyleOpt = Props.labelStyle;
  var valueStyleOpt = Props.valueStyle;
  var outerStyle = outerStyleOpt !== undefined ? outerStyleOpt : "";
  var labelStyle = labelStyleOpt !== undefined ? labelStyleOpt : "";
  var valueStyle = valueStyleOpt !== undefined ? valueStyleOpt : "";
  var match = React.useState((function () {
          
        }));
  var setFingerprintAnchor = match[1];
  var fingerprintAnchor = match[0];
  var issueDetailsRoute_000 = project.name;
  var issueDetailsRoute_001 = Route$BsConsole.getInboxParams(project.name, Caml_option.some(Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                        "fingerprint",
                        /* Equal */Block.__(0, [/* `String */[
                              -976970511,
                              fingerprint
                            ]])
                      ]), Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture), Crdb$BsConsole.Aperture.$$default))), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var issueDetailsRoute = /* ProjectTriage */Block.__(11, [
      issueDetailsRoute_000,
      issueDetailsRoute_001,
      undefined
    ]);
  return React.createElement(React.Fragment, undefined, React.createElement(Button.default, {
                  disableRipple: true,
                  variant: "text",
                  className: Css.style(/* :: */[
                        Css.padding(/* zero */-789508312),
                        /* :: */[
                          Css.selector(":focus-visible", /* :: */[
                                Css.outline(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.green)),
                                /* [] */0
                              ]),
                          /* :: */[
                            Css.selector(":hover", /* :: */[
                                  Css.unsafe("background-color", "unset"),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]),
                  onClick: (function (e) {
                      Belt_Option.map(Caml_option.nullable_to_opt(e.target), (function (target) {
                              return Curry._1(setFingerprintAnchor, (function (param) {
                                            return Caml_option.some(target);
                                          }));
                            }));
                      
                    }),
                  title: Curry._2(I18N$BsConsole.getf(undefined, /* Format */[
                            /* String_literal */Block.__(11, [
                                "Fingerprint menu for Fingerprint: ",
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* String_literal */Block.__(11, [
                                        " Error: ",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* End_of_format */0
                                          ])
                                      ])
                                  ])
                              ]),
                            "Fingerprint menu for Fingerprint: %s Error: %s"
                          ]), BugUtils$BsConsole.formatFingerprint(fingerprint), errorId),
                  children: React.createElement(Row2$BsConsole.make, {
                        className: Css.merge(/* :: */[
                              Css.style(/* :: */[
                                    Css.padding(Css.px(12)),
                                    /* :: */[
                                      Css.fontSize(Css.em(0.8125)),
                                      /* :: */[
                                        Css.hover(/* :: */[
                                              Css.unsafe("filter", "brightness(0%) saturate(0%) invert(34%) sepia(77%) saturate(536%) hue-rotate(118deg) brightness(93%) contrast(101%)"),
                                              /* :: */[
                                                Css.cursor(/* pointer */-786317123),
                                                /* [] */0
                                              ]
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ]),
                              /* :: */[
                                outerStyle,
                                /* [] */0
                              ]
                            ]),
                        children: null
                      }, React.createElement("span", {
                            className: Css.merge(/* :: */[
                                  Css.style(/* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.grey4)),
                                        /* [] */0
                                      ]),
                                  /* :: */[
                                    labelStyle,
                                    /* [] */0
                                  ]
                                ])
                          }, I18N$BsConsole.show(undefined, "Fingerprint:")), React.createElement("span", {
                            className: Css.merge(/* :: */[
                                  Css.style(/* :: */[
                                        Css.paddingLeft(Css.px(5)),
                                        /* :: */[
                                          Css.color(Css.hex(Colors$BsConsole.grey0)),
                                          /* :: */[
                                            Css.cursor(/* pointer */-786317123),
                                            /* [] */0
                                          ]
                                        ]
                                      ]),
                                  /* :: */[
                                    valueStyle,
                                    /* [] */0
                                  ]
                                ])
                          }, I18N$BsConsole.showSkip(BugUtils$BsConsole.formatFingerprint(fingerprint))))
                }), React.createElement(Popover.default, {
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                  anchorEl: fingerprintAnchor,
                  open: Belt_Option.isSome(fingerprintAnchor),
                  onClose: (function (param) {
                      return Curry._1(setFingerprintAnchor, (function (param) {
                                    
                                  }));
                    }),
                  children: React.createElement(MenuList.default, {
                        children: null
                      }, React.createElement(MenuItem.default, {
                            onClick: (function (param) {
                                Clipboard$BsConsole.copy(fingerprint, undefined, undefined);
                                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                              /* String_literal */Block.__(11, [
                                                  "Fingerprint copied to clipboard ",
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* End_of_format */0
                                                    ])
                                                ]),
                                              "Fingerprint copied to clipboard %s"
                                            ]), fingerprint));
                                return Curry._1(setFingerprintAnchor, (function (param) {
                                              
                                            }));
                              }),
                            children: I18N$BsConsole.show(undefined, "Copy")
                          }), React.createElement(MenuItem.default, {
                            onClick: (function (param) {
                                Curry._1(onAddFilter, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                          "fingerprint",
                                          /* Equal */Block.__(0, [/* `String */[
                                                -976970511,
                                                fingerprint
                                              ]])
                                        ]));
                                return Curry._1(setFingerprintAnchor, (function (param) {
                                              
                                            }));
                              }),
                            children: I18N$BsConsole.show(undefined, "Filter to")
                          }), React.createElement(MenuItem.default, {
                            onClick: (function (param) {
                                Curry._1(onAddFilter, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                          "fingerprint",
                                          /* NotEqual */Block.__(1, [/* `String */[
                                                -976970511,
                                                fingerprint
                                              ]])
                                        ]));
                                return Curry._1(setFingerprintAnchor, (function (param) {
                                              
                                            }));
                              }),
                            children: I18N$BsConsole.show(undefined, "Filter against")
                          }), React.createElement(BugLink$BsConsole.make, {
                            route: issueDetailsRoute,
                            handleChangeUrl: handleChangeUrl,
                            children: React.createElement(MenuItem.default, {
                                  onClick: (function (param) {
                                      return Curry._1(setFingerprintAnchor, (function (param) {
                                                    
                                                  }));
                                    }),
                                  children: I18N$BsConsole.show(undefined, "Go to issue details")
                                })
                          }))
                }));
}

var FingerprintRow = {
  make: TriageTopBar$FingerprintRow
};

function TriageTopBar$FingerprintNav(Props) {
  var errorIndex = Props.errorIndex;
  var setErrorIndex = Props.setErrorIndex;
  var onAddFilter = Props.onAddFilter;
  var handleChangeUrl = Props.handleChangeUrl;
  var project = Props.project;
  var aperture = Props.aperture;
  var fingerprint = Props.fingerprint;
  var errorId = Props.errorId;
  var errors = BugTasks$BsConsole.useErrors(aperture, errorIndex / 20 | 0);
  var errorsCount;
  errorsCount = typeof errors === "number" || errors.tag ? undefined : Belt_Option.getWithDefault(errors[0][0][1].instances, 0);
  var filters = Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
  var hasErrorFilter = Belt_Array.some(filters, (function (filter) {
          var match = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
          var match$1 = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
          if (match === "_tx" && !match$1.tag) {
            return true;
          } else {
            return false;
          }
        }));
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.justifyContent(/* spaceBetween */516682146),
                      /* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]
                    ]
                  ])
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.flexDirection(/* column */-963948842),
                          /* :: */[
                            Css.gridGap(Css.px(4)),
                            /* :: */[
                              Css.lineHeight(Css.rem(1.5)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.alignItems(/* center */98248149),
                              /* :: */[
                                Css.gridGap(Css.px(4)),
                                /* :: */[
                                  Css.marginRight(Css.rem(1)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ])
                    }, React.createElement(TriageTopBar$FingerprintRow, {
                          onAddFilter: onAddFilter,
                          handleChangeUrl: handleChangeUrl,
                          project: project,
                          aperture: aperture,
                          fingerprint: fingerprint,
                          errorId: errorId,
                          outerStyle: Css.style(/* :: */[
                                Css.padding(/* zero */-789508312),
                                /* [] */0
                              ]),
                          labelStyle: Css.style(/* :: */[
                                Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
                                /* :: */[
                                  Css.fontSize(/* `rem */[
                                        5691738,
                                        1
                                      ]),
                                  /* [] */0
                                ]
                              ]),
                          valueStyle: Css.style(/* :: */[
                                Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
                                /* :: */[
                                  Css.fontSize(/* `rem */[
                                        5691738,
                                        1
                                      ]),
                                  /* [] */0
                                ]
                              ])
                        }), React.createElement("span", {
                          className: Css.style(/* [] */0)
                        }, I18N$BsConsole.show(undefined, "/")), React.createElement("span", {
                          className: Css.style(/* :: */[
                                Css.fontWeight(/* `num */[
                                      5496390,
                                      500
                                    ]),
                                /* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.grey95)),
                                  /* [] */0
                                ]
                              ])
                        }, I18N$BsConsole.show(undefined, "Error: " + errorId))), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.minHeight(Css.rem(1.5)),
                            /* [] */0
                          ])
                    }, React.createElement(BugPagination$BsConsole.make, {
                          hasErrorFilter: hasErrorFilter,
                          errorsCount: errorsCount,
                          errorIndex: errorIndex,
                          setErrorIndex: setErrorIndex,
                          aperture: aperture
                        }))));
}

var FingerprintNav = {
  make: TriageTopBar$FingerprintNav
};

function TriageTopBar$FingerprintOnly(Props) {
  var fingerprint = Props.fingerprint;
  var maybeFingerprintDescription = Props.maybeFingerprintDescription;
  var outerStyle = Css.style(/* :: */[
        Css.padding(/* zero */-789508312),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                500
              ]),
          /* :: */[
            Css.color(Css.hex(Colors$BsConsole.green)),
            /* [] */0
          ]
        ]
      ]);
  var labelStyle = Css.style(/* :: */[
        Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
        /* :: */[
          Css.fontSize(/* `rem */[
                5691738,
                1
              ]),
          /* [] */0
        ]
      ]);
  var valueStyle = Css.style(/* :: */[
        Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
        /* :: */[
          Css.fontSize(/* `rem */[
                5691738,
                1
              ]),
          /* [] */0
        ]
      ]);
  var match = Gate$BsConsole.isBeta(undefined);
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.justifyContent(/* spaceBetween */516682146),
                      /* :: */[
                        Css.flexGrow(1),
                        /* :: */[
                          Css.overflow(/* hidden */-862584982),
                          /* :: */[
                            Css.boxSizing(/* borderBox */9307263),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.flexDirection(/* column */-963948842),
                            /* :: */[
                              Css.gridGap(Css.px(4)),
                              /* :: */[
                                Css.lineHeight(Css.rem(1)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ])
                }, React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.alignItems(/* flexStart */662439529),
                            /* :: */[
                              Css.gridGap(Css.px(4)),
                              /* :: */[
                                Css.width(Css.pct(100)),
                                /* :: */[
                                  Css.display(/* flex */-1010954439),
                                  /* :: */[
                                    Css.flexDirection(/* column */-963948842),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ])
                    }, React.createElement(Row2$BsConsole.make, {
                          className: Css.merge(/* :: */[
                                Css.style(/* :: */[
                                      Css.padding(Css.px(6)),
                                      /* :: */[
                                        Css.fontSize(Css.em(0.8125)),
                                        /* :: */[
                                          Css.hover(/* :: */[
                                                Css.unsafe("filter", "brightness(0%) saturate(0%) invert(34%) sepia(77%) saturate(536%) hue-rotate(118deg) brightness(93%) contrast(101%)"),
                                                /* :: */[
                                                  Css.cursor(/* pointer */-786317123),
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]),
                                /* :: */[
                                  outerStyle,
                                  /* [] */0
                                ]
                              ]),
                          children: React.createElement(Button.default, {
                                className: Css.style(/* :: */[
                                      Css.lineHeight(Css.em(1)),
                                      /* [] */0
                                    ]),
                                onClick: (function (param) {
                                    var value = BugUtils$BsConsole.formatFingerprint(fingerprint);
                                    return Clipboard$BsConsole.copy(BugUtils$BsConsole.formatFingerprint(fingerprint), (function (param) {
                                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                      /* String */Block.__(2, [
                                                                          /* No_padding */0,
                                                                          /* String_literal */Block.__(11, [
                                                                              " copied to clipboard.",
                                                                              /* End_of_format */0
                                                                            ])
                                                                        ]),
                                                                      "%s copied to clipboard."
                                                                    ]), value));
                                                }), undefined);
                                  }),
                                title: I18N$BsConsole.get(undefined, "Copy fingerprint to clipboard"),
                                children: null
                              }, React.createElement("span", {
                                    className: Css.merge(/* :: */[
                                          Css.style(/* :: */[
                                                Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                /* [] */0
                                              ]),
                                          /* :: */[
                                            labelStyle,
                                            /* [] */0
                                          ]
                                        ])
                                  }, I18N$BsConsole.show(undefined, "Fingerprint:")), React.createElement("span", {
                                    className: Css.merge(/* :: */[
                                          Css.style(/* :: */[
                                                Css.paddingLeft(Css.px(5)),
                                                /* :: */[
                                                  Css.color(Css.hex(Colors$BsConsole.grey0)),
                                                  /* :: */[
                                                    Css.cursor(/* pointer */-786317123),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]),
                                          /* :: */[
                                            valueStyle,
                                            /* [] */0
                                          ]
                                        ])
                                  }, I18N$BsConsole.showSkip(BugUtils$BsConsole.formatFingerprint(fingerprint))))
                        }), match && maybeFingerprintDescription !== undefined ? React.createElement(Row2$BsConsole.make, {
                            className: Css.merge(/* :: */[
                                  Css.style(/* :: */[
                                        Css.maxWidth(Css.pct(100)),
                                        /* :: */[
                                          Css.fontSize(Css.em(0.8125)),
                                          /* :: */[
                                            Css.marginLeft(Css.px(8)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]),
                                  /* :: */[
                                    outerStyle,
                                    /* [] */0
                                  ]
                                ]),
                            children: null
                          }, React.createElement("span", {
                                className: Css.merge(/* :: */[
                                      Css.style(/* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.grey4)),
                                            /* [] */0
                                          ]),
                                      /* :: */[
                                        labelStyle,
                                        /* [] */0
                                      ]
                                    ])
                              }, I18N$BsConsole.show(undefined, "Description:")), React.createElement(Tooltip.default, {
                                children: React.createElement("span", {
                                      className: Css.merge(/* :: */[
                                            Css.style(/* :: */[
                                                  Css.paddingLeft(Css.px(5)),
                                                  /* :: */[
                                                    Css.color(Css.hex(Colors$BsConsole.grey0)),
                                                    /* :: */[
                                                      Css.cursor(/* pointer */-786317123),
                                                      /* :: */[
                                                        Css.overflow(/* hidden */-862584982),
                                                        /* :: */[
                                                          Css.textOverflow(/* ellipsis */166888785),
                                                          /* :: */[
                                                            Css.whiteSpace(/* nowrap */867913355),
                                                            /* :: */[
                                                              Css.flexGrow(1),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]),
                                            /* :: */[
                                              valueStyle,
                                              /* [] */0
                                            ]
                                          ])
                                    }, I18N$BsConsole.show(undefined, maybeFingerprintDescription[0] + (" " + maybeFingerprintDescription[1]))),
                                title: I18N$BsConsole.get(undefined, maybeFingerprintDescription[0] + (" " + maybeFingerprintDescription[1]))
                              })) : null)));
}

var FingerprintOnly = {
  make: TriageTopBar$FingerprintOnly
};

function TriageTopBar(Props) {
  var config = Props.config;
  var onAddFilter = Props.onAddFilter;
  var triageGroup = Props.triageGroup;
  var fetchIssues = Props.fetchIssues;
  var aperture = Props.aperture;
  var fingerprint = Props.fingerprint;
  var project = Props.project;
  var errorData = Props.errorData;
  var match = React.useContext(RouterContext$BsConsole.ctx);
  var handleChangeUrl = match.handleChangeUrl;
  var token = Belt_Option.getWithDefault(React.useContext(TokenContext$BsConsole.ctx), "");
  var match$1 = FetchProjectUsers$BsConsole.use(token, project.name);
  var projectUsers = Belt_Option.getWithDefault(Remote$BsConsole.getSuccess(match$1[0]), []);
  var anchorOrigin = {
    vertical: /* Bottom */437082891,
    horizontal: /* Left */847852583
  };
  var activeUser = config.user;
  var workflows = FetchAvailableWorkflows$BsConsole.use(config, project, token);
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                      /* :: */[
                        Css.height(Css.px(80)),
                        /* :: */[
                          Css.padding2(Css.px(16), Css.px(16)),
                          /* :: */[
                            Css.justifyContent(/* spaceBetween */516682146),
                            /* :: */[
                              Css.alignItems(/* center */98248149),
                              /* :: */[
                                Css.width(Css.pct(100)),
                                /* :: */[
                                  Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                                  /* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, errorData !== undefined ? React.createElement(TriageTopBar$FingerprintNav, {
                    errorIndex: errorData[0],
                    setErrorIndex: errorData[1],
                    onAddFilter: onAddFilter,
                    handleChangeUrl: handleChangeUrl,
                    project: project,
                    aperture: aperture,
                    fingerprint: fingerprint,
                    errorId: errorData[2]
                  }) : React.createElement(TriageTopBar$FingerprintOnly, {
                    fingerprint: fingerprint,
                    maybeFingerprintDescription: triageGroup.description
                  }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.justifyContent(/* flexEnd */924268066),
                          /* :: */[
                            Css.gridGap(Css.rem(2)),
                            /* :: */[
                              Css.marginRight(Css.px(11)),
                              /* :: */[
                                Css.flexGrow(1),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ])
                }, React.createElement(TriageTopBar$MultiState, {
                      config: config,
                      fetchIssues: fetchIssues,
                      token: token,
                      project: project,
                      triageGroup: triageGroup,
                      aperture: aperture
                    }), React.createElement(Divider.default, {
                      className: divider,
                      orientation: "vertical"
                    }), React.createElement(TriageTopBar$IssueAssignee, {
                      fetchIssues: fetchIssues,
                      token: token,
                      project: project,
                      projectUsers: projectUsers,
                      triageGroup: triageGroup,
                      anchorOrigin: anchorOrigin,
                      config: config
                    }), React.createElement(Divider.default, {
                      className: divider,
                      orientation: "vertical"
                    }), React.createElement(TriageTopBar$IssueTickets, {
                      config: config,
                      anchorOrigin: anchorOrigin,
                      activeUser: activeUser,
                      fetchIssues: fetchIssues,
                      handleChangeUrl: handleChangeUrl,
                      project: project,
                      token: token,
                      triageGroup: triageGroup,
                      fingerprint: fingerprint,
                      workflows: workflows
                    }), React.createElement(Divider.default, {
                      className: divider,
                      orientation: "vertical"
                    }), React.createElement(TriageTopBar$IssueTags, {
                      anchorOrigin: anchorOrigin,
                      fetchIssues: fetchIssues,
                      token: token,
                      triageGroup: triageGroup,
                      project: project
                    })));
}

function make(config, onAddFilter, triageGroup, fetchIssues, aperture, fingerprint, project, errorData, _children) {
  var tmp = {
    config: config,
    onAddFilter: onAddFilter,
    triageGroup: triageGroup,
    fetchIssues: fetchIssues,
    aperture: aperture,
    fingerprint: fingerprint,
    project: project
  };
  if (errorData !== undefined) {
    tmp.errorData = Caml_option.valFromOption(errorData);
  }
  return ReasonReactCompat.wrapReactForReasonReact(TriageTopBar, tmp, _children);
}

var Re = {
  make: make
};

var make$1 = TriageTopBar;

exports.Style = Style;
exports.EmptyState = EmptyState;
exports.InboxContent = InboxContent;
exports.MultiState = MultiState;
exports.IssueAssignee = IssueAssignee;
exports.Tooltip = Tooltip$1;
exports.ViewMoreTooltip = ViewMoreTooltip;
exports.IssueTickets = IssueTickets;
exports.IssueTags = IssueTags;
exports.FingerprintRow = FingerprintRow;
exports.FingerprintNav = FingerprintNav;
exports.FingerprintOnly = FingerprintOnly;
exports.make = make$1;
exports.Re = Re;
/* moduleContainer Not a pure module */
