// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var Crdb$BsConsole = require("./crdb.js");
var ApiWf$BsConsole = require("./workflow/workflows-api/ApiWf.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Parse$BsConsole = require("./bs-crdb-response/src/Parse.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");

function makeQuery(aperture, frozenTx, page) {
  var filters = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
            "_tx",
            /* AtMost */Block.__(9, [/* `ObjectID */[
                  -589436806,
                  frozenTx
                ]])
          ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
  var frozenAperture = Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, aperture);
  var __x = Crdb$BsConsole.Fold.empty;
  var __x$1 = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
            "error.message",
            /* Distribution */Block.__(1, [10])
          ]), __x);
  var fold = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
            "callstack",
            /* Head */1
          ]), __x$1);
  var sort = Crdb$BsConsole.Sort.defaultAggregate;
  return Curry._2(Crdb$BsConsole.Query.setPagination, Curry._2(Crdb$BsConsole.Paginate.nth, page, Crdb$BsConsole.Paginate.initial), /* Aggregate */Block.__(0, [
                frozenAperture,
                /* Identity */0,
                fold,
                sort,
                undefined,
                undefined
              ]));
}

function queryDescription(token, frozenTx, page, projectName, aperture, cbSuccess, cbFailure) {
  var query = makeQuery(aperture, frozenTx, page);
  var queryJson = Curry._1(Crdb$BsConsole.Query.toCrdb_, query);
  var onSuccess = Curry.__1(cbSuccess);
  var onFailure = Curry.__1(cbFailure);
  var decodeResp = function (query, response) {
    try {
      var factor = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getFactor, query), /* Custom */["*"]);
      var columns = Parse$BsConsole.parseColumns(response);
      var func = QueryPaginationTask$BsConsole.AggregateUtils.getRows;
      var func$1 = Curry._1(func, factor);
      var rows = Curry._2(func$1, columns, response);
      var parseColumns = function (columns, group) {
        var getter = function (param, param$1) {
          return Group$BsConsole.getAggregation(columns, group, param, param$1);
        };
        var errorMessage = Group$BsConsole.unwrapDistribution(getter("error.message", /* Distribution */1));
        var callstack = Group$BsConsole.unwrapCallstack(getter("callstack", /* Head */3));
        return /* tuple */[
                errorMessage,
                callstack
              ];
      };
      var descriptionGroups = Belt_List.toArray(Belt_List.map(rows, (function (group) {
                  var match = parseColumns(columns, group);
                  var maybeCallstack = match[1];
                  var maybeErrorMessages = match[0];
                  var fingerprint = group.factor.value;
                  var decodeCallstack = function (json) {
                    return {
                            frame: Json_decode.field("frame", (function (param) {
                                    return Json_decode.array(Json_decode.string, param);
                                  }), json)
                          };
                  };
                  var maybeLocation;
                  if (maybeCallstack !== undefined) {
                    try {
                      var $$location = decodeCallstack(JSON.parse(maybeCallstack[1])).frame;
                      maybeLocation = Belt_Array.get($$location, 0);
                    }
                    catch (_err){
                      maybeLocation = undefined;
                    }
                  } else {
                    maybeLocation = undefined;
                  }
                  var maybeErrorMessage;
                  if (maybeErrorMessages !== undefined) {
                    var errorMessages = Belt_List.get(maybeErrorMessages.vals, 0);
                    if (errorMessages !== undefined) {
                      var format = errorMessages[0];
                      if (format !== undefined && !format.tag) {
                        var match$1 = format[0];
                        maybeErrorMessage = typeof match$1 === "number" || match$1[0] !== -976970511 ? undefined : match$1[1];
                      } else {
                        maybeErrorMessage = undefined;
                      }
                    } else {
                      maybeErrorMessage = undefined;
                    }
                  } else {
                    maybeErrorMessage = undefined;
                  }
                  var description = maybeErrorMessage !== undefined ? (
                      maybeLocation !== undefined ? /* tuple */[
                          maybeErrorMessage,
                          "in " + maybeLocation
                        ] : /* tuple */[
                          maybeErrorMessage,
                          ""
                        ]
                    ) : (
                      maybeLocation !== undefined ? /* tuple */[
                          "Error found in " + maybeLocation,
                          ""
                        ] : /* tuple */[
                          "-",
                          ""
                        ]
                    );
                  return /* tuple */[
                          fingerprint,
                          description
                        ];
                })));
      var descriptionMap = Belt_MapString.fromArray(descriptionGroups);
      return /* Ok */Block.__(0, [descriptionMap]);
    }
    catch (raw_err){
      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
      var error = Uncaught$BsConsole.castToJsExn(err);
      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "FingerprintDescription/queryDescription/decodeResp", Backtrace$BsConsole.Attributes.make(undefined));
      Backtrace$BsConsole.Client.send(__x, /* `exn */[
            5049499,
            error
          ]);
      return /* Error */Block.__(1, [[
                  ApiWf$BsConsole.ParseError,
                  error
                ]]);
    }
  };
  var task = Task2$BsConsole.make(/* Api */Block.__(0, ["/query?project=" + projectName]), undefined, /* Post */Block.__(0, [queryJson]), undefined, (function (param) {
          var json = param.json;
          try {
            if (param.status >= 400) {
              var response = ApiWf$BsConsole.$$Error.fromJson(json);
              var __x = Backtrace$BsConsole.Attributes.addString("src_module", "FingerprintDescription/queryDescription/task", Backtrace$BsConsole.Attributes.make(undefined));
              Backtrace$BsConsole.Client.send(__x, /* `string */[
                    288368849,
                    response.error
                  ]);
              return /* Error */Block.__(1, [[
                          ApiWf$BsConsole.ServiceError,
                          response.error
                        ]]);
            }
            var response$1 = decodeResp(query, json);
            if (response$1.tag) {
              return /* Error */Block.__(1, [response$1[0]]);
            } else {
              return /* Ok */Block.__(0, [response$1[0]]);
            }
          }
          catch (raw_err){
            var err = Caml_js_exceptions.internalToOCamlException(raw_err);
            var error = Uncaught$BsConsole.castToJsExn(err);
            var __x$1 = Backtrace$BsConsole.Attributes.addString("src_module", "FingerprintDescription/queryDescription/task", Backtrace$BsConsole.Attributes.make(undefined));
            Backtrace$BsConsole.Client.send(__x$1, /* `exn */[
                  5049499,
                  error
                ]);
            return /* Error */Block.__(1, [[
                        ApiWf$BsConsole.ParseError,
                        error
                      ]]);
          }
        }), undefined, undefined);
  return Task2$BsConsole.handle(token, task, undefined, (function (param) {
                return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
              }));
}

exports.makeQuery = makeQuery;
exports.queryDescription = queryDescription;
/* Crdb-BsConsole Not a pure module */
