// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Form$BsConsole = require("./Form.js");
var I18N$BsConsole = require("./I18N.js");
var ReForm_Helpers = require("bs-reform/lib/js/re/ReForm_Helpers.js");
var Util$BsConsole = require("./util.js");
var Regex$BsConsole = require("./Regex.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var BpgController$BsConsole = require("./BpgController.js");
var Configuration$BsConsole = require("./configuration.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var DemoProjectUtil$BsConsole = require("./DemoProjectUtil.js");
var RetentionPolicy$BsConsole = require("./RetentionPolicy.js");
var ApiBillingLimits$BsConsole = require("./ApiBillingLimits.js");

var containerGrid = Css.style(/* :: */[
      Css.maxWidth(Css.px(900)),
      /* :: */[
        Css.marginLeft(/* auto */-1065951377),
        /* :: */[
          Css.marginRight(/* auto */-1065951377),
          /* :: */[
            Css.marginTop(Css.rem(3)),
            /* [] */0
          ]
        ]
      ]
    ]);

var card = Css.style(/* :: */[
      Css.width(Css.px(350)),
      /* :: */[
        Css.marginTop(Css.px(2)),
        /* [] */0
      ]
    ]);

var actions = Css.style(/* :: */[
      Css.flexDirection(/* rowReverse */378951208),
      /* [] */0
    ]);

var Styles = {
  containerGrid: containerGrid,
  card: card,
  actions: actions
};

var component = ReasonReact.reducerComponent("SettingsProjectsNewPage-BsConsole");

function make(refetchShell, limitsRemote, shell, token, handleTask, handleChangeUrl, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var tmp;
              var exit = 0;
              if (typeof limitsRemote === "number" || limitsRemote.tag) {
                exit = 1;
              } else {
                var limit = limitsRemote[0];
                tmp = ReasonReact.element(String(self.state.cacheKey), undefined, Curry._4(BpgController$BsConsole.Project.Create.make, (function (result, _updated) {
                            var defaultRulesJson = RetentionPolicy$BsConsole.toJson(RetentionPolicy$BsConsole.getDefaultRules(undefined));
                            var rules = Belt_Option.getWithDefault(Belt_Option.flatMap(limit, (function (limit) {
                                        var rulesArr = RetentionPolicy$BsConsole.assembleRules(ApiBillingLimits$BsConsole.getRetentionCompress(limit), ApiBillingLimits$BsConsole.getRetentionDeleteOnlyPhysical(limit), ApiBillingLimits$BsConsole.getRetentionDeleteAll(limit), undefined);
                                        if (rulesArr.length !== 0) {
                                          return Caml_option.some(RetentionPolicy$BsConsole.toJson(rulesArr));
                                        }
                                        
                                      })), defaultRulesJson);
                            RetentionPolicy$BsConsole.updateProjectPolicy(undefined, rules, token, (function (param) {
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully created project and first submission token"));
                                    return Curry._1(refetchShell, token);
                                  }), (function (param) {
                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "There was an error"));
                                  }), result.pid, undefined);
                            
                          }), handleTask, token, (function (state, handleSubmit, _toKey) {
                            var project = state.remote;
                            if (typeof project !== "number" && !project.tag) {
                              var project$1 = project[0];
                              if (typeof shell !== "number" && !shell.tag) {
                                if (Configuration$BsConsole.numberOfNonDemoProjects(shell[0].projects) === 1) {
                                  MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* CreatedFirstProject */Block.__(1, ["org_settings"])]));
                                }
                                
                              }
                              return ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, card, undefined, undefined, undefined, [
                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Success!")])),
                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                            /* String */Block.__(2, [
                                                                                /* No_padding */0,
                                                                                /* String_literal */Block.__(11, [
                                                                                    " has successfully been created. Follow the link below to get started setting your project up.",
                                                                                    /* End_of_format */0
                                                                                  ])
                                                                              ]),
                                                                            "%s has successfully been created. Follow the link below to get started setting your project up."
                                                                          ]), project$1.name)]))
                                                      ])),
                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardActions.Jsx2.make, actions, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                  undefined,
                                                                  undefined,
                                                                  (function (_event) {
                                                                      return Curry._1(handleChangeUrl, /* ProjectSettingsSubmission */Block.__(29, [project$1.name]));
                                                                    }),
                                                                  undefined,
                                                                  undefined,
                                                                  /* Raised */-387916264,
                                                                  /* Primary */-791844958,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  [I18N$BsConsole.show(undefined, "get started")]
                                                                ]))]))
                                            ]));
                            }
                            if (typeof shell === "number") {
                              return null;
                            }
                            if (shell.tag) {
                              return null;
                            }
                            var config = shell[0];
                            return ReasonReact.element(undefined, undefined, Curry._7(Form$BsConsole.NewProjectForm.make, (function (param) {
                                              var values = param.values;
                                              if (Regex$BsConsole.containsSpaces(values.name)) {
                                                return Curry._1(self.send, /* SetValidationMessage */[I18N$BsConsole.get(undefined, "Project name must NOT include spaces.")]);
                                              } else {
                                                Curry._1(self.send, /* SetValidationMessage */[undefined]);
                                                return Curry._1(handleSubmit, /* tuple */[
                                                            {
                                                              pid: 0,
                                                              universe: config.universe.id,
                                                              name: values.name,
                                                              owner: config.user.uid,
                                                              deleted: 0,
                                                              __state: undefined,
                                                              __create: undefined,
                                                              __modify: undefined
                                                            },
                                                            Util$BsConsole.identity,
                                                            param.setError,
                                                            param.setSubmitting,
                                                            param.resetFormState
                                                          ]);
                                              }
                                            }), undefined, undefined, {
                                            name: ""
                                          }, /* :: */[
                                            /* tuple */[
                                              /* Name */869834347,
                                              /* Custom */[(function (values) {
                                                    if (Regex$BsConsole.isProjectName(values.name) && !DemoProjectUtil$BsConsole.isDemoProject(values.name)) {
                                                      return ;
                                                    } else {
                                                      return I18N$BsConsole.get(undefined, "illegal name only use a-z, A-Z, 0-9, or \"-\"");
                                                    }
                                                  })]
                                            ],
                                            /* [] */0
                                          ], undefined, (function (param) {
                                              var getErrorForField = param.getErrorForField;
                                              var handleSubmit = param.handleSubmit;
                                              var handleChange = param.handleChange;
                                              var form = param.form;
                                              var match = state.remote;
                                              var match$1 = self.state.validationMessage;
                                              var tmp;
                                              var exit = 0;
                                              var msg;
                                              if (typeof match === "number") {
                                                if (match$1 !== undefined) {
                                                  msg = match$1;
                                                  exit = 1;
                                                } else {
                                                  tmp = null;
                                                }
                                              } else if (match.tag === /* Failure */1) {
                                                msg = match[0];
                                                exit = 1;
                                              } else if (match$1 !== undefined) {
                                                msg = match$1;
                                                exit = 1;
                                              } else {
                                                tmp = null;
                                              }
                                              if (exit === 1) {
                                                tmp = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(msg)]))]));
                                              }
                                              var msg$1 = Curry._1(getErrorForField, /* Name */869834347);
                                              return ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, card, undefined, undefined, undefined, [
                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Create a project")])),
                                                                        tmp,
                                                                        ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  true,
                                                                                  (function (e) {
                                                                                      return ReForm_Helpers.handleDomFormChange(Curry._1(handleChange, /* Name */869834347), e);
                                                                                    }),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  Belt_Option.isSome(Curry._1(getErrorForField, /* Name */869834347)) || Belt_Option.isSome(form.error),
                                                                                  Caml_option.some(msg$1 !== undefined ? I18N$BsConsole.showSkip(msg$1) : I18N$BsConsole.showEmpty(undefined)),
                                                                                  undefined,
                                                                                  /* `String */[
                                                                                    -976970511,
                                                                                    form.values.name
                                                                                  ],
                                                                                  /* Primary */-791844958,
                                                                                  true,
                                                                                  Caml_option.some(I18N$BsConsole.show(undefined, "name")),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  []
                                                                                ]))
                                                                      ])),
                                                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardActions.Jsx2.make, actions, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                  undefined,
                                                                                  undefined,
                                                                                  (function (_event) {
                                                                                      return Curry._1(handleSubmit, undefined);
                                                                                    }),
                                                                                  undefined,
                                                                                  undefined,
                                                                                  /* Raised */-387916264,
                                                                                  /* Primary */-791844958,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  form.values.name.trim() === "",
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  undefined,
                                                                                  [I18N$BsConsole.show(undefined, "Create project")]
                                                                                ]))]))
                                                            ]));
                                            })));
                          })));
              }
              if (exit === 1) {
                tmp = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, "inherit", undefined, undefined, []));
              }
              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(containerGrid, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, /* Center */980392437, [
                                                  ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                            undefined,
                                                            undefined,
                                                            (function (_event) {
                                                                return Curry._1(handleChangeUrl, /* SettingsProjects */8);
                                                              }),
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronLeft.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                          ])),
                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Back to projects page")]))
                                                ])),
                                        tmp
                                      ]))]));
            }),
          initialState: (function (param) {
              return {
                      cacheKey: 0,
                      validationMessage: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            cacheKey: state.cacheKey,
                            validationMessage: action[0]
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            cacheKey: state.cacheKey + 1 | 0,
                            validationMessage: state.validationMessage
                          }]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.Styles = Styles;
exports.component = component;
exports.make = make;
/* containerGrid Not a pure module */
