// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Colors$BsConsole = require("./Colors.js");
var Clipboard$BsConsole = require("./bindings/Clipboard.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var Popover = require("@material-ui/core/Popover");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");

var menuList = Css.style(/* :: */[
      Css.padding(/* zero */-789508312),
      /* :: */[
        Css.focus(/* :: */[
              Css.unsafe("outline", "none"),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var menuItem = Css.style(/* :: */[
      Css.padding(Css.px(8)),
      /* :: */[
        Css.unsafe("height", "unset"),
        /* :: */[
          Css.fontSize(Css.px(13)),
          /* :: */[
            Css.lineHeight(/* `abs */[
                  4845682,
                  1.0
                ]),
            /* :: */[
              Css.hover(/* :: */[
                    Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.white)),
                      /* [] */0
                    ]
                  ]),
              /* :: */[
                Css.focus(/* :: */[
                      Css.unsafe("outline", "none"),
                      /* :: */[
                        Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                        /* :: */[
                          Css.color(Css.hex(Colors$BsConsole.white)),
                          /* [] */0
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  menuList: menuList,
  menuItem: menuItem
};

function filterOperationToString(param) {
  switch (param.tag | 0) {
    case /* NotEqual */1 :
    case /* NotContains */3 :
        return I18N$BsConsole.get(undefined, "Filter against");
    case /* Equal */0 :
    case /* Contains */2 :
    case /* RegularExpression */4 :
        return I18N$BsConsole.get(undefined, "Filter to");
    case /* GreaterThan */6 :
        return I18N$BsConsole.get(undefined, "Greater than");
    case /* LessThan */7 :
        return I18N$BsConsole.get(undefined, "Less than");
    case /* AtLeast */8 :
        return I18N$BsConsole.get(undefined, "At least");
    case /* AtMost */9 :
        return I18N$BsConsole.get(undefined, "At most");
    case /* AssignedTo */10 :
        return I18N$BsConsole.get(undefined, "Assigned to");
    case /* NotAssignedTo */11 :
        return I18N$BsConsole.get(undefined, "Not assigned to");
    default:
      return I18N$BsConsole.get(undefined, "Invariant");
  }
}

function DrilldownMenu(Props) {
  var textValue = Props.textValue;
  var anchorEl = Props.anchorEl;
  var onClose = Props.onClose;
  var filterOptionsOpt = Props.filterOptions;
  var outsideLinkOptionsOpt = Props.outsideLinkOptions;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var filterOptions = filterOptionsOpt !== undefined ? filterOptionsOpt : [];
  var outsideLinkOptions = outsideLinkOptionsOpt !== undefined ? outsideLinkOptionsOpt : [];
  if (anchorEl !== undefined) {
    return React.createElement(Popover.default, {
                anchorOrigin: {
                  horizontal: "left",
                  vertical: "bottom"
                },
                anchorEl: Caml_option.valFromOption(anchorEl),
                open: true,
                className: Css.style(/* :: */[
                      Css.zIndex(10000),
                      /* [] */0
                    ]),
                onClose: (function (param) {
                    return Curry._1(onClose, undefined);
                  }),
                children: React.createElement(MenuList.default, {
                      autoFocus: false,
                      classes: {
                        root: menuList
                      },
                      children: null
                    }, textValue !== undefined ? React.createElement(MenuItem.default, {
                            disableRipple: true,
                            classes: {
                              root: menuItem
                            },
                            onClick: (function (param) {
                                Clipboard$BsConsole.copy(textValue, (function (param) {
                                        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                            /* String */Block.__(2, [
                                                                /* No_padding */0,
                                                                /* String_literal */Block.__(11, [
                                                                    " copied to clipboard.",
                                                                    /* End_of_format */0
                                                                  ])
                                                              ]),
                                                            "%s copied to clipboard."
                                                          ]), textValue));
                                      }), undefined);
                                return Curry._1(onClose, undefined);
                              }),
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Body2 */9,
                                  children: I18N$BsConsole.show(undefined, "Copy to clipboard")
                                }),
                            key: textValue
                          }) : null, Belt_Array.map(outsideLinkOptions, (function (param) {
                            var name = param[0];
                            return React.createElement("a", {
                                        href: param[1],
                                        rel: "noopener noreferrer",
                                        target: "_blank",
                                        onClick: (function ($$event) {
                                            $$event.stopPropagation();
                                            return Curry._1(onClose, undefined);
                                          })
                                      }, React.createElement(MenuItem.default, {
                                            disableRipple: true,
                                            classes: {
                                              root: menuItem
                                            },
                                            children: React.createElement(BtTypography$BsConsole.make, {
                                                  variant: /* Body2 */9,
                                                  children: I18N$BsConsole.showSkip(name)
                                                }),
                                            key: name + "--OutsideLinkOption"
                                          }));
                          })), Belt_Array.map(filterOptions, (function (param) {
                            var operation = param[1];
                            var attribute = param[0];
                            return React.createElement(Link$BsConsole.Jsx3.make, {
                                        route: Curry._1(handleAddFilters, /* :: */[
                                              /* tuple */[
                                                attribute,
                                                operation
                                              ],
                                              /* [] */0
                                            ]),
                                        onClick: (function ($$event) {
                                            $$event.stopPropagation();
                                            return Curry._1(onClose, undefined);
                                          }),
                                        handleChangeUrl: handleChangeUrl,
                                        children: React.createElement(MenuItem.default, {
                                              disableRipple: true,
                                              classes: {
                                                root: menuItem
                                              },
                                              children: React.createElement(BtTypography$BsConsole.make, {
                                                    variant: /* Body2 */9,
                                                    children: I18N$BsConsole.showSkip(filterOperationToString(operation))
                                                  }),
                                              key: attribute + ("__" + (filterOperationToString(operation) + "--MenuItem"))
                                            }),
                                        key: attribute + ("__" + (filterOperationToString(operation) + "--Link"))
                                      });
                          })))
              });
  } else {
    return null;
  }
}

var make = DrilldownMenu;

exports.Style = Style;
exports.filterOperationToString = filterOperationToString;
exports.make = make;
/* menuList Not a pure module */
