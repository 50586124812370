// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var Version$BsConsole = require("./Version.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var L10N_date_time$BsConsole = require("./L10N_date_time.js");
var L10N_date_time_calendar$BsConsole = require("./L10N_date_time_calendar.js");

var dialog = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.important(Css.padding(Css.px(48))),
        /* [] */0
      ]
    ]);

var dialogClose = Css.style(/* :: */[
      Css.important(Css.position(/* absolute */-1013592457)),
      /* :: */[
        Css.right(Css.zero),
        /* :: */[
          Css.top(Css.zero),
          /* [] */0
        ]
      ]
    ]);

var separator = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.grey1)),
      /* :: */[
        Css.height(Css.px(24)),
        /* :: */[
          Css.width(Css.px(1)),
          /* :: */[
            Css.margin2(/* zero */-789508312, Css.px(14)),
            /* [] */0
          ]
        ]
      ]
    ]);

var backtrace = Css.style(/* :: */[
      Css.height(Css.px(16)),
      /* :: */[
        Css.marginTop(Css.px(4)),
        /* [] */0
      ]
    ]);

var Style = {
  dialog: dialog,
  dialogClose: dialogClose,
  separator: separator,
  backtrace: backtrace
};

var component = RR$BsConsole.statelessComponent("About-BsConsole");

function make(onClose, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var ver = window._BACKTRACE_CORONER_VERSION;
              var coronerVersion = (ver == null) ? I18N$BsConsole.get(undefined, "Unknown") : ver;
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, dialog, undefined, [
                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                    dialogClose,
                                                    undefined,
                                                    Curry.__1(onClose),
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                  ])),
                                          ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, undefined, [React.createElement("img", {
                                                          src: "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg"
                                                        })])),
                                          React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.display(/* flex */-1010954439),
                                                      /* :: */[
                                                        Css.justifyContent(/* center */98248149),
                                                        /* :: */[
                                                          Css.marginTop(Css.em(1)),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ])
                                              }, Curry._3(I18N$BsConsole.showSkipf(/* Format */[
                                                        /* String_literal */Block.__(11, [
                                                            "Console ",
                                                            /* String */Block.__(2, [
                                                                /* No_padding */0,
                                                                /* String */Block.__(2, [
                                                                    /* No_padding */0,
                                                                    /* String_literal */Block.__(11, [
                                                                        " - coronerd ",
                                                                        /* String */Block.__(2, [
                                                                            /* No_padding */0,
                                                                            /* End_of_format */0
                                                                          ])
                                                                      ])
                                                                  ])
                                                              ])
                                                          ]),
                                                        "Console %s%s - coronerd %s"
                                                      ]), Version$BsConsole.version, Gate$BsConsole.isAlpha(undefined) ? "-alpha" : (
                                                      Gate$BsConsole.isBeta(undefined) ? "-beta" : ""
                                                    ), coronerVersion)),
                                          React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.display(/* flex */-1010954439),
                                                      /* :: */[
                                                        Css.justifyContent(/* center */98248149),
                                                        /* :: */[
                                                          Css.marginTop(Css.em(0.5)),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ])
                                              }, Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                                        /* String */Block.__(2, [
                                                            /* No_padding */0,
                                                            /* Char_literal */Block.__(12, [
                                                                /* " " */32,
                                                                /* String */Block.__(2, [
                                                                    /* No_padding */0,
                                                                    /* String_literal */Block.__(11, [
                                                                        " Backtrace I/O, Inc.",
                                                                        /* End_of_format */0
                                                                      ])
                                                                  ])
                                                              ])
                                                          ]),
                                                        "%s %s Backtrace I/O, Inc."
                                                      ]), "\u00A9", L10N_date_time_calendar$BsConsole.Format.numericYear(undefined, L10N_date_time$BsConsole.now(undefined))))
                                        ]))]
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var consoleVersion = Version$BsConsole.version;

exports.consoleVersion = consoleVersion;
exports.Style = Style;
exports.component = component;
exports.make = make;
/* dialog Not a pure module */
