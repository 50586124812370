// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var ApiList$BsConsole = require("../apiList.js");
var Dialog = require("@material-ui/core/Dialog");
var Divider = require("@material-ui/core/Divider");
var Popover = require("@material-ui/core/Popover");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var IconButton = require("@material-ui/core/IconButton");
var AttachFile = require("@material-ui/icons/AttachFile");
var BugAttachmentUpload$BsConsole = require("./BugAttachmentUpload.js");

function BugViewAttachments(Props) {
  var token = Props.token;
  var projectName = Props.projectName;
  var errorId = Props.errorId;
  var attachments = Props.attachments;
  var config = Props.config;
  var onAttachmentUploaded = Props.onAttachmentUploaded;
  var match = React.useState((function () {
          
        }));
  var setAnchor = match[1];
  var anchor = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setShowAttachmentUpload = match$1[1];
  var tmp;
  var exit = 0;
  if (typeof attachments === "number") {
    exit = 1;
  } else if (attachments.tag) {
    tmp = React.createElement(MenuItem.default, {
          disabled: true,
          children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                    /* String_literal */Block.__(11, [
                        "Error: ",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* End_of_format */0
                          ])
                      ]),
                    "Error: %s"
                  ]), Task2$BsConsole.renderError(undefined, attachments[0]))
        });
  } else {
    var attachments$1 = attachments[0][0];
    tmp = attachments$1.length !== 0 ? Belt_Array.map(attachments$1, (function (file) {
              var tmp = {
                key: file.name,
                href: ApiList$BsConsole.getPath(errorId, token, projectName, file)
              };
              var tmp$1 = file.inline ? undefined : "";
              if (tmp$1 !== undefined) {
                tmp.download = Caml_option.valFromOption(tmp$1);
              }
              var tmp$2 = file.inline ? "_blank" : undefined;
              if (tmp$2 !== undefined) {
                tmp.target = Caml_option.valFromOption(tmp$2);
              }
              return React.createElement("a", tmp, React.createElement(MenuItem.default, {
                              onClick: (function (param) {
                                  return Curry._1(setAnchor, (function (param) {
                                                
                                              }));
                                }),
                              children: I18N$BsConsole.showSkip(file.name)
                            }));
            })) : React.createElement(MenuItem.default, {
            disabled: true,
            children: I18N$BsConsole.show(undefined, "No attachments")
          });
  }
  if (exit === 1) {
    tmp = React.createElement(MenuItem.default, {
          disabled: true,
          children: I18N$BsConsole.show(undefined, "Loading...")
        });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Tooltip.default, {
                  enterDelay: 250,
                  children: React.createElement(IconButton.default, {
                        onClick: (function ($$event) {
                            return Curry._1(setAnchor, (function (param) {
                                          return Caml_option.some($$event.target);
                                        }));
                          }),
                        children: React.createElement("span", {
                              className: Css.style(/* :: */[
                                    Css.width(Css.px(20)),
                                    /* :: */[
                                      Css.height(Css.px(20)),
                                      /* :: */[
                                        Css.fontSize(Css.px(20)),
                                        /* [] */0
                                      ]
                                    ]
                                  ])
                            }, React.createElement(AttachFile.default, {
                                  fontSize: "inherit",
                                  viewBox: "1 1 23 23"
                                }))
                      }),
                  title: "Attachments"
                }), React.createElement(Popover.default, {
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                  anchorEl: anchor,
                  open: Belt_Option.isSome(anchor),
                  onClose: (function (param) {
                      return Curry._1(setAnchor, (function (param) {
                                    
                                  }));
                    }),
                  children: React.createElement(MenuList.default, {
                        children: null
                      }, React.createElement(MenuItem.default, {
                            onClick: (function (param) {
                                Curry._1(setAnchor, (function (param) {
                                        
                                      }));
                                return Curry._1(setShowAttachmentUpload, (function (param) {
                                              return true;
                                            }));
                              }),
                            children: I18N$BsConsole.show(undefined, "Upload an attachment")
                          }), React.createElement(Divider.default, { }), tmp)
                }), React.createElement(Dialog.default, {
                  open: match$1[0],
                  onClose: (function (param) {
                      return Curry._1(setShowAttachmentUpload, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: React.createElement(BugAttachmentUpload$BsConsole.make, {
                        config: config,
                        errorId: errorId,
                        onAttachmentUploaded: onAttachmentUploaded
                      })
                }));
}

var make = BugViewAttachments;

exports.make = make;
/* Css Not a pure module */
