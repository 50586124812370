// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var IconButton = require("@material-ui/core/IconButton");
var KeyboardArrowLeft = require("@material-ui/icons/KeyboardArrowLeft");
var KeyboardArrowRight = require("@material-ui/icons/KeyboardArrowRight");

var paginationContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.minHeight(Css.px(48)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey2)),
              /* :: */[
                Css.unsafe("backgroundColor", "inherit"),
                /* :: */[
                  Css.flexWrap(/* nowrap */867913355),
                  /* :: */[
                    Css.borderTop(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var spacer = Css.style(/* :: */[
      Css.flexGrow(1),
      /* :: */[
        Css.flexShrink(1),
        /* :: */[
          Css.flexBasis(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var paginationActions = Css.style(/* :: */[
      Css.marginRight(Css.px(20)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexWrap(/* nowrap */867913355),
          /* [] */0
        ]
      ]
    ]);

function BtTablePagination(Props) {
  var classNameOpt = Props.className;
  var renderLabel = Props.renderLabel;
  var total = Props.total;
  var page = Props.page;
  var rowsPerPageOpt = Props.rowsPerPage;
  var onChangePage = Props.onChangePage;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var rowsPerPage = rowsPerPageOpt !== undefined ? rowsPerPageOpt : 20;
  var from = Caml_int32.imul(page, rowsPerPage) + 1 | 0;
  var to_ = Caml_int32.imul(page + 1 | 0, rowsPerPage) >= total ? total : Caml_int32.imul(page + 1 | 0, rowsPerPage);
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    paginationContainer,
                    /* :: */[
                      className,
                      /* :: */[
                        "BtTablePagination",
                        /* [] */0
                      ]
                    ]
                  ])
            }, React.createElement("div", {
                  className: spacer
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Css.style(/* :: */[
                        Css.whiteSpace(/* nowrap */867913355),
                        /* :: */[
                          Css.marginRight(Css.rem(1.0)),
                          /* [] */0
                        ]
                      ]),
                  children: renderLabel !== undefined ? I18N$BsConsole.showSkip(Curry._3(renderLabel, from, to_, total)) : Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                              /* Int */Block.__(4, [
                                  /* Int_d */0,
                                  /* No_padding */0,
                                  /* No_precision */0,
                                  /* String_literal */Block.__(11, [
                                      " - ",
                                      /* Int */Block.__(4, [
                                          /* Int_d */0,
                                          /* No_padding */0,
                                          /* No_precision */0,
                                          /* String_literal */Block.__(11, [
                                              " of ",
                                              /* Int */Block.__(4, [
                                                  /* Int_d */0,
                                                  /* No_padding */0,
                                                  /* No_precision */0,
                                                  /* End_of_format */0
                                                ])
                                            ])
                                        ])
                                    ])
                                ]),
                              "%d - %d of %d"
                            ]), from, to_, total)
                }), React.createElement("div", {
                  className: paginationActions
                }, React.createElement(IconButton.default, {
                      disabled: page === 0,
                      onClick: (function (param) {
                          return Curry._1(onChangePage, page - 1 | 0);
                        }),
                      children: React.createElement(KeyboardArrowLeft.default, { })
                    }), React.createElement(IconButton.default, {
                      disabled: to_ === total,
                      onClick: (function (param) {
                          return Curry._1(onChangePage, page + 1 | 0);
                        }),
                      children: React.createElement(KeyboardArrowRight.default, { })
                    })));
}

var make = BtTablePagination;

exports.paginationContainer = paginationContainer;
exports.spacer = spacer;
exports.paginationActions = paginationActions;
exports.make = make;
/* paginationContainer Not a pure module */
