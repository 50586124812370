// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("./I18N.js");
var Util$BsConsole = require("./util.js");
var ApiWf$BsConsole = require("./workflow/workflows-api/ApiWf.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var WfRouter$BsConsole = require("./workflow/WfRouter.js");
var BtSettings$BsConsole = require("./BtSettings.js");
var ViewLoading$BsConsole = require("./ViewLoading.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var WarningMessage$BsConsole = require("./WarningMessage.js");
var Launch = require("@material-ui/icons/Launch");
var MenuItem = require("@material-ui/core/MenuItem");
var ApiWfConnections$BsConsole = require("./workflow/workflows-api/ApiWfConnections.js");
var ApiWfIntegrations$BsConsole = require("./workflow/workflows-api/ApiWfIntegrations.js");
var JiraServerConnection$BsConsole = require("./workflow/jira/JiraServerConnection.js");
var OutlinedInput = require("@material-ui/core/OutlinedInput");

var container = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* [] */0
    ]);

var title = Css.style(/* :: */[
      Css.paddingBottom(Css.px(29)),
      /* :: */[
        Css.fontSize(Css.rem(1.5)),
        /* :: */[
          Css.margin(Css.px(0)),
          /* [] */0
        ]
      ]
    ]);

var logo = Css.style(/* :: */[
      Css.width(Css.px(80)),
      /* :: */[
        Css.height(Css.px(80)),
        /* [] */0
      ]
    ]);

var logoContainer = Css.style(/* :: */[
      Css.width(Css.rem(8)),
      /* :: */[
        Css.height(Css.rem(8)),
        /* :: */[
          Css.minWidth(Css.rem(8)),
          /* :: */[
            Css.minHeight(Css.rem(8)),
            /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                /* :: */[
                  Css.justifyContent(/* center */98248149),
                  /* :: */[
                    Css.alignItems(/* center */98248149),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var textContainer = Css.style(/* :: */[
      Css.marginLeft(Css.px(29)),
      /* :: */[
        Css.width(Css.pct(100)),
        /* [] */0
      ]
    ]);

var description = Css.style(/* :: */[
      Css.fontSize(Css.rem(1)),
      /* :: */[
        Css.marginTop(Css.px(0)),
        /* :: */[
          Css.marginBottom(Css.rem(1.5)),
          /* [] */0
        ]
      ]
    ]);

var body = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* [] */0
      ]
    ]);

var benefitsHeader = Css.style(/* :: */[
      Css.fontWeight(/* medium */-20425611),
      /* :: */[
        Css.marginBottom(Css.px(0)),
        /* [] */0
      ]
    ]);

var benefits = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.875)),
      /* :: */[
        Css.paddingBottom(Css.px(26)),
        /* :: */[
          Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
          /* [] */0
        ]
      ]
    ]);

var listItem = Css.style(/* :: */[
      Css.marginBottom(Css.px(8)),
      /* :: */[
        Css.fontSize(Css.rem(0.875)),
        /* [] */0
      ]
    ]);

var ul = Css.style(/* :: */[
      Css.marginTop(Css.px(8)),
      /* :: */[
        Css.marginBottom(Css.px(0)),
        /* :: */[
          Css.paddingLeft(Css.rem(1.25)),
          /* [] */0
        ]
      ]
    ]);

var select = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.875)),
      /* :: */[
        Css.paddingTop(Css.px(8)),
        /* :: */[
          Css.paddingBottom(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var selectMenuItem = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.875)),
      /* [] */0
    ]);

var Styles = {
  container: container,
  title: title,
  logo: logo,
  logoContainer: logoContainer,
  textContainer: textContainer,
  description: description,
  body: body,
  benefitsHeader: benefitsHeader,
  benefits: benefits,
  listItem: listItem,
  ul: ul,
  select: select,
  selectMenuItem: selectMenuItem
};

function JiraPluginDetection$CancelButton(Props) {
  var handleOnClick = Props.handleOnClick;
  return React.createElement(Button.default, {
              variant: "flat",
              size: "medium",
              color: "primary",
              className: Css.style(/* :: */[
                    Css.marginRight(Css.rem(0.3125)),
                    /* [] */0
                  ]),
              onClick: handleOnClick,
              children: I18N$BsConsole.show(undefined, "Cancel")
            });
}

var CancelButton = {
  make: JiraPluginDetection$CancelButton
};

function JiraPluginDetection$JiraConnectionsSelect(Props) {
  var jiraConnectionSelection = Props.jiraConnectionSelection;
  var jiraConnections = Props.jiraConnections;
  var handleJiraConnectionSelection = Props.handleJiraConnectionSelection;
  return React.createElement(Select.default, {
              value: jiraConnectionSelection,
              onChange: (function ($$event, param) {
                  var value = $$event.target.value;
                  if (!(value == null)) {
                    return Curry._1(handleJiraConnectionSelection, value);
                  }
                  
                }),
              input: React.createElement(OutlinedInput.default, {
                    labelWidth: 0
                  }),
              classes: {
                select: select
              },
              children: Belt_Option.getWithDefault(jiraConnections, []).length !== 0 ? Belt_Array.map(Belt_Option.getWithDefault(jiraConnections, []), (function (connection) {
                        return React.createElement(MenuItem.default, {
                                    classes: {
                                      root: selectMenuItem
                                    },
                                    value: connection.id,
                                    children: I18N$BsConsole.showSkip(connection.name),
                                    key: String(connection.id)
                                  });
                      })) : [React.createElement(MenuItem.default, {
                        children: I18N$BsConsole.show(undefined, "No options available")
                      })]
            });
}

var JiraConnectionsSelect = {
  make: JiraPluginDetection$JiraConnectionsSelect
};

function JiraPluginDetection$JiraCloudNextSteps(Props) {
  var jiraPluginLink = Props.jiraPluginLink;
  var style = Props.style;
  return React.createElement("ol", {
              className: style
            }, React.createElement("li", {
                  className: listItem
                }, I18N$BsConsole.show(undefined, "Go to "), React.createElement("a", {
                      href: jiraPluginLink,
                      rel: "noopener noreferrer",
                      onClick: (function (param) {
                          return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Connection_InstallLinkClick */Block.__(6, ["jiraCloud"])]));
                        })
                    }, I18N$BsConsole.show(undefined, "Jira marketplace")), I18N$BsConsole.show(undefined, " and install the plugin.")), React.createElement("li", {
                  className: listItem
                }, I18N$BsConsole.show(undefined, "After the plugin is installed, you will be redirected to Backtrace to complete the integration.")));
}

var JiraCloudNextSteps = {
  make: JiraPluginDetection$JiraCloudNextSteps
};

var buttonsContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexEnd */924268066),
        /* :: */[
          Css.marginTop(Css.rem(3.125)),
          /* [] */0
        ]
      ]
    ]);

var title$1 = Css.style(/* :: */[
      Css.fontSize(Css.rem(1.125)),
      /* :: */[
        Css.fontWeight(/* medium */-20425611),
        /* :: */[
          Css.marginBottom(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var listItem$1 = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.875)),
      /* :: */[
        Css.marginBottom(Css.px(8)),
        /* [] */0
      ]
    ]);

var orderedList = Css.style(/* :: */[
      Css.marginTop(Css.px(0)),
      /* :: */[
        Css.marginBottom(Css.px(8)),
        /* :: */[
          Css.paddingLeft(Css.rem(1.25)),
          /* [] */0
        ]
      ]
    ]);

var icon = Css.style(/* :: */[
      Css.marginLeft(Css.px(3)),
      /* :: */[
        Css.fontSize(Css.rem(1)),
        /* [] */0
      ]
    ]);

var Styles$1 = {
  buttonsContainer: buttonsContainer,
  title: title$1,
  listItem: listItem$1,
  orderedList: orderedList,
  icon: icon
};

function JiraPluginDetection$InstallJiraCloudPlugin(Props) {
  var onCancel = Props.onCancel;
  var jiraPluginLink = "https://marketplace.atlassian.com/apps/1228456/backtrace-for-jira?tab=overview&hosting=cloud";
  return React.createElement(React.Fragment, undefined, React.createElement("p", {
                  className: title$1
                }, I18N$BsConsole.show(undefined, "Next Steps")), React.createElement(JiraPluginDetection$JiraCloudNextSteps, {
                  jiraPluginLink: jiraPluginLink,
                  style: orderedList
                }), React.createElement("div", {
                  className: buttonsContainer
                }, React.createElement(React.Fragment, undefined, React.createElement(JiraPluginDetection$CancelButton, {
                          handleOnClick: (function (param) {
                              return Curry._1(onCancel, undefined);
                            })
                        }), React.createElement(Button.default, {
                          variant: "contained",
                          size: "medium",
                          color: "primary",
                          onClick: (function (param) {
                              return Util$BsConsole.openUrl(jiraPluginLink);
                            }),
                          children: null
                        }, I18N$BsConsole.show(undefined, "Install Jira Plugin "), React.createElement(Launch.default, {
                              className: icon,
                              color: "inherit",
                              fontSize: "inherit"
                            })))));
}

var InstallJiraCloudPlugin = {
  Styles: Styles$1,
  make: JiraPluginDetection$InstallJiraCloudPlugin
};

var buttonsContainer$1 = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexEnd */924268066),
        /* :: */[
          Css.marginTop(Css.rem(3.125)),
          /* [] */0
        ]
      ]
    ]);

var title$2 = Css.style(/* :: */[
      Css.fontSize(Css.rem(1.25)),
      /* :: */[
        Css.fontWeight(/* medium */-20425611),
        /* :: */[
          Css.marginBottom(Css.px(0)),
          /* [] */0
        ]
      ]
    ]);

var text = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.875)),
      /* [] */0
    ]);

var selectConnection = Css.style(/* :: */[
      Css.marginBottom(Css.px(15)),
      /* :: */[
        Css.marginTop(Css.px(10)),
        /* :: */[
          Css.fontWeight(/* medium */-20425611),
          /* [] */0
        ]
      ]
    ]);

var Styles$2 = {
  buttonsContainer: buttonsContainer$1,
  title: title$2,
  text: text,
  selectConnection: selectConnection
};

function JiraPluginDetection$JiraPluginFound(Props) {
  var onCancel = Props.onCancel;
  var onJiraConnectionSelect = Props.onJiraConnectionSelect;
  var onContinue = Props.onContinue;
  var jiraConnections = Props.jiraConnections;
  var jiraConnectionSelection = Props.jiraConnectionSelection;
  var jiraType = Props.jiraType;
  var projectName = Props.projectName;
  var handleChangeUrl = Props.handleChangeUrl;
  return React.createElement(React.Fragment, undefined, React.createElement("p", {
                  className: title$2
                }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* End_of_format */0
                            ]),
                          "%s"
                        ]), jiraType ? "Connection Found!" : "Plugin Installation Found!")), React.createElement("p", {
                  className: text
                }, jiraType ? React.createElement(React.Fragment, undefined, I18N$BsConsole.show(undefined, "Looks like you already have existing connections. You can continue to configuration, or make a "), React.createElement("a", {
                            className: Css.style(/* :: */[
                                  Css.cursor(/* pointer */-786317123),
                                  /* [] */0
                                ]),
                            onClick: (function (param) {
                                return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                              projectName,
                                              /* JiraServer */Block.__(0, [/* Create */1]),
                                              undefined
                                            ]));
                              })
                          }, I18N$BsConsole.show(undefined, "new connection."))) : React.createElement(React.Fragment, undefined, I18N$BsConsole.show(undefined, "Looks like you already have the plugin installed. You can continue to configuration, or "), React.createElement("a", {
                            href: "https://marketplace.atlassian.com/apps/1228456/backtrace-for-jira?tab=overview&hosting=cloud",
                            rel: "noopener noreferrer",
                            onClick: (function (param) {
                                return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Connection_InstallLinkClick */Block.__(6, ["jiraCloud"])]));
                              })
                          }, I18N$BsConsole.show(undefined, "re-install the plugin.")))), React.createElement("p", {
                  className: selectConnection
                }, I18N$BsConsole.show(undefined, "Select a Jira Instance")), React.createElement(JiraPluginDetection$JiraConnectionsSelect, {
                  jiraConnectionSelection: jiraConnectionSelection,
                  jiraConnections: jiraConnections,
                  handleJiraConnectionSelection: onJiraConnectionSelect
                }), React.createElement("div", {
                  className: buttonsContainer$1
                }, React.createElement(JiraPluginDetection$CancelButton, {
                      handleOnClick: (function (param) {
                          return Curry._1(onCancel, undefined);
                        })
                    }), React.createElement(Button.default, {
                      variant: "contained",
                      size: "medium",
                      color: "primary",
                      onClick: (function (param) {
                          return Curry._1(onContinue, undefined);
                        }),
                      children: I18N$BsConsole.show(undefined, "Continue")
                    })));
}

var JiraPluginFound = {
  Styles: Styles$2,
  make: JiraPluginDetection$JiraPluginFound
};

function JiraPluginDetection$PluginSummary(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement("p", {
                  className: description
                }, I18N$BsConsole.show(undefined, "Linking Backtrace and Jira provides your team the ability to quickly debug crashes and errors that direcly impact your end users.")), React.createElement("div", {
                  className: benefits
                }, React.createElement("p", {
                      className: benefitsHeader
                    }, I18N$BsConsole.show(undefined, "Benefits of Backtrace + Jira")), React.createElement("ul", {
                      className: ul
                    }, React.createElement("li", {
                          className: listItem
                        }, I18N$BsConsole.show(undefined, "Create Jira tickets with all the error details within Backtrace to triage and track the most important issues")), React.createElement("li", {
                          className: listItem
                        }, I18N$BsConsole.show(undefined, "Sync details and updates between Backtrace and Jira")), React.createElement("li", {
                          className: listItem
                        }, I18N$BsConsole.show(undefined, "Reopen errors that have reappeared in new versions of your application")), React.createElement("li", {
                          className: listItem
                        }, I18N$BsConsole.show(undefined, "Generate alerts to automatically create Jira tickets")))));
}

var PluginSummary = {
  make: JiraPluginDetection$PluginSummary
};

function JiraPluginDetection(Props) {
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var project = Props.project;
  var config = Props.config;
  var route = Props.route;
  var jiraType = Props.jiraType;
  var match = React.useState((function () {
          return false;
        }));
  var setPluginFound = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setJiraConnections = match$1[1];
  var jiraConnections = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setJiraConnectionSelection = match$2[1];
  var jiraConnectionSelection = match$2[0];
  var match$3 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match$3[1];
  var remote = match$3[0];
  var match$4 = React.useState((function () {
          return "";
        }));
  var setErrorMessage = match$4[1];
  var errorMessage = match$4[0];
  React.useEffect((function () {
          if (typeof route !== "number" && !route.tag) {
            var err = route[1];
            if (err !== undefined) {
              Curry._1(setErrorMessage, (function (param) {
                      return err;
                    }));
            }
            
          }
          
        }), [route]);
  var onCancel = function (param) {
    return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                  project.name,
                  /* WatchersList */2,
                  undefined
                ]));
  };
  var onJiraConnectionSelect = function (value) {
    return Curry._1(setJiraConnectionSelection, (function (param) {
                  return value;
                }));
  };
  var onContinue = function (param) {
    var isConnectionAuthorized = jiraType && jiraConnections !== undefined && jiraConnectionSelection !== undefined ? Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.getBy(jiraConnections, (function (connection) {
                      return connection.id === jiraConnectionSelection;
                    })), (function (connection) {
                  return connection.options.authorizationOptions.authorized;
                })), true) : true;
    if (!isConnectionAuthorized) {
      return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                    project.name,
                    /* JiraServer */Block.__(0, [/* Authorize */Block.__(1, [Belt_Option.getWithDefault(jiraConnectionSelection, -1)])]),
                    undefined
                  ]));
    }
    var param_pluginId = Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType);
    var param$1 = {
      pluginId: param_pluginId,
      connectionId: jiraConnectionSelection,
      connectionCreated: undefined,
      integrationId: undefined,
      referrer: undefined,
      error: undefined
    };
    return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                  project.name,
                  WfRouter$BsConsole.root,
                  param$1
                ]));
  };
  var onSuccess = function (param) {
    var body = param.body;
    return Curry._1(setRemote, (function (param) {
                  return /* Success */Block.__(0, [body]);
                }));
  };
  var onFailure = function (err) {
    Curry._1(setErrorMessage, (function (param) {
            return err;
          }));
    return Curry._1(setRemote, (function (param) {
                  return /* Failure */Block.__(1, [err]);
                }));
  };
  React.useEffect((function () {
          if (typeof remote === "number") {
            if (remote === /* NotAsked */0) {
              Curry._1(setRemote, (function (param) {
                      return /* Loading */1;
                    }));
            } else {
              var maybeTask = ApiWfConnections$BsConsole.QueryConnections.queryConnectionsTask(config, project.name, Curry._1(ApiWfIntegrations$BsConsole.Jira.JiraType.typeToPluginId, jiraType));
              if (maybeTask !== undefined) {
                Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                        return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                      }));
              } else {
                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
              }
            }
          } else if (remote.tag) {
            Curry._1(setPluginFound, (function (param) {
                    return false;
                  }));
          } else {
            var body = remote[0];
            if (body.length !== 0) {
              var firstConnection = Belt_Array.get(body, 0);
              var firstConnectionId = Belt_Option.map(firstConnection, (function (connection) {
                      return connection.id;
                    }));
              Curry._1(setPluginFound, (function (param) {
                      return true;
                    }));
              Curry._1(setJiraConnections, (function (param) {
                      return body;
                    }));
              Curry._1(setJiraConnectionSelection, (function (param) {
                      return firstConnectionId;
                    }));
            } else {
              Curry._1(setPluginFound, (function (param) {
                      return false;
                    }));
              Curry._1(setJiraConnections, (function (param) {
                      
                    }));
              Curry._1(setJiraConnectionSelection, (function (param) {
                      
                    }));
            }
          }
          
        }), [remote]);
  var tmp;
  if (typeof remote === "number") {
    tmp = React.createElement(ViewLoading$BsConsole.Jsx3.make, {
          label: "Checking for Jira plugin"
        });
  } else {
    var tmp$1;
    tmp$1 = match[0] ? (
        jiraType ? (
            typeof route === "number" ? (
                route === /* Default */0 ? React.createElement(React.Fragment, undefined, React.createElement(JiraPluginDetection$PluginSummary, { }), React.createElement(JiraPluginDetection$JiraPluginFound, {
                            onCancel: onCancel,
                            onJiraConnectionSelect: onJiraConnectionSelect,
                            onContinue: onContinue,
                            jiraConnections: jiraConnections,
                            jiraConnectionSelection: jiraConnectionSelection,
                            jiraType: jiraType,
                            projectName: project.name,
                            handleChangeUrl: handleChangeUrl
                          })) : React.createElement(JiraServerConnection$BsConsole.make, {
                        route: route,
                        config: config,
                        projectName: project.name,
                        token: token,
                        handleSetErrorMessage: (function (err) {
                            return Curry._1(setErrorMessage, (function (param) {
                                          return err;
                                        }));
                          }),
                        handleChangeUrl: handleChangeUrl
                      })
              ) : (
                route.tag ? React.createElement(JiraServerConnection$BsConsole.make, {
                        route: route,
                        config: config,
                        projectName: project.name,
                        token: token,
                        handleSetErrorMessage: (function (err) {
                            return Curry._1(setErrorMessage, (function (param) {
                                          return err;
                                        }));
                          }),
                        handleChangeUrl: handleChangeUrl
                      }) : React.createElement(JiraServerConnection$BsConsole.make, {
                        route: route,
                        config: config,
                        projectName: project.name,
                        token: token,
                        handleSetErrorMessage: (function (err) {
                            return Curry._1(setErrorMessage, (function (param) {
                                          return err;
                                        }));
                          }),
                        handleChangeUrl: handleChangeUrl
                      })
              )
          ) : React.createElement(React.Fragment, undefined, React.createElement(JiraPluginDetection$PluginSummary, { }), React.createElement(JiraPluginDetection$JiraPluginFound, {
                    onCancel: onCancel,
                    onJiraConnectionSelect: onJiraConnectionSelect,
                    onContinue: onContinue,
                    jiraConnections: jiraConnections,
                    jiraConnectionSelection: jiraConnectionSelection,
                    jiraType: jiraType,
                    projectName: project.name,
                    handleChangeUrl: handleChangeUrl
                  }))
      ) : (
        jiraType ? React.createElement(JiraServerConnection$BsConsole.make, {
                route: route,
                config: config,
                projectName: project.name,
                token: token,
                handleSetErrorMessage: (function (err) {
                    return Curry._1(setErrorMessage, (function (param) {
                                  return err;
                                }));
                  }),
                handleChangeUrl: handleChangeUrl
              }) : React.createElement(JiraPluginDetection$InstallJiraCloudPlugin, {
                onCancel: onCancel
              })
      );
    tmp = React.createElement(Paper.default, {
          classes: {
            root: container
          },
          children: null
        }, React.createElement("p", {
              className: title
            }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                      /* String_literal */Block.__(11, [
                          "Jira ",
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* String_literal */Block.__(11, [
                                  " Integration",
                                  /* End_of_format */0
                                ])
                            ])
                        ]),
                      "Jira %s Integration"
                    ]), jiraType ? "Server" : "Cloud")), React.createElement("div", {
              className: body
            }, React.createElement("div", {
                  className: logoContainer
                }, React.createElement("img", {
                      className: logo,
                      src: "https://res.cloudinary.com/backtrace/image/upload/v1644361956/workflows_jira.svg"
                    })), React.createElement("div", {
                  className: textContainer
                }, tmp$1)));
  }
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: null
            }, errorMessage.length > 0 ? React.createElement(WarningMessage$BsConsole.make, {
                    errorMessage: I18N$BsConsole.show(undefined, errorMessage)
                  }) : null, tmp);
}

var component = ReasonReact.statelessComponent("JiraPluginDetection");

function make(token, handleChangeUrl, project, config, route, jiraType, children) {
  return ReasonReactCompat.wrapReactForReasonReact(JiraPluginDetection, {
              token: token,
              handleChangeUrl: handleChangeUrl,
              project: project,
              config: config,
              route: route,
              jiraType: jiraType
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = JiraPluginDetection;

exports.Styles = Styles;
exports.CancelButton = CancelButton;
exports.JiraConnectionsSelect = JiraConnectionsSelect;
exports.JiraCloudNextSteps = JiraCloudNextSteps;
exports.InstallJiraCloudPlugin = InstallJiraCloudPlugin;
exports.JiraPluginFound = JiraPluginFound;
exports.PluginSummary = PluginSummary;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* container Not a pure module */
