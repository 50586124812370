// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var InboxMultiTags$BsConsole = require("../../inbox/InboxMultiTags.js");

function BugIssueTags(Props) {
  var anchorOrigin = Props.anchorOrigin;
  var allTags = Props.allTags;
  var target = Props.target;
  var tagGroups = Props.tagGroups;
  var handleAddRemoveTags = Props.handleAddRemoveTags;
  return ReasonReact.element(undefined, undefined, InboxMultiTags$BsConsole.make(anchorOrigin, undefined, allTags, target, tagGroups, handleAddRemoveTags, undefined, []));
}

var make = BugIssueTags;

exports.make = make;
/* ReasonReact Not a pure module */
