// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Route$BsConsole = require("../route.js");
var Task2$BsConsole = require("../Task2.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var Belt_HashMapString = require("bs-platform/lib/js/belt_HashMapString.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var TeamsUtils$BsConsole = require("../teams/TeamsUtils.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var BtTableColumnWidth$BsConsole = require("../BtTableColumnWidth.js");
var BtTableSessionData$BsConsole = require("../BtTableSessionData.js");
var UserFrontendSettings$BsConsole = require("../UserFrontendSettings.js");
var ProjectFrontendSettings$BsConsole = require("../ProjectFrontendSettings.js");
var BacktraceParserGenerator$BsConsole = require("../BacktraceParserGenerator.js");

function getName(t$prime) {
  if (t$prime.tag) {
    return "not yet implemented";
  } else {
    return t$prime[0].name;
  }
}

function getUsername(t$prime) {
  if (t$prime.tag) {
    return "not yet implemented";
  } else {
    return t$prime[0].username;
  }
}

function getMode(t$prime) {
  if (!t$prime.tag) {
    return t$prime[0].mode;
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "SavedQueriesModel.re",
          68,
          9
        ]
      ];
}

function setColumnOrderAndWidth(t$prime, projectName) {
  if (t$prime.tag) {
    return ;
  }
  var args = t$prime[0];
  var match = args.columnOrder;
  var match$1 = args.columnWidth;
  var match$2 = args.view;
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined) {
    BtTableSessionData$BsConsole.ColumnOrder.set(projectName, match, match$2);
    return BtTableSessionData$BsConsole.ColumnWidth.set(projectName, match$1, match$2);
  }
  
}

function getQuery(t$prime) {
  if (!t$prime.tag) {
    return t$prime[0].query;
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "SavedQueriesModel.re",
          87,
          9
        ]
      ];
}

function v0parseMode(t$prime) {
  var str = Json_decode.field("qb", (function (json) {
          return Json_decode.field("mode", Json_decode.string, json);
        }), JSON.parse(Json_decode.field("payload", Json_decode.string, t$prime)));
  switch (str) {
    case "aggregate" :
        return /* Aggregate */1;
    case "select" :
        return /* Instance */2;
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "SavedQueriesModel.re",
              101,
              13
            ]
          ];
  }
}

function v0parseSortOrder(fields, t$prime) {
  var str = Json_decode.field("qb", (function (json) {
          var partial_arg = Belt_List.map(fields, (function (f) {
                  return (function (param) {
                      return Json_decode.field(f, Json_decode.string, param);
                    });
                }));
          return Json_decode.field("sorting", (function (param) {
                        return Json_decode.oneOf(partial_arg, param);
                      }), json);
        }), JSON.parse(Json_decode.field("payload", Json_decode.string, t$prime)));
  switch (str) {
    case "ascending" :
        return /* Ascending */0;
    case "descending" :
        return /* Descending */1;
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "SavedQueriesModel.re",
              118,
              13
            ]
          ];
  }
}

function v0parseSortAggregate(t$prime) {
  try {
    var str = Json_decode.field("qb", (function (json) {
            var partial_arg_000 = function (param) {
              return Json_decode.field("factor", Json_decode.string, param);
            };
            var partial_arg_001 = /* :: */[
              (function (param) {
                  return Json_decode.field("aggFactor", Json_decode.string, param);
                }),
              /* [] */0
            ];
            var partial_arg = /* :: */[
              partial_arg_000,
              partial_arg_001
            ];
            return Json_decode.field("sorting", (function (param) {
                          return Json_decode.oneOf(partial_arg, param);
                        }), json);
          }), JSON.parse(Json_decode.field("payload", Json_decode.string, t$prime)));
    var tmp;
    switch (str) {
      case "age" :
          tmp = /* Range */Block.__(4, [
              "timestamp",
              /* Start */0,
              v0parseSortOrder(/* :: */[
                    "aggSortOrder",
                    /* :: */[
                      "sortOrder",
                      /* [] */0
                    ]
                  ], t$prime)
            ]);
          break;
      case "count" :
          tmp = /* Count */Block.__(1, [v0parseSortOrder(/* :: */[
                    "aggSortOrder",
                    /* :: */[
                      "sortOrder",
                      /* [] */0
                    ]
                  ], t$prime)]);
          break;
      case "recent activity" :
          tmp = /* Range */Block.__(4, [
              "timestamp",
              /* Finish */1,
              v0parseSortOrder(/* :: */[
                    "aggSortOrder",
                    /* :: */[
                      "sortOrder",
                      /* [] */0
                    ]
                  ], t$prime)
            ]);
          break;
      default:
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "SavedQueriesModel.re",
                150,
                17
              ]
            ];
    }
    return tmp;
  }
  catch (exn){
    return ;
  }
}

function v0parseSortInstance(t$prime) {
  try {
    var str = Json_decode.field("qb", (function (json) {
            var partial_arg_000 = function (param) {
              return Json_decode.field("factor", Json_decode.string, param);
            };
            var partial_arg_001 = /* :: */[
              (function (param) {
                  return Json_decode.field("selectFactor", Json_decode.string, param);
                }),
              /* [] */0
            ];
            var partial_arg = /* :: */[
              partial_arg_000,
              partial_arg_001
            ];
            return Json_decode.field("sorting", (function (param) {
                          return Json_decode.oneOf(partial_arg, param);
                        }), json);
          }), JSON.parse(Json_decode.field("payload", Json_decode.string, t$prime)));
    return /* Select */Block.__(5, [
              str,
              v0parseSortOrder(/* :: */[
                    "selectSortOrder",
                    /* :: */[
                      "sortOrder",
                      /* [] */0
                    ]
                  ], t$prime)
            ]);
  }
  catch (exn){
    return ;
  }
}

function v0parseSort(mode, t$prime) {
  switch (mode) {
    case /* Inbox */0 :
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "SavedQueriesModel.re",
                188,
                13
              ]
            ];
    case /* Aggregate */1 :
        return v0parseSortAggregate(t$prime);
    case /* Instance */2 :
        return v0parseSortInstance(t$prime);
    
  }
}

function v0ParseAperture(mode, t$prime) {
  switch (mode) {
    case /* Inbox */0 :
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "SavedQueriesModel.re",
                195,
                13
              ]
            ];
    case /* Aggregate */1 :
        return v0parseSortAggregate(t$prime);
    case /* Instance */2 :
        return v0parseSortInstance(t$prime);
    
  }
}

function v0ParseRelativeTime(t$prime) {
  var str = Json_decode.field("qb", (function (param) {
          return Json_decode.field("dateTimePicker", (function (param) {
                        return Json_decode.field("granularity", Json_decode.string, param);
                      }), param);
        }), JSON.parse(Json_decode.field("payload", Json_decode.string, t$prime)));
  switch (str) {
    case "1D" :
        return Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                      /* Floating */0,
                      /* Day */1
                    ]));
    case "1M" :
        return Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                      /* Floating */0,
                      /* Month */3
                    ]));
    case "1W" :
        return Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                      /* Floating */0,
                      /* Week */2
                    ]));
    case "1Y" :
        return Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                      /* Floating */0,
                      /* Year */5
                    ]));
    case "3M" :
        return Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                      /* Floating */0,
                      /* Quarter */4
                    ]));
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "SavedQueriesModel.re",
              211,
              15
            ]
          ];
  }
}

function v0ParseAbsoluteTime(t$prime) {
  return Json_decode.field("qb", (function (param) {
                return Json_decode.field("dateTimePicker", (function (json) {
                              var d$prime = Json_decode.field("start", Json_decode.date, json);
                              var d$prime$prime = Json_decode.field("end", Json_decode.date, json);
                              return Curry._1(Crdb$BsConsole.TimeAperture.create, /* Custom */Block.__(1, [/* tuple */[
                                              d$prime,
                                              d$prime$prime
                                            ]]));
                            }), param);
              }), JSON.parse(Json_decode.field("payload", Json_decode.string, t$prime)));
}

function v0ParseTime(t$prime) {
  var str = Json_decode.field("time", Json_decode.string, JSON.parse(Json_decode.field("payload", Json_decode.string, t$prime)));
  switch (str) {
    case "absolute" :
        return v0ParseAbsoluteTime(t$prime);
    case "relative" :
        return v0ParseRelativeTime(t$prime);
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "SavedQueriesModel.re",
              240,
              15
            ]
          ];
  }
}

function v0ParseFactor(t$prime) {
  var __x = Belt_List.head(Json_decode.at(/* :: */[
              "queries",
              /* :: */[
                "factor",
                /* :: */[
                  "expanded",
                  /* :: */[
                    "group",
                    /* [] */0
                  ]
                ]
              ]
            ], (function (param) {
                return Json_decode.list(Json_decode.string, param);
              }))(t$prime));
  return Belt_Option.mapWithDefault(__x, undefined, (function (factor) {
                if (factor === "fingerprint") {
                  return /* Identity */0;
                } else {
                  return /* Custom */[factor];
                }
              }));
}

function v0ParseFold(t$prime) {
  var __x = Js_dict.entries(Json_decode.at(/* :: */[
              "queries",
              /* :: */[
                "factor",
                /* :: */[
                  "expanded",
                  /* :: */[
                    "fold",
                    /* [] */0
                  ]
                ]
              ]
            ], (function (param) {
                return Json_decode.dict((function (json) {
                              var __x = Json_decode.list((function (json) {
                                      try {
                                        return Crdb$BsConsole.Operation.fromJson(json);
                                      }
                                      catch (exn){
                                        return ;
                                      }
                                    }), json);
                              var __x$1 = Belt_List.keepMap(__x, Util$BsConsole.identity);
                              return Belt_List.map(__x$1, (function (o) {
                                            if (typeof o === "number" && o === 0) {
                                              return /* Distribution */Block.__(1, [3]);
                                            } else {
                                              return o;
                                            }
                                          }));
                            }), param);
              }))(t$prime));
  return Belt_Array.reduce(__x, Crdb$BsConsole.Fold.empty, (function (acc, param) {
                var attribute = param[0];
                if (attribute === "timestamp") {
                  return acc;
                } else {
                  return Belt_List.reduce(param[1], acc, (function (acc, operation) {
                                return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                                attribute,
                                                operation
                                              ]), acc);
                              }));
                }
              }));
}

function v0ParseFiltersAggregate(json) {
  var __x = Js_dict.entries(Belt_List.headExn(Json_decode.at(/* :: */[
                  "queries",
                  /* :: */[
                    "factor",
                    /* :: */[
                      "expanded",
                      /* :: */[
                        "filter",
                        /* [] */0
                      ]
                    ]
                  ]
                ], (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.dict((function (json) {
                                                var __x = Json_decode.list((function (json) {
                                                        try {
                                                          return Crdb$BsConsole.FilterOperation.fromJson(json);
                                                        }
                                                        catch (raw_err){
                                                          var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                                                          console.log(err);
                                                          return ;
                                                        }
                                                      }), json);
                                                var __x$1 = Belt_List.keep(__x, Belt_Option.isSome);
                                                return Belt_List.map(__x$1, Belt_Option.getExn);
                                              }), param);
                                }), param);
                  }))(json)));
  return Belt_Array.reduce(__x, Crdb$BsConsole.Filters.empty, (function (acc, param) {
                var attribute = param[0];
                if (attribute === "timestamp") {
                  return acc;
                } else {
                  return Belt_List.reduce(param[1], acc, (function (acc, operation) {
                                return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                attribute,
                                                operation
                                              ]), acc);
                              }));
                }
              }));
}

function v0ParseFiltersSelect(json) {
  var __x = Js_dict.entries(Belt_List.headExn(Json_decode.at(/* :: */[
                  "queries",
                  /* :: */[
                    "table",
                    /* :: */[
                      "expanded",
                      /* :: */[
                        "filter",
                        /* [] */0
                      ]
                    ]
                  ]
                ], (function (param) {
                    return Json_decode.list((function (param) {
                                  return Json_decode.dict((function (json) {
                                                var __x = Json_decode.list((function (json) {
                                                        try {
                                                          return Crdb$BsConsole.FilterOperation.fromJson(json);
                                                        }
                                                        catch (raw_err){
                                                          var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                                                          console.log(err);
                                                          return ;
                                                        }
                                                      }), json);
                                                var __x$1 = Belt_List.keep(__x, Belt_Option.isSome);
                                                return Belt_List.map(__x$1, Belt_Option.getExn);
                                              }), param);
                                }), param);
                  }))(json)));
  return Belt_Array.reduce(__x, Crdb$BsConsole.Filters.empty, (function (acc, param) {
                var attribute = param[0];
                if (attribute === "timestamp") {
                  return acc;
                } else {
                  return Belt_List.reduce(param[1], acc, (function (acc, operation) {
                                return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                attribute,
                                                operation
                                              ]), acc);
                              }));
                }
              }));
}

function v0ParseSelect(json) {
  var __x = Json_decode.at(/* :: */[
          "queries",
          /* :: */[
            "table",
            /* :: */[
              "expanded",
              /* :: */[
                "select",
                /* [] */0
              ]
            ]
          ]
        ], (function (param) {
            return Json_decode.list(Json_decode.string, param);
          }))(json);
  var __x$1 = Belt_List.keep(__x, (function (str) {
          switch (str) {
            case "_deleted" :
            case "object" :
            case "timestamp" :
                return false;
            default:
              return true;
          }
        }));
  return Belt_List.reduce(__x$1, Crdb$BsConsole.Select.empty, (function (acc, str) {
                return Curry._2(Crdb$BsConsole.Select.add, str, acc);
              }));
}

function versionToString(t$prime) {
  if (t$prime) {
    return "v1";
  } else {
    return "v0";
  }
}

function modeToString(t$prime) {
  switch (t$prime) {
    case /* Inbox */0 :
        return "inbox";
    case /* Aggregate */1 :
        return "aggregate";
    case /* Instance */2 :
        return "instance";
    
  }
}

function modeToFriendlyString(t$prime) {
  switch (t$prime) {
    case /* Inbox */0 :
        return "Triage";
    case /* Aggregate */1 :
        return "Explore aggregate";
    case /* Instance */2 :
        return "Explore List";
    
  }
}

function modeOfStringExn(t$prime) {
  switch (t$prime) {
    case "aggregate" :
        return /* Aggregate */1;
    case "inbox" :
        return /* Inbox */0;
    case "instance" :
        return /* Instance */2;
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "SavedQueriesModel.re",
              400,
              9
            ]
          ];
  }
}

function fromJsonV0(json) {
  var mode = v0parseMode(json);
  var tmp;
  switch (mode) {
    case /* Inbox */0 :
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "SavedQueriesModel.re",
                435,
                17
              ]
            ];
    case /* Aggregate */1 :
        tmp = /* Aggregate */Block.__(0, [
            Curry._2(Crdb$BsConsole.Aperture.setFilters, v0ParseFiltersAggregate(json), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, v0ParseTime(json), Crdb$BsConsole.Aperture.$$default)),
            v0ParseFactor(json),
            v0ParseFold(json),
            v0parseSort(mode, json),
            undefined,
            undefined
          ]);
        break;
    case /* Instance */2 :
        tmp = /* Select */Block.__(1, [
            Curry._2(Crdb$BsConsole.Aperture.setFilters, v0ParseFiltersSelect(json), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, v0ParseTime(json), Crdb$BsConsole.Aperture.$$default)),
            v0ParseSelect(json),
            v0parseSort(mode, json),
            undefined
          ]);
        break;
    
  }
  return /* Dashboard */Block.__(0, [{
              username: Json_decode.field("owner", Json_decode.string, json),
              version: /* V0 */0,
              name: Json_decode.field("name", Json_decode.string, json),
              mode: v0parseMode(json),
              query: tmp,
              normBy: Json_decode.optional((function (param) {
                      return Json_decode.field("normBy", Json_decode.string, param);
                    }), json),
              columnOrder: Json_decode.optional((function (param) {
                      return Json_decode.field("columnOrder", (function (param) {
                                    return Json_decode.array(Json_decode.string, param);
                                  }), param);
                    }), json),
              columnWidth: Json_decode.optional((function (param) {
                      return Json_decode.field("columnWidth", (function (param) {
                                    return Json_decode.array(BtTableColumnWidth$BsConsole.ofJson, param);
                                  }), param);
                    }), json),
              view: BtTableSessionData$BsConsole.viewFromOptionalString(Json_decode.optional((function (param) {
                          return Json_decode.field("view", Json_decode.string, param);
                        }), json))
            }]);
}

function fromJsonV1Decoder(json) {
  var type_ = Json_decode.field("type", Json_decode.string, json);
  switch (type_) {
    case "aggregate" :
        return /* Aggregate */Block.__(2, [{
                    username: Json_decode.field("username", Json_decode.string, json),
                    version: /* V1 */1,
                    name: Json_decode.field("name", Json_decode.string, json),
                    factor: Json_decode.field("factor", Crdb$BsConsole.Factor.fromJson, json),
                    fold: Json_decode.field("fold", Crdb$BsConsole.Fold.fromJson, json)
                  }]);
    case "dashboard" :
        return /* Dashboard */Block.__(0, [{
                    username: Json_decode.field("username", Json_decode.string, json),
                    version: /* V1 */1,
                    name: Json_decode.field("name", Json_decode.string, json),
                    mode: modeOfStringExn(Json_decode.field("mode", Json_decode.string, json)),
                    query: Json_decode.field("query", (function (json) {
                            var query = Curry._1(Crdb$BsConsole.Query.fromJson, json);
                            var currentSort = Curry._1(Crdb$BsConsole.Query.getSort, query);
                            if (currentSort !== undefined) {
                              return query;
                            } else if (query.tag) {
                              return Curry._2(Crdb$BsConsole.Query.setSort, Crdb$BsConsole.Sort.defaultSelect, query);
                            } else {
                              return Curry._2(Crdb$BsConsole.Query.setSort, Crdb$BsConsole.Sort.defaultAggregate, query);
                            }
                          }), json),
                    normBy: Json_decode.optional((function (param) {
                            return Json_decode.field("normBy", Json_decode.string, param);
                          }), json),
                    columnOrder: Json_decode.optional((function (param) {
                            return Json_decode.field("columnOrder", (function (param) {
                                          return Json_decode.array(Json_decode.string, param);
                                        }), param);
                          }), json),
                    columnWidth: Json_decode.optional((function (param) {
                            return Json_decode.field("columnWidth", (function (param) {
                                          return Json_decode.array(BtTableColumnWidth$BsConsole.ofJson, param);
                                        }), param);
                          }), json),
                    view: BtTableSessionData$BsConsole.viewFromOptionalString(Json_decode.optional((function (param) {
                                return Json_decode.field("view", Json_decode.string, param);
                              }), json))
                  }]);
    case "filter" :
        return /* Filter */Block.__(1, [{
                    username: Json_decode.field("username", Json_decode.string, json),
                    version: /* V1 */1,
                    name: Json_decode.field("name", Json_decode.string, json),
                    filters: Json_decode.field("filters", Crdb$BsConsole.Filters.fromJson, json)
                  }]);
    case "select" :
        return /* Select */Block.__(3, [{
                    username: Json_decode.field("username", Json_decode.string, json),
                    version: /* V1 */1,
                    name: Json_decode.field("name", Json_decode.string, json),
                    select: Json_decode.field("select", Crdb$BsConsole.Select.fromJson, json)
                  }]);
    default:
      return ;
  }
}

function fromJsonV1DecoderSafe(json) {
  try {
    return fromJsonV1Decoder(json);
  }
  catch (exn){
    return ;
  }
}

function fromJsonV1(json) {
  return Json_decode.field("payload", (function (json$prime) {
                return fromJsonV1Decoder(JSON.parse(Json_decode.string(json$prime)));
              }), json);
}

function fromJson(json) {
  try {
    return Json_decode.oneOf(/* :: */[
                fromJsonV1,
                /* :: */[
                  fromJsonV0,
                  /* [] */0
                ]
              ], json);
  }
  catch (exn){
    return ;
  }
}

function fromCRDB(query) {
  return fromJson(BacktraceParserGenerator$BsConsole.Query.to_json(query));
}

function toJson(t$prime) {
  switch (t$prime.tag | 0) {
    case /* Dashboard */0 :
        var args = t$prime[0];
        var __x = args.columnOrder;
        var __x$1 = args.columnWidth;
        var __x$2 = Belt_Option.map(args.view, BtTableSessionData$BsConsole.viewToString);
        var formList_000 = /* tuple */[
          "type",
          "dashboard"
        ];
        var formList_001 = /* :: */[
          /* tuple */[
            "version",
            args.version ? "v1" : "v0"
          ],
          /* :: */[
            /* tuple */[
              "username",
              args.username
            ],
            /* :: */[
              /* tuple */[
                "name",
                args.name
              ],
              /* :: */[
                /* tuple */[
                  "mode",
                  modeToString(args.mode)
                ],
                /* :: */[
                  /* tuple */[
                    "query",
                    Curry._1(Crdb$BsConsole.Query.toJson, args.query)
                  ],
                  /* :: */[
                    /* tuple */[
                      "columnOrder",
                      Json_encode.nullable((function (param) {
                              return Json_encode.array((function (prim) {
                                            return prim;
                                          }), param);
                            }), __x)
                    ],
                    /* :: */[
                      /* tuple */[
                        "columnWidth",
                        Json_encode.nullable((function (param) {
                                return Json_encode.array(BtTableColumnWidth$BsConsole.toJson, param);
                              }), __x$1)
                      ],
                      /* :: */[
                        /* tuple */[
                          "view",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), __x$2)
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ];
        var formList = /* :: */[
          formList_000,
          formList_001
        ];
        var metricGroup = args.normBy;
        return Json_encode.object_(metricGroup !== undefined ? Belt_List.concat(formList, /* :: */[
                          /* tuple */[
                            "normBy",
                            metricGroup
                          ],
                          /* [] */0
                        ]) : formList);
    case /* Filter */1 :
        var args$1 = t$prime[0];
        return Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "filter"
                    ],
                    /* :: */[
                      /* tuple */[
                        "version",
                        args$1.version ? "v1" : "v0"
                      ],
                      /* :: */[
                        /* tuple */[
                          "name",
                          args$1.name
                        ],
                        /* :: */[
                          /* tuple */[
                            "username",
                            args$1.username
                          ],
                          /* :: */[
                            /* tuple */[
                              "filters",
                              Curry._1(Crdb$BsConsole.Filters.toJson, args$1.filters)
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]);
    case /* Aggregate */2 :
        var args$2 = t$prime[0];
        return Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "aggregate"
                    ],
                    /* :: */[
                      /* tuple */[
                        "version",
                        args$2.version ? "v1" : "v0"
                      ],
                      /* :: */[
                        /* tuple */[
                          "name",
                          args$2.name
                        ],
                        /* :: */[
                          /* tuple */[
                            "factor",
                            Curry._1(Crdb$BsConsole.Factor.toJson, args$2.factor)
                          ],
                          /* :: */[
                            /* tuple */[
                              "username",
                              args$2.username
                            ],
                            /* :: */[
                              /* tuple */[
                                "fold",
                                Curry._1(Crdb$BsConsole.Fold.toJson, args$2.fold)
                              ],
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]);
    case /* Select */3 :
        var args$3 = t$prime[0];
        return Json_encode.object_(/* :: */[
                    /* tuple */[
                      "type",
                      "select"
                    ],
                    /* :: */[
                      /* tuple */[
                        "version",
                        args$3.version ? "v1" : "v0"
                      ],
                      /* :: */[
                        /* tuple */[
                          "name",
                          args$3.name
                        ],
                        /* :: */[
                          /* tuple */[
                            "username",
                            args$3.username
                          ],
                          /* :: */[
                            /* tuple */[
                              "select",
                              Curry._1(Crdb$BsConsole.Select.toJson, args$3.select)
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]);
    
  }
}

function getRouteForSavedQuery(savedQuery, projectName) {
  var mode = getMode(savedQuery);
  setColumnOrderAndWidth(savedQuery, projectName);
  var match = getQuery(savedQuery);
  switch (mode) {
    case /* Inbox */0 :
        if (!match.tag) {
          var normBy;
          normBy = savedQuery.tag ? undefined : savedQuery[0].normBy;
          return /* ProjectTriage */Block.__(11, [
                    projectName,
                    Route$BsConsole.getInboxParams(projectName, Caml_option.some(match[0]), match[5], undefined, Caml_option.some(match[2]), match[3], normBy, undefined, undefined)
                  ]);
        }
        break;
    case /* Aggregate */1 :
        if (!match.tag) {
          var factor = match[1];
          if (factor !== undefined) {
            var normBy$1;
            normBy$1 = savedQuery.tag ? undefined : savedQuery[0].normBy;
            return Route$BsConsole.ProjectQueryBuilder.withDefaults(projectName, Caml_option.some(match[0]), Caml_option.some(Belt_Option.getWithDefault(match[5], Crdb$BsConsole.Havings.empty)), factor, Caml_option.some(BtTableSessionData$BsConsole.removeFoldAggs(BtTableSessionData$BsConsole.exploreAggsToRemove, match[2])), Util$BsConsole.optDefault(Crdb$BsConsole.Sort.defaultAggregate, match[3]), Caml_option.some(normBy$1), undefined, undefined);
          }
          
        }
        break;
    case /* Instance */2 :
        if (match.tag) {
          return /* ProjectInstances */Block.__(15, [
                    projectName,
                    {
                      aperture: match[0],
                      select: match[1],
                      sort: Util$BsConsole.optDefault(Crdb$BsConsole.Sort.defaultSelect, match[2])
                    }
                  ]);
        }
        break;
    
  }
  throw [
        Caml_builtin_exceptions.assert_failure,
        /* tuple */[
          "SavedQueriesModel.re",
          641,
          39
        ]
      ];
}

var emptyProjectSettings_bookmarkedViews = [];

var emptyProjectSettings = {
  projectDefault: undefined,
  bookmarkedViews: emptyProjectSettings_bookmarkedViews,
  versionAttribute: undefined,
  normBy: undefined
};

function fetchProjectDefaultAndBookmarkedViewsQuery(token, pid, cb) {
  var arg = BpgTask$BsConsole.ProjectFrontendSettings.fetchAll;
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), true, (function (allProjSettings) {
                if (allProjSettings.tag) {
                  return Curry._1(cb, emptyProjectSettings);
                }
                var myProjSettingsBpg = Belt_Array.getBy(allProjSettings[0], (function (s) {
                        return s.pid === pid;
                      }));
                if (myProjSettingsBpg === undefined) {
                  return Curry._1(cb, emptyProjectSettings);
                }
                var settings = Belt_Option.mapWithDefault(Json.parse(myProjSettingsBpg.json), ProjectFrontendSettings$BsConsole.empty, ProjectFrontendSettings$BsConsole.ofJson);
                var projectDefaultQuery = Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.map(settings.defaultView, (function (defaultView) {
                                return defaultView.savedQueryJsonString;
                              })), Json.parse), fromJsonV1DecoderSafe);
                return Curry._1(cb, {
                            projectDefault: projectDefaultQuery,
                            bookmarkedViews: settings.bookmarkedViews,
                            versionAttribute: settings.versionAttribute,
                            normBy: settings.normBy
                          });
              }));
}

function fetchUserDefaultQuery(token, projectName, uid, cb) {
  var arg = BpgTask$BsConsole.UserFrontendSettings.fetchAll;
  Curry._2((function (param) {
            return (function (param$1, param$2) {
                return Task2$BsConsole.handle(param, arg, param$1, param$2);
              });
          })(token), true, (function (allUserSettings) {
          if (allUserSettings.tag) {
            Curry._1(cb, /* tuple */[
                  undefined,
                  []
                ]);
          } else {
            var myUserSettingsBpg = Belt_Array.getBy(allUserSettings[0], (function (s) {
                    return s.owner === uid;
                  }));
            if (myUserSettingsBpg !== undefined) {
              var parsedSettings = Json.parse(myUserSettingsBpg.json);
              var settings = Belt_Option.map(parsedSettings, UserFrontendSettings$BsConsole.ofJson);
              var userDefaultQuery = Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(settings, (function (s) {
                                  return s.projectDefaultViews;
                                })), (function (defaultViews) {
                              return Js_dict.get(defaultViews, projectName);
                            })), (function (defaultView) {
                          return Json.parse(defaultView.savedQueryJsonString);
                        })), fromJsonV1DecoderSafe);
              var bookmarkedViews = Belt_Option.getWithDefault(Belt_Option.flatMap(settings, (function (s) {
                          return Js_dict.get(s.bookmarkedViews, projectName);
                        })), []);
              Curry._1(cb, /* tuple */[
                    userDefaultQuery,
                    bookmarkedViews
                  ]);
            } else {
              Curry._1(cb, /* tuple */[
                    undefined,
                    []
                  ]);
            }
          }
          
        }));
  
}

function useRefetchableProjectDefaultQueryAndBookmarkedViews(token, pid) {
  var match = React.useState((function () {
          return emptyProjectSettings;
        }));
  var setValue = match[1];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setKey = match$1[1];
  var refetch = function (param) {
    return Curry._1(setKey, (function (key) {
                  return key + 1 | 0;
                }));
  };
  React.useEffect((function () {
          if (pid !== undefined) {
            fetchProjectDefaultAndBookmarkedViewsQuery(token, pid, (function (projectDefault) {
                    Curry._1(setValue, (function (param) {
                            return projectDefault;
                          }));
                    
                  }));
          }
          
        }), /* tuple */[
        match$1[0],
        token,
        pid
      ]);
  return /* tuple */[
          match[0],
          refetch
        ];
}

function useRefetchableUserDefaultQueryAndBookmarkedViews(token, projectName, uid) {
  var match = React.useState((function () {
          return /* tuple */[
                  undefined,
                  []
                ];
        }));
  var setValue = match[1];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setKey = match$1[1];
  var refetch = function (param) {
    return Curry._1(setKey, (function (key) {
                  return key + 1 | 0;
                }));
  };
  React.useEffect((function () {
          fetchUserDefaultQuery(token, projectName, uid, (function (userDefault) {
                  Curry._1(setValue, (function (param) {
                          return userDefault;
                        }));
                  
                }));
          
        }), /* tuple */[
        match$1[0],
        token,
        uid,
        projectName
      ]);
  return /* tuple */[
          match[0],
          refetch
        ];
}

var cache = Belt_HashMapString.make(100);

function userDefaultFromConfig(config, projectName) {
  var cachedUserDefault = Belt_HashMapString.get(cache, "user_default_" + projectName);
  if (cachedUserDefault !== undefined) {
    return Caml_option.valFromOption(cachedUserDefault);
  } else {
    return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(config.userSettings, (function (userSettings) {
                              return userSettings.projectDefaultViews;
                            })), (function (projectDefaultViews) {
                          return Js_dict.get(projectDefaultViews, projectName);
                        })), (function (m) {
                      return Json.parse(m.savedQueryJsonString);
                    })), fromJsonV1DecoderSafe);
  }
}

function projectDefaultFromConfig(config, projectName) {
  var cachedProjectDefault = Belt_HashMapString.get(cache, "project_default_" + projectName);
  if (cachedProjectDefault !== undefined) {
    return Caml_option.valFromOption(cachedProjectDefault);
  } else {
    return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_List.getBy(config.projectsSettings, (function (param) {
                              return param[0] === projectName;
                            })), (function (param) {
                          return param[1].defaultView;
                        })), (function (defaultView) {
                      return Json.parse(defaultView.savedQueryJsonString);
                    })), fromJsonV1DecoderSafe);
  }
}

function getRouteForUserDefault(config, projectName) {
  var cachedUserDefault = Belt_HashMapString.get(cache, "user_default_" + projectName);
  if (cachedUserDefault !== undefined) {
    return Belt_Option.map(Caml_option.valFromOption(cachedUserDefault), (function (cachedDefault) {
                  return getRouteForSavedQuery(cachedDefault, projectName);
                }));
  } else {
    return Belt_Option.map(userDefaultFromConfig(config, projectName), (function (m) {
                  return getRouteForSavedQuery(m, projectName);
                }));
  }
}

function getRouteForProjectDefault(config, projectName) {
  var cachedProjectDefault = Belt_HashMapString.get(cache, "project_default_" + projectName);
  if (cachedProjectDefault !== undefined) {
    return Belt_Option.map(Caml_option.valFromOption(cachedProjectDefault), (function (cachedDefault) {
                  return getRouteForSavedQuery(cachedDefault, projectName);
                }));
  } else {
    return Belt_Option.map(projectDefaultFromConfig(config, projectName), (function (m) {
                  return getRouteForSavedQuery(m, projectName);
                }));
  }
}

function makeProjectDefault(token, savedView, pid, projectName, cb) {
  return ProjectFrontendSettings$BsConsole.updateOrCreate(token, pid, (function (settings) {
                var savedQueryJsonString = JSON.stringify(toJson(savedView));
                var newValue = {
                  savedQueryJsonString: savedQueryJsonString
                };
                if (settings !== undefined) {
                  return {
                          defaultView: newValue,
                          bookmarkedViews: settings.bookmarkedViews,
                          serviceOverrides: settings.serviceOverrides,
                          versionAttribute: settings.versionAttribute,
                          normBy: settings.normBy,
                          unityCDA: settings.unityCDA,
                          firstTimeExperience: settings.firstTimeExperience
                        };
                } else {
                  return {
                          defaultView: newValue,
                          bookmarkedViews: ProjectFrontendSettings$BsConsole.empty.bookmarkedViews,
                          serviceOverrides: ProjectFrontendSettings$BsConsole.empty.serviceOverrides,
                          versionAttribute: ProjectFrontendSettings$BsConsole.empty.versionAttribute,
                          normBy: ProjectFrontendSettings$BsConsole.empty.normBy,
                          unityCDA: ProjectFrontendSettings$BsConsole.empty.unityCDA,
                          firstTimeExperience: ProjectFrontendSettings$BsConsole.empty.firstTimeExperience
                        };
                }
              }), (function (param) {
                var key = "project_default_" + projectName;
                Belt_HashMapString.set(cache, key, savedView);
                return Curry._1(cb, undefined);
              }));
}

function makeUserDefault(token, config, projectName, savedView, uid, cb) {
  return UserFrontendSettings$BsConsole.updateOrCreate(token, uid, TeamsUtils$BsConsole.isGuest(config), (function (settings) {
                var savedQueryJsonString = JSON.stringify(toJson(savedView));
                var newDictValue = {
                  savedQueryJsonString: savedQueryJsonString
                };
                if (settings !== undefined) {
                  var defaultViews = settings.projectDefaultViews;
                  var newDefaultViews;
                  if (defaultViews !== undefined) {
                    var defaultViews$1 = Caml_option.valFromOption(defaultViews);
                    defaultViews$1[projectName] = newDictValue;
                    newDefaultViews = defaultViews$1;
                  } else {
                    newDefaultViews = Js_dict.fromList(/* :: */[
                          /* tuple */[
                            projectName,
                            newDictValue
                          ],
                          /* [] */0
                        ]);
                  }
                  return {
                          projectDefaultViews: Caml_option.some(newDefaultViews),
                          bookmarkedViews: settings.bookmarkedViews,
                          reopenLatestSelectedAttribute: settings.reopenLatestSelectedAttribute,
                          reopenLatestSelectedType: settings.reopenLatestSelectedType,
                          reopenLatestAttributeValues: settings.reopenLatestAttributeValues,
                          reopenLatestSelectedTimePresent: settings.reopenLatestSelectedTimePresent,
                          unityCDA: settings.unityCDA,
                          firstTimeExperience: settings.firstTimeExperience,
                          highlightbarAttributes: settings.highlightbarAttributes,
                          debugViewToggles: settings.debugViewToggles,
                          languageCode: settings.languageCode
                        };
                }
                var newDefaultViews$1 = Js_dict.fromList(/* :: */[
                      /* tuple */[
                        projectName,
                        newDictValue
                      ],
                      /* [] */0
                    ]);
                return {
                        projectDefaultViews: Caml_option.some(newDefaultViews$1),
                        bookmarkedViews: UserFrontendSettings$BsConsole.empty.bookmarkedViews,
                        reopenLatestSelectedAttribute: UserFrontendSettings$BsConsole.empty.reopenLatestSelectedAttribute,
                        reopenLatestSelectedType: UserFrontendSettings$BsConsole.empty.reopenLatestSelectedType,
                        reopenLatestAttributeValues: UserFrontendSettings$BsConsole.empty.reopenLatestAttributeValues,
                        reopenLatestSelectedTimePresent: UserFrontendSettings$BsConsole.empty.reopenLatestSelectedTimePresent,
                        unityCDA: UserFrontendSettings$BsConsole.empty.unityCDA,
                        firstTimeExperience: UserFrontendSettings$BsConsole.empty.firstTimeExperience,
                        highlightbarAttributes: UserFrontendSettings$BsConsole.empty.highlightbarAttributes,
                        debugViewToggles: UserFrontendSettings$BsConsole.empty.debugViewToggles,
                        languageCode: UserFrontendSettings$BsConsole.empty.languageCode
                      };
              }), (function (param) {
                var key = "user_default_" + projectName;
                Belt_HashMapString.set(cache, key, savedView);
                return Curry._1(cb, undefined);
              }), (function (err) {
                var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SavedQueriesModel.re/makeUserDefault", Backtrace$BsConsole.Attributes.make(undefined));
                Backtrace$BsConsole.Client.send(__x, /* `string */[
                      288368849,
                      "Failed to update default view: " + err
                    ]);
                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to update default view"));
              }));
}

function clearUserDefault(token, config, uid, projectName, cb) {
  return UserFrontendSettings$BsConsole.updateOrCreate(token, uid, TeamsUtils$BsConsole.isGuest(config), (function (settings) {
                if (settings === undefined) {
                  return {
                          projectDefaultViews: undefined,
                          bookmarkedViews: UserFrontendSettings$BsConsole.empty.bookmarkedViews,
                          reopenLatestSelectedAttribute: UserFrontendSettings$BsConsole.empty.reopenLatestSelectedAttribute,
                          reopenLatestSelectedType: UserFrontendSettings$BsConsole.empty.reopenLatestSelectedType,
                          reopenLatestAttributeValues: UserFrontendSettings$BsConsole.empty.reopenLatestAttributeValues,
                          reopenLatestSelectedTimePresent: UserFrontendSettings$BsConsole.empty.reopenLatestSelectedTimePresent,
                          unityCDA: UserFrontendSettings$BsConsole.empty.unityCDA,
                          firstTimeExperience: UserFrontendSettings$BsConsole.empty.firstTimeExperience,
                          highlightbarAttributes: UserFrontendSettings$BsConsole.empty.highlightbarAttributes,
                          debugViewToggles: UserFrontendSettings$BsConsole.empty.debugViewToggles,
                          languageCode: UserFrontendSettings$BsConsole.empty.languageCode
                        };
                }
                var defaultViews = settings.projectDefaultViews;
                var newDefaultViews = defaultViews !== undefined ? Caml_option.some(Js_dict.fromList(Belt_List.removeAssoc(Belt_List.fromArray(Js_dict.entries(Caml_option.valFromOption(defaultViews))), projectName, (function (prim, prim$1) {
                                  return prim === prim$1;
                                })))) : undefined;
                return {
                        projectDefaultViews: newDefaultViews,
                        bookmarkedViews: settings.bookmarkedViews,
                        reopenLatestSelectedAttribute: settings.reopenLatestSelectedAttribute,
                        reopenLatestSelectedType: settings.reopenLatestSelectedType,
                        reopenLatestAttributeValues: settings.reopenLatestAttributeValues,
                        reopenLatestSelectedTimePresent: settings.reopenLatestSelectedTimePresent,
                        unityCDA: settings.unityCDA,
                        firstTimeExperience: settings.firstTimeExperience,
                        highlightbarAttributes: settings.highlightbarAttributes,
                        debugViewToggles: settings.debugViewToggles,
                        languageCode: settings.languageCode
                      };
              }), (function (param) {
                var key = "user_default_" + projectName;
                Belt_HashMapString.set(cache, key, undefined);
                return Curry._1(cb, undefined);
              }), (function (err) {
                var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SavedQueriesModel.re/clearUserDefault", Backtrace$BsConsole.Attributes.make(undefined));
                Backtrace$BsConsole.Client.send(__x, /* `string */[
                      288368849,
                      "Failed to clear default view: " + err
                    ]);
                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to clear default view"));
              }));
}

function clearProjectDefault(token, pid, projectName, cb) {
  return ProjectFrontendSettings$BsConsole.updateOrCreate(token, pid, (function (settings) {
                if (settings !== undefined) {
                  return {
                          defaultView: undefined,
                          bookmarkedViews: settings.bookmarkedViews,
                          serviceOverrides: settings.serviceOverrides,
                          versionAttribute: settings.versionAttribute,
                          normBy: settings.normBy,
                          unityCDA: settings.unityCDA,
                          firstTimeExperience: settings.firstTimeExperience
                        };
                } else {
                  return {
                          defaultView: undefined,
                          bookmarkedViews: ProjectFrontendSettings$BsConsole.empty.bookmarkedViews,
                          serviceOverrides: ProjectFrontendSettings$BsConsole.empty.serviceOverrides,
                          versionAttribute: ProjectFrontendSettings$BsConsole.empty.versionAttribute,
                          normBy: ProjectFrontendSettings$BsConsole.empty.normBy,
                          unityCDA: ProjectFrontendSettings$BsConsole.empty.unityCDA,
                          firstTimeExperience: ProjectFrontendSettings$BsConsole.empty.firstTimeExperience
                        };
                }
              }), (function (param) {
                var key = "project_default_" + projectName;
                Belt_HashMapString.set(cache, key, undefined);
                return Curry._1(cb, undefined);
              }));
}

function syncUserDefault(userDefaultModelFromFrontendSettings, config, token, projectName, pid, uid, cb) {
  var arg = BpgTask$BsConsole.Query.fetchAll;
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), undefined, (function (resp) {
                if (resp.tag) {
                  return ;
                }
                var userDefaultBpg = Belt_Array.getBy(Belt_Array.keep(resp[0], (function (q) {
                            return q.project === pid;
                          })), (function (query) {
                        if (query.name === getName(userDefaultModelFromFrontendSettings)) {
                          return true;
                        } else {
                          return query.name === "%dashboard% " + getName(userDefaultModelFromFrontendSettings);
                        }
                      }));
                if (userDefaultBpg !== undefined) {
                  Belt_Option.map(userDefaultBpg.payload, (function (userDefaultBpgJson) {
                          var userDefaultModelJson = JSON.stringify(toJson(userDefaultModelFromFrontendSettings));
                          if (userDefaultBpgJson === userDefaultModelJson) {
                            return ;
                          }
                          var newUserDefaultModel = fromJson(BacktraceParserGenerator$BsConsole.Query.to_json(userDefaultBpg));
                          var uid = userDefaultBpg.owner;
                          if (uid !== undefined) {
                            Belt_Option.map(newUserDefaultModel, (function (newUserDefaultModel) {
                                    return makeUserDefault(token, config, projectName, newUserDefaultModel, uid, (function (param) {
                                                  return Curry._1(cb, newUserDefaultModel);
                                                }));
                                  }));
                          }
                          
                        }));
                  return ;
                } else {
                  return clearUserDefault(token, config, uid, projectName, (function (param) {
                                return Curry._1(cb, undefined);
                              }));
                }
              }));
}

function savedQueriesTask(cacheKey, decodeResp, projectName, param) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/queries"]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "action",
                        "list"
                      ],
                      /* :: */[
                        /* tuple */[
                          "form",
                          Json_encode.object_(/* :: */[
                                /* tuple */[
                                  "expanded",
                                  true
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "extended",
                                    true
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "project",
                                      projectName
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ])
                        ],
                        /* [] */0
                      ]
                    ])]), undefined, decodeResp, cacheKey, undefined);
}

function fetchSavedQueries(token, projectName, cb) {
  var arg = savedQueriesTask(undefined, (function (param) {
          return /* Ok */Block.__(0, [Json_decode.field("response", (function (resp) {
                            return Json_decode.field(projectName, (function (param) {
                                          return Json_decode.list(fromJson, param);
                                        }), resp);
                          }), param.json)]);
        }), projectName, undefined);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), true, (function (savedQueries) {
                if (!savedQueries.tag) {
                  return Curry._1(cb, Belt_List.keepMap(savedQueries[0], Util$BsConsole.identity));
                }
                console.log("error fetching saved queries", savedQueries[0]);
                return Curry._1(cb, /* [] */0);
              }));
}

function useRefetchableSavedQueries(token, projectName) {
  var match = React.useState((function () {
          
        }));
  var setValue = match[1];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setKey = match$1[1];
  var refetch = function (param) {
    return Curry._1(setKey, (function (key) {
                  return key + 1 | 0;
                }));
  };
  React.useEffect((function () {
          fetchSavedQueries(token, projectName, (function (queries) {
                  Curry._1(setValue, (function (param) {
                          return queries;
                        }));
                  
                }));
          
        }), /* tuple */[
        match$1[0],
        token,
        projectName
      ]);
  return /* tuple */[
          match[0],
          refetch
        ];
}

function equal(sq$prime, sq$prime$prime) {
  return getName(sq$prime) === getName(sq$prime$prime);
}

var fromBPG = fromCRDB;

var fakeQuery = "{\"a\":{\"query\":{\"filter\":[{\"_tx\":[[\"at-least\",1]]}]}}}";

exports.getName = getName;
exports.getUsername = getUsername;
exports.getMode = getMode;
exports.setColumnOrderAndWidth = setColumnOrderAndWidth;
exports.getQuery = getQuery;
exports.v0parseMode = v0parseMode;
exports.v0parseSortOrder = v0parseSortOrder;
exports.v0parseSortAggregate = v0parseSortAggregate;
exports.v0parseSortInstance = v0parseSortInstance;
exports.v0parseSort = v0parseSort;
exports.v0ParseAperture = v0ParseAperture;
exports.v0ParseRelativeTime = v0ParseRelativeTime;
exports.v0ParseAbsoluteTime = v0ParseAbsoluteTime;
exports.v0ParseTime = v0ParseTime;
exports.v0ParseFactor = v0ParseFactor;
exports.v0ParseFold = v0ParseFold;
exports.v0ParseFiltersAggregate = v0ParseFiltersAggregate;
exports.v0ParseFiltersSelect = v0ParseFiltersSelect;
exports.v0ParseSelect = v0ParseSelect;
exports.versionToString = versionToString;
exports.modeToString = modeToString;
exports.modeToFriendlyString = modeToFriendlyString;
exports.modeOfStringExn = modeOfStringExn;
exports.fromJsonV0 = fromJsonV0;
exports.fromJsonV1Decoder = fromJsonV1Decoder;
exports.fromJsonV1DecoderSafe = fromJsonV1DecoderSafe;
exports.fromJsonV1 = fromJsonV1;
exports.fromJson = fromJson;
exports.fromCRDB = fromCRDB;
exports.fromBPG = fromBPG;
exports.toJson = toJson;
exports.fakeQuery = fakeQuery;
exports.getRouteForSavedQuery = getRouteForSavedQuery;
exports.emptyProjectSettings = emptyProjectSettings;
exports.fetchProjectDefaultAndBookmarkedViewsQuery = fetchProjectDefaultAndBookmarkedViewsQuery;
exports.fetchUserDefaultQuery = fetchUserDefaultQuery;
exports.useRefetchableProjectDefaultQueryAndBookmarkedViews = useRefetchableProjectDefaultQueryAndBookmarkedViews;
exports.useRefetchableUserDefaultQueryAndBookmarkedViews = useRefetchableUserDefaultQueryAndBookmarkedViews;
exports.cache = cache;
exports.userDefaultFromConfig = userDefaultFromConfig;
exports.projectDefaultFromConfig = projectDefaultFromConfig;
exports.getRouteForUserDefault = getRouteForUserDefault;
exports.getRouteForProjectDefault = getRouteForProjectDefault;
exports.makeProjectDefault = makeProjectDefault;
exports.makeUserDefault = makeUserDefault;
exports.clearUserDefault = clearUserDefault;
exports.clearProjectDefault = clearProjectDefault;
exports.syncUserDefault = syncUserDefault;
exports.savedQueriesTask = savedQueriesTask;
exports.fetchSavedQueries = fetchSavedQueries;
exports.useRefetchableSavedQueries = useRefetchableSavedQueries;
exports.equal = equal;
/* cache Not a pure module */
