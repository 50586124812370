// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var AlertsUtils$BsConsole = require("./AlertsUtils.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Select = require("@material-ui/core/Select");
var Close = require("@material-ui/icons/Close");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var TextField = require("@material-ui/core/TextField");
var IconButton = require("@material-ui/core/IconButton");
var OutlinedInput = require("@material-ui/core/OutlinedInput");
var AlertsComparisonOperator$BsConsole = require("./AlertsComparisonOperator.js");

var triggerStyle = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
        /* :: */[
          Css.border(Css.px(1), Css.solid, Css.hex(Colors$BsConsole.grey3)),
          /* :: */[
            Css.borderRadius(Css.px(3)),
            /* :: */[
              Css.fontSize(Css.px(12)),
              /* :: */[
                Css.whiteSpace(/* nowrap */867913355),
                /* :: */[
                  Css.width(Css.px(100)),
                  /* :: */[
                    Css.textOverflow(/* ellipsis */166888785),
                    /* :: */[
                      Css.overflow(/* hidden */-862584982),
                      /* :: */[
                        Css.height(Css.px(32)),
                        /* :: */[
                          Css.marginRight(Css.px(8)),
                          /* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.justifyContent(/* center */98248149),
                              /* :: */[
                                Css.alignItems(/* center */98248149),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var chipStyle = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
        /* :: */[
          Css.border(Css.px(1), Css.solid, Css.hex(Colors$BsConsole.grey3)),
          /* :: */[
            Css.borderRadius(Css.px(3)),
            /* :: */[
              Css.height(Css.px(32)),
              /* :: */[
                Css.width(Css.px(140)),
                /* :: */[
                  Css.marginRight(Css.px(8)),
                  /* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.justifyContent(/* spaceAround */-485895757),
                      /* :: */[
                        Css.alignItems(/* center */98248149),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var chipInnerStyle = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
        /* :: */[
          Css.fontSize(Css.px(12)),
          /* :: */[
            Css.height(Css.px(14)),
            /* :: */[
              Css.width(Css.px(100)),
              /* :: */[
                Css.whiteSpace(/* nowrap */867913355),
                /* :: */[
                  Css.textOverflow(/* ellipsis */166888785),
                  /* :: */[
                    Css.overflow(/* hidden */-862584982),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var chipStyleFirst = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
        /* :: */[
          Css.border(Css.px(1), Css.solid, Css.hex(Colors$BsConsole.grey3)),
          /* :: */[
            Css.borderRadius(Css.px(3)),
            /* :: */[
              Css.height(Css.px(32)),
              /* :: */[
                Css.width(Css.px(170)),
                /* :: */[
                  Css.marginRight(Css.px(8)),
                  /* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.justifyContent(/* spaceAround */-485895757),
                      /* :: */[
                        Css.alignItems(/* center */98248149),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var chipInnerStyleFirst = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
        /* :: */[
          Css.fontSize(Css.px(12)),
          /* :: */[
            Css.height(Css.px(14)),
            /* :: */[
              Css.width(Css.px(130)),
              /* :: */[
                Css.whiteSpace(/* nowrap */867913355),
                /* :: */[
                  Css.textOverflow(/* ellipsis */166888785),
                  /* :: */[
                    Css.overflow(/* hidden */-862584982),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function optionalTooltip(title) {
  if (title.length > 18) {
    return title;
  } else {
    return "";
  }
}

var dropdownStyle = {
  root: Css.merge(/* :: */[
        triggerStyle,
        /* :: */[
          Css.style(/* :: */[
                Css.important(Css.paddingLeft(Css.px(0))),
                /* :: */[
                  Css.border(Css.px(0), /* solid */12956715, /* transparent */582626130),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ])
};

function AlertsTrigger$Or(Props) {
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.marginRight(Css.px(19)),
                    /* [] */0
                  ])
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Button */10,
                  color: Colors$BsConsole.grey4,
                  children: I18N$BsConsole.show(undefined, "or")
                }));
}

var Or = {
  make: AlertsTrigger$Or
};

var thresholds = /* :: */[
  /* Warning */-685964740,
  /* :: */[
    /* Critical */141150399,
    /* [] */0
  ]
];

var placeholder = "Select attribute";

function AlertsTrigger(Props) {
  var triggerMaybe = Props.triggerMaybe;
  var triggerIndex = Props.triggerIndex;
  var updateTrigger = Props.updateTrigger;
  var removeTriggerCondition = Props.removeTriggerCondition;
  var allAttributes = Props.allAttributes;
  var allSelectedAttributes = Props.allSelectedAttributes;
  var hideCloseButton = Props.hideCloseButton;
  var attr = triggerMaybe !== undefined ? triggerMaybe.attribute + (" - " + triggerMaybe.operation) : placeholder;
  var attrTitle = attr === AlertsUtils$BsConsole.errorCount ? AlertsUtils$BsConsole.userFriendlyErrorCount : attr;
  return React.createElement(React.Fragment, undefined, triggerIndex === 0 ? React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    className: Css.style(/* :: */[
                          Css.marginBottom(Css.px(5)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Button */10,
                        className: Css.style(/* :: */[
                              Css.width(Css.px(170)),
                              /* :: */[
                                Css.marginRight(Css.px(10)),
                                /* [] */0
                              ]
                            ]),
                        color: Colors$BsConsole.grey4,
                        children: I18N$BsConsole.show(undefined, "Aggregation")
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Button */10,
                        className: Css.style(/* :: */[
                              Css.width(Css.px(168)),
                              /* :: */[
                                Css.marginRight(Css.px(10)),
                                /* [] */0
                              ]
                            ]),
                        color: Colors$BsConsole.grey4,
                        children: I18N$BsConsole.show(undefined, "Warning")
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Button */10,
                        className: Css.style(/* :: */[
                              Css.width(Css.px(168)),
                              /* [] */0
                            ]),
                        color: Colors$BsConsole.grey4,
                        children: I18N$BsConsole.show(undefined, "Critical")
                      })) : null, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* spaceBetween */516682146,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(5)),
                        /* [] */0
                      ]),
                  children: null
                }, triggerMaybe !== undefined ? (
                    triggerIndex === 0 ? React.createElement(Tooltip.default, {
                            children: React.createElement("div", {
                                  className: chipStyleFirst
                                }, React.createElement("div", {
                                      className: chipInnerStyleFirst
                                    }, I18N$BsConsole.showSkip(attrTitle))),
                            title: optionalTooltip(attrTitle)
                          }) : React.createElement(React.Fragment, undefined, React.createElement(AlertsTrigger$Or, { }), React.createElement(Tooltip.default, {
                                children: React.createElement("div", {
                                      className: chipStyle
                                    }, React.createElement("div", {
                                          className: chipInnerStyle
                                        }, I18N$BsConsole.showSkip(attrTitle))),
                                title: optionalTooltip(attrTitle)
                              }))
                  ) : React.createElement(React.Fragment, undefined, triggerIndex === 0 ? null : React.createElement(AlertsTrigger$Or, { }), React.createElement(Select.default, {
                            value: placeholder,
                            onChange: (function (e, param) {
                                var value = e.target.value;
                                return Curry._5(updateTrigger, Caml_option.some(value), Caml_option.some(value), 0, 0, undefined);
                              }),
                            input: React.createElement(OutlinedInput.default, {
                                  classes: {
                                    root: Css.merge(/* :: */[
                                          triggerStyle,
                                          /* :: */[
                                            Css.style(/* :: */[
                                                  Css.important(Css.paddingLeft(Css.px(0))),
                                                  /* :: */[
                                                    Css.important(Css.border(Css.px(0), /* solid */12956715, /* transparent */582626130)),
                                                    /* :: */[
                                                      Css.width(Css.px(triggerIndex === 0 ? 170 : 140)),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]),
                                            /* [] */0
                                          ]
                                        ])
                                  }
                                }),
                            renderValue: I18N$BsConsole.showSkip,
                            className: Css.style(/* :: */[
                                  Css.backgroundColor(/* transparent */582626130),
                                  /* :: */[
                                    Css.paddingLeft(Css.rem(0.75)),
                                    /* :: */[
                                      Css.height(Css.rem(2)),
                                      /* :: */[
                                        Css.width(Css.ch(17)),
                                        /* :: */[
                                          Css.marginRight(Css.px(8)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]),
                            children: Belt_List.toArray(Belt_List.map(Belt_List.keepMap(Belt_List.keepMap(allAttributes, (function (att) {
                                                if (Belt_List.has(att.aggregate, "unique", (function (a, b) {
                                                          return a === b;
                                                        }))) {
                                                  return att;
                                                }
                                                
                                              })), (function (att) {
                                            if (Belt_List.has(allSelectedAttributes, att.name, (function (a, b) {
                                                      return a === b;
                                                    }))) {
                                              return ;
                                            } else {
                                              return att;
                                            }
                                          })), (function (attr) {
                                        return React.createElement(MenuItem.default, {
                                                    value: attr.name,
                                                    children: I18N$BsConsole.showSkip(attr.name)
                                                  });
                                      })))
                          })), Belt_List.toArray(Belt_List.map(thresholds, (function (condition) {
                            return React.createElement(React.Fragment, {
                                        children: null,
                                        key: condition
                                      }, React.createElement(Select.default, {
                                            value: triggerMaybe !== undefined ? triggerMaybe.comparisonOperator : /* NoThreshold */2,
                                            disabled: triggerMaybe === undefined,
                                            onChange: (function (e, param) {
                                                var value = e.target.value;
                                                return Curry._5(updateTrigger, undefined, Caml_option.some(value), 0, 0, undefined);
                                              }),
                                            input: React.createElement(OutlinedInput.default, {
                                                  classes: dropdownStyle
                                                }),
                                            renderValue: (function (v) {
                                                return I18N$BsConsole.showSkip(AlertsComparisonOperator$BsConsole.toString(v));
                                              }),
                                            className: Css.style(/* :: */[
                                                  Css.backgroundColor(/* transparent */582626130),
                                                  /* :: */[
                                                    Css.paddingLeft(Css.rem(0.75)),
                                                    /* :: */[
                                                      Css.height(Css.rem(2)),
                                                      /* :: */[
                                                        Css.width(Css.ch(17)),
                                                        /* :: */[
                                                          Css.marginRight(Css.px(8)),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]),
                                            children: Belt_List.toArray(Belt_List.map(AlertsComparisonOperator$BsConsole.operatorOptions, (function (option) {
                                                        return React.createElement(MenuItem.default, {
                                                                    value: option,
                                                                    children: I18N$BsConsole.showSkip(AlertsComparisonOperator$BsConsole.toString(option))
                                                                  });
                                                      })))
                                          }), React.createElement(TextField.default, {
                                            autoFocus: true,
                                            disabled: triggerMaybe !== undefined ? triggerMaybe.comparisonOperator === /* NoThreshold */2 : true,
                                            placeholder: "",
                                            value: condition >= 141150399 ? (
                                                triggerMaybe !== undefined ? String(triggerMaybe.criticalThreshold) : String(0)
                                              ) : (
                                                triggerMaybe !== undefined ? String(triggerMaybe.warningThreshold) : String(0)
                                              ),
                                            color: "primary",
                                            type: "number",
                                            classes: {
                                              root: Css.style(/* :: */[
                                                    Css.width(Css.px(45)),
                                                    /* :: */[
                                                      Css.marginRight(Css.px(8)),
                                                      /* [] */0
                                                    ]
                                                  ])
                                            },
                                            inputProps: {
                                              min: 0
                                            },
                                            onChange: (function ($$event) {
                                                if (triggerMaybe === undefined) {
                                                  return ;
                                                }
                                                var value;
                                                try {
                                                  value = Caml_format.caml_int_of_string($$event.target.value);
                                                }
                                                catch (exn){
                                                  value = 0;
                                                }
                                                var match = condition >= 141150399 ? /* tuple */[
                                                    triggerMaybe.warningThreshold,
                                                    value
                                                  ] : /* tuple */[
                                                    value,
                                                    triggerMaybe.criticalThreshold
                                                  ];
                                                return Curry._5(updateTrigger, undefined, undefined, match[0], match[1], undefined);
                                              })
                                          }));
                          }))), hideCloseButton ? null : React.createElement(IconButton.default, {
                        onClick: (function (param) {
                            return Curry._1(removeTriggerCondition, undefined);
                          }),
                        children: React.createElement(Close.default, { })
                      })));
}

var chipWidth = 140;

var innerChipWidth = 100;

var triggerWidth = 168;

var marginBtm = 5;

var chipLength = 18;

var make = AlertsTrigger;

exports.chipWidth = chipWidth;
exports.innerChipWidth = innerChipWidth;
exports.triggerWidth = triggerWidth;
exports.marginBtm = marginBtm;
exports.triggerStyle = triggerStyle;
exports.chipStyle = chipStyle;
exports.chipInnerStyle = chipInnerStyle;
exports.chipStyleFirst = chipStyleFirst;
exports.chipInnerStyleFirst = chipInnerStyleFirst;
exports.chipLength = chipLength;
exports.optionalTooltip = optionalTooltip;
exports.dropdownStyle = dropdownStyle;
exports.Or = Or;
exports.thresholds = thresholds;
exports.placeholder = placeholder;
exports.make = make;
/* triggerStyle Not a pure module */
