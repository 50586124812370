// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function isDemoProject(projectName) {
  return projectName.startsWith("backtrace-demo-project");
}

function hasDemoProject(guideName) {
  switch (guideName) {
    case "NodeJS" :
    case "React" :
    case "React Native" :
    case "Unity" :
        return true;
    default:
      return false;
  }
}

exports.isDemoProject = isDemoProject;
exports.hasDemoProject = hasDemoProject;
/* No side effect */
