// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("../I18N.js");
var TeamsUtils$BsConsole = require("../teams/TeamsUtils.js");
var TokenContext$BsConsole = require("../context/TokenContext.js");
var DeleteObjects$BsConsole = require("../DeleteObjects.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Divider = require("@material-ui/core/Divider");
var Popover = require("@material-ui/core/Popover");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var MoreVert = require("@material-ui/icons/MoreVert");
var BugMissingSymbols$BsConsole = require("./BugMissingSymbols.js");
var IconButton = require("@material-ui/core/IconButton");

var expandIcon = Css.style(/* :: */[
      Css.fontSize(Css.px(18)),
      /* :: */[
        Css.marginLeft(Css.auto),
        /* :: */[
          Css.marginRight(Css.px(-3)),
          /* :: */[
            Css.unsafe("color", "inherit"),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles = {
  expandIcon: expandIcon
};

function BugMultiLevelMenu$ParentWrapper(Props) {
  var condition = Props.condition;
  var wrapper = Props.wrapper;
  var children = Props.children;
  if (condition) {
    return Curry._1(wrapper, children);
  } else {
    return children;
  }
}

var ParentWrapper = {
  make: BugMultiLevelMenu$ParentWrapper
};

function BugMultiLevelMenu(Props) {
  var config = Props.config;
  var missingSymbols = Props.missingSymbols;
  Props.activeUser;
  var errorId = Props.errorId;
  var onReprocessObject = Props.onReprocessObject;
  var projectName = Props.projectName;
  var isCompressed = Props.isCompressed;
  var token = Belt_Option.getExn(React.useContext(TokenContext$BsConsole.ctx));
  var match = React.useState((function () {
          
        }));
  var setMenuAnchor = match[1];
  var menuAnchor = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setModal = match$1[1];
  var modal = match$1[0];
  var match$2 = React.useState((function () {
          return false;
        }));
  var setShowMissingSymbols = match$2[1];
  var isAdmin = TeamsUtils$BsConsole.isAdmin(config, projectName);
  var shouldReprocess = isCompressed === 1 ? true : false;
  if (isAdmin) {
    return React.createElement(React.Fragment, undefined, React.createElement(Tooltip.default, {
                    enterDelay: 250,
                    children: React.createElement(IconButton.default, {
                          onClick: (function (e) {
                              return Curry._1(setMenuAnchor, (function (param) {
                                            return e.target;
                                          }));
                            }),
                          children: React.createElement("span", {
                                className: Css.style(/* :: */[
                                      Css.width(Css.px(20)),
                                      /* :: */[
                                        Css.height(Css.px(20)),
                                        /* :: */[
                                          Css.fontSize(Css.px(20)),
                                          /* [] */0
                                        ]
                                      ]
                                    ])
                              }, React.createElement(MoreVert.default, {
                                    fontSize: "inherit",
                                    viewBox: "1 1 22 22"
                                  }))
                        }),
                    title: "Advanced"
                  }), React.createElement(Popover.default, {
                    anchorEl: menuAnchor,
                    open: Belt_Option.isSome(menuAnchor),
                    classes: {
                      paper: Css.style(/* :: */[
                            Css.overflow(Css.visible),
                            /* :: */[
                              Css.borderRadius(Css.zero),
                              /* [] */0
                            ]
                          ])
                    },
                    onClose: (function (param) {
                        return Curry._1(setMenuAnchor, (function (param) {
                                      
                                    }));
                      }),
                    children: isAdmin || (
                      missingSymbols !== undefined ? missingSymbols.length !== 0 : false
                    ) ? React.createElement(React.Fragment, undefined, React.createElement(Divider.default, { }), React.createElement(MenuList.default, {
                                children: null
                              }, isAdmin ? React.createElement(React.Fragment, undefined, React.createElement(BugMultiLevelMenu$ParentWrapper, {
                                          condition: shouldReprocess,
                                          wrapper: (function (children) {
                                              return React.createElement(Tooltip.default, {
                                                          placement: "left-start",
                                                          enterDelay: 250,
                                                          children: React.createElement("span", undefined, children),
                                                          title: I18N$BsConsole.get(undefined, "This object is compressed per retention policy and cannot be reprocessed")
                                                        });
                                            }),
                                          children: React.createElement(MenuItem.default, {
                                                disabled: shouldReprocess,
                                                onClick: (function (param) {
                                                    Curry._1(setMenuAnchor, (function (param) {
                                                            
                                                          }));
                                                    return Curry._1(onReprocessObject, undefined);
                                                  }),
                                                children: I18N$BsConsole.show(undefined, "Reprocess object")
                                              })
                                        }), React.createElement(MenuItem.default, {
                                          onClick: (function (param) {
                                              return Curry._1(setModal, (function (param) {
                                                            return /* DeleteForever */Block.__(0, [errorId]);
                                                          }));
                                            }),
                                          children: I18N$BsConsole.show(undefined, "Delete object")
                                        }), modal !== undefined ? React.createElement(DeleteObjects$BsConsole.make, {
                                            variant: modal,
                                            token: token,
                                            onClose: (function (param) {
                                                return Curry._1(setModal, (function (param) {
                                                              
                                                            }));
                                              }),
                                            onSuccess: (function (param) {
                                                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Object successfully deleted"));
                                                window.location.reload(true);
                                                return Curry._1(setModal, (function (param) {
                                                              
                                                            }));
                                              }),
                                            projectName: projectName
                                          }) : null) : null, missingSymbols !== undefined && missingSymbols.length !== 0 ? React.createElement(MenuItem.default, {
                                      onClick: (function (param) {
                                          Curry._1(setMenuAnchor, (function (param) {
                                                  
                                                }));
                                          return Curry._1(setShowMissingSymbols, (function (param) {
                                                        return true;
                                                      }));
                                        }),
                                      children: I18N$BsConsole.show(undefined, "Missing symbols")
                                    }) : null)) : null
                  }), missingSymbols !== undefined && missingSymbols.length !== 0 ? React.createElement(BugMissingSymbols$BsConsole.make, {
                      config: config,
                      open_: match$2[0],
                      onClose: (function (param) {
                          return Curry._1(setShowMissingSymbols, (function (param) {
                                        return false;
                                      }));
                        }),
                      missingSymbols: missingSymbols
                    }) : null);
  } else {
    return null;
  }
}

var make = BugMultiLevelMenu;

exports.Styles = Styles;
exports.ParentWrapper = ParentWrapper;
exports.make = make;
/* expandIcon Not a pure module */
