// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var ApiList$BsConsole = require("./apiList.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var Menu = require("@material-ui/core/Menu");
var BtTypography$BsConsole = require("./BtTypography.js");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var IconButton = require("@material-ui/core/IconButton");
var AttachFile = require("@material-ui/icons/AttachFile");

var ParseError = Caml_exceptions.create("FileAttachment-BsConsole.ParseError");

function makeTask(id, projectName) {
  return Task2$BsConsole.make(/* Api */Block.__(0, ["/list?project=" + (String(projectName) + ("&object=" + (String(id) + "")))]), undefined, /* Get */0, undefined, (function (param) {
                try {
                  return /* Ok */Block.__(0, [ApiList$BsConsole.decodeExn(param.json)]);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  var error = Uncaught$BsConsole.castToJsExn(err);
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "FileAttachment/makeTask", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `exn */[
                        5049499,
                        error
                      ]);
                  return /* Error */Block.__(1, [[
                              ParseError,
                              error
                            ]]);
                }
              }), undefined, undefined);
}

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === Task2$BsConsole.CoronerError) {
    tmp = error[1].message;
  } else if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                /* String_literal */Block.__(11, [
                    "ParseError ",
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* End_of_format */0
                      ])
                  ]),
                "ParseError %s"
              ]), message) : I18N$BsConsole.get(undefined, "ParseError: There was an error parsing data");
  } else {
    tmp = I18N$BsConsole.get(undefined, "Error: Something went wrong. This error has been reported to Backtrace.");
  }
  return Curry._1(onFailure, tmp);
}

function use(id, projectName, token) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match[1];
  var remote = match[0];
  React.useEffect((function () {
          var onSuccess = function (resp) {
            return Curry._1(setRemote, (function (param) {
                          return /* Success */Block.__(0, [resp]);
                        }));
          };
          var onFailure = function (errorMessage) {
            return Curry._1(setRemote, (function (param) {
                          return /* Failure */Block.__(1, [errorMessage]);
                        }));
          };
          if (typeof remote === "number") {
            if (remote !== 0) {
              var arg = makeTask(id, projectName);
              Curry._2((function (param) {
                        return (function (param$1, param$2) {
                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                          });
                      })(token), undefined, (function (param) {
                      return responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              Curry._1(setRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          }
          
        }), [remote]);
  return remote;
}

function FileAttachment$List(Props) {
  var id = Props.id;
  var projectName = Props.projectName;
  var token = Props.token;
  var listRemote = use(id, projectName, token);
  var tmp;
  var exit = 0;
  if (typeof listRemote === "number") {
    exit = 1;
  } else if (listRemote.tag) {
    tmp = React.createElement(MenuItem.default, {
          children: React.createElement(BtTypography$BsConsole.make, {
                variant: /* Caption */11,
                children: I18N$BsConsole.showSkip(listRemote[0])
              })
        });
  } else {
    var files = listRemote[0];
    var match = Belt_List.length(files);
    tmp = match !== 0 ? Belt_Array.map(Belt_List.toArray(files), (function (file) {
              return React.createElement("a", {
                          href: "/api/get?object=" + (id + ("&token=" + (token + ("&project=" + (projectName + ("&attachment_id=" + (file.id + "&attachment_inline=false"))))))),
                          target: "_blank"
                        }, React.createElement(MenuItem.default, {
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Body2 */9,
                                    children: I18N$BsConsole.showSkip(file.name)
                                  })
                            }));
            })) : React.createElement(MenuItem.default, {
            children: React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  children: I18N$BsConsole.show(undefined, "No attachments")
                })
          });
  }
  if (exit === 1) {
    tmp = React.createElement(MenuItem.default, {
          children: React.createElement(BtTypography$BsConsole.make, {
                variant: /* Caption */11,
                children: I18N$BsConsole.show(undefined, "Loading...")
              })
        });
  }
  return React.createElement(MenuList.default, {
              children: tmp
            });
}

var List = {
  make: FileAttachment$List
};

function FileAttachment$IconButton(Props) {
  var id = Props.id;
  var projectName = Props.projectName;
  var token = Props.token;
  var disabledOpt = Props.disabled;
  var classNameOpt = Props.className;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  return React.createElement(React.Fragment, undefined, React.createElement(Tooltip.default, {
                  children: React.createElement(IconButton.default, {
                        disabled: disabled,
                        classes: {
                          root: className,
                          label: Css.style(/* :: */[
                                Css.unsafe("fontSize", "inherit"),
                                /* [] */0
                              ])
                        },
                        onClick: (function ($$event) {
                            var el = $$event.currentTarget;
                            if (!(el == null)) {
                              return Curry._1(setAnchorEl, (function (param) {
                                            return Caml_option.some(el);
                                          }));
                            }
                            
                          }),
                        children: React.createElement(AttachFile.default, {
                              color: "inherit",
                              fontSize: "inherit"
                            })
                      }),
                  title: I18N$BsConsole.get(undefined, "View file attachments")
                }), anchorEl !== undefined ? React.createElement(Menu.default, {
                    anchorEl: Caml_option.valFromOption(anchorEl),
                    open: true,
                    onClose: (function (param) {
                        return Curry._1(setAnchorEl, (function (param) {
                                      
                                    }));
                      }),
                    children: React.createElement(FileAttachment$List, {
                          id: id,
                          projectName: projectName,
                          token: token
                        })
                  }) : null);
}

var IconButton$1 = {
  make: FileAttachment$IconButton
};

exports.ParseError = ParseError;
exports.makeTask = makeTask;
exports.responseCb = responseCb;
exports.use = use;
exports.List = List;
exports.IconButton = IconButton$1;
/* Css Not a pure module */
