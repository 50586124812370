// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Colors$BsConsole = require("../Colors.js");

var inputStyle = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* [] */0
      ]
    ]);

var formStyle = Css.style(/* :: */[
      Css.width(Css.px(180)),
      /* :: */[
        Css.border(Css.px(0), /* none */-922086728, /* transparent */582626130),
        /* :: */[
          Css.height(Css.px(25)),
          /* :: */[
            Css.fontSize(Css.px(12)),
            /* :: */[
              Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey4)),
              /* :: */[
                Css.margin(Css.px(8)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var buttonBase = Css.style(/* :: */[
      Css.border(Css.px(0), /* none */-922086728, /* transparent */582626130),
      /* :: */[
        Css.borderRadius(Css.px(3)),
        /* :: */[
          Css.fontSize(Css.px(14)),
          /* :: */[
            Css.fontWeight(/* medium */-20425611),
            /* :: */[
              Css.padding(Css.px(10)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var container = Css.style(/* :: */[
      Css.width(Css.px(180)),
      /* :: */[
        Css.height(Css.px(80)),
        /* [] */0
      ]
    ]);

exports.inputStyle = inputStyle;
exports.formStyle = formStyle;
exports.buttonBase = buttonBase;
exports.container = container;
/* inputStyle Not a pure module */
