// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Link$BsConsole = require("../Link.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BugGrid$BsConsole = require("./BugGrid.js");
var BugTabs$BsConsole = require("./BugTabs.js");
var BugTasks$BsConsole = require("./data/BugTasks.js");
var Uncaught$BsConsole = require("../Uncaught.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var BugHeader$BsConsole = require("./BugHeader.js");
var BugReplay$BsConsole = require("./replay/BugReplay.js");
var BugSource$BsConsole = require("./BugSource.js");
var BugStyles$BsConsole = require("./BugStyles.js");
var BugMetrics$BsConsole = require("./BugMetrics.js");
var TeamsUtils$BsConsole = require("../teams/TeamsUtils.js");
var Tab = require("@material-ui/core/Tab");
var Tabs = require("@material-ui/core/Tabs");
var BugRegisters$BsConsole = require("./BugRegisters.js");
var BugVariables$BsConsole = require("./BugVariables.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var BugDefectView$BsConsole = require("./BugDefectView.js");
var Configuration$BsConsole = require("../configuration.js");
var RouterContext$BsConsole = require("../context/RouterContext.js");
var Button = require("@material-ui/core/Button");
var BugBreadcrumbs$BsConsole = require("./breadcrumbs/BugBreadcrumbs.js");
var BugDefectUtils$BsConsole = require("./data/BugDefectUtils.js");
var BugReplayUtils$BsConsole = require("./replay/BugReplayUtils.js");
var Tooltip = require("@material-ui/core/Tooltip");
var WindowVariables$BsConsole = require("../WindowVariables.js");
var Warning = require("@material-ui/icons/Warning");
var BugFetchedSource$BsConsole = require("./BugFetchedSource.js");
var Attribute_Helpers$BsConsole = require("../Attribute_Helpers.js");
var BugCrdbAttributes$BsConsole = require("./data/BugCrdbAttributes.js");
var IconButton = require("@material-ui/core/IconButton");
var BugRegistersSimple$BsConsole = require("./BugRegistersSimple.js");
var BugVariableDetails$BsConsole = require("./BugVariableDetails.js");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");
var BugBreadcrumbsUtils$BsConsole = require("./breadcrumbs/BugBreadcrumbsUtils.js");
var BugGenericCallstack$BsConsole = require("./BugGenericCallstack.js");
var UserFrontendSettings$BsConsole = require("../UserFrontendSettings.js");
var BugDefectSourceToggle$BsConsole = require("./BugDefectSourceToggle.js");
var BugGenericThreadSelect$BsConsole = require("./BugGenericThreadSelect.js");
var BugReprocessMissingSymbols$BsConsole = require("./BugReprocessMissingSymbols.js");

var inputStyle = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* [] */0
      ]
    ]);

var formStyle = Css.style(/* :: */[
      Css.width(Css.px(180)),
      /* :: */[
        Css.border(Css.px(0), /* none */-922086728, /* transparent */582626130),
        /* :: */[
          Css.height(Css.px(25)),
          /* :: */[
            Css.fontSize(Css.px(12)),
            /* :: */[
              Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey4)),
              /* :: */[
                Css.margin(Css.px(8)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var tabStyle = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontWeight(Css.medium),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.grey0)),
          /* :: */[
            Css.fontSize(Css.px(12)),
            /* :: */[
              Css.padding2(Css.zero, Css.px(8)),
              /* :: */[
                Css.height(Css.px(32)),
                /* :: */[
                  Css.flexShrink(0),
                  /* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.borderBottom(Css.px(2), Css.solid, Css.hex(BugStyles$BsConsole.Color.dividerTertiary)),
                      /* :: */[
                        Css.textTransform(Css.uppercase),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  inputStyle: inputStyle,
  formStyle: formStyle,
  tabStyle: tabStyle
};

function variablesViewToValue(v) {
  switch (v) {
    case /* Variables */0 :
        return "variables";
    case /* Defects */1 :
        return "defects";
    case /* SourceCode */2 :
        return "sourceCode";
    
  }
}

function valueToVariablesView(str) {
  switch (str) {
    case "defects" :
        return /* Defects */1;
    case "sourceCode" :
        return /* SourceCode */2;
    case "variables" :
        return /* Variables */0;
    default:
      return /* Variables */0;
  }
}

function getSelectedFrame(thread) {
  var faultingFrame = Belt_Option.flatMap(thread.stack, (function (stack) {
          return Caml_option.undefined_to_opt(stack.find((function (frame) {
                            return Caml_obj.caml_equal(frame.faulted, true);
                          })));
        }));
  if (faultingFrame !== undefined) {
    return faultingFrame;
  } else {
    return Belt_Option.flatMap(thread.stack, (function (t) {
                  return Belt_Array.get(t, 0);
                }));
  }
}

function getSelectedVariable(frame) {
  return Belt_Option.flatMap(frame.variables, (function (__x) {
                return Belt_Array.get(__x, 0);
              }));
}

function reducer(state, action) {
  switch (action.tag | 0) {
    case /* SelectThread */0 :
        var thread = action[0];
        var selectedFrame = getSelectedFrame(thread);
        var selectedVariable = Belt_Option.flatMap(selectedFrame, getSelectedVariable);
        return {
                selectedThread: thread,
                selectedFrame: selectedFrame,
                selectedVariable: selectedVariable,
                variablesView: state.variablesView
              };
    case /* SelectFrame */1 :
        var frame = action[0];
        var selectedVariable$1 = getSelectedVariable(frame);
        return {
                selectedThread: state.selectedThread,
                selectedFrame: frame,
                selectedVariable: selectedVariable$1,
                variablesView: state.variablesView
              };
    case /* JumpTo */2 :
        var frame$1 = action[1];
        var thread$1 = action[0];
        var selectedFrame$1 = frame$1 !== undefined ? frame$1 : getSelectedFrame(thread$1);
        var selectedVariable$2 = Belt_Option.flatMap(selectedFrame$1, getSelectedVariable);
        return {
                selectedThread: thread$1,
                selectedFrame: selectedFrame$1,
                selectedVariable: selectedVariable$2,
                variablesView: state.variablesView
              };
    case /* SelectVariable */3 :
        return {
                selectedThread: state.selectedThread,
                selectedFrame: state.selectedFrame,
                selectedVariable: action[0],
                variablesView: state.variablesView
              };
    case /* SetVariablesView */4 :
        return {
                selectedThread: state.selectedThread,
                selectedFrame: state.selectedFrame,
                selectedVariable: state.selectedVariable,
                variablesView: action[0]
              };
    
  }
}

function toString(param) {
  if (param) {
    return "Source Code";
  } else {
    return "Breadcrumbs";
  }
}

function gridReducer(state, action) {
  switch (action.tag | 0) {
    case /* SetThreadsWidth */0 :
        var threadSelectWidth = action[0];
        return {
                threadSelectWidth: threadSelectWidth,
                threadSelectHeight: state.threadSelectHeight,
                variablesWidth: state.variablesWidth + (state.threadSelectWidth - threadSelectWidth | 0) | 0,
                variableExploreHeight: state.variableExploreHeight
              };
    case /* SetThreadsHeight */1 :
        return {
                threadSelectWidth: state.threadSelectWidth,
                threadSelectHeight: action[0],
                variablesWidth: state.variablesWidth,
                variableExploreHeight: state.variableExploreHeight
              };
    case /* SetVariablesWidth */2 :
        return {
                threadSelectWidth: state.threadSelectWidth,
                threadSelectHeight: state.threadSelectHeight,
                variablesWidth: action[0],
                variableExploreHeight: state.variableExploreHeight
              };
    case /* SetVariableExploreHeight */3 :
        return {
                threadSelectWidth: state.threadSelectWidth,
                threadSelectHeight: state.threadSelectHeight,
                variablesWidth: state.variablesWidth,
                variableExploreHeight: action[0]
              };
    
  }
}

function BugGeneric(Props) {
  var config = Props.config;
  var token = Props.token;
  var onAddFilter = Props.onAddFilter;
  var initialSelection = Props.initialSelection;
  var crdbRow = Props.crdbRow;
  var aperture = Props.aperture;
  var error = Props.error;
  var w = Props.width;
  var h = Props.height;
  var hasRepositories = Props.hasRepositories;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var match = React.useContext(RouterContext$BsConsole.ctx);
  var handleReplaceUrl = match.handleReplaceUrl;
  var universeName = config.universe.name;
  var project = Uncaught$BsConsole.getOrThrow(Configuration$BsConsole.getProject(projectName, config), "Could not find project " + (projectName + " in config."));
  var match$1 = React.useState((function () {
          
        }));
  var setBreadbox = match$1[1];
  var breadbox = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setEventbox = match$2[1];
  var eventbox = match$2[0];
  var match$3 = React.useState((function () {
          return /* SourceCode */1;
        }));
  var setActiveTab = match$3[1];
  var activeTab = match$3[0];
  var match$4 = React.useState((function () {
          return Belt_Option.getWithDefault(Belt_Option.flatMap(config.userSettings, (function (us) {
                            return us.debugViewToggles;
                          })), UserFrontendSettings$BsConsole.DebuggerToggles.$$default);
        }));
  var setToggles = match$4[1];
  var toggles = match$4[0];
  var toggleIsLocalVarsVisbile = function (param) {
    var newVal = !toggles.showVariables;
    Curry._1(setToggles, (function (param) {
            return {
                    condenseFunctions: toggles.condenseFunctions,
                    showVariables: newVal,
                    showBugTabsDataRaw: toggles.showBugTabsDataRaw
                  };
          }));
    return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                /* DebuggerToggleShowVariables */Block.__(11, [newVal]),
                /* [] */0
              ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                  
                }), (function (err) {
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugGeneric.re/toggleIsLocalVarsVisible", Backtrace$BsConsole.Attributes.make(undefined));
                  return Backtrace$BsConsole.Client.send(__x, /* `string */[
                              288368849,
                              "Failed to store local variable toggle state: " + err
                            ]);
                }));
  };
  var toggleCondenseFunctions = function (param) {
    var newVal = !toggles.condenseFunctions;
    Curry._1(setToggles, (function (param) {
            return {
                    condenseFunctions: newVal,
                    showVariables: toggles.showVariables,
                    showBugTabsDataRaw: toggles.showBugTabsDataRaw
                  };
          }));
    return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                /* DebuggerToggleCondenseFunctions */Block.__(10, [newVal]),
                /* [] */0
              ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                  
                }), (function (err) {
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugGeneric.re/toggleCondenseFunctions", Backtrace$BsConsole.Attributes.make(undefined));
                  return Backtrace$BsConsole.Client.send(__x, /* `string */[
                              288368849,
                              "Failed to store condense functions toggle state: " + err
                            ]);
                }));
  };
  var toggleShowBugTabsDataRaw = function (param) {
    var newVal = !toggles.showBugTabsDataRaw;
    Curry._1(setToggles, (function (param) {
            return {
                    condenseFunctions: toggles.condenseFunctions,
                    showVariables: toggles.showVariables,
                    showBugTabsDataRaw: newVal
                  };
          }));
    return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                /* DebuggerToggleShowBugTabsDataRow */Block.__(12, [newVal]),
                /* [] */0
              ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                  
                }), (function (err) {
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugGeneric.re/toggleShowBugTabsDataRaw", Backtrace$BsConsole.Attributes.make(undefined));
                  return Backtrace$BsConsole.Client.send(__x, /* `string */[
                              288368849,
                              "Failed to store raw toggle state: " + err
                            ]);
                }));
  };
  var thread = Uncaught$BsConsole.getOrThrow(Belt_Array.get(error.threads, 0), "Thread array empty");
  var frame = getSelectedFrame(thread);
  var match$5 = Belt_Option.getWithDefault(error.faultingThread, /* tuple */[
        thread,
        frame
      ]);
  var frame$1 = match$5[1];
  var variable = Belt_Option.flatMap(frame$1, getSelectedVariable);
  var match$6 = Belt_Option.getWithDefault(Belt_Option.map(initialSelection, (function (param) {
              var variableIndex = param.variableIndex;
              var frameIndex = param.frameIndex;
              try {
                var thread = Uncaught$BsConsole.getOrThrow(Belt_Array.get(error.threads, param.threadIndex), "No thread found for index");
                var frame = frameIndex !== undefined ? Uncaught$BsConsole.getOrThrow(Belt_Array.get(Belt_Option.getWithDefault(thread.stack, []), frameIndex), "No frame found") : getSelectedFrame(thread);
                var variable = variableIndex !== undefined ? Belt_Option.map(Belt_Option.flatMap(frame, (function (v) {
                              return v.variables;
                            })), (function (vars) {
                          return Uncaught$BsConsole.getOrThrow(Belt_Array.get(vars, variableIndex), "No variable found");
                        })) : Belt_Option.flatMap(frame, getSelectedVariable);
                return /* tuple */[
                        thread,
                        frame,
                        variable
                      ];
              }
              catch (exn){
                var selectedThread = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(error.threads.find((function (thread) {
                                return thread.fault;
                              }))), Uncaught$BsConsole.getOrThrow(Belt_Array.get(error.threads, 0), "No threads"));
                var selectedFrame = getSelectedFrame(selectedThread);
                var selectedVariable = Belt_Option.flatMap(selectedFrame, getSelectedVariable);
                return /* tuple */[
                        selectedThread,
                        selectedFrame,
                        selectedVariable
                      ];
              }
            })), /* tuple */[
        match$5[0],
        frame$1,
        variable
      ]);
  var match$7 = React.useReducer(reducer, {
        selectedThread: match$6[0],
        selectedFrame: match$6[1],
        selectedVariable: match$6[2],
        variablesView: /* Variables */0
      });
  var dispatch = match$7[1];
  var match$8 = match$7[0];
  var variablesView = match$8.variablesView;
  var selectedVariable = match$8.selectedVariable;
  var selectedFrame = match$8.selectedFrame;
  var selectedThread = match$8.selectedThread;
  var setSelectedThread = React.useCallback((function (thread) {
          return Curry._1(dispatch, /* SelectThread */Block.__(0, [thread]));
        }), ([]));
  var setSelectedFrame = React.useCallback((function (frame) {
          return Curry._1(dispatch, /* SelectFrame */Block.__(1, [frame]));
        }), ([]));
  var setSelectedVariable = React.useCallback((function (variable) {
          return Curry._1(dispatch, /* SelectVariable */Block.__(3, [variable]));
        }), ([]));
  var selectedThreadHasLocalVariables = React.useMemo((function () {
          return Belt_Option.getWithDefault(Belt_Option.map(selectedThread.stack, (function (frames) {
                            return Belt_Array.reduce(frames, false, (function (acc, currentFrame) {
                                          if (acc) {
                                            return acc;
                                          } else {
                                            return Belt_Option.isSome(currentFrame.variables);
                                          }
                                        }));
                          })), false);
        }), [selectedThread]);
  var match$9 = Belt_Option.flatMap(selectedFrame, (function (v) {
          return v.sourceCode;
        }));
  var match$10 = error.sourceCode;
  var sourceCodeInfo = match$9 !== undefined && match$10 !== undefined ? Js_dict.get(Caml_option.valFromOption(match$10), match$9) : undefined;
  var attachmentsTask = React.useMemo((function () {
          return BugTasks$BsConsole.fetchAttachments(project.name, error.errorId);
        }), /* tuple */[
        project.name,
        error.errorId
      ]);
  var match$11 = Task2$BsConsole.useRefetchable(undefined, attachmentsTask, undefined);
  var refetchAttachments = match$11[1];
  var attachments = match$11[0];
  var services = config.services;
  var scaServiceEndpoint;
  if (services !== undefined) {
    var scaServiceConfig = Belt_List.getBy(services, (function (service) {
            return service.name === "sca";
          }));
    scaServiceEndpoint = scaServiceConfig !== undefined ? scaServiceConfig.endpoint : undefined;
  } else {
    scaServiceEndpoint = undefined;
  }
  var value = Js_dict.get(error.attributes, "build");
  var build = value !== undefined ? Js_json.decodeString(Caml_option.valFromOption(value)) : undefined;
  var defectsRequest;
  if (scaServiceEndpoint !== undefined) {
    var defectsTask = React.useMemo((function () {
            if (build !== undefined) {
              return BugTasks$BsConsole.fetchDefects(scaServiceEndpoint, universeName, projectName, build, undefined, undefined, error.fingerprint);
            } else {
              return BugTasks$BsConsole.fetchDefects(scaServiceEndpoint, universeName, projectName, undefined, undefined, undefined, error.fingerprint);
            }
          }), /* tuple */[
          universeName,
          projectName,
          error.fingerprint,
          build
        ]);
    defectsRequest = Task2$BsConsole.use(undefined, defectsTask, undefined);
  } else {
    defectsRequest = /* NotAsked */0;
  }
  var fingerprintDefects;
  if (typeof defectsRequest === "number" || defectsRequest.tag) {
    fingerprintDefects = undefined;
  } else {
    var results = defectsRequest[0][0];
    fingerprintDefects = Belt_List.length(results) === 0 ? undefined : results;
  }
  var defectsForFrame;
  if (fingerprintDefects !== undefined && selectedFrame !== undefined) {
    var defects = BugDefectUtils$BsConsole.getDefectsByFrame(selectedFrame, fingerprintDefects, sourceCodeInfo);
    defectsForFrame = Belt_List.length(defects) > 0 ? defects : undefined;
  } else {
    defectsForFrame = undefined;
  }
  var threadSelectEl = function (width, height) {
    return React.createElement(BugGenericThreadSelect$BsConsole.make, {
                threads: error.threads,
                selectedThread: selectedThread,
                setSelectedThread: setSelectedThread,
                token: token,
                projectName: projectName,
                universeName: universeName,
                tx: error.errorId,
                width: width,
                height: height,
                paneFocused: false
              });
  };
  var callstackEl = function (width, height) {
    var callstack = selectedThread.stack;
    if (callstack !== undefined) {
      return React.createElement(BugGenericCallstack$BsConsole.make, {
                  config: config,
                  attributes: error.attributes,
                  callstack: callstack,
                  missingSymbols: error.missingSymbols,
                  sourceCodeDict: error.sourceCode,
                  width: width,
                  height: height,
                  selectedFrame: selectedFrame,
                  setSelectedFrame: setSelectedFrame,
                  paneFocused: false,
                  fingerprintDefects: fingerprintDefects,
                  annotations: error.globalAnnotations,
                  isLocalVarsVisible: toggles.showVariables,
                  toggleIsLocalVarsVisbile: toggleIsLocalVarsVisbile,
                  processFrames: toggles.condenseFunctions,
                  toggleProcessFrames: toggleCondenseFunctions
                });
    } else {
      return null;
    }
  };
  var registers = Belt_Option.flatMap(selectedFrame, (function (v) {
          return v.registers;
        }));
  var registersEl;
  if (registers !== undefined) {
    var registers$1 = Caml_option.valFromOption(registers);
    registersEl = (function (width, height) {
        return React.createElement(BugRegistersSimple$BsConsole.make, {
                    width: width,
                    height: height,
                    registers: registers$1
                  });
      });
  } else {
    registersEl = undefined;
  }
  var sessionReplayEl = BugReplayUtils$BsConsole.hasReplayFiles(attachments) ? (function (width, height) {
        return React.createElement(BugReplay$BsConsole.make, {
                    height: height,
                    width: width,
                    attachments: attachments,
                    eventbox: eventbox,
                    setEventbox: setEventbox,
                    token: token,
                    errorId: error.errorId,
                    projectName: projectName
                  });
      }) : undefined;
  var attributes = BugCrdbAttributes$BsConsole.mergeAttributes(error.attributes, crdbRow);
  var breadcrumbsEl = BugBreadcrumbsUtils$BsConsole.hasBreadcrumbFiles(attachments) ? (function (showHeaderOpt, width, height) {
        var showHeader = showHeaderOpt !== undefined ? showHeaderOpt : true;
        return React.createElement(BugBreadcrumbs$BsConsole.make, {
                    token: token,
                    showHeader: showHeader,
                    attachments: attachments,
                    attributes: attributes,
                    projectName: projectName,
                    errorId: error.errorId,
                    breadbox: breadbox,
                    setBreadbox: setBreadbox,
                    height: height,
                    width: width,
                    showBacktraceUnitySDKCTA: BugTabs$BsConsole.shouldShowBacktraceUnitySDKCallToAction(attributes)
                  });
      }) : undefined;
  var match$12 = Belt_Option.flatMap(sourceCodeInfo, (function (sci) {
          return sci.source;
        }));
  var sourceCodeEl = selectedFrame !== undefined ? (
      match$12 !== undefined ? (
          defectsForFrame !== undefined ? (function (w, h) {
                return React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.width(Css.px(w)),
                                  /* :: */[
                                    Css.height(Css.px(h)),
                                    /* :: */[
                                      Css.overflow(Css.auto),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          }, React.createElement(BugDefectSourceToggle$BsConsole.make, {
                                config: config,
                                attachments: attachments,
                                source: match$12,
                                selectedFrame: selectedFrame,
                                projectName: project.name,
                                errorId: error.errorId,
                                defects: defectsForFrame,
                                height: h
                              }));
              }) : (function (w, h) {
                var tmp = {
                  attachments: attachments,
                  source: match$12,
                  projectName: project.name,
                  errorId: error.errorId
                };
                if (selectedFrame.line !== undefined) {
                  tmp.faultingLine = Caml_option.valFromOption(selectedFrame.line);
                }
                return React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.width(Css.px(w)),
                                  /* :: */[
                                    Css.height(Css.px(h)),
                                    /* :: */[
                                      Css.overflow(Css.auto),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          }, React.createElement(BugSource$BsConsole.make, tmp));
              })
        ) : (
          defectsForFrame !== undefined ? (function (w, h) {
                return React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.width(Css.px(w)),
                                  /* :: */[
                                    Css.height(Css.px(h)),
                                    /* :: */[
                                      Css.overflow(Css.auto),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          }, React.createElement(BugDefectView$BsConsole.make, {
                                config: config,
                                defects: defectsForFrame,
                                height: h,
                                fullHeight: true
                              }));
              }) : undefined
        )
    ) : undefined;
  var hasRegisters = Belt_Array.some(error.threads, (function (thread) {
          return Belt_Option.getWithDefault(Belt_Option.map(thread.stack, (function (s) {
                            return Belt_Array.some(s, (function (frame) {
                                          return Belt_Option.isSome(frame.registers);
                                        }));
                          })), false);
        }));
  var hasSourceCode = Belt_Array.some(error.threads, (function (thread) {
          return Belt_Option.getWithDefault(Belt_Option.map(thread.stack, (function (s) {
                            return Belt_Array.some(s, (function (frame) {
                                          var match = frame.sourceCode;
                                          var match$1 = error.sourceCode;
                                          if (match !== undefined && match$1 !== undefined) {
                                            return Belt_Option.isSome(Belt_Option.flatMap(Js_dict.get(Caml_option.valFromOption(match$1), match), (function (sc) {
                                                              return sc.source;
                                                            })));
                                          } else {
                                            return false;
                                          }
                                        }));
                          })), false);
        }));
  var maybeErrorMessage = Belt_Option.flatMap(BugCrdbAttributes$BsConsole.getAttribute(crdbRow, "error.message"), (function (m) {
          return Json_decode.optional(Json_decode.string, m);
        }));
  var downloadMissingSymsBtn = WindowVariables$BsConsole.promptMissingSymbols && TeamsUtils$BsConsole.isAdmin(config, project.name) && error.missingSymbols.length !== 0 ? React.createElement(Tooltip.default, {
          enterDelay: 250,
          children: React.createElement(IconButton.default, {
                ariaLabel: I18N$BsConsole.get(undefined, "Download missing symbols"),
                onClick: (function (_event) {
                    return BugReprocessMissingSymbols$BsConsole.displayPrompt(project.name, config, token, aperture, error, handleChangeUrl);
                  }),
                children: React.createElement("span", {
                      className: Css.style(/* :: */[
                            Css.width(Css.px(20)),
                            /* :: */[
                              Css.height(Css.px(20)),
                              /* :: */[
                                Css.fontSize(Css.px(20)),
                                /* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.orange)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ])
                    }, React.createElement(Warning.default, {
                          fontSize: "inherit",
                          viewBox: "1 1 23 23"
                        }))
              }),
          title: I18N$BsConsole.get(undefined, "Download missing symbols")
        }) : null;
  var javaScriptMiddlePanel = sourceCodeEl !== undefined ? (
      breadcrumbsEl !== undefined ? (function (param, param$1) {
            return React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.width(Css.px(param)),
                              /* :: */[
                                Css.height(Css.px(param$1)),
                                /* [] */0
                              ]
                            ])
                      }, React.createElement(Tabs.default, {
                            value: activeTab,
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.width(Css.pct(100)),
                                    /* :: */[
                                      Css.height(Css.px(34)),
                                      /* :: */[
                                        Css.borderBottom(Css.px(2), Css.solid, Css.hex(BugStyles$BsConsole.Color.dividerTertiary)),
                                        /* [] */0
                                      ]
                                    ]
                                  ])
                            },
                            indicatorColor: "primary",
                            textColor: "primary",
                            onChange: (function (param, newTab) {
                                BugMetrics$BsConsole.send(/* ChangeTab */[newTab ? "Source Code" : "Breadcrumbs"]);
                                return Curry._1(setActiveTab, (function (param) {
                                              return newTab;
                                            }));
                              }),
                            children: null
                          }, React.createElement(Tab.default, {
                                classes: {
                                  root: "walkthrough--debugger--attributes-sourcecode"
                                },
                                value: /* SourceCode */1,
                                label: I18N$BsConsole.show(undefined, "Source Code")
                              }), React.createElement(Tab.default, {
                                classes: {
                                  root: "walkthrough--debugger--attributes-breadcrumbs"
                                },
                                value: /* Breadcrumbs */0,
                                label: I18N$BsConsole.show(undefined, "Breadcrumbs")
                              })), activeTab ? Curry._2(sourceCodeEl, param, param$1) : Curry._3(breadcrumbsEl, false, param, param$1 - 34 | 0));
          }) : sourceCodeEl
    ) : (
      breadcrumbsEl !== undefined ? (function (eta) {
            return Curry._2(breadcrumbsEl, undefined, eta);
          }) : undefined
    );
  var getJavaScriptLayout = function (param) {
    if (javaScriptMiddlePanel !== undefined) {
      if (sessionReplayEl !== undefined) {
        return /* Generic211 */Block.__(2, [{
                    col1: /* tuple */[
                      threadSelectEl,
                      callstackEl
                    ],
                    col2: javaScriptMiddlePanel,
                    col3: sessionReplayEl
                  }]);
      } else {
        return /* Generic21 */Block.__(3, [{
                    col1: /* tuple */[
                      threadSelectEl,
                      callstackEl
                    ],
                    col2: javaScriptMiddlePanel
                  }]);
      }
    } else if (sessionReplayEl !== undefined) {
      return /* Generic21 */Block.__(3, [{
                  col1: /* tuple */[
                    threadSelectEl,
                    callstackEl
                  ],
                  col2: sessionReplayEl
                }]);
    } else {
      return /* Generic11 */Block.__(4, [{
                  col1: threadSelectEl,
                  col2: callstackEl
                }]);
    }
  };
  var localVariablesEl;
  if (selectedThreadHasLocalVariables) {
    var variables = Belt_Option.flatMap(selectedFrame, (function (v) {
            return v.variables;
          }));
    var variablesEl = React.createElement(BugVariables$BsConsole.make, {
          annotationsDict: error.annotationsDict,
          variables: variables,
          selectedVariable: selectedVariable,
          setSelectedVariable: setSelectedVariable,
          paneFocused: true,
          onClick: (function (prim) {
              
            }),
          isLocalVarsVisible: toggles.showVariables,
          toggleIsLocalVarsVisbile: toggleIsLocalVarsVisbile
        });
    var sourceCodeData = Belt_Option.flatMap(sourceCodeInfo, (function (sci) {
            return sci.source;
          }));
    var selectChoices = Belt_Array.keepMap([
          /* tuple */[
            "variables",
            "Variables"
          ],
          Belt_Option.map(defectsForFrame, (function (param) {
                  return /* tuple */[
                          "defects",
                          "Correlated defects"
                        ];
                })),
          Belt_Option.map(sourceCodeData, (function (param) {
                  return /* tuple */[
                          "sourceCode",
                          "Source code"
                        ];
                }))
        ], (function (x) {
            return x;
          }));
    var sourceEl = Belt_Option.map(sourceCodeData, (function (source) {
            var tmp = {
              attachments: attachments,
              source: source,
              projectName: project.name,
              errorId: error.errorId
            };
            var tmp$1 = Belt_Option.flatMap(selectedFrame, (function (v) {
                    return v.line;
                  }));
            if (tmp$1 !== undefined) {
              tmp.faultingLine = Caml_option.valFromOption(tmp$1);
            }
            return React.createElement(BugSource$BsConsole.make, tmp);
          }));
    var defectsEl = Belt_Option.map(defectsForFrame, (function (defects) {
            return React.createElement(BugDefectView$BsConsole.make, {
                        config: config,
                        defects: defects,
                        height: h,
                        fullHeight: true
                      });
          }));
    localVariablesEl = toggles.showVariables ? (function (w, h) {
          if (selectChoices.length <= 1) {
            return variablesEl;
          }
          var tmp;
          switch (variablesView) {
            case /* Variables */0 :
                tmp = variablesEl;
                break;
            case /* Defects */1 :
                tmp = Belt_Option.getWithDefault(defectsEl, null);
                break;
            case /* SourceCode */2 :
                tmp = Belt_Option.getWithDefault(sourceEl, null);
                break;
            
          }
          return React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.width(Css.px(w)),
                            /* :: */[
                              Css.height(Css.px(h)),
                              /* :: */[
                                Css.overflow(Css.auto),
                                /* [] */0
                              ]
                            ]
                          ])
                    }, React.createElement("div", {
                          className: inputStyle
                        }, React.createElement("select", {
                              className: formStyle,
                              onChange: (function ($$event) {
                                  var value = $$event.target.value;
                                  var view;
                                  switch (value) {
                                    case "defects" :
                                        view = /* Defects */1;
                                        break;
                                    case "sourceCode" :
                                        view = /* SourceCode */2;
                                        break;
                                    case "variables" :
                                        view = /* Variables */0;
                                        break;
                                    default:
                                      view = /* Variables */0;
                                  }
                                  return Curry._1(dispatch, /* SetVariablesView */Block.__(4, [view]));
                                })
                            }, Belt_Array.map(selectChoices, (function (param) {
                                    var value = param[0];
                                    return React.createElement("option", {
                                                key: value,
                                                selected: variablesViewToValue(variablesView) === value,
                                                value: value
                                              }, I18N$BsConsole.show(undefined, param[1]));
                                  })))), tmp);
        }) : undefined;
  } else {
    localVariablesEl = undefined;
  }
  React.useEffect((function () {
          MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                  /* Debugger_Load */104,
                  Json_encode.object_(Belt_List.keepMap(/* :: */[
                            /* tuple */[
                              "agent",
                              error.agent
                            ],
                            /* :: */[
                              /* tuple */[
                                "agentVersion",
                                error.agentVersion
                              ],
                              /* :: */[
                                /* tuple */[
                                  "bugFormat",
                                  error.bugFormat
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "debuggerView",
                                    "BugGeneric"
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "threadHasLocalVariables",
                                      Pervasives.string_of_bool(selectedThreadHasLocalVariables)
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ], (function (param) {
                              var k = param[0];
                              return Belt_Option.map(param[1], (function (v) {
                                            return /* tuple */[
                                                    k,
                                                    v
                                                  ];
                                          }));
                            })))
                ]));
          
        }), [error.errorId]);
  React.useEffect((function () {
          var threadIndex = error.threads.findIndex((function (t) {
                  return t === selectedThread;
                }));
          var frameIndex = Belt_Option.getWithDefault(Belt_Option.map(selectedFrame, (function (sf) {
                      return Belt_Option.flatMap(selectedThread.stack, (function (t) {
                                    return t.findIndex((function (f) {
                                                  return f === sf;
                                                }));
                                  }));
                    })), undefined);
          var match = Belt_Option.flatMap(selectedFrame, (function (v) {
                  return v.variables;
                }));
          var variableIndex = selectedVariable !== undefined && match !== undefined ? match.findIndex((function (v) {
                    return v === selectedVariable;
                  })) : undefined;
          Curry._1(handleReplaceUrl, /* ProjectDebugger2 */Block.__(19, [
                  projectName,
                  {
                    aperture: aperture,
                    state: {
                      errorId: error.errorId,
                      threadIndex: threadIndex,
                      frameIndex: frameIndex,
                      variableIndex: variableIndex
                    }
                  }
                ]));
          
        }), /* tuple */[
        selectedThread,
        selectedFrame,
        selectedVariable
      ]);
  var match$13 = error.faultingThread;
  var match$14 = error.threads.length;
  var match$15 = Attribute_Helpers$BsConsole.SDKVariant.getSDKVariant(error.attributes);
  var tmp;
  var exit = 0;
  var content;
  var exit$1 = 0;
  var exit$2 = 0;
  var exit$3 = 0;
  if (match$15) {
    if (sourceCodeEl !== undefined) {
      if (hasRegisters) {
        exit$3 = 4;
      } else if (registersEl !== undefined) {
        exit$2 = 3;
      } else if (match$14 !== 1) {
        content = sourceCodeEl;
        exit = 1;
      } else {
        tmp = /* Generic21 */Block.__(3, [{
              col1: /* tuple */[
                threadSelectEl,
                callstackEl
              ],
              col2: sourceCodeEl
            }]);
      }
    } else if (hasSourceCode && !hasRegisters) {
      if (registersEl !== undefined) {
        exit$1 = 2;
      } else {
        tmp = /* Generic21 */Block.__(3, [{
              col1: /* tuple */[
                threadSelectEl,
                callstackEl
              ],
              col2: (function (param, param$1) {
                  return null;
                })
            }]);
      }
    } else {
      exit$3 = 4;
    }
  } else {
    tmp = getJavaScriptLayout(undefined);
  }
  if (exit$3 === 4) {
    if (localVariablesEl !== undefined && hasRegisters) {
      tmp = /* Btt */Block.__(0, [{
            threadSelect: threadSelectEl,
            callstack: callstackEl,
            variables: localVariablesEl,
            variableDetails: (function (param, param$1) {
                return React.createElement(BugVariableDetails$BsConsole.make, {
                            variable: selectedVariable,
                            variableAnnotations: Belt_Option.flatMap(selectedVariable, (function (selectedVariable) {
                                    return Js_dict.get(error.annotationsDict, selectedVariable.id);
                                  }))
                          });
              }),
            registers: (function (width) {
                return React.createElement(BugRegisters$BsConsole.make, {
                            width: width,
                            registers: Belt_Option.getWithDefault(Belt_Option.flatMap(selectedFrame, (function (v) {
                                        return v.registers;
                                      })), { })
                          });
              }),
            showVariableDetails: true
          }]);
    } else {
      exit$2 = 3;
    }
  }
  if (exit$2 === 3) {
    if (sourceCodeEl !== undefined) {
      tmp = registersEl !== undefined ? /* Generic211 */Block.__(2, [{
              col1: /* tuple */[
                threadSelectEl,
                callstackEl
              ],
              col2: sourceCodeEl,
              col3: registersEl
            }]) : /* Generic21 */Block.__(3, [{
              col1: /* tuple */[
                threadSelectEl,
                callstackEl
              ],
              col2: sourceCodeEl
            }]);
    } else {
      exit$1 = 2;
    }
  }
  if (exit$1 === 2) {
    if (hasSourceCode) {
      tmp = registersEl !== undefined ? /* Generic21 */Block.__(3, [{
              col1: /* tuple */[
                threadSelectEl,
                callstackEl
              ],
              col2: registersEl
            }]) : /* Generic211 */Block.__(2, [{
              col1: /* tuple */[
                threadSelectEl,
                callstackEl
              ],
              col2: (function (param, param$1) {
                  return null;
                }),
              col3: (function (param, param$1) {
                  return null;
                })
            }]);
    } else if (hasRegisters) {
      tmp = registersEl !== undefined ? /* Generic111 */Block.__(1, [{
              col1: threadSelectEl,
              col2: callstackEl,
              col3: registersEl
            }]) : /* Generic111 */Block.__(1, [{
              col1: threadSelectEl,
              col2: callstackEl,
              col3: (function (param, param$1) {
                  return null;
                })
            }]);
    } else if (registersEl !== undefined) {
      content = registersEl;
      exit = 1;
    } else {
      tmp = /* Generic11 */Block.__(4, [{
            col1: threadSelectEl,
            col2: callstackEl
          }]);
    }
  }
  if (exit === 1) {
    tmp = /* Generic111 */Block.__(1, [{
          col1: threadSelectEl,
          col2: callstackEl,
          col3: content
        }]);
  }
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* stretch */-162316795,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              children: React.createElement(Col2$BsConsole.make, {
                    flexGrow: 1,
                    alignItems: /* stretch */-162316795,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(BugHeader$BsConsole.make, {
                        config: config,
                        onAddFilter: onAddFilter,
                        errorId: error.errorId,
                        fingerprint: error.fingerprint,
                        classifiers: error.classifiers,
                        time: error.time,
                        recieved: BugCrdbAttributes$BsConsole.getAttribute(crdbRow, "timestamp.received"),
                        compressed: BugCrdbAttributes$BsConsole.getAttribute(crdbRow, "_compressed"),
                        project: project,
                        downloadMissingSymsBtn: downloadMissingSymsBtn,
                        onJumpToFaultingFrame: (function (param) {
                            var match = error.faultingThread;
                            if (match !== undefined) {
                              return Curry._1(dispatch, /* JumpTo */Block.__(2, [
                                            match[0],
                                            match[1]
                                          ]));
                            }
                            
                          }),
                        faulting: match$13 !== undefined ? (
                            match$13[1] !== undefined ? /* Frame */1 : /* Thread */0
                          ) : undefined,
                        onSelectVariableId: (function (prim) {
                            
                          }),
                        attachments: attachments,
                        universeName: universeName,
                        onAttachmentUploaded: (function (param) {
                            return Curry._1(refetchAttachments, undefined);
                          }),
                        missingSymbols: error.missingSymbols,
                        maybeErrorMessage: maybeErrorMessage,
                        projectName: projectName,
                        attributes: BugCrdbAttributes$BsConsole.mergeAttributes(error.attributes, crdbRow),
                        handleChangeUrl: handleChangeUrl,
                        token: token
                      }), React.createElement(BugGrid$BsConsole.make, {
                        threadCount: error.threads.length,
                        width: w,
                        height: h - (42 + (
                            Belt_Option.isSome(maybeErrorMessage) ? 42 : 0
                          ) | 0) | 0,
                        layout: tmp,
                        tabs: (function (width, height) {
                            var match = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
                            var tmp;
                            if (hasRepositories) {
                              if (match !== undefined && selectedFrame !== undefined) {
                                var framePath = Belt_Option.flatMap(selectedFrame.path, (function (prim) {
                                        if (prim == null) {
                                          return ;
                                        } else {
                                          return Caml_option.some(prim);
                                        }
                                      }));
                                var fullPath;
                                if (framePath !== undefined) {
                                  fullPath = framePath;
                                } else {
                                  var match$1 = error.sourceCode;
                                  var match$2 = selectedFrame.sourceCode;
                                  fullPath = Belt_Option.flatMap(match$1 !== undefined && match$2 !== undefined ? Js_dict.get(Caml_option.valFromOption(match$1), match$2) : undefined, (function (sc) {
                                          return sc.path;
                                        }));
                                }
                                var fullPathSplit = Belt_Option.map(fullPath, (function (fullPath) {
                                        var p1 = fullPath.split("/");
                                        var p2 = fullPath.split("\\");
                                        if (p1.length > p2.length) {
                                          return p1;
                                        } else {
                                          return p2;
                                        }
                                      }));
                                var fileName = Belt_Option.map(fullPathSplit, (function (fullPathSplit) {
                                        return Uncaught$BsConsole.getOrThrow(Belt_Array.get(fullPathSplit, fullPathSplit.length - 1 | 0), "Path index out of bounds");
                                      }));
                                var directory = Belt_Option.map(fullPathSplit, (function (fullPathSplit) {
                                        return Belt_Array.slice(fullPathSplit, 0, fullPathSplit.length - 1 | 0).join("/");
                                      }));
                                var funcName = selectedFrame.funcName;
                                var objName = Belt_Option.flatMap(selectedFrame.library, (function (objName) {
                                        if (objName == null) {
                                          return ;
                                        } else {
                                          return Caml_option.some(objName);
                                        }
                                      }));
                                var faultingLine = selectedFrame.line;
                                tmp = Caml_option.some(React.createElement(BugFetchedSource$BsConsole.make, {
                                          token: token,
                                          projectName: projectName,
                                          universeName: universeName,
                                          attributes: error.attributes,
                                          endpoint: match,
                                          directory: directory,
                                          fileName: fileName,
                                          funcName: (funcName == null) ? undefined : Caml_option.some(funcName),
                                          objName: objName,
                                          faultingLine: faultingLine
                                        }));
                              } else {
                                tmp = undefined;
                              }
                            } else {
                              tmp = match !== undefined ? Caml_option.some(React.createElement(Col2$BsConsole.make, {
                                          alignItems: /* center */98248149,
                                          justifyContent: /* center */98248149,
                                          className: Css.style(/* :: */[
                                                Css.width(Css.pct(100)),
                                                /* :: */[
                                                  Css.height(Css.pct(100)),
                                                  /* :: */[
                                                    Css.padding2(Css.px(10), Css.px(10)),
                                                    /* :: */[
                                                      Css.fontSize(Css.rem(1.5)),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]),
                                          children: null
                                        }, I18N$BsConsole.show(undefined, "Add source repositories to enable this feature."), React.createElement(Button.default, {
                                              variant: "contained",
                                              color: "primary",
                                              classes: {
                                                root: Css.style(/* :: */[
                                                      Css.unsafe("boxShadow", "none"),
                                                      /* :: */[
                                                        Css.marginTop(Css.rem(1)),
                                                        /* [] */0
                                                      ]
                                                    ])
                                              },
                                              onClick: (function (_event) {
                                                  
                                                }),
                                              children: React.createElement(Link$BsConsole.Jsx3.make, {
                                                    route: /* ProjectSettingsSourceCode */Block.__(47, [projectName]),
                                                    handleChangeUrl: handleChangeUrl,
                                                    children: I18N$BsConsole.show(undefined, "Add repositories")
                                                  })
                                            }))) : undefined;
                            }
                            var tmp$1 = {
                              token: token,
                              onAddFilter: onAddFilter,
                              attributes: BugCrdbAttributes$BsConsole.mergeAttributes(error.attributes, crdbRow),
                              globalAnnotations: error.globalAnnotations,
                              width: width,
                              height: height,
                              projectName: projectName,
                              attachments: attachments,
                              errorId: error.errorId,
                              isRaw: toggles.showBugTabsDataRaw,
                              toggleIsRaw: toggleShowBugTabsDataRaw
                            };
                            if (error.memoryMap !== undefined) {
                              tmp$1.memoryMap = Caml_option.valFromOption(error.memoryMap);
                            }
                            if (tmp !== undefined) {
                              tmp$1.bugSourceEl = Caml_option.valFromOption(tmp);
                            }
                            return React.createElement(BugTabs$BsConsole.make, tmp$1);
                          })
                      }))
            });
}

var make = BugGeneric;

exports.Styles = Styles;
exports.variablesViewToValue = variablesViewToValue;
exports.valueToVariablesView = valueToVariablesView;
exports.getSelectedFrame = getSelectedFrame;
exports.getSelectedVariable = getSelectedVariable;
exports.reducer = reducer;
exports.toString = toString;
exports.gridReducer = gridReducer;
exports.make = make;
/* inputStyle Not a pure module */
