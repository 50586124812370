// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var React = require("@backtrace/react");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");
var Version$BsConsole = require("../Version.js");

var pro = "1a5ae56e47be28c998877da5789316c79227a5fad13f9f80cfc06ccdde7f8e77";

var dev = "d67ba70029d1e9a91d2b2fb9d6a00a8348f1b9ab3d21b96f8aa9adf653cd1be1";

var Tokens = {
  pro: pro,
  dev: dev
};

function make(param) {
  return { };
}

function addString(key, value, t) {
  t[key] = value;
  return t;
}

function addJson(key, value, t) {
  t[key] = value;
  
}

var Attributes = {
  make: make,
  addString: addString,
  addJson: addJson
};

function make$1(name, fallback, children) {
  return ReasonReact.wrapJsForReason(React.ErrorBoundary, {
              name: Js_null_undefined.fromOption(name),
              fallback: Js_null_undefined.fromOption(fallback)
            }, children);
}

var Jsx2 = {
  make: make$1
};

var ErrorBoundary = {
  Jsx2: Jsx2
};

var Report = { };

var client = {
  contents: undefined
};

var Configuration = { };

var Log = { };

var BreadcrumbsManager = {
  Log: Log
};

function getAttribute(key) {
  var client$prime = client.contents;
  if (client$prime !== undefined) {
    return Js_dict.get(Caml_option.valFromOption(client$prime).attributes, key);
  }
  
}

function addAttribute(attribute) {
  var client$prime = client.contents;
  if (client$prime !== undefined) {
    Caml_option.valFromOption(client$prime).addAttribute(attribute);
    return ;
  }
  
}

function memorize(key, value) {
  return addAttribute(addString(key, value, { }));
}

function leaveBreadcrumb(logLevelOpt, attributes, message) {
  var logLevel = logLevelOpt !== undefined ? logLevelOpt : /* Info */2;
  var clientRef = client.contents;
  var manager = Belt_Option.flatMap(clientRef, (function (client$prime) {
          return Caml_option.nullable_to_opt(client$prime.breadcrumbs);
        }));
  if (manager === undefined) {
    return ;
  }
  var manager$1 = Caml_option.valFromOption(manager);
  switch (logLevel) {
    case /* Verbose */0 :
        manager$1.verbose(message, attributes);
        return ;
    case /* Debug */1 :
        manager$1.debug(message, attributes);
        return ;
    case /* Info */2 :
        manager$1.info(message, attributes);
        return ;
    case /* Warn */3 :
        manager$1.warn(message, attributes);
        return ;
    case /* Error */4 :
        manager$1.error(message, attributes);
        return ;
    
  }
}

function send(attributes, data) {
  var client$prime = client.contents;
  if (client$prime !== undefined) {
    Caml_option.valFromOption(client$prime).send(data[1], attributes).catch((function (param) {
            return Promise.resolve(undefined);
          }));
    return ;
  }
  
}

function reportWithSourceModule(affectedModule, error) {
  var __x = addString("src_module", affectedModule, { });
  return send(__x, /* `exn */[
              5049499,
              error
            ]);
}

function initialize(options, param) {
  var token = process.env.NODE_ENV === "development" ? dev : pro;
  var defaultConfig = {
    name: "bs-console",
    version: Version$BsConsole.version,
    url: "https://submit.backtrace.io/backtrace/" + (token + "/json")
  };
  var config = Belt_Option.getWithDefault(options, defaultConfig);
  client.contents = Caml_option.some(React.BacktraceClient.initialize(config));
  return client;
}

var Client = {
  client: client,
  Configuration: Configuration,
  BreadcrumbsManager: BreadcrumbsManager,
  getAttribute: getAttribute,
  addAttribute: addAttribute,
  memorize: memorize,
  leaveBreadcrumb: leaveBreadcrumb,
  send: send,
  reportWithSourceModule: reportWithSourceModule,
  initialize: initialize
};

exports.Tokens = Tokens;
exports.Attributes = Attributes;
exports.ErrorBoundary = ErrorBoundary;
exports.Report = Report;
exports.Client = Client;
/* ReasonReact Not a pure module */
