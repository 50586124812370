// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Row2$BsConsole = require("./components/Row2.js");
var Add = require("@material-ui/icons/Add");
var Edit = require("@material-ui/icons/Edit");
var FormatPopover$BsConsole = require("./format-renderers/FormatPopover.js");
var FormatRenderer$BsConsole = require("./format-renderers/FormatRenderer.js");
var Tooltip = require("@material-ui/core/Tooltip");
var IconButton = require("@material-ui/core/IconButton");
var TriageActionPopover$BsConsole = require("./TriageActionPopover.js");

var iconButton = {
  root: Css.style(/* :: */[
        Css.fontSize(Css.rem(1)),
        /* :: */[
          Css.important(Css.padding(Css.px(4))),
          /* [] */0
        ]
      ]),
  label: Css.merge(/* :: */[
        Css.style(/* :: */[
              Css.unsafe("fontSize", "inherit"),
              /* [] */0
            ]),
        /* :: */[
          "triage-action",
          /* [] */0
        ]
      ])
};

var tagsContainer = Css.style(/* :: */[
      Css.unsafe("maxWidth", "calc(100% - 28px)"),
      /* [] */0
    ]);

var tagsRow = Css.style(/* :: */[
      Css.selector(":last-child", /* :: */[
            Css.marginLeft(Css.px(4)),
            /* [] */0
          ]),
      /* [] */0
    ]);

var Styles = {
  iconButton: iconButton,
  tagsContainer: tagsContainer,
  tagsRow: tagsRow
};

function TriageTags(Props) {
  var tags = Props.tags;
  var handleSetIssue = Props.handleSetIssue;
  var allTags = Props.allTags;
  var refreshAllTags = Props.refreshAllTags;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var handleAddRemoveTags = function (addTags, removeTags) {
    var action;
    var exit = 0;
    if (addTags) {
      if (removeTags) {
        exit = 1;
      } else {
        action = /* AddTags */Block.__(8, [addTags]);
      }
    } else if (removeTags) {
      exit = 1;
    } else {
      action = undefined;
    }
    if (exit === 1) {
      action = addTags ? /* AddRemoveTags */Block.__(9, [
            addTags,
            removeTags
          ]) : /* RemoveTags */Block.__(7, [removeTags]);
    }
    if (action !== undefined) {
      Curry._2(handleSetIssue, /* :: */[
            action,
            /* [] */0
          ], undefined);
      return Curry._1(refreshAllTags, undefined);
    }
    
  };
  return React.createElement(React.Fragment, undefined, tags !== undefined ? React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    justifyContent: /* spaceBetween */516682146,
                    className: tagsRow,
                    children: null
                  }, React.createElement(FormatPopover$BsConsole.make, {
                        content: React.createElement(FormatRenderer$BsConsole.make, {
                              value: /* IssueTags */Block.__(15, [tags]),
                              attribute: "fingerprint;issues;tags",
                              handleChangeUrl: handleChangeUrl,
                              handleAddFilters: handleAddFilters
                            }),
                        className: tagsContainer,
                        children: React.createElement(FormatRenderer$BsConsole.make, {
                              value: /* IssueTags */Block.__(15, [tags]),
                              attribute: "fingerprint;issues;tags",
                              handleChangeUrl: handleChangeUrl,
                              handleAddFilters: handleAddFilters
                            })
                      }), React.createElement(Tooltip.default, {
                        placement: "top",
                        children: React.createElement(IconButton.default, {
                              classes: iconButton,
                              onClick: (function ($$event) {
                                  var el = $$event.currentTarget;
                                  return Curry._1(setAnchorEl, (function (param) {
                                                return Caml_option.some(el);
                                              }));
                                }),
                              children: React.createElement(Edit.default, {
                                    color: "inherit",
                                    fontSize: "inherit"
                                  })
                            }),
                        title: "Edit tags"
                      })) : React.createElement(Tooltip.default, {
                    placement: "top",
                    children: React.createElement(IconButton.default, {
                          classes: iconButton,
                          onClick: (function ($$event) {
                              var el = $$event.currentTarget;
                              return Curry._1(setAnchorEl, (function (param) {
                                            return Caml_option.some(el);
                                          }));
                            }),
                          children: React.createElement(Add.default, {
                                color: "inherit",
                                fontSize: "inherit"
                              })
                        }),
                    title: "Add tags"
                  }), anchorEl !== undefined ? React.createElement(TriageActionPopover$BsConsole.Tags.make, {
                    anchorEl: Caml_option.valFromOption(anchorEl),
                    allTags: allTags,
                    tags: Belt_Option.getWithDefault(tags, /* [] */0),
                    onClose: (function (param) {
                        return Curry._1(setAnchorEl, (function (param) {
                                      
                                    }));
                      }),
                    handleAddRemoveTags: handleAddRemoveTags
                  }) : null);
}

var make = TriageTags;

exports.Styles = Styles;
exports.make = make;
/* iconButton Not a pure module */
