// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var AttributeTypeaheadForDetail$BsConsole = require("./typeahead/AttributeTypeaheadForDetail.js");

function isChecked(attributeName, listOfNames) {
  return Belt_List.has(listOfNames, attributeName, (function (prim, prim$1) {
                return prim === prim$1;
              }));
}

function setRef(tokenType, theRef, param) {
  param.state.attributeInputRef.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
  
}

var component = ReasonReact.reducerComponent("FoldInputs");

var menu = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* [] */0
      ]
    ]);

function make(attributes, stats, onClose, onChange, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, {
                              root: menu
                            }, undefined, undefined, undefined, 0, [ReasonReact.element(undefined, Curry._1(self.handle, (function (param, param$1) {
                                          return setRef(/* Attribute */0, param, param$1);
                                        })), Curry.app(AttributeTypeaheadForDetail$BsConsole.make, [
                                        undefined,
                                        undefined,
                                        Belt_List.map(attributes, (function (attribute) {
                                                return {
                                                        attribute: attribute,
                                                        checked: isChecked(attribute.name, stats)
                                                      };
                                              })),
                                        (function (att) {
                                            Curry._1(self.send, /* SetAttribute */[att]);
                                            var newFold = att.checked ? Belt_List.keep(stats, (function (s) {
                                                      return s !== att.attribute.name;
                                                    })) : /* :: */[
                                                att.attribute.name,
                                                stats
                                              ];
                                            Curry._1(onChange, newFold);
                                            setTimeout((function (param) {
                                                    return Curry._1(onClose, undefined);
                                                  }), 200);
                                            
                                          }),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        []
                                      ]))]));
            }),
          initialState: (function (param) {
              return {
                      attribute: undefined,
                      attributeInputRef: {
                        contents: undefined
                      },
                      foldInputRef: {
                        contents: undefined
                      }
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          attribute: action[0].attribute,
                          attributeInputRef: state.attributeInputRef,
                          foldInputRef: state.foldInputRef
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var Inputs = {
  setRef: setRef,
  component: component,
  menu: menu,
  make: make
};

var component$1 = ReasonReact.statelessComponent("Fold");

function make$1(stats, onChange, remote, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              if (typeof remote === "number") {
                return null;
              }
              if (remote.tag) {
                return null;
              }
              var filteredAttributes = remote[0][0];
              var countOfSelectedAttributes = Belt_List.length(Belt_List.keep(filteredAttributes, (function (attribute) {
                          return isChecked(attribute.name, stats);
                        })));
              return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                return React.createElement("div", {
                                            ref: refHandler,
                                            role: "button",
                                            onFocus: onFocus,
                                            onClick: onClick
                                          }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    /* Outlined */-28821822,
                                                    /* Primary */-791844958,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [
                                                      React.createElement("div", {
                                                            className: Css.style(/* :: */[
                                                                  Css.marginRight(Css.px(1)),
                                                                  /* :: */[
                                                                    Css.marginLeft(Css.px(5)),
                                                                    /* [] */0
                                                                  ]
                                                                ])
                                                          }, React.createElement("span", {
                                                                className: Css.style(/* :: */[
                                                                      Css.fontWeight(/* normal */812216871),
                                                                      /* [] */0
                                                                    ])
                                                              }, I18N$BsConsole.show(undefined, "Show: ")), React.createElement("span", {
                                                                className: Css.style(/* :: */[
                                                                      Css.fontWeight(/* medium */-20425611),
                                                                      /* [] */0
                                                                    ])
                                                              }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                        /* Int */Block.__(4, [
                                                                            /* Int_d */0,
                                                                            /* No_padding */0,
                                                                            /* No_precision */0,
                                                                            /* String_literal */Block.__(11, [
                                                                                " Attributes",
                                                                                /* End_of_format */0
                                                                              ])
                                                                          ]),
                                                                        "%d Attributes"
                                                                      ]), countOfSelectedAttributes + 3 | 0))),
                                                      React.createElement("div", {
                                                            className: Css.style(/* :: */[
                                                                  Css.marginRight(Css.px(1)),
                                                                  /* :: */[
                                                                    Css.marginTop(Css.px(1)),
                                                                    /* :: */[
                                                                      Css.height(Css.px(18)),
                                                                      /* [] */0
                                                                    ]
                                                                  ]
                                                                ]) + " smaller-icon"
                                                          }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ArrowDropDown.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))
                                                    ]
                                                  ])));
                              }), undefined, undefined, undefined, undefined, undefined, undefined, (function (onClose) {
                                return ReasonReact.element(undefined, undefined, make(filteredAttributes, stats, onClose, onChange, []));
                              }), undefined, []));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var Default = {
  component: component$1,
  make: make$1
};

exports.isChecked = isChecked;
exports.Inputs = Inputs;
exports.Default = Default;
exports.component = component$1;
exports.make = make$1;
/* component Not a pure module */
