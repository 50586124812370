// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../../../bindings/Mui.js");
var Row$BsConsole = require("../../../utilities/Row.js");
var I18N$BsConsole = require("../../../I18N.js");
var Icon$BsConsole = require("../../../bindings/Icon.js");
var Task2$BsConsole = require("../../../Task2.js");
var Colors$BsConsole = require("../../../Colors.js");
var Column$BsConsole = require("../../../utilities/Column.js");
var Styles$BsConsole = require("../../../utilities/Styles.js");
var AccentCard$BsConsole = require("../../../utilities/AccentCard.js");
var SymbolServer_API$BsConsole = require("./SymbolServer_API.js");
var SymbolServers_Logs$BsConsole = require("./SymbolServers_Logs.js");

var formStyle = Css.style(/* :: */[
      Css.important(Css.marginBottom(Css.px(15))),
      /* :: */[
        Css.width(Css.px(200)),
        /* :: */[
          Css.height(Css.px(40)),
          /* :: */[
            Css.opacity(0.8),
            /* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.blueBackground)),
              /* :: */[
                Css.border(Css.px(0), /* none */-922086728, /* transparent */582626130),
                /* :: */[
                  Css.height(Css.px(35)),
                  /* :: */[
                    Css.padding(Css.px(10)),
                    /* :: */[
                      Css.fontSize(Css.px(13)),
                      /* :: */[
                        Css.fontWeight(/* medium */-20425611),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var labelStyle = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey5)),
      /* :: */[
        Css.lineHeight(Css.px(16)),
        /* :: */[
          Css.fontSize(Css.px(14)),
          /* :: */[
            Css.marginBottom(Css.px(8)),
            /* [] */0
          ]
        ]
      ]
    ]);

var component = ReasonReact.reducerComponent("SymbolServers_SymbolStatus-BsConsole");

function make(endpoint, universe, token, project, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var requiredMessage = match.requiredMessage;
              var debugId = match.debugId;
              var symbolName = match.symbolName;
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                      Css.width(Css.pct(100)),
                                      /* [] */0
                                    ]), [
                                  React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.marginBottom(Css.px(20)),
                                              /* :: */[
                                                Css.fontWeight(/* medium */-20425611),
                                                /* :: */[
                                                  Css.fontSize(Css.px(14)),
                                                  /* [] */0
                                                ]
                                              ]
                                            ])
                                      }, I18N$BsConsole.show(undefined, "Symbol status")),
                                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* flexEnd */924268066, undefined, undefined, undefined, [
                                            ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                      React.createElement("label", {
                                                            className: labelStyle,
                                                            htmlFor: "symbol-status-name"
                                                          }, I18N$BsConsole.show(undefined, "Symbol name")),
                                                      React.createElement("input", {
                                                            className: Css.merge(/* :: */[
                                                                  formStyle,
                                                                  /* :: */[
                                                                    Css.style(/* :: */[
                                                                          Css.marginRight(Css.px(10)),
                                                                          /* :: */[
                                                                            Css.width(Css.px(180)),
                                                                            /* [] */0
                                                                          ]
                                                                        ]),
                                                                    /* [] */0
                                                                  ]
                                                                ]),
                                                            id: "symbol-status-name",
                                                            placeholder: "",
                                                            value: symbolName,
                                                            onChange: (function ($$event) {
                                                                var value = $$event.target.value;
                                                                return Curry._1(send, /* UpdateSymbolName */Block.__(0, [value]));
                                                              })
                                                          })
                                                    ])),
                                            ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                      React.createElement("label", {
                                                            className: labelStyle,
                                                            htmlFor: "symbol-status-debug-id"
                                                          }, I18N$BsConsole.show(undefined, "Debug ID")),
                                                      React.createElement("input", {
                                                            className: Css.merge(/* :: */[
                                                                  formStyle,
                                                                  /* :: */[
                                                                    Css.style(/* :: */[
                                                                          Css.marginRight(Css.px(10)),
                                                                          /* :: */[
                                                                            Css.width(Css.px(300)),
                                                                            /* [] */0
                                                                          ]
                                                                        ]),
                                                                    /* [] */0
                                                                  ]
                                                                ]),
                                                            id: "symbol-status-debug-id",
                                                            placeholder: "",
                                                            value: debugId,
                                                            onChange: (function ($$event) {
                                                                var value = $$event.target.value;
                                                                return Curry._1(send, /* UpdateDebugId */Block.__(1, [value]));
                                                              })
                                                          })
                                                    ])),
                                            ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                          className: Styles$BsConsole.$$Text.tooltipTitle
                                                        }, I18N$BsConsole.show(undefined, "View status of a particular symbol")), undefined, /* Top */4202101, undefined, undefined, undefined, [React.createElement("div", undefined, ReasonReact.element(undefined, undefined, AccentCard$BsConsole.make(I18N$BsConsole.get(undefined, "Get status"), undefined, (function (param) {
                                                                      return Curry._1(send, /* SetShowResult */Block.__(2, [true]));
                                                                    }), false, 100, [])))]))
                                          ])),
                                  requiredMessage !== undefined ? ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                    className: Css.style(/* :: */[
                                                          Css.color(Css.hex(Colors$BsConsole.redDark)),
                                                          /* :: */[
                                                            Css.fontSize(Css.px(12)),
                                                            /* :: */[
                                                              Css.fontFamily("Roboto"),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ])
                                                  }, I18N$BsConsole.showSkip(requiredMessage))])) : null
                                ])), match.showResult ? ReasonReact.element(undefined, undefined, Task2$BsConsole.Fetch.make(token, SymbolServer_API$BsConsole.getSymbolStatus(endpoint, universe, project, symbolName, debugId, undefined), undefined, (function (remote, _refetch) {
                                      if (typeof remote === "number") {
                                        return null;
                                      }
                                      if (remote.tag) {
                                        return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                            Css.padding(Css.px(10)),
                                                            /* :: */[
                                                              Css.margin(Css.px(-10)),
                                                              /* :: */[
                                                                Css.background(Css.hex(Colors$BsConsole.npDarkGrey)),
                                                                /* :: */[
                                                                  Css.color(Css.hex(Colors$BsConsole.grey610)),
                                                                  /* :: */[
                                                                    Css.borderRadius(Css.px(2)),
                                                                    /* :: */[
                                                                      Css.height(Css.pct(100)),
                                                                      /* :: */[
                                                                        Css.overflowY(Css.scroll),
                                                                        /* [] */0
                                                                      ]
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]), [ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                                        className: Css.style(/* :: */[
                                                                              Css.fontSize(Css.px(14)),
                                                                              /* :: */[
                                                                                Css.fontFamily("Inconsolata"),
                                                                                /* :: */[
                                                                                  Css.marginBottom(Css.px(5)),
                                                                                  /* :: */[
                                                                                    Css.textIndent(Css.em(1)),
                                                                                    /* :: */[
                                                                                      Css.marginLeft(Css.em(-1)),
                                                                                      /* :: */[
                                                                                        Css.wordWrap(Css.breakWord),
                                                                                        /* :: */[
                                                                                          Css.wordBreak(Css.breakAll),
                                                                                          /* [] */0
                                                                                        ]
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ])
                                                                      }, React.createElement("span", {
                                                                            className: Css.style(/* :: */[
                                                                                  Css.paddingLeft(Css.px(7)),
                                                                                  /* [] */0
                                                                                ])
                                                                          }, I18N$BsConsole.show(undefined, "Error retrieving symbol status")))]))]));
                                      }
                                      var logs = remote[0][0][0];
                                      return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                                                          Css.padding(Css.px(10)),
                                                          /* :: */[
                                                            Css.marginTop(Css.px(5)),
                                                            /* :: */[
                                                              Css.background(Css.hex(Colors$BsConsole.npDarkGrey)),
                                                              /* :: */[
                                                                Css.color(Css.hex(Colors$BsConsole.grey610)),
                                                                /* :: */[
                                                                  Css.borderRadius(Css.px(2)),
                                                                  /* :: */[
                                                                    Css.height(Css.pct(100)),
                                                                    /* :: */[
                                                                      Css.overflowY(Css.scroll),
                                                                      /* [] */0
                                                                    ]
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ]), [logs.length <= 0 ? React.createElement("div", {
                                                              className: Css.style(/* :: */[
                                                                    Css.color(Css.hex(Colors$BsConsole.grey610)),
                                                                    /* :: */[
                                                                      Css.fontSize(Css.px(14)),
                                                                      /* :: */[
                                                                        Css.fontFamily("Inconsolata"),
                                                                        /* :: */[
                                                                          Css.marginBottom(Css.px(5)),
                                                                          /* :: */[
                                                                            Css.textIndent(Css.em(1)),
                                                                            /* :: */[
                                                                              Css.marginLeft(Css.em(-1)),
                                                                              /* :: */[
                                                                                Css.wordWrap(Css.breakWord),
                                                                                /* :: */[
                                                                                  Css.wordBreak(Css.breakAll),
                                                                                  /* [] */0
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ])
                                                            }, I18N$BsConsole.show(undefined, "No logs currently available for this symbol.")) : Belt_Array.map(logs, (function (log) {
                                                                var match = log.type_;
                                                                var tmp;
                                                                switch (match) {
                                                                  case /* Info */0 :
                                                                      tmp = React.createElement("div", {
                                                                            className: Css.merge(/* :: */[
                                                                                  SymbolServers_Logs$BsConsole.Classes.tooltipContainer,
                                                                                  /* :: */[
                                                                                    Css.style(/* :: */[
                                                                                          Css.color(Css.hex(Colors$BsConsole.teal)),
                                                                                          /* [] */0
                                                                                        ]),
                                                                                    /* [] */0
                                                                                  ]
                                                                                ])
                                                                          }, ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, /* inherit_ */-601204732, /* inherit_ */-601204732, "info", [])));
                                                                      break;
                                                                  case /* Warning */1 :
                                                                      tmp = React.createElement("div", {
                                                                            className: Css.merge(/* :: */[
                                                                                  SymbolServers_Logs$BsConsole.Classes.tooltipContainer,
                                                                                  /* :: */[
                                                                                    Css.style(/* :: */[
                                                                                          Css.color(Css.hex(Colors$BsConsole.orange)),
                                                                                          /* [] */0
                                                                                        ]),
                                                                                    /* [] */0
                                                                                  ]
                                                                                ])
                                                                          }, ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, /* inherit_ */-601204732, /* inherit_ */-601204732, "warning", [])));
                                                                      break;
                                                                  case /* Error */2 :
                                                                      tmp = React.createElement("div", {
                                                                            className: Css.merge(/* :: */[
                                                                                  SymbolServers_Logs$BsConsole.Classes.tooltipContainer,
                                                                                  /* :: */[
                                                                                    Css.style(/* :: */[
                                                                                          Css.color(Css.hex(Colors$BsConsole.red)),
                                                                                          /* [] */0
                                                                                        ]),
                                                                                    /* [] */0
                                                                                  ]
                                                                                ])
                                                                          }, ReasonReact.element(undefined, undefined, Icon$BsConsole.make(undefined, /* inherit_ */-601204732, /* inherit_ */-601204732, "error", [])));
                                                                      break;
                                                                  
                                                                }
                                                                return ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                                tmp,
                                                                                React.createElement("div", {
                                                                                      className: Css.style(/* :: */[
                                                                                            Css.fontSize(Css.px(14)),
                                                                                            /* :: */[
                                                                                              Css.fontFamily("Inconsolata"),
                                                                                              /* :: */[
                                                                                                Css.marginBottom(Css.px(5)),
                                                                                                /* :: */[
                                                                                                  Css.textIndent(Css.em(1)),
                                                                                                  /* :: */[
                                                                                                    Css.marginLeft(Css.em(-1)),
                                                                                                    /* :: */[
                                                                                                      Css.wordWrap(Css.breakWord),
                                                                                                      /* :: */[
                                                                                                        Css.wordBreak(Css.breakAll),
                                                                                                        /* [] */0
                                                                                                      ]
                                                                                                    ]
                                                                                                  ]
                                                                                                ]
                                                                                              ]
                                                                                            ]
                                                                                          ])
                                                                                    }, React.createElement("span", undefined, I18N$BsConsole.showSkip("[" + (log.creationDate + "]"))), React.createElement("span", {
                                                                                          className: Css.style(/* :: */[
                                                                                                Css.paddingLeft(Css.px(7)),
                                                                                                /* [] */0
                                                                                              ])
                                                                                        }, I18N$BsConsole.showSkip(log.message)))
                                                                              ]));
                                                              }))]));
                                    }))) : null);
            }),
          initialState: (function (param) {
              return {
                      symbolName: "",
                      debugId: "",
                      error: undefined,
                      showResult: false,
                      requiredMessage: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* UpdateSymbolName */0 :
                    var id = action[0];
                    var idVal = id !== undefined ? id : "";
                    return /* Update */Block.__(0, [{
                                symbolName: idVal,
                                debugId: state.debugId,
                                error: state.error,
                                showResult: false,
                                requiredMessage: undefined
                              }]);
                case /* UpdateDebugId */1 :
                    var id$1 = action[0];
                    var idVal$1 = id$1 !== undefined ? id$1 : "";
                    return /* Update */Block.__(0, [{
                                symbolName: state.symbolName,
                                debugId: idVal$1,
                                error: state.error,
                                showResult: false,
                                requiredMessage: undefined
                              }]);
                case /* SetShowResult */2 :
                    var match = state.symbolName === "";
                    var match$1 = state.debugId === "";
                    if (match) {
                      if (match$1) {
                        return /* Update */Block.__(0, [{
                                    symbolName: state.symbolName,
                                    debugId: state.debugId,
                                    error: state.error,
                                    showResult: false,
                                    requiredMessage: I18N$BsConsole.get(undefined, "Symbol name and debug ID required")
                                  }]);
                      } else {
                        return /* Update */Block.__(0, [{
                                    symbolName: state.symbolName,
                                    debugId: state.debugId,
                                    error: state.error,
                                    showResult: false,
                                    requiredMessage: I18N$BsConsole.get(undefined, "Symbol name required")
                                  }]);
                      }
                    } else if (match$1) {
                      return /* Update */Block.__(0, [{
                                  symbolName: state.symbolName,
                                  debugId: state.debugId,
                                  error: state.error,
                                  showResult: false,
                                  requiredMessage: I18N$BsConsole.get(undefined, "Debug ID required")
                                }]);
                    } else {
                      return /* Update */Block.__(0, [{
                                  symbolName: state.symbolName,
                                  debugId: state.debugId,
                                  error: state.error,
                                  showResult: action[0],
                                  requiredMessage: undefined
                                }]);
                    }
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.formStyle = formStyle;
exports.labelStyle = labelStyle;
exports.component = component;
exports.make = make;
/* formStyle Not a pure module */
