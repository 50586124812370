// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Bin$BsConsole = require("./aggregation-renderers/Bin.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Shape$BsConsole = require("./bs-crdb-response/src/Shape.js");
var Colors$BsConsole = require("./Colors.js");
var ObjectID$BsConsole = require("./ObjectID.js");
var Truncate$BsConsole = require("./Truncate.js");
var EmptyValue$BsConsole = require("./format-renderers/EmptyValue.js");
var BtTableCell$BsConsole = require("./BtTableCell.js");
var BtTableSort$BsConsole = require("./BtTableSort.js");
var BtTableColumn$BsConsole = require("./BtTableColumn.js");
var FormatRenderer$BsConsole = require("./format-renderers/FormatRenderer.js");
var Tooltip = require("@material-ui/core/Tooltip");
var Delete = require("@material-ui/icons/Delete");
var AggregationType$BsConsole = require("./bs-crdb-response/src/AggregationType.js");
var ButtonBase = require("@material-ui/core/ButtonBase");
var IconButton = require("@material-ui/core/IconButton");
var ArrowUpward = require("@material-ui/icons/ArrowUpward");
var CondensedDistribution$BsConsole = require("./charts/CondensedDistribution.js");
var ArrowDownward = require("@material-ui/icons/ArrowDownward");

function BtTableAdapter$Header(Props) {
  var children = Props.children;
  return React.createElement(Truncate$BsConsole.make, {
              className: Css.merge(/* :: */[
                    Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]),
                    /* :: */[
                      "column-title",
                      /* [] */0
                    ]
                  ]),
              children: children
            });
}

var Header = {
  make: BtTableAdapter$Header
};

function BtTableAdapter$EnhancedHeader(Props) {
  var sortingState = Props.sortingState;
  var onSortChange = Props.onSortChange;
  var disableSort = Props.disableSort;
  var columnName = Props.columnName;
  var align = Props.align;
  var disableDeleteOpt = Props.disableDelete;
  var onColumnDelete = Props.onColumnDelete;
  var children = Props.children;
  var disableDelete = disableDeleteOpt !== undefined ? disableDeleteOpt : false;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.alignItems(/* center */98248149),
                      /* :: */[
                        Css.height(Css.px(24)),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ])
            }, React.createElement("div", {
                  className: Css.merge(/* :: */[
                        Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]),
                        /* :: */[
                          "column-title",
                          /* :: */[
                            disableDelete ? "" : "column-title-deletable",
                            /* [] */0
                          ]
                        ]
                      ])
                }, disableSort ? React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.padding(Css.px(4)),
                                /* :: */[
                                  Css.fontWeight(/* `num */[
                                        5496390,
                                        500
                                      ]),
                                  /* :: */[
                                    Css.whiteSpace(/* nowrap */867913355),
                                    /* :: */[
                                      Css.overflow(/* hidden */-862584982),
                                      /* :: */[
                                        Css.textOverflow(/* ellipsis */166888785),
                                        /* :: */[
                                          Css.width(Css.pct(100)),
                                          /* :: */[
                                            Css.textAlign(align),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ])
                      }, children) : React.createElement(BtTableSort$BsConsole.Label.make, {
                        sortingState: sortingState,
                        columnName: columnName,
                        onSortChange: onSortChange,
                        align: align,
                        children: children
                      })), disableDelete ? null : React.createElement("div", {
                    className: Css.merge(/* :: */[
                          Css.style(/* :: */[
                                Css.display(/* none */-922086728),
                                /* [] */0
                              ]),
                          /* :: */[
                            "delete-column",
                            /* [] */0
                          ]
                        ])
                  }, React.createElement(Tooltip.default, {
                        children: React.createElement(IconButton.default, {
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.accent)),
                                      /* :: */[
                                        Css.fontSize(Css.rem(1)),
                                        /* :: */[
                                          Css.important(Css.padding(Css.px(4))),
                                          /* [] */0
                                        ]
                                      ]
                                    ]),
                                label: Css.style(/* :: */[
                                      Css.unsafe("fontSize", "inherit"),
                                      /* [] */0
                                    ])
                              },
                              onClick: (function (param) {
                                  return Curry._1(onColumnDelete, columnName);
                                }),
                              children: React.createElement(Delete.default, {
                                    color: "inherit",
                                    fontSize: "inherit"
                                  })
                            }),
                        title: "Delete column"
                      })));
}

var EnhancedHeader = {
  make: BtTableAdapter$EnhancedHeader
};

function BtTableAdapter$EnhancedRangeHeader(Props) {
  var sortingState = Props.sortingState;
  var onSortChange = Props.onSortChange;
  var columnName = Props.columnName;
  var align = Props.align;
  var disableDeleteOpt = Props.disableDelete;
  var onColumnDelete = Props.onColumnDelete;
  var children = Props.children;
  var disableDelete = disableDeleteOpt !== undefined ? disableDeleteOpt : false;
  var match = columnName.split("__");
  var rangeSortingState;
  if (match.length !== 2) {
    rangeSortingState = undefined;
  } else {
    var attribute = match[0];
    if (sortingState !== undefined) {
      var match$1 = sortingState.columnName.split("__");
      if (match$1.length !== 2) {
        rangeSortingState = undefined;
      } else {
        var attribute$prime = match$1[0];
        var match$2 = match$1[1];
        switch (match$2) {
          case "range--max" :
              rangeSortingState = attribute === attribute$prime ? /* tuple */[
                  "max",
                  sortingState.direction
                ] : undefined;
              break;
          case "range--min" :
              rangeSortingState = attribute === attribute$prime ? /* tuple */[
                  "min",
                  sortingState.direction
                ] : undefined;
              break;
          default:
            rangeSortingState = undefined;
        }
      }
    } else {
      rangeSortingState = undefined;
    }
  }
  var tmp;
  tmp = rangeSortingState !== undefined && rangeSortingState[0] === "min" ? (
      rangeSortingState[1] >= 759138929 ? React.createElement(ArrowDownward.default, {
              color: "inherit",
              fontSize: "inherit"
            }) : React.createElement(ArrowUpward.default, {
              color: "inherit",
              fontSize: "inherit"
            })
    ) : null;
  var tmp$1;
  tmp$1 = rangeSortingState !== undefined && rangeSortingState[0] === "max" ? (
      rangeSortingState[1] >= 759138929 ? React.createElement(ArrowDownward.default, {
              color: "inherit",
              fontSize: "inherit"
            }) : React.createElement(ArrowUpward.default, {
              color: "inherit",
              fontSize: "inherit"
            })
    ) : null;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.alignItems(/* center */98248149),
                      /* :: */[
                        Css.height(Css.px(24)),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.position(/* relative */903134412),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement("div", {
                  className: Css.merge(/* :: */[
                        Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.padding(Css.px(4)),
                                /* :: */[
                                  Css.fontWeight(/* `num */[
                                        5496390,
                                        500
                                      ]),
                                  /* :: */[
                                    Css.whiteSpace(/* nowrap */867913355),
                                    /* :: */[
                                      Css.overflow(/* hidden */-862584982),
                                      /* :: */[
                                        Css.textOverflow(/* ellipsis */166888785),
                                        /* :: */[
                                          Css.width(Css.pct(100)),
                                          /* :: */[
                                            Css.textAlign(align),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]),
                        /* :: */[
                          "column-title",
                          /* :: */[
                            disableDelete ? "" : "column-title-deletable",
                            /* [] */0
                          ]
                        ]
                      ])
                }, children), disableDelete ? null : React.createElement("div", {
                    className: Css.merge(/* :: */[
                          Css.style(/* :: */[
                                Css.display(/* none */-922086728),
                                /* [] */0
                              ]),
                          /* :: */[
                            "delete-column",
                            /* [] */0
                          ]
                        ])
                  }, React.createElement(Tooltip.default, {
                        children: React.createElement(IconButton.default, {
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.accent)),
                                      /* :: */[
                                        Css.fontSize(Css.rem(1)),
                                        /* :: */[
                                          Css.important(Css.padding(Css.px(4))),
                                          /* [] */0
                                        ]
                                      ]
                                    ]),
                                label: Css.style(/* :: */[
                                      Css.unsafe("fontSize", "inherit"),
                                      /* [] */0
                                    ])
                              },
                              onClick: (function (param) {
                                  return Curry._1(onColumnDelete, columnName);
                                }),
                              children: React.createElement(Delete.default, {
                                    color: "inherit",
                                    fontSize: "inherit"
                                  })
                            }),
                        title: "Delete column"
                      })), React.createElement(Row2$BsConsole.make, {
                  flexWrap: /* nowrap */867913355,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.position(/* absolute */-1013592457),
                          /* :: */[
                            Css.bottom(Css.px(-14)),
                            /* :: */[
                              Css.left(/* zero */-789508312),
                              /* :: */[
                                Css.right(/* zero */-789508312),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement(Tooltip.default, {
                      children: React.createElement(ButtonBase.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.fontSize(Css.px(11)),
                                    /* :: */[
                                      Css.borderRadius(Css.px(3)),
                                      /* :: */[
                                        Css.unsafe("maxWidth", "calc(50% - 1rem)"),
                                        /* :: */[
                                          Css.width(Css.pct(100)),
                                          /* :: */[
                                            Css.justifyContent(/* flexStart */662439529),
                                            /* :: */[
                                              Css.flexDirection(align === /* left */-944764921 ? /* row */5693978 : /* rowReverse */378951208),
                                              /* :: */[
                                                Css.padding2(Css.px(2), Css.px(4)),
                                                /* :: */[
                                                  Css.hover(/* :: */[
                                                        Css.backgroundColor(Css.hex(Colors$BsConsole.blackA125)),
                                                        /* [] */0
                                                      ]),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ])
                            },
                            onClick: (function (param) {
                                var tmp;
                                var exit = 0;
                                if (rangeSortingState !== undefined && rangeSortingState[0] === "min") {
                                  tmp = rangeSortingState[1] >= 759138929 ? ({
                                        columnName: "" + (String(columnName) + "--min"),
                                        direction: /* Asc */3258129
                                      }) : ({
                                        columnName: "" + (String(columnName) + "--min"),
                                        direction: /* Desc */759138929
                                      });
                                } else {
                                  exit = 1;
                                }
                                if (exit === 1) {
                                  tmp = {
                                    columnName: "" + (String(columnName) + "--min"),
                                    direction: /* Desc */759138929
                                  };
                                }
                                return Curry._1(onSortChange, tmp);
                              }),
                            disableRipple: true,
                            children: null
                          }, React.createElement("span", {
                                className: Css.merge(/* :: */[
                                      Css.style(/* :: */[
                                            Css.whiteSpace(/* nowrap */867913355),
                                            /* :: */[
                                              Css.overflow(/* hidden */-862584982),
                                              /* :: */[
                                                Css.textOverflow(/* ellipsis */166888785),
                                                /* [] */0
                                              ]
                                            ]
                                          ]),
                                      /* [] */0
                                    ])
                              }, I18N$BsConsole.show(undefined, "min")), tmp),
                      title: "Sort range by min value"
                    }), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.textAlign(/* center */98248149),
                            /* :: */[
                              Css.width(Css.rem(2)),
                              /* :: */[
                                Css.padding2(/* zero */-789508312, Css.rem(0.5)),
                                /* [] */0
                              ]
                            ]
                          ])
                    }, I18N$BsConsole.showSkip("|")), React.createElement(Tooltip.default, {
                      children: React.createElement(ButtonBase.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.fontSize(Css.px(11)),
                                    /* :: */[
                                      Css.borderRadius(Css.px(3)),
                                      /* :: */[
                                        Css.unsafe("maxWidth", "calc(50% - 1rem)"),
                                        /* :: */[
                                          Css.width(Css.pct(100)),
                                          /* :: */[
                                            Css.justifyContent(/* flexStart */662439529),
                                            /* :: */[
                                              Css.flexDirection(align === /* left */-944764921 ? /* row */5693978 : /* rowReverse */378951208),
                                              /* :: */[
                                                Css.padding2(Css.px(2), Css.px(4)),
                                                /* :: */[
                                                  Css.hover(/* :: */[
                                                        Css.backgroundColor(Css.hex(Colors$BsConsole.blackA125)),
                                                        /* [] */0
                                                      ]),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ])
                            },
                            onClick: (function (param) {
                                var tmp;
                                var exit = 0;
                                if (rangeSortingState !== undefined && rangeSortingState[0] === "max") {
                                  tmp = rangeSortingState[1] >= 759138929 ? ({
                                        columnName: "" + (String(columnName) + "--max"),
                                        direction: /* Asc */3258129
                                      }) : ({
                                        columnName: "" + (String(columnName) + "--max"),
                                        direction: /* Desc */759138929
                                      });
                                } else {
                                  exit = 1;
                                }
                                if (exit === 1) {
                                  tmp = {
                                    columnName: "" + (String(columnName) + "--max"),
                                    direction: /* Desc */759138929
                                  };
                                }
                                return Curry._1(onSortChange, tmp);
                              }),
                            disableRipple: true,
                            children: null
                          }, React.createElement("span", {
                                className: Css.merge(/* :: */[
                                      Css.style(/* :: */[
                                            Css.whiteSpace(/* nowrap */867913355),
                                            /* :: */[
                                              Css.overflow(/* hidden */-862584982),
                                              /* :: */[
                                                Css.textOverflow(/* ellipsis */166888785),
                                                /* [] */0
                                              ]
                                            ]
                                          ]),
                                      /* [] */0
                                    ])
                              }, I18N$BsConsole.show(undefined, "max")), tmp$1),
                      title: "Sort range by max value"
                    })));
}

var EnhancedRangeHeader = {
  make: BtTableAdapter$EnhancedRangeHeader
};

function orderingToDirection(param) {
  if (param) {
    return /* Desc */759138929;
  } else {
    return /* Asc */3258129;
  }
}

function directionToOrdering(param) {
  if (param >= 759138929) {
    return /* Descending */1;
  } else {
    return /* Ascending */0;
  }
}

function getAlignmentByType_(type_) {
  if (type_ !== undefined) {
    if (type_ >= 946114815) {
      if (type_ !== 946115257 && type_ !== 946115928 && type_ >= 946114816) {
        return /* Left */847852583;
      } else {
        return /* Right */-57574468;
      }
    } else if (type_ !== 530205405 && type_ !== 562781022) {
      return /* Left */847852583;
    } else {
      return /* Right */-57574468;
    }
  } else {
    return /* Left */847852583;
  }
}

function getTableSortingState(sort) {
  if (sort.tag !== /* Select */5) {
    return ;
  }
  var direction = sort[1] ? /* Desc */759138929 : /* Asc */3258129;
  return {
          columnName: sort[0],
          direction: direction
        };
}

function onSortChange(cb, sortingState) {
  var direction = directionToOrdering(sortingState.direction);
  return Curry._1(cb, /* Select */Block.__(5, [
                sortingState.columnName,
                direction
              ]));
}

function getColumnAlignment(column) {
  return getAlignmentByType_(column.type_);
}

function getTableColumns(columns) {
  return Belt_List.toArray(Belt_List.map(columns, (function (column) {
                    return BtTableColumn$BsConsole.make(column.name, column.name, (function (row) {
                                  var match = Belt_List.getBy(row, (function (param) {
                                          return param[0] === column.name;
                                        }));
                                  if (match !== undefined) {
                                    return /* `CrdbSelect */[
                                            615627497,
                                            /* tuple */[
                                              match[0],
                                              match[1]
                                            ]
                                          ];
                                  } else {
                                    return /* None */870530776;
                                  }
                                }), undefined, undefined, undefined, 100, undefined, 200, undefined, undefined, getAlignmentByType_(column.type_), undefined);
                  })));
}

function getRowId(row) {
  var match = Belt_List.getBy(row, (function (param) {
          return param[0] === "_tx";
        }));
  if (match === undefined) {
    return "_";
  }
  var match$1 = match[1];
  if (match$1 === undefined) {
    return "_";
  }
  switch (match$1.tag | 0) {
    case /* Raw */0 :
        var match$2 = match$1[0];
        if (typeof match$2 === "number" || match$2[0] !== 365180284) {
          return "_";
        } else {
          return ObjectID$BsConsole.toHexString(Caml_int64.of_float(match$2[1]));
        }
    case /* ObjectID */17 :
        return ObjectID$BsConsole.toHexString(match$1[0]);
    default:
      return "_";
  }
}

var CrdbSelect = {
  getTableSortingState: getTableSortingState,
  onSortChange: onSortChange,
  getColumnAlignment: getColumnAlignment,
  getTableColumns: getTableColumns,
  getRowId: getRowId
};

var factorColumn = "__factor";

var countColumn = "__count";

var statusColumn = "__status";

var tagsColumn = "__tags";

var ticketsColumn = "__tickets";

function rangeToColumnName(range, attribute) {
  if (range) {
    return "" + (String(attribute) + "__range--max");
  } else {
    return "" + (String(attribute) + "__range--min");
  }
}

function isRangeColumn(columnName) {
  var match = columnName.split("__");
  if (match.length !== 2) {
    return false;
  }
  var match$1 = match[1];
  if (match$1 === "range") {
    return true;
  } else {
    return false;
  }
}

function aliasAggColumnNameAndTitle(attribute) {
  switch (attribute) {
    case "callstack - head" :
        return "Description";
    case "fingerprint;issues;state - head" :
        return "Status";
    case "fingerprint;issues;state__head" :
        return statusColumn;
    case "fingerprint;issues;tags - head" :
        return "Tags";
    case "fingerprint;issues;tags__head" :
        return tagsColumn;
    case "fingerprint;issues;ticket - head" :
        return "Ticket";
    case "fingerprint;issues;ticket__head" :
        return ticketsColumn;
    default:
      return attribute;
  }
}

function getAggColumnNameAndTitle(column) {
  var op$prime = column.op;
  var op = op$prime !== undefined ? AggregationType$BsConsole.toString(op$prime) : "";
  var title = column.name + (" - " + op);
  var name = column.name + ("__" + op);
  return /* tuple */[
          aliasAggColumnNameAndTitle(name),
          aliasAggColumnNameAndTitle(title)
        ];
}

function getAggregationFromColumnName(columnName) {
  var match = columnName.split("__");
  if (match.length !== 2) {
    return ;
  }
  var attribute = match[0];
  var match$1 = match[1];
  switch (match$1) {
    case "bin" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Bin */Block.__(0, [
                            32,
                            undefined
                          ])
                      ]));
    case "distribution" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Distribution */Block.__(1, [3])
                      ]));
    case "head" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Head */1
                      ]));
    case "max" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Max */6
                      ]));
    case "mean" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Mean */7
                      ]));
    case "min" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Min */5
                      ]));
    case "range" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Range */8
                      ]));
    case "sum" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Sum */3
                      ]));
    case "tail" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Tail */2
                      ]));
    case "unique" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Unique */4
                      ]));
    default:
      return ;
  }
}

function getSortingStateFromTableSort(sortingState) {
  var ordering = directionToOrdering(sortingState.direction);
  var columnName = sortingState.columnName;
  if (columnName === factorColumn) {
    return /* Group */Block.__(0, [ordering]);
  }
  if (columnName === countColumn) {
    return /* Count */Block.__(1, [ordering]);
  }
  var match = columnName.split("__");
  if (match.length !== 2) {
    return /* Count */Block.__(1, [/* Descending */1]);
  }
  var attribute = match[0];
  var match$1 = match[1];
  switch (match$1) {
    case "head" :
        return /* Head */Block.__(2, [
                  attribute,
                  ordering
                ]);
    case "max" :
        return /* Max */Block.__(8, [
                  attribute,
                  ordering
                ]);
    case "mean" :
        return /* Mean */Block.__(9, [
                  attribute,
                  ordering
                ]);
    case "min" :
        return /* Min */Block.__(7, [
                  attribute,
                  ordering
                ]);
    case "range--max" :
        return /* Range */Block.__(4, [
                  attribute,
                  /* Finish */1,
                  ordering
                ]);
    case "range--min" :
        return /* Range */Block.__(4, [
                  attribute,
                  /* Start */0,
                  ordering
                ]);
    case "sum" :
        return /* Sum */Block.__(10, [
                  attribute,
                  ordering
                ]);
    case "tail" :
        return /* Tail */Block.__(3, [
                  attribute,
                  ordering
                ]);
    case "unique" :
        return /* Unique */Block.__(6, [
                  attribute,
                  ordering
                ]);
    default:
      return /* Count */Block.__(1, [/* Descending */1]);
  }
}

function getTableSortingState$1(sort) {
  switch (sort.tag | 0) {
    case /* Group */0 :
        return {
                columnName: factorColumn,
                direction: sort[0] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Count */1 :
        return {
                columnName: countColumn,
                direction: sort[0] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Head */2 :
        return {
                columnName: "" + (String(sort[0]) + "__head"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Tail */3 :
        return {
                columnName: "" + (String(sort[0]) + "__tail"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Range */4 :
        return {
                columnName: rangeToColumnName(sort[1], sort[0]),
                direction: sort[2] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Select */5 :
        return ;
    case /* Unique */6 :
        return {
                columnName: "" + (String(sort[0]) + "__unique"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Min */7 :
        return {
                columnName: "" + (String(sort[0]) + "__min"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Max */8 :
        return {
                columnName: "" + (String(sort[0]) + "__max"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Mean */9 :
        return {
                columnName: "" + (String(sort[0]) + "__mean"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Sum */10 :
        return {
                columnName: "" + (String(sort[0]) + "__sum"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    
  }
}

function onSortChange$1(cb, sortingState) {
  return Curry._1(cb, getSortingStateFromTableSort(sortingState));
}

function getColumnAlignment$1(column) {
  var op = column.op;
  if (op === undefined) {
    return /* Left */847852583;
  }
  switch (op) {
    case /* Distribution */1 :
    case /* Bin */2 :
        return /* Left */847852583;
    case /* Unique */0 :
    case /* Mean */6 :
    case /* Sum */7 :
        return /* Right */-57574468;
    case /* Head */3 :
    case /* Tail */4 :
    case /* Range */5 :
    case /* Min */8 :
    case /* Max */9 :
        return getAlignmentByType_(column.type_);
    
  }
}

function getGroupTableColumn(factor) {
  var factorString = factor ? factor[0] : "fingerprint";
  return BtTableColumn$BsConsole.make(factorString, factorColumn, (function (row) {
                var groupName = row.factor.value;
                return /* `String */[
                        -976970511,
                        groupName
                      ];
              }), undefined, undefined, undefined, 100, 400, 150, undefined, undefined, undefined, undefined);
}

var countTableColumn = BtTableColumn$BsConsole.make("Errors", countColumn, (function (row) {
        var value = row.count;
        return /* `Int */[
                3654863,
                value
              ];
      }), undefined, undefined, undefined, 100, undefined, 110, undefined, undefined, /* Right */-57574468, undefined);

function getAggColumn(column) {
  var match = getAggColumnNameAndTitle(column);
  var match$1 = column.op;
  var match$2 = column.format;
  var match$3;
  var exit = 0;
  if (match$1 !== undefined) {
    switch (match$1) {
      case /* Distribution */1 :
          match$3 = /* tuple */[
            215,
            215
          ];
          break;
      case /* Bin */2 :
          match$3 = /* tuple */[
            232,
            232
          ];
          break;
      case /* Range */5 :
          match$3 = /* tuple */[
            160,
            160
          ];
          break;
      case /* Unique */0 :
      case /* Head */3 :
      case /* Tail */4 :
      case /* Mean */6 :
      case /* Sum */7 :
      case /* Min */8 :
      case /* Max */9 :
          exit = 1;
          break;
      
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    match$3 = match$2 === 2 ? /* tuple */[
        215,
        215
      ] : /* tuple */[
        undefined,
        160
      ];
  }
  var opString = Belt_Option.getWithDefault(Belt_Option.flatMap(column.op, (function (op) {
              return AggregationType$BsConsole.toString(op);
            })), "");
  var match$4 = column.op;
  var disableSort = match$4 !== undefined ? match$4 === 2 || match$4 === 1 : false;
  return BtTableColumn$BsConsole.make(match[1], match[0], (function (row) {
                var data = Belt_List.getBy(row.aggregations, (function (shape) {
                        if (Shape$BsConsole.getName(shape) === column.name) {
                          return Shape$BsConsole.getAggString(shape) === opString;
                        } else {
                          return false;
                        }
                      }));
                return /* `CrdbAggregate */[
                        -901480206,
                        data
                      ];
              }), disableSort, undefined, undefined, match$3[0], undefined, match$3[1], undefined, undefined, getColumnAlignment$1(column), undefined);
}

function getTableColumns$1(columns, factor) {
  var groupColumn = getGroupTableColumn(factor);
  var defaultColumns = [
    groupColumn,
    countTableColumn
  ];
  var aggColumns = Belt_List.toArray(Belt_List.map(columns, getAggColumn));
  return Belt_Array.concat(defaultColumns, aggColumns);
}

function renderShape(shape, rowCount, handleChangeUrl, handleAddFilters, param) {
  if (shape === undefined) {
    return React.createElement(EmptyValue$BsConsole.make, { });
  }
  switch (shape.tag | 0) {
    case /* Unique */0 :
        var value = shape[1];
        var value$prime = value !== undefined ? /* Raw */Block.__(0, [/* `Float */[
                365180284,
                value
              ]]) : undefined;
        var tmp = {
          value: value$prime,
          attribute: shape[0]
        };
        if (handleChangeUrl !== undefined) {
          tmp.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
        }
        if (handleAddFilters !== undefined) {
          tmp.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
        }
        return React.createElement(FormatRenderer$BsConsole.make, tmp);
    case /* Bin */2 :
        var bin = shape[1];
        if (bin !== undefined) {
          return React.createElement(Bin$BsConsole.make, {
                      data: bin,
                      width: 192,
                      height: 28
                    });
        } else {
          return React.createElement(EmptyValue$BsConsole.make, { });
        }
    case /* Range */8 :
        var range = shape[1];
        var attribute = shape[0];
        if (range === undefined) {
          return React.createElement(EmptyValue$BsConsole.make, { });
        }
        var tmp$1 = {
          value: range[0],
          attribute: attribute,
          className: Css.style(/* :: */[
                Css.unsafe("maxWidth", "calc(50% - 1rem)"),
                /* [] */0
              ])
        };
        if (handleChangeUrl !== undefined) {
          tmp$1.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
        }
        if (handleAddFilters !== undefined) {
          tmp$1.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
        }
        var tmp$2 = {
          value: range[1],
          attribute: attribute,
          className: Css.style(/* :: */[
                Css.unsafe("maxWidth", "calc(50% - 1rem)"),
                /* [] */0
              ])
        };
        if (handleChangeUrl !== undefined) {
          tmp$2.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
        }
        if (handleAddFilters !== undefined) {
          tmp$2.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
        }
        return React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    justifyContent: /* spaceBetween */516682146,
                    className: Css.style(/* :: */[
                          Css.fontFamily("inconsolata"),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(FormatRenderer$BsConsole.make, tmp$1), React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.textAlign(/* center */98248149),
                              /* :: */[
                                Css.width(Css.rem(2)),
                                /* :: */[
                                  Css.padding2(/* zero */-789508312, Css.rem(0.5)),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }, I18N$BsConsole.showSkip("-")), React.createElement(FormatRenderer$BsConsole.make, tmp$2));
    case /* Distribution */9 :
        var value$1 = shape[1];
        if (value$1 !== undefined) {
          return React.createElement(CondensedDistribution$BsConsole.Proto.make, {
                      attribute: shape[0],
                      distribution: value$1,
                      groupCount: rowCount,
                      onExpansion: (function (param) {
                          
                        })
                    });
        } else {
          return React.createElement(EmptyValue$BsConsole.make, { });
        }
    default:
      var tmp$3 = {
        value: shape[1],
        attribute: shape[0]
      };
      if (handleChangeUrl !== undefined) {
        tmp$3.handleChangeUrl = Caml_option.valFromOption(handleChangeUrl);
      }
      if (handleAddFilters !== undefined) {
        tmp$3.handleAddFilters = Caml_option.valFromOption(handleAddFilters);
      }
      return React.createElement(FormatRenderer$BsConsole.make, tmp$3);
  }
}

function renderDataCell(key, value, row, handleChangeUrl, handleAddFilters, param) {
  return React.createElement(BtTableCell$BsConsole.make, {
              children: typeof value === "number" ? React.createElement(EmptyValue$BsConsole.make, { }) : (
                  value[0] !== -901480206 ? React.createElement(EmptyValue$BsConsole.make, { }) : renderShape(value[1], row.count, handleChangeUrl, handleAddFilters, undefined)
                ),
              key: key
            });
}

function getRowId$1(row) {
  return row.factor.attribute + ("-" + (row.factor.value + ("__" + String(row.count))));
}

var CrdbAggregate = {
  factorColumn: factorColumn,
  countColumn: countColumn,
  statusColumn: statusColumn,
  fingerprintColumn: "__fingerprint",
  activityColumn: "__activity",
  tagsColumn: tagsColumn,
  assigneesColumn: "__assignees",
  ticketsColumn: ticketsColumn,
  rangeToColumnName: rangeToColumnName,
  isRangeColumn: isRangeColumn,
  aliasAggColumnNameAndTitle: aliasAggColumnNameAndTitle,
  getAggColumnNameAndTitle: getAggColumnNameAndTitle,
  getAggregationFromColumnName: getAggregationFromColumnName,
  getSortingStateFromTableSort: getSortingStateFromTableSort,
  getTableSortingState: getTableSortingState$1,
  onSortChange: onSortChange$1,
  getColumnAlignment: getColumnAlignment$1,
  getGroupTableColumn: getGroupTableColumn,
  countTableColumn: countTableColumn,
  getAggColumn: getAggColumn,
  getTableColumns: getTableColumns$1,
  renderShape: renderShape,
  renderDataCell: renderDataCell,
  getRowId: getRowId$1
};

exports.Header = Header;
exports.EnhancedHeader = EnhancedHeader;
exports.EnhancedRangeHeader = EnhancedRangeHeader;
exports.orderingToDirection = orderingToDirection;
exports.directionToOrdering = directionToOrdering;
exports.getAlignmentByType_ = getAlignmentByType_;
exports.CrdbSelect = CrdbSelect;
exports.CrdbAggregate = CrdbAggregate;
/* countTableColumn Not a pure module */
