// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var D3Scale = require("d3-scale");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Body1$BsConsole = require("../components/text/Body1.js");
var Colors$BsConsole = require("../Colors.js");
var Numeral$BsConsole = require("../Numeral.js");
var Drilldown$BsConsole = require("../Drilldown.js");
var EmptyValue$BsConsole = require("../format-renderers/EmptyValue.js");
var FormatUtils$BsConsole = require("../FormatUtils.js");
var BtThemeContext$BsConsole = require("../BtThemeContext.js");
var ValueDrilldown$BsConsole = require("../ValueDrilldown.js");
var Tooltip = require("@material-ui/core/Tooltip");
var BsVx_Shape__Bar$BsConsole = require("../bs-vx/src/BsVx_Shape__Bar.js");
var ButtonBase = require("@material-ui/core/ButtonBase");

function getXScale(min, max, valuesTotal) {
  return D3Scale.scaleLinear().rangeRound([
                min,
                max
              ]).domain([
              0,
              valuesTotal
            ]);
}

function getBarBackground(idx, colorRange, restColor) {
  var barBackground = Belt_Array.get(colorRange, idx);
  if (barBackground !== undefined) {
    return Caml_option.valFromOption(barBackground);
  } else {
    return restColor;
  }
}

function generateBarClassName(idx, colorRange, restColor) {
  var barBackground = getBarBackground(idx, colorRange, restColor);
  return Css.style(/* :: */[
              Css.width(Css.pct(100)),
              /* :: */[
                Css.backgroundColor(Css.hex(barBackground)),
                /* :: */[
                  Css.height(Css.px(20)),
                  /* [] */0
                ]
              ]
            ]);
}

function valueToWidthPct(value, total) {
  return value / total * 95 + 5;
}

function generateVizButtonClassName(value, total, idx) {
  var widthPct = valueToWidthPct(value, total);
  if (idx === 3) {
    return Css.style(/* :: */[
                Css.width(Css.pct(widthPct)),
                /* :: */[
                  Css.height(Css.px(20)),
                  /* [] */0
                ]
              ]);
  } else {
    return Css.style(/* :: */[
                Css.marginRight(Css.px(1)),
                /* :: */[
                  Css.unsafe("width", "calc(" + (String(widthPct | 0) + "% - 1px)")),
                  /* :: */[
                    Css.hover(/* :: */[
                          Css.backgroundColor(Css.hex(Colors$BsConsole.blackA125)),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ]
              ]);
  }
}

var Style = {
  getBarBackground: getBarBackground,
  generateBarClassName: generateBarClassName,
  valueToWidthPct: valueToWidthPct,
  generateVizButtonClassName: generateVizButtonClassName
};

function createFilterOptions(attribute, value) {
  return [
          /* tuple */[
            attribute,
            /* Equal */Block.__(0, [/* `String */[
                  -976970511,
                  value
                ]])
          ],
          /* tuple */[
            attribute,
            /* NotEqual */Block.__(1, [/* `String */[
                  -976970511,
                  value
                ]])
          ]
        ];
}

function getPercentageString(value, total) {
  return Numeral$BsConsole.format("0.[00]", Pervasives.string_of_float(value / total * 100)) + "%";
}

var otherValueName = "backtrace-other-values";

function CondensedDistribution$Proto(Props) {
  var attribute = Props.attribute;
  var distribution = Props.distribution;
  var groupCount = Props.groupCount;
  var colorRange = Props.colorRange;
  var onExpansion = Props.onExpansion;
  var match = React.useState((function () {
          
        }));
  var setSelection = match[1];
  var selection = match[0];
  var themeContext = React.useContext(BtThemeContext$BsConsole.context);
  var match$1;
  if (colorRange !== undefined) {
    match$1 = /* tuple */[
      colorRange,
      Belt_Option.getWithDefault(Belt_Array.get(colorRange, 0), Colors$BsConsole.qualitativeVizRest)
    ];
  } else if (themeContext !== undefined) {
    var dataVizPalette = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizPalette(themeContext), Colors$BsConsole.qualitativeVizPalette);
    var dataVizRest = Belt_Option.getWithDefault(BtThemeContext$BsConsole.getDataVizRest(themeContext), Colors$BsConsole.qualitativeVizRest);
    match$1 = /* tuple */[
      dataVizPalette,
      dataVizRest
    ];
  } else {
    match$1 = /* tuple */[
      Colors$BsConsole.qualitativeVizPalette,
      Colors$BsConsole.qualitativeVizRest
    ];
  }
  var fallbackColor = match$1[1];
  var colors = match$1[0];
  var t = distribution.tail;
  var tailData = t !== undefined ? [/* tuple */[
        otherValueName,
        t
      ]] : [];
  var data = Belt_Array.concat(Belt_Array.map(Belt_List.toArray(distribution.vals), (function (param) {
              return /* tuple */[
                      FormatUtils$BsConsole.formatToString(param[0]),
                      param[1]
                    ];
            })), tailData);
  var defaultSelection = Belt_Array.get(data, 0);
  React.useLayoutEffect((function () {
          Curry._1(setSelection, (function (param) {
                  return defaultSelection;
                }));
          
        }), []);
  var tmp;
  if (selection !== undefined) {
    var value = selection[1];
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.unsafe("maxWidth", "calc(100% - 48px)"),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.whiteSpace(/* nowrap */867913355),
                        /* :: */[
                          Css.textOverflow(/* ellipsis */166888785),
                          /* :: */[
                            Css.overflow(/* hidden */-862584982),
                            /* [] */0
                          ]
                        ]
                      ])
                }, I18N$BsConsole.showSkip(selection[0])), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.fontFamily("inconsolata"),
                        /* [] */0
                      ])
                }, I18N$BsConsole.showSkip("(" + (Numeral$BsConsole.format("0,0.[0]a", String(value)) + ")")))), React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.fontFamily("inconsolata"),
                    /* :: */[
                      Css.marginLeft(Css.rem(0.5)),
                      /* [] */0
                    ]
                  ])
            }, I18N$BsConsole.showSkip(getPercentageString(value, groupCount))));
  } else {
    tmp = React.createElement(EmptyValue$BsConsole.make, { });
  }
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.flexDirection(/* column */-963948842),
                      /* :: */[
                        Css.justifyContent(/* spaceAround */-485895757),
                        /* [] */0
                      ]
                    ]
                  ])
            }, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* spaceBetween */516682146,
                  flexWrap: /* nowrap */867913355,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                        /* [] */0
                      ]),
                  children: tmp
                }), React.createElement(Row2$BsConsole.make, {
                  justifyContent: /* spaceBetween */516682146,
                  children: Belt_Array.mapWithIndex(data, (function (idx, param) {
                          var value = param[1];
                          var name = param[0];
                          var percentageString = getPercentageString(value, groupCount);
                          if (name !== otherValueName) {
                            return React.createElement(Drilldown$BsConsole.FromContext.make, {
                                        textValue: name,
                                        filterOptions: createFilterOptions(attribute, name),
                                        className: generateVizButtonClassName(value, groupCount, idx),
                                        children: React.createElement(Tooltip.default, {
                                              children: React.createElement("div", {
                                                    key: name + ("_" + (String(idx) + "__value")),
                                                    className: generateBarClassName(idx, colors, fallbackColor),
                                                    onMouseEnter: (function (param) {
                                                        return Curry._1(setSelection, (function (param) {
                                                                      return /* tuple */[
                                                                              name,
                                                                              value
                                                                            ];
                                                                    }));
                                                      }),
                                                    onMouseLeave: (function (param) {
                                                        return Curry._1(setSelection, (function (param) {
                                                                      return defaultSelection;
                                                                    }));
                                                      })
                                                  }),
                                              title: "" + (String(name) + (" - " + (String(value) + (" / " + (String(groupCount) + (" - " + (String(percentageString) + ""))))))),
                                              key: name + ("_" + (String(idx) + "__tooltip"))
                                            }),
                                        key: name + ("_" + (String(idx) + "__drilldown"))
                                      });
                          }
                          var value$prime = Numeral$BsConsole.format("0,0", String(value));
                          var groupCount$prime = Numeral$BsConsole.format("0,0", String(groupCount));
                          return React.createElement(Tooltip.default, {
                                      children: React.createElement(ButtonBase.default, {
                                            classes: {
                                              root: generateVizButtonClassName(value, groupCount, idx)
                                            },
                                            onClick: (function (param) {
                                                return Curry._1(onExpansion, undefined);
                                              }),
                                            children: React.createElement("div", {
                                                  key: name + ("__" + (String(idx) + "__value")),
                                                  className: generateBarClassName(idx, colors, fallbackColor),
                                                  onMouseEnter: (function (param) {
                                                      return Curry._1(setSelection, (function (param) {
                                                                    return /* tuple */[
                                                                            "others",
                                                                            value
                                                                          ];
                                                                  }));
                                                    }),
                                                  onMouseLeave: (function (param) {
                                                      return Curry._1(setSelection, (function (param) {
                                                                    return defaultSelection;
                                                                  }));
                                                    })
                                                }),
                                            key: name + ("_" + (String(idx) + "__button"))
                                          }),
                                      title: React.createElement(React.Fragment, undefined, React.createElement("div", undefined, Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                                                        /* String_literal */Block.__(11, [
                                                            "Rest of values ",
                                                            /* String */Block.__(2, [
                                                                /* No_padding */0,
                                                                /* String_literal */Block.__(11, [
                                                                    " / ",
                                                                    /* String */Block.__(2, [
                                                                        /* No_padding */0,
                                                                        /* String_literal */Block.__(11, [
                                                                            " - ",
                                                                            /* String */Block.__(2, [
                                                                                /* No_padding */0,
                                                                                /* End_of_format */0
                                                                              ])
                                                                          ])
                                                                      ])
                                                                  ])
                                                              ])
                                                          ]),
                                                        "Rest of values %s / %s - %s"
                                                      ]), value$prime, groupCount$prime, percentageString)), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "click to expand visualization"))),
                                      key: name + ("_" + (String(idx) + "__tooltip"))
                                    });
                        }))
                }));
}

var make = React.memo(CondensedDistribution$Proto);

var Proto = {
  createFilterOptions: createFilterOptions,
  getPercentageString: getPercentageString,
  otherValueName: otherValueName,
  make: make
};

function CondensedDistribution(Props) {
  var attribute = Props.attribute;
  var distribution = Props.distribution;
  var groupCount = Props.groupCount;
  var handleAddFilters = Props.handleAddFilters;
  var handleChangeUrl = Props.handleChangeUrl;
  var onExpansion = Props.onExpansion;
  var chartWidthOpt = Props.chartWidth;
  var barHeightOpt = Props.barHeight;
  var chartWidth = chartWidthOpt !== undefined ? chartWidthOpt : 200;
  var barHeight = barHeightOpt !== undefined ? barHeightOpt : 20;
  var match = React.useState((function () {
          return [];
        }));
  var setChartData = match[1];
  var chartData = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setSelection = match$1[1];
  var selection = match$1[0];
  React.useEffect((function () {
          var t = distribution.tail;
          var tailData = t !== undefined ? [/* tuple */[
                "other",
                t
              ]] : [];
          var data = Belt_Array.concat(Belt_Array.map(Belt_List.toArray(distribution.vals), (function (param) {
                      return /* tuple */[
                              FormatUtils$BsConsole.formatToString(param[0]),
                              param[1]
                            ];
                    })), tailData);
          var valuesTotal = Belt_Array.reduce(Belt_Array.map(data, (function (prim) {
                      return prim[1];
                    })), 0, (function (prim, prim$1) {
                  return prim + prim$1 | 0;
                }));
          var maxBarLength = chartWidth - Caml_int32.imul(data.length - 1 | 0, 5) | 0;
          var xScale = getXScale(5, maxBarLength, valuesTotal);
          var chartData$prime = Belt_Array.reduce(data, [], (function (acc, param) {
                  var value = param[1];
                  var x = Belt_Array.reduce(acc, 0, (function (acc, param) {
                          return acc + param.width | 0;
                        }));
                  return Belt_Array.concat(acc, [{
                                name: param[0],
                                width: Curry._1(xScale, value),
                                value: value,
                                x: x,
                                pct: Util$BsConsole.toFormattedPct(value, valuesTotal)
                              }]);
                }));
          Curry._1(setChartData, (function (param) {
                  return chartData$prime;
                }));
          Curry._1(setSelection, (function (param) {
                  return Belt_Array.get(chartData$prime, 0);
                }));
          
        }), [chartWidth]);
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.flexDirection(/* column */-963948842),
                      /* :: */[
                        Css.justifyContent(/* spaceAround */-485895757),
                        /* :: */[
                          Css.paddingLeft(Css.px(16)),
                          /* :: */[
                            Css.paddingRight(Css.px(16)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement(Row$BsConsole.Jsx3.make, {
                  justifyContent: /* spaceBetween */516682146,
                  alignItems: /* center */98248149,
                  wrap: /* nowrap */867913355,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                        /* [] */0
                      ]),
                  children: selection !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Row$BsConsole.Jsx3.make, {
                              className: Css.style(/* :: */[
                                    Css.unsafe("maxWidth", String(chartWidth - 56 | 0) + "px"),
                                    /* [] */0
                                  ]),
                              children: null
                            }, React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.whiteSpace(/* nowrap */867913355),
                                        /* :: */[
                                          Css.textOverflow(/* ellipsis */166888785),
                                          /* :: */[
                                            Css.overflow(/* hidden */-862584982),
                                            /* [] */0
                                          ]
                                        ]
                                      ])
                                }, I18N$BsConsole.showSkip(selection.name)), React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.fontFamily("inconsolata"),
                                        /* :: */[
                                          Css.marginLeft(Css.px(3)),
                                          /* [] */0
                                        ]
                                      ])
                                }, I18N$BsConsole.showSkip("(" + (Numeral$BsConsole.format("0,0", String(selection.value)) + ("/" + (Numeral$BsConsole.format("0,0", String(groupCount)) + ")")))))), React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.fontFamily("inconsolata"),
                                    /* :: */[
                                      Css.marginLeft(Css.rem(1)),
                                      /* [] */0
                                    ]
                                  ])
                            }, I18N$BsConsole.showSkip(selection.pct))) : React.createElement("div", undefined, React.createElement(Body1$BsConsole.Jsx3.make, {
                              value: "\u2014",
                              color: /* Grey */1,
                              whiteSpace: /* nowrap */867913355
                            }))
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.height(Css.px(20)),
                        /* :: */[
                          Css.width(Css.px(chartWidth)),
                          /* :: */[
                            Css.selector("rect", /* :: */[
                                  Css.cursor(/* pointer */-786317123),
                                  /* [] */0
                                ]),
                            /* :: */[
                              Css.selector("rect:hover", /* :: */[
                                    Css.unsafe("fill", "#" + Colors$BsConsole.grey2),
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, React.createElement("svg", {
                      height: "20px",
                      width: String(chartWidth) + "px"
                    }, Belt_Array.mapWithIndex(chartData, (function (i, data) {
                            var fill;
                            switch (i) {
                              case 0 :
                                  fill = "#" + Colors$BsConsole.grey3;
                                  break;
                              case 1 :
                                  fill = "#" + Colors$BsConsole.grey4;
                                  break;
                              case 2 :
                                  fill = "#" + Colors$BsConsole.grey5;
                                  break;
                              default:
                                fill = "#" + Colors$BsConsole.grey6;
                            }
                            var match = data.name;
                            if (match === "other") {
                              return React.createElement("g", {
                                          key: data.name + (String(i) + "__group"),
                                          onClick: (function (param) {
                                              return Curry._1(onExpansion, undefined);
                                            })
                                        }, React.createElement("title", {
                                              key: data.name + (String(i) + "__title")
                                            }, Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                                                      /* String */Block.__(2, [
                                                          /* No_padding */0,
                                                          /* String_literal */Block.__(11, [
                                                              "\n ",
                                                              /* Int */Block.__(4, [
                                                                  /* Int_d */0,
                                                                  /* No_padding */0,
                                                                  /* No_precision */0,
                                                                  /* String_literal */Block.__(11, [
                                                                      " errors - (",
                                                                      /* String */Block.__(2, [
                                                                          /* No_padding */0,
                                                                          /* String_literal */Block.__(11, [
                                                                              ")\n\n(click to expand set)",
                                                                              /* End_of_format */0
                                                                            ])
                                                                        ])
                                                                    ])
                                                                ])
                                                            ])
                                                        ]),
                                                      "%s\n %d errors - (%s)\n\n(click to expand set)"
                                                    ]), data.name, data.value, data.pct)), React.createElement(BsVx_Shape__Bar$BsConsole.Jsx3.make, {
                                              key: data.name + (String(i) + "__bar"),
                                              x: data.x + i | 0,
                                              width: data.width,
                                              height: barHeight,
                                              fill: fill,
                                              onMouseEnter: (function (param) {
                                                  return Curry._1(setSelection, (function (param) {
                                                                return data;
                                                              }));
                                                }),
                                              onMouseLeave: (function (param) {
                                                  return Curry._1(setSelection, (function (param) {
                                                                return Belt_Array.get(chartData, 0);
                                                              }));
                                                })
                                            }));
                            } else {
                              return React.createElement(ValueDrilldown$BsConsole.Jsx3.make, {
                                          key: data.name + (String(i) + "__drilldown"),
                                          handleAddFilters: handleAddFilters,
                                          handleChangeUrl: handleChangeUrl,
                                          filterOptions: /* :: */[
                                            /* tuple */[
                                              attribute,
                                              /* Equal */Block.__(0, [/* `String */[
                                                    -976970511,
                                                    data.name
                                                  ]])
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                attribute,
                                                /* NotEqual */Block.__(1, [/* `String */[
                                                      -976970511,
                                                      data.name
                                                    ]])
                                              ],
                                              /* [] */0
                                            ]
                                          ],
                                          value: data.name,
                                          target: (function (refHandler, onFocus, onClick) {
                                              return React.createElement("g", {
                                                          key: data.name + (String(i) + "__group"),
                                                          ref: refHandler,
                                                          onFocus: onFocus,
                                                          onClick: onClick
                                                        }, React.createElement("title", {
                                                              key: data.name + (String(i) + "__title")
                                                            }, Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                                                                      /* String */Block.__(2, [
                                                                          /* No_padding */0,
                                                                          /* Char_literal */Block.__(12, [
                                                                              /* "\n" */10,
                                                                              /* Int */Block.__(4, [
                                                                                  /* Int_d */0,
                                                                                  /* No_padding */0,
                                                                                  /* No_precision */0,
                                                                                  /* String_literal */Block.__(11, [
                                                                                      " errors - (",
                                                                                      /* String */Block.__(2, [
                                                                                          /* No_padding */0,
                                                                                          /* String_literal */Block.__(11, [
                                                                                              ")\n\n(click for filtering options)",
                                                                                              /* End_of_format */0
                                                                                            ])
                                                                                        ])
                                                                                    ])
                                                                                ])
                                                                            ])
                                                                        ]),
                                                                      "%s\n%d errors - (%s)\n\n(click for filtering options)"
                                                                    ]), data.name, data.value, data.pct)), React.createElement(BsVx_Shape__Bar$BsConsole.Jsx3.make, {
                                                              key: data.name + (String(i) + "__bar"),
                                                              x: data.x + i | 0,
                                                              width: data.width,
                                                              height: barHeight,
                                                              fill: fill,
                                                              onMouseEnter: (function (param) {
                                                                  return Curry._1(setSelection, (function (param) {
                                                                                return data;
                                                                              }));
                                                                }),
                                                              onMouseLeave: (function (param) {
                                                                  return Curry._1(setSelection, (function (param) {
                                                                                return Belt_Array.get(chartData, 0);
                                                                              }));
                                                                })
                                                            }));
                                            })
                                        });
                            }
                          })))));
}

var make$1 = CondensedDistribution;

exports.getXScale = getXScale;
exports.Style = Style;
exports.Proto = Proto;
exports.make = make$1;
/* make Not a pure module */
