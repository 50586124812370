// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Crdb$BsConsole = require("./crdb.js");
var Gate$BsConsole = require("./Gate.js");
var Href$BsConsole = require("./Href.js");
var I18N$BsConsole = require("./I18N.js");
var Page$BsConsole = require("./page.js");
var Task$BsConsole = require("./task.js");
var Util$BsConsole = require("./util.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Error$BsConsole = require("./error.js");
var Regex$BsConsole = require("./Regex.js");
var Route$BsConsole = require("./route.js");
var Task2$BsConsole = require("./Task2.js");
var AppBar$BsConsole = require("./appBar.js");
var Colors$BsConsole = require("./Colors.js");
var Effect$BsConsole = require("./effect.js");
var IFrame$BsConsole = require("./IFrame.js");
var Triage$BsConsole = require("./Triage.js");
var AppCues$BsConsole = require("./AppCues.js");
var BtTheme$BsConsole = require("./BtTheme.js");
var BugRoot$BsConsole = require("./debugger2/BugRoot.js");
var Project$BsConsole = require("./Project.js");
var ReasonReactRouter = require("reason-react/lib/js/src/ReasonReactRouter.js");
var Session$BsConsole = require("./Session.js");
var ZenDesk$BsConsole = require("./ZenDesk.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Fragment$BsConsole = require("./Fragment.js");
var NotFound$BsConsole = require("./NotFound.js");
var Overview$BsConsole = require("./Overview.js");
var Services$BsConsole = require("./Services.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var ApiTokens$BsConsole = require("./project-settings/apiTokens/ApiTokens.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var LoginPage$BsConsole = require("./loginPage.js");
var PS_Alerts$BsConsole = require("./project-settings/alerts/PS_Alerts.js");
var PhoneHome$BsConsole = require("./PhoneHome.js");
var ResetPage$BsConsole = require("./resetPage.js");
var CreatePage$BsConsole = require("./CreatePage.js");
var Entrypoint$BsConsole = require("./entrypoint.js");
var FrozenPage$BsConsole = require("./subscriptions/FrozenPage.js");
var FrozenUtil$BsConsole = require("./subscriptions/FrozenUtil.js");
var PS_Symbols$BsConsole = require("./project-settings/symbols/PS_Symbols.js");
var SignupPage$BsConsole = require("./signupPage.js");
var TeamsUtils$BsConsole = require("./teams/TeamsUtils.js");
var UDashEntry$BsConsole = require("./UDashEntry.js");
var I18N_config$BsConsole = require("./I18N_config.js");
var PS_Overview$BsConsole = require("./project-settings/PS_Overview.js");
var RecoverPage$BsConsole = require("./recoverPage.js");
var TriageUtils$BsConsole = require("./TriageUtils.js");
var ActivatePage$BsConsole = require("./ActivatePage.js");
var AlertsRouter$BsConsole = require("./project-settings/alerts/AlertsRouter.js");
var CoronerdGate$BsConsole = require("./CoronerdGate.js");
var GenericError$BsConsole = require("./GenericError.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var PS_Scrubbers$BsConsole = require("./project-settings/PS_Scrubbers.js");
var Reprocessing$BsConsole = require("./reprocessing/Reprocessing.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var TokenContext$BsConsole = require("./context/TokenContext.js");
var UiPlayground$BsConsole = require("./UiPlayground.js");
var UserLastSeen$BsConsole = require("./UserLastSeen.js");
var WfPlayground$BsConsole = require("./workflow/WfPlayground.js");
var BpgController$BsConsole = require("./BpgController.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var ConfigContext$BsConsole = require("./context/ConfigContext.js");
var Configuration$BsConsole = require("./configuration.js");
var FetchDescribe$BsConsole = require("./FetchDescribe.js");
var InboxWorkflow$BsConsole = require("./inbox/InboxWorkflow.js");
var MetricsMemory$BsConsole = require("./bs-metrics/MetricsMemory.js");
var NormByDefault$BsConsole = require("./NormByDefault.js");
var PS_Attributes$BsConsole = require("./project-settings/PS_Attributes.js");
var PS_Playground$BsConsole = require("./project-settings/PS_Playground.js");
var ProjectUpload$BsConsole = require("./ProjectUpload.js");
var RouterContext$BsConsole = require("./context/RouterContext.js");
var SamlErrorPage$BsConsole = require("./SamlErrorPage.js");
var ShareRedirect$BsConsole = require("./ShareRedirect.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var ViewContainer$BsConsole = require("./ViewContainer.js");
var ApiDemoProject$BsConsole = require("./ApiDemoProject.js");
var SettingsMePage$BsConsole = require("./settingsMePage.js");
var WfActionsModal$BsConsole = require("./workflow/manual-actions/WfActionsModal.js");
var BlockNavigation$BsConsole = require("./BlockNavigation.js");
var CompareReleases$BsConsole = require("./CompareReleases.js");
var DemoProjectUtil$BsConsole = require("./DemoProjectUtil.js");
var ExploreListData$BsConsole = require("./ExploreListData.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");
var MetricsRemember$BsConsole = require("./bs-metrics/MetricsRemember.js");
var PS_DedupeConfig$BsConsole = require("./project-settings/dedupe/PS_DedupeConfig.js");
var PS_SymbolSearch$BsConsole = require("./project-settings/symbols/PS_SymbolSearch.js");
var ProjectWorkflow$BsConsole = require("./workflow/ProjectWorkflow.js");
var SettingsBilling$BsConsole = require("./SettingsBilling.js");
var WindowVariables$BsConsole = require("./WindowVariables.js");
var WorkflowVersion$BsConsole = require("./workflow/WorkflowVersion.js");
var BacktraceSession$BsConsole = require("./BacktraceSession.js");
var ExploreAggregate$BsConsole = require("./ExploreAggregate.js");
var PS_AccessControl$BsConsole = require("./project-settings/PS_AccessControl.js");
var PS_SymbolServers$BsConsole = require("./project-settings/symbols/PS_SymbolServers.js");
var PS_SymbolsUpload$BsConsole = require("./project-settings/symbols/PS_SymbolsUpload.js");
var ProjectInstances$BsConsole = require("./projectInstances.js");
var SourceCodeConfig$BsConsole = require("./project-settings/SourceCodeConfig.js");
var SubInviteNewUser$BsConsole = require("./subscriptions/SubInviteNewUser.js");
var WorkflowRedirect$BsConsole = require("./WorkflowRedirect.js");
var AttributesContext$BsConsole = require("./context/AttributesContext.js");
var DemoProjectPrompt$BsConsole = require("./DemoProjectPrompt.js");
var PS_MissingSymbols$BsConsole = require("./project-settings/symbols/PS_MissingSymbols.js");
var ProjectFlamegraph$BsConsole = require("./ProjectFlamegraph.js");
var SavedQueriesModel$BsConsole = require("./saved-queries/SavedQueriesModel.js");
var SettingsTeamsPage$BsConsole = require("./SettingsTeamsPage.js");
var SettingsUsersPage$BsConsole = require("./settingsUsersPage.js");
var BillingLimitsFetch$BsConsole = require("./BillingLimitsFetch.js");
var CreateFirstProject$BsConsole = require("./CreateFirstProject.js");
var MessageFeatureGate$BsConsole = require("./MessageFeatureGate.js");
var PS_MetricsExporter$BsConsole = require("./project-settings/PS_MetricsExporter.js");
var PS_RetentionPolicy$BsConsole = require("./project-settings/PS_RetentionPolicy.js");
var PS_StorageSampling$BsConsole = require("./project-settings/PS_StorageSampling.js");
var ProjectNameContext$BsConsole = require("./context/ProjectNameContext.js");
var SettingsSamlConfig$BsConsole = require("./SettingsSamlConfig.js");
var FirstTimeUnityModal$BsConsole = require("./FirstTimeUnityModal.js");
var MetricsGenericEvent$BsConsole = require("./bs-metrics/MetricsGenericEvent.js");
var PS_Documentation_v2$BsConsole = require("./project-settings/PS_Documentation_v2.js");
var PS_ScheduledReports$BsConsole = require("./project-settings/PS_ScheduledReports.js");
var ProjectQueryBuilder$BsConsole = require("./query-builder/projectQueryBuilder.js");
var SavedQueriesContext$BsConsole = require("./saved-queries/SavedQueriesContext.js");
var SettingsInvitesPage$BsConsole = require("./settingsInvitesPage.js");
var PS_ServerSideActions$BsConsole = require("./project-settings/serversideactions/PS_ServerSideActions.js");
var SettingsBillingPlans$BsConsole = require("./SettingsBillingPlans.js");
var SettingsProjectsPage$BsConsole = require("./settingsProjectsPage.js");
var Wf2IntegrationRouter$BsConsole = require("./workflow/Wf2IntegrationRouter.js");
var OrgSettingsNavigation$BsConsole = require("./OrgSettingsNavigation.js");
var PS_SymbolAccessTokens$BsConsole = require("./project-settings/symbols/PS_SymbolAccessTokens.js");
var SettingsSubscriptions$BsConsole = require("./subscriptions/SettingsSubscriptions.js");
var SettingsWhitelistPage$BsConsole = require("./settingsWhitelistPage.js");
var SimilarityProjectView$BsConsole = require("./similarity/SimilarityProjectView.js");
var L10N_date_time_formats$BsConsole = require("./L10N_date_time_formats.js");
var PS_StabilityMonitoring$BsConsole = require("./project-settings/PS_StabilityMonitoring.js");
var SauceLabsLoginRedirect$BsConsole = require("./SauceLabsLoginRedirect.js");
var SettingsNetworkingPage$BsConsole = require("./settingsNetworkingPage.js");
var L10N_date_time_settings$BsConsole = require("./L10N_date_time_settings.js");
var RenderIfBelowErrorCount$BsConsole = require("./RenderIfBelowErrorCount.js");
var SettingsProjectsNewPage$BsConsole = require("./SettingsProjectsNewPage.js");
var SettingsRetentionPolicy$BsConsole = require("./SettingsRetentionPolicy.js");
var PS_ErrorSubmissionTokens$BsConsole = require("./project-settings/PS_ErrorSubmissionTokens.js");
var ResetScrollOnRouteUpdate$BsConsole = require("./ResetScrollOnRouteUpdate.js");
var SettingsProjectsForkPage$BsConsole = require("./settingsProjectsForkPage.js");
var BillingLimitsCallToAction$BsConsole = require("./BillingLimitsCallToAction.js");
var ProjectSettingsNavigation$BsConsole = require("./ProjectSettingsNavigation.js");
var SettingsNetworkingSSLPage$BsConsole = require("./settingsNetworkingSSLPage.js");
var PS_SymbolsUploadedArchives$BsConsole = require("./project-settings/symbols/PS_SymbolsUploadedArchives.js");
var PS_ErrorSubmissionDataSources$BsConsole = require("./project-settings/PS_ErrorSubmissionDataSources.js");
var SettingsWfConnectionManagement$BsConsole = require("./SettingsWfConnectionManagement.js");
var OrgSettingsNavBreadcrumbToolbar$BsConsole = require("./OrgSettingsNavBreadcrumbToolbar.js");
var SettingsNetworkingListenersPage$BsConsole = require("./settingsNetworkingListenersPage.js");
var ProjectSettingsNavBreadcrumbToolbar$BsConsole = require("./ProjectSettingsNavBreadcrumbToolbar.js");

var TooManyRedirects = Caml_exceptions.create("Bridge-BsConsole.TooManyRedirects");

var settingsViewContainer;

var exit = 0;

if (IdentityVariant$BsConsole.current >= 2 && IFrame$BsConsole.isIFrame) {
  settingsViewContainer = Css.style(/* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.width(Css.pct(100)),
          /* :: */[
            Css.unsafe("minHeight", "calc(100vh - 121px)"),
            /* :: */[
              Css.padding2(Css.rem(1), /* zero */-789508312),
              /* [] */0
            ]
          ]
        ]
      ]);
} else {
  exit = 1;
}

if (exit === 1) {
  settingsViewContainer = Css.style(/* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.width(Css.pct(100)),
          /* :: */[
            Css.unsafe("minHeight", "calc(100vh - 121px)"),
            /* :: */[
              Css.padding2(Css.rem(1), Css.rem(2)),
              /* [] */0
            ]
          ]
        ]
      ]);
}

var Style = {
  settingsViewContainer: settingsViewContainer
};

var component = ReasonReact.reducerComponent("Bridge");

function initialize(param) {
  var token = localStorage.getItem("token");
  if (token !== null) {
    return /* Authenticated */[token];
  } else {
    return /* NotAuthenticated */0;
  }
}

function runRequest(request) {
  return request.then((function (response) {
                return response.json();
              }));
}

var redirectCount = {
  contents: 0
};

var hasBannered = {
  contents: false
};

function setHasBannered(param) {
  hasBannered.contents = true;
  
}

function sendWelcomeBanner(auth, param, shell, param$1, param$2) {
  hasBannered.contents;
  
}

function sanitizeNormBy(config, projectName, normBy) {
  if (normBy !== undefined) {
    if (normBy === "_none") {
      return ;
    } else {
      return normBy;
    }
  } else {
    return NormByDefault$BsConsole.get(projectName, config);
  }
}

function make(_children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              Task2$BsConsole.onInvalidToken((function (param) {
                      return Curry._1(self.send, /* HandleExpiredToken */2);
                    }));
              Effect$BsConsole.initializeRouter(undefined);
              var initialUrl = ReasonReactRouter.dangerouslyGetInitialUrl(undefined);
              try {
                var __x = Regex$BsConsole.matchIntegrationParams(initialUrl.search);
                var integrationMatches = Belt_Option.getWithDefault(__x, []);
                if (integrationMatches.length === 2) {
                  var json = Json.parse(atob(Caml_array.caml_array_get(integrationMatches, 1)));
                  if (json !== undefined) {
                    var params = Json_decode.array(MetricsGenericEvent$BsConsole.fromJson, Caml_option.valFromOption(json));
                    if (params.length === 1) {
                      MetricsEvent$BsConsole.queueEvent(/* GenericEvent */Block.__(2, [Caml_array.caml_array_get(params, 0)]));
                    }
                    
                  }
                  
                }
                
              }
              catch (exn){
                
              }
              Curry._1(self.send, /* UrlChange */Block.__(8, [initialUrl]));
              var routerSubscription = ReasonReactRouter.watchUrl((function (url) {
                      return Curry._1(self.send, /* UrlChange */Block.__(8, [url]));
                    }));
              return Curry._1(self.onUnmount, (function (param) {
                            return ReasonReactRouter.unwatchUrl(routerSubscription);
                          }));
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var redirect = function (route) {
                return Curry._1(self.send, /* UpdateUrl */Block.__(9, [route]));
              };
              var handleBack = function (param) {
                return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* Shell */3]));
              };
              var handleTask = function (cb, task) {
                return Curry._1(self.send, /* HandleTask */Block.__(13, [
                              cb,
                              task
                            ]));
              };
              var handleLogout = function (param) {
                var token = self.state.auth;
                if (token) {
                  return Curry._1(self.send, /* Logout */Block.__(14, [
                                token[0],
                                undefined
                              ]));
                } else {
                  return Curry._1(self.send, /* Logout */Block.__(14, [
                                undefined,
                                undefined
                              ]));
                }
              };
              var handleChangeUrl = function (nextroute) {
                var match = Belt_Option.getWithDefault(Belt_Option.map(self.state.route, (function (r) {
                            if (typeof r === "number") {
                              return false;
                            }
                            switch (r.tag | 0) {
                              case /* ProjectTriage */11 :
                                  if (r[2] !== undefined) {
                                    return false;
                                  } else {
                                    return !Curry._2(Crdb$BsConsole.Havings.eq, r[1].havings, Crdb$BsConsole.Havings.empty);
                                  }
                              case /* ProjectQueryBuilder */13 :
                                  return !Curry._2(Crdb$BsConsole.Havings.eq, r[1].havings, Crdb$BsConsole.Havings.empty);
                              default:
                                return false;
                            }
                          })), false);
                var exit = 0;
                if (match && typeof nextroute !== "number") {
                  switch (nextroute.tag | 0) {
                    case /* ProjectInstances */15 :
                    case /* ProjectDebugger */17 :
                    case /* ProjectDebugger2 */19 :
                    case /* ProjectFlamegraph */20 :
                        exit = 1;
                        break;
                    default:
                      
                  }
                }
                if (exit === 1) {
                  var supportLink = Href$BsConsole.Support.make(undefined, /* WhyAreAggregateFiltersRemovedInCertainViews */26);
                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, 10000, undefined, supportLink !== undefined ? React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Aggregation filters were removed for this view. Learn more "), React.createElement("a", {
                                  className: Css.style(/* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.accentLight)),
                                        /* :: */[
                                          Css.hover(/* :: */[
                                                Css.color(Css.hex(Colors$BsConsole.accentLight)),
                                                /* [] */0
                                              ]),
                                          /* :: */[
                                            Css.visited(/* :: */[
                                                  Css.color(Css.hex(Colors$BsConsole.accentLight)),
                                                  /* [] */0
                                                ]),
                                            /* :: */[
                                              Css.fontWeight(/* `num */[
                                                    5496390,
                                                    500
                                                  ]),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]),
                                  href: supportLink,
                                  rel: "noopener noreferrer",
                                  target: "_blank"
                                }, I18N$BsConsole.show(undefined, "here")), I18N$BsConsole.showSkip(".")) : React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Aggregation filters were removed for this view.")));
                }
                return Curry._1(self.send, /* UpdateUrl */Block.__(9, [nextroute]));
              };
              var handleReplaceUrl = function (route) {
                return Curry._1(self.send, /* ReplaceUrl */Block.__(10, [route]));
              };
              var handleAddFilters = function (filters) {
                var route = self.state.route;
                if (route === undefined) {
                  return /* Recover */1;
                }
                if (typeof route === "number") {
                  return /* Recover */1;
                }
                switch (route.tag | 0) {
                  case /* ProjectOverview */8 :
                      var qs = route[1];
                      var filterMap = $$Array.of_list(filters).reduce((function (filterMap, param) {
                              return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                              param[0],
                                              param[1]
                                            ]), filterMap);
                            }), Curry._1(Crdb$BsConsole.Aperture.getFilters, qs.aperture));
                      return /* ProjectOverview */Block.__(8, [
                                route[0],
                                {
                                  aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap, qs.aperture)
                                }
                              ]);
                  case /* ProjectCompareReleases */9 :
                      var qs$1 = route[2];
                      var filterMap$1 = $$Array.of_list(filters).reduce((function (filterMap, param) {
                              return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                              param[0],
                                              param[1]
                                            ]), filterMap);
                            }), Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$1.aperture));
                      return /* ProjectCompareReleases */Block.__(9, [
                                route[0],
                                route[1],
                                {
                                  aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap$1, qs$1.aperture),
                                  groups: qs$1.groups
                                }
                              ]);
                  case /* ProjectTriage */11 :
                      var qs$2 = route[1];
                      var filterMap$2 = $$Array.of_list(filters).reduce((function (filterMap, param) {
                              return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                              param[0],
                                              param[1]
                                            ]), filterMap);
                            }), Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$2.aperture));
                      return /* ProjectTriage */Block.__(11, [
                                route[0],
                                {
                                  aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap$2, qs$2.aperture),
                                  sort: qs$2.sort,
                                  stats: qs$2.stats,
                                  havings: qs$2.havings,
                                  fold: qs$2.fold,
                                  normBy: qs$2.normBy,
                                  similarity: qs$2.similarity
                                },
                                route[2]
                              ]);
                  case /* ProjectQueryBuilder */13 :
                      var qs$3 = route[1];
                      var filterMap$3 = $$Array.of_list(filters).reduce((function (filterMap, param) {
                              return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                              param[0],
                                              param[1]
                                            ]), filterMap);
                            }), Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$3.aperture));
                      return /* ProjectQueryBuilder */Block.__(13, [
                                route[0],
                                {
                                  aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap$3, qs$3.aperture),
                                  factor: qs$3.factor,
                                  havings: qs$3.havings,
                                  fold: qs$3.fold,
                                  sort: qs$3.sort,
                                  normBy: qs$3.normBy,
                                  highlightExporter: qs$3.highlightExporter
                                }
                              ]);
                  case /* ProjectInstances */15 :
                      var qs$4 = route[1];
                      var filterMap$4 = $$Array.of_list(filters).reduce((function (filterMap, param) {
                              return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                              param[0],
                                              param[1]
                                            ]), filterMap);
                            }), Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$4.aperture));
                      return /* ProjectInstances */Block.__(15, [
                                route[0],
                                {
                                  aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap$4, qs$4.aperture),
                                  select: qs$4.select,
                                  sort: qs$4.sort
                                }
                              ]);
                  case /* ProjectDebugger */17 :
                      var qs$5 = route[1];
                      var filterMap$5 = $$Array.of_list(filters).reduce((function (filterMap, param) {
                              return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                              param[0],
                                              param[1]
                                            ]), filterMap);
                            }), Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$5.aperture));
                      return /* ProjectDebugger */Block.__(17, [
                                route[0],
                                {
                                  aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap$5, qs$5.aperture),
                                  sort: qs$5.sort,
                                  s: qs$5.s
                                }
                              ]);
                  case /* ProjectFlamegraph */20 :
                      var qs$6 = route[1];
                      var filterMap$6 = $$Array.of_list(filters).reduce((function (filterMap, param) {
                              return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                              param[0],
                                              param[1]
                                            ]), filterMap);
                            }), Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$6.aperture));
                      return /* ProjectFlamegraph */Block.__(20, [
                                route[0],
                                {
                                  aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap$6, qs$6.aperture),
                                  weighted: qs$6.weighted,
                                  reversed: qs$6.reversed
                                }
                              ]);
                  case /* ProjectSimilarity */21 :
                      return /* ProjectSimilarity */Block.__(21, [
                                route[0],
                                route[1]
                              ]);
                  default:
                    return /* Recover */1;
                }
              };
              var renderAuthenticatedRoute = function (route, token) {
                Backtrace$BsConsole.Client.memorize("route.name", Route$BsConsole.toString(route));
                var tmp;
                var exit = 0;
                if (typeof route === "number") {
                  switch (route) {
                    case /* CreateFirstProject */2 :
                        var refetchShell = function (token) {
                          return Curry._1(self.send, /* RefetchShellRequest */Block.__(2, [token]));
                        };
                        var config = self.state.shell;
                        var exit$1 = 0;
                        if (typeof config === "number" || config.tag) {
                          exit$1 = 2;
                        } else {
                          tmp = ReasonReact.element(undefined, undefined, BillingLimitsFetch$BsConsole.make(config[0].universe.name, handleTask, token, (function (limitsRemote, param) {
                                      return ReasonReact.element(undefined, undefined, Page$BsConsole.make(token, handleChangeUrl, route, self.state.shell, handleBack, handleTask, handleLogout, ReasonReact.element(undefined, undefined, CreateFirstProject$BsConsole.make(self.state.shell, limitsRemote, token, handleTask, handleChangeUrl, refetchShell, []))));
                                    })));
                        }
                        if (exit$1 === 2) {
                          tmp = ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, "inherit", undefined, undefined, []));
                        }
                        break;
                    case /* Signup */0 :
                    case /* Recover */1 :
                    case /* Shell */3 :
                        tmp = null;
                        break;
                    case /* Settings */4 :
                        tmp = ReasonReact.element(undefined, undefined, Page$BsConsole.make(token, handleChangeUrl, route, self.state.shell, handleBack, handleTask, handleLogout, React.createElement("div", undefined)));
                        break;
                    case /* SettingsBillingPlans */19 :
                        tmp = ReasonReact.element(undefined, undefined, Page$BsConsole.make(token, handleChangeUrl, route, self.state.shell, handleBack, handleTask, handleLogout, ReasonReact.element(undefined, undefined, SettingsBillingPlans$BsConsole.make(handleChangeUrl, handleTask, self.state.shell, token, []))));
                        break;
                    case /* SettingsMe */5 :
                    case /* SettingsUsers */6 :
                    case /* SettingsTeams */7 :
                    case /* SettingsProjects */8 :
                    case /* SettingsProjectsNew */9 :
                    case /* SettingsProjectsFork */10 :
                    case /* SettingsInvites */11 :
                    case /* SettingsInvitesNew */12 :
                    case /* SettingsNetworking */13 :
                    case /* SettingsRetentionPolicy */14 :
                    case /* SettingsNetworkingSSL */15 :
                    case /* SettingsNetworkingListeners */16 :
                    case /* SettingsWhitelist */17 :
                    case /* SettingsBilling */18 :
                    case /* SettingsSubscriptions */20 :
                    case /* SettingsSamlConfig */21 :
                        exit = 1;
                        break;
                    case /* WfPlayground */22 :
                        tmp = ReasonReact.element(undefined, undefined, WfPlayground$BsConsole.make(Json_encode.object_(/* [] */0), token, []));
                        break;
                    case /* UiPlayground */23 :
                        tmp = ReasonReact.element(undefined, undefined, UiPlayground$BsConsole.Re.make([]));
                        break;
                    
                  }
                } else {
                  switch (route.tag | 0) {
                    case /* UDashEntry */2 :
                        var match = route[0];
                        var referrer = match.referrer;
                        var project = match.project;
                        tmp = ReasonReact.element(undefined, undefined, UDashEntry$BsConsole.Jsx2.make((function (token$prime) {
                                    return Curry._1(self.send, /* LoginSuccess */Block.__(12, [
                                                  token$prime,
                                                  referrer,
                                                  project
                                                ]));
                                  }), match.token, match.email, project, []));
                        break;
                    case /* Share */4 :
                        var defaultShare = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(window._BACKTRACE_SHARE), "https://share.backtrace.io") + "/api/share";
                        var config$1 = self.state.shell;
                        if (typeof config$1 === "number" || config$1.tag) {
                          tmp = null;
                        } else {
                          var serviceUrl = Belt_Option.getWithDefault(Services$BsConsole.getEndpoint(undefined)(config$1[0].services, "share"), defaultShare);
                          tmp = ReasonReact.element(undefined, undefined, ShareRedirect$BsConsole.make(serviceUrl, route[0].redirect, handleChangeUrl, token, []));
                        }
                        break;
                    case /* SettingsWfConnectionManagement */6 :
                        exit = 1;
                        break;
                    case /* ProjectOverview */8 :
                        var aperture = route[1].aperture;
                        var projectName = route[0];
                        var config$2 = self.state.shell;
                        if (typeof config$2 === "number" || config$2.tag) {
                          tmp = null;
                        } else {
                          var config$3 = config$2[0];
                          tmp = ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(undefined, [ReasonReact.element(undefined, undefined, Curry._4(SavedQueriesContext$BsConsole.Provider.Jsx2.make, projectName, token, config$3, [
                                              ReasonReact.element("AppBar__" + projectName, undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(aperture), undefined, undefined, undefined, route, handleChangeUrl, handleLogout, projectName, self.state.shell, [])),
                                              ReasonReact.element(undefined, undefined, RenderIfBelowErrorCount$BsConsole.Jsx2.make(token, projectName, aperture, (function (param) {
                                                          return ReasonReact.element(undefined, undefined, Overview$BsConsole.Re.make(aperture, projectName, token, config$3, route, handleChangeUrl, handleAddFilters, []));
                                                        }), []))
                                            ]))]));
                        }
                        break;
                    case /* ProjectCompareReleases */9 :
                        var match$1 = route[2];
                        var groups = match$1.groups;
                        var aperture$1 = match$1.aperture;
                        var compareAttribute = route[1];
                        var projectName$1 = route[0];
                        var config$4 = self.state.shell;
                        if (typeof config$4 === "number" || config$4.tag) {
                          tmp = null;
                        } else {
                          var config$5 = config$4[0];
                          tmp = ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(undefined, [ReasonReact.element(undefined, undefined, Curry._4(SavedQueriesContext$BsConsole.Provider.Jsx2.make, projectName$1, token, config$5, [
                                              ReasonReact.element("AppBar__" + projectName$1, undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(aperture$1), undefined, undefined, undefined, route, handleChangeUrl, handleLogout, projectName$1, self.state.shell, [])),
                                              ReasonReact.element(undefined, undefined, RenderIfBelowErrorCount$BsConsole.Jsx2.make(token, projectName$1, aperture$1, (function (param) {
                                                          return ReasonReact.element(undefined, undefined, CompareReleases$BsConsole.Re.make(aperture$1, projectName$1, groups, token, config$5, route, handleChangeUrl, handleReplaceUrl, handleAddFilters, compareAttribute, []));
                                                        }), []))
                                            ]))]));
                        }
                        break;
                    case /* ProjectUpload */10 :
                        var projectName$2 = route[0];
                        var config$6 = self.state.shell;
                        tmp = typeof config$6 === "number" || config$6.tag ? null : ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(undefined, [ReasonReact.element(undefined, undefined, Curry._4(SavedQueriesContext$BsConsole.Provider.Jsx2.make, projectName$2, token, config$6[0], [
                                              ReasonReact.element("AppBar__" + projectName$2, undefined, AppBar$BsConsole.make(token, handleTask, undefined, undefined, undefined, undefined, route, handleChangeUrl, handleLogout, projectName$2, self.state.shell, [])),
                                              ReasonReact.element(undefined, undefined, ProjectUpload$BsConsole.make(handleChangeUrl, token, projectName$2, self.state.shell, []))
                                            ]))]));
                        break;
                    case /* ProjectTriage */11 :
                        var maybeWfLinkTx = route[2];
                        var qs = route[1];
                        var projectName$3 = route[0];
                        var isDetailView = InboxWorkflow$BsConsole.Folders.isDetailView(qs.aperture);
                        var view = isDetailView ? /* Details */1 : /* List */0;
                        var mode = SavedQueriesModel$BsConsole.modeOfStringExn("inbox");
                        var query = TriageUtils$BsConsole.makeQuery(qs.stats, qs.aperture, qs.havings, view, qs.sort, /* [] */0, qs.fold);
                        var config$7 = self.state.shell;
                        var tmp$1;
                        if (typeof config$7 === "number" || config$7.tag) {
                          tmp$1 = null;
                        } else {
                          var config$8 = config$7[0];
                          tmp$1 = ReasonReact.element(undefined, undefined, Curry._3(RouterContext$BsConsole.Provider.Re.make, handleChangeUrl, handleReplaceUrl, ReasonReact.element(undefined, undefined, Curry._4(SavedQueriesContext$BsConsole.Provider.Jsx2.make, projectName$3, token, config$8, [ReasonReact.element(undefined, undefined, Mui$BsConsole.MuiThemeProvider.make(BtTheme$BsConsole.theme, [
                                                      ReasonReact.element("AppBar__" + projectName$3, undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(qs.aperture), undefined, query, mode, route, handleChangeUrl, handleLogout, projectName$3, self.state.shell, [])),
                                                      ReasonReact.element(undefined, undefined, RenderIfBelowErrorCount$BsConsole.Jsx2.make(token, projectName$3, qs.aperture, (function (param) {
                                                                  return ReasonReact.element(undefined, undefined, Triage$BsConsole.Re.make(token, handleChangeUrl, route, config$8, handleTask, projectName$3, qs.aperture, qs.stats, qs.fold, qs.sort, qs.havings, sanitizeNormBy(config$8, projectName$3, qs.normBy), qs.similarity, handleAddFilters, maybeWfLinkTx, []));
                                                                }), []))
                                                    ]))]))));
                        }
                        tmp = ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(undefined, [tmp$1]));
                        break;
                    case /* ProjectQueryBuilder */13 :
                        var qs$1 = route[1];
                        var projectName$4 = route[0];
                        var query$1 = ProjectQueryBuilder$BsConsole.makeQuery(qs$1.aperture, qs$1.factor, qs$1.fold, qs$1.sort, Caml_option.some(qs$1.havings));
                        var mode$1 = SavedQueriesModel$BsConsole.modeOfStringExn("aggregate");
                        var config$9 = self.state.shell;
                        if (typeof config$9 === "number" || config$9.tag) {
                          tmp = null;
                        } else {
                          var config$10 = config$9[0];
                          tmp = ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(undefined, [ReasonReact.element(undefined, undefined, Curry._4(SavedQueriesContext$BsConsole.Provider.Jsx2.make, projectName$4, token, config$10, [
                                              ReasonReact.element("AppBar__" + projectName$4, undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(qs$1.aperture), undefined, query$1, mode$1, route, handleChangeUrl, handleLogout, projectName$4, self.state.shell, [])),
                                              ReasonReact.element(undefined, undefined, RenderIfBelowErrorCount$BsConsole.Jsx2.make(token, projectName$4, qs$1.aperture, (function (param) {
                                                          return ReasonReact.element(undefined, undefined, Mui$BsConsole.MuiThemeProvider.make(BtTheme$BsConsole.theme, [ReasonReact.element(undefined, undefined, ExploreAggregate$BsConsole.Re.make(token, handleChangeUrl, route, config$10, projectName$4, qs$1.aperture, qs$1.fold, qs$1.sort, qs$1.havings, sanitizeNormBy(config$10, projectName$4, qs$1.normBy), handleAddFilters, handleTask, qs$1.factor, qs$1.highlightExporter, []))]));
                                                        }), []))
                                            ]))]));
                        }
                        break;
                    case /* ProjectInstances */15 :
                        var qs$2 = route[1];
                        var projectName$5 = route[0];
                        var query$2 = ExploreListData$BsConsole.makeQuery(qs$2.aperture, qs$2.select, qs$2.sort);
                        var mode$2 = SavedQueriesModel$BsConsole.modeOfStringExn("instance");
                        var config$11 = self.state.shell;
                        var tmp$2;
                        if (typeof config$11 === "number" || config$11.tag) {
                          tmp$2 = null;
                        } else {
                          var config$12 = config$11[0];
                          tmp$2 = ReasonReact.element(undefined, undefined, Curry._4(SavedQueriesContext$BsConsole.Provider.Jsx2.make, projectName$5, token, config$12, [ReasonReact.element(undefined, undefined, Mui$BsConsole.MuiThemeProvider.make(BtTheme$BsConsole.theme, [
                                              ReasonReact.element("AppBar__" + projectName$5, undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(qs$2.aperture), undefined, query$2, mode$2, route, handleChangeUrl, handleLogout, projectName$5, self.state.shell, [])),
                                              ReasonReact.element(undefined, undefined, ProjectInstances$BsConsole.Re.make(qs$2.sort, projectName$5, qs$2.aperture, handleTask, token, handleChangeUrl, qs$2.select, handleAddFilters, config$12, route, []))
                                            ]))]));
                        }
                        tmp = ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(undefined, [tmp$2]));
                        break;
                    case /* ProjectDebugger2 */19 :
                        var qs$3 = route[1];
                        var projectName$6 = route[0];
                        tmp = ReasonReact.element(undefined, undefined, FetchDescribe$BsConsole.Disabled.make(handleTask, token, projectName$6, (function (remote, param) {
                                    var match = self.state.shell;
                                    if (typeof remote === "number") {
                                      return null;
                                    }
                                    if (remote.tag) {
                                      return null;
                                    }
                                    if (typeof match === "number") {
                                      return null;
                                    }
                                    if (match.tag) {
                                      return null;
                                    }
                                    var config = match[0];
                                    var attributes = remote[0];
                                    return ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(undefined, [ReasonReact.element(undefined, undefined, Curry._4(SavedQueriesContext$BsConsole.Provider.Jsx2.make, projectName$6, token, config, [
                                                              ReasonReact.element("AppBar__" + projectName$6, undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(qs$3.aperture), undefined, undefined, undefined, route, handleChangeUrl, handleLogout, projectName$6, self.state.shell, [])),
                                                              ReasonReact.element(undefined, undefined, RenderIfBelowErrorCount$BsConsole.Jsx2.make(token, projectName$6, qs$3.aperture, (function (param) {
                                                                          return ReasonReact.element(undefined, undefined, Curry._2(ConfigContext$BsConsole.Provider.Re.make, config, ReasonReact.element(undefined, undefined, Curry._2(ProjectNameContext$BsConsole.Provider.Re.make, projectName$6, ReasonReact.element(undefined, undefined, Curry._3(RouterContext$BsConsole.Provider.Re.make, handleChangeUrl, handleReplaceUrl, ReasonReact.element(undefined, undefined, Curry._2(AttributesContext$BsConsole.Provider.Re.make, attributes, ReasonReact.element(undefined, undefined, BugRoot$BsConsole.Re.make(projectName$6, config, qs$3.aperture, qs$3.state, route, /* Details */1, token, []))))))))));
                                                                        }), []))
                                                            ]))]));
                                  })));
                        break;
                    case /* ProjectFlamegraph */20 :
                        var qs$4 = route[1];
                        var projectName$7 = route[0];
                        var config$13 = self.state.shell;
                        var tmp$3;
                        if (typeof config$13 === "number" || config$13.tag) {
                          tmp$3 = null;
                        } else {
                          var config$14 = config$13[0];
                          tmp$3 = ReasonReact.element(undefined, undefined, Curry._4(SavedQueriesContext$BsConsole.Provider.Jsx2.make, projectName$7, token, config$14, [ReasonReact.element(undefined, undefined, Mui$BsConsole.MuiThemeProvider.make(BtTheme$BsConsole.theme, [
                                              ReasonReact.element("AppBar__" + projectName$7, undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(qs$4.aperture), undefined, undefined, undefined, route, handleChangeUrl, handleLogout, projectName$7, self.state.shell, [])),
                                              ReasonReact.element(projectName$7, undefined, ProjectFlamegraph$BsConsole.Re.make(config$14, qs$4.aperture, handleTask, qs$4.weighted, qs$4.reversed, projectName$7, token, handleChangeUrl, handleAddFilters, route, []))
                                            ]))]));
                        }
                        tmp = ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(undefined, [tmp$3]));
                        break;
                    case /* ProjectSimilarity */21 :
                        var qs$5 = route[1];
                        var projectName$8 = route[0];
                        var config$15 = self.state.shell;
                        var tmp$4;
                        if (typeof config$15 === "number" || config$15.tag) {
                          tmp$4 = null;
                        } else {
                          var config$16 = config$15[0];
                          tmp$4 = ReasonReact.element(undefined, undefined, Curry._4(SavedQueriesContext$BsConsole.Provider.Jsx2.make, projectName$8, token, config$16, [ReasonReact.element(undefined, undefined, Mui$BsConsole.MuiThemeProvider.make(BtTheme$BsConsole.theme, [
                                              ReasonReact.element("AppBar__" + projectName$8, undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(qs$5.aperture), undefined, undefined, undefined, route, handleChangeUrl, handleLogout, projectName$8, self.state.shell, [])),
                                              ReasonReact.element(projectName$8, undefined, SimilarityProjectView$BsConsole.Re.make(config$16, qs$5.aperture, projectName$8, token, handleChangeUrl, route, handleTask, []))
                                            ]))]));
                        }
                        tmp = ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(undefined, [tmp$4]));
                        break;
                    case /* Workflow */54 :
                        var config$17 = self.state.shell;
                        if (typeof config$17 === "number" || config$17.tag) {
                          tmp = null;
                        } else {
                          var config$18 = config$17[0];
                          var lastProj = Belt_Option.flatMap(config$18.lastProjectPid, (function (pid) {
                                  return Belt_List.getBy(config$18.projects, (function (project) {
                                                return project.pid === pid;
                                              }));
                                }));
                          tmp = ReasonReact.element(undefined, undefined, WorkflowRedirect$BsConsole.Jsx2.make(route[0].nonce, handleChangeUrl, config$18.projects, token, lastProj, config$18, []));
                        }
                        break;
                    case /* Reset */0 :
                    case /* CreateAccount */1 :
                    case /* Login */3 :
                    case /* Activate */5 :
                    case /* Project */7 :
                    case /* ProjectInboxLegacy1 */12 :
                    case /* ProjectQueryBuilderLegacy1 */14 :
                    case /* ProjectInstancesLegacy1 */16 :
                    case /* ProjectDebugger */17 :
                    case /* ProjectDebuggerLegacy1 */18 :
                    case /* RedirectLegacyDebugger */51 :
                    case /* RedirectLegacyDetailView */52 :
                    case /* RedirectLegacyQueryBuilder */53 :
                    case /* SamlError */55 :
                        tmp = null;
                        break;
                    case /* NotFound */56 :
                        tmp = ReasonReact.element(undefined, undefined, NotFound$BsConsole.Jsx2.make(route[0], undefined, []));
                        break;
                    case /* Error */57 :
                        tmp = ReasonReact.element(undefined, undefined, GenericError$BsConsole.Jsx2.make(route[0], []));
                        break;
                    case /* GenerateDemoProject */58 :
                        var config$19 = self.state.shell;
                        var tmp$5;
                        tmp$5 = typeof config$19 === "number" || config$19.tag ? null : ReasonReact.element(undefined, undefined, Curry._5(DemoProjectPrompt$BsConsole.LoadingState.Jsx2.make, config$19[0], token, ApiDemoProject$BsConsole.Platform.stringToType(route[0]), handleChangeUrl, []));
                        tmp = ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(undefined, [tmp$5]));
                        break;
                    default:
                      var name = route[0];
                      tmp = ReasonReact.element(undefined, undefined, Project$BsConsole.Fetch.make(name, token, handleTask, (function (remote, _updater) {
                                  if (typeof remote === "number") {
                                    return null;
                                  }
                                  if (remote.tag) {
                                    return ReasonReact.element(undefined, undefined, NotFound$BsConsole.Jsx2.make("/not-found", /* `string */[
                                                    288368849,
                                                    remote[0]
                                                  ], []));
                                  }
                                  var project = remote[0];
                                  var config = self.state.shell;
                                  if (typeof config === "number") {
                                    return null;
                                  }
                                  if (config.tag) {
                                    return null;
                                  }
                                  var config$1 = config[0];
                                  var tmp;
                                  if (typeof route === "number") {
                                    tmp = null;
                                  } else {
                                    switch (route.tag | 0) {
                                      case /* ProjectSettings */22 :
                                          tmp = ReasonReact.element(undefined, undefined, Curry._2(ProjectNameContext$BsConsole.Provider.Re.make, route[0], ReasonReact.element(undefined, undefined, Curry._3(RouterContext$BsConsole.Provider.Re.make, handleChangeUrl, handleReplaceUrl, ReasonReact.element(undefined, undefined, PS_Overview$BsConsole.Re.make(config$1, handleTask, (function (param) {
                                                                      return Curry._1(self.send, /* RefetchShellRequest */Block.__(2, [token]));
                                                                    }), []))))));
                                          break;
                                      case /* ProjectSettingsPlayground */23 :
                                          tmp = CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.50.5") ? ReasonReact.element(undefined, undefined, Curry._2(ProjectNameContext$BsConsole.Provider.Re.make, route[0], ReasonReact.element(undefined, undefined, Curry._3(RouterContext$BsConsole.Provider.Re.make, handleChangeUrl, handleReplaceUrl, ReasonReact.element(undefined, undefined, PS_Playground$BsConsole.Re.make(config$1, [])))))) : ReasonReact.element(undefined, undefined, Curry._1(CoronerdGate$BsConsole.FeatureUnavailable.Re.make, []));
                                          break;
                                      case /* ProjectSettingsMetricsExporter */24 :
                                          tmp = ReasonReact.element(undefined, undefined, Curry._2(ProjectNameContext$BsConsole.Provider.Re.make, route[0], ReasonReact.element(undefined, undefined, Curry._3(RouterContext$BsConsole.Provider.Re.make, handleChangeUrl, handleReplaceUrl, ReasonReact.element(undefined, undefined, PS_MetricsExporter$BsConsole.Re.make(config$1, handleChangeUrl, []))))));
                                          break;
                                      case /* ProjectSettingsScrubbers */25 :
                                          tmp = ReasonReact.element(undefined, undefined, Curry._2(ProjectNameContext$BsConsole.Provider.Re.make, route[0], ReasonReact.element(undefined, undefined, Curry._3(RouterContext$BsConsole.Provider.Re.make, handleChangeUrl, handleReplaceUrl, ReasonReact.element(undefined, undefined, PS_Scrubbers$BsConsole.Re.make(config$1, handleChangeUrl, []))))));
                                          break;
                                      case /* ProjectSettingsServerSideActions */26 :
                                          tmp = ReasonReact.element(undefined, undefined, Curry._2(ProjectNameContext$BsConsole.Provider.Re.make, route[0], ReasonReact.element(undefined, undefined, Curry._3(RouterContext$BsConsole.Provider.Re.make, handleChangeUrl, handleReplaceUrl, ReasonReact.element(undefined, undefined, PS_ServerSideActions$BsConsole.Re.make(config$1, handleChangeUrl, []))))));
                                          break;
                                      case /* ProjectSettingsAlerts */27 :
                                          var qs = route[1];
                                          var match = qs.tab;
                                          var match$1 = qs.id;
                                          tmp = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsAlerts */Block.__(4, [config$1.user])), [ReasonReact.element(undefined, undefined, Curry._2(ProjectNameContext$BsConsole.Provider.Re.make, route[0], ReasonReact.element(undefined, undefined, Curry._3(RouterContext$BsConsole.Provider.Re.make, handleChangeUrl, handleReplaceUrl, ReasonReact.element(undefined, undefined, PS_Alerts$BsConsole.Re.make(config$1, handleChangeUrl, match !== undefined || match$1 === undefined ? AlertsRouter$BsConsole.stringToType(qs.tab) : /* ErrorThresholdAlerts */0, qs.id, []))))))]));
                                          break;
                                      case /* ProjectSettingsIntegratedDocs */28 :
                                          tmp = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsIntegratedDocs */8), [ReasonReact.element(route[0], undefined, PS_Documentation_v2$BsConsole.make(project, self.state.shell, token, handleTask, handleChangeUrl, route[1].doc, []))]));
                                          break;
                                      case /* ProjectSettingsSubmissionTokens */31 :
                                          tmp = ReasonReact.element(route[0], undefined, PS_ErrorSubmissionTokens$BsConsole.make(handleChangeUrl, handleTask, token, project, self.state.shell, []));
                                          break;
                                      case /* ProjectSettingStorageSampling */32 :
                                          tmp = CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.50.5") ? ReasonReact.element(undefined, undefined, Curry._3(RouterContext$BsConsole.Provider.Re.make, handleChangeUrl, handleReplaceUrl, ReasonReact.element(undefined, undefined, PS_StorageSampling$BsConsole.Re.make(config$1, route[0], [])))) : ReasonReact.element(undefined, undefined, Curry._1(CoronerdGate$BsConsole.FeatureUnavailable.Re.make, []));
                                          break;
                                      case /* ProjectSettingsSubmissionDataSources */33 :
                                          tmp = ReasonReact.element(undefined, undefined, Curry._2(ProjectNameContext$BsConsole.Provider.Re.make, route[0], ReasonReact.element(undefined, undefined, Curry._3(RouterContext$BsConsole.Provider.Re.make, handleChangeUrl, handleReplaceUrl, ReasonReact.element(undefined, undefined, PS_ErrorSubmissionDataSources$BsConsole.Re.make(config$1, []))))));
                                          break;
                                      case /* ProjectSettingsAttributes */34 :
                                          tmp = ReasonReact.element(route[0], undefined, PS_Attributes$BsConsole.make(project, handleTask, handleChangeUrl, token, route[1].attribute, []));
                                          break;
                                      case /* ProjectSettingsIntegrations */35 :
                                          var qs$1 = route[2];
                                          var wfRoute = route[1];
                                          var projectName = route[0];
                                          tmp = ReasonReact.element(undefined, undefined, Curry._4(BpgController$BsConsole.Token.Get.make, handleTask, token, undefined, (function (tokenState, _updater) {
                                                      var tokens = tokenState.remote;
                                                      if (typeof tokens === "number") {
                                                        return null;
                                                      }
                                                      if (tokens.tag) {
                                                        return null;
                                                      }
                                                      var project = Belt_Option.getWithDefault(Belt_List.getBy(config$1.projects, (function (project) {
                                                                  return project.name === projectName;
                                                                })), {
                                                            pid: -1,
                                                            universe: -1,
                                                            name: "",
                                                            owner: -1,
                                                            deleted: -1,
                                                            __state: undefined,
                                                            __create: undefined,
                                                            __modify: undefined
                                                          });
                                                      if (project.pid === -1) {
                                                        Backtrace$BsConsole.Client.send(Backtrace$BsConsole.Attributes.make(undefined), /* `string */[
                                                              288368849,
                                                              "Error: no matching project named: " + (projectName + ".")
                                                            ]);
                                                      }
                                                      var tokens$1 = Belt_List.keep(tokens[0], (function (token) {
                                                              return token.project === project.pid;
                                                            }));
                                                      var submissionToken = Belt_List.get(tokens$1, 0);
                                                      var match = WorkflowVersion$BsConsole.current(config$1);
                                                      if (qs$1 !== undefined && typeof match !== "number" && Belt_Option.isSome(qs$1.pluginId)) {
                                                        var match$1 = qs$1.pluginId;
                                                        var exit = 0;
                                                        if (match$1 !== undefined) {
                                                          switch (match$1) {
                                                            case "jira" :
                                                            case "jira-server" :
                                                            case "slack" :
                                                                exit = 3;
                                                                break;
                                                            default:
                                                              exit = 2;
                                                          }
                                                        } else {
                                                          exit = 2;
                                                        }
                                                        switch (exit) {
                                                          case 2 :
                                                              var __x = Backtrace$BsConsole.Attributes.make(undefined);
                                                              Backtrace$BsConsole.Client.send(__x, /* `string */[
                                                                    288368849,
                                                                    "Unknown PluginId of " + Belt_Option.getWithDefault(qs$1.pluginId, "undefined")
                                                                  ]);
                                                              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Unknown PluginId"));
                                                              return ReasonReact.element(undefined, undefined, ProjectWorkflow$BsConsole.make(submissionToken, config$1.user, projectName, wfRoute, handleTask, handleChangeUrl, token, project, config$1, []));
                                                          case 3 :
                                                              return Wf2IntegrationRouter$BsConsole.getIntegrationComponent(Belt_Option.getWithDefault(qs$1.pluginId, ""), qs$1, config$1, token, handleChangeUrl, projectName);
                                                          
                                                        }
                                                      }
                                                      return ReasonReact.element(undefined, undefined, ProjectWorkflow$BsConsole.make(submissionToken, config$1.user, projectName, wfRoute, handleTask, handleChangeUrl, token, project, config$1, []));
                                                    })));
                                          break;
                                      case /* ProjectSettingsSymbolsUploadedArchives */36 :
                                          var name$1 = route[0];
                                          tmp = ReasonReact.element(name$1, undefined, PS_SymbolsUploadedArchives$BsConsole.make(handleTask, token, handleChangeUrl, name$1, []));
                                          break;
                                      case /* ProjectSettingsSymbolsUpload */37 :
                                          var name$2 = route[0];
                                          tmp = ReasonReact.element(name$2, undefined, PS_SymbolsUpload$BsConsole.make(handleTask, token, handleChangeUrl, self.state.shell, name$2, []));
                                          break;
                                      case /* ProjectSettingsSymbolServers */38 :
                                          var name$3 = route[0];
                                          tmp = ReasonReact.element(name$3, undefined, PS_SymbolServers$BsConsole.make(self.state.shell, handleTask, token, handleChangeUrl, name$3, []));
                                          break;
                                      case /* ProjectSettingsSymbols */39 :
                                          var name$4 = route[0];
                                          tmp = ReasonReact.element(name$4, undefined, PS_Symbols$BsConsole.make(handleTask, token, handleChangeUrl, name$4, []));
                                          break;
                                      case /* ProjectSettingsSymbolAccessTokens */40 :
                                          var name$5 = route[0];
                                          tmp = ReasonReact.element(name$5, undefined, PS_SymbolAccessTokens$BsConsole.make(handleTask, token, project, handleChangeUrl, self.state.shell, name$5, []));
                                          break;
                                      case /* ProjectSettingsMissingSymbols */41 :
                                          var name$6 = route[0];
                                          tmp = ReasonReact.element(name$6, undefined, PS_MissingSymbols$BsConsole.make(handleTask, token, handleChangeUrl, name$6, []));
                                          break;
                                      case /* ProjectSettingsSymbolSearch */42 :
                                          var name$7 = route[0];
                                          tmp = ReasonReact.element(name$7, undefined, PS_SymbolSearch$BsConsole.make(handleTask, token, handleChangeUrl, name$7, []));
                                          break;
                                      case /* ProjectSettingsScheduledReports */43 :
                                          var name$8 = route[0];
                                          tmp = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsScheduledReports */9), [ReasonReact.element(name$8, undefined, PS_ScheduledReports$BsConsole.make(handleChangeUrl, handleTask, token, project, self.state.shell, config$1, name$8, []))]));
                                          break;
                                      case /* ProjectSettingsAccessControl */44 :
                                          tmp = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsAccessControl */Block.__(1, [config$1.user])), [CoronerdGate$BsConsole.isEnoughCoronerdVersion(TeamsUtils$BsConsole.coronerdVersion) ? ReasonReact.element(undefined, undefined, PS_AccessControl$BsConsole.Re.make(config$1, route[0], handleTask, token, project, handleChangeUrl, [])) : ReasonReact.element(undefined, undefined, Curry._1(CoronerdGate$BsConsole.FeatureUnavailable.Re.make, []))]));
                                          break;
                                      case /* ProjectSettingsReprocessing */45 :
                                          tmp = ReasonReact.element(name, undefined, Reprocessing$BsConsole.Re.make(token, route[0], config$1, []));
                                          break;
                                      case /* ProjectSettingsRetentionPolicy */46 :
                                          var name$9 = route[0];
                                          tmp = ReasonReact.element(name$9, undefined, PS_RetentionPolicy$BsConsole.make(handleTask, token, self.state.shell, name$9, []));
                                          break;
                                      case /* ProjectSettingsSourceCode */47 :
                                          tmp = ReasonReact.element(undefined, undefined, SourceCodeConfig$BsConsole.Re.make(token, config$1, route[0], []));
                                          break;
                                      case /* ProjectSettingsApiTokens */48 :
                                          tmp = ReasonReact.element(undefined, undefined, ApiTokens$BsConsole.Re.make(token, config$1, route[0], []));
                                          break;
                                      case /* ProjectSettingsDedupeConfig */49 :
                                          tmp = ReasonReact.element(route[0], undefined, PS_DedupeConfig$BsConsole.make(self.state.shell, project, handleTask, token, handleChangeUrl, route[1], []));
                                          break;
                                      case /* ProjectSettingsStabilityMonitoring */50 :
                                          tmp = ReasonReact.element(undefined, undefined, PS_StabilityMonitoring$BsConsole.Re.make(config$1, route[0], token, []));
                                          break;
                                      default:
                                        tmp = null;
                                    }
                                  }
                                  return ReasonReact.element(undefined, undefined, Curry._4(SavedQueriesContext$BsConsole.Provider.Jsx2.make, name, token, config$1, [ReasonReact.element(undefined, undefined, ResetScrollOnRouteUpdate$BsConsole.Jsx2.make(route, [ReasonReact.element(project.name, undefined, Page$BsConsole.make(token, handleChangeUrl, route, self.state.shell, handleBack, handleTask, handleLogout, ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                                              ReasonReact.element(undefined, undefined, ProjectSettingsNavBreadcrumbToolbar$BsConsole.Jsx2.make(config$1, route, name, handleChangeUrl, [])),
                                                                              React.createElement("div", {
                                                                                    className: settingsViewContainer
                                                                                  }, ReasonReact.element(undefined, undefined, ProjectSettingsNavigation$BsConsole.Jsx2.make(route, config$1, name, handleChangeUrl, [])), tmp)
                                                                            ]))))]))]));
                                })));
                  }
                }
                if (exit === 1) {
                  var config$20 = self.state.shell;
                  if (typeof config$20 === "number") {
                    tmp = null;
                  } else if (config$20.tag) {
                    tmp = Js_exn.raiseError(config$20[0]);
                  } else {
                    var config$21 = config$20[0];
                    var tmp$6;
                    if (typeof route === "number") {
                      switch (route) {
                        case /* SettingsMe */5 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsMe */4), [ReasonReact.element(undefined, undefined, SettingsMePage$BsConsole.make((function (user) {
                                                  return Curry._1(self.send, /* UpdateCurrentUser */Block.__(0, [user]));
                                                }), token, handleTask, config$21, handleChangeUrl, []))]));
                            break;
                        case /* SettingsUsers */6 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsUsers */Block.__(0, [config$21.user])), [ReasonReact.element(undefined, undefined, SettingsUsersPage$BsConsole.make(token, config$21, handleTask, handleChangeUrl, []))]));
                            break;
                        case /* SettingsTeams */7 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(config$21.user.email, /* SettingsTeams */1), [CoronerdGate$BsConsole.isEnoughCoronerdVersion(TeamsUtils$BsConsole.coronerdVersion) ? ReasonReact.element(undefined, undefined, SettingsTeamsPage$BsConsole.Re.make(config$21, token, [])) : ReasonReact.element(undefined, undefined, Curry._1(CoronerdGate$BsConsole.FeatureUnavailable.Re.make, []))]));
                            break;
                        case /* SettingsProjects */8 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(config$21.user.email, /* SettingsProjects */2), [ReasonReact.element(undefined, undefined, SettingsProjectsPage$BsConsole.make(token, config$21, handleTask, handleChangeUrl, []))]));
                            break;
                        case /* SettingsProjectsNew */9 :
                            var refetchShell$1 = function (token) {
                              return Curry._1(self.send, /* RefetchShellRequest */Block.__(2, [token]));
                            };
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsProjectsNew */Block.__(6, [config$21.user])), [ReasonReact.element(undefined, undefined, BillingLimitsFetch$BsConsole.make(config$21.universe.name, handleTask, token, (function (limitsRemote, param) {
                                                  return ReasonReact.element(undefined, undefined, SettingsProjectsNewPage$BsConsole.make(refetchShell$1, limitsRemote, self.state.shell, token, handleTask, handleChangeUrl, []));
                                                })))]));
                            break;
                        case /* SettingsProjectsFork */10 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsProjectsFork */3), [ReasonReact.element(undefined, undefined, SettingsProjectsForkPage$BsConsole.make(self.state.shell, handleChangeUrl, []))]));
                            break;
                        case /* SettingsInvites */11 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsUserInvitationManagement */Block.__(7, [config$21.user])), [ReasonReact.element(undefined, undefined, SettingsInvitesPage$BsConsole.make(handleChangeUrl, handleTask, token, []))]));
                            break;
                        case /* SettingsInvitesNew */12 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsUserInvitationManagement */Block.__(7, [config$21.user])), [ReasonReact.element(undefined, undefined, SubInviteNewUser$BsConsole.Re.make(config$21, token, handleChangeUrl, []))]));
                            break;
                        case /* SettingsNetworking */13 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsNetworking */Block.__(11, [config$21.user])), [ReasonReact.element(undefined, undefined, SettingsNetworkingPage$BsConsole.make(handleTask, token, handleChangeUrl, []))]));
                            break;
                        case /* SettingsRetentionPolicy */14 :
                            tmp$6 = ReasonReact.element(undefined, undefined, SettingsRetentionPolicy$BsConsole.make(handleTask, token, self.state.shell, []));
                            break;
                        case /* SettingsNetworkingSSL */15 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsNetworkingSSL */Block.__(10, [config$21.user])), [ReasonReact.element(undefined, undefined, SettingsNetworkingSSLPage$BsConsole.make(handleChangeUrl, handleTask, token, []))]));
                            break;
                        case /* SettingsNetworkingListeners */16 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsNetworkingListeners */Block.__(9, [config$21.user])), [ReasonReact.element(undefined, undefined, SettingsNetworkingListenersPage$BsConsole.make(token, handleChangeUrl, handleTask, []))]));
                            break;
                        case /* SettingsWhitelist */17 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsUserInvitationManagement */Block.__(7, [config$21.user])), [ReasonReact.element(undefined, undefined, SettingsWhitelistPage$BsConsole.make(config$21, token, handleTask, handleChangeUrl, []))]));
                            break;
                        case /* SettingsBilling */18 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsSubscriptions */Block.__(8, [config$21.user])), [ReasonReact.element(undefined, undefined, SettingsBilling$BsConsole.Re.make(handleTask, token, config$21, handleChangeUrl, []))]));
                            break;
                        case /* SettingsSubscriptions */20 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsSubscriptions */Block.__(8, [config$21.user])), [ReasonReact.element(undefined, undefined, SettingsSubscriptions$BsConsole.Re.make(config$21, token, handleChangeUrl, handleTask, []))]));
                            break;
                        case /* SettingsSamlConfig */21 :
                            tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsSamlConfig */Block.__(12, [config$21.user])), [ReasonReact.element(undefined, undefined, SettingsSamlConfig$BsConsole.Jsx2.make(token, config$21, []))]));
                            break;
                        default:
                          tmp$6 = null;
                      }
                    } else if (route.tag === /* SettingsWfConnectionManagement */6) {
                      var tmp$7 = true;
                      if (!Gate$BsConsole.isNotPermitted(undefined, /* SettingsWfConnectionManagement */Block.__(14, [config$21.user]))) {
                        var match$2 = WorkflowVersion$BsConsole.current(config$21);
                        tmp$7 = typeof match$2 === "number" ? true : false;
                      }
                      tmp$6 = ReasonReact.element(undefined, undefined, MessageFeatureGate$BsConsole.Jsx2.make(tmp$7, [ReasonReact.element(undefined, undefined, SettingsWfConnectionManagement$BsConsole.Jsx2.make(token, config$21, handleChangeUrl, Caml_option.some(route[0].error), []))]));
                    } else {
                      tmp$6 = null;
                    }
                    tmp = ReasonReact.element(undefined, undefined, ResetScrollOnRouteUpdate$BsConsole.Jsx2.make(route, [ReasonReact.element(undefined, undefined, Page$BsConsole.make(token, handleChangeUrl, route, self.state.shell, handleBack, handleTask, handleLogout, ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                ReasonReact.element(undefined, undefined, OrgSettingsNavBreadcrumbToolbar$BsConsole.Jsx2.make(route, handleChangeUrl, config$21, [])),
                                                React.createElement("div", {
                                                      className: settingsViewContainer
                                                    }, ReasonReact.element(undefined, undefined, OrgSettingsNavigation$BsConsole.Jsx2.make(route, config$21, handleChangeUrl, token, [])), tmp$6)
                                              ]))))]));
                  }
                }
                return ReasonReact.element(undefined, undefined, Curry._2(TokenContext$BsConsole.Provider.Re.make, token, tmp));
              };
              var renderNotAuthenticatedRoute = function (route) {
                MetricsMemory$BsConsole.forget("project");
                var tmp;
                var exit = 0;
                if (typeof route === "number") {
                  switch (route) {
                    case /* Signup */0 :
                        tmp = ReasonReact.element(undefined, undefined, SignupPage$BsConsole.make(handleTask, handleChangeUrl, []));
                        break;
                    case /* Recover */1 :
                        tmp = ReasonReact.element(undefined, undefined, RecoverPage$BsConsole.make(handleTask, handleChangeUrl, []));
                        break;
                    default:
                      exit = 1;
                  }
                } else {
                  switch (route.tag | 0) {
                    case /* Reset */0 :
                        var match = route[0];
                        tmp = ReasonReact.element(undefined, undefined, ResetPage$BsConsole.make(handleTask, handleChangeUrl, match.token, match.universe_id, match.username, []));
                        break;
                    case /* CreateAccount */1 :
                        var match$1 = route[0];
                        tmp = ReasonReact.element(undefined, undefined, CreatePage$BsConsole.Re.make(handleTask, handleChangeUrl, match$1.password, match$1.username, []));
                        break;
                    case /* UDashEntry */2 :
                        var match$2 = route[0];
                        var referrer = match$2.referrer;
                        var project = match$2.project;
                        tmp = ReasonReact.element(undefined, undefined, UDashEntry$BsConsole.Jsx2.make((function (token$prime) {
                                    return Curry._1(self.send, /* LoginSuccess */Block.__(12, [
                                                  token$prime,
                                                  referrer,
                                                  project
                                                ]));
                                  }), match$2.token, match$2.email, project, []));
                        break;
                    case /* Login */3 :
                        var referrer$1 = route[0].referrer;
                        Effect$BsConsole.writeReferrerToLocalStorage(referrer$1);
                        tmp = IdentityVariant$BsConsole.current !== 1 ? ReasonReact.element(undefined, undefined, LoginPage$BsConsole.make(handleTask, (function (token) {
                                      return Curry._1(self.send, /* LoginSuccess */Block.__(12, [
                                                    token,
                                                    referrer$1,
                                                    undefined
                                                  ]));
                                    }), handleChangeUrl, [])) : ReasonReact.element(undefined, undefined, SauceLabsLoginRedirect$BsConsole.Jsx2.make([]));
                        break;
                    case /* Activate */5 :
                        var match$3 = route[0];
                        tmp = ReasonReact.element(undefined, undefined, ActivatePage$BsConsole.make(handleChangeUrl, match$3.token, match$3.set, []));
                        break;
                    case /* SamlError */55 :
                        tmp = ReasonReact.element(undefined, undefined, SamlErrorPage$BsConsole.make(route[0], route[1], redirect, []));
                        break;
                    default:
                      exit = 1;
                  }
                }
                if (exit === 1) {
                  tmp = IdentityVariant$BsConsole.current !== 1 ? ReasonReact.element(undefined, undefined, LoginPage$BsConsole.make(handleTask, (function (token) {
                                return Curry._1(self.send, /* LoginSuccess */Block.__(12, [
                                              token,
                                              undefined,
                                              undefined
                                            ]));
                              }), handleChangeUrl, [])) : ReasonReact.element(undefined, undefined, SauceLabsLoginRedirect$BsConsole.Jsx2.make([]));
                }
                return ReasonReact.element(undefined, undefined, Mui$BsConsole.MuiThemeProvider.make(BtTheme$BsConsole.theme, [tmp]));
              };
              var match = self.state.shell;
              var match$1 = window._BACKTRACE_IDENTITY_VARIANT;
              var match$2 = self.state.auth;
              var tmp;
              if (typeof match === "number" || match.tag || (match$1 == null) || !(match$1 === "unity" && match$2)) {
                tmp = null;
              } else {
                var token = match$2[0];
                tmp = ReasonReact.element(undefined, undefined, FirstTimeUnityModal$BsConsole.Jsx2.make(match[0], (function (param) {
                            return Curry._1(self.send, /* RefetchShellRequest */Block.__(2, [token]));
                          }), token, []));
              }
              var match$3 = self.state.route;
              var match$4 = self.state.auth;
              var match$5 = self.state.shell;
              var tmp$1;
              if (match$3 !== undefined) {
                if (match$4) {
                  if (typeof match$5 === "number" || match$5.tag) {
                    tmp$1 = null;
                  } else {
                    var shell = match$5[0];
                    var token$1 = match$4[0];
                    if (match$3 === /* CreateFirstProject */2) {
                      tmp$1 = renderAuthenticatedRoute(match$3, token$1);
                    } else {
                      AppCues$BsConsole.identify(shell.user.username + ("__-__" + shell.universe.name), shell.universe.name, Route$BsConsole.getProjectName(match$3));
                      tmp$1 = ReasonReact.element(undefined, undefined, PhoneHome$BsConsole.Jsx2.make(shell.universe.name, [ReasonReact.element(undefined, undefined, BillingLimitsCallToAction$BsConsole.make(self.state.shell, match$3, token$1, handleTask, handleChangeUrl, (function (limitsRemote, _updater) {
                                            return FrozenUtil$BsConsole.renderRoute(limitsRemote, shell, (function (param) {
                                                          return ReasonReact.element(undefined, undefined, Curry._2(TokenContext$BsConsole.Provider.Re.make, token$1, ReasonReact.element(undefined, undefined, FrozenPage$BsConsole.Jsx2.make(handleLogout, []))));
                                                        }), (function (param) {
                                                          return renderAuthenticatedRoute(match$3, token$1);
                                                        }));
                                          })))]));
                    }
                  }
                } else {
                  tmp$1 = renderNotAuthenticatedRoute(match$3);
                }
              } else {
                tmp$1 = null;
              }
              var token$2 = self.state.auth;
              var tmp$2;
              if (token$2) {
                var btDefaults = Json_encode.object_(/* [] */0);
                tmp$2 = ReasonReact.element(undefined, undefined, WfActionsModal$BsConsole.make(btDefaults, token$2[0], []));
              } else {
                tmp$2 = null;
              }
              return React.createElement(React.Fragment, undefined, tmp, tmp$1, IFrame$BsConsole.isIFrame ? null : ReasonReact.element(undefined, undefined, Curry._2(ZenDesk$BsConsole.Standard.Jsx2.make, undefined, [])), tmp$2);
            }),
          initialState: (function (param) {
              var gateSystemResult = Gate$BsConsole.make([(function (gateName, isEnabled) {
                        var key;
                        switch (gateName) {
                          case /* Alpha */0 :
                              key = "alpha_console";
                              break;
                          case /* Beta */1 :
                              key = "beta_console";
                              break;
                          case /* I18N */2 :
                              key = "i18n_console";
                              break;
                          
                        }
                        return MetricsMemory$BsConsole.memorize(/* tuple */[
                                    key,
                                    isEnabled
                                  ]);
                      })]);
              if (gateSystemResult.tag) {
                console.error(gateSystemResult[0]);
              }
              Curry._1(L10N_date_time_settings$BsConsole.Cache.set, {
                    clockScheme: /* Twelve */0,
                    formats: L10N_date_time_formats$BsConsole.defaults
                  });
              return {
                      auth: initialize(undefined),
                      route: undefined,
                      lastPath: {
                        contents: "/"
                      },
                      entrypoint: undefined,
                      shell: /* NotAsked */0,
                      session: /* NotAsked */0,
                      drawer: /* Open */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case /* GoBack */0 :
                      return /* SideEffects */Block.__(1, [(function (self) {
                                    return ReasonReactRouter.push(self.state.lastPath.contents);
                                  })]);
                  case /* FetchSessionRequest */1 :
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  auth: state.auth,
                                  route: state.route,
                                  lastPath: state.lastPath,
                                  entrypoint: state.entrypoint,
                                  shell: state.shell,
                                  session: /* Loading */1,
                                  drawer: state.drawer
                                },
                                (function (self) {
                                    return Curry._1(self.send, /* HandleTask */Block.__(13, [
                                                  (function (json) {
                                                      var session = Session$BsConsole.decode(TaskCallback$BsConsole.getJson(json));
                                                      if (session !== undefined) {
                                                        return Curry._1(self.send, /* FetchSessionSuccess */Block.__(5, [session]));
                                                      }
                                                      var error = Error$BsConsole.HTTPD.decode(TaskCallback$BsConsole.getJson(json));
                                                      if (error !== undefined) {
                                                        return Curry._1(self.send, /* FetchSessionFailure */Block.__(6, [error.message]));
                                                      } else {
                                                        return Curry._1(self.send, /* FetchSessionFailure */Block.__(6, ["generic error message"]));
                                                      }
                                                    }),
                                                  Task$BsConsole.makeTask(/* Session */1)
                                                ]));
                                  })
                              ]);
                  case /* HandleExpiredToken */2 :
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  auth: /* NotAuthenticated */0,
                                  route: state.route,
                                  lastPath: state.lastPath,
                                  entrypoint: state.entrypoint,
                                  shell: state.shell,
                                  session: state.session,
                                  drawer: state.drawer
                                },
                                (function (self) {
                                    var path = $$String.concat("/", ReasonReactRouter.dangerouslyGetInitialUrl(undefined).path);
                                    var search = ReasonReactRouter.dangerouslyGetInitialUrl(undefined).search;
                                    var referrer = path + (
                                      search !== "" ? "?" + search : ""
                                    );
                                    var match = window._BACKTRACE_IDENTITY_VARIANT;
                                    if (!(match == null) && match === "unity" && IFrame$BsConsole.isIFrame) {
                                      Effect$BsConsole.removeTokenFromLocalStorage(undefined);
                                      IFrame$BsConsole.postMessage({
                                            type_: "backtrace-session-expired",
                                            payload: referrer
                                          });
                                      var qs = Route$BsConsole.UDashEntryParams.decodeSearch(search);
                                      return Curry._1(self.send, /* HandleUDashEntry */Block.__(15, [qs]));
                                    }
                                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Session expired"));
                                    return Curry._1(self.send, /* Logout */Block.__(14, [
                                                  undefined,
                                                  referrer
                                                ]));
                                  })
                              ]);
                  
                }
              } else {
                switch (action.tag | 0) {
                  case /* UpdateCurrentUser */0 :
                      var shell = state.shell;
                      if (typeof shell === "number") {
                        throw [
                              Caml_builtin_exceptions.assert_failure,
                              /* tuple */[
                                "bridge.re",
                                182,
                                13
                              ]
                            ];
                      }
                      if (!shell.tag) {
                        return /* Update */Block.__(0, [{
                                    auth: state.auth,
                                    route: state.route,
                                    lastPath: state.lastPath,
                                    entrypoint: state.entrypoint,
                                    shell: /* Success */Block.__(0, [Configuration$BsConsole.updateCurrentUser(shell[0], action[0])]),
                                    session: state.session,
                                    drawer: state.drawer
                                  }]);
                      }
                      throw [
                            Caml_builtin_exceptions.assert_failure,
                            /* tuple */[
                              "bridge.re",
                              182,
                              13
                            ]
                          ];
                  case /* FetchShellRequest */1 :
                      var token = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  auth: state.auth,
                                  route: state.route,
                                  lastPath: state.lastPath,
                                  entrypoint: state.entrypoint,
                                  shell: /* Loading */1,
                                  session: state.session,
                                  drawer: state.drawer
                                },
                                (function (self) {
                                    return Curry._1(self.send, /* HandleTask */Block.__(13, [
                                                  (function (json) {
                                                      var configuration = Configuration$BsConsole.decode((function (param) {
                                                              return Curry._1(self.send, /* HandleExpiredToken */2);
                                                            }), TaskCallback$BsConsole.getJson(json));
                                                      if (configuration !== undefined) {
                                                        return Curry._1(self.send, /* FetchShellSuccess */Block.__(3, [configuration]));
                                                      }
                                                      var error = Error$BsConsole.HTTPD.decode(TaskCallback$BsConsole.getJson(json));
                                                      if (error !== undefined) {
                                                        return Curry._1(self.send, /* FetchShellFailure */Block.__(4, [error.message]));
                                                      } else {
                                                        return Curry._1(self.send, /* FetchShellFailure */Block.__(4, ["generic error message"]));
                                                      }
                                                    }),
                                                  Task$BsConsole.makeTask(/* Configuration */Block.__(19, [token]))
                                                ]));
                                  })
                              ]);
                  case /* RefetchShellRequest */2 :
                      var token$1 = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                state,
                                (function (self) {
                                    return Curry._1(self.send, /* HandleTask */Block.__(13, [
                                                  (function (json) {
                                                      var configuration = Configuration$BsConsole.decode((function (param) {
                                                              return Curry._1(self.send, /* HandleExpiredToken */2);
                                                            }), TaskCallback$BsConsole.getJson(json));
                                                      if (configuration !== undefined) {
                                                        return Curry._1(self.send, /* FetchShellSuccess */Block.__(3, [configuration]));
                                                      }
                                                      var error = Error$BsConsole.HTTPD.decode(TaskCallback$BsConsole.getJson(json));
                                                      if (error !== undefined) {
                                                        return Curry._1(self.send, /* FetchShellFailure */Block.__(4, [error.message]));
                                                      } else {
                                                        return Curry._1(self.send, /* FetchShellFailure */Block.__(4, ["generic error message"]));
                                                      }
                                                    }),
                                                  Task$BsConsole.makeTask(/* Configuration */Block.__(19, [token$1]))
                                                ]));
                                  })
                              ]);
                  case /* FetchShellSuccess */3 :
                      var configuration = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  auth: state.auth,
                                  route: state.route,
                                  lastPath: state.lastPath,
                                  entrypoint: state.entrypoint,
                                  shell: /* Success */Block.__(0, [configuration]),
                                  session: state.session,
                                  drawer: state.drawer
                                },
                                (function (self) {
                                    var token = state.auth;
                                    if (token) {
                                      I18N_config$BsConsole.initialize(configuration.userSettings);
                                      Curry._1(self.send, /* FetchSessionRequest */1);
                                      sendWelcomeBanner(self.state.auth, (function (cb, task) {
                                              return Curry._1(self.send, /* HandleTask */Block.__(13, [
                                                            cb,
                                                            task
                                                          ]));
                                            }), self.state.shell, (function (token) {
                                              return Curry._1(self.send, /* RefetchShellRequest */Block.__(2, [token]));
                                            }), undefined);
                                      Effect$BsConsole.initializeRouter(undefined);
                                      return UserLastSeen$BsConsole.update(configuration.user, token[0]);
                                    }
                                    
                                  })
                              ]);
                  case /* FetchShellFailure */4 :
                      return /* Update */Block.__(0, [{
                                  auth: state.auth,
                                  route: state.route,
                                  lastPath: state.lastPath,
                                  entrypoint: state.entrypoint,
                                  shell: /* Failure */Block.__(1, [action[0]]),
                                  session: state.session,
                                  drawer: state.drawer
                                }]);
                  case /* FetchSessionSuccess */5 :
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  auth: state.auth,
                                  route: state.route,
                                  lastPath: state.lastPath,
                                  entrypoint: state.entrypoint,
                                  shell: state.shell,
                                  session: /* Success */Block.__(0, [action[0]]),
                                  drawer: state.drawer
                                },
                                (function (_self) {
                                    return Effect$BsConsole.initializeRouter(undefined);
                                  })
                              ]);
                  case /* FetchSessionFailure */6 :
                      return /* Update */Block.__(0, [{
                                  auth: state.auth,
                                  route: state.route,
                                  lastPath: state.lastPath,
                                  entrypoint: state.entrypoint,
                                  shell: state.shell,
                                  session: /* Failure */Block.__(1, [action[0]]),
                                  drawer: state.drawer
                                }]);
                  case /* HandleEntryScenario */7 :
                      var create = Entrypoint$BsConsole.decode(Util$BsConsole.Qs.parse(action[0]));
                      if (create === undefined) {
                        return /* NoUpdate */0;
                      }
                      if (typeof create === "number") {
                        return /* UpdateWithSideEffects */Block.__(2, [
                                  {
                                    auth: state.auth,
                                    route: state.route,
                                    lastPath: state.lastPath,
                                    entrypoint: /* Normal */0,
                                    shell: state.shell,
                                    session: state.session,
                                    drawer: state.drawer
                                  },
                                  (function (_self) {
                                      return Effect$BsConsole.initializeRouter(undefined);
                                    })
                                ]);
                      }
                      switch (create.tag | 0) {
                        case /* UDash */0 :
                            return /* NoUpdate */0;
                        case /* Create */1 :
                            var params = create[0];
                            return /* UpdateWithSideEffects */Block.__(2, [
                                      {
                                        auth: state.auth,
                                        route: state.route,
                                        lastPath: state.lastPath,
                                        entrypoint: create,
                                        shell: state.shell,
                                        session: state.session,
                                        drawer: state.drawer
                                      },
                                      (function (self) {
                                          return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* CreateAccount */Block.__(1, [{
                                                              username: params.username,
                                                              password: params.password
                                                            }])]));
                                        })
                                    ]);
                        case /* Reset */2 :
                            var match = create[0];
                            var username = match.username;
                            var token$2 = match.token;
                            var universe_id = match.universe_id;
                            return /* UpdateWithSideEffects */Block.__(2, [
                                      {
                                        auth: state.auth,
                                        route: state.route,
                                        lastPath: state.lastPath,
                                        entrypoint: /* Reset */Block.__(2, [{
                                              universe_id: universe_id,
                                              token: token$2,
                                              username: username
                                            }]),
                                        shell: state.shell,
                                        session: state.session,
                                        drawer: state.drawer
                                      },
                                      (function (self) {
                                          return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* Reset */Block.__(0, [{
                                                              token: token$2,
                                                              username: username,
                                                              universe_id: universe_id
                                                            }])]));
                                        })
                                    ]);
                        case /* Tokenized */3 :
                            var token$3 = create[0].token;
                            return /* UpdateWithSideEffects */Block.__(2, [
                                      {
                                        auth: state.auth,
                                        route: state.route,
                                        lastPath: state.lastPath,
                                        entrypoint: create,
                                        shell: state.shell,
                                        session: state.session,
                                        drawer: state.drawer
                                      },
                                      (function (self) {
                                          return Curry._1(self.send, /* LoginSuccess */Block.__(12, [
                                                        token$3,
                                                        undefined,
                                                        undefined
                                                      ]));
                                        })
                                    ]);
                        case /* SAML */4 :
                            var token$4 = create[1];
                            return /* UpdateWithSideEffects */Block.__(2, [
                                      {
                                        auth: state.auth,
                                        route: state.route,
                                        lastPath: state.lastPath,
                                        entrypoint: /* SAML */Block.__(4, [
                                            create[0],
                                            token$4
                                          ]),
                                        shell: state.shell,
                                        session: state.session,
                                        drawer: state.drawer
                                      },
                                      (function (self) {
                                          return Curry._1(self.send, /* LoginSuccess */Block.__(12, [
                                                        token$4,
                                                        Effect$BsConsole.getReferrerFromLocalStorage(undefined),
                                                        undefined
                                                      ]));
                                        })
                                    ]);
                        
                      }
                  case /* UrlChange */8 :
                      if (BlockNavigation$BsConsole.isBlocked.contents) {
                        return /* NoUpdate */0;
                      }
                      var url = action[0];
                      return /* SideEffects */Block.__(1, [(function (self) {
                                    var route = Route$BsConsole.routeForPathAndSearch(undefined, url.path, url.search);
                                    try {
                                      BacktraceSession$BsConsole.tick(undefined);
                                    }
                                    catch (raw_e){
                                      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                                      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "bridge/UrlChange", Backtrace$BsConsole.Attributes.make(undefined));
                                      Backtrace$BsConsole.Client.send(__x, /* `exn */[
                                            5049499,
                                            Uncaught$BsConsole.castToJsExn(e)
                                          ]);
                                    }
                                    var match = location.host;
                                    var match$1 = Route$BsConsole.Search.getI18N(url.search);
                                    var exit = 0;
                                    if (match$1 !== undefined && match$1) {
                                      I18N_config$BsConsole.enable(undefined);
                                    } else {
                                      exit = 1;
                                    }
                                    if (exit === 1 && match === "yolo.sp.backtrace.io") {
                                      I18N_config$BsConsole.enable(undefined);
                                    }
                                    var match$2 = Route$BsConsole.Search.getBeta(url.search);
                                    var match$3 = Gate$BsConsole.isBeta(undefined);
                                    var exit$1 = 0;
                                    if (match$2 !== undefined) {
                                      if (match$2) {
                                        if (match$3) {
                                          
                                        } else {
                                          Gate$BsConsole.setBeta(true);
                                        }
                                      } else if (match$3) {
                                        Gate$BsConsole.setBeta(false);
                                      } else {
                                        exit$1 = 1;
                                      }
                                    } else {
                                      exit$1 = 1;
                                    }
                                    if (exit$1 === 1) {
                                      if (WindowVariables$BsConsole._BACKTRACE_USE_BETA && !match$3) {
                                        Gate$BsConsole.setBeta(true);
                                      }
                                      
                                    }
                                    var match$4 = Route$BsConsole.Search.getWfBeta(url.search);
                                    var match$5 = Gate$BsConsole.isWfBeta(undefined);
                                    if (match$4 !== undefined) {
                                      if (match$4) {
                                        if (match$5) {
                                          
                                        } else {
                                          Gate$BsConsole.setIsWfBeta(true);
                                        }
                                      } else if (match$5) {
                                        Gate$BsConsole.setIsWfBeta(false);
                                      }
                                      
                                    }
                                    var match$6 = Route$BsConsole.Search.getAlpha(url.search);
                                    var match$7 = Gate$BsConsole.isAlpha(undefined);
                                    var exit$2 = 0;
                                    if (match$6 !== undefined) {
                                      if (match$6) {
                                        if (match$7) {
                                          
                                        } else {
                                          Gate$BsConsole.setAlpha(true);
                                        }
                                      } else if (match$7) {
                                        Gate$BsConsole.setAlpha(false);
                                      } else {
                                        exit$2 = 1;
                                      }
                                    } else {
                                      exit$2 = 1;
                                    }
                                    if (exit$2 === 1) {
                                      if (WindowVariables$BsConsole._BACKTRACE_USE_ALPHA && !match$7) {
                                        Gate$BsConsole.setAlpha(true);
                                      }
                                      
                                    }
                                    var currentProject = Route$BsConsole.getProjectName(route);
                                    var config = self.state.shell;
                                    if (typeof config !== "number" && !config.tag) {
                                      MetricsRemember$BsConsole.rememberStuff(config[0], currentProject, undefined);
                                      MetricsMemory$BsConsole.memorize(/* tuple */[
                                            "window.innerHeight",
                                            window.innerHeight
                                          ]);
                                      MetricsMemory$BsConsole.memorize(/* tuple */[
                                            "window.innerWidth",
                                            window.innerWidth
                                          ]);
                                      MetricsEvent$BsConsole.send(/* Nav */Block.__(3, [
                                              route,
                                              self.state.route
                                            ]));
                                    }
                                    if (route !== /* CreateFirstProject */2) {
                                      var shell = self.state.shell;
                                      if (typeof shell !== "number" && !shell.tag) {
                                        var match$8 = List.length(shell[0].projects);
                                        if (match$8 !== 0) {
                                          
                                        } else {
                                          Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* CreateFirstProject */2]));
                                        }
                                      }
                                      
                                    }
                                    Belt_Option.map(currentProject, (function (projectName) {
                                            window.sessionStorage.setItem("_Backtrace_last_project", projectName);
                                            
                                          }));
                                    Belt_Option.map(currentProject, (function (projectName) {
                                            if (!DemoProjectUtil$BsConsole.isDemoProject(projectName)) {
                                              window.sessionStorage.setItem("_Backtrace_last_nonDemo_project", projectName);
                                              return ;
                                            }
                                            
                                          }));
                                    var replaceUrlWithDefaultView = function (shell, projectName, token) {
                                      var pid = Belt_Option.map(Belt_List.getBy(shell.projects, (function (project) {
                                                  return project.name === projectName;
                                                })), (function (projBpg) {
                                              return projBpg.pid;
                                            }));
                                      var userDefaultRoute = SavedQueriesModel$BsConsole.getRouteForUserDefault(shell, projectName);
                                      var projectDefaultRoute = SavedQueriesModel$BsConsole.getRouteForProjectDefault(shell, projectName);
                                      if (userDefaultRoute !== undefined) {
                                        if (pid !== undefined) {
                                          Curry._1(self.send, /* ReplaceUrl */Block.__(10, [userDefaultRoute]));
                                          var userDefaultModelFromFrontendSettings = SavedQueriesModel$BsConsole.userDefaultFromConfig(shell, projectName);
                                          if (userDefaultModelFromFrontendSettings !== undefined) {
                                            return SavedQueriesModel$BsConsole.syncUserDefault(userDefaultModelFromFrontendSettings, shell, token, projectName, pid, shell.user.uid, (function (newUserDefaultModel) {
                                                          if (newUserDefaultModel !== undefined) {
                                                            return ;
                                                          } else {
                                                            return SnackPurveyor$BsConsole.enqueue({
                                                                        label: I18N$BsConsole.get(undefined, "Refresh"),
                                                                        onClick: (function (param) {
                                                                            return (window.location.href = '/');
                                                                          })
                                                                      }, undefined, -1, undefined, I18N$BsConsole.show(undefined, "Your default view was deleted by its owner or an admin, please refresh to load the new default view, or save the current view to restore it."));
                                                          }
                                                        }));
                                          } else {
                                            return ;
                                          }
                                        }
                                        
                                      } else if (projectDefaultRoute !== undefined) {
                                        return Curry._1(self.send, /* ReplaceUrl */Block.__(10, [projectDefaultRoute]));
                                      }
                                      var route_001 = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                                      var route = /* ProjectTriage */Block.__(11, [
                                          projectName,
                                          route_001,
                                          undefined
                                        ]);
                                      return Curry._1(self.send, /* ReplaceUrl */Block.__(10, [route]));
                                    };
                                    var match$9 = self.state.auth;
                                    var match$10 = self.state.shell;
                                    if (!match$9) {
                                      var match$11 = Route$BsConsole.routeForPathAndSearch(undefined, url.path, url.search);
                                      var match$12 = self.state.entrypoint;
                                      var exit$3 = 0;
                                      if (typeof match$11 === "number") {
                                        switch (match$11) {
                                          case /* Signup */0 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* Signup */0]));
                                          case /* Recover */1 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* Recover */1]));
                                          default:
                                            exit$3 = 1;
                                        }
                                      } else {
                                        switch (match$11.tag | 0) {
                                          case /* Reset */0 :
                                              var match$13 = match$11[0];
                                              if (match$12 !== undefined) {
                                                return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* Reset */Block.__(0, [{
                                                                    token: match$13.token,
                                                                    username: match$13.username,
                                                                    universe_id: match$13.universe_id
                                                                  }])]));
                                              } else {
                                                return Curry._1(self.send, /* HandleEntryScenario */Block.__(7, [url.search]));
                                              }
                                          case /* CreateAccount */1 :
                                              var match$14 = match$11[0];
                                              if (match$12 === undefined) {
                                                return Curry._1(self.send, /* HandleEntryScenario */Block.__(7, [url.search]));
                                              }
                                              if (typeof match$12 === "number") {
                                                exit$3 = 1;
                                              } else {
                                                if (match$12.tag === /* Create */1) {
                                                  return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* CreateAccount */Block.__(1, [{
                                                                      username: match$14.username,
                                                                      password: match$14.password
                                                                    }])]));
                                                }
                                                exit$3 = 1;
                                              }
                                              break;
                                          case /* UDashEntry */2 :
                                              return Curry._1(self.send, /* HandleUDashEntry */Block.__(15, [match$11[0]]));
                                          case /* Login */3 :
                                              if (match$12 !== undefined && typeof match$12 === "number") {
                                                return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* Login */Block.__(3, [match$11[0]])]));
                                              } else {
                                                return Curry._1(self.send, /* HandleEntryScenario */Block.__(7, [url.search]));
                                              }
                                          case /* Share */4 :
                                              return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* Login */Block.__(3, [{
                                                                  referrer: "share?redirect=" + match$11[0].redirect,
                                                                  token: undefined,
                                                                  redirect: undefined,
                                                                  method: undefined
                                                                }])]));
                                          case /* Activate */5 :
                                              if (match$12 === undefined) {
                                                return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* Activate */Block.__(5, [match$11[0]])]));
                                              }
                                              exit$3 = 1;
                                              break;
                                          case /* SamlError */55 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SamlError */Block.__(55, [
                                                                match$11[0],
                                                                match$11[1]
                                                              ])]));
                                          default:
                                            exit$3 = 1;
                                        }
                                      }
                                      if (exit$3 === 1) {
                                        var match$15 = window._BACKTRACE_IDENTITY_VARIANT;
                                        var exit$4 = 0;
                                        if ((match$15 == null) || match$15 !== "unity") {
                                          exit$4 = 2;
                                        } else {
                                          try {
                                            var qs = Route$BsConsole.UDashEntryParams.decodeSearch(url.search);
                                            return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* UDashEntry */Block.__(2, [qs])]));
                                          }
                                          catch (raw_err){
                                            var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                                            var error = Uncaught$BsConsole.castToJsExn(err);
                                            var path = Belt_List.toArray(url.path).join("/");
                                            var __x$1 = Backtrace$BsConsole.Attributes.addString("src_module", "Bridge/handleNotAuthenticated", Backtrace$BsConsole.Attributes.addString("path", path, Backtrace$BsConsole.Attributes.addString("search", url.search, Backtrace$BsConsole.Attributes.make(undefined))));
                                            Backtrace$BsConsole.Client.send(__x$1, /* `exn */[
                                                  5049499,
                                                  error
                                                ]);
                                            return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* NotFound */Block.__(56, [path])]));
                                          }
                                        }
                                        if (exit$4 === 2) {
                                          var match$16 = List.length(url.path);
                                          var referrer = match$16 !== 0 ? $$String.concat("/", url.path) + (
                                              url.search !== "" ? "?" + url.search : ""
                                            ) : undefined;
                                          return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* Login */Block.__(3, [{
                                                              referrer: referrer,
                                                              token: undefined,
                                                              redirect: undefined,
                                                              method: undefined
                                                            }])]));
                                        }
                                        
                                      }
                                      
                                    }
                                    var token = match$9[0];
                                    if (typeof match$10 === "number" && match$10 === /* NotAsked */0) {
                                      return Curry._1(self.send, /* FetchShellRequest */Block.__(1, [token]));
                                    } else {
                                      var shell$1 = self.state.shell;
                                      var projects;
                                      projects = typeof shell$1 === "number" || shell$1.tag ? undefined : shell$1[0].projects;
                                      var match$17 = Route$BsConsole.routeForPathAndSearch(projects, url.path, url.search);
                                      var match$18 = self.state.shell;
                                      if (typeof match$17 === "number") {
                                        switch (match$17) {
                                          case /* CreateFirstProject */2 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* CreateFirstProject */2]));
                                          case /* Shell */3 :
                                              if (typeof match$18 === "number") {
                                                return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* Shell */3]));
                                              }
                                              if (match$18.tag) {
                                                return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* Shell */3]));
                                              }
                                              var shell$2 = match$18[0];
                                              var lastProj = Belt_Option.flatMap(shell$2.lastProjectPid, (function (pid) {
                                                      return Belt_List.getBy(shell$2.projects, (function (project) {
                                                                    return project.pid === pid;
                                                                  }));
                                                    }));
                                              if (lastProj !== undefined) {
                                                if (!DemoProjectUtil$BsConsole.isDemoProject(lastProj.name)) {
                                                  return replaceUrlWithDefaultView(shell$2, lastProj.name, token);
                                                }
                                                var match$19 = List.length(Belt_List.filter(shell$2.projects, (function (proj) {
                                                            return !DemoProjectUtil$BsConsole.isDemoProject(proj.name);
                                                          })));
                                                if (match$19 === 0) {
                                                  return Curry._1(self.send, /* ReplaceUrl */Block.__(10, [/* CreateFirstProject */2]));
                                                }
                                                var nonDemoProjectFromSessionStorage = window.sessionStorage.getItem("_Backtrace_last_nonDemo_project");
                                                var firstNonDemoProjectInProjectsList = List.hd(shell$2.projects).name;
                                                if (nonDemoProjectFromSessionStorage == null) {
                                                  return replaceUrlWithDefaultView(shell$2, firstNonDemoProjectInProjectsList, token);
                                                } else {
                                                  return replaceUrlWithDefaultView(shell$2, nonDemoProjectFromSessionStorage, token);
                                                }
                                              }
                                              var match$20 = List.length(shell$2.projects);
                                              if (match$20 === 0) {
                                                return Curry._1(self.send, /* ReplaceUrl */Block.__(10, [/* CreateFirstProject */2]));
                                              }
                                              var shellProjectName = List.hd(shell$2.projects).name;
                                              return replaceUrlWithDefaultView(shell$2, shellProjectName, token);
                                          case /* Settings */4 :
                                              return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* SettingsMe */5]));
                                          case /* SettingsMe */5 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsMe */5]));
                                          case /* SettingsUsers */6 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsUsers */6]));
                                          case /* SettingsTeams */7 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsTeams */7]));
                                          case /* SettingsProjects */8 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsProjects */8]));
                                          case /* SettingsProjectsNew */9 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsProjectsNew */9]));
                                          case /* SettingsProjectsFork */10 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsProjectsFork */10]));
                                          case /* SettingsInvites */11 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsInvites */11]));
                                          case /* SettingsInvitesNew */12 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsInvitesNew */12]));
                                          case /* SettingsNetworking */13 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsNetworking */13]));
                                          case /* SettingsRetentionPolicy */14 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsRetentionPolicy */14]));
                                          case /* SettingsNetworkingSSL */15 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsNetworkingSSL */15]));
                                          case /* SettingsNetworkingListeners */16 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsNetworkingListeners */16]));
                                          case /* SettingsWhitelist */17 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsWhitelist */17]));
                                          case /* SettingsBilling */18 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsBilling */18]));
                                          case /* SettingsBillingPlans */19 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsBillingPlans */19]));
                                          case /* SettingsSubscriptions */20 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsSubscriptions */20]));
                                          case /* SettingsSamlConfig */21 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsSamlConfig */21]));
                                          case /* WfPlayground */22 :
                                              var match$21 = location.host;
                                              switch (match$21) {
                                                case "backtrace.sp.backtrace.io" :
                                                case "yolo.sp.backtrace.io" :
                                                    return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* WfPlayground */22]));
                                                default:
                                                  return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* NotFound */Block.__(56, ["/not-found"])]));
                                              }
                                          case /* UiPlayground */23 :
                                              var match$22 = location.host;
                                              switch (match$22) {
                                                case "backtrace.sp.backtrace.io" :
                                                case "yolo.sp.backtrace.io" :
                                                    return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* UiPlayground */23]));
                                                default:
                                                  return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* NotFound */Block.__(56, ["/not-found"])]));
                                              }
                                          default:
                                            return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* Shell */3]));
                                        }
                                      } else {
                                        switch (match$17.tag | 0) {
                                          case /* UDashEntry */2 :
                                              return Curry._1(self.send, /* HandleUDashEntry */Block.__(15, [match$17[0]]));
                                          case /* Share */4 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* Share */Block.__(4, [match$17[0]])]));
                                          case /* SettingsWfConnectionManagement */6 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* SettingsWfConnectionManagement */Block.__(6, [match$17[0]])]));
                                          case /* Project */7 :
                                              if (typeof match$18 === "number" || match$18.tag) {
                                                return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* Shell */3]));
                                              } else {
                                                return replaceUrlWithDefaultView(match$18[0], match$17[0], token);
                                              }
                                          case /* ProjectOverview */8 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectOverview */Block.__(8, [
                                                                match$17[0],
                                                                match$17[1]
                                                              ])]));
                                          case /* ProjectCompareReleases */9 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectCompareReleases */Block.__(9, [
                                                                match$17[0],
                                                                match$17[1],
                                                                match$17[2]
                                                              ])]));
                                          case /* ProjectUpload */10 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectUpload */Block.__(10, [match$17[0]])]));
                                          case /* ProjectTriage */11 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectTriage */Block.__(11, [
                                                                match$17[0],
                                                                match$17[1],
                                                                match$17[2]
                                                              ])]));
                                          case /* ProjectInboxLegacy1 */12 :
                                              return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* ProjectTriage */Block.__(11, [
                                                                match$17[0],
                                                                match$17[1],
                                                                undefined
                                                              ])]));
                                          case /* ProjectQueryBuilder */13 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectQueryBuilder */Block.__(13, [
                                                                match$17[0],
                                                                match$17[1]
                                                              ])]));
                                          case /* ProjectQueryBuilderLegacy1 */14 :
                                              return Curry._1(self.send, /* ReplaceUrl */Block.__(10, [/* ProjectQueryBuilder */Block.__(13, [
                                                                match$17[0],
                                                                match$17[1]
                                                              ])]));
                                          case /* ProjectInstances */15 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectInstances */Block.__(15, [
                                                                match$17[0],
                                                                match$17[1]
                                                              ])]));
                                          case /* ProjectInstancesLegacy1 */16 :
                                              return Curry._1(self.send, /* ReplaceUrl */Block.__(10, [/* ProjectInstances */Block.__(15, [
                                                                match$17[0],
                                                                match$17[1]
                                                              ])]));
                                          case /* ProjectDebugger */17 :
                                          case /* ProjectDebuggerLegacy1 */18 :
                                              break;
                                          case /* ProjectDebugger2 */19 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectDebugger2 */Block.__(19, [
                                                                match$17[0],
                                                                match$17[1]
                                                              ])]));
                                          case /* ProjectFlamegraph */20 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectFlamegraph */Block.__(20, [
                                                                match$17[0],
                                                                match$17[1]
                                                              ])]));
                                          case /* ProjectSimilarity */21 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSimilarity */Block.__(21, [
                                                                match$17[0],
                                                                match$17[1]
                                                              ])]));
                                          case /* ProjectSettings */22 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettings */Block.__(22, [match$17[0]])]));
                                          case /* ProjectSettingsPlayground */23 :
                                              var name = match$17[0];
                                              var match$23 = location.host;
                                              switch (match$23) {
                                                case "backtrace.sp.backtrace.io" :
                                                case "yolo.sp.backtrace.io" :
                                                    return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsPlayground */Block.__(23, [name])]));
                                                default:
                                                  return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* NotFound */Block.__(56, ["/not-found"])]));
                                              }
                                          case /* ProjectSettingsMetricsExporter */24 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsMetricsExporter */Block.__(24, [match$17[0]])]));
                                          case /* ProjectSettingsScrubbers */25 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsScrubbers */Block.__(25, [match$17[0]])]));
                                          case /* ProjectSettingsServerSideActions */26 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsServerSideActions */Block.__(26, [match$17[0]])]));
                                          case /* ProjectSettingsAlerts */27 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsAlerts */Block.__(27, [
                                                                match$17[0],
                                                                match$17[1]
                                                              ])]));
                                          case /* ProjectSettingsIntegratedDocs */28 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsIntegratedDocs */Block.__(28, [
                                                                match$17[0],
                                                                match$17[1]
                                                              ])]));
                                          case /* ProjectSettingsSubmission */29 :
                                          case /* ProjectSettingsSubmissionTokens */31 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsSubmissionTokens */Block.__(31, [match$17[0]])]));
                                          case /* ProjectSettingStorageSampling */32 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingStorageSampling */Block.__(32, [match$17[0]])]));
                                          case /* ProjectSettingsSubmissionDataSources */33 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsSubmissionDataSources */Block.__(33, [match$17[0]])]));
                                          case /* ProjectSettingsAttributes */34 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsAttributes */Block.__(34, [
                                                                match$17[0],
                                                                match$17[1]
                                                              ])]));
                                          case /* ProjectSettingsIntegrations */35 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsIntegrations */Block.__(35, [
                                                                match$17[0],
                                                                match$17[1],
                                                                match$17[2]
                                                              ])]));
                                          case /* ProjectSettingsSymbolsUploadedArchives */36 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsSymbolsUploadedArchives */Block.__(36, [match$17[0]])]));
                                          case /* ProjectSettingsSymbolsUpload */37 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsSymbolsUpload */Block.__(37, [match$17[0]])]));
                                          case /* ProjectSettingsSymbolServers */38 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsSymbolServers */Block.__(38, [match$17[0]])]));
                                          case /* ProjectSettingsSymbols */39 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsSymbols */Block.__(39, [match$17[0]])]));
                                          case /* ProjectSettingsSymbolAccessTokens */40 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsSymbolAccessTokens */Block.__(40, [match$17[0]])]));
                                          case /* ProjectSettingsMissingSymbols */41 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsMissingSymbols */Block.__(41, [match$17[0]])]));
                                          case /* ProjectSettingsSymbolSearch */42 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsSymbolSearch */Block.__(42, [match$17[0]])]));
                                          case /* ProjectSettingsScheduledReports */43 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsScheduledReports */Block.__(43, [match$17[0]])]));
                                          case /* ProjectSettingsAccessControl */44 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsAccessControl */Block.__(44, [match$17[0]])]));
                                          case /* ProjectSettingsReprocessing */45 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsReprocessing */Block.__(45, [match$17[0]])]));
                                          case /* ProjectSettingsStorage */30 :
                                          case /* ProjectSettingsRetentionPolicy */46 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsRetentionPolicy */Block.__(46, [match$17[0]])]));
                                          case /* ProjectSettingsSourceCode */47 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsSourceCode */Block.__(47, [match$17[0]])]));
                                          case /* ProjectSettingsApiTokens */48 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsApiTokens */Block.__(48, [match$17[0]])]));
                                          case /* ProjectSettingsDedupeConfig */49 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsDedupeConfig */Block.__(49, [
                                                                match$17[0],
                                                                match$17[1]
                                                              ])]));
                                          case /* ProjectSettingsStabilityMonitoring */50 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* ProjectSettingsStabilityMonitoring */Block.__(50, [match$17[0]])]));
                                          case /* RedirectLegacyDebugger */51 :
                                              var fingerprint = match$17[1];
                                              var name$1 = match$17[0];
                                              if (fingerprint !== undefined) {
                                                var __x$2 = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                          "_tx",
                                                          /* Equal */Block.__(0, [/* `ObjectID */[
                                                                -589436806,
                                                                match$17[2]
                                                              ]])
                                                        ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, Crdb$BsConsole.Aperture.$$default));
                                                var route_001 = {
                                                  aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                                                              /* Floating */0,
                                                              /* All */6
                                                            ])), Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                                    "fingerprint",
                                                                    /* Equal */Block.__(0, [/* `String */[
                                                                          -976970511,
                                                                          fingerprint
                                                                        ]])
                                                                  ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, Curry._2(Crdb$BsConsole.Aperture.setFilters, __x$2, Crdb$BsConsole.Aperture.$$default))), Crdb$BsConsole.Aperture.$$default)),
                                                  state: undefined
                                                };
                                                var route$1 = /* ProjectDebugger2 */Block.__(19, [
                                                    name$1,
                                                    route_001
                                                  ]);
                                                return Curry._1(self.send, /* UpdateUrl */Block.__(9, [route$1]));
                                              }
                                              var __x$3 = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                        "_tx",
                                                        /* Equal */Block.__(0, [/* `ObjectID */[
                                                              -589436806,
                                                              match$17[2]
                                                            ]])
                                                      ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, Crdb$BsConsole.Aperture.$$default));
                                              var route_001$1 = {
                                                aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                                                            /* Floating */0,
                                                            /* All */6
                                                          ])), Curry._2(Crdb$BsConsole.Aperture.setFilters, __x$3, Crdb$BsConsole.Aperture.$$default)),
                                                state: undefined
                                              };
                                              var route$2 = /* ProjectDebugger2 */Block.__(19, [
                                                  name$1,
                                                  route_001$1
                                                ]);
                                              return Curry._1(self.send, /* UpdateUrl */Block.__(9, [route$2]));
                                          case /* RedirectLegacyDetailView */52 :
                                              var projectName = match$17[0];
                                              var aperture = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                                                          /* Floating */0,
                                                          /* All */6
                                                        ])), Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                                "fingerprint",
                                                                /* Equal */Block.__(0, [/* `String */[
                                                                      -976970511,
                                                                      match$17[1]
                                                                    ]])
                                                              ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, Crdb$BsConsole.Aperture.$$default)), Crdb$BsConsole.Aperture.$$default));
                                              var route_001$2 = Route$BsConsole.getInboxParams(projectName, Caml_option.some(aperture), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                                              var route$3 = /* ProjectTriage */Block.__(11, [
                                                  projectName,
                                                  route_001$2,
                                                  undefined
                                                ]);
                                              return Curry._1(self.send, /* UpdateUrl */Block.__(9, [route$3]));
                                          case /* RedirectLegacyQueryBuilder */53 :
                                              var qs$1 = match$17[1];
                                              var name$2 = match$17[0];
                                              var route_001$3 = Route$BsConsole.getQueryBuilderParams(Caml_option.some(qs$1.aperture), undefined, name$2, undefined, Caml_option.some(qs$1.fold), undefined, undefined, undefined, undefined);
                                              var route$4 = /* ProjectQueryBuilder */Block.__(13, [
                                                  name$2,
                                                  route_001$3
                                                ]);
                                              return Curry._1(self.send, /* UpdateUrl */Block.__(9, [route$4]));
                                          case /* Workflow */54 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* Workflow */Block.__(54, [match$17[0]])]));
                                          case /* NotFound */56 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* NotFound */Block.__(56, [match$17[0]])]));
                                          case /* Error */57 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* Error */Block.__(57, [match$17[0]])]));
                                          case /* GenerateDemoProject */58 :
                                              return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* GenerateDemoProject */Block.__(58, [match$17[0]])]));
                                          default:
                                            return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* Shell */3]));
                                        }
                                      }
                                      return Curry._1(self.send, /* ReplaceUrl */Block.__(10, [/* ProjectDebugger2 */Block.__(19, [
                                                        match$17[0],
                                                        {
                                                          aperture: match$17[1].aperture,
                                                          state: undefined
                                                        }
                                                      ])]));
                                    }
                                  })]);
                  case /* UpdateUrl */9 :
                      var route = action[0];
                      var route$1 = state.route;
                      var lastPath = route$1 !== undefined ? Route$BsConsole.pathForRoute(route$1) : "/";
                      if (!BlockNavigation$BsConsole.isBlocked.contents) {
                        return /* SideEffects */Block.__(1, [(function (_self) {
                                      state.lastPath.contents = lastPath;
                                      var path = Route$BsConsole.pathForRoute(route);
                                      try {
                                        IFrame$BsConsole.postMessage({
                                              type_: "backtrace-url-change",
                                              payload: btoa(path)
                                            });
                                      }
                                      catch (exn){
                                        
                                      }
                                      return ReasonReactRouter.push(path);
                                    })]);
                      }
                      var warning = Belt_Option.getWithDefault(BlockNavigation$BsConsole.warningMessage.contents, "Navigation is currently blocked");
                      window.alert(warning);
                      return /* NoUpdate */0;
                  case /* ReplaceUrl */10 :
                      var route$2 = action[0];
                      var route$3 = state.route;
                      var lastPath$1 = route$3 !== undefined ? Route$BsConsole.pathForRoute(route$3) : "/";
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  auth: state.auth,
                                  route: route$2,
                                  lastPath: state.lastPath,
                                  entrypoint: state.entrypoint,
                                  shell: state.shell,
                                  session: state.session,
                                  drawer: state.drawer
                                },
                                (function (_self) {
                                    state.lastPath.contents = lastPath$1;
                                    var path = Route$BsConsole.pathForRoute(route$2);
                                    try {
                                      IFrame$BsConsole.postMessage({
                                            type_: "backtrace-url-replace",
                                            payload: btoa(Route$BsConsole.pathForRoute(route$2))
                                          });
                                    }
                                    catch (exn){
                                      
                                    }
                                    return Util$BsConsole.replace(path);
                                  })
                              ]);
                  case /* UpdateRoute */11 :
                      return /* Update */Block.__(0, [{
                                  auth: state.auth,
                                  route: action[0],
                                  lastPath: state.lastPath,
                                  entrypoint: state.entrypoint,
                                  shell: state.shell,
                                  session: state.session,
                                  drawer: state.drawer
                                }]);
                  case /* LoginSuccess */12 :
                      var project = action[2];
                      var referrer = action[1];
                      var token$5 = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  auth: /* Authenticated */[token$5],
                                  route: state.route,
                                  lastPath: state.lastPath,
                                  entrypoint: state.entrypoint,
                                  shell: /* NotAsked */0,
                                  session: state.session,
                                  drawer: state.drawer
                                },
                                (function (self) {
                                    Effect$BsConsole.writeTokenToLocalStorage(token$5);
                                    Effect$BsConsole.removeReferrerFromLocalStorage(undefined);
                                    if (referrer === undefined) {
                                      if (project !== undefined) {
                                        return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* Project */Block.__(7, [project])]));
                                      } else {
                                        return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* Shell */3]));
                                      }
                                    }
                                    try {
                                      var fullPath = atob(referrer).split("?");
                                      var __x = Belt_Option.getWithDefault(Belt_Array.get(fullPath, 0), "").split("/");
                                      var path = Belt_List.fromArray(Belt_Array.keep(__x, (function (s) {
                                                  return s !== "";
                                                })));
                                      var search = Belt_Option.getWithDefault(Belt_Array.get(fullPath, 1), "");
                                      return Curry._1(self.send, /* UpdateUrl */Block.__(9, [Route$BsConsole.routeForPathAndSearch(undefined, path, search)]));
                                    }
                                    catch (exn){
                                      return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* Shell */3]));
                                    }
                                  })
                              ]);
                  case /* HandleTask */13 :
                      var task = action[1];
                      var cb = action[0];
                      return /* SideEffects */Block.__(1, [(function (self) {
                                    var request = task.promise;
                                    request.then((function (response) {
                                              return Promise.resolve(/* tuple */[
                                                          response.json(),
                                                          response
                                                        ]);
                                            })).then((function (param) {
                                            var response = param[1];
                                            return param[0].then((function (json) {
                                                          var json$prime = json;
                                                          var error = Error$BsConsole.HTTPD.decode(json);
                                                          if (error !== undefined) {
                                                            var match = error.code;
                                                            if (match !== undefined) {
                                                              if (typeof match === "number") {
                                                                if (match !== 5) {
                                                                  
                                                                } else {
                                                                  Curry._1(self.send, /* HandleExpiredToken */2);
                                                                }
                                                              } else if (match[0] === undefined) {
                                                                var maybeFingerprint = response.headers.get("X-Object-Fingerprint");
                                                                json$prime = Json_encode.object_(/* :: */[
                                                                      /* tuple */[
                                                                        "X-Object-Fingerprint",
                                                                        Json_encode.nullable((function (prim) {
                                                                                return prim;
                                                                              }), maybeFingerprint === null ? undefined : Caml_option.some(maybeFingerprint))
                                                                      ],
                                                                      /* :: */[
                                                                        /* tuple */[
                                                                          "error",
                                                                          Json_encode.object_(/* :: */[
                                                                                /* tuple */[
                                                                                  "message",
                                                                                  error.message
                                                                                ],
                                                                                /* :: */[
                                                                                  /* tuple */[
                                                                                    "code",
                                                                                    7
                                                                                  ],
                                                                                  /* [] */0
                                                                                ]
                                                                              ])
                                                                        ],
                                                                        /* [] */0
                                                                      ]
                                                                    ]);
                                                              }
                                                              
                                                            }
                                                            
                                                          }
                                                          Curry._1(cb, /* Response */[
                                                                json$prime,
                                                                response.headers
                                                              ]);
                                                          return Promise.resolve(json$prime);
                                                        }));
                                          }));
                                    
                                  })]);
                  case /* Logout */14 :
                      var maybeReferrer = action[1];
                      var maybeToken = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  auth: /* NotAuthenticated */0,
                                  route: state.route,
                                  lastPath: state.lastPath,
                                  entrypoint: state.entrypoint,
                                  shell: state.shell,
                                  session: state.session,
                                  drawer: state.drawer
                                },
                                (function (self) {
                                    Effect$BsConsole.removeTokenFromLocalStorage(undefined);
                                    if (maybeToken !== undefined) {
                                      return Curry._1(self.send, /* HandleTask */Block.__(13, [
                                                    (function (_json) {
                                                        return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* Login */Block.__(3, [{
                                                                            referrer: maybeReferrer,
                                                                            token: undefined,
                                                                            redirect: undefined,
                                                                            method: undefined
                                                                          }])]));
                                                      }),
                                                    Task$BsConsole.makeTask(/* Logout */Block.__(8, [maybeToken]))
                                                  ]));
                                    } else {
                                      return Curry._1(self.send, /* UpdateUrl */Block.__(9, [/* Login */Block.__(3, [{
                                                          referrer: maybeReferrer,
                                                          token: undefined,
                                                          redirect: undefined,
                                                          method: undefined
                                                        }])]));
                                    }
                                  })
                              ]);
                  case /* HandleUDashEntry */15 :
                      var qs = action[0];
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  auth: state.auth,
                                  route: state.route,
                                  lastPath: state.lastPath,
                                  entrypoint: /* UDash */Block.__(0, [qs]),
                                  shell: state.shell,
                                  session: state.session,
                                  drawer: state.drawer
                                },
                                (function (self) {
                                    return Curry._1(self.send, /* UpdateRoute */Block.__(11, [/* UDashEntry */Block.__(2, [qs])]));
                                  })
                              ]);
                  
                }
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.TooManyRedirects = TooManyRedirects;
exports.Style = Style;
exports.component = component;
exports.initialize = initialize;
exports.runRequest = runRequest;
exports.redirectCount = redirectCount;
exports.hasBannered = hasBannered;
exports.setHasBannered = setHasBannered;
exports.sendWelcomeBanner = sendWelcomeBanner;
exports.sanitizeNormBy = sanitizeNormBy;
exports.make = make;
/* settingsViewContainer Not a pure module */
