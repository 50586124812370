// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Colors$BsConsole = require("./Colors.js");
var Truncate$BsConsole = require("./Truncate.js");
var BtTableSort$BsConsole = require("./BtTableSort.js");
var BtTableAdapter$BsConsole = require("./BtTableAdapter.js");
var Tooltip = require("@material-ui/core/Tooltip");
var Delete = require("@material-ui/icons/Delete");
var ButtonBase = require("@material-ui/core/ButtonBase");
var IconButton = require("@material-ui/core/IconButton");
var ArrowUpward = require("@material-ui/icons/ArrowUpward");
var ArrowDownward = require("@material-ui/icons/ArrowDownward");

var container = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.height(Css.px(24)),
          /* :: */[
            Css.width(Css.pct(100)),
            /* [] */0
          ]
        ]
      ]
    ]);

function title(deletable) {
  return Css.merge(/* :: */[
              Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              /* :: */[
                "column-title",
                /* :: */[
                  deletable ? "column-title-deletable" : "",
                  /* [] */0
                ]
              ]
            ]);
}

function defaultLabel(align) {
  return Css.style(/* :: */[
              Css.width(Css.pct(100)),
              /* :: */[
                Css.padding(Css.px(4)),
                /* :: */[
                  Css.fontWeight(/* `num */[
                        5496390,
                        500
                      ]),
                  /* :: */[
                    Css.whiteSpace(/* nowrap */867913355),
                    /* :: */[
                      Css.overflow(/* hidden */-862584982),
                      /* :: */[
                        Css.textOverflow(/* ellipsis */166888785),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.textAlign(align),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var deleteContainer = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.display(/* none */-922086728),
            /* [] */0
          ]),
      /* :: */[
        "delete-column",
        /* [] */0
      ]
    ]);

var iconButtonClasses = {
  root: Css.style(/* :: */[
        Css.color(Css.hex(Colors$BsConsole.accent)),
        /* :: */[
          Css.fontSize(Css.rem(1)),
          /* :: */[
            Css.important(Css.padding(Css.px(4))),
            /* [] */0
          ]
        ]
      ]),
  label: Css.style(/* :: */[
        Css.unsafe("fontSize", "inherit"),
        /* [] */0
      ])
};

var truncateLabel = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.width(Css.pct(100)),
            /* [] */0
          ]),
      /* :: */[
        "column-title",
        /* [] */0
      ]
    ]);

var rangeLabelContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.height(Css.px(24)),
          /* :: */[
            Css.width(Css.pct(100)),
            /* :: */[
              Css.position(/* relative */903134412),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

function rangeTitle(deletable, align) {
  return Css.merge(/* :: */[
              Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.padding(Css.px(4)),
                      /* :: */[
                        Css.fontWeight(/* `num */[
                              5496390,
                              500
                            ]),
                        /* :: */[
                          Css.whiteSpace(/* nowrap */867913355),
                          /* :: */[
                            Css.overflow(/* hidden */-862584982),
                            /* :: */[
                              Css.textOverflow(/* ellipsis */166888785),
                              /* :: */[
                                Css.width(Css.pct(100)),
                                /* :: */[
                                  Css.textAlign(align),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                "column-title",
                /* :: */[
                  deletable ? "column-title-deletable" : "",
                  /* [] */0
                ]
              ]
            ]);
}

var rangeMinMaxContainer = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.position(/* absolute */-1013592457),
        /* :: */[
          Css.bottom(Css.px(-14)),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* :: */[
              Css.right(/* zero */-789508312),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

function rangeButton(align) {
  return {
          root: Css.style(/* :: */[
                Css.fontSize(Css.px(11)),
                /* :: */[
                  Css.borderRadius(Css.px(3)),
                  /* :: */[
                    Css.unsafe("maxWidth", "calc(50% - 1rem)"),
                    /* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.justifyContent(/* flexStart */662439529),
                        /* :: */[
                          Css.flexDirection(align === /* left */-944764921 ? /* row */5693978 : /* rowReverse */378951208),
                          /* :: */[
                            Css.padding2(Css.px(2), Css.px(4)),
                            /* :: */[
                              Css.hover(/* :: */[
                                    Css.backgroundColor(Css.hex(Colors$BsConsole.blackA125)),
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ])
        };
}

var rangeLabel = Css.style(/* :: */[
      Css.whiteSpace(/* nowrap */867913355),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.textOverflow(/* ellipsis */166888785),
          /* [] */0
        ]
      ]
    ]);

var rangeSeperator = Css.style(/* :: */[
      Css.textAlign(/* center */98248149),
      /* :: */[
        Css.width(Css.rem(2)),
        /* :: */[
          Css.padding2(/* zero */-789508312, Css.rem(0.5)),
          /* [] */0
        ]
      ]
    ]);

var Style = {
  container: container,
  title: title,
  defaultLabel: defaultLabel,
  deleteContainer: deleteContainer,
  iconButtonClasses: iconButtonClasses,
  truncateLabel: truncateLabel,
  rangeLabelContainer: rangeLabelContainer,
  rangeTitle: rangeTitle,
  rangeMinMaxContainer: rangeMinMaxContainer,
  rangeButton: rangeButton,
  rangeLabel: rangeLabel,
  rangeSeperator: rangeSeperator
};

function BtEnhancedTableHeaderLabel$Default(Props) {
  var sortingState = Props.sortingState;
  var onSortChange = Props.onSortChange;
  var disableSort = Props.disableSort;
  var columnName = Props.columnName;
  var align = Props.align;
  var onColumnDelete = Props.onColumnDelete;
  var children = Props.children;
  return React.createElement("div", {
              className: container
            }, React.createElement("div", {
                  className: title(Belt_Option.isSome(onColumnDelete))
                }, disableSort ? React.createElement("div", {
                        className: defaultLabel(align)
                      }, children) : React.createElement(BtTableSort$BsConsole.Label.make, {
                        sortingState: sortingState,
                        columnName: columnName,
                        onSortChange: onSortChange,
                        align: align,
                        children: children
                      })), onColumnDelete !== undefined ? React.createElement("div", {
                    className: deleteContainer
                  }, React.createElement(Tooltip.default, {
                        children: React.createElement(IconButton.default, {
                              classes: iconButtonClasses,
                              onClick: (function (param) {
                                  return Curry._1(onColumnDelete, columnName);
                                }),
                              children: React.createElement(Delete.default, {
                                    color: "inherit",
                                    fontSize: "inherit"
                                  })
                            }),
                        title: "Delete column"
                      })) : null);
}

var Default = {
  make: BtEnhancedTableHeaderLabel$Default
};

function BtEnhancedTableHeaderLabel$Range(Props) {
  var sortingState = Props.sortingState;
  var onSortChange = Props.onSortChange;
  var columnName = Props.columnName;
  var align = Props.align;
  var onColumnDelete = Props.onColumnDelete;
  var children = Props.children;
  var match = columnName.split("__");
  var rangeSortingState;
  if (match.length !== 2) {
    rangeSortingState = undefined;
  } else {
    var attribute = match[0];
    if (sortingState !== undefined) {
      var match$1 = sortingState.columnName.split("__");
      if (match$1.length !== 2) {
        rangeSortingState = undefined;
      } else {
        var attribute$prime = match$1[0];
        var match$2 = match$1[1];
        switch (match$2) {
          case "range--max" :
              rangeSortingState = attribute === attribute$prime ? /* tuple */[
                  "max",
                  sortingState.direction
                ] : undefined;
              break;
          case "range--min" :
              rangeSortingState = attribute === attribute$prime ? /* tuple */[
                  "min",
                  sortingState.direction
                ] : undefined;
              break;
          default:
            rangeSortingState = undefined;
        }
      }
    } else {
      rangeSortingState = undefined;
    }
  }
  var tmp;
  tmp = rangeSortingState !== undefined && rangeSortingState[0] === "min" ? (
      rangeSortingState[1] >= 759138929 ? React.createElement(ArrowDownward.default, {
              color: "inherit",
              fontSize: "inherit"
            }) : React.createElement(ArrowUpward.default, {
              color: "inherit",
              fontSize: "inherit"
            })
    ) : null;
  var tmp$1;
  tmp$1 = rangeSortingState !== undefined && rangeSortingState[0] === "max" ? (
      rangeSortingState[1] >= 759138929 ? React.createElement(ArrowDownward.default, {
              color: "inherit",
              fontSize: "inherit"
            }) : React.createElement(ArrowUpward.default, {
              color: "inherit",
              fontSize: "inherit"
            })
    ) : null;
  return React.createElement("div", {
              className: rangeLabelContainer
            }, React.createElement("div", {
                  className: rangeTitle(Belt_Option.isSome(onColumnDelete), align)
                }, children), onColumnDelete !== undefined ? React.createElement("div", {
                    className: deleteContainer
                  }, React.createElement(Tooltip.default, {
                        children: React.createElement(IconButton.default, {
                              classes: iconButtonClasses,
                              onClick: (function (param) {
                                  return Curry._1(onColumnDelete, columnName);
                                }),
                              children: React.createElement(Delete.default, {
                                    color: "inherit",
                                    fontSize: "inherit"
                                  })
                            }),
                        title: "Delete column"
                      })) : null, React.createElement(Row2$BsConsole.make, {
                  flexWrap: /* nowrap */867913355,
                  className: rangeMinMaxContainer,
                  children: null
                }, React.createElement(Tooltip.default, {
                      children: React.createElement(ButtonBase.default, {
                            classes: rangeButton(align),
                            onClick: (function (param) {
                                var tmp;
                                var exit = 0;
                                if (rangeSortingState !== undefined && rangeSortingState[0] === "min") {
                                  tmp = rangeSortingState[1] >= 759138929 ? ({
                                        columnName: "" + (String(columnName) + "--min"),
                                        direction: /* Asc */3258129
                                      }) : ({
                                        columnName: "" + (String(columnName) + "--min"),
                                        direction: /* Desc */759138929
                                      });
                                } else {
                                  exit = 1;
                                }
                                if (exit === 1) {
                                  tmp = {
                                    columnName: "" + (String(columnName) + "--min"),
                                    direction: /* Desc */759138929
                                  };
                                }
                                return Curry._1(onSortChange, tmp);
                              }),
                            disableRipple: true,
                            children: null
                          }, React.createElement("span", {
                                className: rangeLabel
                              }, I18N$BsConsole.show(undefined, "min")), tmp),
                      title: "Sort range by min value"
                    }), React.createElement("div", {
                      className: rangeSeperator
                    }, I18N$BsConsole.showSkip("|")), React.createElement(Tooltip.default, {
                      children: React.createElement(ButtonBase.default, {
                            classes: rangeButton(align),
                            onClick: (function (param) {
                                var tmp;
                                var exit = 0;
                                if (rangeSortingState !== undefined && rangeSortingState[0] === "max") {
                                  tmp = rangeSortingState[1] >= 759138929 ? ({
                                        columnName: "" + (String(columnName) + "--max"),
                                        direction: /* Asc */3258129
                                      }) : ({
                                        columnName: "" + (String(columnName) + "--max"),
                                        direction: /* Desc */759138929
                                      });
                                } else {
                                  exit = 1;
                                }
                                if (exit === 1) {
                                  tmp = {
                                    columnName: "" + (String(columnName) + "--max"),
                                    direction: /* Desc */759138929
                                  };
                                }
                                return Curry._1(onSortChange, tmp);
                              }),
                            disableRipple: true,
                            children: null
                          }, React.createElement("span", {
                                className: rangeLabel
                              }, I18N$BsConsole.show(undefined, "max")), tmp$1),
                      title: "Sort range by max value"
                    })));
}

var $$Range = {
  make: BtEnhancedTableHeaderLabel$Range
};

function BtEnhancedTableHeaderLabel(Props) {
  var column = Props.column;
  var sortingState = Props.sortingState;
  var onSortChange = Props.onSortChange;
  var onColumnDelete = Props.onColumnDelete;
  var match = column.align;
  var align = match >= 847852583 ? /* left */-944764921 : /* right */-379319332;
  if (onSortChange === undefined) {
    return React.createElement(Truncate$BsConsole.make, {
                className: truncateLabel,
                children: I18N$BsConsole.showSkip(column.title)
              });
  }
  var col = column.name;
  if (BtTableAdapter$BsConsole.CrdbAggregate.isRangeColumn(col)) {
    var tmp = {
      sortingState: sortingState,
      onSortChange: onSortChange,
      columnName: column.name,
      align: align,
      children: I18N$BsConsole.showSkip(column.title)
    };
    if (onColumnDelete !== undefined) {
      tmp.onColumnDelete = Caml_option.valFromOption(onColumnDelete);
    }
    return React.createElement(BtEnhancedTableHeaderLabel$Range, tmp);
  }
  var tmp$1 = {
    sortingState: sortingState,
    onSortChange: onSortChange,
    disableSort: column.disableSort,
    columnName: column.name,
    align: align,
    children: I18N$BsConsole.showSkip(column.title)
  };
  if (onColumnDelete !== undefined) {
    tmp$1.onColumnDelete = Caml_option.valFromOption(onColumnDelete);
  }
  return React.createElement(BtEnhancedTableHeaderLabel$Default, tmp$1);
}

var make = BtEnhancedTableHeaderLabel;

exports.Style = Style;
exports.Default = Default;
exports.$$Range = $$Range;
exports.make = make;
/* container Not a pure module */
