// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Bin$BsConsole = require("./aggregation-renderers/Bin.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Util$BsConsole = require("./util.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Parse$BsConsole = require("./bs-crdb-response/src/Parse.js");
var Route$BsConsole = require("./route.js");
var Colors$BsConsole = require("./Colors.js");
var Issues$BsConsole = require("./bs-crdb-response/src/Issues.js");
var Numeral$BsConsole = require("./Numeral.js");
var IssueTags$BsConsole = require("./format-renderers/IssueTags.js");
var EmptyValue$BsConsole = require("./format-renderers/EmptyValue.js");
var IssueState$BsConsole = require("./format-renderers/IssueState.js");
var TriageData$BsConsole = require("./TriageData.js");
var ApiOverview$BsConsole = require("./ApiOverview.js");
var BtTableCell$BsConsole = require("./BtTableCell.js");
var Interpolate$BsConsole = require("./Interpolate.js");
var TriageGroup$BsConsole = require("./TriageGroup.js");
var IssueTickets$BsConsole = require("./format-renderers/IssueTickets.js");
var BtTableColumn$BsConsole = require("./BtTableColumn.js");
var BtTableAdapter$BsConsole = require("./BtTableAdapter.js");
var FormatRenderer$BsConsole = require("./format-renderers/FormatRenderer.js");
var IssueAssignees$BsConsole = require("./format-renderers/IssueAssignees.js");
var L10N_date_time$BsConsole = require("./L10N_date_time.js");
var Tooltip = require("@material-ui/core/Tooltip");
var TriageFingerprint$BsConsole = require("./TriageFingerprint.js");
var FetchProjectOverview$BsConsole = require("./FetchProjectOverview.js");
var BtEnhancedTableHeaderLabel$BsConsole = require("./BtEnhancedTableHeaderLabel.js");

var _VERSION_ATTRIBUTE_TOKEN = "<%versionAttribute%>";

function hasVersionAttribute(queryItem) {
  if (JSON.stringify(queryItem.queryJson).includes(_VERSION_ATTRIBUTE_TOKEN) || queryItem.name.includes(_VERSION_ATTRIBUTE_TOKEN)) {
    return true;
  } else {
    return Belt_Option.isSome(Belt_Option.flatMap(queryItem.queryItemConfigs, (function (configs) {
                      return Belt_List.getBy(configs, (function (configItem) {
                                    return configItem.value.includes(_VERSION_ATTRIBUTE_TOKEN);
                                  }));
                    })));
  }
}

function renderDataCell(row, column, value, key, totalCount, handleChangeUrl, handleAddFilters) {
  var col = column.name;
  if (col === BtTableAdapter$BsConsole.CrdbAggregate.factorColumn) {
    var value$1 = Parse$BsConsole.factorToFormat(row.factor);
    return React.createElement(BtTableCell$BsConsole.make, {
                children: React.createElement(FormatRenderer$BsConsole.make, {
                      value: value$1,
                      attribute: row.factor.attribute,
                      handleChangeUrl: handleChangeUrl,
                      handleAddFilters: handleAddFilters
                    }),
                key: key + ("-factor__" + row.factor.value)
              });
  }
  if (col !== BtTableAdapter$BsConsole.CrdbAggregate.countColumn) {
    return BtTableAdapter$BsConsole.CrdbAggregate.renderDataCell(key + ("__" + column.name), value, row, handleChangeUrl, handleAddFilters, undefined);
  }
  var errorPercentage = Numeral$BsConsole.format("0.[000]", Pervasives.string_of_float(row.count / totalCount * 100)) + "%";
  return React.createElement(BtTableCell$BsConsole.make, {
              children: React.createElement(Tooltip.default, {
                    children: React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* :: */[
                                  Css.textAlign(/* right */-379319332),
                                  /* :: */[
                                    Css.fontFamily("inconsolata"),
                                    /* :: */[
                                      Css.padding2(/* zero */-789508312, Css.px(4)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ])
                        }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(row.count)))),
                    title: Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* String_literal */Block.__(11, [
                                      " of total errors in set",
                                      /* End_of_format */0
                                    ])
                                ]),
                              "%s of total errors in set"
                            ]), errorPercentage)
                  }),
              key: key + ("-" + (String(row.count) + column.name))
            });
}

function renderHeaderCell(param, column, sortingState, onSortChange) {
  return React.createElement(BtEnhancedTableHeaderLabel$BsConsole.make, {
              column: column,
              sortingState: sortingState,
              onSortChange: onSortChange
            });
}

var AggregateTable = {
  renderDataCell: renderDataCell,
  renderHeaderCell: renderHeaderCell
};

var statusTableColumn = BtTableColumn$BsConsole.override(TriageData$BsConsole.statusTableColumn, undefined, undefined, undefined, undefined, undefined, undefined, 105, undefined, 105, undefined, undefined, undefined, undefined);

function getTableColumns(aggColumns) {
  var triageColumns = [
    statusTableColumn,
    TriageData$BsConsole.fingerprintTableColumn,
    TriageData$BsConsole.countTableColumn(undefined),
    TriageData$BsConsole.activityTableColumn,
    TriageData$BsConsole.callstackTableColumn,
    TriageData$BsConsole.tagsTableColumn,
    TriageData$BsConsole.assigneesTableColumn,
    TriageData$BsConsole.ticketsTableColumn
  ];
  var aggTableColumns = TriageData$BsConsole.getAggTableColumns(aggColumns);
  return Belt_Array.concat(triageColumns, aggTableColumns);
}

function getTableContainerClassName(rows) {
  var tableContainerStyles = Css.style(/* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
        /* :: */[
          Css.maxHeight(Css.pct(100)),
          /* :: */[
            Css.width(Css.pct(100)),
            /* :: */[
              Css.selector(".BtTableBody > .BtTableRow .triage-action", /* :: */[
                    Css.unsafe("color", "inherit"),
                    /* :: */[
                      Css.opacity(0.5),
                      /* [] */0
                    ]
                  ]),
              /* :: */[
                Css.selector(".BtTableBody > .BtTableRow:hover .triage-action", /* :: */[
                      Css.opacity(1.0),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.accent)),
                        /* [] */0
                      ]
                    ]),
                /* :: */[
                  Css.selector("tr.BtTableRow:hover .frame-count", /* :: */[
                        Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                        /* :: */[
                          Css.color(Css.hex(Colors$BsConsole.white)),
                          /* [] */0
                        ]
                      ]),
                  /* :: */[
                    Css.selector("tr.BtTableRow:hover .triage-fingerprint-link", /* :: */[
                          Css.color(Css.hex(Colors$BsConsole.accent)),
                          /* :: */[
                            Css.textDecoration(/* underline */131142924),
                            /* [] */0
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]);
  var rowStyles = Css.style(Belt_List.fromArray(Belt_Array.mapWithIndex(rows, (function (idx, group) {
                  var index = String(idx + 1 | 0);
                  return Css.selector(".BtTableBody > .BtTableRow:nth-of-type(" + (String(index) + ") > td:nth-of-type(1)"), /* :: */[
                              Css.before(/* :: */[
                                    Css.unsafe("content", "''"),
                                    /* :: */[
                                      Css.position(/* absolute */-1013592457),
                                      /* :: */[
                                        Css.left(Css.px(1)),
                                        /* :: */[
                                          Css.top(Css.px(2)),
                                          /* :: */[
                                            Css.bottom(Css.px(2)),
                                            /* :: */[
                                              Css.width(Css.px(3)),
                                              /* :: */[
                                                Css.borderRadius(Css.px(1)),
                                                /* :: */[
                                                  Css.backgroundColor(Css.hex(Issues$BsConsole.State.toColor(group.issues.state))),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]),
                              /* [] */0
                            ]);
                }))));
  return Css.merge(/* :: */[
              tableContainerStyles,
              /* :: */[
                rowStyles,
                /* [] */0
              ]
            ]);
}

function renderDataCell$1(row, column, value, key, totalCount, aperture, apertureForQuery, handleChangeUrl, handleAddFilters, projectName) {
  var col = column.name;
  if (col === TriageData$BsConsole.fingerprintColumn) {
    return React.createElement(BtTableCell$BsConsole.make, {
                children: React.createElement(TriageFingerprint$BsConsole.make, {
                      fingerprint: row.fingerprint,
                      handleChangeUrl: handleChangeUrl,
                      getDetailsRoute: (function (fingerprint) {
                          var aperture$1 = Crdb$BsConsole.addFilter(aperture, /* :: */[
                                /* tuple */[
                                  "fingerprint",
                                  /* Equal */Block.__(0, [/* `String */[
                                        -976970511,
                                        fingerprint
                                      ]])
                                ],
                                /* [] */0
                              ]);
                          var init = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                          return /* ProjectTriage */Block.__(11, [
                                    projectName,
                                    {
                                      aperture: aperture$1,
                                      sort: init.sort,
                                      stats: init.stats,
                                      havings: init.havings,
                                      fold: init.fold,
                                      normBy: init.normBy,
                                      similarity: init.similarity
                                    },
                                    undefined
                                  ]);
                        }),
                      aperture: aperture,
                      projectName: projectName
                    }),
                key: key + ("-factor__" + row.fingerprint)
              });
  }
  if (col === TriageData$BsConsole.countColumn) {
    var errorPercentage = Numeral$BsConsole.format("0.[000]", Pervasives.string_of_float(row.count / totalCount * 100)) + "%";
    return React.createElement(BtTableCell$BsConsole.make, {
                children: React.createElement(Tooltip.default, {
                      children: React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* :: */[
                                    Css.textAlign(/* right */-379319332),
                                    /* :: */[
                                      Css.fontFamily("inconsolata"),
                                      /* :: */[
                                        Css.padding2(/* zero */-789508312, Css.px(4)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ])
                          }, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(row.count)))),
                      title: Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* String_literal */Block.__(11, [
                                        " of total errors in set",
                                        /* End_of_format */0
                                      ])
                                  ]),
                                "%s of total errors in set"
                              ]), errorPercentage)
                    }),
                key: key + ("-" + (String(row.count) + column.name))
              });
  }
  if (col === TriageData$BsConsole.activityColumn) {
    var tmp;
    var exit = 0;
    if (typeof value === "number" || value[0] !== -901480206) {
      exit = 1;
    } else {
      var match = value[1];
      if (match !== undefined && match.tag === /* Bin */2 && match[0] === "timestamp") {
        var data = match[1];
        if (data !== undefined) {
          var binRange = Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, apertureForQuery));
          var date = row.firstSeen;
          var title = date !== undefined ? Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                      /* String_literal */Block.__(11, [
                          "First seen ",
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* End_of_format */0
                            ])
                        ]),
                      "First seen %s"
                    ]), L10N_date_time$BsConsole.Format.textAbbreviatedDay(undefined, Caml_option.valFromOption(date))) : I18N$BsConsole.get(undefined, "First seen N/A");
          var dateRangeFormat = function (jsDate) {
            return I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.numericDayTwentyFour(undefined, jsDate));
          };
          var match$1 = row.timestampRange;
          tmp = React.createElement(Tooltip.default, {
                placement: "top",
                children: React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.margin2(/* zero */-789508312, Css.px(4)),
                            /* [] */0
                          ])
                    }, React.createElement(Bin$BsConsole.make, {
                          data: data,
                          width: 164,
                          height: 26,
                          range: binRange
                        }), match$1 !== undefined ? React.createElement(Row2$BsConsole.make, {
                            justifyContent: /* spaceBetween */516682146,
                            className: Css.style(/* :: */[
                                  Css.fontSize(Css.px(10)),
                                  /* :: */[
                                    Css.width(Css.pct(100)),
                                    /* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.grey2)),
                                      /* :: */[
                                        Css.selector("> div", /* :: */[
                                              Css.lineHeight(Css.px(8)),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]),
                            children: null
                          }, React.createElement(Tooltip.default, {
                                children: React.createElement("div", undefined, dateRangeFormat(match$1[0])),
                                title: I18N$BsConsole.get(undefined, "First seen in set")
                              }), React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.margin2(/* zero */-789508312, Css.em(0.5)),
                                      /* [] */0
                                    ])
                              }, I18N$BsConsole.showSkip("-")), React.createElement(Tooltip.default, {
                                children: React.createElement("div", undefined, dateRangeFormat(match$1[1])),
                                title: I18N$BsConsole.get(undefined, "Last seen in set")
                              })) : null),
                title: title
              });
        } else {
          exit = 1;
        }
      } else {
        exit = 1;
      }
    }
    if (exit === 1) {
      tmp = React.createElement(EmptyValue$BsConsole.make, { });
    }
    return React.createElement(BtTableCell$BsConsole.make, {
                children: tmp,
                key: String(row.count) + (column.name + "__activity")
              });
  }
  var tmp$1;
  var exit$1 = 0;
  if (typeof value === "number") {
    exit$1 = 1;
  } else {
    var variant = value[0];
    if (variant >= -429493458) {
      if (variant !== 650515019) {
        if (variant !== 766393650) {
          if (variant >= -429493457) {
            exit$1 = 1;
          } else {
            var tickets = value[1];
            if (tickets !== undefined) {
              var unlinkedTickets = Belt_Option.getWithDefault(Issues$BsConsole.Tickets.getUnlinked(tickets), /* [] */0);
              var linkedTickets = Belt_Option.getWithDefault(Issues$BsConsole.Tickets.getLinked(tickets), /* [] */0);
              var tickets$1 = Belt_List.toArray(Belt_List.keepMap(Belt_List.keep(Belt_List.map(Belt_List.concat(unlinkedTickets, linkedTickets), (function (ticket) {
                                  switch (ticket.tag | 0) {
                                    case /* Unlinked */0 :
                                    case /* Linked */1 :
                                        break;
                                    case /* InteralAssigneeLink */2 :
                                        return ;
                                    
                                  }
                                  var t = ticket[0];
                                  return /* tuple */[
                                          t.short,
                                          t.url
                                        ];
                                })), Belt_Option.isSome), Util$BsConsole.identity));
              tmp$1 = tickets$1.length !== 0 ? React.createElement(IssueTickets$BsConsole.make, {
                      tickets: tickets$1
                    }) : React.createElement(EmptyValue$BsConsole.make, { });
            } else {
              exit$1 = 1;
            }
          }
        } else {
          var tags = value[1];
          if (tags !== undefined) {
            var tags$1 = Belt_List.toArray(Belt_List.sort(Belt_List.keep(tags, (function (tag) {
                            return tag.trim() !== "";
                          })), (function (a, b) {
                        if (b.length > a.length) {
                          return 1;
                        } else {
                          return -1;
                        }
                      })));
            tmp$1 = React.createElement(IssueTags$BsConsole.make, {
                  tags: tags$1,
                  handleChangeUrl: handleChangeUrl,
                  handleAddFilters: handleAddFilters
                });
          } else {
            exit$1 = 1;
          }
        }
      } else {
        var assignees = value[1];
        if (assignees !== undefined) {
          tmp$1 = React.createElement(IssueAssignees$BsConsole.make, {
                assignees: assignees
              });
        } else {
          exit$1 = 1;
        }
      }
    } else if (variant !== -1007993960) {
      if (variant !== -901480206) {
        exit$1 = 1;
      } else {
        tmp$1 = BtTableAdapter$BsConsole.CrdbAggregate.renderShape(value[1], row.count, handleChangeUrl, handleAddFilters, undefined);
      }
    } else {
      tmp$1 = React.createElement(IssueState$BsConsole.make, {
            state: value[1]
          });
    }
  }
  if (exit$1 === 1) {
    tmp$1 = React.createElement(EmptyValue$BsConsole.make, { });
  }
  return React.createElement(BtTableCell$BsConsole.make, {
              children: tmp$1,
              key: row.fingerprint + column.name
            });
}

function renderHeaderCell$1(param, column, sortingState, onSortChange) {
  return React.createElement(BtEnhancedTableHeaderLabel$BsConsole.make, {
              column: column,
              sortingState: sortingState,
              onSortChange: onSortChange
            });
}

var TriageTable = {
  statusTableColumn: statusTableColumn,
  getTableColumns: getTableColumns,
  getTableContainerClassName: getTableContainerClassName,
  renderDataCell: renderDataCell$1,
  renderHeaderCell: renderHeaderCell$1
};

function getFactor(query, versionAttribute) {
  var f = Curry._1(Crdb$BsConsole.Query.getFactor, query);
  if (f !== undefined) {
    if (f && f[0] === _VERSION_ATTRIBUTE_TOKEN) {
      if (versionAttribute !== undefined) {
        return /* Custom */[versionAttribute];
      } else {
        return /* Custom */["*"];
      }
    } else {
      return f;
    }
  }
  
}

function factorToString(factor) {
  if (!factor) {
    return "fingerprint";
  }
  var attr = factor[0];
  switch (attr) {
    case "*" :
    case "NONE" :
    case "None" :
        return "*";
    default:
      return attr;
  }
}

function getDefaultGroup(queryItem, versionAttribute) {
  var defaultGroupFromQuery = Belt_Option.map(getFactor(queryItem.query, versionAttribute), factorToString);
  var defaultGroupFromConfig = ApiOverview$BsConsole.QueryItem.getBacktraceDefaultGroupFromConfig(queryItem);
  if (defaultGroupFromConfig !== undefined) {
    return defaultGroupFromConfig;
  } else {
    return defaultGroupFromQuery;
  }
}

function getCurrentGroup(queryItem, versionAttribute) {
  var defaultGroup = getDefaultGroup(queryItem, versionAttribute);
  var groupFromConfig = ApiOverview$BsConsole.QueryItem.getGroupFromConfig(queryItem);
  if (groupFromConfig !== undefined) {
    return groupFromConfig;
  } else {
    return defaultGroup;
  }
}

function convertBinDataToFloats(binData) {
  return Belt_List.reduce(binData, [], (function (acc, param) {
                var count = param[2];
                var end_ = param[1];
                var start = param[0];
                if (start === undefined) {
                  return acc;
                }
                switch (start.tag | 0) {
                  case /* Raw */0 :
                      var match = start[0];
                      if (typeof match === "number") {
                        return acc;
                      }
                      var variant = match[0];
                      if (variant !== 365180284) {
                        if (variant !== 758940238) {
                          return acc;
                        }
                        if (end_ === undefined) {
                          return acc;
                        }
                        if (end_.tag) {
                          return acc;
                        }
                        var match$1 = end_[0];
                        if (typeof match$1 === "number" || match$1[0] !== 758940238) {
                          return acc;
                        } else {
                          return Belt_Array.concat(acc, [/* tuple */[
                                        Math.floor(match[1].valueOf() / 1000),
                                        Math.floor(match$1[1].valueOf() / 1000),
                                        count
                                      ]]);
                        }
                      }
                      if (end_ === undefined) {
                        return acc;
                      }
                      if (end_.tag) {
                        return acc;
                      }
                      var match$2 = end_[0];
                      if (typeof match$2 === "number" || match$2[0] !== 365180284) {
                        return acc;
                      } else {
                        return Belt_Array.concat(acc, [/* tuple */[
                                      match[1],
                                      match$2[1],
                                      count
                                    ]]);
                      }
                  case /* UnixTimestamp */2 :
                      if (end_ !== undefined && end_.tag === /* UnixTimestamp */2) {
                        return Belt_Array.concat(acc, [/* tuple */[
                                      Math.floor(start[0].valueOf() / 1000),
                                      Math.floor(end_[0].valueOf() / 1000),
                                      count
                                    ]]);
                      } else {
                        return acc;
                      }
                  case /* GpsTimestamp */3 :
                      if (end_ !== undefined && end_.tag === /* GpsTimestamp */3) {
                        return Belt_Array.concat(acc, [/* tuple */[
                                      Math.floor(start[0].valueOf() / 1000),
                                      Math.floor(end_[0].valueOf() / 1000),
                                      count
                                    ]]);
                      } else {
                        return acc;
                      }
                  default:
                    return acc;
                }
              }));
}

function getUniqueFingerprint(columns, group) {
  return Group$BsConsole.unwrapUniqueCount(Group$BsConsole.getAggregation(columns, group, "fingerprint", /* Unique */0));
}

function getUniqueGuid(columns, group) {
  return Group$BsConsole.unwrapUniqueCount(Group$BsConsole.getAggregation(columns, group, "guid", /* Unique */0));
}

function useTableData(serviceEndpoint, token, projectName, universe, queryItem, aperture, versionAttribute, dashboard, overridesOpt, param) {
  var overrides = overridesOpt !== undefined ? overridesOpt : /* [] */0;
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setTableRemote = match[1];
  var match$1 = React.useState((function () {
          var __x = queryItem.query;
          return Curry._2(Crdb$BsConsole.Query.setAperture, aperture, __x);
        }));
  var setQuery = match$1[1];
  var query = match$1[0];
  var match$2 = React.useState((function () {
          return /* [] */0;
        }));
  var setLocalOverrides = match$2[1];
  var localOverrides = match$2[0];
  var match$3 = React.useState((function () {
          return overrides;
        }));
  var setQueryOverrides = match$3[1];
  var queryOverrides = match$3[0];
  var queryRequestRemote = FetchProjectOverview$BsConsole.useQueryRequest(serviceEndpoint, token, projectName, universe, queryItem.id, query, versionAttribute, queryOverrides, dashboard);
  React.useEffect((function () {
          var overrides$prime = Belt_List.keep(overrides, (function (param) {
                  var k = param[0];
                  return Belt_Option.isNone(Belt_List.getBy(localOverrides, (function (param) {
                                    return k === param[0];
                                  })));
                }));
          Curry._1(setQueryOverrides, (function (param) {
                  return Belt_List.concat(overrides$prime, localOverrides);
                }));
          
        }), /* tuple */[
        localOverrides,
        overrides
      ]);
  React.useEffect((function () {
          var currentAperture = Curry._1(Crdb$BsConsole.Query.getAperture, query);
          if (!Curry._2(Crdb$BsConsole.Aperture.eq, aperture, currentAperture)) {
            Curry._1(setQuery, (function (currentQuery) {
                    return Curry._2(Crdb$BsConsole.Query.setAperture, aperture, currentQuery);
                  }));
          }
          
        }), [aperture]);
  React.useEffect((function () {
          var match = queryItem.variant;
          if (typeof queryRequestRemote === "number") {
            if (queryRequestRemote === /* NotAsked */0) {
              Curry._1(setTableRemote, (function (param) {
                      return /* NotAsked */0;
                    }));
            } else {
              Curry._1(setTableRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          } else if (queryRequestRemote.tag) {
            var msg = queryRequestRemote[0];
            Curry._1(setTableRemote, (function (param) {
                    return /* Failure */Block.__(1, [msg]);
                  }));
          } else {
            var match$1 = queryRequestRemote[0];
            var summary = match$1[0];
            switch (summary.tag | 0) {
              case /* Select */0 :
                  break;
              case /* Aggregate */1 :
                  var next = match$1[1];
                  var match$2 = summary[0];
                  var metadata = match$2[2];
                  var rows = match$2[1];
                  var columns = match$2[0];
                  var exit = 0;
                  switch (match) {
                    case /* Explore */0 :
                        var factor = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getFactor, query), /* Custom */["*"]);
                        var columns$1 = Belt_Array.map(BtTableAdapter$BsConsole.CrdbAggregate.getTableColumns(columns, factor), (function (column) {
                                if (!(column.title.includes(_VERSION_ATTRIBUTE_TOKEN) && Belt_Option.isSome(versionAttribute))) {
                                  return column;
                                }
                                var title = Belt_Option.getWithDefault(versionAttribute, "").replace(column.title, _VERSION_ATTRIBUTE_TOKEN);
                                return BtTableColumn$BsConsole.override(column, title, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                              }));
                        var rows$1 = Belt_List.toArray(rows);
                        var loading = typeof next === "number" ? next !== 0 : false;
                        var sortingState = Belt_Option.flatMap(Curry._1(Crdb$BsConsole.Query.getSort, query), (function (sort) {
                                return BtTableAdapter$BsConsole.CrdbAggregate.getTableSortingState(sort);
                              }));
                        var handleSetSort = function (sort) {
                          return Curry._1(setQuery, (function (query) {
                                        return Curry._2(Crdb$BsConsole.Query.setSort, sort, query);
                                      }));
                        };
                        var setSortingState = function (param) {
                          return BtTableAdapter$BsConsole.CrdbAggregate.onSortChange(handleSetSort, param);
                        };
                        Curry._1(setTableRemote, (function (param) {
                                return /* Success */Block.__(0, [/* AggregateTable */Block.__(0, [{
                                                rows: rows$1,
                                                columns: columns$1,
                                                metadata: metadata,
                                                loading: loading,
                                                sortingState: sortingState,
                                                setSortingState: setSortingState
                                              }])]);
                              }));
                        break;
                    case /* Triage */1 :
                        var match$3 = TriageGroup$BsConsole.fromCrdbResponseGroup([], columns, metadata.seq, rows);
                        var triageGroups = match$3[0];
                        var columns$2 = getTableColumns(match$3[1]);
                        var containerClassName = getTableContainerClassName(triageGroups);
                        var loading$1 = typeof next === "number" ? next !== 0 : false;
                        var sortingState$1 = Belt_Option.flatMap(Curry._1(Crdb$BsConsole.Query.getSort, query), (function (sort) {
                                return BtTableAdapter$BsConsole.CrdbAggregate.getTableSortingState(sort);
                              }));
                        var handleSetSort$1 = function (sort) {
                          return Curry._1(setQuery, (function (query) {
                                        return Curry._2(Crdb$BsConsole.Query.setSort, sort, query);
                                      }));
                        };
                        var setSortingState$1 = function (param) {
                          return BtTableAdapter$BsConsole.CrdbAggregate.onSortChange(handleSetSort$1, param);
                        };
                        Curry._1(setTableRemote, (function (param) {
                                return /* Success */Block.__(0, [/* TriageTable */Block.__(2, [{
                                                rows: triageGroups,
                                                columns: columns$2,
                                                metadata: metadata,
                                                loading: loading$1,
                                                sortingState: sortingState$1,
                                                setSortingState: setSortingState$1,
                                                containerClassName: containerClassName
                                              }])]);
                              }));
                        break;
                    case /* GroupedBinChart */2 :
                        var match$4 = Belt_List.getBy(queryOverrides, (function (param) {
                                return param[0] === "group";
                              }));
                        var overrideFactor = match$4 !== undefined ? Belt_Array.get(Json_decode.array(Json_decode.string, match$4[1]), 0) : undefined;
                        var binAttribute = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_List.getBy(columns, (function (col) {
                                        return Caml_obj.caml_equal(col.op, /* Bin */2);
                                      })), (function (col) {
                                    return col.name;
                                  })), "timestamp");
                        var timeStampBinAgg = Belt_Array.getBy(Curry._1(Crdb$BsConsole.Fold.toArray, Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getFold, query), Crdb$BsConsole.Fold.empty)), (function (agg) {
                                if (Curry._1(Crdb$BsConsole.Aggregation.getAttribute, agg) === binAttribute) {
                                  return Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg)) === "bin";
                                } else {
                                  return false;
                                }
                              }));
                        var range;
                        if (timeStampBinAgg !== undefined) {
                          var match$5 = Curry._1(Crdb$BsConsole.Aggregation.getOperation, Caml_option.valFromOption(timeStampBinAgg));
                          var exit$1 = 0;
                          if (typeof match$5 === "number" || match$5.tag) {
                            exit$1 = 2;
                          } else {
                            var match$6 = match$5[1];
                            if (match$6 !== undefined) {
                              var start$prime = Caml_int64.to_float(match$6[0]);
                              var end_$prime = Caml_int64.to_float(match$6[1]);
                              range = /* tuple */[
                                start$prime,
                                end_$prime
                              ];
                            } else {
                              exit$1 = 2;
                            }
                          }
                          if (exit$1 === 2) {
                            var range$1 = Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(Crdb$BsConsole.Query.getAperture, query)));
                            range = /* tuple */[
                              Math.floor(range$1[0].valueOf() / 1000),
                              Math.floor(range$1[1].valueOf() / 1000)
                            ];
                          }
                          
                        } else {
                          var range$2 = Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(Crdb$BsConsole.Query.getAperture, query)));
                          range = /* tuple */[
                            Math.floor(range$2[0].valueOf() / 1000),
                            Math.floor(range$2[1].valueOf() / 1000)
                          ];
                        }
                        var bins;
                        if (timeStampBinAgg !== undefined) {
                          var match$7 = Curry._1(Crdb$BsConsole.Aggregation.getOperation, Caml_option.valFromOption(timeStampBinAgg));
                          if (typeof match$7 === "number" || match$7.tag) {
                            bins = 32;
                          } else {
                            var bins$prime = match$7[0];
                            bins = bins$prime !== undefined ? bins$prime : 32;
                          }
                        } else {
                          bins = 32;
                        }
                        var data = Belt_List.toArray(Belt_List.keepMap(Belt_List.map(rows, (function (row) {
                                        var timestampBin = Belt_List.getBy(row.aggregations, (function (shape) {
                                                if (shape.tag === /* Bin */2) {
                                                  return shape[0] === binAttribute;
                                                } else {
                                                  return false;
                                                }
                                              }));
                                        return /* tuple */[
                                                row.factor.value,
                                                timestampBin
                                              ];
                                      })), (function (param) {
                                    var shape = param[1];
                                    var groupValue = param[0];
                                    if (shape === undefined) {
                                      return ;
                                    }
                                    if (shape.tag !== /* Bin */2) {
                                      return ;
                                    }
                                    var binData = shape[1];
                                    if (binData === undefined) {
                                      return ;
                                    }
                                    if (shape[0] !== binAttribute) {
                                      return ;
                                    }
                                    var __x = convertBinDataToFloats(binData);
                                    var data = Interpolate$BsConsole.Histogram.merge(Interpolate$BsConsole.Histogram.make(/* Float */0, bins, range[0], range[1]), __x);
                                    var match = getFactor(query, versionAttribute);
                                    if (groupValue === "*" && !(match !== undefined && !(factorToString(match) === "*" || overrideFactor === "*"))) {
                                      return /* tuple */[
                                              "Total",
                                              data
                                            ];
                                    } else {
                                      return /* tuple */[
                                              groupValue,
                                              data
                                            ];
                                    }
                                  })));
                        var loading$2 = typeof next === "number" ? next !== 0 : false;
                        var handleUpdateGroup = function (group) {
                          if (group === undefined) {
                            return Curry._1(setLocalOverrides, (function (param) {
                                          return /* [] */0;
                                        }));
                          }
                          var groupOverride_001 = [group];
                          var groupOverride = /* tuple */[
                            "group",
                            groupOverride_001
                          ];
                          return Curry._1(setLocalOverrides, (function (_overrides) {
                                        return /* :: */[
                                                groupOverride,
                                                /* [] */0
                                              ];
                                      }));
                        };
                        var match$8 = getFactor(query, versionAttribute);
                        var currentGroup = overrideFactor !== undefined ? overrideFactor : (
                            match$8 !== undefined ? factorToString(match$8) : "*"
                          );
                        Curry._1(setTableRemote, (function (param) {
                                return /* Success */Block.__(0, [/* GroupedBinChart */Block.__(1, [{
                                                data: data,
                                                loading: loading$2,
                                                metadata: metadata,
                                                bins: bins,
                                                range: range,
                                                handleUpdateGroup: handleUpdateGroup,
                                                currentGroup: currentGroup
                                              }])]);
                              }));
                        break;
                    case /* Summary */3 :
                        var totalInstances = Belt_Option.getWithDefault(metadata.instances, 0);
                        var groupedUniqueCounts = Belt_List.reduce(rows, [], (function (acc, row) {
                                var fingerprintUniqueAgg = Belt_List.getBy(row.aggregations, (function (shape) {
                                        if (shape.tag || shape[0] !== "fingerprint") {
                                          return false;
                                        } else {
                                          return true;
                                        }
                                      }));
                                var count;
                                if (fingerprintUniqueAgg !== undefined && !(fingerprintUniqueAgg.tag || fingerprintUniqueAgg[0] !== "fingerprint")) {
                                  var count$1 = fingerprintUniqueAgg[1];
                                  count = count$1 !== undefined ? count$1 | 0 : 0;
                                } else {
                                  count = 0;
                                }
                                return Belt_Array.concat(acc, [/* tuple */[
                                              row.factor.value,
                                              count
                                            ]]);
                              }));
                        var totalFingerprints = Belt_Array.reduce(groupedUniqueCounts, 0, (function (acc, param) {
                                return acc + param[1] | 0;
                              }));
                        var match$9 = Belt_Array.getBy(groupedUniqueCounts, (function (param) {
                                return param[0] === "open";
                              }));
                        var open_ = match$9 !== undefined ? match$9[1] : 0;
                        var match$10 = Belt_Array.getBy(groupedUniqueCounts, (function (param) {
                                return param[0] === "in-progress";
                              }));
                        var in_progress = match$10 !== undefined ? match$10[1] : 0;
                        Curry._1(setTableRemote, (function (param) {
                                return /* Success */Block.__(0, [/* SummaryRow */Block.__(3, [{
                                                totalInstances: totalInstances,
                                                totalFingerprints: totalFingerprints,
                                                open_: open_,
                                                in_progress: in_progress
                                              }])]);
                              }));
                        break;
                    case /* Distribution */5 :
                    case /* Distribution_Triage */6 :
                        exit = 1;
                        break;
                    case /* Summary_V2 */4 :
                    case /* Summed_Event_Point */7 :
                    case /* Unique_Event_Point */8 :
                    case /* Compare_Group_Table */9 :
                    case /* Summed_Event_Bin_Chart */10 :
                    case /* Grouped_Unique_Event_Usage_Bin_Chart */11 :
                    case /* Grouped_Summed_Event_Bin_Chart */12 :
                        break;
                    
                  }
                  if (exit === 1) {
                    var groups = Belt_List.toArray(Belt_List.map(rows, (function (group) {
                                return {
                                        group: group.factor.value,
                                        group_format_value: Parse$BsConsole.factorToFormat(group.factor),
                                        group_format_attribute: group.factor.attribute,
                                        count: group.count,
                                        unique_fingerprint: getUniqueFingerprint(columns, group),
                                        unique_guid: getUniqueGuid(columns, group)
                                      };
                              })));
                    var totalCountFromGroups = Belt_Array.reduce(groups, 0, (function (acc, param) {
                            return acc + param.count | 0;
                          }));
                    var match$11 = queryItem.variant;
                    if (match$11 !== 5) {
                      if (match$11 !== 6) {
                        
                      } else {
                        Curry._1(setTableRemote, (function (param) {
                                return /* Success */Block.__(0, [/* TriageDistribution */Block.__(6, [groups])]);
                              }));
                      }
                    } else {
                      var total = Belt_Option.getWithDefault(metadata.instances, totalCountFromGroups);
                      var rest = (total - totalCountFromGroups | 0) > 0 ? total - totalCountFromGroups | 0 : undefined;
                      var groups$1 = rest !== undefined ? Belt_Array.concat(groups, [{
                                group: "__rest",
                                group_format_value: undefined,
                                group_format_attribute: "",
                                count: rest,
                                unique_fingerprint: undefined,
                                unique_guid: undefined
                              }]) : groups;
                      Curry._1(setTableRemote, (function (param) {
                              return /* Success */Block.__(0, [/* Distribution */Block.__(5, [groups$1])]);
                            }));
                    }
                  }
                  break;
              case /* Summary */2 :
                  if (match === 4) {
                    var summary$1 = summary[0];
                    Curry._1(setTableRemote, (function (param) {
                            return /* Success */Block.__(0, [/* SummaryV2Row */Block.__(4, [summary$1])]);
                          }));
                  }
                  break;
              case /* PointQuery */3 :
                  var pointQuery = summary[0];
                  Curry._1(setTableRemote, (function (param) {
                          return /* Success */Block.__(0, [/* PointRatio */Block.__(7, [pointQuery])]);
                        }));
                  break;
              case /* CompareGroups */4 :
                  var groups$2 = summary[0];
                  Curry._1(setTableRemote, (function (param) {
                          return /* Success */Block.__(0, [/* CompareGroups */Block.__(8, [groups$2])]);
                        }));
                  break;
              case /* SummedEventsBin */5 :
                  var match$12 = summary[0];
                  var range$3 = match$12.range;
                  var end_ = range$3[1];
                  var start = range$3[0];
                  var bins$1 = match$12.bins;
                  var errorGroup = match$12.errorGroup;
                  var summedGroup = match$12.summedGroup;
                  var title = match$12.title;
                  var summedEvents = Interpolate$BsConsole.Histogram.merge(Interpolate$BsConsole.Histogram.make(/* Float */0, bins$1, start, end_), match$12.summedEvents);
                  var errorEvents = Interpolate$BsConsole.Histogram.merge(Interpolate$BsConsole.Histogram.make(/* Float */0, bins$1, start, end_), match$12.errorEvents);
                  var binData = Belt_Array.zipBy(summedEvents, errorEvents, (function (param, param$1) {
                          return {
                                  numerator: param$1[2],
                                  denominator: param[2],
                                  range: /* tuple */[
                                    param[0],
                                    param[1]
                                  ]
                                };
                        }));
                  var groupName = errorGroup === "count" ? "count / " + summedGroup : "unique(" + (errorGroup + (") / " + summedGroup));
                  Curry._1(setTableRemote, (function (param) {
                          return /* Success */Block.__(0, [/* GroupedRatioBins */Block.__(9, [{
                                          title: title,
                                          bins: bins$1,
                                          range: range$3,
                                          denominatorGroup: summedGroup,
                                          numeratorGroup: errorGroup,
                                          data: [/* tuple */[
                                              groupName,
                                              binData
                                            ]],
                                          inverse: true
                                        }])]);
                        }));
                  break;
              case /* GroupedUniqueEventUsageBins */6 :
                  var match$13 = summary[0];
                  var range$4 = match$13.range;
                  var end_$1 = range$4[1];
                  var start$1 = range$4[0];
                  var bins$2 = match$13.bins;
                  var uniqueEvent = match$13.uniqueEvent;
                  var title$1 = match$13.title;
                  var totalEvents = Interpolate$BsConsole.Histogram.merge(Interpolate$BsConsole.Histogram.make(/* Float */0, bins$2, start$1, end_$1), match$13.totalEventsBins);
                  var groupedBins = Belt_Array.map(match$13.groupedUniqueEvents, (function (param) {
                          var binData = Interpolate$BsConsole.Histogram.merge(Interpolate$BsConsole.Histogram.make(/* Float */0, bins$2, start$1, end_$1), param.data);
                          return /* tuple */[
                                  param.group,
                                  binData
                                ];
                        }));
                  var data$1 = Belt_Array.map(groupedBins, (function (param) {
                          var ratioBinData = Belt_Array.zipBy(totalEvents, param[1], (function (param, param$1) {
                                  return {
                                          numerator: param$1[2],
                                          denominator: param[2],
                                          range: /* tuple */[
                                            param[0],
                                            param[1]
                                          ]
                                        };
                                }));
                          return /* tuple */[
                                  param[0],
                                  ratioBinData
                                ];
                        }));
                  var numeratorGroup = uniqueEvent === "guid" ? "unique user events" : "unique " + (uniqueEvent + " events");
                  var denominatorGroup = uniqueEvent === "guid" ? "total user events" : "total " + (uniqueEvent + " events");
                  Curry._1(setTableRemote, (function (param) {
                          return /* Success */Block.__(0, [/* GroupedRatioBins */Block.__(9, [{
                                          title: title$1,
                                          bins: bins$2,
                                          range: range$4,
                                          denominatorGroup: denominatorGroup,
                                          numeratorGroup: numeratorGroup,
                                          data: data$1,
                                          inverse: false
                                        }])]);
                        }));
                  break;
              case /* GroupedSummedEventBins */7 :
                  var match$14 = summary[0];
                  var range$5 = match$14.range;
                  var end_$2 = range$5[1];
                  var start$2 = range$5[0];
                  var summedGroup$1 = match$14.summedGroup;
                  var errorGroup$1 = match$14.errorGroup;
                  var bins$3 = match$14.bins;
                  var title$2 = match$14.title;
                  var data$2 = Belt_Array.map(match$14.groupedEvents, (function (param) {
                          var summedEvents = Interpolate$BsConsole.Histogram.merge(Interpolate$BsConsole.Histogram.make(/* Float */0, bins$3, start$2, end_$2), param.summedEvents);
                          var errorEvents = Interpolate$BsConsole.Histogram.merge(Interpolate$BsConsole.Histogram.make(/* Float */0, bins$3, start$2, end_$2), param.errorEvents);
                          var ratioBins = Belt_Array.zipBy(summedEvents, errorEvents, (function (param, param$1) {
                                  return {
                                          numerator: param$1[2],
                                          denominator: param[2],
                                          range: /* tuple */[
                                            param[0],
                                            param[1]
                                          ]
                                        };
                                }));
                          return /* tuple */[
                                  param.group,
                                  ratioBins
                                ];
                        }));
                  Curry._1(setTableRemote, (function (param) {
                          return /* Success */Block.__(0, [/* GroupedRatioBins */Block.__(9, [{
                                          title: title$2,
                                          bins: bins$3,
                                          range: range$5,
                                          denominatorGroup: summedGroup$1,
                                          numeratorGroup: errorGroup$1,
                                          data: data$2,
                                          inverse: true
                                        }])]);
                        }));
                  break;
              
            }
          }
          
        }), [queryRequestRemote]);
  return match[0];
}

var _GROUP_ATTRIBUTE_TOKEN = "<%group%>";

exports._VERSION_ATTRIBUTE_TOKEN = _VERSION_ATTRIBUTE_TOKEN;
exports._GROUP_ATTRIBUTE_TOKEN = _GROUP_ATTRIBUTE_TOKEN;
exports.hasVersionAttribute = hasVersionAttribute;
exports.AggregateTable = AggregateTable;
exports.TriageTable = TriageTable;
exports.getFactor = getFactor;
exports.factorToString = factorToString;
exports.getDefaultGroup = getDefaultGroup;
exports.getCurrentGroup = getCurrentGroup;
exports.convertBinDataToFloats = convertBinDataToFloats;
exports.getUniqueFingerprint = getUniqueFingerprint;
exports.getUniqueGuid = getUniqueGuid;
exports.useTableData = useTableData;
/* statusTableColumn Not a pure module */
