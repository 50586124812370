// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BugTheme$BsConsole = require("./BugTheme.js");
var Tab = require("@material-ui/core/Tab");
var BugTabCount$BsConsole = require("./BugTabCount.js");
var Tabs = require("@material-ui/core/Tabs");
var Styles = require("@material-ui/core/styles");
var Popover = require("@material-ui/core/Popover");
var ButtonBase = require("@material-ui/core/ButtonBase");
var IconButton = require("@material-ui/core/IconButton");
var KeyboardArrowLeft = require("@material-ui/icons/KeyboardArrowLeft");
var KeyboardArrowRight = require("@material-ui/icons/KeyboardArrowRight");

function BugAnnotationsPopover$Card(Props) {
  var annotation = Props.annotation;
  var onSelectVariableId = Props.onSelectVariableId;
  var match = annotation.typ;
  var tmp;
  switch (match) {
    case /* Comment */0 :
        tmp = I18N$BsConsole.get(undefined, "COMMENT");
        break;
    case /* Warning */1 :
        tmp = I18N$BsConsole.get(undefined, "WARNING");
        break;
    case /* Critical */2 :
        tmp = I18N$BsConsole.get(undefined, "CRITICAL");
        break;
    case /* Json */3 :
        tmp = I18N$BsConsole.get(undefined, "JSON");
        break;
    case /* JSONPP */4 :
        tmp = I18N$BsConsole.get(undefined, "JSONPP");
        break;
    
  }
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.padding2(Css.px(2), Css.px(6)),
                    /* [] */0
                  ])
            }, React.createElement(ButtonBase.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(onSelectVariableId, annotation.variableId);
                    }),
                  children: React.createElement(Col2$BsConsole.make, {
                        className: Css.style(/* :: */[
                              Css.padding(Css.px(8)),
                              /* :: */[
                                Css.background(Css.hex(Colors$BsConsole.blueBackground)),
                                /* :: */[
                                  Css.textAlign(/* left */-944764921),
                                  /* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]),
                        children: null
                      }, React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.fontSize(Css.px(12)),
                                  /* :: */[
                                    Css.fontWeight(Css.medium),
                                    /* [] */0
                                  ]
                                ])
                          }, I18N$BsConsole.showSkip(tmp)), React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.fontSize(Css.px(12)),
                                  /* :: */[
                                    Css.paddingTop(Css.px(4)),
                                    /* :: */[
                                      Css.unsafe("wordBreak", "break-word"),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          }, I18N$BsConsole.dynamic(annotation.text)))
                }));
}

var Card = {
  make: BugAnnotationsPopover$Card
};

function visibleAnnotationsReducer(state, action) {
  if (typeof action === "number") {
    if (action !== 0) {
      return {
              page: state.page - 1 | 0,
              activeTab: state.activeTab
            };
    } else {
      return {
              page: state.page + 1 | 0,
              activeTab: state.activeTab
            };
    }
  } else {
    return {
            page: 0,
            activeTab: action[0]
          };
  }
}

function BugAnnotationsPopover(Props) {
  var annotations = Props.annotations;
  var anchorEl = Props.anchorEl;
  var onClose = Props.onClose;
  var onSelectVariableId = Props.onSelectVariableId;
  var match = React.useMemo((function () {
          return /* tuple */[
                  Belt_Array.keep(annotations, (function (annotation) {
                          return annotation.typ === /* Critical */2;
                        })),
                  Belt_Array.keep(annotations, (function (annotation) {
                          return annotation.typ === /* Warning */1;
                        })),
                  Belt_Array.keep(annotations, (function (annotation) {
                          return annotation.typ === /* Comment */0;
                        }))
                ];
        }), [annotations]);
  var commentAnnotations = match[2];
  var warningAnnotations = match[1];
  var criticalAnnotations = match[0];
  var match$1 = React.useReducer(visibleAnnotationsReducer, {
        page: 0,
        activeTab: criticalAnnotations.length !== 0 ? /* Critical */0 : (
            warningAnnotations.length !== 0 ? /* Warning */1 : (
                commentAnnotations.length !== 0 ? /* Comment */2 : /* Critical */0
              )
          )
      });
  var dispatch = match$1[1];
  var match$2 = match$1[0];
  var activeTab = match$2.activeTab;
  var page = match$2.page;
  var visibleAnnotations;
  switch (activeTab) {
    case /* Critical */0 :
        visibleAnnotations = criticalAnnotations;
        break;
    case /* Warning */1 :
        visibleAnnotations = warningAnnotations;
        break;
    case /* Comment */2 :
        visibleAnnotations = commentAnnotations;
        break;
    
  }
  var tmp;
  if (visibleAnnotations.length !== 0) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.overflow(Css.hidden),
                    /* [] */0
                  ])
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.padding2(Css.px(6), Css.zero),
                        /* [] */0
                      ])
                }, Belt_Array.mapWithIndex(Belt_Array.slice(visibleAnnotations, (page << 3), 8), (function (i, annotation) {
                        return React.createElement(BugAnnotationsPopover$Card, {
                                    annotation: annotation,
                                    onSelectVariableId: onSelectVariableId,
                                    key: String(i)
                                  });
                      })))), React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              justifyContent: /* flexEnd */924268066,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.paddingBottom(Css.px(8)),
                      /* :: */[
                        Css.paddingRight(Css.px(6)),
                        /* :: */[
                          Css.background(Css.hex(Colors$BsConsole.white)),
                          /* :: */[
                            Css.borderRadius(Css.px(4)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]),
              children: null
            }, React.createElement(IconButton.default, {
                  disabled: page === 0,
                  classes: {
                    root: Css.style(/* :: */[
                          Css.fontSize(Css.px(20)),
                          /* :: */[
                            Css.padding(Css.px(4)),
                            /* [] */0
                          ]
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* PrevPage */1);
                    }),
                  children: React.createElement(KeyboardArrowLeft.default, {
                        fontSize: "inherit"
                      })
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.fontSize(Css.px(14)),
                        /* :: */[
                          Css.lineHeight(Css.px(14)),
                          /* [] */0
                        ]
                      ])
                }, Curry._3(I18N$BsConsole.showf(undefined, /* Format */[
                          /* Int */Block.__(4, [
                              /* Int_d */0,
                              /* No_padding */0,
                              /* No_precision */0,
                              /* String_literal */Block.__(11, [
                                  " - ",
                                  /* Int */Block.__(4, [
                                      /* Int_d */0,
                                      /* No_padding */0,
                                      /* No_precision */0,
                                      /* String_literal */Block.__(11, [
                                          " of ",
                                          /* Int */Block.__(4, [
                                              /* Int_d */0,
                                              /* No_padding */0,
                                              /* No_precision */0,
                                              /* End_of_format */0
                                            ])
                                        ])
                                    ])
                                ])
                            ]),
                          "%d - %d of %d"
                        ]), (page << 3) + 1 | 0, Caml_primitive.caml_int_min(visibleAnnotations.length, ((page + 1 | 0) << 3)), visibleAnnotations.length)), React.createElement(IconButton.default, {
                  disabled: ((page + 1 | 0) << 3) >= visibleAnnotations.length,
                  classes: {
                    root: Css.style(/* :: */[
                          Css.fontSize(Css.px(20)),
                          /* :: */[
                            Css.padding(Css.px(4)),
                            /* [] */0
                          ]
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* NextPage */0);
                    }),
                  children: React.createElement(KeyboardArrowRight.default, {
                        fontSize: "inherit"
                      })
                })));
  } else {
    var tmp$1;
    switch (activeTab) {
      case /* Critical */0 :
          tmp$1 = I18N$BsConsole.get(undefined, "critical");
          break;
      case /* Warning */1 :
          tmp$1 = I18N$BsConsole.get(undefined, "warning");
          break;
      case /* Comment */2 :
          tmp$1 = I18N$BsConsole.get(undefined, "comment");
          break;
      
    }
    tmp = React.createElement(Row2$BsConsole.make, {
          justifyContent: /* center */98248149,
          className: Css.style(/* :: */[
                Css.padding(Css.px(16)),
                /* [] */0
              ]),
          children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                    /* String_literal */Block.__(11, [
                        "No ",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* String_literal */Block.__(11, [
                                " annotations",
                                /* End_of_format */0
                              ])
                          ])
                      ]),
                    "No %s annotations"
                  ]), tmp$1)
        });
  }
  return React.createElement(Styles.MuiThemeProvider, {
              theme: BugTheme$BsConsole.blue,
              children: React.createElement(Popover.default, {
                    anchorOrigin: {
                      horizontal: "left",
                      vertical: "bottom"
                    },
                    transformOrigin: {
                      horizontal: "left",
                      vertical: "top"
                    },
                    anchorEl: anchorEl,
                    open: Belt_Option.isSome(anchorEl),
                    classes: {
                      paper: Css.style(/* :: */[
                            Css.marginTop(Css.px(4)),
                            /* :: */[
                              Css.overflow(Css.visible),
                              /* :: */[
                                Css.width(Css.px(344)),
                                /* :: */[
                                  Css.border(Css.px(1), Css.solid, Css.hex(Colors$BsConsole.blue)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ])
                    },
                    onClose: onClose,
                    children: React.createElement(Col2$BsConsole.make, {
                          alignItems: /* stretch */-162316795,
                          className: Css.style(/* :: */[
                                Css.color(Css.hex(Colors$BsConsole.grey0)),
                                /* :: */[
                                  Css.overflow(Css.visible),
                                  /* [] */0
                                ]
                              ]),
                          children: null
                        }, React.createElement(Row2$BsConsole.make, {
                              alignItems: /* center */98248149,
                              justifyContent: /* spaceBetween */516682146,
                              className: Css.style(/* :: */[
                                    Css.padding(Css.px(12)),
                                    /* [] */0
                                  ]),
                              children: React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Annotations"))
                            }), React.createElement(Row2$BsConsole.make, {
                              className: Css.style(/* :: */[
                                    Css.padding2(Css.zero, Css.px(6)),
                                    /* [] */0
                                  ]),
                              children: React.createElement(Tabs.default, {
                                    value: activeTab,
                                    indicatorColor: "primary",
                                    textColor: "primary",
                                    onChange: (function (param, tab) {
                                        return Curry._1(dispatch, /* SetActiveTab */[tab]);
                                      }),
                                    children: null
                                  }, React.createElement(Tab.default, {
                                        label: React.createElement(Row2$BsConsole.make, {
                                              children: null
                                            }, I18N$BsConsole.show(undefined, "Critical"), React.createElement(BugTabCount$BsConsole.make, {
                                                  label: String(criticalAnnotations.length)
                                                })),
                                        disabled: criticalAnnotations.length === 0
                                      }), React.createElement(Tab.default, {
                                        label: React.createElement(Row2$BsConsole.make, {
                                              children: null
                                            }, I18N$BsConsole.show(undefined, "Warning"), React.createElement(BugTabCount$BsConsole.make, {
                                                  label: String(warningAnnotations.length)
                                                })),
                                        disabled: warningAnnotations.length === 0
                                      }), React.createElement(Tab.default, {
                                        label: React.createElement(Row2$BsConsole.make, {
                                              children: null
                                            }, I18N$BsConsole.show(undefined, "Comment"), React.createElement(BugTabCount$BsConsole.make, {
                                                  label: String(commentAnnotations.length)
                                                })),
                                        disabled: commentAnnotations.length === 0
                                      }))
                            }), tmp)
                  })
            });
}

var make = BugAnnotationsPopover;

exports.Card = Card;
exports.visibleAnnotationsReducer = visibleAnnotationsReducer;
exports.make = make;
/* Css Not a pure module */
