// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Href$BsConsole = require("./Href.js");
var I18N$BsConsole = require("./I18N.js");
var Formality__Form = require("re-formality/lib/js/src/Formality__Form.bs.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var Column$BsConsole = require("./utilities/Column.js");
var Footer$BsConsole = require("./Footer.js");
var SignUp$BsConsole = require("./SignUp.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var AuthForms$BsConsole = require("./auth/AuthForms.js");
var AuthTasks$BsConsole = require("./auth/AuthTasks.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var ViewContainer$BsConsole = require("./ViewContainer.js");
var Formality__PublicHelpers = require("re-formality/lib/js/src/Formality__PublicHelpers.bs.js");

var SignupFormContainer = Formality__Form.Make({
      validators: AuthForms$BsConsole.SignUp.validators
    });

var grid = Css.style(/* :: */[
      Css.alignItems(/* center */98248149),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* [] */0
      ]
    ]);

var button = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var gridPadding = Css.style(/* :: */[
      Css.padding(Css.px(12)),
      /* [] */0
    ]);

var span = Css.style(/* :: */[
      Css.margin2(/* zero */-789508312, Css.px(5)),
      /* [] */0
    ]);

var loadingContainer = Css.style(/* :: */[
      Css.height(Css.px(320)),
      /* [] */0
    ]);

var link = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* [] */0
    ]);

var errorText = Css.style(/* :: */[
      Css.fontSize(Css.px(16)),
      /* [] */0
    ]);

var paper = Css.style(/* :: */[
      Css.padding(Css.px(16)),
      /* [] */0
    ]);

var card = Css.style(/* :: */[
      Css.width(Css.px(450)),
      /* [] */0
    ]);

var cardContent = Css.style(/* :: */[
      Css.important(Css.padding(Css.px(32))),
      /* [] */0
    ]);

var logoContainer = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.px(16)),
      /* :: */[
        Css.marginBottom(Css.px(32)),
        /* [] */0
      ]
    ]);

var logo = Css.style(/* :: */[
      Css.height(Css.px(32)),
      /* [] */0
    ]);

var statusContainer = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* [] */0
    ]);

var statusHeader = Css.style(/* :: */[
      Css.marginBottom(Css.px(16)),
      /* :: */[
        Css.alignSelf(/* center */98248149),
        /* [] */0
      ]
    ]);

var subtitle = Css.style(/* :: */[
      Css.alignSelf(/* center */98248149),
      /* [] */0
    ]);

var heading = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* :: */[
        Css.alignSelf(/* center */98248149),
        /* [] */0
      ]
    ]);

var emailRow = Css.style(/* :: */[
      Css.marginBottom(Css.px(16)),
      /* [] */0
    ]);

var inputsContainer = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* [] */0
    ]);

var submitButton = Css.merge(/* :: */[
      button,
      /* :: */[
        Css.style(/* :: */[
              Css.marginBottom(Css.px(16)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var Styles = {
  grid: grid,
  button: button,
  gridPadding: gridPadding,
  span: span,
  loadingContainer: loadingContainer,
  link: link,
  errorText: errorText,
  paper: paper,
  card: card,
  cardContent: cardContent,
  logoContainer: logoContainer,
  logo: logo,
  statusContainer: statusContainer,
  statusHeader: statusHeader,
  subtitle: subtitle,
  heading: heading,
  emailRow: emailRow,
  inputsContainer: inputsContainer,
  submitButton: submitButton
};

var component = ReasonReact.statelessComponent("SignupPage-BsConsole__CreateNewUser");

function make(domains, handleChangeUrl, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              if (List.length(domains) > 0) {
                return ReasonReact.element(undefined, undefined, Curry._3(SignupFormContainer.make, {
                                emailStart: "",
                                emailDomain: "",
                                username: ""
                              }, (function (state, param) {
                                  var notifyOnFailure = param.notifyOnFailure;
                                  var notifyOnSuccess = param.notifyOnSuccess;
                                  return Task2$BsConsole.handleUnauthorized(AuthTasks$BsConsole.signUp(state.emailStart + ("@" + state.emailDomain), state.username, undefined), undefined, (function (resp) {
                                                if (!resp.tag) {
                                                  return Curry._1(notifyOnSuccess, undefined);
                                                }
                                                var match = resp[0];
                                                if (match[0] === Task2$BsConsole.CoronerError) {
                                                  return Curry._2(notifyOnFailure, /* [] */0, match[1].message);
                                                } else {
                                                  return Curry._2(notifyOnFailure, /* [] */0, "UnknownError");
                                                }
                                              }));
                                }), (function (form) {
                                  var match = form.status;
                                  if (typeof match === "number") {
                                    switch (match) {
                                      case /* Editing */0 :
                                          break;
                                      case /* Submitting */1 :
                                          return ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, loadingContainer, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, "primary", undefined, undefined, []))]));
                                      case /* Submitted */2 :
                                          return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                          ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, statusContainer, [
                                                                    ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading2 */1, undefined, statusHeader, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Success!")])),
                                                                    ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Subtitle1 */6, undefined, subtitle, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Please check your email for an account activation link")]))
                                                                  ])),
                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                    /* Medium */861718677,
                                                                    button,
                                                                    (function (_event) {
                                                                        return Curry._1(handleChangeUrl, /* Login */Block.__(3, [{
                                                                                        referrer: undefined,
                                                                                        token: undefined,
                                                                                        redirect: undefined,
                                                                                        method: undefined
                                                                                      }]));
                                                                      }),
                                                                    undefined,
                                                                    undefined,
                                                                    /* Raised */-387916264,
                                                                    /* Primary */-791844958,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    [I18N$BsConsole.show(undefined, "Back to log in")]
                                                                  ]))
                                                        ]));
                                      
                                    }
                                  }
                                  var partial_arg = form.submit;
                                  var match$1 = form.status;
                                  var tmp;
                                  if (typeof match$1 === "number") {
                                    tmp = null;
                                  } else {
                                    var msg = match$1[1];
                                    tmp = msg !== undefined ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(gridPadding, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V12 */11, /* Nowrap */-397426005, undefined, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, errorText, undefined, undefined, [I18N$BsConsole.showSkip(msg)]))]))])) : null;
                                  }
                                  var match$2 = Curry._1(form.result, /* EmailStart */0);
                                  var tmp$1;
                                  tmp$1 = match$2 !== undefined && match$2.tag ? true : false;
                                  var match$3 = Curry._1(form.result, /* EmailStart */0);
                                  var tmp$2;
                                  tmp$2 = match$3 !== undefined && match$3.tag ? match$3[0] : "";
                                  var match$4 = Curry._1(form.result, /* Username */2);
                                  var tmp$3;
                                  tmp$3 = match$4 !== undefined && match$4.tag ? true : false;
                                  var match$5 = Curry._1(form.result, /* Username */2);
                                  var tmp$4;
                                  tmp$4 = match$5 !== undefined && match$5.tag ? match$5[0] : "";
                                  return React.createElement("form", {
                                              onSubmit: (function (param) {
                                                  return Formality__PublicHelpers.Dom.preventDefault(partial_arg, param);
                                                })
                                            }, ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                      ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, /* center */98248149, undefined, undefined, statusContainer, [
                                                                ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading2 */1, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Sign up")])),
                                                                ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Subtitle1 */6, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Provide an email and username to begin.")]))
                                                              ])),
                                                      tmp,
                                                      ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, inputsContainer, [
                                                                ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, /* nowrap */867913355, emailRow, [
                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                                    undefined,
                                                                                    undefined,
                                                                                    I18N$BsConsole.get(undefined, "Email"),
                                                                                    true,
                                                                                    (function ($$event) {
                                                                                        return Curry._2(form.change, /* EmailStart */0, Curry._2(AuthForms$BsConsole.SignUp.EmailStart.update, form.state, $$event.target.value));
                                                                                      }),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    tmp$1,
                                                                                    Caml_option.some(I18N$BsConsole.show(undefined, tmp$2)),
                                                                                    "text",
                                                                                    /* `String */[
                                                                                      -976970511,
                                                                                      form.state.emailStart
                                                                                    ],
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    []
                                                                                  ])),
                                                                          React.createElement("span", {
                                                                                className: span
                                                                              }, I18N$BsConsole.showSkip("@")),
                                                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Select.Jsx2.make, [
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    /* `String */[
                                                                                      -976970511,
                                                                                      form.state.emailDomain
                                                                                    ],
                                                                                    (function ($$event, param) {
                                                                                        return Curry._2(form.change, /* EmailDomain */1, Curry._2(AuthForms$BsConsole.SignUp.EmailDomain.update, form.state, $$event.target.value));
                                                                                      }),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    Css.style(/* :: */[
                                                                                          Css.minWidth(Css.px(150)),
                                                                                          /* [] */0
                                                                                        ]),
                                                                                    undefined,
                                                                                    I18N$BsConsole.get(undefined, "Select a domain"),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    [List.map((function (domain) {
                                                                                              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                                                                              -976970511,
                                                                                                              domain
                                                                                                            ], undefined, undefined, [I18N$BsConsole.showSkip(domain)]));
                                                                                            }), domains)]
                                                                                  ]))
                                                                        ])),
                                                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                          undefined,
                                                                          undefined,
                                                                          I18N$BsConsole.get(undefined, "Username"),
                                                                          true,
                                                                          (function ($$event) {
                                                                              return Curry._2(form.change, /* Username */2, Curry._2(AuthForms$BsConsole.SignUp.Username.update, form.state, $$event.target.value));
                                                                            }),
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          tmp$3,
                                                                          Caml_option.some(I18N$BsConsole.showSkip(tmp$4)),
                                                                          "text",
                                                                          /* `String */[
                                                                            -976970511,
                                                                            form.state.username
                                                                          ],
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          undefined,
                                                                          []
                                                                        ]))
                                                              ])),
                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                /* Medium */861718677,
                                                                submitButton,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                /* Raised */-387916264,
                                                                /* Primary */-791844958,
                                                                "submit",
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                [I18N$BsConsole.show(undefined, "Send verification")]
                                                              ])),
                                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                /* Medium */861718677,
                                                                button,
                                                                (function (_event) {
                                                                    return Curry._1(handleChangeUrl, /* Login */Block.__(3, [{
                                                                                    referrer: undefined,
                                                                                    token: undefined,
                                                                                    redirect: undefined,
                                                                                    method: undefined
                                                                                  }]));
                                                                  }),
                                                                undefined,
                                                                undefined,
                                                                /* Flat */781662169,
                                                                /* Primary */-791844958,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                [I18N$BsConsole.show(undefined, "Back to log in")]
                                                              ]))
                                                    ])));
                                })));
              }
              var supportLink = Href$BsConsole.Support.make(undefined, /* GuideAccountManagement */8);
              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                              supportLink !== undefined ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(gridPadding, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Nowrap */-397426005, undefined, undefined, undefined, /* Center */980392437, [
                                          ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Warning.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                          React.createElement("div", {
                                                style: {
                                                  marginLeft: "1.5em"
                                                }
                                              }, I18N$BsConsole.show(undefined, "An administrator will need to allowlist some domains before you can create your own account. Learn more "), React.createElement("a", {
                                                    href: supportLink,
                                                    target: "_blank"
                                                  }, I18N$BsConsole.show(undefined, "here")), I18N$BsConsole.showSkip("."))
                                        ])) : ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(gridPadding, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Nowrap */-397426005, undefined, undefined, undefined, /* Center */980392437, [
                                          ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Warning.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                          React.createElement("div", {
                                                style: {
                                                  marginLeft: "1.5em"
                                                }
                                              }, I18N$BsConsole.show(undefined, "An administrator will need to allowlist some domains before you can create your own account."))
                                        ])),
                              ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(gridPadding, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                  /* Medium */861718677,
                                                  button,
                                                  (function (_event) {
                                                      return Curry._1(handleChangeUrl, /* Login */Block.__(3, [{
                                                                      referrer: undefined,
                                                                      token: undefined,
                                                                      redirect: undefined,
                                                                      method: undefined
                                                                    }]));
                                                    }),
                                                  undefined,
                                                  undefined,
                                                  /* Flat */781662169,
                                                  /* Primary */-791844958,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  [I18N$BsConsole.show(undefined, "Back to log in")]
                                                ]))]))
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var CreateNewUser = {
  component: component,
  make: make
};

var component$1 = ReasonReact.statelessComponent("SignupPage-BsConsole");

function make$1(handleTask, handleChangeUrl, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(Css.merge(/* :: */[
                                  grid,
                                  /* :: */[
                                    "login-container",
                                    /* [] */0
                                  ]
                                ]), [
                              ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, card, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, cardContent, [
                                                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, logoContainer, [React.createElement("img", {
                                                                  className: logo,
                                                                  src: "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg"
                                                                })])),
                                                  ReasonReact.element(undefined, undefined, SignUp$BsConsole.FetchWhitelist.make(handleTask, (function (remote) {
                                                              if (typeof remote !== "number") {
                                                                if (remote.tag) {
                                                                  return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                                                  ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, statusContainer, [
                                                                                            ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading2 */1, undefined, statusHeader, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Error!")])),
                                                                                            ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Subtitle1 */6, undefined, subtitle, undefined, undefined, undefined, [I18N$BsConsole.showSkip(remote[0])]))
                                                                                          ])),
                                                                                  ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                            /* Medium */861718677,
                                                                                            button,
                                                                                            (function (_event) {
                                                                                                return Curry._1(handleChangeUrl, /* Login */Block.__(3, [{
                                                                                                                referrer: undefined,
                                                                                                                token: undefined,
                                                                                                                redirect: undefined,
                                                                                                                method: undefined
                                                                                                              }]));
                                                                                              }),
                                                                                            undefined,
                                                                                            undefined,
                                                                                            /* Flat */781662169,
                                                                                            /* Primary */-791844958,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            undefined,
                                                                                            [I18N$BsConsole.show(undefined, "Back to log in")]
                                                                                          ]))
                                                                                ]));
                                                                } else {
                                                                  return ReasonReact.element(undefined, undefined, make(remote[0], handleChangeUrl, []));
                                                                }
                                                              }
                                                              remote === /* NotAsked */0;
                                                              return ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, loadingContainer, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, "primary", undefined, undefined, []))]));
                                                            })))
                                                ]))])),
                              ReasonReact.element(undefined, undefined, Footer$BsConsole.make([]))
                            ]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.SignupFormContainer = SignupFormContainer;
exports.Styles = Styles;
exports.CreateNewUser = CreateNewUser;
exports.component = component$1;
exports.make = make$1;
/* SignupFormContainer Not a pure module */
