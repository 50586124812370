// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Add = require("@material-ui/icons/Add");
var Edit = require("@material-ui/icons/Edit");
var FormatPopover$BsConsole = require("./format-renderers/FormatPopover.js");
var FormatRenderer$BsConsole = require("./format-renderers/FormatRenderer.js");
var Tooltip = require("@material-ui/core/Tooltip");
var IconButton = require("@material-ui/core/IconButton");
var TriageActionPopover$BsConsole = require("./TriageActionPopover.js");

var iconButton = {
  root: Css.style(/* :: */[
        Css.fontSize(Css.rem(1)),
        /* :: */[
          Css.important(Css.padding(Css.px(4))),
          /* [] */0
        ]
      ]),
  label: Css.merge(/* :: */[
        Css.style(/* :: */[
              Css.unsafe("fontSize", "inherit"),
              /* [] */0
            ]),
        /* :: */[
          "triage-action",
          /* [] */0
        ]
      ])
};

var ticketsContainer = Css.style(/* :: */[
      Css.unsafe("maxWidth", "calc(100% - 28px)"),
      /* [] */0
    ]);

var ticketsRow = Css.style(/* :: */[
      Css.selector(":last-child", /* :: */[
            Css.marginLeft(Css.px(4)),
            /* [] */0
          ]),
      /* :: */[
        Css.height(Css.px(40)),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  iconButton: iconButton,
  ticketsContainer: ticketsContainer,
  ticketsRow: ticketsRow
};

function TriageTickets$Default(Props) {
  var tickets = Props.tickets;
  var handleSetIssue = Props.handleSetIssue;
  var projectName = Props.projectName;
  var workflows = Props.workflows;
  var handleChangeUrl = Props.handleChangeUrl;
  var config = Props.config;
  var fingerprint = Props.fingerprint;
  var fetchIssues = Props.fetchIssues;
  var token = Props.token;
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  return React.createElement(React.Fragment, undefined, tickets !== undefined ? React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    justifyContent: /* spaceBetween */516682146,
                    className: ticketsRow,
                    children: null
                  }, React.createElement(FormatPopover$BsConsole.make, {
                        content: React.createElement(FormatRenderer$BsConsole.make, {
                              value: /* IssueTickets */Block.__(13, [tickets]),
                              attribute: "fingerprint;issues;tickets"
                            }),
                        className: ticketsContainer,
                        children: React.createElement(FormatRenderer$BsConsole.make, {
                              value: /* IssueTickets */Block.__(13, [tickets]),
                              attribute: "fingerprint;issues;tickets"
                            })
                      }), React.createElement(Tooltip.default, {
                        placement: "top",
                        children: React.createElement(IconButton.default, {
                              classes: iconButton,
                              onClick: (function ($$event) {
                                  var el = $$event.currentTarget;
                                  return Curry._1(setAnchorEl, (function (param) {
                                                return Caml_option.some(el);
                                              }));
                                }),
                              children: React.createElement(Edit.default, {
                                    color: "inherit",
                                    fontSize: "inherit"
                                  })
                            }),
                        title: I18N$BsConsole.get(undefined, "Edit tickets")
                      })) : React.createElement(Tooltip.default, {
                    placement: "top",
                    children: React.createElement(IconButton.default, {
                          classes: iconButton,
                          onClick: (function ($$event) {
                              var el = $$event.currentTarget;
                              return Curry._1(setAnchorEl, (function (param) {
                                            return Caml_option.some(el);
                                          }));
                            }),
                          children: React.createElement(Add.default, {
                                color: "inherit",
                                fontSize: "inherit"
                              })
                        }),
                    title: I18N$BsConsole.get(undefined, "Add ticket")
                  }), anchorEl !== undefined ? React.createElement(TriageActionPopover$BsConsole.Tickets.make, {
                    anchorEl: Caml_option.valFromOption(anchorEl),
                    onClose: (function (param) {
                        return Curry._1(setAnchorEl, (function (param) {
                                      
                                    }));
                      }),
                    tickets: tickets,
                    handleSetIssue: handleSetIssue,
                    projectName: projectName,
                    workflows: workflows,
                    handleChangeUrl: handleChangeUrl,
                    config: config,
                    fingerprint: fingerprint,
                    fetchIssues: fetchIssues,
                    token: token,
                    handleRemoveTickets: (function (tickets) {
                        Curry._2(handleSetIssue, /* :: */[
                              /* RemoveTickets */Block.__(6, [tickets]),
                              /* [] */0
                            ], undefined);
                        
                      })
                  }) : null);
}

var Default = {
  make: TriageTickets$Default
};

var make = TriageTickets$Default;

exports.Styles = Styles;
exports.Default = Default;
exports.make = make;
/* iconButton Not a pure module */
