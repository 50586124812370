// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Crdb$BsConsole = require("./crdb.js");
var AppBar$BsConsole = require("./appBar.js");
var SettingsBar$BsConsole = require("./settingsBar.js");
var TriageUtils$BsConsole = require("./TriageUtils.js");
var InboxWorkflow$BsConsole = require("./inbox/InboxWorkflow.js");
var SavedQueriesModel$BsConsole = require("./saved-queries/SavedQueriesModel.js");
var WildcardTimestampBin$BsConsole = require("./WildcardTimestampBin.js");

var component = RR$BsConsole.statelessComponent("AppHeader-BsConsole");

function make(route, shell, handleBack, handleChangeUrl, handleLogout, token, handleTask, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              var exit = 0;
              if (typeof route === "number") {
                switch (route) {
                  case /* Settings */4 :
                  case /* SettingsMe */5 :
                  case /* SettingsUsers */6 :
                  case /* SettingsTeams */7 :
                  case /* SettingsProjects */8 :
                  case /* SettingsProjectsNew */9 :
                  case /* SettingsProjectsFork */10 :
                  case /* SettingsInvites */11 :
                  case /* SettingsInvitesNew */12 :
                  case /* SettingsNetworking */13 :
                  case /* SettingsRetentionPolicy */14 :
                  case /* SettingsNetworkingSSL */15 :
                  case /* SettingsNetworkingListeners */16 :
                  case /* SettingsWhitelist */17 :
                  case /* SettingsBilling */18 :
                  case /* SettingsBillingPlans */19 :
                  case /* SettingsSubscriptions */20 :
                  case /* SettingsSamlConfig */21 :
                      exit = 2;
                      break;
                  case /* Signup */0 :
                  case /* Recover */1 :
                  case /* CreateFirstProject */2 :
                  case /* Shell */3 :
                  case /* WfPlayground */22 :
                  case /* UiPlayground */23 :
                      return null;
                  
                }
              } else {
                switch (route.tag | 0) {
                  case /* SettingsWfConnectionManagement */6 :
                      exit = 2;
                      break;
                  case /* ProjectTriage */11 :
                      var qs = route[1];
                      var aperture = qs.aperture;
                      var name = route[0];
                      var isDetailView = InboxWorkflow$BsConsole.Folders.isDetailView(aperture);
                      var view = isDetailView ? /* Details */1 : /* List */0;
                      var query = TriageUtils$BsConsole.makeQuery(qs.stats, aperture, qs.havings, view, qs.sort, /* [] */0, qs.fold);
                      var mode = SavedQueriesModel$BsConsole.modeOfStringExn("inbox");
                      var tmp;
                      tmp = typeof shell === "number" || shell.tag ? null : ReasonReact.element("__Wildcard" + name, undefined, WildcardTimestampBin$BsConsole.Re.make(shell[0], (function (range) {
                                    return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                                                  name,
                                                  {
                                                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Custom */Block.__(1, [range]), aperture),
                                                    sort: qs.sort,
                                                    stats: qs.stats,
                                                    havings: qs.havings,
                                                    fold: qs.fold,
                                                    normBy: qs.normBy,
                                                    similarity: qs.similarity
                                                  }
                                                ]));
                                  }), aperture, name, token, route, handleChangeUrl, undefined, undefined, []));
                      return React.createElement(React.Fragment, undefined, ReasonReact.element("AppBar__" + name, undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(aperture), undefined, query, mode, route, handleChangeUrl, handleLogout, name, shell, [])), tmp);
                  case /* ProjectInstances */15 :
                      var qs$1 = route[1];
                      var name$1 = route[0];
                      var query_000 = qs$1.aperture;
                      var query_001 = qs$1.select;
                      var query_002 = qs$1.sort;
                      var query$1 = /* Select */Block.__(1, [
                          query_000,
                          query_001,
                          query_002,
                          undefined
                        ]);
                      var aperture$1 = qs$1.aperture;
                      var mode$1 = SavedQueriesModel$BsConsole.modeOfStringExn("instance");
                      var tmp$1;
                      tmp$1 = typeof shell === "number" || shell.tag ? null : ReasonReact.element("__Wildcard" + name$1, undefined, WildcardTimestampBin$BsConsole.Re.make(shell[0], (function (range) {
                                    return Curry._1(handleChangeUrl, /* ProjectInstances */Block.__(15, [
                                                  name$1,
                                                  {
                                                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Custom */Block.__(1, [range]), aperture$1),
                                                    select: qs$1.select,
                                                    sort: qs$1.sort
                                                  }
                                                ]));
                                  }), aperture$1, name$1, token, route, handleChangeUrl, undefined, undefined, []));
                      return React.createElement(React.Fragment, undefined, ReasonReact.element("AppBar__" + name$1, undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(aperture$1), undefined, query$1, mode$1, route, handleChangeUrl, handleLogout, name$1, shell, [])), tmp$1);
                  case /* ProjectDebugger */17 :
                      var qs$2 = route[1];
                      var name$2 = route[0];
                      var aperture$2 = qs$2.aperture;
                      var tmp$2;
                      tmp$2 = typeof shell === "number" || shell.tag ? null : ReasonReact.element("__Wildcard" + name$2, undefined, WildcardTimestampBin$BsConsole.Re.make(shell[0], (function (range) {
                                    return Curry._1(handleChangeUrl, /* ProjectDebugger */Block.__(17, [
                                                  name$2,
                                                  {
                                                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Custom */Block.__(1, [range]), aperture$2),
                                                    sort: qs$2.sort,
                                                    s: qs$2.s
                                                  }
                                                ]));
                                  }), aperture$2, name$2, token, route, handleChangeUrl, undefined, undefined, []));
                      return React.createElement(React.Fragment, undefined, ReasonReact.element("AppBar__" + name$2, undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(aperture$2), undefined, undefined, undefined, route, handleChangeUrl, handleLogout, name$2, shell, [])), tmp$2);
                  case /* ProjectDebugger2 */19 :
                      var aperture$3 = route[1].aperture;
                      var name$3 = route[0];
                      return React.createElement(React.Fragment, undefined, ReasonReact.element(JSON.stringify(Curry._1(Crdb$BsConsole.Aperture.toJson, aperture$3)) + ("__" + name$3), undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(aperture$3), undefined, undefined, undefined, route, handleChangeUrl, handleLogout, name$3, shell, [])));
                  case /* ProjectFlamegraph */20 :
                      var qs$3 = route[1];
                      var name$4 = route[0];
                      var query_000$1 = qs$3.aperture;
                      var query_002$1 = Crdb$BsConsole.Fold.empty;
                      var query$2 = /* Aggregate */Block.__(0, [
                          query_000$1,
                          undefined,
                          query_002$1,
                          undefined,
                          undefined,
                          undefined
                        ]);
                      var aperture$4 = qs$3.aperture;
                      var tmp$3;
                      tmp$3 = typeof shell === "number" || shell.tag ? null : ReasonReact.element("__Wildcard" + name$4, undefined, WildcardTimestampBin$BsConsole.Re.make(shell[0], (function (range) {
                                    return Curry._1(handleChangeUrl, /* ProjectFlamegraph */Block.__(20, [
                                                  name$4,
                                                  {
                                                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Custom */Block.__(1, [range]), aperture$4),
                                                    weighted: qs$3.weighted,
                                                    reversed: qs$3.reversed
                                                  }
                                                ]));
                                  }), aperture$4, name$4, token, route, handleChangeUrl, undefined, undefined, []));
                      return React.createElement(React.Fragment, undefined, ReasonReact.element("AppBar__" + name$4, undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(aperture$4), undefined, query$2, undefined, route, handleChangeUrl, handleLogout, name$4, shell, [])), tmp$3);
                  case /* ProjectQueryBuilder */13 :
                  case /* ProjectSimilarity */21 :
                      exit = 1;
                      break;
                  case /* Reset */0 :
                  case /* CreateAccount */1 :
                  case /* UDashEntry */2 :
                  case /* Login */3 :
                  case /* Share */4 :
                  case /* Activate */5 :
                  case /* Project */7 :
                  case /* ProjectOverview */8 :
                  case /* ProjectCompareReleases */9 :
                  case /* ProjectInboxLegacy1 */12 :
                  case /* ProjectQueryBuilderLegacy1 */14 :
                  case /* ProjectInstancesLegacy1 */16 :
                  case /* ProjectDebuggerLegacy1 */18 :
                  case /* ProjectSettingsStorage */30 :
                  case /* RedirectLegacyDebugger */51 :
                  case /* RedirectLegacyDetailView */52 :
                  case /* RedirectLegacyQueryBuilder */53 :
                  case /* Workflow */54 :
                  case /* SamlError */55 :
                  case /* NotFound */56 :
                  case /* Error */57 :
                  case /* GenerateDemoProject */58 :
                      return null;
                  default:
                    var name$5 = route[0];
                    return ReasonReact.element("AppBar__" + name$5, undefined, AppBar$BsConsole.make(token, handleTask, undefined, undefined, undefined, undefined, route, handleChangeUrl, handleLogout, name$5, shell, []));
                }
              }
              switch (exit) {
                case 1 :
                    var qs$4 = route[1];
                    var name$6 = route[0];
                    var query_000$2 = qs$4.aperture;
                    var query_001$1 = qs$4.factor;
                    var query_002$2 = qs$4.fold;
                    var query_003 = qs$4.sort;
                    var query_005 = Caml_option.some(qs$4.havings);
                    var query$3 = /* Aggregate */Block.__(0, [
                        query_000$2,
                        query_001$1,
                        query_002$2,
                        query_003,
                        undefined,
                        query_005
                      ]);
                    var aperture$5 = qs$4.aperture;
                    var mode$2 = SavedQueriesModel$BsConsole.modeOfStringExn("aggregate");
                    var tmp$4;
                    tmp$4 = typeof shell === "number" || shell.tag ? null : ReasonReact.element("__Wildcard" + name$6, undefined, WildcardTimestampBin$BsConsole.Re.make(shell[0], (function (range) {
                                  return Curry._1(handleChangeUrl, /* ProjectQueryBuilder */Block.__(13, [
                                                name$6,
                                                {
                                                  aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Custom */Block.__(1, [range]), aperture$5),
                                                  factor: qs$4.factor,
                                                  havings: qs$4.havings,
                                                  fold: qs$4.fold,
                                                  sort: qs$4.sort,
                                                  normBy: qs$4.normBy,
                                                  highlightExporter: qs$4.highlightExporter
                                                }
                                              ]));
                                }), aperture$5, name$6, token, route, handleChangeUrl, undefined, undefined, []));
                    return React.createElement(React.Fragment, undefined, ReasonReact.element("AppBar__" + name$6, undefined, AppBar$BsConsole.make(token, handleTask, Caml_option.some(aperture$5), undefined, query$3, mode$2, route, handleChangeUrl, handleLogout, name$6, shell, [])), tmp$4);
                case 2 :
                    return ReasonReact.element(undefined, undefined, SettingsBar$BsConsole.make(handleBack, handleChangeUrl, handleLogout, shell, []));
                
              }
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
