// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Col2$BsConsole = require("../../components/Col2.js");
var Crdb$BsConsole = require("../../crdb.js");
var I18N$BsConsole = require("../../I18N.js");
var Util$BsConsole = require("../../util.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var ApiAlerts$BsConsole = require("./ApiAlerts.js");
var Backtrace$BsConsole = require("../../bindings/Backtrace.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var InboxUtils$BsConsole = require("../../inbox/InboxUtils.js");
var FilterInput$BsConsole = require("../../FilterInput.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var DescribeTask$BsConsole = require("../../DescribeTask.js");
var Info = require("@material-ui/icons/Info");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var Close = require("@material-ui/icons/Close");
var BtAutocomplete$BsConsole = require("../../BtAutocomplete.js");
var WarningMessage$BsConsole = require("../../WarningMessage.js");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var TextField = require("@material-ui/core/TextField");
var IconButton = require("@material-ui/core/IconButton");
var AttributeTypeahead$BsConsole = require("../../typeahead/AttributeTypeahead.js");
var ListItemIcon = require("@material-ui/core/ListItemIcon");
var ListItemText = require("@material-ui/core/ListItemText");
var OutlinedInput = require("@material-ui/core/OutlinedInput");
var FormHelperText = require("@material-ui/core/FormHelperText");
var AddCircleOutline = require("@material-ui/icons/AddCircleOutline");

var indicator = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
      /* :: */[
        Css.height(Css.px(4)),
        /* :: */[
          Css.margin2(/* zero */-789508312, Css.px(4)),
          /* [] */0
        ]
      ]
    ]);

var inputItem = Css.style(/* :: */[
      Css.padding(Css.px(10)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* row */5693978),
          /* :: */[
            Css.flex(/* `num */[
                  5496390,
                  1
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var inputTitle = Css.style(/* :: */[
      Css.marginBottom(Css.px(10)),
      /* [] */0
    ]);

var topMargin = Css.style(/* :: */[
      Css.marginTop(Css.px(30)),
      /* [] */0
    ]);

var bottomMargin = Css.style(/* :: */[
      Css.marginBottom(Css.px(30)),
      /* [] */0
    ]);

var rightLeftMargin = Css.style(/* :: */[
      Css.marginLeft(Css.px(10)),
      /* :: */[
        Css.marginRight(Css.px(10)),
        /* [] */0
      ]
    ]);

var flexRow = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.marginBottom(Css.px(10)),
          /* [] */0
        ]
      ]
    ]);

var flexColumn = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* [] */0
      ]
    ]);

var li = Css.merge(/* :: */[
      bottomMargin,
      /* :: */[
        flexColumn,
        /* :: */[
          Css.style(/* :: */[
                Css.marginLeft(Css.px(30)),
                /* :: */[
                  Css.marginRight(Css.px(30)),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var section = Css.merge(/* :: */[
      flexRow,
      /* [] */0
    ]);

var sectionColumn = Css.merge(/* :: */[
      flexColumn,
      /* :: */[
        Css.style(/* :: */[
              Css.flex(/* `num */[
                    5496390,
                    1
                  ]),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var ul = Css.style(/* :: */[
      Css.padding(Css.px(0)),
      /* :: */[
        Css.marginLeft(Css.px(25)),
        /* :: */[
          Css.marginRight(Css.px(55)),
          /* [] */0
        ]
      ]
    ]);

var circledNumbers = Css.style(/* :: */[
      Css.borderRadius(Css.pct(50)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.blue)),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.white)),
          /* :: */[
            Css.paddingRight(Css.px(5)),
            /* :: */[
              Css.paddingLeft(Css.px(5)),
              /* :: */[
                Css.marginRight(Css.px(5)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var buttonContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexEnd */924268066),
        /* :: */[
          Css.marginTop(Css.px(20)),
          /* :: */[
            Css.marginRight(Css.px(74)),
            /* [] */0
          ]
        ]
      ]
    ]);

var saveButton = Css.style(/* :: */[
      Css.marginLeft(Css.px(10)),
      /* :: */[
        Css.marginRight(Css.px(55)),
        /* [] */0
      ]
    ]);

var chipContainer = Css.style(/* :: */[
      Css.display(/* inlineFlex */53323314),
      /* :: */[
        Css.flexWrap(/* wrap */-822134326),
        /* :: */[
          Css.fontSize(Css.rem(0.9)),
          /* [] */0
        ]
      ]
    ]);

var chip = Css.style(/* :: */[
      Css.paddingRight(Css.px(5)),
      /* :: */[
        Css.paddingLeft(Css.px(7)),
        /* :: */[
          Css.display(/* inlineFlex */53323314),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
              /* :: */[
                Css.borderRadius(Css.px(7)),
                /* :: */[
                  Css.margin(Css.px(7)),
                  /* :: */[
                    Css.height(Css.px(30)),
                    /* :: */[
                      Css.overflow(/* hidden */-862584982),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var invalidChip = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.orangeLight)),
      /* [] */0
    ]);

var chipItem = Css.style(/* :: */[
      Css.marginLeft(Css.px(5)),
      /* :: */[
        Css.marginRight(Css.px(5)),
        /* [] */0
      ]
    ]);

var chipIconButtonClasses = {
  root: Css.style(/* :: */[
        Css.color(Css.hex(Colors$BsConsole.red)),
        /* :: */[
          Css.fontSize(Css.rem(1)),
          /* :: */[
            Css.important(Css.padding(Css.px(4))),
            /* :: */[
              Css.height(Css.pct(100)),
              /* :: */[
                Css.borderRadius(Css.px(0)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]),
  label: Css.style(/* :: */[
        Css.unsafe("fontSize", "inherit"),
        /* [] */0
      ])
};

var Styles = {
  indicator: indicator,
  inputItem: inputItem,
  inputTitle: inputTitle,
  topMargin: topMargin,
  bottomMargin: bottomMargin,
  rightLeftMargin: rightLeftMargin,
  flexRow: flexRow,
  flexColumn: flexColumn,
  li: li,
  section: section,
  sectionColumn: sectionColumn,
  ul: ul,
  circledNumbers: circledNumbers,
  buttonContainer: buttonContainer,
  saveButton: saveButton,
  chipContainer: chipContainer,
  chip: chip,
  invalidChip: invalidChip,
  chipItem: chipItem,
  chipIconButtonClasses: chipIconButtonClasses
};

function CreateEditAlert$Section(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: section
            }, children);
}

var Section = {
  make: CreateEditAlert$Section
};

function CreateEditAlert$SectionColumn(Props) {
  var children = Props.children;
  var classNamesOpt = Props.classNames;
  var classNames = classNamesOpt !== undefined ? classNamesOpt : "";
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    sectionColumn,
                    /* :: */[
                      classNames,
                      /* [] */0
                    ]
                  ])
            }, children);
}

var SectionColumn = {
  make: CreateEditAlert$SectionColumn
};

function formatFilter(filterSelections) {
  return Belt_Array.map(filterSelections, (function (filter) {
                var match = filter.attribute;
                return {
                        type_: filter.type_,
                        attribute: match !== undefined ? match.name : "",
                        filter: [
                          filter.operator,
                          filter.value
                        ]
                      };
              }));
}

var Filter = {
  formatFilter: formatFilter
};

var frequencyTimesAvailable = [
  /* OneMinute */0,
  /* FiveMinutes */1,
  /* FifteenMinutes */2,
  /* ThirtyMinutes */3,
  /* OneHour */4,
  /* TwelveHours */5,
  /* OneDay */6
];

function typeToString(param) {
  switch (param) {
    case /* OneMinute */0 :
        return I18N$BsConsole.get(undefined, "1 minute");
    case /* FiveMinutes */1 :
        return I18N$BsConsole.get(undefined, "5 minutes");
    case /* FifteenMinutes */2 :
        return I18N$BsConsole.get(undefined, "15 minutes");
    case /* ThirtyMinutes */3 :
        return I18N$BsConsole.get(undefined, "30 minutes");
    case /* OneHour */4 :
        return I18N$BsConsole.get(undefined, "1 hour");
    case /* TwelveHours */5 :
        return I18N$BsConsole.get(undefined, "12 hours");
    case /* OneDay */6 :
        return I18N$BsConsole.get(undefined, "1 day");
    
  }
}

function typeToInt(param) {
  switch (param) {
    case /* OneMinute */0 :
        return 60000;
    case /* FiveMinutes */1 :
        return 300000;
    case /* FifteenMinutes */2 :
        return 900000;
    case /* ThirtyMinutes */3 :
        return 1800000;
    case /* OneHour */4 :
        return 3600000;
    case /* TwelveHours */5 :
        return 43200000;
    case /* OneDay */6 :
        return 86400000;
    
  }
}

function intToType(ms) {
  if (ms >= 1800000) {
    if (ms >= 43200000) {
      if (ms === 86400000) {
        return /* OneDay */6;
      }
      if (ms < 43200001) {
        return /* TwelveHours */5;
      }
      
    } else {
      if (ms === 3600000) {
        return /* OneHour */4;
      }
      if (ms < 1800001) {
        return /* ThirtyMinutes */3;
      }
      
    }
  } else {
    if (ms === 60000) {
      return /* OneMinute */0;
    }
    if (ms === 300000) {
      return /* FiveMinutes */1;
    }
    if (ms === 900000) {
      return /* FifteenMinutes */2;
    }
    
  }
  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "CreateEditAlert/Frequency/intToType", Backtrace$BsConsole.Attributes.addString("unHandledApiValue", "true", Backtrace$BsConsole.Attributes.make(undefined)));
  Backtrace$BsConsole.Client.send(__x, /* `string */[
        288368849,
        "Frequency of " + (String(ms) + "ms is not an available option and was defaulted to 1 min")
      ]);
  return /* OneMinute */0;
}

var Frequency = {
  frequencyTimesAvailable: frequencyTimesAvailable,
  typeToString: typeToString,
  typeToInt: typeToInt,
  intToType: intToType
};

var executionDelayTimesAvailable = [
  /* NoDelay */0,
  /* OneMinute */1,
  /* TwoMinutes */2,
  /* FiveMinutes */3,
  /* TenMinutes */4,
  /* ThirtyMinutes */5,
  /* OneHour */6
];

function typeToString$1(param) {
  switch (param) {
    case /* NoDelay */0 :
        return I18N$BsConsole.get(undefined, "No delay");
    case /* OneMinute */1 :
        return I18N$BsConsole.get(undefined, "1 minute");
    case /* TwoMinutes */2 :
        return I18N$BsConsole.get(undefined, "2 minutes");
    case /* FiveMinutes */3 :
        return I18N$BsConsole.get(undefined, "5 minutes");
    case /* TenMinutes */4 :
        return I18N$BsConsole.get(undefined, "10 minutes");
    case /* ThirtyMinutes */5 :
        return I18N$BsConsole.get(undefined, "30 minutes");
    case /* OneHour */6 :
        return I18N$BsConsole.get(undefined, "1 hour");
    
  }
}

function typeToInt$1(param) {
  switch (param) {
    case /* NoDelay */0 :
        return 0;
    case /* OneMinute */1 :
        return 60000;
    case /* TwoMinutes */2 :
        return 120000;
    case /* FiveMinutes */3 :
        return 300000;
    case /* TenMinutes */4 :
        return 600000;
    case /* ThirtyMinutes */5 :
        return 1800000;
    case /* OneHour */6 :
        return 3600000;
    
  }
}

function intToType$1(ms) {
  if (ms >= 300000) {
    if (ms >= 1800000) {
      if (ms === 3600000) {
        return /* OneHour */6;
      }
      if (ms < 1800001) {
        return /* ThirtyMinutes */5;
      }
      
    } else {
      if (ms === 600000) {
        return /* TenMinutes */4;
      }
      if (ms < 300001) {
        return /* FiveMinutes */3;
      }
      
    }
  } else {
    if (ms === 0) {
      return /* NoDelay */0;
    }
    if (ms === 60000) {
      return /* OneMinute */1;
    }
    if (ms === 120000) {
      return /* TwoMinutes */2;
    }
    
  }
  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "CreateEditAlert/ExecutionDelay/intToType", Backtrace$BsConsole.Attributes.addString("unHandledApiValue", "true", Backtrace$BsConsole.Attributes.make(undefined)));
  Backtrace$BsConsole.Client.send(__x, /* `string */[
        288368849,
        "Alert Execution Delay of " + (String(ms) + "ms is not an available option and was defaulted to No Delay (0)")
      ]);
  return /* NoDelay */0;
}

var ExecutionDelay = {
  executionDelayTimesAvailable: executionDelayTimesAvailable,
  typeToString: typeToString$1,
  typeToInt: typeToInt$1,
  intToType: intToType$1
};

function CreateEditAlert$Required(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              className: className,
              children: null
            }, children, React.createElement("span", {
                  style: {
                    color: Colors$BsConsole.octothorpe(Colors$BsConsole.npDarkRed)
                  }
                }, I18N$BsConsole.showSkip(" *")));
}

var Required = {
  make: CreateEditAlert$Required
};

function CreateEditAlert$AlertName(Props) {
  var alertNameSelection = Props.alertNameSelection;
  var handleNameSelection = Props.handleNameSelection;
  return React.createElement("ul", {
              className: ul
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.merge(/* :: */[
                        bottomMargin,
                        /* :: */[
                          topMargin,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "1. New alert")
                }), React.createElement("li", {
                  className: li
                }, React.createElement(CreateEditAlert$Required, {
                      className: inputTitle,
                      children: I18N$BsConsole.show(undefined, "Alert Name")
                    }), React.createElement(TextField.default, {
                      autoFocus: false,
                      disabled: false,
                      placeholder: "Alert Name",
                      value: alertNameSelection,
                      color: "primary",
                      variant: "outlined",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.selector("input", /* :: */[
                                    Css.important(Css.padding(Css.px(10))),
                                    /* [] */0
                                  ]),
                              /* :: */[
                                Css.width(Css.px(300)),
                                /* [] */0
                              ]
                            ])
                      },
                      onChange: (function ($$event) {
                          return Curry._1(handleNameSelection, Belt_Option.getWithDefault($$event.target.value, ""));
                        })
                    })));
}

var AlertName = {
  make: CreateEditAlert$AlertName
};

function CreateEditAlert$TriggerAnAlertWhen(Props) {
  var alertConditionNamesAvailable = Props.alertConditionNamesAvailable;
  var alertConditionTimesAvailable = Props.alertConditionTimesAvailable;
  var alertConditionSelection = Props.alertConditionSelection;
  var handleConditionSelection = Props.handleConditionSelection;
  var isConditionValid = Props.isConditionValid;
  var match = React.useState((function () {
          return false;
        }));
  var setValueError = match[1];
  var match$1 = alertConditionSelection.name;
  var tmp;
  if (match$1 >= 2) {
    var match$2 = alertConditionSelection.name;
    var tmp$1;
    if (match$2 !== 2) {
      if (match$2 >= 3) {
        tmp$1 = "Number of users";
      } else {
        throw [
              Caml_builtin_exceptions.assert_failure,
              /* tuple */[
                "CreateEditAlert.re",
                361,
                27
              ]
            ];
      }
    } else {
      tmp$1 = "Number of errors";
    }
    var value = alertConditionSelection.value;
    tmp = React.createElement(React.Fragment, undefined, React.createElement(CreateEditAlert$SectionColumn, {
              children: null,
              classNames: Css.style(/* :: */[
                    Css.marginLeft(Css.px(20)),
                    /* [] */0
                  ])
            }, React.createElement(CreateEditAlert$Required, {
                  className: inputTitle,
                  children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* End_of_format */0
                              ]),
                            "%s"
                          ]), tmp$1)
                }), React.createElement(TextField.default, {
                  autoFocus: false,
                  placeholder: "",
                  value: value !== undefined ? String(value) : "",
                  color: "primary",
                  variant: "outlined",
                  type: "number",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.selector("input", /* :: */[
                                Css.important(Css.padding(Css.px(10))),
                                /* [] */0
                              ]),
                          /* :: */[
                            Css.width(Css.pct(100)),
                            /* [] */0
                          ]
                        ])
                  },
                  onChange: (function ($$event) {
                      var value = Belt_Option.getWithDefault($$event.target.value, "");
                      if (value !== "" && Caml_format.caml_int_of_string(value) > 0) {
                        var newCondition_name = alertConditionSelection.name;
                        var newCondition_timeFrame = alertConditionSelection.timeFrame;
                        var newCondition_value = Caml_format.caml_int_of_string(value);
                        var newCondition = {
                          name: newCondition_name,
                          timeFrame: newCondition_timeFrame,
                          value: newCondition_value
                        };
                        Curry._1(setValueError, (function (param) {
                                return false;
                              }));
                        return Curry._1(handleConditionSelection, newCondition);
                      }
                      if (value !== "") {
                        return Curry._1(setValueError, (function (param) {
                                      return true;
                                    }));
                      }
                      var newCondition_name$1 = alertConditionSelection.name;
                      var newCondition_timeFrame$1 = alertConditionSelection.timeFrame;
                      var newCondition$1 = {
                        name: newCondition_name$1,
                        timeFrame: newCondition_timeFrame$1,
                        value: undefined
                      };
                      Curry._1(setValueError, (function (param) {
                              return false;
                            }));
                      return Curry._1(handleConditionSelection, newCondition$1);
                    })
                }), match[0] ? React.createElement(FormHelperText.default, {
                    error: true,
                    children: I18N$BsConsole.show(undefined, "positive integers only")
                  }) : (
                isConditionValid || !Belt_Option.isNone(alertConditionSelection.value) ? null : React.createElement(FormHelperText.default, {
                        error: true,
                        children: I18N$BsConsole.show(undefined, "value is required")
                      })
              )), React.createElement(CreateEditAlert$SectionColumn, {
              children: null,
              classNames: Css.style(/* :: */[
                    Css.marginLeft(Css.px(20)),
                    /* [] */0
                  ])
            }, React.createElement(CreateEditAlert$Required, {
                  className: inputTitle,
                  children: I18N$BsConsole.show(undefined, "In time frame")
                }), React.createElement(Select.default, {
                  value: alertConditionSelection.timeFrame,
                  onChange: (function ($$event, param) {
                      var value = $$event.target.value;
                      if (!(value == null)) {
                        return Curry._1(handleConditionSelection, {
                                    name: alertConditionSelection.name,
                                    timeFrame: value,
                                    value: alertConditionSelection.value
                                  });
                      }
                      
                    }),
                  input: React.createElement(OutlinedInput.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* [] */0
                              ])
                        },
                        labelWidth: 0
                      }),
                  classes: {
                    select: Css.merge(/* :: */[
                          inputItem,
                          /* [] */0
                        ]),
                    selectMenu: Css.style(/* :: */[
                          Css.paddingRight(Css.px(30)),
                          /* [] */0
                        ])
                  },
                  autoWidth: true,
                  children: alertConditionTimesAvailable.length !== 0 ? Belt_Array.map(alertConditionTimesAvailable, (function (conditionTime) {
                            return React.createElement(MenuItem.default, {
                                        value: conditionTime,
                                        children: I18N$BsConsole.show(undefined, Curry._1(ApiAlerts$BsConsole.Condition.TimeFrame.typeToString, conditionTime)),
                                        key: Curry._1(ApiAlerts$BsConsole.Condition.TimeFrame.typeToString, conditionTime)
                                      });
                          })) : [React.createElement(MenuItem.default, {
                            children: I18N$BsConsole.show(undefined, "No options available")
                          })]
                }), !isConditionValid && Belt_Option.isNone(alertConditionSelection.timeFrame) ? React.createElement(FormHelperText.default, {
                    error: true,
                    children: I18N$BsConsole.show(undefined, "time frame is required")
                  }) : null));
  } else {
    tmp = null;
  }
  return React.createElement("li", {
              className: Css.merge(/* :: */[
                    li,
                    /* :: */[
                      Css.style(/* :: */[
                            Css.marginRight(Css.px(74)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(CreateEditAlert$Section, {
                  children: null
                }, React.createElement(CreateEditAlert$SectionColumn, {
                      children: null
                    }, React.createElement(CreateEditAlert$Required, {
                          className: inputTitle,
                          children: null
                        }, React.createElement("span", {
                              className: circledNumbers
                            }, I18N$BsConsole.show(undefined, "1")), I18N$BsConsole.show(undefined, "Trigger an alert when...")), React.createElement(Select.default, {
                          value: alertConditionSelection.name,
                          onChange: (function ($$event, param) {
                              var value = $$event.target.value;
                              if (!(value == null)) {
                                return Curry._1(handleConditionSelection, value >= 2 ? ({
                                                name: value,
                                                timeFrame: alertConditionSelection.timeFrame,
                                                value: alertConditionSelection.value
                                              }) : ({
                                                name: value,
                                                timeFrame: undefined,
                                                value: undefined
                                              }));
                              }
                              
                            }),
                          input: React.createElement(OutlinedInput.default, {
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.width(Css.pct(100)),
                                        /* [] */0
                                      ])
                                },
                                labelWidth: 0
                              }),
                          classes: {
                            select: Css.merge(/* :: */[
                                  inputItem,
                                  /* :: */[
                                    Css.style(/* :: */[
                                          Css.unsafe("width", "calc(100% - 45px)"),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ]
                                ]),
                            selectMenu: Css.style(/* :: */[
                                  Css.paddingRight(Css.px(30)),
                                  /* [] */0
                                ])
                          },
                          autoWidth: true,
                          children: alertConditionNamesAvailable.length !== 0 ? Belt_Array.map(alertConditionNamesAvailable, (function (conditionName) {
                                    return React.createElement(MenuItem.default, {
                                                value: conditionName,
                                                children: I18N$BsConsole.show(undefined, Curry._1(ApiAlerts$BsConsole.Condition.Name.typeToMenuItem, conditionName)),
                                                key: Curry._1(ApiAlerts$BsConsole.Condition.Name.typeToString, conditionName)
                                              });
                                  })) : [React.createElement(MenuItem.default, {
                                    children: I18N$BsConsole.show(undefined, "No options available")
                                  })]
                        }), React.createElement(FormHelperText.default, {
                          children: I18N$BsConsole.show(undefined, "Add an alert trigger")
                        })), tmp));
}

var TriggerAnAlertWhen = {
  make: CreateEditAlert$TriggerAnAlertWhen
};

function CreateEditAlert$FilterChip(Props) {
  var filter = Props.filter;
  var index = Props.index;
  var handleDeleteFilterChip = Props.handleDeleteFilterChip;
  var match = filter.attribute;
  var match$1 = filter.invalidAttributeName;
  var attributeName = match !== undefined ? match.name : (
      match$1 !== undefined ? match$1 : ""
    );
  var truncate = function (str) {
    if (str.length > 50) {
      return $$String.sub(str, 0, 50) + "...";
    } else {
      return str;
    }
  };
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    chip,
                    /* :: */[
                      Belt_Option.isSome(filter.invalidAttributeName) ? invalidChip : "",
                      /* [] */0
                    ]
                  ])
            }, React.createElement(Tooltip.default, {
                  children: React.createElement("div", undefined, React.createElement("span", {
                            className: chipItem
                          }, I18N$BsConsole.showSkip(attributeName)), React.createElement("span", {
                            className: chipItem
                          }, I18N$BsConsole.showSkip(Util$BsConsole.filterOpMap(filter.operator))), React.createElement("span", {
                            className: chipItem
                          }, I18N$BsConsole.showSkip(truncate(filter.value)))),
                  title: attributeName + (", " + (filter.operator + (", " + filter.value)))
                }), React.createElement(Tooltip.default, {
                  children: React.createElement(IconButton.default, {
                        classes: chipIconButtonClasses,
                        onClick: (function (param) {
                            return Curry._1(handleDeleteFilterChip, index);
                          }),
                        children: React.createElement(Close.default, {
                              fontSize: "inherit"
                            })
                      }),
                  title: "Delete filter"
                }));
}

var FilterChip = {
  make: CreateEditAlert$FilterChip
};

function CreateEditAlert$AlertFilterChips(Props) {
  var filterSelections = Props.filterSelections;
  var handleDeleteFilterChip = Props.handleDeleteFilterChip;
  var invalidAttributesString = {
    contents: ""
  };
  return React.createElement("div", {
              className: chipContainer
            }, Belt_Array.mapWithIndex(filterSelections, (function (index, filter) {
                    return React.createElement(CreateEditAlert$FilterChip, {
                                filter: filter,
                                index: index,
                                handleDeleteFilterChip: handleDeleteFilterChip
                              });
                  })), (Belt_Array.forEachWithIndex(filterSelections, (function (index, filter) {
                      var invalidAttribute = filter.invalidAttributeName;
                      if (invalidAttribute !== undefined) {
                        invalidAttributesString.contents = invalidAttributesString.contents + ((
                            index !== 0 ? ", " : " "
                          ) + invalidAttribute);
                        return ;
                      }
                      
                    })), invalidAttributesString.contents !== "" ? React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(100)),
                            /* [] */0
                          ])
                    }, React.createElement(FormHelperText.default, {
                          error: true,
                          children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                    /* String_literal */Block.__(11, [
                                        "Invalid attribute(s): ",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* String_literal */Block.__(11, [
                                                ". Delete attribute(s) to continue.",
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ]),
                                    "Invalid attribute(s): %s. Delete attribute(s) to continue."
                                  ]), invalidAttributesString.contents)
                        })) : null));
}

var AlertFilterChips = {
  make: CreateEditAlert$AlertFilterChips
};

function CreateEditAlert$AddChipButton(Props) {
  var filter = Props.filter;
  var onSuccess = Props.onSuccess;
  var onError = Props.onError;
  Props.className;
  var isValid = function (param) {
    return !(filter.attribute === undefined || filter.operator === "" || filter.value.trim() === "");
  };
  return React.createElement(Tooltip.default, {
              placement: "bottom",
              children: React.createElement(Button.default, {
                    variant: "contained",
                    color: "primary",
                    className: Css.style(/* :: */[
                          Css.height(Css.px(25)),
                          /* :: */[
                            Css.marginTop(Css.px(7)),
                            /* :: */[
                              Css.marginLeft(Css.px(15)),
                              /* [] */0
                            ]
                          ]
                        ]),
                    onClick: (function (param) {
                        if (isValid(undefined)) {
                          return Curry._1(onSuccess, undefined);
                        } else {
                          return Curry._1(onError, undefined);
                        }
                      }),
                    children: I18N$BsConsole.show(undefined, "Add")
                  }),
              title: "Add filter"
            });
}

var AddChipButton = {
  make: CreateEditAlert$AddChipButton
};

function CreateEditAlert$AttributeValueInput(Props) {
  var isValid = Props.isValid;
  var isDisabled = Props.isDisabled;
  var filterValue = Props.filterValue;
  var handleIsFilterValid = Props.handleIsFilterValid;
  var handleSetFilterValue = Props.handleSetFilterValue;
  var validationError = Props.validationError;
  return React.createElement("span", {
              className: Css.style(/* :: */[
                    Css.flexGrow(1),
                    /* :: */[
                      Css.marginLeft(Css.px(15)),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(TextField.default, {
                  autoFocus: false,
                  disabled: isDisabled,
                  placeholder: "value",
                  value: filterValue,
                  color: "primary",
                  variant: "outlined",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.selector("input", /* :: */[
                                Css.important(Css.padding(Css.px(10))),
                                /* [] */0
                              ]),
                          /* :: */[
                            Css.width(Css.pct(100)),
                            /* [] */0
                          ]
                        ])
                  },
                  onChange: (function ($$event) {
                      var value = Belt_Option.getWithDefault($$event.target.value, "");
                      if (value !== "") {
                        Curry._1(handleIsFilterValid, false);
                      } else if (isValid) {
                        Curry._1(handleIsFilterValid, true);
                      }
                      return Curry._1(handleSetFilterValue, value);
                    })
                }), React.createElement(FormHelperText.default, {
                  children: I18N$BsConsole.show(undefined, "Filter value")
                }), validationError && filterValue === "" ? React.createElement(FormHelperText.default, {
                    error: true,
                    children: I18N$BsConsole.show(undefined, "A filter value must be set to add")
                  }) : null);
}

var AttributeValueInput = {
  make: CreateEditAlert$AttributeValueInput
};

function CreateEditAlert$AttributeValueInputAutocomplete(Props) {
  var attribute = Props.attribute;
  var token = Props.token;
  var projectName = Props.projectName;
  var onChange = Props.onChange;
  var onDelete = Props.onDelete;
  var initialValue = Props.initialValue;
  var isValid = Props.isValid;
  var filterValue = Props.filterValue;
  var handleIsFilterValid = Props.handleIsFilterValid;
  var validationError = Props.validationError;
  var allTimeAperture = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
              /* Floating */0,
              /* All */6
            ])), Crdb$BsConsole.Aperture.$$default);
  var match = FilterInput$BsConsole.ValueInputAutocomplete.useAttributeValueSuggestions(attribute, allTimeAperture, Crdb$BsConsole.Havings.empty, token, projectName);
  return React.createElement("span", {
              className: Css.style(/* :: */[
                    Css.flexGrow(1),
                    /* :: */[
                      Css.marginLeft(Css.px(15)),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(BtAutocomplete$BsConsole.make, {
                  toString: (function (param) {
                      return param[0];
                    }),
                  considerations: match[0],
                  onDelete: onDelete,
                  onChange: (function (raw, value$prime) {
                      if (value$prime !== undefined) {
                        return Curry._1(onChange, value$prime[0]);
                      } else {
                        return Curry._1(onChange, raw);
                      }
                    }),
                  defaultValue: Belt_Option.getWithDefault(initialValue, ""),
                  loading: match[1],
                  placeholder: I18N$BsConsole.get(undefined, "Value"),
                  autoFocus: false,
                  inputAriaLabel: I18N$BsConsole.get(undefined, "Value"),
                  renderListOption: (function (param, idx) {
                      var value = param[0];
                      return React.createElement(Col2$BsConsole.make, {
                                  justifyContent: /* spaceAround */-485895757,
                                  className: Css.style(/* :: */[
                                        Css.minWidth(Css.px(100)),
                                        /* [] */0
                                      ]),
                                  children: null,
                                  key: value + ("__" + String(idx))
                                }, React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Body1 */8,
                                      className: Css.style(/* :: */[
                                            Css.margin(Css.zero),
                                            /* :: */[
                                              Css.unsafe("overflowWrap", "anywhere"),
                                              /* [] */0
                                            ]
                                          ]),
                                      children: I18N$BsConsole.showSkip(value)
                                    }), React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Caption */11,
                                      className: Css.style(/* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.grey4)),
                                            /* :: */[
                                              Css.marginBottom(Css.zero),
                                              /* [] */0
                                            ]
                                          ]),
                                      children: I18N$BsConsole.showSkip(String(param[1]))
                                    }));
                    }),
                  renderInput: (function (param) {
                      var onFocus = param.onFocus;
                      var onChange = param.onChange;
                      var onFocus$prime = Curry.__1(onFocus);
                      var onChange$prime = function ($$event) {
                        var newValue = $$event.target.value;
                        if (newValue !== "") {
                          Curry._1(handleIsFilterValid, false);
                        } else if (isValid) {
                          Curry._1(handleIsFilterValid, true);
                        }
                        return Curry._1(onChange, $$event);
                      };
                      return React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                      onFocus: onFocus$prime,
                                      className: Css.style(/* :: */[
                                            Css.important(Css.marginBottom(/* zero */-789508312)),
                                            /* :: */[
                                              Css.width(Css.pct(100)),
                                              /* :: */[
                                                Css.selector("input", /* :: */[
                                                      Css.important(Css.padding(Css.px(10))),
                                                      /* [] */0
                                                    ]),
                                                /* [] */0
                                              ]
                                            ]
                                          ]),
                                      placeholder: param.placeholder,
                                      value: param.value,
                                      onChange: onChange$prime,
                                      autoFocus: true,
                                      variant: "outlined"
                                    }), React.createElement(FormHelperText.default, {
                                      children: I18N$BsConsole.show(undefined, "Filter value")
                                    }), validationError && filterValue === "" ? React.createElement(FormHelperText.default, {
                                        error: true,
                                        children: I18N$BsConsole.show(undefined, "A filter value must be set to add")
                                      }) : null);
                    })
                }));
}

var AttributeValueInputAutocomplete = {
  make: CreateEditAlert$AttributeValueInputAutocomplete
};

var initialState = {
  filter: {
    type_: "attribute",
    attribute: undefined,
    operator: "",
    value: "",
    invalidAttributeName: undefined
  },
  validationError: false
};

function reducer(state, action) {
  switch (action.tag | 0) {
    case /* SetFilter */0 :
        return {
                filter: action[0],
                validationError: state.validationError
              };
    case /* SetFilterAndReset */1 :
        return {
                filter: action[0],
                validationError: action[1]
              };
    case /* SetValidationError */2 :
        return {
                filter: state.filter,
                validationError: action[0]
              };
    
  }
}

function CreateEditAlert$AlertFilters(Props) {
  var attributes = Props.attributes;
  var filterSelections = Props.filterSelections;
  var handleAddFilter = Props.handleAddFilter;
  var handleIsFilterValid = Props.handleIsFilterValid;
  var token = Props.token;
  var projectName = Props.projectName;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var validationError = match$1.validationError;
  var filter = match$1.filter;
  var match$2 = React.useState((function () {
          return 0;
        }));
  var setRowKey = match$2[1];
  React.useEffect((function () {
          Curry._1(setRowKey, (function (k) {
                  return k + 1 | 0;
                }));
          
        }), [filterSelections]);
  var match$3 = filter.attribute;
  var attribute = filter.attribute;
  var attr = filter.attribute;
  var tmp;
  if (attr !== undefined) {
    var match$4 = filter.operator;
    var match$5 = Belt_List.keep(attr.aggregate, (function (agg) {
            return agg === "bin";
          }));
    var exit = 0;
    var exit$1 = 0;
    switch (match$4) {
      case "assigned-to" :
      case "equal" :
      case "linked-to" :
      case "not-assigned-to" :
      case "not-equal" :
      case "not-linked-to" :
          exit$1 = 2;
          break;
      default:
        exit = 1;
    }
    if (exit$1 === 2) {
      if (match$5) {
        exit = 1;
      } else {
        tmp = React.createElement(CreateEditAlert$AttributeValueInputAutocomplete, {
              attribute: attr,
              token: token,
              projectName: projectName,
              onChange: (function (value) {
                  return Curry._1(dispatch, /* SetFilter */Block.__(0, [{
                                  type_: filter.type_,
                                  attribute: filter.attribute,
                                  operator: filter.operator,
                                  value: value,
                                  invalidAttributeName: filter.invalidAttributeName
                                }]));
                }),
              onDelete: (function (param) {
                  return Curry._1(dispatch, /* SetFilter */Block.__(0, [{
                                  type_: filter.type_,
                                  attribute: filter.attribute,
                                  operator: filter.operator,
                                  value: "",
                                  invalidAttributeName: filter.invalidAttributeName
                                }]));
                }),
              initialValue: filter.value,
              isValid: filter.attribute === undefined && filter.operator === "",
              filterValue: filter.value,
              handleIsFilterValid: handleIsFilterValid,
              validationError: validationError
            });
      }
    }
    if (exit === 1) {
      tmp = React.createElement(CreateEditAlert$AttributeValueInput, {
            isValid: filter.attribute === undefined && filter.operator === "",
            isDisabled: filter.operator === "",
            filterValue: filter.value,
            handleIsFilterValid: handleIsFilterValid,
            handleSetFilterValue: (function (value) {
                return Curry._1(dispatch, /* SetFilter */Block.__(0, [{
                                type_: filter.type_,
                                attribute: filter.attribute,
                                operator: filter.operator,
                                value: value,
                                invalidAttributeName: filter.invalidAttributeName
                              }]));
              }),
            validationError: validationError
          });
    }
    
  } else {
    tmp = React.createElement(CreateEditAlert$AttributeValueInput, {
          isValid: filter.attribute === undefined && filter.operator === "",
          isDisabled: filter.operator === "",
          filterValue: filter.value,
          handleIsFilterValid: handleIsFilterValid,
          handleSetFilterValue: (function (value) {
              return Curry._1(dispatch, /* SetFilter */Block.__(0, [{
                              type_: filter.type_,
                              attribute: filter.attribute,
                              operator: filter.operator,
                              value: value,
                              invalidAttributeName: filter.invalidAttributeName
                            }]));
            }),
          validationError: validationError
        });
  }
  return React.createElement("div", {
              className: flexRow
            }, React.createElement(BtAutocomplete$BsConsole.make, {
                  toString: (function (attribute$prime) {
                      return attribute$prime.name;
                    }),
                  considerations: Belt_List.toArray(attributes),
                  onChange: (function (_raw, attribute$prime) {
                      Curry._1(handleIsFilterValid, false);
                      return Curry._1(dispatch, /* SetFilterAndReset */Block.__(1, [
                                    {
                                      type_: filter.type_,
                                      attribute: attribute$prime,
                                      operator: filter.operator,
                                      value: filter.value,
                                      invalidAttributeName: filter.invalidAttributeName
                                    },
                                    validationError
                                  ]));
                    }),
                  defaultValue: match$3 !== undefined ? match$3.name : "",
                  placeholder: I18N$BsConsole.get(undefined, "Attribute"),
                  autoFocus: false,
                  inputAriaLabel: I18N$BsConsole.get(undefined, "Attribute"),
                  renderListOption: (function (attr, idx) {
                      return React.createElement(AttributeTypeahead$BsConsole.AttributeItem.make, {
                                  name: attr.name,
                                  description: attr.description,
                                  custom: attr.custom,
                                  triage: InboxUtils$BsConsole.isNestedIssues(attr.name),
                                  used: attr.statistics.used,
                                  key: attr.name + ("__" + String(idx))
                                });
                    }),
                  renderInput: (function (param) {
                      var onFocus = param.onFocus;
                      var onChange = param.onChange;
                      var onFocus$prime = Curry.__1(onFocus);
                      var onChange$prime = function ($$event) {
                        var newValue = $$event.target.value;
                        if (newValue === "") {
                          if (filter.value === "") {
                            Curry._1(handleIsFilterValid, true);
                          }
                          Curry._1(dispatch, /* SetFilterAndReset */Block.__(1, [
                                  {
                                    type_: filter.type_,
                                    attribute: undefined,
                                    operator: "",
                                    value: filter.value,
                                    invalidAttributeName: filter.invalidAttributeName
                                  },
                                  validationError
                                ]));
                        }
                        return Curry._1(onChange, $$event);
                      };
                      return React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                      onFocus: onFocus$prime,
                                      className: Css.style(/* :: */[
                                            Css.important(Css.marginBottom(/* zero */-789508312)),
                                            /* :: */[
                                              Css.width(Css.px(300)),
                                              /* :: */[
                                                Css.selector("input", /* :: */[
                                                      Css.important(Css.padding(Css.px(10))),
                                                      /* [] */0
                                                    ]),
                                                /* [] */0
                                              ]
                                            ]
                                          ]),
                                      placeholder: param.placeholder,
                                      value: param.value,
                                      onChange: onChange$prime,
                                      variant: "outlined"
                                    }), React.createElement(FormHelperText.default, {
                                      children: I18N$BsConsole.show(undefined, "Filter attribute")
                                    }), validationError && filter.attribute === undefined ? React.createElement(FormHelperText.default, {
                                        error: true,
                                        children: I18N$BsConsole.show(undefined, "A filter attribute must be set to add")
                                      }) : null);
                    }),
                  key: "attribute-" + String(match$2[0])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.style(/* :: */[
                        Css.alignSelf(/* flexStart */662439529),
                        /* :: */[
                          Css.paddingTop(Css.px(10)),
                          /* :: */[
                            Css.marginLeft(Css.px(10)),
                            /* :: */[
                              Css.marginRight(Css.px(10)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "values")
                }), React.createElement("span", {
                  className: Css.style(/* :: */[
                        Css.flexGrow(1),
                        /* [] */0
                      ])
                }, React.createElement(Select.default, {
                      value: filter.operator,
                      disabled: Belt_Option.isNone(filter.attribute),
                      onChange: (function ($$event, param) {
                          var value = $$event.target.value;
                          if (!(value == null)) {
                            if (value !== "") {
                              Curry._1(handleIsFilterValid, false);
                            } else if (filter.attribute === undefined && filter.value === "") {
                              Curry._1(handleIsFilterValid, true);
                            }
                            return Curry._1(dispatch, /* SetFilter */Block.__(0, [{
                                            type_: filter.type_,
                                            attribute: filter.attribute,
                                            operator: value,
                                            value: filter.value,
                                            invalidAttributeName: filter.invalidAttributeName
                                          }]));
                          }
                          
                        }),
                      input: React.createElement(OutlinedInput.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ])
                            },
                            labelWidth: 0
                          }),
                      classes: {
                        select: Css.merge(/* :: */[
                              inputItem,
                              /* :: */[
                                Css.style(/* :: */[
                                      Css.unsafe("width", "calc(100% - 20px)"),
                                      /* [] */0
                                    ]),
                                /* [] */0
                              ]
                            ])
                      },
                      autoWidth: true,
                      children: attribute !== undefined ? (
                          Belt_List.toArray(attribute.filter).length !== 0 ? Belt_Array.map(Belt_List.toArray(attribute.filter), (function (condition) {
                                    return React.createElement(MenuItem.default, {
                                                value: condition,
                                                children: I18N$BsConsole.showSkip(condition),
                                                key: condition
                                              });
                                  })) : [React.createElement(MenuItem.default, {
                                    children: I18N$BsConsole.show(undefined, "No options available")
                                  })]
                        ) : [React.createElement(MenuItem.default, {
                                children: I18N$BsConsole.show(undefined, "No options available")
                              })]
                    }), React.createElement(FormHelperText.default, {
                      children: I18N$BsConsole.show(undefined, "Filter operator")
                    }), validationError && filter.operator === "" ? React.createElement(FormHelperText.default, {
                        error: true,
                        children: I18N$BsConsole.show(undefined, "A filter operator must be set to add")
                      }) : null), tmp, React.createElement(CreateEditAlert$AddChipButton, {
                  filter: filter,
                  onSuccess: (function (param) {
                      Curry._1(handleIsFilterValid, true);
                      Curry._1(dispatch, /* SetFilterAndReset */Block.__(1, [
                              {
                                type_: "attribute",
                                attribute: undefined,
                                operator: "",
                                value: "",
                                invalidAttributeName: undefined
                              },
                              false
                            ]));
                      return Curry._1(handleAddFilter, filter);
                    }),
                  onError: (function (param) {
                      return Curry._1(dispatch, /* SetValidationError */Block.__(2, [true]));
                    }),
                  className: Css.style(/* :: */[
                        Css.alignSelf(/* flexStart */662439529),
                        /* [] */0
                      ])
                }));
}

var AlertFilters = {
  initialState: initialState,
  reducer: reducer,
  make: CreateEditAlert$AlertFilters
};

function CreateEditAlert$OnlyShowErrorsWhere(Props) {
  var attributes = Props.attributes;
  var filterSelections = Props.filterSelections;
  var isFilterValid = Props.isFilterValid;
  var handleAddFilter = Props.handleAddFilter;
  var handleDeleteFilterChip = Props.handleDeleteFilterChip;
  var handleIsFilterValid = Props.handleIsFilterValid;
  var token = Props.token;
  var projectName = Props.projectName;
  return React.createElement("li", {
              className: Css.merge(/* :: */[
                    li,
                    /* :: */[
                      Css.style(/* :: */[
                            Css.marginRight(Css.px(0)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: inputTitle,
                  children: null
                }, React.createElement("span", {
                      className: circledNumbers
                    }, I18N$BsConsole.showSkip("2")), I18N$BsConsole.show(undefined, "Only show errors where...")), React.createElement(CreateEditAlert$AlertFilters, {
                  attributes: attributes,
                  filterSelections: filterSelections,
                  handleAddFilter: handleAddFilter,
                  handleIsFilterValid: handleIsFilterValid,
                  token: token,
                  projectName: projectName
                }), isFilterValid ? null : React.createElement(FormHelperText.default, {
                    error: true,
                    children: I18N$BsConsole.show(undefined, "Must click Add to save filter or clear out all filter inputs to continue")
                  }), React.createElement(CreateEditAlert$AlertFilterChips, {
                  filterSelections: filterSelections,
                  handleDeleteFilterChip: handleDeleteFilterChip
                }));
}

var OnlyShowErrorsWhere = {
  make: CreateEditAlert$OnlyShowErrorsWhere
};

function CreateEditAlert$CloseButton(Props) {
  var handleClick = Props.handleClick;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(IconButton.default, {
              size: "small",
              className: Css.merge(/* :: */[
                    className,
                    /* :: */[
                      Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.red)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]),
              onClick: (function (_e) {
                  return Curry._1(handleClick, undefined);
                }),
              children: React.createElement(Close.default, {
                    fontSize: "small"
                  })
            });
}

var CloseButton = {
  make: CreateEditAlert$CloseButton
};

function CreateEditAlert$AlertIntegrationsRow(Props) {
  var index = Props.index;
  var selectedIntegration = Props.selectedIntegration;
  var integrationSelections = Props.integrationSelections;
  var integrations = Props.integrations;
  var availableIntegrations = Props.availableIntegrations;
  var handleintegrationSelections = Props.handleintegrationSelections;
  var handleDeleteIntegrationRow = Props.handleDeleteIntegrationRow;
  return React.createElement("div", {
              className: flexRow
            }, React.createElement("span", {
                  className: Css.style(/* :: */[
                        Css.flexGrow(1),
                        /* [] */0
                      ])
                }, React.createElement(Select.default, {
                      value: selectedIntegration,
                      disabled: false,
                      onChange: (function ($$event, param) {
                          var value = $$event.target.value;
                          if (!(value == null)) {
                            return Curry._2(handleintegrationSelections, value, index);
                          }
                          
                        }),
                      input: React.createElement(OutlinedInput.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.width(Css.pct(100)),
                                    /* [] */0
                                  ])
                            },
                            labelWidth: 0
                          }),
                      classes: {
                        select: Css.merge(/* :: */[
                              inputItem,
                              /* :: */[
                                Css.style(/* :: */[
                                      Css.unsafe("width", "calc(100% - 20px)"),
                                      /* [] */0
                                    ]),
                                /* [] */0
                              ]
                            ])
                      },
                      autoWidth: true,
                      children: integrations.length !== 0 ? Belt_Array.map(Belt_SortArray.stableSortBy(integrations, (function (a1, a2) {
                                    return $$String.compare(a1.pluginId.toLowerCase(), a2.pluginId.toLowerCase());
                                  })), (function (integration) {
                                var matchedIntegration = Belt_Array.keep(availableIntegrations, (function (availableIntegration) {
                                        return availableIntegration.description.type_ === integration.pluginId;
                                      }));
                                var match_ = Belt_Array.get(matchedIntegration, 0);
                                var tmp;
                                if (match_ !== undefined) {
                                  var icon = match_.description.icon;
                                  tmp = icon !== undefined ? React.createElement(ListItemIcon.default, {
                                          classes: {
                                            root: Css.style(/* :: */[
                                                  Css.marginRight(Css.px(7)),
                                                  /* [] */0
                                                ])
                                          },
                                          children: React.createElement("img", {
                                                style: {
                                                  height: "20px",
                                                  padding: "0px",
                                                  width: "20px"
                                                },
                                                src: icon
                                              })
                                        }) : null;
                                } else {
                                  tmp = null;
                                }
                                return React.createElement(MenuItem.default, {
                                            disabled: Belt_Array.some(integrationSelections, (function (selection) {
                                                    return Caml_obj.caml_equal(selection, integration);
                                                  })),
                                            value: integration,
                                            children: null,
                                            key: integration.watcherName
                                          }, tmp, React.createElement(ListItemText.default, {
                                                classes: {
                                                  root: Css.style(/* :: */[
                                                        Css.padding(Css.px(0)),
                                                        /* [] */0
                                                      ])
                                                },
                                                children: I18N$BsConsole.showSkip(integration.watcherName)
                                              }));
                              })) : [React.createElement(MenuItem.default, {
                                children: I18N$BsConsole.show(undefined, "No options available")
                              })]
                    }), React.createElement(FormHelperText.default, {
                      children: I18N$BsConsole.show(undefined, "Add an integration")
                    })), React.createElement(CreateEditAlert$CloseButton, {
                  handleClick: (function (param) {
                      return Curry._1(handleDeleteIntegrationRow, index);
                    }),
                  className: Css.style(/* :: */[
                        Css.alignSelf(/* flexStart */662439529),
                        /* [] */0
                      ])
                }));
}

var AlertIntegrationsRow = {
  make: CreateEditAlert$AlertIntegrationsRow
};

function CreateEditAlert$AddRow(Props) {
  var title = Props.title;
  var action = Props.action;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.marginTop(Css.px(10)),
                    /* :: */[
                      Css.justifyContent(/* flexStart */662439529),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(Button.default, {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blue)),
                        /* [] */0
                      ]),
                  onClick: (function (param) {
                      return Curry._1(action, undefined);
                    }),
                  children: null
                }, React.createElement(AddCircleOutline.default, {
                      fontSize: "inherit"
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body2 */9,
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.blue)),
                            /* :: */[
                              Css.fontWeight(/* `num */[
                                    5496390,
                                    500
                                  ]),
                              /* :: */[
                                Css.fontSize(Css.rem(0.875)),
                                /* :: */[
                                  Css.paddingLeft(Css.px(3)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, title)
                    })));
}

var AddRow = {
  make: CreateEditAlert$AddRow
};

function CreateEditAlert$NotifyMeVia(Props) {
  var integrations = Props.integrations;
  var availableIntegrations = Props.availableIntegrations;
  var integrationSelections = Props.integrationSelections;
  var handleintegrationSelections = Props.handleintegrationSelections;
  var handleAddIntegrationRow = Props.handleAddIntegrationRow;
  var handleDeleteIntegrationRow = Props.handleDeleteIntegrationRow;
  return React.createElement("li", {
              className: li
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: inputTitle,
                  children: null
                }, React.createElement("span", {
                      className: circledNumbers
                    }, I18N$BsConsole.showSkip("3")), I18N$BsConsole.show(undefined, "Notify me via...")), Belt_Array.mapWithIndex(integrationSelections, (function (index, selectedIntegration) {
                    return React.createElement(CreateEditAlert$AlertIntegrationsRow, {
                                index: index,
                                selectedIntegration: selectedIntegration,
                                integrationSelections: integrationSelections,
                                integrations: integrations,
                                availableIntegrations: availableIntegrations,
                                handleintegrationSelections: handleintegrationSelections,
                                handleDeleteIntegrationRow: handleDeleteIntegrationRow
                              });
                  })), React.createElement(CreateEditAlert$AddRow, {
                  title: I18N$BsConsole.get(undefined, "Add new integration"),
                  action: handleAddIntegrationRow
                }));
}

var NotifyMeVia = {
  make: CreateEditAlert$NotifyMeVia
};

function CreateEditAlert$AlertFrequency(Props) {
  var alertFrequencyTimesAvailable = Props.alertFrequencyTimesAvailable;
  var alertFrequencySelection = Props.alertFrequencySelection;
  var handleFrequencySelection = Props.handleFrequencySelection;
  return React.createElement("li", {
              className: li
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: inputTitle,
                  children: I18N$BsConsole.show(undefined, "Alert frequency")
                }), React.createElement(Select.default, {
                  value: alertFrequencySelection,
                  onChange: (function ($$event, param) {
                      var value = $$event.target.value;
                      if (!(value == null)) {
                        return Curry._1(handleFrequencySelection, value);
                      }
                      
                    }),
                  input: React.createElement(OutlinedInput.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.width(Css.px(300)),
                                /* [] */0
                              ])
                        },
                        labelWidth: 0
                      }),
                  classes: {
                    select: Css.merge(/* :: */[
                          inputItem,
                          /* :: */[
                            Css.style(/* :: */[
                                  Css.unsafe("width", "calc(100% - 20px)"),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ])
                  },
                  autoWidth: true,
                  children: alertFrequencyTimesAvailable.length !== 0 ? Belt_Array.map(alertFrequencyTimesAvailable, (function (frequency) {
                            return React.createElement(MenuItem.default, {
                                        value: frequency,
                                        children: I18N$BsConsole.showSkip(typeToString(frequency)),
                                        key: typeToString(frequency)
                                      });
                          })) : [React.createElement(MenuItem.default, {
                            children: I18N$BsConsole.show(undefined, "No options available")
                          })]
                }));
}

var AlertFrequency = {
  make: CreateEditAlert$AlertFrequency
};

function CreateEditAlert$AlertExecutionDelay(Props) {
  var alertExecutionDelayTimesAvailable = Props.alertExecutionDelayTimesAvailable;
  var alertExecutionDelaySelection = Props.alertExecutionDelaySelection;
  var handleExecutionDelaySelection = Props.handleExecutionDelaySelection;
  return React.createElement("li", {
              className: li
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: inputTitle,
                  children: null
                }, I18N$BsConsole.show(undefined, "Alert execution delay"), React.createElement(Tooltip.default, {
                      classes: {
                        tooltip: Css.style(/* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.grey15)),
                              /* [] */0
                            ])
                      },
                      interactive: true,
                      children: React.createElement(Info.default, {
                            className: Css.style(/* :: */[
                                  Css.fontSize(Css.rem(1)),
                                  /* :: */[
                                    Css.marginLeft(Css.px(5)),
                                    /* [] */0
                                  ]
                                ])
                          }),
                      title: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Body1 */8,
                            className: Css.style(/* :: */[
                                  Css.lineHeight(Css.rem(1.375)),
                                  /* [] */0
                                ]),
                            children: null
                          }, React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.marginBottom(Css.rem(0.5)),
                                      /* [] */0
                                    ])
                              }, I18N$BsConsole.show(undefined, "Delay alerts to accommodate system dependencies like symbol downloads or fingerprint alterations.")), React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.marginBottom(Css.rem(0.5)),
                                      /* [] */0
                                    ])
                              }, I18N$BsConsole.show(undefined, "Consult support for optimal delay duration.")))
                    })), React.createElement(Select.default, {
                  value: alertExecutionDelaySelection,
                  onChange: (function ($$event, param) {
                      var value = $$event.target.value;
                      if (!(value == null)) {
                        return Curry._1(handleExecutionDelaySelection, value);
                      }
                      
                    }),
                  input: React.createElement(OutlinedInput.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.width(Css.px(300)),
                                /* [] */0
                              ])
                        },
                        labelWidth: 0
                      }),
                  classes: {
                    select: Css.merge(/* :: */[
                          inputItem,
                          /* :: */[
                            Css.style(/* :: */[
                                  Css.unsafe("width", "calc(100% - 20px)"),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ])
                  },
                  autoWidth: true,
                  children: alertExecutionDelayTimesAvailable.length !== 0 ? Belt_Array.map(alertExecutionDelayTimesAvailable, (function (executionDelay) {
                            return React.createElement(MenuItem.default, {
                                        value: executionDelay,
                                        children: I18N$BsConsole.showSkip(typeToString$1(executionDelay)),
                                        key: typeToString$1(executionDelay)
                                      });
                          })) : [React.createElement(MenuItem.default, {
                            children: I18N$BsConsole.show(undefined, "No options available")
                          })]
                }));
}

var AlertExecutionDelay = {
  make: CreateEditAlert$AlertExecutionDelay
};

function CreateEditAlert$SetConditions(Props) {
  var attributes = Props.attributes;
  var integrations = Props.integrations;
  var availableIntegrations = Props.availableIntegrations;
  var alertConditionNamesAvailable = Props.alertConditionNamesAvailable;
  var alertConditionTimesAvailable = Props.alertConditionTimesAvailable;
  var alertFrequencyTimesAvailable = Props.alertFrequencyTimesAvailable;
  var alertExecutionDelayTimesAvailable = Props.alertExecutionDelayTimesAvailable;
  var alertConditionSelection = Props.alertConditionSelection;
  var isConditionValid = Props.isConditionValid;
  var filterSelections = Props.filterSelections;
  var isFilterValid = Props.isFilterValid;
  var integrationSelections = Props.integrationSelections;
  var alertFrequencySelection = Props.alertFrequencySelection;
  var alertExecutionDelaySelection = Props.alertExecutionDelaySelection;
  var handleConditionSelection = Props.handleConditionSelection;
  var handleAddFilter = Props.handleAddFilter;
  var handleintegrationSelections = Props.handleintegrationSelections;
  var handleFrequencySelection = Props.handleFrequencySelection;
  var handleExecutionDelaySelection = Props.handleExecutionDelaySelection;
  var handleDeleteFilterChip = Props.handleDeleteFilterChip;
  var handleDeleteIntegrationRow = Props.handleDeleteIntegrationRow;
  var handleAddIntegrationRow = Props.handleAddIntegrationRow;
  var handleIsFilterValid = Props.handleIsFilterValid;
  var token = Props.token;
  var projectName = Props.projectName;
  return React.createElement("ul", {
              className: ul
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: bottomMargin,
                  children: I18N$BsConsole.show(undefined, "2. Set conditions")
                }), React.createElement(CreateEditAlert$TriggerAnAlertWhen, {
                  alertConditionNamesAvailable: alertConditionNamesAvailable,
                  alertConditionTimesAvailable: alertConditionTimesAvailable,
                  alertConditionSelection: alertConditionSelection,
                  handleConditionSelection: handleConditionSelection,
                  isConditionValid: isConditionValid
                }), React.createElement(CreateEditAlert$OnlyShowErrorsWhere, {
                  attributes: attributes,
                  filterSelections: filterSelections,
                  isFilterValid: isFilterValid,
                  handleAddFilter: handleAddFilter,
                  handleDeleteFilterChip: handleDeleteFilterChip,
                  handleIsFilterValid: handleIsFilterValid,
                  token: token,
                  projectName: projectName
                }), React.createElement(CreateEditAlert$NotifyMeVia, {
                  integrations: integrations,
                  availableIntegrations: availableIntegrations,
                  integrationSelections: integrationSelections,
                  handleintegrationSelections: handleintegrationSelections,
                  handleAddIntegrationRow: handleAddIntegrationRow,
                  handleDeleteIntegrationRow: handleDeleteIntegrationRow
                }), React.createElement(CreateEditAlert$AlertFrequency, {
                  alertFrequencyTimesAvailable: alertFrequencyTimesAvailable,
                  alertFrequencySelection: alertFrequencySelection,
                  handleFrequencySelection: handleFrequencySelection
                }), React.createElement(CreateEditAlert$AlertExecutionDelay, {
                  alertExecutionDelayTimesAvailable: alertExecutionDelayTimesAvailable,
                  alertExecutionDelaySelection: alertExecutionDelaySelection,
                  handleExecutionDelaySelection: handleExecutionDelaySelection
                }));
}

var SetConditions = {
  make: CreateEditAlert$SetConditions
};

function CreateEditAlert$PageButtons(Props) {
  var alertNameSelection = Props.alertNameSelection;
  var isFilterValid = Props.isFilterValid;
  var areAttributesInvalid = Props.areAttributesInvalid;
  var handleCancel = Props.handleCancel;
  var handleSave = Props.handleSave;
  return React.createElement("div", {
              className: buttonContainer
            }, React.createElement(Button.default, {
                  color: "primary",
                  onClick: (function (param) {
                      return Curry._1(handleCancel, undefined);
                    }),
                  children: I18N$BsConsole.show(undefined, "Cancel")
                }), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  className: saveButton,
                  disabled: alertNameSelection.trim() === "" || !isFilterValid || Curry._1(areAttributesInvalid, undefined),
                  onClick: (function (param) {
                      return Curry._1(handleSave, undefined);
                    }),
                  children: I18N$BsConsole.show(undefined, "Save alert")
                }));
}

var PageButtons = {
  make: CreateEditAlert$PageButtons
};

var initialState_alertConditionSelection = {
  name: /* Trace */1,
  timeFrame: undefined,
  value: undefined
};

var initialState_integrationSelections = [undefined];

var initialState_filterSelections = [];

var initialState_alertConditionNamesAvailable = ApiAlerts$BsConsole.Condition.Name.availableOptions;

var initialState_alertConditionTimesAvailable = ApiAlerts$BsConsole.Condition.TimeFrame.availableOptions;

var initialState$1 = {
  editRemote: /* NotAsked */0,
  errorMessage: undefined,
  alertNameSelection: "",
  alertConditionSelection: initialState_alertConditionSelection,
  integrationSelections: initialState_integrationSelections,
  alertFrequencySelection: /* OneMinute */0,
  alertExecutionDelaySelection: /* NoDelay */0,
  isConditionValid: true,
  filterSelections: initialState_filterSelections,
  isFilterValid: true,
  alertConditionNamesAvailable: initialState_alertConditionNamesAvailable,
  alertConditionTimesAvailable: initialState_alertConditionTimesAvailable,
  alertFrequencyTimesAvailable: frequencyTimesAvailable,
  alertExecutionDelayTimesAvailable: executionDelayTimesAvailable
};

function reducer$1(state, action) {
  switch (action.tag | 0) {
    case /* SetErrorMessage */0 :
        return {
                editRemote: state.editRemote,
                errorMessage: action[0],
                alertNameSelection: state.alertNameSelection,
                alertConditionSelection: state.alertConditionSelection,
                integrationSelections: state.integrationSelections,
                alertFrequencySelection: state.alertFrequencySelection,
                alertExecutionDelaySelection: state.alertExecutionDelaySelection,
                isConditionValid: state.isConditionValid,
                filterSelections: state.filterSelections,
                isFilterValid: state.isFilterValid,
                alertConditionNamesAvailable: state.alertConditionNamesAvailable,
                alertConditionTimesAvailable: state.alertConditionTimesAvailable,
                alertFrequencyTimesAvailable: state.alertFrequencyTimesAvailable,
                alertExecutionDelayTimesAvailable: state.alertExecutionDelayTimesAvailable
              };
    case /* SetAlertNameSelection */1 :
        return {
                editRemote: state.editRemote,
                errorMessage: state.errorMessage,
                alertNameSelection: action[0],
                alertConditionSelection: state.alertConditionSelection,
                integrationSelections: state.integrationSelections,
                alertFrequencySelection: state.alertFrequencySelection,
                alertExecutionDelaySelection: state.alertExecutionDelaySelection,
                isConditionValid: state.isConditionValid,
                filterSelections: state.filterSelections,
                isFilterValid: state.isFilterValid,
                alertConditionNamesAvailable: state.alertConditionNamesAvailable,
                alertConditionTimesAvailable: state.alertConditionTimesAvailable,
                alertFrequencyTimesAvailable: state.alertFrequencyTimesAvailable,
                alertExecutionDelayTimesAvailable: state.alertExecutionDelayTimesAvailable
              };
    case /* SetAlertConditionSelection */2 :
        return {
                editRemote: state.editRemote,
                errorMessage: state.errorMessage,
                alertNameSelection: state.alertNameSelection,
                alertConditionSelection: action[0],
                integrationSelections: state.integrationSelections,
                alertFrequencySelection: state.alertFrequencySelection,
                alertExecutionDelaySelection: state.alertExecutionDelaySelection,
                isConditionValid: state.isConditionValid,
                filterSelections: state.filterSelections,
                isFilterValid: state.isFilterValid,
                alertConditionNamesAvailable: state.alertConditionNamesAvailable,
                alertConditionTimesAvailable: state.alertConditionTimesAvailable,
                alertFrequencyTimesAvailable: state.alertFrequencyTimesAvailable,
                alertExecutionDelayTimesAvailable: state.alertExecutionDelayTimesAvailable
              };
    case /* SetConditionValidation */3 :
        return {
                editRemote: state.editRemote,
                errorMessage: state.errorMessage,
                alertNameSelection: state.alertNameSelection,
                alertConditionSelection: state.alertConditionSelection,
                integrationSelections: state.integrationSelections,
                alertFrequencySelection: state.alertFrequencySelection,
                alertExecutionDelaySelection: state.alertExecutionDelaySelection,
                isConditionValid: action[0],
                filterSelections: state.filterSelections,
                isFilterValid: state.isFilterValid,
                alertConditionNamesAvailable: state.alertConditionNamesAvailable,
                alertConditionTimesAvailable: state.alertConditionTimesAvailable,
                alertFrequencyTimesAvailable: state.alertFrequencyTimesAvailable,
                alertExecutionDelayTimesAvailable: state.alertExecutionDelayTimesAvailable
              };
    case /* SetFilterSelections */4 :
        return {
                editRemote: state.editRemote,
                errorMessage: state.errorMessage,
                alertNameSelection: state.alertNameSelection,
                alertConditionSelection: state.alertConditionSelection,
                integrationSelections: state.integrationSelections,
                alertFrequencySelection: state.alertFrequencySelection,
                alertExecutionDelaySelection: state.alertExecutionDelaySelection,
                isConditionValid: state.isConditionValid,
                filterSelections: action[0],
                isFilterValid: state.isFilterValid,
                alertConditionNamesAvailable: state.alertConditionNamesAvailable,
                alertConditionTimesAvailable: state.alertConditionTimesAvailable,
                alertFrequencyTimesAvailable: state.alertFrequencyTimesAvailable,
                alertExecutionDelayTimesAvailable: state.alertExecutionDelayTimesAvailable
              };
    case /* SetFilterValidation */5 :
        return {
                editRemote: state.editRemote,
                errorMessage: state.errorMessage,
                alertNameSelection: state.alertNameSelection,
                alertConditionSelection: state.alertConditionSelection,
                integrationSelections: state.integrationSelections,
                alertFrequencySelection: state.alertFrequencySelection,
                alertExecutionDelaySelection: state.alertExecutionDelaySelection,
                isConditionValid: state.isConditionValid,
                filterSelections: state.filterSelections,
                isFilterValid: action[0],
                alertConditionNamesAvailable: state.alertConditionNamesAvailable,
                alertConditionTimesAvailable: state.alertConditionTimesAvailable,
                alertFrequencyTimesAvailable: state.alertFrequencyTimesAvailable,
                alertExecutionDelayTimesAvailable: state.alertExecutionDelayTimesAvailable
              };
    case /* SetIntegrationSelections */6 :
        return {
                editRemote: state.editRemote,
                errorMessage: state.errorMessage,
                alertNameSelection: state.alertNameSelection,
                alertConditionSelection: state.alertConditionSelection,
                integrationSelections: action[0],
                alertFrequencySelection: state.alertFrequencySelection,
                alertExecutionDelaySelection: state.alertExecutionDelaySelection,
                isConditionValid: state.isConditionValid,
                filterSelections: state.filterSelections,
                isFilterValid: state.isFilterValid,
                alertConditionNamesAvailable: state.alertConditionNamesAvailable,
                alertConditionTimesAvailable: state.alertConditionTimesAvailable,
                alertFrequencyTimesAvailable: state.alertFrequencyTimesAvailable,
                alertExecutionDelayTimesAvailable: state.alertExecutionDelayTimesAvailable
              };
    case /* SetFrequencySelection */7 :
        return {
                editRemote: state.editRemote,
                errorMessage: state.errorMessage,
                alertNameSelection: state.alertNameSelection,
                alertConditionSelection: state.alertConditionSelection,
                integrationSelections: state.integrationSelections,
                alertFrequencySelection: action[0],
                alertExecutionDelaySelection: state.alertExecutionDelaySelection,
                isConditionValid: state.isConditionValid,
                filterSelections: state.filterSelections,
                isFilterValid: state.isFilterValid,
                alertConditionNamesAvailable: state.alertConditionNamesAvailable,
                alertConditionTimesAvailable: state.alertConditionTimesAvailable,
                alertFrequencyTimesAvailable: state.alertFrequencyTimesAvailable,
                alertExecutionDelayTimesAvailable: state.alertExecutionDelayTimesAvailable
              };
    case /* SetExecutionDelaySelection */8 :
        return {
                editRemote: state.editRemote,
                errorMessage: state.errorMessage,
                alertNameSelection: state.alertNameSelection,
                alertConditionSelection: state.alertConditionSelection,
                integrationSelections: state.integrationSelections,
                alertFrequencySelection: state.alertFrequencySelection,
                alertExecutionDelaySelection: action[0],
                isConditionValid: state.isConditionValid,
                filterSelections: state.filterSelections,
                isFilterValid: state.isFilterValid,
                alertConditionNamesAvailable: state.alertConditionNamesAvailable,
                alertConditionTimesAvailable: state.alertConditionTimesAvailable,
                alertFrequencyTimesAvailable: state.alertFrequencyTimesAvailable,
                alertExecutionDelayTimesAvailable: state.alertExecutionDelayTimesAvailable
              };
    case /* SetEditState */9 :
        var editState = action[0];
        return {
                editRemote: /* Success */Block.__(0, [undefined]),
                errorMessage: state.errorMessage,
                alertNameSelection: editState.alertNameSelection,
                alertConditionSelection: editState.alertConditionSelection,
                integrationSelections: editState.integrationSelections,
                alertFrequencySelection: editState.alertFrequencySelection,
                alertExecutionDelaySelection: editState.alertExecutionDelaySelection,
                isConditionValid: state.isConditionValid,
                filterSelections: editState.filterSelections,
                isFilterValid: state.isFilterValid,
                alertConditionNamesAvailable: state.alertConditionNamesAvailable,
                alertConditionTimesAvailable: state.alertConditionTimesAvailable,
                alertFrequencyTimesAvailable: state.alertFrequencyTimesAvailable,
                alertExecutionDelayTimesAvailable: state.alertExecutionDelayTimesAvailable
              };
    
  }
}

function CreateEditAlert(Props) {
  var alertState = Props.alertState;
  var config = Props.config;
  var token = Props.token;
  var projectName = Props.projectName;
  var integrations = Props.integrations;
  var availableIntegrations = Props.availableIntegrations;
  var handleRefresh = Props.handleRefresh;
  var handleCancel = Props.handleCancel;
  var match = React.useReducer(reducer$1, initialState$1);
  var dispatch = match[1];
  var match$1 = match[0];
  var isFilterValid = match$1.isFilterValid;
  var filterSelections = match$1.filterSelections;
  var alertExecutionDelaySelection = match$1.alertExecutionDelaySelection;
  var alertFrequencySelection = match$1.alertFrequencySelection;
  var integrationSelections = match$1.integrationSelections;
  var alertConditionSelection = match$1.alertConditionSelection;
  var alertNameSelection = match$1.alertNameSelection;
  var errorMessage = match$1.errorMessage;
  var invalidAttributeFound = function (filters) {
    return Belt_Array.some(filters, (function (filter) {
                  return Belt_Option.isSome(filter.invalidAttributeName);
                }));
  };
  var handleSave = function (param) {
    var isValidForm = function (param) {
      var match = alertConditionSelection.name;
      var match$1 = alertConditionSelection.timeFrame;
      var match$2 = alertConditionSelection.value;
      var match$3 = invalidAttributeFound(filterSelections);
      if (match >= 2 && !(match$1 !== undefined && match$2 !== undefined)) {
        Curry._1(dispatch, /* SetConditionValidation */Block.__(3, [false]));
        return false;
      }
      if (match$3) {
        Curry._1(dispatch, /* SetConditionValidation */Block.__(3, [false]));
        return false;
      } else {
        Curry._1(dispatch, /* SetConditionValidation */Block.__(3, [true]));
        return true;
      }
    };
    var match = isValidForm(undefined);
    if (!match) {
      return ;
    }
    if (alertState) {
      var onSuccess = function (param) {
        return Curry._1(handleRefresh, undefined);
      };
      var onFailure = function (err) {
        return Curry._1(dispatch, /* SetErrorMessage */Block.__(0, [err]));
      };
      var timeFrame = alertConditionSelection.timeFrame;
      var maybeTask = ApiAlerts$BsConsole.EditAlert.editAlertTask(String(alertState[0].id), alertNameSelection, {
            name: Curry._1(ApiAlerts$BsConsole.Condition.Name.typeToString, alertConditionSelection.name),
            timeFrame: timeFrame !== undefined ? Curry._1(ApiAlerts$BsConsole.Condition.TimeFrame.typeToMs, timeFrame) : undefined,
            value: alertConditionSelection.value
          }, formatFilter(filterSelections), typeToInt(alertFrequencySelection), typeToInt$1(alertExecutionDelaySelection), Belt_Array.map(Belt_Array.keepMap(integrationSelections, (function (integration) {
                      return integration;
                    })), (function (integration) {
                  return integration.id;
                })), config, projectName);
      if (maybeTask !== undefined) {
        return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiAlerts$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
      } else {
        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
      }
    }
    var onSuccess$1 = function (param) {
      return Curry._1(handleRefresh, undefined);
    };
    var onFailure$1 = function (err) {
      return Curry._1(dispatch, /* SetErrorMessage */Block.__(0, [err]));
    };
    var timeFrame$1 = alertConditionSelection.timeFrame;
    var maybeTask$1 = ApiAlerts$BsConsole.CreateAlert.createAlertTask(alertNameSelection, {
          name: Curry._1(ApiAlerts$BsConsole.Condition.Name.typeToString, alertConditionSelection.name),
          timeFrame: timeFrame$1 !== undefined ? Curry._1(ApiAlerts$BsConsole.Condition.TimeFrame.typeToMs, timeFrame$1) : undefined,
          value: alertConditionSelection.value
        }, formatFilter(filterSelections), typeToInt(alertFrequencySelection), typeToInt$1(alertExecutionDelaySelection), Belt_Array.map(Belt_Array.keepMap(integrationSelections, (function (integration) {
                    return integration;
                  })), (function (integration) {
                return integration.id;
              })), config, projectName);
    if (maybeTask$1 !== undefined) {
      return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask$1), undefined, (function (param) {
                    return ApiAlerts$BsConsole.responseCb(onSuccess$1, onFailure$1, param);
                  }));
    } else {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
    }
  };
  var match$2 = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var describeRemote = match$2[0];
  var attributes;
  attributes = typeof describeRemote === "number" || describeRemote.tag ? /* [] */0 : Belt_List.sort(describeRemote[0], (function (attr1, attr2) {
            return $$String.compare(attr1.name.toLowerCase(), attr2.name.toLowerCase());
          }));
  if (alertState) {
    var alert = alertState[0];
    if (match$1.editRemote === /* NotAsked */0 && Belt_List.length(attributes) > 0) {
      var timeFrame = alert.condition.timeFrame;
      var filters = alert.filters;
      var editInitialState_alertNameSelection = alert.name;
      var editInitialState_alertConditionSelection = {
        name: Curry._1(ApiAlerts$BsConsole.Condition.Name.stringToType, alert.condition.name),
        timeFrame: timeFrame !== undefined ? Curry._1(ApiAlerts$BsConsole.Condition.TimeFrame.msToType, timeFrame) : undefined,
        value: alert.condition.value
      };
      var editInitialState_integrationSelections = Belt_Array.map(Belt_Array.keep(integrations, (function (integration) {
                  return Belt_Array.some(alert.integrations, (function (alertIntegration) {
                                return alertIntegration.id === integration.id;
                              }));
                })), (function (integration) {
              return integration;
            }));
      var editInitialState_alertFrequencySelection = intToType(alert.frequency);
      var editInitialState_alertExecutionDelaySelection = intToType$1(alert.executionDelay);
      var editInitialState_filterSelections = filters !== undefined ? Belt_Array.map(filters, (function (filter) {
                var maybeAttribute = Belt_List.getBy(attributes, (function (attr) {
                        return attr.name === filter.attribute;
                      }));
                return {
                        type_: filter.type_,
                        attribute: maybeAttribute,
                        operator: Belt_Option.getWithDefault(Belt_Array.get(filter.filter, 0), ""),
                        value: Belt_Option.getWithDefault(Belt_Array.get(filter.filter, 1), ""),
                        invalidAttributeName: maybeAttribute !== undefined ? undefined : filter.attribute
                      };
              })) : [];
      var editInitialState = {
        alertNameSelection: editInitialState_alertNameSelection,
        alertConditionSelection: editInitialState_alertConditionSelection,
        integrationSelections: editInitialState_integrationSelections,
        alertFrequencySelection: editInitialState_alertFrequencySelection,
        alertExecutionDelaySelection: editInitialState_alertExecutionDelaySelection,
        filterSelections: editInitialState_filterSelections
      };
      Curry._1(dispatch, /* SetEditState */Block.__(9, [editInitialState]));
      return null;
    }
    
  }
  return React.createElement("div", {
              className: Css.merge(/* :: */[
                    rightLeftMargin,
                    /* :: */[
                      bottomMargin,
                      /* [] */0
                    ]
                  ])
            }, Belt_Option.isSome(errorMessage) ? React.createElement(WarningMessage$BsConsole.make, {
                    errorMessage: I18N$BsConsole.show(undefined, Belt_Option.getWithDefault(errorMessage, "Something went wrong. Please try again."))
                  }) : null, React.createElement(CreateEditAlert$AlertName, {
                  alertNameSelection: alertNameSelection,
                  handleNameSelection: (function (name) {
                      return Curry._1(dispatch, /* SetAlertNameSelection */Block.__(1, [name]));
                    })
                }), React.createElement(CreateEditAlert$SetConditions, {
                  attributes: attributes,
                  integrations: integrations,
                  availableIntegrations: availableIntegrations,
                  alertConditionNamesAvailable: match$1.alertConditionNamesAvailable,
                  alertConditionTimesAvailable: match$1.alertConditionTimesAvailable,
                  alertFrequencyTimesAvailable: match$1.alertFrequencyTimesAvailable,
                  alertExecutionDelayTimesAvailable: match$1.alertExecutionDelayTimesAvailable,
                  alertConditionSelection: alertConditionSelection,
                  isConditionValid: match$1.isConditionValid,
                  filterSelections: filterSelections,
                  isFilterValid: isFilterValid,
                  integrationSelections: integrationSelections,
                  alertFrequencySelection: alertFrequencySelection,
                  alertExecutionDelaySelection: alertExecutionDelaySelection,
                  handleConditionSelection: (function (condition) {
                      return Curry._1(dispatch, /* SetAlertConditionSelection */Block.__(2, [condition]));
                    }),
                  handleAddFilter: (function (filter) {
                      var newFilters = Belt_Array.concat(filterSelections, [{
                              type_: filter.type_,
                              attribute: filter.attribute,
                              operator: filter.operator,
                              value: filter.value,
                              invalidAttributeName: undefined
                            }]);
                      return Curry._1(dispatch, /* SetFilterSelections */Block.__(4, [newFilters]));
                    }),
                  handleintegrationSelections: (function (newIntegration, index) {
                      var editedIntegration = Belt_Array.mapWithIndex(integrationSelections, (function (idx, integration) {
                              if (idx === index) {
                                return newIntegration;
                              } else {
                                return integration;
                              }
                            }));
                      return Curry._1(dispatch, /* SetIntegrationSelections */Block.__(6, [editedIntegration]));
                    }),
                  handleFrequencySelection: (function (frequency) {
                      return Curry._1(dispatch, /* SetFrequencySelection */Block.__(7, [frequency]));
                    }),
                  handleExecutionDelaySelection: (function (executionDelay) {
                      return Curry._1(dispatch, /* SetExecutionDelaySelection */Block.__(8, [executionDelay]));
                    }),
                  handleDeleteFilterChip: (function (index) {
                      var rowDeleted = Belt_Array.keepWithIndex(filterSelections, (function (param, idx) {
                              return idx !== index;
                            }));
                      return Curry._1(dispatch, /* SetFilterSelections */Block.__(4, [rowDeleted]));
                    }),
                  handleDeleteIntegrationRow: (function (index) {
                      var rowDeleted = Belt_Array.keepWithIndex(integrationSelections, (function (param, idx) {
                              return idx !== index;
                            }));
                      return Curry._1(dispatch, /* SetIntegrationSelections */Block.__(6, [rowDeleted]));
                    }),
                  handleAddIntegrationRow: (function (param) {
                      var newRowAdded = Belt_Array.concat(integrationSelections, [undefined]);
                      return Curry._1(dispatch, /* SetIntegrationSelections */Block.__(6, [newRowAdded]));
                    }),
                  handleIsFilterValid: (function (isValid) {
                      return Curry._1(dispatch, /* SetFilterValidation */Block.__(5, [isValid]));
                    }),
                  token: token,
                  projectName: projectName
                }), React.createElement(CreateEditAlert$PageButtons, {
                  alertNameSelection: alertNameSelection,
                  isFilterValid: isFilterValid,
                  areAttributesInvalid: (function (param) {
                      return invalidAttributeFound(filterSelections);
                    }),
                  handleCancel: handleCancel,
                  handleSave: handleSave
                }));
}

var make = CreateEditAlert;

exports.Styles = Styles;
exports.Section = Section;
exports.SectionColumn = SectionColumn;
exports.Filter = Filter;
exports.Frequency = Frequency;
exports.ExecutionDelay = ExecutionDelay;
exports.Required = Required;
exports.AlertName = AlertName;
exports.TriggerAnAlertWhen = TriggerAnAlertWhen;
exports.FilterChip = FilterChip;
exports.AlertFilterChips = AlertFilterChips;
exports.AddChipButton = AddChipButton;
exports.AttributeValueInput = AttributeValueInput;
exports.AttributeValueInputAutocomplete = AttributeValueInputAutocomplete;
exports.AlertFilters = AlertFilters;
exports.OnlyShowErrorsWhere = OnlyShowErrorsWhere;
exports.CloseButton = CloseButton;
exports.AlertIntegrationsRow = AlertIntegrationsRow;
exports.AddRow = AddRow;
exports.NotifyMeVia = NotifyMeVia;
exports.AlertFrequency = AlertFrequency;
exports.AlertExecutionDelay = AlertExecutionDelay;
exports.SetConditions = SetConditions;
exports.PageButtons = PageButtons;
exports.initialState = initialState$1;
exports.reducer = reducer$1;
exports.make = make;
/* indicator Not a pure module */
