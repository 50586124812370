// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Icon = require("@material-ui/core/Icon");
var BtTypography$BsConsole = require("./BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var Info = require("@material-ui/icons/Info");
var Button = require("@material-ui/core/Button");
var Close = require("@material-ui/icons/Close");
var $$Storage = require("@material-ui/icons/Storage");
var Warning = require("@material-ui/icons/Warning");
var SubTextFormatter$BsConsole = require("./subscriptions/SubTextFormatter.js");
var IconButton = require("@material-ui/core/IconButton");
var MetricsNotification$BsConsole = require("./bs-metrics/MetricsNotification.js");
var CheckCircle = require("@material-ui/icons/CheckCircle");

var metricsNamespace = "banner/storage_usage/";

function StorageBanner(Props) {
  var totalStorageUsed = Props.totalStorageUsed;
  var storageLimit = Props.storageLimit;
  var storageRejected = Props.storageRejected;
  var handleChangeUrl = Props.handleChangeUrl;
  var close = Props.close;
  var percentageUsed = storageLimit <= 0.0 ? 100 : totalStorageUsed * 100 / storageLimit;
  React.useEffect((function () {
          MetricsNotification$BsConsole.Send.send(/* Impression */Block.__(2, [{
                    nameSpace: metricsNamespace,
                    details: "Storage usage at " + (String(percentageUsed) + "%")
                  }]));
          
        }), []);
  return React.createElement(Paper.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.width(Css.px(450)),
                      /* :: */[
                        Css.position(/* fixed */10615156),
                        /* :: */[
                          Css.top(Css.px(64)),
                          /* :: */[
                            Css.right(Css.px(0)),
                            /* :: */[
                              Css.margin(Css.px(15)),
                              /* :: */[
                                Css.padding(Css.px(25)),
                                /* :: */[
                                  Css.zIndex(1101),
                                  /* :: */[
                                    Css.display(/* flex */-1010954439),
                                    /* :: */[
                                      Css.flexDirection(/* column */-963948842),
                                      /* :: */[
                                        Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
                                        /* :: */[
                                          Css.color(Css.hex(Colors$BsConsole.grey2)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ])
              },
              square: true,
              elevation: 8,
              children: null
            }, React.createElement(IconButton.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.alignSelf(/* flexEnd */924268066),
                          /* :: */[
                            Css.fontSize(Css.px(18)),
                            /* :: */[
                              Css.padding(Css.px(4)),
                              /* :: */[
                                Css.width(Css.px(28)),
                                /* :: */[
                                  Css.height(Css.px(28)),
                                  /* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.black)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ])
                  },
                  onClick: (function (param) {
                      MetricsNotification$BsConsole.Send.send(/* DismissClick */Block.__(1, [{
                                nameSpace: metricsNamespace,
                                details: "x_out"
                              }]));
                      return Curry._1(close, undefined);
                    }),
                  children: React.createElement(Close.default, {
                        fontSize: "inherit"
                      })
                }), React.createElement(Icon.default, {
                  className: Css.style(/* :: */[
                        Css.alignSelf(/* flexStart */662439529),
                        /* :: */[
                          Css.marginBottom(Css.px(15)),
                          /* [] */0
                        ]
                      ]),
                  fontSize: "large",
                  children: React.createElement($$Storage.default, {
                        fontSize: "inherit"
                      })
                }), React.createElement("div", undefined, percentageUsed >= 100 ? React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading5 */4,
                        className: Css.style(/* :: */[
                              Css.fontWeight(/* bold */-1055161979),
                              /* :: */[
                                Css.marginBottom(Css.px(20)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.show(undefined, "You have reached your storage limit!")
                      }) : React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading5 */4,
                        className: Css.style(/* :: */[
                              Css.fontWeight(/* bold */-1055161979),
                              /* :: */[
                                Css.marginBottom(Css.px(20)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.show(undefined, "You are nearing your storage limit!")
                      }), React.createElement(Row2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.px(8)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(Info.default, {
                          fontSize: "inherit"
                        }), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body2 */9,
                          className: Css.style(/* :: */[
                                Css.margin(Css.px(7)),
                                /* :: */[
                                  Css.marginTop(Css.px(0)),
                                  /* [] */0
                                ]
                              ]),
                          children: null
                        }, Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                  /* String_literal */Block.__(11, [
                                      "You have purchased ",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* String_literal */Block.__(11, [
                                              " and consumed ",
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* String_literal */Block.__(11, [
                                                      ", and have ",
                                                      /* End_of_format */0
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ]),
                                  "You have purchased %s and consumed %s, and have "
                                ]), SubTextFormatter$BsConsole.bytes(storageLimit), SubTextFormatter$BsConsole.bytes(totalStorageUsed)), React.createElement("span", {
                              className: Css.style(/* :: */[
                                    Css.fontWeight(/* `num */[
                                          5496390,
                                          600
                                        ]),
                                    /* [] */0
                                  ])
                            }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* String_literal */Block.__(11, [
                                              " of rejected events.",
                                              /* End_of_format */0
                                            ])
                                        ]),
                                      "%s of rejected events."
                                    ]), SubTextFormatter$BsConsole.bytes(storageRejected))))), React.createElement(Row2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.px(8)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(Warning.default, {
                          fontSize: "inherit"
                        }), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body2 */9,
                          className: Css.style(/* :: */[
                                Css.margin(Css.px(7)),
                                /* :: */[
                                  Css.marginTop(Css.px(0)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Backtrace actively monitors storage quota. When quota is enforced, you will no longer receive crash and error data until you take action to manage your storage")
                        })), React.createElement(Row2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.px(8)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(CheckCircle.default, {
                          fontSize: "inherit"
                        }), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body2 */9,
                          className: Css.style(/* :: */[
                                Css.margin(Css.px(7)),
                                /* :: */[
                                  Css.marginTop(Css.px(0)),
                                  /* [] */0
                                ]
                              ]),
                          children: null
                        }, I18N$BsConsole.show(undefined, "Please review "), React.createElement("a", {
                              href: "https://docs.saucelabs.com/error-reporting/project-setup/storage-management/",
                              rel: "noopener noreferrer",
                              target: "_blank"
                            }, I18N$BsConsole.show(undefined, "Storage Management documentation")), I18N$BsConsole.show(undefined, " for details on how to adjust your consumed storage or purchase additional storage for your needs")))), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.marginTop(Css.px(15)),
                          /* :: */[
                            Css.marginBottom(Css.px(15)),
                            /* [] */0
                          ]
                        ]
                      ]),
                  onClick: (function (param) {
                      MetricsNotification$BsConsole.Send.send(/* CtaClick */Block.__(0, [{
                                nameSpace: metricsNamespace,
                                details: "increase_storage"
                              }]));
                      Curry._1(handleChangeUrl, /* SettingsSubscriptions */20);
                      return Curry._1(close, undefined);
                    }),
                  children: I18N$BsConsole.show(undefined, "Increase your account storage")
                }));
}

function make(totalStorageUsed, storageLimit, storageRejected, handleChangeUrl, close, children) {
  return ReasonReactCompat.wrapReactForReasonReact(StorageBanner, {
              totalStorageUsed: totalStorageUsed,
              storageLimit: storageLimit,
              storageRejected: storageRejected,
              handleChangeUrl: handleChangeUrl,
              close: close
            }, children);
}

var Jsx2 = {
  make: make
};

var make$1 = StorageBanner;

exports.metricsNamespace = metricsNamespace;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* Css Not a pure module */
