// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Col2$BsConsole = require("../../components/Col2.js");
var Href$BsConsole = require("../../Href.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Util$BsConsole = require("../../util.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Regex$BsConsole = require("../../Regex.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var Icon = require("@material-ui/core/Icon");
var Menu = require("@material-ui/core/Menu");
var BtTypography$BsConsole = require("../../BtTypography.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");
var Info = require("@material-ui/icons/Info");
var Configuration$BsConsole = require("../../configuration.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Clear = require("@material-ui/icons/Clear");
var Divider = require("@material-ui/core/Divider");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var IconButton = require("@material-ui/core/IconButton");
var CardContent = require("@material-ui/core/CardContent");
var ArrowUpward = require("@material-ui/icons/ArrowUpward");
var ArrowDownward = require("@material-ui/icons/ArrowDownward");
var CircularProgress = require("@material-ui/core/CircularProgress");

function toJsonSourceCodePaths(sourceCodePaths) {
  return Json_encode.list((function (scp) {
                return Json_encode.object_(/* :: */[
                            /* tuple */[
                              "path",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), scp.path)
                            ],
                            /* :: */[
                              /* tuple */[
                                "position",
                                scp.position
                              ],
                              /* [] */0
                            ]
                          ]);
              }), sourceCodePaths);
}

function fromJsonSourceCodePaths(json) {
  return {
          path: Json_decode.optional((function (param) {
                  return Json_decode.field("path", Json_decode.string, param);
                }), json),
          position: Json_decode.field("position", Json_decode.$$int, json)
        };
}

function fromJson(json) {
  return {
          id: Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.$$int, param);
                }), json),
          projectName: Json_decode.field("project_name", Json_decode.string, json),
          universeName: Json_decode.field("universe_name", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          host: Json_decode.field("host", Json_decode.string, json),
          port: Json_decode.field("port", Json_decode.$$int, json),
          changelistAttr: Json_decode.optional((function (param) {
                  return Json_decode.field("changelist_attribute", Json_decode.string, param);
                }), json),
          userName: Json_decode.field("user_name", Json_decode.string, json),
          password: Json_decode.optional((function (param) {
                  return Json_decode.field("password", Json_decode.string, param);
                }), json),
          sourceCodePaths: Json_decode.field("source_code_paths", (function (param) {
                  return Json_decode.list(fromJsonSourceCodePaths, param);
                }), json),
          connectionStatus: Json_decode.optional((function (param) {
                  return Json_decode.field("connection_status", Json_decode.string, param);
                }), json),
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", Json_decode.string, param);
                }), json)
        };
}

function toJson(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "id",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), t.id)
              ],
              /* :: */[
                /* tuple */[
                  "project_name",
                  t.projectName
                ],
                /* :: */[
                  /* tuple */[
                    "universe_name",
                    t.universeName
                  ],
                  /* :: */[
                    /* tuple */[
                      "name",
                      t.name
                    ],
                    /* :: */[
                      /* tuple */[
                        "host",
                        t.host
                      ],
                      /* :: */[
                        /* tuple */[
                          "port",
                          t.port
                        ],
                        /* :: */[
                          /* tuple */[
                            "changelist_attribute",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), t.changelistAttr)
                          ],
                          /* :: */[
                            /* tuple */[
                              "user_name",
                              t.userName
                            ],
                            /* :: */[
                              /* tuple */[
                                "password",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), t.password)
                              ],
                              /* :: */[
                                /* tuple */[
                                  "source_code_paths",
                                  toJsonSourceCodePaths(t.sourceCodePaths)
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "connection_status",
                                    Json_encode.nullable((function (prim) {
                                            return prim;
                                          }), t.connectionStatus)
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "error",
                                      Json_encode.nullable((function (prim) {
                                              return prim;
                                            }), t.error)
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var PerforceConnectionError = Caml_exceptions.create("Perforce-BsConsole.PerforceConnectionError");

function fetchPerforceServers(projectName, universeName, config) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + "/perforce_servers")))))]), undefined, /* Get */0, undefined, (function (param) {
                return /* Ok */Block.__(0, [Json_decode.field("perforce_servers", (function (param) {
                                  return Json_decode.list(fromJson, param);
                                }), param.json)]);
              }), undefined, undefined);
}

function decodeResp(param) {
  var json = param.json;
  try {
    return /* Ok */Block.__(0, [Json_decode.field("perforce_server", fromJson, json)]);
  }
  catch (parseErr){
    var errName = Json_decode.optional((function (param) {
            return Json_decode.field("error_name", Json_decode.string, param);
          }), json);
    if (Caml_obj.caml_equal(errName, "perforce_unable_to_connect")) {
      var errMsg = Json_decode.field("error_message", Json_decode.string, json);
      return /* Error */Block.__(1, [[
                  PerforceConnectionError,
                  errMsg
                ]]);
    }
    if (Caml_obj.caml_equal(errName, "perforce_connect_timeout")) {
      return /* Error */Block.__(1, [[
                  PerforceConnectionError,
                  I18N$BsConsole.get(undefined, "Timed out while connecting to host. Please check host and port and try again.")
                ]]);
    }
    throw parseErr;
  }
}

function saveServer(server, universeName, projectName, config) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + "/perforce_servers")))))]), undefined, /* Post */Block.__(0, [toJson(server)]), undefined, decodeResp, undefined, undefined);
}

function updateServer(server, id, universeName, projectName, config) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + ("/perforce_servers/" + (String(id) + "")))))))]), undefined, /* Put */Block.__(1, [toJson(server)]), undefined, decodeResp, undefined, undefined);
}

function deleteServer(id, universeName, projectName, config, param) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + ("/perforce_servers/" + (String(id) + "")))))))]), undefined, /* Delete */1, undefined, (function (param) {
                if (param.ok) {
                  return /* Ok */Block.__(0, [undefined]);
                } else {
                  return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                }
              }), undefined, undefined);
}

function sourceCodePath(path) {
  return Belt_Option.map(path, Regex$BsConsole.sourceCodePathValid) === true;
}

function sourceCodePaths(paths) {
  if (Belt_List.length(paths) === 0) {
    return false;
  } else {
    return Belt_List.every(paths, sourceCodePath);
  }
}

var Validate = {
  sourceCodePath: sourceCodePath,
  sourceCodePaths: sourceCodePaths
};

function Perforce$Create(Props) {
  var config = Props.config;
  var projectName = Props.projectName;
  var token = Props.token;
  var refetchRepositories = Props.refetchRepositories;
  var close = Props.close;
  var match = React.useState((function () {
          
        }));
  var setName = match[1];
  var name = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setHost = match$1[1];
  var host = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setPort = match$2[1];
  var port = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setUserName = match$3[1];
  var userName = match$3[0];
  var match$4 = React.useState((function () {
          
        }));
  var setPassword = match$4[1];
  var password = match$4[0];
  var match$5 = React.useState((function () {
          
        }));
  var setChangelistAttr = match$5[1];
  var changelistAttr = match$5[0];
  var match$6 = React.useState((function () {
          return /* :: */[
                  {
                    path: undefined,
                    position: 0
                  },
                  /* [] */0
                ];
        }));
  var setSourceCodePaths = match$6[1];
  var sourceCodePaths$1 = match$6[0];
  var match$7 = React.useState((function () {
          return false;
        }));
  var setProgress = match$7[1];
  var match$8 = React.useState((function () {
          
        }));
  var setErrorMsg = match$8[1];
  var errorMsg = match$8[0];
  var universeName = config.universe.name;
  var depotFormValid = function (param) {
    var match = sourceCodePaths(Belt_List.map(sourceCodePaths$1, (function (path) {
                return path.path;
              })));
    if (name !== undefined && host !== undefined && userName !== undefined && match) {
      return true;
    } else {
      return false;
    }
  };
  var supportLink = Href$BsConsole.Support.make(true, /* SettingsAttributes */21);
  return React.createElement(React.Fragment, undefined, React.createElement(IconButton.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.height(Css.px(48)),
                          /* :: */[
                            Css.position(/* absolute */-1013592457),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* :: */[
                                Css.top(/* zero */-789508312),
                                /* :: */[
                                  Css.right(/* zero */-789508312),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ])
                  },
                  color: "primary",
                  onClick: (function (_event) {
                      return Curry._1(close, undefined);
                    }),
                  children: React.createElement(Clear.default, { })
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  children: I18N$BsConsole.show(undefined, "Add Perforce server")
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                  className: Css.style(/* :: */[
                        Css.margin2(Css.rem(0.5), /* zero */-789508312),
                        /* :: */[
                          Css.height(Css.px(4)),
                          /* [] */0
                        ]
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Name")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.mapWithDefault(name, "", Util$BsConsole.identity),
                  onChange: (function ($$event) {
                      var name = BtSettings$BsConsole.getInputValue($$event);
                      return Curry._1(setName, (function (param) {
                                    if (name.length > 0) {
                                      return name;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Host")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.mapWithDefault(host, "", Util$BsConsole.identity),
                  onChange: (function ($$event) {
                      var host = BtSettings$BsConsole.getInputValue($$event);
                      return Curry._1(setHost, (function (param) {
                                    if (host.length > 0) {
                                      return host;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Port")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "1666",
                  value: Belt_Option.mapWithDefault(port, "1666", (function (prim) {
                          return String(prim);
                        })),
                  onChange: (function ($$event) {
                      var port = parseInt(BtSettings$BsConsole.getInputValue($$event), 10);
                      return Curry._1(setPort, (function (param) {
                                    if (Number.isFinite(port)) {
                                      return port;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Username")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.mapWithDefault(userName, "", Util$BsConsole.identity),
                  onChange: (function ($$event) {
                      var userName = BtSettings$BsConsole.getInputValue($$event);
                      return Curry._1(setUserName, (function (param) {
                                    if (userName.length > 0) {
                                      return userName;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Password (optional)")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.mapWithDefault(password, "", Util$BsConsole.identity),
                  onChange: (function ($$event) {
                      var password = BtSettings$BsConsole.getInputValue($$event);
                      return Curry._1(setPassword, (function (param) {
                                    if (password.length > 0) {
                                      return password;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ])
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* flexEnd */924268066,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* :: */[
                          Css.width(Css.pct(95)),
                          /* [] */0
                        ]
                      ]),
                  children: React.createElement(Col2$BsConsole.make, {
                        justifyContent: /* spaceBetween */516682146,
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.paddingRight(Css.rem(0.5)),
                                /* [] */0
                              ]
                            ]),
                        children: null
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Caption */11,
                            className: Css.style(/* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                  /* :: */[
                                    Css.lineHeight(Css.em(1.375)),
                                    /* [] */0
                                  ]
                                ]),
                            children: React.createElement(Row2$BsConsole.make, {
                                  alignItems: /* center */98248149,
                                  className: Css.style(/* :: */[
                                        Css.marginBottom(Css.rem(0.5)),
                                        /* [] */0
                                      ]),
                                  children: null
                                }, React.createElement("span", {
                                      className: Css.style(/* :: */[
                                            Css.marginRight(Css.rem(0.5)),
                                            /* [] */0
                                          ])
                                    }, I18N$BsConsole.show(undefined, "Source code paths")), React.createElement(Tooltip.default, {
                                      classes: {
                                        tooltip: Css.style(/* :: */[
                                              Css.backgroundColor(Css.hex(Colors$BsConsole.grey15)),
                                              /* [] */0
                                            ])
                                      },
                                      interactive: true,
                                      children: React.createElement(Info.default, {
                                            className: Css.style(/* :: */[
                                                  Css.fontSize(Css.rem(1)),
                                                  /* [] */0
                                                ])
                                          }),
                                      title: React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Body1 */8,
                                            className: Css.style(/* :: */[
                                                  Css.lineHeight(Css.em(1.375)),
                                                  /* [] */0
                                                ]),
                                            children: null
                                          }, React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.marginBottom(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.show(undefined, "Specify a list of depot paths to try, in order, when searching for matching source code.")), supportLink !== undefined ? React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.marginBottom(Css.em(0.5)),
                                                        /* [] */0
                                                      ])
                                                }, I18N$BsConsole.show(undefined, "You can specify an "), React.createElement("a", {
                                                      className: "link",
                                                      href: supportLink,
                                                      rel: "noreferrer",
                                                      target: "_blank"
                                                    }, I18N$BsConsole.show(undefined, "attribute")), I18N$BsConsole.show(undefined, " to be dynamically included in the path by using {attribute} template syntax.")) : null, React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.marginBottom(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.show(undefined, "For example, you might choose to first search \"//depot/releases/{version}/\" followed by \"//depot/develop/\". If the version attribute is set to '1.15' on the error that you are inspecting, then it will be used to create a search path of \"//depot/releases/1.15/\".")), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Some examples:")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("//depot/project/release/{version}")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("//depot/project/main/")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("//depot/project/develop/")))
                                    }))
                          }), Belt_List.toArray(Belt_List.mapWithIndex(Belt_List.sort(sourceCodePaths$1, (function (a, b) {
                                      return Caml_primitive.caml_int_compare(a.position, b.position);
                                    })), (function (idx, path) {
                                  return React.createElement(Row2$BsConsole.make, {
                                              alignItems: /* center */98248149,
                                              className: Css.style(/* :: */[
                                                    Css.width(Css.pct(100)),
                                                    /* :: */[
                                                      Css.marginBottom(Css.rem(0.5)),
                                                      /* [] */0
                                                    ]
                                                  ]),
                                              children: null
                                            }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                                                  placeholder: "//depot/project/src/",
                                                  value: Belt_Option.mapWithDefault(path.path, "", Util$BsConsole.identity),
                                                  onChange: (function ($$event) {
                                                      var newPath = BtSettings$BsConsole.getInputValue($$event);
                                                      return Curry._1(setSourceCodePaths, (function (param) {
                                                                    return Belt_List.mapWithIndex(sourceCodePaths$1, (function (i, path) {
                                                                                  if (i === idx) {
                                                                                    return {
                                                                                            path: newPath,
                                                                                            position: i
                                                                                          };
                                                                                  } else {
                                                                                    return path;
                                                                                  }
                                                                                }));
                                                                  }));
                                                    }),
                                                  error: Belt_Option.isSome(path.path) && !sourceCodePath(path.path),
                                                  className: Css.style(/* :: */[
                                                        Css.width(Css.pct(93)),
                                                        /* :: */[
                                                          Css.marginBottom(/* zero */-789508312),
                                                          /* :: */[
                                                            Css.marginTop(/* zero */-789508312),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ])
                                                }), React.createElement(Row2$BsConsole.make, {
                                                  alignItems: /* center */98248149,
                                                  className: Css.style(/* :: */[
                                                        Css.marginLeft(Css.rem(0.5)),
                                                        /* :: */[
                                                          Css.width(Css.pct(4)),
                                                          /* [] */0
                                                        ]
                                                      ]),
                                                  children: null
                                                }, React.createElement(IconButton.default, {
                                                      classes: {
                                                        root: Css.style(/* :: */[
                                                              Css.padding(Css.px(4)),
                                                              /* :: */[
                                                                Css.marginLeft(Css.rem(0.5)),
                                                                /* [] */0
                                                              ]
                                                            ])
                                                      },
                                                      onClick: (function (param) {
                                                          var oldPos = path.position;
                                                          var newPos = path.position - 1 | 0;
                                                          if (newPos < 0) {
                                                            return ;
                                                          }
                                                          var updatedPaths = Belt_List.map(sourceCodePaths$1, (function (path) {
                                                                  var match = newPos - path.position | 0;
                                                                  if (match !== -1) {
                                                                    if (match !== 0) {
                                                                      return path;
                                                                    } else {
                                                                      return {
                                                                              path: path.path,
                                                                              position: oldPos
                                                                            };
                                                                    }
                                                                  } else {
                                                                    return {
                                                                            path: path.path,
                                                                            position: newPos
                                                                          };
                                                                  }
                                                                }));
                                                          return Curry._1(setSourceCodePaths, (function (param) {
                                                                        return updatedPaths;
                                                                      }));
                                                        }),
                                                      children: React.createElement(ArrowUpward.default, { })
                                                    }), React.createElement(IconButton.default, {
                                                      classes: {
                                                        root: Css.style(/* :: */[
                                                              Css.padding(Css.px(4)),
                                                              /* :: */[
                                                                Css.marginLeft(Css.rem(0.5)),
                                                                /* [] */0
                                                              ]
                                                            ])
                                                      },
                                                      onClick: (function (param) {
                                                          var len = Belt_List.length(sourceCodePaths$1);
                                                          var oldPos = path.position;
                                                          var newPos = path.position + 1 | 0;
                                                          if (newPos === len) {
                                                            return ;
                                                          }
                                                          var updatedPaths = Belt_List.map(sourceCodePaths$1, (function (path) {
                                                                  var match = newPos - path.position | 0;
                                                                  if (match !== 0) {
                                                                    if (match !== 1) {
                                                                      return path;
                                                                    } else {
                                                                      return {
                                                                              path: path.path,
                                                                              position: newPos
                                                                            };
                                                                    }
                                                                  } else {
                                                                    return {
                                                                            path: path.path,
                                                                            position: oldPos
                                                                          };
                                                                  }
                                                                }));
                                                          return Curry._1(setSourceCodePaths, (function (param) {
                                                                        return updatedPaths;
                                                                      }));
                                                        }),
                                                      children: React.createElement(ArrowDownward.default, { })
                                                    }), idx !== 0 ? React.createElement(IconButton.default, {
                                                        classes: {
                                                          root: Css.style(/* :: */[
                                                                Css.padding(Css.px(4)),
                                                                /* :: */[
                                                                  Css.marginLeft(Css.rem(0.5)),
                                                                  /* [] */0
                                                                ]
                                                              ])
                                                        },
                                                        onClick: (function (param) {
                                                            var pos = path.position;
                                                            var updatedPaths = Belt_List.map(Belt_List.filter(sourceCodePaths$1, (function (path) {
                                                                        return path.position !== pos;
                                                                      })), (function (path) {
                                                                    if (path.position > pos) {
                                                                      return {
                                                                              path: path.path,
                                                                              position: path.position - 1 | 0
                                                                            };
                                                                    } else {
                                                                      return path;
                                                                    }
                                                                  }));
                                                            return Curry._1(setSourceCodePaths, (function (param) {
                                                                          return updatedPaths;
                                                                        }));
                                                          }),
                                                        children: React.createElement(Icon.default, {
                                                              children: I18N$BsConsole.showSkip("delete")
                                                            })
                                                      }) : null));
                                }))), React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            className: Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* :: */[
                                    Css.marginTop(Css.rem(0.25)),
                                    /* [] */0
                                  ]
                                ]),
                            children: React.createElement(Button.default, {
                                  variant: "outlined",
                                  color: "primary",
                                  onClick: (function (param) {
                                      return Curry._1(setSourceCodePaths, (function (paths) {
                                                    return Belt_List.concat(paths, /* :: */[
                                                                {
                                                                  path: undefined,
                                                                  position: Belt_List.length(paths)
                                                                },
                                                                /* [] */0
                                                              ]);
                                                  }));
                                    }),
                                  children: I18N$BsConsole.show(undefined, "Add path")
                                })
                          }))
                }), React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* [] */0
                        ]
                      ]),
                  children: React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        className: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(0.5)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement("span", {
                            className: Css.style(/* :: */[
                                  Css.marginRight(Css.rem(0.5)),
                                  /* [] */0
                                ])
                          }, I18N$BsConsole.show(undefined, "Changelist ID attribute (optional)")), React.createElement(Tooltip.default, {
                            classes: {
                              tooltip: Css.style(/* :: */[
                                    Css.backgroundColor(Css.hex(Colors$BsConsole.grey15)),
                                    /* [] */0
                                  ])
                            },
                            interactive: true,
                            children: React.createElement(Info.default, {
                                  className: Css.style(/* :: */[
                                        Css.fontSize(Css.rem(1)),
                                        /* [] */0
                                      ])
                                }),
                            title: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Body1 */8,
                                  className: Css.style(/* :: */[
                                        Css.lineHeight(Css.em(1.375)),
                                        /* [] */0
                                      ]),
                                  children: null
                                }, React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.marginBottom(Css.em(0.5)),
                                            /* [] */0
                                          ])
                                    }, I18N$BsConsole.show(undefined, "The name of the attribute which contains the changelist ID.")), React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.marginBottom(Css.em(0.5)),
                                            /* [] */0
                                          ])
                                    }, I18N$BsConsole.show(undefined, "When specified, source code lookups will be quicker and require less work by the Perforce server.")))
                          }))
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.getWithDefault(changelistAttr, ""),
                  onChange: (function ($$event) {
                      var newVal = BtSettings$BsConsole.getInputValue($$event);
                      var newVal$prime = newVal === "" ? undefined : newVal;
                      return Curry._1(setChangelistAttr, (function (param) {
                                    return newVal$prime;
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ])
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  children: null
                }, React.createElement(Button.default, {
                      variant: "contained",
                      color: "primary",
                      disabled: !depotFormValid(undefined),
                      onClick: (function (param) {
                          Curry._1(setProgress, (function (param) {
                                  return true;
                                }));
                          var match = Belt_List.length(sourceCodePaths$1) > 0;
                          var server = name !== undefined && host !== undefined && userName !== undefined && match ? ({
                                id: undefined,
                                projectName: projectName,
                                universeName: universeName,
                                name: name,
                                host: host,
                                port: Belt_Option.mapWithDefault(port, 1666, Util$BsConsole.identity),
                                changelistAttr: changelistAttr,
                                userName: userName,
                                password: password,
                                sourceCodePaths: sourceCodePaths$1,
                                connectionStatus: undefined,
                                error: undefined
                              }) : undefined;
                          if (server !== undefined) {
                            MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                    /* SourceCode_PerforceServerCreate */87,
                                    Json_encode.object_(/* :: */[
                                          /* tuple */[
                                            "project",
                                            projectName
                                          ],
                                          /* [] */0
                                        ])
                                  ]));
                            Curry._1(setErrorMsg, (function (param) {
                                    
                                  }));
                            Task2$BsConsole.handle(token, saveServer(server, universeName, projectName, config), undefined, (function (newServer) {
                                    Curry._1(setProgress, (function (param) {
                                            return false;
                                          }));
                                    if (newServer.tag) {
                                      var err = newServer[0];
                                      if (err[0] === PerforceConnectionError) {
                                        var errStr = err[1];
                                        return Curry._1(setErrorMsg, (function (param) {
                                                      return I18N$BsConsole.get(undefined, "Error connecting to Perforce server: ") + errStr;
                                                    }));
                                      }
                                      console.log(err);
                                      return Curry._1(setErrorMsg, (function (param) {
                                                    return I18N$BsConsole.get(undefined, "Encountered an error while saving perforce server information.");
                                                  }));
                                    }
                                    var match = newServer[0].id;
                                    if (match !== undefined) {
                                      Curry._1(close, undefined);
                                      return Curry._1(refetchRepositories, undefined);
                                    } else {
                                      return Curry._1(setErrorMsg, (function (param) {
                                                    return I18N$BsConsole.get(undefined, "Encountered an error while saving perforce server information.");
                                                  }));
                                    }
                                  }));
                            return ;
                          } else {
                            return Curry._1(setProgress, (function (param) {
                                          return false;
                                        }));
                          }
                        }),
                      children: I18N$BsConsole.show(undefined, "Create")
                    }), match$7[0] ? React.createElement(CircularProgress.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginLeft(Css.rem(1)),
                                /* [] */0
                              ])
                        }
                      }) : null, errorMsg !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading4 */3,
                        className: Css.style(/* :: */[
                              Css.marginTop(Css.px(3)),
                              /* :: */[
                                Css.fontSize(Css.px(18)),
                                /* :: */[
                                  Css.marginLeft(Css.rem(1)),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        color: Colors$BsConsole.errorDark,
                        children: I18N$BsConsole.dynamic(errorMsg)
                      }) : null));
}

var Create = {
  make: Perforce$Create
};

function Perforce$Edit(Props) {
  var server = Props.server;
  var config = Props.config;
  var projectName = Props.projectName;
  var token = Props.token;
  var refetchRepositories = Props.refetchRepositories;
  var close = Props.close;
  var match = React.useState((function () {
          return server.name;
        }));
  var setName = match[1];
  var name = match[0];
  var match$1 = React.useState((function () {
          return server.host;
        }));
  var setHost = match$1[1];
  var host = match$1[0];
  var match$2 = React.useState((function () {
          return server.port;
        }));
  var setPort = match$2[1];
  var port = match$2[0];
  var match$3 = React.useState((function () {
          return server.userName;
        }));
  var setUserName = match$3[1];
  var userName = match$3[0];
  var match$4 = React.useState((function () {
          
        }));
  var setPassword = match$4[1];
  var password = match$4[0];
  var match$5 = React.useState((function () {
          return server.sourceCodePaths;
        }));
  var setSourceCodePaths = match$5[1];
  var sourceCodePaths$1 = match$5[0];
  var match$6 = React.useState((function () {
          return false;
        }));
  var setProgress = match$6[1];
  var match$7 = React.useState((function () {
          
        }));
  var setErrorMsg = match$7[1];
  var errorMsg = match$7[0];
  var match$8 = React.useState((function () {
          return server.changelistAttr;
        }));
  var setChangelistAttr = match$8[1];
  var changelistAttr = match$8[0];
  var universeName = config.universe.name;
  var depotFormValid = function (param) {
    var match = sourceCodePaths(Belt_List.map(sourceCodePaths$1, (function (path) {
                return path.path;
              })));
    if (name !== undefined && host !== undefined && userName !== undefined && match) {
      return true;
    } else {
      return false;
    }
  };
  var supportLink = Href$BsConsole.Support.make(true, /* SettingsAttributes */21);
  return React.createElement(React.Fragment, undefined, React.createElement(IconButton.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.height(Css.px(48)),
                          /* :: */[
                            Css.position(/* absolute */-1013592457),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* :: */[
                                Css.top(/* zero */-789508312),
                                /* :: */[
                                  Css.right(/* zero */-789508312),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ])
                  },
                  color: "primary",
                  onClick: (function (_event) {
                      return Curry._1(close, undefined);
                    }),
                  children: React.createElement(Clear.default, { })
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  children: I18N$BsConsole.show(undefined, "Edit Perforce server")
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                  className: Css.style(/* :: */[
                        Css.margin2(Css.rem(0.5), /* zero */-789508312),
                        /* :: */[
                          Css.height(Css.px(4)),
                          /* [] */0
                        ]
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Name")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.mapWithDefault(name, "", Util$BsConsole.identity),
                  onChange: (function ($$event) {
                      var name = BtSettings$BsConsole.getInputValue($$event);
                      return Curry._1(setName, (function (param) {
                                    if (name.length > 0) {
                                      return name;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Host")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.mapWithDefault(host, "", Util$BsConsole.identity),
                  onChange: (function ($$event) {
                      var host = BtSettings$BsConsole.getInputValue($$event);
                      return Curry._1(setHost, (function (param) {
                                    if (host.length > 0) {
                                      return host;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Port")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "1666",
                  value: Belt_Option.mapWithDefault(port, "1666", (function (prim) {
                          return String(prim);
                        })),
                  onChange: (function ($$event) {
                      var port = parseInt(BtSettings$BsConsole.getInputValue($$event), 10);
                      return Curry._1(setPort, (function (param) {
                                    if (Number.isFinite(port)) {
                                      return port;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Username")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.mapWithDefault(userName, "", Util$BsConsole.identity),
                  onChange: (function ($$event) {
                      var userName = BtSettings$BsConsole.getInputValue($$event);
                      return Curry._1(setUserName, (function (param) {
                                    if (userName.length > 0) {
                                      return userName;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Password (optional)")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: I18N$BsConsole.get(undefined, "Update password (optional)"),
                  value: Belt_Option.mapWithDefault(password, "", Util$BsConsole.identity),
                  onChange: (function ($$event) {
                      var password = BtSettings$BsConsole.getInputValue($$event);
                      return Curry._1(setPassword, (function (param) {
                                    if (password.length > 0) {
                                      return password;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ])
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* flexEnd */924268066,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* :: */[
                          Css.width(Css.pct(95)),
                          /* [] */0
                        ]
                      ]),
                  children: React.createElement(Col2$BsConsole.make, {
                        justifyContent: /* spaceBetween */516682146,
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.paddingRight(Css.rem(0.5)),
                                /* [] */0
                              ]
                            ]),
                        children: null
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Caption */11,
                            className: Css.style(/* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                  /* :: */[
                                    Css.lineHeight(Css.em(1.375)),
                                    /* [] */0
                                  ]
                                ]),
                            children: React.createElement(Row2$BsConsole.make, {
                                  alignItems: /* center */98248149,
                                  className: Css.style(/* :: */[
                                        Css.marginBottom(Css.rem(0.5)),
                                        /* [] */0
                                      ]),
                                  children: null
                                }, React.createElement("span", {
                                      className: Css.style(/* :: */[
                                            Css.marginRight(Css.rem(0.5)),
                                            /* [] */0
                                          ])
                                    }, I18N$BsConsole.show(undefined, "Source code paths")), React.createElement(Tooltip.default, {
                                      classes: {
                                        tooltip: Css.style(/* :: */[
                                              Css.backgroundColor(Css.hex(Colors$BsConsole.grey15)),
                                              /* [] */0
                                            ])
                                      },
                                      interactive: true,
                                      children: React.createElement(Info.default, {
                                            className: Css.style(/* :: */[
                                                  Css.fontSize(Css.rem(1)),
                                                  /* [] */0
                                                ])
                                          }),
                                      title: React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Body1 */8,
                                            className: Css.style(/* :: */[
                                                  Css.lineHeight(Css.em(1.375)),
                                                  /* [] */0
                                                ]),
                                            children: null
                                          }, React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.marginBottom(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.show(undefined, "Specify a list of depot paths to try, in order, when searching for matching source code.")), supportLink !== undefined ? React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.marginBottom(Css.em(0.5)),
                                                        /* [] */0
                                                      ])
                                                }, I18N$BsConsole.show(undefined, "You can specify an "), React.createElement("a", {
                                                      className: "link",
                                                      href: supportLink,
                                                      rel: "noreferrer",
                                                      target: "_blank"
                                                    }, I18N$BsConsole.show(undefined, "attribute")), I18N$BsConsole.show(undefined, " to be dynamically included in the path by using {attribute} template syntax.")) : null, React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.marginBottom(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.show(undefined, "For example, you might choose to first search \"//depot/releases/{version}/\" followed by \"//depot/develop/\". If the version attribute is set to '1.15' on the error that you are inspecting, then it will be used to create a search path of \"//depot/releases/1.15/\".")), React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Some examples:")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("//depot/project/release/{version}")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("//depot/project/main/")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("//depot/project/develop/")))
                                    }))
                          }), Belt_List.toArray(Belt_List.mapWithIndex(Belt_List.sort(sourceCodePaths$1, (function (a, b) {
                                      return Caml_primitive.caml_int_compare(a.position, b.position);
                                    })), (function (idx, path) {
                                  return React.createElement(Row2$BsConsole.make, {
                                              alignItems: /* center */98248149,
                                              className: Css.style(/* :: */[
                                                    Css.width(Css.pct(100)),
                                                    /* :: */[
                                                      Css.marginBottom(Css.rem(0.5)),
                                                      /* [] */0
                                                    ]
                                                  ]),
                                              children: null
                                            }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                                                  placeholder: "//depot/project/src/",
                                                  value: Belt_Option.mapWithDefault(path.path, "", Util$BsConsole.identity),
                                                  onChange: (function ($$event) {
                                                      var newPath = BtSettings$BsConsole.getInputValue($$event);
                                                      return Curry._1(setSourceCodePaths, (function (param) {
                                                                    return Belt_List.mapWithIndex(sourceCodePaths$1, (function (i, path) {
                                                                                  if (i === idx) {
                                                                                    return {
                                                                                            path: newPath,
                                                                                            position: i
                                                                                          };
                                                                                  } else {
                                                                                    return path;
                                                                                  }
                                                                                }));
                                                                  }));
                                                    }),
                                                  error: Belt_Option.isSome(path.path) && !sourceCodePath(path.path),
                                                  className: Css.style(/* :: */[
                                                        Css.width(Css.pct(93)),
                                                        /* :: */[
                                                          Css.marginBottom(/* zero */-789508312),
                                                          /* :: */[
                                                            Css.marginTop(/* zero */-789508312),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ])
                                                }), React.createElement(Row2$BsConsole.make, {
                                                  alignItems: /* center */98248149,
                                                  className: Css.style(/* :: */[
                                                        Css.marginLeft(Css.rem(0.5)),
                                                        /* :: */[
                                                          Css.width(Css.pct(4)),
                                                          /* [] */0
                                                        ]
                                                      ]),
                                                  children: null
                                                }, React.createElement(IconButton.default, {
                                                      classes: {
                                                        root: Css.style(/* :: */[
                                                              Css.padding(Css.px(4)),
                                                              /* :: */[
                                                                Css.marginLeft(Css.rem(0.5)),
                                                                /* [] */0
                                                              ]
                                                            ])
                                                      },
                                                      onClick: (function (param) {
                                                          var oldPos = path.position;
                                                          var newPos = path.position - 1 | 0;
                                                          if (newPos < 0) {
                                                            return ;
                                                          }
                                                          var updatedPaths = Belt_List.map(sourceCodePaths$1, (function (path) {
                                                                  var match = newPos - path.position | 0;
                                                                  if (match !== -1) {
                                                                    if (match !== 0) {
                                                                      return path;
                                                                    } else {
                                                                      return {
                                                                              path: path.path,
                                                                              position: oldPos
                                                                            };
                                                                    }
                                                                  } else {
                                                                    return {
                                                                            path: path.path,
                                                                            position: newPos
                                                                          };
                                                                  }
                                                                }));
                                                          return Curry._1(setSourceCodePaths, (function (param) {
                                                                        return updatedPaths;
                                                                      }));
                                                        }),
                                                      children: React.createElement(ArrowUpward.default, { })
                                                    }), React.createElement(IconButton.default, {
                                                      classes: {
                                                        root: Css.style(/* :: */[
                                                              Css.padding(Css.px(4)),
                                                              /* :: */[
                                                                Css.marginLeft(Css.rem(0.5)),
                                                                /* [] */0
                                                              ]
                                                            ])
                                                      },
                                                      onClick: (function (param) {
                                                          var len = Belt_List.length(sourceCodePaths$1);
                                                          var oldPos = path.position;
                                                          var newPos = path.position + 1 | 0;
                                                          if (newPos === len) {
                                                            return ;
                                                          }
                                                          var updatedPaths = Belt_List.map(sourceCodePaths$1, (function (path) {
                                                                  var match = newPos - path.position | 0;
                                                                  if (match !== 0) {
                                                                    if (match !== 1) {
                                                                      return path;
                                                                    } else {
                                                                      return {
                                                                              path: path.path,
                                                                              position: newPos
                                                                            };
                                                                    }
                                                                  } else {
                                                                    return {
                                                                            path: path.path,
                                                                            position: oldPos
                                                                          };
                                                                  }
                                                                }));
                                                          return Curry._1(setSourceCodePaths, (function (param) {
                                                                        return updatedPaths;
                                                                      }));
                                                        }),
                                                      children: React.createElement(ArrowDownward.default, { })
                                                    }), idx !== 0 ? React.createElement(IconButton.default, {
                                                        classes: {
                                                          root: Css.style(/* :: */[
                                                                Css.padding(Css.px(4)),
                                                                /* :: */[
                                                                  Css.marginLeft(Css.rem(0.5)),
                                                                  /* [] */0
                                                                ]
                                                              ])
                                                        },
                                                        onClick: (function (param) {
                                                            var pos = path.position;
                                                            var updatedPaths = Belt_List.map(Belt_List.filter(sourceCodePaths$1, (function (path) {
                                                                        return path.position !== pos;
                                                                      })), (function (path) {
                                                                    if (path.position > pos) {
                                                                      return {
                                                                              path: path.path,
                                                                              position: path.position - 1 | 0
                                                                            };
                                                                    } else {
                                                                      return path;
                                                                    }
                                                                  }));
                                                            return Curry._1(setSourceCodePaths, (function (param) {
                                                                          return updatedPaths;
                                                                        }));
                                                          }),
                                                        children: React.createElement(Icon.default, {
                                                              children: I18N$BsConsole.showSkip("delete")
                                                            })
                                                      }) : null));
                                }))), React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            className: Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* :: */[
                                    Css.marginTop(Css.rem(0.25)),
                                    /* [] */0
                                  ]
                                ]),
                            children: React.createElement(Button.default, {
                                  variant: "outlined",
                                  color: "primary",
                                  onClick: (function (param) {
                                      return Curry._1(setSourceCodePaths, (function (paths) {
                                                    return Belt_List.concat(paths, /* :: */[
                                                                {
                                                                  path: undefined,
                                                                  position: Belt_List.length(paths)
                                                                },
                                                                /* [] */0
                                                              ]);
                                                  }));
                                    }),
                                  children: I18N$BsConsole.show(undefined, "Add path")
                                })
                          }))
                }), React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* [] */0
                        ]
                      ]),
                  children: React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        className: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(0.5)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement("span", {
                            className: Css.style(/* :: */[
                                  Css.marginRight(Css.rem(0.5)),
                                  /* [] */0
                                ])
                          }, I18N$BsConsole.show(undefined, "Changelist ID attribute (optional)")), React.createElement(Tooltip.default, {
                            classes: {
                              tooltip: Css.style(/* :: */[
                                    Css.backgroundColor(Css.hex(Colors$BsConsole.grey15)),
                                    /* [] */0
                                  ])
                            },
                            interactive: true,
                            children: React.createElement(Info.default, {
                                  className: Css.style(/* :: */[
                                        Css.fontSize(Css.rem(1)),
                                        /* [] */0
                                      ])
                                }),
                            title: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Body1 */8,
                                  className: Css.style(/* :: */[
                                        Css.lineHeight(Css.em(1.375)),
                                        /* [] */0
                                      ]),
                                  children: null
                                }, React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.marginBottom(Css.em(0.5)),
                                            /* [] */0
                                          ])
                                    }, I18N$BsConsole.show(undefined, "The name of the attribute which contains the changelist ID.")), React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.marginBottom(Css.em(0.5)),
                                            /* [] */0
                                          ])
                                    }, I18N$BsConsole.show(undefined, "When specified, source code lookups will be quicker and require less work by the Perforce server.")))
                          }))
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.getWithDefault(changelistAttr, ""),
                  onChange: (function ($$event) {
                      var newVal = BtSettings$BsConsole.getInputValue($$event);
                      var newVal$prime = newVal === "" ? undefined : newVal;
                      return Curry._1(setChangelistAttr, (function (param) {
                                    return newVal$prime;
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ])
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  children: null
                }, React.createElement(Button.default, {
                      variant: "contained",
                      color: "primary",
                      disabled: !depotFormValid(undefined),
                      onClick: (function (param) {
                          Curry._1(setProgress, (function (param) {
                                  return true;
                                }));
                          var match = Belt_List.length(sourceCodePaths$1) > 0;
                          var newServer = name !== undefined && host !== undefined && userName !== undefined && match ? ({
                                id: undefined,
                                projectName: projectName,
                                universeName: universeName,
                                name: name,
                                host: host,
                                port: Belt_Option.mapWithDefault(port, 1666, Util$BsConsole.identity),
                                changelistAttr: changelistAttr,
                                userName: userName,
                                password: password,
                                sourceCodePaths: sourceCodePaths$1,
                                connectionStatus: undefined,
                                error: undefined
                              }) : undefined;
                          var match$1 = server.id;
                          if (newServer !== undefined && match$1 !== undefined) {
                            MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                    /* SourceCode_PerforceServerEdit */88,
                                    Json_encode.object_(/* :: */[
                                          /* tuple */[
                                            "project",
                                            projectName
                                          ],
                                          /* [] */0
                                        ])
                                  ]));
                            Curry._1(setErrorMsg, (function (param) {
                                    
                                  }));
                            Task2$BsConsole.handle(token, updateServer(newServer, match$1, universeName, projectName, config), undefined, (function (savedServer) {
                                    Curry._1(setProgress, (function (param) {
                                            return false;
                                          }));
                                    if (savedServer.tag) {
                                      var err = savedServer[0];
                                      if (err[0] === PerforceConnectionError) {
                                        var errStr = err[1];
                                        return Curry._1(setErrorMsg, (function (param) {
                                                      return I18N$BsConsole.get(undefined, "Error connecting to Perforce server: ") + errStr;
                                                    }));
                                      }
                                      console.log(err);
                                      return Curry._1(setErrorMsg, (function (param) {
                                                    return I18N$BsConsole.get(undefined, "Encountered an error while saving perforce server information.");
                                                  }));
                                    }
                                    var match = savedServer[0].id;
                                    if (match !== undefined) {
                                      Curry._1(close, undefined);
                                      return Curry._1(refetchRepositories, undefined);
                                    } else {
                                      return Curry._1(setErrorMsg, (function (param) {
                                                    return I18N$BsConsole.get(undefined, "Encountered an error while saving perforce server information.");
                                                  }));
                                    }
                                  }));
                            return ;
                          }
                          return Curry._1(setProgress, (function (param) {
                                        return false;
                                      }));
                        }),
                      children: I18N$BsConsole.show(undefined, "Update")
                    }), match$6[0] ? React.createElement(CircularProgress.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginLeft(Css.rem(1)),
                                /* [] */0
                              ])
                        }
                      }) : null, errorMsg !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading4 */3,
                        className: Css.style(/* :: */[
                              Css.marginTop(Css.px(3)),
                              /* :: */[
                                Css.fontSize(Css.px(18)),
                                /* :: */[
                                  Css.marginLeft(Css.rem(1)),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        color: Colors$BsConsole.errorDark,
                        children: I18N$BsConsole.dynamic(errorMsg)
                      }) : null));
}

var Edit = {
  make: Perforce$Edit
};

function Perforce$Show(Props) {
  var server = Props.server;
  var config = Props.config;
  var projectName = Props.projectName;
  var token = Props.token;
  var refetchRepositories = Props.refetchRepositories;
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setShowEditForm = match$1[1];
  var match$2 = React.useState((function () {
          return false;
        }));
  var setShowDeleteDialog = match$2[1];
  var menuItemStyle = {
    root: Css.style(/* :: */[
          Css.paddingTop(Css.em(0.25)),
          /* :: */[
            Css.paddingBottom(Css.em(0.25)),
            /* [] */0
          ]
        ])
  };
  var status = server.connectionStatus;
  var attr = server.changelistAttr;
  var match$3 = server.error;
  var match$4 = server.connectionStatus;
  return React.createElement(Col2$BsConsole.make, {
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* spaceBetween */516682146,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Heading5 */4,
                      children: I18N$BsConsole.showSkip(server.name)
                    }), React.createElement(Row2$BsConsole.make, {
                      alignItems: /* baseline */287825029,
                      children: null
                    }, React.createElement(React.Fragment, undefined, status !== undefined ? (
                            status === "working" ? React.createElement(Tooltip.default, {
                                    children: React.createElement("span", {
                                          className: Css.style(/* :: */[
                                                Css.color(Css.hex(Colors$BsConsole.greenLight)),
                                                /* [] */0
                                              ])
                                        }, React.createElement(Icon.default, {
                                              children: I18N$BsConsole.showSkip("check_circle")
                                            })),
                                    title: I18N$BsConsole.get(undefined, "Successfully connected")
                                  }) : (
                                status === "not_working" ? React.createElement(Tooltip.default, {
                                        children: React.createElement("span", {
                                              className: Css.style(/* :: */[
                                                    Css.color(Css.hex(Colors$BsConsole.redDark)),
                                                    /* [] */0
                                                  ])
                                            }, React.createElement(Icon.default, {
                                                  children: I18N$BsConsole.showSkip("close")
                                                })),
                                        title: I18N$BsConsole.get(undefined, "Error while accessing server: ") + Belt_Option.mapWithDefault(server.error, "", Util$BsConsole.identity)
                                      }) : null
                              )
                          ) : null), React.createElement(Button.default, {
                          onClick: (function ($$event) {
                              var el = $$event.currentTarget;
                              if (!(el == null)) {
                                return Curry._1(setAnchorEl, (function (param) {
                                              return Caml_option.some(el);
                                            }));
                              }
                              
                            }),
                          children: React.createElement(Icon.default, {
                                children: I18N$BsConsole.showSkip("more_vert")
                              })
                        }))), React.createElement(Menu.default, {
                  disableAutoFocusItem: true,
                  classes: {
                    paper: Css.style(/* :: */[
                          Css.marginLeft(Css.rem(1.5)),
                          /* [] */0
                        ])
                  },
                  anchorEl: anchorEl,
                  open: Belt_Option.isSome(anchorEl),
                  onClose: (function (param) {
                      return Curry._1(setAnchorEl, (function (param) {
                                    
                                  }));
                    }),
                  children: null
                }, React.createElement(MenuItem.default, {
                      classes: menuItemStyle,
                      onClick: (function (param) {
                          Curry._1(setAnchorEl, (function (param) {
                                  
                                }));
                          return Curry._1(setShowEditForm, (function (param) {
                                        return true;
                                      }));
                        }),
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Caption */11,
                            children: I18N$BsConsole.show(undefined, "Edit")
                          })
                    }), React.createElement(MenuItem.default, {
                      classes: menuItemStyle,
                      onClick: (function (param) {
                          Curry._1(setShowDeleteDialog, (function (param) {
                                  return true;
                                }));
                          return Curry._1(setAnchorEl, (function (param) {
                                        
                                      }));
                        }),
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Caption */11,
                            children: I18N$BsConsole.show(undefined, "Delete")
                          })
                    })), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* :: */[
                          Css.height(Css.px(4)),
                          /* [] */0
                        ]
                      ])
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* flexStart */662439529,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.marginBottom(Css.rem(0.5)),
                          /* :: */[
                            Css.marginTop(Css.rem(0.5)),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.blackA54)),
                            /* [] */0
                          ]),
                      children: I18N$BsConsole.show(undefined, "Host")
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.marginLeft(Css.rem(2)),
                            /* :: */[
                              Css.overflow(/* hidden */-862584982),
                              /* :: */[
                                Css.unsafe("overflowWrap", "break-word"),
                                /* [] */0
                              ]
                            ]
                          ]),
                      color: Colors$BsConsole.grey1,
                      children: I18N$BsConsole.showSkip(server.host + (":" + String(server.port)))
                    })), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* flexStart */662439529,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.marginBottom(Css.rem(0.5)),
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.blackA54)),
                            /* [] */0
                          ]),
                      children: I18N$BsConsole.show(undefined, "User")
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.marginLeft(Css.rem(2)),
                            /* :: */[
                              Css.overflow(/* hidden */-862584982),
                              /* :: */[
                                Css.unsafe("overflowWrap", "break-word"),
                                /* [] */0
                              ]
                            ]
                          ]),
                      color: Colors$BsConsole.grey1,
                      children: I18N$BsConsole.showSkip(server.userName)
                    })), attr !== undefined ? React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    justifyContent: /* flexStart */662439529,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.5)),
                            /* [] */0
                          ]
                        ]),
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* [] */0
                            ]),
                        children: I18N$BsConsole.show(undefined, "Changelist attribute")
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.marginLeft(Css.rem(1.5)),
                              /* :: */[
                                Css.overflow(/* hidden */-862584982),
                                /* :: */[
                                  Css.unsafe("overflowWrap", "break-word"),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        color: Colors$BsConsole.grey1,
                        children: I18N$BsConsole.showSkip(attr)
                      })) : null, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* flexStart */662439529,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.marginBottom(Css.rem(0.5)),
                          /* [] */0
                        ]
                      ]),
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* [] */0
                            ]),
                        children: I18N$BsConsole.show(undefined, "Source code paths:")
                      })
                }), React.createElement("ul", {
                  className: Css.style(/* :: */[
                        Css.marginTop(/* zero */-789508312),
                        /* [] */0
                      ])
                }, Belt_List.toArray(Belt_List.map(Belt_List.sort(server.sourceCodePaths, (function (r, r$prime) {
                                return r.position - r$prime.position | 0;
                              })), (function (r) {
                            return React.createElement("li", {
                                        key: String(r.position)
                                      }, React.createElement("code", undefined, I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(r.path, "", Util$BsConsole.identity) + "\n")));
                          })))), match$3 !== undefined && match$4 === "not_working" ? React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body1 */8,
                    className: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(1)),
                          /* [] */0
                        ]),
                    color: Colors$BsConsole.errorDark,
                    children: I18N$BsConsole.dynamic(match$3)
                  }) : null, React.createElement(Dialog.default, {
                  open: match$1[0],
                  classes: {
                    paper: Css.style(/* :: */[
                          Css.width(Css.pct(90)),
                          /* :: */[
                            Css.unsafe("maxWidth", "unset"),
                            /* [] */0
                          ]
                        ]),
                    container: Css.style(/* :: */[
                          Css.alignItems(/* flexStart */662439529),
                          /* [] */0
                        ]),
                    root: Css.style(/* :: */[
                          Css.userSelect(/* none */-922086728),
                          /* [] */0
                        ])
                  },
                  onClose: (function (param) {
                      return Curry._1(setShowEditForm, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: React.createElement(CardContent.default, {
                        children: React.createElement(Perforce$Edit, {
                              server: server,
                              config: config,
                              projectName: projectName,
                              token: token,
                              refetchRepositories: refetchRepositories,
                              close: (function (param) {
                                  return Curry._1(setShowEditForm, (function (param) {
                                                return false;
                                              }));
                                }),
                              key: "-1"
                            })
                      })
                }), React.createElement(Dialog.default, {
                  open: match$2[0],
                  classes: {
                    paper: Css.style(/* :: */[
                          Css.minWidth(Css.px(400)),
                          /* :: */[
                            Css.maxWidth(Css.px(800)),
                            /* [] */0
                          ]
                        ]),
                    container: Css.style(/* :: */[
                          Css.alignItems(/* center */98248149),
                          /* [] */0
                        ]),
                    root: Css.style(/* :: */[
                          Css.userSelect(/* none */-922086728),
                          /* [] */0
                        ])
                  },
                  onClose: (function (_e) {
                      return Curry._1(setShowDeleteDialog, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: React.createElement(CardContent.default, {
                        children: null
                      }, React.createElement(IconButton.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.height(Css.px(48)),
                                    /* :: */[
                                      Css.position(/* absolute */-1013592457),
                                      /* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                        /* :: */[
                                          Css.top(/* zero */-789508312),
                                          /* :: */[
                                            Css.right(/* zero */-789508312),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ])
                            },
                            color: "primary",
                            onClick: (function (_event) {
                                return Curry._1(setShowDeleteDialog, (function (param) {
                                              return false;
                                            }));
                              }),
                            children: React.createElement(Clear.default, { })
                          }), React.createElement(Row2$BsConsole.make, {
                            className: Css.style(/* :: */[
                                  Css.marginTop(Css.rem(2)),
                                  /* :: */[
                                    Css.marginBottom(Css.rem(1.5)),
                                    /* [] */0
                                  ]
                                ]),
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Heading6 */5,
                                  children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Are you sure you want to delete perforce server") + (" \"" + (server.name + "\"?")))
                                })
                          }), React.createElement(Row2$BsConsole.make, {
                            justifyContent: /* flexEnd */924268066,
                            children: null
                          }, React.createElement(Button.default, {
                                color: "primary",
                                onClick: (function (_event) {
                                    return Curry._1(setShowDeleteDialog, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Cancel")
                              }), React.createElement(Button.default, {
                                variant: "raised",
                                color: "primary",
                                disabled: false,
                                onClick: (function (_event) {
                                    MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                            /* SourceCode_PerforceServerDelete */89,
                                            Json_encode.object_(/* :: */[
                                                  /* tuple */[
                                                    "project",
                                                    projectName
                                                  ],
                                                  /* [] */0
                                                ])
                                          ]));
                                    return Task2$BsConsole.handle(token, deleteServer(server.id, config.universe.name, projectName, config, undefined), undefined, (function (param) {
                                                  return Curry._1(refetchRepositories, undefined);
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Yes")
                              })))
                }));
}

var Show = {
  make: Perforce$Show
};

exports.toJsonSourceCodePaths = toJsonSourceCodePaths;
exports.fromJsonSourceCodePaths = fromJsonSourceCodePaths;
exports.fromJson = fromJson;
exports.toJson = toJson;
exports.PerforceConnectionError = PerforceConnectionError;
exports.fetchPerforceServers = fetchPerforceServers;
exports.decodeResp = decodeResp;
exports.saveServer = saveServer;
exports.updateServer = updateServer;
exports.deleteServer = deleteServer;
exports.Validate = Validate;
exports.Create = Create;
exports.Edit = Edit;
exports.Show = Show;
/* Css Not a pure module */
