// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var StorageFetch$BsConsole = require("../StorageFetch.js");

function timestampToString(timestamp) {
  return new Date(timestamp * 1000).toString();
}

function SystemUsageDistribution(Props) {
  var data = Props.data;
  var timestamp = Props.timestamp;
  Props.barHeight;
  var onItemClick = Props.onItemClick;
  var itemLabel = Props.itemLabel;
  var match = React.useState((function () {
          return [];
        }));
  var setChartData = match[1];
  var chartData = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setSelection = match$1[1];
  var selection = match$1[0];
  React.useEffect((function () {
          var valuesTotal = Belt_Array.reduce(Belt_Array.map(data, (function (prim) {
                      return prim[1];
                    })), 0, (function (prim, prim$1) {
                  return prim + prim$1;
                }));
          var chartData$prime = Belt_Array.reverse(Belt_List.toArray(Belt_List.sort(Belt_List.fromArray(Belt_Array.reduce(data, [], (function (acc, param) {
                                  var value = param[1];
                                  return Belt_Array.concat(acc, [{
                                                name: param[0],
                                                value: value,
                                                pct: Util$BsConsole.toPct(value, valuesTotal)
                                              }]);
                                }))), (function (a, b) {
                          return a.value - b.value | 0;
                        }))));
          Curry._1(setChartData, (function (param) {
                  return chartData$prime;
                }));
          Curry._1(setSelection, (function (param) {
                  
                }));
          
        }), [data]);
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.flexDirection(/* column */-963948842),
                      /* :: */[
                        Css.justifyContent(/* spaceAround */-485895757),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ])
            }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, /* center */98248149, undefined, /* nowrap */867913355, Css.style(/* [] */0), [ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* [] */0), [ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading6 */5, undefined, Css.style(/* [] */0), undefined, undefined, undefined, [Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                    /* String_literal */Block.__(11, [
                                                        "Total Usage: ",
                                                        /* String */Block.__(2, [
                                                            /* No_padding */0,
                                                            /* End_of_format */0
                                                          ])
                                                      ]),
                                                    "Total Usage: %s"
                                                  ]), Curry._1(StorageFetch$BsConsole.StorageData.Usage.toBytesString, StorageFetch$BsConsole.StorageData.total(data)))]))]))])), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.flexWrap(/* nowrap */867913355),
                            /* :: */[
                              Css.height(Css.px(20)),
                              /* :: */[
                                Css.marginTop(Css.px(5)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ])
                }, Belt_Array.mapWithIndex(chartData, (function (i, item) {
                        if (item.value < 1) {
                          return null;
                        } else {
                          return React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.height(Css.px(20)),
                                            /* :: */[
                                              Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey5)),
                                              /* :: */[
                                                Css.backgroundColor(Css.hex(Belt_Array.getExn(Colors$BsConsole.tags, Caml_int32.mod_(i, Colors$BsConsole.tags.length)))),
                                                /* :: */[
                                                  Css.unsafe("flex-grow", String(item.pct)),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]) + (
                                        selection !== undefined && i !== selection ? " " + Css.style(/* :: */[
                                                Css.opacity(0.35),
                                                /* [] */0
                                              ]) : ""
                                      ),
                                      onClick: (function (param) {
                                          return Curry._1(onItemClick, /* tuple */[
                                                      item.name,
                                                      item.value
                                                    ]);
                                        }),
                                      onMouseEnter: (function (param) {
                                          return Curry._1(setSelection, (function (param) {
                                                        return i;
                                                      }));
                                        }),
                                      onMouseLeave: (function (param) {
                                          return Curry._1(setSelection, (function (param) {
                                                        
                                                      }));
                                        })
                                    });
                        }
                      }))), itemLabel !== undefined ? ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Subtitle1 */6, undefined, Css.style(/* :: */[
                            Css.marginTop(Css.px(25)),
                            /* [] */0
                          ]), undefined, undefined, undefined, [I18N$BsConsole.dynamic(itemLabel)])) : null, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.flexDirection(/* row */5693978),
                          /* :: */[
                            Css.justifyContent(/* flexStart */662439529),
                            /* :: */[
                              Css.flexWrap(/* wrap */-822134326),
                              /* :: */[
                                Css.marginTop(Css.px(5)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ])
                }, Belt_Array.mapWithIndex(chartData, (function (i, item) {
                        return React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.width(Css.pct(25)),
                                          /* :: */[
                                            Css.display(/* flex */-1010954439),
                                            /* :: */[
                                              Css.alignItems(/* center */98248149),
                                              /* :: */[
                                                Css.paddingTop(Css.px(5)),
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]),
                                    onClick: (function (param) {
                                        return Curry._1(onItemClick, /* tuple */[
                                                    item.name,
                                                    item.value
                                                  ]);
                                      }),
                                    onMouseEnter: (function (param) {
                                        return Curry._1(setSelection, (function (param) {
                                                      return i;
                                                    }));
                                      }),
                                    onMouseLeave: (function (param) {
                                        return Curry._1(setSelection, (function (param) {
                                                      
                                                    }));
                                      })
                                  }, React.createElement("div", {
                                        className: Css.style(/* :: */[
                                              Css.height(Css.em(1)),
                                              /* :: */[
                                                Css.width(Css.em(1)),
                                                /* :: */[
                                                  Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey5)),
                                                  /* :: */[
                                                    Css.backgroundColor(Css.hex(Belt_Array.getExn(Colors$BsConsole.tags, Caml_int32.mod_(i, Colors$BsConsole.tags.length)))),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ])
                                      }), ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Caption */11, undefined, Css.style(/* :: */[
                                                Css.display(/* flex */-1010954439),
                                                /* :: */[
                                                  Css.alignItems(/* center */98248149),
                                                  /* :: */[
                                                    Css.marginLeft(Css.px(6)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]) + (
                                            selection !== undefined && i !== selection ? " " + Css.style(/* :: */[
                                                    Css.opacity(0.25),
                                                    /* [] */0
                                                  ]) : ""
                                          ), undefined, undefined, undefined, [Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                                      /* String */Block.__(2, [
                                                          /* No_padding */0,
                                                          /* String_literal */Block.__(11, [
                                                              ": ",
                                                              /* String */Block.__(2, [
                                                                  /* No_padding */0,
                                                                  /* End_of_format */0
                                                                ])
                                                            ])
                                                        ]),
                                                      "%s: %s"
                                                    ]), item.name, Curry._1(StorageFetch$BsConsole.StorageData.Usage.toBytesString, item.value))])));
                      }))), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.justifyContent(/* flexEnd */924268066),
                          /* :: */[
                            Css.marginTop(Css.px(5)),
                            /* [] */0
                          ]
                        ]
                      ])
                }, ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Caption */11, undefined, Css.style(/* :: */[
                              Css.marginTop(Css.px(25)),
                              /* [] */0
                            ]), undefined, undefined, undefined, [I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Last updated") + (": " + timestampToString(timestamp)))]))));
}

function make(data, barHeightOpt, onItemClick, timestamp, itemLabel, _children) {
  var barHeight = barHeightOpt !== undefined ? barHeightOpt : 20;
  return ReasonReactCompat.wrapReactForReasonReact(SystemUsageDistribution, {
              data: data,
              timestamp: timestamp,
              barHeight: barHeight,
              onItemClick: onItemClick,
              itemLabel: itemLabel
            }, _children);
}

var Re = {
  make: make
};

var make$1 = SystemUsageDistribution;

exports.timestampToString = timestampToString;
exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
