// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Colors$BsConsole = require("../../Colors.js");
var Close = require("@material-ui/icons/Close");
var IconButton = require("@material-ui/core/IconButton");

function JiraCloseIconButton(Props) {
  var handleClick = Props.handleClick;
  var classNameOpt = Props.className;
  var ariaLabelOpt = Props.ariaLabel;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var ariaLabel = ariaLabelOpt !== undefined ? ariaLabelOpt : "";
  return React.createElement(IconButton.default, {
              ariaLabel: ariaLabel,
              size: "small",
              className: Css.merge(/* :: */[
                    className,
                    /* :: */[
                      Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.red)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]),
              onClick: (function (_e) {
                  return Curry._1(handleClick, undefined);
                }),
              children: React.createElement(Close.default, {
                    fontSize: "small"
                  })
            });
}

var make = JiraCloseIconButton;

exports.make = make;
/* Css Not a pure module */
