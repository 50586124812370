// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Task$BsConsole = require("./task.js");
var Util$BsConsole = require("./util.js");
var Error$BsConsole = require("./error.js");
var Colors$BsConsole = require("./Colors.js");
var Option$BsConsole = require("./option.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var AuthForms$BsConsole = require("./auth/AuthForms.js");
var Card = require("@material-ui/core/Card");
var Bpg_response$BsConsole = require("./bpg_response.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var ViewContainer$BsConsole = require("./ViewContainer.js");
var Button = require("@material-ui/core/Button");
var TextField = require("@material-ui/core/TextField");
var CardContent = require("@material-ui/core/CardContent");
var CircularProgress = require("@material-ui/core/CircularProgress");
var BacktraceParserGenerator$BsConsole = require("./BacktraceParserGenerator.js");

function decode(json) {
  return Option$BsConsole.tryWith((function (param) {
                return {
                        token: Json_decode.field("token", Json_decode.string, json),
                        universe: Json_decode.at(/* :: */[
                                "universe",
                                /* :: */[
                                  "id",
                                  /* [] */0
                                ]
                              ], Json_decode.$$int)(json)
                      };
              }));
}

function use(username, password, handleTask) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setRemote = match[1];
  var remote = match[0];
  React.useEffect((function () {
          if (typeof remote === "number") {
            if (remote !== 0) {
              Curry._2(handleTask, (function (json) {
                      var t = decode(TaskCallback$BsConsole.getJson(json));
                      if (t !== undefined) {
                        return Curry._1(setRemote, (function (param) {
                                      return /* Success */Block.__(0, [t]);
                                    }));
                      }
                      var error = Error$BsConsole.HTTPD.decode(TaskCallback$BsConsole.getJson(json));
                      if (error !== undefined) {
                        return Curry._1(setRemote, (function (param) {
                                      return /* Failure */Block.__(1, [error.message]);
                                    }));
                      } else {
                        return Curry._1(setRemote, (function (param) {
                                      return /* Failure */Block.__(1, ["Something went wrong"]);
                                    }));
                      }
                    }), Task$BsConsole.makeTask(/* Login */Block.__(7, [
                          username,
                          password
                        ])));
            } else {
              Curry._1(setRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          }
          
        }), [remote]);
  return remote;
}

var TemporaryToken = {
  decode: decode,
  use: use
};

var grid = Css.style(/* :: */[
      Css.alignItems(/* center */98248149),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* [] */0
      ]
    ]);

var button = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var gridPadding = Css.style(/* :: */[
      Css.padding(Css.px(12)),
      /* [] */0
    ]);

var loadingContainer = Css.style(/* :: */[
      Css.height(Css.px(320)),
      /* [] */0
    ]);

var paper = Css.style(/* :: */[
      Css.padding(Css.px(16)),
      /* [] */0
    ]);

var card = Css.style(/* :: */[
      Css.width(Css.px(450)),
      /* [] */0
    ]);

var cardContent = Css.style(/* :: */[
      Css.important(Css.padding4(Css.px(32), Css.px(32), Css.px(16), Css.px(32))),
      /* [] */0
    ]);

var logoContainer = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.px(16)),
      /* :: */[
        Css.marginBottom(Css.px(32)),
        /* [] */0
      ]
    ]);

var logo = Css.style(/* :: */[
      Css.height(Css.px(32)),
      /* [] */0
    ]);

var Style = {
  grid: grid,
  button: button,
  gridPadding: gridPadding,
  loadingContainer: loadingContainer,
  paper: paper,
  card: card,
  cardContent: cardContent,
  logoContainer: logoContainer,
  logo: logo
};

function createBpgJson(username, universeID, nextPassword) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "type",
                "configuration/users"
              ],
              /* :: */[
                /* tuple */[
                  "action",
                  "modify"
                ],
                /* :: */[
                  /* tuple */[
                    "key",
                    Json_encode.object_(/* :: */[
                          /* tuple */[
                            "universe",
                            universeID
                          ],
                          /* :: */[
                            /* tuple */[
                              "username",
                              username
                            ],
                            /* [] */0
                          ]
                        ])
                  ],
                  /* :: */[
                    /* tuple */[
                      "fields",
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "password",
                              Util$BsConsole.stringToHex(nextPassword)
                            ],
                            /* [] */0
                          ])
                    ],
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

var R = Bpg_response$BsConsole.Make({
      table: BacktraceParserGenerator$BsConsole.Users.table,
      to_json: BacktraceParserGenerator$BsConsole.Users.to_json,
      of_json: BacktraceParserGenerator$BsConsole.Users.of_json,
      Key: BacktraceParserGenerator$BsConsole.Users.Key,
      to_key: BacktraceParserGenerator$BsConsole.Users.to_key
    });

function CreatePage$Loading(Props) {
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.display(/* flex */-1010954439),
                      /* :: */[
                        Css.justifyContent(/* center */98248149),
                        /* [] */0
                      ]
                    ]
                  ])
            }, React.createElement(CircularProgress.default, {
                  size: 100,
                  color: "primary"
                }));
}

var Loading = {
  make: CreatePage$Loading
};

function CreatePage$InputForm(Props) {
  var token = Props.token;
  var universeID = Props.universeID;
  var username = Props.username;
  var handleTask = Props.handleTask;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = React.useState((function () {
          return "";
        }));
  var setNewPassword1 = match[1];
  var newPassword1 = match[0];
  var match$1 = React.useState((function () {
          return "";
        }));
  var setNewPassword2 = match$1[1];
  var newPassword2 = match$1[0];
  var match$2 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setCreateRemote = match$2[1];
  var createRemote = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setError = match$3[1];
  var error = match$3[0];
  React.useEffect((function () {
          if (typeof createRemote === "number" && createRemote !== 0) {
            var json = createBpgJson(username, universeID, newPassword1);
            Curry._2(handleTask, (function (json) {
                    var __x = Bpg_response$BsConsole.head(TaskCallback$BsConsole.getJson(json));
                    var match = Belt_Option.mapWithDefault(__x, R.defaultError, R.decode);
                    if (!match.tag) {
                      return Curry._1(setCreateRemote, (function (param) {
                                    return /* Success */Block.__(0, [/* [] */0]);
                                  }));
                    }
                    var msg = match[0];
                    return Curry._1(setCreateRemote, (function (param) {
                                  return /* Failure */Block.__(1, [msg]);
                                }));
                  }), Task$BsConsole.makeTask(/* BPG */Block.__(9, [
                        token,
                        /* :: */[
                          json,
                          /* [] */0
                        ]
                      ])));
          }
          
        }), [createRemote]);
  React.useEffect((function () {
          if (error !== undefined) {
            var match = AuthForms$BsConsole.genericPasswordValidator(newPassword1);
            var match$1 = newPassword1 === newPassword2;
            var newError = match !== undefined ? match : (
                match$1 ? undefined : I18N$BsConsole.get(undefined, "Passwords must match")
              );
            Curry._1(setError, (function (param) {
                    return newError;
                  }));
          }
          
        }), /* tuple */[
        newPassword1,
        newPassword2
      ]);
  if (typeof createRemote === "number") {
    if (createRemote === /* NotAsked */0) {
      return React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.marginTop(Css.px(24)),
                        /* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.flexDirection(/* column */-963948842),
                            /* [] */0
                          ]
                        ]
                      ])
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Heading4 */3,
                      children: I18N$BsConsole.show(undefined, "Set your password")
                    }), error !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Caption */11,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.redDark)),
                              /* :: */[
                                Css.marginTop(Css.rem(1)),
                                /* [] */0
                              ]
                            ]),
                        children: I18N$BsConsole.showSkip(error)
                      }) : null, React.createElement(TextField.default, {
                      autoFocus: true,
                      placeholder: I18N$BsConsole.get(undefined, "password"),
                      value: newPassword1,
                      color: "primary",
                      type: "password",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginTop(Css.rem(1)),
                              /* [] */0
                            ])
                      },
                      onChange: (function ($$event) {
                          var value = $$event.target.value;
                          return Curry._1(setNewPassword1, (function (param) {
                                        return value;
                                      }));
                        }),
                      onBlur: (function (param) {
                          return Curry._1(setError, (function (param) {
                                        return AuthForms$BsConsole.genericPasswordValidator(newPassword1);
                                      }));
                        })
                    }), React.createElement(TextField.default, {
                      placeholder: I18N$BsConsole.get(undefined, "confirm password"),
                      value: newPassword2,
                      color: "primary",
                      type: "password",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginTop(Css.rem(1)),
                              /* [] */0
                            ])
                      },
                      onChange: (function ($$event) {
                          var value = $$event.target.value;
                          return Curry._1(setNewPassword2, (function (param) {
                                        return value;
                                      }));
                        }),
                      onBlur: (function (param) {
                          if (newPassword2 === newPassword1) {
                            return ;
                          } else {
                            return Curry._1(setError, (function (param) {
                                          return I18N$BsConsole.get(undefined, "Passwords must match");
                                        }));
                          }
                        })
                    }), React.createElement(Button.default, {
                      variant: "contained",
                      color: "primary",
                      disabled: Belt_Option.isSome(error) || !(newPassword1 !== "" && newPassword2 !== "") || newPassword1 !== newPassword2,
                      type: "submit",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginTop(Css.px(24)),
                              /* [] */0
                            ])
                      },
                      onClick: (function (param) {
                          return Curry._1(setCreateRemote, (function (param) {
                                        return /* Loading */1;
                                      }));
                        }),
                      children: I18N$BsConsole.show(undefined, "Set password")
                    }));
    } else {
      return React.createElement(CreatePage$Loading, { });
    }
  } else if (createRemote.tag) {
    return React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.marginTop(Css.px(24)),
                      /* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.flexDirection(/* column */-963948842),
                          /* [] */0
                        ]
                      ]
                    ])
              }, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading4 */3,
                    children: I18N$BsConsole.show(undefined, "Error")
                  }), React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Caption */11,
                    children: I18N$BsConsole.showSkip(createRemote[0])
                  }), React.createElement(Button.default, {
                    variant: "contained",
                    color: "primary",
                    type: "submit",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginTop(Css.px(24)),
                            /* [] */0
                          ])
                    },
                    onClick: (function (param) {
                        return Curry._1(setCreateRemote, (function (param) {
                                      return /* NotAsked */0;
                                    }));
                      }),
                    children: I18N$BsConsole.show(undefined, "Try again")
                  }));
  } else {
    return React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.marginTop(Css.px(24)),
                      /* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.flexDirection(/* column */-963948842),
                          /* [] */0
                        ]
                      ]
                    ])
              }, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading4 */3,
                    children: I18N$BsConsole.show(undefined, "Success!")
                  }), React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Caption */11,
                    children: I18N$BsConsole.show(undefined, "Your user is now set up for Backtrace")
                  }), React.createElement(Button.default, {
                    variant: "contained",
                    color: "primary",
                    type: "submit",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginTop(Css.px(24)),
                            /* [] */0
                          ])
                    },
                    onClick: (function (param) {
                        return Curry._1(handleChangeUrl, /* Login */Block.__(3, [{
                                        referrer: undefined,
                                        token: undefined,
                                        redirect: undefined,
                                        method: undefined
                                      }]));
                      }),
                    children: I18N$BsConsole.show(undefined, "Back to login")
                  }));
  }
}

var InputForm = {
  make: CreatePage$InputForm
};

function CreatePage(Props) {
  var handleTask = Props.handleTask;
  var handleChangeUrl = Props.handleChangeUrl;
  var password = Props.password;
  var username = Props.username;
  var tempTokenRemote = use(username, password, handleTask);
  var tmp;
  if (typeof tempTokenRemote === "number") {
    tmp = tempTokenRemote === /* NotAsked */0 ? React.createElement(CreatePage$Loading, { }) : React.createElement(CreatePage$Loading, { });
  } else if (tempTokenRemote.tag) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
              variant: /* Heading5 */4,
              className: Css.style(/* :: */[
                    Css.marginTop(Css.rem(1)),
                    /* [] */0
                  ]),
              children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                        /* String_literal */Block.__(11, [
                            "Error: ",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* End_of_format */0
                              ])
                          ]),
                        "Error: %s"
                      ]), tempTokenRemote[0])
            }), React.createElement(BtTypography$BsConsole.make, {
              variant: /* Subtitle1 */6,
              className: Css.style(/* :: */[
                    Css.color(Css.hex(Colors$BsConsole.grey3)),
                    /* [] */0
                  ]),
              children: I18N$BsConsole.show(undefined, "Please contact your Backtrace I/O administrator")
            }));
  } else {
    var tempToken = tempTokenRemote[0];
    tmp = React.createElement(CreatePage$InputForm, {
          token: tempToken.token,
          universeID: tempToken.universe,
          username: username,
          handleTask: handleTask,
          handleChangeUrl: handleChangeUrl
        });
  }
  return React.createElement(ViewContainer$BsConsole.make, {
              children: React.createElement(Card.default, {
                    className: card,
                    children: React.createElement(CardContent.default, {
                          className: cardContent,
                          children: null
                        }, React.createElement(Row2$BsConsole.make, {
                              alignItems: /* center */98248149,
                              justifyContent: /* center */98248149,
                              className: logoContainer,
                              children: React.createElement("img", {
                                    className: logo,
                                    src: "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg"
                                  })
                            }), tmp)
                  }),
              className: Css.merge(/* :: */[
                    grid,
                    /* :: */[
                      "login-container",
                      /* [] */0
                    ]
                  ])
            });
}

function make(handleTask, handleChangeUrl, password, username, children) {
  return ReasonReactCompat.wrapReactForReasonReact(CreatePage, {
              handleTask: handleTask,
              handleChangeUrl: handleChangeUrl,
              password: password,
              username: username
            }, children);
}

var Re = {
  make: make
};

var make$1 = CreatePage;

exports.TemporaryToken = TemporaryToken;
exports.Style = Style;
exports.createBpgJson = createBpgJson;
exports.R = R;
exports.Loading = Loading;
exports.InputForm = InputForm;
exports.make = make$1;
exports.Re = Re;
/* grid Not a pure module */
