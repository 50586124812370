// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Slate = require("slate");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Body1$BsConsole = require("../components/text/Body1.js");
var Colors$BsConsole = require("../Colors.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var Subtitle2$BsConsole = require("../components/text/Subtitle2.js");
var CommentContent$BsConsole = require("./CommentContent.js");
var L10N_date_time$BsConsole = require("../L10N_date_time.js");

var component = RR$BsConsole.reducerComponent("Comment-BsConsole");

function make(edited, comment, commenter, activeUser, allUsers, onEditComment, onEditingSaved, onEditingCancelled, onDeleteComment, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var match = param.state;
              var username = commenter.username;
              var value = edited ? match.editorValue : Slate.Value.fromJSON(comment.contents);
              var match$1 = activeUser.uid === commenter.uid;
              var tmp = { };
              var tmp$1 = edited ? Css.style(/* :: */[
                      Css.padding2(Css.px(12), Css.px(8)),
                      /* :: */[
                        Css.border(Css.px(1), Css.solid, Css.hex(Colors$BsConsole.black)),
                        /* :: */[
                          Css.borderRadius(Css.px(8)),
                          /* [] */0
                        ]
                      ]
                    ]) : undefined;
              if (tmp$1 !== undefined) {
                tmp.className = Caml_option.valFromOption(tmp$1);
              }
              return React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.display(/* flex */-1010954439),
                                /* :: */[
                                  Css.marginBottom(Css.rem(1)),
                                  /* :: */[
                                    Css.alignItems(Css.center),
                                    /* [] */0
                                  ]
                                ]
                              ])
                        }, React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                    /* :: */[
                                      Css.paddingRight(Css.px(16)),
                                      /* [] */0
                                    ]
                                  ])
                            }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Chat.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.padding4(Css.px(8), Css.px(8), Css.px(20), Css.px(4)),
                                    /* :: */[
                                      Css.backgroundColor(edited ? Css.hex(Colors$BsConsole.npLightYellow) : Css.hex(Colors$BsConsole.white)),
                                      /* :: */[
                                        Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                        /* :: */[
                                          Css.borderRadius(Css.px(4)),
                                          /* :: */[
                                            Css.flexGrow(1),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]),
                              onMouseEnter: (function (param) {
                                  return Curry._1(send, /* MouseEnter */1);
                                }),
                              onMouseLeave: (function (param) {
                                  return Curry._1(send, /* MouseLeave */2);
                                })
                            }, React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.display(/* flex */-1010954439),
                                        /* [] */0
                                      ])
                                }, React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.padding2(Css.px(8), Css.px(8)),
                                            /* [] */0
                                          ])
                                    }, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Avatar.Jsx2.make, undefined, Css.style(/* :: */[
                                                  Css.width(Css.px(24)),
                                                  /* :: */[
                                                    Css.height(Css.px(24)),
                                                    /* :: */[
                                                      Css.fontSize(Css.rem(1)),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]), [I18N$BsConsole.showSkip(username.charAt(0).toUpperCase())]))), React.createElement("div", {
                                      className: Css.style(/* :: */[
                                            Css.flexGrow(1),
                                            /* [] */0
                                          ])
                                    }, React.createElement("div", {
                                          className: Css.style(/* :: */[
                                                Css.display(/* flex */-1010954439),
                                                /* :: */[
                                                  Css.justifyContent(Css.spaceBetween),
                                                  /* [] */0
                                                ]
                                              ])
                                        }, React.createElement("div", {
                                              className: Css.style(/* :: */[
                                                    Css.display(/* flex */-1010954439),
                                                    /* :: */[
                                                      Css.paddingBottom(Css.px(18)),
                                                      /* :: */[
                                                        Css.height(Css.px(48)),
                                                        /* :: */[
                                                          Css.alignItems(Css.flexEnd),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ])
                                            }, ReasonReact.element(undefined, undefined, Curry._5(Subtitle2$BsConsole.make, undefined, username, /* Grey */1, undefined, [])), ReasonReact.element(undefined, undefined, Curry._5(Body1$BsConsole.make, Css.style(/* :: */[
                                                          Css.paddingLeft(Css.px(12)),
                                                          /* [] */0
                                                        ]), L10N_date_time$BsConsole.Format.textAbbreviatedDay(undefined, comment.timestamp), /* Primary */4, undefined, [])), comment.edited ? React.createElement("div", {
                                                    className: Css.style(/* :: */[
                                                          Css.paddingLeft(Css.px(8)),
                                                          /* :: */[
                                                            Css.fontSize(Css.rem(0.75)),
                                                            /* :: */[
                                                              Css.color(Css.hex(Colors$BsConsole.blackA60)),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ])
                                                  }, I18N$BsConsole.show(undefined, "(edited)")) : null), match$1 && match.hovered && !edited ? React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.justifySelf(Css.flexEnd),
                                                      /* :: */[
                                                        Css.alignSelf(Css.flexStart),
                                                        /* :: */[
                                                          Css.display(/* flex */-1010954439),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ])
                                              }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                        undefined,
                                                        undefined,
                                                        (function (param) {
                                                            return Curry._1(send, /* StartEditing */0);
                                                          }),
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Edit.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                      ])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                        undefined,
                                                        undefined,
                                                        (function (param) {
                                                            return Curry._1(onDeleteComment, undefined);
                                                          }),
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Delete.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                      ]))) : null), React.createElement("div", {
                                          className: Css.style(/* :: */[
                                                Css.paddingRight(Css.px(16)),
                                                /* [] */0
                                              ])
                                        }, React.createElement("div", tmp, ReasonReact.element(undefined, undefined, CommentContent$BsConsole.make(undefined, (function (ref) {
                                                        return Curry._1(send, /* SetEditorRef */Block.__(0, [ref]));
                                                      }), edited, allUsers, activeUser, value, (function (newValue) {
                                                        return Curry._1(send, /* SetEditorValue */Block.__(1, [newValue]));
                                                      }), [])))), edited ? React.createElement("div", {
                                            className: Css.style(/* :: */[
                                                  Css.display(/* flex */-1010954439),
                                                  /* :: */[
                                                    Css.paddingTop(Css.px(24)),
                                                    /* [] */0
                                                  ]
                                                ])
                                          }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    undefined,
                                                    (function (param) {
                                                        return Curry._1(onEditingCancelled, undefined);
                                                      }),
                                                    undefined,
                                                    undefined,
                                                    /* Outlined */-28821822,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [I18N$BsConsole.show(undefined, "Cancel")]
                                                  ])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    Css.style(/* :: */[
                                                          Css.marginLeft(Css.px(8)),
                                                          /* [] */0
                                                        ]),
                                                    (function (param) {
                                                        return Curry._2(onEditingSaved, comment.id, value);
                                                      }),
                                                    undefined,
                                                    undefined,
                                                    /* Outlined */-28821822,
                                                    /* Primary */-791844958,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [I18N$BsConsole.show(undefined, "Save changes")]
                                                  ]))) : null))));
            }),
          initialState: (function (param) {
              return {
                      hovered: false,
                      editorRef: {
                        contents: undefined
                      },
                      editorValue: Slate.Value.fromJSON(comment.contents)
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case /* StartEditing */0 :
                      return /* UpdateWithSideEffects */Block.__(2, [
                                {
                                  hovered: state.hovered,
                                  editorRef: state.editorRef,
                                  editorValue: Slate.Value.fromJSON(comment.contents)
                                },
                                (function (param) {
                                    setTimeout((function (param) {
                                            var rf = state.editorRef.contents;
                                            if (rf === undefined) {
                                              return ;
                                            }
                                            var rf$1 = Caml_option.valFromOption(rf);
                                            rf$1.focus();
                                            return rf$1.moveFocusToEndOfDocument();
                                          }), 0);
                                    return Curry._1(onEditComment, comment);
                                  })
                              ]);
                  case /* MouseEnter */1 :
                      return /* Update */Block.__(0, [{
                                  hovered: true,
                                  editorRef: state.editorRef,
                                  editorValue: state.editorValue
                                }]);
                  case /* MouseLeave */2 :
                      return /* Update */Block.__(0, [{
                                  hovered: false,
                                  editorRef: state.editorRef,
                                  editorValue: state.editorValue
                                }]);
                  
                }
              } else {
                if (action.tag) {
                  return /* Update */Block.__(0, [{
                              hovered: state.hovered,
                              editorRef: state.editorRef,
                              editorValue: action[0]
                            }]);
                }
                var nullableRef = action[0];
                return /* SideEffects */Block.__(1, [(function (param) {
                              param.state.editorRef.contents = (nullableRef == null) ? undefined : Caml_option.some(nullableRef);
                              
                            })]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
