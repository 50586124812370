// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Lazy = require("bs-platform/lib/js/lazy.js");
var Block = require("bs-platform/lib/js/block.js");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Int = require("bs-platform/lib/js/belt_Int.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Util$BsConsole = require("../../util.js");
var BugThread$BsConsole = require("./BugThread.js");
var BugSourceCode$BsConsole = require("./BugSourceCode.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var BugMemoryRegion$BsConsole = require("./BugMemoryRegion.js");

function fromJson(json) {
  return Json_decode.oneOf(/* :: */[
              (function (json) {
                  return {
                          name: Json_decode.field("name", Json_decode.string, json),
                          objectIds: Json_decode.field("object_ids", (function (param) {
                                  return Json_decode.array((function (param) {
                                                
                                              }), param);
                                }), json)
                        };
                }),
              /* :: */[
                (function (json) {
                    var name = Json_decode.string(json);
                    return {
                            name: name,
                            objectIds: []
                          };
                  }),
                /* [] */0
              ]
            ], json);
}

var Classifier = {
  fromJson: fromJson
};

function decodeAnnotations(annotationsDict) {
  var annotations = [];
  var globalAnnotations = [];
  Belt_Array.forEach(Js_dict.entries(annotationsDict), (function (param) {
          var annotationsJson = param[1];
          var key = param[0];
          var match = (annotationsJson == null);
          var match$1 = Belt_Int.fromString(key);
          if (match) {
            return ;
          } else if (match$1 !== undefined) {
            if (match$1 !== 0) {
              return Belt_Array.forEach(annotationsJson, (function (annotationJson) {
                            var text = Json_decode.field("text", Json_decode.string, annotationJson);
                            var match$2 = Json_decode.field("type", Json_decode.string, annotationJson);
                            switch (match$2) {
                              case "Comment" :
                                  annotations.push({
                                        typ: /* Comment */0,
                                        text: text,
                                        variableId: match$1
                                      });
                                  return ;
                              case "Critical" :
                                  annotations.push({
                                        typ: /* Critical */2,
                                        text: text,
                                        variableId: match$1
                                      });
                                  return ;
                              case "JSON" :
                              case "JSONPP" :
                                  return ;
                              case "Warning" :
                                  annotations.push({
                                        typ: /* Warning */1,
                                        text: text,
                                        variableId: match$1
                                      });
                                  return ;
                              default:
                                throw [
                                      Caml_builtin_exceptions.assert_failure,
                                      /* tuple */[
                                        "BugError.re",
                                        66,
                                        17
                                      ]
                                    ];
                            }
                          }));
            } else {
              return Belt_Array.forEachWithIndex(annotationsJson, (function (idx, annotationJson) {
                            var text = Json_decode.field("text", Json_decode.string, annotationJson);
                            var match = Json_decode.field("type", Json_decode.string, annotationJson);
                            switch (match) {
                              case "JSON" :
                                  globalAnnotations.push({
                                        key: /* `Idx */[
                                          3652637,
                                          idx
                                        ],
                                        json: Lazy.from_fun((function (param) {
                                                return JSON.parse(text);
                                              }))
                                      });
                                  return ;
                              case "Comment" :
                              case "Critical" :
                              case "Warning" :
                                  return ;
                              default:
                                throw [
                                      Caml_builtin_exceptions.assert_failure,
                                      /* tuple */[
                                        "BugError.re",
                                        53,
                                        17
                                      ]
                                    ];
                            }
                          }));
            }
          } else {
            globalAnnotations.push({
                  key: /* `Key */[
                    3752319,
                    key
                  ],
                  json: Lazy.from_val(annotationsJson)
                });
            return ;
          }
        }));
  return /* tuple */[
          annotations,
          globalAnnotations
        ];
}

function fromJson$1(fingerprint, errorId, bugFormat, json) {
  var annotationsDict = Json_decode.field("annotations", (function (param) {
          return Json_decode.dict((function (prim) {
                        return prim;
                      }), param);
        }), json);
  var match = decodeAnnotations(annotationsDict);
  var threads = Belt_SortArray.stableSortBy(Json_decode.field("threads", (function (prim) {
              return prim;
            }), json), (function (t1, t2) {
          var match = t1.faulted;
          var match$1 = t2.faulted;
          if (match) {
            if (match$1) {
              return 0;
            } else {
              return -1;
            }
          } else if (match$1) {
            return 1;
          } else {
            return 0;
          }
        }));
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("classifiers", (function (param) {
                        return Json_decode.array(fromJson, param);
                      }), param);
        }), json);
  return {
          fingerprint: fingerprint,
          errorId: errorId,
          uuid: Json_decode.field("uuid", Json_decode.string, json),
          time: DateFns.parseString(undefined, Json_decode.field("time", Json_decode.string, json)),
          attributes: Json_decode.field("keys", (function (param) {
                  return Json_decode.dict(Util$BsConsole.identity, param);
                }), json),
          memoryMap: Json_decode.field("pmap", (function (param) {
                  return Json_decode.array(BugMemoryRegion$BsConsole.fromJson, param);
                }), json),
          classifiers: Belt_Option.getWithDefault(__x, []),
          threads: threads,
          threadGroups: BugThread$BsConsole.Btt.groupThreads(threads),
          variableAnnotations: match[0],
          annotationsDict: annotationsDict,
          globalAnnotations: match[1],
          system: Json_decode.optional((function (__x) {
                  return Json_decode.field("st", (function (param) {
                                return Json_decode.dict(Util$BsConsole.identity, param);
                              }), __x);
                }), json),
          globalVariables: Json_decode.optional((function (__x) {
                  return Json_decode.field("global_variables", (function (param) {
                                return Json_decode.array((function (prim) {
                                              return prim;
                                            }), param);
                              }), __x);
                }), json),
          bugFormat: bugFormat
        };
}

var Btt = {
  fromJson: fromJson$1
};

function getFileAndDirectoryFromPath(path) {
  if (path === undefined) {
    return /* tuple */[
            undefined,
            undefined
          ];
  }
  var forwardSlashIndex = path.lastIndexOf("/");
  var backSlashIndex = path.lastIndexOf("\\");
  var index = forwardSlashIndex !== -1 ? forwardSlashIndex : backSlashIndex;
  var file = path.substring(index + 1 | 0);
  var directory = path.substring(0, index + 1 | 0);
  return /* tuple */[
          file,
          directory
        ];
}

function fromJson$2(fingerprint, errorId, bugFormat, json) {
  var sourceCode = Json_decode.optional((function (param) {
          return Json_decode.field("sourceCode", (function (param) {
                        return Json_decode.dict(BugSourceCode$BsConsole.fromJson, param);
                      }), param);
        }), json);
  var partial_arg_000 = function (json) {
    return Json_decode.array((function (prim) {
                  return prim;
                }), json);
  };
  var partial_arg_001 = /* :: */[
    (function (json) {
        return Belt_Array.map(Js_dict.entries(Json_decode.dict((function (prim) {
                              return prim;
                            }), json)), (function (param) {
                      var v = param[1];
                      v.key = param[0];
                      return v;
                    }));
      }),
    /* [] */0
  ];
  var partial_arg = /* :: */[
    partial_arg_000,
    partial_arg_001
  ];
  var threads = Belt_Array.map(Belt_SortArray.stableSortBy(Json_decode.field("threads", (function (param) {
                  return Json_decode.oneOf(partial_arg, param);
                }), json), (function (t1, t2) {
              var match = t1.fault;
              var match$1 = t2.fault;
              if (match) {
                if (match$1) {
                  return 0;
                } else {
                  return -1;
                }
              } else if (match$1) {
                return 1;
              } else {
                return 0;
              }
            })), (function (thread) {
          var stack = thread.stack;
          if (stack === undefined) {
            return thread;
          }
          var newStack = Belt_Array.map(stack, (function (frame) {
                  var match = frame.path;
                  if (match === undefined) {
                    var key = frame.sourceCode;
                    var path = key !== undefined && sourceCode !== undefined ? Belt_Option.flatMap(Js_dict.get(Caml_option.valFromOption(sourceCode), key), (function (code) {
                              return code.path;
                            })) : undefined;
                    var match$1 = getFileAndDirectoryFromPath(path);
                    var directory = match$1[1];
                    var file = match$1[0];
                    if (file !== undefined) {
                      if (directory !== undefined) {
                        frame.directory = directory;
                        frame.path = Caml_option.some(file);
                      } else {
                        frame.path = Caml_option.some(file);
                      }
                    }
                    
                  }
                  return frame;
                }));
          thread.stack = newStack;
          return thread;
        }));
  var annotationsDict = Belt_Option.getWithDefault(Json_decode.optional((function (__x) {
              return Json_decode.field("annotations", (function (param) {
                            return Json_decode.dict((function (prim) {
                                          return prim;
                                        }), param);
                          }), __x);
            }), json), { });
  var match = decodeAnnotations(annotationsDict);
  var attributes = Belt_Option.getWithDefault(Json_decode.optional((function (param) {
              return Json_decode.field("attributes", (function (param) {
                            return Json_decode.dict(Util$BsConsole.identity, param);
                          }), param);
            }), json), { });
  var modFaultingTid = Belt_Option.flatMap(Js_dict.get(attributes, "_mod_faulting_tid"), (function (id) {
          try {
            return Json_decode.string(id);
          }
          catch (exn){
            return ;
          }
        }));
  var thread = threads.find((function (thread) {
          return thread.fault;
        }));
  var faultingThread = Belt_Option.map(Belt_Option.getWithDefault(Belt_Option.map(modFaultingTid, (function (id) {
                  return Caml_option.undefined_to_opt(threads.find((function (thread) {
                                    return Caml_obj.caml_equal(thread.key, id);
                                  })));
                })), thread !== undefined ? thread : Belt_Array.get(threads, 0)), (function (thread) {
          var frames = thread.stack;
          var frame = Belt_Option.flatMap(frames, (function (s) {
                  return Caml_option.undefined_to_opt(s.find((function (frame) {
                                    return Caml_obj.caml_equal(frame.faulted, true);
                                  })));
                }));
          var frame$1 = frame !== undefined ? frame : Belt_Option.flatMap(frames, (function (s) {
                    return Belt_Array.get(s, 0);
                  }));
          return /* tuple */[
                  thread,
                  frame$1
                ];
        }));
  return {
          fingerprint: fingerprint,
          errorId: errorId,
          uuid: Json_decode.field("uuid", Json_decode.string, json),
          agent: Json_decode.optional((function (param) {
                  return Json_decode.field("agent", Json_decode.string, param);
                }), json),
          agentVersion: Json_decode.optional((function (param) {
                  return Json_decode.field("agentVersion", Json_decode.string, param);
                }), json),
          time: Json_decode.oneOf(/* :: */[
                (function (json) {
                    return DateFns.$$parseFloat(undefined, Json_decode.field("timestamp", Json_decode.$$float, json) * 1000);
                  }),
                /* :: */[
                  (function (json) {
                      return DateFns.parseString(undefined, Json_decode.field("timestamp", Json_decode.string, json));
                    }),
                  /* [] */0
                ]
              ], json),
          attributes: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("attributes", (function (param) {
                                    return Json_decode.dict(Util$BsConsole.identity, param);
                                  }), param);
                    }), json), { }),
          memoryMap: Json_decode.optional((function (param) {
                  return Json_decode.field("memory", (function (param) {
                                return Json_decode.array(BugMemoryRegion$BsConsole.fromJson, param);
                              }), param);
                }), json),
          classifiers: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("classifiers", (function (param) {
                                    return Json_decode.array(fromJson, param);
                                  }), param);
                    }), json), []),
          threads: threads,
          faultingThread: faultingThread,
          variableAnnotations: match[0],
          annotationsDict: annotationsDict,
          globalAnnotations: match[1],
          missingSymbols: Belt_Option.getWithDefault(Js_dict.get(annotationsDict, "Missing Symbols"), []),
          sourceCode: sourceCode,
          bugFormat: bugFormat
        };
}

var Generic = {
  getFileAndDirectoryFromPath: getFileAndDirectoryFromPath,
  fromJson: fromJson$2
};

function fromJson$3(fingerprint, errorId, bugFormat, json) {
  var timeField = Json_decode.optional((function (param) {
          return Json_decode.field("time", Json_decode.string, param);
        }), json);
  if (timeField !== undefined) {
    return /* Btt */Block.__(0, [fromJson$1(fingerprint, errorId, bugFormat, json)]);
  } else {
    return /* Generic */Block.__(1, [fromJson$2(fingerprint, errorId, bugFormat, json)]);
  }
}

function getFingerprint(err) {
  return err[0].fingerprint;
}

function getErrorId(err) {
  return err[0].errorId;
}

exports.Classifier = Classifier;
exports.decodeAnnotations = decodeAnnotations;
exports.Btt = Btt;
exports.Generic = Generic;
exports.fromJson = fromJson$3;
exports.getFingerprint = getFingerprint;
exports.getErrorId = getErrorId;
/* DateFns Not a pure module */
