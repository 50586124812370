// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("../../I18N.js");
var Util$BsConsole = require("../../util.js");
var Colors$BsConsole = require("../../Colors.js");
var Chip = require("@material-ui/core/Chip");
var BtTypography$BsConsole = require("../../BtTypography.js");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var Close = require("@material-ui/icons/Close");
var MenuItem = require("@material-ui/core/MenuItem");
var IconButton = require("@material-ui/core/IconButton");
var Typography = require("@material-ui/core/Typography");
var JiraCloseIconButton$BsConsole = require("./JiraCloseIconButton.js");
var OutlinedInput = require("@material-ui/core/OutlinedInput");
var FormHelperText = require("@material-ui/core/FormHelperText");
var AddCircleOutline = require("@material-ui/icons/AddCircleOutline");

var horizontalContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var integrationNameLabel = Css.style(/* :: */[
      Css.fontSize(Css.px(16)),
      /* :: */[
        Css.marginBottom(Css.px(5)),
        /* [] */0
      ]
    ]);

var inputItem = Css.style(/* :: */[
      Css.padding(Css.px(10)),
      /* :: */[
        Css.minWidth(Css.px(250)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* :: */[
            Css.boxSizing(/* borderBox */9307263),
            /* [] */0
          ]
        ]
      ]
    ]);

var containerRightMargin = Css.style(/* :: */[
      Css.marginRight(Css.px(40)),
      /* [] */0
    ]);

var verticalContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

var halfVerticalContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.width(Css.pct(50)),
          /* [] */0
        ]
      ]
    ]);

var halfVerticalContainerWithButton = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.unsafe("width", "calc(50% + 49px)"),
          /* [] */0
        ]
      ]
    ]);

var inputWidth = Css.style(/* :: */[
      Css.marginRight(Css.px(49)),
      /* :: */[
        Css.unsafe("width", "calc(100% - 49px)"),
        /* [] */0
      ]
    ]);

var inputWithButtonWidth = Css.style(/* :: */[
      Css.unsafe("width", "calc(100% - 49px)"),
      /* [] */0
    ]);

var chipIconButton = Css.style(/* :: */[
      Css.important(Css.borderRadius(Css.px(0))),
      /* [] */0
    ]);

var chipRoot = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
      /* :: */[
        Css.important(Css.border(Css.px(2), /* solid */12956715, Css.hex(Colors$BsConsole.grey6))),
        /* :: */[
          Css.important(Css.borderRadius(Css.px(3))),
          /* :: */[
            Css.important(Css.padding(Css.px(0))),
            /* :: */[
              Css.important(Css.position(/* relative */903134412)),
              /* :: */[
                Css.important(Css.paddingRight(Css.px(36))),
                /* :: */[
                  Css.important(Css.height(Css.px(40))),
                  /* :: */[
                    Css.important(Css.marginRight(Css.px(10))),
                    /* :: */[
                      Css.important(Css.marginBottom(Css.px(10))),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var chipRootWithoutIcon = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
      /* :: */[
        Css.important(Css.border(Css.px(2), /* solid */12956715, Css.hex(Colors$BsConsole.grey6))),
        /* :: */[
          Css.important(Css.borderRadius(Css.px(3))),
          /* :: */[
            Css.important(Css.padding(Css.px(0))),
            /* :: */[
              Css.important(Css.position(/* relative */903134412)),
              /* :: */[
                Css.important(Css.height(Css.px(40))),
                /* :: */[
                  Css.important(Css.marginRight(Css.px(10))),
                  /* :: */[
                    Css.important(Css.marginBottom(Css.px(10))),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var chipIcon = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.right(Css.px(0)),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.redDark)),
          /* :: */[
            Css.hover(/* :: */[
                  Css.important(Css.background(Css.hex(Colors$BsConsole.redBackground))),
                  /* [] */0
                ]),
            /* :: */[
              Css.important(Css.margin(Css.px(0))),
              /* :: */[
                Css.height(Css.pct(100)),
                /* :: */[
                  Css.cursor(/* pointer */-786317123),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var chipLabel = Css.style(/* :: */[
      Css.fontSize(Css.rem(1)),
      /* :: */[
        Css.important(Css.padding(Css.px(0))),
        /* :: */[
          Css.important(Css.paddingTop(Css.px(10))),
          /* :: */[
            Css.important(Css.paddingBottom(Css.px(10))),
            /* [] */0
          ]
        ]
      ]
    ]);

var chipLabelWithoutIcon = Css.style(/* :: */[
      Css.fontSize(Css.rem(1)),
      /* :: */[
        Css.important(Css.padding(Css.px(0))),
        /* :: */[
          Css.important(Css.paddingTop(Css.px(10))),
          /* :: */[
            Css.important(Css.paddingBottom(Css.px(10))),
            /* :: */[
              Css.important(Css.color(Css.hex(Colors$BsConsole.redDark))),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  horizontalContainer: horizontalContainer,
  integrationNameLabel: integrationNameLabel,
  inputItem: inputItem,
  containerRightMargin: containerRightMargin,
  verticalContainer: verticalContainer,
  halfVerticalContainer: halfVerticalContainer,
  halfVerticalContainerWithButton: halfVerticalContainerWithButton,
  inputWidth: inputWidth,
  inputWithButtonWidth: inputWithButtonWidth,
  chipIconButton: chipIconButton,
  chipRoot: chipRoot,
  chipRootWithoutIcon: chipRootWithoutIcon,
  chipIcon: chipIcon,
  chipLabel: chipLabel,
  chipLabelWithoutIcon: chipLabelWithoutIcon
};

function JiraStatusMapping$Required(Props) {
  var children = Props.children;
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* [] */0
                  ]),
              children: null
            }, children, React.createElement("span", {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.npDarkRed)),
                        /* :: */[
                          Css.marginTop(Css.px(16)),
                          /* :: */[
                            Css.marginLeft(Css.px(3)),
                            /* [] */0
                          ]
                        ]
                      ])
                }, I18N$BsConsole.showSkip(" *")));
}

var Required = {
  make: JiraStatusMapping$Required
};

function JiraStatusMapping(Props) {
  var isStatusMappingValid = Props.isStatusMappingValid;
  var jiraStatusesAvailable = Props.jiraStatusesAvailable;
  var jiraStatusMappingSelections = Props.jiraStatusMappingSelections;
  var handleBtStatusMappingChange = Props.handleBtStatusMappingChange;
  var handleJiraStatusMappingAdd = Props.handleJiraStatusMappingAdd;
  var handleJiraStatusMappingDelete = Props.handleJiraStatusMappingDelete;
  var match = React.useState((function () {
          return false;
        }));
  var setOpenSelector = match[1];
  var openSelector = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setInProgressSelector = match$1[1];
  var inProgressSelector = match$1[0];
  var match$2 = React.useState((function () {
          return false;
        }));
  var setResolvedSelector = match$2[1];
  var resolvedSelector = match$2[0];
  var match$3 = React.useState((function () {
          return false;
        }));
  var setMutedSelector = match$3[1];
  var mutedSelector = match$3[0];
  var match$4 = React.useState((function () {
          return [];
        }));
  var setJiraStatusesLeftToMap = match$4[1];
  var jiraStatusesLeftToMap = match$4[0];
  React.useEffect((function () {
          if (jiraStatusMappingSelections !== undefined) {
            var open_ = Belt_Option.getWithDefault(jiraStatusMappingSelections.open_, []);
            var inProgress = Belt_Option.getWithDefault(jiraStatusMappingSelections.inProgress, []);
            var resolved = Belt_Option.getWithDefault(jiraStatusMappingSelections.resolved, []);
            var muted = Belt_Option.getWithDefault(jiraStatusMappingSelections.muted, []);
            var allStatuses = Util$BsConsole.getUniqueValues(Belt_Array.concatMany([
                      open_,
                      inProgress,
                      resolved,
                      muted
                    ]));
            Curry._1(setJiraStatusesLeftToMap, (function (param) {
                    return Belt_Array.keep(jiraStatusesAvailable, (function (availableStatus) {
                                  return !Belt_Array.some(allStatuses, (function (selectedStatus) {
                                                return selectedStatus === availableStatus.id;
                                              }));
                                }));
                  }));
          } else {
            Curry._1(setJiraStatusesLeftToMap, (function (param) {
                    return jiraStatusesAvailable;
                  }));
          }
          
        }), /* tuple */[
        jiraStatusMappingSelections,
        jiraStatusesAvailable
      ]);
  var getStatusFromId = function (id) {
    var maybeStatus = Belt_Array.getBy(jiraStatusesAvailable, (function (status) {
            return status.id === id;
          }));
    if (maybeStatus !== undefined) {
      return maybeStatus.name;
    } else {
      return "";
    }
  };
  var tmp;
  if (jiraStatusMappingSelections !== undefined) {
    var open_ = jiraStatusMappingSelections.open_;
    tmp = open_ !== undefined ? Belt_Array.map(open_, (function (status) {
              return React.createElement(Chip.default, {
                          label: React.createElement(Typography.default, {
                                variant: "h5",
                                classes: {
                                  root: chipLabel
                                },
                                children: I18N$BsConsole.showSkip(getStatusFromId(status))
                              }),
                          icon: React.createElement(IconButton.default, {
                                ariaLabel: "delete status",
                                className: chipIconButton,
                                onClick: (function (param) {
                                    return Curry._2(handleJiraStatusMappingDelete, /* Open */0, status);
                                  }),
                                children: React.createElement(Close.default, {
                                      fontSize: "small"
                                    })
                              }),
                          classes: {
                            icon: chipIcon,
                            root: chipRoot
                          }
                        });
            })) : null;
  } else {
    tmp = null;
  }
  var tmp$1;
  if (jiraStatusMappingSelections !== undefined) {
    var inProgress = jiraStatusMappingSelections.inProgress;
    tmp$1 = inProgress !== undefined ? Belt_Array.map(inProgress, (function (status) {
              return React.createElement(Chip.default, {
                          label: React.createElement(Typography.default, {
                                variant: "h5",
                                classes: {
                                  root: chipLabel
                                },
                                children: I18N$BsConsole.showSkip(getStatusFromId(status))
                              }),
                          icon: React.createElement(IconButton.default, {
                                ariaLabel: "delete status",
                                className: chipIconButton,
                                onClick: (function (param) {
                                    return Curry._2(handleJiraStatusMappingDelete, /* InProgress */2, status);
                                  }),
                                children: React.createElement(Close.default, {
                                      fontSize: "small"
                                    })
                              }),
                          classes: {
                            icon: chipIcon,
                            root: chipRoot
                          }
                        });
            })) : null;
  } else {
    tmp$1 = null;
  }
  var tmp$2;
  if (jiraStatusMappingSelections !== undefined) {
    var resolved = jiraStatusMappingSelections.resolved;
    tmp$2 = resolved !== undefined ? Belt_Array.map(resolved, (function (status) {
              return React.createElement(Chip.default, {
                          label: React.createElement(Typography.default, {
                                variant: "h5",
                                classes: {
                                  root: chipLabel
                                },
                                children: I18N$BsConsole.showSkip(getStatusFromId(status))
                              }),
                          icon: React.createElement(IconButton.default, {
                                ariaLabel: "delete status",
                                className: chipIconButton,
                                onClick: (function (param) {
                                    return Curry._2(handleJiraStatusMappingDelete, /* Resolved */1, status);
                                  }),
                                children: React.createElement(Close.default, {
                                      fontSize: "small"
                                    })
                              }),
                          classes: {
                            icon: chipIcon,
                            root: chipRoot
                          }
                        });
            })) : null;
  } else {
    tmp$2 = null;
  }
  var tmp$3;
  if (jiraStatusMappingSelections !== undefined) {
    var muted = jiraStatusMappingSelections.muted;
    tmp$3 = muted !== undefined ? Belt_Array.map(muted, (function (status) {
              return React.createElement(Chip.default, {
                          label: React.createElement(Typography.default, {
                                variant: "h5",
                                classes: {
                                  root: chipLabel
                                },
                                children: I18N$BsConsole.showSkip(getStatusFromId(status))
                              }),
                          icon: React.createElement(IconButton.default, {
                                ariaLabel: "delete status",
                                className: chipIconButton,
                                onClick: (function (param) {
                                    return Curry._2(handleJiraStatusMappingDelete, /* Muted */3, status);
                                  }),
                                children: React.createElement(Close.default, {
                                      fontSize: "small"
                                    })
                              }),
                          classes: {
                            icon: chipIcon,
                            root: chipRoot
                          }
                        });
            })) : null;
  } else {
    tmp$3 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: horizontalContainer
                }, React.createElement("div", {
                      className: Css.merge(/* :: */[
                            containerRightMargin,
                            /* :: */[
                              verticalContainer,
                              /* [] */0
                            ]
                          ])
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(0)),
                                /* :: */[
                                  Css.marginTop(Css.px(40)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Backtrace/Jira status mapping")
                        }), React.createElement(FormHelperText.default, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* [] */0
                              ]),
                          children: I18N$BsConsole.show(undefined, "Maps custom Jira statuses for Jira to Backtrace syncing")
                        }))), jiraStatusesLeftToMap.length !== 0 ? React.createElement("div", {
                    className: horizontalContainer
                  }, React.createElement("div", {
                        className: verticalContainer
                      }, React.createElement(JiraStatusMapping$Required, {
                            children: React.createElement("p", {
                                  className: integrationNameLabel
                                }, I18N$BsConsole.show(undefined, "Jira statuses left to map"))
                          }), React.createElement(FormHelperText.default, {
                            className: Css.style(/* :: */[
                                  Css.marginBottom(Css.px(10)),
                                  /* [] */0
                                ]),
                            error: !isStatusMappingValid,
                            children: I18N$BsConsole.show(undefined, "All Jira statuses must be mapped to a Backtrace status")
                          }), React.createElement("div", {
                            className: Css.merge(/* :: */[
                                  horizontalContainer,
                                  /* :: */[
                                    Css.style(/* :: */[
                                          Css.marginBottom(Css.px(10)),
                                          /* :: */[
                                            Css.flexWrap(/* wrap */-822134326),
                                            /* [] */0
                                          ]
                                        ]),
                                    /* [] */0
                                  ]
                                ])
                          }, Belt_Array.map(jiraStatusesLeftToMap, (function (status) {
                                  return React.createElement(Chip.default, {
                                              label: React.createElement(Typography.default, {
                                                    variant: "h5",
                                                    classes: {
                                                      root: chipLabelWithoutIcon
                                                    },
                                                    children: I18N$BsConsole.showSkip(status.name)
                                                  }),
                                              classes: {
                                                root: chipRootWithoutIcon
                                              }
                                            });
                                }))))) : null, React.createElement("div", {
                  className: horizontalContainer
                }, React.createElement("div", {
                      className: halfVerticalContainer
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.rem(0.5)),
                                /* :: */[
                                  Css.marginTop(Css.px(40)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Backtrace \"Open\" status")
                        }), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body1 */8,
                          children: I18N$BsConsole.show(undefined, "Jira status default")
                        }), React.createElement(FormHelperText.default, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* :: */[
                                  Css.marginTop(Css.px(0)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "On Backtrace status update, Jira will be synced to Jira status default")
                        }), React.createElement(Select.default, {
                          value: jiraStatusMappingSelections !== undefined ? Belt_Array.get(Belt_Option.getWithDefault(jiraStatusMappingSelections.open_, []), 0) : undefined,
                          disabled: false,
                          onChange: (function ($$event, param) {
                              var value = $$event.target.value;
                              if (!(value == null)) {
                                return Curry._2(handleBtStatusMappingChange, /* Open */0, value);
                              }
                              
                            }),
                          input: React.createElement(OutlinedInput.default, {
                                classes: {
                                  root: inputWidth
                                },
                                labelWidth: 0
                              }),
                          classes: {
                            select: Css.style(/* :: */[
                                  Css.padding(Css.px(10)),
                                  /* :: */[
                                    Css.width(/* auto */-1065951377),
                                    /* :: */[
                                      Css.boxSizing(/* borderBox */9307263),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          },
                          autoWidth: true,
                          children: jiraStatusesAvailable.length !== 0 ? Belt_Array.map(jiraStatusesAvailable, (function (jiraStatus) {
                                    return React.createElement(MenuItem.default, {
                                                value: jiraStatus.id,
                                                children: I18N$BsConsole.showSkip(jiraStatus.name),
                                                key: jiraStatus.id
                                              });
                                  })) : [React.createElement(MenuItem.default, {
                                    children: I18N$BsConsole.show(undefined, "No options available")
                                  })]
                        }), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body1 */8,
                          className: Css.style(/* :: */[
                                Css.marginTop(Css.rem(1)),
                                /* [] */0
                              ]),
                          children: I18N$BsConsole.show(undefined, "Additional Jira to Backtrace mapping")
                        }), React.createElement(FormHelperText.default, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* :: */[
                                  Css.marginTop(Css.px(0)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Other Jira status synced to Backtrace status")
                        }), React.createElement("div", {
                          className: Css.merge(/* :: */[
                                horizontalContainer,
                                /* :: */[
                                  Css.style(/* :: */[
                                        Css.flexWrap(/* wrap */-822134326),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ])
                        }, tmp), openSelector ? React.createElement(Select.default, {
                            open: openSelector,
                            onClose: (function (param) {
                                return Curry._1(setOpenSelector, (function (param) {
                                              return false;
                                            }));
                              }),
                            value: "",
                            disabled: false,
                            onChange: (function ($$event, param) {
                                var value = $$event.target.value;
                                if (!(value == null)) {
                                  return Curry._2(handleJiraStatusMappingAdd, /* Open */0, value);
                                }
                                
                              }),
                            input: React.createElement(OutlinedInput.default, {
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.width(Css.px(100)),
                                          /* [] */0
                                        ])
                                  },
                                  labelWidth: 0
                                }),
                            classes: {
                              select: inputItem
                            },
                            autoWidth: true,
                            children: jiraStatusesAvailable.length !== 0 ? Belt_Array.map(jiraStatusesAvailable, (function (jiraStatus) {
                                      var tmp;
                                      if (jiraStatusMappingSelections !== undefined) {
                                        var open_ = jiraStatusMappingSelections.open_;
                                        tmp = open_ !== undefined ? Belt_Array.some(open_, (function (statusId) {
                                                  return statusId === jiraStatus.id;
                                                })) : false;
                                      } else {
                                        tmp = false;
                                      }
                                      return React.createElement(MenuItem.default, {
                                                  disabled: tmp,
                                                  value: jiraStatus.id,
                                                  children: I18N$BsConsole.showSkip(jiraStatus.name),
                                                  key: jiraStatus.id
                                                });
                                    })) : [React.createElement(MenuItem.default, {
                                      children: I18N$BsConsole.show(undefined, "No options available")
                                    })]
                          }) : null, React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.width(Css.px(200)),
                                /* [] */0
                              ])
                        }, React.createElement(Button.default, {
                              color: "primary",
                              onClick: (function (param) {
                                  return Curry._1(setOpenSelector, (function (param) {
                                                return true;
                                              }));
                                }),
                              children: null
                            }, React.createElement(AddCircleOutline.default, {
                                  fontSize: "inherit"
                                }), React.createElement("span", {
                                  className: Css.style(/* :: */[
                                        Css.marginLeft(Css.rem(0.5)),
                                        /* [] */0
                                      ])
                                }, I18N$BsConsole.show(undefined, "Sync another Jira status"))))), React.createElement("div", {
                      className: halfVerticalContainer
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.rem(0.5)),
                                /* :: */[
                                  Css.marginTop(Css.px(40)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Backtrace \"In progress\" status")
                        }), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body1 */8,
                          children: I18N$BsConsole.show(undefined, "Jira status default")
                        }), React.createElement(FormHelperText.default, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* :: */[
                                  Css.marginTop(Css.px(0)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "On Backtrace status update, Jira will be synced to Jira status default")
                        }), React.createElement(Select.default, {
                          value: jiraStatusMappingSelections !== undefined ? Belt_Array.get(Belt_Option.getWithDefault(jiraStatusMappingSelections.inProgress, []), 0) : undefined,
                          disabled: false,
                          onChange: (function ($$event, param) {
                              var value = $$event.target.value;
                              if (!(value == null)) {
                                return Curry._2(handleBtStatusMappingChange, /* InProgress */2, value);
                              }
                              
                            }),
                          input: React.createElement(OutlinedInput.default, {
                                classes: {
                                  root: inputWidth
                                },
                                labelWidth: 0
                              }),
                          classes: {
                            select: Css.style(/* :: */[
                                  Css.padding(Css.px(10)),
                                  /* :: */[
                                    Css.width(/* auto */-1065951377),
                                    /* :: */[
                                      Css.boxSizing(/* borderBox */9307263),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          },
                          autoWidth: true,
                          children: jiraStatusesAvailable.length !== 0 ? Belt_Array.map(jiraStatusesAvailable, (function (jiraStatus) {
                                    return React.createElement(MenuItem.default, {
                                                value: jiraStatus.id,
                                                children: I18N$BsConsole.showSkip(jiraStatus.name),
                                                key: jiraStatus.id
                                              });
                                  })) : [React.createElement(MenuItem.default, {
                                    children: I18N$BsConsole.show(undefined, "No options available")
                                  })]
                        }), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body1 */8,
                          className: Css.style(/* :: */[
                                Css.marginTop(Css.rem(1)),
                                /* [] */0
                              ]),
                          children: I18N$BsConsole.show(undefined, "Additional Jira to Backtrace mapping")
                        }), React.createElement(FormHelperText.default, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* :: */[
                                  Css.marginTop(Css.px(0)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Other Jira status synced to Backtrace status")
                        }), React.createElement("div", {
                          className: Css.merge(/* :: */[
                                horizontalContainer,
                                /* :: */[
                                  Css.style(/* :: */[
                                        Css.flexWrap(/* wrap */-822134326),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ])
                        }, tmp$1), inProgressSelector ? React.createElement(Select.default, {
                            open: inProgressSelector,
                            onClose: (function (param) {
                                return Curry._1(setInProgressSelector, (function (param) {
                                              return false;
                                            }));
                              }),
                            value: "",
                            disabled: false,
                            onChange: (function ($$event, param) {
                                var value = $$event.target.value;
                                if (!(value == null)) {
                                  return Curry._2(handleJiraStatusMappingAdd, /* InProgress */2, value);
                                }
                                
                              }),
                            input: React.createElement(OutlinedInput.default, {
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.width(Css.px(100)),
                                          /* [] */0
                                        ])
                                  },
                                  labelWidth: 0
                                }),
                            classes: {
                              select: inputItem
                            },
                            autoWidth: true,
                            children: jiraStatusesAvailable.length !== 0 ? Belt_Array.map(jiraStatusesAvailable, (function (jiraStatus) {
                                      var tmp;
                                      if (jiraStatusMappingSelections !== undefined) {
                                        var inProgress = jiraStatusMappingSelections.inProgress;
                                        tmp = inProgress !== undefined ? Belt_Array.some(inProgress, (function (statusId) {
                                                  return statusId === jiraStatus.id;
                                                })) : false;
                                      } else {
                                        tmp = false;
                                      }
                                      return React.createElement(MenuItem.default, {
                                                  disabled: tmp,
                                                  value: jiraStatus.id,
                                                  children: I18N$BsConsole.showSkip(jiraStatus.name),
                                                  key: jiraStatus.id
                                                });
                                    })) : [React.createElement(MenuItem.default, {
                                      children: I18N$BsConsole.show(undefined, "No options available")
                                    })]
                          }) : null, React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.width(Css.px(200)),
                                /* [] */0
                              ])
                        }, React.createElement(Button.default, {
                              color: "primary",
                              onClick: (function (param) {
                                  return Curry._1(setInProgressSelector, (function (param) {
                                                return true;
                                              }));
                                }),
                              children: null
                            }, React.createElement(AddCircleOutline.default, {
                                  fontSize: "inherit"
                                }), React.createElement("span", {
                                  className: Css.style(/* :: */[
                                        Css.marginLeft(Css.rem(0.5)),
                                        /* [] */0
                                      ])
                                }, I18N$BsConsole.show(undefined, "Sync another Jira status")))))), React.createElement("div", {
                  className: horizontalContainer
                }, React.createElement("div", {
                      className: halfVerticalContainer
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.rem(0.5)),
                                /* :: */[
                                  Css.marginTop(Css.px(40)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Backtrace \"Resolved\" status")
                        }), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body1 */8,
                          children: I18N$BsConsole.show(undefined, "Jira status default")
                        }), React.createElement(FormHelperText.default, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* :: */[
                                  Css.marginTop(Css.px(0)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "On Backtrace status update, Jira will be synced to Jira status default")
                        }), React.createElement(Select.default, {
                          value: jiraStatusMappingSelections !== undefined ? Belt_Array.get(Belt_Option.getWithDefault(jiraStatusMappingSelections.resolved, []), 0) : undefined,
                          disabled: false,
                          onChange: (function ($$event, param) {
                              var value = $$event.target.value;
                              if (!(value == null)) {
                                return Curry._2(handleBtStatusMappingChange, /* Resolved */1, value);
                              }
                              
                            }),
                          input: React.createElement(OutlinedInput.default, {
                                classes: {
                                  root: inputWidth
                                },
                                labelWidth: 0
                              }),
                          classes: {
                            select: Css.style(/* :: */[
                                  Css.padding(Css.px(10)),
                                  /* :: */[
                                    Css.width(/* auto */-1065951377),
                                    /* :: */[
                                      Css.boxSizing(/* borderBox */9307263),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          },
                          autoWidth: true,
                          children: jiraStatusesAvailable.length !== 0 ? Belt_Array.map(jiraStatusesAvailable, (function (jiraStatus) {
                                    return React.createElement(MenuItem.default, {
                                                value: jiraStatus.id,
                                                children: I18N$BsConsole.showSkip(jiraStatus.name),
                                                key: jiraStatus.id
                                              });
                                  })) : [React.createElement(MenuItem.default, {
                                    children: I18N$BsConsole.show(undefined, "No options available")
                                  })]
                        }), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body1 */8,
                          className: Css.style(/* :: */[
                                Css.marginTop(Css.rem(1)),
                                /* [] */0
                              ]),
                          children: I18N$BsConsole.show(undefined, "Additional Jira to Backtrace mapping")
                        }), React.createElement(FormHelperText.default, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* :: */[
                                  Css.marginTop(Css.px(0)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Other Jira status synced to Backtrace status")
                        }), React.createElement("div", {
                          className: Css.merge(/* :: */[
                                horizontalContainer,
                                /* :: */[
                                  Css.style(/* :: */[
                                        Css.flexWrap(/* wrap */-822134326),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ])
                        }, tmp$2), resolvedSelector ? React.createElement(Select.default, {
                            open: resolvedSelector,
                            onClose: (function (param) {
                                return Curry._1(setResolvedSelector, (function (param) {
                                              return false;
                                            }));
                              }),
                            value: "",
                            disabled: false,
                            onChange: (function ($$event, param) {
                                var value = $$event.target.value;
                                if (!(value == null)) {
                                  return Curry._2(handleJiraStatusMappingAdd, /* Resolved */1, value);
                                }
                                
                              }),
                            input: React.createElement(OutlinedInput.default, {
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.width(Css.px(100)),
                                          /* [] */0
                                        ])
                                  },
                                  labelWidth: 0
                                }),
                            classes: {
                              select: inputItem
                            },
                            autoWidth: true,
                            children: jiraStatusesAvailable.length !== 0 ? Belt_Array.map(jiraStatusesAvailable, (function (jiraStatus) {
                                      var tmp;
                                      if (jiraStatusMappingSelections !== undefined) {
                                        var resolved = jiraStatusMappingSelections.resolved;
                                        tmp = resolved !== undefined ? Belt_Array.some(resolved, (function (statusId) {
                                                  return statusId === jiraStatus.id;
                                                })) : false;
                                      } else {
                                        tmp = false;
                                      }
                                      return React.createElement(MenuItem.default, {
                                                  disabled: tmp,
                                                  value: jiraStatus.id,
                                                  children: I18N$BsConsole.showSkip(jiraStatus.name),
                                                  key: jiraStatus.id
                                                });
                                    })) : [React.createElement(MenuItem.default, {
                                      children: I18N$BsConsole.show(undefined, "No options available")
                                    })]
                          }) : null, React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.width(Css.px(200)),
                                /* [] */0
                              ])
                        }, React.createElement(Button.default, {
                              color: "primary",
                              onClick: (function (param) {
                                  return Curry._1(setResolvedSelector, (function (param) {
                                                return true;
                                              }));
                                }),
                              children: null
                            }, React.createElement(AddCircleOutline.default, {
                                  fontSize: "inherit"
                                }), React.createElement("span", {
                                  className: Css.style(/* :: */[
                                        Css.marginLeft(Css.rem(0.5)),
                                        /* [] */0
                                      ])
                                }, I18N$BsConsole.show(undefined, "Sync another Jira status"))))), React.createElement("div", {
                      className: halfVerticalContainer
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.rem(0.5)),
                                /* :: */[
                                  Css.marginTop(Css.px(40)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Backtrace \"Muted\" status")
                        }), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body1 */8,
                          children: I18N$BsConsole.show(undefined, "Jira status default")
                        }), React.createElement(FormHelperText.default, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* :: */[
                                  Css.marginTop(Css.px(0)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "On Backtrace status update, Jira will be synced to Jira status default")
                        }), React.createElement(Select.default, {
                          value: jiraStatusMappingSelections !== undefined ? Belt_Array.get(Belt_Option.getWithDefault(jiraStatusMappingSelections.muted, []), 0) : undefined,
                          disabled: false,
                          onChange: (function ($$event, param) {
                              var value = $$event.target.value;
                              if (!(value == null)) {
                                return Curry._2(handleBtStatusMappingChange, /* Muted */3, value);
                              }
                              
                            }),
                          input: React.createElement(OutlinedInput.default, {
                                classes: {
                                  root: inputWidth
                                },
                                labelWidth: 0
                              }),
                          classes: {
                            select: Css.style(/* :: */[
                                  Css.padding(Css.px(10)),
                                  /* :: */[
                                    Css.width(/* auto */-1065951377),
                                    /* :: */[
                                      Css.boxSizing(/* borderBox */9307263),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          },
                          autoWidth: true,
                          children: jiraStatusesAvailable.length !== 0 ? Belt_Array.map(jiraStatusesAvailable, (function (jiraStatus) {
                                    return React.createElement(MenuItem.default, {
                                                value: jiraStatus.id,
                                                children: I18N$BsConsole.showSkip(jiraStatus.name),
                                                key: jiraStatus.id
                                              });
                                  })) : [React.createElement(MenuItem.default, {
                                    children: I18N$BsConsole.show(undefined, "No options available")
                                  })]
                        }), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body1 */8,
                          className: Css.style(/* :: */[
                                Css.marginTop(Css.rem(1)),
                                /* [] */0
                              ]),
                          children: I18N$BsConsole.show(undefined, "Additional Jira to Backtrace mapping")
                        }), React.createElement(FormHelperText.default, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* :: */[
                                  Css.marginTop(Css.px(0)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Other Jira status synced to Backtrace status")
                        }), React.createElement("div", {
                          className: Css.merge(/* :: */[
                                horizontalContainer,
                                /* :: */[
                                  Css.style(/* :: */[
                                        Css.flexWrap(/* wrap */-822134326),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ])
                        }, tmp$3), mutedSelector ? React.createElement(Select.default, {
                            open: mutedSelector,
                            onClose: (function (param) {
                                return Curry._1(setMutedSelector, (function (param) {
                                              return false;
                                            }));
                              }),
                            value: "",
                            disabled: false,
                            onChange: (function ($$event, param) {
                                var value = $$event.target.value;
                                if (!(value == null)) {
                                  return Curry._2(handleJiraStatusMappingAdd, /* Muted */3, value);
                                }
                                
                              }),
                            input: React.createElement(OutlinedInput.default, {
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.width(Css.px(100)),
                                          /* [] */0
                                        ])
                                  },
                                  labelWidth: 0
                                }),
                            classes: {
                              select: inputItem
                            },
                            autoWidth: true,
                            children: jiraStatusesAvailable.length !== 0 ? Belt_Array.map(jiraStatusesAvailable, (function (jiraStatus) {
                                      var tmp;
                                      if (jiraStatusMappingSelections !== undefined) {
                                        var muted = jiraStatusMappingSelections.muted;
                                        tmp = muted !== undefined ? Belt_Array.some(muted, (function (statusId) {
                                                  return statusId === jiraStatus.id;
                                                })) : false;
                                      } else {
                                        tmp = false;
                                      }
                                      return React.createElement(MenuItem.default, {
                                                  disabled: tmp,
                                                  value: jiraStatus.id,
                                                  children: I18N$BsConsole.showSkip(jiraStatus.name),
                                                  key: jiraStatus.id
                                                });
                                    })) : [React.createElement(MenuItem.default, {
                                      children: I18N$BsConsole.show(undefined, "No options available")
                                    })]
                          }) : null, React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.width(Css.px(200)),
                                /* [] */0
                              ])
                        }, React.createElement(Button.default, {
                              color: "primary",
                              onClick: (function (param) {
                                  return Curry._1(setMutedSelector, (function (param) {
                                                return true;
                                              }));
                                }),
                              children: null
                            }, React.createElement(AddCircleOutline.default, {
                                  fontSize: "inherit"
                                }), React.createElement("span", {
                                  className: Css.style(/* :: */[
                                        Css.marginLeft(Css.rem(0.5)),
                                        /* [] */0
                                      ])
                                }, I18N$BsConsole.show(undefined, "Sync another Jira status")))))), React.createElement("div", {
                  className: horizontalContainer
                }, React.createElement("div", {
                      className: halfVerticalContainer
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(0)),
                                /* :: */[
                                  Css.marginTop(Css.px(40)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "Jira \"Reopen\" status")
                        }), React.createElement(FormHelperText.default, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(10)),
                                /* :: */[
                                  Css.marginTop(Css.px(0)),
                                  /* [] */0
                                ]
                              ]),
                          children: I18N$BsConsole.show(undefined, "On resolved until behavior, Backtrace will reopen the Jira ticket with the following status")
                        }), React.createElement("div", undefined, React.createElement(Select.default, {
                              value: jiraStatusMappingSelections !== undefined ? Belt_Array.get(Belt_Option.getWithDefault(jiraStatusMappingSelections.reopened, []), 0) : undefined,
                              disabled: false,
                              onChange: (function ($$event, param) {
                                  var value = $$event.target.value;
                                  if (!(value == null)) {
                                    return Curry._2(handleBtStatusMappingChange, /* Reopened */4, value);
                                  }
                                  
                                }),
                              input: React.createElement(OutlinedInput.default, {
                                    classes: {
                                      root: inputWithButtonWidth
                                    },
                                    labelWidth: 0
                                  }),
                              classes: {
                                select: Css.style(/* :: */[
                                      Css.padding(Css.px(10)),
                                      /* :: */[
                                        Css.width(/* auto */-1065951377),
                                        /* :: */[
                                          Css.boxSizing(/* borderBox */9307263),
                                          /* [] */0
                                        ]
                                      ]
                                    ])
                              },
                              autoWidth: true,
                              children: jiraStatusesAvailable.length !== 0 ? Belt_Array.map(jiraStatusesAvailable, (function (jiraStatus) {
                                        return React.createElement(MenuItem.default, {
                                                    value: jiraStatus.id,
                                                    children: I18N$BsConsole.showSkip(jiraStatus.name),
                                                    key: jiraStatus.id
                                                  });
                                      })) : [React.createElement(MenuItem.default, {
                                        children: I18N$BsConsole.show(undefined, "No options available")
                                      })]
                            }), React.createElement(JiraCloseIconButton$BsConsole.make, {
                              handleClick: (function (param) {
                                  if (jiraStatusMappingSelections !== undefined) {
                                    return Curry._2(handleJiraStatusMappingDelete, /* Reopened */4, Belt_Option.getWithDefault(Belt_Array.get(Belt_Option.getWithDefault(jiraStatusMappingSelections.reopened, []), 0), ""));
                                  }
                                  
                                }),
                              ariaLabel: "delete status"
                            })))));
}

var make = JiraStatusMapping;

exports.Styles = Styles;
exports.Required = Required;
exports.make = make;
/* horizontalContainer Not a pure module */
