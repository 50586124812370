// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BugStyles$BsConsole = require("./BugStyles.js");
var Collapse = require("@material-ui/core/Collapse");
var IconButton = require("@material-ui/core/IconButton");
var KeyboardArrowDown = require("@material-ui/icons/KeyboardArrowDown");

function BugRegisters(Props) {
  var width = Props.width;
  var registers = Props.registers;
  var match = React.useState((function () {
          return true;
        }));
  var setExpanded = match[1];
  var expanded = match[0];
  var registers$1 = Js_dict.entries(registers);
  var registersCount = registers$1.length;
  if (registersCount === 0) {
    return null;
  }
  var colCount = Caml_primitive.caml_int_min(width / 160 | 0, 3);
  var registersPerCol = Caml_int32.div(registersCount, colCount) + (
    Caml_int32.mod_(registersCount, colCount) === 0 ? 0 : 1
  ) | 0;
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.fontFamily("Inconsolata"),
                    /* :: */[
                      Css.fontSize(Css.px(12)),
                      /* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]
                    ]
                  ]),
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* spaceBetween */516682146,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.cursor(/* pointer */-786317123),
                          /* [] */0
                        ]
                      ]),
                  onClick: (function (param) {
                      return Curry._1(setExpanded, (function (prim) {
                                    return !prim;
                                  }));
                    }),
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.padding2(Css.px(8), Css.px(8)),
                            /* [] */0
                          ]),
                      children: React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.fontFamily("Roboto"),
                                  /* :: */[
                                    Css.fontWeight(Css.medium),
                                    /* :: */[
                                      Css.fontSize(Css.px(12)),
                                      /* :: */[
                                        Css.textTransform(Css.uppercase),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ])
                          }, I18N$BsConsole.show(undefined, "Registers"))
                    }), React.createElement(IconButton.default, {
                      classes: {
                        root: Css.style(/* :: */[
                              Css.padding(Css.px(5)),
                              /* [] */0
                            ])
                      },
                      onClick: (function (e) {
                          Curry._1(setExpanded, (function (prim) {
                                  return !prim;
                                }));
                          return e.stopPropagation();
                        }),
                      children: React.createElement(KeyboardArrowDown.default, {
                            className: Css.style(/* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.blackA87)),
                                  /* :: */[
                                    Css.transform(Css.rotate(Css.deg(expanded ? 0 : 180))),
                                    /* :: */[
                                      Css.transition(250, undefined, undefined, "transform"),
                                      /* [] */0
                                    ]
                                  ]
                                ]),
                            fontSize: "small"
                          })
                    })), React.createElement(Collapse.default, {
                  in: expanded,
                  timeout: 250,
                  children: React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* flexStart */662439529,
                        className: Css.style(/* :: */[
                              Css.padding2(Css.px(12), Css.px(16)),
                              /* :: */[
                                Css.height(Css.pct(100)),
                                /* :: */[
                                  Css.borderTop(Css.px(1), Css.solid, Css.hex(BugStyles$BsConsole.Color.dividerTertiary)),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        children: Belt_Array.map(Belt_Array.range(0, colCount - 1 | 0), (function (i) {
                                return React.createElement(React.Fragment, {
                                            children: null,
                                            key: String(i)
                                          }, i === 0 ? null : React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.height(Css.pct(85)),
                                                        /* :: */[
                                                          Css.width(Css.px(1)),
                                                          /* :: */[
                                                            Css.backgroundColor(Css.hex(BugStyles$BsConsole.Color.dividerSecondary)),
                                                            /* :: */[
                                                              Css.alignSelf(Css.center),
                                                              /* :: */[
                                                                Css.margin2(Css.zero, Css.px(16)),
                                                                /* [] */0
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ])
                                                }), React.createElement(Col2$BsConsole.make, {
                                                children: Belt_Array.map(Belt_Array.slice(registers$1, Caml_int32.imul(i, registersPerCol), registersPerCol), (function (param) {
                                                        var key = param[0];
                                                        return React.createElement(Row2$BsConsole.make, {
                                                                    alignItems: /* center */98248149,
                                                                    justifyContent: /* spaceBetween */516682146,
                                                                    className: Css.style(/* :: */[
                                                                          Css.width(Css.pct(100)),
                                                                          /* :: */[
                                                                            Css.height(Css.px(20)),
                                                                            /* [] */0
                                                                          ]
                                                                        ]),
                                                                    children: null,
                                                                    key: key
                                                                  }, React.createElement("div", {
                                                                        className: Css.style(/* :: */[
                                                                              Css.paddingRight(Css.px(16)),
                                                                              /* [] */0
                                                                            ])
                                                                      }, I18N$BsConsole.showSkip(key)), React.createElement("div", undefined, I18N$BsConsole.showSkip(param[1])));
                                                      })),
                                                key: String(i)
                                              }));
                              }))
                      }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ])
                }));
}

var make = BugRegisters;

exports.make = make;
/* Css Not a pure module */
