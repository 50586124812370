// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Bin$BsConsole = require("./aggregation-renderers/Bin.js");
var Base$BsConsole = require("./bs-crdb-response/src/Base.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Col2$BsConsole = require("./components/Col2.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Row2$BsConsole = require("./components/Row2.js");
var Shape$BsConsole = require("./bs-crdb-response/src/Shape.js");
var Colors$BsConsole = require("./Colors.js");
var IFrame$BsConsole = require("./IFrame.js");
var Issues$BsConsole = require("./bs-crdb-response/src/Issues.js");
var AddFold$BsConsole = require("./AddFold.js");
var Numeral$BsConsole = require("./Numeral.js");
var Truncate$BsConsole = require("./Truncate.js");
var SortOrder$BsConsole = require("./bs-crdb-query/src/SortOrder.js");
var EmptyValue$BsConsole = require("./format-renderers/EmptyValue.js");
var ReopenGate$BsConsole = require("./reopen/ReopenGate.js");
var TriageTags$BsConsole = require("./TriageTags.js");
var BtTableCell$BsConsole = require("./BtTableCell.js");
var SummedEvents$BsConsole = require("./SummedEvents.js");
var BtTableColumn$BsConsole = require("./BtTableColumn.js");
var FormatPopover$BsConsole = require("./format-renderers/FormatPopover.js");
var TriageTickets$BsConsole = require("./TriageTickets.js");
var Button = require("@material-ui/core/Button");
var BtTableAdapter$BsConsole = require("./BtTableAdapter.js");
var FormatRenderer$BsConsole = require("./format-renderers/FormatRenderer.js");
var L10N_date_time$BsConsole = require("./L10N_date_time.js");
var Tooltip = require("@material-ui/core/Tooltip");
var AggregationType$BsConsole = require("./bs-crdb-response/src/AggregationType.js");
var FetchIssuesData$BsConsole = require("./FetchIssuesData.js");
var TriageAssignees$BsConsole = require("./TriageAssignees.js");
var TriageMultiState$BsConsole = require("./inbox/TriageMultiState.js");
var TriageFingerprint$BsConsole = require("./TriageFingerprint.js");
var ButtonBase = require("@material-ui/core/ButtonBase");
var AddCircle = require("@material-ui/icons/AddCircle");
var ActivitySortHeader$BsConsole = require("./aggregation-renderers/ActivitySortHeader.js");
var BtTableSessionData$BsConsole = require("./BtTableSessionData.js");
var CondensedDistribution$BsConsole = require("./charts/CondensedDistribution.js");
var CountNormalizationCell$BsConsole = require("./CountNormalizationCell.js");
var L10N_date_time_calendar$BsConsole = require("./L10N_date_time_calendar.js");
var TriageFingerprintSorting$BsConsole = require("./TriageFingerprintSorting.js");

var statusColumn = "__status";

var fingerprintColumn = "__fingerprint";

var countColumn = "__count";

var activityColumn = "__activity";

var callstackColumn = "__callstack";

var tagsColumn = "__tags";

var assigneesColumn = "__assignees";

var ticketsColumn = "__tickets";

function orderingToDirection(param) {
  if (param) {
    return /* Desc */759138929;
  } else {
    return /* Asc */3258129;
  }
}

function directionToOrdering(param) {
  if (param >= 759138929) {
    return /* Descending */1;
  } else {
    return /* Ascending */0;
  }
}

function rangeToColumnName(range, attribute) {
  if (range) {
    return "" + (String(attribute) + "__range--max");
  } else {
    return "" + (String(attribute) + "__range--min");
  }
}

function isRangeColumn(columnName) {
  var match = columnName.split("__");
  if (match.length !== 2) {
    return false;
  }
  var match$1 = match[1];
  if (match$1 === "range") {
    return true;
  } else {
    return false;
  }
}

function getAggregationFromColumnName(columnName) {
  var match = columnName.split("__");
  if (match.length !== 2) {
    return ;
  }
  var attribute = match[0];
  var match$1 = match[1];
  switch (match$1) {
    case "bin" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Bin */Block.__(0, [
                            32,
                            undefined
                          ])
                      ]));
    case "distribution" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Distribution */Block.__(1, [3])
                      ]));
    case "head" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Head */1
                      ]));
    case "max" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Max */6
                      ]));
    case "mean" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Mean */7
                      ]));
    case "min" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Min */5
                      ]));
    case "range" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Range */8
                      ]));
    case "sum" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Sum */3
                      ]));
    case "tail" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Tail */2
                      ]));
    case "unique" :
        return Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        attribute,
                        /* Unique */4
                      ]));
    default:
      return ;
  }
}

function getSortingStateFromTableSort(sortingState) {
  var ordering = directionToOrdering(sortingState.direction);
  var columnName = sortingState.columnName;
  if (columnName === fingerprintColumn) {
    return /* Group */Block.__(0, [ordering]);
  }
  if (columnName === countColumn) {
    return /* Count */Block.__(1, [ordering]);
  }
  var match = columnName.split("__");
  if (match.length !== 2) {
    return /* Count */Block.__(1, [/* Descending */1]);
  }
  var attribute = match[0];
  var match$1 = match[1];
  switch (match$1) {
    case "head" :
        return /* Head */Block.__(2, [
                  attribute,
                  ordering
                ]);
    case "max" :
        return /* Max */Block.__(8, [
                  attribute,
                  ordering
                ]);
    case "mean" :
        return /* Mean */Block.__(9, [
                  attribute,
                  ordering
                ]);
    case "min" :
        return /* Min */Block.__(7, [
                  attribute,
                  ordering
                ]);
    case "range--max" :
        return /* Range */Block.__(4, [
                  attribute,
                  /* Finish */1,
                  ordering
                ]);
    case "range--min" :
        return /* Range */Block.__(4, [
                  attribute,
                  /* Start */0,
                  ordering
                ]);
    case "sum" :
        return /* Sum */Block.__(10, [
                  attribute,
                  ordering
                ]);
    case "tail" :
        return /* Tail */Block.__(3, [
                  attribute,
                  ordering
                ]);
    case "unique" :
        return /* Unique */Block.__(6, [
                  attribute,
                  ordering
                ]);
    default:
      return /* Count */Block.__(1, [/* Descending */1]);
  }
}

function getTableSortingState(sort) {
  switch (sort.tag | 0) {
    case /* Group */0 :
        return {
                columnName: fingerprintColumn,
                direction: sort[0] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Count */1 :
        return {
                columnName: countColumn,
                direction: sort[0] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Head */2 :
        return {
                columnName: "" + (String(sort[0]) + "__head"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Tail */3 :
        return {
                columnName: "" + (String(sort[0]) + "__tail"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Range */4 :
        return {
                columnName: rangeToColumnName(sort[1], sort[0]),
                direction: sort[2] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Select */5 :
        return ;
    case /* Unique */6 :
        return {
                columnName: "" + (String(sort[0]) + "__unique"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Min */7 :
        return {
                columnName: "" + (String(sort[0]) + "__min"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Max */8 :
        return {
                columnName: "" + (String(sort[0]) + "__max"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Mean */9 :
        return {
                columnName: "" + (String(sort[0]) + "__mean"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    case /* Sum */10 :
        return {
                columnName: "" + (String(sort[0]) + "__sum"),
                direction: sort[1] ? /* Desc */759138929 : /* Asc */3258129
              };
    
  }
}

function onSortChange(cb, sortingState) {
  return Curry._1(cb, getSortingStateFromTableSort(sortingState));
}

function getColumnAlignment(column) {
  var op = column.op;
  if (op === undefined) {
    return /* Left */847852583;
  }
  switch (op) {
    case /* Distribution */1 :
    case /* Bin */2 :
        return /* Left */847852583;
    case /* Unique */0 :
    case /* Mean */6 :
    case /* Sum */7 :
        return /* Right */-57574468;
    case /* Head */3 :
    case /* Tail */4 :
    case /* Range */5 :
    case /* Min */8 :
    case /* Max */9 :
        return BtTableAdapter$BsConsole.getAlignmentByType_(column.type_);
    
  }
}

var statusTableColumn = BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Status"), statusColumn, (function (row) {
        var state = row.issues.state;
        if (state !== undefined) {
          return /* `IssueState */[
                  -1007993960,
                  state
                ];
        } else {
          return /* None */870530776;
        }
      }), true, true, undefined, 130, 130, 130, undefined, undefined, undefined, undefined);

var fingerprintTableColumn = BtTableColumn$BsConsole.make("Fingerprint", fingerprintColumn, (function (row) {
        var groupName = row.fingerprint;
        return /* `String */[
                -976970511,
                groupName
              ];
      }), true, true, undefined, 142, 142, 142, "walkthrough--triage--fingerprint-header", undefined, undefined, undefined);

function countTableColumn(normBy) {
  return BtTableColumn$BsConsole.make(normBy !== undefined ? "Errors by " + (String(Caml_option.valFromOption(normBy)) + "") : I18N$BsConsole.get(undefined, "Errors"), countColumn, (function (row) {
                var count = row.count;
                return /* `Int */[
                        3654863,
                        count
                      ];
              }), undefined, undefined, undefined, Belt_Option.isSome(normBy) ? 150 : 100, undefined, Belt_Option.isSome(normBy) ? 160 : 120, undefined, undefined, /* Right */-57574468, undefined);
}

var activityTableColumn = BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Activity"), activityColumn, (function (row) {
        var _timestampBin = row.timestampBin;
        var data = _timestampBin !== undefined ? /* Bin */Block.__(2, [
              "timestamp",
              row.timestampBin
            ]) : undefined;
        return /* `CrdbAggregate */[
                -901480206,
                data
              ];
      }), undefined, true, undefined, 204, 204, 204, undefined, undefined, undefined, undefined);

var callstackTableColumn = BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Callstack"), callstackColumn, (function (row) {
        var callstack = row.callstack;
        var data = callstack !== undefined ? /* Head */Block.__(3, [
              "callstack",
              /* Callstack */Block.__(9, [callstack])
            ]) : undefined;
        return /* `CrdbAggregate */[
                -901480206,
                data
              ];
      }), true, undefined, undefined, 215, undefined, 215, undefined, undefined, undefined, undefined);

var tagsTableColumn = BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Tags"), tagsColumn, (function (row) {
        return /* `IssueTags */[
                766393650,
                row.issues.tags
              ];
      }), true, undefined, undefined, undefined, undefined, 160, undefined, undefined, undefined, undefined);

var assigneesTableColumn = BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Assignees"), assigneesColumn, (function (row) {
        return /* `IssueAssignees */[
                650515019,
                row.issues.assignees
              ];
      }), true, undefined, undefined, undefined, undefined, 160, undefined, undefined, undefined, undefined);

var ticketsTableColumn = BtTableColumn$BsConsole.make(I18N$BsConsole.get(undefined, "Tickets"), ticketsColumn, (function (row) {
        return /* `IssueTickets */[
                -429493458,
                row.issues.tickets
              ];
      }), true, undefined, undefined, undefined, undefined, 160, undefined, undefined, undefined, undefined);

function getColumnFromParse(column) {
  var op$prime = column.op;
  var op = op$prime !== undefined ? AggregationType$BsConsole.toString(op$prime) : "";
  var match = column.op;
  var match$1 = column.format;
  var match$2;
  var exit = 0;
  if (match !== undefined) {
    switch (match) {
      case /* Distribution */1 :
          match$2 = /* tuple */[
            215,
            215
          ];
          break;
      case /* Bin */2 :
          match$2 = /* tuple */[
            232,
            232
          ];
          break;
      case /* Range */5 :
          match$2 = /* tuple */[
            160,
            160
          ];
          break;
      case /* Unique */0 :
      case /* Head */3 :
      case /* Tail */4 :
      case /* Mean */6 :
      case /* Sum */7 :
      case /* Min */8 :
      case /* Max */9 :
          exit = 1;
          break;
      
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    match$2 = match$1 === 2 ? /* tuple */[
        215,
        215
      ] : /* tuple */[
        undefined,
        160
      ];
  }
  var match$3 = column.op;
  var disableSort = match$3 !== undefined ? match$3 === 2 || match$3 === 1 : false;
  return BtTableColumn$BsConsole.make(column.name + (" - " + op), column.name + ("__" + op), (function (row) {
                var data = Belt_Array.getBy(row.aggregations, (function (shape) {
                        if (Shape$BsConsole.getName(shape) === column.name) {
                          return Shape$BsConsole.getAggString(shape) === op;
                        } else {
                          return false;
                        }
                      }));
                return /* `CrdbAggregate */[
                        -901480206,
                        data
                      ];
              }), disableSort, undefined, undefined, match$2[0], undefined, match$2[1], undefined, undefined, getColumnAlignment(column), undefined);
}

function getAggTableColumns(aggColumns) {
  return Belt_Array.map(aggColumns, getColumnFromParse);
}

function getTableColumns(aggColumns, normBy) {
  var defaultColumns = [
    statusTableColumn,
    fingerprintTableColumn,
    countTableColumn(normBy),
    activityTableColumn,
    callstackTableColumn,
    tagsTableColumn,
    assigneesTableColumn,
    ticketsTableColumn
  ];
  var aggColumns$1 = Belt_Array.map(aggColumns, getColumnFromParse);
  return Belt_Array.concat(defaultColumns, aggColumns$1);
}

function renderHeaderCell(param, column, sortingState, onSortChange, onColumnDelete, handleSetSort) {
  var onColumnDelete$prime = function (columnName) {
    return Curry._1(onColumnDelete, getAggregationFromColumnName(columnName));
  };
  var col = column.name;
  var disableDelete = col === countColumn || col === fingerprintColumn || col === activityColumn || col === callstackColumn || col === ticketsColumn || col === tagsColumn || col === assigneesColumn ? true : col === statusColumn;
  var match = column.align;
  var align = match >= 847852583 ? /* left */-944764921 : /* right */-379319332;
  if (onSortChange === undefined) {
    return React.createElement(BtTableAdapter$BsConsole.Header.make, {
                children: I18N$BsConsole.showSkip(column.title)
              });
  }
  var col$1 = column.name;
  if (col$1 === fingerprintColumn) {
    var sort = sortingState !== undefined ? getSortingStateFromTableSort(sortingState) : Crdb$BsConsole.Sort.defaultAggregate;
    return React.createElement(TriageFingerprintSorting$BsConsole.Jsx3.make, {
                handleSetSort: handleSetSort,
                sort: sort,
                name: column.title
              });
  }
  if (col$1 !== activityColumn) {
    if (isRangeColumn(col$1)) {
      return React.createElement(BtTableAdapter$BsConsole.EnhancedRangeHeader.make, {
                  sortingState: sortingState,
                  onSortChange: onSortChange,
                  columnName: column.name,
                  align: align,
                  disableDelete: disableDelete,
                  onColumnDelete: onColumnDelete$prime,
                  children: I18N$BsConsole.showSkip(column.title)
                });
    } else {
      return React.createElement(BtTableAdapter$BsConsole.EnhancedHeader.make, {
                  sortingState: sortingState,
                  onSortChange: onSortChange,
                  disableSort: column.disableSort,
                  columnName: column.name,
                  align: align,
                  disableDelete: disableDelete,
                  onColumnDelete: onColumnDelete$prime,
                  children: I18N$BsConsole.showSkip(column.title)
                });
    }
  }
  var sort$1 = sortingState !== undefined ? getSortingStateFromTableSort(sortingState) : Crdb$BsConsole.Sort.defaultAggregate;
  return React.createElement(ActivitySortHeader$BsConsole.make, {
              handleSetSort: handleSetSort,
              sort: sort$1
            });
}

function renderStubHeader(query, attributes, onChange, param) {
  return React.createElement(AddFold$BsConsole.make, {
              query: query,
              onChange: onChange,
              attributes: attributes,
              children: React.createElement(Button.default, {
                    color: "primary",
                    classes: {
                      root: Css.style(/* :: */[
                            Css.marginLeft(Css.px(16)),
                            /* [] */0
                          ])
                    },
                    onClick: (function (_event) {
                        
                      }),
                    children: React.createElement(Row2$BsConsole.make, {
                          children: null
                        }, React.createElement(AddCircle.default, { }), React.createElement("span", {
                              className: Css.style(/* :: */[
                                    Css.marginLeft(Css.rem(0.5)),
                                    /* :: */[
                                      Css.whiteSpace(/* nowrap */867913355),
                                      /* [] */0
                                    ]
                                  ])
                            }, I18N$BsConsole.show(undefined, "Add aggregation")))
                  })
            });
}

function renderTriageDataCell(row, column, value, key, projectName, handleChangeUrl, handleAddFilters, aperture, apertureForQuery, totalCount, stabilityScore, normBy, onAggregationExpandChange, sort, config, handleSetIssue, allTags, refreshAllTags, projectUsers, workflows, fetchIssues, token, uniqueCount) {
  var handleSetIssue$1 = Curry._1(handleSetIssue, row);
  var col = column.name;
  if (col === fingerprintColumn) {
    return React.createElement(BtTableCell$BsConsole.make, {
                children: React.createElement(TriageFingerprint$BsConsole.make, {
                      fingerprint: row.fingerprint,
                      handleChangeUrl: handleChangeUrl,
                      getDetailsRoute: (function (fingerprint) {
                          return Curry._1(handleAddFilters, /* :: */[
                                      /* tuple */[
                                        "fingerprint",
                                        /* Equal */Block.__(0, [/* `String */[
                                              -976970511,
                                              fingerprint
                                            ]])
                                      ],
                                      /* [] */0
                                    ]);
                        }),
                      aperture: aperture,
                      projectName: projectName,
                      row: row
                    }),
                key: key + ("-factor__" + row.fingerprint)
              });
  }
  if (col === countColumn) {
    var errorPercentage = Numeral$BsConsole.format("0.[000]", Pervasives.string_of_float(row.count / totalCount * 100)) + "%";
    var tmp;
    var exit = 0;
    if (stabilityScore !== undefined && normBy !== undefined) {
      var applicationSessionCount = Belt_Option.flatMap(Belt_Array.getBy(row.aggregations, (function (agg) {
                  if (agg.tag || agg[0] !== "application.session") {
                    return false;
                  } else {
                    return agg[1] !== undefined;
                  }
                })), (function (agg) {
              if (agg.tag) {
                return ;
              }
              if (agg[0] !== "application.session") {
                return ;
              }
              var count = agg[1];
              if (count !== undefined && count > 0) {
                return count;
              }
              
            }));
      var score;
      if (stabilityScore.tag) {
        var match = Belt_Array.getBy(stabilityScore[0], (function (param) {
                return param[0] === row.fingerprint;
              }));
        score = match !== undefined ? match[1] : undefined;
      } else {
        score = stabilityScore[0];
      }
      var match$1 = applicationSessionCount !== undefined ? /* tuple */[
          "unique application.session",
          applicationSessionCount
        ] : /* tuple */[
          "errors",
          row.count
        ];
      var numeratorCount = match$1[1];
      var numerator = match$1[0];
      var numeratorCountString = Numeral$BsConsole.format("0,0", numeratorCount.toString());
      var groupValue = row.fingerprint;
      var metricsScoreTitle;
      if (score !== undefined) {
        if (stabilityScore.tag) {
          var scoreTotalString = Numeral$BsConsole.format("0,0", Int64.to_string(score));
          metricsScoreTitle = Curry._6(I18N$BsConsole.getf(undefined, /* Format */[
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* Char_literal */Block.__(12, [
                            /* " " */32,
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    " / ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* Char_literal */Block.__(12, [
                                            /* " " */32,
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* String_literal */Block.__(11, [
                                                    " in ",
                                                    /* String */Block.__(2, [
                                                        /* No_padding */0,
                                                        /* Char_literal */Block.__(12, [
                                                            /* " " */32,
                                                            /* String */Block.__(2, [
                                                                /* No_padding */0,
                                                                /* End_of_format */0
                                                              ])
                                                          ])
                                                      ])
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ]),
                    "%s %s / %s %s in %s %s"
                  ]), numeratorCountString, numerator, scoreTotalString, normBy, "fingerprint", groupValue);
        } else {
          var scoreTotalString$1 = Numeral$BsConsole.format("0,0", Int64.to_string(score));
          metricsScoreTitle = Curry._4(I18N$BsConsole.getf(undefined, /* Format */[
                    /* String */Block.__(2, [
                        /* No_padding */0,
                        /* Char_literal */Block.__(12, [
                            /* " " */32,
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    " / ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* Char_literal */Block.__(12, [
                                            /* " " */32,
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ])
                                  ])
                              ])
                          ])
                      ]),
                    "%s %s / %s %s"
                  ]), numeratorCountString, numerator, scoreTotalString$1, normBy);
        }
      } else {
        metricsScoreTitle = React.createElement("div", undefined, React.createElement("div", undefined, I18N$BsConsole.show(undefined, "No metrics have been captured for this group. Visit the project's 'Stability monitoring' settings to ensure the proper metrics and importers are configured.")), React.createElement(Link$BsConsole.Jsx3.make, {
                  route: /* ProjectSettingsStabilityMonitoring */Block.__(50, [projectName]),
                  handleChangeUrl: handleChangeUrl,
                  className: Css.style(/* :: */[
                        Css.important(Css.textDecoration(/* underline */131142924)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Stability monitoring settings")
                }));
      }
      tmp = React.createElement(CountNormalizationCell$BsConsole.make, {
            count: row.count,
            normalizedPercentageString: score !== undefined ? "(" + (Numeral$BsConsole.format("0.[0000]", Pervasives.string_of_float(Caml_primitive.caml_float_min(numeratorCount / Caml_int64.to_float(score) * 100, 100))) + "%)") : I18N$BsConsole.get(undefined, "(N/A)"),
            countTitle: Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* String_literal */Block.__(11, [
                              " of total errors in set",
                              /* End_of_format */0
                            ])
                        ]),
                      "%s of total errors in set"
                    ]), errorPercentage),
            normalizedTitle: metricsScoreTitle,
            normalizedTooltipInteractive: !Belt_Option.isSome(score)
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = React.createElement(Tooltip.default, {
            children: React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.textAlign(/* right */-379319332),
                          /* :: */[
                            Css.fontFamily("inconsolata"),
                            /* :: */[
                              Css.padding2(/* zero */-789508312, Css.px(4)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, React.createElement(Truncate$BsConsole.make, {
                      children: I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(row.count)))
                    })),
            title: Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* String_literal */Block.__(11, [
                              " of total errors in set",
                              /* End_of_format */0
                            ])
                        ]),
                      "%s of total errors in set"
                    ]), errorPercentage)
          });
    }
    return React.createElement(BtTableCell$BsConsole.make, {
                children: tmp,
                key: key + ("-" + (String(row.count) + column.name))
              });
  }
  if (col === activityColumn) {
    var tmp$1;
    var exit$1 = 0;
    if (typeof value === "number" || value[0] !== -901480206) {
      exit$1 = 1;
    } else {
      var match$2 = value[1];
      if (match$2 !== undefined && match$2.tag === /* Bin */2 && match$2[0] === "timestamp") {
        var data = match$2[1];
        if (data !== undefined) {
          var binRange = Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, apertureForQuery));
          var title = Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                    /* String_literal */Block.__(11, [
                        "First seen ",
                        /* String */Block.__(2, [
                            /* No_padding */0,
                            /* End_of_format */0
                          ])
                      ]),
                    "First seen %s"
                  ]), Belt_Option.getWithDefault(Belt_Option.map(row.firstSeen, (function (date) {
                          return L10N_date_time_calendar$BsConsole.Format.textAbbreviatedDay(undefined, date);
                        })), "N/A"));
          var dateRangeFormat = function (jsDate) {
            return I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.numericDayTwentyFour(undefined, jsDate));
          };
          var match$3 = row.timestampRange;
          tmp$1 = React.createElement(Tooltip.default, {
                placement: "top",
                children: React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.margin2(/* zero */-789508312, Css.px(4)),
                            /* [] */0
                          ])
                    }, React.createElement(Bin$BsConsole.make, {
                          data: data,
                          width: 164,
                          height: 26,
                          barFill: "#" + Colors$BsConsole.mutedChartViz,
                          range: binRange
                        }), match$3 !== undefined ? React.createElement(Row2$BsConsole.make, {
                            justifyContent: /* spaceBetween */516682146,
                            className: Css.style(/* :: */[
                                  Css.fontSize(Css.px(10)),
                                  /* :: */[
                                    Css.width(Css.pct(100)),
                                    /* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.grey2)),
                                      /* :: */[
                                        Css.selector("> div", /* :: */[
                                              Css.lineHeight(Css.px(8)),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]),
                            children: null
                          }, React.createElement(Tooltip.default, {
                                children: React.createElement("div", undefined, dateRangeFormat(match$3[0])),
                                title: I18N$BsConsole.get(undefined, "First seen in set")
                              }), React.createElement("div", {
                                className: Css.style(/* :: */[
                                      Css.margin2(/* zero */-789508312, Css.em(0.5)),
                                      /* [] */0
                                    ])
                              }, I18N$BsConsole.showSkip("-")), React.createElement(Tooltip.default, {
                                children: React.createElement("div", undefined, dateRangeFormat(match$3[1])),
                                title: I18N$BsConsole.get(undefined, "Last seen in set")
                              })) : null),
                title: title
              });
        } else {
          exit$1 = 1;
        }
      } else {
        exit$1 = 1;
      }
    }
    if (exit$1 === 1) {
      tmp$1 = React.createElement(EmptyValue$BsConsole.make, { });
    }
    return React.createElement(BtTableCell$BsConsole.make, {
                children: tmp$1,
                key: String(row.count) + (column.name + "__activity")
              });
  }
  if (typeof value !== "number") {
    var variant = value[0];
    if (variant >= -429493458) {
      if (variant === 650515019) {
        return React.createElement(BtTableCell$BsConsole.make, {
                    children: React.createElement(TriageAssignees$BsConsole.make, {
                          assignees: value[1],
                          tickets: row.issues.tickets,
                          handleSetIssue: handleSetIssue$1,
                          projectUsers: projectUsers
                        }),
                    key: row.fingerprint + column.name
                  });
      }
      if (variant === 766393650) {
        return React.createElement(BtTableCell$BsConsole.make, {
                    children: React.createElement(TriageTags$BsConsole.make, {
                          tags: value[1],
                          handleSetIssue: handleSetIssue$1,
                          allTags: Belt_List.fromArray(allTags),
                          refreshAllTags: refreshAllTags,
                          handleChangeUrl: handleChangeUrl,
                          handleAddFilters: handleAddFilters
                        }),
                    key: row.fingerprint + column.name
                  });
      }
      if (variant < -429493457) {
        return React.createElement(BtTableCell$BsConsole.make, {
                    children: React.createElement(TriageTickets$BsConsole.make, {
                          tickets: value[1],
                          handleSetIssue: handleSetIssue$1,
                          projectName: projectName,
                          workflows: workflows,
                          handleChangeUrl: handleChangeUrl,
                          config: config,
                          fingerprint: row.fingerprint,
                          fetchIssues: fetchIssues,
                          token: token
                        }),
                    key: row.fingerprint + column.name
                  });
      }
      
    } else {
      if (variant === -1007993960) {
        return React.createElement(BtTableCell$BsConsole.make, {
                    children: React.createElement(TriageMultiState$BsConsole.make, {
                          config: config,
                          handleSetState: (function (s, i) {
                              return Curry._2(handleSetIssue$1, ReopenGate$BsConsole.allow ? /* :: */[
                                            /* SetState */Block.__(1, [s]),
                                            /* :: */[
                                              /* SetInvariant */Block.__(2, [i]),
                                              /* [] */0
                                            ]
                                          ] : /* :: */[
                                            /* SetState */Block.__(1, [s]),
                                            /* [] */0
                                          ], undefined);
                            }),
                          aperture: aperture,
                          targetType: /* TriageCell */-1038879658,
                          state: value[1],
                          invariant: row.issues.invariants,
                          projectName: projectName,
                          sort: sort
                        }),
                    key: row.fingerprint + column.name
                  });
      }
      if (variant === -901480206) {
        var shape = value[1];
        if (shape !== undefined) {
          var tmp$2;
          switch (shape.tag | 0) {
            case /* Unique */0 :
                var value$1 = shape[1];
                var attribute = shape[0];
                var exit$2 = 0;
                if (uniqueCount !== undefined && attribute === uniqueCount[0]) {
                  var unique = uniqueCount[1];
                  var count;
                  count = unique.tag ? Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Array.getBy(unique[0], (function (group) {
                                    return group.groupValue === row.fingerprint;
                                  })), (function (group) {
                                return Caml_int64.to_int32(group.count);
                              })), 1) : Caml_int64.to_float(unique[0]);
                  var value$2 = Belt_Option.getWithDefault(value$1, 0);
                  var ratio = value$2 / count;
                  var percentage = Numeral$BsConsole.format("0.[000]", Pervasives.string_of_float(Caml_primitive.caml_float_min(ratio * 100, 100))) + ("%" + (
                      ratio > 1 ? "*" : ""
                    ));
                  var normalizedTitle = count > 0 && value$2 > 0 ? (
                      ratio > 1 ? React.createElement(Col2$BsConsole.make, {
                              children: null
                            }, React.createElement("div", undefined, Curry._4(I18N$BsConsole.showf(undefined, /* Format */[
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* String_literal */Block.__(11, [
                                                  " of unique ",
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* String_literal */Block.__(11, [
                                                          " events (",
                                                          /* String */Block.__(2, [
                                                              /* No_padding */0,
                                                              /* String_literal */Block.__(11, [
                                                                  " / ",
                                                                  /* String */Block.__(2, [
                                                                      /* No_padding */0,
                                                                      /* Char_literal */Block.__(12, [
                                                                          /* ")" */41,
                                                                          /* End_of_format */0
                                                                        ])
                                                                    ])
                                                                ])
                                                            ])
                                                        ])
                                                    ])
                                                ])
                                            ]),
                                          "%s of unique %s events (%s / %s)"
                                        ]), percentage, attribute, Base$BsConsole.floatToString(value$2), Base$BsConsole.floatToString(count))), React.createElement("div", undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                          /* String_literal */Block.__(11, [
                                              "* unique events for ",
                                              /* String */Block.__(2, [
                                                  /* No_padding */0,
                                                  /* String_literal */Block.__(11, [
                                                      " may be incomplete for current time range",
                                                      /* End_of_format */0
                                                    ])
                                                ])
                                            ]),
                                          "* unique events for %s may be incomplete for current time range"
                                        ]), attribute))) : Curry._4(I18N$BsConsole.getf(undefined, /* Format */[
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* String_literal */Block.__(11, [
                                          " of unique ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* String_literal */Block.__(11, [
                                                  " events (",
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* String_literal */Block.__(11, [
                                                          " / ",
                                                          /* String */Block.__(2, [
                                                              /* No_padding */0,
                                                              /* Char_literal */Block.__(12, [
                                                                  /* ")" */41,
                                                                  /* End_of_format */0
                                                                ])
                                                            ])
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ]),
                                  "%s of unique %s events (%s / %s)"
                                ]), percentage, attribute, Base$BsConsole.floatToString(value$2), Base$BsConsole.floatToString(count))
                    ) : Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                              /* String_literal */Block.__(11, [
                                  "Insufficient unique event data for ",
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* String_literal */Block.__(11, [
                                          " for the current timeframe",
                                          /* End_of_format */0
                                        ])
                                    ])
                                ]),
                              "Insufficient unique event data for %s for the current timeframe"
                            ]), attribute);
                  tmp$2 = React.createElement(CountNormalizationCell$BsConsole.make, {
                        count: value$2 | 0,
                        normalizedPercentageString: count > 0 ? "(" + (percentage + ")") : I18N$BsConsole.get(undefined, "(N/A)"),
                        normalizedTitle: normalizedTitle
                      });
                } else {
                  exit$2 = 2;
                }
                if (exit$2 === 2) {
                  var value$prime = value$1 !== undefined ? /* Raw */Block.__(0, [/* `Float */[
                          365180284,
                          value$1
                        ]]) : undefined;
                  tmp$2 = React.createElement(FormatRenderer$BsConsole.make, {
                        value: value$prime,
                        attribute: attribute,
                        handleChangeUrl: handleChangeUrl,
                        handleAddFilters: handleAddFilters
                      });
                }
                break;
            case /* Bin */2 :
                var bin = shape[1];
                if (bin !== undefined) {
                  var attribute$1 = shape[0];
                  tmp$2 = React.createElement(Tooltip.default, {
                        placement: "top",
                        children: React.createElement(ButtonBase.default, {
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.padding(Css.px(4)),
                                      /* :: */[
                                        Css.hover(/* :: */[
                                              Css.backgroundColor(Css.hex(Colors$BsConsole.blackA10)),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]
                                    ])
                              },
                              onClick: (function (param) {
                                  return Curry._1(onAggregationExpandChange, /* Bin */Block.__(1, [
                                                row.fingerprint,
                                                attribute$1,
                                                bin
                                              ]));
                                }),
                              disableRipple: true,
                              children: React.createElement(Bin$BsConsole.make, {
                                    data: bin,
                                    width: 192,
                                    height: 28
                                  })
                            }),
                        title: I18N$BsConsole.get(undefined, "Click to expand chart")
                      });
                } else {
                  tmp$2 = React.createElement(EmptyValue$BsConsole.make, { });
                }
                break;
            case /* Range */8 :
                var range = shape[1];
                var attribute$2 = shape[0];
                tmp$2 = range !== undefined ? React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        justifyContent: /* spaceBetween */516682146,
                        className: Css.style(/* :: */[
                              Css.fontFamily("inconsolata"),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement(FormatRenderer$BsConsole.make, {
                            value: range[0],
                            attribute: attribute$2,
                            handleChangeUrl: handleChangeUrl,
                            handleAddFilters: handleAddFilters,
                            className: Css.style(/* :: */[
                                  Css.unsafe("maxWidth", "calc(50% - 1rem)"),
                                  /* [] */0
                                ])
                          }), React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.textAlign(/* center */98248149),
                                  /* :: */[
                                    Css.width(Css.rem(2)),
                                    /* :: */[
                                      Css.padding2(/* zero */-789508312, Css.rem(0.5)),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          }, I18N$BsConsole.showSkip("-")), React.createElement(FormatRenderer$BsConsole.make, {
                            value: range[1],
                            attribute: attribute$2,
                            handleChangeUrl: handleChangeUrl,
                            handleAddFilters: handleAddFilters,
                            className: Css.style(/* :: */[
                                  Css.unsafe("maxWidth", "calc(50% - 1rem)"),
                                  /* [] */0
                                ])
                          })) : React.createElement(EmptyValue$BsConsole.make, { });
                break;
            case /* Distribution */9 :
                var value$3 = shape[1];
                if (value$3 !== undefined) {
                  var attribute$3 = shape[0];
                  tmp$2 = React.createElement(CondensedDistribution$BsConsole.Proto.make, {
                        attribute: attribute$3,
                        distribution: value$3,
                        groupCount: row.count,
                        onExpansion: (function (param) {
                            return Curry._1(onAggregationExpandChange, /* Distribution */Block.__(0, [
                                          row.fingerprint,
                                          attribute$3
                                        ]));
                          })
                      });
                } else {
                  tmp$2 = React.createElement(EmptyValue$BsConsole.make, { });
                }
                break;
            default:
              var value$4 = shape[1];
              var attribute$4 = shape[0];
              var exit$3 = 0;
              if (value$4 !== undefined) {
                var exit$4 = 0;
                switch (value$4.tag | 0) {
                  case /* Raw */0 :
                  case /* None_ */1 :
                      exit$4 = 3;
                      break;
                  case /* Classifiers */4 :
                      tmp$2 = React.createElement(FormatPopover$BsConsole.make, {
                            content: React.createElement(FormatRenderer$BsConsole.make, {
                                  value: value$4,
                                  attribute: attribute$4,
                                  handleChangeUrl: handleChangeUrl,
                                  handleAddFilters: handleAddFilters,
                                  config: config,
                                  token: token,
                                  aperture: aperture,
                                  projectName: projectName,
                                  fingerprint: row.fingerprint
                                }),
                            children: React.createElement(FormatRenderer$BsConsole.make, {
                                  value: value$4,
                                  attribute: attribute$4,
                                  handleChangeUrl: handleChangeUrl,
                                  handleAddFilters: handleAddFilters,
                                  config: config,
                                  token: token,
                                  aperture: aperture,
                                  projectName: projectName,
                                  fingerprint: row.fingerprint
                                })
                          });
                      break;
                  default:
                    exit$3 = 2;
                }
                if (exit$4 === 3) {
                  var match$4 = value$4[0];
                  if (typeof match$4 === "number" || match$4[0] !== -976970511 || attribute$4 === "fingerprint") {
                    exit$3 = 2;
                  } else {
                    tmp$2 = React.createElement(FormatPopover$BsConsole.WithCopyButton.make, {
                          value: match$4[1],
                          children: React.createElement(FormatRenderer$BsConsole.make, {
                                value: value$4,
                                attribute: attribute$4,
                                handleChangeUrl: handleChangeUrl,
                                handleAddFilters: handleAddFilters,
                                config: config,
                                token: token,
                                aperture: aperture,
                                projectName: projectName,
                                fingerprint: row.fingerprint
                              })
                        });
                  }
                }
                
              } else {
                exit$3 = 2;
              }
              if (exit$3 === 2) {
                tmp$2 = React.createElement(FormatRenderer$BsConsole.make, {
                      value: value$4,
                      attribute: attribute$4,
                      handleChangeUrl: handleChangeUrl,
                      handleAddFilters: handleAddFilters,
                      config: config,
                      token: token,
                      aperture: aperture,
                      projectName: projectName,
                      fingerprint: row.fingerprint
                    });
              }
              
          }
          return React.createElement(BtTableCell$BsConsole.make, {
                      children: tmp$2,
                      key: row.fingerprint + column.name
                    });
        }
        
      }
      
    }
  }
  return React.createElement(BtTableCell$BsConsole.make, {
              children: React.createElement(EmptyValue$BsConsole.make, { }),
              key: row.fingerprint + column.name
            });
}

function getTableContainerClassName(rows) {
  var rowStyles = Css.style(Belt_List.fromArray(Belt_Array.mapWithIndex(rows, (function (idx, group) {
                  var index = String(idx + 1 | 0);
                  return Css.selector(".BtTableBody > .BtTableRow:nth-of-type(" + (String(index) + ") > td.BtTableSelectCell"), /* :: */[
                              Css.boxShadows(/* :: */[
                                    Css.boxShadow(Css.px(3), undefined, undefined, undefined, true, Css.hex(Issues$BsConsole.State.toColor(group.issues.state))),
                                    /* :: */[
                                      Css.boxShadow(Css.px(-1), undefined, undefined, undefined, true, Css.hex(Colors$BsConsole.grey6)),
                                      /* [] */0
                                    ]
                                  ]),
                              /* [] */0
                            ]);
                }))));
  var match = rows.length >= 4;
  var controlHeight = match ? (
      IFrame$BsConsole.isIFrame ? Css.style(/* :: */[
              Css.unsafe("maxHeight", "unset !important"),
              /* :: */[
                Css.minHeight(Css.px(249)),
                /* [] */0
              ]
            ]) : Css.style(/* :: */[
              Css.unsafe("maxHeight", "calc(100vh - 146px)"),
              /* :: */[
                Css.minHeight(Css.px(249)),
                /* [] */0
              ]
            ])
    ) : "";
  return Css.merge(/* :: */[
              rowStyles,
              /* :: */[
                controlHeight,
                /* :: */[
                  Css.style(/* :: */[
                        Css.selector(".BtTableBody > .BtTableRow .triage-action", /* :: */[
                              Css.unsafe("color", "inherit"),
                              /* :: */[
                                Css.opacity(0.5),
                                /* [] */0
                              ]
                            ]),
                        /* :: */[
                          Css.selector(".BtTableBody > .BtTableRow:hover .triage-action", /* :: */[
                                Css.opacity(1.0),
                                /* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.accent)),
                                  /* [] */0
                                ]
                              ]),
                          /* :: */[
                            Css.selector("tr.BtTableRow:hover .frame-count", /* :: */[
                                  Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                                  /* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.white)),
                                    /* [] */0
                                  ]
                                ]),
                            /* :: */[
                              Css.selector("tr.BtTableRow:hover .triage-fingerprint-link", /* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.accent)),
                                    /* :: */[
                                      Css.textDecoration(/* underline */131142924),
                                      /* [] */0
                                    ]
                                  ]),
                              /* :: */[
                                Css.selector("th:hover .delete-column", /* :: */[
                                      Css.display(/* block */888960333),
                                      /* :: */[
                                        Css.marginLeft(Css.px(4)),
                                        /* [] */0
                                      ]
                                    ]),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]),
                  /* [] */0
                ]
              ]
            ]);
}

function getRowId(row) {
  return row.fingerprint;
}

function makeQuery(attributes, query, normBy) {
  if (normBy === undefined) {
    return query;
  }
  var match = Belt_List.getBy(attributes, (function (attr) {
          if (attr.name === "application.session") {
            return attr.statistics.used;
          } else {
            return false;
          }
        }));
  if (match === undefined) {
    return query;
  }
  var __x = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getFold, query), Crdb$BsConsole.Fold.empty);
  var fold = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
            "application.session",
            /* Unique */4
          ]), __x);
  return /* Aggregate */Block.__(0, [
            Curry._1(Crdb$BsConsole.Query.getAperture, query),
            Curry._1(Crdb$BsConsole.Query.getFactor, query),
            fold,
            Curry._1(Crdb$BsConsole.Query.getSort, query),
            undefined,
            Curry._1(Crdb$BsConsole.Query.getHavings, query)
          ]);
}

function useTableData(projectName, token, query, normBy, sort, attributes, uniqueCount) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setTableRemote = match[1];
  var match$1 = React.useState((function () {
          
        }));
  var setSortingState = match$1[1];
  var queryForRequest = SummedEvents$BsConsole.Normalize.useQueryForAggTable(attributes, query, normBy);
  var match$2 = FetchIssuesData$BsConsole.use(projectName, token, queryForRequest);
  var triageGroupsRemote = match$2[0];
  React.useEffect((function () {
          if (typeof triageGroupsRemote === "number") {
            if (triageGroupsRemote === /* NotAsked */0) {
              Curry._1(setTableRemote, (function (param) {
                      return /* NotAsked */0;
                    }));
            } else {
              Curry._1(setTableRemote, (function (param) {
                      return /* Loading */1;
                    }));
            }
          } else if (triageGroupsRemote.tag) {
            var msg = triageGroupsRemote[0];
            Curry._1(setTableRemote, (function (param) {
                    return /* Failure */Block.__(1, [msg]);
                  }));
          } else {
            var match = triageGroupsRemote[0];
            var requestQuery = match.requestQuery;
            var setPage = match.setPage;
            var page = match.page;
            var projectUsers = match.projectUsers;
            var meta = match.meta;
            var columns = match.columns;
            var rows = match.rows;
            var loading = match.loading;
            var triageGroups = match.triageGroups;
            var aggColumns = SummedEvents$BsConsole.Normalize.sanitizeAggColumns(match.aggColumns, Curry._1(Crdb$BsConsole.Query.getFold, query));
            var columns$prime = getTableColumns(aggColumns, normBy);
            var containerClassName = getTableContainerClassName(triageGroups);
            var columnOrder = BtTableSessionData$BsConsole.ColumnOrder.get(projectName, /* Triage */0);
            var columnWidths = BtTableSessionData$BsConsole.ColumnWidth.get(projectName, /* Triage */0);
            Curry._1(setTableRemote, (function (param) {
                    return /* Success */Block.__(0, [{
                                columns: columns$prime,
                                rows: triageGroups,
                                groups: rows,
                                columnDescs: columns,
                                loading: loading,
                                page: page,
                                setPage: setPage,
                                projectUsers: projectUsers,
                                meta: meta,
                                containerClassName: containerClassName,
                                columnOrder: columnOrder,
                                columnWidths: columnWidths,
                                requestQuery: requestQuery
                              }]);
                  }));
          }
          
        }), /* tuple */[
        triageGroupsRemote,
        normBy,
        queryForRequest,
        uniqueCount
      ]);
  React.useEffect((function () {
          var sortingState = getTableSortingState(sort);
          Curry._1(setSortingState, (function (param) {
                  return sortingState;
                }));
          
        }), /* tuple */[
        Curry._1(Crdb$BsConsole.Sort.getAttribute, sort),
        Curry._1(Crdb$BsConsole.Sort.getAggString, sort),
        SortOrder$BsConsole.Ordering.toString(Curry._1(Crdb$BsConsole.Sort.getOrdering, sort))
      ]);
  return /* tuple */[
          match[0],
          match$1[0],
          match$2[1]
        ];
}

exports.statusColumn = statusColumn;
exports.fingerprintColumn = fingerprintColumn;
exports.countColumn = countColumn;
exports.activityColumn = activityColumn;
exports.callstackColumn = callstackColumn;
exports.tagsColumn = tagsColumn;
exports.assigneesColumn = assigneesColumn;
exports.ticketsColumn = ticketsColumn;
exports.orderingToDirection = orderingToDirection;
exports.directionToOrdering = directionToOrdering;
exports.rangeToColumnName = rangeToColumnName;
exports.isRangeColumn = isRangeColumn;
exports.getAggregationFromColumnName = getAggregationFromColumnName;
exports.getSortingStateFromTableSort = getSortingStateFromTableSort;
exports.getTableSortingState = getTableSortingState;
exports.onSortChange = onSortChange;
exports.getColumnAlignment = getColumnAlignment;
exports.statusTableColumn = statusTableColumn;
exports.fingerprintTableColumn = fingerprintTableColumn;
exports.countTableColumn = countTableColumn;
exports.activityTableColumn = activityTableColumn;
exports.callstackTableColumn = callstackTableColumn;
exports.tagsTableColumn = tagsTableColumn;
exports.assigneesTableColumn = assigneesTableColumn;
exports.ticketsTableColumn = ticketsTableColumn;
exports.getColumnFromParse = getColumnFromParse;
exports.getAggTableColumns = getAggTableColumns;
exports.getTableColumns = getTableColumns;
exports.renderHeaderCell = renderHeaderCell;
exports.renderStubHeader = renderStubHeader;
exports.renderTriageDataCell = renderTriageDataCell;
exports.getTableContainerClassName = getTableContainerClassName;
exports.getRowId = getRowId;
exports.makeQuery = makeQuery;
exports.useTableData = useTableData;
/* statusTableColumn Not a pure module */
