// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Route$BsConsole = require("./route.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtSelect$BsConsole = require("./BtSelect.js");
var DateSelect$BsConsole = require("./DateSelect.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

var toolbar = Css.style(/* :: */[
      Css.alignItems(/* center */98248149),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.white)),
        /* :: */[
          Css.unsafe("backgroundColor", "inherit"),
          /* [] */0
        ]
      ]
    ]);

var link = Css.style(/* :: */[
      Css.textDecoration(/* none */-922086728),
      /* :: */[
        Css.unsafe("color", "inherit"),
        /* :: */[
          Css.margin(Css.px(-16)),
          /* :: */[
            Css.padding(Css.px(16)),
            /* :: */[
              Css.width(Css.pct(100.0)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Style = {
  toolbar: toolbar,
  link: link
};

function stripTimestampFilters(aperture) {
  var f = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var f$prime = Curry._2(Crdb$BsConsole.Filters.keep, (function (filter) {
          return Curry._1(Crdb$BsConsole.Filter.getAttribute, filter) !== "timestamp";
        }), f);
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, f$prime, aperture);
}

function setCustomTimeAperture(route, ta) {
  if (typeof route === "number") {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "granSelect.re",
            94,
            9
          ]
        ];
  }
  switch (route.tag | 0) {
    case /* ProjectOverview */8 :
        return /* ProjectOverview */Block.__(8, [
                  route[0],
                  {
                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, ta, route[1].aperture)
                  }
                ]);
    case /* ProjectCompareReleases */9 :
        var qs = route[2];
        return /* ProjectCompareReleases */Block.__(9, [
                  route[0],
                  route[1],
                  {
                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, ta, qs.aperture),
                    groups: qs.groups
                  }
                ]);
    case /* ProjectTriage */11 :
        var qs$1 = route[1];
        return /* ProjectTriage */Block.__(11, [
                  route[0],
                  {
                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, ta, qs$1.aperture),
                    sort: qs$1.sort,
                    stats: qs$1.stats,
                    havings: qs$1.havings,
                    fold: qs$1.fold,
                    normBy: qs$1.normBy,
                    similarity: qs$1.similarity
                  }
                ]);
    case /* ProjectQueryBuilder */13 :
        var qs$2 = route[1];
        return /* ProjectQueryBuilder */Block.__(13, [
                  route[0],
                  {
                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, ta, qs$2.aperture),
                    factor: qs$2.factor,
                    havings: qs$2.havings,
                    fold: qs$2.fold,
                    sort: qs$2.sort,
                    normBy: qs$2.normBy,
                    highlightExporter: qs$2.highlightExporter
                  }
                ]);
    case /* ProjectInstances */15 :
        var qs$3 = route[1];
        return /* ProjectInstances */Block.__(15, [
                  route[0],
                  {
                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, ta, qs$3.aperture),
                    select: qs$3.select,
                    sort: qs$3.sort
                  }
                ]);
    case /* ProjectDebugger */17 :
        var qs$4 = route[1];
        return /* ProjectDebugger */Block.__(17, [
                  route[0],
                  {
                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, ta, qs$4.aperture),
                    sort: qs$4.sort,
                    s: qs$4.s
                  }
                ]);
    case /* ProjectDebugger2 */19 :
        var qs$5 = route[1];
        return /* ProjectDebugger2 */Block.__(19, [
                  route[0],
                  {
                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, ta, qs$5.aperture),
                    state: qs$5.state
                  }
                ]);
    case /* ProjectFlamegraph */20 :
        var qs$6 = route[1];
        return /* ProjectFlamegraph */Block.__(20, [
                  route[0],
                  {
                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, ta, qs$6.aperture),
                    weighted: qs$6.weighted,
                    reversed: qs$6.reversed
                  }
                ]);
    case /* ProjectSimilarity */21 :
        var qs$7 = route[1];
        return /* ProjectSimilarity */Block.__(21, [
                  route[0],
                  {
                    aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, ta, qs$7.aperture),
                    factor: qs$7.factor,
                    havings: qs$7.havings,
                    fold: qs$7.fold,
                    sort: qs$7.sort,
                    normBy: qs$7.normBy,
                    highlightExporter: qs$7.highlightExporter
                  }
                ]);
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "granSelect.re",
              94,
              9
            ]
          ];
  }
}

function getRoute(route, granularityString) {
  var timeAperture = Crdb$BsConsole.granularityStringToTimeAperture(granularityString);
  if (typeof route === "number") {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "granSelect.re",
            183,
            9
          ]
        ];
  }
  switch (route.tag | 0) {
    case /* ProjectOverview */8 :
        return /* ProjectOverview */Block.__(8, [
                  route[0],
                  {
                    aperture: stripTimestampFilters(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, route[1].aperture))
                  }
                ]);
    case /* ProjectCompareReleases */9 :
        var qs = route[2];
        return /* ProjectCompareReleases */Block.__(9, [
                  route[0],
                  route[1],
                  {
                    aperture: stripTimestampFilters(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, qs.aperture)),
                    groups: qs.groups
                  }
                ]);
    case /* ProjectTriage */11 :
        var qs$1 = route[1];
        return /* ProjectTriage */Block.__(11, [
                  route[0],
                  {
                    aperture: stripTimestampFilters(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, qs$1.aperture)),
                    sort: qs$1.sort,
                    stats: qs$1.stats,
                    havings: qs$1.havings,
                    fold: qs$1.fold,
                    normBy: qs$1.normBy,
                    similarity: qs$1.similarity
                  }
                ]);
    case /* ProjectQueryBuilder */13 :
        var qs$2 = route[1];
        return /* ProjectQueryBuilder */Block.__(13, [
                  route[0],
                  {
                    aperture: stripTimestampFilters(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, qs$2.aperture)),
                    factor: qs$2.factor,
                    havings: qs$2.havings,
                    fold: qs$2.fold,
                    sort: qs$2.sort,
                    normBy: qs$2.normBy,
                    highlightExporter: qs$2.highlightExporter
                  }
                ]);
    case /* ProjectInstances */15 :
        var qs$3 = route[1];
        return /* ProjectInstances */Block.__(15, [
                  route[0],
                  {
                    aperture: stripTimestampFilters(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, qs$3.aperture)),
                    select: qs$3.select,
                    sort: qs$3.sort
                  }
                ]);
    case /* ProjectDebugger */17 :
        var qs$4 = route[1];
        return /* ProjectDebugger */Block.__(17, [
                  route[0],
                  {
                    aperture: stripTimestampFilters(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, qs$4.aperture)),
                    sort: qs$4.sort,
                    s: qs$4.s
                  }
                ]);
    case /* ProjectDebugger2 */19 :
        return /* ProjectDebugger2 */Block.__(19, [
                  route[0],
                  {
                    aperture: stripTimestampFilters(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, route[1].aperture)),
                    state: undefined
                  }
                ]);
    case /* ProjectFlamegraph */20 :
        var qs$5 = route[1];
        return /* ProjectFlamegraph */Block.__(20, [
                  route[0],
                  {
                    aperture: stripTimestampFilters(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, qs$5.aperture)),
                    weighted: qs$5.weighted,
                    reversed: qs$5.reversed
                  }
                ]);
    case /* ProjectSimilarity */21 :
        var qs$6 = route[1];
        return /* ProjectSimilarity */Block.__(21, [
                  route[0],
                  {
                    aperture: stripTimestampFilters(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, qs$6.aperture)),
                    factor: qs$6.factor,
                    havings: qs$6.havings,
                    fold: qs$6.fold,
                    sort: qs$6.sort,
                    normBy: qs$6.normBy,
                    highlightExporter: qs$6.highlightExporter
                  }
                ]);
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "granSelect.re",
              183,
              9
            ]
          ];
  }
}

var options = [
  {
    title: I18N$BsConsole.get(undefined, "last hour"),
    value: "hour"
  },
  {
    title: I18N$BsConsole.get(undefined, "last day"),
    value: "day"
  },
  {
    title: I18N$BsConsole.get(undefined, "last 7 days"),
    value: "week"
  },
  {
    title: I18N$BsConsole.get(undefined, "last 30 days"),
    value: "month"
  },
  {
    title: I18N$BsConsole.get(undefined, "last 90 days"),
    value: "quarter"
  },
  {
    title: I18N$BsConsole.get(undefined, "last year"),
    value: "year"
  },
  {
    title: I18N$BsConsole.get(undefined, "all time"),
    value: "all"
  },
  {
    title: I18N$BsConsole.get(undefined, "custom"),
    value: "custom"
  },
  {
    title: I18N$BsConsole.get(undefined, "first seen in last hour"),
    value: "first-seen-hour"
  },
  {
    title: I18N$BsConsole.get(undefined, "first seen in last day"),
    value: "first-seen-day"
  },
  {
    title: I18N$BsConsole.get(undefined, "first seen in last 7 days"),
    value: "first-seen-week"
  },
  {
    title: I18N$BsConsole.get(undefined, "first seen in last 30 days"),
    value: "first-seen-month"
  },
  {
    title: I18N$BsConsole.get(undefined, "first seen in last 90 days"),
    value: "first-seen-quarter"
  },
  {
    title: I18N$BsConsole.get(undefined, "first seen in last year"),
    value: "first-seen-year"
  }
];

var OptionsList = {
  options: options
};

var component = ReasonReact.reducerComponent("GranSelect-BsConsole");

function make(route, handleChangeUrl, aperture, selectClassNameOpt, selectRootClassNameOpt, _children) {
  var selectClassName = selectClassNameOpt !== undefined ? selectClassNameOpt : "";
  var selectRootClassName = selectRootClassNameOpt !== undefined ? selectRootClassNameOpt : "";
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture));
              var match = Crdb$BsConsole.granularityValueForAperture(aperture);
              var value;
              switch (match) {
                case "all" :
                    value = I18N$BsConsole.get(undefined, "all time");
                    break;
                case "custom" :
                case "custom-first-seen" :
                    value = I18N$BsConsole.get(undefined, "custom");
                    break;
                case "day" :
                    value = I18N$BsConsole.get(undefined, "last day");
                    break;
                case "first-seen-day" :
                    value = I18N$BsConsole.get(undefined, "first seen in the last day");
                    break;
                case "first-seen-hour" :
                    value = I18N$BsConsole.get(undefined, "first seen in the last hour");
                    break;
                case "first-seen-month" :
                    value = I18N$BsConsole.get(undefined, "first seen in the last 30 days");
                    break;
                case "first-seen-quarter" :
                    value = I18N$BsConsole.get(undefined, "first seen in the last 90 days");
                    break;
                case "first-seen-week" :
                    value = I18N$BsConsole.get(undefined, "first seen in the last 7 days");
                    break;
                case "first-seen-year" :
                    value = I18N$BsConsole.get(undefined, "first seen in the last year");
                    break;
                case "hour" :
                    value = I18N$BsConsole.get(undefined, "last hour");
                    break;
                case "month" :
                    value = I18N$BsConsole.get(undefined, "last 30 days");
                    break;
                case "quarter" :
                    value = I18N$BsConsole.get(undefined, "last 90 days");
                    break;
                case "week" :
                    value = I18N$BsConsole.get(undefined, "last 7 days");
                    break;
                case "year" :
                    value = I18N$BsConsole.get(undefined, "last year");
                    break;
                default:
                  value = I18N$BsConsole.get(undefined, "custom");
              }
              return React.createElement(React.Fragment, undefined, React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.unsafe("backgroundColor", "inherit"),
                                    /* [] */0
                                  ]),
                              onClick: (function (param) {
                                  if (self.state.open_) {
                                    return ;
                                  } else {
                                    return Curry._1(self.send, /* Open */0);
                                  }
                                })
                            }, ReasonReact.element(undefined, undefined, BtSelect$BsConsole.make((function ($$event, _element) {
                                        var value = $$event.target.value;
                                        if (value === "custom") {
                                          if (self.state.open_) {
                                            return Curry._1(self.send, /* ForceDateOpen */2);
                                          } else {
                                            return Curry._1(self.send, /* Open */0);
                                          }
                                        } else {
                                          return Curry._1(self.send, /* CloseAndHandle */Block.__(0, [value]));
                                        }
                                      }), /* `String */[
                                      -976970511,
                                      value
                                    ], selectClassName, I18N$BsConsole.get(undefined, "Time frame"), I18N$BsConsole.showSkip, self.state.open_, (function (param) {
                                        return Curry._1(self.send, /* Close */1);
                                      }), (function (param) {
                                        return Curry._1(self.send, /* Open */0);
                                      }), Css.merge(/* :: */[
                                          selectRootClassName,
                                          /* :: */[
                                            "walkthrough--project_toolbar--time_frame_dropdown",
                                            /* [] */0
                                          ]
                                        ]), undefined, [Belt_Array.map(options, (function (option) {
                                              if (option.value === "custom") {
                                                if (Crdb$BsConsole.granularityValueForAperture(aperture) === "custom-first-seen") {
                                                  return ReasonReact.element("custom-first-seen", undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                                  -976970511,
                                                                  "custom-first-seen"
                                                                ], undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Button */242538002, undefined, /* Inherit */-72987685, undefined, undefined, [I18N$BsConsole.showSkip(option.title)]))]));
                                                } else {
                                                  return ReasonReact.element("custom", undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                                  -976970511,
                                                                  "custom"
                                                                ], undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Button */242538002, undefined, /* Inherit */-72987685, undefined, undefined, [I18N$BsConsole.showSkip(option.title)]))]));
                                                }
                                              } else {
                                                return ReasonReact.element(option.value, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, /* `String */[
                                                                -976970511,
                                                                option.value
                                                              ], undefined, undefined, [React.createElement("a", {
                                                                      className: link,
                                                                      href: Route$BsConsole.pathForRoute(getRoute(route, option.value)),
                                                                      onClick: (function (e) {
                                                                          e.preventDefault();
                                                                          
                                                                        })
                                                                    }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Button */242538002, undefined, /* Inherit */-72987685, undefined, undefined, [I18N$BsConsole.showSkip(option.title)])))]));
                                              }
                                            }))]))), ReasonReact.element(JSON.stringify(Curry._1(Crdb$BsConsole.Aperture.toJson, aperture)), undefined, DateSelect$BsConsole.make((function (param) {
                                    var to_ = param[1];
                                    var from_ = param[0];
                                    var match = self.state.errorMessage;
                                    if (match !== undefined) {
                                      return ;
                                    }
                                    Curry._1(self.send, /* SetError */Block.__(1, [undefined]));
                                    Curry._1(self.send, /* UnForceDateOpen */3);
                                    var ta = param[2] ? Curry._1(Crdb$BsConsole.TimeAperture.create, /* Custom */Block.__(1, [/* tuple */[
                                                from_,
                                                to_
                                              ]])) : Curry._1(Crdb$BsConsole.TimeAperture.create, /* CustomFirstSeen */Block.__(3, [/* tuple */[
                                                from_,
                                                to_
                                              ]]));
                                    Curry._1(handleChangeUrl, setCustomTimeAperture(route, ta));
                                    return Curry._1(self.send, /* Close */1);
                                  }), Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture)), (function (param) {
                                    return Curry._1(self.send, /* Close */1);
                                  }), (function (param) {
                                    return Curry._1(self.send, /* UnForceDateOpen */3);
                                  }), (function (message) {
                                    return Curry._1(self.send, /* SetError */Block.__(1, [message]));
                                  }), self.state.dateOpen, Caml_option.some(self.state.errorMessage), [])));
            }),
          initialState: (function (param) {
              return {
                      open_: false,
                      dateOpen: false,
                      errorMessage: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case /* Open */0 :
                      return /* Update */Block.__(0, [{
                                  open_: true,
                                  dateOpen: state.dateOpen,
                                  errorMessage: state.errorMessage
                                }]);
                  case /* Close */1 :
                      return /* Update */Block.__(0, [{
                                  open_: false,
                                  dateOpen: false,
                                  errorMessage: state.errorMessage
                                }]);
                  case /* ForceDateOpen */2 :
                      return /* Update */Block.__(0, [{
                                  open_: state.open_,
                                  dateOpen: true,
                                  errorMessage: state.errorMessage
                                }]);
                  case /* UnForceDateOpen */3 :
                      return /* Update */Block.__(0, [{
                                  open_: state.open_,
                                  dateOpen: false,
                                  errorMessage: state.errorMessage
                                }]);
                  
                }
              } else {
                if (action.tag) {
                  return /* Update */Block.__(0, [{
                              open_: state.open_,
                              dateOpen: state.dateOpen,
                              errorMessage: action[0]
                            }]);
                }
                var value = action[0];
                return /* SideEffects */Block.__(1, [(function (self) {
                              Curry._1(self.send, /* Close */1);
                              setTimeout((function (param) {
                                      return Curry._1(handleChangeUrl, getRoute(route, value));
                                    }), 0);
                              
                            })]);
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(reactProps.route, reactProps.handleChangeUrl, reactProps.aperture, Caml_option.undefined_to_opt(reactProps.selectClassName), Caml_option.undefined_to_opt(reactProps.selectRootClassName), []);
      }));

var Jsx3 = {
  make: make$1
};

exports.Style = Style;
exports.stripTimestampFilters = stripTimestampFilters;
exports.setCustomTimeAperture = setCustomTimeAperture;
exports.getRoute = getRoute;
exports.OptionsList = OptionsList;
exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* toolbar Not a pure module */
