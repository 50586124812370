// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BtTable$BsConsole = require("../BtTable.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Services$BsConsole = require("../Services.js");
var Clipboard$BsConsole = require("../bindings/Clipboard.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTableRow$BsConsole = require("../BtTableRow.js");
var Tab = require("@material-ui/core/Tab");
var BtTableBody$BsConsole = require("../BtTableBody.js");
var BtTableCell$BsConsole = require("../BtTableCell.js");
var BtTableHead$BsConsole = require("../BtTableHead.js");
var Tabs = require("@material-ui/core/Tabs");
var BtTypography$BsConsole = require("../BtTypography.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var DescribeTask$BsConsole = require("../DescribeTask.js");
var Paper = require("@material-ui/core/Paper");
var Edit = require("@material-ui/icons/Edit");
var Info = require("@material-ui/icons/Info");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Build = require("@material-ui/icons/Build");
var Close = require("@material-ui/icons/Close");
var L10N_date_time$BsConsole = require("../L10N_date_time.js");
var Divider = require("@material-ui/core/Divider");
var Popover = require("@material-ui/core/Popover");
var Tooltip = require("@material-ui/core/Tooltip");
var Delete = require("@material-ui/icons/Delete");
var Collapse = require("@material-ui/core/Collapse");
var MenuItem = require("@material-ui/core/MenuItem");
var Warning = require("@material-ui/icons/Warning");
var BtTableContainer$BsConsole = require("../BtTableContainer.js");
var MoreVert = require("@material-ui/icons/MoreVert");
var IconButton = require("@material-ui/core/IconButton");
var CardContent = require("@material-ui/core/CardContent");
var Description = require("@material-ui/icons/Description");
var ApiStabilityMonitoring$BsConsole = require("../ApiStabilityMonitoring.js");
var KeyboardArrowUp = require("@material-ui/icons/KeyboardArrowUp");
var FetchStabilityMonitoring$BsConsole = require("./FetchStabilityMonitoring.js");
var StabilityMonitoringForms$BsConsole = require("./StabilityMonitoringForms.js");
var KeyboardArrowDown = require("@material-ui/icons/KeyboardArrowDown");
var KeyboardArrowLeft = require("@material-ui/icons/KeyboardArrowLeft");
var KeyboardArrowRight = require("@material-ui/icons/KeyboardArrowRight");

var menuItem = Css.style(/* :: */[
      Css.padding(Css.px(8)),
      /* :: */[
        Css.unsafe("height", "unset"),
        /* :: */[
          Css.fontSize(Css.px(13)),
          /* :: */[
            Css.lineHeight(/* `abs */[
                  4845682,
                  1.0
                ]),
            /* :: */[
              Css.hover(/* :: */[
                    Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.white)),
                      /* [] */0
                    ]
                  ]),
              /* :: */[
                Css.focus(/* :: */[
                      Css.unsafe("outline", "none"),
                      /* :: */[
                        Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                        /* :: */[
                          Css.color(Css.hex(Colors$BsConsole.white)),
                          /* [] */0
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

function getLevelColor(param) {
  switch (param) {
    case "Info" :
        return Css.hex(Colors$BsConsole.greenExtraLight);
    case "Warning" :
        return Css.hex(Colors$BsConsole.redLight);
    default:
      return Css.hex(Colors$BsConsole.white);
  }
}

function PS_StabilityMonitoring$LogLine(Props) {
  var level = Props.level;
  var time = Props.time;
  var importerName = Props.importerName;
  var message = Props.message;
  return React.createElement("p", {
              className: Css.style(/* :: */[
                    Css.margin2(Css.px(2), /* zero */-789508312),
                    /* [] */0
                  ])
            }, I18N$BsConsole.showSkip("["), React.createElement("span", {
                  className: Css.style(/* :: */[
                        Css.color(getLevelColor(level)),
                        /* [] */0
                      ])
                }, I18N$BsConsole.showSkip(level)), I18N$BsConsole.showSkip("]["), React.createElement("span", {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blueLight)),
                        /* [] */0
                      ])
                }, I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.numericDayTwentyFour(undefined, time))), I18N$BsConsole.showSkip("]["), React.createElement("span", {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.orangeExtraLight)),
                        /* [] */0
                      ])
                }, I18N$BsConsole.showSkip(importerName)), I18N$BsConsole.showSkip("] "), React.createElement("span", undefined, I18N$BsConsole.dynamic(message)));
}

var LogLine = {
  getLevelColor: getLevelColor,
  make: PS_StabilityMonitoring$LogLine
};

function getImporterName(importers, importerId) {
  var importer = Belt_Array.getBy(importers, (function (importer) {
          return importer.id === importerId;
        }));
  if (importer !== undefined) {
    return importer.name;
  } else {
    return importerId;
  }
}

function PS_StabilityMonitoring$ServiceLogs(Props) {
  var serviceEndpoint = Props.serviceEndpoint;
  var projectName = Props.projectName;
  var token = Props.token;
  var onClose = Props.onClose;
  var importers = Props.importers;
  var source = Props.source;
  var importer = Props.importer;
  var match = source !== undefined ? (
      importer !== undefined ? Js_exn.raiseError("Missing parameter: must have either source or importer for logs endpoint") : /* tuple */[
          source.id,
          undefined
        ]
    ) : (
      importer !== undefined ? /* tuple */[
          undefined,
          importer.id
        ] : Js_exn.raiseError("Missing parameter: must have either source or importer for logs endpoint")
    );
  var match$1 = FetchStabilityMonitoring$BsConsole.useLogs(serviceEndpoint, projectName, token, match[0], match[1]);
  var setPage = match$1[3];
  var page = match$1[2];
  var refreshLogs = match$1[1];
  var logsRemote = match$1[0];
  var title = source !== undefined ? source.name : (
      importer !== undefined ? importer.name : I18N$BsConsole.get(undefined, "metrics importer")
    );
  var tmp;
  tmp = typeof logsRemote === "number" ? I18N$BsConsole.show(undefined, "Loading...") : (
      logsRemote.tag ? React.createElement(React.Fragment, undefined, I18N$BsConsole.show(undefined, "[ Error ] Failed to fetch logs -"), React.createElement("span", undefined, I18N$BsConsole.dynamic(logsRemote[0]))) : Belt_Array.mapWithIndex(logsRemote[0], (function (idx, log) {
                return React.createElement(PS_StabilityMonitoring$LogLine, {
                            level: log.level,
                            time: log.time,
                            importerName: getImporterName(importers, log.importerId),
                            message: log.message,
                            key: log.time.toDateString() + ("__" + String(idx))
                          });
              }))
    );
  return React.createElement(Dialog.default, {
              open: true,
              classes: {
                paper: Css.style(/* :: */[
                      Css.maxWidth(Css.px(1200)),
                      /* :: */[
                        Css.minWidth(Css.px(950)),
                        /* [] */0
                      ]
                    ])
              },
              onClose: (function (param) {
                  return Curry._1(onClose, undefined);
                }),
              maxWidth: "lg",
              disableBackdropClick: true,
              children: null
            }, React.createElement(CardContent.default, {
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      justifyContent: /* spaceBetween */516682146,
                      children: null
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading5 */4,
                          color: Colors$BsConsole.grey1,
                          children: null
                        }, I18N$BsConsole.show(undefined, "Logs for "), React.createElement("span", undefined, I18N$BsConsole.showSkip(title))), React.createElement(IconButton.default, {
                          disableRipple: true,
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.fontSize(Css.rem(1.5)),
                                  /* :: */[
                                    Css.important(Css.padding(Css.px(4))),
                                    /* [] */0
                                  ]
                                ]),
                            label: Css.style(/* :: */[
                                  Css.unsafe("fontSize", "inherit"),
                                  /* [] */0
                                ])
                          },
                          onClick: (function (param) {
                              return Curry._1(onClose, undefined);
                            }),
                          children: React.createElement(Close.default, {
                                fontSize: "inherit"
                              })
                        })), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                      className: Css.style(/* :: */[
                            Css.margin2(Css.rem(1), /* zero */-789508312),
                            /* :: */[
                              Css.height(Css.px(4)),
                              /* :: */[
                                Css.marginBottom(/* zero */-789508312),
                                /* [] */0
                              ]
                            ]
                          ])
                    })), React.createElement(CardContent.default, {
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      children: null
                    }, React.createElement(Button.default, {
                          color: "primary",
                          onClick: (function (param) {
                              return Curry._1(refreshLogs, undefined);
                            }),
                          children: I18N$BsConsole.show(undefined, "Refresh logs")
                        }), React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          children: null
                        }, React.createElement(Tooltip.default, {
                              children: React.createElement(IconButton.default, {
                                    disabled: page <= 0,
                                    color: "primary",
                                    onClick: (function (param) {
                                        return Curry._1(setPage, page - 1 | 0);
                                      }),
                                    children: React.createElement(KeyboardArrowLeft.default, {
                                          color: "inherit"
                                        })
                                  }),
                              title: I18N$BsConsole.get(undefined, "Previous page of logs")
                            }), React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.fontFamily("inconsolata"),
                                    /* :: */[
                                      Css.margin2(/* zero */-789508312, Css.px(4)),
                                      /* [] */0
                                    ]
                                  ])
                            }, I18N$BsConsole.showSkip(String(page + 1 | 0))), React.createElement(Tooltip.default, {
                              children: React.createElement(IconButton.default, {
                                    color: "primary",
                                    onClick: (function (param) {
                                        return Curry._1(setPage, page + 1 | 0);
                                      }),
                                    children: React.createElement(KeyboardArrowRight.default, {
                                          color: "inherit"
                                        })
                                  }),
                              title: I18N$BsConsole.get(undefined, "Next page of logs")
                            }))), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.backgroundColor(Css.hex(Colors$BsConsole.grey2)),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.white)),
                              /* :: */[
                                Css.fontFamily("inconsolata"),
                                /* :: */[
                                  Css.padding(Css.px(4)),
                                  /* :: */[
                                    Css.height(Css.px(300)),
                                    /* :: */[
                                      Css.overflowY(/* auto */-1065951377),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ])
                    }, tmp)));
}

var ServiceLogs = {
  getImporterName: getImporterName,
  make: PS_StabilityMonitoring$ServiceLogs
};

function PS_StabilityMonitoring$ImportersTable(Props) {
  var importers = Props.importers;
  var onAddImporter = Props.onAddImporter;
  var onEditImporter = Props.onEditImporter;
  var onDeleteImporter = Props.onDeleteImporter;
  var serviceEndpoint = Props.serviceEndpoint;
  var token = Props.token;
  var projectName = Props.projectName;
  var onOpenImporterLogs = Props.onOpenImporterLogs;
  return React.createElement(BtTableContainer$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                    /* :: */[
                      Css.borderRadius(Css.px(3)),
                      /* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ]
                    ]
                  ]),
              children: null
            }, React.createElement(BtTable$BsConsole.make, {
                  stickyHeader: true,
                  children: null
                }, React.createElement(BtTableHead$BsConsole.make, {
                      children: React.createElement(BtTableRow$BsConsole.make, {
                            children: null
                          }, React.createElement(BtTableCell$BsConsole.Header.make, {
                                children: I18N$BsConsole.show(undefined, "Name")
                              }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                children: I18N$BsConsole.show(undefined, "Metric group")
                              }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                children: I18N$BsConsole.show(undefined, "Last scraped time")
                              }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                className: Css.style(/* :: */[
                                      Css.textAlign(/* right */-379319332),
                                      /* [] */0
                                    ]),
                                children: importers.length !== 0 ? React.createElement(Button.default, {
                                        variant: "outlined",
                                        color: "primary",
                                        onClick: (function (param) {
                                            return Curry._1(onAddImporter, undefined);
                                          }),
                                        children: I18N$BsConsole.show(undefined, "Add importer")
                                      }) : null
                              }))
                    }), React.createElement(BtTableBody$BsConsole.make, {
                      children: Belt_Array.mapWithIndex(importers, (function (idx, importer) {
                              return React.createElement(BtTableRow$BsConsole.make, {
                                          className: Css.style(/* :: */[
                                                Css.hover(/* :: */[
                                                      Css.selector(".importer-action", /* :: */[
                                                            Css.opacity(1.0),
                                                            /* [] */0
                                                          ]),
                                                      /* [] */0
                                                    ]),
                                                /* [] */0
                                              ]),
                                          children: null,
                                          key: importer.name + ("__" + String(idx))
                                        }, React.createElement(BtTableCell$BsConsole.make, {
                                              children: I18N$BsConsole.showSkip(importer.name)
                                            }), React.createElement(BtTableCell$BsConsole.make, {
                                              children: I18N$BsConsole.showSkip(importer.metricGroup)
                                            }), React.createElement(BtTableCell$BsConsole.make, {
                                              children: Caml_obj.caml_greaterthan(importer.lastScrapedTime, new Date(0.0)) ? I18N$BsConsole.showSkip(L10N_date_time$BsConsole.Format.numericDayTwentyFour(undefined, importer.lastScrapedTime)) : I18N$BsConsole.showSkip(" - ")
                                            }), React.createElement(BtTableCell$BsConsole.make, {
                                              className: Css.style(/* :: */[
                                                    Css.textAlign(/* right */-379319332),
                                                    /* [] */0
                                                  ]),
                                              children: null
                                            }, React.createElement(Tooltip.default, {
                                                  children: React.createElement(IconButton.default, {
                                                        disableRipple: true,
                                                        classes: {
                                                          root: Css.merge(/* :: */[
                                                                Css.style(/* :: */[
                                                                      Css.opacity(0),
                                                                      /* :: */[
                                                                        Css.fontSize(Css.rem(1)),
                                                                        /* :: */[
                                                                          Css.marginRight(Css.px(4)),
                                                                          /* [] */0
                                                                        ]
                                                                      ]
                                                                    ]),
                                                                /* :: */[
                                                                  "importer-action",
                                                                  /* [] */0
                                                                ]
                                                              ]),
                                                          label: Css.style(/* :: */[
                                                                Css.unsafe("fontSize", "inherit"),
                                                                /* [] */0
                                                              ])
                                                        },
                                                        color: "primary",
                                                        onClick: (function (param) {
                                                            return Curry._1(onOpenImporterLogs, importer);
                                                          }),
                                                        children: React.createElement(Description.default, { })
                                                      }),
                                                  title: I18N$BsConsole.get(undefined, "View logs")
                                                }), React.createElement(Tooltip.default, {
                                                  children: React.createElement(IconButton.default, {
                                                        disableRipple: true,
                                                        classes: {
                                                          root: Css.merge(/* :: */[
                                                                Css.style(/* :: */[
                                                                      Css.opacity(0),
                                                                      /* :: */[
                                                                        Css.fontSize(Css.rem(1)),
                                                                        /* :: */[
                                                                          Css.marginRight(Css.px(4)),
                                                                          /* [] */0
                                                                        ]
                                                                      ]
                                                                    ]),
                                                                /* :: */[
                                                                  "importer-action",
                                                                  /* [] */0
                                                                ]
                                                              ]),
                                                          label: Css.style(/* :: */[
                                                                Css.unsafe("fontSize", "inherit"),
                                                                /* [] */0
                                                              ])
                                                        },
                                                        color: "primary",
                                                        onClick: (function (param) {
                                                            var onSuccess = function (_resp) {
                                                              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Importer test was successful."));
                                                            };
                                                            var onFailure = function (msg) {
                                                              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic("Importer test failed: " + msg));
                                                            };
                                                            var arg = FetchStabilityMonitoring$BsConsole.makeCheckSourcesTask(serviceEndpoint, projectName, importer.sourceId, importer.query);
                                                            return Curry._2((function (param) {
                                                                            return (function (param$1, param$2) {
                                                                                return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                                              });
                                                                          })(token), undefined, (function (param) {
                                                                          return FetchStabilityMonitoring$BsConsole.responseCb(onSuccess, onFailure, param);
                                                                        }));
                                                          }),
                                                        children: React.createElement(Build.default, { })
                                                      }),
                                                  title: I18N$BsConsole.get(undefined, "test importer")
                                                }), React.createElement(Tooltip.default, {
                                                  children: React.createElement(IconButton.default, {
                                                        disableRipple: true,
                                                        classes: {
                                                          root: Css.merge(/* :: */[
                                                                Css.style(/* :: */[
                                                                      Css.opacity(0),
                                                                      /* :: */[
                                                                        Css.fontSize(Css.rem(1)),
                                                                        /* :: */[
                                                                          Css.marginRight(Css.px(4)),
                                                                          /* [] */0
                                                                        ]
                                                                      ]
                                                                    ]),
                                                                /* :: */[
                                                                  "importer-action",
                                                                  /* [] */0
                                                                ]
                                                              ]),
                                                          label: Css.style(/* :: */[
                                                                Css.unsafe("fontSize", "inherit"),
                                                                /* [] */0
                                                              ])
                                                        },
                                                        color: "primary",
                                                        onClick: (function (param) {
                                                            return Curry._1(onEditImporter, importer);
                                                          }),
                                                        children: React.createElement(Edit.default, { })
                                                      }),
                                                  title: I18N$BsConsole.get(undefined, "Edit importer")
                                                }), React.createElement(Tooltip.default, {
                                                  children: React.createElement(IconButton.default, {
                                                        disableRipple: true,
                                                        classes: {
                                                          root: Css.merge(/* :: */[
                                                                Css.style(/* :: */[
                                                                      Css.opacity(0),
                                                                      /* :: */[
                                                                        Css.fontSize(Css.rem(1)),
                                                                        /* [] */0
                                                                      ]
                                                                    ]),
                                                                /* :: */[
                                                                  "importer-action",
                                                                  /* [] */0
                                                                ]
                                                              ]),
                                                          label: Css.style(/* :: */[
                                                                Css.unsafe("fontSize", "inherit"),
                                                                /* [] */0
                                                              ])
                                                        },
                                                        color: "primary",
                                                        onClick: (function (param) {
                                                            return Curry._1(onDeleteImporter, importer);
                                                          }),
                                                        children: React.createElement(Delete.default, { })
                                                      }),
                                                  title: I18N$BsConsole.get(undefined, "delete importer")
                                                })));
                            }))
                    })), importers.length !== 0 ? null : React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    justifyContent: /* center */98248149,
                    className: Css.style(/* :: */[
                          Css.padding(Css.rem(1)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        color: Colors$BsConsole.grey3,
                        children: I18N$BsConsole.show(undefined, "No importers linked to this source yet.")
                      }), React.createElement(Button.default, {
                        variant: "outlined",
                        color: "primary",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginLeft(Css.rem(1)),
                                /* [] */0
                              ])
                        },
                        onClick: (function (param) {
                            return Curry._1(onAddImporter, undefined);
                          }),
                        children: I18N$BsConsole.show(undefined, "Add importer")
                      })));
}

var ImportersTable = {
  make: PS_StabilityMonitoring$ImportersTable
};

function PS_StabilityMonitoring$Source(Props) {
  var source = Props.source;
  var importers = Props.importers;
  var onAddImporter = Props.onAddImporter;
  var onEditImporter = Props.onEditImporter;
  var onDeleteImporter = Props.onDeleteImporter;
  var onEdit = Props.onEdit;
  var onDelete = Props.onDelete;
  var onOpenSourceLogs = Props.onOpenSourceLogs;
  var onOpenImporterLogs = Props.onOpenImporterLogs;
  var serviceEndpoint = Props.serviceEndpoint;
  var projectName = Props.projectName;
  var token = Props.token;
  var match = React.useState((function () {
          return false;
        }));
  var setExpanded = match[1];
  var expanded = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setAnchorEl = match$1[1];
  var anchorEl = match$1[0];
  return React.createElement(React.Fragment, undefined, React.createElement(Paper.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginRight(Css.rem(1)),
                          /* :: */[
                            Css.marginBottom(Css.rem(1)),
                            /* [] */0
                          ]
                        ])
                  },
                  children: React.createElement(CardContent.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.important(Css.paddingBottom(Css.px(16))),
                                /* :: */[
                                  Css.selector("&, div", /* :: */[
                                        Css.unsafe("backgroundColor", "inherit"),
                                        /* [] */0
                                      ]),
                                  /* :: */[
                                    Css.hover(/* :: */[
                                          Css.selector(".view-logs", /* :: */[
                                                Css.opacity(1),
                                                /* [] */0
                                              ]),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ])
                        },
                        children: null
                      }, React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            justifyContent: /* spaceBetween */516682146,
                            children: null
                          }, React.createElement(Col2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.unsafe("maxWidth", "calc(100% - 32px)"),
                                      /* [] */0
                                    ]),
                                children: null
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Body1 */8,
                                    color: Colors$BsConsole.grey1,
                                    children: I18N$BsConsole.showSkip(source.name)
                                  }), React.createElement(Row2$BsConsole.make, {
                                    alignItems: /* center */98248149,
                                    className: Css.style(/* :: */[
                                          Css.flexWrap(/* wrap */-822134326),
                                          /* :: */[
                                            Css.paddingTop(Css.px(6)),
                                            /* [] */0
                                          ]
                                        ]),
                                    children: null
                                  }, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body2 */9,
                                        className: Css.style(/* :: */[
                                              Css.whiteSpace(/* nowrap */867913355),
                                              /* :: */[
                                                Css.overflow(/* hidden */-862584982),
                                                /* :: */[
                                                  Css.textOverflow(/* ellipsis */166888785),
                                                  /* :: */[
                                                    Css.paddingRight(Css.px(8)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]),
                                        color: Colors$BsConsole.grey4,
                                        children: null
                                      }, React.createElement("span", {
                                            className: Css.style(/* :: */[
                                                  Css.textTransform(/* capitalize */-509867604),
                                                  /* [] */0
                                                ])
                                          }, I18N$BsConsole.showSkip(ApiStabilityMonitoring$BsConsole.Source.typeToString(source.type_) + " - ")), I18N$BsConsole.showSkip(source.url)), React.createElement(Tooltip.default, {
                                        children: React.createElement(Button.default, {
                                              color: "primary",
                                              classes: {
                                                root: Css.style(/* :: */[
                                                      Css.padding(/* zero */-789508312),
                                                      /* [] */0
                                                    ])
                                              },
                                              onClick: (function (param) {
                                                  return Clipboard$BsConsole.copy(source.id, (function (param) {
                                                                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Source UUID copied to clipboard"));
                                                              }), undefined);
                                                }),
                                              children: React.createElement(BtTypography$BsConsole.make, {
                                                    variant: /* Body2 */9,
                                                    className: Css.style(/* :: */[
                                                          Css.whiteSpace(/* nowrap */867913355),
                                                          /* :: */[
                                                            Css.overflow(/* hidden */-862584982),
                                                            /* :: */[
                                                              Css.textOverflow(/* ellipsis */166888785),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ]),
                                                    children: I18N$BsConsole.showSkip(source.id)
                                                  })
                                            }),
                                        title: I18N$BsConsole.get(undefined, "Source UUID - click to copy to clipboard")
                                      }))), React.createElement(Row2$BsConsole.make, {
                                alignItems: /* center */98248149,
                                children: null
                              }, React.createElement(Tooltip.default, {
                                    children: React.createElement(Button.default, {
                                          color: "primary",
                                          classes: {
                                            root: Css.merge(/* :: */[
                                                  Css.style(/* :: */[
                                                        Css.opacity(0),
                                                        /* [] */0
                                                      ]),
                                                  /* :: */[
                                                    "view-logs",
                                                    /* [] */0
                                                  ]
                                                ])
                                          },
                                          onClick: (function (param) {
                                              return Curry._1(onOpenSourceLogs, source);
                                            }),
                                          children: I18N$BsConsole.show(undefined, "View logs")
                                        }),
                                    title: I18N$BsConsole.get(undefined, "View logs for source")
                                  }), React.createElement(IconButton.default, {
                                    disableRipple: true,
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.fontSize(Css.rem(1.5)),
                                            /* :: */[
                                              Css.important(Css.padding(Css.px(4))),
                                              /* [] */0
                                            ]
                                          ]),
                                      label: Css.style(/* :: */[
                                            Css.unsafe("fontSize", "inherit"),
                                            /* [] */0
                                          ])
                                    },
                                    onClick: (function (param) {
                                        var expanded$1 = !expanded;
                                        return Curry._1(setExpanded, (function (param) {
                                                      return expanded$1;
                                                    }));
                                      }),
                                    children: expanded ? React.createElement(KeyboardArrowUp.default, {
                                            fontSize: "inherit"
                                          }) : React.createElement(KeyboardArrowDown.default, {
                                            fontSize: "inherit"
                                          })
                                  }), React.createElement(IconButton.default, {
                                    disableRipple: true,
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.fontSize(Css.rem(1.5)),
                                            /* :: */[
                                              Css.important(Css.padding(Css.px(4))),
                                              /* [] */0
                                            ]
                                          ]),
                                      label: Css.style(/* :: */[
                                            Css.unsafe("fontSize", "inherit"),
                                            /* [] */0
                                          ])
                                    },
                                    onClick: (function ($$event) {
                                        var element = $$event.currentTarget;
                                        if (!(element == null)) {
                                          return Curry._1(setAnchorEl, (function (param) {
                                                        return Caml_option.some(element);
                                                      }));
                                        }
                                        
                                      }),
                                    children: React.createElement(MoreVert.default, {
                                          fontSize: "inherit"
                                        })
                                  }))), React.createElement(Collapse.default, {
                            in: expanded,
                            timeout: 200,
                            children: React.createElement(React.Fragment, undefined, React.createElement(Divider.default, {
                                      className: Css.style(/* :: */[
                                            Css.margin2(Css.rem(1), /* zero */-789508312),
                                            /* [] */0
                                          ])
                                    }), React.createElement(PS_StabilityMonitoring$ImportersTable, {
                                      importers: importers,
                                      onAddImporter: onAddImporter,
                                      onEditImporter: onEditImporter,
                                      onDeleteImporter: onDeleteImporter,
                                      serviceEndpoint: serviceEndpoint,
                                      token: token,
                                      projectName: projectName,
                                      onOpenImporterLogs: onOpenImporterLogs
                                    }))
                          }))
                }), anchorEl !== undefined ? React.createElement(Popover.default, {
                    anchorOrigin: {
                      horizontal: "left",
                      vertical: "bottom"
                    },
                    anchorEl: Caml_option.valFromOption(anchorEl),
                    open: true,
                    className: Css.style(/* :: */[
                          Css.zIndex(10000),
                          /* [] */0
                        ]),
                    onClose: (function (param) {
                        return Curry._1(setAnchorEl, (function (param) {
                                      
                                    }));
                      }),
                    children: null
                  }, React.createElement(MenuItem.default, {
                        disableRipple: true,
                        classes: {
                          root: menuItem
                        },
                        onClick: (function (param) {
                            Curry._1(setAnchorEl, (function (param) {
                                    
                                  }));
                            return Curry._1(onAddImporter, undefined);
                          }),
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.show(undefined, "Add importer")
                            })
                      }), React.createElement(MenuItem.default, {
                        disableRipple: true,
                        classes: {
                          root: menuItem
                        },
                        onClick: (function (param) {
                            Curry._1(setAnchorEl, (function (param) {
                                    
                                  }));
                            return Curry._1(onEdit, source);
                          }),
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.show(undefined, "Edit source")
                            })
                      }), React.createElement(MenuItem.default, {
                        disableRipple: true,
                        classes: {
                          root: menuItem
                        },
                        onClick: (function (param) {
                            Curry._1(setAnchorEl, (function (param) {
                                    
                                  }));
                            return Curry._1(onDelete, source);
                          }),
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.show(undefined, "Delete source")
                            })
                      })) : null);
}

var Source = {
  make: PS_StabilityMonitoring$Source
};

function PS_StabilityMonitoring$Sources(Props) {
  var token = Props.token;
  var projectName = Props.projectName;
  var serviceEndpoint = Props.serviceEndpoint;
  var currentProject = Props.currentProject;
  var metricGroups = Props.metricGroups;
  var metrics = Props.metrics;
  Props.updateMetricGroupRemote;
  Props.updateMetricRemote;
  var match = React.useState((function () {
          
        }));
  var setFormModal = match[1];
  var formModal = match[0];
  var match$1 = FetchStabilityMonitoring$BsConsole.getSources(token, projectName, serviceEndpoint);
  var updateSourcesRemote = match$1[1];
  var sourcesRemote = match$1[0];
  var sources;
  sources = typeof sourcesRemote === "number" ? [] : (
      sourcesRemote.tag ? [] : sourcesRemote[0]
    );
  var match$2 = FetchStabilityMonitoring$BsConsole.getImporters(token, projectName, serviceEndpoint);
  var updateImportersRemote = match$2[1];
  var importersRemote = match$2[0];
  var importers;
  importers = typeof importersRemote === "number" ? [] : (
      importersRemote.tag ? [] : importersRemote[0]
    );
  var submissionTokenRemote = Task2$BsConsole.use(true, BpgTask$BsConsole.Token.fetchAll, undefined);
  if (typeof submissionTokenRemote === "number") {
    return null;
  }
  if (submissionTokenRemote.tag) {
    return null;
  }
  var tokens = Belt_Array.keep(submissionTokenRemote[0][0], (function (token) {
          return token.project === currentProject.pid;
        }));
  var submissionToken = Belt_Array.get(tokens, 0);
  if (submissionToken === undefined) {
    return null;
  }
  var tmp;
  if (formModal !== undefined) {
    switch (formModal.tag | 0) {
      case /* CreateSource */0 :
          tmp = React.createElement(StabilityMonitoringForms$BsConsole.Source.make, {
                source: formModal[0],
                projectName: projectName,
                token: token,
                onSave: (function (param) {
                    return Curry._1(updateSourcesRemote, undefined);
                  }),
                onClose: (function (param) {
                    return Curry._1(setFormModal, (function (param) {
                                  
                                }));
                  }),
                submissionToken: submissionToken.id,
                serviceEndpoint: serviceEndpoint
              });
          break;
      case /* CreateImporter */1 :
          tmp = React.createElement(StabilityMonitoringForms$BsConsole.Importer.make, {
                serviceEndpoint: serviceEndpoint,
                projectName: projectName,
                sourceId: formModal[0],
                importer: formModal[1],
                metricGroups: metricGroups,
                metrics: metrics,
                token: token,
                onClose: (function (param) {
                    return Curry._1(setFormModal, (function (param) {
                                  
                                }));
                  }),
                onSave: (function (param) {
                    Curry._1(setFormModal, (function (param) {
                            
                          }));
                    return Curry._1(updateImportersRemote, undefined);
                  })
              });
          break;
      case /* DeleteSource */2 :
          var source = formModal[0];
          tmp = React.createElement(StabilityMonitoringForms$BsConsole.Delete.make, {
                onClose: (function (param) {
                    return Curry._1(setFormModal, (function (param) {
                                  
                                }));
                  }),
                onDelete: (function (param) {
                    var onSuccess = function (_resp) {
                      Curry._1(setFormModal, (function (param) {
                              
                            }));
                      Curry._1(updateSourcesRemote, undefined);
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully deleted metric source"));
                    };
                    var onFailure = function (msg) {
                      Curry._1(setFormModal, (function (param) {
                              
                            }));
                      Curry._1(updateSourcesRemote, undefined);
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic("Failed to delete metric source: " + msg));
                    };
                    var arg = FetchStabilityMonitoring$BsConsole.makeDeleteSourcesTask(serviceEndpoint, projectName, source.id);
                    return Curry._2((function (param) {
                                    return (function (param$1, param$2) {
                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                      });
                                  })(token), undefined, (function (param) {
                                  return FetchStabilityMonitoring$BsConsole.responseCb(onSuccess, onFailure, param);
                                }));
                  }),
                entityName: I18N$BsConsole.get(undefined, "metric source") + (" - " + source.name)
              });
          break;
      case /* DeleteImporter */3 :
          var importer = formModal[0];
          tmp = React.createElement(StabilityMonitoringForms$BsConsole.Delete.make, {
                onClose: (function (param) {
                    return Curry._1(setFormModal, (function (param) {
                                  
                                }));
                  }),
                onDelete: (function (param) {
                    var onSuccess = function (_resp) {
                      Curry._1(setFormModal, (function (param) {
                              
                            }));
                      Curry._1(updateImportersRemote, undefined);
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully deleted importer"));
                    };
                    var onFailure = function (msg) {
                      Curry._1(setFormModal, (function (param) {
                              
                            }));
                      Curry._1(updateImportersRemote, undefined);
                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic("Failed to delete importer: " + msg));
                    };
                    var arg = FetchStabilityMonitoring$BsConsole.makeDeleteImportersTask(serviceEndpoint, projectName, importer.id);
                    return Curry._2((function (param) {
                                    return (function (param$1, param$2) {
                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                      });
                                  })(token), undefined, (function (param) {
                                  return FetchStabilityMonitoring$BsConsole.serviceDeleteResponseCb(onSuccess, onFailure, param);
                                }));
                  }),
                entityName: I18N$BsConsole.get(undefined, "metric importer") + (" - " + importer.name)
              });
          break;
      case /* SourceLogs */4 :
          tmp = React.createElement(PS_StabilityMonitoring$ServiceLogs, {
                serviceEndpoint: serviceEndpoint,
                projectName: projectName,
                token: token,
                onClose: (function (param) {
                    return Curry._1(setFormModal, (function (param) {
                                  
                                }));
                  }),
                importers: importers,
                source: formModal[0]
              });
          break;
      case /* ImporterLogs */5 :
          tmp = React.createElement(PS_StabilityMonitoring$ServiceLogs, {
                serviceEndpoint: serviceEndpoint,
                projectName: projectName,
                token: token,
                onClose: (function (param) {
                    return Curry._1(setFormModal, (function (param) {
                                  
                                }));
                  }),
                importers: importers,
                importer: formModal[0]
              });
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Button.default, {
                  disableRipple: true,
                  variant: "contained",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(1)),
                          /* [] */0
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(setFormModal, (function (param) {
                                    return /* CreateSource */Block.__(0, [undefined]);
                                  }));
                    }),
                  children: I18N$BsConsole.show(undefined, "Create a metric source")
                }), Belt_Array.mapWithIndex(sources, (function (idx, source) {
                    return React.createElement(PS_StabilityMonitoring$Source, {
                                source: source,
                                importers: Belt_Array.keep(importers, (function (importer) {
                                        return importer.sourceId === source.id;
                                      })),
                                onAddImporter: (function (param) {
                                    return Curry._1(setFormModal, (function (param) {
                                                  return /* CreateImporter */Block.__(1, [
                                                            source.id,
                                                            undefined
                                                          ]);
                                                }));
                                  }),
                                onEditImporter: (function (importer) {
                                    return Curry._1(setFormModal, (function (param) {
                                                  return /* CreateImporter */Block.__(1, [
                                                            source.id,
                                                            importer
                                                          ]);
                                                }));
                                  }),
                                onDeleteImporter: (function (importer) {
                                    return Curry._1(setFormModal, (function (param) {
                                                  return /* DeleteImporter */Block.__(3, [importer]);
                                                }));
                                  }),
                                onEdit: (function (source) {
                                    return Curry._1(setFormModal, (function (param) {
                                                  return /* CreateSource */Block.__(0, [source]);
                                                }));
                                  }),
                                onDelete: (function (source) {
                                    return Curry._1(setFormModal, (function (param) {
                                                  return /* DeleteSource */Block.__(2, [source]);
                                                }));
                                  }),
                                onOpenSourceLogs: (function (source) {
                                    return Curry._1(setFormModal, (function (param) {
                                                  return /* SourceLogs */Block.__(4, [source]);
                                                }));
                                  }),
                                onOpenImporterLogs: (function (importer) {
                                    return Curry._1(setFormModal, (function (param) {
                                                  return /* ImporterLogs */Block.__(5, [importer]);
                                                }));
                                  }),
                                serviceEndpoint: serviceEndpoint,
                                projectName: projectName,
                                token: token,
                                key: source.name + ("__" + String(idx))
                              });
                  })), tmp);
}

var Sources = {
  make: PS_StabilityMonitoring$Sources
};

function PS_StabilityMonitoring$MetricGroup(Props) {
  var metricGroup = Props.metricGroup;
  var metricAttributes = Props.metricAttributes;
  var metrics = Props.metrics;
  var setFormModal = Props.setFormModal;
  var onEdit = Props.onEdit;
  var onDelete = Props.onDelete;
  var match = React.useState((function () {
          return false;
        }));
  var setExpanded = match[1];
  var expanded = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setAnchorEl = match$1[1];
  var anchorEl = match$1[0];
  var description = metricGroup.description;
  return React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.Paper.make, {
                  className: Css.style(/* :: */[
                        Css.unsafe("width", "calc(50% - 1rem)"),
                        /* :: */[
                          Css.marginRight(Css.rem(1)),
                          /* :: */[
                            Css.marginBottom(Css.rem(1)),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: React.createElement(CardContent.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.important(Css.paddingBottom(Css.px(16))),
                                /* [] */0
                              ])
                        },
                        children: null
                      }, React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            justifyContent: /* spaceBetween */516682146,
                            children: null
                          }, React.createElement(Col2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.unsafe("maxWidth", "calc(90% - 32px)"),
                                      /* [] */0
                                    ]),
                                children: null
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Body1 */8,
                                    color: Colors$BsConsole.grey1,
                                    children: I18N$BsConsole.showSkip(metricGroup.name)
                                  }), description !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Body2 */9,
                                      className: Css.style(/* :: */[
                                            Css.width(Css.pct(100)),
                                            /* :: */[
                                              Css.whiteSpace(/* nowrap */867913355),
                                              /* :: */[
                                                Css.overflow(/* hidden */-862584982),
                                                /* :: */[
                                                  Css.textOverflow(/* ellipsis */166888785),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]),
                                      color: Colors$BsConsole.grey4,
                                      children: I18N$BsConsole.showSkip(description)
                                    }) : null), React.createElement(Row2$BsConsole.make, {
                                alignItems: /* center */98248149,
                                children: null
                              }, React.createElement(IconButton.default, {
                                    disableRipple: true,
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.fontSize(Css.rem(1.5)),
                                            /* :: */[
                                              Css.important(Css.padding(Css.px(4))),
                                              /* [] */0
                                            ]
                                          ]),
                                      label: Css.style(/* :: */[
                                            Css.unsafe("fontSize", "inherit"),
                                            /* [] */0
                                          ])
                                    },
                                    onClick: (function (param) {
                                        var expanded$1 = !expanded;
                                        return Curry._1(setExpanded, (function (param) {
                                                      return expanded$1;
                                                    }));
                                      }),
                                    children: expanded ? React.createElement(KeyboardArrowUp.default, {
                                            fontSize: "inherit"
                                          }) : React.createElement(KeyboardArrowDown.default, {
                                            fontSize: "inherit"
                                          })
                                  }), React.createElement(IconButton.default, {
                                    disableRipple: true,
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.fontSize(Css.rem(1.5)),
                                            /* :: */[
                                              Css.important(Css.padding(Css.px(4))),
                                              /* [] */0
                                            ]
                                          ]),
                                      label: Css.style(/* :: */[
                                            Css.unsafe("fontSize", "inherit"),
                                            /* [] */0
                                          ])
                                    },
                                    onClick: (function ($$event) {
                                        var element = $$event.currentTarget;
                                        if (!(element == null)) {
                                          return Curry._1(setAnchorEl, (function (param) {
                                                        return Caml_option.some(element);
                                                      }));
                                        }
                                        
                                      }),
                                    children: React.createElement(MoreVert.default, {
                                          fontSize: "inherit"
                                        })
                                  }))), React.createElement(Collapse.default, {
                            in: expanded,
                            timeout: 200,
                            children: React.createElement(React.Fragment, undefined, React.createElement(Divider.default, {
                                      className: Css.style(/* :: */[
                                            Css.margin2(Css.rem(1), /* zero */-789508312),
                                            /* [] */0
                                          ])
                                    }), React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Body1 */8,
                                      color: Colors$BsConsole.grey1,
                                      children: I18N$BsConsole.show(undefined, "Attributes:")
                                    }), React.createElement(Row2$BsConsole.make, {
                                      alignItems: /* center */98248149,
                                      flexWrap: /* wrap */-822134326,
                                      className: Css.style(/* :: */[
                                            Css.marginBottom(Css.rem(0.5)),
                                            /* [] */0
                                          ]),
                                      children: metricAttributes.length !== 0 ? Belt_Array.mapWithIndex(metricAttributes, (function (idx, attribute) {
                                                return React.createElement(StabilityMonitoringForms$BsConsole.ItemChip.make, {
                                                            name: attribute.name,
                                                            onDelete: (function (param) {
                                                                return Curry._1(setFormModal, /* Attribute_Delete */Block.__(3, [attribute]));
                                                              }),
                                                            key: attribute.name + ("__" + String(idx))
                                                          });
                                              })) : React.createElement(BtTypography$BsConsole.make, {
                                              variant: /* Body1 */8,
                                              color: Colors$BsConsole.grey4,
                                              children: I18N$BsConsole.show(undefined, "No attributes are attached to this metric group yet.")
                                            })
                                    }), metricAttributes.length !== 0 ? React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                                            alignItems: /* center */98248149,
                                            children: React.createElement(BtTypography$BsConsole.make, {
                                                  variant: /* Body1 */8,
                                                  color: Colors$BsConsole.grey1,
                                                  children: I18N$BsConsole.show(undefined, "Metrics:")
                                                })
                                          }), React.createElement(Row2$BsConsole.make, {
                                            alignItems: /* center */98248149,
                                            flexWrap: /* wrap */-822134326,
                                            className: Css.style(/* :: */[
                                                  Css.marginBottom(Css.rem(0.5)),
                                                  /* [] */0
                                                ]),
                                            children: metrics.length !== 0 ? Belt_Array.mapWithIndex(metrics, (function (idx, metric) {
                                                      return React.createElement(StabilityMonitoringForms$BsConsole.ItemChip.make, {
                                                                  name: metric.name,
                                                                  onDelete: (function (param) {
                                                                      return Curry._1(setFormModal, /* Value_Delete */Block.__(5, [metric]));
                                                                    }),
                                                                  title: metric.attributeValues,
                                                                  key: metric.name + ("__" + String(idx))
                                                                });
                                                    })) : React.createElement(BtTypography$BsConsole.make, {
                                                    variant: /* Body1 */8,
                                                    color: Colors$BsConsole.grey4,
                                                    children: I18N$BsConsole.show(undefined, "No metrics are attached to this metric group yet.")
                                                  })
                                          })) : null, React.createElement(Row2$BsConsole.make, {
                                      children: null
                                    }, React.createElement(Button.default, {
                                          disableRipple: true,
                                          color: "primary",
                                          classes: {
                                            root: Css.style(/* :: */[
                                                  Css.marginRight(Css.rem(0.5)),
                                                  /* [] */0
                                                ])
                                          },
                                          onClick: (function (param) {
                                              return Curry._1(setFormModal, /* Attribute_Create */Block.__(2, [metricGroup]));
                                            }),
                                          children: I18N$BsConsole.showSkip(metricAttributes.length !== 0 ? I18N$BsConsole.get(undefined, "Edit linked attributes") : I18N$BsConsole.get(undefined, "link attributes"))
                                        }), metricAttributes.length !== 0 ? React.createElement(Button.default, {
                                            disableRipple: true,
                                            color: "primary",
                                            onClick: (function (param) {
                                                return Curry._1(setFormModal, /* Value_Create */Block.__(4, [metricGroup]));
                                              }),
                                            children: I18N$BsConsole.show(undefined, "Add metric")
                                          }) : null))
                          }))
                }), anchorEl !== undefined ? React.createElement(Popover.default, {
                    anchorOrigin: {
                      horizontal: "left",
                      vertical: "bottom"
                    },
                    anchorEl: Caml_option.valFromOption(anchorEl),
                    open: true,
                    className: Css.style(/* :: */[
                          Css.zIndex(10000),
                          /* [] */0
                        ]),
                    onClose: (function (param) {
                        return Curry._1(setAnchorEl, (function (param) {
                                      
                                    }));
                      }),
                    children: null
                  }, React.createElement(MenuItem.default, {
                        disableRipple: true,
                        classes: {
                          root: menuItem
                        },
                        onClick: (function (param) {
                            Curry._1(setAnchorEl, (function (param) {
                                    
                                  }));
                            return Curry._1(onEdit, metricGroup);
                          }),
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.show(undefined, "Edit metric group")
                            })
                      }), React.createElement(MenuItem.default, {
                        disableRipple: true,
                        classes: {
                          root: menuItem
                        },
                        onClick: (function (param) {
                            Curry._1(setAnchorEl, (function (param) {
                                    
                                  }));
                            return Curry._1(onDelete, metricGroup);
                          }),
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.show(undefined, "delete metric group")
                            })
                      })) : null);
}

var MetricGroup = {
  make: PS_StabilityMonitoring$MetricGroup
};

function PS_StabilityMonitoring$MetricGroups(Props) {
  var currentProject = Props.currentProject;
  var token = Props.token;
  var metricGroups = Props.metricGroups;
  var metrics = Props.metrics;
  var metricAttributes = Props.metricAttributes;
  var attributes = Props.attributes;
  var updateMetricGroupRemote = Props.updateMetricGroupRemote;
  var updateMetricsAttributeRemote = Props.updateMetricsAttributeRemote;
  var updateMetricRemote = Props.updateMetricRemote;
  var match = React.useState((function () {
          
        }));
  var setFormModal = match[1];
  var formModal = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setWaiting = match$1[1];
  var waiting = match$1[0];
  React.useEffect((function () {
          if (waiting !== undefined) {
            var metricGroupName = waiting[0];
            var metricGroup = Belt_Array.getBy(metricGroups, (function (group) {
                    return group.name === metricGroupName;
                  }));
            if (metricGroup !== undefined) {
              Curry._1(setFormModal, (function (param) {
                      return /* Attribute_Create */Block.__(2, [metricGroup]);
                    }));
              Curry._1(setWaiting, (function (param) {
                      
                    }));
            }
            
          }
          
        }), /* tuple */[
        waiting,
        metricGroups
      ]);
  var tmp;
  if (formModal !== undefined) {
    switch (formModal.tag | 0) {
      case /* MetricGroup_Create */0 :
          tmp = React.createElement(StabilityMonitoringForms$BsConsole.MetricGroup.make, {
                metricGroup: formModal[0],
                project: currentProject,
                token: token,
                onClose: (function (param) {
                    return Curry._1(setFormModal, (function (param) {
                                  
                                }));
                  }),
                onSave: (function (param) {
                    return Curry._1(updateMetricGroupRemote, undefined);
                  }),
                onOpenAttributeForm: (function (metricGroup) {
                    return Curry._1(setWaiting, (function (param) {
                                  return /* MetricAttribute */[metricGroup];
                                }));
                  })
              });
          break;
      case /* DeleteMetricGroup */1 :
          var metricGroup = formModal[0];
          tmp = React.createElement(StabilityMonitoringForms$BsConsole.Delete.make, {
                onClose: (function (param) {
                    return Curry._1(setFormModal, (function (param) {
                                  
                                }));
                  }),
                onDelete: (function (param) {
                    var arg = BpgTask$BsConsole.MetricGroup.cascadeDeleteUnsafe(metricGroup);
                    return Curry._2((function (param) {
                                    return (function (param$1, param$2) {
                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                      });
                                  })(token), undefined, (function (resp) {
                                  if (resp.tag) {
                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to deleted metric group."));
                                  }
                                  Curry._1(setFormModal, (function (param) {
                                          
                                        }));
                                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully deleted metric group and associated objects."));
                                  Curry._1(updateMetricGroupRemote, undefined);
                                  Curry._1(updateMetricsAttributeRemote, undefined);
                                  return Curry._1(updateMetricRemote, undefined);
                                }));
                  }),
                entityName: metricGroup.name,
                warningLabel: I18N$BsConsole.get(undefined, "Deleting this group will delete all objects associated with it.")
              });
          break;
      case /* Attribute_Create */2 :
          var metricGroup$1 = formModal[0];
          tmp = React.createElement(StabilityMonitoringForms$BsConsole.MetricAttribute.make, {
                attributes: attributes,
                metricGroup: metricGroup$1,
                initialMetricAttributes: Belt_Array.keep(metricAttributes, (function (metricAttribute) {
                        return metricAttribute.metricGroup === metricGroup$1.id;
                      })),
                onOpenMetricForm: (function (metricGroup) {
                    return Curry._1(setFormModal, (function (param) {
                                  return /* Value_Create */Block.__(4, [metricGroup]);
                                }));
                  }),
                token: token,
                onClose: (function (param) {
                    return Curry._1(setFormModal, (function (param) {
                                  
                                }));
                  }),
                onCreate: (function (param) {
                    return Curry._1(updateMetricsAttributeRemote, undefined);
                  })
              });
          break;
      case /* Attribute_Delete */3 :
          var attributeMetric = formModal[0];
          tmp = React.createElement(StabilityMonitoringForms$BsConsole.Delete.make, {
                onClose: (function (param) {
                    return Curry._1(setFormModal, (function (param) {
                                  
                                }));
                  }),
                onDelete: (function (param) {
                    var arg = BpgTask$BsConsole.MetricAttribute.$$delete(attributeMetric);
                    return Curry._2((function (param) {
                                    return (function (param$1, param$2) {
                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                      });
                                  })(token), undefined, (function (resp) {
                                  if (resp.tag) {
                                    Curry._1(setFormModal, (function (param) {
                                            
                                          }));
                                    Curry._1(updateMetricsAttributeRemote, undefined);
                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to delete metric attribute"));
                                  }
                                  Curry._1(setFormModal, (function (param) {
                                          
                                        }));
                                  Curry._1(updateMetricsAttributeRemote, undefined);
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully deleted metric attribute"));
                                }));
                  }),
                entityName: I18N$BsConsole.get(undefined, "metric attribute") + (" - " + attributeMetric.name)
              });
          break;
      case /* Value_Create */4 :
          var metricGroup$2 = formModal[0];
          tmp = React.createElement(StabilityMonitoringForms$BsConsole.MetricValue.make, {
                metricGroup: metricGroup$2,
                metricAttributes: Belt_Array.keep(metricAttributes, (function (metricAttribute) {
                        return metricAttribute.metricGroup === metricGroup$2.id;
                      })),
                token: token,
                projectName: currentProject.name,
                onClose: (function (param) {
                    return Curry._1(setFormModal, (function (param) {
                                  
                                }));
                  }),
                onSave: (function (param) {
                    return Curry._1(updateMetricRemote, undefined);
                  })
              });
          break;
      case /* Value_Delete */5 :
          var metric = formModal[0];
          tmp = React.createElement(StabilityMonitoringForms$BsConsole.Delete.make, {
                onClose: (function (param) {
                    return Curry._1(setFormModal, (function (param) {
                                  
                                }));
                  }),
                onDelete: (function (param) {
                    var arg = BpgTask$BsConsole.Metric.$$delete(metric);
                    return Curry._2((function (param) {
                                    return (function (param$1, param$2) {
                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                      });
                                  })(token), undefined, (function (resp) {
                                  if (resp.tag) {
                                    Curry._1(setFormModal, (function (param) {
                                            
                                          }));
                                    Curry._1(updateMetricRemote, undefined);
                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to delete metric"));
                                  }
                                  Curry._1(setFormModal, (function (param) {
                                          
                                        }));
                                  Curry._1(updateMetricRemote, undefined);
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully deleted metric"));
                                }));
                  }),
                entityName: I18N$BsConsole.get(undefined, "metric") + (" - " + metric.name)
              });
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Button.default, {
                  disableRipple: true,
                  variant: "contained",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(1)),
                          /* [] */0
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(setFormModal, (function (param) {
                                    return /* MetricGroup_Create */Block.__(0, [undefined]);
                                  }));
                    }),
                  children: I18N$BsConsole.show(undefined, "Create a metric group")
                }), React.createElement(Row2$BsConsole.make, {
                  flexWrap: /* wrap */-822134326,
                  children: Belt_Array.mapWithIndex(Belt_Array.keep(metricGroups, (function (metricGroup) {
                              return metricGroup.project === currentProject.pid;
                            })), (function (idx, metricGroup) {
                          var metricAttributes$1 = Belt_Array.keep(metricAttributes, (function (attr) {
                                  return attr.metricGroup === metricGroup.id;
                                }));
                          var metrics$1 = Belt_Array.keep(metrics, (function (metric) {
                                  return metric.metricGroup === metricGroup.id;
                                }));
                          return React.createElement(PS_StabilityMonitoring$MetricGroup, {
                                      metricGroup: metricGroup,
                                      metricAttributes: metricAttributes$1,
                                      metrics: metrics$1,
                                      setFormModal: (function (formModal) {
                                          return Curry._1(setFormModal, (function (param) {
                                                        return formModal;
                                                      }));
                                        }),
                                      onEdit: (function (param) {
                                          return Curry._1(setFormModal, (function (param) {
                                                        return /* MetricGroup_Create */Block.__(0, [metricGroup]);
                                                      }));
                                        }),
                                      onDelete: (function (param) {
                                          return Curry._1(setFormModal, (function (param) {
                                                        return /* DeleteMetricGroup */Block.__(1, [metricGroup]);
                                                      }));
                                        }),
                                      key: metricGroup.name + ("__" + String(idx))
                                    });
                        }))
                }), tmp);
}

var MetricGroups = {
  make: PS_StabilityMonitoring$MetricGroups
};

function PS_StabilityMonitoring(Props) {
  var config = Props.config;
  var projectName = Props.projectName;
  var token = Props.token;
  var serviceEndpoint = Services$BsConsole.getEndpoint(undefined)(config.services, "metrics-importer");
  var currentProject = Belt_Option.getExn(Belt_List.getBy(config.projects, (function (project) {
              return project.name === projectName;
            })));
  var match = React.useState((function () {
          return /* MetricGroups */0;
        }));
  var setTab = match[1];
  var tab = match[0];
  var match$1 = Task2$BsConsole.useRefetchable(undefined, BpgTask$BsConsole.MetricGroup.fetchAll, undefined);
  var updateMetricGroupRemote = match$1[1];
  var metricGroupRemote = match$1[0];
  var match$2 = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var describeRemote = match$2[0];
  var match$3 = Task2$BsConsole.useRefetchable(true, BpgTask$BsConsole.MetricAttribute.fetchAll, undefined);
  var metricAttributeRemote = match$3[0];
  var match$4 = Task2$BsConsole.useRefetchable(undefined, BpgTask$BsConsole.Metric.fetchAll, undefined);
  var updateMetricRemote = match$4[1];
  var metricRemote = match$4[0];
  var metricGroups;
  metricGroups = typeof metricGroupRemote === "number" ? [] : (
      metricGroupRemote.tag ? [] : Belt_Array.keep(metricGroupRemote[0][0], (function (metricGroup) {
                return metricGroup.project === currentProject.pid;
              }))
    );
  var metricAttributes;
  metricAttributes = typeof metricAttributeRemote === "number" ? [] : (
      metricAttributeRemote.tag ? [] : metricAttributeRemote[0][0]
    );
  var metrics;
  metrics = typeof metricRemote === "number" ? [] : (
      metricRemote.tag ? [] : metricRemote[0][0]
    );
  var attributes;
  attributes = typeof describeRemote === "number" ? [] : (
      describeRemote.tag ? [] : Belt_List.toArray(describeRemote[0])
    );
  var tmp;
  if (CoronerdGate$BsConsole.stabilityMonitoring) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              className: Css.style(/* :: */[
                    Css.marginBottom(Css.rem(1)),
                    /* [] */0
                  ]),
              color: Colors$BsConsole.grey1,
              children: null
            }, React.createElement("span", {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.accent)),
                        /* :: */[
                          Css.marginRight(Css.px(2)),
                          /* [] */0
                        ]
                      ])
                }, React.createElement(Info.default, {
                      color: "inherit",
                      fontSize: "inherit"
                    })), React.createElement("strong", undefined, I18N$BsConsole.show(undefined, "Tip: ")), I18N$BsConsole.show(undefined, "Create a metric group to define the shape of the metrics you will compute error rates against. Then, define metric sources and importers to collect the data and populate those metrics.")), React.createElement(Tabs.default, {
              value: tab,
              classes: {
                root: Css.style(/* :: */[
                      Css.selector("button", /* :: */[
                            Css.textAlign(/* left */-944764921),
                            /* [] */0
                          ]),
                      /* [] */0
                    ])
              },
              indicatorColor: "primary",
              onChange: (function (param, tab) {
                  return Curry._1(setTab, (function (param) {
                                return tab;
                              }));
                }),
              children: null
            }, React.createElement(Tab.default, {
                  classes: {
                    labelContainer: Css.style(/* :: */[
                          Css.padding(Css.px(4)),
                          /* [] */0
                        ]),
                    root: Css.style(/* :: */[
                          Css.unsafe("minWidth", "unset"),
                          /* :: */[
                            Css.unsafe("minHeight", "unset"),
                            /* :: */[
                              Css.marginRight(Css.rem(1)),
                              /* [] */0
                            ]
                          ]
                        ])
                  },
                  value: /* MetricGroups */0,
                  label: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading6 */5,
                        color: Colors$BsConsole.grey1,
                        children: I18N$BsConsole.show(undefined, "Metrics groups")
                      }),
                  disableRipple: true
                }), React.createElement(Tab.default, {
                  classes: {
                    labelContainer: Css.style(/* :: */[
                          Css.padding(/* zero */-789508312),
                          /* [] */0
                        ]),
                    root: Css.style(/* :: */[
                          Css.unsafe("minWidth", "unset"),
                          /* :: */[
                            Css.unsafe("minHeight", "unset"),
                            /* :: */[
                              Css.marginRight(Css.rem(1)),
                              /* [] */0
                            ]
                          ]
                        ])
                  },
                  value: /* MetricSources */1,
                  label: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading6 */5,
                        color: Colors$BsConsole.grey1,
                        children: I18N$BsConsole.show(undefined, "Metrics sources")
                      }),
                  disableRipple: true
                })), tab ? (
            serviceEndpoint !== undefined ? React.createElement(PS_StabilityMonitoring$Sources, {
                    token: token,
                    projectName: projectName,
                    serviceEndpoint: serviceEndpoint,
                    currentProject: currentProject,
                    metricGroups: metricGroups,
                    metrics: metrics,
                    updateMetricGroupRemote: updateMetricGroupRemote,
                    updateMetricRemote: updateMetricRemote
                  }) : React.createElement(Paper.default, {
                    classes: {
                      root: Css.style(/* :: */[
                            Css.padding(Css.px(16)),
                            /* [] */0
                          ])
                    },
                    children: React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          children: null
                        }, React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.redDark)),
                                    /* :: */[
                                      Css.marginRight(Css.rem(0.5)),
                                      /* [] */0
                                    ]
                                  ])
                            }, React.createElement(Warning.default, {
                                  color: "inherit"
                                })), React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body1 */8,
                              children: I18N$BsConsole.show(undefined, "Adding sources and importers requires the metrics-importer Backtrace service. Please reach out to Backtrace support for further assistance.")
                            }))
                  })
          ) : React.createElement(PS_StabilityMonitoring$MetricGroups, {
                currentProject: currentProject,
                token: token,
                metricGroups: metricGroups,
                metrics: metrics,
                metricAttributes: metricAttributes,
                attributes: attributes,
                updateMetricGroupRemote: updateMetricGroupRemote,
                updateMetricsAttributeRemote: match$3[1],
                updateMetricRemote: updateMetricRemote
              }));
  } else {
    var version = window._BACKTRACE_CORONER_VERSION;
    tmp = React.createElement(Paper.default, {
          classes: {
            root: Css.style(/* :: */[
                  Css.padding(Css.px(16)),
                  /* [] */0
                ])
          },
          children: null
        }, React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.redDark)),
                        /* :: */[
                          Css.marginRight(Css.rem(0.5)),
                          /* [] */0
                        ]
                      ])
                }, React.createElement(Warning.default, {
                      color: "inherit"
                    })), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  children: I18N$BsConsole.show(undefined, "Unfortunately this feature requires a minimum coronerd version, please upgrade your instance or contact Backtrace support for further information.")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  children: null
                }, I18N$BsConsole.show(undefined, "Minimum version:"), React.createElement("span", undefined, I18N$BsConsole.showSkip(CoronerdGate$BsConsole.stabilityMonitoringMinimumVersion)))), (version == null) ? null : React.createElement(BtTypography$BsConsole.make, {
                variant: /* Caption */11,
                children: null
              }, I18N$BsConsole.show(undefined, "Current version:"), React.createElement("span", undefined, I18N$BsConsole.showSkip(version))));
  }
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ]),
                  color: Colors$BsConsole.grey1,
                  children: I18N$BsConsole.show(undefined, "Stability monitoring")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(1)),
                        /* [] */0
                      ]),
                  color: Colors$BsConsole.grey1,
                  children: I18N$BsConsole.show(undefined, "Configure Backtrace to compute error rates against specific metrics like \"Sessions started\" or \"Songs played\". These metrics can be imported from data that lives in external systems like Graphite, Influx, Redshift, or Postgres.")
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                  className: Css.style(/* :: */[
                        Css.margin2(Css.rem(1), /* zero */-789508312),
                        /* :: */[
                          Css.height(Css.px(4)),
                          /* [] */0
                        ]
                      ])
                }), tmp);
}

function make(config, projectName, token, children) {
  return ReasonReactCompat.wrapReactForReasonReact(PS_StabilityMonitoring, {
              config: config,
              projectName: projectName,
              token: token
            }, children);
}

var Re = {
  make: make
};

var make$1 = PS_StabilityMonitoring;

exports.menuItem = menuItem;
exports.LogLine = LogLine;
exports.ServiceLogs = ServiceLogs;
exports.ImportersTable = ImportersTable;
exports.Source = Source;
exports.Sources = Sources;
exports.MetricGroup = MetricGroup;
exports.MetricGroups = MetricGroups;
exports.make = make$1;
exports.Re = Re;
/* menuItem Not a pure module */
