// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Col2$BsConsole = require("../components/Col2.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Group$BsConsole = require("../bs-crdb-response/src/Group.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var Numeral$BsConsole = require("../Numeral.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var Chip = require("@material-ui/core/Chip");
var BtTypography$BsConsole = require("../BtTypography.js");
var DescribeTask$BsConsole = require("../DescribeTask.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var TokenContext$BsConsole = require("../context/TokenContext.js");
var Info = require("@material-ui/icons/Info");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Switch = require("@material-ui/core/Switch");
var BtAutocomplete$BsConsole = require("../BtAutocomplete.js");
var Tooltip = require("@material-ui/core/Tooltip");
var Delete = require("@material-ui/icons/Delete");
var IconButton = require("@material-ui/core/IconButton");
var CrdbPaginationTask$BsConsole = require("../CrdbPaginationTask.js");
var SamplingConfigurationApi$BsConsole = require("./SamplingConfigurationApi.js");

var featureTitle = Css.style(/* [] */0);

var featureDescription = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* [] */0
    ]);

var subFeatureTitle = Css.style(/* [] */0);

var marginBottomSpace = Css.style(/* :: */[
      Css.marginBottom(Css.rem(1)),
      /* [] */0
    ]);

var slightMarginBottomSpace = Css.style(/* :: */[
      Css.marginBottom(Css.rem(0.5)),
      /* [] */0
    ]);

var marginTopSpace = Css.style(/* :: */[
      Css.marginTop(Css.rem(1)),
      /* [] */0
    ]);

var marginLeftSpace = Css.style(/* :: */[
      Css.marginLeft(Css.rem(1)),
      /* [] */0
    ]);

var marginRightSpace = Css.style(/* :: */[
      Css.marginRight(Css.rem(1)),
      /* [] */0
    ]);

var attributeList = Css.style(/* :: */[
      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey2)),
      /* [] */0
    ]);

var attributeChip = Css.style(/* :: */[
      Css.margin(Css.rem(0.25)),
      /* :: */[
        Css.borderRadius(Css.em(0.25)),
        /* [] */0
      ]
    ]);

var halfSpace = Css.style(/* :: */[
      Css.width(Css.pct(49)),
      /* [] */0
    ]);

var rowItem = Css.style(/* :: */[
      Css.height(Css.px(48)),
      /* :: */[
        Css.marginBottom(Css.rem(0.5)),
        /* :: */[
          Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
          /* :: */[
            Css.paddingLeft(Css.rem(1)),
            /* :: */[
              Css.paddingRight(Css.rem(1)),
              /* :: */[
                Css.paddingTop(Css.rem(0.25)),
                /* :: */[
                  Css.paddingBottom(Css.rem(0.25)),
                  /* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.justifyContent(/* spaceBetween */516682146),
                      /* :: */[
                        Css.alignItems(/* center */98248149),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var disabledRow = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.disabled)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.blackA87)),
        /* [] */0
      ]
    ]);

var attributeItem = Css.merge(/* :: */[
      rowItem,
      /* :: */[
        Css.style(/* :: */[
              Css.marginRight(Css.em(0.5)),
              /* :: */[
                Css.marginBottom(Css.em(0.5)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var attributeItemDisabled = Css.merge(/* :: */[
      attributeItem,
      /* :: */[
        disabledRow,
        /* [] */0
      ]
    ]);

var backoffListItem = Css.merge(/* :: */[
      rowItem,
      /* :: */[
        Css.style(/* :: */[
              Css.height(Css.em(2)),
              /* :: */[
                Css.width(Css.pct(100.0)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var backoffListItemDisabled = Css.merge(/* :: */[
      backoffListItem,
      /* :: */[
        disabledRow,
        /* [] */0
      ]
    ]);

var resetListItem = Css.merge(/* :: */[
      rowItem,
      /* :: */[
        Css.style(/* :: */[
              Css.width(Css.rem(20)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var resetListItemDisabled = Css.merge(/* :: */[
      attributeItem,
      /* :: */[
        disabledRow,
        /* [] */0
      ]
    ]);

var backoffRow = Css.merge(/* :: */[
      rowItem,
      /* :: */[
        Css.style(/* :: */[
              Css.height(Css.px(48)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var Style = {
  merge: Css.merge,
  featureTitle: featureTitle,
  featureDescription: featureDescription,
  subFeatureTitle: subFeatureTitle,
  marginBottomSpace: marginBottomSpace,
  slightMarginBottomSpace: slightMarginBottomSpace,
  marginTopSpace: marginTopSpace,
  marginLeftSpace: marginLeftSpace,
  marginRightSpace: marginRightSpace,
  attributeList: attributeList,
  attributeChip: attributeChip,
  halfSpace: halfSpace,
  rowItem: rowItem,
  disabledRow: disabledRow,
  attributeItem: attributeItem,
  attributeItemDisabled: attributeItemDisabled,
  backoffListItem: backoffListItem,
  backoffListItemDisabled: backoffListItemDisabled,
  resetListItem: resetListItem,
  resetListItemDisabled: resetListItemDisabled,
  backoffRow: backoffRow
};

function PS_StorageSampling$Attributes$Edit(Props) {
  var conf = Props.conf;
  var attributes = Props.attributes;
  var setConf = Props.setConf;
  return React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Subtitle2 */7,
                  className: Css.merge(/* :: */[
                        slightMarginBottomSpace,
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Object grouping")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Css.merge(/* :: */[
                        marginBottomSpace,
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Define the attributes used to evaluate whether or not to persist an object and it's associated attachments to long-term storage. Additional attributes may be included in addition to fingerprint, which define buckets to which storage sampling rates are applied. The storage sampling rates are also configurable below.")
                }), React.createElement(Row2$BsConsole.make, {
                  className: marginBottomSpace,
                  children: React.createElement(BtAutocomplete$BsConsole.make, {
                        toString: (function (attr) {
                            return attr.name;
                          }),
                        considerations: Belt_List.toArray(Belt_List.keep(attributes, (function (att) {
                                    return Belt_Option.isNone(Belt_List.getBy(conf.configuration.attributes, (function (confAtt) {
                                                      return confAtt === att.name;
                                                    })));
                                  }))),
                        onChange: (function (raw, _attribute) {
                            if (Belt_List.length(conf.configuration.attributes) >= 4) {
                              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "There is a maxium of 4 attributes."));
                            } else if (Belt_Option.isSome(Belt_List.getBy(attributes, (function (param) {
                                          return param.name === raw;
                                        })))) {
                              return Curry._1(setConf, (function (param) {
                                            return SamplingConfigurationApi$BsConsole.addAttribute(conf, raw);
                                          }));
                            } else {
                              return ;
                            }
                          }),
                        defaultValue: "",
                        placeholder: I18N$BsConsole.get(undefined, "Select an attribute"),
                        autoFocus: true,
                        inputAriaLabel: I18N$BsConsole.get(undefined, "Attribute"),
                        renderListOption: (function (attr, idx) {
                            return React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body1 */8,
                                        children: I18N$BsConsole.dynamic(attr.name),
                                        key: attr.name + ("__" + String(idx))
                                      });
                          }),
                        renderInput: (function (param) {
                            var onFocus = param.onFocus;
                            var onChange = param.onChange;
                            var onFocus$prime = Curry.__1(onFocus);
                            var onChange$prime = Curry.__1(onChange);
                            return React.createElement(BtSettings$BsConsole.FormSectionTextField.make, {
                                        onFocus: onFocus$prime,
                                        className: Css.merge(/* :: */[
                                              slightMarginBottomSpace,
                                              /* [] */0
                                            ]),
                                        placeholder: param.placeholder,
                                        value: param.value,
                                        onChange: onChange$prime
                                      });
                          })
                      })
                }), React.createElement(Row2$BsConsole.make, {
                  flexWrap: /* wrap */-822134326,
                  className: Css.merge(/* :: */[
                        marginBottomSpace,
                        /* :: */[
                          attributeList,
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, React.createElement(Chip.default, {
                      label: I18N$BsConsole.show(undefined, "fingerprint"),
                      classes: {
                        root: attributeChip
                      }
                    }), Belt_List.toArray(Belt_List.map(conf.configuration.attributes, (function (attribute) {
                            return React.createElement(Chip.default, {
                                        label: I18N$BsConsole.show(undefined, attribute),
                                        onDelete: (function (param) {
                                            return Curry._1(setConf, (function (param) {
                                                          return SamplingConfigurationApi$BsConsole.removeAttribute(conf, attribute);
                                                        }));
                                          }),
                                        classes: {
                                          root: attributeChip
                                        }
                                      });
                          })))));
}

var Edit = {
  make: PS_StorageSampling$Attributes$Edit
};

function PS_StorageSampling$Attributes$List(Props) {
  var samplingConfiguration = Props.samplingConfiguration;
  if (samplingConfiguration !== undefined) {
    return React.createElement(Row2$BsConsole.make, {
                children: null
              }, Belt_List.toArray(Belt_List.map(samplingConfiguration.configuration.attributes, (function (attribute) {
                          return React.createElement("div", {
                                      className: samplingConfiguration.disabled ? attributeItemDisabled : attributeItem
                                    }, React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Body1 */8,
                                          children: I18N$BsConsole.dynamic(attribute)
                                        }));
                        }))), React.createElement("div", {
                    className: samplingConfiguration.disabled ? attributeItemDisabled : attributeItem
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        children: I18N$BsConsole.show(undefined, "fingerprint")
                      })));
  } else {
    return null;
  }
}

var List = {
  make: PS_StorageSampling$Attributes$List
};

var Attributes = {
  Edit: Edit,
  List: List
};

function PS_StorageSampling$PercentageSampled(Props) {
  var crdbRemote = Props.crdbRemote;
  var tmp;
  if (typeof crdbRemote === "number" || crdbRemote.tag) {
    tmp = null;
  } else {
    var match = crdbRemote[0][0];
    var columns = match[0];
    var match$1 = Belt_List.reduce(match[1], /* tuple */[
          /* tuple */[
            0,
            0
          ],
          /* tuple */[
            0,
            0
          ]
        ], (function (param, group) {
            var match = param[1];
            var otherSizeAcc = match[1];
            var otherCountAcc = match[0];
            var samplingAcc = param[0];
            var getter = function (param, param$1) {
              return Group$BsConsole.getAggregation(columns, group, param, param$1);
            };
            var objectSize = Group$BsConsole.unwrapSum(getter("object.size", /* Sum */7));
            if (objectSize === undefined) {
              return /* tuple */[
                      samplingAcc,
                      /* tuple */[
                        otherCountAcc,
                        otherSizeAcc
                      ]
                    ];
            }
            if (objectSize.tag !== /* Bytes */5) {
              return /* tuple */[
                      samplingAcc,
                      /* tuple */[
                        otherCountAcc,
                        otherSizeAcc
                      ]
                    ];
            }
            var v = objectSize[0];
            if (group.factor.value === "sampling") {
              return /* tuple */[
                      /* tuple */[
                        group.count,
                        Caml_format.caml_float_of_string(v)
                      ],
                      /* tuple */[
                        otherCountAcc,
                        otherSizeAcc
                      ]
                    ];
            } else {
              return /* tuple */[
                      samplingAcc,
                      /* tuple */[
                        otherCountAcc + group.count | 0,
                        otherSizeAcc + Caml_format.caml_float_of_string(v)
                      ]
                    ];
            }
          }));
    var otherSize = match$1[1][1];
    var match$2 = match$1[0];
    var sampledSize = match$2[1];
    tmp = React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              children: I18N$BsConsole.dynamic(match$2[0] === 0 ? I18N$BsConsole.get(undefined, "Storage sampling policies have not taken effect in the last 7 days.") : Numeral$BsConsole.format("0,0.0b", Pervasives.string_of_float(otherSize)) + (" / " + (Numeral$BsConsole.format("0,0.0b", Pervasives.string_of_float(sampledSize + otherSize)) + (" (" + (Numeral$BsConsole.format("0,0.00", Pervasives.string_of_float(otherSize / (otherSize + sampledSize) * 100)) + ("%) " + I18N$BsConsole.get(undefined, "of objects have been persisted to long-term storage in the last 7 days.")))))))
            }));
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var PercentageSampled = {
  make: PS_StorageSampling$PercentageSampled
};

function PS_StorageSampling$Backoffs$Edit(Props) {
  var conf = Props.conf;
  var setConf = Props.setConf;
  var match = React.useState((function () {
          return 0;
        }));
  var setErrorValue = match[1];
  var errorValue = match[0];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setSecondsValue = match$1[1];
  var secondsValue = match$1[0];
  var match$2 = React.useState((function () {
          return conf.configuration.resetInterval;
        }));
  var setResetInterval = match$2[1];
  return React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Subtitle2 */7,
                  className: Css.merge(/* :: */[
                        slightMarginBottomSpace,
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Rate limit rules")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Css.merge(/* :: */[
                        marginBottomSpace,
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Rate limits determine how long to wait between object acceptances. Add or delete limits to customize your rules.")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  children: React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        children: null
                      }, I18N$BsConsole.show(undefined, "Keep "), React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: I18N$BsConsole.get(undefined, "[ count ]"),
                            value: String(errorValue),
                            onChange: (function ($$event) {
                                var value = Caml_format.caml_int_of_string(BtSettings$BsConsole.getInputValue($$event));
                                return Curry._1(setErrorValue, (function (param) {
                                              return value;
                                            }));
                              }),
                            className: Css.merge(/* :: */[
                                  marginLeftSpace,
                                  /* :: */[
                                    marginRightSpace,
                                    /* [] */0
                                  ]
                                ])
                          }), I18N$BsConsole.show(undefined, " errors delaying "), React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: "[ minutes ]",
                            value: String(secondsValue / 60 | 0),
                            onChange: (function ($$event) {
                                var value = Caml_format.caml_int_of_string(BtSettings$BsConsole.getInputValue($$event));
                                return Curry._1(setSecondsValue, (function (param) {
                                              return Caml_int32.imul(value, 60);
                                            }));
                              }),
                            className: Css.merge(/* :: */[
                                  marginLeftSpace,
                                  /* :: */[
                                    marginRightSpace,
                                    /* [] */0
                                  ]
                                ])
                          }), I18N$BsConsole.show(undefined, " minutes between accepting each."), React.createElement(Button.default, {
                            disabled: secondsValue < 0 || errorValue <= 0,
                            onClick: (function (param) {
                                if (errorValue > 0) {
                                  return Curry._1(setConf, (function (param) {
                                                return SamplingConfigurationApi$BsConsole.addBackoff(conf, {
                                                            count: errorValue,
                                                            interval: secondsValue
                                                          });
                                              }));
                                }
                                
                              }),
                            children: I18N$BsConsole.show(undefined, "Add")
                          }))
                }), Belt_List.toArray(Belt_List.mapWithIndex(conf.configuration.backoffs, (function (_idxM, backoff) {
                        return React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Body2 */9,
                                    children: React.createElement(Row2$BsConsole.make, {
                                          alignItems: /* center */98248149,
                                          justifyContent: /* spaceBetween */516682146,
                                          className: backoffRow,
                                          children: null
                                        }, I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Keep") + (" " + (String(backoff.count) + (" " + (I18N$BsConsole.get(undefined, "objects waiting") + (" " + (String(backoff.interval / 60 | 0) + (" " + I18N$BsConsole.get(undefined, "minutes between accepting objects."))))))))), React.createElement(IconButton.default, {
                                              onClick: (function (param) {
                                                  Curry._1(setConf, (function (param) {
                                                          return SamplingConfigurationApi$BsConsole.removeBackoff(conf, backoff);
                                                        }));
                                                  
                                                }),
                                              children: React.createElement(Delete.default, { })
                                            }))
                                  });
                      }))), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  children: React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        children: null
                      }, I18N$BsConsole.show(undefined, "Reset rate limits every "), React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: "",
                            value: String(match$2[0]),
                            onChange: (function ($$event) {
                                var value = Caml_format.caml_int_of_string(BtSettings$BsConsole.getInputValue($$event));
                                Curry._1(setResetInterval, (function (param) {
                                        return value;
                                      }));
                                Curry._1(setConf, (function (param) {
                                        return SamplingConfigurationApi$BsConsole.setResetInterval(conf, value);
                                      }));
                                
                              }),
                            className: Css.merge(/* :: */[
                                  marginLeftSpace,
                                  /* :: */[
                                    marginRightSpace,
                                    /* [] */0
                                  ]
                                ])
                          }), I18N$BsConsole.show(undefined, "days."))
                }));
}

var Edit$1 = {
  make: PS_StorageSampling$Backoffs$Edit
};

function PS_StorageSampling$Backoffs$List(Props) {
  var samplingConfiguration = Props.samplingConfiguration;
  return React.createElement(React.Fragment, undefined, samplingConfiguration !== undefined ? Belt_List.toArray(Belt_List.mapWithIndex(samplingConfiguration.configuration.backoffs, (function (_i, rateLimit) {
                          return React.createElement("div", {
                                      className: samplingConfiguration.disabled ? backoffListItemDisabled : backoffListItem
                                    }, React.createElement(BtTypography$BsConsole.make, {
                                          variant: /* Body1 */8,
                                          children: I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Keep") + (" " + (String(rateLimit.count) + (" " + (I18N$BsConsole.get(undefined, "errors delaying") + (" " + (String(rateLimit.interval / 60 | 0) + (" " + I18N$BsConsole.get(undefined, "minutes between accepting each.")))))))))
                                        }));
                        }))) : null);
}

var List$1 = {
  make: PS_StorageSampling$Backoffs$List
};

var Backoffs = {
  Edit: Edit$1,
  List: List$1
};

function PS_StorageSampling$SamplingConfiguration$Edit(Props) {
  var token = Props.token;
  Props.project;
  var refresh = Props.refresh;
  var samplingConfiguration = Props.samplingConfiguration;
  var attributes = Props.attributes;
  var match = React.useState((function () {
          return samplingConfiguration;
        }));
  var setConf = match[1];
  var conf = match[0];
  React.useEffect((function () {
          Curry._1(setConf, (function (param) {
                  return samplingConfiguration;
                }));
          
        }), [samplingConfiguration]);
  if (conf !== undefined) {
    return React.createElement(Col2$BsConsole.make, {
                justifyContent: /* spaceBetween */516682146,
                children: null
              }, React.createElement(PS_StorageSampling$Attributes$Edit, {
                    conf: conf,
                    attributes: attributes,
                    setConf: setConf
                  }), React.createElement(PS_StorageSampling$Backoffs$Edit, {
                    conf: conf,
                    setConf: setConf
                  }), React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    justifyContent: /* spaceBetween */516682146,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(Button.default, {
                        color: "primary",
                        disabled: Caml_obj.caml_equal(SamplingConfigurationApi$BsConsole.sortBackoffs(conf).configuration.backoffs, SamplingConfigurationApi$BsConsole.Backoff.$$default),
                        classes: {
                          root: Css.merge(/* :: */[
                                subFeatureTitle,
                                /* :: */[
                                  marginBottomSpace,
                                  /* :: */[
                                    marginLeftSpace,
                                    /* [] */0
                                  ]
                                ]
                              ])
                        },
                        onClick: (function (_e) {
                            return Curry._1(setConf, (function (conf) {
                                          return Belt_Option.map(conf, SamplingConfigurationApi$BsConsole.resetRateLimitDefaults);
                                        }));
                          }),
                        children: I18N$BsConsole.show(undefined, "Reset Defaults")
                      }), React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        disabled: !SamplingConfigurationApi$BsConsole.isValid(conf),
                        classes: {
                          root: Css.merge(/* :: */[
                                subFeatureTitle,
                                /* :: */[
                                  marginBottomSpace,
                                  /* :: */[
                                    marginLeftSpace,
                                    /* [] */0
                                  ]
                                ]
                              ])
                        },
                        onClick: (function (_e) {
                            return SamplingConfigurationApi$BsConsole.save(token, conf, (function (param) {
                                          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Success updating configuration."));
                                          Curry._1(refresh, undefined);
                                          return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Sampling_SaveConfiguration */44]));
                                        }), (function (msg) {
                                          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, msg)));
                                        }));
                          }),
                        children: I18N$BsConsole.show(undefined, "Save")
                      })), Belt_Option.isSome(Belt_List.getBy(conf.configuration.backoffs, (function (backoff) {
                          return backoff.interval === 0;
                        }))) ? null : React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Caption */11,
                      children: I18N$BsConsole.show(undefined, "Invalid configuration. Must have a 0 minute rate limit rule.")
                    }));
  } else {
    return null;
  }
}

var Edit$2 = {
  make: PS_StorageSampling$SamplingConfiguration$Edit
};

var SamplingConfiguration = {
  Edit: Edit$2
};

function PS_StorageSampling$Main(Props) {
  Props.config;
  var token = Props.token;
  var project = Props.project;
  var match = React.useState((function () {
          
        }));
  var setSamplingConfiguration = match[1];
  var samplingConfiguration = match[0];
  var match$1 = SamplingConfigurationApi$BsConsole.use(token);
  var refetchSamplingConfiguration = match$1[1];
  var samplingConfigurations = match$1[0];
  React.useEffect((function () {
          if (typeof samplingConfigurations !== "number" && !samplingConfigurations.tag) {
            var samplingConfiguration = Belt_Array.getBy(samplingConfigurations[0], (function (sc) {
                    return sc.project === project.pid;
                  }));
            if (samplingConfiguration !== undefined) {
              Curry._1(setSamplingConfiguration, (function (param) {
                      return samplingConfiguration;
                    }));
            } else {
              Curry._1(setSamplingConfiguration, (function (param) {
                      return {
                              key: undefined,
                              project: project.pid,
                              configuration: SamplingConfigurationApi$BsConsole.Configuration.$$default,
                              disabled: true
                            };
                    }));
            }
          }
          
        }), [samplingConfigurations]);
  var query_000 = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Relative */Block.__(0, [
          /* Floating */0,
          /* Week */2
        ]), Crdb$BsConsole.Aperture.$$default);
  var query_001 = /* Custom */["_delete_reason"];
  var query_002 = Curry._1(Crdb$BsConsole.Fold.fromArray, [Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
              "object.size",
              /* Sum */3
            ])]);
  var query = /* Aggregate */Block.__(0, [
      query_000,
      query_001,
      query_002,
      undefined,
      undefined,
      undefined
    ]);
  var match$2 = CrdbPaginationTask$BsConsole.Aggregate.use(token, project.name, query, /* Custom */["_delete_reason"], "sampling_report");
  var match$3 = DescribeTask$BsConsole.use(token, project.name, undefined, undefined);
  var describeRemote = match$3[0];
  var match$4 = React.useState((function () {
          return false;
        }));
  var setEditModal = match$4[1];
  var tmp;
  tmp = typeof samplingConfigurations === "number" || samplingConfigurations.tag ? null : React.createElement(React.Fragment, undefined, React.createElement(PS_StorageSampling$PercentageSampled, {
              crdbRemote: match$2[0]
            }), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
              className: Css.merge(/* :: */[
                    marginBottomSpace,
                    /* :: */[
                      marginTopSpace,
                      /* [] */0
                    ]
                  ])
            }), React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: subFeatureTitle,
                  children: I18N$BsConsole.show(undefined, "Enable sampling")
                }), React.createElement(Switch.default, {
                  onChange: (function (param, param$1) {
                      var checked = Belt_Option.getWithDefault(Belt_Option.map(samplingConfiguration, (function (sc) {
                                  return !sc.disabled;
                                })), false);
                      Belt_Option.map(samplingConfiguration, (function (sc) {
                              return SamplingConfigurationApi$BsConsole.setDisabled(token, sc, (function (param) {
                                            SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Success updating enablement."));
                                            Curry._1(refetchSamplingConfiguration, undefined);
                                            return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [checked ? /* Sampling_Disable */43 : /* Sampling_Enable */42]));
                                          }), (function (msg) {
                                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, msg)));
                                          }), !sc.disabled);
                            }));
                      
                    }),
                  checked: Belt_Option.getWithDefault(Belt_Option.map(samplingConfiguration, (function (sc) {
                              return !sc.disabled;
                            })), false)
                })), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
              className: Css.merge(/* :: */[
                    marginBottomSpace,
                    /* :: */[
                      marginTopSpace,
                      /* [] */0
                    ]
                  ])
            }), React.createElement("div", {
              className: marginBottomSpace
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.merge(/* :: */[
                        subFeatureTitle,
                        /* :: */[
                          marginBottomSpace,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Sampling settings")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.merge(/* :: */[
                        subFeatureTitle,
                        /* :: */[
                          marginBottomSpace,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Attribute and rate limits determine which errors to store.")
                }), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  classes: {
                    root: marginBottomSpace
                  },
                  onClick: (function (param) {
                      return Curry._1(setEditModal, (function (param) {
                                    return true;
                                  }));
                    }),
                  children: I18N$BsConsole.show(undefined, "Open configuration")
                })), React.createElement(BtTypography$BsConsole.make, {
              variant: /* Heading6 */5,
              className: Css.merge(/* :: */[
                    subFeatureTitle,
                    /* :: */[
                      marginTopSpace,
                      /* :: */[
                        marginBottomSpace,
                        /* [] */0
                      ]
                    ]
                  ]),
              children: I18N$BsConsole.show(undefined, "Object grouping")
            }), React.createElement(PS_StorageSampling$Attributes$List, {
              samplingConfiguration: samplingConfiguration
            }), React.createElement(BtTypography$BsConsole.make, {
              variant: /* Heading6 */5,
              className: Css.merge(/* :: */[
                    subFeatureTitle,
                    /* :: */[
                      marginTopSpace,
                      /* :: */[
                        marginBottomSpace,
                        /* [] */0
                      ]
                    ]
                  ]),
              children: I18N$BsConsole.show(undefined, "Rate limit rules")
            }), React.createElement(PS_StorageSampling$Backoffs$List, {
              samplingConfiguration: samplingConfiguration
            }), Belt_Option.getWithDefault(Belt_Option.map(samplingConfiguration, (function (sc) {
                    return React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Body1 */8,
                                children: I18N$BsConsole.dynamic(I18N$BsConsole.get(undefined, "Reset interval") + (": " + (String(sc.configuration.resetInterval) + (" " + I18N$BsConsole.get(undefined, "days")))))
                              });
                  })), null));
  var tmp$1;
  tmp$1 = typeof describeRemote === "number" || describeRemote.tag ? null : React.createElement(BtModal$BsConsole.Default.make, {
          open: match$4[0],
          onClose: (function (param) {
              return Curry._1(setEditModal, (function (param) {
                            return false;
                          }));
            }),
          modalWidth: 850,
          title: I18N$BsConsole.get(undefined, "Configuration"),
          subtitle: "",
          divider: true,
          children: React.createElement(Col2$BsConsole.make, {
                children: React.createElement(PS_StorageSampling$SamplingConfiguration$Edit, {
                      token: token,
                      project: project,
                      refresh: (function (param) {
                          Curry._1(refetchSamplingConfiguration, undefined);
                          return Curry._1(setEditModal, (function (param) {
                                        return false;
                                      }));
                        }),
                      samplingConfiguration: samplingConfiguration,
                      attributes: describeRemote[0]
                    })
              })
        });
  return React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.merge(/* :: */[
                        featureDescription,
                        /* :: */[
                          marginBottomSpace,
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, I18N$BsConsole.show(undefined, "Sampling conserves storage space by limiting the number of objects (used in the debugger view) persisted to long-term storage. Additional attributes may be included in addition to fingerprint, which define buckets to which storage sampling rates are applied. The storage sampling rates are also configurable below."), React.createElement(Tooltip.default, {
                      children: React.createElement("span", undefined, React.createElement(Info.default, {
                                color: "inherit",
                                fontSize: "inherit"
                              })),
                      title: I18N$BsConsole.get(undefined, "During \"processing\", Backtrace will a) execute data scrubbers, b) apply debug symbols, c) extract register information, environment variables, and human readable call stacks, d) index attributes, and e) apply fingerprinting algorithm. After processing, dump objects can be retained for Object Reprocessing and for Download if needed.")
                    })), tmp, tmp$1);
}

var Main = {
  make: PS_StorageSampling$Main
};

function PS_StorageSampling(Props) {
  var config = Props.config;
  var projectName = Props.projectName;
  var project = Belt_List.getBy(config.projects, (function (project) {
          return project.name === projectName;
        }));
  var token = Belt_Option.getExn(React.useContext(TokenContext$BsConsole.ctx));
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.merge(/* :: */[
                        featureTitle,
                        /* :: */[
                          marginBottomSpace,
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Storage Sampling")
                }), project !== undefined ? React.createElement(PS_StorageSampling$Main, {
                    config: config,
                    token: token,
                    project: project
                  }) : React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading5 */4,
                    className: Css.merge(/* :: */[
                          subFeatureTitle,
                          /* :: */[
                            marginBottomSpace,
                            /* [] */0
                          ]
                        ]),
                    children: I18N$BsConsole.show(undefined, "Project not found")
                  }));
}

function make(config, projectName, children) {
  return ReasonReactCompat.wrapReactForReasonReact(PS_StorageSampling, {
              config: config,
              projectName: projectName
            }, children);
}

var Re = {
  make: make
};

var make$1 = PS_StorageSampling;

exports.Style = Style;
exports.Attributes = Attributes;
exports.PercentageSampled = PercentageSampled;
exports.Backoffs = Backoffs;
exports.SamplingConfiguration = SamplingConfiguration;
exports.Main = Main;
exports.make = make$1;
exports.Re = Re;
/* featureTitle Not a pure module */
