// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Crdb$BsConsole = require("./crdb.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var About$BsConsole = require("./About.js");
var Route$BsConsole = require("./route.js");
var Share$BsConsole = require("./Share.js");
var Colors$BsConsole = require("./Colors.js");
var IFrame$BsConsole = require("./IFrame.js");
var ReasonReactRouter = require("reason-react/lib/js/src/ReasonReactRouter.js");
var Version$BsConsole = require("./Version.js");
var ZenDesk$BsConsole = require("./ZenDesk.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var Services$BsConsole = require("./Services.js");
var Changelog$BsConsole = require("./Changelog.js");
var GranSelect$BsConsole = require("./granSelect.js");
var FilterShell$BsConsole = require("./filterShell.js");
var SauceColors$BsConsole = require("./SauceColors.js");
var IFrameAppBar$BsConsole = require("./IFrameAppBar.js");
var TenantSelect$BsConsole = require("./TenantSelect.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var DemoProjectUtil$BsConsole = require("./DemoProjectUtil.js");
var HideFeatureGate$BsConsole = require("./HideFeatureGate.js");
var SavedQueriesModel$BsConsole = require("./saved-queries/SavedQueriesModel.js");
var BtProjectAutocomplete$BsConsole = require("./BtProjectAutocomplete.js");
var SavedQueriesContainer$BsConsole = require("./saved-queries/SavedQueriesContainer.js");
var CreateFirstProjectBanner$BsConsole = require("./CreateFirstProjectBanner.js");

function nameForRoute(route) {
  if (typeof route === "number") {
    return ;
  }
  switch (route.tag | 0) {
    case /* Project */7 :
    case /* ProjectOverview */8 :
    case /* ProjectCompareReleases */9 :
    case /* ProjectUpload */10 :
    case /* ProjectTriage */11 :
    case /* ProjectInboxLegacy1 */12 :
    case /* ProjectQueryBuilder */13 :
    case /* ProjectQueryBuilderLegacy1 */14 :
    case /* ProjectInstances */15 :
    case /* ProjectInstancesLegacy1 */16 :
    case /* ProjectDebugger */17 :
    case /* ProjectDebuggerLegacy1 */18 :
    case /* ProjectDebugger2 */19 :
    case /* ProjectFlamegraph */20 :
    case /* ProjectSimilarity */21 :
    case /* ProjectSettings */22 :
    case /* ProjectSettingsMetricsExporter */24 :
    case /* ProjectSettingsScrubbers */25 :
    case /* ProjectSettingsServerSideActions */26 :
    case /* ProjectSettingsAlerts */27 :
    case /* ProjectSettingsIntegratedDocs */28 :
    case /* ProjectSettingsSubmission */29 :
    case /* ProjectSettingsSubmissionTokens */31 :
    case /* ProjectSettingsSubmissionDataSources */33 :
    case /* ProjectSettingsAttributes */34 :
    case /* ProjectSettingsIntegrations */35 :
    case /* ProjectSettingsSymbolsUploadedArchives */36 :
    case /* ProjectSettingsSymbolsUpload */37 :
    case /* ProjectSettingsSymbolServers */38 :
    case /* ProjectSettingsSymbols */39 :
    case /* ProjectSettingsSymbolAccessTokens */40 :
    case /* ProjectSettingsMissingSymbols */41 :
    case /* ProjectSettingsSymbolSearch */42 :
    case /* ProjectSettingsScheduledReports */43 :
    case /* ProjectSettingsAccessControl */44 :
    case /* ProjectSettingsReprocessing */45 :
    case /* ProjectSettingsRetentionPolicy */46 :
    case /* ProjectSettingsSourceCode */47 :
    case /* ProjectSettingsApiTokens */48 :
    case /* ProjectSettingsDedupeConfig */49 :
    case /* ProjectSettingsStabilityMonitoring */50 :
    case /* RedirectLegacyDebugger */51 :
    case /* RedirectLegacyDetailView */52 :
    case /* RedirectLegacyQueryBuilder */53 :
    case /* SamlError */55 :
    case /* NotFound */56 :
        return route[0];
    default:
      return ;
  }
}

function handleApertureHavingChange(route, aperture, havings) {
  if (typeof route === "number") {
    throw [
          Caml_builtin_exceptions.assert_failure,
          /* tuple */[
            "appBar.re",
            67,
            9
          ]
        ];
  }
  switch (route.tag | 0) {
    case /* ProjectOverview */8 :
        return /* ProjectOverview */Block.__(8, [
                  route[0],
                  {
                    aperture: aperture
                  }
                ]);
    case /* ProjectCompareReleases */9 :
        return /* ProjectCompareReleases */Block.__(9, [
                  route[0],
                  route[1],
                  {
                    aperture: aperture,
                    groups: route[2].groups
                  }
                ]);
    case /* ProjectTriage */11 :
        var qs = route[1];
        return /* ProjectTriage */Block.__(11, [
                  route[0],
                  {
                    aperture: aperture,
                    sort: qs.sort,
                    stats: qs.stats,
                    havings: havings,
                    fold: qs.fold,
                    normBy: qs.normBy,
                    similarity: qs.similarity
                  },
                  route[2]
                ]);
    case /* ProjectQueryBuilder */13 :
        var qs$1 = route[1];
        return /* ProjectQueryBuilder */Block.__(13, [
                  route[0],
                  {
                    aperture: aperture,
                    factor: qs$1.factor,
                    havings: havings,
                    fold: qs$1.fold,
                    sort: qs$1.sort,
                    normBy: qs$1.normBy,
                    highlightExporter: qs$1.highlightExporter
                  }
                ]);
    case /* ProjectInstances */15 :
        var qs$2 = route[1];
        return /* ProjectInstances */Block.__(15, [
                  route[0],
                  {
                    aperture: aperture,
                    select: qs$2.select,
                    sort: qs$2.sort
                  }
                ]);
    case /* ProjectDebugger */17 :
        var qs$3 = route[1];
        return /* ProjectDebugger */Block.__(17, [
                  route[0],
                  {
                    aperture: aperture,
                    sort: qs$3.sort,
                    s: qs$3.s
                  }
                ]);
    case /* ProjectDebugger2 */19 :
        return /* ProjectDebugger2 */Block.__(19, [
                  route[0],
                  {
                    aperture: aperture,
                    state: route[1].state
                  }
                ]);
    case /* ProjectFlamegraph */20 :
        var qs$4 = route[1];
        return /* ProjectFlamegraph */Block.__(20, [
                  route[0],
                  {
                    aperture: aperture,
                    weighted: qs$4.weighted,
                    reversed: qs$4.reversed
                  }
                ]);
    case /* ProjectSimilarity */21 :
        var qs$5 = route[1];
        return /* ProjectSimilarity */Block.__(21, [
                  route[0],
                  {
                    aperture: aperture,
                    factor: qs$5.factor,
                    havings: qs$5.havings,
                    fold: qs$5.fold,
                    sort: qs$5.sort,
                    normBy: qs$5.normBy,
                    highlightExporter: qs$5.highlightExporter
                  }
                ]);
    default:
      throw [
            Caml_builtin_exceptions.assert_failure,
            /* tuple */[
              "appBar.re",
              67,
              9
            ]
          ];
  }
}

function nextRouteForCurrentRouteAndName(config, currentRoute, nextProjectName) {
  var userDefaultRoute = SavedQueriesModel$BsConsole.getRouteForUserDefault(config, nextProjectName);
  var projectDefaultRoute = SavedQueriesModel$BsConsole.getRouteForProjectDefault(config, nextProjectName);
  var match = ReasonReactRouter.dangerouslyGetInitialUrl(undefined).path;
  var useSavedQuery;
  if (match && match[0] === "p") {
    var match$1 = match[1];
    if (match$1) {
      var match$2 = match$1[1];
      useSavedQuery = match$2 && match$2[0] === "settings" ? false : true;
    } else {
      useSavedQuery = true;
    }
  } else {
    useSavedQuery = true;
  }
  if (useSavedQuery) {
    if (userDefaultRoute !== undefined) {
      return userDefaultRoute;
    }
    if (projectDefaultRoute !== undefined) {
      return projectDefaultRoute;
    }
    
  }
  if (typeof currentRoute === "number") {
    return ;
  }
  switch (currentRoute.tag | 0) {
    case /* ProjectOverview */8 :
        return /* ProjectOverview */Block.__(8, [
                  nextProjectName,
                  {
                    aperture: Crdb$BsConsole.Aperture.$$default
                  }
                ]);
    case /* ProjectCompareReleases */9 :
        return /* ProjectCompareReleases */Block.__(9, [
                  nextProjectName,
                  undefined,
                  {
                    aperture: Crdb$BsConsole.Aperture.$$default,
                    groups: undefined
                  }
                ]);
    case /* ProjectUpload */10 :
        return /* ProjectUpload */Block.__(10, [nextProjectName]);
    case /* ProjectTriage */11 :
        return /* ProjectTriage */Block.__(11, [
                  nextProjectName,
                  Route$BsConsole.getInboxParams(nextProjectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                  currentRoute[2]
                ]);
    case /* ProjectQueryBuilder */13 :
        return /* ProjectQueryBuilder */Block.__(13, [
                  nextProjectName,
                  Route$BsConsole.getQueryBuilderParams(undefined, undefined, nextProjectName, undefined, undefined, undefined, undefined, undefined, undefined)
                ]);
    case /* ProjectInstances */15 :
        return /* ProjectInstances */Block.__(15, [
                  nextProjectName,
                  Route$BsConsole.getInstancesParams(nextProjectName, undefined, undefined)
                ]);
    case /* ProjectDebugger */17 :
        return /* ProjectDebugger */Block.__(17, [
                  nextProjectName,
                  Route$BsConsole.getDebuggerParams(undefined, undefined, nextProjectName, undefined)
                ]);
    case /* ProjectDebugger2 */19 :
        return /* ProjectDebugger2 */Block.__(19, [
                  nextProjectName,
                  {
                    aperture: Crdb$BsConsole.Aperture.$$default,
                    state: undefined
                  }
                ]);
    case /* ProjectFlamegraph */20 :
        return /* ProjectFlamegraph */Block.__(20, [
                  nextProjectName,
                  Route$BsConsole.getFlamegraphParams(undefined, undefined, nextProjectName, undefined, undefined)
                ]);
    case /* ProjectSimilarity */21 :
        return /* ProjectSimilarity */Block.__(21, [
                  nextProjectName,
                  Route$BsConsole.getQueryBuilderParams(undefined, undefined, nextProjectName, undefined, undefined, undefined, undefined, undefined, undefined)
                ]);
    case /* ProjectSettings */22 :
        return /* ProjectSettings */Block.__(22, [nextProjectName]);
    case /* ProjectSettingsMetricsExporter */24 :
        return /* ProjectSettingsMetricsExporter */Block.__(24, [nextProjectName]);
    case /* ProjectSettingsScrubbers */25 :
        return /* ProjectSettingsScrubbers */Block.__(25, [nextProjectName]);
    case /* ProjectSettingsServerSideActions */26 :
        return /* ProjectSettingsServerSideActions */Block.__(26, [nextProjectName]);
    case /* ProjectSettingsAlerts */27 :
        return /* ProjectSettingsAlerts */Block.__(27, [
                  nextProjectName,
                  currentRoute[1]
                ]);
    case /* ProjectSettingsIntegratedDocs */28 :
        return /* ProjectSettingsIntegratedDocs */Block.__(28, [
                  nextProjectName,
                  {
                    doc: undefined
                  }
                ]);
    case /* ProjectSettingsSubmission */29 :
        return /* ProjectSettingsSubmission */Block.__(29, [nextProjectName]);
    case /* ProjectSettingsSubmissionTokens */31 :
        return /* ProjectSettingsSubmissionTokens */Block.__(31, [nextProjectName]);
    case /* ProjectSettingStorageSampling */32 :
        return /* ProjectSettingStorageSampling */Block.__(32, [nextProjectName]);
    case /* ProjectSettingsSubmissionDataSources */33 :
        return /* ProjectSettingsSubmissionDataSources */Block.__(33, [nextProjectName]);
    case /* ProjectSettingsAttributes */34 :
        return /* ProjectSettingsAttributes */Block.__(34, [
                  nextProjectName,
                  Route$BsConsole.ProjectSettingsAttributesParams.empty
                ]);
    case /* ProjectSettingsIntegrations */35 :
        return /* ProjectSettingsIntegrations */Block.__(35, [
                  nextProjectName,
                  currentRoute[1],
                  currentRoute[2]
                ]);
    case /* ProjectSettingsSymbolsUploadedArchives */36 :
        return /* ProjectSettingsSymbolsUploadedArchives */Block.__(36, [nextProjectName]);
    case /* ProjectSettingsSymbolsUpload */37 :
        return /* ProjectSettingsSymbolsUpload */Block.__(37, [nextProjectName]);
    case /* ProjectSettingsSymbolServers */38 :
        return /* ProjectSettingsSymbolServers */Block.__(38, [nextProjectName]);
    case /* ProjectSettingsSymbols */39 :
        return /* ProjectSettingsSymbols */Block.__(39, [nextProjectName]);
    case /* ProjectSettingsSymbolAccessTokens */40 :
        return /* ProjectSettingsSymbolAccessTokens */Block.__(40, [nextProjectName]);
    case /* ProjectSettingsMissingSymbols */41 :
        return /* ProjectSettingsMissingSymbols */Block.__(41, [nextProjectName]);
    case /* ProjectSettingsSymbolSearch */42 :
        return /* ProjectSettingsSymbolSearch */Block.__(42, [nextProjectName]);
    case /* ProjectSettingsScheduledReports */43 :
        return /* ProjectSettingsScheduledReports */Block.__(43, [nextProjectName]);
    case /* ProjectSettingsReprocessing */45 :
        return /* ProjectSettingsReprocessing */Block.__(45, [nextProjectName]);
    case /* ProjectSettingsRetentionPolicy */46 :
        return /* ProjectSettingsRetentionPolicy */Block.__(46, [nextProjectName]);
    case /* ProjectSettingsSourceCode */47 :
        return /* ProjectSettingsSourceCode */Block.__(47, [nextProjectName]);
    case /* ProjectSettingsApiTokens */48 :
        return /* ProjectSettingsApiTokens */Block.__(48, [nextProjectName]);
    case /* ProjectSettingsDedupeConfig */49 :
        return /* ProjectSettingsDedupeConfig */Block.__(49, [
                  nextProjectName,
                  undefined
                ]);
    case /* ProjectSettingsStabilityMonitoring */50 :
        return /* ProjectSettingsStabilityMonitoring */Block.__(50, [nextProjectName]);
    default:
      return ;
  }
}

function getNormBy(route) {
  if (typeof route === "number") {
    return ;
  }
  switch (route.tag | 0) {
    case /* ProjectTriage */11 :
    case /* ProjectQueryBuilder */13 :
        return route[1].normBy;
    default:
      return ;
  }
}

var appBar = Css.style(/* :: */[
      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.grey2))),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey0)),
        /* :: */[
          Css.unsafe("position", "sticky !important"),
          /* :: */[
            Css.important(Css.flexDirection(/* row */5693978)),
            /* :: */[
              Css.justifyContent(/* flexEnd */924268066),
              /* :: */[
                Css.alignItems(/* center */98248149),
                /* :: */[
                  Css.important(Css.padding(Css.zero)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var projectAndLogoContainer = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.grey2)),
      /* [] */0
    ]);

var projectToolbar = Css.style(/* :: */[
      Css.maxWidth(Css.px(200)),
      /* [] */0
    ]);

var select = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.white))),
      /* :: */[
        Css.selector(".select", /* :: */[
              Css.maxWidth(Css.px(120)),
              /* [] */0
            ]),
        /* :: */[
          Css.selector(".root", /* :: */[
                Css.color(Css.hex(Colors$BsConsole.white)),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var granSelect = Css.merge(/* :: */[
      select,
      /* :: */[
        Css.style(/* :: */[
              Css.margin2(Css.zero, Css.rem(1)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var projectName = Css.style(/* :: */[
      Css.overflow(/* hidden */-862584982),
      /* :: */[
        Css.whiteSpace(/* nowrap */867913355),
        /* :: */[
          Css.textOverflow(/* ellipsis */166888785),
          /* [] */0
        ]
      ]
    ]);

var spacer = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.flexGrow(1),
            /* [] */0
          ]),
      /* :: */[
        "spacer",
        /* [] */0
      ]
    ]);

var iframeVersionItem = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.75)),
      /* :: */[
        Css.height(Css.rem(0.70)),
        /* :: */[
          Css.padding(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var Style = {
  appBar: appBar,
  projectAndLogoContainer: projectAndLogoContainer,
  projectToolbar: projectToolbar,
  select: select,
  granSelect: granSelect,
  projectName: projectName,
  spacer: spacer,
  iframeVersionItem: iframeVersionItem
};

var component = ReasonReact.reducerComponent("AppBar-BsConsole_Default");

function make(token, handleTask, aperture, param, query, mode, route, handleChangeUrl, handleLogout, name, shell, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              var tmp;
              if (typeof shell === "number" || shell.tag) {
                tmp = null;
              } else {
                var _universes = shell[0].tenancy;
                tmp = _universes ? React.createElement("img", {
                        alt: I18N$BsConsole.get(undefined, "Backtrace logo - click to link to default view"),
                        src: "https://res.cloudinary.com/backtrace/image/upload/v1678299510/saucelabs_glyph.svg",
                        onClick: (function (_event) {
                            return Curry._1(handleChangeUrl, route);
                          })
                      }) : React.createElement("img", {
                        alt: I18N$BsConsole.get(undefined, "Backtrace logo - click to link to default view"),
                        src: "https://res.cloudinary.com/backtrace/image/upload/v1678128739/saucelabs_backtrace_logo_nav_1.svg",
                        onClick: (function (_event) {
                            return Curry._1(handleChangeUrl, route);
                          })
                      });
              }
              var tmp$1;
              var exit = 0;
              if (typeof shell === "number" || shell.tag) {
                exit = 1;
              } else {
                var config = shell[0];
                var universes = config.tenancy;
                tmp$1 = React.createElement(React.Fragment, undefined, universes ? ReasonReact.element(undefined, undefined, TenantSelect$BsConsole.make(config.universe, universes[0], [])) : null, ReasonReact.element(undefined, undefined, BtProjectAutocomplete$BsConsole.Jsx2.make(Belt_List.sort(Belt_List.filter(config.projects, (function (p) {
                                        return !DemoProjectUtil$BsConsole.isDemoProject(p.name);
                                      })), (function (p1, p2) {
                                    return $$String.compare(p1.name.toLowerCase(), p2.name.toLowerCase());
                                  })), (function (name) {
                                var route$1 = nextRouteForCurrentRouteAndName(config, route, name);
                                if (route$1 !== undefined) {
                                  return Curry._1(handleChangeUrl, route$1);
                                }
                                
                              }), select, I18N$BsConsole.get(undefined, "Project"), name, [])));
              }
              if (exit === 1) {
                tmp$1 = ReasonReact.element(undefined, undefined, BtProjectAutocomplete$BsConsole.Jsx2.make(/* [] */0, (function (param) {
                            
                          }), select, I18N$BsConsole.get(undefined, "Project"), name, []));
              }
              var tmp$2;
              if (aperture !== undefined) {
                var aperture$1 = Caml_option.valFromOption(aperture);
                tmp$2 = React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, GranSelect$BsConsole.make(route, handleChangeUrl, aperture$1, granSelect, Css.style(/* :: */[
                                  Css.backgroundColor(Css.hex(Colors$BsConsole.grey3)),
                                  /* [] */0
                                ]), [])), ReasonReact.element(undefined, undefined, FilterShell$BsConsole.Re.make((function (aperture, havings) {
                                return Curry._1(handleChangeUrl, handleApertureHavingChange(route, aperture, havings));
                              }), name, handleTask, aperture$1, Belt_Option.getWithDefault(Belt_Option.flatMap(query, (function (q) {
                                        return Curry._1(Crdb$BsConsole.Query.getHavings, q);
                                      })), Crdb$BsConsole.Havings.empty), route, token, Css.style(/* :: */[
                                  Css.important(Css.color(Css.hex(Colors$BsConsole.white))),
                                  /* :: */[
                                    Css.backgroundColor(Css.hex(Colors$BsConsole.grey3)),
                                    /* [] */0
                                  ]
                                ]), shell, [])));
              } else {
                tmp$2 = React.createElement("div", {
                      className: spacer
                    });
              }
              var tmp$3;
              tmp$3 = typeof shell === "number" || shell.tag ? null : ReasonReact.element(undefined, undefined, SavedQueriesContainer$BsConsole.Re.make(name, token, handleTask, handleChangeUrl, shell[0], query, mode, getNormBy(route), (function (param) {
                            var handleOpen = param.handleOpen;
                            return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Saved views"), undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                      Css.merge(/* :: */[
                                                            Css.style(/* :: */[
                                                                  Css.color(Css.hex(Colors$BsConsole.white)),
                                                                  /* :: */[
                                                                    Css.marginRight(Css.px(5)),
                                                                    /* [] */0
                                                                  ]
                                                                ]),
                                                            /* :: */[
                                                              "walkthrough--project_toolbar--saved_views_button",
                                                              /* :: */[
                                                                "saved-views__icon-button",
                                                                /* [] */0
                                                              ]
                                                            ]
                                                          ]),
                                                      undefined,
                                                      (function (param) {
                                                          return Curry._2(handleOpen, undefined, undefined);
                                                        }),
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      "Open saved views",
                                                      [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ViewCompact.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                    ]))]));
                          }), []));
              var tmp$4;
              var exit$1 = 0;
              if (typeof shell === "number" || shell.tag) {
                exit$1 = 1;
              } else {
                var config$1 = shell[0];
                var serviceUrl = Belt_Option.getWithDefault(Belt_List.getBy(/* :: */[
                          Services$BsConsole.getEndpoint(undefined)(config$1.services, "share"),
                          /* :: */[
                            Belt_Option.map(Caml_option.nullable_to_opt(window._BACKTRACE_SHARE), (function (s) {
                                    return s + "/api/share";
                                  })),
                            /* :: */[
                              "https://share.backtrace.io/api/share",
                              /* [] */0
                            ]
                          ]
                        ], Belt_Option.isSome), "https://share.backtrace.io/api/share");
                var ver = window._BACKTRACE_CORONER_VERSION;
                var coronerVersion = (ver == null) ? I18N$BsConsole.get(undefined, "Unknown") : ver;
                tmp$4 = React.createElement(React.Fragment, undefined, serviceUrl !== undefined ? ReasonReact.element(undefined, undefined, Share$BsConsole.make(route, serviceUrl, token, [])) : null, ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* NotificationBellIcon */10), [ReasonReact.element(undefined, undefined, Changelog$BsConsole.Jsx2.make([]))])), ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* ZendeskAppBarLauncher */12), [ReasonReact.element(undefined, undefined, Curry._3(ZenDesk$BsConsole.IconButton.Jsx2.make, Css.style(/* :: */[
                                            Css.marginRight(Css.px(5)),
                                            /* [] */0
                                          ]), {
                                        email: config$1.user.email,
                                        username: config$1.user.username,
                                        role: config$1.user.role,
                                        universe: config$1.universe.name,
                                        coronerdVersion: config$1.coronerdVersion,
                                        consoleVersion: Version$BsConsole.version
                                      }, []))])), ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                return React.createElement("div", {
                                            ref: refHandler,
                                            onFocus: onFocus,
                                            onClick: onClick
                                          }, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Menu"), undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                              "walkthrough--project_toolbar--menu_button",
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              "Open menu",
                                                              [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Avatar.Jsx2.make, undefined, Css.style(/* :: */[
                                                                              Css.backgroundColor(Css.hex(SauceColors$BsConsole.green)),
                                                                              /* :: */[
                                                                                Css.color(Css.hex(Colors$BsConsole.grey2)),
                                                                                /* [] */0
                                                                              ]
                                                                            ]), [I18N$BsConsole.showSkip($$String.uppercase($$String.sub(config$1.user.username, 0, 1)))]))]
                                                            ]))])));
                              }), undefined, {
                              vertical: /* Bottom */437082891,
                              horizontal: /* Center */980392437
                            }, undefined, undefined, undefined, undefined, (function (onClose) {
                                return [
                                        ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* About */6), [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, "walkthrough--project_toolbar_menu_dropdown--about", (function (_event) {
                                                              Curry._1(self.send, /* Dialog */[/* About */0]);
                                                              return Curry._1(onClose, undefined);
                                                            }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "About")]))])),
                                        ReasonReact.element(undefined, undefined, Link$BsConsole.make(Belt_Option.mapWithDefault(nameForRoute(route), route, (function (name) {
                                                        return /* ProjectSettings */Block.__(22, [name]);
                                                      })), undefined, handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, "walkthrough--project_toolbar_menu_dropdown--project_settings", undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Project settings")]))])),
                                        ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* OrganizationSettings */Block.__(2, [config$1.user])), [ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* Settings */4, undefined, handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, "walkthrough--project_toolbar_menu_dropdown--organization_settings", undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Organization settings")]))]))])),
                                        ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsUserInvitationManagement */Block.__(7, [config$1.user])), [ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* SettingsInvitesNew */12, undefined, handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, "walkthrough--project_toolbar_menu_dropdown--invite_a_user", undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Invite a user")]))]))])),
                                        ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsProjectsNew */Block.__(6, [config$1.user])), [ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* SettingsProjectsNew */9, undefined, handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, "walkthrough--project_toolbar_menu_dropdown--create_a_project", undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Create a project")]))]))])),
                                        ReasonReact.element(undefined, undefined, Link$BsConsole.make(Belt_Option.mapWithDefault(nameForRoute(route), route, (function (name) {
                                                        return /* ProjectUpload */Block.__(10, [name]);
                                                      })), undefined, handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, "walkthrough--project_toolbar_menu_dropdown--upload_an_error", undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Upload an error")]))])),
                                        ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* SettingsLogout */5), [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, "walkthrough--project_toolbar_menu_dropdown--logout", (function (param) {
                                                              Curry._1(handleLogout, undefined);
                                                              return Curry._1(onClose, undefined);
                                                            }), undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Logout")]))])),
                                        ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* VersionsMenuItem */7), [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, iframeVersionItem, undefined, undefined, true, undefined, [Curry._3(I18N$BsConsole.showSkipf(/* Format */[
                                                                      /* String_literal */Block.__(11, [
                                                                          "Console ",
                                                                          /* String */Block.__(2, [
                                                                              /* No_padding */0,
                                                                              /* String */Block.__(2, [
                                                                                  /* No_padding */0,
                                                                                  /* String_literal */Block.__(11, [
                                                                                      " - coronerd ",
                                                                                      /* String */Block.__(2, [
                                                                                          /* No_padding */0,
                                                                                          /* End_of_format */0
                                                                                        ])
                                                                                    ])
                                                                                ])
                                                                            ])
                                                                        ]),
                                                                      "Console %s%s - coronerd %s"
                                                                    ]), Version$BsConsole.version, Gate$BsConsole.isAlpha(undefined) ? "-alpha" : (
                                                                    Gate$BsConsole.isBeta(undefined) ? "-beta" : ""
                                                                  ), coronerVersion)]))]))
                                      ];
                              }), undefined, [])));
              }
              if (exit$1 === 1) {
                tmp$4 = ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Avatar.Jsx2.make, undefined, undefined, []));
              }
              var _About = self.state.dialog;
              return React.createElement(React.Fragment, undefined, ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.AppBar.Jsx2.make, appBar, undefined, [
                                  ReasonReact.element(undefined, undefined, HideFeatureGate$BsConsole.Jsx2.make(Gate$BsConsole.isNotPermitted(undefined, /* ProjectSelector */0), [ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [
                                                      ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectTriage */Block.__(11, [
                                                                  name,
                                                                  Route$BsConsole.getInboxParams(name, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                                                                  undefined
                                                                ]), undefined, handleChangeUrl, undefined, undefined, Css.style(/* :: */[
                                                                    Css.cursor(/* pointer */-786317123),
                                                                    /* :: */[
                                                                      Css.margin2(Css.zero, Css.rem(1)),
                                                                      /* :: */[
                                                                        Css.display(/* inlineFlex */53323314),
                                                                        /* :: */[
                                                                          Css.alignItems(/* center */98248149),
                                                                          /* :: */[
                                                                            Css.padding(Css.px(12)),
                                                                            /* [] */0
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ]
                                                                  ]), [tmp])),
                                                      tmp$1
                                                    ]))])),
                                  tmp$2,
                                  ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.Toolbar.Jsx2.make, undefined, Css.style(/* :: */[
                                                Css.justifySelf(/* flexEnd */924268066),
                                                /* [] */0
                                              ]), undefined, [
                                            tmp$3,
                                            tmp$4
                                          ]))
                                ])), _About !== undefined ? ReasonReact.element(undefined, undefined, About$BsConsole.make((function (_event) {
                                      return Curry._1(self.send, /* Dialog */[undefined]);
                                    }), [])) : null, ReasonReact.element(undefined, undefined, CreateFirstProjectBanner$BsConsole.Jsx2.make(handleChangeUrl, shell, [])));
            }),
          initialState: (function (param) {
              return {
                      dialog: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          dialog: action[0]
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var Default = {
  Style: Style,
  component: component,
  make: make
};

var component$1 = ReasonReact.statelessComponent("AppBar-BsConsole");

function make$1(token, handleTask, aperture, havings, query, mode, route, handleChangeUrl, handleLogout, name, shell, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              if (IFrame$BsConsole.isIFrame) {
                return ReasonReact.element(undefined, undefined, IFrameAppBar$BsConsole.Jsx2.make(token, handleTask, aperture, query, mode, route, handleChangeUrl, name, shell, []));
              } else {
                return ReasonReact.element(undefined, undefined, make(token, handleTask, aperture, havings, query, mode, route, handleChangeUrl, handleLogout, name, shell, []));
              }
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.nameForRoute = nameForRoute;
exports.handleApertureHavingChange = handleApertureHavingChange;
exports.nextRouteForCurrentRouteAndName = nextRouteForCurrentRouteAndName;
exports.getNormBy = getNormBy;
exports.Default = Default;
exports.component = component$1;
exports.make = make$1;
/* appBar Not a pure module */
