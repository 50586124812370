// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Select = require("@material-ui/core/Select");
var MenuItem = require("@material-ui/core/MenuItem");
var TextField = require("@material-ui/core/TextField");

function ReopenVersion(Props) {
  Props.config;
  Props.token;
  var version = Props.version;
  var setVersion = Props.setVersion;
  var field = Props.field;
  var setField = Props.setField;
  var attributesRemotes = Props.attributesRemotes;
  var greatestAttributeRemote = Belt_List.getBy(attributesRemotes, (function (param) {
          return Caml_obj.caml_equal(param[0].name, field);
        }));
  var tmp;
  if (greatestAttributeRemote !== undefined) {
    var match = greatestAttributeRemote[1];
    tmp = typeof match === "number" && match !== 0 ? I18N$BsConsole.get(undefined, "...loading") : I18N$BsConsole.get(undefined, "...");
  } else {
    tmp = I18N$BsConsole.get(undefined, "...");
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Select.default, {
                  value: /* `String */[
                    -976970511,
                    Belt_Option.getWithDefault(field, "")
                  ],
                  onChange: (function ($$event, param) {
                      var value = $$event.target.value;
                      var v = value[1];
                      Belt_List.forEach(attributesRemotes, (function (param) {
                              var remoteValue = param[1];
                              var match = Caml_obj.caml_equal(param[0], v);
                              if (!match) {
                                return ;
                              }
                              if (typeof remoteValue === "number") {
                                return ;
                              }
                              if (remoteValue.tag) {
                                return ;
                              }
                              var v$1 = remoteValue[0];
                              return Curry._1(setVersion, (function (param) {
                                            return v$1;
                                          }));
                            }));
                      Curry._1(setVersion, (function (param) {
                              
                            }));
                      return Curry._1(setField, (function (param) {
                                    return Caml_option.some(v);
                                  }));
                    }),
                  renderValue: (function (value) {
                      return React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Caption */11,
                                  children: I18N$BsConsole.showSkip(value[1])
                                });
                    }),
                  disableUnderline: true,
                  children: Belt_Array.map(Belt_List.toArray(attributesRemotes), (function (param) {
                          var name = param[0].name;
                          return React.createElement(MenuItem.default, {
                                      value: /* `String */[
                                        -976970511,
                                        name
                                      ],
                                      children: React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Caption */11,
                                            children: I18N$BsConsole.showSkip(name)
                                          })
                                    });
                        }))
                }), React.createElement("label", {
                  className: Css.style(/* :: */[
                        Css.width(Css.px(180)),
                        /* [] */0
                      ]),
                  htmlFor: "versionInput"
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Caption */11,
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.grey1)),
                            /* :: */[
                              Css.margin(Css.px(0)),
                              /* [] */0
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, "greater than")
                    })), React.createElement(TextField.default, {
                  helperText: "",
                  disabled: Belt_Option.isNone(field),
                  error: false,
                  placeholder: tmp,
                  value: Belt_Option.getWithDefault(version, ""),
                  onChange: (function ($$event) {
                      var value = $$event.target.value;
                      return Curry._1(setVersion, (function (param) {
                                    return value;
                                  }));
                    })
                }));
}

var make = ReopenVersion;

exports.make = make;
/* Css Not a pure module */
