// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Morgue$BsConsole = require("../bs-crdb-response/src/Morgue.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var ValueDrilldown$BsConsole = require("../ValueDrilldown.js");
var CallstackCopyButton$BsConsole = require("../CallstackCopyButton.js");
var CallstackCopyButtonWithObjectFetch$BsConsole = require("../CallstackCopyButtonWithObjectFetch.js");

var root = Css.style(/* :: */[
      Css.width(Css.vw(80)),
      /* :: */[
        Css.height(Css.vh(80)),
        /* :: */[
          Css.important(Css.maxWidth(Css.vw(100))),
          /* :: */[
            Css.overflow(/* hidden */-862584982),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles = {
  root: root
};

var component = ReasonReact.statelessComponent("Renderer_CallstackExpansion-BsConsole");

function make(onClose, open_, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              open_,
                              (function ($$event) {
                                  $$event.stopPropagation();
                                  return Curry._1(onClose, undefined);
                                }),
                              {
                                paper: root
                              },
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              children
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var ExpansionDialog = {
  Styles: Styles,
  component: component,
  make: make
};

var root$1 = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.height(Css.pct(100)),
        /* [] */0
      ]
    ]);

var expansionCallstack = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.blueBackground)),
      /* :: */[
        Css.cursor(/* default */465819841),
        /* :: */[
          Css.overflow(/* auto */-1065951377),
          /* :: */[
            Css.margin2(/* zero */-789508312, Css.px(24)),
            /* :: */[
              Css.padding(Css.px(16)),
              /* :: */[
                Css.unsafe("width", "unset"),
                /* :: */[
                  Css.unsafe("maxHeight", "calc(100% - 148px)"),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var expansionTitle = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* [] */0
    ]);

var switchBackground = Css.style(/* :: */[
      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accent))),
      /* [] */0
    ]);

var switchChecked = Css.style(/* :: */[
      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentLight))),
      /* [] */0
    ]);

var switchUnchecked = Css.style(/* :: */[
      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accent))),
      /* [] */0
    ]);

var actionIconButton = Css.style(/* :: */[
      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accent))),
      /* [] */0
    ]);

var Styles$1 = {
  root: root$1,
  expansionCallstack: expansionCallstack,
  expansionTitle: expansionTitle,
  switchBackground: switchBackground,
  switchChecked: switchChecked,
  switchUnchecked: switchUnchecked,
  actionIconButton: actionIconButton
};

var component$1 = ReasonReact.reducerComponent("Renderer_CallstackExpansion-BsConsole");

function make$1(attribute, onClose, open_Opt, param, frames, handleAddFilters, handleChangeUrl, config, token, aperture, projectName, _children) {
  var open_ = open_Opt !== undefined ? open_Opt : false;
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              var morgueProcessedFrames = Morgue$BsConsole.Callstack.prep(frames, {
                    suffix: 2,
                    dynamic: true
                  });
              var isWorthProcessingFrames = Caml_obj.caml_notequal(morgueProcessedFrames, frames) && attribute === "callstack";
              var processedFrames = self.state.useProcessedCallsack ? morgueProcessedFrames : frames;
              var tmp;
              var exit = 0;
              if (config !== undefined && token !== undefined && aperture !== undefined && projectName !== undefined) {
                tmp = ReasonReact.element(undefined, undefined, CallstackCopyButtonWithObjectFetch$BsConsole.Re.make(config, token, projectName, Caml_option.valFromOption(aperture), frames, undefined, /* Button */0, []));
              } else {
                exit = 1;
              }
              if (exit === 1) {
                tmp = ReasonReact.element(undefined, undefined, CallstackCopyButton$BsConsole.make(/* Frames */Block.__(0, [frames]), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                  actionIconButton,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Assignment.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                ])), []));
              }
              return ReasonReact.element(undefined, undefined, make(onClose, open_, [React.createElement("div", {
                                    className: root$1,
                                    onClick: (function (e) {
                                        e.stopPropagation();
                                        
                                      })
                                  }, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(expansionTitle, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, /* Center */980392437, [
                                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Title */594052472, undefined, undefined, undefined, undefined, [attribute === "callstack" ? I18N$BsConsole.show(undefined, "Normalized callstack") : I18N$BsConsole.showSkip(attribute)]))])),
                                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, undefined, [
                                                                tmp,
                                                                isWorthProcessingFrames ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, [
                                                                                      ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Switch.Jsx2.make, !isWorthProcessingFrames, undefined, /* `Bool */[
                                                                                                737456202,
                                                                                                self.state.useProcessedCallsack
                                                                                              ], (function (param, param$1) {
                                                                                                  return Curry._1(self.send, /* SetNormalizedCallstack */[!self.state.useProcessedCallsack]);
                                                                                                }), undefined, {
                                                                                                colorSecondary: switchUnchecked,
                                                                                                iconChecked: switchChecked,
                                                                                                bar: switchBackground
                                                                                              }, "", [])),
                                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Condense function names")]))
                                                                                    ]))])) : null
                                                              ]))]))
                                          ])), ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(expansionCallstack, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Nowrap */-397426005, undefined, /* Column */-81804554, undefined, undefined, [
                                            Belt_Array.mapWithIndex(Belt_Array.keepWithIndex(frames, (function (param, i) {
                                                        return i < self.state.renderLimit;
                                                      })), (function (i, frame) {
                                                    return ReasonReact.element(String(i), undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, ValueDrilldown$BsConsole.make(handleAddFilters, handleChangeUrl, /* :: */[
                                                                              /* tuple */[
                                                                                attribute,
                                                                                /* Contains */Block.__(2, [/* `String */[
                                                                                      -976970511,
                                                                                      frame
                                                                                    ]])
                                                                              ],
                                                                              /* :: */[
                                                                                /* tuple */[
                                                                                  attribute,
                                                                                  /* NotContains */Block.__(3, [/* `String */[
                                                                                        -976970511,
                                                                                        frame
                                                                                      ]])
                                                                                ],
                                                                                /* [] */0
                                                                              ]
                                                                            ], undefined, frame, undefined, undefined, (function (refHandler, onFocus, onClick) {
                                                                                var processedFrame = Belt_Array.get(processedFrames, i);
                                                                                if (processedFrame === undefined) {
                                                                                  return null;
                                                                                }
                                                                                var partial_arg = {
                                                                                  fontFamily: "Inconsolata",
                                                                                  minWidth: "unset",
                                                                                  textAlign: "left",
                                                                                  textTransform: "none",
                                                                                  width: "unset"
                                                                                };
                                                                                var arg = function (param, param$1, param$2) {
                                                                                  return ValueDrilldown$BsConsole.wrapWithButton(onClick, partial_arg, param, param$1, param$2);
                                                                                };
                                                                                return React.createElement("div", {
                                                                                            ref: refHandler,
                                                                                            style: {
                                                                                              display: "inline-block"
                                                                                            },
                                                                                            onFocus: onFocus,
                                                                                            onClick: onClick
                                                                                          }, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [Curry._3(arg, undefined, undefined, I18N$BsConsole.showSkip(processedFrame))])));
                                                                              }), undefined, onClose, []))]));
                                                  })),
                                            frames.length > self.state.renderLimit ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                        undefined,
                                                        undefined,
                                                        (function (param) {
                                                            return Curry._1(self.send, /* RenderMore */0);
                                                          }),
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        [I18N$BsConsole.show(undefined, "Show more")]
                                                      ])) : null
                                          ])))]));
            }),
          initialState: (function (param) {
              return {
                      useProcessedCallsack: attribute === "callstack",
                      renderLimit: 500
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [{
                            useProcessedCallsack: action[0],
                            renderLimit: state.renderLimit
                          }]);
              } else {
                return /* Update */Block.__(0, [{
                            useProcessedCallsack: state.useProcessedCallsack,
                            renderLimit: state.renderLimit + 250 | 0
                          }]);
              }
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

exports.ExpansionDialog = ExpansionDialog;
exports.Styles = Styles$1;
exports.component = component$1;
exports.make = make$1;
/* root Not a pure module */
