// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Close = require("@material-ui/icons/Close");
var IconButton = require("@material-ui/core/IconButton");
var CardContent = require("@material-ui/core/CardContent");

function BtModal$MessageAndButton(Props) {
  var message = Props.message;
  var buttonTitle = Props.buttonTitle;
  var buttonAction = Props.buttonAction;
  return React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Subtitle1 */6,
                  className: Css.style(/* :: */[
                        Css.marginTop(Css.px(5)),
                        /* :: */[
                          Css.marginBottom(Css.px(15)),
                          /* :: */[
                            Css.fontSize(Css.px(12)),
                            /* [] */0
                          ]
                        ]
                      ]),
                  color: Colors$BsConsole.grey4,
                  children: I18N$BsConsole.showSkip(message)
                }), React.createElement(Row2$BsConsole.make, {
                  justifyContent: /* flexEnd */924268066,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: React.createElement(Button.default, {
                        variant: "contained",
                        color: "primary",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginTop(Css.px(10)),
                                /* [] */0
                              ])
                        },
                        onClick: buttonAction,
                        children: I18N$BsConsole.showSkip(buttonTitle)
                      })
                }));
}

var MessageAndButton = {
  make: BtModal$MessageAndButton
};

function BtModal$Default(Props) {
  var _open = Props.open;
  var onClose = Props.onClose;
  var modalWidthOpt = Props.modalWidth;
  var modalHeight = Props.modalHeight;
  var title = Props.title;
  var subtitle = Props.subtitle;
  var dividerOpt = Props.divider;
  var maxWidthOpt = Props.maxWidth;
  var fullWidth = Props.fullWidth;
  var fullScreen = Props.fullScreen;
  var className = Props.className;
  var dialogClasses = Props.dialogClasses;
  var disableBackdropClick = Props.disableBackdropClick;
  var children = Props.children;
  var modalWidth = modalWidthOpt !== undefined ? modalWidthOpt : 250;
  var divider = dividerOpt !== undefined ? dividerOpt : false;
  var maxWidth = maxWidthOpt !== undefined ? maxWidthOpt : "false";
  var maxWidthValue = Belt_Option.isSome(fullScreen) || Belt_Option.isSome(fullWidth) ? Css.maxWidth(Css.pct(100)) : Css.maxWidth(Css.px(modalWidth));
  var classes = dialogClasses !== undefined ? Caml_option.valFromOption(dialogClasses) : (
      className !== undefined ? ({
            root: className,
            container: "",
            paper: ""
          }) : ({
            root: "",
            container: "",
            paper: ""
          })
    );
  var tmp = {
    open: _open,
    classes: classes,
    onClose: onClose,
    maxWidth: maxWidth,
    fullWidth: Belt_Option.getWithDefault(fullWidth, false),
    fullScreen: Belt_Option.getWithDefault(fullScreen, false),
    children: React.createElement(CardContent.default, {
          classes: {
            root: modalHeight !== undefined ? Css.style(/* :: */[
                    Css.height(Css.px(modalHeight)),
                    /* [] */0
                  ]) : ""
          },
          children: null
        }, React.createElement("div", {
              className: Css.merge(/* :: */[
                    "smaller-icon",
                    /* :: */[
                      Css.style(/* :: */[
                            Css.width(Css.pct(100)),
                            /* :: */[
                              Css.display(/* flex */-1010954439),
                              /* :: */[
                                Css.justifyContent(/* spaceBetween */516682146),
                                /* :: */[
                                  Css.alignItems(/* center */98248149),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      /* [] */0
                    ]
                  ])
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Subtitle2 */7,
                  children: I18N$BsConsole.showSkip(title)
                }), React.createElement(IconButton.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.padding(Css.px(0)),
                          /* [] */0
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(onClose, undefined);
                    }),
                  children: React.createElement(Close.default, { })
                })), divider ? React.createElement(BtSettings$BsConsole.FancyDivider.make, { }) : null, React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body2 */9,
              className: Css.style(/* :: */[
                    maxWidthValue,
                    /* :: */[
                      Css.marginBottom(Css.px(15)),
                      /* [] */0
                    ]
                  ]),
              children: I18N$BsConsole.showSkip(subtitle)
            }), React.createElement("div", {
              className: Css.style(/* :: */[
                    maxWidthValue,
                    /* [] */0
                  ])
            }, children))
  };
  if (disableBackdropClick !== undefined) {
    tmp.disableBackdropClick = Caml_option.valFromOption(disableBackdropClick);
  }
  return React.createElement(Dialog.default, tmp);
}

var Default = {
  make: BtModal$Default
};

exports.MessageAndButton = MessageAndButton;
exports.Default = Default;
/* Css Not a pure module */
