// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Colors$BsConsole = require("../Colors.js");
var IconButton = require("@material-ui/core/IconButton");
var ArrowDropDown = require("@material-ui/icons/ArrowDropDown");

function BugTreeExpandIcon(Props) {
  var onClick = Props.onClick;
  var expanded = Props.expanded;
  var durationOpt = Props.duration;
  var duration = durationOpt !== undefined ? durationOpt : 200;
  return React.createElement(IconButton.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.fontSize(Css.px(16)),
                      /* :: */[
                        Css.padding(Css.px(1)),
                        /* :: */[
                          Css.margin2(Css.px(1), Css.zero),
                          /* :: */[
                            Css.width(Css.px(18)),
                            /* :: */[
                              Css.height(Css.px(18)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ])
              },
              onClick: onClick,
              children: React.createElement(ArrowDropDown.default, {
                    className: Css.style(/* :: */[
                          Css.color(Css.hex(Colors$BsConsole.blackA87)),
                          /* :: */[
                            Css.transform(Css.rotate(Css.deg(expanded ? 0 : -90))),
                            /* :: */[
                              Css.transition(duration, undefined, undefined, "transform"),
                              /* [] */0
                            ]
                          ]
                        ]),
                    fontSize: "inherit"
                  })
            });
}

var make = BugTreeExpandIcon;

exports.make = make;
/* Css Not a pure module */
