// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Route$BsConsole = require("./route.js");
var Task2$BsConsole = require("./Task2.js");
var Colors$BsConsole = require("./Colors.js");
var ApiShare$BsConsole = require("./ApiShare.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var ObjectID$BsConsole = require("./ObjectID.js");
var Clipboard$BsConsole = require("./bindings/Clipboard.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");

function toHttpdError(json) {
  return {
          code: undefined,
          message: Json_decode.field("message", Json_decode.string, json)
        };
}

function shortenUrl(serviceUrl, $$location$1, param) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceUrl]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                      /* tuple */[
                        "url",
                        $$location$1
                      ],
                      /* [] */0
                    ])]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var json = param.json;
                try {
                  return ApiShare$BsConsole.fromJson(json);
                }
                catch (exn){
                  return /* Error */Block.__(1, [[
                              Task2$BsConsole.Httpd,
                              toHttpdError(json)
                            ]]);
                }
              }), undefined, undefined);
}

var component = RR$BsConsole.reducerComponent("Share-BsConsole");

function make(route, serviceUrl, token, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, I18N$BsConsole.show(undefined, "Copy shortened link"), undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                        "walkthrough--project_toolbar--copy_shortened_link_button " + Css.style(/* :: */[
                                              Css.color(Css.hex(Colors$BsConsole.white)),
                                              /* :: */[
                                                Css.marginRight(Css.px(5)),
                                                /* [] */0
                                              ]
                                            ]),
                                        undefined,
                                        (function (param) {
                                            return Curry._1(self.send, /* Click */0);
                                          }),
                                        undefined,
                                        undefined,
                                        undefined,
                                        self.state.active,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Link.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                      ]))]));
            }),
          initialState: (function (param) {
              return {
                      active: false,
                      cachedUrl: undefined
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* SideEffects */Block.__(1, [(function (self) {
                              var $$location$1;
                              if (typeof route === "number" || route.tag !== /* ProjectDebugger2 */19) {
                                $$location$1 = location.href;
                              } else {
                                var state = route[1].state;
                                if (state !== undefined) {
                                  var $$location$2 = decodeURIComponent(location.href);
                                  var currRouteStr = decodeURIComponent(Route$BsConsole.pathForRoute(route));
                                  var currRouteIndex = $$location$2.indexOf(currRouteStr);
                                  var baseLocation = $$location$2.slice(0, currRouteIndex);
                                  var newRouteStr = Route$BsConsole.pathForRoute(/* ProjectDebugger2 */Block.__(19, [
                                          route[0],
                                          {
                                            aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                          "_tx",
                                                          /* Equal */Block.__(0, [/* `ObjectID */[
                                                                -589436806,
                                                                ObjectID$BsConsole.fromHexString(state.errorId)
                                                              ]])
                                                        ]), Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Relative */Block.__(0, [
                                                        /* Floating */0,
                                                        /* All */6
                                                      ]), Crdb$BsConsole.Aperture.$$default)),
                                            state: state
                                          }
                                        ]));
                                  $$location$1 = encodeURI(baseLocation) + newRouteStr;
                                } else {
                                  $$location$1 = location.href;
                                }
                              }
                              var cachedUrl = self.state.cachedUrl;
                              if (cachedUrl !== undefined && cachedUrl.href === $$location$1) {
                                return Curry._1(self.send, /* Success */Block.__(1, [cachedUrl]));
                              } else {
                                return Curry._1(self.send, /* Request */Block.__(0, [$$location$1]));
                              }
                            })]);
              }
              switch (action.tag | 0) {
                case /* Request */0 :
                    var $$location$1 = action[0];
                    return /* UpdateWithSideEffects */Block.__(2, [
                              {
                                active: true,
                                cachedUrl: state.cachedUrl
                              },
                              (function (self) {
                                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, 4000, undefined, I18N$BsConsole.show(undefined, "Generating link..."));
                                  var arg = shortenUrl(serviceUrl, $$location$1, undefined);
                                  return Curry._2((function (param) {
                                                  return (function (param$1, param$2) {
                                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                    });
                                                })(token), undefined, (function (response) {
                                                if (response.tag) {
                                                  return Curry._1(self.send, /* Failure */Block.__(2, [Task2$BsConsole.renderError(undefined, response[0])]));
                                                }
                                                var response$1 = response[0];
                                                var url = response$1.url.includes("https://") || response$1.url.includes("http://") ? response$1.url : "https://" + response$1.url;
                                                return Curry._1(self.send, /* Success */Block.__(1, [{
                                                                href: $$location$1,
                                                                short: url
                                                              }]));
                                              }));
                                })
                            ]);
                case /* Success */1 :
                    var url = action[0];
                    Clipboard$BsConsole.copy(url.short, undefined, undefined);
                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, 4000, undefined, I18N$BsConsole.show(undefined, "Shareable link copied to clipboard!"));
                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Share_CreateTinyUrlSuccess */18]));
                    return /* Update */Block.__(0, [{
                                active: false,
                                cachedUrl: url
                              }]);
                case /* Failure */2 :
                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Share_CreateTinyUrlFailure */19]));
                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, 4000, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                  /* String_literal */Block.__(11, [
                                      "Error ",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* End_of_format */0
                                        ])
                                    ]),
                                  "Error %s"
                                ]), action[0]));
                    return /* Update */Block.__(0, [{
                                active: false,
                                cachedUrl: state.cachedUrl
                              }]);
                
              }
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.toHttpdError = toHttpdError;
exports.shortenUrl = shortenUrl;
exports.component = component;
exports.make = make;
/* component Not a pure module */
