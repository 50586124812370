// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Table = require("@material-ui/core/Table");
var Tooltip = require("@material-ui/core/Tooltip");
var Delete = require("@material-ui/icons/Delete");
var TableRow = require("@material-ui/core/TableRow");
var TableBody = require("@material-ui/core/TableBody");
var TableCell = require("@material-ui/core/TableCell");
var TableHead = require("@material-ui/core/TableHead");
var IconButton = require("@material-ui/core/IconButton");

function TeamsMemberTable$NoMembers(Props) {
  return React.createElement(Table.default, {
              children: null
            }, React.createElement(TableHead.default, {
                  children: React.createElement(TableRow.default, {
                        children: null
                      }, React.createElement(TableCell.default, {
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* TableTitle */13,
                                  color: Colors$BsConsole.grey0,
                                  children: I18N$BsConsole.show(undefined, "Email")
                                })
                          }), React.createElement(TableCell.default, {
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* TableTitle */13,
                                  color: Colors$BsConsole.grey0,
                                  children: I18N$BsConsole.show(undefined, "Username")
                                })
                          }))
                }), React.createElement(TableBody.default, {
                  children: React.createElement(TableRow.default, {
                        children: React.createElement(TableCell.default, {
                              colSpan: 2,
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* TableBody */14,
                                    color: Colors$BsConsole.grey0,
                                    children: I18N$BsConsole.show(undefined, "This team does not have any members yet.")
                                  })
                            })
                      })
                }));
}

var NoMembers = {
  make: TeamsMemberTable$NoMembers
};

function TeamsMemberTable(Props) {
  Props.token;
  var members = Props.members;
  var refetchTeamMembers = Props.refetchTeamMembers;
  var setDeleteDialog = Props.setDeleteDialog;
  var team = Props.team;
  var setDeleteRefetch = Props.setDeleteRefetch;
  var allUsers = Props.allUsers;
  var arrayOfOptionalUsers = Belt_Array.map(members, (function (teamMember) {
          var user = Belt_Array.getBy(allUsers, (function (u) {
                  return u.uid === teamMember.user;
                }));
          return /* tuple */[
                  user,
                  teamMember
                ];
        }));
  return React.createElement(Table.default, {
              children: null
            }, React.createElement(TableHead.default, {
                  children: React.createElement(TableRow.default, {
                        children: null
                      }, React.createElement(TableCell.default, {
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* TableTitle */13,
                                  color: Colors$BsConsole.grey0,
                                  children: I18N$BsConsole.show(undefined, "Email")
                                })
                          }), React.createElement(TableCell.default, {
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* TableTitle */13,
                                  color: Colors$BsConsole.grey0,
                                  children: I18N$BsConsole.show(undefined, "Username")
                                })
                          }), React.createElement(TableCell.default, {
                            children: null
                          }))
                }), React.createElement(TableBody.default, {
                  children: $$Array.map((function (param) {
                          var user = param[0];
                          if (user === undefined) {
                            return null;
                          }
                          var member = param[1];
                          return React.createElement(TableRow.default, {
                                      children: null,
                                      key: user.email + ("-" + user.username)
                                    }, React.createElement(TableCell.default, {
                                          children: React.createElement(BtTypography$BsConsole.make, {
                                                variant: /* TableBody */14,
                                                color: Colors$BsConsole.grey0,
                                                children: I18N$BsConsole.showSkip(user.email)
                                              })
                                        }), React.createElement(TableCell.default, {
                                          children: React.createElement(BtTypography$BsConsole.make, {
                                                variant: /* TableBody */14,
                                                color: Colors$BsConsole.grey0,
                                                children: I18N$BsConsole.showSkip(user.username)
                                              })
                                        }), React.createElement(TableCell.default, {
                                          className: Css.style(/* :: */[
                                                Css.display(/* flex */-1010954439),
                                                /* :: */[
                                                  Css.justifyContent(/* flexEnd */924268066),
                                                  /* :: */[
                                                    Css.important(Css.marginBottom(Css.px(-1))),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]),
                                          children: React.createElement(Tooltip.default, {
                                                children: React.createElement(IconButton.default, {
                                                      ariaLabel: "Remove member from team",
                                                      onClick: (function (_e) {
                                                          Curry._1(setDeleteRefetch, (function (param) {
                                                                  return refetchTeamMembers;
                                                                }));
                                                          return Curry._1(setDeleteDialog, (function (param) {
                                                                        return /* TeamMember */Block.__(1, [
                                                                                  member.team,
                                                                                  member.user,
                                                                                  team,
                                                                                  user
                                                                                ]);
                                                                      }));
                                                        }),
                                                      children: React.createElement(Delete.default, { })
                                                    }),
                                                title: I18N$BsConsole.get(undefined, "Remove member from team")
                                              })
                                        }));
                        }), arrayOfOptionalUsers)
                }));
}

var make = TeamsMemberTable;

exports.NoMembers = NoMembers;
exports.make = make;
/* Css Not a pure module */
