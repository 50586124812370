// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Route$BsConsole = require("../route.js");
var Colors$BsConsole = require("../Colors.js");
var Issues$BsConsole = require("../bs-crdb-response/src/Issues.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var ReopenTime$BsConsole = require("./ReopenTime.js");
var TeamsUtils$BsConsole = require("../teams/TeamsUtils.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var ReopenStyles$BsConsole = require("./ReopenStyles.js");
var TokenContext$BsConsole = require("../context/TokenContext.js");
var ReopenVersion$BsConsole = require("./ReopenVersion.js");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var Collapse = require("@material-ui/core/Collapse");
var MenuItem = require("@material-ui/core/MenuItem");
var GetGreatestVersion$BsConsole = require("../GetGreatestVersion.js");
var FormControl = require("@material-ui/core/FormControl");
var UserFrontendSettings$BsConsole = require("../UserFrontendSettings.js");

function toString(param) {
  switch (param) {
    case /* SeenAgain */0 :
        return "seen again";
    case /* Time */1 :
        return "seen again after";
    case /* Version */2 :
        return "seen in version";
    case /* NoInvariant */3 :
        return "none";
    
  }
}

function ofString(inv) {
  switch (inv) {
    case "seen again" :
        return /* SeenAgain */0;
    case "seen again after" :
        return /* Time */1;
    case "seen in version" :
        return /* Version */2;
    default:
      return /* NoInvariant */3;
  }
}

var Invariant = {
  toString: toString,
  ofString: ofString
};

var reopenTypeOptions = [
  /* SeenAgain */0,
  /* Time */1,
  /* Version */2
];

function Reopen(Props) {
  var config = Props.config;
  var nextState = Props.nextState;
  var handleSetState = Props.handleSetState;
  var projectName = Props.projectName;
  var onClose = Props.onClose;
  var apertureOpt = Props.aperture;
  var sortOpt = Props.sort;
  var aperture = apertureOpt !== undefined ? Caml_option.valFromOption(apertureOpt) : Crdb$BsConsole.Aperture.$$default;
  var sort = sortOpt !== undefined ? Caml_option.valFromOption(sortOpt) : undefined;
  var token = Belt_Option.getExn(React.useContext(TokenContext$BsConsole.ctx));
  var match = React.useState((function () {
          return /* SeenAgain */0;
        }));
  var setReopenType = match[1];
  var reopenType = match[0];
  var match$1 = React.useState((function () {
          return /* [] */0;
        }));
  var setPreviousAttributeValues = match$1[1];
  var previousAttributeValues = match$1[0];
  var match$2 = React.useState((function () {
          return ReopenTime$BsConsole.TimePresets.$$default;
        }));
  var setTimePreset = match$2[1];
  var timePreset = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setVersion = match$3[1];
  var version = match$3[0];
  var match$4 = React.useState((function () {
          
        }));
  var setField = match$4[1];
  var field = match$4[0];
  var match$5 = GetGreatestVersion$BsConsole.use(projectName, aperture, token, Caml_option.some(sort), undefined);
  var fetchVersionAttributeMaxValues = match$5[1];
  var attributeVersionsRemote = match$5[0];
  React.useEffect((function () {
          if (typeof attributeVersionsRemote !== "number" && !attributeVersionsRemote.tag) {
            var attributesRemotes = attributeVersionsRemote[0];
            if (field !== undefined) {
              if (version === undefined) {
                var match = Belt_List.getBy(previousAttributeValues, (function (param) {
                        return Caml_obj.caml_equal(param[0], field);
                      }));
                var match$1 = Belt_List.getBy(attributesRemotes, (function (param) {
                        return param[0].name === field;
                      }));
                if (match !== undefined) {
                  var value = match[1];
                  Curry._1(setVersion, (function (param) {
                          return value;
                        }));
                } else if (match$1 !== undefined) {
                  var value$1 = match$1[1];
                  if (typeof value$1 !== "number" && !value$1.tag) {
                    var value$2 = value$1[0];
                    Curry._1(setVersion, (function (param) {
                            return value$2;
                          }));
                  }
                  
                }
                
              }
              
            } else {
              Belt_Option.map(Belt_List.get(attributesRemotes, 0), (function (param) {
                      var att = param[0];
                      return Curry._1(setField, (function (param) {
                                    return att.name;
                                  }));
                    }));
            }
          }
          
        }), /* tuple */[
        field,
        attributeVersionsRemote,
        previousAttributeValues
      ]);
  React.useEffect((function () {
          Belt_Option.map(field, Curry.__1(fetchVersionAttributeMaxValues));
          
        }), [field]);
  React.useEffect((function () {
          UserFrontendSettings$BsConsole.getParsedSettingsFromBpg(token, config.user.uid, (function (settings) {
                  return Belt_Option.map(settings, (function (settings) {
                                Curry._1(setPreviousAttributeValues, (function (param) {
                                        return Belt_Option.getWithDefault(settings.reopenLatestAttributeValues, /* [] */0);
                                      }));
                                Curry._1(setField, (function (param) {
                                        return settings.reopenLatestSelectedAttribute;
                                      }));
                                Belt_Option.map(settings.reopenLatestSelectedType, (function (reopenType) {
                                        return Curry._1(setReopenType, (function (param) {
                                                      return ofString(reopenType);
                                                    }));
                                      }));
                                return Belt_Option.map(settings.reopenLatestSelectedTimePresent, (function (tp) {
                                              return Curry._1(setTimePreset, (function (param) {
                                                            return tp;
                                                          }));
                                            }));
                              }));
                }));
          
        }), ([]));
  var match$6 = React.useState((function () {
          return false;
        }));
  var setLoading = match$6[1];
  var loading = match$6[0];
  var handleSetInvariant = function (invariant) {
    Curry._1(onClose, undefined);
    return Curry._2(handleSetState, nextState, invariant);
  };
  var tmp;
  if (reopenType !== 0) {
    switch (reopenType - 1 | 0) {
      case /* SeenAgain */0 :
          tmp = React.createElement("div", {
                className: ReopenStyles$BsConsole.container
              }, React.createElement(ReopenTime$BsConsole.make, {
                    timePreset: timePreset,
                    setTimePreset: setTimePreset
                  }));
          break;
      case /* Time */1 :
          if (typeof attributeVersionsRemote === "number") {
            tmp = null;
          } else if (attributeVersionsRemote.tag) {
            tmp = React.createElement("div", {
                  className: ReopenStyles$BsConsole.container
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Caption */11,
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.px(5)),
                            /* :: */[
                              Css.width(Css.px(180)),
                              /* [] */0
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, "Whoops, something went wrong. Unable to set reopen on version. Please choose another option or reach out to support for assistance.")
                    }));
          } else {
            var attributesRemotes = attributeVersionsRemote[0];
            var match$7 = Belt_List.length(attributesRemotes);
            tmp = React.createElement("div", {
                  className: Css.merge(/* :: */[
                        ReopenStyles$BsConsole.container,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.marginBottom(Css.rem(0.5)),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]
                      ])
                }, match$7 !== 0 ? React.createElement(ReopenVersion$BsConsole.make, {
                        config: config,
                        token: token,
                        version: version,
                        setVersion: setVersion,
                        field: field,
                        setField: setField,
                        attributesRemotes: attributesRemotes
                      }) : React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Caption */11,
                        className: Css.style(/* :: */[
                              Css.marginBottom(Css.px(5)),
                              /* :: */[
                                Css.width(Css.px(180)),
                                /* [] */0
                              ]
                            ]),
                        children: null
                      }, I18N$BsConsole.show(undefined, "No semantic version attributes found on this group. Go to "), React.createElement("a", {
                            className: Css.style(/* :: */[
                                  Css.important(Css.color(Css.hex(Colors$BsConsole.accent))),
                                  /* :: */[
                                    Css.fontWeight(/* medium */-20425611),
                                    /* [] */0
                                  ]
                                ]),
                            href: Route$BsConsole.pathForRoute(/* ProjectSettingsAttributes */Block.__(34, [
                                    projectName,
                                    Route$BsConsole.ProjectSettingsAttributesParams.empty
                                  ])),
                            target: "_blank"
                          }, I18N$BsConsole.show(undefined, "Attributes")), I18N$BsConsole.show(undefined, " to create one.")));
          }
          break;
      case /* Version */2 :
          tmp = null;
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(FormControl.default, {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.flexDirection(/* column */-963948842),
                      /* :: */[
                        Css.padding(Css.px(15)),
                        /* [] */0
                      ]
                    ]
                  ]),
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.px(3)),
                        /* [] */0
                      ]),
                  color: Colors$BsConsole.grey1,
                  children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* String_literal */Block.__(11, [
                                    ", until fingerprint is...",
                                    /* End_of_format */0
                                  ])
                              ]),
                            "%s, until fingerprint is..."
                          ]), Issues$BsConsole.State.toStringAsVerbCapitalized(nextState))
                }), React.createElement(Select.default, {
                  value: /* `String */[
                    -976970511,
                    toString(reopenType)
                  ],
                  onChange: (function ($$event, param) {
                      var value = $$event.target.value;
                      var nextValue = ofString(value[1]);
                      return Curry._1(setReopenType, (function (param) {
                                    return nextValue;
                                  }));
                    }),
                  renderValue: (function (value) {
                      return React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Caption */11,
                                  children: I18N$BsConsole.showSkip(value[1])
                                });
                    }),
                  disableUnderline: true,
                  children: Belt_Array.map(reopenTypeOptions, (function (inv) {
                          return React.createElement(MenuItem.default, {
                                      value: /* `String */[
                                        -976970511,
                                        toString(inv)
                                      ],
                                      children: React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Caption */11,
                                            children: I18N$BsConsole.showSkip(toString(inv))
                                          })
                                    });
                        }))
                }), React.createElement(Collapse.default, {
                  in: reopenType !== /* SeenAgain */0,
                  timeout: 200,
                  children: tmp
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.marginTop(Css.px(5)),
                          /* :: */[
                            Css.marginBottom(Css.px(5)),
                            /* [] */0
                          ]
                        ]
                      ])
                }, React.createElement(Button.default, {
                      disableRipple: true,
                      variant: "contained",
                      color: "primary",
                      disabled: loading,
                      classes: {
                        root: Css.merge(/* :: */[
                              "",
                              /* :: */[
                                Css.style(/* :: */[
                                      Css.marginRight(Css.rem(1)),
                                      /* [] */0
                                    ]),
                                /* [] */0
                              ]
                            ])
                      },
                      onClick: (function (_event) {
                          Curry._1(setLoading, (function (param) {
                                  return true;
                                }));
                          switch (reopenType) {
                            case /* SeenAgain */0 :
                                UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                                      /* SetReopenLatestSelectedType */Block.__(3, [toString(reopenType)]),
                                      /* [] */0
                                    ], TeamsUtils$BsConsole.isGuest(config), Util$BsConsole.identity, (function (err) {
                                        var __x = Backtrace$BsConsole.Attributes.addString("src_module", "Reopen.re/onClick", Backtrace$BsConsole.Attributes.make(undefined));
                                        return Backtrace$BsConsole.Client.send(__x, /* `string */[
                                                    288368849,
                                                    "Failed to SetReopenLatestSelectedType: " + err
                                                  ]);
                                      }));
                                handleSetInvariant(/* Time */Block.__(0, ["1"]));
                                return ;
                            case /* Time */1 :
                                UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                                      /* SetReopenLastestSelectedTimePreset */Block.__(6, [timePreset]),
                                      /* :: */[
                                        /* SetReopenLatestSelectedType */Block.__(3, [toString(reopenType)]),
                                        /* [] */0
                                      ]
                                    ], TeamsUtils$BsConsole.isGuest(config), Util$BsConsole.identity, (function (err) {
                                        var __x = Backtrace$BsConsole.Attributes.addString("src_module", "Reopen.re/onClick", Backtrace$BsConsole.Attributes.make(undefined));
                                        return Backtrace$BsConsole.Client.send(__x, /* `string */[
                                                    288368849,
                                                    "Failed to SetReopenLastestSelectedTimePreset and SetReopenLatestSelectedType: " + err
                                                  ]);
                                      }));
                                handleSetInvariant(/* Time */Block.__(0, [ReopenTime$BsConsole.TimePresets.dateToInvariantSubmissionFormat(ReopenTime$BsConsole.TimePresets.toDate(timePreset))]));
                                return ;
                            case /* Version */2 :
                                if (field !== undefined && version !== undefined) {
                                  UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                                        /* SetReopenLatestSelectedType */Block.__(3, [toString(reopenType)]),
                                        /* :: */[
                                          /* SetReopenLatestAttributeValue */Block.__(5, [/* tuple */[
                                                field,
                                                version
                                              ]]),
                                          /* :: */[
                                            /* SetReopenLatestSelectedAttribute */Block.__(4, [field]),
                                            /* [] */0
                                          ]
                                        ]
                                      ], TeamsUtils$BsConsole.isGuest(config), Util$BsConsole.identity, (function (err) {
                                          var __x = Backtrace$BsConsole.Attributes.addString("src_module", "Reopen.re/onClick", Backtrace$BsConsole.Attributes.make(undefined));
                                          return Backtrace$BsConsole.Client.send(__x, /* `string */[
                                                      288368849,
                                                      "Failed to SetReopenLatestSelectedType, SetReopenLatestAttributeValue, and SetReopenLatestSelectedAttribute: " + err
                                                    ]);
                                        }));
                                  handleSetInvariant(/* Version */Block.__(1, [/* tuple */[
                                            field,
                                            version
                                          ]]));
                                  return ;
                                } else {
                                  return ;
                                }
                            case /* NoInvariant */3 :
                                return ;
                            
                          }
                        }),
                      children: loading ? I18N$BsConsole.show(undefined, "Loading...") : I18N$BsConsole.show(undefined, "Save")
                    }), React.createElement(Button.default, {
                      disableRipple: true,
                      variant: "text",
                      color: "primary",
                      disabled: loading,
                      onClick: (function (_event) {
                          return Curry._1(onClose, undefined);
                        }),
                      children: I18N$BsConsole.show(undefined, "Cancel")
                    })));
}

function make(config, nextState, handleSetState, projectName, onClose, aperture, sortOpt, children) {
  var sort = sortOpt !== undefined ? Caml_option.valFromOption(sortOpt) : undefined;
  return ReasonReactCompat.wrapReactForReasonReact(Reopen, {
              config: config,
              nextState: nextState,
              handleSetState: handleSetState,
              projectName: projectName,
              onClose: onClose,
              aperture: aperture,
              sort: sort
            }, children);
}

var Re = {
  make: make
};

var make$1 = Reopen;

exports.Invariant = Invariant;
exports.reopenTypeOptions = reopenTypeOptions;
exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
