// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Lazy = require("bs-platform/lib/js/lazy.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var I18N$BsConsole = require("../I18N.js");

function make(key, value, children, depth, suffix, annotationsOpt, color, param) {
  var annotations = annotationsOpt !== undefined ? annotationsOpt : [];
  return {
          key: key,
          value: value,
          children: children,
          depth: depth,
          suffix: suffix,
          annotations: annotations,
          color: color
        };
}

function getItemType(json) {
  if (Array.isArray(json)) {
    return /* Arr */0;
  } else if (typeof json === "object" && json !== null) {
    return /* Obj */1;
  } else {
    return /* Val */2;
  }
}

var priorityKeys = [
  "debug_file",
  "debug_identifier",
  "path"
];

function fromJson(key, depthOpt, renderPreviewOpt, json) {
  var depth = depthOpt !== undefined ? depthOpt : -1;
  var renderPreview = renderPreviewOpt !== undefined ? renderPreviewOpt : true;
  var match = getItemType(json);
  var match$1;
  switch (match) {
    case /* Arr */0 :
        var children = Belt_Array.mapWithIndex(json, (function (idx, el) {
                return Lazy.from_fun((function (param) {
                              return fromJson(String(idx), depth + 1 | 0, renderPreview, el);
                            }));
              }));
        if (children.length === 0) {
          match$1 = /* tuple */[
            Caml_option.some(I18N$BsConsole.showSkip("[]")),
            []
          ];
        } else {
          var previewItems = Belt_Array.map(Belt_Array.slice(json, 0, 3), (function (item) {
                    var match = getItemType(item);
                    switch (match) {
                      case /* Arr */0 :
                          return "[..]";
                      case /* Obj */1 :
                          return "{..}";
                      case /* Val */2 :
                          return "" + (String(item) + "");
                      
                    }
                  })).join(", ");
          var preview = json.length <= 3 ? "[" + (previewItems + "]") : "[" + (previewItems + ", ...]");
          match$1 = /* tuple */[
            renderPreview ? Caml_option.some(React.createElement("span", {
                        className: Css.style(/* :: */[
                              Css.fontStyle(Css.italic),
                              /* [] */0
                            ])
                      }, I18N$BsConsole.showSkip(preview))) : undefined,
            children
          ];
        }
        break;
    case /* Obj */1 :
        var entries = Js_dict.entries(json);
        var children$1 = Belt_Array.map(entries, (function (param) {
                var v = param[1];
                var key = param[0];
                return Lazy.from_fun((function (param) {
                              return fromJson(key, depth + 1 | 0, renderPreview, v);
                            }));
              }));
        if (children$1.length === 0) {
          match$1 = /* tuple */[
            Caml_option.some(I18N$BsConsole.showSkip("{}")),
            []
          ];
        } else {
          var previewItems$1 = Belt_Array.map(Belt_Array.slice(Belt_SortArray.stableSortBy(entries, (function (param, param$1) {
                            var match = priorityKeys.indexOf(param[0]);
                            var match$1 = priorityKeys.indexOf(param$1[0]);
                            if (match !== -1) {
                              if (match$1 !== -1) {
                                return Caml_primitive.caml_int_compare(match, match$1);
                              } else {
                                return -1;
                              }
                            } else if (match$1 !== -1) {
                              return 1;
                            } else {
                              return 0;
                            }
                          })), 0, 2), (function (param) {
                    var item = param[1];
                    var key = param[0];
                    var match = getItemType(item);
                    switch (match) {
                      case /* Arr */0 :
                          return key + ": [..]";
                      case /* Obj */1 :
                          return key + ": {..}";
                      case /* Val */2 :
                          return "" + (String(key) + (": " + (String(item) + "")));
                      
                    }
                  })).join(", ");
          var preview$1 = entries.length <= 2 ? "{" + (previewItems$1 + "}") : "{" + (previewItems$1 + ", ...}");
          match$1 = /* tuple */[
            renderPreview ? Caml_option.some(React.createElement("span", {
                        className: Css.style(/* :: */[
                              Css.fontStyle(Css.italic),
                              /* [] */0
                            ])
                      }, I18N$BsConsole.showSkip(preview$1))) : undefined,
            children$1
          ];
        }
        break;
    case /* Val */2 :
        match$1 = /* tuple */[
          Caml_option.some(I18N$BsConsole.showSkip("" + (String(json) + ""))),
          []
        ];
        break;
    
  }
  return make(key, match$1[0], match$1[1], depth, undefined, undefined, undefined, undefined);
}

exports.make = make;
exports.getItemType = getItemType;
exports.priorityKeys = priorityKeys;
exports.fromJson = fromJson;
/* Css Not a pure module */
