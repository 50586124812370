// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Card = require("@material-ui/core/Card");
var BtTypography$BsConsole = require("../BtTypography.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var CardContent = require("@material-ui/core/CardContent");

var logo = Css.style(/* :: */[
      Css.height(Css.px(32)),
      /* [] */0
    ]);

var Styles = {
  logo: logo
};

function TeamsHasProjects(Props) {
  React.useEffect((function () {
          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* TeamsWelcomeSeen */130]));
          
        }), ([]));
  return React.createElement(Card.default, {
              className: Css.style(/* :: */[
                    Css.padding(Css.px(25)),
                    /* [] */0
                  ]),
              children: React.createElement(CardContent.default, {
                    children: null
                  }, React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* center */98248149,
                        children: React.createElement("img", {
                              className: logo,
                              src: "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg"
                            })
                      }), React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.display(/* flex */-1010954439),
                              /* :: */[
                                Css.flexDirection(/* column */-963948842),
                                /* :: */[
                                  Css.alignItems(/* center */98248149),
                                  /* :: */[
                                    Css.marginTop(Css.px(50)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Heading1 */0,
                            className: Css.style(/* :: */[
                                  Css.marginBottom(Css.px(10)),
                                  /* [] */0
                                ]),
                            color: Colors$BsConsole.grey0,
                            children: I18N$BsConsole.show(undefined, "Welcome!")
                          }), React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Subtitle1 */6,
                            color: Colors$BsConsole.grey0,
                            children: I18N$BsConsole.show(undefined, "You haven't been invited to any projects yet. Please reach out to your team administrator for access.")
                          })))
            });
}

function make(children) {
  return ReasonReactCompat.wrapReactForReasonReact(TeamsHasProjects, { }, children);
}

var Re = {
  make: make
};

var make$1 = TeamsHasProjects;

exports.Styles = Styles;
exports.make = make$1;
exports.Re = Re;
/* logo Not a pure module */
