// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RR$BsConsole = require("../RR.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var TriageGroup$BsConsole = require("../TriageGroup.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var InboxMetrics$BsConsole = require("./InboxMetrics.js");
var Paper = require("@material-ui/core/Paper");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Popper = require("@material-ui/core/Popper");
var BtFilterSelect$BsConsole = require("../BtFilterSelect.js");
var Tooltip = require("@material-ui/core/Tooltip");
var BtOutlineSelect$BsConsole = require("../BtOutlineSelect.js");
var WorkflowVersion$BsConsole = require("../workflow/WorkflowVersion.js");
var MenuItem = require("@material-ui/core/MenuItem");
var InboxMergerError$BsConsole = require("./InboxMergerError.js");
var IconButton = require("@material-ui/core/IconButton");
var MergeType = require("@material-ui/icons/MergeType");
var CardActions = require("@material-ui/core/CardActions");
var CardContent = require("@material-ui/core/CardContent");
var Fingerprint = require("@material-ui/icons/Fingerprint");

var component = RR$BsConsole.statelessComponent("InboxMergeButton-BsConsole");

var root = Css.style(/* :: */[
      Css.minWidth(Css.px(50)),
      /* [] */0
    ]);

var list = Css.style(/* :: */[
      Css.maxWidth(Css.px(460)),
      /* [] */0
    ]);

var buttonContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.justifyContent(/* flexEnd */924268066),
          /* :: */[
            Css.width(Css.pct(100)),
            /* [] */0
          ]
        ]
      ]
    ]);

var Style = {
  root: root,
  list: list,
  buttonContainer: buttonContainer
};

var mergeText = I18N$BsConsole.get(undefined, "This will merge all selected errors into a single new error. All assignees, linked issues, and tags will be retained in the original errors, and the system will union the values for the new error.");

var workflowMergeText = I18N$BsConsole.get(undefined, "This will merge all selected errors into a single parent source error resulting a new group. All assignees, linked issues, and tags will be retained in the original errors, and the system will union the values for the new error. The status from the parent source group will be the status of the new resulting group including \"resolve / mute until\".");

function InboxMergeButton(Props) {
  var fingerprints = Props.fingerprints;
  var onMerge = Props.onMerge;
  var config = Props.config;
  var projectName = Props.projectName;
  var token = Props.token;
  var variantOpt = Props.variant;
  var classNameOpt = Props.className;
  var defaultSourceFingerprint = Props.defaultSourceFingerprint;
  var variant = variantOpt !== undefined ? variantOpt : /* Default */0;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var match$1 = React.useState((function () {
          if (defaultSourceFingerprint !== undefined) {
            return defaultSourceFingerprint;
          } else {
            return Belt_Array.get(fingerprints, 0);
          }
        }));
  var setSourceFingerprint = match$1[1];
  var sourceFingerprint = match$1[0];
  var wfVersion = React.useMemo((function () {
          return WorkflowVersion$BsConsole.current(config);
        }), [config]);
  var buttonDisabled = React.useMemo((function () {
          return fingerprints.length <= 1;
        }), [fingerprints]);
  var handleOnClick = React.useCallback((function ($$event) {
          var target = $$event.currentTarget;
          if (!(target == null)) {
            return Curry._1(setAnchorEl, (function (param) {
                          return Caml_option.some(target);
                        }));
          }
          
        }), ([]));
  var handleOnSelectChange = function ($$event, _jsEvent) {
    var value$prime = $$event.target.value;
    return Curry._1(setSourceFingerprint, (function (param) {
                  if (value$prime == null) {
                    return ;
                  } else {
                    return Caml_option.some(value$prime);
                  }
                }));
  };
  var onSuccess = React.useCallback((function (group) {
          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                        /* String_literal */Block.__(11, [
                            "Successfully merged ",
                            /* Int */Block.__(4, [
                                /* Int_d */0,
                                /* No_padding */0,
                                /* No_precision */0,
                                /* String_literal */Block.__(11, [
                                    " errors",
                                    /* End_of_format */0
                                  ])
                              ])
                          ]),
                        "Successfully merged %d errors"
                      ]), fingerprints.length));
          InboxMetrics$BsConsole.send(/* MergeSuccess */0);
          return Curry._1(onMerge, group);
        }), [fingerprints]);
  var onFailure = React.useCallback((function (param) {
          InboxMetrics$BsConsole.send(/* MergeFailure */1);
          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to merge groups"));
        }), ([]));
  var handleMergeGroups = function (param) {
    if (typeof wfVersion !== "number" && sourceFingerprint !== undefined) {
      return InboxMergerError$BsConsole.workflowMergeErrors(wfVersion[0], token, onSuccess, onFailure, Belt_List.fromArray(fingerprints), sourceFingerprint, projectName, undefined);
    }
    if (CoronerdGate$BsConsole.mergeUnmergeEndpoint) {
      return InboxMergerError$BsConsole.mergeErrors(token, onSuccess, onFailure, fingerprints, projectName, undefined);
    } else {
      return InboxMergerError$BsConsole.deprecatedMergeErrors(token, onSuccess, onFailure, Belt_List.fromArray(fingerprints), projectName, undefined);
    }
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Tooltip.default, {
                  children: variant ? React.createElement(IconButton.default, {
                          disabled: buttonDisabled,
                          color: "primary",
                          className: className,
                          onClick: handleOnClick,
                          children: React.createElement(MergeType.default, { })
                        }) : React.createElement(Button.default, {
                          variant: "contained",
                          color: "primary",
                          className: className,
                          disabled: buttonDisabled,
                          onClick: handleOnClick,
                          children: I18N$BsConsole.show(undefined, "Merge groups")
                        }),
                  title: I18N$BsConsole.get(undefined, "Merge groups")
                }), React.createElement(Popper.default, {
                  anchorEl: anchorEl,
                  open: Belt_Option.isSome(anchorEl),
                  children: React.createElement(Paper.default, {
                        className: Css.style(/* :: */[
                              Css.maxWidth(Css.px(400)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement(CardContent.default, {
                            children: typeof wfVersion === "number" ? I18N$BsConsole.showSkip(mergeText) : React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body1 */8,
                                        children: I18N$BsConsole.show(undefined, "Merging all selected fingerprints will result in a new fingerprint. Assignees, linked issues, and tags will be retained in the original fingerprints, and the system will attempt to union values in the new fingerprint.")
                                      }), React.createElement("br", undefined), React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body1 */8,
                                        children: I18N$BsConsole.show(undefined, "Please choose a source fingerprint to copy the status and \"resolve/mute until\" rules in the new fingerprint. All other statuses and rules will not apply to the new fingerprint.")
                                      }))
                          }), typeof wfVersion === "number" ? null : (
                          defaultSourceFingerprint !== undefined ? React.createElement(CardContent.default, {
                                  children: null
                                }, React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Body1 */8,
                                      children: I18N$BsConsole.show(undefined, "Source fingerprint group")
                                    }), React.createElement(Row2$BsConsole.make, {
                                      alignItems: /* center */98248149,
                                      children: I18N$BsConsole.showSkip(TriageGroup$BsConsole.formatFingerprint(defaultSourceFingerprint).slice(0, 7))
                                    })) : React.createElement(CardContent.default, {
                                  children: React.createElement(Row2$BsConsole.make, {
                                        justifyContent: /* center */98248149,
                                        children: React.createElement(BtOutlineSelect$BsConsole.make, {
                                              value: sourceFingerprint,
                                              name: "source-fingerprint",
                                              renderValue: (function (value) {
                                                  return React.createElement(Row2$BsConsole.make, {
                                                              alignItems: /* center */98248149,
                                                              children: null
                                                            }, React.createElement(Row2$BsConsole.make, {
                                                                  alignItems: /* center */98248149,
                                                                  className: Css.style(/* :: */[
                                                                        Css.color(Css.hex(Colors$BsConsole.grey2)),
                                                                        /* :: */[
                                                                          Css.marginRight(Css.rem(0.5)),
                                                                          /* [] */0
                                                                        ]
                                                                      ]),
                                                                  children: React.createElement(Fingerprint.default, {
                                                                        color: "inherit",
                                                                        fontSize: "inherit"
                                                                      })
                                                                }), value !== undefined ? I18N$BsConsole.showSkip(TriageGroup$BsConsole.formatFingerprint(value).slice(0, 7)) : I18N$BsConsole.showSkip("-"));
                                                }),
                                              onChange: handleOnSelectChange,
                                              label: "Set source group",
                                              className: Css.style(/* :: */[
                                                    Css.minWidth(Css.px(200)),
                                                    /* [] */0
                                                  ]),
                                              children: Belt_Array.map(fingerprints, (function (fingerprint) {
                                                      return React.createElement(MenuItem.default, {
                                                                  disableRipple: true,
                                                                  classes: BtFilterSelect$BsConsole.Style.menuItemClasses,
                                                                  value: fingerprint,
                                                                  children: I18N$BsConsole.showSkip(TriageGroup$BsConsole.formatFingerprint(fingerprint).slice(0, 7)),
                                                                  key: fingerprint
                                                                });
                                                    }))
                                            })
                                      })
                                })
                        ), React.createElement(CardContent.default, {
                            children: React.createElement(CardActions.default, {
                                  className: Css.style(/* :: */[
                                        Css.justifyContent(/* flexEnd */924268066),
                                        /* [] */0
                                      ]),
                                  children: null
                                }, React.createElement(Button.default, {
                                      color: "primary",
                                      onClick: (function (param) {
                                          return Curry._1(setAnchorEl, (function (param) {
                                                        
                                                      }));
                                        }),
                                      children: I18N$BsConsole.show(undefined, "Cancel")
                                    }), React.createElement(Button.default, {
                                      variant: "contained",
                                      color: "primary",
                                      onClick: (function (param) {
                                          handleMergeGroups(undefined);
                                          return Curry._1(setAnchorEl, (function (param) {
                                                        
                                                      }));
                                        }),
                                      children: I18N$BsConsole.show(undefined, "Merge")
                                    }))
                          }))
                }));
}

function make(fingerprints, onMerge, config, projectName, token, variant, className, defaultSourceFingerprint, children) {
  var tmp = {
    fingerprints: fingerprints,
    onMerge: onMerge,
    config: config,
    projectName: projectName,
    token: token
  };
  if (variant !== undefined) {
    tmp.variant = Caml_option.valFromOption(variant);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (defaultSourceFingerprint !== undefined) {
    tmp.defaultSourceFingerprint = Caml_option.valFromOption(defaultSourceFingerprint);
  }
  return ReasonReactCompat.wrapReactForReasonReact(InboxMergeButton, tmp, children);
}

var Jsx2 = {
  make: make
};

var make$1 = InboxMergeButton;

exports.component = component;
exports.Style = Style;
exports.mergeText = mergeText;
exports.workflowMergeText = workflowMergeText;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* component Not a pure module */
