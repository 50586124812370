// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Email$BsConsole = require("./Email.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var PhoneHomeTask$BsConsole = require("./PhoneHomeTask.js");
var Dialog = require("@material-ui/core/Dialog");
var Typography = require("@material-ui/core/Typography");
var CardContent = require("@material-ui/core/CardContent");
var DialogTitle = require("@material-ui/core/DialogTitle");

var card = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.justifyContent(/* flexStart */662439529),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.margin(Css.px(35)),
              /* :: */[
                Css.marginTop(Css.px(25)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var email = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.blueDark))),
      /* [] */0
    ]);

var logo = Css.style(/* :: */[
      Css.height(Css.px(32)),
      /* :: */[
        Css.marginBottom(Css.px(40)),
        /* [] */0
      ]
    ]);

function PhoneHome$AccountDisabledScreen(Props) {
  return React.createElement(Dialog.default, {
              open: true,
              disableBackdropClick: true,
              disableEscapeKeyDown: true,
              children: React.createElement(CardContent.default, {
                    className: card,
                    children: null
                  }, React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* center */98248149,
                        children: React.createElement("img", {
                              className: logo,
                              src: "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg"
                            })
                      }), React.createElement(DialogTitle.default, {
                        children: I18N$BsConsole.show(undefined, "Your Backtrace account has been disabled.")
                      }), React.createElement(Typography.default, {
                        variant: "subheading",
                        children: null
                      }, I18N$BsConsole.show(undefined, "Please e-mail "), React.createElement("a", {
                            className: email,
                            href: "mailto:" + Email$BsConsole.sales
                          }, I18N$BsConsole.showSkip(Email$BsConsole.sales)), I18N$BsConsole.show(undefined, " to re-enable your account.")))
            });
}

var AccountDisabledScreen = {
  card: card,
  email: email,
  logo: logo,
  make: PhoneHome$AccountDisabledScreen
};

function PhoneHome(Props) {
  var universeName = Props.universeName;
  var children = Props.children;
  var match = PhoneHomeTask$BsConsole.use(universeName);
  var phoneHomeRemote = match[0];
  if (typeof phoneHomeRemote === "number" || phoneHomeRemote.tag || phoneHomeRemote[0] !== true) {
    return children;
  } else {
    return React.createElement(PhoneHome$AccountDisabledScreen, { });
  }
}

function make(universeName, children) {
  return ReasonReactCompat.wrapReactForReasonReact(PhoneHome, {
              universeName: universeName,
              children: children
            }, children);
}

var Jsx2 = {
  make: make
};

var make$1 = PhoneHome;

exports.AccountDisabledScreen = AccountDisabledScreen;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* card Not a pure module */
