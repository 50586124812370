// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Shell$BsConsole = require("./shell.js");
var Task2$BsConsole = require("./Task2.js");
var Styles$BsConsole = require("./utilities/Styles.js");
var Unpack$BsConsole = require("./bs-crdb-response/src/Unpack.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BugTasks$BsConsole = require("./debugger2/data/BugTasks.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var ResponseSelect$BsConsole = require("./bs-crdb-response/src/ResponseSelect.js");
var Tooltip = require("@material-ui/core/Tooltip");
var IconButton = require("@material-ui/core/IconButton");
var Assignment = require("@material-ui/icons/Assignment");
var CallstackCopyButton$BsConsole = require("./CallstackCopyButton.js");

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            loading: true,
            errorId: state.errorId,
            formattedText: state.formattedText
          };
  }
  switch (action.tag | 0) {
    case /* SetErrorId */0 :
        return {
                loading: state.loading,
                errorId: action[0],
                formattedText: state.formattedText
              };
    case /* SetLoading */1 :
        return {
                loading: action[0],
                errorId: state.errorId,
                formattedText: state.formattedText
              };
    case /* SetFormattedText */2 :
        return {
                loading: false,
                errorId: state.errorId,
                formattedText: action[0]
              };
    
  }
}

var initialState = {
  loading: false,
  errorId: undefined,
  formattedText: undefined
};

function getCallstack(errorObject, frames) {
  if (errorObject === undefined) {
    return /* Frames */Block.__(0, [frames]);
  }
  if (errorObject.tag) {
    var error = errorObject[0];
    try {
      var selectedThread = Belt_Option.getWithDefault(Belt_Option.map(error.faultingThread, (function (prim) {
                  return prim[0];
                })), Belt_Array.getExn(error.threads, 0));
      var stack = selectedThread.stack;
      var frames$1 = stack !== undefined ? stack : [];
      return /* Generic */Block.__(2, [frames$1]);
    }
    catch (exn){
      return /* Frames */Block.__(0, [frames]);
    }
  } else {
    var error$1 = errorObject[0];
    try {
      var thread = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(error$1.threads.find((function (thread) {
                      return thread.faulted;
                    }))), Belt_Array.getExn(error$1.threads, 0));
      var frames$2 = thread.user;
      return /* Btt */Block.__(3, [frames$2]);
    }
    catch (exn$1){
      return /* Frames */Block.__(0, [frames]);
    }
  }
}

function copyToClipboard(text) {
  var __x = window.navigator.clipboard.writeText(text);
  var __x$1 = __x.then((function (param) {
          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Callstack copied to clipboard"));
          return Promise.resolve(undefined);
        }));
  __x$1.catch((function (param) {
          SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to copy callstack to clipboard"));
          return Promise.resolve(undefined);
        }));
  
}

function CallstackCopyButtonWithObjectFetch(Props) {
  var config = Props.config;
  var token = Props.token;
  var projectName = Props.projectName;
  var aperture = Props.aperture;
  var frames = Props.frames;
  var fingerprint = Props.fingerprint;
  var targetOpt = Props.target;
  var target = targetOpt !== undefined ? targetOpt : /* Icon */1;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var formattedText = match$1.formattedText;
  var errorId = match$1.errorId;
  var loading = match$1.loading;
  var endpoint = Shell$BsConsole.getQueryEndpoint(config);
  var newAperture;
  if (fingerprint !== undefined) {
    var __x = Crdb$BsConsole.Filters.empty;
    var __x$1 = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
              "fingerprint",
              Crdb$BsConsole.FilterOperation.fromTuple(/* tuple */[
                    "equal",
                    fingerprint
                  ])
            ]), __x);
    newAperture = Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                  "_deleted",
                  Crdb$BsConsole.FilterOperation.fromTuple(/* tuple */[
                        "equal",
                        String(0)
                      ])
                ]), __x$1), aperture);
  } else {
    newAperture = aperture;
  }
  var __x$2 = Crdb$BsConsole.Select.empty;
  var query_001 = Curry._2(Crdb$BsConsole.Select.add, "_tx", __x$2);
  var query_002 = Crdb$BsConsole.Sort.defaultSelect;
  var query_003 = Caml_option.some(Curry._2(Crdb$BsConsole.Paginate.nth, 0, Crdb$BsConsole.Paginate.initial));
  var query = /* Select */Block.__(1, [
      newAperture,
      query_001,
      query_002,
      query_003
    ]);
  React.useEffect((function () {
          if (errorId !== undefined) {
            var arg = BugTasks$BsConsole.getError(projectName, errorId, token);
            Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (resp) {
                    if (resp.tag) {
                      SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                      var callstack = getCallstack(undefined, frames);
                      var formattedText = CallstackCopyButton$BsConsole.getFormattedText(callstack);
                      Curry._1(dispatch, /* SetFormattedText */Block.__(2, [formattedText]));
                      return copyToClipboard(formattedText);
                    }
                    var callstack$1 = getCallstack(resp[0], frames);
                    var formattedText$1 = CallstackCopyButton$BsConsole.getFormattedText(callstack$1);
                    Curry._1(dispatch, /* SetFormattedText */Block.__(2, [formattedText$1]));
                    return copyToClipboard(formattedText$1);
                  }));
          }
          
        }), [errorId]);
  var onClick = function (param) {
    if (formattedText !== undefined) {
      return copyToClipboard(formattedText);
    }
    Curry._1(dispatch, /* FetchingError */0);
    var arg = Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(endpoint) + ("?project=" + (String(projectName) + "")))]), undefined, /* Post */Block.__(0, [Crdb$BsConsole.QueryManager.toCrdb(query)]), undefined, (function (respBag) {
            return /* Ok */Block.__(0, [respBag.json]);
          }), undefined, undefined);
    return Curry._2((function (param) {
                    return (function (param$1, param$2) {
                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                      });
                  })(token), undefined, (function (resp) {
                  if (resp.tag) {
                    Curry._1(dispatch, /* SetLoading */Block.__(1, [false]));
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, resp[0])));
                  }
                  var json = resp[0];
                  try {
                    var columns = ResponseSelect$BsConsole.columns(json);
                    var rows = Belt_Array.map(Belt_List.toArray(Unpack$BsConsole.response2PointOh(json, columns)), Belt_List.toArray);
                    var errorIdRows = Belt_Array.map(rows, (function (row) {
                            var nonNullFields = Belt_Array.keepMap(row, (function (param) {
                                    var k = param[0];
                                    return Belt_Option.map(param[1], (function (v) {
                                                  return /* tuple */[
                                                          k,
                                                          v
                                                        ];
                                                }));
                                  }));
                            var param = Belt_Option.getExn(Caml_option.undefined_to_opt(nonNullFields.find((function (param) {
                                            return param[0] === "id";
                                          }))));
                            var v = param[1];
                            if (!v.tag) {
                              var match = v[0];
                              if (typeof match !== "number" && match[0] === -976970511) {
                                return match[1];
                              }
                              
                            }
                            throw [
                                  Caml_builtin_exceptions.assert_failure,
                                  /* tuple */[
                                    "CallstackCopyButtonWithObjectFetch.re",
                                    187,
                                    31
                                  ]
                                ];
                          }));
                    var errorId = Belt_Array.get(errorIdRows, 0);
                    if (errorId !== undefined) {
                      return Curry._1(dispatch, /* SetErrorId */Block.__(0, [errorId]));
                    }
                    var callstack = getCallstack(undefined, frames);
                    var formattedText = CallstackCopyButton$BsConsole.getFormattedText(callstack);
                    Curry._1(dispatch, /* SetFormattedText */Block.__(2, [formattedText]));
                    return copyToClipboard(formattedText);
                  }
                  catch (exn){
                    Curry._1(dispatch, /* SetLoading */Block.__(1, [false]));
                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, Task2$BsConsole.UnknownError)));
                  }
                }));
  };
  if (target) {
    return React.createElement(Tooltip.default, {
                placement: "top",
                children: React.createElement(IconButton.default, {
                      disabled: loading,
                      onClick: onClick,
                      children: React.createElement(Assignment.default, {
                            color: "inherit",
                            fontSize: "inherit"
                          })
                    }),
                title: React.createElement("span", {
                      className: Styles$BsConsole.$$Text.tooltipTitle
                    }, I18N$BsConsole.show(undefined, "Copy callstack to clipboard"))
              });
  } else {
    return React.createElement(Button.default, {
                color: "primary",
                disabled: loading,
                classes: {
                  root: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ])
                },
                onClick: onClick,
                children: I18N$BsConsole.show(undefined, "Copy callstack")
              });
  }
}

function make(config, token, projectName, aperture, frames, fingerprint, targetOpt, _children) {
  var target = targetOpt !== undefined ? targetOpt : /* Icon */1;
  return ReasonReactCompat.wrapReactForReasonReact(CallstackCopyButtonWithObjectFetch, {
              config: config,
              token: token,
              projectName: projectName,
              aperture: aperture,
              frames: frames,
              fingerprint: fingerprint,
              target: target
            }, _children);
}

var Re = {
  make: make
};

var make$1 = CallstackCopyButtonWithObjectFetch;

exports.reducer = reducer;
exports.initialState = initialState;
exports.getCallstack = getCallstack;
exports.copyToClipboard = copyToClipboard;
exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
