// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Qs = require("qs");
var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var List = require("bs-platform/lib/js/list.js");
var Uuid = require("uuid");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Printf = require("bs-platform/lib/js/printf.js");
var Belt_Id = require("bs-platform/lib/js/belt_Id.js");
var Belt_Set = require("bs-platform/lib/js/belt_Set.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Printexc = require("bs-platform/lib/js/printexc.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RR$BsConsole = require("./RR.js");
var FuzzaldrinPlus = require("fuzzaldrin-plus");
var Numeral$BsConsole = require("./Numeral.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var InboxUtils$BsConsole = require("./inbox/InboxUtils.js");
var L10N_date_time$BsConsole = require("./L10N_date_time.js");
var L10N_date_time_clock$BsConsole = require("./L10N_date_time_clock.js");
var L10N_date_time_calendar$BsConsole = require("./L10N_date_time_calendar.js");

function formatBytes(value) {
  var regex = new RegExp("(.+)\\.$");
  var result = regex.exec(value);
  var value$1 = result !== null ? Belt_Option.getWithDefault(Belt_Array.get(result, 1), "") : value;
  if (value$1.length > 4) {
    return Numeral$BsConsole.format("0,0.00b", value$1);
  } else {
    return Numeral$BsConsole.format("0b", value$1);
  }
}

function formatIpv4(value) {
  return Curry._1((function formatIpv4(str){
        var int = parseInt(str);
        return ( (int>>>24) +'.' + (int>>16 & 255) +'.' + (int>>8 & 255) +'.' + (int & 255) );
      }), value);
}

function stringToHex(str) {
  return str.split("").map((function (param, i) {
                    return str.charCodeAt(i);
                  })).map((function (charCode) {
                  return charCode.toString(16);
                })).join("");
}

function formattedStringify(j) {
  return JSON.stringify(j, null, 2);
}

function filterBacktraceInternalUsers(users, universe, isUnityVariant) {
  switch (universe) {
    case "backtrace" :
    case "bt-demos" :
    case "cd03" :
    case "yolo" :
        return users;
    default:
      if (isUnityVariant) {
        return Belt_Array.keep(Belt_Array.keep(Belt_Array.keep(users, (function (param) {
                              return !param.email.includes("backtrace.io");
                            })), (function (param) {
                          return !param.email.includes("unity.com");
                        })), (function (param) {
                      return !param.email.includes("unity3d.com");
                    }));
      } else {
        return Belt_Array.keep(users, (function (param) {
                      return !param.email.includes("backtrace.io");
                    }));
      }
  }
}

function shouldFilterBacktraceInternalUser(email, universe, isUnityVariant) {
  switch (universe) {
    case "backtrace" :
    case "bt-demos" :
    case "cd03" :
    case "yolo" :
        return false;
    default:
      if (isUnityVariant && !(email.includes("unity3d.com") && email.includes("unity.com"))) {
        return false;
      } else {
        return email.includes("backtrace.io");
      }
  }
}

function rawStringify(record) {
  return Curry._1(((function strinify(fullRecord) {
          function encodeValues(r) {

            if ( typeof r === "number" ) {
              return r;
            }

            if ( typeof r === "string" ) {
              return `"${encodeURIComponent(r.replace(/"/g, '\\"'))}"`;
            }

            if (Array.isArray(r)) {
              return `[${
                r.map(i => encodeValues(i)).join(",")
              }]`;
            }

            if (typeof r === "object") {
              const keyValues = Object.keys(r).map(k => {
               return `"${k}":${encodeValues(r[k])}`;
              });
              return `{${keyValues}}`;
            }

            return `"${encodeURIComponent(r)}"`;
          }
          function qsStrinify(r) {
            return Object.keys(r)
              .map(k => {
                return `${k}=${encodeValues(r[k])}`;
              })
              .join("&");
          }
          return qsStrinify(fullRecord);
        })), record);
}

function parse(search) {
  try {
    return Qs.parse(search);
  }
  catch (exn){
    return Belt_Option.getExn(Json.parse("{}"));
  }
}

var stringify = rawStringify;

var Qs$1 = {
  rawStringify: rawStringify,
  parse: parse,
  stringify: stringify
};

function filterAttribute(maybeSearch, l) {
  if (maybeSearch === undefined) {
    return l;
  }
  var __x = $$Array.mapi((function (i, e) {
          return {
                  q: InboxUtils$BsConsole.getFriendlyName(e.name),
                  index: i
                };
        }), $$Array.of_list(l));
  var results = FuzzaldrinPlus.filter(__x, maybeSearch, {
        key: "q"
      });
  if (results.length !== 0) {
    return $$Array.to_list($$Array.map((function (index) {
                      return List.nth(l, index);
                    }), $$Array.map((function (r) {
                          return r.index;
                        }), results)));
  }
  
}

function filterAttributeForDetail(maybeSearch, l) {
  if (maybeSearch === undefined) {
    return l;
  }
  var __x = $$Array.mapi((function (i, e) {
          return {
                  q: InboxUtils$BsConsole.getFriendlyName(e.attribute.name),
                  index: i
                };
        }), $$Array.of_list(l));
  var results = FuzzaldrinPlus.filter(__x, maybeSearch, {
        key: "q"
      });
  if (results.length !== 0) {
    return $$Array.to_list($$Array.map((function (index) {
                      return List.nth(l, index);
                    }), $$Array.map((function (r) {
                          return r.index;
                        }), results)));
  }
  
}

function filterTagList(l, search) {
  if (search.length <= 0) {
    return l;
  }
  var __x = $$Array.mapi((function (i, e) {
          return {
                  q: e.tag,
                  index: i
                };
        }), $$Array.of_list(l));
  var results = FuzzaldrinPlus.filter(__x, search, {
        key: "q"
      });
  if (results.length !== 0) {
    return $$Array.to_list($$Array.map((function (index) {
                      return List.nth(l, index);
                    }), $$Array.map((function (r) {
                          return r.index;
                        }), results)));
  } else {
    return /* [] */0;
  }
}

function filterOperation(search, items) {
  if (search === undefined) {
    return items;
  }
  var results = FuzzaldrinPlus.filter($$Array.of_list(items), search);
  if (results.length !== 0) {
    return $$Array.to_list(results);
  }
  
}

function filterValue(search, items) {
  if (search === undefined) {
    return items;
  }
  var results = FuzzaldrinPlus.filter($$Array.map((function (param) {
              return {
                      name: param[0],
                      count: param[1]
                    };
            }), $$Array.of_list(items)), search, {
        key: "name"
      });
  if (results.length !== 0) {
    return $$Array.to_list($$Array.map((function (v) {
                      return /* tuple */[
                              v.name,
                              v.count
                            ];
                    }), results));
  }
  
}

var Fuzz = {
  filterAttribute: filterAttribute,
  filterAttributeForDetail: filterAttributeForDetail,
  filterTagList: filterTagList,
  filterOperation: filterOperation,
  filterValue: filterValue
};

function optDefault($$default, maybe) {
  if (maybe !== undefined) {
    return Caml_option.valFromOption(maybe);
  } else {
    return $$default;
  }
}

function noop(param) {
  
}

function identity(x) {
  return x;
}

function logPipe(thing) {
  console.log(thing);
  return thing;
}

function log2Pipe(x, x$prime) {
  console.log(x, x$prime);
  return x$prime;
}

function safeMakeEvent(eventName) {
  if (typeof Event === "function") {
    return new Event(eventName);
  }
  var $$event = document.createEvent("Event");
  $$event.initEvent(eventName, true, true);
  return $$event;
}

function replace(path) {
  var match = typeof history === "undefined" ? undefined : history;
  var match$1 = typeof window === "undefined" ? undefined : window;
  if (match !== undefined && match$1 !== undefined) {
    match.replaceState(null, "", path);
    return ;
  }
  
}

function formatDate(withTimeOpt, withTimezoneOpt, date) {
  var withTime = withTimeOpt !== undefined ? withTimeOpt : true;
  var withTimezone = withTimezoneOpt !== undefined ? withTimezoneOpt : true;
  if (withTime) {
    if (withTimezone) {
      return Curry._2(Printf.sprintf(/* Format */[
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* Char_literal */Block.__(12, [
                              /* " " */32,
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ])
                        ]),
                      "%s %s"
                    ]), L10N_date_time$BsConsole.Format.textAbbreviatedDayTwentyFour(undefined, date), L10N_date_time_clock$BsConsole.Format.getGMTOffset(date));
    } else {
      return L10N_date_time$BsConsole.Format.textAbbreviatedDayTwentyFour(undefined, date);
    }
  } else {
    return L10N_date_time_calendar$BsConsole.Format.textAbbreviatedDay(undefined, date);
  }
}

function formatDateRange(withTimezoneOpt, withTimeOpt, from, to_, param) {
  var withTimezone = withTimezoneOpt !== undefined ? withTimezoneOpt : true;
  var withTime = withTimeOpt !== undefined ? withTimeOpt : true;
  var isSameTZ = from.getTimezoneOffset() === to_.getTimezoneOffset();
  var fromFormatter;
  var exit = 0;
  if (withTimezone && isSameTZ) {
    var partial_arg = false;
    var partial_arg$1 = withTime;
    fromFormatter = (function (param) {
        return formatDate(partial_arg$1, partial_arg, param);
      });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var partial_arg$2 = withTimezone;
    var partial_arg$3 = withTime;
    fromFormatter = (function (param) {
        return formatDate(partial_arg$3, partial_arg$2, param);
      });
  }
  var partial_arg$4 = withTimezone;
  var partial_arg$5 = withTime;
  var toFormatter = function (param) {
    return formatDate(partial_arg$5, partial_arg$4, param);
  };
  return Curry._1(fromFormatter, from) + (" - " + Curry._1(toFormatter, to_));
}

function getUniqueValues(arr) {
  var cmp = Caml_obj.caml_compare;
  var Comparator = Belt_Id.MakeComparable({
        cmp: cmp
      });
  return Belt_Set.toArray(Belt_Set.fromArray(arr, Comparator));
}

function hexToDec(str) {
  return parseInt(str, 16).toString(10);
}

function decToHex(str) {
  return parseInt(str, 10).toString(16);
}

function reportExn(exn) {
  if (exn[0] === Js_exn.$$Error) {
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "util/reportExn(Js.Exn.Error)", Backtrace$BsConsole.Attributes.make(undefined));
    return Backtrace$BsConsole.Client.send(__x, /* `exn */[
                5049499,
                exn[1]
              ]);
  }
  try {
    return Js_exn.raiseError(Printexc.to_string(exn));
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e[0] === Js_exn.$$Error) {
      var __x$1 = Backtrace$BsConsole.Attributes.addString("src_module", "util/reportExn(error)", Backtrace$BsConsole.Attributes.make(undefined));
      return Backtrace$BsConsole.Client.send(__x$1, /* `exn */[
                  5049499,
                  e[1]
                ]);
    }
    throw e;
  }
}

function filterOpMap(operator) {
  switch (operator) {
    case "at-least" :
        return ">=";
    case "at-most" :
        return "<=";
    case "equal" :
        return "=";
    case "greater-than" :
        return ">";
    case "inverse-regular-expression" :
        return "inverse-regex";
    case "less-than" :
        return "<";
    case "not-equal" :
        return "!=";
    case "regular-expression" :
        return "regex";
    default:
      return operator;
  }
}

function openUrl(url) {
  window.open(url, "_blank", "noopener,noreferrer");
  
}

function openUrlInSameTab(url) {
  window.open(url, "_self", "noopener,noreferrer");
  
}

function toPct(occurrences, total) {
  return occurrences / total * 100 | 0;
}

function toFormattedPct(occurrences, total) {
  var value = occurrences / total * 100;
  var value$prime;
  if (value > 1) {
    var match = Pervasives.string_of_float(value).split(".");
    if (match.length !== 2) {
      value$prime = Pervasives.string_of_float(value);
    } else {
      var num = match[0];
      var dec = match[1];
      if (dec === "") {
        value$prime = num;
      } else {
        var n = Caml_format.caml_int_of_string(num);
        var d = n > 0 ? dec.substring(0, 1) : dec.substring(0, 3);
        value$prime = num + ("." + d);
      }
    }
  } else {
    value$prime = "<1.0";
  }
  return value$prime + "%";
}

function nonEmptyStr(s) {
  return s.trim() !== "";
}

var Int = { };

var component = RR$BsConsole.statelessComponent("Util_RenderIf");

function make(isTrue, thenShow, otherwiseOpt, _children) {
  var otherwise = otherwiseOpt !== undefined ? otherwiseOpt : (function (param) {
        return null;
      });
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              if (Curry._1(isTrue, undefined)) {
                return Curry._1(thenShow, undefined);
              } else {
                return Curry._1(otherwise, undefined);
              }
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var RenderIf = {
  component: component,
  make: make
};

function renderIf(isTrue, thenShow, otherwiseOpt, param) {
  var otherwise = otherwiseOpt !== undefined ? otherwiseOpt : (function (param) {
        return null;
      });
  if (Curry._1(isTrue, undefined)) {
    return Curry._1(thenShow, undefined);
  } else {
    return Curry._1(otherwise, undefined);
  }
}

function pluralize(string, occurrences, pluralizerOpt, includeNumberOpt, param) {
  var pluralizer = pluralizerOpt !== undefined ? pluralizerOpt : "s";
  var includeNumber = includeNumberOpt !== undefined ? includeNumberOpt : false;
  return (
          includeNumber ? String(occurrences) : ""
        ) + (" " + (
            occurrences > 1 ? string + pluralizer : string
          ));
}

function tap(fn, x) {
  Curry._1(fn, x);
  return x;
}

var Uuid$1 = { };

function generateUuid(param) {
  return Uuid.v4();
}

var $$Option;

exports.formatBytes = formatBytes;
exports.formatIpv4 = formatIpv4;
exports.stringToHex = stringToHex;
exports.formattedStringify = formattedStringify;
exports.filterBacktraceInternalUsers = filterBacktraceInternalUsers;
exports.shouldFilterBacktraceInternalUser = shouldFilterBacktraceInternalUser;
exports.Qs = Qs$1;
exports.Fuzz = Fuzz;
exports.optDefault = optDefault;
exports.noop = noop;
exports.identity = identity;
exports.logPipe = logPipe;
exports.log2Pipe = log2Pipe;
exports.safeMakeEvent = safeMakeEvent;
exports.replace = replace;
exports.formatDate = formatDate;
exports.formatDateRange = formatDateRange;
exports.getUniqueValues = getUniqueValues;
exports.hexToDec = hexToDec;
exports.decToHex = decToHex;
exports.reportExn = reportExn;
exports.filterOpMap = filterOpMap;
exports.openUrl = openUrl;
exports.openUrlInSameTab = openUrlInSameTab;
exports.toPct = toPct;
exports.toFormattedPct = toFormattedPct;
exports.nonEmptyStr = nonEmptyStr;
exports.Int = Int;
exports.RenderIf = RenderIf;
exports.renderIf = renderIf;
exports.pluralize = pluralize;
exports.tap = tap;
exports.$$Option = $$Option;
exports.Uuid = Uuid$1;
exports.generateUuid = generateUuid;
/* component Not a pure module */
