// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RR$BsConsole = require("./RR.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Route$BsConsole = require("./route.js");
var Shape$BsConsole = require("./bs-crdb-response/src/Shape.js");
var Colors$BsConsole = require("./Colors.js");
var Issues$BsConsole = require("./bs-crdb-response/src/Issues.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Paper = require("@material-ui/core/Paper");
var InboxWorkflow$BsConsole = require("./inbox/InboxWorkflow.js");
var Divider = require("@material-ui/core/Divider");
var Tooltip = require("@material-ui/core/Tooltip");
var AggregationType$BsConsole = require("./bs-crdb-response/src/AggregationType.js");

var defaultColumns = [
  {
    name: "selection",
    title: "Selection",
    width: 69
  },
  {
    name: "state",
    title: "Status",
    width: 118
  },
  {
    name: "fingerprint",
    title: "Fingerprint",
    width: 94
  },
  {
    name: "count",
    title: "Errors",
    width: 90
  },
  {
    name: "activity",
    title: "Activity",
    width: 145
  },
  {
    name: "callstack__head",
    title: "Normalized callstack",
    width: 200
  },
  {
    name: "assignees",
    title: "Assignees",
    width: 120
  },
  {
    name: "tags",
    title: "Tags",
    width: 140
  },
  {
    name: "tickets",
    title: "Tickets",
    width: 120
  },
  {
    name: "add__column",
    title: "add__column",
    width: 200
  }
];

var defaultColumnNames = Belt_Array.map(defaultColumns, (function (c) {
        return c.name;
      }));

function getNonIssueAggName(aggColumns) {
  return Belt_Array.keepMap(aggColumns, (function (column) {
                return Belt_Option.map(column.op, (function (aggregation) {
                              var aggStr = AggregationType$BsConsole.toString(aggregation);
                              var name = column.name + ("__" + aggStr);
                              var title = column.name + (" - " + aggStr);
                              return /* tuple */[
                                      name,
                                      title
                                    ];
                            }));
              }));
}

function findByColName(columns, colName) {
  var arr = colName.split("__");
  var columnName = Belt_Array.getExn(arr, 0);
  var aggregationStr = Belt_Array.getExn(arr, 1);
  var aggregation = AggregationType$BsConsole.fromString(aggregationStr);
  return Belt_Option.getExn(Belt_List.getBy(columns, (function (col) {
                    if (col.name === columnName) {
                      return Caml_obj.caml_equal(col.op, aggregation);
                    } else {
                      return false;
                    }
                  })));
}

function getAggValueByColumnName(group, colName) {
  var match = colName.split("__");
  var match$1;
  if (match.length !== 2) {
    match$1 = /* tuple */[
      "",
      ""
    ];
  } else {
    var n = match[0];
    var a = match[1];
    match$1 = /* tuple */[
      n,
      a
    ];
  }
  var aggString = match$1[1];
  var name = match$1[0];
  return Belt_Array.get(Belt_Array.keep(group.aggregations, (function (agg) {
                    if (Shape$BsConsole.getName(agg) === name) {
                      return Shape$BsConsole.getAggString(agg) === aggString;
                    } else {
                      return false;
                    }
                  })), 0);
}

var component = RR$BsConsole.statelessComponent("TriageUtils-BsConsole__Tooltip");

function make(interactive, enterDelay, leaveDelayOpt, title, placementOpt, classes, children) {
  var leaveDelay = leaveDelayOpt !== undefined ? leaveDelayOpt : 0;
  var placement = placementOpt !== undefined ? placementOpt : "right-end";
  return ReasonReactCompat.wrapReactForReasonReact(Tooltip.default, {
              classes: classes,
              interactive: interactive,
              placement: placement,
              enterDelay: enterDelay,
              children: children,
              leaveDelay: leaveDelay,
              title: title
            }, children);
}

var Tooltip$1 = {
  component: component,
  make: make
};

function TriageUtils$MoreTooltipContainer(Props) {
  var buttonTextOpt = Props.buttonText;
  var refHandler = Props.refHandler;
  var onClick = Props.onClick;
  var onFocus = Props.onFocus;
  var classNameOpt = Props.className;
  var tooltipClassNameOpt = Props.tooltipClassName;
  var tooltipContent = Props.tooltipContent;
  var variantOpt = Props.variant;
  var children = Props.children;
  var buttonText = buttonTextOpt !== undefined ? buttonTextOpt : "";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tooltipClassName = tooltipClassNameOpt !== undefined ? tooltipClassNameOpt : "";
  var variant = variantOpt !== undefined ? variantOpt : /* Default */0;
  var tmp;
  if (variant) {
    var tmp$1 = {
      ref: (function (ref) {
          if (refHandler !== undefined) {
            return Curry._1(refHandler, ref);
          }
          
        }),
      className: Css.merge(/* :: */[
            Css.style(/* :: */[
                  Css.padding2(Css.px(4), Css.px(8)),
                  /* :: */[
                    Css.color(Css.hex(Colors$BsConsole.accent)),
                    /* :: */[
                      Css.fontWeight(/* `num */[
                            5496390,
                            500
                          ]),
                      /* :: */[
                        Css.cursor(/* pointer */-786317123),
                        /* :: */[
                          Css.unsafe("lineHeight", "1.75"),
                          /* :: */[
                            Css.textAlign(/* center */98248149),
                            /* :: */[
                              Css.hover(/* :: */[
                                    Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground)),
                                    /* [] */0
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]),
            /* :: */[
              className,
              /* [] */0
            ]
          ]),
      role: "button"
    };
    if (onFocus !== undefined) {
      tmp$1.onFocus = Caml_option.valFromOption(onFocus);
    }
    if (onClick !== undefined) {
      tmp$1.onClick = Caml_option.valFromOption(onClick);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Divider.default, { }), React.createElement("div", tmp$1, I18N$BsConsole.showSkip(buttonText)));
  } else {
    tmp = null;
  }
  return React.createElement(Tooltip.default, {
              classes: {
                popper: Css.style(/* :: */[
                      Css.opacity(1),
                      /* [] */0
                    ]),
                tooltip: Css.merge(/* :: */[
                      Css.style(/* :: */[
                            Css.padding(Css.zero),
                            /* :: */[
                              Css.background(Css.none),
                              /* :: */[
                                Css.color(Css.hex(Colors$BsConsole.grey1)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      /* :: */[
                        tooltipClassName,
                        /* [] */0
                      ]
                    ])
              },
              interactive: true,
              placement: "right-start",
              enterDelay: 300,
              children: React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.alignItems(/* center */98248149),
                            /* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.height(Css.px(48)),
                                /* :: */[
                                  Css.paddingLeft(Css.px(16)),
                                  /* :: */[
                                    Css.paddingRight(Css.px(16)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ])
                  }, React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.overflow(/* hidden */-862584982),
                              /* :: */[
                                Css.textOverflow(/* ellipsis */166888785),
                                /* :: */[
                                  Css.whiteSpace(/* nowrap */867913355),
                                  /* [] */0
                                ]
                              ]
                            ])
                      }, children)),
              leaveDelay: 300,
              title: React.createElement(Paper.default, {
                    classes: {
                      root: Css.style(/* :: */[
                            Css.fontSize(Css.rem(0.8125)),
                            /* :: */[
                              Css.minHeight(Css.px(48)),
                              /* [] */0
                            ]
                          ])
                    },
                    children: null
                  }, tooltipContent, tmp)
            });
}

function make$1(tooltipClassNameOpt, buttonText, variant, refHandler, onClick, onFocus, tooltipContent, classNameOpt, children) {
  var tooltipClassName = tooltipClassNameOpt !== undefined ? tooltipClassNameOpt : "";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    buttonText: buttonText,
    className: className,
    tooltipClassName: tooltipClassName,
    tooltipContent: tooltipContent,
    variant: variant,
    children: children
  };
  if (refHandler !== undefined) {
    tmp.refHandler = Caml_option.valFromOption(refHandler);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  return ReasonReactCompat.wrapReactForReasonReact(TriageUtils$MoreTooltipContainer, tmp, children);
}

var Re = {
  make: make$1
};

var MoreTooltipContainer = {
  make: TriageUtils$MoreTooltipContainer,
  Re: Re
};

function defaultTriageFold(aperture) {
  return /* :: */[
          Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                "timestamp",
                /* Range */8
              ]),
          /* :: */[
            Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                  "timestamp",
                  /* Bin */Block.__(0, [
                      32,
                      Crdb$BsConsole.ensureValidTimeRange(Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture)))
                    ])
                ]),
            /* :: */[
              Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                    "callstack",
                    /* Head */1
                  ]),
              /* :: */[
                Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      "fingerprint;first_seen",
                      /* Head */1
                    ]),
                /* :: */[
                  Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        Issues$BsConsole.State.columnName,
                        /* Head */1
                      ]),
                  /* :: */[
                    Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                          Issues$BsConsole.Assignees.columnName,
                          /* Head */1
                        ]),
                    /* :: */[
                      Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                            Issues$BsConsole.Tickets.columnName,
                            /* Head */1
                          ]),
                      /* :: */[
                        Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                              Issues$BsConsole.Tags.columnName,
                              /* Head */1
                            ]),
                        /* :: */[
                          Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                Issues$BsConsole.Invariant.columnName,
                                /* Head */1
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ];
}

function triageFold(aperture, fold) {
  return Belt_List.reduce(defaultTriageFold(aperture), fold, (function (acc, curr) {
                return Curry._2(Crdb$BsConsole.Fold.add, curr, acc);
              }));
}

function getStatsFold(stats, aperture, attributes) {
  return Belt_List.reduce(stats, triageFold(aperture, Crdb$BsConsole.Fold.empty), (function (fold, attr) {
                var attrInfo = Belt_List.getBy(attributes, (function (a) {
                        return a.name === attr;
                      }));
                if (attrInfo === undefined) {
                  return fold;
                }
                var match = Belt_List.getBy(attrInfo.aggregate, (function (agg) {
                        return agg === "bin";
                      }));
                if (match !== undefined) {
                  return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                  attr,
                                  /* Bin */Block.__(0, [
                                      32,
                                      undefined
                                    ])
                                ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                      attr,
                                      /* Range */8
                                    ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                          attr,
                                          /* Sum */3
                                        ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                              attr,
                                              /* Mean */7
                                            ]), fold))));
                } else {
                  return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                  attr,
                                  /* Distribution */Block.__(1, [48])
                                ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                      attr,
                                      /* Unique */4
                                    ]), fold));
                }
              }));
}

function makeQuery(stats, aperture, havings, view, sort, attributes, fold) {
  var aperture$1 = view ? InboxWorkflow$BsConsole.Folders.clearAllNestedFilters(aperture) : aperture;
  var fold$1 = view ? Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
              "classifiers",
              /* Head */1
            ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                  "guid",
                  /* Unique */4
                ]), getStatsFold(stats, aperture$1, attributes))) : triageFold(aperture$1, fold);
  return /* Aggregate */Block.__(0, [
            aperture$1,
            /* Identity */0,
            fold$1,
            sort,
            undefined,
            Caml_option.some(havings)
          ]);
}

function removeDefaultFoldAggs(fold) {
  var f = Belt_List.reduce(defaultTriageFold(Crdb$BsConsole.Aperture.$$default), fold, (function (acc, curr) {
          return Curry._2(Crdb$BsConsole.Fold.remove, curr, acc);
        }));
  var timestampAgg = Belt_Array.getBy(Curry._1(Crdb$BsConsole.Fold.toArray, f), (function (agg) {
          if (Curry._1(Crdb$BsConsole.Aggregation.getAttribute, agg) === "timestamp") {
            return Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg)) === "bin";
          } else {
            return false;
          }
        }));
  if (timestampAgg !== undefined) {
    return Curry._2(Crdb$BsConsole.Fold.remove, Caml_option.valFromOption(timestampAgg), f);
  } else {
    return f;
  }
}

function getRouteForTriage(aperture, fold, sort, projectName, havings) {
  var init = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  return /* ProjectTriage */Block.__(11, [
            projectName,
            {
              aperture: aperture,
              sort: sort,
              stats: init.stats,
              havings: havings,
              fold: removeDefaultFoldAggs(fold),
              normBy: init.normBy,
              similarity: init.similarity
            },
            undefined
          ]);
}

exports.defaultColumns = defaultColumns;
exports.defaultColumnNames = defaultColumnNames;
exports.getNonIssueAggName = getNonIssueAggName;
exports.findByColName = findByColName;
exports.getAggValueByColumnName = getAggValueByColumnName;
exports.Tooltip = Tooltip$1;
exports.MoreTooltipContainer = MoreTooltipContainer;
exports.defaultTriageFold = defaultTriageFold;
exports.triageFold = triageFold;
exports.getStatsFold = getStatsFold;
exports.makeQuery = makeQuery;
exports.removeDefaultFoldAggs = removeDefaultFoldAggs;
exports.getRouteForTriage = getRouteForTriage;
/* defaultColumnNames Not a pure module */
