// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var Tab = require("@material-ui/core/Tab");
var Tabs = require("@material-ui/core/Tabs");
var BtTypography$BsConsole = require("../BtTypography.js");
var Edit = require("@material-ui/icons/Edit");
var Button = require("@material-ui/core/Button");
var Tooltip = require("@material-ui/core/Tooltip");
var Delete = require("@material-ui/icons/Delete");
var TeamsMemberTable$BsConsole = require("./TeamsMemberTable.js");
var TeamsAddTeamUsers$BsConsole = require("./TeamsAddTeamUsers.js");
var IconButton = require("@material-ui/core/IconButton");
var ExpansionPanel = require("@material-ui/core/ExpansionPanel");
var TeamsProjectAccessTable$BsConsole = require("./TeamsProjectAccessTable.js");
var KeyboardArrowDown = require("@material-ui/icons/KeyboardArrowDown");
var TeamsAddProjectAccessToTeam$BsConsole = require("./TeamsAddProjectAccessToTeam.js");
var ExpansionPanelDetails = require("@material-ui/core/ExpansionPanelDetails");
var ExpansionPanelSummary = require("@material-ui/core/ExpansionPanelSummary");

function TeamsPanel(Props) {
  var config = Props.config;
  var token = Props.token;
  var team = Props.team;
  var setCurrentTeam = Props.setCurrentTeam;
  var editTeam = Props.editTeam;
  var setDeleteDialog = Props.setDeleteDialog;
  var setDeleteRefetch = Props.setDeleteRefetch;
  var refetchTeams = Props.refetchTeams;
  var teamMembers = Props.teamMembers;
  var refetchTeamMembers = Props.refetchTeamMembers;
  var allUsers = Props.allUsers;
  var projectMemberTeams = Props.projectMemberTeams;
  var refetchProjectMemberTeams = Props.refetchProjectMemberTeams;
  var allProjects = Props.allProjects;
  var match = React.useState((function () {
          return /* Members */0;
        }));
  var setTab = match[1];
  var tab = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setAddMember = match$1[1];
  var match$2 = React.useState((function () {
          return false;
        }));
  var setAddProjectAccess = match$2[1];
  var membersCount = teamMembers.length;
  var isTeamMember = Belt_Option.isSome(Belt_Array.getBy(teamMembers, (function (teamMember) {
              return teamMember.user === config.user.uid;
            })));
  var match$3 = config.user.role;
  var tmp;
  tmp = match$3 === "admin" ? true : false;
  var canSeeTeam = tmp || isTeamMember;
  if (!canSeeTeam) {
    return null;
  }
  var description = team.description;
  return React.createElement("div", {
              key: String(team.id)
            }, React.createElement(ExpansionPanel.default, {
                  className: Css.style(/* :: */[
                        Css.important(Css.marginBottom(Css.px(10))),
                        /* :: */[
                          Css.important(Css.borderRadius(Css.px(0))),
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, React.createElement(ExpansionPanelSummary.default, {
                      className: Css.style(/* :: */[
                            Css.paddingLeft(Css.px(24)),
                            /* :: */[
                              Css.paddingRight(Css.px(0)),
                              /* [] */0
                            ]
                          ]),
                      expandIcon: React.createElement(Tooltip.default, {
                            children: React.createElement("div", {
                                  className: Css.merge(/* :: */[
                                        "smaller-icon",
                                        /* :: */[
                                          Css.style(/* :: */[
                                                Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                /* [] */0
                                              ]),
                                          /* [] */0
                                        ]
                                      ])
                                }, React.createElement(KeyboardArrowDown.default, { })),
                            title: I18N$BsConsole.get(undefined, "Expand")
                          }),
                      children: React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* :: */[
                                    Css.display(/* flex */-1010954439),
                                    /* :: */[
                                      Css.justifyContent(/* spaceBetween */516682146),
                                      /* :: */[
                                        Css.alignItems(/* center */98248149),
                                        /* :: */[
                                          Css.paddingRight(Css.px(10)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ])
                          }, React.createElement("div", {
                                className: Css.merge(/* :: */[
                                      "smaller-icon",
                                      /* :: */[
                                        Css.style(/* :: */[
                                              Css.width(Css.pct(60)),
                                              /* :: */[
                                                Css.whiteSpace(/* nowrap */867913355),
                                                /* :: */[
                                                  Css.overflow(/* hidden */-862584982),
                                                  /* :: */[
                                                    Css.textOverflow(/* ellipsis */166888785),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]),
                                        /* [] */0
                                      ]
                                    ])
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* TableTitle */13,
                                    color: Colors$BsConsole.grey0,
                                    children: I18N$BsConsole.showSkip(team.name)
                                  })), React.createElement("div", {
                                className: Css.merge(/* :: */[
                                      "smaller-icon",
                                      /* :: */[
                                        Css.style(/* :: */[
                                              Css.color(Css.hex(Colors$BsConsole.grey4)),
                                              /* :: */[
                                                Css.display(/* flex */-1010954439),
                                                /* :: */[
                                                  Css.alignItems(/* center */98248149),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]),
                                        /* [] */0
                                      ]
                                    ])
                              }, React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* TableTitle */13,
                                    className: Css.style(/* :: */[
                                          Css.marginRight(Css.px(24)),
                                          /* [] */0
                                        ]),
                                    color: Colors$BsConsole.grey0,
                                    children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                              /* Int */Block.__(4, [
                                                  /* Int_d */0,
                                                  /* No_padding */0,
                                                  /* No_precision */0,
                                                  /* String_literal */Block.__(11, [
                                                      " members",
                                                      /* End_of_format */0
                                                    ])
                                                ]),
                                              "%d members"
                                            ]), membersCount)
                                  }), React.createElement(Tooltip.default, {
                                    children: React.createElement(IconButton.default, {
                                          ariaLabel: "Edit",
                                          classes: {
                                            root: Css.style(/* :: */[
                                                  Css.marginRight(Css.px(0)),
                                                  /* [] */0
                                                ])
                                          },
                                          onClick: (function (e) {
                                              e.stopPropagation();
                                              Curry._1(setCurrentTeam, (function (param) {
                                                      return team;
                                                    }));
                                              return Curry._1(editTeam, undefined);
                                            }),
                                          onFocus: (function (e) {
                                              return e.stopPropagation();
                                            }),
                                          children: React.createElement(Edit.default, { })
                                        }),
                                    title: I18N$BsConsole.get(undefined, "Edit team")
                                  }), React.createElement(Tooltip.default, {
                                    children: React.createElement(IconButton.default, {
                                          ariaLabel: "Delete",
                                          classes: {
                                            root: Css.style(/* :: */[
                                                  Css.marginRight(Css.px(10)),
                                                  /* [] */0
                                                ])
                                          },
                                          onClick: (function (e) {
                                              e.stopPropagation();
                                              Curry._1(setCurrentTeam, (function (param) {
                                                      return team;
                                                    }));
                                              Curry._1(setDeleteRefetch, (function (param) {
                                                      return refetchTeams;
                                                    }));
                                              return Curry._1(setDeleteDialog, (function (param) {
                                                            return /* Team */Block.__(0, [
                                                                      team.id,
                                                                      team.universe,
                                                                      team
                                                                    ]);
                                                          }));
                                            }),
                                          onFocus: (function (e) {
                                              return e.stopPropagation();
                                            }),
                                          children: React.createElement(Delete.default, { })
                                        }),
                                    title: I18N$BsConsole.get(undefined, "Delete team")
                                  })))
                    }), React.createElement(ExpansionPanelDetails.default, {
                      children: React.createElement(Col2$BsConsole.make, {
                            className: Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* [] */0
                                ]),
                            children: null
                          }, description !== undefined ? React.createElement("div", {
                                  className: Css.style(/* :: */[
                                        Css.width(Css.pct(250)),
                                        /* [] */0
                                      ])
                                }, React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Caption */11,
                                      color: Colors$BsConsole.grey0,
                                      children: I18N$BsConsole.showSkip(description)
                                    })) : null, React.createElement(Row2$BsConsole.make, {
                                alignItems: /* center */98248149,
                                justifyContent: /* spaceBetween */516682146,
                                className: Css.style(/* :: */[
                                      Css.width(Css.pct(100)),
                                      /* [] */0
                                    ]),
                                children: null
                              }, React.createElement(Tabs.default, {
                                    value: tab,
                                    classes: {
                                      indicator: Css.style(/* :: */[
                                            Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                                            /* [] */0
                                          ])
                                    },
                                    onChange: (function (_event, tab) {
                                        return Curry._1(setTab, (function (param) {
                                                      return tab;
                                                    }));
                                      }),
                                    children: null
                                  }, React.createElement(Tab.default, {
                                        classes: {
                                          root: Css.style(/* :: */[
                                                Css.textTransform(/* none */-922086728),
                                                /* [] */0
                                              ])
                                        },
                                        value: /* Members */0,
                                        label: I18N$BsConsole.show(undefined, "Members")
                                      }), React.createElement(Tab.default, {
                                        classes: {
                                          root: Css.style(/* :: */[
                                                Css.textTransform(/* none */-922086728),
                                                /* [] */0
                                              ])
                                        },
                                        value: /* ProjectAccess */1,
                                        label: I18N$BsConsole.show(undefined, "Project access")
                                      })), React.createElement("div", {
                                    className: Css.style(/* :: */[
                                          Css.display(/* flex */-1010954439),
                                          /* [] */0
                                        ])
                                  }, React.createElement(Button.default, {
                                        variant: "outlined",
                                        color: "primary",
                                        classes: {
                                          root: Css.style(/* :: */[
                                                Css.marginTop(Css.px(10)),
                                                /* :: */[
                                                  Css.marginBottom(Css.px(10)),
                                                  /* :: */[
                                                    Css.marginRight(Css.px(10)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ])
                                        },
                                        onClick: (function (param) {
                                            return Curry._1(setAddMember, (function (param) {
                                                          return true;
                                                        }));
                                          }),
                                        children: I18N$BsConsole.show(undefined, "Add members")
                                      }), React.createElement(Button.default, {
                                        variant: "outlined",
                                        color: "primary",
                                        classes: {
                                          root: Css.style(/* :: */[
                                                Css.marginTop(Css.px(10)),
                                                /* :: */[
                                                  Css.marginBottom(Css.px(10)),
                                                  /* :: */[
                                                    Css.marginRight(Css.px(10)),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ])
                                        },
                                        onClick: (function (param) {
                                            return Curry._1(setAddProjectAccess, (function (param) {
                                                          return true;
                                                        }));
                                          }),
                                        children: I18N$BsConsole.show(undefined, "Add projects")
                                      }))), React.createElement(Row2$BsConsole.make, {
                                className: Css.style(/* :: */[
                                      Css.width(Css.pct(100)),
                                      /* [] */0
                                    ]),
                                children: tab ? (
                                    projectMemberTeams.length !== 0 ? React.createElement(TeamsProjectAccessTable$BsConsole.make, {
                                            teams: projectMemberTeams,
                                            allProjects: allProjects,
                                            setDeleteDialog: setDeleteDialog,
                                            team: team,
                                            setDeleteRefetch: setDeleteRefetch,
                                            refetchProjectMemberTeams: refetchProjectMemberTeams
                                          }) : React.createElement(TeamsProjectAccessTable$BsConsole.NoProjectMembers.make, { })
                                  ) : (
                                    teamMembers.length !== 0 ? React.createElement(TeamsMemberTable$BsConsole.make, {
                                            token: token,
                                            members: teamMembers,
                                            refetchTeamMembers: refetchTeamMembers,
                                            setDeleteDialog: setDeleteDialog,
                                            team: team,
                                            setDeleteRefetch: setDeleteRefetch,
                                            allUsers: allUsers
                                          }) : React.createElement(TeamsMemberTable$BsConsole.NoMembers.make, { })
                                  )
                              }))
                    })), React.createElement(TeamsAddTeamUsers$BsConsole.make, {
                  config: config,
                  token: token,
                  open: match$1[0],
                  currentTeam: team,
                  onClose: (function (param) {
                      return Curry._1(setAddMember, (function (param) {
                                    return false;
                                  }));
                    }),
                  modalWidth: 250,
                  refetchTeamMembers: refetchTeamMembers,
                  cancelButtonText: I18N$BsConsole.get(undefined, "Cancel"),
                  allUsers: allUsers
                }), React.createElement(TeamsAddProjectAccessToTeam$BsConsole.make, {
                  token: token,
                  openDialog: match$2[0],
                  closeDialogFn: (function (param) {
                      return Curry._1(setAddProjectAccess, (function (param) {
                                    return false;
                                  }));
                    }),
                  team: team,
                  refetchProjectMemberTeams: refetchProjectMemberTeams,
                  allProjects: allProjects
                }));
}

var make = TeamsPanel;

exports.make = make;
/* Css Not a pure module */
