// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");

var component = ReasonReact.statelessComponent("PrTitleRow-BsConsole");

function make(factorName, factorValue, aggregationName, aggregationParameter, onClose, param) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              return ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.grey2)),
                                    /* :: */[
                                      Css.paddingLeft(Css.rem(1)),
                                      /* :: */[
                                        Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                                        /* :: */[
                                          Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey5)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]), [
                              ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [
                                        React.createElement("div", {
                                              className: Css.style(/* :: */[
                                                    Css.color(Css.hex(Colors$BsConsole.grey3)),
                                                    /* :: */[
                                                      Css.fontWeight(/* bold */-1055161979),
                                                      /* :: */[
                                                        Css.marginRight(Css.rem(0.5)),
                                                        /* [] */0
                                                      ]
                                                    ]
                                                  ])
                                            }, I18N$BsConsole.showSkip(factorName)),
                                        React.createElement("div", {
                                              className: Css.style(/* :: */[
                                                    Css.whiteSpace(/* nowrap */867913355),
                                                    /* :: */[
                                                      Css.textOverflow(/* ellipsis */166888785),
                                                      /* :: */[
                                                        Css.overflow(/* hidden */-862584982),
                                                        /* :: */[
                                                          Css.maxWidth(Css.px(250)),
                                                          /* :: */[
                                                            Css.marginRight(Css.rem(2)),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]),
                                              title: factorValue
                                            }, I18N$BsConsole.showSkip(factorValue)),
                                        React.createElement("div", {
                                              className: Css.style(/* :: */[
                                                    Css.color(Css.hex(Colors$BsConsole.grey3)),
                                                    /* :: */[
                                                      Css.fontWeight(/* bold */-1055161979),
                                                      /* :: */[
                                                        Css.marginRight(Css.rem(0.5)),
                                                        /* [] */0
                                                      ]
                                                    ]
                                                  ])
                                            }, I18N$BsConsole.showSkip(aggregationParameter)),
                                        React.createElement("div", undefined, I18N$BsConsole.showSkip(aggregationName))
                                      ])),
                              ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                        Css.style(/* :: */[
                                              Css.color(Css.hex(Colors$BsConsole.grey3)),
                                              /* [] */0
                                            ]),
                                        undefined,
                                        (function (param) {
                                            return Curry._1(onClose, undefined);
                                          }),
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                      ]))
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
/* component Not a pure module */
