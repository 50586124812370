// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var Paper = require("@material-ui/core/Paper");
var Info = require("@material-ui/icons/Info");
var Close = require("@material-ui/icons/Close");
var BtThemeContext$BsConsole = require("./BtThemeContext.js");
var Tooltip = require("@material-ui/core/Tooltip");
var IconButton = require("@material-ui/core/IconButton");

var parentDiv = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.margin(Css.rem(1)),
          /* :: */[
            Css.position(/* relative */903134412),
            /* [] */0
          ]
        ]
      ]
    ]);

function paper(primaryMain) {
  return Css.style(/* :: */[
              Css.borderLeft(Css.px(8), /* solid */12956715, Css.hex(Colors$BsConsole.deoctothorpe(primaryMain))),
              /* :: */[
                Css.padding(Css.rem(0.4)),
                /* :: */[
                  Css.marginBottom(Css.px(20)),
                  /* [] */0
                ]
              ]
            ]);
}

function infoIcon(primaryMain) {
  return Css.style(/* :: */[
              Css.color(Css.hex(Colors$BsConsole.deoctothorpe(primaryMain))),
              /* :: */[
                Css.marginRight(Css.px(15)),
                /* [] */0
              ]
            ]);
}

var h4 = Css.style(/* :: */[
      Css.margin4(Css.px(0), Css.px(0), Css.px(8), Css.px(0)),
      /* :: */[
        Css.fontSize(Css.px(20)),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                500
              ]),
          /* [] */0
        ]
      ]
    ]);

var p = Css.style(/* :: */[
      Css.margin(Css.px(0)),
      /* :: */[
        Css.fontSize(Css.px(16)),
        /* [] */0
      ]
    ]);

var hideWarningIcon = Css.style(/* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.right(Css.px(0)),
        /* :: */[
          Css.top(Css.px(0)),
          /* :: */[
            Css.color(Css.hex(Colors$BsConsole.redDark)),
            /* [] */0
          ]
        ]
      ]
    ]);

var expandButton = Css.style(/* :: */[
      Css.width(Css.px(125)),
      /* :: */[
        Css.marginTop(Css.px(10)),
        /* :: */[
          Css.color(Css.hex(Colors$BsConsole.blue)),
          /* [] */0
        ]
      ]
    ]);

var Style = {
  parentDiv: parentDiv,
  paper: paper,
  infoIcon: infoIcon,
  h4: h4,
  p: p,
  hideWarningIcon: hideWarningIcon,
  expandButton: expandButton
};

function WarningMessage(Props) {
  var classNameOpt = Props.className;
  var errorHeaderOpt = Props.errorHeader;
  var errorMessage = Props.errorMessage;
  var errorElement = Props.errorElement;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var errorHeader = errorHeaderOpt !== undefined ? Caml_option.valFromOption(errorHeaderOpt) : I18N$BsConsole.show(undefined, "Warning: An error has occurred");
  var match = React.useState((function () {
          return false;
        }));
  var setHidden = match[1];
  React.useEffect((function () {
          Curry._1(setHidden, (function (param) {
                  return false;
                }));
          
        }), /* tuple */[
        errorMessage,
        errorElement
      ]);
  var themeContext = Belt_Option.getWithDefault(React.useContext(BtThemeContext$BsConsole.context), /* Backtrace */0);
  var palette = BtThemeContext$BsConsole.getPalette(themeContext);
  if (match[0]) {
    return null;
  } else {
    return React.createElement(Paper.default, {
                classes: {
                  root: Css.merge(/* :: */[
                        paper(palette.error.main),
                        /* :: */[
                          className,
                          /* [] */0
                        ]
                      ])
                },
                children: React.createElement("div", {
                      className: parentDiv
                    }, React.createElement(Tooltip.default, {
                          children: React.createElement(IconButton.default, {
                                ariaLabel: "close warning",
                                className: hideWarningIcon,
                                onClick: (function (param) {
                                    return Curry._1(setHidden, (function (param) {
                                                  return true;
                                                }));
                                  }),
                                children: React.createElement(Close.default, { })
                              }),
                          title: "close warning"
                        }), React.createElement(Info.default, {
                          className: infoIcon(palette.error.main)
                        }), React.createElement("div", undefined, React.createElement("h4", {
                              className: h4
                            }, errorHeader), React.createElement("p", {
                              className: p
                            }, I18N$BsConsole.show(undefined, "Error: "), errorMessage), errorElement !== undefined ? Caml_option.valFromOption(errorElement) : null))
              });
  }
}

var make = WarningMessage;

exports.Style = Style;
exports.make = make;
/* parentDiv Not a pure module */
