// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var DescribeTask$BsConsole = require("./DescribeTask.js");
var MetricsValue$BsConsole = require("./MetricsValue.js");
var InboxWorkflow$BsConsole = require("./inbox/InboxWorkflow.js");
var ProjectTriage$BsConsole = require("./ProjectTriage.js");

function Triage$View(Props) {
  var metricsAttributes = Props.metricsAttributes;
  var handleChangeUrl = Props.handleChangeUrl;
  var route = Props.route;
  var aperture = Props.aperture;
  var projectName = Props.projectName;
  var token = Props.token;
  var attributes = Props.attributes;
  var fold = Props.fold;
  var isDetailView = Props.isDetailView;
  var havings = Props.havings;
  var config = Props.config;
  var sort = Props.sort;
  var stats = Props.stats;
  var handleTask = Props.handleTask;
  var handleAddFilters = Props.handleAddFilters;
  var normBy = Props.normBy;
  var similarity = Props.similarity;
  var stabilityScore = MetricsValue$BsConsole.use(aperture, normBy, token, projectName, metricsAttributes, /* Identity */0);
  return React.createElement(React.Fragment, undefined, React.createElement(ProjectTriage$BsConsole.make, {
                  fold: fold,
                  view: isDetailView ? /* Details */1 : /* List */0,
                  attributes: attributes,
                  havings: havings,
                  aperture: aperture,
                  config: config,
                  sort: sort,
                  stats: stats,
                  token: token,
                  handleTask: handleTask,
                  projectName: projectName,
                  universe: config.universe.name,
                  handleChangeUrl: handleChangeUrl,
                  handleAddFilters: handleAddFilters,
                  route: route,
                  normBy: normBy,
                  similarity: similarity,
                  metricsAttributes: metricsAttributes,
                  stabilityScore: stabilityScore
                }));
}

var View = {
  make: Triage$View
};

function Triage(Props) {
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var route = Props.route;
  var config = Props.config;
  var handleTask = Props.handleTask;
  var projectName = Props.projectName;
  var aperture = Props.aperture;
  var stats = Props.stats;
  var fold = Props.fold;
  var sort = Props.sort;
  var havings = Props.havings;
  var normBy = Props.normBy;
  var similarity = Props.similarity;
  var handleAddFilters = Props.handleAddFilters;
  var match = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var describeRemote = match[0];
  var match$1 = DescribeTask$BsConsole.use(token, projectName, "metrics", undefined);
  var metricsDescribeRemote = match$1[0];
  var metricsAttributes = React.useMemo((function () {
          if (typeof metricsDescribeRemote === "number" || metricsDescribeRemote.tag) {
            return /* [] */0;
          } else {
            return metricsDescribeRemote[0];
          }
        }), [metricsDescribeRemote]);
  var isDetailView = InboxWorkflow$BsConsole.Folders.isDetailView(aperture);
  if (typeof describeRemote === "number" || describeRemote.tag) {
    return null;
  } else {
    return React.createElement(Triage$View, {
                metricsAttributes: metricsAttributes,
                handleChangeUrl: handleChangeUrl,
                route: route,
                aperture: aperture,
                projectName: projectName,
                token: token,
                attributes: describeRemote[0],
                fold: fold,
                isDetailView: isDetailView,
                havings: havings,
                config: config,
                sort: sort,
                stats: stats,
                handleTask: handleTask,
                handleAddFilters: handleAddFilters,
                normBy: normBy,
                similarity: similarity
              });
  }
}

function make(token, handleChangeUrl, route, config, handleTask, projectName, aperture, stats, fold, sort, havings, normBy, similarity, handleAddFilters, children) {
  return ReasonReactCompat.wrapReactForReasonReact(Triage, {
              token: token,
              handleChangeUrl: handleChangeUrl,
              route: route,
              config: config,
              handleTask: handleTask,
              projectName: projectName,
              aperture: aperture,
              stats: stats,
              fold: fold,
              sort: sort,
              havings: havings,
              normBy: normBy,
              similarity: similarity,
              handleAddFilters: handleAddFilters
            }, children);
}

var Re = {
  make: make
};

var make$1 = Triage;

exports.View = View;
exports.make = make$1;
exports.Re = Re;
/* react Not a pure module */
