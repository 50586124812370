// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Fastly$BsConsole = require("../Fastly.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");

var __I18N_PARENT_FOLDER = "/inline-integration-guides";

var __I18N_CDN_NAMESPACE = "i18n";

function nameGet(guide) {
  return guide.name;
}

function byFormat(guideList, format) {
  return Belt_Array.keep(guideList, (function (guide) {
                return guide.format === format;
              }));
}

function byQsName(guideList, qsName) {
  var qsName$1 = qsName.toLowerCase();
  return Belt_Array.keep(guideList, (function (guide) {
                return guide.qsName.toLowerCase() === qsName$1;
              }));
}

function hasQsName(guideList, qsName) {
  return byQsName(guideList, qsName).length !== 0;
}

function getFirstByQsName(guideList, qsName) {
  return Belt_Array.get(byQsName(guideList, qsName), 0);
}

function missingLanguage(defaultLanguage, languageCode) {
  var language = I18N$BsConsole.LanguageCode.toISO639(languageCode);
  var message = "Console Application::IntegrationGuideLanguageMissing::" + (String(language) + "");
  var __x = Backtrace$BsConsole.Attributes.make(undefined);
  var __x$1 = Backtrace$BsConsole.Attributes.addString("i18n.integration-guides.load", "Guides.re", __x);
  var __x$2 = Backtrace$BsConsole.Attributes.addString("i18n.integration-guides.language", language, __x$1);
  var __x$3 = Backtrace$BsConsole.Attributes.addString("i18n.integration-guides.load.failure", "LANGUAGE_MISSING", __x$2);
  Backtrace$BsConsole.Client.send(Backtrace$BsConsole.Attributes.addString("src_module", "Guides/Internal.Report.missingLanguage", __x$3), /* `string */[
        288368849,
        message
      ]);
  return defaultLanguage;
}

function missingFileForLanguage(languageCode, guide, reason) {
  var guideName = guide.qsName;
  var language = I18N$BsConsole.LanguageCode.toISO639(languageCode);
  var message = "Console Application::IntegrationGuideMissing::" + (String(guideName) + ("::" + (String(language) + ("::" + (String(reason) + "")))));
  var __x = Backtrace$BsConsole.Attributes.make(undefined);
  var __x$1 = Backtrace$BsConsole.Attributes.addString("i18n.integration-guides.load", "Guides.re", __x);
  var __x$2 = Backtrace$BsConsole.Attributes.addString("i18n.integration-guides.name", guideName, __x$1);
  var __x$3 = Backtrace$BsConsole.Attributes.addString("i18n.integration-guides.language", language, __x$2);
  var __x$4 = Backtrace$BsConsole.Attributes.addString("i18n.integration-guides.load.failure", "NOT_FOUND", __x$3);
  return Backtrace$BsConsole.Client.send(Backtrace$BsConsole.Attributes.addString("src_module", "Guides/Internal.Report.missingFileForLanguage", __x$4), /* `string */[
              288368849,
              message
            ]);
}

var Report = {
  missingLanguage: missingLanguage,
  missingFileForLanguage: missingFileForLanguage
};

var Internal = {
  Report: Report
};

function languageFolder(languageCode) {
  if (languageCode !== 1) {
    if (languageCode !== 0) {
      return missingLanguage("en_US", languageCode);
    } else {
      return "en_US";
    }
  } else {
    return "ja_JP";
  }
}

function loadFromFileSystem(guide, languageCode) {
  var __x = guide.markdown;
  return Belt_Option.map(Caml_option.undefined_to_opt(__x.find((function (param) {
                        return param[0] === languageFolder(languageCode);
                      }))), (function (param) {
                return param[1];
              }));
}

function loadFromCDN(guide, languageCode) {
  var getResponseText = function (res) {
    if (res.ok) {
      var __x = res.text();
      return __x.then((function (r) {
                    return Promise.resolve(r);
                  }));
    }
    missingFileForLanguage(languageCode, guide, res.statusText);
    return Promise.resolve(undefined);
  };
  var __x = fetch(Fastly$BsConsole.getResourceUrl(__I18N_CDN_NAMESPACE, [
              __I18N_PARENT_FOLDER,
              languageFolder(languageCode),
              guide.fileName
            ].join("/")));
  return __x.then(getResponseText);
}

var Markdown = {
  languageFolder: languageFolder,
  loadFromFileSystem: loadFromFileSystem,
  loadFromCDN: loadFromCDN
};

function loadMarkdown(languageCode, guide) {
  var loadFromFileSystemIfEmpty = function (maybe, languageCode) {
    if (maybe !== undefined) {
      return maybe;
    } else {
      return loadFromFileSystem(guide, languageCode);
    }
  };
  var loadFromFileSystemIfEmpty$1 = function (promise, languageCode) {
    return promise.then((function (maybe) {
                  return Promise.resolve(loadFromFileSystemIfEmpty(maybe, languageCode));
                }));
  };
  var promise = loadFromFileSystemIfEmpty$1(loadFromFileSystemIfEmpty$1(loadFromCDN(guide, languageCode), languageCode), /* English */0);
  var errorMessage = Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
            /* String_literal */Block.__(11, [
                "Guide Not Found (404): ",
                /* String */Block.__(2, [
                    /* No_padding */0,
                    /* End_of_format */0
                  ])
              ]),
            "Guide Not Found (404): %s"
          ]), guide.fileName);
  return promise.then((function (maybe) {
                if (maybe !== undefined) {
                  return Promise.resolve(maybe);
                } else {
                  missingFileForLanguage(languageCode, guide, errorMessage);
                  return Promise.resolve(errorMessage);
                }
              }));
}

var Fetch;

exports.Fetch = Fetch;
exports.__I18N_PARENT_FOLDER = __I18N_PARENT_FOLDER;
exports.__I18N_CDN_NAMESPACE = __I18N_CDN_NAMESPACE;
exports.nameGet = nameGet;
exports.byFormat = byFormat;
exports.byQsName = byQsName;
exports.hasQsName = hasQsName;
exports.getFirstByQsName = getFirstByQsName;
exports.Internal = Internal;
exports.Markdown = Markdown;
exports.loadMarkdown = loadMarkdown;
/* I18N-BsConsole Not a pure module */
