// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Route$BsConsole = require("../route.js");
var Colors$BsConsole = require("../Colors.js");
var BtTableRow$BsConsole = require("../BtTableRow.js");
var BtTableCell$BsConsole = require("../BtTableCell.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var Divider = require("@material-ui/core/Divider");
var Tooltip = require("@material-ui/core/Tooltip");
var Delete = require("@material-ui/icons/Delete");
var AlertsParseQuery$BsConsole = require("../alerts/AlertsParseQuery.js");
var IconButton = require("@material-ui/core/IconButton");
var OpenInNew = require("@material-ui/icons/OpenInNew");
var AlertsQueryDisplay$BsConsole = require("../alerts/AlertsQueryDisplay.js");

var chipLabel = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.black)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
        /* :: */[
          Css.fontSize(/* `px */[
                25096,
                12
              ]),
          /* :: */[
            Css.whiteSpace(/* nowrap */867913355),
            /* :: */[
              Css.overflow(/* hidden */-862584982),
              /* :: */[
                Css.textOverflow(/* ellipsis */166888785),
                /* :: */[
                  Css.borderRadius(Css.px(3)),
                  /* :: */[
                    Css.maxWidth(Css.px(150)),
                    /* :: */[
                      Css.height(Css.px(24)),
                      /* :: */[
                        Css.marginRight(Css.px(3)),
                        /* :: */[
                          Css.marginTop(Css.px(3)),
                          /* :: */[
                            Css.padding(Css.px(5)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function optionalTooltip(title, length) {
  if (title.length > length) {
    return title;
  } else {
    return "";
  }
}

function queryFromJson(json) {
  return {
          fold: Route$BsConsole.withDefaultOnException(Crdb$BsConsole.Fold.empty, AlertsParseQuery$BsConsole.parseFold, json),
          factor: Json_decode.optional((function (param) {
                  return Json_decode.field("group", AlertsParseQuery$BsConsole.factorFromJson, param);
                }), json),
          filter: Route$BsConsole.withDefaultOnException(Crdb$BsConsole.Filters.empty, AlertsParseQuery$BsConsole.filtersFromJson, json)
        };
}

function parseQuery(m) {
  try {
    var q = Json.parse(m.query);
    if (q !== undefined) {
      return queryFromJson(Caml_option.valFromOption(q));
    } else {
      return ;
    }
  }
  catch (exn){
    return ;
  }
}

function ExporterMetricRow(Props) {
  var m = Props.m;
  var setMetricDeleteModal = Props.setMetricDeleteModal;
  var projectName = Props.projectName;
  var queryFormatted;
  try {
    queryFormatted = JSON.stringify(JSON.parse(m.query), null, 2);
  }
  catch (exn){
    queryFormatted = I18N$BsConsole.get(undefined, "Error parsing query");
  }
  var parsedQuery = parseQuery(m);
  if (parsedQuery === undefined) {
    return React.createElement(BtTableRow$BsConsole.make, {
                children: null
              }, React.createElement(BtTableCell$BsConsole.make, {
                    children: React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Caption */11,
                          className: Css.style(/* :: */[
                                Css.color(Css.hex(Colors$BsConsole.grey4)),
                                /* [] */0
                              ]),
                          children: I18N$BsConsole.showSkip(m.name)
                        })
                  }), React.createElement(BtTableCell$BsConsole.make, {
                    children: React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body2 */9,
                          children: I18N$BsConsole.show(undefined, "Error parsing metric query")
                        })
                  }), React.createElement(BtTableCell$BsConsole.make, {
                    className: Css.style(/* :: */[
                          Css.important(Css.padding(/* zero */-789508312)),
                          /* [] */0
                        ]),
                    children: React.createElement(IconButton.default, {
                          onClick: (function (param) {
                              return Curry._1(setMetricDeleteModal, (function (param) {
                                            return m;
                                          }));
                            }),
                          children: React.createElement(Delete.default, { })
                        })
                  }));
  }
  var f = parsedQuery.factor;
  var groupBy = f !== undefined ? AlertsQueryDisplay$BsConsole.getFactorString(f) : undefined;
  var tmp;
  if (groupBy !== undefined) {
    var groupByStr = I18N$BsConsole.get(undefined, "group by") + (" \"" + (groupBy + "\""));
    tmp = React.createElement(Tooltip.default, {
          children: React.createElement("div", {
                key: groupByStr,
                className: Css.merge(/* :: */[
                      chipLabel,
                      /* :: */[
                        Css.style(/* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.blueBackground)),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ])
              }, I18N$BsConsole.showSkip(groupByStr)),
          title: optionalTooltip(groupByStr, 24)
        });
  } else {
    tmp = Curry._1(Crdb$BsConsole.Fold.toArray, parsedQuery.fold).length === 0 ? React.createElement("div", undefined, React.createElement(BtTypography$BsConsole.make, {
                variant: /* Body2 */9,
                children: I18N$BsConsole.show(undefined, "Empty fold")
              })) : null;
  }
  return React.createElement(BtTableRow$BsConsole.make, {
              children: null
            }, React.createElement(BtTableCell$BsConsole.make, {
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Caption */11,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.grey4)),
                              /* [] */0
                            ]),
                        children: I18N$BsConsole.showSkip(m.name)
                      })
                }), React.createElement(BtTableCell$BsConsole.make, {
                  children: React.createElement(Tooltip.default, {
                        classes: {
                          popper: Css.style(/* :: */[
                                Css.opacity(1),
                                /* [] */0
                              ]),
                          tooltip: Css.style(/* :: */[
                                Css.width(Css.pct(100)),
                                /* :: */[
                                  Css.padding(Css.zero),
                                  /* :: */[
                                    Css.background(Css.none),
                                    /* :: */[
                                      Css.maxWidth(Css.vw(40)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ])
                        },
                        interactive: true,
                        placement: "right-end",
                        enterDelay: 300,
                        children: React.createElement("div", undefined, React.createElement(Row2$BsConsole.make, {
                                  flexWrap: /* wrap */-822134326,
                                  className: Css.style(/* :: */[
                                        Css.marginTop(Css.px(3)),
                                        /* :: */[
                                          Css.marginBottom(Css.px(3)),
                                          /* [] */0
                                        ]
                                      ]),
                                  children: null
                                }, tmp, Belt_Array.map(Curry._1(Crdb$BsConsole.Fold.toArray, parsedQuery.fold), (function (agg) {
                                        var match = Curry._1(Crdb$BsConsole.Aggregation.getId, agg);
                                        var attr = "\"" + (match[0] + ("\" - " + match[1]));
                                        return React.createElement(Tooltip.default, {
                                                    children: React.createElement("div", {
                                                          key: attr,
                                                          className: chipLabel
                                                        }, I18N$BsConsole.showSkip(attr)),
                                                    title: optionalTooltip(attr, 24)
                                                  });
                                      })), Belt_Array.map(Curry._1(Crdb$BsConsole.Filters.toArray, parsedQuery.filter), (function (filter) {
                                        var filterAttribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
                                        var filterOperation = Curry._1(Crdb$BsConsole.Filter.getOperation, filter);
                                        var filterValue = Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(filterOperation)));
                                        var filterString = I18N$BsConsole.get(undefined, "filtered to") + ("\"" + (filterAttribute + ("\" " + (Util$BsConsole.filterOpMap(Crdb$BsConsole.FilterOperation.toString(filterOperation)) + (" " + filterValue)))));
                                        return React.createElement(Tooltip.default, {
                                                    children: React.createElement("div", {
                                                          key: filterAttribute,
                                                          className: Css.merge(/* :: */[
                                                                chipLabel,
                                                                /* :: */[
                                                                  Css.style(/* :: */[
                                                                        Css.maxWidth(Css.px(250)),
                                                                        /* :: */[
                                                                          Css.backgroundColor(Css.hex(Colors$BsConsole.greenExtraLight)),
                                                                          /* [] */0
                                                                        ]
                                                                      ]),
                                                                  /* [] */0
                                                                ]
                                                              ])
                                                        }, I18N$BsConsole.showSkip(filterString)),
                                                    title: optionalTooltip(filterString, 87)
                                                  });
                                      })))),
                        leaveDelay: 300,
                        title: React.createElement(Paper.default, {
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.padding(Css.px(10)),
                                      /* [] */0
                                    ])
                              },
                              children: null
                            }, React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Subtitle2 */7,
                                  className: Css.style(/* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.grey0)),
                                        /* [] */0
                                      ]),
                                  children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Query for metric ") + m.name)
                                }), React.createElement(Divider.default, { }), React.createElement("div", undefined, React.createElement("pre", {
                                      className: Css.style(/* :: */[
                                            Css.whiteSpace(/* preWrap */660870029),
                                            /* :: */[
                                              Css.maxHeight(Css.px(200)),
                                              /* :: */[
                                                Css.overflowY(Css.auto),
                                                /* :: */[
                                                  Css.fontSize(Css.px(12)),
                                                  /* :: */[
                                                    Css.fontFamily("Inconsolata"),
                                                    /* :: */[
                                                      Css.color(Css.hex(Colors$BsConsole.grey0)),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ])
                                    }, I18N$BsConsole.showSkip(queryFormatted))))
                      })
                }), React.createElement(BtTableCell$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.important(Css.padding(/* zero */-789508312)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(Tooltip.default, {
                      children: React.createElement(IconButton.default, {
                            onClick: (function (param) {
                                return Curry._1(setMetricDeleteModal, (function (param) {
                                              return m;
                                            }));
                              }),
                            children: React.createElement(Delete.default, { })
                          }),
                      title: I18N$BsConsole.get(undefined, "Delete metric")
                    }), React.createElement(Tooltip.default, {
                      children: React.createElement(IconButton.default, {
                            onClick: (function (param) {
                                var f = parsedQuery.factor;
                                var factorForPath = f !== undefined ? (
                                    f ? /* Custom */[f[0]] : /* Custom */["fingerprint"]
                                  ) : /* Custom */["NONE"];
                                var __x = Curry._2(Crdb$BsConsole.Aperture.setFilters, parsedQuery.filter, Crdb$BsConsole.Aperture.$$default);
                                return Util$BsConsole.openUrl(Route$BsConsole.pathForRoute(Route$BsConsole.ProjectQueryBuilder.withDefaults(projectName, Caml_option.some(Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                                                                  /* Floating */0,
                                                                  /* Week */2
                                                                ])), __x)), undefined, factorForPath, Caml_option.some(parsedQuery.fold), undefined, undefined, undefined, undefined)));
                              }),
                            children: React.createElement(OpenInNew.default, { })
                          }),
                      title: I18N$BsConsole.get(undefined, "View metric query in explore")
                    })));
}

var chipLength = 24;

var make = ExporterMetricRow;

exports.chipLabel = chipLabel;
exports.chipLength = chipLength;
exports.optionalTooltip = optionalTooltip;
exports.queryFromJson = queryFromJson;
exports.parseQuery = parseQuery;
exports.make = make;
/* chipLabel Not a pure module */
