// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Row2$BsConsole = require("./components/Row2.js");
var Route$BsConsole = require("./route.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Services$BsConsole = require("./Services.js");
var Tab = require("@material-ui/core/Tab");
var Tabs = require("@material-ui/core/Tabs");
var Toolbar = require("@material-ui/core/Toolbar");

var indicator = Css.style(/* :: */[
      Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.green))),
      /* [] */0
    ]);

var root = Css.style(/* :: */[
      Css.minWidth(Css.px(110)),
      /* [] */0
    ]);

var linkWithBorder = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.marginRight(Css.px(17)),
        /* :: */[
          Css.after(/* :: */[
                Css.unsafe("content", "''"),
                /* :: */[
                  Css.position(/* absolute */-1013592457),
                  /* :: */[
                    Css.width(Css.px(1)),
                    /* :: */[
                      Css.right(Css.px(-8)),
                      /* :: */[
                        Css.top(Css.px(6)),
                        /* :: */[
                          Css.bottom(Css.px(6)),
                          /* :: */[
                            Css.backgroundColor(Css.hex(Colors$BsConsole.grey675)),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var toolbar = Css.style(/* :: */[
      Css.minHeight(Css.px(50)),
      /* :: */[
        Css.justifyContent(/* spaceBetween */516682146),
        /* :: */[
          Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
          /* :: */[
            Css.justifyContent(/* spaceBetween */516682146),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles = {
  indicator: indicator,
  root: root,
  linkWithBorder: linkWithBorder,
  toolbar: toolbar
};

function ViewNav$NavLink(Props) {
  var route = Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return ReasonReact.element(undefined, undefined, Link$BsConsole.make(route, undefined, handleChangeUrl, undefined, undefined, className, [children]));
}

var NavLink = {
  make: ViewNav$NavLink
};

function getHavingsFromRoute(route) {
  if (typeof route === "number") {
    return Crdb$BsConsole.Havings.empty;
  }
  switch (route.tag | 0) {
    case /* ProjectTriage */11 :
        if (route[2] !== undefined) {
          return Crdb$BsConsole.Havings.empty;
        } else {
          return route[1].havings;
        }
    case /* ProjectQueryBuilder */13 :
        return route[1].havings;
    default:
      return Crdb$BsConsole.Havings.empty;
  }
}

function getNextOverviewRoute(projectName, aperture, _route) {
  return /* ProjectOverview */Block.__(8, [
            projectName,
            {
              aperture: aperture
            }
          ]);
}

function getNextCompareReleasesRoute(projectName, aperture, _route) {
  return /* ProjectCompareReleases */Block.__(9, [
            projectName,
            undefined,
            {
              aperture: aperture,
              groups: undefined
            }
          ]);
}

function getNextTriageRoute(projectName, aperture, route) {
  var havings = getHavingsFromRoute(route);
  var init = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  return /* ProjectTriage */Block.__(11, [
            projectName,
            {
              aperture: aperture,
              sort: init.sort,
              stats: init.stats,
              havings: havings,
              fold: init.fold,
              normBy: init.normBy,
              similarity: init.similarity
            },
            undefined
          ]);
}

function getNextExploreRoute(projectName, aperture, route) {
  var havings = getHavingsFromRoute(route);
  var init = Route$BsConsole.getQueryBuilderParams(undefined, undefined, projectName, undefined, undefined, undefined, undefined, undefined, undefined);
  return /* ProjectQueryBuilder */Block.__(13, [
            projectName,
            {
              aperture: aperture,
              factor: init.factor,
              havings: havings,
              fold: init.fold,
              sort: init.sort,
              normBy: init.normBy,
              highlightExporter: init.highlightExporter
            }
          ]);
}

function getNextDebugRoute(projectName, aperture, _route) {
  return /* ProjectDebugger2 */Block.__(19, [
            projectName,
            {
              aperture: aperture,
              state: undefined
            }
          ]);
}

function getTabValueFromRoute(route) {
  if (typeof route === "number") {
    return /* Triage */2;
  }
  switch (route.tag | 0) {
    case /* ProjectOverview */8 :
        return /* Overview */0;
    case /* ProjectCompareReleases */9 :
        return /* Releases */1;
    case /* ProjectTriage */11 :
        return /* Triage */2;
    case /* ProjectDebugger2 */19 :
        return /* Debug */4;
    case /* ProjectQueryBuilder */13 :
    case /* ProjectInstances */15 :
    case /* ProjectFlamegraph */20 :
    case /* ProjectSimilarity */21 :
        return /* Explore */3;
    default:
      return /* Triage */2;
  }
}

function ViewNav$WithOverviewAndReleases(Props) {
  var aperture = Props.aperture;
  var route = Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var classNameOpt = Props.className;
  var childrenOpt = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var func = function (param, param$1) {
    return /* ProjectOverview */Block.__(8, [
              projectName,
              {
                aperture: param
              }
            ]);
  };
  var func$1 = function (param, param$1) {
    return getNextCompareReleasesRoute(projectName, param, param$1);
  };
  var func$2 = function (param, param$1) {
    return getNextTriageRoute(projectName, param, param$1);
  };
  var func$3 = function (param, param$1) {
    return getNextExploreRoute(projectName, param, param$1);
  };
  var func$4 = function (param, param$1) {
    return getNextDebugRoute(projectName, param, param$1);
  };
  return React.createElement(Toolbar.default, {
              disableGutters: true,
              className: Css.merge(/* :: */[
                    toolbar,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ]),
              children: React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    justifyContent: /* spaceBetween */516682146,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(Tabs.default, {
                        value: getTabValueFromRoute(route),
                        classes: {
                          indicator: indicator,
                          root: root
                        },
                        onChange: (function (param, param$1) {
                            
                          }),
                        children: null
                      }, React.createElement(ViewNav$NavLink, {
                            route: Curry._2(func, aperture, route),
                            handleChangeUrl: handleChangeUrl,
                            children: React.createElement(Tab.default, {
                                  classes: {
                                    root: Css.merge(/* :: */[
                                          root,
                                          /* :: */[
                                            "walkthrough--mode_tab--overview",
                                            /* [] */0
                                          ]
                                        ])
                                  },
                                  label: I18N$BsConsole.show(undefined, "Overview")
                                })
                          }), React.createElement(ViewNav$NavLink, {
                            route: Curry._2(func$1, aperture, route),
                            handleChangeUrl: handleChangeUrl,
                            className: linkWithBorder,
                            children: React.createElement(Tab.default, {
                                  classes: {
                                    root: Css.merge(/* :: */[
                                          root,
                                          /* :: */[
                                            "walkthrough--mode_tab--overview",
                                            /* [] */0
                                          ]
                                        ])
                                  },
                                  label: I18N$BsConsole.show(undefined, "Releases")
                                })
                          }), React.createElement(ViewNav$NavLink, {
                            route: Curry._2(func$2, aperture, route),
                            handleChangeUrl: handleChangeUrl,
                            children: React.createElement(Tab.default, {
                                  classes: {
                                    root: Css.merge(/* :: */[
                                          root,
                                          /* :: */[
                                            "walkthrough--mode_tab--triage",
                                            /* [] */0
                                          ]
                                        ])
                                  },
                                  label: I18N$BsConsole.show(undefined, "Triage")
                                })
                          }), React.createElement(ViewNav$NavLink, {
                            route: Curry._2(func$3, aperture, route),
                            handleChangeUrl: handleChangeUrl,
                            children: React.createElement(Tab.default, {
                                  classes: {
                                    root: Css.merge(/* :: */[
                                          root,
                                          /* :: */[
                                            "walkthrough--mode_tab--explore",
                                            /* [] */0
                                          ]
                                        ])
                                  },
                                  label: I18N$BsConsole.show(undefined, "Explore")
                                })
                          }), React.createElement(ViewNav$NavLink, {
                            route: Curry._2(func$4, aperture, route),
                            handleChangeUrl: handleChangeUrl,
                            children: React.createElement(Tab.default, {
                                  classes: {
                                    root: Css.merge(/* :: */[
                                          root,
                                          /* :: */[
                                            "walkthrough--mode_tab--debug",
                                            /* [] */0
                                          ]
                                        ])
                                  },
                                  label: I18N$BsConsole.show(undefined, "Debug")
                                })
                          })), children)
            });
}

var WithOverviewAndReleases = {
  getHavingsFromRoute: getHavingsFromRoute,
  getNextOverviewRoute: getNextOverviewRoute,
  getNextCompareReleasesRoute: getNextCompareReleasesRoute,
  getNextTriageRoute: getNextTriageRoute,
  getNextExploreRoute: getNextExploreRoute,
  getNextDebugRoute: getNextDebugRoute,
  getTabValueFromRoute: getTabValueFromRoute,
  make: ViewNav$WithOverviewAndReleases
};

function ViewNav$WithoutOverview(Props) {
  var aperture = Props.aperture;
  var route = Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var classNameOpt = Props.className;
  var childrenOpt = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var value;
  if (typeof route === "number") {
    value = /* Triage */0;
  } else {
    switch (route.tag | 0) {
      case /* ProjectTriage */11 :
          value = /* Triage */0;
          break;
      case /* ProjectDebugger2 */19 :
          value = /* Debug */2;
          break;
      case /* ProjectQueryBuilder */13 :
      case /* ProjectInstances */15 :
      case /* ProjectFlamegraph */20 :
      case /* ProjectSimilarity */21 :
          value = /* Explore */1;
          break;
      default:
        value = /* Triage */0;
    }
  }
  var havings;
  if (typeof route === "number") {
    havings = Crdb$BsConsole.Havings.empty;
  } else {
    switch (route.tag | 0) {
      case /* ProjectTriage */11 :
          havings = route[2] !== undefined ? Crdb$BsConsole.Havings.empty : route[1].havings;
          break;
      case /* ProjectQueryBuilder */13 :
          havings = route[1].havings;
          break;
      default:
        havings = Crdb$BsConsole.Havings.empty;
    }
  }
  var init = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var init$1 = Route$BsConsole.getQueryBuilderParams(undefined, undefined, projectName, undefined, undefined, undefined, undefined, undefined, undefined);
  return React.createElement(Toolbar.default, {
              disableGutters: true,
              className: Css.merge(/* :: */[
                    toolbar,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ]),
              children: React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    justifyContent: /* spaceBetween */516682146,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(Tabs.default, {
                        value: value,
                        classes: {
                          indicator: indicator,
                          root: root
                        },
                        onChange: (function (param, param$1) {
                            
                          }),
                        children: null
                      }, React.createElement(ViewNav$NavLink, {
                            route: /* ProjectTriage */Block.__(11, [
                                projectName,
                                {
                                  aperture: aperture,
                                  sort: Crdb$BsConsole.Sort.defaultAggregate,
                                  stats: init.stats,
                                  havings: havings,
                                  fold: init.fold,
                                  normBy: init.normBy,
                                  similarity: init.similarity
                                },
                                undefined
                              ]),
                            handleChangeUrl: handleChangeUrl,
                            children: React.createElement(Tab.default, {
                                  classes: {
                                    root: Css.merge(/* :: */[
                                          root,
                                          /* :: */[
                                            "walkthrough--mode_tab--triage",
                                            /* [] */0
                                          ]
                                        ])
                                  },
                                  label: I18N$BsConsole.show(undefined, "Triage")
                                })
                          }), React.createElement(ViewNav$NavLink, {
                            route: /* ProjectQueryBuilder */Block.__(13, [
                                projectName,
                                {
                                  aperture: aperture,
                                  factor: init$1.factor,
                                  havings: havings,
                                  fold: init$1.fold,
                                  sort: init$1.sort,
                                  normBy: init$1.normBy,
                                  highlightExporter: init$1.highlightExporter
                                }
                              ]),
                            handleChangeUrl: handleChangeUrl,
                            children: React.createElement(Tab.default, {
                                  classes: {
                                    root: Css.merge(/* :: */[
                                          root,
                                          /* :: */[
                                            "walkthrough--mode_tab--explore",
                                            /* [] */0
                                          ]
                                        ])
                                  },
                                  label: I18N$BsConsole.show(undefined, "Explore")
                                })
                          }), React.createElement(ViewNav$NavLink, {
                            route: /* ProjectDebugger2 */Block.__(19, [
                                projectName,
                                {
                                  aperture: aperture,
                                  state: undefined
                                }
                              ]),
                            handleChangeUrl: handleChangeUrl,
                            children: React.createElement(Tab.default, {
                                  classes: {
                                    root: Css.merge(/* :: */[
                                          root,
                                          /* :: */[
                                            "walkthrough--mode_tab--debug",
                                            /* [] */0
                                          ]
                                        ])
                                  },
                                  label: I18N$BsConsole.show(undefined, "Debug")
                                })
                          })), children)
            });
}

var WithoutOverview = {
  make: ViewNav$WithoutOverview
};

function ViewNav$Default(Props) {
  var config = Props.config;
  var aperture = Props.aperture;
  var route = Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var classNameOpt = Props.className;
  var childrenOpt = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var overviewEndpoint = Services$BsConsole.getEndpoint(undefined)(config.services, "overview");
  if (Belt_Option.isSome(overviewEndpoint)) {
    return React.createElement(ViewNav$WithOverviewAndReleases, {
                aperture: aperture,
                route: route,
                handleChangeUrl: handleChangeUrl,
                projectName: projectName,
                className: className,
                children: children
              });
  } else {
    return React.createElement(ViewNav$WithoutOverview, {
                aperture: aperture,
                route: route,
                handleChangeUrl: handleChangeUrl,
                projectName: projectName,
                className: className,
                children: children
              });
  }
}

var Default = {
  make: ViewNav$Default
};

function make(config, aperture, handleChangeUrl, route, projectName, classNameOpt, children) {
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return ReasonReactCompat.wrapReactForReasonReact(ViewNav$Default, {
              config: config,
              aperture: aperture,
              route: route,
              handleChangeUrl: handleChangeUrl,
              projectName: projectName,
              className: className,
              children: children
            }, children);
}

var Re = {
  make: make
};

var make$1 = ViewNav$Default;

exports.Styles = Styles;
exports.NavLink = NavLink;
exports.WithOverviewAndReleases = WithOverviewAndReleases;
exports.WithoutOverview = WithoutOverview;
exports.Default = Default;
exports.Re = Re;
exports.make = make$1;
/* indicator Not a pure module */
