// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Href$BsConsole = require("../../Href.js");
var I18N$BsConsole = require("../../I18N.js");
var Util$BsConsole = require("../../util.js");
var Route$BsConsole = require("../../route.js");
var Colors$BsConsole = require("../../Colors.js");
var Upload$BsConsole = require("../../Upload.js");
var Request$BsConsole = require("../../Request.js");
var DropZone$BsConsole = require("../../DropZone.js");
var MuiIcons$BsConsole = require("../../MuiIcons.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var SymbolTable$BsConsole = require("./SymbolTable.js");
var FetchSymbols$BsConsole = require("../../FetchSymbols.js");
var Configuration$BsConsole = require("../../configuration.js");
var BlockNavigation$BsConsole = require("../../BlockNavigation.js");

var title = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.paddingBottom(/* zero */-789508312),
        /* [] */0
      ]
    ]);

var dropZone = Css.style(/* :: */[
      Css.outline(Css.px(1), /* dashed */568403505, Css.hex(Colors$BsConsole.grey5)),
      /* :: */[
        Css.width(Css.pct(50)),
        /* :: */[
          Css.flexGrow(1),
          /* [] */0
        ]
      ]
    ]);

var dropZoneText = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey5)),
      /* :: */[
        Css.padding2(Css.em(1), /* zero */-789508312),
        /* [] */0
      ]
    ]);

var dropZoneIcon = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey5)),
      /* :: */[
        Css.alignSelf(/* center */98248149),
        /* [] */0
      ]
    ]);

var fileListContainer = Css.style(/* :: */[
      Css.flexGrow(1),
      /* :: */[
        Css.width(Css.pct(50)),
        /* :: */[
          Css.paddingLeft(Css.em(1)),
          /* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.flexDirection(/* column */-963948842),
              /* :: */[
                Css.justifyContent(/* spaceBetween */516682146),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var fileName = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
      /* :: */[
        Css.height(Css.px(48)),
        /* :: */[
          Css.whiteSpace(/* nowrap */867913355),
          /* :: */[
            Css.marginBottom(Css.px(2)),
            /* [] */0
          ]
        ]
      ]
    ]);

var fileText = Css.style(/* :: */[
      Css.fontFamily("Inconsolata;monospace"),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.textOverflow(/* ellipsis */166888785),
          /* :: */[
            Css.whiteSpace(/* nowrap */867913355),
            /* [] */0
          ]
        ]
      ]
    ]);

var fileList = Css.style(/* :: */[
      Css.padding(/* zero */-789508312),
      /* :: */[
        Css.height(Css.px(210)),
        /* :: */[
          Css.overflow(/* auto */-1065951377),
          /* [] */0
        ]
      ]
    ]);

var deleteFile = Css.style(/* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.cursor(/* pointer */-786317123),
        /* [] */0
      ]
    ]);

var Styles = {
  title: title,
  dropZone: dropZone,
  dropZoneText: dropZoneText,
  dropZoneIcon: dropZoneIcon,
  fileListContainer: fileListContainer,
  fileName: fileName,
  fileText: fileText,
  fileList: fileList,
  deleteFile: deleteFile
};

var component = ReasonReact.statelessComponent("PS_SymbolsUpload-BsConsole__ChooseFiles");

function make(handleAddFiles, onDeleteClick, files, handleNextStep, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Add symbol files")])),
                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, undefined, [
                                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(dropZone, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, DropZone$BsConsole.make(handleAddFiles, ".zip, .tar, .pdb, .sym", {
                                                                      display: "flex",
                                                                      minHeight: "150px",
                                                                      minWidth: "350px",
                                                                      padding: "3em",
                                                                      alignContent: "center",
                                                                      flexDirection: "column",
                                                                      justifyContent: "center"
                                                                    }, [
                                                                      React.createElement("div", {
                                                                            className: "dropzone-svg-container " + dropZoneIcon
                                                                          }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.CloudUpload.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))),
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, dropZoneText, /* Title */594052472, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Drag & drop files here or click to select files")]))
                                                                    ]))])),
                                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(fileListContainer, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                            ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.List.Jsx2.make, undefined, fileList, [files.length !== 0 ? Belt_Array.map(files, (function (file) {
                                                                                return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, "list-item " + fileName, undefined, undefined, undefined, [
                                                                                                ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, {
                                                                                                          primary: fileText
                                                                                                        }, undefined, undefined, undefined, [I18N$BsConsole.showSkip(file.name)])),
                                                                                                React.createElement("span", {
                                                                                                      className: "action-button " + deleteFile,
                                                                                                      onClick: (function (_event) {
                                                                                                          return Curry._1(onDeleteClick, file.name);
                                                                                                        })
                                                                                                    }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))
                                                                                              ]));
                                                                              })) : ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.ListItem.Jsx2.make, undefined, fileName, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, {
                                                                                            primary: fileText
                                                                                          }, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "No files selected")]))]))])),
                                                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Row_Reverse */-674898307, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                undefined,
                                                                                undefined,
                                                                                handleNextStep,
                                                                                undefined,
                                                                                undefined,
                                                                                /* Raised */-387916264,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                files.length === 0,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                [I18N$BsConsole.show(undefined, "Next")]
                                                                              ]))]))
                                                          ]))
                                                ]))]))
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var ChooseFiles = {
  Styles: Styles,
  component: component,
  make: make
};

var textFieldContainer = Css.style(/* :: */[
      Css.width(Css.px(300)),
      /* :: */[
        Css.marginTop(Css.em(1)),
        /* :: */[
          Css.position(/* relative */903134412),
          /* [] */0
        ]
      ]
    ]);

var textField = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var tagListPaper = Css.style(/* :: */[
      Css.maxHeight(Css.px(200)),
      /* :: */[
        Css.minWidth(Css.px(300)),
        /* :: */[
          Css.overflow(/* auto */-1065951377),
          /* :: */[
            Css.position(/* absolute */-1013592457),
            /* :: */[
              Css.top(Css.px(32)),
              /* :: */[
                Css.zIndex(2),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles$1 = {
  textFieldContainer: textFieldContainer,
  textField: textField,
  tagListPaper: tagListPaper
};

function setRef(theRef, param) {
  var myRef = (theRef == null) ? undefined : Caml_option.some(theRef);
  if (Belt_Option.isSome(myRef)) {
    param.state.tagListRef.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
    return ;
  }
  
}

var fuzz = Util$BsConsole.Fuzz.filterTagList;

var component$1 = ReasonReact.reducerComponent("PS_SymbolsUpload-BsConsole__AutoComplete");

function make$1(data, setTagValue, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (self) {
              return React.createElement("div", {
                          className: textFieldContainer,
                          onKeyDown: (function ($$event) {
                              var keycode = $$event.keyCode;
                              if (keycode >= 38) {
                                if (keycode >= 41) {
                                  return ;
                                }
                                switch (keycode - 38 | 0) {
                                  case 0 :
                                      return Curry._1(self.send, /* SetIndex */Block.__(0, [self.state.selectedIndex > -1 ? self.state.selectedIndex - 1 | 0 : -1]));
                                  case 1 :
                                      return ;
                                  case 2 :
                                      var tagListLength = Belt_List.length(Curry._2(fuzz, data, self.state.tagValue));
                                      return Curry._1(self.send, /* SetIndex */Block.__(0, [self.state.selectedIndex < (tagListLength - 1 | 0) ? self.state.selectedIndex + 1 | 0 : -1]));
                                  
                                }
                              } else {
                                if (keycode !== 13) {
                                  return ;
                                }
                                if (self.state.selectedIndex > -1) {
                                  var tagList = Belt_List.sort(Curry._2(fuzz, data, self.state.tagValue), (function (a, b) {
                                          return b.lastUpdateTime - a.lastUpdateTime | 0;
                                        }));
                                  var tag = Belt_List.get(tagList, self.state.selectedIndex);
                                  if (tag !== undefined) {
                                    Curry._1(self.send, /* SetTagValue */Block.__(2, [tag.tag]));
                                  }
                                  
                                }
                                return $$event.target.blur();
                              }
                            })
                        }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                  textField,
                                  undefined,
                                  I18N$BsConsole.get(undefined, "Select a tag or create a new one"),
                                  undefined,
                                  (function ($$event) {
                                      var value = $$event.target.value;
                                      return Curry._1(self.send, /* SetTagValue */Block.__(2, [value]));
                                    }),
                                  (function (_event) {
                                      return Curry._1(self.send, /* SetPopoverState */Block.__(1, [true]));
                                    }),
                                  (function ($$event) {
                                      var nextLocation = $$event.relatedTarget;
                                      var r = self.state.tagListRef.contents;
                                      var contains = r !== undefined ? Caml_option.valFromOption(r).contains(nextLocation) : false;
                                      if (contains) {
                                        return ;
                                      } else {
                                        return Curry._1(self.send, /* SetPopoverState */Block.__(1, [false]));
                                      }
                                    }),
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  /* `String */[
                                    -976970511,
                                    self.state.tagValue
                                  ],
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  []
                                ])), self.state.open_ ? ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, tagListPaper, [React.createElement("div", {
                                          ref: Curry._1(self.handle, setRef),
                                          onBlur: (function ($$event) {
                                              var nextLocation = $$event.relatedTarget;
                                              var r = self.state.tagListRef.contents;
                                              var contains = r !== undefined ? Caml_option.valFromOption(r).contains(nextLocation) : false;
                                              if (contains) {
                                                return ;
                                              } else {
                                                return Curry._1(self.send, /* SetPopoverState */Block.__(1, [false]));
                                              }
                                            }),
                                          onMouseLeave: (function (_event) {
                                              return Curry._1(self.send, /* SetIndex */Block.__(0, [-1]));
                                            })
                                        }, ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.MenuList.Jsx2.make, undefined, [Belt_Array.concat([$$String.trim(self.state.tagValue) !== "" && Belt_List.length(Belt_List.keep(data, (function (tag) {
                                                                    return tag.tag === self.state.tagValue;
                                                                  }))) === 0 ? React.createElement("div", {
                                                                onMouseEnter: (function (_event) {
                                                                    return Curry._1(self.send, /* SetIndex */Block.__(0, [-1]));
                                                                  })
                                                              }, ReasonReact.element(self.state.tagValue + "__input", undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, undefined, undefined, self.state.selectedIndex === -1, [
                                                                        I18N$BsConsole.show(undefined, "new tag"),
                                                                        I18N$BsConsole.showSkip("" + self.state.tagValue)
                                                                      ]))) : null], Belt_List.toArray(Belt_List.mapWithIndex(Belt_List.sort(Curry._2(fuzz, data, self.state.tagValue), (function (a, b) {
                                                                      return b.lastUpdateTime - a.lastUpdateTime | 0;
                                                                    })), (function (idx, tag) {
                                                                  return React.createElement("div", {
                                                                              onMouseEnter: (function (_event) {
                                                                                  return Curry._1(self.send, /* SetIndex */Block.__(0, [idx]));
                                                                                })
                                                                            }, ReasonReact.element(tag.tag, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function (_event) {
                                                                                        Curry._1(self.send, /* SetTagValue */Block.__(2, [tag.tag]));
                                                                                        return Curry._1(self.send, /* SetPopoverState */Block.__(1, [false]));
                                                                                      }), undefined, undefined, self.state.selectedIndex === idx, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.ListItemText.Jsx2.make, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(tag.tag)]))])));
                                                                }))))])))])) : null);
            }),
          initialState: (function (param) {
              return {
                      tagValue: "",
                      open_: false,
                      tagListRef: {
                        contents: undefined
                      },
                      selectedIndex: -1
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* SetIndex */0 :
                    return /* Update */Block.__(0, [{
                                tagValue: state.tagValue,
                                open_: state.open_,
                                tagListRef: state.tagListRef,
                                selectedIndex: action[0]
                              }]);
                case /* SetPopoverState */1 :
                    return /* Update */Block.__(0, [{
                                tagValue: state.tagValue,
                                open_: action[0],
                                tagListRef: state.tagListRef,
                                selectedIndex: state.selectedIndex
                              }]);
                case /* SetTagValue */2 :
                    var tagValue = action[0];
                    Curry._1(setTagValue, tagValue);
                    return /* Update */Block.__(0, [{
                                tagValue: tagValue,
                                open_: state.open_,
                                tagListRef: state.tagListRef,
                                selectedIndex: -1
                              }]);
                
              }
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var TagAutoComplete = {
  Styles: Styles$1,
  setRef: setRef,
  fuzz: fuzz,
  component: component$1,
  make: make$1
};

var title$1 = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.paddingBottom(/* zero */-789508312),
        /* [] */0
      ]
    ]);

var buttonContainer = Css.style(/* :: */[
      Css.marginTop(Css.px(24)),
      /* [] */0
    ]);

var back = Css.style(/* :: */[
      Css.marginRight(Css.em(1)),
      /* [] */0
    ]);

var Styles$2 = {
  title: title$1,
  buttonContainer: buttonContainer,
  back: back
};

var component$2 = ReasonReact.reducerComponent("PS_SymbolsUpload-BsConsole__ChooseFiles");

function make$2(handleTask, token, name, handleNextStep, handlePrevStep, handleAddTag, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (self) {
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title$1, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Select a tag (optional)")])),
                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Tags serve as a container to symbol files. For example, it is recommended to use different tags for different versions of your application so you can easily search and refer to symbols of a particular version rather than across all versions of your application. If no tag is explicitly created or selected, your upload will be given a tag of 'anon'.")])),
                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, FetchSymbols$BsConsole.Tags.make(handleTask, token, name, (function (remote, _update) {
                                                              if (typeof remote === "number") {
                                                                if (remote === /* NotAsked */0) {
                                                                  return null;
                                                                } else {
                                                                  return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []));
                                                                }
                                                              } else if (remote.tag) {
                                                                return null;
                                                              } else {
                                                                return ReasonReact.element(undefined, undefined, make$1(remote[0], (function (tagValue) {
                                                                                  return Curry._1(self.send, /* SetTagValue */[tagValue]);
                                                                                }), []));
                                                              }
                                                            })))])),
                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(buttonContainer, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Row_Reverse */-674898307, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                      undefined,
                                                                      back,
                                                                      handlePrevStep,
                                                                      undefined,
                                                                      undefined,
                                                                      /* Raised */-387916264,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [I18N$BsConsole.show(undefined, "Back")]
                                                                    ])),
                                                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                      undefined,
                                                                      undefined,
                                                                      (function (_event) {
                                                                          if ($$String.trim(self.state.tagValue) !== "") {
                                                                            Curry._1(handleAddTag, $$String.trim(self.state.tagValue));
                                                                          }
                                                                          return Curry._1(handleNextStep, undefined);
                                                                        }),
                                                                      undefined,
                                                                      undefined,
                                                                      /* Raised */-387916264,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [I18N$BsConsole.show(undefined, "Upload files")]
                                                                    ]))
                                                          ]))]))
                                      ]))
                            ]));
            }),
          initialState: (function (param) {
              return {
                      tagValue: ""
                    };
            }),
          retainedProps: component$2.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          tagValue: action[0]
                        }]);
            }),
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var ChooseTag = {
  Styles: Styles$2,
  component: component$2,
  make: make$2
};

var barSuccess = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.greenLight)),
      /* [] */0
    ]);

var barLoading = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.blueLight)),
      /* [] */0
    ]);

var barFailure = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.error)),
      /* [] */0
    ]);

var fileName$1 = Css.style(/* :: */[
      Css.fontFamily("Inconsolata;monospace"),
      /* [] */0
    ]);

var error = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.error)),
      /* [] */0
    ]);

var withBottomMargin = Css.style(/* :: */[
      Css.marginBottom(Css.px(24)),
      /* [] */0
    ]);

var Styles$3 = {
  barSuccess: barSuccess,
  barLoading: barLoading,
  barFailure: barFailure,
  fileName: fileName$1,
  error: error,
  withBottomMargin: withBottomMargin
};

var component$3 = ReasonReact.reducerComponent("PS_SymbolsUpload-BsConsole_File_Upload");

function make$3(token, port, name, tag, universe, file, handleUploadComplete, handleAddArchiveId, handleAddUploadError, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: (function (self) {
              Curry._1(self.send, /* SetFileId */Block.__(1, [file.name]));
              var onProgress = function (param) {
                return Curry._1(self.send, /* SetProgress */Block.__(0, [param[0] / param[1] * 100]));
              };
              Curry._1(self.send, /* SetStatus */Block.__(2, [/* Loading */1]));
              var match = Upload$BsConsole.makeRequest(/* SymbolFile */Block.__(1, [
                      token,
                      port,
                      name,
                      universe.name,
                      tag,
                      file
                    ]), (function (response) {
                      var match = response.status;
                      if (match) {
                        return Curry._1(self.send, /* SetStatus */Block.__(2, [/* Success */Block.__(0, [response.rxid])]));
                      } else {
                        return Curry._1(self.send, /* SetStatus */Block.__(2, [/* Failure */Block.__(1, [response.message])]));
                      }
                    }));
              var request = match[0];
              Request$BsConsole.XML_HttpRequest.addOnProgressListener(onProgress, request);
              return Request$BsConsole.XML_HttpRequest.sendForm(match[1], request);
            }),
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (self) {
              var match = self.state.fileId;
              var match$1 = self.state.status;
              if (match !== undefined) {
                if (typeof match$1 === "number") {
                  if (match$1 === /* NotAsked */0) {
                    return null;
                  } else {
                    return ReasonReact.element(match + "_uploader", undefined, Mui$BsConsole.Grid.make(withBottomMargin, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Column */-81804554, undefined, undefined, [
                                    ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, /* Baseline */-354838363, [
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_label", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [React.createElement("span", {
                                                                                  className: fileName$1
                                                                                }, I18N$BsConsole.showSkip(match))]))])),
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_status", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "...uploading")]))]))
                                                      ]))])),
                                    ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.LinearProgress.Jsx2.make, {
                                                        bar: barLoading
                                                      }, undefined, undefined, /* `Float */[
                                                        365180284,
                                                        self.state.progress
                                                      ], undefined, /* Determinate */373815968, []))]))
                                  ]));
                  }
                } else if (match$1.tag) {
                  return ReasonReact.element(match + "_uploader", undefined, Mui$BsConsole.Grid.make(withBottomMargin, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Column */-81804554, undefined, undefined, [
                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, undefined, [
                                                      ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_label", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [React.createElement("span", {
                                                                                className: fileName$1
                                                                              }, I18N$BsConsole.showSkip(match))]))])),
                                                      ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_status", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [React.createElement("span", {
                                                                                className: error
                                                                              }, I18N$BsConsole.show(undefined, "Error"))]))]))
                                                    ]))])),
                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_error_message", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.dynamic(match$1[0])]))])),
                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.LinearProgress.Jsx2.make, {
                                                      bar: barFailure
                                                    }, undefined, undefined, /* `Float */[
                                                      365180284,
                                                      self.state.progress
                                                    ], undefined, /* Determinate */373815968, []))]))
                                ]));
                } else {
                  return ReasonReact.element(match + "_uploader", undefined, Mui$BsConsole.Grid.make(withBottomMargin, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Column */-81804554, undefined, undefined, [
                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, /* Baseline */-354838363, [
                                                      ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_label", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [React.createElement("span", {
                                                                                className: fileName$1
                                                                              }, I18N$BsConsole.showSkip(match))]))])),
                                                      ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(match + "_uploader_status", undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Success")]))]))
                                                    ]))])),
                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.LinearProgress.Jsx2.make, {
                                                      bar: barSuccess
                                                    }, undefined, undefined, /* `Float */[
                                                      365180284,
                                                      self.state.progress
                                                    ], undefined, /* Determinate */373815968, []))]))
                                ]));
                }
              } else {
                return null;
              }
            }),
          initialState: (function (param) {
              return {
                      progress: 0,
                      fileId: undefined,
                      status: /* NotAsked */0
                    };
            }),
          retainedProps: component$3.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* SetProgress */0 :
                    return /* Update */Block.__(0, [{
                                progress: action[0],
                                fileId: state.fileId,
                                status: state.status
                              }]);
                case /* SetFileId */1 :
                    return /* Update */Block.__(0, [{
                                progress: state.progress,
                                fileId: action[0],
                                status: state.status
                              }]);
                case /* SetStatus */2 :
                    var status = action[0];
                    if (typeof status === "number") {
                      status === /* NotAsked */0;
                    } else if (status.tag) {
                      Curry._1(handleAddUploadError, status[0]);
                      Curry._1(handleUploadComplete, file.name);
                    } else {
                      var archiveId = status[0];
                      Curry._1(handleUploadComplete, file.name);
                      if (archiveId !== undefined) {
                        Curry._1(handleAddArchiveId, archiveId);
                      }
                      
                    }
                    return /* Update */Block.__(0, [{
                                progress: state.progress,
                                fileId: state.fileId,
                                status: status
                              }]);
                
              }
            }),
          jsElementWrapped: component$3.jsElementWrapped
        };
}

var FileUploader = {
  Styles: Styles$3,
  component: component$3,
  make: make$3
};

var component$4 = ReasonReact.reducerComponent("PS_SymbolsUpload-BsConsole__UploadFiles");

function make$4(shell, files, tag, name, token, unblockNavigation, handleNextStep, _children) {
  return {
          debugName: component$4.debugName,
          reactClassInternal: component$4.reactClassInternal,
          handedOffState: component$4.handedOffState,
          willReceiveProps: component$4.willReceiveProps,
          didMount: component$4.didMount,
          didUpdate: component$4.didUpdate,
          willUnmount: component$4.willUnmount,
          willUpdate: component$4.willUpdate,
          shouldUpdate: component$4.shouldUpdate,
          render: (function (self) {
              var tmp;
              if (typeof shell === "number" || shell.tag) {
                tmp = null;
              } else {
                var config = shell[0];
                var universe = config.universe;
                var maybePort = Configuration$BsConsole.getPort(undefined, config);
                tmp = ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                          maybePort !== undefined ? Belt_Array.mapWithIndex(files, (function (index, file) {
                                    return ReasonReact.element(String(index) + file.name, undefined, make$3(token, maybePort, name, tag, universe, file, (function (upload) {
                                                      return Curry._1(self.send, /* RemovePendingUpload */Block.__(0, [upload]));
                                                    }), (function (archiveId) {
                                                      return Curry._1(self.send, /* AddArchiveId */Block.__(2, [archiveId]));
                                                    }), (function (uploadError) {
                                                      return Curry._1(self.send, /* AddUploadError */Block.__(1, [uploadError]));
                                                    }), []));
                                  })) : null,
                          ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Row_Reverse */-674898307, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                              undefined,
                                              undefined,
                                              (function (_event) {
                                                  return Curry._2(handleNextStep, self.state.archiveIds, self.state.uploadErrors);
                                                }),
                                              undefined,
                                              undefined,
                                              /* Raised */-387916264,
                                              undefined,
                                              undefined,
                                              undefined,
                                              self.state.pendingUploads.length !== 0,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              [I18N$BsConsole.show(undefined, "View upload summary")]
                                            ]))]))
                        ]));
              }
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [tmp]));
            }),
          initialState: (function (param) {
              return {
                      uploadErrors: [],
                      archiveIds: [],
                      pendingUploads: Belt_Array.map(files, (function (file) {
                              return file.name;
                            }))
                    };
            }),
          retainedProps: component$4.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* RemovePendingUpload */0 :
                    var upload = action[0];
                    var newPendingUploads = Belt_Array.keep(state.pendingUploads, (function (upload$prime) {
                            return upload$prime !== upload;
                          }));
                    if (newPendingUploads.length === 0) {
                      Curry._1(unblockNavigation, undefined);
                    }
                    return /* Update */Block.__(0, [{
                                uploadErrors: state.uploadErrors,
                                archiveIds: state.archiveIds,
                                pendingUploads: newPendingUploads
                              }]);
                case /* AddUploadError */1 :
                    var newUploadErrors = Belt_Array.concat(state.uploadErrors, [action[0]]);
                    return /* Update */Block.__(0, [{
                                uploadErrors: newUploadErrors,
                                archiveIds: state.archiveIds,
                                pendingUploads: state.pendingUploads
                              }]);
                case /* AddArchiveId */2 :
                    var newArchiveIds = Belt_Array.concat(state.archiveIds, [action[0]]);
                    return /* Update */Block.__(0, [{
                                uploadErrors: state.uploadErrors,
                                archiveIds: newArchiveIds,
                                pendingUploads: state.pendingUploads
                              }]);
                
              }
            }),
          jsElementWrapped: component$4.jsElementWrapped
        };
}

var UploadFiles = {
  component: component$4,
  make: make$4
};

var title$2 = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.paddingBottom(/* zero */-789508312),
        /* [] */0
      ]
    ]);

var actionButton = Css.style(/* :: */[
      Css.marginRight(Css.em(1)),
      /* [] */0
    ]);

var errors = Css.style(/* :: */[
      Css.padding(Css.px(16)),
      /* :: */[
        Css.marginTop(Css.px(16)),
        /* :: */[
          Css.marginBottom(Css.px(24)),
          /* :: */[
            Css.marginLeft(/* zero */-789508312),
            /* :: */[
              Css.marginRight(/* zero */-789508312),
              /* :: */[
                Css.display(/* inlineFlex */53323314),
                /* :: */[
                  Css.color(Css.hex(Colors$BsConsole.error)),
                  /* :: */[
                    Css.borderRadius(Css.px(4)),
                    /* :: */[
                      Css.alignItems(/* center */98248149),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles$4 = {
  title: title$2,
  actionButton: actionButton,
  errors: errors
};

var component$5 = ReasonReact.statelessComponent("PS_SymbolsUpload-BsConsole__UploadSummary");

function make$5(name, archiveIds, uploadErrors, handleChangeUrl, tag, handleTask, token, handleUploadMore, _children) {
  return {
          debugName: component$5.debugName,
          reactClassInternal: component$5.reactClassInternal,
          handedOffState: component$5.handedOffState,
          willReceiveProps: component$5.willReceiveProps,
          didMount: component$5.didMount,
          didUpdate: component$5.didUpdate,
          willUnmount: component$5.willUnmount,
          willUpdate: component$5.willUpdate,
          shouldUpdate: component$5.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title$2, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Upload summary")])),
                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                        uploadErrors.length !== 0 ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [React.createElement("div", {
                                                          className: errors
                                                        }, React.createElement("span", {
                                                              style: {
                                                                marginRight: "1em"
                                                              }
                                                            }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Warning.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "One or more errors were present in your archive upload. Please refer to the uploaded archives page for more details.")])))])) : null,
                                        archiveIds.length !== 0 ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, FetchSymbols$BsConsole.Archives.make(handleTask, token, name, Caml_option.some(archiveIds), (function (remote, _update) {
                                                                if (typeof remote === "number") {
                                                                  if (remote === /* NotAsked */0) {
                                                                    return null;
                                                                  } else {
                                                                    return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, undefined, undefined, undefined, []))]));
                                                                  }
                                                                } else if (remote.tag) {
                                                                  return null;
                                                                } else {
                                                                  return ReasonReact.element(undefined, undefined, Curry._6(SymbolTable$BsConsole.UploadSummary.Re.make, token, name, remote[0], tag, archiveIds, []));
                                                                }
                                                              })))])) : null,
                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [
                                                  uploadErrors.length !== 0 ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                              undefined,
                                                              actionButton,
                                                              (function (_event) {
                                                                  return Curry._1(handleChangeUrl, /* ProjectSettingsSymbolsUploadedArchives */Block.__(36, [name]));
                                                                }),
                                                              undefined,
                                                              undefined,
                                                              /* Raised */-387916264,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              [I18N$BsConsole.show(undefined, "Go to uploaded archives")]
                                                            ])) : null,
                                                  ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                            undefined,
                                                            actionButton,
                                                            (function (_event) {
                                                                return Curry._1(handleChangeUrl, /* ProjectTriage */Block.__(11, [
                                                                              name,
                                                                              Route$BsConsole.getInboxParams(name, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
                                                                            ]));
                                                              }),
                                                            undefined,
                                                            undefined,
                                                            /* Raised */-387916264,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [I18N$BsConsole.show(undefined, "Go to project triage")]
                                                          ])),
                                                  ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                            undefined,
                                                            actionButton,
                                                            (function (_event) {
                                                                return Curry._1(handleChangeUrl, /* ProjectSettingsMissingSymbols */Block.__(41, [name]));
                                                              }),
                                                            undefined,
                                                            undefined,
                                                            /* Raised */-387916264,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [I18N$BsConsole.show(undefined, "View missing symbols")]
                                                          ])),
                                                  ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                            undefined,
                                                            actionButton,
                                                            handleUploadMore,
                                                            undefined,
                                                            undefined,
                                                            /* Raised */-387916264,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [I18N$BsConsole.show(undefined, "Upload more symbols")]
                                                          ]))
                                                ]))
                                      ]))
                            ]));
            }),
          initialState: component$5.initialState,
          retainedProps: component$5.retainedProps,
          reducer: component$5.reducer,
          jsElementWrapped: component$5.jsElementWrapped
        };
}

var UploadSummary = {
  Styles: Styles$4,
  component: component$5,
  make: make$5
};

var title$3 = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.paddingBottom(/* zero */-789508312),
        /* [] */0
      ]
    ]);

var stepper = Css.style(/* :: */[
      Css.backgroundColor(/* transparent */582626130),
      /* [] */0
    ]);

var stepLabel = Css.style(/* :: */[
      Css.whiteSpace(/* nowrap */867913355),
      /* [] */0
    ]);

var Styles$5 = {
  title: title$3,
  stepper: stepper,
  stepLabel: stepLabel
};

var steps = [
  {
    view: /* ChooseFiles */0,
    label: I18N$BsConsole.get(undefined, "Add symbol files")
  },
  {
    view: /* ChooseTag */1,
    label: I18N$BsConsole.get(undefined, "Select a tag")
  },
  {
    view: /* UploadFiles */2,
    label: I18N$BsConsole.get(undefined, "Upload symbols")
  },
  {
    view: /* Summary */3,
    label: I18N$BsConsole.get(undefined, "Upload summary")
  }
];

var component$6 = ReasonReact.reducerComponent("PS_SymbolsUpload-BsConsole");

function make$6(handleTask, token, handleChangeUrl, shell, name, _children) {
  return {
          debugName: component$6.debugName,
          reactClassInternal: component$6.reactClassInternal,
          handedOffState: component$6.handedOffState,
          willReceiveProps: component$6.willReceiveProps,
          didMount: component$6.didMount,
          didUpdate: component$6.didUpdate,
          willUnmount: component$6.willUnmount,
          willUpdate: component$6.willUpdate,
          shouldUpdate: component$6.shouldUpdate,
          render: (function (self) {
              var supportLink = Href$BsConsole.Support.make(undefined, /* SettingsSymbolification */22);
              var match = self.state.view;
              var tmp;
              switch (match) {
                case /* ChooseFiles */0 :
                    tmp = /* `Int */[
                      3654863,
                      0
                    ];
                    break;
                case /* ChooseTag */1 :
                    tmp = /* `Int */[
                      3654863,
                      1
                    ];
                    break;
                case /* UploadFiles */2 :
                    tmp = /* `Int */[
                      3654863,
                      2
                    ];
                    break;
                case /* Summary */3 :
                    tmp = /* `Int */[
                      3654863,
                      3
                    ];
                    break;
                
              }
              var match$1 = self.state.view;
              var tmp$1;
              switch (match$1) {
                case /* ChooseFiles */0 :
                    tmp$1 = ReasonReact.element(undefined, undefined, make((function (files) {
                                return Curry._1(self.send, /* SetFiles */Block.__(4, [Belt_Array.concat(files, self.state.files)]));
                              }), (function (name) {
                                var files = Belt_Array.keep(self.state.files, (function (file) {
                                        return file.name !== name;
                                      }));
                                return Curry._1(self.send, /* SetFiles */Block.__(4, [files]));
                              }), self.state.files, (function (_event) {
                                return Curry._1(self.send, /* SetView */Block.__(1, [/* ChooseTag */1]));
                              }), []));
                    break;
                case /* ChooseTag */1 :
                    tmp$1 = ReasonReact.element(undefined, undefined, make$2(handleTask, token, name, (function (_event) {
                                return Curry._1(self.send, /* SetView */Block.__(1, [/* UploadFiles */2]));
                              }), (function (_event) {
                                return Curry._1(self.send, /* SetView */Block.__(1, [/* ChooseFiles */0]));
                              }), (function (tag) {
                                return Curry._1(self.send, /* SetTag */Block.__(0, [tag]));
                              }), []));
                    break;
                case /* UploadFiles */2 :
                    tmp$1 = ReasonReact.element(undefined, undefined, BlockNavigation$BsConsole.make(I18N$BsConsole.get(undefined, "Leaving page during file uploads may corrupt uploading process."), (function (unblockNavigation) {
                                var tag = self.state.tag;
                                return ReasonReact.element(undefined, undefined, make$4(shell, self.state.files, tag !== undefined ? tag : "anon", name, token, unblockNavigation, (function (archiveIds, uploadErrors) {
                                                  Curry._1(self.send, /* SetArchiveIds */Block.__(2, [archiveIds]));
                                                  Curry._1(self.send, /* SetUploadErrors */Block.__(3, [uploadErrors]));
                                                  return Curry._1(self.send, /* SetView */Block.__(1, [/* Summary */3]));
                                                }), []));
                              })));
                    break;
                case /* Summary */3 :
                    var tag = self.state.tag;
                    tmp$1 = ReasonReact.element(undefined, undefined, make$5(name, self.state.archiveIds, self.state.uploadErrors, handleChangeUrl, tag !== undefined ? tag : "anon", handleTask, token, (function (_event) {
                                Curry._1(self.send, /* SetFiles */Block.__(4, [[]]));
                                Curry._1(self.send, /* SetView */Block.__(1, [/* ChooseFiles */0]));
                                return Curry._1(self.send, /* SetArchiveIds */Block.__(2, [[]]));
                              }), []));
                    break;
                
              }
              return ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Container.Jsx2.make, undefined, [
                              ReasonReact.element(undefined, undefined, Curry._2(BtSettings$BsConsole.Paper.Jsx2.make, undefined, [
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, title$3, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Upload symbols")])),
                                        ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Select an archive file (.zip, .tar), .pdb or .sym files from your local drive. If you are uploading .pdb files for 64-bit Windows applications, remember to include the corresponding .dll or .exe files as well for more accurate symbolification. To learn more about symbolification please refer to our documentation.")])),
                                                  supportLink !== undefined ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, Css.style(/* :: */[
                                                                  Css.marginTop(Css.rem(0.5)),
                                                                  /* [] */0
                                                                ]), /* Subheading */148169314, undefined, undefined, undefined, undefined, [React.createElement("a", {
                                                                    href: supportLink,
                                                                    target: "_blank"
                                                                  }, I18N$BsConsole.show(undefined, "Symbolification documentation"))])) : null
                                                ]))
                                      ])),
                              ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Stepper.Jsx2.make, stepper, undefined, Caml_option.some(React.createElement("div", {
                                                style: {
                                                  borderBottom: "solid 2px " + Colors$BsConsole.octothorpe(Colors$BsConsole.blackA20),
                                                  width: "100%"
                                                }
                                              })), tmp, undefined, undefined, undefined, [Belt_Array.map(steps, (function (step) {
                                                return ReasonReact.element(step.label, undefined, Curry._6(Mui$BsConsole.Step.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.StepLabel.Jsx2.make, undefined, {
                                                                          label: stepLabel
                                                                        }, [I18N$BsConsole.show(undefined, step.label)]))]));
                                              }))])),
                              tmp$1
                            ]));
            }),
          initialState: (function (param) {
              return {
                      view: /* ChooseFiles */0,
                      files: [],
                      tag: undefined,
                      archiveIds: [],
                      uploadErrors: []
                    };
            }),
          retainedProps: component$6.retainedProps,
          reducer: (function (action, state) {
              switch (action.tag | 0) {
                case /* SetTag */0 :
                    return /* Update */Block.__(0, [{
                                view: state.view,
                                files: state.files,
                                tag: action[0],
                                archiveIds: state.archiveIds,
                                uploadErrors: state.uploadErrors
                              }]);
                case /* SetView */1 :
                    return /* Update */Block.__(0, [{
                                view: action[0],
                                files: state.files,
                                tag: state.tag,
                                archiveIds: state.archiveIds,
                                uploadErrors: state.uploadErrors
                              }]);
                case /* SetArchiveIds */2 :
                    return /* Update */Block.__(0, [{
                                view: state.view,
                                files: state.files,
                                tag: state.tag,
                                archiveIds: action[0],
                                uploadErrors: state.uploadErrors
                              }]);
                case /* SetUploadErrors */3 :
                    return /* Update */Block.__(0, [{
                                view: state.view,
                                files: state.files,
                                tag: state.tag,
                                archiveIds: state.archiveIds,
                                uploadErrors: action[0]
                              }]);
                case /* SetFiles */4 :
                    return /* Update */Block.__(0, [{
                                view: state.view,
                                files: action[0],
                                tag: state.tag,
                                archiveIds: state.archiveIds,
                                uploadErrors: state.uploadErrors
                              }]);
                
              }
            }),
          jsElementWrapped: component$6.jsElementWrapped
        };
}

var RR;

exports.RR = RR;
exports.ChooseFiles = ChooseFiles;
exports.TagAutoComplete = TagAutoComplete;
exports.ChooseTag = ChooseTag;
exports.FileUploader = FileUploader;
exports.UploadFiles = UploadFiles;
exports.UploadSummary = UploadSummary;
exports.Styles = Styles$5;
exports.steps = steps;
exports.component = component$6;
exports.make = make$6;
/* title Not a pure module */
