// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Key$BsConsole = require("./Key.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Href$BsConsole = require("./Href.js");
var I18N$BsConsole = require("./I18N.js");
var Email$BsConsole = require("./Email.js");
var Colors$BsConsole = require("./Colors.js");
var Styles$BsConsole = require("./utilities/Styles.js");
var Fragment$BsConsole = require("./Fragment.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var UtilWindow$BsConsole = require("./UtilWindow.js");
var BillingConstants$BsConsole = require("./BillingConstants.js");
var BillingPlansFetch$BsConsole = require("./BillingPlansFetch.js");
var SettingsContainer$BsConsole = require("./SettingsContainer.js");
var ApiBillingChangePlan$BsConsole = require("./ApiBillingChangePlan.js");
var BillingInvoicesFetch$BsConsole = require("./BillingInvoicesFetch.js");
var BillingSubscriptionFetch$BsConsole = require("./BillingSubscriptionFetch.js");
var SettingsBillingPaymentView$BsConsole = require("./SettingsBillingPaymentView.js");

function promise(url, token, nextPlanID) {
  return (function(url, token, nextPlanID) {
        const promise = new Promise((resolve, reject) => {
          var xmlhttp = new XMLHttpRequest();
          xmlhttp.open(
            'PUT',
            `${url}/subscriptions?token=${token}`
          );
          xmlhttp.setRequestHeader('Content-Type', 'application/json');
          xmlhttp.addEventListener('load', function() {
            resolve(JSON.parse(this.responseText));
          });
          xmlhttp.addEventListener('error', reject);
          xmlhttp.addEventListener('abort', reject);
          xmlhttp.send(
            JSON.stringify({
              nextPlanID,
              agreedToTermsOfService: true,
            })
          );
        });
        return promise;
      })(url, token, nextPlanID);
}

var component = ReasonReact.reducerComponent("SettingsBillingPlans-BsConsole");

function make(token, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var changePlan = function (planId) {
                return Curry._1(send, /* Request */Block.__(0, [planId]));
              };
              return Curry._2(children, param.state.remote, changePlan);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                return /* Update */Block.__(0, [{
                            remote: /* Failure */Block.__(1, ["Failed to change plan"])
                          }]);
              }
              if (action.tag) {
                return /* Update */Block.__(0, [{
                            remote: /* Success */Block.__(0, [action[0]])
                          }]);
              }
              var planId = action[0];
              return /* UpdateWithSideEffects */Block.__(2, [
                        {
                          remote: /* Loading */1
                        },
                        (function (self) {
                            promise(BillingConstants$BsConsole.serverUrl, token, planId).then((function (response) {
                                      var response$1 = ApiBillingChangePlan$BsConsole.ofJson(response);
                                      if (response$1 !== undefined && response$1 && response$1[0].status === "Success") {
                                        Curry._1(self.send, /* Success */Block.__(1, ["Success"]));
                                      } else {
                                        Curry._1(self.send, /* Failure */0);
                                      }
                                      return Promise.resolve(undefined);
                                    })).catch((function (_error) {
                                    Curry._1(self.send, /* Failure */0);
                                    return Promise.resolve(undefined);
                                  }));
                            
                          })
                      ]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var FetchChangePlan = {
  promise: promise,
  component: component,
  make: make
};

var container = Css.style(/* :: */[
      Css.position(Css.relative),
      /* [] */0
    ]);

var closeButton = Css.style(/* :: */[
      Css.unsafe("position", "absolute !important"),
      /* :: */[
        Css.right(Css.px(12)),
        /* :: */[
          Css.top(Css.px(12)),
          /* [] */0
        ]
      ]
    ]);

var textContent = Css.style(/* :: */[
      Css.margin2(Css.em(1), Css.em(0)),
      /* [] */0
    ]);

var planText = Css.style(/* :: */[
      Css.fontWeight(/* bold */-1055161979),
      /* :: */[
        Css.textTransform(/* capitalize */-509867604),
        /* [] */0
      ]
    ]);

var detailText = Css.style(/* :: */[
      Css.unsafe("marginLeft", "1em !important"),
      /* [] */0
    ]);

var checkBoxRoot = Css.style(/* :: */[
      Css.unsafe("padding", "0 !important"),
      /* [] */0
    ]);

var link = Css.style(/* :: */[
      Css.textDecoration(/* none */-922086728),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.blue)),
        /* [] */0
      ]
    ]);

var successIcon = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.blue)),
      /* [] */0
    ]);

var failureIcon = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.error)),
      /* [] */0
    ]);

var component$1 = RR$BsConsole.reducerComponent("SettingsBillingPlans-BsConsole__UpgradeModal");

function make$1(plan, plans, features, period, onClose, billingInvoicesRemote, bustBillingSubscriptionCache, changePlanRemote, handleChangePlan, handleChangeUrl, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (param) {
              var planIndex = plan[1];
              var send = param.send;
              var state = param.state;
              var plan$prime = Belt_Array.get(plans, planIndex);
              var planId = plan$prime !== undefined ? (
                  period === /* Annual */0 ? plan$prime.id.year : plan$prime.id.month
                ) : undefined;
              var tmp;
              if (typeof changePlanRemote === "number") {
                if (changePlanRemote === /* NotAsked */0) {
                  var plan$1 = Belt_Array.get(plans, planIndex);
                  var tmp$1;
                  if (plan$1 !== undefined) {
                    if (period === /* Annual */0) {
                      var price = Belt_Option.getWithDefault(plan$1.price.year, 0);
                      tmp$1 = "$" + (
                        price > 0 ? String(price / 100 | 0) : "0"
                      );
                    } else {
                      var price$1 = Belt_Option.getWithDefault(plan$1.price.month, 0);
                      tmp$1 = "$" + ((
                          price$1 > 0 ? String(price$1 / 100 | 0) : "0"
                        ) + I18N$BsConsole.get(undefined, "/month"));
                    }
                  } else {
                    tmp$1 = "$0";
                  }
                  var tmp$2;
                  tmp$2 = typeof billingInvoicesRemote === "number" || billingInvoicesRemote.tag ? I18N$BsConsole.showSkip("xxxx") : I18N$BsConsole.showSkip(Belt_Option.getWithDefault(billingInvoicesRemote[0].customer.card, "xxxx"));
                  tmp = ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(textContent, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [
                                                I18N$BsConsole.show(undefined, "Are you sure you would like to upgrade to the "),
                                                React.createElement("span", {
                                                      className: planText
                                                    }, I18N$BsConsole.showSkip(plan[0] + (" " + (
                                                            period === /* Annual */0 ? I18N$BsConsole.get(undefined, "yearly") : I18N$BsConsole.get(undefined, "monthly")
                                                          )))),
                                                I18N$BsConsole.show(undefined, " plan?")
                                              ]))])),
                            ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])),
                            Belt_Array.map(features, (function (feature) {
                                    var value = Belt_Array.get(feature.values, planIndex);
                                    return ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                    ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, undefined, [
                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(feature.label)])),
                                                              ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [value !== undefined ? Belt_Option.getWithDefault(value.text, "-") : "-"]))
                                                            ])),
                                                    ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, []))
                                                  ]));
                                  })),
                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, [
                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "price:")])),
                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, detailText, /* Body2 */-904051920, undefined, undefined, undefined, undefined, [tmp$1]))
                                    ])),
                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, [
                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "payment method:")])),
                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, detailText, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [
                                                I18N$BsConsole.show(undefined, "card ending in "),
                                                React.createElement("strong", undefined, tmp$2)
                                              ]))
                                    ])),
                            React.createElement("br", undefined),
                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, undefined, undefined, undefined, undefined, [
                                      I18N$BsConsole.show(undefined, "Click "),
                                      React.createElement("a", {
                                            className: link,
                                            href: "#",
                                            onClick: (function (_event) {
                                                ((window.open("https://backtrace.io/software-license-agreement/", "_blank", "location=0")));
                                                
                                              })
                                          }, I18N$BsConsole.show(undefined, "here")),
                                      I18N$BsConsole.show(undefined, " to read our terms of service")
                                    ])),
                            React.createElement("br", undefined),
                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, [
                                      ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                undefined,
                                                /* `Bool */[
                                                  737456202,
                                                  state.tos
                                                ],
                                                undefined,
                                                /* Primary */-791844958,
                                                undefined,
                                                undefined,
                                                {
                                                  root: checkBoxRoot
                                                },
                                                true,
                                                (function (param, param$1) {
                                                    return Curry._1(send, /* ToggleTOS */0);
                                                  }),
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                []
                                              ])),
                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body2 */-904051920, undefined, undefined, {
                                                body2: detailText
                                              }, undefined, [I18N$BsConsole.show(undefined, "I have read and agree to Backtrace I/O Terms of Service")]))
                                    ])),
                            React.createElement("br", undefined),
                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Row_Reverse */-674898307, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                undefined,
                                                undefined,
                                                (function (_event) {
                                                    if (planId !== undefined) {
                                                      return Curry._1(handleChangePlan, planId);
                                                    }
                                                    
                                                  }),
                                                undefined,
                                                undefined,
                                                /* Raised */-387916264,
                                                /* Primary */-791844958,
                                                undefined,
                                                undefined,
                                                !state.tos || planId === undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [I18N$BsConsole.show(undefined, "Confirm upgrade")]
                                              ]))]))
                          ]));
                } else {
                  tmp = ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, "inherit", undefined, undefined, []))]))]));
                }
              } else {
                tmp = changePlanRemote.tag ? ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [React.createElement("div", {
                                            className: "extra-large-icon " + failureIcon
                                          }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Warning.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))])),
                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "There was an error updating your Backtrace I/O plan")]))])),
                            React.createElement("br", undefined),
                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Row_Reverse */-674898307, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                undefined,
                                                undefined,
                                                (function (_event) {
                                                    return Curry._1(handleChangeUrl, /* SettingsBilling */18);
                                                  }),
                                                undefined,
                                                undefined,
                                                /* Raised */-387916264,
                                                /* Primary */-791844958,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [I18N$BsConsole.show(undefined, "back to settings")]
                                              ]))]))
                          ])) : ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [React.createElement("div", {
                                            className: "extra-large-icon " + successIcon
                                          }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Check.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])))])),
                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "You have successfully updated your Backtrace I/O plan")]))])),
                            React.createElement("br", undefined),
                            ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Row_Reverse */-674898307, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                undefined,
                                                undefined,
                                                (function (_event) {
                                                    return Curry._1(handleChangeUrl, /* SettingsBilling */18);
                                                  }),
                                                undefined,
                                                undefined,
                                                /* Raised */-387916264,
                                                /* Primary */-791844958,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [I18N$BsConsole.show(undefined, "back to settings")]
                                              ]))]))
                          ]));
              }
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              container,
                              true,
                              (function ($$event) {
                                  if (typeof changePlanRemote === "number") {
                                    return Curry._1(onClose, $$event);
                                  }
                                  if (changePlanRemote.tag) {
                                    return Curry._1(onClose, $$event);
                                  }
                                  Curry._1(onClose, $$event);
                                  return Curry._1(bustBillingSubscriptionCache, undefined);
                                }),
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [
                                ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Change plan")])),
                                ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Divider.Jsx2.make, undefined, undefined, [])),
                                ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                          undefined,
                                          undefined,
                                          (function (_event) {
                                              if (typeof changePlanRemote === "number") {
                                                return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                              }
                                              if (changePlanRemote.tag) {
                                                return Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                              }
                                              Curry._1(onClose, UtilWindow$BsConsole.fakeSynthenticEvent);
                                              return Curry._1(bustBillingSubscriptionCache, undefined);
                                            }),
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          {
                                            root: closeButton
                                          },
                                          undefined,
                                          undefined,
                                          [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                        ])),
                                tmp
                              ]
                            ]));
            }),
          initialState: (function (param) {
              return {
                      tos: false
                    };
            }),
          retainedProps: component$1.retainedProps,
          reducer: (function (action, state) {
              return /* Update */Block.__(0, [{
                          tos: !state.tos
                        }]);
            }),
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var UpgradeModal = {
  container: container,
  closeButton: closeButton,
  textContent: textContent,
  planText: planText,
  detailText: detailText,
  checkBoxRoot: checkBoxRoot,
  link: link,
  successIcon: successIcon,
  failureIcon: failureIcon,
  component: component$1,
  make: make$1
};

var centerAlign = Css.style(/* :: */[
      Css.unsafe("textAlign", "center !important"),
      /* [] */0
    ]);

var planName = Css.style(/* :: */[
      Css.textTransform(Css.capitalize),
      /* :: */[
        Css.unsafe("color", "currentColor !important"),
        /* :: */[
          Css.padding2(Css.px(5), Css.zero),
          /* [] */0
        ]
      ]
    ]);

var planNameCell = Css.style(/* :: */[
      Css.unsafe("border", "none !important"),
      /* [] */0
    ]);

var selectedPlanName = Css.style(/* :: */[
      Css.background(Css.hex(Colors$BsConsole.blueDark)),
      /* :: */[
        Css.unsafe("color", Colors$BsConsole.octothorpe(Colors$BsConsole.white) + " !important"),
        /* :: */[
          Css.borderTopLeftRadius(Css.px(4)),
          /* :: */[
            Css.borderTopRightRadius(Css.px(4)),
            /* [] */0
          ]
        ]
      ]
    ]);

var selectedPlanPrice = Css.style(/* :: */[
      Css.background(Css.hex(Colors$BsConsole.blueDark)),
      /* :: */[
        Css.unsafe("color", Colors$BsConsole.octothorpe(Colors$BsConsole.white) + " !important"),
        /* :: */[
          Css.important(Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.blueDark))),
          /* [] */0
        ]
      ]
    ]);

var selectedPlan = Css.style(/* :: */[
      Css.unsafe("boxShadow", "inset 1px 0 0 #" + (Colors$BsConsole.blueDark + (", inset -1px 0 0 #" + (Colors$BsConsole.blueDark + " !important")))),
      /* :: */[
        Css.important(Css.borderColor(Css.hex(Colors$BsConsole.blueDark))),
        /* [] */0
      ]
    ]);

var disabledPlan = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.grey3))),
      /* [] */0
    ]);

var noBorder = Css.style(/* :: */[
      Css.unsafe("border", "none !important"),
      /* [] */0
    ]);

var forceCapitalize = Css.style(/* :: */[
      Css.textTransform(Css.capitalize),
      /* [] */0
    ]);

var component$2 = RR$BsConsole.statelessComponent("SettingsBillingPlans-BsConsole__PlansTable");

function make$2(plans, period, features, currentPlan, chosenPlan, setChosenPlan, lowestAvailable, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.Table.Jsx2.make, undefined, undefined, [
                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableHead.Jsx2.make, undefined, [
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, {
                                                  head: noBorder
                                                }, undefined, undefined, undefined, undefined, [])),
                                        Belt_Array.map(plans, (function (plan) {
                                                var decoration = chosenPlan !== undefined && chosenPlan[0] === plan.name ? selectedPlanName : "";
                                                return ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, {
                                                                head: planNameCell + (" " + decoration)
                                                              }, undefined, "checkbox", undefined, "head", [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body2 */-904051920, /* Center */980392437, undefined, {
                                                                          body2: planName
                                                                        }, undefined, [
                                                                          I18N$BsConsole.showSkip(plan.name.includes("custom") ? "custom" : plan.name),
                                                                          I18N$BsConsole.showSkip(plan.name === "free" ? "*" : ""),
                                                                          (
                                                                            plan.name.includes("custom") ? "custom" : plan.name
                                                                          ) === currentPlan.name ? React.createElement("span", {
                                                                                  className: "extra-smaller-icon"
                                                                                }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Check.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))) : null
                                                                        ]))]));
                                              }))
                                      ])),
                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableBody.Jsx2.make, undefined, [
                                        ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, undefined, undefined, "checkbox", undefined, undefined, [])),
                                                  Belt_Array.mapWithIndex(plans, (function (idx, plan) {
                                                          var decoration = chosenPlan !== undefined ? (
                                                              chosenPlan[0] === plan.name ? selectedPlanPrice : (
                                                                  idx < lowestAvailable ? disabledPlan : ""
                                                                )
                                                            ) : (
                                                              idx < lowestAvailable ? disabledPlan : ""
                                                            );
                                                          var tmp;
                                                          if (period) {
                                                            var price = Belt_Option.getWithDefault(plan.price.month, 0);
                                                            tmp = price > 0 ? String(price / 100 | 0) : "0";
                                                          } else {
                                                            var price$1 = Belt_Option.getWithDefault(plan.price.year, 0);
                                                            tmp = price$1 > 0 ? String(price$1 / 1200 | 0) : "0";
                                                          }
                                                          return ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, {
                                                                          body: centerAlign + (" " + decoration)
                                                                        }, undefined, "checkbox", undefined, undefined, [I18N$BsConsole.showSkip("$" + (tmp + "/Mo"))]));
                                                        }))
                                                ])),
                                        Belt_Array.map(features, (function (feature) {
                                                var __x = feature.values;
                                                return ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, {
                                                                          body: forceCapitalize
                                                                        }, undefined, "checkbox", undefined, undefined, [I18N$BsConsole.showSkip(feature.label)])),
                                                                Belt_Array.mapWithIndex(__x, (function (idx, value) {
                                                                        var decoration = chosenPlan !== undefined ? (
                                                                            idx === chosenPlan[1] ? selectedPlan : (
                                                                                idx < lowestAvailable ? disabledPlan : ""
                                                                              )
                                                                          ) : (
                                                                            idx < lowestAvailable ? disabledPlan : ""
                                                                          );
                                                                        return ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, {
                                                                                        root: centerAlign + (" " + decoration)
                                                                                      }, undefined, "checkbox", undefined, undefined, [I18N$BsConsole.showSkip(Belt_Option.getWithDefault(value.text, "-"))]));
                                                                      }))
                                                              ]));
                                              })),
                                        ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.TableRow.Jsx2.make, undefined, [
                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, {
                                                            root: centerAlign + (" " + noBorder)
                                                          }, undefined, "checkbox", undefined, undefined, [])),
                                                  Belt_Array.mapWithIndex(plans, (function (idx, plan) {
                                                          var disabled = idx < lowestAvailable || (
                                                            period ? plan.id.month === undefined : plan.id.year === undefined
                                                          );
                                                          return ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.TableCell.Jsx2.make, undefined, {
                                                                          root: centerAlign + (" " + noBorder)
                                                                        }, undefined, "checkbox", undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                                                    undefined,
                                                                                    chosenPlan !== undefined ? /* `Bool */[
                                                                                        737456202,
                                                                                        plan.name === chosenPlan[0]
                                                                                      ] : /* `Bool */[
                                                                                        737456202,
                                                                                        false
                                                                                      ],
                                                                                    undefined,
                                                                                    /* Primary */-791844958,
                                                                                    disabled,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    (function (_event, checked) {
                                                                                        return Curry._1(setChosenPlan, checked ? /* tuple */[
                                                                                                      plan.name,
                                                                                                      idx
                                                                                                    ] : undefined);
                                                                                      }),
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    undefined,
                                                                                    []
                                                                                  ]))]));
                                                        }))
                                                ]))
                                      ]))
                            ]));
            }),
          initialState: component$2.initialState,
          retainedProps: component$2.retainedProps,
          reducer: component$2.reducer,
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var PlansTable = {
  centerAlign: centerAlign,
  planName: planName,
  planNameCell: planNameCell,
  selectedPlanName: selectedPlanName,
  selectedPlanPrice: selectedPlanPrice,
  selectedPlan: selectedPlan,
  disabledPlan: disabledPlan,
  noBorder: noBorder,
  forceCapitalize: forceCapitalize,
  component: component$2,
  make: make$2
};

var activePeriod = Css.style(/* :: */[
      Css.unsafe("color", "#232323 !important"),
      /* [] */0
    ]);

var currentPlanName = Css.style(/* :: */[
      Css.unsafe("color", "#232323 !important"),
      /* :: */[
        Css.textTransform(Css.capitalize),
        /* [] */0
      ]
    ]);

var component$3 = RR$BsConsole.reducerComponent("SettingsBillingPlans-BsConsole__PlanSelect");

function make$3(subscription, plansData, isAdmin, billingInvoicesRemote, bustBillingInvoicesCache, bustBillingSubscriptionCache, token, handleChangeUrl, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: (function (param) {
              var send = param.send;
              var planNames = Belt_Array.map(plansData.plans, (function (plan) {
                      if (plan.name.includes("custom")) {
                        return "custom";
                      } else {
                        return plan.name;
                      }
                    }));
              var index = planNames.indexOf(subscription.current.plan.name);
              var currentPeriod = subscription.current.period === "year" ? /* Annual */0 : /* Monthly */1;
              var match = param.state.period;
              if (match) {
                if (currentPeriod) {
                  return Curry._1(send, /* SetLowestAvailable */Block.__(0, [index + 1 | 0]));
                } else {
                  return Curry._1(send, /* SetLowestAvailable */Block.__(0, [planNames.length]));
                }
              } else if (currentPeriod && !subscription.current.plan.name.includes("custom")) {
                return Curry._1(send, /* SetLowestAvailable */Block.__(0, [index]));
              } else {
                return Curry._1(send, /* SetLowestAvailable */Block.__(0, [index + 1 | 0]));
              }
            }),
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (param) {
              var send = param.send;
              var state = param.state;
              var next = subscription.next;
              var tmp;
              var exit = 0;
              if (typeof billingInvoicesRemote === "number" || billingInvoicesRemote.tag) {
                exit = 1;
              } else {
                var invoices = billingInvoicesRemote[0];
                var hasValidCard = invoices.customer.cardStatus === "valid";
                var match = state.chosenPlan;
                var disabled = match !== undefined ? (
                    match[0] === "free" ? !isAdmin : !isAdmin || !hasValidCard
                  ) : true;
                var tmp$1;
                if (hasValidCard) {
                  var card = invoices.customer.card;
                  tmp$1 = ReasonReact.element(undefined, undefined, SettingsBillingPaymentView$BsConsole.make(token, (function (response) {
                              if (response) {
                                return Curry._1(bustBillingInvoicesCache, undefined);
                              } else {
                                console.log("something went wrong");
                                return ;
                              }
                            }), undefined, [ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                          className: Styles$BsConsole.$$Text.tooltipTitle
                                        }, card !== undefined ? Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                    /* String_literal */Block.__(11, [
                                                        "Current payment method - card ending in ",
                                                        /* String */Block.__(2, [
                                                            /* No_padding */0,
                                                            /* End_of_format */0
                                                          ])
                                                      ]),
                                                    "Current payment method - card ending in %s"
                                                  ]), card) : I18N$BsConsole.show(undefined, "There is no payment method associated with this account")), undefined, /* Top */4202101, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                /* Primary */-791844958,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [I18N$BsConsole.show(undefined, "modify payment method")]
                                              ]))]))]));
                } else {
                  tmp$1 = ReasonReact.element(undefined, undefined, SettingsBillingPaymentView$BsConsole.make(token, (function (response) {
                              if (response) {
                                return Curry._1(bustBillingInvoicesCache, undefined);
                              } else {
                                console.log("something went wrong");
                                return ;
                              }
                            }), undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      /* Primary */-791844958,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [I18N$BsConsole.show(undefined, "Add card")]
                                    ]))]));
                }
                tmp = ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                          tmp$1,
                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                    undefined,
                                    undefined,
                                    (function (_event) {
                                        var plan = state.chosenPlan;
                                        if (plan !== undefined) {
                                          return Curry._1(send, /* Dialog */Block.__(2, [/* Upgrade */[plan]]));
                                        }
                                        
                                      }),
                                    undefined,
                                    undefined,
                                    /* Raised */-387916264,
                                    /* Primary */-791844958,
                                    undefined,
                                    undefined,
                                    disabled,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    [I18N$BsConsole.show(undefined, "upgrade plan")]
                                  ]))
                        ]));
              }
              if (exit === 1) {
                tmp = ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          /* Raised */-387916264,
                          undefined,
                          undefined,
                          undefined,
                          true,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          [I18N$BsConsole.show(undefined, "upgrade plan")]
                        ]));
              }
              var match$1 = state.dialog;
              var tmp$2;
              if (match$1 !== undefined) {
                var plan = match$1[0];
                tmp$2 = ReasonReact.element(undefined, undefined, make(token, (function (changePlanRemote, handleChangePlan) {
                            return ReasonReact.element(undefined, undefined, make$1(plan, plansData.plans, plansData.features, state.period, (function (_event) {
                                              return Curry._1(send, /* Dialog */Block.__(2, [undefined]));
                                            }), billingInvoicesRemote, bustBillingSubscriptionCache, changePlanRemote, handleChangePlan, handleChangeUrl, []));
                          })));
              } else {
                tmp$2 = null;
              }
              return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [
                              ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                        isAdmin ? null : ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [React.createElement("span", {
                                                                    className: Css.style(/* :: */[
                                                                          Css.color(Css.hex(Colors$BsConsole.error)),
                                                                          /* [] */0
                                                                        ])
                                                                  }, I18N$BsConsole.show(undefined, "*only admins may modify current plan*"))]))])),
                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [
                                                            I18N$BsConsole.show(undefined, "Current plan: "),
                                                            React.createElement("span", {
                                                                  className: currentPlanName
                                                                }, Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                                                          /* String */Block.__(2, [
                                                                              /* No_padding */0,
                                                                              /* Char_literal */Block.__(12, [
                                                                                  /* " " */32,
                                                                                  /* String */Block.__(2, [
                                                                                      /* No_padding */0,
                                                                                      /* End_of_format */0
                                                                                    ])
                                                                                ])
                                                                            ]),
                                                                          "%s %s"
                                                                        ]), subscription.current.plan.name, subscription.current.period === "month" ? I18N$BsConsole.get(undefined, "monthly") : I18N$BsConsole.get(undefined, "annual")))
                                                          ]))])),
                                        next !== undefined ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [
                                                              I18N$BsConsole.show(undefined, "Plan is scheduled to change to "),
                                                              React.createElement("span", {
                                                                    className: currentPlanName
                                                                  }, Curry._2(I18N$BsConsole.showSkipf(/* Format */[
                                                                            /* String */Block.__(2, [
                                                                                /* No_padding */0,
                                                                                /* Char_literal */Block.__(12, [
                                                                                    /* " " */32,
                                                                                    /* String */Block.__(2, [
                                                                                        /* No_padding */0,
                                                                                        /* End_of_format */0
                                                                                      ])
                                                                                  ])
                                                                              ]),
                                                                            "%s %s"
                                                                          ]), next.plan.name, next.period === "month" ? I18N$BsConsole.get(undefined, "monthly") : I18N$BsConsole.get(undefined, "annual"))),
                                                              I18N$BsConsole.show(undefined, " after current cycle")
                                                            ]))])) : null,
                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, [
                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, {
                                                            caption: state.period === /* Annual */0 ? activePeriod : ""
                                                          }, undefined, [I18N$BsConsole.show(undefined, "Annual")])),
                                                  ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Switch.Jsx2.make, undefined, /* Default */-384499551, /* `Bool */[
                                                            737456202,
                                                            state.period === /* Monthly */1
                                                          ], (function (_event, param) {
                                                              return Curry._1(send, /* TogglePeriod */0);
                                                            }), undefined, undefined, undefined, [])),
                                                  ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, {
                                                            caption: state.period === /* Monthly */1 ? activePeriod : ""
                                                          }, undefined, [I18N$BsConsole.show(undefined, "Monthly")]))
                                                ])),
                                        ReasonReact.element(undefined, undefined, make$2(plansData.plans, state.period, plansData.features, subscription.current.plan, state.chosenPlan, (function (plan) {
                                                    return Curry._1(send, /* SetChosenPlan */Block.__(1, [plan]));
                                                  }), state.lowestAvailable, [])),
                                        React.createElement("br", undefined),
                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, undefined, [
                                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                            ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [
                                                                      React.createElement("span", {
                                                                            className: "extra-smaller-icon"
                                                                          }, ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Check.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))),
                                                                      I18N$BsConsole.show(undefined, " - Current plan")
                                                                    ])),
                                                            state.period === /* Annual */0 ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "* - Plan is only available in a monthly subscription")])) : null
                                                          ])),
                                                  tmp
                                                ]))
                                      ])),
                              tmp$2
                            ]));
            }),
          initialState: (function (param) {
              return {
                      period: /* Annual */0,
                      chosenPlan: undefined,
                      lowestAvailable: 0,
                      dialog: undefined
                    };
            }),
          retainedProps: component$3.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                var match = state.period;
                var period = match ? /* Annual */0 : /* Monthly */1;
                var currentPeriod = subscription.current.period === "year" ? /* Annual */0 : /* Monthly */1;
                var planNames = Belt_Array.map(plansData.plans, (function (plan) {
                        if (plan.name.includes("custom")) {
                          return "custom";
                        } else {
                          return plan.name;
                        }
                      }));
                var index = planNames.indexOf(subscription.current.plan.name);
                var match$1 = Belt_Option.getWithDefault(state.chosenPlan, /* tuple */[
                      "",
                      0
                    ]);
                var lowestAvailable = period ? (
                    currentPeriod ? index + 1 | 0 : planNames.length
                  ) : (
                    currentPeriod && !subscription.current.plan.name.includes("custom") ? index : index + 1 | 0
                  );
                return /* Update */Block.__(0, [{
                            period: period,
                            chosenPlan: match$1[1] >= lowestAvailable ? state.chosenPlan : undefined,
                            lowestAvailable: lowestAvailable,
                            dialog: state.dialog
                          }]);
              }
              switch (action.tag | 0) {
                case /* SetLowestAvailable */0 :
                    return /* Update */Block.__(0, [{
                                period: state.period,
                                chosenPlan: state.chosenPlan,
                                lowestAvailable: action[0],
                                dialog: state.dialog
                              }]);
                case /* SetChosenPlan */1 :
                    return /* Update */Block.__(0, [{
                                period: state.period,
                                chosenPlan: action[0],
                                lowestAvailable: state.lowestAvailable,
                                dialog: state.dialog
                              }]);
                case /* Dialog */2 :
                    return /* Update */Block.__(0, [{
                                period: state.period,
                                chosenPlan: state.chosenPlan,
                                lowestAvailable: state.lowestAvailable,
                                dialog: action[0]
                              }]);
                
              }
            }),
          jsElementWrapped: component$3.jsElementWrapped
        };
}

var PlanSelect = {
  activePeriod: activePeriod,
  currentPlanName: currentPlanName,
  component: component$3,
  make: make$3
};

var buttonContainer = Css.style(/* :: */[
      Css.display(Css.inlineFlex),
      /* :: */[
        Css.alignItems(Css.center),
        /* :: */[
          Css.cursor(/* pointer */-786317123),
          /* [] */0
        ]
      ]
    ]);

var link$1 = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.blue)),
      /* [] */0
    ]);

var component$4 = RR$BsConsole.statelessComponent("SettingsBillingPlans-BsConsole");

function make$4(handleChangeUrl, handleTask, shell, token, _children) {
  return {
          debugName: component$4.debugName,
          reactClassInternal: component$4.reactClassInternal,
          handedOffState: component$4.handedOffState,
          willReceiveProps: component$4.willReceiveProps,
          didMount: component$4.didMount,
          didUpdate: component$4.didUpdate,
          willUnmount: component$4.willUnmount,
          willUpdate: component$4.willUpdate,
          shouldUpdate: component$4.shouldUpdate,
          render: (function (_self) {
              var tmp;
              if (typeof shell === "number" || shell.tag) {
                tmp = null;
              } else {
                var config = shell[0];
                tmp = ReasonReact.element(undefined, undefined, Key$BsConsole.make((function (billingSubscriptionKey, bustBillingSubscriptionCache) {
                            return ReasonReact.element(billingSubscriptionKey, undefined, BillingSubscriptionFetch$BsConsole.make(handleTask, token, (function (remote) {
                                              return ReasonReact.element(undefined, undefined, BillingPlansFetch$BsConsole.make(handleTask, token, (function (remote$prime) {
                                                                var exit = 0;
                                                                var msg;
                                                                var exit$1 = 0;
                                                                var exit$2 = 0;
                                                                var exit$3 = 0;
                                                                if (typeof remote === "number") {
                                                                  if (remote === /* NotAsked */0) {
                                                                    exit$3 = 5;
                                                                  } else {
                                                                    exit = 1;
                                                                  }
                                                                } else if (remote.tag) {
                                                                  exit$3 = 5;
                                                                } else {
                                                                  var subscription = remote[0];
                                                                  if (typeof remote$prime === "number") {
                                                                    if (remote$prime === /* NotAsked */0) {
                                                                      return null;
                                                                    }
                                                                    exit$3 = 5;
                                                                  } else if (remote$prime.tag) {
                                                                    exit$1 = 3;
                                                                  } else {
                                                                    var plansData = remote$prime[0];
                                                                    return ReasonReact.element(undefined, undefined, Key$BsConsole.make((function (billingInvoicesKey, bustBillingInvoicesCache) {
                                                                                      return ReasonReact.element(billingInvoicesKey, undefined, BillingInvoicesFetch$BsConsole.make(handleTask, token, (function (billingInvoicesRemote) {
                                                                                                        var match = config.user.role;
                                                                                                        var tmp;
                                                                                                        tmp = match === "admin" ? true : false;
                                                                                                        return ReasonReact.element(undefined, undefined, make$3(subscription, plansData, tmp, billingInvoicesRemote, bustBillingInvoicesCache, bustBillingSubscriptionCache, token, handleChangeUrl, []));
                                                                                                      })));
                                                                                    })));
                                                                  }
                                                                }
                                                                if (exit$3 === 5) {
                                                                  if (typeof remote$prime === "number" && remote$prime !== 0) {
                                                                    exit = 1;
                                                                  } else {
                                                                    exit$2 = 4;
                                                                  }
                                                                }
                                                                if (exit$2 === 4) {
                                                                  if (typeof remote === "number") {
                                                                    exit$1 = 3;
                                                                  } else {
                                                                    msg = remote[0];
                                                                    exit = 2;
                                                                  }
                                                                }
                                                                if (exit$1 === 3) {
                                                                  if (typeof remote$prime === "number") {
                                                                    return null;
                                                                  }
                                                                  if (!remote$prime.tag) {
                                                                    return null;
                                                                  }
                                                                  msg = remote$prime[0];
                                                                  exit = 2;
                                                                }
                                                                switch (exit) {
                                                                  case 1 :
                                                                      return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element("plans-loading", undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 150, "inherit", undefined, undefined, []))]))]))]));
                                                                  case 2 :
                                                                      return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.Paper.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(msg)]));
                                                                  
                                                                }
                                                              })));
                                            })));
                          })));
              }
              var supportLink = Href$BsConsole.Support.make(undefined, /* ArticlePricingTierFeatures */3);
              return ReasonReact.element(undefined, undefined, SettingsContainer$BsConsole.make([
                              React.createElement("div", {
                                    className: buttonContainer,
                                    onClick: (function (_event) {
                                        return Curry._1(handleChangeUrl, /* SettingsBilling */18);
                                      })
                                  }, ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                            undefined,
                                            undefined,
                                            (function (_event) {
                                                return Curry._1(handleChangeUrl, /* SettingsBilling */18);
                                              }),
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronLeft.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                          ])), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Back to billing settings")]))),
                              tmp,
                              React.createElement("br", undefined),
                              React.createElement("br", undefined),
                              supportLink !== undefined ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [
                                                    I18N$BsConsole.show(undefined, "Need to handle high workloads, advanced security features or more customization? No problem! Click "),
                                                    React.createElement("a", {
                                                          className: link$1,
                                                          href: supportLink,
                                                          rel: "noreferrer",
                                                          target: "_blank"
                                                        }, I18N$BsConsole.show(undefined, "here")),
                                                    I18N$BsConsole.show(undefined, " to learn more, or contact us at "),
                                                    React.createElement("a", {
                                                          className: link$1,
                                                          href: "mailto:" + Email$BsConsole.sales
                                                        }, I18N$BsConsole.showSkip(Email$BsConsole.sales)),
                                                    I18N$BsConsole.showSkip(".")
                                                  ]))])) : null
                            ]));
            }),
          initialState: component$4.initialState,
          retainedProps: component$4.retainedProps,
          reducer: component$4.reducer,
          jsElementWrapped: component$4.jsElementWrapped
        };
}

exports.FetchChangePlan = FetchChangePlan;
exports.UpgradeModal = UpgradeModal;
exports.PlansTable = PlansTable;
exports.PlanSelect = PlanSelect;
exports.buttonContainer = buttonContainer;
exports.link = link$1;
exports.component = component$4;
exports.make = make$4;
/* component Not a pure module */
