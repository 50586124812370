// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

function fromJson(json) {
  var tmp;
  try {
    tmp = Json_decode.oneOf(/* :: */[
          (function (json) {
              return /* SourceCode */Block.__(0, [{
                          startColumn: Json_decode.field("startColumn", Json_decode.$$int, json),
                          startLine: Json_decode.field("startLine", Json_decode.$$int, json),
                          tabWidth: Json_decode.optional((function (param) {
                                  return Json_decode.field("tabWidth", Json_decode.$$int, param);
                                }), json),
                          startPos: Json_decode.optional((function (param) {
                                  return Json_decode.field("startPos", Json_decode.$$int, param);
                                }), json),
                          text: Json_decode.field("text", Json_decode.string, json)
                        }]);
            }),
          /* :: */[
            (function (json) {
                return /* LogFile */Block.__(1, [{
                            highlightLine: Json_decode.field("highlightLine", Json_decode.bool, json),
                            text: Json_decode.field("text", Json_decode.string, json),
                            title: Json_decode.field("title", Json_decode.string, json),
                            typ: Json_decode.field("type", Json_decode.string, json)
                          }]);
              }),
            /* :: */[
              (function (json) {
                  var typ = Json_decode.field("type", Json_decode.string, json);
                  if (typ === "html-attachment") {
                    return /* HtmlAttachment */Block.__(2, [Json_decode.field("attachment", Json_decode.string, json)]);
                  }
                  throw [
                        Caml_builtin_exceptions.assert_failure,
                        /* tuple */[
                          "BugSourceCode.re",
                          55,
                          28
                        ]
                      ];
                }),
              /* [] */0
            ]
          ]
        ], json);
  }
  catch (exn){
    tmp = undefined;
  }
  return {
          path: Json_decode.optional((function (param) {
                  return Json_decode.field("path", Json_decode.string, param);
                }), json),
          source: tmp
        };
}

exports.fromJson = fromJson;
/* No side effect */
