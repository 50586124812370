// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Printf = require("bs-platform/lib/js/printf.js");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Email$BsConsole = require("../Email.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var Numeral$BsConsole = require("../Numeral.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Services$BsConsole = require("../Services.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var Tab = require("@material-ui/core/Tab");
var Chip = require("@material-ui/core/Chip");
var Tabs = require("@material-ui/core/Tabs");
var BtTypography$BsConsole = require("../BtTypography.js");
var TokenContext$BsConsole = require("../context/TokenContext.js");
var Paper = require("@material-ui/core/Paper");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Switch = require("@material-ui/core/Switch");
var DataSourcesApi$BsConsole = require("./DataSourcesApi.js");
var L10N_date_time$BsConsole = require("../L10N_date_time.js");
var Divider = require("@material-ui/core/Divider");
var Popover = require("@material-ui/core/Popover");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var TextField = require("@material-ui/core/TextField");
var MoreVert = require("@material-ui/icons/MoreVert");
var IconButton = require("@material-ui/core/IconButton");
var Typography = require("@material-ui/core/Typography");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");
var CardContent = require("@material-ui/core/CardContent");
var L10N_date_time_clock$BsConsole = require("../L10N_date_time_clock.js");
var ArrowForward = require("@material-ui/icons/ArrowForward");
var CircularProgress = require("@material-ui/core/CircularProgress");
var DataSourcesConnectionForm$BsConsole = require("./DataSourcesConnectionForm.js");

var minimumSwitchVersion = "1.3.1";

var minimumXBoxAndPS5Version = "1.4.0";

var containerWithBorder = Css.style(/* :: */[
      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
      /* :: */[
        Css.borderRadius(Css.px(3)),
        /* [] */0
      ]
    ]);

var cardContainer = Css.style(/* :: */[
      Css.padding2(Css.em(0.5), Css.em(1)),
      /* :: */[
        Css.marginBottom(Css.em(1.0)),
        /* :: */[
          Css.marginRight(Css.rem(1.0)),
          /* :: */[
            Css.unsafe("width", "calc(50% - 1rem)"),
            /* [] */0
          ]
        ]
      ]
    ]);

var rowHeaderRow = Css.style(/* :: */[
      Css.marginBottom(Css.em(1)),
      /* [] */0
    ]);

var rowDescriptor = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* :: */[
        Css.marginRight(Css.rem(0.5)),
        /* [] */0
      ]
    ]);

var rowDescriptionRow = Css.style(/* :: */[
      Css.unsafe("marginLeft", "calc(1rem + 28px)"),
      /* :: */[
        Css.selector("> div", /* :: */[
              Css.marginRight(Css.em(1)),
              /* [] */0
            ]),
        /* :: */[
          Css.marginBottom(Css.em(1)),
          /* :: */[
            Css.fontSize(Css.px(13)),
            /* [] */0
          ]
        ]
      ]
    ]);

var informationRow = Css.style(/* :: */[
      Css.marginBottom(Css.rem(0.3)),
      /* :: */[
        Css.fontSize(Css.px(13)),
        /* [] */0
      ]
    ]);

var column = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.selector("> div:not(:last-child)", /* :: */[
                Css.marginBottom(Css.rem(0.3)),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var Style = {
  containerWithBorder: containerWithBorder,
  cardContainer: cardContainer,
  rowHeaderRow: rowHeaderRow,
  rowDescriptor: rowDescriptor,
  rowDescriptionRow: rowDescriptionRow,
  informationRow: informationRow,
  column: column
};

function PS_ErrorSubmissionDataSources$Logs(Props) {
  var getLogsRemote = Props.getLogsRemote;
  var tmp;
  if (typeof getLogsRemote === "number") {
    tmp = getLogsRemote === /* NotAsked */0 ? null : React.createElement(Row2$BsConsole.make, {
            justifyContent: /* center */98248149,
            className: Css.style(/* :: */[
                  Css.margin2(Css.rem(2), Css.zero),
                  /* [] */0
                ]),
            children: React.createElement(CircularProgress.default, {
                  color: "inherit"
                })
          });
  } else if (getLogsRemote.tag) {
    tmp = null;
  } else {
    var logs = getLogsRemote[0][0][0];
    tmp = Belt_List.length(logs) > 0 ? Belt_List.toArray(Belt_List.map(logs, (function (log) {
                  return React.createElement("div", undefined, I18N$BsConsole.showSkip(log.creationDate + (": " + log.message)));
                }))) : I18N$BsConsole.show(undefined, "No logs exist for this connection");
  }
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.backgroundColor(Css.hex(Colors$BsConsole.grey1)),
                    /* :: */[
                      Css.color(Css.hex(Colors$BsConsole.white)),
                      /* :: */[
                        Css.overflow(Css.auto),
                        /* :: */[
                          Css.padding(Css.rem(0.5)),
                          /* :: */[
                            Css.display(/* flex */-1010954439),
                            /* :: */[
                              Css.flexDirection(/* column */-963948842),
                              /* :: */[
                                Css.fontFamily("Inconsolata"),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, tmp);
}

var Logs = {
  make: PS_ErrorSubmissionDataSources$Logs
};

function displayLastDownloadDate(jsDate) {
  return Curry._2(Printf.sprintf(/* Format */[
                  /* String */Block.__(2, [
                      /* No_padding */0,
                      /* Char_literal */Block.__(12, [
                          /* " " */32,
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* End_of_format */0
                            ])
                        ])
                    ]),
                  "%s %s"
                ]), L10N_date_time$BsConsole.Format.textDay(undefined, jsDate), L10N_date_time_clock$BsConsole.Format.getGMTOffset(jsDate));
}

function PS_ErrorSubmissionDataSources$ConnectionInfo(Props) {
  var dataSource = Props.dataSource;
  var date = DataSourcesApi$BsConsole.getStatistics(dataSource).lastDownloadDate;
  return React.createElement(Row2$BsConsole.make, {
              className: informationRow,
              children: null
            }, React.createElement("div", {
                  className: column
                }, React.createElement("div", {
                      className: rowDescriptor
                    }, I18N$BsConsole.show(undefined, "Connection status:")), React.createElement("div", {
                      className: rowDescriptor
                    }, I18N$BsConsole.show(undefined, "Last file download time:")), React.createElement("div", {
                      className: rowDescriptor
                    }, I18N$BsConsole.show(undefined, "Total files downloaded:")), React.createElement("div", {
                      className: rowDescriptor
                    }, I18N$BsConsole.show(undefined, "Total bytes downloaded:")), React.createElement("div", {
                      className: rowDescriptor
                    }, I18N$BsConsole.show(undefined, "Total failed downloads:"))), React.createElement("div", {
                  className: column
                }, React.createElement("div", undefined, I18N$BsConsole.showSkip(DataSourcesApi$BsConsole.getStatus(dataSource))), React.createElement("div", undefined, I18N$BsConsole.showSkip(date !== undefined ? displayLastDownloadDate(Caml_option.valFromOption(date)) : "\u2014")), React.createElement("div", undefined, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", Pervasives.string_of_float(DataSourcesApi$BsConsole.getStatistics(dataSource).totalNumberOfDownloadedFiles)))), React.createElement("div", undefined, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0.0b", Pervasives.string_of_float(DataSourcesApi$BsConsole.getStatistics(dataSource).totalNumberOfDownloadedBytes)))), React.createElement("div", undefined, I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", Pervasives.string_of_float(DataSourcesApi$BsConsole.getStatistics(dataSource).totalNumberOfFailures))))));
}

var ConnectionInfo = {
  displayLastDownloadDate: displayLastDownloadDate,
  make: PS_ErrorSubmissionDataSources$ConnectionInfo
};

function PS_ErrorSubmissionDataSources$ConnectionActions(Props) {
  var dataSource = Props.dataSource;
  var serviceEndpoint = Props.serviceEndpoint;
  var token = Props.token;
  var refetch = Props.refetch;
  var setEdit = Props.setEdit;
  var onDeleteOpt = Props.onDelete;
  var onDelete = onDeleteOpt !== undefined ? onDeleteOpt : (function (param) {
        
      });
  var match = React.useState((function () {
          return false;
        }));
  var setDeleteDialogState = match[1];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setDisableDialogState = match$1[1];
  var match$2 = React.useState((function () {
          
        }));
  var setAnchorEl = match$2[1];
  var anchorEl = match$2[0];
  var deleteTask = DataSourcesApi$BsConsole.$$delete(serviceEndpoint, DataSourcesApi$BsConsole.getId(dataSource));
  var disabled;
  switch (dataSource.tag | 0) {
    case /* NintendoSwitch */1 :
        disabled = dataSource[0].disabled;
        break;
    case /* Playstation4 */0 :
    case /* XBox */2 :
        disabled = dataSource[0].disabled;
        break;
    case /* Playstation5 */3 :
        disabled = dataSource[0].disabled;
        break;
    case /* Unity */4 :
        disabled = dataSource[0].disabled;
        break;
    
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  children: null
                }, React.createElement(Switch.default, {
                      color: "primary",
                      onChange: (function (_event, _value) {
                          return Curry._1(setDisableDialogState, (function (param) {
                                        return true;
                                      }));
                        }),
                      checked: !disabled
                    }), React.createElement(IconButton.default, {
                      classes: {
                        root: "smaller-icon"
                      },
                      onClick: (function ($$event) {
                          return Curry._1(setAnchorEl, (function (param) {
                                        return $$event.currentTarget;
                                      }));
                        }),
                      children: React.createElement(MoreVert.default, { })
                    })), React.createElement(Popover.default, {
                  anchorEl: anchorEl,
                  open: Belt_Option.isSome(anchorEl),
                  onClose: (function (param) {
                      return Curry._1(setAnchorEl, (function (param) {
                                    
                                  }));
                    }),
                  children: React.createElement(MenuList.default, {
                        children: null
                      }, React.createElement(MenuItem.default, {
                            onClick: (function (_event) {
                                Curry._1(setEdit, dataSource);
                                return Curry._1(setAnchorEl, (function (param) {
                                              
                                            }));
                              }),
                            children: I18N$BsConsole.show(undefined, "Edit")
                          }), React.createElement(MenuItem.default, {
                            onClick: (function (param) {
                                Curry._1(setDeleteDialogState, (function (param) {
                                        return true;
                                      }));
                                return Curry._1(setAnchorEl, (function (param) {
                                              
                                            }));
                              }),
                            children: I18N$BsConsole.show(undefined, "Delete")
                          }))
                }), React.createElement(Dialog.default, {
                  open: match[0],
                  onClose: (function (param) {
                      return Curry._1(setDeleteDialogState, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: React.createElement(CardContent.default, {
                        children: null
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Body1 */8,
                            children: I18N$BsConsole.show(undefined, "Are you sure you would like to delete this connection?")
                          }), React.createElement(Row2$BsConsole.make, {
                            justifyContent: /* flexEnd */924268066,
                            children: null
                          }, React.createElement(Button.default, {
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.marginRight(Css.rem(0.5)),
                                        /* [] */0
                                      ])
                                },
                                onClick: (function (param) {
                                    return Curry._1(setDeleteDialogState, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Cancel")
                              }), React.createElement(Button.default, {
                                variant: "raised",
                                color: "primary",
                                onClick: (function (param) {
                                    return Task2$BsConsole.handle(token, deleteTask, undefined, (function (param) {
                                                  if (param.tag) {
                                                    Curry._1(setDeleteDialogState, (function (param) {
                                                            return false;
                                                          }));
                                                    Curry._1(refetch, undefined);
                                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "There was an error"));
                                                  }
                                                  Curry._1(setDeleteDialogState, (function (param) {
                                                          return false;
                                                        }));
                                                  Curry._1(onDelete, undefined);
                                                  Curry._1(refetch, undefined);
                                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                      /* String_literal */Block.__(11, [
                                                                          "Successfully deleted data source ",
                                                                          /* String */Block.__(2, [
                                                                              /* No_padding */0,
                                                                              /* End_of_format */0
                                                                            ])
                                                                        ]),
                                                                      "Successfully deleted data source %s"
                                                                    ]), DataSourcesApi$BsConsole.getName(dataSource)));
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Delete")
                              })))
                }), React.createElement(Dialog.default, {
                  open: match$1[0],
                  onClose: (function (param) {
                      return Curry._1(setDisableDialogState, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: React.createElement(CardContent.default, {
                        children: null
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Body1 */8,
                            children: disabled ? I18N$BsConsole.show(undefined, "Are you sure you would like to enable this connection?") : I18N$BsConsole.show(undefined, "Are you sure you would like to disable this connection?")
                          }), React.createElement(Row2$BsConsole.make, {
                            justifyContent: /* flexEnd */924268066,
                            children: null
                          }, React.createElement(Button.default, {
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.marginRight(Css.rem(0.5)),
                                        /* [] */0
                                      ])
                                },
                                onClick: (function (param) {
                                    return Curry._1(setDisableDialogState, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Cancel")
                              }), React.createElement(Button.default, {
                                variant: "raised",
                                color: "primary",
                                onClick: (function (param) {
                                    var arg = DataSourcesApi$BsConsole.toggleDisabled(DataSourcesApi$BsConsole.getId(dataSource), serviceEndpoint, !disabled);
                                    return Curry._2((function (param) {
                                                    return (function (param$1, param$2) {
                                                        return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                      });
                                                  })(token), undefined, (function (param) {
                                                  if (param.tag) {
                                                    Curry._1(setDisableDialogState, (function (param) {
                                                            return false;
                                                          }));
                                                    Curry._1(refetch, undefined);
                                                    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "There was an error"));
                                                  }
                                                  Curry._1(setDisableDialogState, (function (param) {
                                                          return false;
                                                        }));
                                                  Curry._1(refetch, undefined);
                                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                      /* String_literal */Block.__(11, [
                                                                          "Successfully updated data source ",
                                                                          /* String */Block.__(2, [
                                                                              /* No_padding */0,
                                                                              /* End_of_format */0
                                                                            ])
                                                                        ]),
                                                                      "Successfully updated data source %s"
                                                                    ]), DataSourcesApi$BsConsole.getName(dataSource)));
                                                }));
                                  }),
                                children: disabled ? I18N$BsConsole.show(undefined, "Enable") : I18N$BsConsole.show(undefined, "Disable")
                              })))
                }));
}

var ConnectionActions = {
  make: PS_ErrorSubmissionDataSources$ConnectionActions
};

function PS_ErrorSubmissionDataSources$DetailsDialog(Props) {
  var setEdit = Props.setEdit;
  var _open = Props.open;
  var dataSource = Props.dataSource;
  var refetch = Props.refetch;
  var onClose = Props.onClose;
  var serviceEndpoint = Props.serviceEndpoint;
  var token = Props.token;
  var match = React.useState((function () {
          return 7;
        }));
  var setDownloadDays = match[1];
  var downloadDays = match[0];
  var match$1 = React.useState((function () {
          return /* Logs */0;
        }));
  var setTab = match$1[1];
  var tab = match$1[0];
  var match$2 = Task2$BsConsole.useRefetchable(undefined, React.useMemo((function () {
              return DataSourcesApi$BsConsole.getLogs(serviceEndpoint, DataSourcesApi$BsConsole.getId(dataSource), undefined, undefined, undefined, undefined);
            }), /* tuple */[
            serviceEndpoint,
            DataSourcesApi$BsConsole.getId(dataSource)
          ]), undefined);
  var getLogsRefetch = match$2[1];
  var match$3 = DataSourcesApi$BsConsole.getLogoInfo(dataSource);
  var subheading;
  switch (dataSource.tag | 0) {
    case /* Playstation4 */0 :
        subheading = dataSource[0].serverUrl;
        break;
    case /* NintendoSwitch */1 :
        subheading = dataSource[0].serverUrl;
        break;
    case /* XBox */2 :
        subheading = dataSource[0].bucketName;
        break;
    case /* Playstation5 */3 :
        subheading = dataSource[0].serverUrl;
        break;
    case /* Unity */4 :
        subheading = undefined;
        break;
    
  }
  var tmp;
  tmp = dataSource.tag === /* Unity */4 ? null : React.createElement(PS_ErrorSubmissionDataSources$ConnectionActions, {
          dataSource: dataSource,
          serviceEndpoint: serviceEndpoint,
          token: token,
          refetch: refetch,
          setEdit: setEdit,
          onDelete: onClose
        });
  var tmp$1;
  tmp$1 = dataSource.tag === /* Unity */4 ? null : React.createElement("div", {
          className: Css.style(/* :: */[
                Css.marginTop(Css.rem(1.0)),
                /* :: */[
                  Css.marginBottom(Css.rem(1.0)),
                  /* [] */0
                ]
              ])
        }, React.createElement(Typography.default, {
              variant: "subtitle2",
              classes: {
                root: Css.marginBottom(Css.rem(0.5))
              },
              children: I18N$BsConsole.show(undefined, "Download missing errors")
            }), React.createElement("div", {
              className: Css.merge(/* :: */[
                    Css.style(/* :: */[
                          Css.fontSize(Css.px(13)),
                          /* [] */0
                        ]),
                    /* :: */[
                      rowDescriptor,
                      /* [] */0
                    ]
                  ])
            }, I18N$BsConsole.show(undefined, "You can download errors missing from Backtrace by specifying a time range below.")), React.createElement(Row2$BsConsole.make, {
              alignItems: /* baseline */287825029,
              className: Css.style(/* :: */[
                    Css.fontSize(Css.px(13)),
                    /* :: */[
                      Css.marginBottom(Css.rem(0.5)),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement("div", {
                  className: rowDescriptor
                }, I18N$BsConsole.show(undefined, "Download all errors from the last ")), React.createElement(TextField.default, {
                  helperText: "",
                  error: false,
                  placeholder: "",
                  value: downloadDays !== undefined ? String(downloadDays) : "",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.maxWidth(Css.px(50)),
                          /* :: */[
                            Css.margin2(Css.zero, Css.rem(0.5)),
                            /* :: */[
                              Css.selector("input", /* :: */[
                                    Css.textAlign(/* center */98248149),
                                    /* :: */[
                                      Css.fontSize(Css.px(13)),
                                      /* :: */[
                                        Css.important(Css.padding(Css.zero)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ])
                  },
                  inputProps: {
                    type: "number",
                    min: 0
                  },
                  onChange: (function (e) {
                      var value = e.target.value;
                      var value$1 = value === "" ? undefined : Caml_format.caml_int_of_string(value);
                      return Curry._1(setDownloadDays, (function (param) {
                                    return value$1;
                                  }));
                    })
                }), React.createElement("div", {
                  className: rowDescriptor
                }, I18N$BsConsole.show(undefined, " days."))), React.createElement(Button.default, {
              variant: "outlined",
              color: "primary",
              disabled: downloadDays !== undefined ? downloadDays <= 0 : true,
              onClick: (function (param) {
                  var from_ = new Date();
                  var day = from_.getDate();
                  from_.setDate(day - Belt_Option.getWithDefault(downloadDays, 0));
                  var arg = DataSourcesApi$BsConsole.fetchRange(serviceEndpoint, DataSourcesApi$BsConsole.getId(dataSource), from_, new Date());
                  return Curry._2((function (param) {
                                  return (function (param$1, param$2) {
                                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                    });
                                })(token), undefined, (function (param) {
                                if (param.tag) {
                                  Curry._1(refetch, undefined);
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "There was an error queueing download request"));
                                }
                                Curry._1(refetch, undefined);
                                Curry._1(getLogsRefetch, undefined);
                                return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Successfully enqueued download request"));
                              }));
                }),
              children: I18N$BsConsole.show(undefined, "Download")
            }));
  return React.createElement(Dialog.default, {
              open: _open,
              classes: {
                paper: Css.style(/* :: */[
                      Css.minWidth(Css.px(900)),
                      /* [] */0
                    ])
              },
              onClose: (function (param) {
                  return Curry._1(onClose, undefined);
                }),
              children: null
            }, React.createElement(CardContent.default, {
                  children: React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        justifyContent: /* spaceBetween */516682146,
                        children: null
                      }, React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            children: null
                          }, React.createElement("img", {
                                className: Css.merge(/* :: */[
                                      Css.style(/* :: */[
                                            Css.marginRight(Css.rem(1)),
                                            /* [] */0
                                          ]),
                                      /* :: */[
                                        match$3[2],
                                        /* [] */0
                                      ]
                                    ]),
                                alt: match$3[1],
                                src: match$3[0]
                              }), React.createElement(Typography.default, {
                                variant: "h5",
                                children: I18N$BsConsole.showSkip(DataSourcesApi$BsConsole.getName(dataSource))
                              })), React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            children: React.createElement(Button.default, {
                                  variant: "text",
                                  color: "primary",
                                  onClick: (function (param) {
                                      return Curry._1(onClose, undefined);
                                    }),
                                  children: I18N$BsConsole.show(undefined, "Close")
                                })
                          }))
                }), React.createElement(Divider.default, { }), React.createElement(CardContent.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.overflowY(/* auto */-1065951377),
                          /* :: */[
                            Css.paddingTop(/* zero */-789508312),
                            /* [] */0
                          ]
                        ])
                  },
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      children: null
                    }, subheading !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Body1 */8,
                            className: Css.style(/* :: */[
                                  Css.overflow(/* hidden */-862584982),
                                  /* :: */[
                                    Css.whiteSpace(/* nowrap */867913355),
                                    /* :: */[
                                      Css.textOverflow(/* ellipsis */166888785),
                                      /* [] */0
                                    ]
                                  ]
                                ]),
                            children: I18N$BsConsole.showSkip(subheading)
                          }) : null, tmp), React.createElement(Typography.default, {
                      variant: "subtitle2",
                      classes: {
                        root: Css.marginBottom(Css.rem(0.5))
                      },
                      children: I18N$BsConsole.show(undefined, "Connection information")
                    }), React.createElement(PS_ErrorSubmissionDataSources$ConnectionInfo, {
                      dataSource: dataSource
                    }), tmp$1, React.createElement("div", {
                      className: containerWithBorder
                    }, React.createElement(Row2$BsConsole.make, {
                          alignItems: /* center */98248149,
                          justifyContent: /* spaceBetween */516682146,
                          className: Css.style(/* :: */[
                                Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
                                /* [] */0
                              ]),
                          children: null
                        }, React.createElement(Tabs.default, {
                              value: tab,
                              classes: {
                                indicator: Css.style(/* :: */[
                                      Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                                      /* [] */0
                                    ])
                              },
                              onChange: (function (_event, tab) {
                                  return Curry._1(setTab, (function (param) {
                                                return tab;
                                              }));
                                }),
                              children: null
                            }, React.createElement(Tab.default, {
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.textTransform(/* none */-922086728),
                                          /* [] */0
                                        ])
                                  },
                                  value: /* Logs */0,
                                  label: I18N$BsConsole.show(undefined, "Recent logs")
                                }), React.createElement(Tab.default, {
                                  classes: {
                                    root: Css.style(/* :: */[
                                          Css.textTransform(/* none */-922086728),
                                          /* [] */0
                                        ])
                                  },
                                  value: /* AttributeMapping */1,
                                  label: I18N$BsConsole.show(undefined, "Attribute name mapping")
                                })), tab ? null : React.createElement(Button.default, {
                                variant: "text",
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.marginRight(Css.rem(1.0)),
                                        /* [] */0
                                      ])
                                },
                                onClick: (function (param) {
                                    return Curry._1(getLogsRefetch, undefined);
                                  }),
                                children: I18N$BsConsole.show(undefined, "Refresh logs")
                              })), tab ? React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.display(/* flex */-1010954439),
                                  /* :: */[
                                    Css.padding(Css.rem(0.5)),
                                    /* :: */[
                                      Css.flexWrap(/* wrap */-822134326),
                                      /* :: */[
                                        Css.maxHeight(Css.px(350)),
                                        /* :: */[
                                          Css.overflowX(/* auto */-1065951377),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ])
                          }, Belt_Array.map(DataSourcesApi$BsConsole.getAttributeMapping(dataSource), (function (param) {
                                  return React.createElement(Chip.default, {
                                              label: React.createElement("span", {
                                                    className: Css.style(/* :: */[
                                                          Css.display(/* flex */-1010954439),
                                                          /* :: */[
                                                            Css.alignItems(/* center */98248149),
                                                            /* :: */[
                                                              Css.justifyContent(/* spaceBetween */516682146),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ]) + " smaller-icon"
                                                  }, I18N$BsConsole.showSkip(param[0]), React.createElement(ArrowForward.default, { }), I18N$BsConsole.showSkip(param[1])),
                                              variant: "outlined",
                                              size: "small",
                                              classes: {
                                                root: Css.style(/* :: */[
                                                      Css.marginRight(Css.px(6)),
                                                      /* :: */[
                                                        Css.marginBottom(Css.px(6)),
                                                        /* [] */0
                                                      ]
                                                    ])
                                              }
                                            });
                                })), DataSourcesApi$BsConsole.getAttributeMapping(dataSource).length === 0 ? React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Body1 */8,
                                  children: I18N$BsConsole.show(undefined, "No attribute mappings exist for this connection")
                                }) : null) : React.createElement(PS_ErrorSubmissionDataSources$Logs, {
                            getLogsRemote: match$2[0]
                          }))));
}

var DetailsDialog = {
  make: PS_ErrorSubmissionDataSources$DetailsDialog
};

function PS_ErrorSubmissionDataSources$DataSourceCard(Props) {
  var config = Props.config;
  var refetch = Props.refetch;
  var setEdit = Props.setEdit;
  var dataSource = Props.dataSource;
  var token = Belt_Option.getExn(React.useContext(TokenContext$BsConsole.ctx));
  var serviceEndpoint = Belt_Option.getExn(Services$BsConsole.getEndpoint(undefined)(config.services, "connector"));
  var match = DataSourcesApi$BsConsole.getLogoInfo(dataSource);
  var match$1 = React.useState((function () {
          return false;
        }));
  var setDialogState = match$1[1];
  var subheading;
  switch (dataSource.tag | 0) {
    case /* Playstation4 */0 :
        subheading = dataSource[0].serverUrl;
        break;
    case /* NintendoSwitch */1 :
        subheading = dataSource[0].serverUrl;
        break;
    case /* XBox */2 :
        subheading = dataSource[0].bucketName;
        break;
    case /* Playstation5 */3 :
        subheading = dataSource[0].serverUrl;
        break;
    case /* Unity */4 :
        subheading = undefined;
        break;
    
  }
  var tmp;
  tmp = dataSource.tag === /* Unity */4 ? null : React.createElement(PS_ErrorSubmissionDataSources$ConnectionActions, {
          dataSource: dataSource,
          serviceEndpoint: serviceEndpoint,
          token: token,
          refetch: refetch,
          setEdit: setEdit
        });
  return React.createElement(React.Fragment, undefined, React.createElement(Paper.default, {
                  classes: {
                    root: cardContainer
                  },
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      justifyContent: /* spaceBetween */516682146,
                      children: null
                    }, React.createElement("img", {
                          className: match[2],
                          alt: match[1],
                          src: match[0]
                        }), tmp), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.overflow(/* hidden */-862584982),
                            /* :: */[
                              Css.whiteSpace(/* nowrap */867913355),
                              /* :: */[
                                Css.textOverflow(/* ellipsis */166888785),
                                /* :: */[
                                  Css.marginBottom(Css.rem(0.25)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      children: I18N$BsConsole.showSkip(DataSourcesApi$BsConsole.getName(dataSource))
                    }), subheading !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: Css.style(/* :: */[
                              Css.overflow(/* hidden */-862584982),
                              /* :: */[
                                Css.whiteSpace(/* nowrap */867913355),
                                /* :: */[
                                  Css.textOverflow(/* ellipsis */166888785),
                                  /* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.grey4)),
                                    /* :: */[
                                      Css.marginBottom(Css.rem(0.25)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]),
                        children: I18N$BsConsole.showSkip(subheading)
                      }) : null, React.createElement(PS_ErrorSubmissionDataSources$ConnectionInfo, {
                      dataSource: dataSource
                    }), React.createElement(Button.default, {
                      variant: "text",
                      color: "primary",
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginLeft(Css.px(-8)),
                              /* [] */0
                            ])
                      },
                      onClick: (function (param) {
                          return Curry._1(setDialogState, (function (param) {
                                        return true;
                                      }));
                        }),
                      children: I18N$BsConsole.show(undefined, "More information")
                    })), React.createElement(PS_ErrorSubmissionDataSources$DetailsDialog, {
                  setEdit: setEdit,
                  open: match$1[0],
                  dataSource: dataSource,
                  refetch: refetch,
                  onClose: (function (param) {
                      return Curry._1(setDialogState, (function (param) {
                                    return false;
                                  }));
                    }),
                  serviceEndpoint: serviceEndpoint,
                  token: token
                }));
}

var DataSourceCard = {
  make: PS_ErrorSubmissionDataSources$DataSourceCard
};

function PS_ErrorSubmissionDataSources$List(Props) {
  var config = Props.config;
  var refetch = Props.refetch;
  var setEdit = Props.setEdit;
  var dataSources = Props.dataSources;
  return React.createElement(Row2$BsConsole.make, {
              flexWrap: /* wrap */-822134326,
              children: Belt_Array.mapWithIndex(dataSources, (function (i, dataSource) {
                      return React.createElement(PS_ErrorSubmissionDataSources$DataSourceCard, {
                                  config: config,
                                  refetch: refetch,
                                  setEdit: setEdit,
                                  dataSource: dataSource,
                                  key: String(i) + ("__" + DataSourcesApi$BsConsole.getName(dataSource))
                                });
                    }))
            });
}

var List = {
  make: PS_ErrorSubmissionDataSources$List
};

function PS_ErrorSubmissionDataSources$Main(Props) {
  var config = Props.config;
  var serviceEndpoint = Props.serviceEndpoint;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var match = React.useState((function () {
          
        }));
  var setDialogState = match[1];
  var dialog = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setIntegrationsPopoverAnchor = match$1[1];
  var integrationsPopoverAnchor = match$1[0];
  var match$2 = Task2$BsConsole.useRefetchable(undefined, React.useMemo((function () {
              return DataSourcesApi$BsConsole.get(serviceEndpoint, projectName);
            }), [projectName]), undefined);
  var refetchDataSourceSubscriptions = match$2[1];
  var dataSourcesRemote = match$2[0];
  var submissionTokenRemote = Task2$BsConsole.use(true, BpgTask$BsConsole.Token.fetchAll, undefined);
  var showNintendoSwitch;
  if (typeof dataSourcesRemote === "number" || dataSourcesRemote.tag) {
    showNintendoSwitch = false;
  } else {
    var version = dataSourcesRemote[0][0][1];
    if (version !== undefined) {
      var version$prime = Semver.valid(version);
      showNintendoSwitch = version$prime !== undefined && Semver.gte(version$prime, minimumSwitchVersion) ? true : false;
    } else {
      showNintendoSwitch = false;
    }
  }
  var showXBoxAndPS5;
  if (typeof dataSourcesRemote === "number" || dataSourcesRemote.tag) {
    showXBoxAndPS5 = false;
  } else {
    var version$1 = dataSourcesRemote[0][0][1];
    if (version$1 !== undefined) {
      var version$prime$1 = Semver.valid(version$1);
      showXBoxAndPS5 = version$prime$1 !== undefined && Semver.gte(version$prime$1, minimumXBoxAndPS5Version) ? true : false;
    } else {
      showXBoxAndPS5 = false;
    }
  }
  var tmp;
  var exit = 0;
  if (typeof dataSourcesRemote === "number") {
    exit = 1;
  } else {
    tmp = dataSourcesRemote.tag ? React.createElement("div", undefined, I18N$BsConsole.showSkip(Task2$BsConsole.renderError(undefined, dataSourcesRemote[0]))) : React.createElement(PS_ErrorSubmissionDataSources$List, {
            config: config,
            refetch: refetchDataSourceSubscriptions,
            setEdit: (function (dataSource) {
                return Curry._1(setDialogState, (function (param) {
                              return /* Modify */Block.__(1, [dataSource]);
                            }));
              }),
            dataSources: dataSourcesRemote[0][0][0]
          });
  }
  if (exit === 1) {
    tmp = React.createElement(Row2$BsConsole.make, {
          justifyContent: /* center */98248149,
          className: Css.style(/* :: */[
                Css.padding(Css.rem(1)),
                /* [] */0
              ]),
          children: React.createElement(CircularProgress.default, {
                size: 100,
                color: "primary"
              })
        });
  }
  var tmp$1;
  if (dialog !== undefined) {
    var tmp$2;
    var exit$1 = 0;
    if (typeof submissionTokenRemote === "number" || submissionTokenRemote.tag) {
      exit$1 = 1;
    } else {
      var project = Belt_Option.getExn(Belt_List.getBy(config.projects, (function (project) {
                  return project.name === projectName;
                })));
      var tokens = Belt_Array.keep(submissionTokenRemote[0][0], (function (token) {
              return token.project === project.pid;
            }));
      tmp$2 = React.createElement(DataSourcesConnectionForm$BsConsole.make, {
            config: config,
            form: dialog,
            refetch: refetchDataSourceSubscriptions,
            onClose: (function (param) {
                return Curry._1(setDialogState, (function (param) {
                              
                            }));
              }),
            tokens: tokens
          });
    }
    if (exit$1 === 1) {
      tmp$2 = React.createElement(CircularProgress.default, { });
    }
    tmp$1 = React.createElement(Dialog.default, {
          open: Belt_Option.isSome(dialog),
          classes: {
            paper: Css.style(/* :: */[
                  Css.maxWidth(Css.px(650)),
                  /* [] */0
                ])
          },
          onClose: (function (param) {
              return Curry._1(setDialogState, (function (param) {
                            
                          }));
            }),
          maxWidth: "lg",
          disableBackdropClick: true,
          children: tmp$2
        });
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(1)),
                        /* :: */[
                          Css.color(Css.hex(Colors$BsConsole.grey4)),
                          /* [] */0
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "\"Data Sources\" are for Backtrace to collect your error data from external storage platforms.")
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(1)),
                        /* [] */0
                      ])
                }), React.createElement(Button.default, {
                  variant: "raised",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(1)),
                          /* [] */0
                        ])
                  },
                  onClick: (function (e) {
                      var currTarget = e.currentTarget;
                      return Curry._1(setIntegrationsPopoverAnchor, (function (param) {
                                    return Caml_option.some(currTarget);
                                  }));
                    }),
                  children: I18N$BsConsole.show(undefined, "Add new integration")
                }), tmp, React.createElement(Popover.default, {
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                  transformOrigin: {
                    horizontal: "left",
                    vertical: "top"
                  },
                  anchorEl: integrationsPopoverAnchor,
                  open: Belt_Option.isSome(integrationsPopoverAnchor),
                  onClose: (function (param) {
                      return Curry._1(setIntegrationsPopoverAnchor, (function (param) {
                                    
                                  }));
                    }),
                  children: React.createElement(MenuList.default, {
                        children: null
                      }, React.createElement(MenuItem.default, {
                            onClick: (function (param) {
                                Curry._1(setDialogState, (function (param) {
                                        return /* Create */Block.__(0, [/* Playstation4 */0]);
                                      }));
                                return Curry._1(setIntegrationsPopoverAnchor, (function (param) {
                                              
                                            }));
                              }),
                            value: "playstation 4",
                            children: I18N$BsConsole.show(undefined, "PlayStation 4")
                          }), showXBoxAndPS5 ? React.createElement(MenuItem.default, {
                              onClick: (function (param) {
                                  Curry._1(setDialogState, (function (param) {
                                          return /* Create */Block.__(0, [/* Playstation5 */3]);
                                        }));
                                  return Curry._1(setIntegrationsPopoverAnchor, (function (param) {
                                                
                                              }));
                                }),
                              value: "playstation 5",
                              children: I18N$BsConsole.show(undefined, "PlayStation 5")
                            }) : null, showNintendoSwitch ? React.createElement(MenuItem.default, {
                              onClick: (function (param) {
                                  Curry._1(setDialogState, (function (param) {
                                          return /* Create */Block.__(0, [/* NintendoSwitch */1]);
                                        }));
                                  return Curry._1(setIntegrationsPopoverAnchor, (function (param) {
                                                
                                              }));
                                }),
                              value: "nintendo switch",
                              children: I18N$BsConsole.show(undefined, "Nintendo Switch")
                            }) : null)
                }), tmp$1);
}

var Main = {
  make: PS_ErrorSubmissionDataSources$Main
};

function PS_ErrorSubmissionDataSources$ServiceFailure(Props) {
  return React.createElement(Typography.default, {
              variant: "subtitle1",
              classes: {
                root: Css.style(/* :: */[
                      Css.color(Css.hex(Colors$BsConsole.grey4)),
                      /* :: */[
                        Css.paddingBottom(Css.rem(1)),
                        /* [] */0
                      ]
                    ])
              },
              children: null
            }, React.createElement("span", {
                  className: Css.style(/* :: */[
                        Css.fontFamily("Inconsolata"),
                        /* :: */[
                          Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                          /* :: */[
                            Css.color(Css.hex(Colors$BsConsole.accent)),
                            /* :: */[
                              Css.padding2(Css.px(3), Css.px(2)),
                              /* :: */[
                                Css.whiteSpace(/* nowrap */867913355),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ])
                }, I18N$BsConsole.show(undefined, "backtrace-connector")), I18N$BsConsole.show(undefined, " service is not available. Please contact "), React.createElement("a", {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.accent)),
                        /* :: */[
                          Css.hover(/* :: */[
                                Css.color(Css.hex(Colors$BsConsole.accent)),
                                /* :: */[
                                  Css.important(Css.textDecoration(/* underline */131142924)),
                                  /* [] */0
                                ]
                              ]),
                          /* :: */[
                            Css.visited(/* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.accent)),
                                  /* :: */[
                                    Css.important(Css.textDecoration(/* underline */131142924)),
                                    /* [] */0
                                  ]
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]),
                  href: "mailto:" + Email$BsConsole.support
                }, I18N$BsConsole.show(undefined, "customer support")), I18N$BsConsole.show(undefined, " for further help."));
}

var ServiceFailure = {
  make: PS_ErrorSubmissionDataSources$ServiceFailure
};

function PS_ErrorSubmissionDataSources(Props) {
  var config = Props.config;
  var serviceEndpoint = Services$BsConsole.getEndpoint(undefined)(config.services, "connector");
  return React.createElement(BtSettings$BsConsole.Container.make, {
              children: null
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(1.0)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Data sources")
                }), serviceEndpoint !== undefined ? React.createElement(PS_ErrorSubmissionDataSources$Main, {
                    config: config,
                    serviceEndpoint: serviceEndpoint
                  }) : React.createElement(PS_ErrorSubmissionDataSources$ServiceFailure, { }));
}

function make(config, children) {
  return ReasonReactCompat.wrapReactForReasonReact(PS_ErrorSubmissionDataSources, {
              config: config
            }, children);
}

var Re = {
  make: make
};

var make$1 = PS_ErrorSubmissionDataSources;

exports.minimumSwitchVersion = minimumSwitchVersion;
exports.minimumXBoxAndPS5Version = minimumXBoxAndPS5Version;
exports.Style = Style;
exports.Logs = Logs;
exports.ConnectionInfo = ConnectionInfo;
exports.ConnectionActions = ConnectionActions;
exports.DetailsDialog = DetailsDialog;
exports.DataSourceCard = DataSourceCard;
exports.List = List;
exports.Main = Main;
exports.ServiceFailure = ServiceFailure;
exports.make = make$1;
exports.Re = Re;
/* containerWithBorder Not a pure module */
