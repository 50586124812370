// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var Morgue$BsConsole = require("../bs-crdb-response/src/Morgue.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var Uncaught$BsConsole = require("../Uncaught.js");
var BugThread$BsConsole = require("./data/BugThread.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var Flag = require("@material-ui/icons/Flag");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Tooltip = require("@material-ui/core/Tooltip");
var BugSectionHeader$BsConsole = require("./BugSectionHeader.js");
var IconButton = require("@material-ui/core/IconButton");
var Reprocessing_Tasks$BsConsole = require("../reprocessing/Reprocessing_Tasks.js");
var ArrowDropDown = require("@material-ui/icons/ArrowDropDown");

function updateFaultingTid(token, projectName, universeName, id, tid) {
  var arg = Task2$BsConsole.make(/* Api */Block.__(0, ["/_mod_faulting_tid?universe=" + (String(universeName) + ("&project=" + (String(projectName) + "")))]), undefined, /* Post */Block.__(0, [Json_encode.object_(/* :: */[
                /* tuple */[
                  "tids",
                  Json_encode.array(Util$BsConsole.identity, [Json_encode.object_(/* :: */[
                              /* tuple */[
                                "id",
                                id
                              ],
                              /* :: */[
                                /* tuple */[
                                  "tid",
                                  tid
                                ],
                                /* [] */0
                              ]
                            ])])
                ],
                /* [] */0
              ])]), undefined, (function (param) {
          return /* Ok */Block.__(0, [param.json]);
        }), undefined, undefined);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), undefined, (function (resp) {
                if (resp.tag) {
                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to update faulting thread id"));
                }
                var arg = Reprocessing_Tasks$BsConsole.reloadObjects(token, projectName, universeName, [id]);
                return Curry._2((function (param) {
                                return (function (param$1, param$2) {
                                    return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                  });
                              })(token), undefined, (function (resp) {
                              return SnackPurveyor$BsConsole.enqueue(undefined, undefined, 5000, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                  /* String_literal */Block.__(11, [
                                                      "Updated faulting thread to ",
                                                      /* String */Block.__(2, [
                                                          /* No_padding */0,
                                                          /* String_literal */Block.__(11, [
                                                              ". \nReprocessing scheduled for error instance. \nRefresh the page to reload the error.",
                                                              /* End_of_format */0
                                                            ])
                                                        ])
                                                    ]),
                                                  "Updated faulting thread to %s. \nReprocessing scheduled for error instance. \nRefresh the page to reload the error."
                                                ]), tid));
                            }));
              }));
}

function BugGenericThreadSelect$ThreadGroupRow(Props) {
  var paneFocused = Props.paneFocused;
  var thread = Props.thread;
  var selectedThread = Props.selectedThread;
  var setSelectedThread = Props.setSelectedThread;
  var updateFaultingTid = Props.updateFaultingTid;
  var typ = Props.typ;
  Props.setExpanded;
  var match = React.useState((function () {
          return false;
        }));
  var setHovered = match[1];
  var hovered = match[0];
  var firstFrame = Belt_Option.map(thread.stack, (function (s) {
          return Belt_Array.get(s, 0);
        }));
  var bg = Css.background(Caml_obj.caml_equal(thread, selectedThread) ? (
          paneFocused ? Css.hex(Colors$BsConsole.tealExtraLight) : Css.hex(Colors$BsConsole.blackA10)
        ) : (
          hovered ? Css.hex(Colors$BsConsole.blackA04) : Css.hex(Colors$BsConsole.white)
        ));
  var cn = Css.style(/* :: */[
        bg,
        /* :: */[
          Css.height(Css.px(20)),
          /* :: */[
            Css.padding2(Css.px(4), Css.px(3)),
            /* [] */0
          ]
        ]
      ]);
  var tmp;
  if (typ !== undefined) {
    if (typeof typ === "number") {
      tmp = typ !== 0 ? React.createElement("svg", {
              height: "20",
              width: "20",
              shapeRendering: "crispEdges"
            }, React.createElement("path", {
                  d: "M 9 0 L 9 10 L 18 10",
                  fill: "none",
                  stroke: Colors$BsConsole.octothorpe(Colors$BsConsole.blackA20),
                  strokeWidth: "1"
                })) : React.createElement("svg", {
              height: "20",
              width: "20",
              shapeRendering: "crispEdges"
            }, React.createElement("path", {
                  d: "M 9 0 L 9 20",
                  fill: "none",
                  stroke: Colors$BsConsole.octothorpe(Colors$BsConsole.blackA20),
                  strokeWidth: "1"
                }));
    } else {
      var setExpanded = typ[1];
      tmp = React.createElement(IconButton.default, {
            classes: {
              root: Css.merge(/* :: */[
                    Css.style(/* :: */[
                          Css.fontSize(Css.px(16)),
                          /* :: */[
                            Css.padding(Css.px(1)),
                            /* :: */[
                              Css.margin2(Css.px(1), Css.zero),
                              /* :: */[
                                Css.width(Css.px(18)),
                                /* :: */[
                                  Css.height(Css.px(18)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]),
                    /* [] */0
                  ])
            },
            onClick: (function (e) {
                e.stopPropagation();
                return Curry._1(setExpanded, (function (prim) {
                              return !prim;
                            }));
              }),
            children: React.createElement(ArrowDropDown.default, {
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA87)),
                        /* :: */[
                          Css.transform(Css.rotate(Css.deg(typ[0] ? 0 : -90))),
                          /* :: */[
                            Css.transition(200, undefined, undefined, "transform"),
                            /* [] */0
                          ]
                        ]
                      ]),
                  fontSize: "inherit"
                })
          });
    }
  } else {
    tmp = null;
  }
  var match$1 = thread.name;
  var match$2 = thread.key;
  var match$3 = Belt_Option.flatMap(firstFrame, (function (frame) {
          return Belt_Option.flatMap(frame, (function (frame) {
                        return Caml_option.nullable_to_opt(frame.funcName);
                      }));
        }));
  var match$4 = Belt_Option.flatMap(firstFrame, (function (frame) {
          return Belt_Option.flatMap(frame, (function (frame) {
                        return Caml_option.nullable_to_opt(frame.address);
                      }));
        }));
  var library = Belt_Option.flatMap(firstFrame, (function (frame) {
          return Belt_Option.flatMap(frame, (function (f) {
                        return f.library;
                      }));
        }));
  var tmp$1;
  if (library !== undefined) {
    var library$1 = Caml_option.valFromOption(library);
    if (library$1 == null) {
      tmp$1 = null;
    } else {
      var p1 = library$1.split("/");
      var p2 = library$1.split("\\");
      tmp$1 = I18N$BsConsole.showSkip(p1.length > p2.length ? Uncaught$BsConsole.getOrThrow(Belt_Array.get(p1, p1.length - 1 | 0), "Error getting shortpath for p1") : Uncaught$BsConsole.getOrThrow(Belt_Array.get(p2, p2.length - 1 | 0), "Error getting shortpath for p2"));
    }
  } else {
    tmp$1 = null;
  }
  var match$5 = CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.61.112");
  var match$6 = hovered || thread.fault;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.unsafe("display", "contents"),
                    /* :: */[
                      Css.color(thread.fault ? Css.hex(Colors$BsConsole.npLightRed) : Css.hex(Colors$BsConsole.blackA87)),
                      /* :: */[
                        Css.padding2(Css.px(4), Css.px(2)),
                        /* :: */[
                          Css.height(Css.px(20)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]),
              onClick: (function (param) {
                  return Curry._1(setSelectedThread, thread);
                }),
              onMouseEnter: (function (param) {
                  return Curry._1(setHovered, (function (param) {
                                return true;
                              }));
                }),
              onMouseLeave: (function (param) {
                  return Curry._1(setHovered, (function (param) {
                                return false;
                              }));
                })
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        bg,
                        /* :: */[
                          Css.height(Css.px(20)),
                          /* :: */[
                            Css.width(Css.px(20)),
                            /* [] */0
                          ]
                        ]
                      ])
                }, tmp), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        cn,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.textOverflow(Css.ellipsis),
                                /* :: */[
                                  Css.overflow(Css.hidden),
                                  /* :: */[
                                    Css.whiteSpace(Css.nowrap),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, match$1 !== undefined ? I18N$BsConsole.showSkip(match$1) : (
                    match$2 !== undefined ? I18N$BsConsole.showSkip(match$2) : React.createElement("i", undefined, I18N$BsConsole.show(undefined, "Unnamed"))
                  )), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        cn,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.textOverflow(Css.ellipsis),
                                /* :: */[
                                  Css.overflow(Css.hidden),
                                  /* :: */[
                                    Css.whiteSpace(Css.nowrap),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, I18N$BsConsole.showSkip(match$3 !== undefined ? Uncaught$BsConsole.getOrThrow(Belt_Array.get(Morgue$BsConsole.Callstack.prep([match$3], {
                                    suffix: 2,
                                    dynamic: true
                                  }), 0), "Error with funcName") : (
                        match$4 !== undefined ? match$4 : ""
                      ))), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        cn,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.textOverflow(Css.ellipsis),
                                /* :: */[
                                  Css.overflow(Css.hidden),
                                  /* :: */[
                                    Css.whiteSpace(Css.nowrap),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, tmp$1), React.createElement("div", {
                  className: Css.merge(/* :: */[
                        cn,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.textOverflow(Css.ellipsis),
                                /* :: */[
                                  Css.overflow(Css.hidden),
                                  /* :: */[
                                    Css.whiteSpace(Css.nowrap),
                                    /* :: */[
                                      Css.textAlign(/* right */-379319332),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ])
                }, match$5 && match$6 ? React.createElement(Tooltip.default, {
                        placement: "top",
                        children: React.createElement(IconButton.default, {
                              classes: {
                                root: Css.merge(/* :: */[
                                      Css.style(/* :: */[
                                            Css.fontSize(Css.px(16)),
                                            /* :: */[
                                              Css.padding(Css.px(1)),
                                              /* :: */[
                                                Css.marginTop(Css.px(-3)),
                                                /* :: */[
                                                  Css.width(Css.px(18)),
                                                  /* :: */[
                                                    Css.height(Css.px(16)),
                                                    /* :: */[
                                                      Css.alignSelf(/* center */98248149),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]),
                                      /* [] */0
                                    ])
                              },
                              onClick: (function (e) {
                                  e.stopPropagation();
                                  if (!thread.fault) {
                                    Belt_Option.map(thread.key, Curry.__1(updateFaultingTid));
                                    return ;
                                  }
                                  
                                }),
                              children: thread.fault ? React.createElement(Flag.default, {
                                      className: Css.style(/* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.npLightRed)),
                                            /* [] */0
                                          ]),
                                      fontSize: "inherit"
                                    }) : React.createElement(MuiIcons$BsConsole.FlagOutline.make, {
                                      className: Css.style(/* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.npLightRed)),
                                            /* [] */0
                                          ]),
                                      fontSize: "inherit"
                                    })
                            }),
                        title: thread.fault ? I18N$BsConsole.get(undefined, "The faulting thread") : I18N$BsConsole.get(undefined, "Mark this thread as the faulting thread")
                      }) : null));
}

var ThreadGroupRow = {
  make: BugGenericThreadSelect$ThreadGroupRow
};

function BugGenericThreadSelect$ThreadGroup(Props) {
  var group = Props.group;
  var selectedThread = Props.selectedThread;
  var setSelectedThread = Props.setSelectedThread;
  var updateFaultingTid = Props.updateFaultingTid;
  var paneFocused = Props.paneFocused;
  var thread = Uncaught$BsConsole.getOrThrow(Belt_Array.get(group, 0), "No threads in ThreadGroup");
  var match = React.useState((function () {
          return false;
        }));
  var expanded = match[0];
  var tmp = {
    paneFocused: paneFocused,
    thread: thread,
    selectedThread: selectedThread,
    setSelectedThread: setSelectedThread,
    updateFaultingTid: updateFaultingTid
  };
  var tmp$1 = group.length === 1 ? undefined : /* Parent */[
      expanded,
      match[1]
    ];
  if (tmp$1 !== undefined) {
    tmp.typ = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(BugGenericThreadSelect$ThreadGroupRow, tmp), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.unsafe("display", "contents"),
                        /* :: */[
                          Css.height(Css.px(Caml_int32.imul(group.length - 1 | 0, 20))),
                          /* :: */[
                            Css.overflowY(Css.hidden),
                            /* [] */0
                          ]
                        ]
                      ])
                }, expanded ? Belt_Array.mapWithIndex(Belt_Array.sliceToEnd(group, 1), (function (idx, thread) {
                          return React.createElement(BugGenericThreadSelect$ThreadGroupRow, {
                                      paneFocused: paneFocused,
                                      thread: thread,
                                      selectedThread: selectedThread,
                                      setSelectedThread: setSelectedThread,
                                      updateFaultingTid: updateFaultingTid,
                                      typ: idx === (group.length - 2 | 0) ? /* LastChild */1 : /* Child */0,
                                      key: String(idx)
                                    });
                        })) : null));
}

var ThreadGroup = {
  make: BugGenericThreadSelect$ThreadGroup
};

function BugGenericThreadSelect(Props) {
  var threads = Props.threads;
  var selectedThread = Props.selectedThread;
  var setSelectedThread = Props.setSelectedThread;
  var token = Props.token;
  var projectName = Props.projectName;
  var universeName = Props.universeName;
  var tx = Props.tx;
  var w = Props.width;
  var h = Props.height;
  var paneFocused = Props.paneFocused;
  var threadGroups = BugThread$BsConsole.Generic.groupThreads(threads);
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.px(w)),
                    /* :: */[
                      Css.height(Css.px(h)),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement(BugSectionHeader$BsConsole.make, {
                  title: "Threads",
                  count: threads.length
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.height(Css.pct(100)),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* :: */[
                            Css.overflowY(Css.auto),
                            /* :: */[
                              Css.overflowX(Css.hidden),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.padding2(Css.px(4), Css.zero),
                            /* :: */[
                              Css.fontFamily("Inconsolata"),
                              /* :: */[
                                Css.fontSize(Css.px(12)),
                                /* :: */[
                                  Css.cursor(/* pointer */-786317123),
                                  /* :: */[
                                    Css.display(Css.grid),
                                    /* :: */[
                                      Css.alignItems(Css.center),
                                      /* :: */[
                                        Css.gridTemplateColumns(/* :: */[
                                              Css.px(20),
                                              /* :: */[
                                                Css.auto,
                                                /* :: */[
                                                  Css.auto,
                                                  /* :: */[
                                                    Css.auto,
                                                    /* :: */[
                                                      Css.auto,
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]),
                                        /* :: */[
                                          Css.unsafe("gridTemplateRows", "repeat(20px)"),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ])
                    }, Belt_Array.mapWithIndex(threadGroups, (function (idx, group) {
                            return React.createElement(BugGenericThreadSelect$ThreadGroup, {
                                        group: group,
                                        selectedThread: selectedThread,
                                        setSelectedThread: setSelectedThread,
                                        updateFaultingTid: (function (newTid) {
                                            return updateFaultingTid(token, projectName, universeName, tx, newTid);
                                          }),
                                        paneFocused: paneFocused,
                                        key: String(idx)
                                      });
                          })))));
}

var make = BugGenericThreadSelect;

exports.updateFaultingTid = updateFaultingTid;
exports.ThreadGroupRow = ThreadGroupRow;
exports.ThreadGroup = ThreadGroup;
exports.make = make;
/* Css Not a pure module */
