// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Gate$BsConsole = require("./Gate.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var BtTable$BsConsole = require("./BtTable.js");
var Numeral$BsConsole = require("./Numeral.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtTableRow$BsConsole = require("./BtTableRow.js");
var BtTableBody$BsConsole = require("./BtTableBody.js");
var BtTableCell$BsConsole = require("./BtTableCell.js");
var BtTableHead$BsConsole = require("./BtTableHead.js");
var BtTableSort$BsConsole = require("./BtTableSort.js");
var BtTableColumn$BsConsole = require("./BtTableColumn.js");
var FormatRenderer$BsConsole = require("./format-renderers/FormatRenderer.js");
var Checkbox = require("@material-ui/core/Checkbox");
var BtTableContainer$BsConsole = require("./BtTableContainer.js");
var BtTablePagination$BsConsole = require("./BtTablePagination.js");
var BtTableColumnWidth$BsConsole = require("./BtTableColumnWidth.js");
var BtEnhancedTableHeaderCell$BsConsole = require("./BtEnhancedTableHeaderCell.js");

function getTableClassName(fixedLeftColumns, fixedRightColumn, startingPoint, columnWidths, zebraStripe, dragging, columnOrder) {
  var classNameL = Css.merge(Belt_List.fromArray(Belt_Array.mapWithIndex(fixedLeftColumns, (function (idx, columnName) {
                  var nth = startingPoint > 0 ? idx + 2 | 0 : idx + 1 | 0;
                  var headerSelectorName = "th.BtTableHeaderCell:nth-of-type(" + (String(nth) + ")");
                  var dataCellSelectorName = "td.BtTableDataCell:nth-of-type(" + (String(nth) + ")");
                  var leftOffset = Belt_Array.reduceWithIndex(fixedLeftColumns, startingPoint, (function (acc, curr, idx) {
                          if (idx >= fixedLeftColumns.indexOf(columnName)) {
                            return acc;
                          }
                          var col = Belt_Array.getBy(columnWidths, (function (column$prime) {
                                  return column$prime.name === curr;
                                }));
                          if (col !== undefined) {
                            return col.width + acc | 0;
                          } else {
                            return acc;
                          }
                        }));
                  return Css.style(/* :: */[
                              Css.selector(headerSelectorName, /* :: */[
                                    Css.left(Css.px(leftOffset)),
                                    /* :: */[
                                      Css.important(Css.zIndex(3)),
                                      /* [] */0
                                    ]
                                  ]),
                              /* :: */[
                                Css.selector(dataCellSelectorName, /* :: */[
                                      Css.left(Css.px(leftOffset)),
                                      /* :: */[
                                        Css.important(Css.zIndex(2)),
                                        /* :: */[
                                          Css.position(/* sticky */188263721),
                                          /* [] */0
                                        ]
                                      ]
                                    ]),
                                /* [] */0
                              ]
                            ]);
                }))));
  var rightFixedCol = Belt_Option.isSome(fixedRightColumn) ? Css.style(/* :: */[
          Css.selector("td.BtTableDataCell:last-of-type", /* :: */[
                Css.position(/* sticky */188263721),
                /* :: */[
                  Css.right(Css.px(0)),
                  /* :: */[
                    Css.textAlign(/* right */-379319332),
                    /* [] */0
                  ]
                ]
              ]),
          /* :: */[
            Css.selector("th.BtTableHeaderCell:last-of-type", /* :: */[
                  Css.position(/* sticky */188263721),
                  /* :: */[
                    Css.right(Css.px(0)),
                    /* :: */[
                      Css.textAlign(/* right */-379319332),
                      /* [] */0
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]) : "";
  var striping = zebraStripe ? Css.style(/* :: */[
          Css.selector("tr.BtTableRow:nth-of-type(even)", /* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                /* [] */0
              ]),
          /* [] */0
        ]) : "";
  var draggingClassName;
  if (dragging !== undefined) {
    var dropContainers = Belt_Array.keep(columnOrder, (function (col) {
            return !fixedLeftColumns.includes(col);
          }));
    var draggingIndex = dropContainers.indexOf(dragging);
    var offset = fixedLeftColumns.length + (
      startingPoint > 0 ? 1 : 0
    ) | 0;
    draggingClassName = Css.merge(/* :: */[
          Css.style(/* :: */[
                Css.selector("th.BtTableHeaderCell > *, th.BtTableHeaderCell > * > *", /* :: */[
                      Css.pointerEvents(/* none */-922086728),
                      /* :: */[
                        Css.position(/* relative */903134412),
                        /* [] */0
                      ]
                    ]),
                /* [] */0
              ]),
          Belt_List.mapWithIndex(Belt_List.fromArray(dropContainers), (function (idx, _col) {
                  return Css.style(/* :: */[
                              Css.selector("th:nth-of-type(" + (String((idx + offset | 0) + 1 | 0) + ").active-drop"), /* :: */[
                                    Css.unsafe("overflow", "unset"),
                                    /* [] */0
                                  ]),
                              /* :: */[
                                Css.selector("th:nth-of-type(" + (String((idx + offset | 0) + 1 | 0) + ").active-drop::after"), idx < draggingIndex ? /* :: */[
                                        Css.unsafe("content", "''"),
                                        /* :: */[
                                          Css.width(Css.pct(100)),
                                          /* :: */[
                                            Css.position(Css.absolute),
                                            /* :: */[
                                              Css.backgroundColor(Css.hex(Colors$BsConsole.blackA04)),
                                              /* :: */[
                                                Css.top(Css.px(0)),
                                                /* :: */[
                                                  Css.right(Css.px(0)),
                                                  /* :: */[
                                                    Css.left(Css.px(0)),
                                                    /* :: */[
                                                      Css.zIndex(4),
                                                      /* :: */[
                                                        Css.height(Css.px(1000)),
                                                        /* :: */[
                                                          Css.boxShadow(Css.px(2), undefined, undefined, undefined, true, Css.hex(Colors$BsConsole.accent)),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ] : (
                                        idx > draggingIndex ? /* :: */[
                                            Css.unsafe("content", "''"),
                                            /* :: */[
                                              Css.width(Css.pct(100)),
                                              /* :: */[
                                                Css.position(Css.absolute),
                                                /* :: */[
                                                  Css.backgroundColor(Css.hex(Colors$BsConsole.blackA04)),
                                                  /* :: */[
                                                    Css.top(Css.px(0)),
                                                    /* :: */[
                                                      Css.right(Css.px(0)),
                                                      /* :: */[
                                                        Css.left(Css.px(0)),
                                                        /* :: */[
                                                          Css.zIndex(4),
                                                          /* :: */[
                                                            Css.height(Css.px(1000)),
                                                            /* :: */[
                                                              Css.boxShadow(Css.px(-2), undefined, undefined, undefined, true, Css.hex(Colors$BsConsole.accent)),
                                                              /* [] */0
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ] : /* :: */[
                                            Css.unsafe("content", "''"),
                                            /* :: */[
                                              Css.width(Css.pct(100)),
                                              /* :: */[
                                                Css.position(Css.absolute),
                                                /* :: */[
                                                  Css.backgroundColor(Css.hex(Colors$BsConsole.blackA04)),
                                                  /* :: */[
                                                    Css.top(Css.px(0)),
                                                    /* :: */[
                                                      Css.right(Css.px(0)),
                                                      /* :: */[
                                                        Css.left(Css.px(0)),
                                                        /* :: */[
                                                          Css.zIndex(4),
                                                          /* :: */[
                                                            Css.height(Css.px(1000)),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                      )),
                                /* [] */0
                              ]
                            ]);
                }))
        ]);
  } else {
    draggingClassName = "";
  }
  return Css.merge(/* :: */[
              classNameL,
              /* :: */[
                striping,
                /* :: */[
                  draggingClassName,
                  /* :: */[
                    rightFixedCol,
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

function getDataCellClassName(column, row, onSortChange) {
  var match = Curry._1(column.getCellValue, row);
  var match$1 = column.disableSort;
  if (typeof match === "number") {
    return Css.style(/* [] */0);
  }
  var variant = match[0];
  var exit = 0;
  if (variant >= 3654864) {
    if (variant !== 365180284) {
      if (variant !== 737456202) {
        return Css.style(/* [] */0);
      }
      exit = 1;
    } else {
      exit = 2;
    }
  } else if (variant !== -976970511) {
    if (variant < 3654863) {
      return Css.style(/* [] */0);
    }
    exit = 2;
  } else {
    exit = 1;
  }
  switch (exit) {
    case 1 :
        if (match$1 || onSortChange === undefined) {
          return Css.style(/* [] */0);
        } else {
          return Css.style(/* :: */[
                      Css.important(Css.paddingLeft(Css.px(20))),
                      /* :: */[
                        Css.important(Css.paddingRight(Css.px(20))),
                        /* [] */0
                      ]
                    ]);
        }
    case 2 :
        if (match$1 || onSortChange === undefined) {
          return Css.style(/* [] */0);
        } else {
          return Css.style(/* :: */[
                      Css.important(Css.paddingLeft(Css.px(20))),
                      /* :: */[
                        Css.important(Css.paddingRight(Css.px(20))),
                        /* :: */[
                          Css.fontFamily("Inconsolata"),
                          /* [] */0
                        ]
                      ]
                    ]);
        }
    
  }
}

var Styles = {
  getTableClassName: getTableClassName,
  getDataCellClassName: getDataCellClassName
};

function BtEnhancedTable$BtEnhancedTableRow(Props) {
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var onClick = Props.onClick;
  var expandedContent = Props.expandedContent;
  var totalColumns = Props.totalColumns;
  var className = Props.className;
  var children = Props.children;
  if (expandedContent !== undefined) {
    var tmp = {
      expandedContent: Caml_option.valFromOption(expandedContent),
      colSpan: totalColumns,
      children: children
    };
    if (className !== undefined) {
      tmp.className = Caml_option.valFromOption(className);
    }
    if (onMouseEnter !== undefined) {
      tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
    }
    if (onMouseLeave !== undefined) {
      tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
    }
    if (onClick !== undefined) {
      tmp.onClick = Caml_option.valFromOption(onClick);
    }
    return React.createElement(BtTableRow$BsConsole.Expandable.make, tmp);
  }
  var tmp$1 = {
    children: children
  };
  if (className !== undefined) {
    tmp$1.className = Caml_option.valFromOption(className);
  }
  if (onMouseEnter !== undefined) {
    tmp$1.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
  }
  if (onMouseLeave !== undefined) {
    tmp$1.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
  }
  if (onClick !== undefined) {
    tmp$1.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement(BtTableRow$BsConsole.make, tmp$1);
}

var BtEnhancedTableRow = {
  make: BtEnhancedTable$BtEnhancedTableRow
};

function setWidth(element, width) {
  element.width = String(width) + "px";
  
}

function defaultRowSort(rows, columns, sortingState) {
  var sortedColumn = Belt_Array.getBy(columns, (function (col) {
          return col.name === sortingState.columnName;
        }));
  if (sortedColumn !== undefined) {
    return Belt_List.toArray(Belt_List.sort(Belt_List.fromArray(rows), (function (a, b) {
                      var match = Curry._1(sortedColumn.getCellValue, a);
                      var match$1 = Curry._1(sortedColumn.getCellValue, b);
                      var match$2 = sortingState.direction;
                      if (typeof match === "number") {
                        return 0;
                      }
                      var variant = match[0];
                      if (variant >= 3654864) {
                        if (variant !== 365180284) {
                          if (variant !== 737456202) {
                            return 0;
                          }
                          if (typeof match$1 === "number") {
                            return 0;
                          }
                          if (match$1[0] !== 737456202) {
                            return 0;
                          }
                          var b$prime = match$1[1];
                          var a$prime = match[1];
                          if (match$2 >= 759138929) {
                            return $$String.compare(Pervasives.string_of_bool(a$prime).toLowerCase(), Pervasives.string_of_bool(b$prime).toLowerCase());
                          } else {
                            return $$String.compare(Pervasives.string_of_bool(b$prime).toLowerCase(), Pervasives.string_of_bool(a$prime).toLowerCase());
                          }
                        }
                        if (typeof match$1 === "number") {
                          return 0;
                        }
                        if (match$1[0] !== 365180284) {
                          return 0;
                        }
                        var b$prime$1 = match$1[1];
                        var a$prime$1 = match[1];
                        if (match$2 >= 759138929) {
                          return Caml_primitive.caml_float_compare(a$prime$1, b$prime$1);
                        } else {
                          return Caml_primitive.caml_float_compare(b$prime$1, a$prime$1);
                        }
                      }
                      if (variant !== -976970511) {
                        if (variant < 3654863) {
                          return 0;
                        }
                        if (typeof match$1 === "number") {
                          return 0;
                        }
                        if (match$1[0] !== 3654863) {
                          return 0;
                        }
                        var b$prime$2 = match$1[1];
                        var a$prime$2 = match[1];
                        if (match$2 >= 759138929) {
                          return Caml_primitive.caml_int_compare(a$prime$2, b$prime$2);
                        } else {
                          return Caml_primitive.caml_int_compare(b$prime$2, a$prime$2);
                        }
                      }
                      if (typeof match$1 === "number") {
                        return 0;
                      }
                      if (match$1[0] !== -976970511) {
                        return 0;
                      }
                      var b$prime$3 = match$1[1];
                      var a$prime$3 = match[1];
                      if (match$2 >= 759138929) {
                        return $$String.compare(a$prime$3.toLowerCase(), b$prime$3.toLowerCase());
                      } else {
                        return $$String.compare(b$prime$3.toLowerCase(), a$prime$3.toLowerCase());
                      }
                    })));
  } else {
    return rows;
  }
}

function getColumnAlignment(column, row) {
  var match = Curry._1(column.getCellValue, row);
  if (typeof match !== "number") {
    var variant = match[0];
    if (variant >= 3654864) {
      if (variant === 365180284) {
        return /* right */-379319332;
      }
      if (variant === 615627497) {
        var format = match[1][1];
        if (format !== undefined) {
          return FormatRenderer$BsConsole.getColumnAlignment(format);
        }
        
      }
      
    } else if (variant !== -976970511) {
      if (variant >= 3654863) {
        return /* right */-379319332;
      }
      
    } else {
      var match$1 = column.align;
      if (match$1 >= 847852583) {
        return /* left */-944764921;
      } else {
        return /* right */-379319332;
      }
    }
  }
  var match$2 = column.align;
  if (match$2 >= 847852583) {
    return /* left */-944764921;
  } else {
    return /* right */-379319332;
  }
}

function getColumnOrder(columns, columnOrder, fixedLeftColumns) {
  var cleanedColumnOrder = Belt_Array.concat(fixedLeftColumns, Belt_Array.keep(Belt_Array.keep(columnOrder, (function (columnName) {
                  return Belt_Option.isSome(Belt_Array.getBy(columns, (function (column) {
                                    return column.name === columnName;
                                  })));
                })), (function (col) {
              return !fixedLeftColumns.includes(col);
            })));
  var missingColumns = Belt_Array.keep(Belt_Array.map(columns, (function (column) {
              return column.name;
            })), (function (columnName) {
          return !cleanedColumnOrder.includes(columnName);
        }));
  return Belt_Array.concat(cleanedColumnOrder, missingColumns);
}

function getColumnWidths(columns, columnWidths) {
  return Belt_Array.keepMap(columns, (function (column) {
                var columnWidth = Belt_Array.getBy(columnWidths, (function (colWidth) {
                        return column.name === colWidth.name;
                      }));
                if (columnWidth !== undefined) {
                  return columnWidth;
                }
                var initialWidth = column.initialWidth;
                if (initialWidth !== undefined) {
                  return {
                          name: column.name,
                          width: initialWidth
                        };
                }
                
              }));
}

function reducer(state, action) {
  switch (action.tag | 0) {
    case /* OnResizeChange */0 :
        return {
                columnWidths: state.columnWidths,
                columnOrder: state.columnOrder,
                dragging: state.dragging,
                resizing: action[0]
              };
    case /* OnDraggingChange */1 :
        return {
                columnWidths: state.columnWidths,
                columnOrder: state.columnOrder,
                dragging: action[0],
                resizing: state.resizing
              };
    case /* OnColumnOrderChange */2 :
        var dragged = state.dragging;
        if (dragged === undefined) {
          return state;
        }
        var draggedIdx = state.columnOrder.indexOf(dragged);
        var targetIdx = state.columnOrder.indexOf(action[0]);
        var match;
        if (draggedIdx > targetIdx) {
          var l = Belt_Array.slice(state.columnOrder, 0, targetIdx);
          var r = Belt_Array.keep(Belt_Array.sliceToEnd(state.columnOrder, targetIdx), (function (col) {
                  return col !== dragged;
                }));
          match = /* tuple */[
            l,
            r
          ];
        } else {
          var l$1 = Belt_Array.keep(Belt_Array.slice(state.columnOrder, 0, targetIdx + 1 | 0), (function (col) {
                  return col !== dragged;
                }));
          var r$1 = Belt_Array.sliceToEnd(state.columnOrder, targetIdx + 1 | 0);
          match = /* tuple */[
            l$1,
            r$1
          ];
        }
        var newColumnOrder = Belt_Array.concatMany([
              match[0],
              [dragged],
              match[1]
            ]);
        return {
                columnWidths: state.columnWidths,
                columnOrder: newColumnOrder,
                dragging: undefined,
                resizing: state.resizing
              };
    case /* SetColumnOrder */3 :
        return {
                columnWidths: state.columnWidths,
                columnOrder: action[0],
                dragging: state.dragging,
                resizing: state.resizing
              };
    case /* OnColumnWidthChange */4 :
        var column = action[0];
        var match$1 = Belt_Array.getBy(state.columnWidths, (function (column$prime) {
                return column$prime.name === column.name;
              }));
        var columnWidths = match$1 !== undefined ? Belt_Array.map(state.columnWidths, (function (column$prime) {
                  if (column$prime.name === column.name) {
                    return column;
                  } else {
                    return column$prime;
                  }
                })) : Belt_Array.concat(state.columnWidths, [column]);
        return {
                columnWidths: columnWidths,
                columnOrder: state.columnOrder,
                dragging: state.dragging,
                resizing: state.resizing
              };
    
  }
}

function getInitialState(columns, columnOrder, fixedLeftColumns, columnWidths) {
  var columnOrder$1 = getColumnOrder(columns, columnOrder, fixedLeftColumns);
  var columnWidths$1 = getColumnWidths(columns, columnWidths);
  return {
          columnWidths: columnWidths$1,
          columnOrder: columnOrder$1,
          dragging: undefined,
          resizing: false
        };
}

function BtEnhancedTable(Props) {
  var columns = Props.columns;
  var rows = Props.rows;
  var getRowId = Props.getRowId;
  var showGridOpt = Props.showGrid;
  var renderDataCell = Props.renderDataCell;
  var renderHeaderCell = Props.renderHeaderCell;
  var renderExpandedRowContent = Props.renderExpandedRowContent;
  var renderStubHeader = Props.renderStubHeader;
  var stubMinWidth = Props.stubMinWidth;
  var renderStubDataCell = Props.renderStubDataCell;
  var sortingState = Props.sortingState;
  var onSortChange = Props.onSortChange;
  var onSelectedChange = Props.onSelectedChange;
  var selected = Props.selected;
  var onRowEnter = Props.onRowEnter;
  var onRowLeave = Props.onRowLeave;
  var onRowClick = Props.onRowClick;
  var onColumnOrderChange = Props.onColumnOrderChange;
  var resizeableColumnsOpt = Props.resizeableColumns;
  var draggableColumnsOpt = Props.draggableColumns;
  var fixedLeftColumnsOpt = Props.fixedLeftColumns;
  var fixedRightColumn = Props.fixedRightColumn;
  var columnOrderOpt = Props.columnOrder;
  var zebraStripeOpt = Props.zebraStripe;
  var page = Props.page;
  var onChangePage = Props.onChangePage;
  var totalRows = Props.totalRows;
  var rowsPerPage = Props.rowsPerPage;
  var loading = Props.loading;
  var emptyTableText = Props.emptyTableText;
  var containerClassNameOpt = Props.containerClassName;
  var columnWidthsOpt = Props.columnWidths;
  var onColumnWidthChange = Props.onColumnWidthChange;
  var showGrid = showGridOpt !== undefined ? showGridOpt : true;
  var resizeableColumns = resizeableColumnsOpt !== undefined ? resizeableColumnsOpt : false;
  var draggableColumns = draggableColumnsOpt !== undefined ? draggableColumnsOpt : false;
  var fixedLeftColumns = fixedLeftColumnsOpt !== undefined ? fixedLeftColumnsOpt : [];
  var columnOrder = columnOrderOpt !== undefined ? columnOrderOpt : [];
  var zebraStripe = zebraStripeOpt !== undefined ? zebraStripeOpt : false;
  var containerClassName = containerClassNameOpt !== undefined ? containerClassNameOpt : "";
  var columnWidths = columnWidthsOpt !== undefined ? columnWidthsOpt : [];
  var containerRef = React.useRef(null);
  var hiddenRef = React.useRef(null);
  var tableRef = React.useRef(null);
  var match = React.useReducer(reducer, undefined, (function (param) {
          return getInitialState(columns, columnOrder, fixedLeftColumns, columnWidths);
        }));
  var dispatch = match[1];
  var match$1 = match[0];
  var resizing = match$1.resizing;
  var dragging = match$1.dragging;
  var columnOrder$1 = match$1.columnOrder;
  var columnWidths$1 = match$1.columnWidths;
  var resetScrollTop = function (param) {
    var container = containerRef.current;
    if (!(container == null)) {
      container.scrollTop = 0.0;
      return ;
    }
    
  };
  React.useEffect((function () {
          resetScrollTop(undefined);
          
        }), [rows]);
  React.useEffect((function () {
          var columnNames = Belt_Array.map(columns, (function (col) {
                  return col.name;
                }));
          var ordered = Belt_Array.keep(columnOrder$1, (function (col) {
                  return columnNames.includes(col);
                }));
          var diff = Belt_Array.keep(columnNames, (function (col) {
                  return !ordered.includes(col);
                }));
          var newColumnOrder = Belt_Array.concat(ordered, diff);
          Curry._1(dispatch, /* SetColumnOrder */Block.__(3, [newColumnOrder]));
          
        }), [columns]);
  React.useLayoutEffect((function () {
          if (onColumnOrderChange !== undefined && columnOrder$1.length === columns.length && !Belt_Option.isSome(dragging)) {
            Curry._1(onColumnOrderChange, columnOrder$1);
          }
          
        }), /* tuple */[
        JSON.stringify(columnOrder$1),
        dragging
      ]);
  React.useEffect((function () {
          if (onColumnWidthChange !== undefined) {
            Curry._1(onColumnWidthChange, columnWidths$1);
          }
          
        }), [JSON.stringify(Json_encode.array(BtTableColumnWidth$BsConsole.toJson, columnWidths$1))]);
  React.useEffect((function () {
          var match = tableRef.current;
          if (!(match == null) && resizeableColumns) {
            var hidden = hiddenRef.current;
            var containerWidth = !(hidden == null) ? hidden.offsetWidth : 0;
            var columnsWidth = columnWidths$1.length !== 0 ? Belt_Array.reduce(columnWidths$1, 0, (function (acc, curr) {
                      return acc + curr.width | 0;
                    })) : 0;
            var selectColumnWidth = Belt_Option.isSome(selected) ? 48 : 0;
            var columnsWidth$prime = (columnsWidth + selectColumnWidth | 0) + Belt_Option.getWithDefault(stubMinWidth, 0) | 0;
            var minWidth = containerWidth < columnsWidth$prime ? columnsWidth$prime : containerWidth;
            match.setAttribute("style", "min-width: " + (String(minWidth) + "px;"));
          }
          
        }), /* tuple */[
        columnWidths$1,
        rows,
        columns
      ]);
  var stubColumn = fixedRightColumn !== undefined ? fixedRightColumn : BtTableColumn$BsConsole.make("__stub__", "__stub__", (function (param) {
            return /* None */870530776;
          }), true, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var orderedColumns = Belt_Array.concat(Belt_Array.reduce(columnOrder$1, [], (function (acc, curr) {
              var col = Belt_Array.getBy(columns, (function (col) {
                      return col.name === curr;
                    }));
              if (col !== undefined) {
                return Belt_Array.concat(acc, [col]);
              } else {
                return acc;
              }
            })), resizeableColumns || Belt_Option.isSome(fixedRightColumn) ? [stubColumn] : []);
  var tableClassName = getTableClassName(fixedLeftColumns, fixedRightColumn, Belt_Option.isSome(selected) ? 48 : 0, columnWidths$1, zebraStripe, dragging, columnOrder$1);
  var totalColumns = (columns.length + (
      Belt_Option.isSome(selected) ? 1 : 0
    ) | 0) + (
    Belt_Option.isSome(renderExpandedRowContent) ? 1 : 0
  ) | 0;
  var tmp = {
    stickyHeader: true,
    showGrid: showGrid,
    className: tableClassName,
    children: null,
    ref: tableRef
  };
  if (loading !== undefined) {
    tmp.loading = Caml_option.valFromOption(loading);
  }
  var tmp$1;
  if (selected !== undefined) {
    var checked = rows.length !== 0 && rows.length === selected.length;
    var indeterminate = selected.length !== 0 && !checked;
    var ids = Belt_Array.keep(Belt_Array.map(rows, Curry.__1(getRowId)), (function (id) {
            return id !== "";
          }));
    tmp$1 = React.createElement(BtTableCell$BsConsole.Header.make, {
          className: Css.merge(/* :: */[
                Css.style(/* :: */[
                      Css.important(Css.padding(/* zero */-789508312)),
                      /* [] */0
                    ]),
                /* :: */[
                  showGrid ? Css.style(/* :: */[
                          Css.position(/* sticky */188263721),
                          /* :: */[
                            Css.left(/* zero */-789508312),
                            /* :: */[
                              Css.important(Css.zIndex(4)),
                              /* [] */0
                            ]
                          ]
                        ]) : "",
                  /* :: */[
                    "BtTableSelectCell",
                    /* [] */0
                  ]
                ]
              ]),
          children: React.createElement(Checkbox.default, {
                checked: checked,
                color: "primary",
                indeterminate: indeterminate,
                onChange: (function ($$event) {
                    if (onSelectedChange === undefined) {
                      return ;
                    }
                    var value = $$event.target.checked;
                    return Curry._1(onSelectedChange, value && !indeterminate ? ids : []);
                  })
              })
        });
  } else {
    tmp$1 = null;
  }
  var tmp$2;
  if (page !== undefined && onChangePage !== undefined && totalRows !== undefined) {
    var tmp$3 = {
      total: totalRows,
      page: page,
      onChangePage: onChangePage
    };
    if (rowsPerPage !== undefined) {
      tmp$3.rowsPerPage = Caml_option.valFromOption(rowsPerPage);
    }
    tmp$2 = React.createElement(BtTablePagination$BsConsole.make, tmp$3);
  } else {
    tmp$2 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(BtTableContainer$BsConsole.make, {
                  className: containerClassName,
                  children: null,
                  ref: containerRef
                }, React.createElement(BtTable$BsConsole.make, tmp, resizeableColumns ? React.createElement("colgroup", undefined, selected !== undefined ? React.createElement("col", {
                                  style: {
                                    width: "48px"
                                  }
                                }) : null, Belt_Array.mapWithIndex(orderedColumns, (function (idx, column) {
                                  var match = column.name;
                                  if (match === "__stub__") {
                                    var minWidth = stubMinWidth !== undefined ? String(stubMinWidth) + "px" : undefined;
                                    var tmp = { };
                                    if (minWidth !== undefined) {
                                      tmp.minWidth = Caml_option.valFromOption(minWidth);
                                    }
                                    return React.createElement("col", {
                                                key: "__stub__" + String(idx),
                                                style: tmp
                                              });
                                  }
                                  var column$1 = Belt_Array.getBy(columnWidths$1, (function (col) {
                                          return col.name === column.name;
                                        }));
                                  var width = column$1 !== undefined ? (
                                      column$1.name === "__fingerprint" && Gate$BsConsole.isBetaFn(undefined) ? String(column$1.width + 150 | 0) + "px" : String(column$1.width) + "px"
                                    ) : "";
                                  return React.createElement("col", {
                                              key: column.name + ("-" + (width + ("__" + String(idx)))),
                                              style: {
                                                width: width
                                              }
                                            });
                                }))) : null, React.createElement(BtTableHead$BsConsole.make, {
                          className: Css.style(/* :: */[
                                Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
                                /* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.grey3)),
                                  /* [] */0
                                ]
                              ]),
                          children: React.createElement(BtTableRow$BsConsole.make, {
                                children: null
                              }, renderExpandedRowContent !== undefined ? React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                      className: Css.style(/* :: */[
                                            Css.width(Css.px(48)),
                                            /* [] */0
                                          ])
                                    }) : null, tmp$1, Belt_Array.mapWithIndex(orderedColumns, (function (idx, column) {
                                      var match = column.name;
                                      if (match === "__stub__") {
                                        return React.createElement(BtTableCell$BsConsole.HeaderStub.make, {
                                                    className: Css.style(/* :: */[
                                                          Css.textAlign(/* left */-944764921),
                                                          /* [] */0
                                                        ]),
                                                    children: renderStubHeader !== undefined ? Curry._1(renderStubHeader, undefined) : null,
                                                    key: "stub__" + String(idx)
                                                  });
                                      }
                                      var key = column.name + ("__" + String(idx));
                                      var columnWidth = Belt_Array.getBy(columnWidths$1, (function (column$prime) {
                                              return column$prime.name === column.name;
                                            }));
                                      var onWidthChange = function (width) {
                                        return Curry._1(dispatch, /* OnColumnWidthChange */Block.__(4, [{
                                                        name: column.name,
                                                        width: width
                                                      }]));
                                      };
                                      var row = Belt_Array.get(rows, 0);
                                      var align = row !== undefined ? getColumnAlignment(column, Caml_option.valFromOption(row)) : /* left */-944764921;
                                      var tmp;
                                      if (renderHeaderCell !== undefined) {
                                        tmp = Curry._4(renderHeaderCell, align, column, sortingState, onSortChange);
                                      } else {
                                        var match$1 = column.disableSort;
                                        var exit = 0;
                                        if (onSortChange !== undefined && !match$1) {
                                          tmp = React.createElement(BtTableSort$BsConsole.Label.make, {
                                                sortingState: sortingState,
                                                columnName: column.name,
                                                onSortChange: onSortChange,
                                                align: align,
                                                children: I18N$BsConsole.showSkip(column.title)
                                              });
                                        } else {
                                          exit = 1;
                                        }
                                        if (exit === 1) {
                                          tmp = React.createElement("span", {
                                                className: Css.merge(/* :: */[
                                                      Css.style(/* :: */[
                                                            Css.width(Css.pct(100)),
                                                            /* :: */[
                                                              Css.whiteSpace(/* nowrap */867913355),
                                                              /* :: */[
                                                                Css.overflow(/* hidden */-862584982),
                                                                /* :: */[
                                                                  Css.textOverflow(/* ellipsis */166888785),
                                                                  /* :: */[
                                                                    Css.textAlign(align),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]),
                                                      /* :: */[
                                                        "column-title",
                                                        /* [] */0
                                                      ]
                                                    ])
                                              }, I18N$BsConsole.showSkip(column.title));
                                        }
                                        
                                      }
                                      return React.createElement(BtEnhancedTableHeaderCell$BsConsole.make, {
                                                  column: column,
                                                  className: column.headerCellClassName,
                                                  resizeable: resizeableColumns && !column.disableResize,
                                                  draggable: draggableColumns && !column.disableDraggable && !resizing && !fixedLeftColumns.includes(column.name),
                                                  columnWidth: columnWidth,
                                                  onWidthChange: onWidthChange,
                                                  minWidth: column.minWidth,
                                                  maxWidth: column.maxWidth,
                                                  initialWidth: column.initialWidth,
                                                  disableSort: column.disableSort,
                                                  disableDrop: fixedLeftColumns.includes(column.name),
                                                  onDraggingChange: (function (dragging) {
                                                      return Curry._1(dispatch, /* OnDraggingChange */Block.__(1, [dragging]));
                                                    }),
                                                  onResizeChange: (function (resizing) {
                                                      return Curry._1(dispatch, /* OnResizeChange */Block.__(0, [resizing]));
                                                    }),
                                                  onDragDrop: (function (target) {
                                                      return Curry._1(dispatch, /* OnColumnOrderChange */Block.__(2, [target]));
                                                    }),
                                                  children: tmp,
                                                  key: key
                                                });
                                    })))
                        }), React.createElement(BtTableBody$BsConsole.make, {
                          children: null
                        }, Belt_Array.mapWithIndex(rows, (function (rowIndex, row) {
                                var key = Curry._1(getRowId, row) + ("__" + String(rowIndex));
                                var checked = selected !== undefined ? selected.includes(Curry._1(getRowId, row)) : false;
                                var tmp = {
                                  totalColumns: totalColumns,
                                  className: checked ? Css.style(/* :: */[
                                          Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accentBackground))),
                                          /* [] */0
                                        ]) : "",
                                  children: null,
                                  key: key
                                };
                                var tmp$1 = onRowEnter !== undefined ? (function (param) {
                                      return Curry._2(onRowEnter, param, row);
                                    }) : undefined;
                                if (tmp$1 !== undefined) {
                                  tmp.onMouseEnter = Caml_option.valFromOption(tmp$1);
                                }
                                var tmp$2 = onRowLeave !== undefined ? (function (param) {
                                      return Curry._2(onRowLeave, param, row);
                                    }) : undefined;
                                if (tmp$2 !== undefined) {
                                  tmp.onMouseLeave = Caml_option.valFromOption(tmp$2);
                                }
                                var tmp$3 = onRowClick !== undefined ? (function (param) {
                                      return Curry._2(onRowClick, param, row);
                                    }) : undefined;
                                if (tmp$3 !== undefined) {
                                  tmp.onClick = Caml_option.valFromOption(tmp$3);
                                }
                                var tmp$4 = renderExpandedRowContent !== undefined ? Caml_option.some(Curry._1(renderExpandedRowContent, row)) : undefined;
                                if (tmp$4 !== undefined) {
                                  tmp.expandedContent = Caml_option.valFromOption(tmp$4);
                                }
                                return React.createElement(BtEnhancedTable$BtEnhancedTableRow, tmp, selected !== undefined ? React.createElement(BtTableCell$BsConsole.Data.make, {
                                                  className: Css.merge(/* :: */[
                                                        Css.style(/* :: */[
                                                              Css.important(Css.padding(/* zero */-789508312)),
                                                              /* [] */0
                                                            ]),
                                                        /* :: */[
                                                          showGrid ? Css.style(/* :: */[
                                                                  Css.position(/* sticky */188263721),
                                                                  /* :: */[
                                                                    Css.left(/* zero */-789508312),
                                                                    /* :: */[
                                                                      Css.zIndex(2),
                                                                      /* [] */0
                                                                    ]
                                                                  ]
                                                                ]) : "",
                                                          /* :: */[
                                                            "BtTableSelectCell",
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]),
                                                  children: React.createElement(Checkbox.default, {
                                                        checked: checked,
                                                        color: "primary",
                                                        onChange: (function ($$event) {
                                                            if (selected === undefined) {
                                                              return ;
                                                            }
                                                            var value = $$event.target.checked;
                                                            var newSelected = value ? Belt_Array.concat(selected, [Curry._1(getRowId, row)]) : Belt_Array.keep(selected, (function (id) {
                                                                      return id !== Curry._1(getRowId, row);
                                                                    }));
                                                            if (onSelectedChange !== undefined) {
                                                              return Curry._1(onSelectedChange, newSelected);
                                                            }
                                                            
                                                          })
                                                      })
                                                }) : null, Belt_Array.mapWithIndex(orderedColumns, (function (colIndex, column) {
                                                  var row$1 = Belt_Array.get(rows, 0);
                                                  var align = row$1 !== undefined ? getColumnAlignment(column, Caml_option.valFromOption(row$1)) : /* left */-944764921;
                                                  var match = column.name;
                                                  if (match === "__stub__") {
                                                    return React.createElement(BtTableCell$BsConsole.DataStub.make, {
                                                                children: renderStubDataCell !== undefined ? Curry._1(renderStubDataCell, undefined) : null,
                                                                key: "stub__" + String(colIndex)
                                                              });
                                                  }
                                                  var key$1 = key + ("-" + String(colIndex));
                                                  if (renderDataCell !== undefined) {
                                                    return Curry._4(renderDataCell, row, column, Curry._1(column.getCellValue, row), key$1);
                                                  }
                                                  var match$1 = Curry._1(column.getCellValue, row);
                                                  var tmp;
                                                  if (typeof match$1 === "number") {
                                                    tmp = null;
                                                  } else {
                                                    var variant = match$1[0];
                                                    if (variant >= 3654864) {
                                                      if (variant !== 365180284) {
                                                        if (variant !== 615627497) {
                                                          tmp = variant !== 737456202 ? null : I18N$BsConsole.showSkip(Pervasives.string_of_bool(match$1[1]));
                                                        } else {
                                                          var match$2 = match$1[1];
                                                          tmp = React.createElement(FormatRenderer$BsConsole.make, {
                                                                value: match$2[1],
                                                                attribute: match$2[0]
                                                              });
                                                        }
                                                      } else {
                                                        tmp = I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0.0", Pervasives.string_of_float(match$1[1])));
                                                      }
                                                    } else {
                                                      tmp = variant !== -976970511 ? (
                                                          variant !== -744106340 ? (
                                                              variant >= 3654863 ? I18N$BsConsole.showSkip(Numeral$BsConsole.format("0,0", String(match$1[1]))) : null
                                                            ) : match$1[1]
                                                        ) : I18N$BsConsole.showSkip(match$1[1]);
                                                    }
                                                  }
                                                  return React.createElement(BtTableCell$BsConsole.make, {
                                                              className: Css.merge(/* :: */[
                                                                    column.dataCellClassName,
                                                                    /* :: */[
                                                                      getDataCellClassName(column, row, onSortChange),
                                                                      /* [] */0
                                                                    ]
                                                                  ]),
                                                              align: align,
                                                              children: tmp,
                                                              key: key$1
                                                            });
                                                })));
                              })), rows.length === 0 ? React.createElement(BtTableRow$BsConsole.make, {
                                children: React.createElement(BtTableCell$BsConsole.Data.make, {
                                      colSpan: columns.length,
                                      className: Css.style(/* :: */[
                                            Css.important(Css.textAlign(/* center */98248149)),
                                            /* [] */0
                                          ]),
                                      children: I18N$BsConsole.showSkip(Belt_Option.getWithDefault(emptyTableText, I18N$BsConsole.get(undefined, "no data")))
                                    }),
                                key: "empty-row"
                              }) : null)), React.createElement("div", {
                      ref: hiddenRef,
                      className: Css.style(/* :: */[
                            Css.visibility(/* hidden */-862584982),
                            /* :: */[
                              Css.width(Css.pct(100)),
                              /* [] */0
                            ]
                          ])
                    })), tmp$2);
}

function make(columns, rows, getRowId, showGridOpt, renderDataCell, renderHeaderCell, renderStubHeader, renderExpandedRowContent, stubMinWidth, renderStubDataCell, sortingState, onSortChange, onColumnOrderChange, onSelectedChange, selected, onRowEnter, onRowLeave, onRowClick, resizeableColumnsOpt, draggableColumnsOpt, fixedLeftColumnsOpt, fixedRightColumn, columnOrderOpt, zebraStripeOpt, page, onChangePage, totalRows, loading, emptyTableText, containerClassName, columnWidthsOpt, onColumnWidthChange, children) {
  var showGrid = showGridOpt !== undefined ? showGridOpt : true;
  var resizeableColumns = resizeableColumnsOpt !== undefined ? resizeableColumnsOpt : false;
  var draggableColumns = draggableColumnsOpt !== undefined ? draggableColumnsOpt : false;
  var fixedLeftColumns = fixedLeftColumnsOpt !== undefined ? fixedLeftColumnsOpt : [];
  var columnOrder = columnOrderOpt !== undefined ? columnOrderOpt : [];
  var zebraStripe = zebraStripeOpt !== undefined ? zebraStripeOpt : false;
  var columnWidths = columnWidthsOpt !== undefined ? columnWidthsOpt : [];
  var tmp = {
    columns: columns,
    rows: rows,
    getRowId: getRowId,
    showGrid: showGrid,
    resizeableColumns: resizeableColumns,
    draggableColumns: draggableColumns,
    fixedLeftColumns: fixedLeftColumns,
    columnOrder: columnOrder,
    zebraStripe: zebraStripe,
    columnWidths: columnWidths
  };
  if (renderDataCell !== undefined) {
    tmp.renderDataCell = Caml_option.valFromOption(renderDataCell);
  }
  if (renderHeaderCell !== undefined) {
    tmp.renderHeaderCell = Caml_option.valFromOption(renderHeaderCell);
  }
  if (renderExpandedRowContent !== undefined) {
    tmp.renderExpandedRowContent = Caml_option.valFromOption(renderExpandedRowContent);
  }
  if (renderStubHeader !== undefined) {
    tmp.renderStubHeader = Caml_option.valFromOption(renderStubHeader);
  }
  if (stubMinWidth !== undefined) {
    tmp.stubMinWidth = Caml_option.valFromOption(stubMinWidth);
  }
  if (renderStubDataCell !== undefined) {
    tmp.renderStubDataCell = Caml_option.valFromOption(renderStubDataCell);
  }
  if (sortingState !== undefined) {
    tmp.sortingState = Caml_option.valFromOption(sortingState);
  }
  if (onSortChange !== undefined) {
    tmp.onSortChange = Caml_option.valFromOption(onSortChange);
  }
  if (onSelectedChange !== undefined) {
    tmp.onSelectedChange = Caml_option.valFromOption(onSelectedChange);
  }
  if (selected !== undefined) {
    tmp.selected = Caml_option.valFromOption(selected);
  }
  if (onRowEnter !== undefined) {
    tmp.onRowEnter = Caml_option.valFromOption(onRowEnter);
  }
  if (onRowLeave !== undefined) {
    tmp.onRowLeave = Caml_option.valFromOption(onRowLeave);
  }
  if (onRowClick !== undefined) {
    tmp.onRowClick = Caml_option.valFromOption(onRowClick);
  }
  if (onColumnOrderChange !== undefined) {
    tmp.onColumnOrderChange = Caml_option.valFromOption(onColumnOrderChange);
  }
  if (fixedRightColumn !== undefined) {
    tmp.fixedRightColumn = Caml_option.valFromOption(fixedRightColumn);
  }
  if (page !== undefined) {
    tmp.page = Caml_option.valFromOption(page);
  }
  if (onChangePage !== undefined) {
    tmp.onChangePage = Caml_option.valFromOption(onChangePage);
  }
  if (totalRows !== undefined) {
    tmp.totalRows = Caml_option.valFromOption(totalRows);
  }
  if (loading !== undefined) {
    tmp.loading = Caml_option.valFromOption(loading);
  }
  if (emptyTableText !== undefined) {
    tmp.emptyTableText = Caml_option.valFromOption(emptyTableText);
  }
  if (containerClassName !== undefined) {
    tmp.containerClassName = Caml_option.valFromOption(containerClassName);
  }
  if (onColumnWidthChange !== undefined) {
    tmp.onColumnWidthChange = Caml_option.valFromOption(onColumnWidthChange);
  }
  return ReasonReactCompat.wrapReactForReasonReact(BtEnhancedTable, tmp, children);
}

var Re = {
  make: make
};

var make$1 = BtEnhancedTable;

exports.Styles = Styles;
exports.BtEnhancedTableRow = BtEnhancedTableRow;
exports.setWidth = setWidth;
exports.defaultRowSort = defaultRowSort;
exports.getColumnAlignment = getColumnAlignment;
exports.getColumnOrder = getColumnOrder;
exports.getColumnWidths = getColumnWidths;
exports.reducer = reducer;
exports.getInitialState = getInitialState;
exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
