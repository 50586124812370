// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var Table = require("@material-ui/core/Table");
var Tooltip = require("@material-ui/core/Tooltip");
var Delete = require("@material-ui/icons/Delete");
var TableRow = require("@material-ui/core/TableRow");
var TableBody = require("@material-ui/core/TableBody");
var TableCell = require("@material-ui/core/TableCell");
var TableHead = require("@material-ui/core/TableHead");
var IconButton = require("@material-ui/core/IconButton");

function TeamsProjectAccessTable$NoProjectMembers(Props) {
  return React.createElement(Table.default, {
              children: null
            }, React.createElement(TableHead.default, {
                  children: React.createElement(TableRow.default, {
                        children: null
                      }, React.createElement(TableCell.default, {
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* TableTitle */13,
                                  color: Colors$BsConsole.grey0,
                                  children: I18N$BsConsole.show(undefined, "Project")
                                })
                          }), React.createElement(TableCell.default, {
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* TableTitle */13,
                                  color: Colors$BsConsole.grey0,
                                  children: I18N$BsConsole.show(undefined, "Role")
                                })
                          }))
                }), React.createElement(TableBody.default, {
                  children: React.createElement(TableRow.default, {
                        children: React.createElement(TableCell.default, {
                              colSpan: 2,
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* TableBody */14,
                                    color: Colors$BsConsole.grey0,
                                    children: I18N$BsConsole.show(undefined, "This team does not grant access to any projects yet.")
                                  })
                            })
                      })
                }));
}

var NoProjectMembers = {
  make: TeamsProjectAccessTable$NoProjectMembers
};

function TeamsProjectAccessTable(Props) {
  var teams = Props.teams;
  var allProjects = Props.allProjects;
  var setDeleteDialog = Props.setDeleteDialog;
  var team = Props.team;
  var setDeleteRefetch = Props.setDeleteRefetch;
  var refetchProjectMemberTeams = Props.refetchProjectMemberTeams;
  var rows = $$Array.map((function (member) {
          var project = Belt_Option.getExn(Belt_Array.getBy(allProjects, (function (project) {
                      return project.pid === member.project;
                    })));
          return /* tuple */[
                  project,
                  member
                ];
        }), teams);
  return React.createElement(Table.default, {
              children: null
            }, React.createElement(TableHead.default, {
                  children: React.createElement(TableRow.default, {
                        children: null
                      }, React.createElement(TableCell.default, {
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* TableTitle */13,
                                  color: Colors$BsConsole.grey0,
                                  children: I18N$BsConsole.show(undefined, "Project")
                                })
                          }), React.createElement(TableCell.default, {
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* TableTitle */13,
                                  color: Colors$BsConsole.grey0,
                                  children: I18N$BsConsole.show(undefined, "Role")
                                })
                          }), React.createElement(TableCell.default, {
                            children: null
                          }))
                }), React.createElement(TableBody.default, {
                  children: $$Array.map((function (param) {
                          var member = param[1];
                          var project = param[0];
                          return React.createElement(TableRow.default, {
                                      children: null,
                                      key: project.name
                                    }, React.createElement(TableCell.default, {
                                          children: React.createElement(BtTypography$BsConsole.make, {
                                                variant: /* TableBody */14,
                                                color: Colors$BsConsole.grey0,
                                                children: I18N$BsConsole.showSkip(project.name)
                                              })
                                        }), React.createElement(TableCell.default, {
                                          children: React.createElement(BtTypography$BsConsole.make, {
                                                variant: /* TableBody */14,
                                                color: Colors$BsConsole.grey0,
                                                children: I18N$BsConsole.showSkip(member.role)
                                              })
                                        }), React.createElement(TableCell.default, {
                                          className: Css.style(/* :: */[
                                                Css.display(/* flex */-1010954439),
                                                /* :: */[
                                                  Css.justifyContent(/* flexEnd */924268066),
                                                  /* :: */[
                                                    Css.important(Css.marginBottom(Css.px(-1))),
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]),
                                          children: React.createElement(Tooltip.default, {
                                                children: React.createElement(IconButton.default, {
                                                      ariaLabel: I18N$BsConsole.get(undefined, "Remove project access from team"),
                                                      onClick: (function (_e) {
                                                          Curry._1(setDeleteRefetch, (function (param) {
                                                                  return refetchProjectMemberTeams;
                                                                }));
                                                          return Curry._1(setDeleteDialog, (function (param) {
                                                                        return /* ProjectMemberTeam */Block.__(2, [
                                                                                  member.project,
                                                                                  member.team,
                                                                                  team,
                                                                                  project
                                                                                ]);
                                                                      }));
                                                        }),
                                                      children: React.createElement(Delete.default, { })
                                                    }),
                                                title: I18N$BsConsole.get(undefined, "Remove from team")
                                              })
                                        }));
                        }), rows)
                }));
}

var make = TeamsProjectAccessTable;

exports.NoProjectMembers = NoProjectMembers;
exports.make = make;
/* Css Not a pure module */
