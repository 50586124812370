// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var I18N$BsConsole = require("../../I18N.js");
var Util$BsConsole = require("../../util.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var Configuration$BsConsole = require("../../configuration.js");
var Button = require("@material-ui/core/Button");
var IconButton = require("@material-ui/core/IconButton");
var ArrowUpward = require("@material-ui/icons/ArrowUpward");
var ArrowDownward = require("@material-ui/icons/ArrowDownward");

function fromJson(json) {
  return {
          id: Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.$$int, param);
                }), json),
          position: Json_decode.field("position", Json_decode.$$int, json),
          repositoryId: Json_decode.field("repository_id", Json_decode.$$int, json),
          attributeTemplate: Json_decode.field("attribute_template", Json_decode.string, json)
        };
}

function toJson(t) {
  var props_000 = /* tuple */[
    "position",
    t.position
  ];
  var props_001 = /* :: */[
    /* tuple */[
      "repository_id",
      t.repositoryId
    ],
    /* :: */[
      /* tuple */[
        "attribute_template",
        t.attributeTemplate
      ],
      /* [] */0
    ]
  ];
  var props = /* :: */[
    props_000,
    props_001
  ];
  var id = t.id;
  return Json_encode.object_(id !== undefined ? /* :: */[
                /* tuple */[
                  "id",
                  id
                ],
                props
              ] : props);
}

function fetchCommitRules(config, repositoryId, universeName, projectName) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + ("/commit_rules?repository_id=" + (String(repositoryId) + "")))))))]), undefined, /* Get */0, undefined, (function (param) {
                return /* Ok */Block.__(0, [Json_decode.field("commit_rules", (function (param) {
                                  return Json_decode.list(fromJson, param);
                                }), param.json)]);
              }), undefined, undefined);
}

function saveCommitRule(commitRule, universeName, projectName, config) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  var id = commitRule.id;
  if (id !== undefined) {
    return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + ("/commit_rules/" + (String(id) + "")))))))]), undefined, /* Put */Block.__(1, [toJson(commitRule)]), undefined, (function (param) {
                  return /* Ok */Block.__(0, [Json_decode.field("commit_rule", fromJson, param.json)]);
                }), undefined, undefined);
  } else {
    return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + "/commit_rules")))))]), undefined, /* Post */Block.__(0, [toJson(commitRule)]), undefined, (function (param) {
                  return /* Ok */Block.__(0, [fromJson(param.json)]);
                }), undefined, undefined);
  }
}

function CommitRule$Rule(Props) {
  var commitRule = Props.commitRule;
  var positionChangeCb = Props.positionChangeCb;
  var moveUp = function (_evt) {
    return Curry._2(positionChangeCb, commitRule.id, commitRule.position - 1 | 0);
  };
  var moveDown = function (_evt) {
    return Curry._2(positionChangeCb, commitRule.id, commitRule.position + 1 | 0);
  };
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey5)),
                    /* :: */[
                      Css.padding(Css.rem(1)),
                      /* :: */[
                        Css.margin(Css.rem(1)),
                        /* :: */[
                          Css.marginLeft(/* zero */-789508312),
                          /* [] */0
                        ]
                      ]
                    ]
                  ])
            }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: commitRule.attributeTemplate,
                  label: I18N$BsConsole.show(undefined, "Attribute Template"),
                  onChange: (function (param) {
                      
                    }),
                  disabled: true
                }), React.createElement(IconButton.default, {
                  onClick: moveUp,
                  children: React.createElement(ArrowUpward.default, { })
                }), React.createElement(IconButton.default, {
                  onClick: moveDown,
                  children: React.createElement(ArrowDownward.default, { })
                }));
}

var Rule = {
  make: CommitRule$Rule
};

function CommitRule$Rules(Props) {
  var token = Props.token;
  var config = Props.config;
  var repositoryId = Props.repositoryId;
  var refetchRequest = Props.refetchRequest;
  var universeName = Props.universeName;
  var projectName = Props.projectName;
  var match = React.useState((function () {
          return /* [] */0;
        }));
  var setRules = match[1];
  var rules = match[0];
  var match$1 = React.useState((function () {
          return 0;
        }));
  var setRefetchKey = match$1[1];
  React.useEffect((function () {
          Curry._1(setRefetchKey, (function (k) {
                  return k + 1 | 0;
                }));
          
        }), [refetchRequest]);
  React.useEffect((function () {
          Task2$BsConsole.handle(token, fetchCommitRules(config, repositoryId, universeName, projectName), undefined, (function (rules) {
                  Belt_Result.map(rules, (function (rules) {
                          return Curry._1(setRules, (function (param) {
                                        return rules;
                                      }));
                        }));
                  
                }));
          
        }), [match$1[0]]);
  var positionChangeCb = function (id, newPos) {
    var newPos$1 = newPos < 0 ? 0 : newPos;
    var newPos$2 = newPos$1 > (Belt_List.length(rules) - 1 | 0) ? Belt_List.length(rules) - 1 | 0 : newPos$1;
    var origRule = Belt_List.getBy(rules, (function (r) {
            return Caml_obj.caml_equal(r.id, id);
          }));
    if (origRule === undefined) {
      return ;
    }
    var newRule_id = origRule.id;
    var newRule_repositoryId = origRule.repositoryId;
    var newRule_attributeTemplate = origRule.attributeTemplate;
    var newRule = {
      id: newRule_id,
      position: newPos$2,
      repositoryId: newRule_repositoryId,
      attributeTemplate: newRule_attributeTemplate
    };
    return Task2$BsConsole.handle(token, saveCommitRule(newRule, universeName, projectName, config), undefined, (function (savedRule) {
                  !savedRule.tag;
                  return Curry._1(setRefetchKey, (function (k) {
                                return k + 1 | 0;
                              }));
                }));
  };
  return React.createElement(React.Fragment, undefined, Belt_List.toArray(Belt_List.map(Belt_List.sort(rules, (function (r, r$prime) {
                            return r.position - r$prime.position | 0;
                          })), (function (r) {
                        return Belt_Option.mapWithDefault(r.id, null, (function (id) {
                                      return React.createElement(CommitRule$Rule, {
                                                  commitRule: r,
                                                  positionChangeCb: positionChangeCb,
                                                  key: String(id)
                                                });
                                    }));
                      }))));
}

var Rules = {
  make: CommitRule$Rules
};

function CommitRule$NewRule(Props) {
  var repositoryId = Props.repositoryId;
  var config = Props.config;
  var token = Props.token;
  var createCommitRuleCb = Props.createCommitRuleCb;
  var universeName = Props.universeName;
  var projectName = Props.projectName;
  var match = React.useState((function () {
          
        }));
  var setAttributeTemplate = match[1];
  var attributeTemplate = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setErrorMsg = match$1[1];
  var errorMsg = match$1[0];
  return React.createElement(React.Fragment, undefined, React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.mapWithDefault(attributeTemplate, "", Util$BsConsole.identity),
                  label: I18N$BsConsole.show(undefined, "Attribute template"),
                  onChange: (function ($$event) {
                      return Curry._1(setAttributeTemplate, BtSettings$BsConsole.getInputValue($$event));
                    })
                }), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  disabled: attributeTemplate === undefined,
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginLeft(Css.rem(1)),
                          /* [] */0
                        ])
                  },
                  onClick: (function (param) {
                      Curry._1(setErrorMsg, (function (param) {
                              
                            }));
                      if (attributeTemplate !== undefined) {
                        var newRule = {
                          id: undefined,
                          position: 0,
                          repositoryId: repositoryId,
                          attributeTemplate: attributeTemplate
                        };
                        Task2$BsConsole.handle(token, saveCommitRule(newRule, universeName, projectName, config), undefined, (function (_resp) {
                                Curry._1(setAttributeTemplate, (function (param) {
                                        
                                      }));
                                return Curry._1(createCommitRuleCb, undefined);
                              }));
                      }
                      
                    }),
                  children: I18N$BsConsole.show(undefined, "Create rule")
                }), errorMsg !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading4 */3,
                    className: Css.style(/* :: */[
                          Css.marginTop(Css.px(3)),
                          /* :: */[
                            Css.fontSize(Css.px(18)),
                            /* [] */0
                          ]
                        ]),
                    color: Colors$BsConsole.errorDark,
                    children: I18N$BsConsole.dynamic(errorMsg)
                  }) : null);
}

var NewRule = {
  make: CommitRule$NewRule
};

exports.fromJson = fromJson;
exports.toJson = toJson;
exports.fetchCommitRules = fetchCommitRules;
exports.saveCommitRule = saveCommitRule;
exports.Rule = Rule;
exports.Rules = Rules;
exports.NewRule = NewRule;
/* Css Not a pure module */
