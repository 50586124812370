// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("../../I18N.js");
var Util$BsConsole = require("../../util.js");
var ApiWf$BsConsole = require("../../workflow/workflows-api/ApiWf.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var BpgTask$BsConsole = require("../../BpgTask.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var Menu = require("@material-ui/core/Menu");
var WfComponents$BsConsole = require("../../workflow/WfComponents.js");
var Paper = require("@material-ui/core/Paper");
var Table = require("@material-ui/core/Table");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var $$Error = require("@material-ui/icons/Error");
var WarningMessage$BsConsole = require("../../WarningMessage.js");
var Tooltip = require("@material-ui/core/Tooltip");
var Cancel = require("@material-ui/icons/Cancel");
var IdentityVariant$BsConsole = require("../../IdentityVariant.js");
var MenuItem = require("@material-ui/core/MenuItem");
var TableRow = require("@material-ui/core/TableRow");
var Warning = require("@material-ui/icons/Warning");
var TableBody = require("@material-ui/core/TableBody");
var TableCell = require("@material-ui/core/TableCell");
var TableHead = require("@material-ui/core/TableHead");
var MoreVert = require("@material-ui/icons/MoreVert");
var IconButton = require("@material-ui/core/IconButton");
var DialogTitle = require("@material-ui/core/DialogTitle");
var ApiScheduledReports$BsConsole = require("./ApiScheduledReports.js");
var CheckCircle = require("@material-ui/icons/CheckCircle");
var DialogActions = require("@material-ui/core/DialogActions");
var DialogContent = require("@material-ui/core/DialogContent");
var CircularProgress = require("@material-ui/core/CircularProgress");
var DialogContentText = require("@material-ui/core/DialogContentText");

var emdash = "\u2014";

var container = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var loadingContainerStyle = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.color(Css.hex(Colors$BsConsole.blue)),
            /* :: */[
              Css.zIndex(5),
              /* :: */[
                Css.height(Css.vh(40)),
                /* :: */[
                  Css.width(Css.pct(100)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var paper = Css.style(/* :: */[
      Css.padding(Css.px(24)),
      /* :: */[
        Css.paddingBottom(Css.px(50)),
        /* [] */0
      ]
    ]);

var cell = {
  root: Css.style(/* :: */[
        Css.important(Css.padding(Css.px(0))),
        /* [] */0
      ])
};

var cellHeader = {
  root: Css.style(/* :: */[
        Css.important(Css.padding(Css.px(0))),
        /* :: */[
          Css.fontSize(Css.px(12)),
          /* :: */[
            Css.lineHeight(Css.px(16)),
            /* :: */[
              Css.fontWeight(/* `num */[
                    5496390,
                    600
                  ]),
              /* [] */0
            ]
          ]
        ]
      ])
};

var cellHeaderCenter = {
  root: Css.style(/* :: */[
        Css.important(Css.padding(Css.px(0))),
        /* :: */[
          Css.fontSize(Css.px(12)),
          /* :: */[
            Css.lineHeight(Css.px(16)),
            /* :: */[
              Css.fontWeight(/* `num */[
                    5496390,
                    600
                  ]),
              /* :: */[
                Css.textIndent(Css.px(15)),
                /* [] */0
              ]
            ]
          ]
        ]
      ])
};

var largeCell = Css.style(/* :: */[
      Css.width(Css.pct(30)),
      /* [] */0
    ]);

var smallCell = Css.style(/* :: */[
      Css.width(Css.pct(15)),
      /* [] */0
    ]);

var menuCell = Css.style(/* :: */[
      Css.width(Css.pct(5)),
      /* [] */0
    ]);

var statusIcon = Css.style(/* :: */[
      Css.fontSize(Css.rem(1.2)),
      /* :: */[
        Css.marginRight(Css.px(5)),
        /* [] */0
      ]
    ]);

var statusIconContainer = Css.style(/* :: */[
      Css.display(/* inlineFlex */53323314),
      /* :: */[
        Css.paddingRight(Css.px(5)),
        /* :: */[
          Css.paddingLeft(Css.px(2)),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.borderRadius(Css.rem(0.5)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var green = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.npGreen)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.greenExtraLight)),
        /* [] */0
      ]
    ]);

var yellow = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.orangeDark)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.orangeExtraLight)),
        /* [] */0
      ]
    ]);

var red = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.redDark)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.redBackground)),
        /* [] */0
      ]
    ]);

var Styles = {
  container: container,
  loadingContainerStyle: loadingContainerStyle,
  paper: paper,
  cell: cell,
  cellHeader: cellHeader,
  cellHeaderCenter: cellHeaderCenter,
  largeCell: largeCell,
  smallCell: smallCell,
  menuCell: menuCell,
  statusIcon: statusIcon,
  statusIconContainer: statusIconContainer,
  green: green,
  yellow: yellow,
  red: red
};

var initialState = {
  reportsRemote: /* NotAsked */0,
  dialog: /* ClosedDialog */0,
  errorMessage: ""
};

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            reportsRemote: /* Loading */1,
            dialog: /* ClosedDialog */0,
            errorMessage: state.errorMessage
          };
  }
  switch (action.tag | 0) {
    case /* SetReportsRemote */0 :
        return {
                reportsRemote: action[0],
                dialog: state.dialog,
                errorMessage: state.errorMessage
              };
    case /* SetDialog */1 :
        return {
                reportsRemote: state.reportsRemote,
                dialog: action[0],
                errorMessage: state.errorMessage
              };
    case /* SetErrorMessage */2 :
        return {
                reportsRemote: state.reportsRemote,
                dialog: state.dialog,
                errorMessage: action[0]
              };
    
  }
}

function convertUtcToLocalTime(maybeUtcTimestamp) {
  if (maybeUtcTimestamp === undefined) {
    return emdash;
  }
  var utcDate = new Date(maybeUtcTimestamp);
  var year = utcDate.getFullYear() | 0;
  var month = (utcDate.getMonth() | 0) + 1 | 0;
  var day = utcDate.getDate() | 0;
  var hours = utcDate.getHours() | 0;
  var minutes = utcDate.getMinutes() | 0;
  var ampm = hours >= 12 ? "PM" : "AM";
  return Curry._6(I18N$BsConsole.getf(undefined, /* Format */[
                  /* Int */Block.__(4, [
                      /* Int_d */0,
                      /* Lit_padding */Block.__(0, [
                          /* Zeros */2,
                          2
                        ]),
                      /* No_precision */0,
                      /* Char_literal */Block.__(12, [
                          /* "/" */47,
                          /* Int */Block.__(4, [
                              /* Int_d */0,
                              /* Lit_padding */Block.__(0, [
                                  /* Zeros */2,
                                  2
                                ]),
                              /* No_precision */0,
                              /* Char_literal */Block.__(12, [
                                  /* "/" */47,
                                  /* Int */Block.__(4, [
                                      /* Int_d */0,
                                      /* Lit_padding */Block.__(0, [
                                          /* Zeros */2,
                                          2
                                        ]),
                                      /* No_precision */0,
                                      /* Char_literal */Block.__(12, [
                                          /* " " */32,
                                          /* Int */Block.__(4, [
                                              /* Int_d */0,
                                              /* Lit_padding */Block.__(0, [
                                                  /* Zeros */2,
                                                  2
                                                ]),
                                              /* No_precision */0,
                                              /* Char_literal */Block.__(12, [
                                                  /* ":" */58,
                                                  /* Int */Block.__(4, [
                                                      /* Int_d */0,
                                                      /* Lit_padding */Block.__(0, [
                                                          /* Zeros */2,
                                                          2
                                                        ]),
                                                      /* No_precision */0,
                                                      /* Char_literal */Block.__(12, [
                                                          /* " " */32,
                                                          /* String */Block.__(2, [
                                                              /* No_padding */0,
                                                              /* End_of_format */0
                                                            ])
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ]),
                  "%02d/%02d/%02d %02d:%02d %s"
                ]), month, day, year % 100, hours % 12, minutes, ampm);
}

function ScheduledReportsTable$TableRow(Props) {
  var report = Props.report;
  var users = Props.users;
  var handleDeleteConfirmation = Props.handleDeleteConfirmation;
  var onEditReport = Props.onEditReport;
  var config = Props.config;
  var token = Props.token;
  var projectName = Props.projectName;
  var handleRefresh = Props.handleRefresh;
  var handleError = Props.handleError;
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var getUserNameFromId = function (id) {
    var maybeUser = Belt_Array.getBy(users, (function (user) {
            return user.uid === id;
          }));
    if (maybeUser !== undefined) {
      return maybeUser.username;
    } else {
      return emdash;
    }
  };
  var match$1 = report.enabled;
  var match$2 = report.lastError;
  return React.createElement(TableRow.default, {
              children: null
            }, React.createElement(TableCell.default, {
                  classes: cell,
                  children: I18N$BsConsole.show(undefined, report.name)
                }), React.createElement(TableCell.default, {
                  classes: cell,
                  children: I18N$BsConsole.show(undefined, getUserNameFromId(report.ownerId))
                }), React.createElement(TableCell.default, {
                  classes: cell,
                  children: match$1 ? (
                      match$2 !== undefined ? React.createElement(Tooltip.default, {
                              placement: "bottom-start",
                              children: React.createElement("div", {
                                    className: Css.merge(/* :: */[
                                          statusIconContainer,
                                          /* :: */[
                                            yellow,
                                            /* [] */0
                                          ]
                                        ])
                                  }, React.createElement(Warning.default, {
                                        className: statusIcon
                                      }), I18N$BsConsole.show(undefined, "Warning")),
                              title: I18N$BsConsole.get(undefined, match$2)
                            }) : React.createElement("div", {
                              className: Css.merge(/* :: */[
                                    statusIconContainer,
                                    /* :: */[
                                      green,
                                      /* [] */0
                                    ]
                                  ])
                            }, React.createElement(CheckCircle.default, {
                                  className: statusIcon
                                }), I18N$BsConsole.show(undefined, "Active"))
                    ) : (
                      match$2 !== undefined ? React.createElement(Tooltip.default, {
                              placement: "bottom-start",
                              children: React.createElement("div", {
                                    className: Css.merge(/* :: */[
                                          statusIconContainer,
                                          /* :: */[
                                            red,
                                            /* [] */0
                                          ]
                                        ])
                                  }, React.createElement($$Error.default, {
                                        className: statusIcon
                                      }), I18N$BsConsole.show(undefined, "Error")),
                              title: I18N$BsConsole.get(undefined, match$2)
                            }) : React.createElement("div", {
                              className: Css.merge(/* :: */[
                                    statusIconContainer,
                                    /* :: */[
                                      red,
                                      /* [] */0
                                    ]
                                  ])
                            }, React.createElement(Cancel.default, {
                                  className: statusIcon
                                }), I18N$BsConsole.show(undefined, "Disabled"))
                    )
                }), React.createElement(Tooltip.default, {
                  placement: "bottom-start",
                  children: React.createElement(TableCell.default, {
                        classes: cell,
                        children: I18N$BsConsole.show(undefined, convertUtcToLocalTime(report.lastSent))
                      }),
                  title: I18N$BsConsole.get(undefined, "Local timezone")
                }), React.createElement(TableCell.default, {
                  className: menuCell,
                  classes: cell,
                  children: null
                }, React.createElement(IconButton.default, {
                      onClick: (function ($$event) {
                          var el = $$event.currentTarget;
                          if (!(el == null)) {
                            return Curry._1(setAnchorEl, (function (param) {
                                          return Caml_option.some(el);
                                        }));
                          }
                          
                        }),
                      children: React.createElement(MoreVert.default, { })
                    }), anchorEl !== undefined ? React.createElement(Menu.default, {
                        anchorEl: Caml_option.valFromOption(anchorEl),
                        open: true,
                        onClose: (function (param) {
                            return Curry._1(setAnchorEl, (function (param) {
                                          
                                        }));
                          }),
                        children: null
                      }, React.createElement(MenuItem.default, {
                            onClick: (function (param) {
                                Curry._1(setAnchorEl, (function (param) {
                                        
                                      }));
                                return Curry._1(onEditReport, report);
                              }),
                            children: I18N$BsConsole.show(undefined, "Edit report")
                          }), React.createElement(MenuItem.default, {
                            onClick: (function (param) {
                                Curry._1(setAnchorEl, (function (param) {
                                        
                                      }));
                                var onSuccess = function (param) {
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Report preview was sent"));
                                };
                                var onFailure = function (err) {
                                  return Curry._1(handleError, "Error sending report preview: " + err);
                                };
                                var maybeTask = ApiScheduledReports$BsConsole.PreviewScheduledReport.previewScheduledReportTask(config, projectName, report.id, [config.user.email]);
                                if (maybeTask !== undefined) {
                                  return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                                return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                                              }));
                                } else {
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                }
                              }),
                            children: I18N$BsConsole.show(undefined, "Preview report")
                          }), React.createElement(MenuItem.default, {
                            onClick: (function (param) {
                                Curry._1(setAnchorEl, (function (param) {
                                        
                                      }));
                                var onSuccess = function (param) {
                                  return Curry._1(handleRefresh, undefined);
                                };
                                var onFailure = function (err) {
                                  return Curry._1(handleError, "Error sumbitting report setup: " + err);
                                };
                                var maybeTask = ApiScheduledReports$BsConsole.ToogleEnabledScheduledReport.toogleEnabledScheduledReportTask(!report.enabled, config, projectName, report.id);
                                if (maybeTask !== undefined) {
                                  return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                                return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                                              }));
                                } else {
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                }
                              }),
                            children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* String_literal */Block.__(11, [
                                              " report",
                                              /* End_of_format */0
                                            ])
                                        ]),
                                      "%s report"
                                    ]), report.enabled ? "Disable" : "Enable")
                          }), React.createElement(MenuItem.default, {
                            onClick: (function (param) {
                                Curry._1(setAnchorEl, (function (param) {
                                        
                                      }));
                                return Curry._1(handleDeleteConfirmation, report);
                              }),
                            children: I18N$BsConsole.show(undefined, "Delete report")
                          })) : null));
}

var TableRow$1 = {
  convertUtcToLocalTime: convertUtcToLocalTime,
  make: ScheduledReportsTable$TableRow
};

function ScheduledReportsTable$TableHead(Props) {
  return React.createElement(TableHead.default, {
              children: React.createElement(TableRow.default, {
                    children: null
                  }, React.createElement(TableCell.default, {
                        className: largeCell,
                        classes: cellHeader,
                        children: I18N$BsConsole.show(undefined, "Title")
                      }), React.createElement(TableCell.default, {
                        className: largeCell,
                        classes: cellHeader,
                        children: I18N$BsConsole.show(undefined, "Owner")
                      }), React.createElement(TableCell.default, {
                        className: smallCell,
                        classes: cellHeaderCenter,
                        children: I18N$BsConsole.show(undefined, "Status")
                      }), React.createElement(TableCell.default, {
                        className: smallCell,
                        classes: cellHeader,
                        children: I18N$BsConsole.show(undefined, "Last Sent")
                      }), React.createElement(TableCell.default, {
                        className: Css.style(/* :: */[
                              Css.width(Css.px(50)),
                              /* [] */0
                            ]),
                        classes: cellHeader
                      }))
            });
}

var TableHead$1 = {
  make: ScheduledReportsTable$TableHead
};

function ScheduledReportsTable$DeleteConfirmation(Props) {
  var report = Props.report;
  var token = Props.token;
  var handleSetError = Props.handleSetError;
  var closeDialog = Props.closeDialog;
  var closeDialogAndRefetch = Props.closeDialogAndRefetch;
  var config = Props.config;
  var projectName = Props.projectName;
  return React.createElement(Dialog.default, {
              open: true,
              onClose: (function (param) {
                  return Curry._1(closeDialog, undefined);
                }),
              children: null
            }, React.createElement(DialogTitle.default, {
                  children: I18N$BsConsole.show(undefined, "Are you sure?")
                }), React.createElement(DialogContent.default, {
                  children: React.createElement(DialogContentText.default, {
                        children: Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                                  /* String_literal */Block.__(11, [
                                      "Are you sure you want to delete the report \"",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* String_literal */Block.__(11, [
                                              "\"?",
                                              /* End_of_format */0
                                            ])
                                        ])
                                    ]),
                                  "Are you sure you want to delete the report \"%s\"?"
                                ]), report.name)
                      })
                }), React.createElement(DialogActions.default, {
                  children: null
                }, React.createElement(Button.default, {
                      color: "primary",
                      onClick: (function (param) {
                          return Curry._1(closeDialog, undefined);
                        }),
                      children: I18N$BsConsole.show(undefined, "No")
                    }), React.createElement(Button.default, {
                      color: "primary",
                      onClick: (function (param) {
                          var reportId = report.id;
                          var maybeTask = ApiScheduledReports$BsConsole.DeleteScheduledReport.deleteScheduledReportTask(config, projectName, reportId);
                          if (maybeTask !== undefined) {
                            return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                          return ApiWf$BsConsole.responseCb((function (param) {
                                                        SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Report was deleted successfully."));
                                                        return Curry._1(closeDialogAndRefetch, undefined);
                                                      }), (function (err) {
                                                        Curry._1(closeDialog, undefined);
                                                        return Curry._1(handleSetError, "Could not delete integration: " + err);
                                                      }), param);
                                        }));
                          } else {
                            return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                          }
                        }),
                      children: I18N$BsConsole.show(undefined, "Yes")
                    })));
}

var DeleteConfirmation = {
  make: ScheduledReportsTable$DeleteConfirmation
};

function ScheduledReportsTable(Props) {
  var token = Props.token;
  var config = Props.config;
  var projectName = Props.projectName;
  var onEditReport = Props.onEditReport;
  var onCreateReport = Props.onCreateReport;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var errorMessage = match$1.errorMessage;
  var dialog = match$1.dialog;
  var reportsRemote = match$1.reportsRemote;
  React.useEffect((function () {
          if (typeof reportsRemote === "number") {
            var onSuccess = function (reports) {
              return Curry._1(dispatch, /* SetReportsRemote */Block.__(0, [/* Success */Block.__(0, [reports])]));
            };
            var onFailure = function (err) {
              return Curry._1(dispatch, /* SetErrorMessage */Block.__(2, [err]));
            };
            var maybeTask = ApiScheduledReports$BsConsole.QueryScheduledReports.queryScheduledReportsTask(projectName, config);
            if (maybeTask !== undefined) {
              Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                      return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
                    }));
            } else {
              SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
            }
          }
          
        }), [reportsRemote]);
  var allUsersRemote = Task2$BsConsole.use(undefined, BpgTask$BsConsole.Users.fetchAll, undefined);
  var users;
  if (typeof allUsersRemote === "number") {
    users = [];
  } else if (allUsersRemote.tag) {
    users = [];
  } else {
    var __x = Belt_List.fromArray(Util$BsConsole.filterBacktraceInternalUsers(allUsersRemote[0][0], config.universe.name, IdentityVariant$BsConsole.current === /* Unity */2));
    users = Belt_List.toArray(Belt_List.sort(__x, (function (u1, u2) {
                return $$String.compare(u1.username.toLowerCase(), u2.username.toLowerCase());
              })));
  }
  var tmp;
  var exit = 0;
  if (typeof reportsRemote === "number") {
    exit = 1;
  } else if (reportsRemote.tag) {
    tmp = null;
  } else {
    var reports = reportsRemote[0];
    tmp = reports.length !== 0 ? React.createElement(Table.default, {
            children: null
          }, React.createElement(ScheduledReportsTable$TableHead, { }), React.createElement(TableBody.default, {
                children: Belt_Array.map(reports, (function (report) {
                        return React.createElement(ScheduledReportsTable$TableRow, {
                                    report: report,
                                    users: users,
                                    handleDeleteConfirmation: (function (report) {
                                        return Curry._1(dispatch, /* SetDialog */Block.__(1, [/* DeleteReport */[report]]));
                                      }),
                                    onEditReport: onEditReport,
                                    config: config,
                                    token: token,
                                    projectName: projectName,
                                    handleRefresh: (function (param) {
                                        return Curry._1(dispatch, /* SetReportsRemote */Block.__(0, [/* Loading */1]));
                                      }),
                                    handleError: (function (err) {
                                        return Curry._1(dispatch, /* SetErrorMessage */Block.__(2, [err]));
                                      })
                                  });
                      }))
              })) : React.createElement(WfComponents$BsConsole.EmptyTableMessage.make, {
            title: "No scheduled reports configured yet",
            description: "Create reports to start automatically recieving e-mailed reports.",
            buttonLabel: "Create report",
            onClick: (function (param) {
                return Curry._1(onCreateReport, undefined);
              })
          });
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: loadingContainerStyle
        }, React.createElement(CircularProgress.default, {
              size: 100,
              color: "inherit"
            }));
  }
  return React.createElement(BtSettings$BsConsole.Container.make, {
              className: container,
              children: null
            }, dialog ? React.createElement(ScheduledReportsTable$DeleteConfirmation, {
                    report: dialog[0],
                    token: token,
                    handleSetError: (function (err) {
                        return Curry._1(dispatch, /* SetErrorMessage */Block.__(2, [err]));
                      }),
                    closeDialog: (function (param) {
                        return Curry._1(dispatch, /* SetDialog */Block.__(1, [/* ClosedDialog */0]));
                      }),
                    closeDialogAndRefetch: (function (param) {
                        return Curry._1(dispatch, /* DialogResetAndRefetchReports */0);
                      }),
                    config: config,
                    projectName: projectName
                  }) : null, errorMessage !== "" ? React.createElement(WarningMessage$BsConsole.make, {
                    errorMessage: I18N$BsConsole.show(undefined, errorMessage)
                  }) : null, React.createElement(Paper.default, {
                  classes: {
                    root: paper
                  },
                  children: tmp
                }));
}

var component = ReasonReact.statelessComponent("ScheduledReportsTable");

function make(token, config, projectName, onEditReport, onCreateReport, children) {
  return ReasonReactCompat.wrapReactForReasonReact(ScheduledReportsTable, {
              token: token,
              config: config,
              projectName: projectName,
              onEditReport: onEditReport,
              onCreateReport: onCreateReport
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = ScheduledReportsTable;

exports.emdash = emdash;
exports.Styles = Styles;
exports.initialState = initialState;
exports.reducer = reducer;
exports.TableRow = TableRow$1;
exports.TableHead = TableHead$1;
exports.DeleteConfirmation = DeleteConfirmation;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* container Not a pure module */
