// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("./RR.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var I18N$BsConsole = require("./I18N.js");
var Formality__Form = require("re-formality/lib/js/src/Formality__Form.bs.js");
var Task2$BsConsole = require("./Task2.js");
var Footer$BsConsole = require("./Footer.js");
var AuthForms$BsConsole = require("./auth/AuthForms.js");
var AuthTasks$BsConsole = require("./auth/AuthTasks.js");
var ViewContainer$BsConsole = require("./ViewContainer.js");
var Formality__PublicHelpers = require("re-formality/lib/js/src/Formality__PublicHelpers.bs.js");
var LoadingOverlay$BsConsole = require("./LoadingOverlay.js");

var ActivateFormContainer = Formality__Form.Make({
      validators: AuthForms$BsConsole.Activate.validators
    });

var gridPadding = Css.style(/* :: */[
      Css.padding(Css.px(12)),
      /* [] */0
    ]);

var buttonStyle = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var grid = Css.style(/* :: */[
      Css.alignItems(/* center */98248149),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* [] */0
      ]
    ]);

var grid$1 = Css.style(/* :: */[
      Css.alignItems(/* center */98248149),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* [] */0
      ]
    ]);

var card = Css.style(/* :: */[
      Css.width(Css.px(450)),
      /* [] */0
    ]);

var cardContent = Css.style(/* :: */[
      Css.important(Css.padding4(Css.px(32), Css.px(32), Css.px(16), Css.px(32))),
      /* [] */0
    ]);

var logoContainer = Css.style(/* :: */[
      Css.padding2(/* zero */-789508312, Css.px(16)),
      /* :: */[
        Css.marginBottom(Css.px(32)),
        /* [] */0
      ]
    ]);

var logo = Css.style(/* :: */[
      Css.height(Css.px(32)),
      /* [] */0
    ]);

var Styles = {
  grid: grid$1,
  card: card,
  cardContent: cardContent,
  logoContainer: logoContainer,
  logo: logo
};

var component = RR$BsConsole.statelessComponent("ActivatePage-BsConsole__Success");

function make(handleChangeUrl, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                              ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(gridPadding, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Success!")])),
                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Your account has been created.")]))
                                      ])),
                              ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(gridPadding, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                  /* Medium */861718677,
                                                  buttonStyle,
                                                  (function (_event) {
                                                      return Curry._1(handleChangeUrl, /* Login */Block.__(3, [{
                                                                      referrer: undefined,
                                                                      token: undefined,
                                                                      redirect: undefined,
                                                                      method: undefined
                                                                    }]));
                                                    }),
                                                  undefined,
                                                  undefined,
                                                  /* Flat */781662169,
                                                  /* Primary */-791844958,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  [I18N$BsConsole.show(undefined, "Back to log in")]
                                                ]))]))
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var Success = {
  component: component,
  make: make
};

var component$1 = RR$BsConsole.statelessComponent("ActivatePage-BsConsole__ActivateForm");

function make$1(handleChangeUrl, token, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry._3(ActivateFormContainer.make, {
                              password: "",
                              passwordConfirmation: ""
                            }, (function (state, param) {
                                var notifyOnFailure = param.notifyOnFailure;
                                var notifyOnSuccess = param.notifyOnSuccess;
                                return Task2$BsConsole.handleUnauthorized(AuthTasks$BsConsole.activate(token, state.password, undefined), undefined, (function (param) {
                                              if (!param.tag) {
                                                return Curry._1(notifyOnSuccess, undefined);
                                              }
                                              var match = param[0];
                                              if (match[0] === Task2$BsConsole.CoronerError) {
                                                return Curry._2(notifyOnFailure, /* [] */0, match[1].message);
                                              } else {
                                                return Curry._2(notifyOnFailure, /* [] */0, I18N$BsConsole.get(undefined, "Unknown error has occured"));
                                              }
                                            }));
                              }), (function (form) {
                                var match = form.status;
                                if (typeof match === "number" && match >= 2) {
                                  return ReasonReact.element(undefined, undefined, make(handleChangeUrl, []));
                                }
                                var partial_arg = form.submit;
                                var match$1 = form.status;
                                var tmp;
                                if (typeof match$1 === "number") {
                                  tmp = null;
                                } else {
                                  var msg = match$1[1];
                                  tmp = msg !== undefined ? ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(gridPadding, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V12 */11, /* Nowrap */-397426005, undefined, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, true, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, undefined, {
                                                                  fontSize: "16px"
                                                                }, undefined, [I18N$BsConsole.showSkip(msg)]))]))])) : null;
                                }
                                var match$2 = Curry._1(form.result, /* Password */0);
                                var tmp$1;
                                tmp$1 = match$2 !== undefined && match$2.tag ? true : false;
                                var match$3 = Curry._1(form.result, /* Password */0);
                                var tmp$2;
                                tmp$2 = match$3 !== undefined && match$3.tag ? match$3[0] : "";
                                var match$4 = Curry._1(form.result, /* PasswordConfirmation */1);
                                var tmp$3;
                                tmp$3 = match$4 !== undefined && match$4.tag ? true : false;
                                var match$5 = Curry._1(form.result, /* PasswordConfirmation */1);
                                var tmp$4;
                                tmp$4 = match$5 !== undefined && match$5.tag ? match$5[0] : "";
                                var match$6 = form.status;
                                return React.createElement("div", {
                                            style: {
                                              position: "relative"
                                            }
                                          }, React.createElement("form", {
                                                onSubmit: (function (param) {
                                                    return Formality__PublicHelpers.Dom.preventDefault(partial_arg, param);
                                                  })
                                              }, ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(gridPadding, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Headline */579538228, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Activate your account")]))])),
                                                        tmp,
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(gridPadding, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                            undefined,
                                                                            undefined,
                                                                            I18N$BsConsole.get(undefined, "Password"),
                                                                            true,
                                                                            (function ($$event) {
                                                                                return Curry._2(form.change, /* Password */0, Curry._2(AuthForms$BsConsole.Activate.Password.update, form.state, $$event.target.value));
                                                                              }),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            tmp$1,
                                                                            Caml_option.some(I18N$BsConsole.showSkip(tmp$2)),
                                                                            "password",
                                                                            /* `String */[
                                                                              -976970511,
                                                                              form.state.password
                                                                            ],
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            []
                                                                          ]))])),
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(gridPadding, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.TextField.Jsx2.make, [
                                                                            undefined,
                                                                            undefined,
                                                                            I18N$BsConsole.get(undefined, "Confirm password"),
                                                                            true,
                                                                            (function ($$event) {
                                                                                return Curry._2(form.change, /* PasswordConfirmation */1, Curry._2(AuthForms$BsConsole.Activate.PasswordConfirmation.update, form.state, $$event.target.value));
                                                                              }),
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            tmp$3,
                                                                            Caml_option.some(I18N$BsConsole.showSkip(tmp$4)),
                                                                            "password",
                                                                            /* `String */[
                                                                              -976970511,
                                                                              form.state.passwordConfirmation
                                                                            ],
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            []
                                                                          ]))])),
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(gridPadding, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                            /* Medium */861718677,
                                                                            buttonStyle,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            /* Raised */-387916264,
                                                                            /* Primary */-791844958,
                                                                            "submit",
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            [I18N$BsConsole.show(undefined, "Activate account")]
                                                                          ]))])),
                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(gridPadding, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, /* V12 */11, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                            /* Medium */861718677,
                                                                            buttonStyle,
                                                                            (function (_event) {
                                                                                return Curry._1(handleChangeUrl, /* Login */Block.__(3, [{
                                                                                                referrer: undefined,
                                                                                                token: undefined,
                                                                                                redirect: undefined,
                                                                                                method: undefined
                                                                                              }]));
                                                                              }),
                                                                            undefined,
                                                                            undefined,
                                                                            /* Flat */781662169,
                                                                            /* Primary */-791844958,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            [I18N$BsConsole.show(undefined, "Back to log in")]
                                                                          ]))]))
                                                      ]))), typeof match$6 === "number" && match$6 === 1 ? ReasonReact.element(undefined, undefined, LoadingOverlay$BsConsole.make([])) : null);
                              })));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var ActivateForm = {
  component: component$1,
  make: make$1
};

var component$2 = RR$BsConsole.reducerComponent("ActivatePage-BsConsole__NoFormScenario");

function make$2(handleChangeUrl, token, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (self) {
              var msg = self.state.remote;
              var tmp;
              tmp = typeof msg === "number" ? (
                  msg === /* NotAsked */0 ? null : ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.CircularProgress.Jsx2.make, undefined, 100, undefined, undefined, undefined, []))
                ) : (
                  msg.tag ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.showSkip(msg[0])])) : ReasonReact.element(undefined, undefined, make(handleChangeUrl, []))
                );
              return ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, undefined, [tmp]));
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component$2.retainedProps,
          reducer: (function (action, _state) {
              if (typeof action === "number") {
                if (action !== 0) {
                  return /* Update */Block.__(0, [{
                              remote: /* Success */Block.__(0, [I18N$BsConsole.get(undefined, "success")])
                            }]);
                } else {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              remote: /* Loading */1
                            },
                            (function (self) {
                                return Task2$BsConsole.handleUnauthorized(AuthTasks$BsConsole.activate(token, undefined, undefined), undefined, (function (param) {
                                              if (!param.tag) {
                                                return Curry._1(self.send, /* Success */1);
                                              }
                                              var match = param[0];
                                              if (match[0] === Task2$BsConsole.CoronerError) {
                                                return Curry._1(self.send, /* Failure */[match[1].message]);
                                              } else {
                                                return Curry._1(self.send, /* Failure */[I18N$BsConsole.get(undefined, "Unknown error has occured")]);
                                              }
                                            }));
                              })
                          ]);
                }
              } else {
                return /* Update */Block.__(0, [{
                            remote: /* Failure */Block.__(1, [action[0]])
                          }]);
              }
            }),
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var NoFormScenario = {
  component: component$2,
  make: make$2
};

var component$3 = ReasonReact.statelessComponent("ActivatePage-BsConsole");

function make$3(handleChangeUrl, token, set, _children) {
  return {
          debugName: component$3.debugName,
          reactClassInternal: component$3.reactClassInternal,
          handedOffState: component$3.handedOffState,
          willReceiveProps: component$3.willReceiveProps,
          didMount: component$3.didMount,
          didUpdate: component$3.didUpdate,
          willUnmount: component$3.willUnmount,
          willUpdate: component$3.willUpdate,
          shouldUpdate: component$3.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, ViewContainer$BsConsole.Re.make(Css.merge(/* :: */[
                                  grid$1,
                                  /* :: */[
                                    "login-container",
                                    /* [] */0
                                  ]
                                ]), [
                              ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.Card.Jsx2.make, card, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, cardContent, [
                                                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* center */98248149, /* center */98248149, undefined, undefined, logoContainer, [React.createElement("img", {
                                                                  className: logo,
                                                                  src: "https://res.cloudinary.com/backtrace/image/upload/v1677784857/saucelabs_backtrace_logo.svg"
                                                                })])),
                                                  set ? ReasonReact.element(undefined, undefined, make$1(handleChangeUrl, token, [])) : ReasonReact.element(undefined, undefined, make$2(handleChangeUrl, token, []))
                                                ]))])),
                              ReasonReact.element(undefined, undefined, Footer$BsConsole.make([]))
                            ]));
            }),
          initialState: component$3.initialState,
          retainedProps: component$3.retainedProps,
          reducer: component$3.reducer,
          jsElementWrapped: component$3.jsElementWrapped
        };
}

exports.ActivateFormContainer = ActivateFormContainer;
exports.gridPadding = gridPadding;
exports.buttonStyle = buttonStyle;
exports.grid = grid;
exports.Styles = Styles;
exports.Success = Success;
exports.ActivateForm = ActivateForm;
exports.NoFormScenario = NoFormScenario;
exports.component = component$3;
exports.make = make$3;
/* ActivateFormContainer Not a pure module */
