// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("./I18N.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Tooltip = require("@material-ui/core/Tooltip");
var IconButton = require("@material-ui/core/IconButton");
var HelpOutline = require("@material-ui/icons/HelpOutline");

function unwrapJsValue(value) {
  return value[1];
}

function webWidget(actionOpt, $$event, parameters, param) {
  var action = actionOpt !== undefined ? actionOpt : "";
  var unwrappedParameters = Belt_Option.map(parameters, unwrapJsValue);
  var action$1 = action === "" ? "webWidget" : "webWidget:" + action;
  window.zE(action$1, $$event, unwrappedParameters);
  
}

function initialize(param) {
  window.zESettings = {
    webWidget: {
      offset: {
        horizontal: "-1000px"
      }
    }
  };
  var scriptTag = document.createElement("script");
  scriptTag.setAttribute("type", "text/javascript");
  scriptTag.setAttribute("async", "true");
  scriptTag.setAttribute("src", "https://static.zdassets.com/ekr/snippet.js?key=5a7cb263-cb2c-43dd-a1ca-216d4c4d1748");
  scriptTag.setAttribute("id", "ze-snippet");
  Belt_Option.map(Belt_Array.get(Array.prototype.slice.call(document.getElementsByTagName("script")), 0), (function (topScript) {
          return Belt_Option.map(Caml_option.nullable_to_opt(topScript.parentNode), (function (head) {
                        return head.insertBefore(scriptTag, topScript);
                      }));
        }));
  
}

function zEExists(param) {
  return Curry._1((function() {
      return typeof window.zE === "function";
    }), undefined);
}

function zEInit(onSuccess, onFailure, iterations) {
  if (zEExists(undefined)) {
    return Curry._1(onSuccess, undefined);
  } else if (iterations >= 35) {
    return Curry._1(onFailure, undefined);
  } else {
    setTimeout((function (param) {
            return zEInit(onSuccess, onFailure, iterations + 1 | 0);
          }), 100);
    return ;
  }
}

function zEInitPromise(param) {
  return new Promise((function (resolve, param) {
                var onSuccess = function (param) {
                  return resolve(true);
                };
                var onFailure = function (param) {
                  return resolve(false);
                };
                setTimeout((function (param) {
                        return zEInit(onSuccess, onFailure, 0);
                      }), 100);
                
              }));
}

function onInitialized(configuration) {
  webWidget(undefined, "updateSettings", /* `Obj */[
        3950551,
        {
          offset: {
            horizontal: "0px",
            vertical: "0px"
          }
        }
      ], undefined);
  webWidget(undefined, "show", undefined, undefined);
  if (configuration !== undefined) {
    webWidget(undefined, "prefill", /* `Obj */[
          3950551,
          {
            email: {
              value: configuration.email,
              readOnly: true
            },
            name: {
              value: configuration.username
            }
          }
        ], undefined);
  }
  return webWidget("on", "close", /* `Callback */[
              -659008027,
              (function (param) {
                  return webWidget(undefined, "show", undefined, undefined);
                })
            ], undefined);
}

function ZenDesk$Standard(Props) {
  var configuration = Props.configuration;
  React.useEffect((function () {
          var __x = zEInitPromise(undefined);
          var __x$1 = __x.then((function (initialized) {
                  if (initialized) {
                    onInitialized(configuration);
                  }
                  return Promise.resolve(undefined);
                }));
          __x$1.catch((function (exn) {
                  console.log(exn);
                  return Promise.resolve(undefined);
                }));
          
        }), [configuration]);
  return null;
}

function make(configuration, _children) {
  var tmp = { };
  if (configuration !== undefined) {
    tmp.configuration = Caml_option.valFromOption(configuration);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ZenDesk$Standard, tmp, _children);
}

var Jsx2 = {
  make: make
};

var Standard = {
  onInitialized: onInitialized,
  make: ZenDesk$Standard,
  Jsx2: Jsx2
};

var iconButton = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.white)),
      /* [] */0
    ]);

var Styles = {
  iconButton: iconButton
};

function onInitialized$1(configuration) {
  webWidget(undefined, "hide", undefined, undefined);
  if (configuration !== undefined) {
    webWidget(undefined, "prefill", /* `Obj */[
          3950551,
          {
            email: {
              value: configuration.email,
              readOnly: true
            },
            name: {
              value: configuration.username
            }
          }
        ], undefined);
    return webWidget("on", "close", /* `Callback */[
                -659008027,
                (function (param) {
                    return webWidget(undefined, "hide", undefined, undefined);
                  })
              ], undefined);
  }
  
}

function ZenDesk$IconButton(Props) {
  var classNameOpt = Props.className;
  var configuration = Props.configuration;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useState((function () {
          return false;
        }));
  var setActive = match[1];
  React.useEffect((function () {
          var __x = zEInitPromise(undefined);
          var __x$1 = __x.then((function (initialized) {
                  if (initialized) {
                    onInitialized$1(configuration);
                    Curry._1(setActive, (function (param) {
                            return true;
                          }));
                  }
                  return Promise.resolve(undefined);
                }));
          __x$1.catch((function (exn) {
                  console.log(exn);
                  return Promise.resolve(undefined);
                }));
          
        }), [configuration]);
  if (match[0]) {
    return React.createElement(Tooltip.default, {
                placement: "bottom",
                children: React.createElement(IconButton.default, {
                      className: Css.merge(/* :: */[
                            iconButton,
                            /* :: */[
                              className,
                              /* [] */0
                            ]
                          ]),
                      onClick: (function (_event) {
                          webWidget(undefined, "show", undefined, undefined);
                          return webWidget(undefined, "open", undefined, undefined);
                        }),
                      children: React.createElement(HelpOutline.default, { })
                    }),
                title: I18N$BsConsole.get(undefined, "Help & support")
              });
  } else {
    return null;
  }
}

function make$1(className, configuration, _children) {
  var tmp = {
    configuration: configuration
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ZenDesk$IconButton, tmp, _children);
}

var Jsx2$1 = {
  make: make$1
};

var IconButton$1 = {
  Styles: Styles,
  onInitialized: onInitialized$1,
  make: ZenDesk$IconButton,
  Jsx2: Jsx2$1
};

exports.unwrapJsValue = unwrapJsValue;
exports.webWidget = webWidget;
exports.initialize = initialize;
exports.zEExists = zEExists;
exports.zEInit = zEInit;
exports.zEInitPromise = zEInitPromise;
exports.Standard = Standard;
exports.IconButton = IconButton$1;
/* iconButton Not a pure module */
